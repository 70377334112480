import React, { useMemo } from 'react';

import { useGalleryContext } from '@app/features/realEstate/gallery/GalleryProvider';
import { PropertyPageTypeGallery, trackGalleryVisit } from '@app/services/tracking/trackTracking';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { fromS3ID } from '@app/utils/from-s3id';
import { RealEstate } from '@app/libs/apollo/introspection';

import { RealEstateGalleryImageAppointment } from '../../../realEstateHeader/components/RealEstateGalleryImageAppointment';
import { SliderImageObject } from '../../../gallery/gallery.types';
import { getHeaderSliderImages } from '../utils/realEstateImages.utils';

interface UseImagesGallery {
  blurredCoverImageUrl: string;
  coverImageUrl: string;
  imagesCount: number;
  imagesGrid: SliderImageObject[];
  openMainGallery: (index: number) => () => void;
}

const IMAGES_TO_SHOW = 4;

export const useImagesGallery = (
  realEstate: RealEstate,
  showPrivateInformations: boolean,
  showImages: boolean
): UseImagesGallery => {
  const { stateOfCustomer } = useMe();
  const { openGallery, closeGallery } = useGalleryContext();

  const [headerSliderImages, blurredCoverImageUrl] = useMemo(
    () => [
      getHeaderSliderImages(realEstate, showPrivateInformations, stateOfCustomer).map(
        ({ url, ...image }) => ({
          ...image,
          url: fromS3ID(url, showImages ? 'q-100' : 'w-788,bl-25'),
        })
      ),
      fromS3ID(realEstate.coverImage, 'bl-20:q-50'),
    ],
    [realEstate, showPrivateInformations, stateOfCustomer, showImages]
  );

  const openMainGallery = (index: number) => () => {
    trackGalleryVisit({
      countGalleryImages: headerSliderImages.length,
      propertyId: realEstate.id,
      typeGallery: PropertyPageTypeGallery.MAIN,
    });
    openGallery({
      contact: realEstate.contact,
      defaultIndex: index,
      images: headerSliderImages,
      ...(showPrivateInformations && { url3d: realEstate.url3D }),
      ...(stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT && {
        appointmentImage: (
          <RealEstateGalleryImageAppointment
            propertyId={realEstate.id}
            imageUrl={blurredCoverImageUrl}
            onPress={closeGallery}
          />
        ),
      }),
    });
  };

  return {
    blurredCoverImageUrl,
    coverImageUrl: headerSliderImages[0]?.url || '',
    imagesCount: headerSliderImages.length,
    imagesGrid: headerSliderImages.slice(1, IMAGES_TO_SHOW + 1),
    openMainGallery,
  };
};
