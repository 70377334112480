/**
 * @desc Copy `console.error` in a variable, before the mock, to be able to log errors that we don't want to hide.
 * @param regexList {string[]}
 */
export const mockError = (regexList: string[]): (() => void) => {
  const errorLog = console.error;
  return jest.spyOn(console, 'error').mockImplementation((...args) => {
    const logs = args.join(' ');
    if (!regexList.some(rx => logs.search(rx) !== -1)) {
      errorLog(...args);
    }
  }).mockRestore;
};
