import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="sz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#sz_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h28V0H0v4zM0 20h28v-4H0v4z"
          fill="#486BCA"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 16h28V4H0v12z" fill="#FFDF20" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.667h28V5.333H0v9.334z"
          fill="#CF1615"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 13.333c3.314 0 6-3.333 6-3.333s-2.686-3.333-6-3.333S8 10 8 10s2.686 3.333 6 3.333z"
          fill="#fff"
        />
        <Mask
          id="sz_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={8}
          y={6}
          width={12}
          height={8}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 13.333c3.314 0 6-3.333 6-3.333s-2.686-3.333-6-3.333S8 10 8 10s2.686 3.333 6 3.333z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#sz_svg__b)" fillRule="evenodd" clipRule="evenodd">
          <Path d="M14.667 6.667H8v6.666h5.333l1.334-6.666z" fill="#262626" />
          <Path d="M15.333 10.667a.667.667 0 100-1.334.667.667 0 000 1.334z" fill="#1A1A1A" />
          <Path d="M12.667 10.667a.667.667 0 100-1.333.667.667 0 000 1.333z" fill="#F6F6F6" />
        </G>
      </G>
    </Svg>
  );
};

export default SvgSz;
