import React from 'react';

import { View, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

import { useTranslation } from '@app/services/translations/translations';
import { PositioningStatus } from '@app/libs/apollo/introspection';
import { Chip, Hoverable, LikeButton, RadiusContainer, Theme, useTheme } from '@app/ui';
import { getRealEstateTag } from '@app/utils/real-estate/getRealEstateTag.utils';

export interface RealEstateHeaderProps {
  image: string;
  isBookmarked: boolean;
  isNew?: boolean;
  shortTermNightlyRent?: number;
  isExclusive?: boolean;
  positioningStatus?: PositioningStatus;
  isInTransaction?: boolean;
  expirationDate?: Date;
  isViewed: boolean;
}

type RealEstatePreviewCardHeaderProps = {
  onBookmark: () => void;
} & RealEstateHeaderProps;

const OPACITY_VIEWED_CARD = 0.5;

const getStyles = (theme: Theme, isViewed: boolean) =>
  StyleSheet.create({
    container: {
      borderWidth: 0,
      height: 282,
      overflow: 'hidden',
      width: '100%',
    },
    headerTag: {
      left: theme.spacing.SIZE_04,
      position: 'absolute',
      top: theme.spacing.SIZE_04,
    },
    image: { opacity: isViewed ? OPACITY_VIEWED_CARD : 1 },
    likeButton: {
      bottom: theme.spacing.SIZE_04,
      position: 'absolute',
      right: theme.spacing.SIZE_04,
    },
  });

export const RealEstatePreviewCardHeader: React.FC<RealEstatePreviewCardHeaderProps> = ({
  image,
  isNew,
  shortTermNightlyRent,
  isExclusive,
  positioningStatus,
  isInTransaction,
  expirationDate,
  isBookmarked,
  isViewed,
  onBookmark,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const styles = getStyles(theme, isViewed);

  const realEstateHeaderChipProps = getRealEstateTag({
    expirationDate,
    isExclusive,
    isInTransaction,
    isNew,
    language: i18n.resolvedLanguage,
    positioningStatus,
    shortTermNightlyRent,
    shortVersion: true,
    translate: t,
  });

  const imageScale = useSharedValue(1);

  const animatedStyle = useAnimatedStyle(() => ({
    ...StyleSheet.absoluteFillObject,
    transform: [{ scale: withSpring(imageScale.value) }],
  }));

  const onHoverIn = () => {
    imageScale.value = 1.1;
  };

  const onHoverOut = () => {
    imageScale.value = 1;
  };

  return (
    <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      <RadiusContainer style={styles.container} radiusSize={theme.borderRadius.SIZE_06}>
        <Animated.Image
          style={[animatedStyle, styles.image]}
          source={{
            uri: image,
          }}
        />
        {!!realEstateHeaderChipProps && (
          <View style={styles.headerTag} testID="real-estate-tag">
            <Chip {...realEstateHeaderChipProps} size="medium" />
          </View>
        )}

        <View style={styles.likeButton}>
          <LikeButton
            liked={isBookmarked}
            onPress={onBookmark}
            size="s"
            testID="real-estate-like-btn"
          />
        </View>
      </RadiusContainer>
    </Hoverable>
  );
};
