import * as segment from '@app/libs/segment/segment';
import { RealEstate, TransactionStatus } from '@app/libs/apollo/introspection';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import {
  AdvisorNavigatorRoutes,
  DebugMenuNavigatorRoutes,
  DiscussNavigatorRoutes,
  ForgotPasswordNavigatorRoutes,
  HomePageNavigatorRoutes,
  MainMenuNavigatorRoutes,
  ProfileNavigatorRoutes,
  ProjectNavigatorRoutes,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
  Routes,
} from '@app/navigation/types/routes';
import { ExtendedSearchResultsType } from '@app/features/search-engine/searchEngine.types';

import { getRealEstateBadgeType } from './utils';

/** List of screens not controlled by navigation */
export enum NativeScreenName {
  VIEW_PROFILE_DOCUMENT = '/profile/document',
  ERROR = '/error',
  FAVORITES = '/properties/favorites',
  PRESELECTION = '/properties/selected',
  FORGOT_PASSWORD_CODESTEP = '/forgot-password/codeStep',
  FORGOT_PASSWORD_NEW_PASSWORD = '/forgot-password/newPassword',
  PROJECT = 'projectPage',
  PROJECT_URL = '/projects',
  PROJECTS = 'projectListPage',
  LOGIN = '/login',
  AUTH = '/auth',
  ONBOARDING = 'welcome',
}

export const trackChangeScreen = (
  pageId: Routes | NativeScreenName,
  params: {
    realEstate?: RealEstate;
    method?: SignInMethod;
    document?: { id: string; category: string };
    count?: number;
    isFromNotifications?: boolean;
    isFromSearchEngine?: boolean;
    id?: string;
    realEstatePosition?: number;
    soldRealEstate?: {
      id: string;
    };
    project?: { id: string; status?: TransactionStatus | null };
    projectNumber?: number;
    eventName?: string;
    fromExtendedResultsSection?: ExtendedSearchResultsType;
  } = {}
): void => {
  switch (pageId) {
    case ProfileNavigatorRoutes.ProfileRoot:
      segment.screen(`View profile page`);
      break;

    case AdvisorNavigatorRoutes.AdvisorRoot:
      segment.screen(`View advisor page`);
      break;

    case PublicNavigatorRoutes.Register:
      segment.screen('View registration', {
        method: params.method,
      });
      break;

    case RealEstateNavigatorRoutes.RealEstateList:
      segment.screen('View matching page');
      break;

    case RealEstateNavigatorRoutes.RealEstate:
      if (params.realEstate) {
        segment.screen('View property page', {
          annualRevaluationRate: params.realEstate.project.renting.annual.revaluationRate,
          city: params.realEstate.building.address.city,
          investStrategy: params.realEstate.project.strategy,
          isARecommandation: !!params.realEstate?.metadata?.positioning?.isSuggestion,
          isFromExtendedResults: !!params.fromExtendedResultsSection,
          ...(params.fromExtendedResultsSection && {
            extendedResultsSection: params.fromExtendedResultsSection,
          }),
          isFromNotifications: params.isFromNotifications,
          locationType: params.realEstate.project.renting.type,
          propertyId: params.realEstate.id,
          propertyPosition: params.realEstatePosition,
          region: params.realEstate?.building.address?.regionName,
          renovationAmount: params.realEstate.project.renovation.amount,
          type: params.realEstate.type,
          ...(params.isFromSearchEngine && {
            badgeType: getRealEstateBadgeType(
              params.realEstate.isNew,
              params.realEstate.isExclusive,
              !!params.realEstate.project.renting.shortTerm
            ),
          }),
        });
      }
      break;

    case RealEstateNavigatorRoutes.RealEstateSold:
      if (params.soldRealEstate) {
        segment.screen('View sold property page', {
          id: params.soldRealEstate.id,
        });
      }
      break;

    case ProfileNavigatorRoutes.ProfileDocumentsList:
      segment.screen('View profile document');
      break;

    case ProfileNavigatorRoutes.ProfilePassword:
      segment.screen('View profile password');
      break;

    case NativeScreenName.VIEW_PROFILE_DOCUMENT:
      segment.screen('View a document', {
        documentCategory: params.document?.category,
        documentId: params.document?.id,
      });
      break;

    case ProfileNavigatorRoutes.ProfileSponsorship:
      segment.screen('View profile sponsorship');
      break;

    case NativeScreenName.ERROR:
      segment.screen('View error page');
      break;

    case NativeScreenName.FAVORITES:
      segment.screen('View favorites page', { count: params.count || 0 });
      break;

    case ProfileNavigatorRoutes.ProfileInformation:
      segment.screen('View profile information');
      break;

    case PublicNavigatorRoutes.ForgotPassword:
      segment.screen('View forgotten password');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep:
      segment.screen('View forgotten code');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep:
      segment.screen('View forgotten confirmed password');
      break;

    case ProjectNavigatorRoutes.Project:
      segment.screen('Project Detail', {
        projectId: params.project?.id,
        projectStatus: params.project?.status,
        propertyId: params.realEstate?.id,
      });
      break;

    case MainMenuNavigatorRoutes.Projects:
      segment.screen('Project Menu page', {
        projectNumber: params.projectNumber,
      });
      break;

    case HomePageNavigatorRoutes.HomePageRoot:
      segment.screen('View homepage');
      break;

    case PublicNavigatorRoutes.Login:
      segment.screen('View login form page');
      break;

    case PublicNavigatorRoutes.Auth:
      segment.screen('View auth form page');
      break;
    case NativeScreenName.ONBOARDING:
      params.eventName && segment.screen(params.eventName);
      break;
    case ProfileNavigatorRoutes.ProfileNotificationPreferences:
      segment.screen('View profile notification settings');
      break;
    case DiscussNavigatorRoutes.DiscussRoot:
      segment.screen('View discuss page');
      break;

    case MainMenuNavigatorRoutes.RealEstates:
    case AdvisorNavigatorRoutes.AdvisorAppointment:
    case DebugMenuNavigatorRoutes.DebugMenuRoot:
      return;
    default:
      // we hide console on production thanks to "babel-plugin-transform-remove-console"
      console.log(`${pageId} [Tracking] route doesn't exist`, { pageId, params });
      break;
  }
};
