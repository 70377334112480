import React, { useCallback } from 'react';

import { StackScreenProps } from '@react-navigation/stack';

import { useRedirectByEmail } from '@app/shared/hooks/useRedirectByEmail';
import { AuthenticationStrategy } from '@app/libs/identity/identity.type';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

type SsoRedirectScreenProp = StackScreenProps<
  PublicStackParamList,
  PublicNavigatorRoutes.SsoRedirect
>;

export const SsoRedirect: React.FC<SsoRedirectScreenProp> = ({ navigation, route }) => {
  const { redirectByEmailSSO } = useRedirectByEmail();
  const { idToken, type, id } = route.params ?? {};

  const redirectApple = useCallback(async () => {
    const { email } = route.params;

    return redirectByEmailSSO({
      idToken,
      method: SignInMethod.Apple,
      strategy: AuthenticationStrategy.Apple,
      userInfo: { email, id },
    });
  }, [route.params, redirectByEmailSSO, idToken, id]);

  if (idToken && type === 'apple') {
    redirectApple();
  } else {
    navigation.push(PublicNavigatorRoutes.Auth);
  }

  return null;
};
