import { UseSlideTypeResponse } from '@app/ui';

import { CarouselType } from './components/carousel/Carousel.types';
import { OnboardingScreenType } from './components/onboarding-screen/OnboardingScreen.types';

export enum ActionType {
  'NEXT' = 'NEXT',
  'HOME' = 'HOME',
  'ADVISOR' = 'ADVISOR',
}

export type OnboardingPagesScreenType = (Omit<
  OnboardingScreenType,
  'primaryAction' | 'secondaryAction'
> & {
  primaryAction?: ActionType;
  secondaryAction?: ActionType;
  key: string;
  pageTrackingEvent: string;
})[];

export type OnboardingTemplateType = Omit<CarouselType, 'children' | 'currentStep'> & {
  onboardingPagesScreen: OnboardingPagesScreenType;
  actions: { [key in ActionType]: () => void };
  nbSlide: number;
  carouselProps: UseSlideTypeResponse['carouselProps'];
  //step;
};
