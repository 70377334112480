import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgLk = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="lk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#lk_svg__a)">
        <Path fill="#FFBF19" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.667 1.333h14.666c.737 0 1.334.597 1.334 1.334v14.666c0 .737-.597 1.334-1.334 1.334H10.667V1.333z"
          fill="#A52531"
        />
        <Path
          d="M1.333 2.667c0-.737.597-1.334 1.334-1.334h2.666v17.334H2.667a1.333 1.333 0 01-1.334-1.334V2.667z"
          fill="#03664F"
        />
        <Rect x={5.333} y={1.333} width={4} height={17.333} rx={0.667} fill="#FF6816" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.333 3.333a.667.667 0 11-1.333 0v-.666h.667c.368 0 .666.298.666.666zm10.667 0a.667.667 0 001.333 0v-.666h-.666a.667.667 0 00-.667.666zM24.667 16a.667.667 0 000 1.333h.666v-.666a.667.667 0 00-.666-.667zm-11.334.667a.667.667 0 10-1.333 0v.666h.667a.667.667 0 00.666-.666zM16 12.574a.574.574 0 00-.574-.574.535.535 0 01-.18-.03c-.386-.139-1.913-.72-1.913-1.303 0-.365.2-.73.38-.986a.832.832 0 00.12-.766c-.226-.598-.593-1.687-.5-2.248.05-.3.25-.561.48-.772.355-.326.854-.018.854.464v2.562c0 .253.142.484.368.597l.677.338a.447.447 0 10.4-.8l-.779-.39V8c.332 0 .438-.448.141-.596l-.14-.07v-.667h1.056c.177 0 .347-.07.472-.196l.276-.276A.667.667 0 0117.61 6h.646c.252 0 .483.143.596.369l.359.717c.079.158.092.342.036.51l-.287.86a.667.667 0 00.633.877h2.191a.524.524 0 00.234-.991l-.543-.272a.667.667 0 00-.298-.07h-.51A.667.667 0 0120 7.333v-.39c0-.177.07-.347.195-.472L20.667 6v.667c0 .368.298.666.666.666h.922c.252 0 .483.143.596.369l.429.858c.035.07.058.147.065.225.03.336.104 1.305-.012 1.882-.093.467-.355.9-.521 1.14a.77.77 0 00-.145.435v1.915a.51.51 0 11-.965-.227l.228-.456a.315.315 0 000-.281l-.032-.063c-.14-.282-.565-.182-.565.133 0 .046-.01.092-.031.133l-.47.941a.596.596 0 11-1.067-.533l.165-.33a.666.666 0 00.07-.298v-.51a.667.667 0 00-.667-.666H18a.667.667 0 00-.667.667v.509a.668.668 0 01-.07.298l-.432.863a.596.596 0 11-1.066-.533l.165-.33a.666.666 0 00.07-.298v-.602z"
          fill="#FFBF18"
        />
      </G>
    </Svg>
  );
};

export default SvgLk;
