import React, { useCallback } from 'react';

import { StyleSheet, View } from 'react-native';

import { ListItem } from '@app/ui/atoms/list/ListItem';
import { Text } from '@app/ui/atoms/Text/Text';
import { Checkbox, Expandable, ExpandableContext, Icon, Theme, useTheme } from '@app/ui';

import { SelectGroupOption, SelectGroupProps } from './SelectGroup.types';

type SelectGroupItemProps = {
  label: string;
  options: (SelectGroupOption & {
    selected: boolean;
  })[];
} & {
  isFirst: boolean;
  multiple: boolean;
  canSelectAll: boolean;
  closeList: () => void;
  onChangeValues: SelectGroupProps['onChangeValues'];
  values: SelectGroupProps['values'];
} & (
    | {
        multiple: true;
        canSelectAll?: boolean;
      }
    | {
        multiple?: false;
        canSelectAll?: false;
      }
  );

const getStyles = ({ theme, isFirst }: { theme: Theme; isFirst: boolean }) =>
  StyleSheet.create({
    body: {
      paddingLeft: theme.spacing.SIZE_08,
    },
    header: {
      alignItems: 'center',
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing.SIZE_06 - theme.spacing.SIZE_01,
      paddingTop: isFirst ? 0 : theme.spacing.SIZE_06 - theme.spacing.SIZE_01,
    },
    headerLeft: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_04 - theme.spacing.SIZE_01,
    },
    headerText: {
      paddingTop: theme.spacing.SIZE_01,
    },
  });

export const SelectGroupItem: React.FC<SelectGroupItemProps> = ({
  options,
  label,
  multiple,
  canSelectAll,
  closeList,
  onChangeValues,
  values,
  isFirst = false,
}) => {
  const theme = useTheme();
  const styles = getStyles({ isFirst, theme });
  const isAllGroupOptionsSelected = options.every(option => option.selected);
  const optionKeys = options.map(option => option.key);

  const onUnSelectAllGroupOptions = () => {
    onChangeValues([...values.filter(key => !optionKeys.includes(key))]);
  };

  const onSelectAllGroupOptions = () => {
    onChangeValues([...new Set([...values, ...optionKeys])]);
  };

  const createSelectHandler = useCallback(
    (item: SelectGroupOption) => () => {
      if (multiple) {
        const currentItem = item.key;
        const isSelected = values.find(value => value === currentItem);

        if (isSelected) {
          onChangeValues(values.filter(value => value !== currentItem));
        } else {
          onChangeValues([...values, item.key]);
        }
      } else {
        onChangeValues([item.label]);
        closeList();
      }
    },
    [closeList, multiple, onChangeValues, values]
  );

  return (
    <Expandable key={label} id={label}>
      <Expandable.Header>
        <View>
          <ExpandableContext.Consumer>
            {({ expanded }) => (
              <View style={styles.header}>
                <View style={styles.headerLeft}>
                  {!!canSelectAll && (
                    <Checkbox
                      testID="select-group-header-checkbox"
                      checked={isAllGroupOptionsSelected}
                      onChange={
                        isAllGroupOptionsSelected
                          ? onUnSelectAllGroupOptions
                          : onSelectAllGroupOptions
                      }
                    />
                  )}
                  <Text textStyle="Body2Medium" style={styles.headerText}>
                    {label}
                  </Text>
                </View>
                {expanded ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
              </View>
            )}
          </ExpandableContext.Consumer>
        </View>
      </Expandable.Header>
      <Expandable.Content>
        <View style={styles.body}>
          {options.map(option => (
            <ListItem
              onPress={createSelectHandler(option)}
              checkbox={multiple}
              checked={option.selected}
              {...option}
              key={option.label.toLowerCase()}
            />
          ))}
        </View>
      </Expandable.Content>
    </Expandable>
  );
};
