import { SearchEngineHeaderType } from '@app/features/search-engine/compounds/search-engine-header/searchEngineHeader.type';
import {
  AvailableCountryCode,
  PropertyKind,
  RoomCountFilter,
} from '@app/libs/apollo/introspection';
import { SearchEngineFiltersDrawerType } from '@app/features/search-engine/compounds/search-engine-filters/search-engine-filters-drawer/searchEngineFiltersDrawer.type';
import { StateOfCustomer } from '@app/shared/hooks/useMe';
import { Trilean } from '@app/ui/molecules/trilean-controller/TrileanController.types';
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';

import { UseSearchEngineFiltersProps } from './hooks/useSearchEngineFilters';
import { SearchEngineSortStrategy } from './compounds/search-engine-filters/search-engine-sort/SearchEngineSort.types';
import { SoldRealEstateItem } from './compounds/sold-real-estate-card/SoldRealEstateCard';

export enum SearchEngineSection {
  ALL = 'all',
  BOOKMARKED = 'bookmarked',
}

export enum SearchEngineSource {
  DRAWER = 'drawer',
  NO_RESULT = 'noResult',
  BUDGET_QF = 'budgetQF',
  COUNTRY_QF = 'countryQF',
  REGION_QF = 'regionQF',
  ZONE_QF = 'zoneQF',
  PROPERTY_TYPE_QF = 'propertyTypeQF',
  SORT_STRATEGY = 'sortStrategy',
}

export enum ExtendedSearchResultsType {
  HigherBudget = 'HigherBudget',
  Nearby = 'Nearby',
}

export type SearchEngineLocationItem = {
  threshold?: number;
  position: number;
};

export type SearchEngineFilterFormProps = {
  alreadyRented?: Trilean;
  atGroundFloor?: Trilean;
  budgetMax?: number | null;
  budgetMin?: number | null;
  capacity?: number | null;
  country?: AvailableCountryCode | null;
  flatSharing?: Trilean;
  regionList?: string[];
  zones?: string[];
  roomCount?: RoomCountFilter[];
  typology?: PropertyKind[];
  withRenovation?: Trilean;
  withShortTermRental?: Trilean;
  sortStrategy?: SearchEngineSortStrategy;
};

export enum SearchEngineRealEstatesLoadingType {
  FetchMore = 'fetchmore',
  Refetch = 'refetch',
}

export type SearchEngineFilterForm = Required<Omit<SearchEngineFilterFormProps, 'capacity'>>;
export type SearchEngineItem = RealEstatePreviewItem | SoldRealEstateItem;

export type SearchEngineType = {
  state: StateOfCustomer;
  hasMore?: boolean;
  list: SearchEngineItem[];
  withHigherBudgetList: RealEstatePreviewItem[];
  withNearbyList: RealEstatePreviewItem[];
  header: Pick<
    SearchEngineHeaderType,
    | 'section'
    | 'state'
    | 'resultCount'
    | 'bookmarkedCount'
    | 'filterCount'
    | 'onSectionChange'
    | 'hideFilterButton'
  >;
  filter: SearchEngineFilterFormProps & {
    defaultValues: SearchEngineFilterFormProps;
  };
  sortStrategy?: SearchEngineSortStrategy;
  position?: number;
  regionListByCountry: SearchEngineFiltersDrawerType['regionListByCountry'];
  zoneLocationListByCountry: SearchEngineFiltersDrawerType['zoneLocationListByCountry'];
  listLoading: boolean;
  listLoadingIsRefresh?: boolean;
  filterLoading: boolean;
  onFilterShow: (source: string) => Promise<void> | void;
  onFilterReset: UseSearchEngineFiltersProps['resetSearchEngineFilters'];
  onFilterUpdate: (
    filter: SearchEngineFilterForm,
    source: SearchEngineSource
  ) => Promise<void> | void;
  onFilterCountryClick: SearchEngineFiltersDrawerType['onCountryClick'];
  onItemClick: (
    item: SearchEngineItem,
    info: SearchEngineLocationItem,
    extendedResultsSection?: ExtendedSearchResultsType
  ) => Promise<void> | void;
  onItemBookmark: (item: RealEstatePreviewItem) => Promise<void> | void;
  onLoadMoreRealEstates?: () => void;
  form: any;
  // form: UseFormReturn<SearchEngineFilterForm>; Break typescript for tests in SearchEngine.template.test.tsx
  resetForm: () => void;
};
