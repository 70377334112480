import * as React from 'react';

import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const SvgYoutube = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <G
        clipPath="url(#youtube_svg__clip0_4874_4159)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color}
      >
        <Path d="M12 3v2h-.075a86.587 86.587 0 00-1.016.007c-.668.007-1.575.022-2.534.05-.961.029-1.966.071-2.833.134-.888.065-1.548.147-1.877.233a1.78 1.78 0 00-1.227 1.25A28 28 0 002 11.745v.011a28 28 0 00.434 5.093 1.78 1.78 0 001.227 1.186c.327.087.99.168 1.881.234.867.063 1.872.105 2.833.134a136.735 136.735 0 003.55.057h.37a140.377 140.377 0 003.33-.057c.961-.029 1.966-.071 2.833-.134.888-.065 1.548-.147 1.877-.233a1.78 1.78 0 001.227-1.25c.298-1.66.444-3.345.438-5.032v-.01a27.995 27.995 0 00-.438-5.11 1.78 1.78 0 00-1.217-1.247c-.34-.08-1.002-.154-1.88-.212a75.137 75.137 0 00-2.837-.123 149.26 149.26 0 00-3.333-.051h-.22L12 5V4 3h.08a106.602 106.602 0 011.032.007c.675.006 1.595.02 2.57.046.974.026 2.01.066 2.915.126.88.059 1.714.141 2.24.27a3.78 3.78 0 012.673 2.729l-.97.242.984-.178c.328 1.818.487 3.662.476 5.51a29.997 29.997 0 01-.49 5.49 3.78 3.78 0 01-2.638 2.72l-.014.004c-.533.143-1.375.233-2.255.297-.907.066-1.944.11-2.918.14a138.654 138.654 0 01-3.605.057h-.16a117.552 117.552 0 01-1.033-.007c-.676-.008-1.596-.022-2.572-.05a70.548 70.548 0 01-2.918-.14c-.88-.064-1.722-.154-2.255-.297l-.006-.001a3.78 3.78 0 01-2.638-2.611.996.996 0 01-.022-.096A30 30 0 010 11.748a30 30 0 01.49-5.532 3.78 3.78 0 012.638-2.72l.014-.003c.533-.143 1.375-.233 2.255-.297.907-.066 1.944-.11 2.918-.14A138.228 138.228 0 0111.92 3H12z" />
        <Path d="M9.247 7.616a1 1 0 01.997-.005l5.75 3.27a1 1 0 010 1.738l-5.75 3.27a1 1 0 01-1.494-.869V8.48a1 1 0 01.497-.864zm1.503 2.583v3.102l2.727-1.551-2.727-1.55z" />
      </G>
      <Defs>
        <ClipPath id="youtube_svg__clip0_4874_4159">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SvgYoutube;
