import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCircle = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12.36 22.45a9.731 9.731 0 01-3.9-.788 10.09 10.09 0 01-3.174-2.137c-.9-.9-1.613-1.958-2.137-3.175a9.732 9.732 0 01-.788-3.9c0-1.383.262-2.683.788-3.9a10.091 10.091 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.138a9.743 9.743 0 013.9-.787c1.383 0 2.683.263 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.959 2.137 3.175a9.731 9.731 0 01.788 3.9 9.73 9.73 0 01-.788 3.9 10.09 10.09 0 01-2.137 3.175c-.9.9-1.959 1.613-3.175 2.137a9.73 9.73 0 01-3.9.788zm0-2c2.217 0 4.105-.779 5.664-2.337 1.558-1.558 2.337-3.446 2.337-5.663 0-2.216-.78-4.104-2.337-5.663C16.465 5.23 14.577 4.45 12.36 4.45c-2.217 0-4.104.78-5.662 2.337C5.14 8.347 4.36 10.234 4.36 12.45c0 2.217.779 4.105 2.338 5.663 1.558 1.558 3.445 2.337 5.662 2.337z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCircle;
