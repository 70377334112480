import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPricetag = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12.85 22a2.07 2.07 0 01-1.45-.6l-8.8-8.8a2.069 2.069 0 01-.6-1.45V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 014 2h7.15c.283 0 .55.054.8.162.25.109.467.255.65.438l8.8 8.825c.383.383.575.854.575 1.412a1.92 1.92 0 01-.575 1.413l-7.15 7.15a2.114 2.114 0 01-.637.437 1.841 1.841 0 01-.763.163zm-.025-2l7.15-7.15L11.15 4H4v7.15L12.825 20zM6.5 8c.417 0 .77-.146 1.062-.438C7.854 7.271 8 6.917 8 6.5c0-.417-.146-.77-.438-1.062A1.444 1.444 0 006.5 5c-.417 0-.77.146-1.062.438A1.444 1.444 0 005 6.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPricetag;
