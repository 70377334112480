import { isSameDay, startOfDay } from 'date-fns';

export const getDiffDays = (date1: Date, date2: Date): number => {
  if (isSameDay(date1, date2)) {
    return 0;
  }

  const diffTime = Math.abs(startOfDay(date2).getTime() - startOfDay(date1).getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
