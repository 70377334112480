import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPercent = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7.89 10.961a3.372 3.372 0 01-2.475-1.025A3.372 3.372 0 014.39 7.461c0-.967.342-1.792 1.025-2.475A3.372 3.372 0 017.89 3.961c.967 0 1.792.342 2.475 1.025a3.372 3.372 0 011.025 2.475c0 .967-.342 1.792-1.025 2.475a3.373 3.373 0 01-2.475 1.025zm0-2c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062a1.444 1.444 0 00-1.062-.438c-.417 0-.77.146-1.062.438a1.444 1.444 0 00-.438 1.062c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zm9 11a3.372 3.372 0 01-2.475-1.025 3.372 3.372 0 01-1.025-2.475c0-.967.342-1.792 1.025-2.475a3.372 3.372 0 012.475-1.025c.967 0 1.792.342 2.475 1.025a3.372 3.372 0 011.025 2.475c0 .967-.342 1.792-1.025 2.475a3.373 3.373 0 01-2.475 1.025zm0-2c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062a1.444 1.444 0 00-1.062-.438c-.417 0-.77.146-1.062.438a1.444 1.444 0 00-.438 1.062c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zm-11.8 1.3a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7l13.2-13.2a.948.948 0 01.7-.275c.283 0 .517.092.7.275a.948.948 0 01.275.7.948.948 0 01-.275.7l-13.2 13.2a.948.948 0 01-.7.275.948.948 0 01-.7-.275z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPercent;
