// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';

import { ActionButton } from '@app/ui/molecules/ActionButton';

import { CounterActivatorProps, GenericFormatActivator } from './CounterActivator.types';
import { formatActivatorDefault, FormatActivatorDefaultType } from './CounterActivator.utils';

export { CounterActivatorProps };

export const CounterActivator = <T extends GenericFormatActivator = FormatActivatorDefaultType>({
  onPress,
  open,
  values,
  placeholder,
  formatActivatorValues,
  noCounter = false,
  disabled,
  ...rest
}: CounterActivatorProps<T>): JSX.Element => {
  const { count, valueString } = formatActivatorValues
    ? formatActivatorValues(placeholder, values)
    : formatActivatorDefault(placeholder, values);

  return (
    <ActionButton
      onPress={onPress}
      rightIconName={open ? 'ChevronUp' : 'ChevronDown'}
      active={open || !!count}
      label={valueString}
      count={!noCounter && !disabled ? count : 0}
      withSolidBorder={true}
      testID="counter-activator"
      disabled={disabled}
      {...rest}
    />
  );
};
