import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgAw = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#4189DD" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.65 3.98l.685-1.313.686 1.314 1.314.686-1.314.685-.686 1.315-.686-1.315-1.314-.685L4.65 3.98z"
        fill="#D21034"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.65 3.98l.685-1.313.686 1.314 1.314.686-1.314.685-.686 1.315-.686-1.315-1.314-.685L4.65 3.98zm-.495 1.867l1.18 2.26 1.18-2.26 2.261-1.18-2.261-1.18-1.18-2.261-1.18 2.26-2.261 1.18 2.261 1.18z"
        fill="#fff"
      />
      <Path d="M0 14.667h28V16H0v-1.333zM0 12h28v1.333H0V12z" fill="#F9D616" />
    </Svg>
  );
};

export default SvgAw;
