import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StyleSheet } from 'react-native';

import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
// eslint-disable-next-line max-len
import { FurnishmentDetailsTemplate } from '@app/features/realEstate/realEstateAvailable/realEstateRenovation/components/furnishment/FurnishmentDetailsTemplate';
import { Theme, useTheme } from '@app/ui';

type FurnishmentDetailsViewProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.FurnishmentDetails
>;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    safeArea: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },
  });

export const FurnishmentDetailsView: React.FC<FurnishmentDetailsViewProps> = ({
  route,
  navigation,
}) => {
  const { furnishingAmount, roomCount } = route.params;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <SafeAreaView style={styles.safeArea} edges={['top']}>
      <FurnishmentDetailsTemplate
        furnishingAmount={furnishingAmount}
        roomCount={roomCount}
        onClose={navigation.goBack}
      />
    </SafeAreaView>
  );
};
