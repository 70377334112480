import { IconName } from '@app/ui';
import { SynthesisTheme } from '@app/libs/apollo/introspection';

export const getIconFromTheme = (theme: SynthesisTheme): IconName => {
  switch (theme) {
    case SynthesisTheme.AdditionalLots:
      return 'BuildingPlus';
    case SynthesisTheme.AlreadyRentedProperty:
      return 'Feather';
    case SynthesisTheme.BrightApartment:
      return 'Sun';
    case SynthesisTheme.CoOwnershipManagement:
      return 'RealEstateAgent';
    case SynthesisTheme.CoOwnershipService:
      return 'LocationAutomation';
    case SynthesisTheme.CoOwnershipWork:
      return 'Engineer';
    case SynthesisTheme.DiscountedFromZone:
      return 'TrendingDown';
    case SynthesisTheme.Employment:
      return 'BriefCase';
    case SynthesisTheme.EnergyPerformance:
      return 'Zap';
    case SynthesisTheme.ExceptionalProperty:
      return 'Medal';
    case SynthesisTheme.HighProfitability:
      return 'Invest';
    case SynthesisTheme.LandValuation:
      return 'Monitoring';
    case SynthesisTheme.Location:
      return 'Location';
    case SynthesisTheme.PropertyPrice:
      return 'SellPrice';
    case SynthesisTheme.PropertySituation:
      return 'Works';
    case SynthesisTheme.PublicInvestmentProject:
      return 'Radar';
    case SynthesisTheme.RentalTension:
      return 'Key';
    case SynthesisTheme.SellerType:
      return 'Pricetag';
    case SynthesisTheme.Student:
      return 'School';
    case SynthesisTheme.Transport:
      return 'Metro';
    default:
      return 'Medal';
  }
};
