import { TFunction } from 'react-i18next';

import { trackTooltip } from '@app/services/tracking/trackTracking';

import { Importance, RealEstateAttributeProps } from '../../../components/RealEstateAttribute';

type AttributeMapper = Record<string, RealEstateAttributeProps>;

interface InvestmentAttributeData {
  bankCommission: string;
  masteosCommission: string;
  brokerageFees: string;
  isPaymentCash: boolean;
  deedPurchaseFees: string;
  stateAssetsTransferTax: string;
  salePrice: string;
  renovationAmount: string;
  notaryCommission: string;
  furnituresAmount: string;
  withBrokerageFees: boolean;
  handleBrokerageFeesCheckbox: (value: boolean) => void;
  financialModelVersion: number;
}

export const getInvestmentAttributes = (
  data: InvestmentAttributeData,
  t: TFunction,
  propertyId: string
): AttributeMapper => ({
  bankCommission: {
    importance: Importance.LOW,
    label: t('shared.financialTool.bankCommission'),
    testID: 'property-financial-line-bank-commission',
    tooltip: {
      description: t(`shared.financialTool.tooltip.bankCommissionDescription`),
      onOpen: () => trackTooltip({ propertyId, tooltipType: 'Frais bancaires' }),
      title: t(`shared.financialTool.tooltip.bankCommission`),
    },
    value: data.bankCommission,
  },
  brokerageFees: {
    importance: Importance.LOW,
    inputSwitch: {
      disabled: data.isPaymentCash,
      setValue: data.handleBrokerageFeesCheckbox,
      testID: 'property-financial-line-brokerage-fees-switch',
      value: data.withBrokerageFees,
    },
    label: t('shared.financialTool.brokerageFees'),
    testID: 'property-financial-line-brokerage-fees',
    tooltip: {
      description: t(`shared.financialTool.tooltip.brokerageFeesDescription`),
      onOpen: () => trackTooltip({ propertyId, tooltipType: 'Frais de courtage' }),
      title: t(`shared.financialTool.tooltip.brokerageFees`),
    },
    value: data.brokerageFees,
  },
  deedPurchaseFees: {
    importance: Importance.LOW,
    label: t('shared.financialTool.deedPurchaseFees'),
    testID: 'property-financial-line-deed-purchase-fees',
    tooltip: {
      description: t(`shared.financialTool.tooltip.deedPurchaseFeesDescription`),
      onOpen: () => trackTooltip({ propertyId, tooltipType: "Frais d'acte d'achat" }),
      title: t(`shared.financialTool.deedPurchaseFees`),
    },
    value: data.deedPurchaseFees,
  },
  furnituresAmount: {
    importance: Importance.LOW,
    label: t('shared.financialTool.furnituresAmount'),
    testID: 'property-financial-line-furnitures-amount',
    value: data.furnituresAmount,
  },
  masteosCommission: {
    importance: Importance.LOW,
    label: t('shared.financialTool.masteosCommission'),
    testID: 'property-financial-line-masteos-commission',
    tooltip: {
      description: t(`shared.financialTool.tooltip.masteosCommission`, {
        context: `v${data.financialModelVersion}`,
      }),
      onOpen: () => trackTooltip({ propertyId, tooltipType: 'Frais Masteos' }),
      title: t(`shared.financialTool.masteosCommission`),
    },
    value: data.masteosCommission,
  },
  notaryCommission: {
    importance: Importance.LOW,
    label: t('shared.financialTool.notaryCommission'),
    testID: 'property-financial-line-notary-commission',
    value: data.notaryCommission,
  },
  renovationAmount: {
    importance: Importance.LOW,
    label: t('shared.financialTool.renovationAmount'),
    testID: 'property-financial-line-renovation-amount',
    value: data.renovationAmount,
  },
  salePrice: {
    importance: Importance.LOW,
    label: t('shared.financialTool.salePrice'),
    testID: 'property-financial-line-sale-price',
    tooltip: {
      description: t(`shared.financialTool.tooltip.salePrice`),
      onOpen: () => trackTooltip({ propertyId, tooltipType: 'Prix de vente FAI' }),
      title: t(`shared.financialTool.salePrice`),
    },
    value: data.salePrice,
  },
  stateAssetsTransferTax: {
    importance: Importance.LOW,
    label: t('shared.financialTool.stateAssetsTransferTax'),
    testID: 'property-financial-line-state-assets-transfer-tax',
    tooltip: {
      description: t(`shared.financialTool.tooltip.stateAssetsTransferTaxDescription`),
      onOpen: () => trackTooltip({ propertyId, tooltipType: 'ITP' }),
      title: t(`shared.financialTool.stateAssetsTransferTax`),
    },
    value: data.stateAssetsTransferTax,
  },
});

const config = {
  BE: [
    'salePrice',
    'renovationAmount',
    'furnituresAmount',
    'notaryCommission',
    'deedPurchaseFees',
    'masteosCommission',
    'bankCommission',
  ],
  ES: [
    'salePrice',
    'renovationAmount',
    'furnituresAmount',
    'notaryCommission',
    'stateAssetsTransferTax',
    'masteosCommission',
    'bankCommission',
    'brokerageFees',
  ],
  FR: [
    'salePrice',
    'renovationAmount',
    'furnituresAmount',
    'notaryCommission',
    'masteosCommission',
    'bankCommission',
    'brokerageFees',
  ],
};

export const getAttributesByCountryCode = (
  mapper: AttributeMapper,
  countryCode: string
): RealEstateAttributeProps[] => {
  return Object.keys(config).includes(countryCode)
    ? config[countryCode].reduce(
        (attributes: RealEstateAttributeProps[], nextAttributeKey: string) => {
          if (Object.keys(mapper).includes(nextAttributeKey)) {
            return [...attributes, mapper[nextAttributeKey]];
          }

          return attributes;
        },
        []
      )
    : [];
};
