import {
  buildInitOptions,
  captureException,
  captureMessage,
  Extras,
  init,
} from '@app/libs/sentry/sentry';
import { config } from '@app/config';

export const isEnabled = (): boolean =>
  Boolean(config.SENTRY_DSN) && config.NODE_ENV === 'production';

export const logError = (err: Error | string, extra?: Extras): void => {
  if (isEnabled()) {
    captureException(err, { extra });
  }
  console.log(err);
};

export const logMessage = (message: string, extra?: Extras): void => {
  if (isEnabled()) {
    captureMessage(message, { extra });
  }
  console.warn('[error-monitoring] - ', message, `extra: ${JSON.stringify(extra)}`);
};

export const initializeErrorMonitoring = (): void => {
  if (isEnabled()) {
    init(buildInitOptions());
  }
};
