import React, { PropsWithChildren, ReactElement } from 'react';

import { UniversalDrawer } from '@app/shared/components/UniversalDrawer/UniversalDrawer';
import { Modal, useResponsive } from '@app/ui';

interface ResponsiveDrawerProps {
  children: ReactElement | null;
  onClose: () => void;
}

export const ResponsiveDrawer: React.FC<PropsWithChildren<ResponsiveDrawerProps>> = ({
  children,
  onClose,
}) => {
  const { isLowerThanLaptop } = useResponsive();

  return isLowerThanLaptop ? (
    <Modal
      visible={!!children}
      onPressClose={onClose}
      position="fullscreen"
      hideCross
      noContainerPadding
      withSafeAreaView
    >
      {children}
    </Modal>
  ) : (
    <UniversalDrawer
      isOpen={!!children}
      direction="right"
      onClickBackdrop={onClose}
      onClickCrossBtn={onClose}
      showCloseBtn
    >
      {children}
    </UniversalDrawer>
  );
};
