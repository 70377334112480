import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgBa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ba_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ba_svg__a)">
        <Path fill="#0B36B2" d="M0 0h28v20H0z" />
        <G filter="url(#ba_svg__filter0_d_4223_10460)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M22.667 20V0h-14l14 20z" fill="#FFD045" />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.333 18.667l-.942.276.276-.943-.277-.943.943.276.943-.276L18 18l.276.943-.943-.276zM14.667 14.667l-.943.276L14 14l-.276-.943.943.276.943-.276-.277.943.277.943-.943-.276zM12 10.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.276.943.276.943-.943-.276zM9.333 6.667l-.942.276L8.667 6l-.276-.943.942.276.943-.276L10 6l.276.943-.943-.276zM6.667 2.667l-.943.276L6 2l-.276-.943.943.276.942-.276L7.333 2l.276.943-.942-.276z"
          fill="#fff"
        />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgBa;
