import { formatCounterActivatorReturnType } from './CounterActivator.types';

export type FormatActivatorDefaultType = (
  placeholder: string,
  values?: string[]
) => formatCounterActivatorReturnType;

export const formatActivatorDefault: FormatActivatorDefaultType = (placeholder, values) => {
  return {
    count: values?.length || 0,
    valueString: (values?.length && values.join(', ')) || placeholder,
  };
};
