import { Platform, StyleProp, ViewStyle } from 'react-native';
import { get, has } from 'lodash';

import { palette } from '../theme/palette';
import { Paths } from '../types/paths';
import { ComponentThemes, Theme } from '../theme/types';

export const noOutline = Platform.select({
  web: {
    outlineColor: 'none' as const,
    outlineStyle: 'none' as const,
    outlineWidth: '0' as const,
  } as StyleProp<ViewStyle>,
});

export const shadow = (elevation: number) => {
  return (Platform.select({
    default: {},
    native: computeShadow(elevation),
    web: {} as StyleProp<ViewStyle>,
  }) || {}) as object;
};

export const computeShadow = (elevation: number) => {
  const shadowOffset = Math.floor(elevation / 2);
  const shadowColor = palette.base.black;
  const shadowOpacity = computeShadowOpacity(elevation);
  const shadowRadius = computeShadowRadius(elevation);

  return {
    elevation,
    shadowColor,
    shadowOffset: {
      height: shadowOffset,
      width: 0,
    },
    shadowOpacity,
    shadowRadius,
  };
};

const computeShadowOpacity = (elevation: number) => {
  const base = 0.18;
  const e_base = [4, 8, 12, 16, 20, 24];
  const e_baseIndex = e_base.findIndex(e => e === elevation) + 1;

  if (e_base.includes(elevation)) {
    return Number(
      Number(base + 0.02 * (elevation - e_baseIndex) + 0.01 * (e_baseIndex + 1)).toFixed(2)
    );
  }

  return Number(Number(base + 0.02 * (elevation - 1)).toFixed(2));
};

const computeShadowRadius = (elevation: number) => {
  return Number(Number(0.646514 * elevation + 0.0939799).toFixed(2));
};

export const getValue = (path: Paths<Theme>, theme: Theme, componentTheme: ComponentThemes) => {
  const valueExists = has(theme, path);

  if (valueExists) {
    return get(theme, path);
  }

  return get(componentTheme, path);
};

export const hexToRgbA = (hex: string, alpha: number) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(Number(c) >> 16) & 255, (Number(c) >> 8) & 255, Number(c) & 255].join(
      ','
    )},${alpha})`;
  }
  throw new Error('Bad Hex');
};

/**
 * @desc In case you have used the Stylesheet and you have
 * enhanced the style with a conditional value such as
 * the before style set is deleted.
 * Use this utility to concatenate the styles.
 */
export const computeStyle = <T,>(
  prop: T | undefined,
  key: keyof T
): { [x: string]: T[keyof T] } | undefined => prop?.[key] && { [key]: prop[key] };
