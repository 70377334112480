import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTrendingDown = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M17 18a.965.965 0 01-.712-.288A.965.965 0 0116 17c0-.283.096-.52.288-.712A.965.965 0 0117 16h1.6l-5.2-5.15-3.3 3.3a.917.917 0 01-.312.2c-.125.05-.255.075-.388.075a.942.942 0 01-.375-.075 1.316 1.316 0 01-.325-.2L2.675 8.075A.918.918 0 012.4 7.4c0-.267.1-.5.3-.7a.948.948 0 01.7-.275c.283 0 .517.092.7.275L9.4 12l3.3-3.3c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.883.883 0 01.325.213l5.9 5.9V13c0-.283.096-.521.288-.713A.967.967 0 0121 12c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0121 18h-4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTrendingDown;
