import React from 'react';

import { View, Linking } from 'react-native';
import { useTranslation } from 'react-i18next';

import { useResponsive, Text, useTheme } from '@app/ui';
import { PRIVACY_POLICY, TERMS_URL } from '@app/constants/constants';

import { getStyles } from '../onboarding.styles';

interface CguRegisterProps {
  isWeb: boolean;
}

const createOpenLinkHandler = (url: string) => () => Linking.openURL(url);

export const CguRegister: React.FC<CguRegisterProps> = ({ isWeb }) => {
  const { t } = useTranslation();
  const { isGreaterThanTablet } = useResponsive();
  const theme = useTheme();
  const commonStyles = getStyles({ isFixed: isWeb, isGreaterThanTablet, theme });
  const textStyle = isWeb ? 'Body2' : 'Body3';

  return (
    <View>
      <Text>
        <Text textStyle={textStyle}>{t('register.cguStep1')}</Text>
        <Text
          textStyle={textStyle}
          style={commonStyles.link}
          onPress={createOpenLinkHandler(TERMS_URL)}
        >
          {t('register.cguStep2')}
        </Text>
        <Text textStyle={textStyle}>{t('register.cguStep3')}</Text>
        <Text
          textStyle={textStyle}
          style={commonStyles.link}
          onPress={createOpenLinkHandler(PRIVACY_POLICY)}
        >
          {t('register.cguStep4')}
        </Text>
      </Text>
    </View>
  );
};
