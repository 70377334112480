import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { View } from 'react-native';

import { ListItemUnionProps, SelectV2, SimpleTextSelector } from '@app/ui';
import { resolveLanguageCode } from '@app/utils/lang/resolveLanguageCode';
import { useTranslation } from '@app/services/translations/translations';
import { ChosenOption } from '@app/ui/organisms/select/Select.types';

import { LanguageSwitcherOption, LanguageSwitcherProps } from './languageSwitcher.types';
import { languageSwitcherOptions } from './languageSwitcher.constants';
import { styles } from './languageSwitcher.styles';

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  style,
  lang = 'fr',
  onLangChange,
  small,
}) => {
  const [value, setValue] = useState<ChosenOption[]>([]);
  const { t } = useTranslation();

  const handleSelectChange = (selectedOption: ListItemUnionProps, closeList) => {
    onLangChange(selectedOption.key as string);
    closeList();
  };

  const findOption = useCallback((keyLang: string): ChosenOption | undefined => {
    return languageSwitcherOptions.find(
      languageSwitcherOption => languageSwitcherOption.value === keyLang
    );
  }, []);

  useEffect(() => {
    let selectedOption = findOption(lang);

    if (lang && !selectedOption) {
      selectedOption = findOption(resolveLanguageCode(lang));
    }

    setValue(selectedOption ? [selectedOption] : []);
  }, [findOption, lang]);

  const options = useMemo<ListItemUnionProps[]>(
    () =>
      languageSwitcherOptions.map((languageOption: LanguageSwitcherOption) => ({
        ...languageOption,
        selected: !!value.find(
          (currentValue: ChosenOption) => currentValue.value === languageOption.value
        ),
      })),
    [value]
  );

  const val = useMemo(
    () =>
      small
        ? (value.length > 0 && value[0].value.toString().toUpperCase()) || ''
        : languageSwitcherOptions.find(lan => lan.value === lang)?.label || '',
    [lang, small, value]
  );

  return (
    <View testID="LanguageSwitcher" style={[styles.root, style]}>
      {!!small && (
        <SimpleTextSelector
          options={options}
          onSelectValue={handleSelectChange}
          value={val}
          testID="language-switcher-simple-text-selector"
        />
      )}

      {!small && (
        <View style={styles.selectWrapper}>
          <SelectV2
            values={[val]}
            onSelectValue={handleSelectChange}
            options={options}
            placeholder={t('profile.lang')}
            isDisabled={false}
            testID="language-switcher-select"
          />
        </View>
      )}
    </View>
  );
};
