import {
  Animated,
  GestureResponderEvent,
  GestureResponderHandlers,
  PanResponderCallbacks,
  PanResponderGestureState,
} from 'react-native';

import {
  ChangeIndexProps,
  GetPanResponderCallbacksProps,
  OnMoveShouldSetPanResponderCaptureProps,
  OnPanResponderReleaseProps,
  PanResponderCallback,
  PanResponderCaptureCallback,
} from './usePanResponderCallbacks.types';

export const MIN_DISTANCE_FOR_ACTION = 0.2;
export const MIN_DISTANCE_TO_CAPTURE = 5;

export const onMoveShouldSetPanResponderCapture =
  ({
    gesturesEnabled,
    minDistanceToCapture = MIN_DISTANCE_TO_CAPTURE,
  }: OnMoveShouldSetPanResponderCaptureProps): PanResponderCaptureCallback =>
  (_: GestureResponderEvent, gestureState: PanResponderGestureState) =>
    !gesturesEnabled ? false : Math.abs(gestureState.dx) > minDistanceToCapture;

const onPanResponderMove = (pan: Animated.ValueXY): PanResponderCallback =>
  Animated.event([null, { dx: pan.x }], { useNativeDriver: false });

export const onPanResponderRelease =
  ({
    count,
    currentStep,
    loop,
    minDistanceForAction = MIN_DISTANCE_FOR_ACTION,
    onGoStep,
    spring,
    width,
  }: OnPanResponderReleaseProps): PanResponderCallback =>
  (_: GestureResponderEvent, gesture: PanResponderGestureState) => {
    const correction = gesture.moveX - gesture.x0;

    if (Math.abs(correction) < width * minDistanceForAction) {
      spring(0);
    } else {
      changeIndexByGesture({
        correction,
        count,
        currentStep,
        loop,
        onGoStep,
        spring,
        width,
      });
    }
  };

export const changeIndexByGesture = ({
  currentStep,
  correction,
  count,
  loop,
  spring,
  onGoStep,
  width,
}: ChangeIndexProps): void => {
  const delta = correction > 0 ? -1 : 1;

  let skipChanges = !delta;
  let calcDelta = delta;

  if (currentStep <= 0 && delta < 0) {
    skipChanges = !loop;
    calcDelta = count + delta;
  } else if (currentStep + 1 >= count && delta > 0) {
    skipChanges = !loop;
    calcDelta = -1 * currentStep + delta - 1;
  }

  if (skipChanges) {
    return spring(0);
  }

  onGoStep?.(currentStep + calcDelta);
  spring(-width * calcDelta);
};

/**
 * @desc Create callbacks to handle animation with gestures
 */
export const getPanResponderCallbacks = ({
  count,
  currentStep,
  gesturesEnabled,
  loop,
  minDistanceForAction,
  minDistanceToCapture,
  onGoStep,
  pan,
  setOffset,
  spring,
  width,
}: GetPanResponderCallbacksProps): PanResponderCallbacks & GestureResponderHandlers => ({
  onMoveShouldSetPanResponderCapture: onMoveShouldSetPanResponderCapture({
    gesturesEnabled,
    minDistanceToCapture,
  }),
  onMoveShouldSetResponderCapture: () => true,
  onPanResponderGrant: setOffset,
  onPanResponderMove: onPanResponderMove(pan),
  onPanResponderRelease: onPanResponderRelease({
    count,
    currentStep,
    loop,
    minDistanceForAction,
    onGoStep,
    spring,
    width,
  }),
  onPanResponderTerminationRequest: () => false,
});
