import React, { CSSProperties, forwardRef } from 'react';

import { buttonTheme } from '@app/ui/molecules/Button/button.theme';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { LoaderDefault } from '@app/ui/atoms/Loader';

import { ButtonProps } from '../Button.types';
import { ButtonContainer, LabelContainer, Label, StyledIcon } from './Button.styled';
import { getButtonFontStyle, getLoadersMap } from '../utils';
import { iconSizeMap } from '../constants';

/**
 * The Button component is used to trigger an action or event,
 * such as submitting a form, opening a dialog, canceling an action,
 * or performing a delete operation.
 * Its props extends the props of react-native component `View`
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onPress = () => {},
      isLoading = false,
      isInDark = false,
      disabled = false,
      variant = 'primary',
      textStyle = {},
      children,
      style = {},
      testID = '',
      className = '',
      labelStyle = {},
      wrapperStyle = {},
      size = 'l',
      iconName,
      iconPlacement,
    },
    ref
  ) => {
    const componentTheme = useComponentTheme('button', buttonTheme);

    const containerStyles = {
      display: 'flex',
      ...(wrapperStyle as React.CSSProperties),
      ...style,
    };

    const textFont = getButtonFontStyle(size);
    const loaderVariant = getLoadersMap(isInDark).get(variant);

    return (
      <ButtonContainer
        ref={ref as React.LegacyRef<HTMLButtonElement>}
        data-testid={testID}
        onClick={onPress}
        isInDark={isInDark}
        variant={variant}
        size={size}
        disabled={disabled}
        className={className}
        style={containerStyles as React.CSSProperties}
        componentTheme={componentTheme}
      >
        {isLoading ? (
          <LoaderDefault variant={loaderVariant} />
        ) : (
          <LabelContainer style={labelStyle as React.CSSProperties} iconPlacement={iconPlacement}>
            <Label
              size={size}
              isInDark={isInDark}
              variant={variant}
              componentTheme={componentTheme}
              disabled={disabled}
              textFont={textFont}
              style={textStyle as CSSProperties}
            >
              {children}
            </Label>
            {!!iconName && (
              <StyledIcon
                name={iconName}
                iconPlacement={iconPlacement}
                testID="button-icon"
                size={iconSizeMap.get(size)}
              />
            )}
          </LabelContainer>
        )}
      </ButtonContainer>
    );
  }
);

Button.displayName = 'Button';
