import React from 'react';

import { StyleProp, View, ViewStyle } from 'react-native';

import { ShadowContainer } from '@app/ui';
import { ShadowSize } from '@app/ui/theme/shadows';
import { TimelineStepStatus } from '@app/libs/apollo/introspection';

export const StepWrapper = ({
  children,
  status,
  shadowSize,
  testID,
  style,
}: {
  children: React.ReactNode;
  status: TimelineStepStatus;
  testID: string;
  shadowSize: ShadowSize;
  style?: StyleProp<ViewStyle>;
}) =>
  status == TimelineStepStatus.Active ? (
    <ShadowContainer testID={testID} shadowSize={shadowSize} style={style}>
      {children}
    </ShadowContainer>
  ) : (
    <View testID={testID} style={style}>
      {children}
    </View>
  );
