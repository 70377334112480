import { storage } from '@app/services/storage/storage';
import { useAddBookmarkMutation, useDeleteBookmarkMutation } from '@app/libs/apollo/introspection';

enum DisplayedPropertiesTab {
  ALL = 'All',
  FAVORITES = 'Favorites',
  SELECTED = 'Selected',
}

interface BookmarkPropertyReturns {
  bookmarkProperty: ReturnType<typeof useAddBookmarkMutation>[0];
  loading: boolean;
}

export const useBookmarkProperty = (): BookmarkPropertyReturns => {
  const [bookmarkProperty, { loading }] = useAddBookmarkMutation();

  return { bookmarkProperty, loading };
};

interface UnbookmarkPropertyReturns {
  unbookmarkProperty: ReturnType<typeof useDeleteBookmarkMutation>[0];
  loading: boolean;
}

export const useDislikeProperty = (): UnbookmarkPropertyReturns => {
  const [unbookmarkProperty, { loading }] = useDeleteBookmarkMutation();

  return { loading, unbookmarkProperty };
};

export const resetInfinityScroll = (): void => {
  storage.saveString('@storage-last-opened-tab', DisplayedPropertiesTab.ALL);
};
