import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgEr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="er_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#er_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M28 0v10H0V0h28z" fill="#29C53F" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M28 10v10.667H0V10h28z" fill="#579DED" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20l28-10L0 0v20z" fill="#F42D56" />
        <Path d="M12.667 10a4 4 0 11-8 0 4 4 0 018 0z" stroke="#FFC63C" strokeWidth={1.333} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9.333A.667.667 0 108 8a.667.667 0 000 1.333z"
          fill="#FFC63C"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 10a.667.667 0 100-1.333.667.667 0 000 1.333zM8.667 8.667a.667.667 0 100-1.334.667.667 0 000 1.334zM8 10.667a.667.667 0 100-1.334.667.667 0 000 1.334z"
          fill="#FFC63C"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 11.333a.667.667 0 100-1.333.667.667 0 000 1.333zM8 12a.667.667 0 100-1.333A.667.667 0 008 12z"
          fill="#FFC63C"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 12.667a.667.667 0 100-1.334.667.667 0 000 1.334z"
          fill="#FFC63C"
        />
      </G>
    </Svg>
  );
};

export default SvgEr;
