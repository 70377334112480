import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tn_svg__a)">
        <Path fill="#E92434" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 10a6 6 0 11-12 0 6 6 0 0112 0zm-8 0a4.334 4.334 0 003.811 4.302 4.667 4.667 0 110-8.604A4.334 4.334 0 0012 10zm3.13 2.655l1.224-1.485 1.803.674-1.033-1.624 1.198-1.507-1.864.482-1.063-1.605-.118 1.921-1.855.515 1.79.706-.083 1.923z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgTn;
