import { StyleSheet } from 'react-native';

import { SizeWidth } from '@app/ui/atoms/dot/Dot.styles';
import { DotSize } from '@app/ui/atoms/dot/Dot.types';

type GetStyleType = {
  numberOfDotsDisplayed: number;
  size: DotSize;
};

export const GAP = 4;

export const getStyle = ({ numberOfDotsDisplayed, size }: GetStyleType) =>
  StyleSheet.create({
    dotContainer: {
      alignItems: 'center',
      height: SizeWidth[size],
      justifyContent: 'center',
      width: SizeWidth[size],
    },
    dotList: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: GAP,
      justifyContent: 'space-between',
    },
    dotListContainer: {
      overflow: 'hidden',
      position: 'relative',
      width: numberOfDotsDisplayed * (SizeWidth[size] + GAP) - GAP,
    },
    gap: { justifyContent: 'space-between' },
  });
