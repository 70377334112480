import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCheckboxUnchecked = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2zM6 2a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V6a4 4 0 00-4-4H6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCheckboxUnchecked;
