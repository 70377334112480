import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgAg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ag_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ag_svg__a)">
        <Path fill="#E2243B" d="M0 0h28v20H0z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 0h28L14 20 0 0z" fill="#262626" />
        <Mask
          id="ag_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={28}
          height={20}
        >
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 0h28L14 20 0 0z" fill="#fff" />
        </Mask>
        <G mask="url(#ag_svg__b)">
          <G filter="url(#ag_svg__filter0_d_4223_10337)">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 11l-2.296 2.543.175-3.422-3.422.175L11 8 8 5.333 11.879 6l-.546-4L14 5.333 16.667 2l-.546 4L20 5.333 17 8l2.543 2.296-3.422-.175.175 3.422L14 11z"
              fill="#FFCF3C"
            />
          </G>
          <G filter="url(#ag_svg__filter1_d_4223_10337)">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 13.333h28V8H0v5.333z"
              fill="#1984D8"
            />
          </G>
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#fff" />
        </G>
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgAg;
