import axios, { AxiosInstance } from 'axios';

import {
  AuthenticateGoogleResponse,
  AuthenticateReqBody,
  AuthenticateResponse,
  AuthenticationStrategy,
  ForgotPasswordReqBody,
  ForgotPasswordResponse,
  RenewPasswordReqBody,
  RenewPasswordResponse,
  ResetPasswordOrigin,
  ResetPasswordReqBody,
  ResetPasswordResponse,
} from './identity.type';

export let identityClient: IdentityClient;

class IdentityClient {
  private readonly headers = {
    'Content-type': 'application/json',
  };
  private readonly app: AxiosInstance;

  public constructor(identityApiUrl: string) {
    this.app = axios.create({ baseURL: identityApiUrl, headers: this.headers });
  }

  public async forgotPassword(body: ForgotPasswordReqBody): Promise<ForgotPasswordResponse> {
    const { data } = await this.app.post('/forgot-password', {
      ...body,
      origin: ResetPasswordOrigin.ApollonUser,
    });
    return data;
  }

  public async resetPassword(body: ResetPasswordReqBody): Promise<ResetPasswordResponse> {
    const { data } = await this.app.post('/reset-password', body);
    return data;
  }

  public async renewPassword(body: RenewPasswordReqBody): Promise<RenewPasswordResponse> {
    const { data } = await this.app.post('/renew-password', body);
    return data;
  }

  public async authenticate(body: AuthenticateReqBody): Promise<AuthenticateResponse> {
    if (body.strategy === AuthenticationStrategy.Local) {
      const { data } = await this.app.post('/authenticate', body);
      return data;
    }
    const { data } = await this.app.post(`/authenticate/${body.strategy}`, body);
    return data;
  }

  public async authenticateGoogleNative(idToken: string): Promise<AuthenticateGoogleResponse> {
    const { data } = await this.app.post(`/authenticate/google`, { idToken });
    return data;
  }

  public async authenticateGoogleWeb(code: string): Promise<AuthenticateGoogleResponse> {
    const { data } = await this.app.post(
      `/authenticate/google-web?code=${encodeURIComponent(code)}`
    );
    return data;
  }

  public async authenticateApple({ code, platform }): Promise<AuthenticateGoogleResponse> {
    const { data } = await this.app.post(`/authenticate/apple-token`, { code, platform });
    return data;
  }
}

export const initializeIdentity = (identityApiUrl: string): void => {
  identityClient = new IdentityClient(identityApiUrl);
};
