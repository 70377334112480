import React, { useCallback } from 'react';

import { TextStyle } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { computeStyle } from '@app/ui/utils/style';
import { useTheme } from '@app/ui/contexts/config';

import { getTableHeaderStyle } from './TableHeader.styles';
import { TableColumnProps, TableHeaderProps } from './TableHeader.types';
export { TableColumnProps, TableHeaderProps };

export const TableHeader = <T,>({ columns, style }: TableHeaderProps<T>): JSX.Element => {
  const theme = useTheme();
  const tableHeaderStyle = getTableHeaderStyle(theme);
  const getColumnStyle = useCallback(
    (columnStyle: TextStyle) => ({
      ...computeStyle<TextStyle>(columnStyle, 'backgroundColor'),
      ...computeStyle<TextStyle>(columnStyle, 'width'),
      ...computeStyle<TextStyle>(columnStyle, 'color'),
    }),
    []
  );
  return (
    <>
      {columns.map((column, index) => (
        <Text
          key={`${index}_${String(column.field)}`}
          textStyle="Caption1Medium"
          style={[tableHeaderStyle.box, style, column.style && getColumnStyle(column.style)]}
        >
          {column.headerName}
        </Text>
      ))}
    </>
  );
};
