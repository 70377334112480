import React from 'react';

import { useWindowDimensions } from 'react-native';

import { Timeline, TimelineStepStatus } from '@app/libs/apollo/introspection';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { useResponsive } from '@app/ui';

import { useHomePageRenderItem } from './hooks/useHomePageRenderItem';
import { SectionContainer, sectionPaddingForScreen, useActiveBreakPoint } from './SectionContainer';

type HomePageStepsProps = {
  timeline: Timeline;
};

export const HomePageSteps: React.FC<HomePageStepsProps> = ({ timeline }) => {
  const { width } = useWindowDimensions();
  const { isNativeOrResponsive } = useResponsive();
  const activeBreakPoint = useActiveBreakPoint();

  const { renderItem } = useHomePageRenderItem();
  const foundIndex = timeline.steps.findIndex(
    step => step.status === TimelineStepStatus.Active || step.status === TimelineStepStatus.Error
  );

  const defaultIndex = foundIndex !== -1 ? foundIndex : 0;

  return (
    <SectionContainer>
      <Carousel
        showDots={true}
        data={timeline.steps}
        renderItem={renderItem}
        panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
        width={width * (isNativeOrResponsive ? 0.75 : 0.4)}
        pagingEnabled
        loop={false}
        style={{
          marginHorizontal: -sectionPaddingForScreen[activeBreakPoint],
          width: width ? width : 0,
        }}
        height={270}
        defaultIndex={defaultIndex}
        scrollAnimationDuration={1000}
      />
    </SectionContainer>
  );
};
