import { StyleSheet } from 'react-native';

import { isWeb } from '@app/constants/platform';
import { Theme } from '@app/ui';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      marginHorizontal: 'auto',
      maxWidth: isWeb() ? 335 : '100%',
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_08,
      width: '100%',
    },
    forgotPasswordLink: {
      textDecorationLine: 'underline',
    },
    form: {
      width: '100%',
    },
  });
