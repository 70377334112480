import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import { TranslationSwitch } from '@app/ui/molecules/translation-switch/TranslationSwitch';
import { TranslationContent } from '@app/ui/atoms/translation-content/TranslationContent';

import {
  TranslationContextProps,
  TranslationContentGroupComposition,
  TranslationContentGroupProps,
} from './TranslationContentGroupTypes';

export const TranslationContentContext = createContext<TranslationContextProps>({
  showTranslation: false,
});

export const TranslationContentGroup: React.FC<PropsWithChildren<TranslationContentGroupProps>> &
  TranslationContentGroupComposition = ({
  children,
  initialState = {
    showTranslation: false,
  },
}) => {
  const [showTranslation, setShowTranslation] = useState(initialState.showTranslation);
  const toggleTranslation = () => setShowTranslation(value => !value);

  return (
    <TranslationContentContext.Provider value={{ showTranslation, toggleTranslation }}>
      {children}
    </TranslationContentContext.Provider>
  );
};

const withTranslationContext = <T extends TranslationContextProps = TranslationContextProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithTranslationContext = (props: Omit<T, keyof TranslationContextProps>) => {
    const { showTranslation, toggleTranslation: handleChangeLang } =
      useContext(TranslationContentContext);
    return (
      <WrappedComponent
        {...(props as T)}
        handleChangeLang={handleChangeLang}
        showTranslation={showTranslation}
      ></WrappedComponent>
    );
  };
  WithTranslationContext.displayName = `withTranslationContext(${displayName})`;
  return WithTranslationContext;
};

TranslationContentGroup.context = TranslationContentContext;
TranslationContentGroup.Item = withTranslationContext(TranslationContent);
TranslationContentGroup.Switch = withTranslationContext(TranslationSwitch);
