/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AvailableCountryCode,
  GetSoldRealEstatesV2Query,
  GetZonesQuery,
  RealEstate,
  SearchRealEstatesDocument,
  SearchRealEstatesQuery,
  useGetLocationsQuery,
  useGetMyFavoritesRealEstatesQuery,
  useGetSoldRealEstatesV2Query,
  useGetZonesQuery,
  useSearchRealEstatesQuery,
} from '@app/libs/apollo/introspection';
import {
  PropertyPage,
  trackFilterClick,
  TrackingOrigin,
  trackLoadMoreButtonClicked,
  trackResetFilterClick,
  trackSortStrategyClick,
} from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';
import { getApolloClient } from '@app/libs/apollon/apollonClient';
import { useMe } from '@app/shared/hooks/useMe';
import { customerPreferencesToPreferencesInput } from '@app/utils/customerPreferencesToPreferencesInput';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { toggleBookmarkRealEstate, useBookmark } from '@app/shared/hooks/useBookmark';
import { getAPILang } from '@app/libs/i18n/helper';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { resolveToMasteosLanguage } from '@app/utils/lang/resolveLanguageCode';

import { getInfoPerTotalResult } from '../utils/get-limit-per-page';
import {
  SearchEngineFilterForm,
  SearchEngineRealEstatesLoadingType,
  SearchEngineSource,
} from '../searchEngine.types';
import { filterToPreferencesMapper } from '../compounds/search-engine-filters/utils/filter-to-preferences.mapper';
import { getFilterChanges } from '../compounds/search-engine-filters/utils/get-filter-changes';
import { getSortedCustomerPref } from './useGetSearchEngineDatas.utils';

interface RealEstateLocations {
  __typename?: string;
  countryCode: AvailableCountryCode;
  regions: string[];
}

interface RealEstateCursor {
  nextRealEstateId?: string;
  nextRealEstatePublishingDate?: Date;
}

export interface UseGetSearchEngineDatasResult {
  totalBookmarks: number;
  totalRealEstates: number;
  loading: boolean;
  filterLoading: boolean;
  realEstates: RealEstate[];
  soldRealEstates: GetSoldRealEstatesV2Query['soldRealEstatesV2'];
  zoneLocations: GetZonesQuery['getZones']['locations'];
  extendedRealEstates: SearchRealEstatesQuery['searchRealEstates']['extendedRealEstates'];
  locations: RealEstateLocations[];
  bookmarkedRealEstates: RealEstate[];
  bookmarksLoading: boolean;
  cursor?: RealEstateCursor;
  loadingType: SearchEngineRealEstatesLoadingType;
  myFavoritesRefetch: () => void;
  next: () => void;
  toggleBookmark: (item: toggleBookmarkRealEstate) => void;
}

export type FiltersStateProps = {
  source: SearchEngineSource;
  filtersField: SearchEngineFilterForm;
  resetMode: boolean;
};

export const useGetSearchEngineDatas = ({
  filters,
}: {
  filters: FiltersStateProps;
}): UseGetSearchEngineDatasResult => {
  const client = getApolloClient();
  const { stateOfCustomer, customer } = useMe();
  const { toggleBookmark } = useBookmark({});
  const [totalInCache, setTotalInCache] = useState(0);
  const [loadingType, setLoadingType] = useState(SearchEngineRealEstatesLoadingType.Refetch);

  const { setValidMsg } = useToastContext();
  const { t, i18n } = useTranslation();

  const apiLang = getAPILang(i18n.resolvedLanguage as I18nSupportedLanguage);

  const customerPref = getSortedCustomerPref(customer);

  const { filtersField } = filters || {};

  const hasFilter = filters && Object.keys(filtersField).length;

  const limit = totalInCache || getInfoPerTotalResult().limit;

  const formattedFiltersField = useMemo(
    () => (hasFilter ? filterToPreferencesMapper(filtersField) : undefined),
    [filtersField, hasFilter]
  );

  const currentFilters = useMemo(
    () => (hasFilter ? formattedFiltersField : customerPreferencesToPreferencesInput(customerPref)),
    [formattedFiltersField, customerPref, hasFilter]
  );

  useEffect(() => {
    const res = client.cache.readQuery<SearchRealEstatesQuery>({
      query: SearchRealEstatesDocument,
    });

    const total = res?.searchRealEstates?.realEstates?.length ?? 0;
    if (total > 0) {
      setTotalInCache(total);
    }
  }, [client.cache]);

  useEffect(() => {
    setLoadingType(SearchEngineRealEstatesLoadingType.Refetch);
  }, [filters]);

  const onCompleted = useCallback(
    data => {
      if (!hasFilter || loadingType === SearchEngineRealEstatesLoadingType.FetchMore) {
        return;
      }

      if (filters?.resetMode) {
        trackResetFilterClick({
          numberOfResults: data.searchRealEstates.total,
          source: filters?.source,
        });
      } else if (filters.source == SearchEngineSource.SORT_STRATEGY) {
        trackSortStrategyClick(filtersField.sortStrategy);
      } else {
        trackFilterClick(
          getFilterChanges(filtersField),
          filterToPreferencesMapper(filtersField),
          data.searchRealEstates.total,
          filters.source
        );
      }

      const message =
        filters?.source === SearchEngineSource.NO_RESULT
          ? t('searchEngine.toast.filter.reset')
          : t('searchEngine.toast.filter.apply');

      setValidMsg({
        icon: 'Check',
        title: message,
        withClose: true,
      });
    },
    [hasFilter, filters?.resetMode, filters?.source, filtersField, setValidMsg, t, loadingType]
  );

  const {
    data: myRealEstatesData,
    loading: myRealEstatesLoading,
    fetchMore: myRealEstatesFetchMore,
  } = useSearchRealEstatesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted,
    skip: !stateOfCustomer,
    variables: {
      filters: currentFilters,
      limit,
      withCustomerPreferencesUpdate: !!formattedFiltersField,
    },
  });

  const { data: getLocationsData, loading: getLocationsLoading } = useGetLocationsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { data: getZonesData, loading: getZonesLoading } = useGetZonesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      lang: apiLang,
    },
  });

  const {
    data: getMyFavoritesData,
    loading: getMyFavoritesLoading,
    refetch: myFavoritesRefetch,
  } = useGetMyFavoritesRealEstatesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { data: getSoldRealEstates, loading: getSoldRealEstatesLoading } =
    useGetSoldRealEstatesV2Query({
      fetchPolicy: 'cache-first',
      variables: { lang: resolveToMasteosLanguage(i18n.resolvedLanguage) },
    });

  const next = useCallback(() => {
    setLoadingType(SearchEngineRealEstatesLoadingType.FetchMore);
    trackLoadMoreButtonClicked(
      getInfoPerTotalResult(myRealEstatesData?.searchRealEstates.realEstates.length).loadMoreButton
    );

    if (myRealEstatesData?.searchRealEstates.cursor) {
      myRealEstatesFetchMore({
        variables: {
          cursor: {
            nextRealEstateId: myRealEstatesData.searchRealEstates.cursor.nextRealEstateId,
            nextRealEstateNetReturn:
              myRealEstatesData.searchRealEstates.cursor.nextRealEstateNetReturn,
            nextRealEstatePublishingDate:
              myRealEstatesData.searchRealEstates.cursor.nextRealEstatePublishingDate,
          },
          filters: currentFilters,
          limit: getInfoPerTotalResult(myRealEstatesData.searchRealEstates.realEstates.length)
            .limit,
          withCustomerPreferencesUpdate: false,
        },
      });
    }
  }, [myRealEstatesData, currentFilters, myRealEstatesFetchMore]);

  const realEstates = useMemo(() => {
    return (myRealEstatesData?.searchRealEstates.realEstates as RealEstate[]) ?? [];
  }, [myRealEstatesData]);

  const bookmarkedRealEstates = useMemo(() => {
    return (getMyFavoritesData?.myFavoritesRealEstates.realEstates as RealEstate[]) ?? [];
  }, [getMyFavoritesData]);

  const handleToggleBookMark = item => {
    toggleBookmark(item, {
      currentTab: TrackingOrigin.PREVIEW,
      page: PropertyPage.MATCHING,
    });
  };

  // TODO: Remove support for others countries #countryRemoval
  const locations = getLocationsData?.getLocations.locations.filter(
    item => item.countryCode === 'FR'
  );

  return {
    bookmarkedRealEstates,
    bookmarksLoading: getMyFavoritesLoading,
    cursor: myRealEstatesData?.searchRealEstates.cursor,
    extendedRealEstates: myRealEstatesData?.searchRealEstates?.extendedRealEstates ?? {
      withHigherBudget: [],
      withNearby: [],
    },
    filterLoading: getLocationsLoading || getZonesLoading,
    loading: myRealEstatesLoading || getSoldRealEstatesLoading,
    loadingType,
    locations: locations ?? [],
    myFavoritesRefetch,
    next,
    realEstates,
    soldRealEstates: getSoldRealEstates?.soldRealEstatesV2 ?? [],
    toggleBookmark: handleToggleBookMark,
    totalBookmarks: getMyFavoritesData?.myFavoritesRealEstates.realEstates.length ?? 0,
    totalRealEstates: myRealEstatesData?.searchRealEstates.total ?? 0,
    zoneLocations: getZonesData?.getZones?.locations || [],
  };
};
