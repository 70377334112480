import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    dash: {
      borderBottomWidth: theme.spacing.SIZE_01,
      borderColor: theme.palette.neutral[900],
      marginRight: theme.spacing.SIZE_04,
      marginTop: theme.spacing.SIZE_03,
      width: theme.spacing.SIZE_04 - 2,
    },
    input: {
      marginRight: theme.spacing.SIZE_04,
      minWidth: theme.spacing.SIZE_10,
      width: theme.spacing.SIZE_10,
      ...theme.textStyles.Headline1,
      textAlign: 'center',
    },

    inputContainer: {
      marginRight: theme.spacing.SIZE_03,
      width: 46,
    },
    inputsContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    lastInput: {
      marginRight: theme.spacing.NONE,
    },
    leftMargin: {
      marginLeft: 0,
    },
    resendCodeLink: {
      ...theme.textStyles.Caption1,
      color: theme.palette.system.primary[500],
      paddingLeft: theme.spacing.SIZE_02,
      textDecorationLine: 'underline',
    },
    rightMargin: {
      marginRight: 0,
    },
    textContainer: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingTop: theme.spacing.SIZE_03,
    },
  });
