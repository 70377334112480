import { palette } from './palette';
import { Spacing, BorderRadius, Breakpoints } from './Sizes';
import { shadows } from './shadows';
import { Fonts, TEXT_STYLES, FONT_WEIGHTS } from './fonts';
import { ZINDEX } from './zindex';

export const defaultTheme = {
  borderRadius: BorderRadius,
  breakpoints: Breakpoints,
  fontWeights: FONT_WEIGHTS,
  fonts: Fonts,
  palette,
  shadows,
  spacing: Spacing,
  textStyles: TEXT_STYLES,
  zIndex: ZINDEX,
};
