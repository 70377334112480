import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="br_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#br_svg__a)">
        <Path fill="#05AB41" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.532 10.56a.667.667 0 010-1.12l10.106-6.539a.667.667 0 01.724 0l10.106 6.54a.667.667 0 010 1.119l-10.106 6.539a.667.667 0 01-.724 0l-10.106-6.54z"
          fill="#FDD216"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z"
          fill="#053087"
        />
        <Mask
          id="br_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={9}
          y={5}
          width={10}
          height={10}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#br_svg__b)">
          <Path
            d="M8.78 8.78c.733-.593 3.097-.031 5.304.558 2.207.588 4.509 1.822 5.095 2.546"
            stroke="#fff"
            strokeWidth={1.333}
            strokeLinecap="square"
          />
        </G>
      </G>
    </Svg>
  );
};

export default SvgBr;
