import React from 'react';

import { ProjectList } from '@app/features/projectList/ProjectList';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';
import { isNative } from '@app/constants/platform';

export const ProjectListView: React.FC = () => {
  return (
    <ScreenWrapper withoutScrollView withoutPadding={isNative()}>
      <ProjectList />
    </ScreenWrapper>
  );
};
