import { Linking, Platform } from 'react-native';

import { requestNotifications, RESULTS } from '@app/libs/rn-permissions/rn-permissions';

import { NotificationSystem } from './notification';

export const getNotificationPermissions = async (): Promise<boolean> => {
  const deviceState = await NotificationSystem.getDeviceState();

  return deviceState.hasNotificationPermission;
};

interface AskNotificationReturns {
  hasPrompted: boolean;
  notificationsEnabled: boolean;
}

const promptPermission = async () => {
  if (Platform.OS === 'android' && Platform.Version >= 33) {
    const detailedPermission = await requestNotifications();
    const isBlocked = detailedPermission === RESULTS.DENIED;

    if (isBlocked) {
      Linking.openSettings();
    }

    return !isBlocked;
  }
  await NotificationSystem.promptPermission();
};

export const ensureNotificationPermissions = async (): Promise<AskNotificationReturns> => {
  return NotificationSystem.getDeviceState()
    .then(
      ({
        hasNotificationPermission,
        isSubscribed,
      }: { hasNotificationPermission?: boolean; isSubscribed?: boolean } | null) => {
        return !isSubscribed && hasNotificationPermission;
      }
    )
    .then(async hasPrompted => {
      return {
        hasPrompted,
        notificationsEnabled: !hasPrompted ? await promptPermission() : true,
      };
    }) as Promise<AskNotificationReturns>;
};
