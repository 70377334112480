import { Theme } from '@app/ui/theme/types';

export const BOTTOM_BAR_MENU_HEIGHT = 48;

export const bottomBarMenuTheme = (theme: Theme) => {
  return {
    menuItem: {
      button: {
        border: 'none',
      },
      icon: {
        activeIconColor: theme.palette.base.black,
        iconColor: theme.palette.neutral[600],
      },
      iconWeb: {
        activeIconColor: theme.palette.system.secondary[500],
        iconColor: theme.palette.neutral[600],
      },
    },
    menuWrapper: {
      background: theme.palette.base.white,
      border: `1px solid ${theme.palette.neutral[300]}`,
      borderTopColor: theme.palette.neutral[300],
      height: `${BOTTOM_BAR_MENU_HEIGHT}px`,
      width: '100%',
    },
  };
};
