import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPaper = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 2h7.175a1.978 1.978 0 011.4.575l4.85 4.85a1.978 1.978 0 01.575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 22H6zm7-18H6v16h12V9h-4a.965.965 0 01-.712-.288A.965.965 0 0113 8V4zM6 4v5-5 16V4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPaper;
