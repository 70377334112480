import { format, Locale } from 'date-fns';
import * as localeList from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

type DateFormatter = {
  formatDate: (date: Date) => string;
};

export const useDateFormatter = (localeCode?: string): DateFormatter => {
  const { i18n } = useTranslation();
  localeCode = localeCode ?? i18n.resolvedLanguage;
  const locale: Locale = localeList[localeCode] ?? localeList.enUS;

  const getFormattedTime = (date: Date) => ({
    day: format(date, 'do MMMM', { locale }),
    fullDate: format(date, 'PPPp', { locale }),
    hour: format(date, 'H', { locale }),
    minutes: format(date, 'mm', { locale }),
  });

  const formatDate = (date: Date | string): string => {
    if (date instanceof Date) {
      return getFormattedTime(date).fullDate;
    }
    return getFormattedTime(new Date(date)).fullDate;
  };

  return { formatDate };
};
