import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgUstensils = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 014 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v16c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 22H4zm0-2h16V4H4v16zm4.75-1c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-6a2.208 2.208 0 001.075-.713c.283-.341.425-.754.425-1.237V6.5a.48.48 0 00-.15-.35.48.48 0 00-.7 0 .48.48 0 00-.15.35V9h-.75V6.5a.48.48 0 00-.15-.35.48.48 0 00-.7 0 .48.48 0 00-.15.35V9H7.5V6.5a.48.48 0 00-.15-.35.48.48 0 00-.7 0 .48.48 0 00-.15.35v3.8c0 .483.142.896.425 1.237.283.342.642.58 1.075.713v6a.728.728 0 00.75.75zm6 0c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-5.6c.55-.267.98-.692 1.288-1.275.308-.583.462-1.267.462-2.05 0-.95-.237-1.742-.712-2.375S15.467 6 14.75 6c-.717 0-1.312.317-1.787.95-.475.633-.713 1.425-.713 2.375 0 .783.154 1.467.463 2.05.308.583.737 1.008 1.287 1.275v5.6a.728.728 0 00.75.75z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgUstensils;
