import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgMasonry = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.927 6.468A2.833 2.833 0 0014 10.454v2.856l-.516.269-3.788-2.421a1 1 0 00-1.351.26l-7.158 10a1 1 0 001.075 1.547l16-4.348a1 1 0 00.277-1.807l-3.111-1.989.034-.017a1 1 0 00.538-.887v-2.732a2.628 2.628 0 001.71-.804l4.788-4.95a2.833 2.833 0 000-3.912 2.614 2.614 0 00-3.784 0l-4.787 4.95zm1.39 2.475a.751.751 0 010-1.037l4.788-4.95a.693.693 0 011.003 0 .751.751 0 010 1.037l-4.788 4.95a.693.693 0 01-1.003 0zm-4.779 6.17l.9-.469-2.02-1.29-4.97 6.945 11.112-3.02-2.178-1.392-1.92 1a1 1 0 11-.924-1.774z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgMasonry;
