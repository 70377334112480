import React from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { checkboxTheme } from '@app/ui/atoms/checkbox/checkbox.theme';
import { Icon } from '@app/ui/atoms/Icon';

import { CheckProps } from '../Checkbox.types';
import { CheckboxInputContainer, CheckboxTickWrapper, Input } from './CheckboxInput.styles';
import { getCheckboxColor } from '../utils';

export const CheckboxInput: React.FC<CheckProps> = props => {
  const { checked, id, onChange = () => {}, disabled, testID, error, size = 'm' } = props;

  const componentTheme = useComponentTheme('checkbox', checkboxTheme);

  const checkboxColor = getCheckboxColor(checked, !!disabled, !!error, componentTheme);

  const handleOnChange = () => {
    onChange(!checked);
  };

  return (
    <CheckboxInputContainer
      componentTheme={componentTheme}
      $checked={checked}
      $disabled={disabled}
      $error={error}
      $size={size}
    >
      <Input
        componentTheme={componentTheme}
        $checked={checked}
        $disabled={disabled}
        $size={size}
        id={id}
        onChange={handleOnChange}
        type="checkbox"
        data-testid={testID}
      />
      <CheckboxTickWrapper componentTheme={componentTheme}>
        {checked ? (
          <Icon
            name="CheckboxChecked"
            size={componentTheme.size.size[size]}
            color={checkboxColor}
          />
        ) : (
          <Icon
            name="CheckboxUnchecked"
            size={componentTheme.size.size[size]}
            color={checkboxColor}
          />
        )}
      </CheckboxTickWrapper>
    </CheckboxInputContainer>
  );
};
