import { FinancialModel } from '@masteos/agora';

import { Project } from '@app/libs/apollo/introspection';
import { hasPublicToken } from '@app/utils/getPublicToken';

export const computedWorkAmount = (
  project: Project,
  financialModel?: FinancialModel | null
): number => {
  if (hasPublicToken()) {
    return (project.renovation.amount || 0) + (financialModel?.cgpCommission || 0);
  }

  return project.renovation.amount || 0;
};
