import { Platform } from 'react-native';

import { RadioButtonComponent, RadioItemComponent, RadioGroupComponent } from './RadioButton.types';

export const { RadioButton } = Platform.select<() => { RadioButton: RadioButtonComponent }>({
  default: () => require('./web/RadioButton'),
  native: () => require('./native/RadioButton'),
})();

export const { RadioGroup } = Platform.select<() => { RadioGroup: RadioGroupComponent }>({
  default: () => require('./web/RadioGroup'),
  native: () => require('./native/RadioGroup'),
})();

export const { RadioItem } = Platform.select<() => { RadioItem: RadioItemComponent }>({
  default: () => require('./web/RadioItem'),
  native: () => require('./native/RadioItem'),
})();

export * from './RadioButton.types';
