import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getTableHeaderStyle = (theme: Theme) =>
  StyleSheet.create({
    box: {
      backgroundColor: theme.palette.neutral[50],
      padding: theme.spacing.SIZE_03,
    },
  });
