import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigation, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { View } from 'react-native';

import { BottomBarMenu, IconName, useTheme } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { MainMenuNavigatorRoutes } from '@app/navigation/types/routes';
import { useCurrentRoute } from '@app/navigation/helpers/useCurrentRoutes';

import { getStyles } from './navigation.styles.web';
import { MobileNavigationNavigationProp } from './navigation.types';
import { DOM_BODY_CONTAINER_ID } from './navigation.constants';
import { filterNavigationLinks } from '../../navigation/utils/filterNavigationLinks.utils';
import { SidebarItem } from './components/sidebar-item/SidebarItem';

export const MobileNavigation: React.FC<{ children?: React.ReactNode }> = memo(({ children }) => {
  const navigation = useNavigation<MobileNavigationNavigationProp>();
  const currentStack = useCurrentRoute();
  const routeName = getFocusedRouteNameFromRoute(currentStack);
  const theme = useTheme();

  const styles = useMemo(() => getStyles(theme), [theme]);

  const { t } = useTranslation();
  const { customer } = useMe();

  const redirectPage = (path: MainMenuNavigatorRoutes) => () => {
    navigation.reset({
      index: 0,
      routes: [{ name: path }],
    });
  };

  const sideBarItemsProps = useMemo(() => {
    const nav = [
      {
        iconName: 'Home' as IconName,
        isActive: routeName === MainMenuNavigatorRoutes.HomePage,
        isMobile: true,
        key: MainMenuNavigatorRoutes.HomePage,
        label: t('navigation.topBar.homepage'),
        onPress: redirectPage(MainMenuNavigatorRoutes.HomePage),
        testID: 'homepage-page',
      },
      {
        iconName: 'SearchProperty' as IconName,
        isActive: routeName === MainMenuNavigatorRoutes.RealEstates,
        isMobile: true,
        key: MainMenuNavigatorRoutes.RealEstates,
        label: t('navigation.search'),
        onPress: redirectPage(MainMenuNavigatorRoutes.RealEstates),
        testID: 'button-search-page',
      },
      ...(customer.hasOngoingProject
        ? [
            {
              iconName: 'Projects' as IconName,
              isActive: routeName === MainMenuNavigatorRoutes.Projects,
              isMobile: true,
              key: MainMenuNavigatorRoutes.Projects,
              label: t('navigation.projects'),
              onPress: redirectPage(MainMenuNavigatorRoutes.Projects),
              testID: 'button-projects-page',
            },
          ]
        : []),
      ...(customer.hasNoAppointmentsPassed
        ? [
            {
              iconName: 'Headset' as IconName,
              isActive: routeName === MainMenuNavigatorRoutes.Discuss,
              isMobile: true,
              key: MainMenuNavigatorRoutes.Discuss,
              label: t('navigation.projects'),
              onPress: redirectPage(MainMenuNavigatorRoutes.Discuss),
              testID: 'button-discuss-page',
            },
          ]
        : []),
      {
        iconName: 'User' as IconName,
        isActive: routeName === MainMenuNavigatorRoutes.Profile,
        isMobile: true,
        key: MainMenuNavigatorRoutes.Profile,
        label: t('navigation.profileNav'),
        onPress: redirectPage(MainMenuNavigatorRoutes.Profile),
        testID: 'navbar-profile-link-button',
      },
    ];

    return filterNavigationLinks(nav, customer);
  }, [customer, redirectPage, routeName, t]);
  return (
    <View style={styles.container} testID="mobile-navigation">
      <View style={styles.body} testID="mobile-navigation-body" nativeID={DOM_BODY_CONTAINER_ID}>
        {children}
      </View>

      <BottomBarMenu.MenuWrapper wrapperStyle={styles.mobileMenu} testID="properties-mobile-navbar">
        {sideBarItemsProps.map(({ key, ...rest }) => (
          <SidebarItem {...rest} key={key} />
        ))}
      </BottomBarMenu.MenuWrapper>
    </View>
  );
});

MobileNavigation.displayName = 'MobileNavigation';
