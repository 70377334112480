// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
/* eslint-disable local-rules/no-raw-text */
import React from 'react';

import { View } from 'react-native';
import styled from '@emotion/styled';

import { IconPin } from '@app/ui/atoms/iconPin/IconPin';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { useTheme } from '@app/ui/contexts/config';
import { Text } from '@app/ui/atoms/Text/Text';

import { RowWithIconType } from './RowWithIcon.types';
import { getStyles } from './RowWithIcon.styles.web';

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.neutral[200]};
  margin-left: 24px;
  margin-right: 24px;
`;

export const RowWithIcon: React.FC<RowWithIconType> = ({
  value,
  title,
  iconName,
  numberOfLines,
  iconColor,
}) => {
  const { isNativeOrResponsive } = useResponsive();
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <View style={styles.container}>
      <View style={styles.leftBlock}>
        <IconPin icon={iconName} color={iconColor} />

        <Text textStyle="Body2Medium" ellipsizeMode="tail" numberOfLines={numberOfLines}>
          {title}
        </Text>
        {!isNativeOrResponsive && <Line />}
      </View>
      <View style={styles.rightBlock}>
        <Text textStyle="Body2Medium" style={styles.valueText}>
          {value}
        </Text>
      </View>
    </View>
  );
};
