import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getDefaultImageStyle = ({ theme }: { theme: Theme }) => {
  return StyleSheet.create({
    imageWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[200],
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
  });
};
