import React, { useEffect } from 'react';

import MasteosLogo from '@webImages/logo.svg';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Button, Image } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { trackChangePage, WebPageName } from '@app/services/tracking/pageTracking';

type ErrorPageProps = {
  message: string;
  resetError?: () => void;
};

export const ErrorScreen: React.FC<ErrorPageProps> = ({ resetError }) => {
  const { t } = useTranslation();
  const { height: windowHeight } = useWindowDimensions();

  const handleButton = () => {
    resetError?.();
  };

  const styleSheet = StyleSheet.create({
    containerButton: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 40,
    },
    image: {
      backgroundPosition: 'bottom',
      position: 'absolute',
    },
    wrapper: {
      alignItems: 'center',
      height: windowHeight,
      justifyContent: 'center',
      width: '100%',
    },
    wrapperImage: {
      height: 100,
      width: 100,
    },
  });

  useEffect(() => {
    trackChangePage(WebPageName.ERROR);
  }, []);

  return (
    <View style={styleSheet.wrapper}>
      <View style={styleSheet.wrapperImage}>
        <Image resizeMode="contain" style={styleSheet.image} source={{ uri: MasteosLogo }} />
      </View>
      <View style={styleSheet.containerButton}>
        <Button variant="tertiary" onPress={handleButton}>
          {t('errorScreen.buttonLabel')}
        </Button>
      </View>
    </View>
  );
};

// eslint-disable-next-line import/no-default-export
export default ErrorScreen;
