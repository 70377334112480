import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgUpload = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.846 20.103c-1.517 0-2.813-.525-3.887-1.575-1.076-1.05-1.613-2.334-1.613-3.85 0-1.3.391-2.459 1.175-3.475.783-1.017 1.808-1.667 3.075-1.95.416-1.534 1.25-2.775 2.5-3.725 1.25-.95 2.666-1.425 4.25-1.425 1.95 0 3.604.679 4.962 2.037 1.358 1.358 2.038 3.013 2.038 4.963 1.15.133 2.104.629 2.863 1.487a4.41 4.41 0 011.137 3.013c0 1.25-.438 2.312-1.312 3.188-.876.874-1.938 1.312-3.188 1.312h-5.5c-.55 0-1.021-.196-1.412-.587a1.927 1.927 0 01-.588-1.413v-5.2l-.9.9a.948.948 0 01-.7.275.948.948 0 01-.7-.275.948.948 0 01-.275-.7c0-.284.091-.517.275-.7l2.6-2.6c.1-.1.208-.171.325-.213.116-.042.241-.062.375-.062.133 0 .258.02.375.062a.883.883 0 01.325.213l2.6 2.6a.948.948 0 01.275.7.949.949 0 01-.275.7.948.948 0 01-.7.275.948.948 0 01-.7-.275l-.9-.9v5.2h5.5c.7 0 1.291-.242 1.775-.725.483-.484.725-1.075.725-1.775s-.242-1.292-.725-1.775c-.484-.484-1.075-.725-1.775-.725h-1.5v-2c0-1.384-.488-2.563-1.462-3.538-.976-.975-2.155-1.462-3.538-1.462-1.384 0-2.563.487-3.537 1.462-.976.975-1.463 2.154-1.463 3.538h-.5c-.967 0-1.792.341-2.475 1.025a3.372 3.372 0 00-1.025 2.475c0 .966.341 1.792 1.025 2.475a3.373 3.373 0 002.475 1.025h1.5c.283 0 .52.096.713.288a.967.967 0 01.287.712c0 .283-.096.52-.287.712a.968.968 0 01-.713.288h-1.5z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgUpload;
