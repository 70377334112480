import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgNa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="na_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#na_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 13.333L28 0H0v13.333z" fill="#0C4799" />
        <Path
          d="M4.667 6l-1.02 1.13.077-1.52-1.521.077 1.13-1.02-1.13-1.02 1.52.077-.077-1.521 1.02 1.13 1.021-1.13-.078 1.52 1.521-.077L6 4.666l1.13 1.021-1.52-.078.077 1.521L4.667 6z"
          fill="#FFD243"
        />
        <Path d="M0 20h28V6.667L0 20z" fill="#1BAC55" />
        <Path d="M3.082 22.667L30.171 5.74l-5.253-8.407L-2.171 14.26l5.253 8.407z" fill="#fff" />
        <Path d="M-.63 23.072l32.792-20.49-3.533-5.654-32.791 20.49 3.533 5.654z" fill="#E52347" />
      </G>
    </Svg>
  );
};

export default SvgNa;
