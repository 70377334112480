import React, { useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { DataRows } from '@app/ui/organisms/data-rows/DataRows';
import { getImageUrl } from '@app/utils/getImageUrl';
import { SoldRealEstateAfterRenovationV2 } from '@app/libs/apollo/introspection';

import { useGalleryContext } from '../../../gallery/GalleryProvider';
import { MiniGallery } from '../MiniGallery/MiniGallery';
import { RenovateWithMasteos } from '../renovateWithMasteos/RenovateWithMasteos';
import { getAfterRenovationRows } from './getAfterRenovationRows.utils';

type AfterRenovationProps = Pick<
  SoldRealEstateAfterRenovationV2,
  'dpe' | 'layout' | 'esthetics' | 'performances'
> & {
  images: string[];
  description: string;
};

const getStyles = ({ theme }: { theme: Theme; isLowerThanDesktop: boolean }) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_08,
    },
    title: {
      paddingBottom: theme.spacing.SIZE_06,
    },
  });

export const AfterRenovation: React.FC<AfterRenovationProps> = ({
  images,
  description,
  dpe,
  layout,
  esthetics,
  performances,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const { openGallery } = useGalleryContext();
  const styles = useMemo(
    () => getStyles({ isLowerThanDesktop, theme }),
    [theme, isLowerThanDesktop]
  );

  const imagesGrid = images.map(url => ({
    description: t('property.afterWork'),
    url: getImageUrl(url),
  }));

  const openGalleryAt = (index: number) => () => {
    openGallery({
      defaultIndex: index,
      images: imagesGrid,
    });
  };

  const rows = getAfterRenovationRows({
    dpe,
    esthetics,
    layout,
    performances,
    t,
  });

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title} textStyle="Headline1Medium">
          {t('propertySold.afterRenovation.title')}
        </Text>
        <Text textStyle="Body2">{description}</Text>
      </View>
      {!!rows.length && <DataRows testID="after-renovation-data-rows" rows={rows} />}
      <MiniGallery imagesGrid={imagesGrid} openGallery={openGalleryAt} />
      <RenovateWithMasteos />
    </View>
  );
};
