import { ComputedPosition, ComputationParams } from '../PopoverContainer.types';
import { POPOVER_CONSTANTS } from './constants';
export * from './constants';

export const computePosition = ({
  viewport,
  container,
  trigger,
  CONSTANTS = POPOVER_CONSTANTS,
}: ComputationParams): ComputedPosition => {
  const calculateCorners = () => {
    // Top corners
    if (trigger.y <= container.height) {
      y = trigger.y - CONSTANTS.paddingY;
      diamondY = CONSTANTS.paddingY;
    }

    // Bottom corners
    if (trigger.y + container.height >= viewport.height) {
      y = trigger.y + trigger.height - container.height + CONSTANTS.paddingY;
      diamondY = trigger.y - y;
    }
  };

  // Popover above trigger element
  let x = trigger.x - container.width / 2 + trigger.width / 2;
  let y =
    trigger.y -
    container.height -
    CONSTANTS.containerSpacing -
    CONSTANTS.diamondSize +
    CONSTANTS.diamondOffset;
  let diamondX = (container.width - CONSTANTS.diamondSize) / 2;
  let diamondY = container.height - CONSTANTS.diamondSize / 2 - CONSTANTS.diamondOffset;

  // Popover under trigger element
  if (trigger.y - CONSTANTS.containerSpacing <= container.height) {
    y =
      trigger.y +
      trigger.height +
      CONSTANTS.containerSpacing +
      CONSTANTS.diamondSize -
      CONSTANTS.diamondOffset;
    diamondY = -CONSTANTS.diamondSize / 2 + CONSTANTS.diamondOffset;
  }

  // Popover on the right side of trigger element
  if (trigger.x <= container.width / 2) {
    x =
      trigger.x +
      trigger.width +
      CONSTANTS.containerSpacing +
      CONSTANTS.diamondSize -
      CONSTANTS.diamondOffset;
    y = trigger.y - container.height / 2 + trigger.height / 2;
    diamondX = 0 - CONSTANTS.diamondSize / 2 + CONSTANTS.diamondOffset;
    diamondY = container.height / 2 - CONSTANTS.diamondSize / 2;
    calculateCorners();
  }

  // Popover on the left side of trigger element
  if (trigger.x + container.width / 2 >= viewport.width) {
    x =
      trigger.x -
      container.width -
      CONSTANTS.containerSpacing -
      CONSTANTS.diamondSize +
      CONSTANTS.diamondOffset;
    y = trigger.y - container.height / 2 + trigger.height / 2;
    diamondX = container.width - CONSTANTS.diamondSize / 2 - CONSTANTS.diamondOffset;
    diamondY = container.height / 2 - CONSTANTS.diamondSize / 2;
    calculateCorners();
  }

  return {
    diamondX,
    diamondY,
    x,
    y,
  };
};
