import React, { useMemo, useState } from 'react';

import { LayoutChangeEvent, View, StyleSheet } from 'react-native';

import { DataBlock, ShadowContainer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { Row } from '@app/features/realEstate/realEstateAvailable/realEstateGeneral/hooks/useRealEstateGeneral';
import { useTranslation } from '@app/services/translations/translations';
import { ShortTermRentalSwitch } from '@app/features/realEstate/realEstateAvailable/components/ShortTermRentalSwitch';
import { isWeb } from '@app/constants/platform';

interface RealEstateKeyNumbersProps {
  hasShortTermRental: boolean;
  withShortTermRental: boolean;
  toggleShortTermRental: (value: boolean) => void;
  projectAmout: number;
  rows: Row[];
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    card: {
      borderRadius: theme.borderRadius.SIZE_08,
      gap: theme.spacing.SIZE_06,
      padding: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
    },
    container: {
      gap: theme.spacing.SIZE_07,
      ...(!isLowerThanLaptop &&
        isWeb() && { position: 'sticky' as 'relative', top: theme.spacing.SIZE_13 }),
    },
    rows: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing.SIZE_06,
    },
  });

export const RealEstateKeyNumbers: React.FC<RealEstateKeyNumbersProps> = ({
  hasShortTermRental,
  toggleShortTermRental,
  projectAmout = 0,
  rows,
  withShortTermRental,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isNativeOrResponsive, isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanLaptop), [isLowerThanLaptop, theme]);

  const [datablockWidth, setDatablockWidth] = useState(0);

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setDatablockWidth(nativeEvent.layout.width / 2 - theme.spacing.SIZE_06 / 2);
  };

  return (
    <View style={styles.container}>
      <Text textStyle="Headline1Medium">{t('propertyDescription.keyNumbers')}</Text>

      <ShadowContainer shadowSize={200} style={styles.card}>
        {!!hasShortTermRental && (
          <ShortTermRentalSwitch
            withShortTermRental={withShortTermRental}
            toggleShortTermRental={toggleShortTermRental}
          />
        )}

        <View style={styles.rows} onLayout={onLayout}>
          {rows?.map(row => (
            <DataBlock
              key={row.title}
              icon={row.icon}
              title={row.title}
              valueStyle={row.dataStyle}
              data={row.data}
              testID={row.testID}
              hint={row.hint}
              width={
                isNativeOrResponsive ? datablockWidth : `calc(50% - ${theme.spacing.SIZE_06 / 2}px)`
              }
            />
          ))}
        </View>

        <DataBlock
          icon="Wallet"
          title={t('shared.financialTool.projectAmount')}
          data={currencyFormatter(projectAmout, 0)}
          testID="property-general-data-block-project-cost"
          variant="inverted"
        />
      </ShadowContainer>
    </View>
  );
};
