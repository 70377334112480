import React, { PropsWithChildren } from 'react';

import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { SsoProviderProps } from './SsoProvider.types';

export const SsoProvider: React.FC<PropsWithChildren<SsoProviderProps>> = ({
  googleWebClientId,
  children,
}) => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  return <GoogleOAuthProvider clientId={googleWebClientId}>{children}</GoogleOAuthProvider>;
};
