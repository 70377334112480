import * as React from 'react';

import Svg, { G, Path, Defs, LinearGradient, Stop, ClipPath } from 'react-native-svg';

const SvgZw = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <G clipPath="url(#zw_svg__clip0_4223_12154)">
        <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2.667h28V0H0v2.667zM0 20h28v-2.667H0V20zM0 8h28V5.333H0V8zM0 14.667h28V12H0v2.667z"
          fill="#369D0C"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 12h28V8H0v4z" fill="#262626" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.333h28V2.667H0v2.666zM0 17.333h28v-2.666H0v2.666z"
          fill="#FFD40E"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.333 20V0H0l13.333 10L0 20h-1.333z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 18.333L11.111 10 0 1.667v16.666zM13.333 10L0 20h-1.333V0H0l13.333 10z"
          fill="#262626"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 11.3l-1.96 1.397.724-2.295L.83 8.97l2.406-.022L4 6.667l.764 2.281 2.406.022-1.934 1.432.723 2.295L4 11.3z"
          fill="url(#zw_svg__paint0_linear_4223_12154)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="zw_svg__paint0_linear_4223_12154"
          x1={0.667}
          y1={6.667}
          x2={0.667}
          y2={13.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F12F1F" />
          <Stop offset={1} stopColor="#DE2010" />
        </LinearGradient>
        <ClipPath id="zw_svg__clip0_4223_12154">
          <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SvgZw;
