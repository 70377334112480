import React from 'react';

import { View } from 'react-native';

import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { useTheme } from '@app/ui/contexts/config';

import { getHeaderPageContainerStyle } from './HeaderPageContainer.styles';
import { HeaderPageContainerType } from './HeaderPageContainer.types';

export const HeaderPageContainer: React.FC<HeaderPageContainerType> = ({ children, style }) => {
  const { isLowerThanTablet } = useResponsive();
  const theme = useTheme();
  const styles = getHeaderPageContainerStyle({ isLowerThanTablet, theme });

  return (
    <View style={[style, styles.container]} testID="header-page-container">
      {children}
    </View>
  );
};

export { HeaderPageContainerType };
