import React, { useMemo } from 'react';

import { StyleSheet, TextStyle } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { IconName, IconPopoverOrModal, Theme, useResponsive, useTheme } from '@app/ui';
import { currencyFormatter, percentFormatter } from '@app/utils/currency-formatter';
import { useFinancialModel } from '@app/shared/hooks/useFinancialModel';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { computedWorkAmount } from '../utils/computedWorkAmount.utils';

export interface Row {
  icon: IconName;
  title: string;
  dataStyle?: TextStyle;
  data: string;
  testID: string;
  hint?: React.ReactNode;
}

interface RealEstateDescriptionOutput {
  financialModel: FinancialModel;
  hasMandate: boolean;
  rows: Row[];
  width: number | string;
  trendingUp: number;
  withShortTermRental: boolean;
  setWithShortTermRental: (v: boolean) => void;
}

const DATA_BLOCK_WIDTH_ON_MOBILE = '100%';
const DATA_BLOCK_WIDTH = 234;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    shortTermRentalText: {
      color: theme.palette.tint.purple[500],
    },
  });

export const useRealEstateGeneral = (realEstate: RealEstate): RealEstateDescriptionOutput => {
  const { t } = useRealEstateTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);
  const { customer } = useMe();
  const { financialModel, loading, withShortTermRental, setWithShortTermRental } =
    useFinancialModel(realEstate);
  const trendingUp = (realEstate.trending?.appreciation?.evaluationRate ?? 0) * 100;
  const hasMandate = customer?.searchMandate?.valid ?? false;

  const width = isLowerThanLaptop ? DATA_BLOCK_WIDTH_ON_MOBILE : DATA_BLOCK_WIDTH;

  let rows: Row[] = [];

  if (!loading) {
    rows = [
      {
        data: percentFormatter(financialModel?.grossReturn, 1),
        hint: (
          <IconPopoverOrModal
            title={t('propertySynthese.tooltip.grossReturnTitle')}
            content={t('propertySynthese.tooltip.grossReturnDescription', {
              context: `v${realEstate.financialModelVersion}`,
            })}
            iconName="Info"
            iconSize="s"
          />
        ),
        icon: 'Chart',
        testID: 'property-general-data-block-gross-return',
        title: t('shared.financialTool.grossReturn'),
        ...(withShortTermRental && { dataStyle: styles.shortTermRentalText }),
      },
      {
        data: percentFormatter(financialModel?.netReturn, 1),
        hint: (
          <IconPopoverOrModal
            title={t('propertySynthese.tooltip.netReturnTitle')}
            content={t('propertySynthese.tooltip.netReturnDescription', {
              context: `v${realEstate.financialModelVersion}`,
            })}
            iconName="Info"
            iconSize="s"
          />
        ),
        icon: 'Chart',
        testID: 'property-general-data-block-net-return',
        title: t('shared.financialTool.netReturn'),
        ...(withShortTermRental && { dataStyle: styles.shortTermRentalText }),
      },
      {
        data: currencyFormatter(financialModel?.annualRentIncome / 12, 0),
        icon: 'Euro',
        testID: 'property-general-data-block-annual-rent-income',
        title: t('shared.financialTool.monthlyRentIncomeNoCharges'),
        ...(withShortTermRental && { dataStyle: styles.shortTermRentalText }),
      },
      {
        data: currencyFormatter(computedWorkAmount(realEstate.project, financialModel), 0),
        icon: 'Hammer',
        testID: 'property-general-data-block-renovation',
        title: t('shared.financialTool.renovation'),
      },
    ];
  }

  return {
    financialModel,
    hasMandate,
    rows,
    setWithShortTermRental,
    trendingUp,
    width,
    // TODO: Feature deactivited for now #removeShortTermRental
    withShortTermRental: false,
  };
};
