import React, { useCallback } from 'react';

import { View } from 'react-native';

import { SimpleTextActivator } from '@app/ui/molecules/dropdown-activator/simple-text-activator/SimpleTextActivator';
import { ListItem } from '@app/ui/atoms/list/ListItem';
import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';

import { SimpleTextSelectorItemProps, SimpleTextSelectorProps } from './SimpleTextSelector.types';
import { getStyles } from './SimpleTextSelector.styles';

export { SimpleTextSelectorItemProps, SimpleTextSelectorProps };

export const SimpleTextSelector: React.FC<SimpleTextSelectorProps> = ({
  options,
  value,
  maxHeight = 300,
  maxWidth = 500,
  onSelectValue,
  ...rest
}) => {
  const styles = getStyles();

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="simple-text-selector-list-container">
          {options.map(item => {
            const onSelect = () => {
              onSelectValue(item, closeList);
            };
            return (
              <ListItem
                {...item}
                key={item.key || item.label.toLowerCase()}
                onPress={onSelect}
                checked={item.selected}
              />
            );
          })}
        </View>
      );
    },
    [onSelectValue, options, styles.listContainer]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => (
      <DropdownListContainer list={getList(closeList)} maxHeight={maxHeightList} />
    ),
    [getList]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return <SimpleTextActivator open={isOpen} value={value} onPress={toggleList} />;
    },
    [value]
  );

  return (
    <DropdownV2
      {...rest}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      renderList={renderList}
      renderActivator={renderActivator}
      fitParent={false}
    />
  );
};
