import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';

import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { PublicHomePage } from '@app/features/publicHomePage/PublicHomePage';

type PublicHomeViewProps = StackScreenProps<
  PublicStackParamList,
  PublicNavigatorRoutes.PublicHome | PublicNavigatorRoutes.RealEstateList
>;

export const PublicHomeView: React.FC<PublicHomeViewProps> = () => {
  return <PublicHomePage />;
};
