import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgAz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="az_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#az_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 6.667h28V0H0v6.667z" fill="#24AAD5" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#21BF75" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.333h28V6.667H0v6.666z"
          fill="#ED1845"
        />
        <G filter="url(#az_svg__filter0_d_4223_10377)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 12a1.99 1.99 0 001.111-.337 1.667 1.667 0 110-3.326A2 2 0 1014 12zm2-2a.667.667 0 11-1.333 0A.667.667 0 0116 10z"
            fill="#fff"
          />
        </G>
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgAz;
