import { NotificationPermissionStatus } from './rn-permissions.types';

export const checkNotificationsStatus: () => Promise<
  NotificationPermissionStatus | undefined
> = () => Promise.resolve(undefined);

export const requestNotifications: () => Promise<NotificationPermissionStatus | undefined> = () =>
  Promise.resolve(undefined);

export const RESULTS = {} as NotificationPermissionStatus;
