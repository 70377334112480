/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { View } from 'react-native';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { FormProvider } from 'react-hook-form';

import { useContainerQueriesHook, useResponsive } from '@app/ui';
import { SearchEngineHeader } from '@app/features/search-engine/compounds/search-engine-header/SearchEngineHeader';
import {
  ExtendedSearchResultsType,
  SearchEngineItem,
  SearchEngineLocationItem,
  SearchEngineSection,
  SearchEngineSource,
  SearchEngineType,
} from '@app/features/search-engine/searchEngine.types';
import { UniversalDrawer } from '@app/shared/components/UniversalDrawer/UniversalDrawer';
import { getStyle } from '@app/features/search-engine/searchEngine.styles';
import { isWeb } from '@app/constants/platform';
import { SearchEngineList } from '@app/features/search-engine/compounds/search-engine-list/SearchEngineList';

// eslint-disable-next-line max-len
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';

// eslint-disable-next-line max-len

import { SearchEngineFiltersHorizontal } from './compounds/search-engine-filters/search-engine-filters-horizontal/SearchEngineFiltersHorizontal';
import { Filters } from './compounds/search-engine-filters/search-engine-filters-drawer/Filters';

const query = {
  noHorizontalFilters: 856,
};

export const SearchEngineTemplate: React.FC<SearchEngineType> = ({
  list,
  withHigherBudgetList,
  withNearbyList,
  header,
  state,
  filter,
  resetForm: clearForm,
  hasMore,
  position,
  listLoadingIsRefresh,
  regionListByCountry,
  zoneLocationListByCountry,
  listLoading,
  filterLoading,
  onFilterReset,
  onFilterShow,
  onFilterUpdate,
  onFilterCountryClick,
  onItemBookmark,
  onItemClick,
  onLoadMoreRealEstates,
  form,
}) => {
  const bottomSheetRef = useRef<BottomSheetModal>(null);

  const { isMobile, isLowerThanTablet } = useResponsive();
  const [showDrawer, setShowDrawer] = useState(false);
  const styles = getStyle({ isLowerThanTablet, isMobile });
  const {
    matches: { noHorizontalFilters },
    onLayout,
  } = useContainerQueriesHook({ query });

  const handleFilterShow = useCallback(
    (source: SearchEngineSource) => () => {
      if (isWeb()) {
        document.body.style.overflow = 'hidden';
      }
      setShowDrawer(true);
      bottomSheetRef.current?.present();
      onFilterShow(source);
    },
    [onFilterShow]
  );

  const handleFilterHide = useCallback(
    (clear = false) => {
      if (filterLoading) {
        return;
      }

      clear && clearForm();
      if (isWeb()) {
        document.body.style.overflow = 'auto';
      }
      bottomSheetRef.current?.dismiss();
      setShowDrawer(false);
    },
    [filterLoading, clearForm]
  );

  const handleItemClick = useCallback(
    (
      item: SearchEngineItem,
      info: SearchEngineLocationItem,
      extendedResultsSection?: ExtendedSearchResultsType
    ) => Promise.resolve(onItemClick(item, info, extendedResultsSection)),
    [onItemClick]
  );

  const handleItemBookmark = useCallback(
    (item: RealEstatePreviewItem) => Promise.resolve(onItemBookmark(item)),
    [onItemBookmark]
  );

  // We enable back the scrolling if the component is unmounted while filter is open
  useEffect(
    () => () => {
      if (isWeb() && document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'auto';
      }
    },
    []
  );

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer} onLayout={onLayout}>
        <SearchEngineHeader
          section={header.section}
          state={state}
          loading={listLoading}
          resultCount={header.resultCount}
          bookmarkedCount={header.bookmarkedCount}
          filterCount={header.filterCount}
          onSectionChange={header.onSectionChange}
          onFilterShow={handleFilterShow(SearchEngineSource.DRAWER)}
          hideFilterButton={!noHorizontalFilters}
          onSubmit={onFilterUpdate}
          form={form}
          {...filter}
        />
        {!noHorizontalFilters && header.section !== SearchEngineSection.BOOKMARKED ? (
          <SearchEngineFiltersHorizontal
            onFilterShow={handleFilterShow(SearchEngineSource.DRAWER)}
            form={form}
            {...filter}
            regionListByCountry={regionListByCountry}
            loading={filterLoading}
            filterCount={header.filterCount}
            onSubmit={onFilterUpdate}
            onCountryClick={onFilterCountryClick}
            zoneLocationListByCountry={zoneLocationListByCountry}
          />
        ) : null}
      </View>
      <View style={[styles.bodyContainer, styles.bodyContainerLayout]}>
        <SearchEngineList
          list={list}
          hasMore={hasMore}
          section={header.section}
          position={position}
          loading={listLoading}
          loadingIsRefresh={listLoadingIsRefresh}
          onItemBookmark={handleItemBookmark}
          onItemClick={handleItemClick}
          onFilterShow={handleFilterShow(SearchEngineSource.NO_RESULT)}
          onFilterReset={() => onFilterReset(SearchEngineSource.NO_RESULT)}
          onLoadMoreRealEstates={onLoadMoreRealEstates}
          withHigherBudgetList={withHigherBudgetList}
          withNearbyList={withNearbyList}
        />
      </View>

      <UniversalDrawer
        isOpen={showDrawer}
        direction="right"
        bottomSheetRef={bottomSheetRef}
        showCloseBtn
        onClickCrossBtn={() => handleFilterHide(true)}
        onClickBackdrop={() => handleFilterHide(true)}
      >
        <FormProvider {...form}>
          <Filters isOpen={showDrawer} onSubmit={onFilterUpdate} onClose={handleFilterHide} />
        </FormProvider>
      </UniversalDrawer>
    </View>
  );
};
