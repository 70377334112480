import { FieldPolicy } from '@apollo/client';

import { isIncomingGreaterThanExisting } from '@app/libs/apollon/utils/isIncomingGreaterThanExisting.utils';
import { MyRealEstatesCachedResult } from '@app/libs/apollon/types/myRealEstatesCachedResult';

/**
 * In memory Cache algorithm for myRealEstates List
 * This cache is aim to concatenate result into the previous query result in order to have an infinite scroll.
 *
 * But we need also need to check if the query is a re-fetch. In that case,
 * we will add new realEstates on top of the result
 */
export const myRealEstatesMemoryCache: FieldPolicy<
  MyRealEstatesCachedResult,
  MyRealEstatesCachedResult
> = {
  keyArgs: ['filters'],
  merge: (existing, incoming, { variables }) => {
    if (!existing) {
      return incoming;
    }

    const realEstates = [...existing.realEstates];
    const cachedNextRealEstateId: string = incoming.cursor
      ? `RealEstate:${incoming.cursor.nextRealEstateId}`
      : '';
    const isRefreshResult =
      cachedNextRealEstateId &&
      (existing.realEstates.find(
        realEstateCached => realEstateCached.__ref === cachedNextRealEstateId
      ) ||
        (existing.cursor && existing.cursor.nextRealEstateId === incoming.cursor.nextRealEstateId));

    for (const realEstateCached of incoming.realEstates) {
      const realEstateAlreadyExists = !!realEstates.find(
        currentRealEstateCached => currentRealEstateCached.__ref === realEstateCached.__ref
      );

      if (isRefreshResult) {
        if (!realEstateAlreadyExists) {
          realEstates.splice(0, 0, realEstateCached);
        }

        continue;
      }

      if (!realEstateAlreadyExists) {
        realEstates.push(realEstateCached);
      }
    }

    let correctCursor: MyRealEstatesCachedResult['cursor'];

    /**
     * WARNING: This filter is linked to the sort of the mongo query, which means that
     * any modification of this filter (adding, removing fields) has to be reported to
     * infrastructure/mongo/real-estate/builder/real-estate-search-query.builder.ts >
     * RealEstateSearchQueryBuilder.withCursor()
     */
    if (incoming.cursor && existing.cursor) {
      correctCursor = isIncomingGreaterThanExisting(
        incoming.cursor,
        existing.cursor,
        variables.filters
      )
        ? existing.cursor
        : incoming.cursor;
    }

    if (!existing.cursor) {
      correctCursor = existing.cursor;
    }

    return {
      ...existing,
      cursor: correctCursor || incoming.cursor,
      realEstates,
      total: incoming.total,
    };
  },
};
