export const TRANSLATION_SERVICE = 'DeepL';

export const TERMS_URL = 'https://www.masteos.com/cgu';

export const LINKS_MASTEOS = 'https://links.masteos.com';

export const PRIVACY_POLICY = 'https://www.masteos.com/confidentialite';

// Max 32 bits value
export const MAX_GRAPHQL_INT = 2147483647;
