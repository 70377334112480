import React, { CSSProperties, PropsWithChildren } from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';

import { BottomBarMenuContainer } from './StyledComponents';
import { BottomBarMenuProps } from '../BottomBarMenu.types';
import { bottomBarMenuTheme } from '../bottomBarMenu.theme';

export const MenuWrapper: React.FC<
  PropsWithChildren<Omit<BottomBarMenuProps, 'wrapperStyle'>> & {
    wrapperStyle: CSSProperties;
  }
> = ({ wrapperStyle = {}, children, testID }) => {
  const componentTheme = useComponentTheme('bottomBarMenu', bottomBarMenuTheme);

  return (
    <BottomBarMenuContainer
      style={wrapperStyle}
      data-testid={testID}
      componentTheme={componentTheme}
    >
      {children}
    </BottomBarMenuContainer>
  );
};
