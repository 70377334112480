import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="cn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#cn_svg__a)">
        <Path fill="#F1361D" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.073 3.1l.968.166.726.661.165-.968.662-.726-.968-.166-.727-.661-.165.968-.661.727zm2.594 3.388l.839-.51.982.022-.51-.84L14 4.18l-.84.51-.981-.022.51.839-.022.982zm-1.139 5.483l-.82.54-.012-.983-.54-.82.982-.012.821-.54.012.982.54.821-.983.012zm.713-2.54l.977-.108.88.436-.108-.977.435-.88-.976.108-.88-.436.108.977-.436.88zM6.667 8.227L4.316 9.903l.867-2.754-2.32-1.718 2.887-.026.917-2.738.917 2.738 2.887.026-2.32 1.718.867 2.754-2.351-1.676z"
          fill="#FFDC42"
        />
      </G>
    </Svg>
  );
};

export default SvgCn;
