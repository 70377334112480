import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTiktok = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.947 2h2.685v.005a.897.897 0 01.966.723c.33 1.655 1.768 2.9 3.491 2.909h.016a.899.899 0 01.895.909v3.636a.9.9 0 01-.895.91l-.02-.001a7.918 7.918 0 01-3.559-.851v5.396c0 3.515-2.804 6.364-6.263 6.364S3 19.15 3 15.636s2.804-6.363 6.263-6.363c.494 0 .895.407.895.909v2.727c0 .502-.4.91-.895.91-.988 0-1.79.813-1.79 1.817 0 1.005.802 1.819 1.79 1.819s1.79-.814 1.79-1.819V2.91c0-.502.4-.909.894-.909zm2.096 1.818h-1.2v11.818c0 2.009-1.603 3.637-3.58 3.637-1.976 0-3.579-1.628-3.579-3.637 0-1.694 1.14-3.118 2.684-3.521v-.933c-2.042.421-3.579 2.255-3.579 4.454 0 2.51 2.003 4.546 4.474 4.546 2.47 0 4.474-2.035 4.474-4.546V8.818a.93.93 0 01.007-.116.917.917 0 01.19-.684.885.885 0 011.257-.14 6.181 6.181 0 003.02 1.33V7.38c-1.94-.33-3.527-1.719-4.168-3.56z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTiktok;
