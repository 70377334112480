import { getValues, getDefaultConfig } from './conf-values';
import { ConfigKeys } from './config.enum';
import { Config, MobileConfig } from './config.types';

export let config: Config = getDefaultConfig() as Config;

export const getConfigByKey = (key: ConfigKeys): string => config[key] || null;

export const setConfig = (newConf: MobileConfig): void => {
  config = getValues(newConf);
};

export * from './config.enum';
