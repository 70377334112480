import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLock = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 1c1.383 0 2.563.487 3.538 1.462C16.513 3.437 17 4.617 17 6v2h1c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 22H6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 20V10c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 8h1V6c0-1.383.488-2.563 1.463-3.538C9.438 1.487 10.617 1 12 1zm0 2c-.833 0-1.542.292-2.125.875A2.893 2.893 0 009 6v2h6V6c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0012 3zM6 20h12V10H6v10zm6-7c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0010 15c0 .55.196 1.021.588 1.413.391.391.862.587 1.412.587.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.021-.587-1.413A1.928 1.928 0 0012 13z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLock;
