import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMinimize = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M13 12a.965.965 0 01-.712-.288A.965.965 0 0112 11V5c0-.283.096-.521.288-.713A.967.967 0 0113 4a.97.97 0 01.713.287A.97.97 0 0114 5v3.6l5.9-5.9a.948.948 0 01.7-.275c.283 0 .517.092.7.275a.948.948 0 01.275.7.948.948 0 01-.275.7L15.4 10H19c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0119 12h-6zM2.7 21.3a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7L8.6 14H5a.968.968 0 01-.713-.288A.967.967 0 014 13a.97.97 0 01.287-.713A.97.97 0 015 12h6a.97.97 0 01.713.287A.97.97 0 0112 13v6c0 .283-.096.52-.287.712A.968.968 0 0111 20a.965.965 0 01-.712-.288A.965.965 0 0110 19v-3.6l-5.9 5.9a.948.948 0 01-.7.275.948.948 0 01-.7-.275z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMinimize;
