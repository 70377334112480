import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgHeart = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10.65 20.15l-1.725-1.575a68.234 68.234 0 01-4.787-4.813C2.713 12.171 2 10.417 2 8.5c0-1.567.525-2.875 1.575-3.925C4.625 3.525 5.933 3 7.5 3a5.72 5.72 0 012.5.562c.783.375 1.45.888 2 1.538a5.962 5.962 0 012-1.538A5.72 5.72 0 0116.5 3c1.567 0 2.875.525 3.925 1.575C21.475 5.625 22 6.933 22 8.5c0 1.917-.708 3.675-2.125 5.275A59.863 59.863 0 0115.05 18.6l-1.7 1.55a1.89 1.89 0 01-1.35.55 1.89 1.89 0 01-1.35-.55zm.4-13.05c-.483-.683-1-1.204-1.55-1.563C8.95 5.179 8.283 5 7.5 5c-1 0-1.833.333-2.5 1S4 7.5 4 8.5c0 .867.308 1.787.925 2.762A19.063 19.063 0 007.138 14.1a38.261 38.261 0 002.65 2.575c.908.8 1.645 1.458 2.212 1.975.567-.517 1.304-1.175 2.213-1.975a38.57 38.57 0 002.65-2.575 19.101 19.101 0 002.212-2.838c.617-.975.925-1.895.925-2.762 0-1-.333-1.833-1-2.5s-1.5-1-2.5-1c-.783 0-1.45.179-2 .537-.55.359-1.067.88-1.55 1.563a1.138 1.138 0 01-.95.5 1.138 1.138 0 01-.95-.5z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgHeart;
