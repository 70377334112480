import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgClipboard = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7.883 17.23h5a.968.968 0 00.713-.288.966.966 0 00.287-.712.97.97 0 00-.287-.713.97.97 0 00-.713-.287h-5a.97.97 0 00-.713.287.97.97 0 00-.287.713c0 .283.096.52.287.712.192.192.43.288.713.288zm0-4h8c.283 0 .52-.096.712-.288a.965.965 0 00.288-.712.968.968 0 00-.288-.713.967.967 0 00-.712-.287h-8a.97.97 0 00-.713.287.97.97 0 00-.287.713c0 .283.096.52.287.712.192.192.43.288.713.288zm0-4h8c.283 0 .52-.096.712-.288a.965.965 0 00.288-.712.968.968 0 00-.288-.713.967.967 0 00-.712-.287h-8a.97.97 0 00-.713.287.97.97 0 00-.287.713c0 .283.096.52.287.712.192.192.43.288.713.288zm-3 12c-.55 0-1.021-.196-1.413-.587a1.928 1.928 0 01-.587-1.413v-14c0-.55.196-1.021.587-1.413a1.928 1.928 0 011.413-.587h4.2c.217-.6.58-1.083 1.088-1.45a2.855 2.855 0 011.712-.55c.633 0 1.204.183 1.713.55.508.367.87.85 1.087 1.45h4.2c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 01-1.413.587h-14zm7-16.75a.727.727 0 00.75-.75.73.73 0 00-.212-.538.731.731 0 00-.538-.212.728.728 0 00-.75.75.726.726 0 00.75.75zm-7 14.75h14v-14h-14v14z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgClipboard;
