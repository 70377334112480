import React, { PropsWithChildren, useMemo, useState } from 'react';

import {
  Animated,
  Modal as RNModal,
  Platform,
  LayoutRectangle,
  View,
  useWindowDimensions,
  LayoutChangeEvent,
  SafeAreaView,
} from 'react-native';

import { Spacing } from '@app/ui/theme/Sizes';
import { palette } from '@app/ui/theme/palette';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { Icon } from '@app/ui/atoms/Icon';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { Text } from '@app/ui/atoms/Text/Text';

import type { ModalProps } from './Modal.types';
import { computePosition, getStyles } from './Modal.styles';
import { useModalSlideAnimation } from './useModalSlideAnimation';

/**
 * Use BottomSheet or FullScreenModal instead
 * @deprecated
 */
export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  children,
  testID = 'modal-wrapper',
  title,
  position = 'bottom',
  visible,
  onPressClose,
  hideCross = false,
  withSafeAreaView = false,
  crossSecondary = false,
  leftPositionCross = false,
  noContainerPadding = false,
  ...modalProps
}) => {
  const [containerStyles, setContainerStyles] = useState<LayoutRectangle | null>(null);

  const dimensions = useWindowDimensions();

  const { fadeAnimation, animation, onClose } = useModalSlideAnimation(
    visible,
    position,
    onPressClose,
    dimensions
  );
  const styles = useMemo(
    () =>
      getStyles({
        crossSecondary,
        dimensions,
        leftPositionCross,
        noContainerPadding,
      }),
    [dimensions, crossSecondary]
  );
  const positionStyles = useMemo(
    () => computePosition(position, containerStyles, dimensions),
    [containerStyles, dimensions]
  );

  const disablePressOnCard = () => undefined;

  const PressableOrView = position === 'fullscreen' ? View : Pressable;

  const onLayout = (e: LayoutChangeEvent) => setContainerStyles(e.nativeEvent.layout);

  const WrapperSafeArea = withSafeAreaView ? SafeAreaView : View;

  return (
    <RNModal visible={visible} transparent statusBarTranslucent {...modalProps} testID={testID}>
      <WrapperSafeArea testID={`modal-${withSafeAreaView ? 'safeAreaView' : 'view'}`}>
        <Animated.View style={[styles.fullScreen, fadeAnimation]}>
          <PressableOrView style={styles.fullScreen} onPress={onClose} withPressOpacity={false}>
            <Animated.View style={[styles.fullScreen, animation]}>
              <PressableOrView
                style={[
                  styles.container,
                  positionStyles,
                  Platform.select({ web: { cursor: 'default' } }),
                ]}
                onLayout={e => onLayout(e)}
                onPress={() => disablePressOnCard()}
                testID="modal-pressable-or-view"
                withPressOpacity={false}
              >
                {!hideCross ? (
                  <Pressable onPress={onClose} style={styles.closeButton} hitSlop={30}>
                    <Icon name="Cross" size={Spacing.SIZE_07} color={palette.neutral[800]} />
                  </Pressable>
                ) : null}
                <Text textStyle="Title3Medium">{title}</Text>
                <Spacer height={Spacing.SIZE_06} /> {children}
              </PressableOrView>
            </Animated.View>
          </PressableOrView>
        </Animated.View>
      </WrapperSafeArea>
    </RNModal>
  );
};
