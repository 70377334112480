import { useHasAnAccountWithEmailPasswordQuery } from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';

export const useHasAnAccountWithEmailPassword = (): boolean => {
  const { customer } = useMe();
  const { data, loading } = useHasAnAccountWithEmailPasswordQuery({
    variables: { email: customer?.email },
  });

  return loading ? undefined : !!data?.customerInfoByEmail.hasAnAccountWithEmailPassword;
};
