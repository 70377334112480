import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useFinancialModel } from '@app/shared/hooks/useFinancialModel';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { InvestmentWarning } from '@app/features/realEstate/components/InvestmentWarning';
import { isNative } from '@app/constants/platform';
import { ShortTermRentalSwitch } from '@app/features/realEstate/realEstateAvailable/components/ShortTermRentalSwitch';
import { trackSwitchToShortTermRental } from '@app/services/tracking/trackTracking';

import { RealEstateFinancialFunding } from './components/RealEstateFinancialFunding';
import { RealEstateFinancialInvestment } from './components/RealEstateFinancialInvestment';
import { RealEstateEnrichmentProjectionSummary } from './components/RealEstateEnrichmentProjectionSummary';
import { RealEstateFinancialSummary } from './components/RealEstateFinancialSummary';
import { RealEstateEnrichmentProjection } from './components/RealEstateEnrichmentProjection';
import { RealEstateFinancialAnalysisSummary } from './components/RealEstateFinancialAnalysisSummary';
import { RealEstateFinancialAnnualIncomes } from './components/RealEstateFinancialAnnualIncomes';
import { RealEstateFinancialAnnualCharges } from './components/RealEstateFinancialAnnualCharges';
import { RealEstateFinancialResults } from './components/RealEstateFinancialResults';

interface RealEstateFinancesProps {
  realEstate: RealEstate;
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_09,
      ...(isNative() && {
        paddingHorizontal: theme.spacing.SIZE_06,
        paddingTop: theme.spacing.SIZE_08,
      }),
    },
    section: {
      gap: theme.spacing.SIZE_08,
    },
    titleContainer: {
      ...(!isLowerThanLaptop && {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }),
      ...(isLowerThanLaptop && {
        alignItems: 'flex-start',
        rowGap: theme.spacing.SIZE_07,
      }),
    },
  });

export const RealEstateFinances: React.FC<RealEstateFinancesProps> = ({ realEstate }) => {
  const { t } = useRealEstateTranslation();

  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanLaptop), [isLowerThanLaptop, theme]);

  const {
    defaultFinancialModel,
    evaluationRate,
    financialModel,
    isPaymentCash,
    loanDown,
    loanDuration,
    loanInterestRate,
    shortTermNightlyRent,
    resellingYear,
    setEvaluationRate,
    setIsPaymentCash,
    setLoanDown,
    setLoanDuration,
    setLoanInterestRate,
    setResellingYear,
    setWithBrokerageFees,
    setWithPropertyManagement,
    setWithPremiumPropertyManagement,
    setAnnualAvgOccupancyInMonths,
    setShortTermNightlyRent,
    setShortTermOccupancyRate,
    setWithShortTermRental,
    withBrokerageFees,
    withPropertyManagement,
    withPremiumPropertyManagement,
    withShortTermRental,
    loading,
  } = useFinancialModel(realEstate);

  const toggleShortTermRental = (value: boolean) => {
    if (value) {
      trackSwitchToShortTermRental('finances');
    }
    setWithShortTermRental(value);
  };

  // TODO: Feature deactivited for now #removeShortTermRental
  // const showShortTermSwitch = !!realEstate.project.renting.shortTerm;
  const showShortTermSwitch = false;

  if (loading || !defaultFinancialModel || !financialModel) {
    return <View style={{ paddingHorizontal: theme.spacing.SIZE_05 }} />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.titleContainer}>
          <Text textStyle="Headline1Medium">{t('shared.financialTool.financialModel')}</Text>

          {!!showShortTermSwitch && (
            <ShortTermRentalSwitch
              withShortTermRental={withShortTermRental}
              toggleShortTermRental={toggleShortTermRental}
            />
          )}
        </View>

        <RealEstateFinancialSummary
          financialModel={financialModel}
          realEstateId={realEstate.id}
          withShortTermRental={withShortTermRental}
        />

        <RealEstateFinancialInvestment
          realEstate={realEstate}
          financialModel={financialModel}
          withBrokerageFees={withBrokerageFees}
          setWithBrokerageFees={setWithBrokerageFees}
          isPaymentCash={isPaymentCash}
        />

        <RealEstateFinancialFunding
          realEstate={realEstate}
          financialModel={financialModel}
          defaultFinancialModel={defaultFinancialModel}
          isPaymentCash={isPaymentCash}
          loanDown={loanDown}
          setLoanDown={setLoanDown}
          loanDuration={loanDuration}
          loanInterestRate={loanInterestRate}
          setLoanInterestRate={setLoanInterestRate}
          setLoanDuration={setLoanDuration}
          setIsPaymentCash={setIsPaymentCash}
        />
      </View>

      <View style={styles.section}>
        <View style={styles.titleContainer}>
          <Text textStyle="Headline1Medium">{t('shared.financialTool.enrichmentProjection')}</Text>

          {!!showShortTermSwitch && (
            <ShortTermRentalSwitch
              withShortTermRental={withShortTermRental}
              toggleShortTermRental={toggleShortTermRental}
            />
          )}
        </View>

        <RealEstateEnrichmentProjectionSummary
          enrichmentCoeficient={financialModel?.resellingInvestmentCapitalMultiplier}
          enrichmentResellingYear={financialModel?.resellingNetEnrichment}
          resellingYear={resellingYear}
          resellingYearCashFlow={financialModel?.resellingAvaibleCashFlow}
          resellingCumulativeCashFlow={financialModel?.resellingCumulativeCashFlow}
          realEstateId={realEstate.id}
          withShortTermRental={withShortTermRental}
        />

        <RealEstateEnrichmentProjection
          realEstateId={realEstate.id}
          resellingYear={resellingYear}
          setResellingYear={setResellingYear}
          evaluationRate={evaluationRate}
          trendingAppreciation={realEstate.trending?.appreciation}
          setEvaluationRate={setEvaluationRate}
          resellingPrice={financialModel?.resellingYearPrice}
          bankCapitalStillDue={financialModel?.resellingCapitalStillDue}
          resellingYearCashFlow={financialModel?.resellingAvaibleCashFlow}
          loanDownAmount={financialModel?.loanDownPayment}
          cumulativeCashFlow={financialModel?.resellingCumulativeCashFlow}
          enrichementResellingYear={financialModel?.resellingNetEnrichment}
        />
      </View>

      <View style={styles.section}>
        <View style={styles.titleContainer}>
          <Text textStyle="Headline1Medium">{t('shared.financialTool.analysisTitle')}</Text>

          {!!showShortTermSwitch && (
            <ShortTermRentalSwitch
              withShortTermRental={withShortTermRental}
              toggleShortTermRental={toggleShortTermRental}
            />
          )}
        </View>

        <RealEstateFinancialAnalysisSummary
          financialModel={financialModel}
          financialModelVersion={realEstate.financialModelVersion}
          realEstateId={realEstate.id}
          withShortTermRental={withShortTermRental}
        />
        <RealEstateFinancialAnnualIncomes
          annualTotalIncome={financialModel.annualTotalIncome}
          annualCharges={realEstate.annual.charges || 0}
          annualRentIncome={financialModel.annualRentIncome}
          annualAvgOccupancyInMonths={financialModel.annualTurnoverIndice}
          countryCode={realEstate.building.address.countryCode}
          financialModelVersion={realEstate.financialModelVersion || 1}
          isFurnished={realEstate.project.furnishing.isFurnished ?? true}
          realEstateId={realEstate.id}
          shortTermNightlyRent={
            shortTermNightlyRent ?? (realEstate.project.renting.shortTerm?.nightlyRent || 0)
          }
          withShortTermRental={withShortTermRental}
          shortTermOccupancyRate={realEstate.project.renting.shortTerm?.occupancyRate}
          setAnnualAvgOccupancyInMonths={setAnnualAvgOccupancyInMonths}
          setShortTermNightlyRent={setShortTermNightlyRent}
          setShortTermOccupancyRate={setShortTermOccupancyRate}
        />
        <RealEstateFinancialAnnualCharges
          realEstate={realEstate}
          financialModel={financialModel}
          withPropertyManagement={withPropertyManagement}
          withPremiumPropertyManagement={withPremiumPropertyManagement}
          setWithPropertyManagement={setWithPropertyManagement}
          setWithPremiumPropertyManagement={setWithPremiumPropertyManagement}
          withShortTermRental={withShortTermRental}
        />
        <RealEstateFinancialResults
          financialModel={financialModel}
          withShortTermRental={withShortTermRental}
        />
      </View>

      <InvestmentWarning />
    </View>
  );
};
