import React from 'react';

import { TextStyle, View, ViewStyle } from 'react-native';

import { Checkbox } from '@app/ui/atoms/checkbox';
import { Icon } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';

import { getCheckboxTileStyle } from './CheckboxTile.styles';
import { CheckboxTileType, CheckboxTileVariantType } from './CheckboxTile.types';

export const CheckboxTile: React.FC<CheckboxTileType> = ({
  label,
  iconName,
  disabled = false,
  checked = false,
  variant = CheckboxTileVariantType.Default,
  onChange,
}) => {
  const isVariantDefault = variant === CheckboxTileVariantType.Default;
  const theme = useTheme();

  const handleOnChange = () => {
    onChange(!checked);
  };

  return (
    <Pressable testID="checkbox-tile-test-id" disabled={disabled} onPress={handleOnChange}>
      {({ hovered }) => {
        const style = getCheckboxTileStyle({
          checked,
          disabled,
          hovered,
          theme,
          variant,
        });
        return (
          <View style={[style.container, style.containerColor]}>
            <View style={style.checkbox}>
              <Checkbox disabled={disabled} checked={checked} />
            </View>
            <View style={style.labelContainer as ViewStyle}>
              <View style={style.iconStyle}>
                <Icon
                  disabled={disabled}
                  size={theme.spacing.SIZE_07}
                  name={iconName}
                  color={style.textStyle.color}
                />
              </View>
              <Text
                numberOfLines={isVariantDefault ? 1 : 2}
                style={style.textStyle as TextStyle}
                textStyle="Button1"
              >
                {label}
              </Text>
            </View>
          </View>
        );
      }}
    </Pressable>
  );
};

export { CheckboxTileType };
