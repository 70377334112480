import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgEye = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12.183 15.722c1.25 0 2.312-.437 3.188-1.312.874-.875 1.312-1.938 1.312-3.188 0-1.25-.438-2.313-1.312-3.188-.876-.875-1.938-1.312-3.188-1.312-1.25 0-2.313.437-3.188 1.312s-1.312 1.938-1.312 3.188c0 1.25.437 2.313 1.312 3.188s1.938 1.312 3.188 1.312zm0-1.8c-.75 0-1.388-.263-1.912-.788a2.601 2.601 0 01-.788-1.912c0-.75.262-1.388.788-1.913a2.603 2.603 0 011.912-.787c.75 0 1.387.262 1.913.787.524.525.787 1.163.787 1.913s-.263 1.387-.787 1.912a2.604 2.604 0 01-1.913.788zm0 4.8c-2.317 0-4.434-.612-6.35-1.837-1.917-1.225-3.367-2.88-4.35-4.963a.812.812 0 01-.1-.313 2.917 2.917 0 010-.775.812.812 0 01.1-.312c.983-2.083 2.433-3.737 4.35-4.962 1.916-1.225 4.033-1.838 6.35-1.838 2.316 0 4.433.613 6.35 1.838 1.916 1.225 3.366 2.879 4.35 4.962.05.083.083.187.1.312a2.937 2.937 0 010 .775.813.813 0 01-.1.313c-.984 2.083-2.434 3.738-4.35 4.963-1.917 1.225-4.034 1.837-6.35 1.837zm0-2a9.545 9.545 0 005.188-1.488 9.77 9.77 0 003.612-4.012 9.777 9.777 0 00-3.612-4.013 9.55 9.55 0 00-5.188-1.487 9.55 9.55 0 00-5.188 1.487 9.777 9.777 0 00-3.612 4.013 9.77 9.77 0 003.612 4.012 9.545 9.545 0 005.188 1.488z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgEye;
