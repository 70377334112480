/* eslint-disable max-len */
import { useCallback, useEffect, useMemo } from 'react';

import { UseFormReturn, useForm } from 'react-hook-form';

import { PropertyChangesFilter } from '@app/services/tracking/trackTracking';
import { useMe } from '@app/shared/hooks/useMe';
import { getDefaultFormValues } from '@app/features/search-engine/compounds/search-engine-filters/utils/get-default-form-values';
import { preferencesToFilterMapper } from '@app/features/search-engine/compounds/search-engine-filters/utils/preferences-to-filter.mapper';
import { getFilterChanges } from '@app/features/search-engine/compounds/search-engine-filters/utils/get-filter-changes';
import { getInitialFormValues } from '@app/features/search-engine/compounds/search-engine-filters/utils/get-initial-form-values';

import {
  SearchEngineFilterForm,
  SearchEngineFilterFormProps,
  SearchEngineSource,
} from '../searchEngine.types';
import { FiltersStateProps } from './useGetSearchEngineDatas';
import { SearchEngineSortStrategy } from '../compounds/search-engine-filters/search-engine-sort/SearchEngineSort.types';

export interface UseSearchEngineFiltersProps {
  filter: { defaultValues: SearchEngineFilterForm };
  filterCount: number;
  form: UseFormReturn<SearchEngineFilterForm>;
  clearForm: () => void;
  resetSearchEngineFilters: (source: SearchEngineSource) => void;
  updateSearchEngineFilters: (
    formValues: SearchEngineFilterForm,
    source?: SearchEngineSource
  ) => void;
}

export const useSearchEngineFilters = (
  setFilters: (args: FiltersStateProps) => void,
  filters?: FiltersStateProps
): UseSearchEngineFiltersProps => {
  const { customer, stateOfCustomer } = useMe();

  const { filtersField } = filters || {};

  const hasFilter = Boolean(filters && Object.keys(filtersField).length);

  const filter: SearchEngineFilterFormProps = useMemo(
    () =>
      hasFilter
        ? {
            ...filters.filtersField,
            capacity: customer.searchMandate?.capacity,
          }
        : customer
        ? preferencesToFilterMapper({
            ...customer.preferences,
            capacity: customer.searchMandate?.capacity,
          })
        : {},
    [customer, filters?.filtersField, hasFilter]
  );

  const filterChanges = useMemo<PropertyChangesFilter>(() => getFilterChanges(filter), [filter]);

  const filterCount = useMemo(
    () => Object.values(filterChanges).reduce((acc, value) => acc + +value, 0),
    [filterChanges]
  );

  const defaultValues = useMemo<SearchEngineFilterForm>(
    () =>
      getDefaultFormValues({
        capacity: filter.capacity,
        sortStrategy: !customer.searchMandate?.valid
          ? SearchEngineSortStrategy.NetReturnDesc
          : SearchEngineSortStrategy.LastPublishedAtDesc,
      }),
    [filter.capacity, customer.searchMandate?.valid]
  );

  const initialValues = useMemo<SearchEngineFilterForm>(
    () => getInitialFormValues(filter, defaultValues),
    [defaultValues, filter]
  );

  const form = useForm<SearchEngineFilterForm>({
    defaultValues: initialValues,
    mode: 'all',
  });

  useEffect(() => {
    form.reset(initialValues);
  }, [initialValues]);

  const updateSearchEngineFilters = useCallback(
    (formValues: SearchEngineFilterForm, source?: SearchEngineSource, resetMode?: boolean) => {
      setFilters({ filtersField: formValues, resetMode, source: source });
      form.reset(formValues);
    },
    [setFilters, form]
  );

  const resetSearchEngineFilters = useCallback(
    (source: SearchEngineSource) => {
      const resetFormValues = {
        ...defaultValues,
        country:
          stateOfCustomer === 'postSignedMandate' ? initialValues.country : defaultValues.country,
        regionList: [],
        zones: [],
      };

      updateSearchEngineFilters(resetFormValues, source, true);
    },
    [stateOfCustomer, defaultValues, initialValues.country, updateSearchEngineFilters]
  );

  const clearForm = useCallback(() => {
    form.reset(initialValues);
  }, [initialValues, form]);

  return {
    clearForm,
    filter: {
      ...filter,
      defaultValues: initialValues,
    },
    filterCount,
    form,
    resetSearchEngineFilters,
    updateSearchEngineFilters,
  };
};
