import * as React from 'react';

import Svg, { SvgProps, G, Mask, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const Subway = ({ color, ...props }: SvgProps) => (
  <Svg width={50} height={50} viewBox="3 1 50 50" fill="none" {...props}>
    <G>
      <Mask id="b" fill="#fff">
        <Path
          fillRule="evenodd"
          d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
          clipRule="evenodd"
        />
      </Mask>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="m30.12 40.803-.123-.992-.323.04-.238.222.684.73Zm-4.778-.075.683-.73-.224-.21-.304-.048-.155.988ZM27.772 43l-.684.73.683.64.683-.64-.683-.73ZM43 25.967c0 7.031-5.61 12.928-13.003 13.844l.245 1.985C38.52 40.77 45 34.122 45 25.966h-2ZM28 12c8.348 0 15 6.315 15 13.967h2C45 17.087 37.325 10 28 10v2ZM13 25.967C13 18.315 19.652 12 28 12v-2c-9.325 0-17 7.087-17 15.967h2ZM25.497 39.74C18.355 38.623 13 32.837 13 25.967h-2c0 7.967 6.187 14.498 14.188 15.749l.309-1.976Zm2.957 2.53-2.429-2.272-1.366 1.46 2.43 2.272 1.365-1.46Zm.982-2.197-2.348 2.197 1.366 1.46 2.349-2.197-1.367-1.46Z"
        mask="url(#b)"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="M21.483 18.9c.22 0 .447.06.683.18.251.105.44.247.565.427l5.72 8.402-1.176-.044 5.862-8.358c.298-.405.682-.607 1.153-.607.377 0 .715.127 1.013.382.298.255.447.584.447.988v13.01c0 .389-.133.718-.4.988-.267.255-.62.382-1.06.382-.44 0-.8-.127-1.083-.382-.266-.27-.4-.6-.4-.989V22.697l.918.202-4.802 6.988a1.9 1.9 0 0 1-.542.427c-.204.12-.416.172-.636.157a1.145 1.145 0 0 1-.635-.157 2.024 2.024 0 0 1-.518-.427L22.095 23.1l.612-1.19v11.368c0 .39-.125.72-.376.989-.251.255-.581.382-.99.382-.391 0-.713-.127-.964-.382-.251-.27-.377-.6-.377-.989V20.27c0-.374.141-.696.424-.966.298-.27.651-.404 1.06-.404Z"
      />
    </G>
    <Defs></Defs>
  </Svg>
);
export { Subway };
