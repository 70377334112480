import React, { useCallback, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, useResponsive } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { trackPositioningSeeMore } from '@app/services/tracking/trackTracking';

import { getMissingDocumentList } from '../utils/getMissingDocumentList.utils';
import { MissingDocumentModal } from './MissingDocumentModal';
import { PositioningModal } from './PositioningModal';

interface RealEstateInterestedButtonProps {
  realEstateId: string;
}

export const RealEstateInterestedButton: React.FC<RealEstateInterestedButtonProps> = ({
  realEstateId,
}): React.ReactElement => {
  const { t } = useTranslation();
  const { customer } = useMe();
  const { isNativeOrResponsive, isLowerThanDesktop } = useResponsive();
  const isNativeOrLowerThanDesktop = isNativeOrResponsive || isLowerThanDesktop;

  const { openModal } = useContext(ModalContext);
  const missingDocumentList = useMemo(() => getMissingDocumentList(customer), [customer]);

  const missingDocumentModalDelay = 500;

  const openMissingDocumentModal = useCallback(() => {
    if (missingDocumentList.length) {
      setTimeout(
        () =>
          openModal(
            ModalKey.MissingDocument,
            <MissingDocumentModal documentList={missingDocumentList} />,
            {
              position: isNativeOrLowerThanDesktop ? 'bottom' : 'center',
              title: t('positioning.missingDocumentModal.title'),
            }
          ),
        missingDocumentModalDelay
      );
    }
  }, [missingDocumentList, isNativeOrLowerThanDesktop, openModal, t]);

  const handleOnClick = useCallback(() => {
    openModal(
      ModalKey.Positioning,
      <PositioningModal
        realEstateId={realEstateId}
        callbackAfterConfirm={openMissingDocumentModal}
      />,
      {
        title: t('positioning.positioningModal.title'),
        webSize: 's',
      }
    );
    trackPositioningSeeMore(realEstateId);
  }, [realEstateId]);

  return (
    <Button
      onPress={handleOnClick}
      testID="button-interested-without-suggestion"
      iconName="ThumbsUp"
      iconPlacement="left"
      wrapperStyle={{
        ...(isNativeOrLowerThanDesktop && { flex: 1 }),
      }}
    >
      {t('suggestions.suggestionCard.actions.interested')}
    </Button>
  );
};
