import { useEffect } from 'react';

import { ScaledSize } from 'react-native';

import { useAnimation } from '@app/ui/utils/useAnimation';
import { getColorWithOpacity, palette } from '@app/ui/theme/palette';

import type { ModalPosition } from './Modal.types';

const shouldEnableFade = (position: ModalPosition) =>
  position === 'center' || position === 'fullscreen';

const isSlideVertical = (position: ModalPosition) => position === 'top' || position === 'bottom';

const isSlideNegative = (position: ModalPosition) => position === 'left' || position === 'top';

export const useModalSlideAnimation = (
  visible: boolean,
  position: ModalPosition,
  onPressClose: () => void,
  dimensions: ScaledSize
) => {
  const windowSize = isSlideVertical(position) ? dimensions.height : dimensions.width;
  const { interpolate, setIsOn } = useAnimation(visible, 400);
  const [translate, opacity, fade] = interpolate(
    isSlideNegative(position) ? [-windowSize, 0] : [windowSize, 0],
    [0, 1],
    [getColorWithOpacity(palette.base.black, 0), getColorWithOpacity(palette.base.black, 20)]
  );

  useEffect(() => setIsOn(visible), [visible]);

  const onClose = () => {
    setIsOn(false);
    setTimeout(onPressClose, 400);
  };

  const fadeAnimation = { backgroundColor: fade };
  if (shouldEnableFade(position)) {
    return { animation: { opacity }, fadeAnimation, onClose };
  }

  return {
    animation: {
      transform: [
        isSlideVertical(position) ? { translateY: translate } : { translateX: translate },
      ],
    },
    fadeAnimation,
    onClose,
  };
};
