import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgOm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="om_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#om_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20z" fill="#138E16" />
        <Path d="M0 20h9.333v-6.667H28V6.667H9.333V0H0v20z" fill="#ED2B35" />
        <Path
          d="M4.667 4.712L3.51 6.402l.6-1.958-2.043.15 1.906-.752-1.391-1.505L4.358 3.36l.309-2.026.308 2.026 1.777-1.022L5.36 3.842l1.905.751-2.043-.15.6 1.96-1.156-1.691z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgOm;
