import React, { FC, PropsWithChildren, useCallback, useReducer } from 'react';

import { BannerContextProps } from './BannerManager.types';
import { bannerManagerReducer, initialState } from './state/reducer';
import { BannerManagerActions, BannerManagerState } from './state/actions';

export const BannerContext = React.createContext<BannerContextProps>({
  isDisplayed: false,
});

export const BannerManagerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(bannerManagerReducer, initialState);
  const { isDisplayed, component, callbackClose } = state;

  const openBanner = useCallback(
    (key, bannerComponent, options) => {
      if (isDisplayed) {
        return;
      }

      dispatch({
        payload: {
          bannerKey: key,
          callbackClose: options?.callbackClose,
          component: bannerComponent,
          isDisplayed: true,
        } as BannerManagerState,
        type: BannerManagerActions.OPEN_BANNER,
      });
    },
    [isDisplayed]
  );

  const closeBanner = useCallback(
    params => {
      dispatch({ type: BannerManagerActions.CLOSE_BANNER });
      callbackClose?.(params);
    },
    [callbackClose]
  );

  const value = {
    closeBanner,
    component,
    isDisplayed,
    openBanner,
  };

  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};
