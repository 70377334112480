import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCompare = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M14.828 12.914l-3.6-3.6a.871.871 0 01-.212-.325 1.099 1.099 0 01-.063-.375c0-.133.02-.258.063-.375a.871.871 0 01.212-.325l3.6-3.6c.2-.2.433-.3.7-.3.266 0 .5.1.7.3.2.2.3.438.3.712 0 .276-.1.513-.3.713l-1.875 1.875h6.175c.283 0 .52.096.712.287.192.192.288.43.288.713 0 .284-.096.52-.288.712a.965.965 0 01-.712.288h-6.175l1.875 1.875c.2.2.3.434.3.7 0 .267-.1.5-.3.7-.2.2-.43.309-.687.325-.259.017-.497-.083-.713-.3zm-8 5.975c.2.2.433.304.7.312.266.009.5-.087.7-.287l3.6-3.6c.1-.1.17-.208.213-.325.041-.116.062-.241.062-.375 0-.133-.021-.258-.062-.375a.883.883 0 00-.213-.325l-3.6-3.6a.96.96 0 00-.7-.3c-.267 0-.5.1-.7.3-.2.2-.3.438-.3.712 0 .276.1.513.3.713l1.875 1.875H2.528a.967.967 0 00-.712.287.968.968 0 00-.288.713c0 .284.096.52.288.712a.965.965 0 00.712.288h6.175L6.828 17.49c-.2.2-.3.434-.3.7 0 .267.1.5.3.7z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCompare;
