import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgSi = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="si_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#si_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.333h28V6.667H9.244V5.333s-.94-.666-1.91-.666c-.971 0-2 .666-2 .666v1.334H0v6.666zM8.667 8A1.333 1.333 0 116 8a1.333 1.333 0 012.667 0z"
          fill="#0C47B7"
        />
        <G filter="url(#si_svg__filter0_d_4223_11818)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#E53B35" />
        </G>
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgSi;
