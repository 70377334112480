/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { View } from 'react-native';
import { Controller } from 'react-hook-form';

import {
  ActionButton,
  DROPDOWN_CLOSE_OVERLAY,
  FilterBudgetDropdown,
  FilterListSelector,
  Input,
  useTheme,
} from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { inputToNumber, numberToInput } from '@app/utils/input-formatter';
import { formatOption, formatSelectorValues, selectValue } from '@app/utils/selector.utils';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { isWeb } from '@app/constants/platform';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { FilterGroupListSelector } from '@app/ui/organisms/filter-group-list-selector/FilterGroupListSelector';

import { getStyles } from './SearchEngineFiltersHorizontal.styles';
import { useSearchEngineFormFilter } from '../hooks/useSearchEngineFormFilter';
import {
  FILTER_NAME_TRACKING_KEY,
  getPropertyTypeList,
} from './SearchEngineFiltersHorizontal.utils';
import {
  SearchEngineFilterFormHorizontal,
  SearchEngineFiltersHorizontalProps,
} from './SearchEngineFiltersHorizontal.types';
import { SearchEngineSort } from '../search-engine-sort/SearchEngineSort';
import { getZoneGroupOptions } from '../utils/get-zone-group-options.utils';

export const SearchEngineFiltersHorizontal: React.FC<SearchEngineFiltersHorizontalProps> = ({
  regionListByCountry,
  onSubmit,
  onSubmitError,
  onFilterShow,
  form,
  defaultValues,
  filterCount,
  capacity,
  zoneLocationListByCountry,
}) => {
  const [hasBudgetError, setHasBudgetError] = useState<boolean>(false);
  const theme = useTheme();
  const styles = getStyles(theme);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    reset,
    watch,
    setValue,
    resetField,
    trigger,
    setError,
    clearErrors,
  } = form;

  // TODO: Remove support for others countries #countryRemoval
  useEffect(() => {
    setValue('country', AvailableCountryCode.Fr);
  }, []);

  const { budgetMinRules, budgetMaxRules, regionOptionList, regionListValue } =
    useSearchEngineFormFilter(
      [AvailableCountryCode.Fr],
      regionListByCountry,
      capacity,
      getValues,
      watch
    );

  const valueMin = watch('budgetMin');
  const valueMax = watch('budgetMax');
  const valueCountry = watch('country');

  const budgetValue = useMemo(
    () => ({
      max: { label: t('propertiesPreferences.max'), value: valueMax ? valueMax.toString() : null },
      min: { label: t('propertiesPreferences.min'), value: valueMin ? valueMin.toString() : null },
    }),
    [t, valueMax, valueMin]
  );

  const submit = useCallback(
    (closeList?: () => void, field?: SearchEngineFilterFormHorizontal, resetMode = false) => {
      handleSubmit(
        v =>
          Promise.resolve(onSubmit(v, FILTER_NAME_TRACKING_KEY[field], resetMode))
            .catch(() => reset(defaultValues))
            .finally(() => {
              if (isWeb()) {
                window.scrollTo({ behavior: 'smooth', top: 0 });
              }
            }),
        v => onSubmitError?.(v)
      )();

      closeList?.();
    },
    [defaultValues, handleSubmit, onSubmit, onSubmitError, reset]
  );

  const cancel = useCallback(
    (closeList, field) => {
      setValue(field, []);
      if (field === 'country') {
        setValue('regionList', []);
        setValue('zones', []);
      }
      submit(closeList, field, true);
    },
    [setValue, submit]
  );

  const propertyTypeList = useMemo(() => getPropertyTypeList(t), [t]);

  const onCallbackClose = useCallback(
    (source, field) => {
      const hasError = errors && Object.keys(errors).length;
      const canSubmit =
        source === DROPDOWN_CLOSE_OVERLAY && Object.keys(dirtyFields).length && !hasError;

      if (canSubmit) {
        submit(undefined, field);
      } else if (hasError) {
        Object.keys(errors).forEach((key: keyof SearchEngineFilterForm) => {
          resetField(key, { defaultValue: defaultValues[key] });
        });
      }
    },
    [defaultValues, dirtyFields, errors, resetField, submit]
  );

  const commonFilterProps = {
    buttonLabel: {
      ghost: t('propertiesPreferences.erase'),
      primary: t('shared.show'),
    },
    onPrimaryClick: submit,
  };

  const getActionProps = useCallback(
    (field: SearchEngineFilterFormHorizontal) => {
      return {
        onCallbackClose: sourceClose => onCallbackClose(sourceClose, field),
        onGhostClick: closeList => cancel(closeList, field),
        onPrimaryClick: closeList => submit(closeList, field),
      };
    },
    [submit, onCallbackClose, cancel]
  );

  const checkBudget = useCallback(async () => {
    const isValidBudgetMin = await trigger('budgetMin');
    const isValidBudgetMax = await trigger('budgetMax');

    return isValidBudgetMin && isValidBudgetMax && !hasBudgetError;
  }, [hasBudgetError, trigger]);

  const cancelBudget = useCallback(
    closeList => {
      setValue('budgetMin', null);
      setValue('budgetMax', capacity ?? null);

      submit(closeList, SearchEngineFilterFormHorizontal.BUDGET, true);
    },
    [capacity, setValue, submit]
  );

  const onPrimaryClickBudget = useCallback(
    async closeList => {
      const validBudget = await checkBudget();
      validBudget && submit(closeList, SearchEngineFilterFormHorizontal.BUDGET);
    },
    [checkBudget, submit]
  );

  const onCallbackCloseBudget = useCallback(
    async source => {
      if (source !== DROPDOWN_CLOSE_OVERLAY) {
        return;
      }

      const validBudget = await checkBudget();

      if (validBudget) {
        submit(undefined, SearchEngineFilterFormHorizontal.BUDGET);
        return;
      }

      resetField('budgetMin', { defaultValue: defaultValues['budgetMin'] });
      resetField('budgetMax', { defaultValue: defaultValues['budgetMax'] });
    },
    [checkBudget, defaultValues, resetField, submit]
  );

  /** When one of the fields is in error, it rerenders but it doesn't rerender the whole component,
   * so the dropdown doesn't have the information that one of its fields is in error.
   * So you have to pass this information in the state to start a rerender*/
  const checkBudgetError = useCallback(() => {
    const hasError = !!(errors.budgetMax || errors.budgetMin);
    hasError != hasBudgetError && setHasBudgetError(hasError);
  }, [errors.budgetMax, errors.budgetMin, hasBudgetError]);

  const onChangeBudgetMax = (v, onChange) => {
    const newValue = inputToNumber(v);

    const budgetMin = watch('budgetMin');

    if (newValue < budgetMin) {
      setError('budgetMin', {
        message: t('propertiesPreferences.formErrors.budgetMinSupMax'),
        type: 'custom',
      });
    } else {
      clearErrors('budgetMin');
    }
    return onChange(newValue);
  };

  const showZoneFilter = valueCountry === AvailableCountryCode.Fr;

  return (
    <View style={styles.container}>
      <View style={styles.filters}>
        {showZoneFilter ? (
          <View style={styles.selector} testID="zones-selector">
            <Controller
              control={control}
              key="zone-controller"
              render={({ field: { onChange, value } }) => (
                <FilterGroupListSelector
                  {...commonFilterProps}
                  {...getActionProps(SearchEngineFilterFormHorizontal.ZONE)}
                  placeholder={t('searchEngine.filter.zone.label')}
                  optionGroups={getZoneGroupOptions({
                    regionZones: zoneLocationListByCountry[valueCountry] || [],
                    t,
                  })}
                  minWidth={375}
                  maxHeight={500}
                  selectAllLabel={t(`searchEngine.filter.zone.selectAll${valueCountry}`) as string}
                  unselectAllLabel={
                    t(`searchEngine.filter.zone.selectAll${valueCountry}`) as string
                  }
                  onChangeValues={onChange}
                  values={value}
                  multiple
                  formatActivatorValues={(placeholder, values) => ({
                    count: values.length,
                    valueString: placeholder,
                  })}
                />
              )}
              name="zones"
            />
          </View>
        ) : (
          <View style={styles.selector} testID="regions-selector">
            <Controller
              control={control}
              key="regions-controller"
              render={({ field: { onChange, value } }) => (
                <FilterListSelector
                  {...commonFilterProps}
                  placeholder={t('searchEngine.filter.region.label')}
                  values={formatSelectorValues(regionListValue(value, regionOptionList), value)}
                  options={formatOption({ optionList: regionOptionList, value })}
                  {...getActionProps(SearchEngineFilterFormHorizontal.REGION_LIST)}
                  onSelectValue={item =>
                    selectValue({ item, list: regionOptionList, onChange, value })
                  }
                  disabled={!regionOptionList.length}
                  multiple
                />
              )}
              name="regionList"
            />
          </View>
        )}

        <View style={styles.selector}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <FilterListSelector
                {...commonFilterProps}
                placeholder={t('propertyDescription.type')}
                values={formatSelectorValues(propertyTypeList, value)}
                options={formatOption({ optionList: propertyTypeList, value })}
                {...getActionProps(SearchEngineFilterFormHorizontal.TYPOLOGY)}
                onSelectValue={item =>
                  selectValue({
                    item,
                    list: propertyTypeList,
                    onChange,
                    value,
                  })
                }
                multiple
              />
            )}
            name="typology"
          />
        </View>

        <View style={styles.selector}>
          <FilterBudgetDropdown
            placeholder={t('propertyModals.budget')}
            {...commonFilterProps}
            onCallbackClose={onCallbackCloseBudget}
            onPrimaryClick={!hasBudgetError ? onPrimaryClickBudget : null}
            onGhostClick={closeList => cancelBudget(closeList)}
            values={budgetValue}
            minField={
              <Controller
                control={control}
                rules={budgetMinRules}
                render={({ field: { onChange, onBlur, value } }) => {
                  checkBudgetError();
                  return (
                    <Input
                      testID="search-engine-filter-budget-min--input"
                      errorMessageTestID="search-engine-filter-budget-min--input-error"
                      label={t('propertiesPreferences.minimalBudget')}
                      onBlur={onBlur}
                      onChangeText={v => onChange(inputToNumber(v))}
                      value={numberToInput(value)}
                      error={errors.budgetMin?.message}
                      keyboardType="numeric"
                    />
                  );
                }}
                name="budgetMin"
              />
            }
            maxField={
              <Controller
                control={control}
                rules={budgetMaxRules}
                render={({ field: { onChange, onBlur, value } }) => {
                  checkBudgetError();
                  return (
                    <Input
                      testID="search-engine-filter-budget-max--input"
                      errorMessageTestID="search-engine-filter-budget-max--input-error"
                      label={t('propertiesPreferences.maximalBudget')}
                      onBlur={onBlur}
                      onChangeText={v => onChangeBudgetMax(v, onChange)}
                      value={numberToInput(value)}
                      error={
                        errors.budgetMax?.message ||
                        (!!errors.budgetMin && errors.budgetMin.type === 'lessThanMax' && ' ')
                      }
                      keyboardType="numeric"
                    />
                  );
                }}
                name="budgetMax"
              />
            }
          />
        </View>

        <View style={[styles.selector, styles.allFilter]}>
          <ActionButton
            disabled={false}
            leftIconName="Filter"
            onPress={onFilterShow}
            testID="search-engine-filter-horizontal-filter-button"
            label={t('propertiesPreferences.allFilters')}
            size="m"
            active={!!filterCount}
            withSolidBorder
          />
        </View>
      </View>

      <SearchEngineSort
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        form={form}
        defaultValues={defaultValues}
      />
    </View>
  );
};
