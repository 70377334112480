import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Switch, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

interface ShortTermRentalSwitchProps {
  withShortTermRental: boolean;
  toggleShortTermRental: (value: boolean) => void;
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.tint.purple[50],
      borderRadius: theme.borderRadius.SIZE_05,
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
      justifyContent: 'space-between',
      padding: theme.spacing.SIZE_04,
      ...(isLowerThanLaptop && {
        width: '100%',
      }),
    },
    switch: {
      backgroundColor: theme.palette.system.primary[500],
    },
    text: {
      color: theme.palette.tint.purple[500],
    },
  });

export const ShortTermRentalSwitch: React.FC<ShortTermRentalSwitchProps> = ({
  toggleShortTermRental,
  withShortTermRental,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop, isNativeOrResponsive } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanLaptop), [isLowerThanLaptop, theme]);

  return (
    <View style={styles.container}>
      <Text textStyle="Body2" style={styles.text}>
        {isNativeOrResponsive
          ? t('realEstate.shortTermRental.switchAbbr')
          : t('realEstate.shortTermRental.switch')}
      </Text>

      <Switch checked={withShortTermRental} onCheck={toggleShortTermRental} style={styles.switch} />
    </View>
  );
};
