import React from 'react';

import { StyleSheet, View } from 'react-native';

import { IconButton, useTheme } from '@app/ui';

import { useNPSDisplay } from './useNPSDisplay.hook';
import { NPSContent } from './NPS-content';

export const NPSContainer: React.FC = () => {
  const { isVisible, category, onClose } = useNPSDisplay();
  const { spacing, borderRadius, palette } = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: palette.base.white,
      borderColor: palette.brand[1],
      borderRadius: borderRadius.SIZE_06,
      borderStyle: 'solid',
      borderWidth: 2,
      bottom: spacing.SIZE_07,
      boxShadow: `0 0 50px ${palette.neutral[400]}`,
      height: 300,
      padding: spacing.SIZE_05,
      position: 'absolute',
      right: spacing.SIZE_07,
      width: '500px',
    },
    header: { alignItems: 'flex-end' },
  });

  return (
    <>
      {!!isVisible && (
        <View style={styles.container}>
          <>
            <View style={styles.header}>
              <IconButton onPress={onClose} iconName="Cross" />
            </View>

            {!!category && <NPSContent category={category} />}
          </>
        </View>
      )}
    </>
  );
};
