import React from 'react';

import { View } from 'react-native';

import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';
import { Text } from '@app/ui/atoms/Text/Text';
import { RotatingChevron } from '@app/ui/atoms/RotatingChevron/RotatingChevron';

import { getPressableStyles, getStyles } from './SelectActivator.styles';
import { formatActivatorDefault } from '../counter-activator/CounterActivator.utils';
import { SelectActivatorProps } from './SelectActivator.types';

export { SelectActivatorProps };

export const SelectActivator: React.FC<SelectActivatorProps> = ({
  isDisabled,
  onPress,
  open,
  placeholder,
  values,
  helperText,
}) => {
  const theme = useTheme();
  const styles = getStyles({
    isDisabled,
    opened: open,
    theme,
  });
  const { valueString } = formatActivatorDefault(placeholder, values);

  return (
    <View style={styles.container}>
      <Pressable onPress={!isDisabled ? onPress : null} testID="Select-toggler">
        {({ hovered }) => {
          const pressableStyle = getPressableStyles({
            hovered,
            isDisabled,
            isPlaceholder: valueString === placeholder,
            opened: open,
            theme,
          });

          return (
            <View style={pressableStyle.inputContainer} testID="select-activator-input-container">
              <Text style={pressableStyle.text} numberOfLines={1} testID="Select-input">
                {valueString}
              </Text>
              <RotatingChevron
                opened={open}
                disabled={false}
                size={20}
                chevronType="ChevronDown"
                color={theme.palette.neutral[isDisabled ? 600 : 900]}
                style={pressableStyle.chevron}
              />
            </View>
          );
        }}
      </Pressable>
      <Text style={styles.helperText}>{helperText}</Text>
    </View>
  );
};
