import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  LayoutChangeEvent,
  LayoutRectangle,
  Modal as ModalRN,
  useWindowDimensions,
  View,
} from 'react-native';
import { debounce } from 'lodash';

import { useTheme } from '@app/ui/contexts/config';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { isNative } from '@app/constants/platform';

import { DropdownType } from './Dropdown.types';
import { getStyles } from './Dropdown.styles';
import { getListPosition, updateActivatorPosition } from './Dropdown.utils';
import { BottomSheet } from '../bottom-sheet/BottomSheet';

export { DropdownType };

export const DEFAULT_MEASURES = {
  height: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const DROPDOWN_CLOSE_OVERLAY = 'overlay';

export const DropdownV2: FC<DropdownType> = ({
  renderList,
  renderActivator,
  onCallbackClose,
  fitParent = true,
  maxWidth,
  maxHeight,
  minWidth,
  testID,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [listWidth, setListWidth] = useState<number>(0);

  const [activatorPosition, setActivatorPosition] = useState<LayoutRectangle | null>(
    DEFAULT_MEASURES
  );

  const theme = useTheme();

  const activatorRef = useRef<View>(null);

  const dimensions = useWindowDimensions();
  const { isLargeMobile } = useResponsive();

  const styles = getStyles({ dimensions, theme });
  const isModalMode = isNative() || isLargeMobile;

  const listPos = useMemo(
    () =>
      !isModalMode && isOpen && activatorPosition
        ? getListPosition({
            dimensions,
            fitParent,
            listWidth,
            maxHeight,
            maxWidth,
            minWidth,
            parentLayout: activatorPosition,
          })
        : {},
    [
      isModalMode,
      isOpen,
      activatorPosition,
      dimensions,
      fitParent,
      listWidth,
      maxHeight,
      maxWidth,
      minWidth,
    ]
  );

  const updateListPos = () => {
    updateActivatorPosition(activatorRef, activatorPosition, setActivatorPosition);
  };

  useEffect(() => {
    if (isNative()) {
      return;
    }

    const debounceScroll = debounce(updateListPos, 5);

    document.addEventListener('scroll', debounceScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', debounceScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateListPos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatorRef?.current, dimensions, isOpen]);

  const closeList = () => {
    onCallbackClose?.();
    setIsOpen(false);
  };

  const overlayClose = useCallback(() => {
    onCallbackClose?.(DROPDOWN_CLOSE_OVERLAY);
    setIsOpen(false);
  }, [onCallbackClose]);

  const openList = () => {
    setIsOpen(true);
  };

  const toggleList = () => {
    isOpen ? closeList() : openList();
  };

  const renderActivatorElement = () => {
    return (
      <View ref={activatorRef} testID="dropdown-activator">
        {renderActivator({
          closeList,
          isOpen,
          openList,
          toggleList,
        })}
      </View>
    );
  };

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;

      if (layout.width !== listWidth) {
        setListWidth(layout.width);
      }
    },
    [setListWidth, listWidth]
  );

  const renderListContainerWeb = () => {
    return isOpen ? (
      <ModalRN transparent visible={isOpen} animationType="fade" testID="Dropdown">
        <Pressable
          onPress={isOpen ? overlayClose : null}
          style={styles.overlay}
          testID="dropdown-overlay"
          withPressOpacity={false}
        >
          <View
            style={[styles.dropdownContainer, listPos]}
            onLayout={onLayout}
            testID="dropdown-container-list-web"
          >
            {renderList({
              closeList,
              isOpen,
              maxHeight: null,
              openList,
              toggleList,
            })}
          </View>
        </Pressable>
      </ModalRN>
    ) : null;
  };

  const renderListContainerModal = () => {
    return (
      <BottomSheet visible={isOpen} onPressClose={overlayClose} noPadding>
        <View style={styles.modalListContainer}>
          {renderList({
            closeList,
            isOpen,
            maxHeight: dimensions.height / 2,
            openList,
            toggleList,
          })}
        </View>
      </BottomSheet>
    );
  };

  const renderContainerList = isModalMode ? renderListContainerModal : renderListContainerWeb;

  return (
    <View testID={testID}>
      {renderActivatorElement()}
      {renderContainerList()}
    </View>
  );
};
