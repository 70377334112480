import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getHeaderPageContainerStyle = ({
  theme,
  isLowerThanTablet,
}: {
  theme: Theme;
  isLowerThanTablet: boolean;
}): ReturnType<typeof StyleSheet.create> => {
  return StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: 1,
      flexDirection: 'row',
      height: 56,
      paddingHorizontal: isLowerThanTablet ? 20 : 40,
      width: '100%',
    },
  });
};
