import { uniq } from 'lodash';

import { ModalManagerAction, ModalManagerActions, ModalManagerState } from './actions';

export const initialState = {
  component: null,
  excludedModals: [],
  isVisible: false,
  modalKey: undefined,
  position: undefined,
  title: undefined,
  webSize: undefined,
};

export const modalManagerReducer = (
  state: ModalManagerState,
  action: ModalManagerAction
): ModalManagerState => {
  switch (action.type) {
    case ModalManagerActions.SET_TITLE:
      return {
        ...state,
        title: action.payload.title,
      };

    case ModalManagerActions.OPEN_MODAL:
      return {
        ...state,
        callbackClose: action.payload.callbackClose,
        component: action.payload.component,
        excludedModals: uniq([...state.excludedModals, ...action.payload.excludedModals]),
        isVisible: action.payload.isVisible,
        modalKey: action.payload.modalKey,
        position: action.payload.position,
        title: action.payload.title,
        webSize: action.payload.webSize,
      };

    case ModalManagerActions.CLOSE_MODAL:
      return {
        ...state,
        component: null,
        isVisible: false,
        title: undefined,
      };

    case ModalManagerActions.CLEAR_EXCLUDED_MODALS:
      return {
        ...state,
        excludedModals: [],
      };

    case ModalManagerActions.SET_EXCLUDED:
      return {
        ...state,
        excludedModals: uniq([...state.excludedModals, ...action.payload.excludedModals]),
      };
    default:
      return state;
  }
};
