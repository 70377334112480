import React, { useEffect, useRef, useState } from 'react';

import { Animated, StyleSheet, View } from 'react-native';

import { Dot, DotSize } from '@app/ui/atoms/dot/Dot';
import { SizeWidth } from '@app/ui/atoms/dot/Dot.styles';

import { DotsListType } from './DotsList.types';
import { getStyle } from './DotsList.styles';
import { computeReducedDotSize } from './DotsList.utils';

export { DotsListType };

export const DotsList: React.FC<DotsListType> = ({
  nbDot,
  activeIndex,
  numberOfDotsDisplayed = nbDot,
  size = DotSize.M,
  ...dotProps
}) => {
  const dotArray = Array.from({ length: nbDot });
  const scrollX = useRef(new Animated.Value(0)).current;
  const [scrollIndex, setScrollIndex] = useState(0);

  const styles = getStyle({ numberOfDotsDisplayed, size });

  useEffect(() => {
    const dotOuterWidth = SizeWidth.L + 4;
    Animated.timing(scrollX, {
      duration: 150,
      toValue: dotOuterWidth * scrollIndex * -1,
      useNativeDriver: false,
    }).start();
  }, [scrollIndex, scrollX]);

  useEffect(() => {
    if (numberOfDotsDisplayed == nbDot || activeIndex < numberOfDotsDisplayed - 1) {
      setScrollIndex(0); // Shows the leftmost dots
      return;
    }

    if (activeIndex > nbDot - numberOfDotsDisplayed + 1) {
      setScrollIndex(nbDot - numberOfDotsDisplayed); // Shows the rightmost dots
      return;
    }

    setScrollIndex(activeIndex - Math.floor(numberOfDotsDisplayed / 2)); // Center the active dot
  }, [activeIndex, nbDot, numberOfDotsDisplayed]);

  const computeDotSize = (index: number) => {
    if (numberOfDotsDisplayed == nbDot) {
      return size;
    }

    const conditionalSize = (condition: boolean) =>
      condition ? computeReducedDotSize(size) : size;

    if (scrollIndex == 0) {
      // If the leftmost dots are shown
      return conditionalSize(Math.abs(index - scrollIndex) >= numberOfDotsDisplayed - 1);
    }

    if (scrollIndex == nbDot - numberOfDotsDisplayed) {
      // If the rightmost are shown
      return conditionalSize(Math.abs(index - nbDot + 1) >= numberOfDotsDisplayed - 1);
    }

    return conditionalSize(Math.abs(index - activeIndex) > 1);
  };

  return (
    <View style={styles.dotListContainer}>
      <Animated.View
        style={StyleSheet.flatten([styles.dotList, { transform: [{ translateX: scrollX }] }])}
      >
        {dotArray.map((_, i) => (
          <View key={i} style={styles.dotContainer}>
            <Dot active={activeIndex === i} size={computeDotSize(i)} {...dotProps} />
          </View>
        ))}
      </Animated.View>
    </View>
  );
};
