import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Spacer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { isNative, isWeb } from '@app/constants/platform';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    ...(isNative() && {
      container: {
        flex: 1,
        marginBottom: theme.spacing.SIZE_09,
      },
    }),
    textColor: { color: theme.palette.neutral[isWeb() ? 900 : 700] },
  });

export const InvestmentWarning: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <Text style={styles.textColor} textStyle={isLowerThanLaptop ? 'Caption1' : 'Body3'}>
        {t('investmentWarning.warningMessage')}
      </Text>
      <Spacer height={isMobile ? theme.spacing.SIZE_12 : theme.spacing.SIZE_08} />
    </View>
  );
};
