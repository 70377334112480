import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { StyleSheet, View } from 'react-native';

import { useResponsive } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { useGetPandadocLinkLazyQuery } from '@app/libs/apollo/introspection';
import { OverviewModal } from '@app/shared/components/Modals/in-app-signature/components/OverviewModal';
import { SignInModal } from '@app/shared/components/Modals/in-app-signature/components/SignatureModal';
import { trackMandateSignatureModalClosed } from '@app/services/tracking/trackTracking';
import {
  InAppSignatureModalCloseReason,
  SignatureModalState,
} from '@app/shared/components/Modals/in-app-signature/InAppSignatureModal.types';
import { CongratsModal } from '@app/shared/components/Modals/in-app-signature/components/CongratsModal';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { SignMandateReminderType } from '@app/shared/hooks/useSignMandateState/useSignMandateState.hook.types';
import { storage } from '@app/services/storage/storage';
import { getFullName } from '@app/utils/getFullName';
import { navigationRef } from '@app/navigation/navigationRef';
import { PrivateNavigatorRoutes } from '@app/navigation/types/routes';
import { BannerContext } from '@app/shared/contexts/banner-manager/BannerManagerProvider';
import { getApolloClient } from '@app/libs/apollon/apollonClient';

const getStyles = ({ isLowerThanLaptop }: { isLowerThanLaptop: boolean }) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: 756,
      ...(isLowerThanLaptop && { width: 'auto' }),
    },
    content: { flexGrow: 1 },
  });

export const InAppSignatureModal: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { closeBanner } = useContext(BannerContext);
  const { cache } = getApolloClient();

  const {
    customer: { owner, searchMandate, id: customerId },
  } = useMe();
  const { isLowerThanLaptop } = useResponsive();

  const [isPremandate, setIsPremandate] = useState<boolean>(
    searchMandate?.preMandateStatus === 'PENDING'
  );
  const [getPandadocLink, { data, loading, refetch }] = useGetPandadocLinkLazyQuery();
  const [modalState, setModalState] = useState<SignatureModalState>(SignatureModalState.Overview);
  const ref = useRef<SignatureModalState>(modalState);

  const styles = useMemo(() => getStyles({ isLowerThanLaptop }), [isLowerThanLaptop]);

  const handleNextStep = useCallback(() => {
    setModalState(currentState => {
      let nextStep;
      let source;
      switch (currentState) {
        case SignatureModalState.Overview:
          nextStep = SignatureModalState.SignIn;
          source = InAppSignatureModalCloseReason.NextStep;
          getPandadocLink();
          break;
        case SignatureModalState.SignIn:
          nextStep = SignatureModalState.Signed;
          source = InAppSignatureModalCloseReason.NextStep;
          break;
        case SignatureModalState.Signed:
          nextStep = SignatureModalState.Congrats;
          source = InAppSignatureModalCloseReason.NextStep;
          if (isPremandate) {
            cache.modify({
              fields: { searchMandate: mandate => ({ ...mandate, preMandateStatus: 'ACTIVE' }) },
              id: cache.identify({ __typename: 'Customer', id: customerId }),
            });
            setIsPremandate(false);
            refetch();
            nextStep = SignatureModalState.SignIn;
          }
          break;
        case SignatureModalState.Congrats:
          nextStep = SignatureModalState.Congrats;
          source = InAppSignatureModalCloseReason.CompleteStep;
          trackMandateSignatureModalClosed(source, currentState);
          closeModal?.({
            closeSource: InAppSignatureModalCloseReason.CompleteStep,
            modalState: SignatureModalState.Congrats,
          });
      }

      ref.current = nextStep;
      return nextStep;
    });
  }, [closeModal, getPandadocLink, isPremandate]);

  const actionsOnClose = (currentRef: SignatureModalState) => {
    if (currentRef && currentRef !== SignatureModalState.Congrats) {
      trackMandateSignatureModalClosed(
        InAppSignatureModalCloseReason.CrossOrOutsideClick,
        currentRef
      );
      closeModal?.({
        closeSource: InAppSignatureModalCloseReason.CrossOrOutsideClick,
        modalState: currentRef,
      });
    }

    if (currentRef !== SignatureModalState.Signed && currentRef !== SignatureModalState.Congrats) {
      storage
        .readObject<SignMandateReminderType>('signMandateReminder')
        .then(signMandateReminder => {
          storage.saveObject('signMandateReminder', {
            ...signMandateReminder,
            modal: {
              lastDisplayedDate: new Date().toISOString(),
              shouldDisplay: !signMandateReminder?.modal?.lastDisplayedDate,
            },
          });
        });
    }

    if ([SignatureModalState.Signed, SignatureModalState.Congrats].includes(currentRef)) {
      closeModal?.({
        closeSource: InAppSignatureModalCloseReason.CompleteStep,
        modalState: currentRef,
      });
      closeBanner?.();
      storage.clear('signMandateReminder').finally(() => {
        navigationRef.reset({
          index: 0,
          routes: [{ name: PrivateNavigatorRoutes.MainMenu }],
        });
      });
    }
  };

  useEffect(() => () => actionsOnClose(ref.current), [closeModal]);

  return (
    <View style={styles.container} testID="in-app-signature-modal">
      {modalState === SignatureModalState.Overview && (
        <OverviewModal
          isPremandate={isPremandate}
          advisorFullName={getFullName(owner?.firstName, owner?.lastName)}
          handleNextStep={handleNextStep}
        />
      )}
      {!!data &&
        !loading &&
        [SignatureModalState.SignIn, SignatureModalState.Signed].includes(modalState) && (
          <SignInModal
            createPandadocLink={data.createPandadocLink}
            handleNextStep={handleNextStep}
          />
        )}
      {modalState === SignatureModalState.Congrats && (
        <CongratsModal handleNextStep={handleNextStep} />
      )}
    </View>
  );
};
