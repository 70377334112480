// Generate region specific translation keys array
export const generateRegionKeys = (key: string, regionCode?: string): string[] | string => {
  if (!regionCode) {
    return key;
  }

  const [countryCode] = regionCode.split('-');

  return [`${key}_${regionCode}`, `${key}_${countryCode}`, key];
};
