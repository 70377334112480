import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  ActionButton,
  Chip,
  ChipProps,
  Text,
  Theme,
  getColorWithOpacity,
  useResponsive,
  useTheme,
} from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { isWeb } from '@app/constants/platform';

import { RealEstateActionButtons } from './RealEstateActionButtons';

interface RealEstateCoverImageOverlayProps {
  imagesCount: number;
  isFavorite: boolean;
  shouldUseShare: boolean;
  renderChip?: (props: Partial<ChipProps>) => React.ReactElement<typeof Chip> | undefined;
  onBack: () => void;
  onShare: () => void;
  onLike?: () => void;
  onPress3dUrl?: () => void;
}

const getStyles = ({
  theme,
  isMobile,
  inset,
}: {
  theme: Theme;
  isMobile: boolean;
  inset: EdgeInsets;
}) =>
  StyleSheet.create({
    backButton: {
      left: isMobile ? 4 : 12,
      position: 'absolute',
      top: isMobile ? inset.top + 8 : 12,
    },
    chip: {
      bottom: isMobile ? 8 : 12,
      left: isMobile ? 4 : 12,
      padding: theme.spacing.SIZE_02,
      position: 'absolute',
    },
    imageCounter: {
      alignItems: 'center',
      backgroundColor: getColorWithOpacity(theme.palette.base.black, 50),
      borderRadius: 4,
      bottom: isMobile ? 8 : 12,
      justifyContent: 'center',
      left: isMobile ? 4 : 12,
      padding: theme.spacing.SIZE_02,
      position: 'absolute',
    },
    url3dButton: {
      bottom: isMobile ? 8 : 12,
      position: 'absolute',
      right: isMobile ? 4 : 12,
    },
  });

export const RealEstateCoverImageOverlay: React.FC<RealEstateCoverImageOverlayProps> = ({
  imagesCount,
  isFavorite,
  shouldUseShare,
  onBack,
  onLike,
  onPress3dUrl,
  onShare,
  renderChip,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inset = useSafeAreaInsets();
  const { isMobile } = useResponsive();
  const styles = useMemo(() => getStyles({ inset, isMobile, theme }), [inset, isMobile, theme]);

  return (
    <>
      {(!!inset.top || isWeb()) && (
        <>
          <View style={styles.backButton}>
            <ActionButton leftIconName="ArrowLeft" onPress={onBack} />
          </View>

          <RealEstateActionButtons
            isFavorite={isFavorite}
            onLike={onLike}
            onShare={onShare}
            shouldUseShare={shouldUseShare}
            onPress3dUrl={renderChip ? onPress3dUrl : undefined}
          />
        </>
      )}

      {renderChip ? (
        renderChip({ size: 'medium', style: styles.chip })
      ) : (
        <>
          <View style={styles.imageCounter} testID="real-estate-cover-image-overlay-image-counter">
            <Text textStyle="Body2" style={{ color: theme.palette.base.white }}>
              1/{imagesCount}
            </Text>
          </View>
          {!!onPress3dUrl && (
            <ActionButton
              testID="real-estate-cover-image-overlay-3d-visit-action-button-bottom"
              leftIconName="Cube"
              label={t('shared.virtualVisitLabel')}
              style={styles.url3dButton}
              onPress={onPress3dUrl}
            />
          )}
        </>
      )}
    </>
  );
};
