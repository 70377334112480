import React, { useState } from 'react';

import { View, StyleSheet } from 'react-native';
import { LivingRoom } from '@assets/illustrations';

import { Hoverable, Icon, Image, Pressable, Theme, useResponsive, useTheme } from '@app/ui';

interface ImageCellProps {
  url: string;
  width: number;
  onPress: () => void;
}

const getStyles = (width: number, theme: Theme, isLowerThanTablet: boolean) => {
  const imageHeight = isLowerThanTablet ? 148 : 223;
  return StyleSheet.create({
    hoverContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.black,
      height: imageHeight,
      justifyContent: 'center',
      opacity: 0.4,
      position: 'absolute',
      top: 0,
      width,
    },

    imageCell: {
      height: imageHeight,
      width,
    },
  });
};

export const ImageCell: React.FC<ImageCellProps> = ({ url, width, onPress }) => {
  const [isCellHovered, setIsCellHovered] = useState(false);
  const theme = useTheme();
  const { isLowerThanTablet } = useResponsive();

  const styles = getStyles(width, theme, isLowerThanTablet);

  const handleOnHoverIn = () => setIsCellHovered(true);
  const handleOnHoverOut = () => setIsCellHovered(false);
  return (
    <Pressable onPress={onPress} testID="image-cell">
      <Hoverable onHoverIn={handleOnHoverIn} onHoverOut={handleOnHoverOut}>
        <Image
          source={{ uri: url ?? LivingRoom }}
          resizeMode={url ? 'cover' : 'contain'}
          style={styles.imageCell}
        />
        {!!isCellHovered && (
          <View style={styles.hoverContainer}>
            <Icon name="Eye" color={theme.palette.base.white} testID="eye-icon" />
          </View>
        )}
      </Hoverable>
    </Pressable>
  );
};
