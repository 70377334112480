import React, { useEffect } from 'react';

import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { useMe } from '@app/shared/hooks/useMe';
import { useTranslation } from '@app/services/translations/translations';
import { useUtmValues } from '@app/shared/hooks/useUtmValues.web';
import { useSignMandateStateHook } from '@app/shared/hooks/useSignMandateState/useSignMandateState.hook';

export const shouldUpdateAppLanguage = (
  currentLanguage: string,
  customerLang?: string
): boolean => {
  if (currentLanguage === 'cimode') {
    return false;
  }

  if (!customerLang) {
    return false;
  }

  return customerLang !== currentLanguage;
};

export const AccountInformations: React.FC = () => {
  const { setValidMsg } = useToastContext();
  const { t, i18n } = useTranslation();

  useSignMandateStateHook();
  useUtmValues();

  const { isAssetManagementConsultant, customer } = useMe();

  useEffect(() => {
    if (isAssetManagementConsultant) {
      setValidMsg({
        icon: 'Check',
        isBottom: true,
        timeout: 15000,
        title: t('profile.pro-account'),
        withClose: true,
      });
    }
  }, [isAssetManagementConsultant]);

  useEffect(() => {
    if (shouldUpdateAppLanguage(i18n.language, customer?.lang)) {
      i18n.changeLanguage(customer.lang);
    }
  }, [customer, i18n]);

  return null;
};
