import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgData = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5.6 2.05h1.95l3.375 9H9.05L8.3 8.9H4.85l-.75 2.15H2.225l3.375-9zm.125 10.05c.7 0 1.292.242 1.775.725.483.483.725 1.075.725 1.775a2.529 2.529 0 01-.725 1.775l-.375.35.7.7 1.425-1.4 1.425 1.425-1.4 1.4 1.4 1.425-1.425 1.4-1.4-1.4-1.05 1.05c-.25.25-.533.437-.85.562-.317.125-.65.188-1 .188-.7 0-1.283-.246-1.75-.738-.467-.491-.7-1.087-.7-1.787 0-.333.067-.654.2-.963.133-.308.317-.579.55-.812l1.05-1.05-.35-.35a2.636 2.636 0 01-.55-.8c-.133-.3-.2-.617-.2-.95 0-.7.246-1.296.738-1.788a2.434 2.434 0 011.787-.737zm0 6.05L4.65 19.2a.376.376 0 00-.1.162.685.685 0 00-.025.188.5.5 0 00.138.35c.091.1.204.15.337.15a.48.48 0 00.35-.15l1.075-1.05-.7-.7zM5.7 14.1a.439.439 0 00-.337.15.5.5 0 00-.138.35c0 .067.008.129.025.187.017.059.05.113.1.163l.375.35.325-.325a.51.51 0 00.112-.163.462.462 0 00.038-.187.524.524 0 00-.15-.363.463.463 0 00-.35-.162zM6.525 4l-1.15 3.35h2.4L6.625 4h-.1zm10.95-2.625c.133.217.246.442.337.675.092.233.171.467.238.7l-1.075.325L22 3.05v2.025h-1.675A8.04 8.04 0 0118.6 8c.45.267.917.487 1.4.662.483.175.983.33 1.5.463l-.475 1.925a17.39 17.39 0 01-2.087-.675A10.04 10.04 0 0117 9.35c-.617.4-1.263.737-1.938 1.012s-1.37.505-2.087.688l-.45-1.925c.5-.133.992-.288 1.475-.463.483-.175.95-.395 1.4-.662a8.105 8.105 0 01-1.012-1.363 7.042 7.042 0 01-.688-1.562H12v-2h4.4a4.33 4.33 0 00-.2-.637 45.916 45.916 0 00-.25-.613l1.525-.45zm2.6 11.1l1.425 1.4-7.775 7.775-1.425-1.4 7.775-7.775zm-5.575.575c.417 0 .77.146 1.062.437.292.292.438.646.438 1.063 0 .417-.146.771-.438 1.063a1.446 1.446 0 01-1.062.437c-.417 0-.77-.146-1.062-.437A1.447 1.447 0 0113 14.55c0-.417.146-.771.438-1.063a1.446 1.446 0 011.062-.437zm1.325-7.975c.133.317.3.617.5.9.2.283.425.55.675.8a6.128 6.128 0 001.2-1.7h-2.375zM19.5 18.05c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.771-.438 1.063a1.446 1.446 0 01-1.062.437c-.417 0-.77-.146-1.062-.437A1.447 1.447 0 0118 19.55c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgData;
