import { TFunction } from 'react-i18next';

import { useTranslation } from '@app/services/translations/translations';
import { generateRegionKeys } from '@app/utils/generateRegionKeys';

export const useRegionTranslation = (regionCode: string): { t: TFunction } => {
  const { t: translationFunc } = useTranslation();

  const t: TFunction = (key, options) =>
    translationFunc(Array.isArray(key) ? key : generateRegionKeys(key, regionCode), options);

  return { t };
};
