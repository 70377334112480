import React from 'react';

import { ScrollView } from 'react-native';

import { getStyles } from './List.styles';
import { ListProps } from './List.types';
import { ListItem } from './ListItem';

/**
 * TODO MOVE THIS IN A FOLDER IN molecules/list/
 */

const List: React.FC<ListProps> = ({
  children,
  itemsHeight = 48,
  numberOfLines,
  isHeightLimited,
  padding = 0, // Forced to do this for the moment because of the current integration >.<'
}) => {
  const childrenCount = children.length;
  const shouldCutHeight = isHeightLimited && childrenCount > 5;
  const listHeight =
    itemsHeight === -1
      ? undefined
      : itemsHeight * (shouldCutHeight ? 5 : childrenCount) + padding * 2;

  const styles = getStyles(listHeight, padding);

  return (
    <ScrollView style={styles.container} testID="atomList">
      {React.Children.map(children, (Child: JSX.Element) =>
        React.cloneElement(Child, {
          height: itemsHeight === -1 ? undefined : itemsHeight,
          numberOfLines,
        })
      )}
    </ScrollView>
  );
};

export * from './List.types';
export { List, ListItem };
