import { Alert, Share } from 'react-native';

import { logMessage } from '@app/services/errorMonitoring/errorMonitoring';
import { isNative, isWeb } from '@app/constants/platform';

export interface Sharable {
  title?: string;
  url: string;
}

export const canShare = (content: Sharable, isMobile: boolean): boolean =>
  isNative() || (isWeb() && isMobile && navigator.canShare?.(content));

export const onShare = async (content: Sharable, isMobile: boolean): Promise<void> => {
  const { title, url } = content;

  const shouldUseShare = canShare(content, isMobile);

  if (shouldUseShare) {
    if (isWeb()) {
      await shareFromWeb(title ?? '', url);
    } else {
      await shareFromIosOrAndroid(title ?? '', url);
    }
  } else {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(url);
    } else {
      alert('Not supported');
    }
  }
};

async function shareFromIosOrAndroid(title: string, url: string) {
  try {
    await Share.share({
      message: `${title}\n${url}`,
      url,
    });
  } catch (error) {
    Alert.alert((error as Error).message);
  }
}

async function shareFromWeb(title: string, url: string) {
  try {
    await navigator.share({
      title,
      url,
    });
  } catch (e) {
    if (!(e as Error).toString().includes('AbortError')) {
      logMessage((e as Error).toString());
    }
  }
}
