import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMk = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mk_svg__a)">
        <Path fill="#E81B26" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666zM10.208 7.28c.201-.28.433-.538.69-.767L1.962-3.53-.715-.558l10.923 7.837zm.69 6.206a4.691 4.691 0 01-.69-.765L-.715 20.558l2.677 2.973 8.936-10.044zm2.187 1.09L12 20h4l-1.085-5.423a4.687 4.687 0 01-1.83 0zm4.707-1.855c-.201.28-.433.537-.69.765l8.936 10.045 2.677-2.973-10.923-7.837zM9.38 10.66a4.705 4.705 0 010-1.32L0 8v4l9.38-1.34zm3.705-5.237L12 0h4l-1.085 5.423a4.69 4.69 0 00-1.83 0zM18.62 9.34a4.696 4.696 0 010 1.32L28 12V8l-9.38 1.34zm-.828-2.06a4.695 4.695 0 00-.69-.767L26.038-3.53l2.677 2.973-10.923 7.837z"
          fill="#FFE94F"
        />
      </G>
    </Svg>
  );
};

export default SvgMk;
