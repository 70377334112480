import { StyleSheet, Platform } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme, disabled: boolean) =>
  StyleSheet.create({
    container: {
      width: '100%',
      ...Platform.select({ web: { cursor: 'pointer' } }),
    },
    inputStyle: {
      minWidth: 100,
    },
    inputsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing.SIZE_06,
      width: '100%',
    },
    label: {
      color: theme.palette.neutral[900],
    },
    labelContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: theme.spacing.SIZE_02,
    },
    renderThumbComponent: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[disabled ? 600 : 900],
      borderRadius: theme.spacing.SIZE_05,
      height: theme.spacing.SIZE_05,
      justifyContent: 'center',
      width: theme.spacing.SIZE_05,
      zIndex: 2,
    },
  });
