import * as React from 'react';

import Svg, { SvgProps, G, Mask, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const Rail = ({ color, ...props }: SvgProps) => (
  <Svg width={50} height={50} viewBox="3 1 50 50" fill="none" {...props}>
    <G>
      <Mask id="b" fill="#fff">
        <Path
          fillRule="evenodd"
          d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
          clipRule="evenodd"
        />
      </Mask>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="m30.12 40.803-.123-.992-.323.04-.238.222.684.73Zm-4.778-.075.683-.73-.224-.21-.304-.048-.155.988ZM27.772 43l-.684.73.683.64.683-.64-.683-.73ZM43 25.967c0 7.031-5.61 12.928-13.003 13.844l.245 1.985C38.52 40.77 45 34.122 45 25.966h-2ZM28 12c8.348 0 15 6.315 15 13.967h2C45 17.087 37.325 10 28 10v2ZM13 25.967C13 18.315 19.652 12 28 12v-2c-9.325 0-17 7.087-17 15.967h2ZM25.497 39.74C18.355 38.623 13 32.837 13 25.967h-2c0 7.967 6.187 14.498 14.188 15.749l.309-1.976Zm2.957 2.53-2.429-2.272-1.366 1.46 2.43 2.272 1.365-1.46Zm.982-2.197-2.348 2.197 1.366 1.46 2.349-2.197-1.367-1.46Z"
        mask="url(#b)"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="M23.988 34a.46.46 0 0 1-.347-.148.51.51 0 0 1-.141-.365c0-.066.019-.142.056-.227a.405.405 0 0 1 .15-.188l.919-.65c-.738 0-1.36-.267-1.866-.8S22 30.434 22 29.658v-7.5c0-1.092.481-1.892 1.444-2.398.962-.507 2.481-.76 4.556-.76 2.15 0 3.688.243 4.612.73.925.487 1.388 1.296 1.388 2.428v7.5c0 .776-.253 1.43-.76 1.964-.506.533-1.128.8-1.865.8l.919.65c.062.04.112.1.15.178a.548.548 0 0 1 .056.237.51.51 0 0 1-.14.365.46.46 0 0 1-.347.148h-8.025Zm-.488-8.684h3.75v-2.369H23.5v2.369Zm5.25 0h3.75v-2.369h-3.75v2.369Zm-3.375 4.737c.313 0 .578-.116.797-.346.219-.23.328-.51.328-.839 0-.328-.11-.608-.328-.838a1.058 1.058 0 0 0-.797-.346c-.313 0-.578.115-.797.346-.219.23-.328.51-.328.838 0 .33.11.609.328.84.219.23.485.345.797.345Zm5.25 0c.313 0 .578-.116.797-.346.219-.23.328-.51.328-.839 0-.328-.11-.608-.328-.838a1.058 1.058 0 0 0-.797-.346c-.313 0-.578.115-.797.346-.219.23-.328.51-.328.838 0 .33.11.609.328.84.219.23.485.345.797.345Zm-6 .79h6.75c.325 0 .594-.113.806-.336.213-.224.319-.507.319-.85v-2.762h-9v2.763c0 .342.106.625.319.849.212.223.481.335.806.335ZM28 20.578c-1.075 0-1.966.066-2.672.197-.706.132-1.203.33-1.49.592h8.4c-.225-.263-.691-.46-1.397-.592-.707-.131-1.654-.197-2.841-.197Z"
      />
    </G>
    <Defs></Defs>
  </Svg>
);
export { Rail };
