import React, { useCallback, useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { Expandable, Spacer, Theme, useTheme } from '@app/ui';
import {
  trackBrokerFees,
  trackFinancialToggle,
  trackFinancialUntoggle,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { RealEstateFinancialHeader } from './RealEstateFinancialHeader';
import {
  getAttributesByCountryCode,
  getInvestmentAttributes,
} from '../utils/realEstateAttributesMapper.utils';
import {
  Importance,
  RealEstateAttribute,
  RealEstateAttributeProps,
} from '../../../components/RealEstateAttribute';
import { areEqual, differentFuncParameters } from '../utils/realEstateFinances.utils';
import { computedWorkAmount } from '../../realEstateGeneral/utils/computedWorkAmount.utils';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    border: {
      borderBottomColor: theme.palette.neutral[300],
      borderBottomWidth: 1,
      width: '100%',
    },
    container: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      paddingBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_03,
      width: '100%',
    },
  });

interface RealEstateFinancialInvestmentProps {
  realEstate: RealEstate;
  financialModel: FinancialModel;
  withBrokerageFees: boolean;
  isPaymentCash: boolean;
  setWithBrokerageFees: (v: boolean) => void;
}

const isOdd = (index: number): boolean => index % 2 !== 0;

export const RealEstateFinancialInvestment: React.FC<RealEstateFinancialInvestmentProps> =
  React.memo(
    ({ realEstate, financialModel, withBrokerageFees, isPaymentCash, setWithBrokerageFees }) => {
      const { t } = useRealEstateTranslation();
      const theme = useTheme();
      const styles = useMemo(() => getStyles(theme), [theme]);

      const projectAmount = currencyFormatter(financialModel.projectAmount, 0);
      const handleBrokerageFeesCheckbox = useCallback((value: boolean) => {
        trackBrokerFees({ isChecked: value, propertyId: realEstate.id });
        setWithBrokerageFees(value);
      }, []);
      const { countryCode } = realEstate.building.address;

      const trackToggle = ({ expanded }) =>
        expanded
          ? trackFinancialUntoggle({ propertyId: realEstate.id, sectionName: 'project' })
          : trackFinancialToggle({ propertyId: realEstate.id, sectionName: 'project' });

      // The cgp commission is diluted in the renovation amount
      const renovationAmount = computedWorkAmount(realEstate.project, financialModel);

      const mapperData = {
        bankCommission: currencyFormatter(financialModel.bankCommission, 0),
        brokerageFees: currencyFormatter(financialModel.brokerageFees, 0),
        deedPurchaseFees: currencyFormatter(financialModel?.stateAssetsTransferTax, 0),
        financialModelVersion: realEstate.financialModelVersion,
        furnituresAmount: currencyFormatter(realEstate.project.furnishing.amount, 0),
        handleBrokerageFeesCheckbox,
        isPaymentCash,
        masteosCommission: currencyFormatter(financialModel.masteosCommission, 0),
        notaryCommission: currencyFormatter(financialModel.notaryCommission, 0),
        renovationAmount: currencyFormatter(renovationAmount, 0),
        salePrice: currencyFormatter(financialModel.salePrice, 0),
        stateAssetsTransferTax: currencyFormatter(financialModel?.stateAssetsTransferTax, 0),
        withBrokerageFees,
      };

      const investmentAttributesData = getInvestmentAttributes(mapperData, t, realEstate.id);
      const data = getAttributesByCountryCode(investmentAttributesData, countryCode);

      return (
        <View style={styles.container} testID="project-cost">
          <Expandable onPress={trackToggle}>
            <Expandable.Header testID="project-cost-header">
              <Expandable.Context.Consumer>
                {({ expanded }) => (
                  <RealEstateFinancialHeader
                    title={t(`shared.financialTool.projectCost`)}
                    icon="Wallet"
                    hasChevron
                    chevronOpen={expanded}
                  />
                )}
              </Expandable.Context.Consumer>
            </Expandable.Header>
            <Expandable.Content>
              {data.map((d: RealEstateAttributeProps, index: number) => (
                <RealEstateAttribute
                  key={d?.testID}
                  label={d?.label}
                  value={d?.value}
                  importance={d?.importance}
                  isOdd={isOdd(index + 1)}
                  testID={d?.testID}
                  tooltip={d?.tooltip}
                  inputSwitch={d?.inputSwitch}
                />
              ))}

              <Spacer height={theme.spacing.SIZE_05} />

              <View style={styles.border} />

              <Spacer height={theme.spacing.SIZE_04} />

              <RealEstateAttribute
                label={t('shared.financialTool.projectAmount')}
                value={projectAmount}
                importance={Importance.HIGH}
                testID="property-financial-line-project-amount"
              />
            </Expandable.Content>
          </Expandable>
        </View>
      );
    },
    areEqual(
      ['isPaymentCash', 'withBrokerageFees'],
      (
        prevState: RealEstateFinancialInvestmentProps,
        nextState: RealEstateFinancialInvestmentProps
      ) => {
        return differentFuncParameters(prevState, nextState, [
          'projectAmount',
          'brokerageFees',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'deedPurchaseFees',
          'masteosCommission',
          'notaryCommission',
          'bankCommission',
          'stateAssetsTransferTax',
          'salePrice',
        ]);
      }
    )
  );

RealEstateFinancialInvestment.displayName = 'PropertyFinancialInvestment';
