import { CustomerPositioningCancellationReason } from '@app/libs/apollo/introspection';

export const toggleSelectedReason = (
  selectedReasons: Set<CustomerPositioningCancellationReason>,
  key: CustomerPositioningCancellationReason
): Set<CustomerPositioningCancellationReason> => {
  const newSelectedReasons = new Set(selectedReasons);
  if (newSelectedReasons.has(key)) {
    newSelectedReasons.delete(key);
  } else {
    newSelectedReasons.add(key);
  }
  return newSelectedReasons;
};
