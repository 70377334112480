import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Icon, IconName, Text, Theme, useTheme } from '@app/ui';

export type RenovateWithMasteosListItemProps = {
  title: string;
  description: string;
  iconName: IconName;
  isLast?: boolean;
};

const getStyles = ({ isLast, theme }: { isLast: boolean; theme: Theme }) =>
  StyleSheet.create({
    container: {
      paddingBottom: isLast ? 0 : theme.spacing.SIZE_06,
    },
    icon: {
      marginRight: 6,
      marginTop: -3,
    },
    title: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 6,
    },
  });

export const RenovateWithMasteosListItem: React.FC<RenovateWithMasteosListItemProps> = ({
  iconName,
  title,
  description,
  isLast = false,
}) => {
  const theme = useTheme();
  const styles = getStyles({ isLast, theme });

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <Icon name={iconName} size={20} style={styles.icon} />
        <Text textStyle="Body1Medium">{title}</Text>
      </View>
      <Text textStyle="Body3">{description}</Text>
    </View>
  );
};
