import { StatusBarStyle, StatusBar, ColorValue } from 'react-native';

import {
  ProjectNavigatorRoutes,
  RealEstateNavigatorRoutes,
  Routes,
} from '@app/navigation/types/routes';

interface StatusBarOptions {
  style: StatusBarStyle;
  backgroundColor: ColorValue;
}

const DEFAULT_STATUS_BAR_OPTIONS: StatusBarOptions = {
  backgroundColor: 'transparent',
  style: 'dark-content',
};

// only for status bar for routes that is light-content
export const statusBarStyleForRoute: {
  [key in Routes]?: StatusBarOptions;
} = {
  [ProjectNavigatorRoutes.Project]: { backgroundColor: 'transparent', style: 'light-content' },

  [RealEstateNavigatorRoutes.RealEstate]: {
    backgroundColor: 'transparent',
    style: 'light-content',
  },
  [RealEstateNavigatorRoutes.RealEstateSold]: {
    backgroundColor: 'transparent',
    style: 'light-content',
  },
};

export const setStatusBarStyleForRoute = (currentRoute?: Routes): void => {
  setTimeout(() =>
    StatusBar.setBarStyle(
      currentRoute
        ? statusBarStyleForRoute[currentRoute]?.style ?? DEFAULT_STATUS_BAR_OPTIONS.style
        : DEFAULT_STATUS_BAR_OPTIONS.style,
      true
    )
  );
};
