import React, { useMemo } from 'react';

import { ScrollView, StyleSheet, View } from 'react-native';

import { Icon, IconButton, IconName, Spacer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { isNative } from '@app/constants/platform';

import { TablePreview } from '../TablePreview';
import { getFurnishmentDetails } from '../../utils/furnishing';

interface FurnishmentDetailsTemplateProps {
  furnishingAmount: number;
  roomCount: number;
  onClose?: () => void;
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    descriptionContainer: {
      marginBottom: theme.spacing.SIZE_05,
    },
    item: {
      gap: theme.spacing.SIZE_03,
    },
    itemFurnitures: {
      gap: theme.spacing.SIZE_01,
      paddingHorizontal: theme.spacing.SIZE_07,
    },
    itemTitle: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
    },
    scrollView: {
      flex: 1,
      padding: theme.spacing.SIZE_06,
    },
    titleContainer: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_05,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_05,
      ...(isLowerThanLaptop && {
        borderBottomWidth: 1,
        borderColor: theme.palette.neutral[100],
        paddingBottom: theme.spacing.SIZE_05,
      }),
    },
  });

export const FurnishmentDetailsTemplate: React.FC<FurnishmentDetailsTemplateProps> = ({
  furnishingAmount,
  roomCount,
  onClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop);

  const furnishment = useMemo(() => getFurnishmentDetails(roomCount), [roomCount]);

  return (
    <>
      <View style={styles.titleContainer}>
        {!!isLowerThanLaptop && <IconButton iconName="ArrowLeft" iconSize="m" onPress={onClose} />}
        <Text textStyle={isLowerThanLaptop ? 'Headline2Medium' : 'Title3Medium'}>
          {t('realEstate.renovation.furnishment.detailsTitle')}
        </Text>
      </View>

      <ScrollView style={styles.scrollView}>
        <View style={styles.descriptionContainer}>
          <Text textStyle="Body2">{t('realEstate.renovation.furnishment.detailsDescription')}</Text>
        </View>

        <TablePreview
          total={furnishingAmount}
          totalLabel={t('realEstate.renovation.furnishment.totalLabel')}
          data={furnishment}
          renderItem={item => (
            <View style={styles.item}>
              <View style={styles.itemTitle}>
                <Icon name={item.icon as IconName} size={16} />
                <Text textStyle="Body2Medium">
                  {t(`realEstate.renovation.furnishment.${item.title}`)}
                </Text>
              </View>

              {'furnitures' in item && (
                <View style={styles.itemFurnitures}>
                  {!!item.subtitle && (
                    <Text textStyle="Body3">
                      {t(`realEstate.renovation.furnishment.${item.subtitle}`)}
                    </Text>
                  )}
                  {item.furnitures.map(furniture => (
                    <Text textStyle="Body3" key={furniture}>
                      ● {t(`realEstate.renovation.furnishment.furnitures.${furniture}`)}
                    </Text>
                  ))}
                </View>
              )}
            </View>
          )}
        />

        {isNative() && <Spacer height={theme.spacing.SIZE_10} />}
      </ScrollView>
    </>
  );
};
