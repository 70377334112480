import React, { MutableRefObject, forwardRef, useMemo, useState } from 'react';

import { GestureResponderEvent, StyleSheet } from 'react-native';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel-4';

import { Image, Pressable, Theme, useTheme } from '@app/ui';

import { SliderImageObject } from '../gallery.types';

interface SmallCarouselProps {
  ref: MutableRefObject<ICarouselInstance>;
  data: SliderImageObject[];
  onSlidePress: (index: number) => void;
  width: number;
  defaultIndex?: number;
}

const IMAGE_PER_PAGE = 3;
const SLIDE_TO_SHOW = 9;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    image: {
      paddingHorizontal: theme.spacing.SIZE_04,
    },
    pressableImage: {
      flex: 1,
    },
  });

export const SmallCarousel = forwardRef(
  (
    { data, onSlidePress, width, defaultIndex }: SmallCarouselProps,
    ref: MutableRefObject<ICarouselInstance>
  ) => {
    const theme = useTheme();
    const styles = useMemo(() => getStyles(theme), [theme]);

    /* 
    These 2 methods (onPressIn, onPressOut) and state fix the problem of the swipe detected as a touch
    (onPress is called on carousel swipe)
  */
    const [smallCarouselOnPress, setSmallCarouselOnPress] = useState<{ x: number; y: number }>();
    const onPressIn = (({ nativeEvent }: GestureResponderEvent) => {
      setSmallCarouselOnPress({
        x: nativeEvent.locationX,
        y: nativeEvent.locationY,
      });
    }) as () => void;

    const onPressOut = (index: number) =>
      (({ nativeEvent }: GestureResponderEvent) => {
        if (
          smallCarouselOnPress?.x === nativeEvent.locationX &&
          smallCarouselOnPress?.y === nativeEvent.locationY
        ) {
          onSlidePress(index);
        }
      }) as () => void;

    return (
      <Carousel
        ref={ref}
        data={data}
        enabled={false}
        renderItem={({ item, index }) => (
          <Pressable
            style={styles.pressableImage}
            onPressIn={onPressIn}
            onPressOut={onPressOut(index)}
          >
            <Image source={{ uri: item.url }} style={styles.image} resizeMode="cover" />
          </Pressable>
        )}
        width={width / IMAGE_PER_PAGE}
        height={100}
        style={{ width }}
        defaultIndex={defaultIndex}
        windowSize={SLIDE_TO_SHOW}
      />
    );
  }
);

SmallCarousel.displayName = 'SmallCarousel';
