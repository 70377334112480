import { RequiredThemes } from '@app/ui/theme/types';

export const getCheckboxColor = (
  checked: boolean,
  disabled: boolean,
  error: boolean,
  checkboxTheme: RequiredThemes['checkbox']
) => {
  if (disabled) {
    return checkboxTheme.color['disabled'];
  }
  if (error) {
    return checkboxTheme.color['error'];
  }
  if (checked) {
    return checkboxTheme.color['active'];
  }

  return checkboxTheme.color['default'];
};
