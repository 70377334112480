import { Trilean } from '../TrileanController.types';

export const stringToTrilean = (str: string): Trilean => {
  if (str === 'true') {
    return true;
  }
  if (str === 'false') {
    return false;
  }
  if (str === 'null') {
    return null;
  }
  throw new Error(`String->Trilean case unhandled: ${str}`);
};
