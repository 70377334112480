import React, { useMemo } from 'react';

import { View } from 'react-native';

import {
  Chip,
  HeaderPage,
  Icon,
  Image,
  Spacer,
  StepStatus,
  useResponsive,
  useTheme,
} from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { fromS3ID } from '@app/utils/from-s3id';
import { projectTimelineSteps } from '@app/features/project/ProjectHeader/projectTimeline';
import { getStageStatus } from '@app/utils/project';
import { ProjectCategory } from '@app/features/project/Project.types';
import { MAX_WIDTH_CONTAINER } from '@app/features/project/Project.styles';
import { AdvisorSelector } from '@app/shared/components/advisor-selector/AdvisorSelector';
import { AdvisorSourcePage } from '@app/services/tracking/constants';
import { TimelineStep } from '@app/shared/components/timeline/Timeline.types';
import { Timeline } from '@app/shared/components/timeline/Timeline';

import { ProjectResponsiveHeader } from './ProjectResponsiveHeader';
import { getHeaderStyles } from './ProjectHeader.styles';
import { getIconIndicator, getStepStatus } from './ProjectHeader.utils';
import { chipsProps, ProjectHeaderProps } from './ProjectHeader.const';

type TimelineStages = Record<
  ProjectCategory,
  Omit<TimelineStep, 'status'> & { status?: StepStatus }
>;

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  address,
  chips,
  imageCover,
  onBack,
  onButtonPress,
  projectAmount,
  projectSteps,
  scrollToStage,
  setStepActive,
  status,
  stepActive,
  contact,
  title,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanDesktop, isLowerThanTablet } = useResponsive();
  const styles = useMemo(
    () => getHeaderStyles({ isLowerThanTablet, theme }),
    [theme, isLowerThanTablet]
  );
  const onTimelineStepPress = (stepNumber: number) => setStepActive(stepNumber, true);

  const timelineStages = useMemo(
    () =>
      Object.entries(projectSteps).reduce<TimelineStages>((acc, [category, steps]) => {
        const projectCategory = category as ProjectCategory;
        acc[projectCategory] = {
          indicator: (
            <Icon
              name={getIconIndicator(projectCategory)}
              color={theme.palette.base.white}
              height="20"
            />
          ),
          ...(projectCategory === ProjectCategory.Transaction && { status: getStepStatus(status) }),
          subSteps: projectTimelineSteps['fr-FR'][projectCategory].map(({ key }, index) => ({
            content: t(`projectTimeline.${projectCategory}.steps.${key}`),
            status: getStageStatus(index + 1, steps?.currentStage),
            title: `${t('shared.step')} ${index + 1}`,
          })),
          title: t(`project.${projectCategory}.tab.name`),
        };
        return acc;
      }, {} as TimelineStages),
    [projectSteps, status, t, theme]
  );

  return (
    <>
      {isLowerThanDesktop ? (
        <ProjectResponsiveHeader
          address={address}
          chips={chips}
          imageCover={imageCover}
          onBack={onBack}
          onButtonPress={onButtonPress}
          projectAmount={projectAmount}
          status={status}
          title={title}
          contact={contact}
        />
      ) : (
        <View style={styles.header} testID="project-header">
          <View style={styles.headerBlock}>
            <View>
              <HeaderPage
                title={title}
                onBack={onBack}
                subtitle={
                  <Chip
                    text={t(`project.transactionStatus.${status.toLowerCase()}`)}
                    size="medium"
                    {...chipsProps[status]}
                  />
                }
                bottomElement={address}
              />
            </View>
            <View style={styles.advisorSelector}>
              <AdvisorSelector source={AdvisorSourcePage.PROJECT_PAGE} contact={contact} />
            </View>
          </View>
          <View style={styles.subHeader}>
            <Spacer height={theme.spacing.SIZE_09} />
            <Timeline
              activeStep={stepActive}
              steps={timelineStages as Record<ProjectCategory, TimelineStep>}
              onStepPress={onTimelineStepPress}
              onSubStepPress={scrollToStage}
            />
            <Spacer height={theme.spacing.SIZE_07} />
            <Image
              source={{ uri: fromS3ID(imageCover, `w-${MAX_WIDTH_CONTAINER}`) }}
              style={styles.coverImage}
            />
          </View>
        </View>
      )}
    </>
  );
};
