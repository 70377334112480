import { StyleSheet } from 'react-native';

export const getStyle = ({ aspectRatio }: { aspectRatio?: number; resizeMode?: string }) =>
  StyleSheet.create({
    children: {
      flex: 1,
    },
    defaultChildrenWrapper: {
      height: '100%',
      position: 'absolute',
      width: '100%',
      zIndex: 2,
    },
    image: {
      height: '100%',
      position: 'relative',
      width: '100%',
    },
    wrapper: {
      aspectRatio: aspectRatio || undefined,
      bottom: 0,
      height: aspectRatio ? 'auto' : '100%',
      overflow: 'hidden',
      width: '100%',
    },
  });
