import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChair = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.742 1.03a1 1 0 01.728 1.213L18 12.123v3.714l1.949 5.847a1 1 0 11-1.898.632L16.28 17H7.721l-1.772 5.316a1 1 0 01-1.898-.632l1.658-4.975A3 3 0 017 11h9.22l.572-2.29a30.145 30.145 0 01-.622-.263 16.157 16.157 0 01-.93-.439c-.253-.131-.594-.32-.82-.538-.316-.306-.38-.706-.402-.88a3.336 3.336 0 010-.692c.04-.454.152-.978.302-1.47.149-.49.354-1.006.61-1.423.127-.207.291-.431.501-.614.206-.18.539-.391.979-.391h2.06l.06-.242a1 1 0 011.212-.728zM17.97 4h-1.302l-.032.05c-.043.07-.087.153-.131.246a5.304 5.304 0 00-.27.714 5.64 5.64 0 00-.23 1.138c.044.025.097.054.159.086.235.122.523.255.81.382l.31.132L17.968 4zM6 14a1 1 0 011-1h9v2H6.978A1 1 0 016 14zm10.004-7.652v-.002c.006.028.003.033 0 .002z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChair;
