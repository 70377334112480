import React, { useContext } from 'react';

import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { BottomSheet, ModalFullScreen, Spacer, Text, useTheme } from '@app/ui';
import { isNative } from '@app/constants/platform';

import { ModalContext } from './ModalManagerProvider';
import { getStyles } from './modalDisplayer.styles';

export const ModalDisplayer: React.FC = () => {
  const { isVisible, closeModal, title, component, position, webSize } = useContext(ModalContext);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { top, bottom } = useSafeAreaInsets();

  if (isNative() && position === 'fullscreen') {
    return (
      <ModalFullScreen isVisible={isVisible} onPressClose={closeModal}>
        <View style={styles.content}>
          <Spacer height={top} />

          <Text>{title}</Text>
          {component}
          <Spacer height={bottom} />
        </View>
      </ModalFullScreen>
    );
  }

  return (
    <>
      <BottomSheet
        visible={isVisible}
        onPressClose={closeModal}
        webSize={webSize}
        testID="modal-wrapper"
      >
        {typeof title === 'string' ? (
          <>
            <Text testID="ModalTitle" textStyle="Title3Medium">
              {title}
            </Text>
            <Spacer height={theme.spacing.SIZE_04} />
          </>
        ) : (
          title
        )}
        {component}
      </BottomSheet>
    </>
  );
};
