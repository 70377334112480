import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgFile = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9 18h6a.968.968 0 00.713-.288A.967.967 0 0016 17a.967.967 0 00-.287-.712A.968.968 0 0015 16H9a.965.965 0 00-.712.288A.965.965 0 008 17c0 .283.096.52.288.712A.965.965 0 009 18zm0-4h6a.968.968 0 00.713-.288A.967.967 0 0016 13a.97.97 0 00-.287-.713A.97.97 0 0015 12H9a.967.967 0 00-.712.287A.968.968 0 008 13c0 .283.096.52.288.712A.965.965 0 009 14zm-3 8c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 2h7.175a1.978 1.978 0 011.4.575l4.85 4.85a1.978 1.978 0 01.575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 22H6zm7-18H6v16h12V9h-4a.965.965 0 01-.712-.288A.965.965 0 0113 8V4zM6 4v5-5 16V4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgFile;
