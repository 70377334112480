import { StyleSheet, Platform } from 'react-native';

import { RequiredThemes, Theme } from '@app/ui/theme/types';

import { SwitchSize } from './Switch.types';

export const getLabelColor = (disabled: boolean, componentTheme: RequiredThemes['switch']) =>
  disabled ? componentTheme.labelColor.disabled : componentTheme.labelColor.default;

export const styles = (
  theme: Theme,
  size: SwitchSize,
  componentTheme: RequiredThemes['switch'],
  disabled: boolean
) =>
  StyleSheet.create({
    check: {
      backgroundColor: theme.palette.base.white,
      borderRadius: componentTheme.sizes[size].indicatorSize,
      height: componentTheme.sizes[size].indicatorSize,
      position: 'absolute',
      top: theme.spacing.SIZE_01 + 1,
      width: componentTheme.sizes[size].indicatorSize,
    },
    container: {
      borderRadius: componentTheme.sizes[size].height,
      height: componentTheme.sizes[size].height,
      width: componentTheme.sizes[size].width,
    },
    disabledMainContainer: {
      display: 'flex',
      ...Platform.select({ web: { cursor: 'not-allowed' } }),
    },
    label: {
      color: getLabelColor(disabled, componentTheme),
      paddingLeft: theme.spacing.SIZE_03,
    },
    mainContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
  });
