import React from 'react';

import { View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { useTheme } from '@app/ui/contexts/config';

import { getCardBannerStyle } from './CardBanner.styles';
import { CardBannerProps } from './CardBanner.types';

export { CardBannerProps };

export const CardBanner: React.FC<CardBannerProps> = ({
  testID = 'card-banner',
  text,
  color,
  children,
}) => {
  const theme = useTheme();
  const style = getCardBannerStyle({ color, theme });

  return (
    <View style={style.container} testID={testID}>
      {!!children && <View style={style.childrenWrapper}>{children}</View>}
      <Text textStyle="Body3Medium" style={style.text}>
        {text}
      </Text>
    </View>
  );
};
