import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { Checkbox, Text, useTheme } from '@app/ui';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';

import { getIsAllSelected } from './SelectGroupHeader.utils';
import { SelectGroupMultipleWithSelectAll, SelectGroupProps } from './SelectGroup.types';

type SelectGroupHeaderProps = Pick<SelectGroupProps, 'optionGroups' | 'values' | 'onChangeValues'> &
  Pick<SelectGroupMultipleWithSelectAll, 'selectAllLabel' | 'unselectAllLabel'>;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: 1,
      flexDirection: 'row',
      marginHorizontal: theme.spacing.SIZE_07,
      paddingBottom: theme.spacing.SIZE_07 - theme.spacing.SIZE_01,
      paddingTop: theme.spacing.SIZE_09 + theme.spacing.SIZE_01,
    },
    text: {
      flex: 1,
    },
  });

export const SelectGroupHeader: React.FC<SelectGroupHeaderProps> = ({
  optionGroups,
  values,
  onChangeValues,
  selectAllLabel,
  unselectAllLabel,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isAllSelected = getIsAllSelected(optionGroups, values);

  const selectAll = () => {
    const flattenOptions = optionGroups.map(optionGroup => optionGroup.options).flat();
    onChangeValues(flattenOptions.map(option => option.key));
  };

  const unselectAll = () => {
    onChangeValues([]);
  };

  return (
    <Pressable
      onPress={isAllSelected ? unselectAll : selectAll}
      testID="select-group-header-pressable"
      withPressOpacity={false}
    >
      <View style={styles.container} testID="select-group-header-container">
        <Text textStyle="Body2Medium" style={styles.text}>
          {isAllSelected ? unselectAllLabel : selectAllLabel}
        </Text>
        <Checkbox checked={isAllSelected} />
      </View>
    </Pressable>
  );
};
