import { TextStyle, ViewStyle } from 'react-native';

import { RequiredThemes } from '@app/ui/theme/types';

import { ChipSizeVariant } from './Chip.types';

export const styles = (componentTheme: RequiredThemes['chip']) => ({
  icon: {
    marginRight: componentTheme.icon.marginRight,
  },
  wrapper: {
    alignItems: 'center',
    borderRadius: componentTheme.wrapper.borderRadius,
    display: 'flex',
    flexDirection: 'row',
  } as unknown as { [key: string]: ViewStyle | TextStyle },
});

export const computedPadding = (componentTheme: RequiredThemes['chip'], size: ChipSizeVariant) => ({
  paddingHorizontal:
    size === 'small'
      ? componentTheme.paddingHorizontal.small
      : componentTheme.paddingHorizontal.medium,
  paddingVertical:
    size === 'small' ? componentTheme.paddingVertical.small : componentTheme.paddingVertical.medium,
});
