import { useAddBookmarkMutation, useDeleteBookmarkMutation } from '@app/libs/apollo/introspection';
import { PropertyPage, trackBookmark, trackUnbookmark } from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';
import { ToastMsgProps, useToastContext } from '@app/shared/contexts/toast/Toast';

interface UseLikeButton {
  isFavorite: boolean;
  onPress: () => Promise<void>;
}

export const useLikeButton = (realEstateId: string, isFavorite = false): UseLikeButton => {
  const [like] = useAddBookmarkMutation();
  const [dislike] = useDeleteBookmarkMutation();

  const { setValidMsg } = useToastContext();
  const { t } = useTranslation();

  const onPress = async () => {
    const toastProps: ToastMsgProps = { withClose: true };
    if (!isFavorite) {
      await like({ variables: { isPositionedOn: false, realEstateId } });
      trackBookmark({ page: PropertyPage.PROPERTY, realEstateId });
      toastProps.icon = 'HeartFilled';
      toastProps.title = t('searchEngine.toast.favorite.add');
    } else {
      await dislike({ variables: { realEstateId } });
      trackUnbookmark({ page: PropertyPage.PROPERTY, realEstateId });
      toastProps.icon = 'Heart';
      toastProps.title = t('searchEngine.toast.favorite.remove');
    }
    setValidMsg(toastProps);
  };

  return { isFavorite, onPress };
};
