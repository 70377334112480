import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBi = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bi_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bi_svg__a)">
        <Path fill="#E4233B" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0l12 10L0 20V0zM28 0L16 10l12 10V0z"
          fill="#34CD4E"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.962 6.394A4.648 4.648 0 0014 5.334a4.648 4.648 0 00-2.962 1.06l-12.32-8.31-1.492 2.21 12.32 8.31a4.665 4.665 0 000 2.792l-12.32 8.31 1.492 2.21 12.32-8.31A4.648 4.648 0 0014 14.666a4.648 4.648 0 002.962-1.06l12.32 8.31 1.492-2.21-12.32-8.31a4.663 4.663 0 000-2.792l12.32-8.31-1.492-2.21-12.32 8.31z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 8.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.276.943.276.943L14 8.667zM12 12l-.943.276.276-.943-.276-.943.943.277.943-.277-.276.943.276.943L12 12zm3.057.276L16 12l.943.276-.276-.943.276-.943-.943.277-.943-.277.276.943-.276.943z"
          fill="#DF2239"
        />
      </G>
    </Svg>
  );
};

export default SvgBi;
