import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgHt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ht_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ht_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 9.333h28V0H0v9.333z" fill="#112EBC" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28V9.333H0V20z" fill="#E3264A" />
        <Rect
          x={9.333}
          y={6.667}
          width={9.333}
          height={8}
          rx={0.667}
          fill="url(#ht_svg__paint0_linear_4223_10967)"
        />
        <Mask
          id="ht_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={9}
          y={6}
          width={10}
          height={9}
        >
          <Rect x={9.333} y={6.667} width={9.333} height={8} rx={0.667} fill="#fff" />
        </Mask>
        <G mask="url(#ht_svg__b)">
          <Path
            d="M9.667 14.333v-.794l1.919-.96a2.334 2.334 0 011.043-.246h2.742c.362 0 .719.085 1.043.247l1.92.96v.793H9.666z"
            fill="#AABCAE"
            stroke="#366C14"
            strokeWidth={0.667}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 12a2 2 0 100-4 2 2 0 000 4z"
            fill="#D4B872"
          />
          <Path
            d="M11 11.387c0-.28.323-.435.542-.26l1.833 1.467a1 1 0 001.25 0l1.834-1.467a.333.333 0 01.541.26v1.28c0 .184-.15.333-.333.333h-5.334a.333.333 0 01-.333-.333v-1.28z"
            fill="#C28321"
            stroke="#0D3488"
            strokeWidth={0.667}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.569 8.569A.333.333 0 0112.805 8h2.39c.297 0 .446.359.236.569l-1.195 1.195a.333.333 0 01-.472 0L12.57 8.57z"
            fill="#216C30"
          />
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="ht_svg__paint0_linear_4223_10967"
          x1={9.333}
          y1={6.667}
          x2={9.333}
          y2={14.667}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset={1} stopColor="#F0F0F0" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgHt;
