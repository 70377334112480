import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgBb = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bb_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bb_svg__a)">
        <Path fill="url(#bb_svg__paint0_linear_4223_10421)" d="M0 0h28v20H0z" />
        <Path fill="#08379D" d="M13.333 0H28v20H13.333z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h9.333V0H0v20z" fill="#08379D" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 20h9.334V0H9.333v20z"
          fill="#FFCC50"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.746 6.413a.333.333 0 01.554.006l.696 1.065a.333.333 0 01-.279.516h-.015a.333.333 0 00-.332.367l.267 2.666c.017.17.16.3.331.3h.228a.333.333 0 00.235-.096l.47-.465a.333.333 0 00.099-.237V9.47a.333.333 0 00-.098-.235l-.187-.188a.333.333 0 01.13-.552l.953-.318c.24-.08.48.127.433.377l-.491 2.673a.333.333 0 01-.097.18l-1.213 1.166a.333.333 0 01-.23.093H15a.333.333 0 00-.333.333v.667c0 .184-.15.333-.334.333h-.666a.333.333 0 01-.334-.333V13a.333.333 0 00-.333-.333h-.195a.333.333 0 01-.236-.098l-1.164-1.164a.334.334 0 01-.091-.17l-.535-2.672a.333.333 0 01.433-.381l.943.314a.333.333 0 01.13.552l-.187.188A.333.333 0 0012 9.47v1.064c0 .089.036.174.099.237l.47.465a.333.333 0 00.235.096h.228c.17 0 .314-.13.331-.3l.267-2.666A.334.334 0 0013.298 8a.334.334 0 01-.277-.52l.725-1.067z"
          fill="#262626"
        />
      </G>
      <Defs>
        <LinearGradient
          id="bb_svg__paint0_linear_4223_10421"
          x1={0}
          y1={0}
          x2={0}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1132C7" />
          <Stop offset={1} stopColor="#0522A5" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgBb;
