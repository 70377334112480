import { useEffect, useRef, useState } from 'react';

import { Animated } from 'react-native';

type OutputRange = number[] | string[];

/**
 * Handles interpolate with [0, 1] as inputRange to prevent rededouncy in components
 *
 * @param {Animated.Value} animatedTiming
 * @param {OutputRange} outputRange
 * @returns {Animated.AnimatedInterpolation}
 */
export const simpleInterpolate = (animatedTiming: Animated.Value, outputRange: OutputRange) =>
  animatedTiming.interpolate({
    inputRange: [0, 1],
    outputRange,
  });

/**
 * Handles multiple interpolates with [0, 1] as inputRange
 *
 * @param {Animated.Value} animatedTiming
 * @param {OutputRange[]} ranges
 * @returns {Animated.AnimatedInterpolation[]}
 */
export const multipleInterpolates = (animatedTiming: Animated.Value, ...ranges: OutputRange[]) =>
  ranges.map(range => simpleInterpolate(animatedTiming, range));

/**
 * Create the basis for most of the simple animations, will probably evolve to handle callbacks on animation end etc.
 *
 * @param {boolean} defaultState in which state the animation should start for the first time
 * @param {number} duration duration of the animation
 * @returns {object} containing {boolean} isOn, {method} setIsOn, {Animated.Value} animatedTiming, {method} interpolate
 */
export const useAnimation = (defaultState?: boolean, duration = 200) => {
  const animatedTiming = useRef(new Animated.Value(defaultState ? 1 : 0)).current;
  const [isOn, setIsOn] = useState(defaultState);

  useEffect(() => {
    Animated.timing(animatedTiming, {
      duration,
      toValue: isOn ? 1 : 0,
      useNativeDriver: false,
    }).start();
  }, [isOn]);

  const interpolate = (...ranges: OutputRange[]) => multipleInterpolates(animatedTiming, ...ranges);

  return { animatedTiming, interpolate, isOn, setIsOn };
};
