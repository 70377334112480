import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgThumbsDown = (props: SvgProps) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5965 22.4064C13.436 22.7675 13.0778 23.0002 12.6826 23.0002C11.6218 23.0002 10.6044 22.5788 9.85422 21.8287C9.10407 21.0785 8.68265 20.0611 8.68265 19.0002V16.0002L4.02776 16.0002C3.59501 16.0042 3.16649 15.9146 2.77161 15.7374C2.37485 15.5593 2.02155 15.2971 1.73618 14.9689C1.45082 14.6407 1.24021 14.2544 1.11896 13.8368C0.997709 13.4192 0.96871 12.9801 1.03397 12.5502L2.41395 3.55031C2.41399 3.55005 2.41391 3.55057 2.41395 3.55031C2.52258 2.83526 2.886 2.18273 3.43709 1.7143C3.98683 1.24702 4.6865 0.993561 5.40783 1.00024L19.6826 1.00024C20.4783 1.00024 21.2414 1.31631 21.804 1.87892C22.3666 2.44153 22.6826 3.20459 22.6826 4.00024L22.6826 11.0002C22.6826 11.7959 22.3666 12.559 21.804 13.1216C21.2414 13.6842 20.4783 14.0002 19.6826 14.0002H17.3325L13.5965 22.4064ZM15.6826 12.788L12.0748 20.9056C11.7734 20.8095 11.4963 20.6423 11.2684 20.4145C10.8934 20.0394 10.6826 19.5307 10.6826 19.0002L10.6826 15.0002C10.6826 14.448 10.2349 14.0002 9.68265 14.0002H4.02265L4.01132 14.0003C3.86637 14.0019 3.72279 13.9721 3.59054 13.9127C3.45828 13.8533 3.34052 13.7659 3.2454 13.6566C3.15027 13.5472 3.08007 13.4184 3.03965 13.2792C2.99928 13.1401 2.98959 12.9939 3.01126 12.8507C3.01128 12.8506 3.01124 12.8508 3.01126 12.8507L4.39134 3.85018C4.4275 3.61173 4.54863 3.39438 4.73239 3.23818C4.91615 3.08198 5.15018 2.99745 5.39134 3.00018L15.6826 3.00024L15.6826 12.788ZM17.6826 3.00024L17.6826 12.0002H19.6826C19.9479 12.0002 20.2022 11.8949 20.3898 11.7074C20.5773 11.5198 20.6826 11.2655 20.6826 11.0002L20.6826 4.00024C20.6826 3.73503 20.5773 3.48067 20.3898 3.29314C20.2022 3.1056 19.9479 3.00024 19.6826 3.00024H17.6826Z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgThumbsDown;
