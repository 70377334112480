import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgUz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="uz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#uz_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 6.667h28V0H0v6.667z" fill="#04AAC8" />
        <Path d="M0 20h28v-6.667H0V20z" fill="#23C840" />
        <Path
          d="M4.667 5.333a1.99 1.99 0 001.111-.337 1.667 1.667 0 110-3.326 2 2 0 10-1.111 3.663zM8 4.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm2-2a.667.667 0 100-1.334.667.667 0 000 1.334zM13.333 2A.667.667 0 1112 2a.667.667 0 011.333 0zm-.666 3.333a.667.667 0 100-1.333.667.667 0 000 1.333zm-2-.666a.667.667 0 11-1.334 0 .667.667 0 011.334 0z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgUz;
