import React, { useContext } from 'react';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PN from 'awesome-phonenumber';
import { StyleSheet, View } from 'react-native';

import { Button, PhoneInput, Text, Theme, useTheme } from '@app/ui';
import { combinePhone, parsePhoneNumber } from '@app/utils/phone';
import { useTranslation } from '@app/services/translations/translations';
import { useMe } from '@app/shared/hooks/useMe';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { useCallMeBackMutation } from '@app/libs/apollo/introspection';

import { DiscussReinsurance } from './DiscussReinsurance';
import { DiscussBeCalledModal } from '../DiscussBeCalledModal';
import { DiscussBeCalledErrorModal } from '../DiscussBeCalledErrorModal';

type DiscussBeCalledContentProps = {
  setIsModalConfirmationVisible?: (value: boolean) => void;
};

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    gap: { gap: theme.spacing.SIZE_03 },
  });

export const DiscussBeCalledContent: React.FC<DiscussBeCalledContentProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { closeModal, openModal } = useContext(ModalContext);
  const [callMeBack, { loading }] = useCallMeBackMutation();

  const { customer } = useMe();

  const validationSchema = Yup.object().shape({
    phonePrefix: Yup.string().required(),
    phoneSuffix: Yup.string()
      .nullable()
      .test(
        'phoneSuffix',
        t('profile.phoneNumber'),
        (phoneSuffix, ctx) =>
          phoneSuffix === '' || new PN(ctx.parent.phonePrefix + phoneSuffix).isValid()
      ),
  });

  const { phonePrefix: phonePrefixDefault, phoneSuffix: phoneSuffixDefault } = parsePhoneNumber(
    customer?.phoneNumber || ''
  );

  const { control, handleSubmit, getValues, setValue, setError } = useForm({
    defaultValues: {
      phonePrefix: phonePrefixDefault,
      phoneSuffix: phoneSuffixDefault,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async () => {
    const { phonePrefix, phoneSuffix } = getValues();
    const phoneNumber = phonePrefix + phoneSuffix;
    const isValid = new PN(phoneNumber).isValid();
    if (!isValid) {
      setError('phoneSuffix', {
        message: t('discuss.beCalled.phoneNumberInvalid'),
        type: 'manual',
      });
      return;
    }
    const computedPhoneNumber = phoneSuffix ? combinePhone(phonePrefix, phoneSuffix) : undefined;

    if (!computedPhoneNumber) {
      return;
    }

    await callMeBack({ variables: { phoneNumber: computedPhoneNumber } })
      .then(() => {
        closeModal?.();

        openModal?.(ModalKey.BeCalledConfirm, <DiscussBeCalledModal />, {
          position: 'center',
          title: t('discuss.beCalled.title'),
          webSize: 's',
        });
      })
      .catch(() => {
        closeModal?.();

        openModal?.(ModalKey.BeCalledError, <DiscussBeCalledErrorModal />, {
          position: 'center',
          title: t('discuss.beCalledError.title'),
          webSize: 's',
        });
      });
  };

  return (
    <View style={styles.gap}>
      <Text textStyle="Caption1Medium">{t('discuss.beCalled.content')}</Text>

      <Controller
        control={control}
        render={({ field: { onChange: onPrefixChange }, fieldState: { error } }) => (
          <PhoneInput
            value={getValues('phoneSuffix')}
            label={t('profile.phoneLabel')}
            onCountryChange={v => v && onPrefixChange(v.toString())}
            onChange={v => setValue('phoneSuffix', v)}
            error={error?.message}
          />
        )}
        name="phoneSuffix"
      />

      <DiscussReinsurance />

      <Button
        isLoading={loading}
        onPress={handleSubmit(onSubmit)}
        iconName="Phone"
        iconPlacement="left"
      >
        {t('discuss.beCalled.button')}
      </Button>
    </View>
  );
};
