import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { palette, Spacing } from '@app/ui';

import { AVATAR_SIZE } from './utils/advisor.constants';

export const getGeneralStyle = (isMobile: boolean, isGreaterThanTablet: boolean) => {
  return StyleSheet.create({
    component: { paddingHorizontal: isMobile ? 20 : 0 },
    container: {
      maxWidth: isGreaterThanTablet ? 680 : '100%',
      width: '100%',
    },
    contentWrapper: {
      alignItems: isMobile ? 'stretch' : 'center',
      width: '100%',
    },
    infoText: { color: palette.neutral[700], fontStyle: 'italic' },
  });
};

export const getButtonWrapperStyle = (isGreaterThanTablet: boolean): ViewStyle => ({
  alignSelf: isGreaterThanTablet ? 'flex-end' : 'center',
  width: isGreaterThanTablet ? 230 : '100%',
});

export const getAdvisorCardStyle = (
  isGreaterThanTablet: boolean,
  isLaptop: boolean,
  advisorSideLarge: boolean
): Record<string, StyleProp<ViewStyle | TextStyle>> => {
  return StyleSheet.create({
    advisorDescription: { color: palette.neutral[600] },
    advisorName: { flexShrink: 1 },
    advisorSide: {
      alignItems: 'center',
      flexBasis: (isGreaterThanTablet && (advisorSideLarge ? 300 : 250)) || 'auto',
      flexDirection: 'row',
      marginBottom: isGreaterThanTablet ? Spacing.NONE : Spacing.SIZE_04,
      marginRight: isGreaterThanTablet ? 57 : Spacing.NONE,
      maxWidth: isLaptop && advisorSideLarge ? 250 : 'auto',
    },
    appointmentSide: {
      flex: 1,
      width: isGreaterThanTablet ? 'auto' : '100%',
    },
    avatarContainer: {
      backgroundColor: palette.base.white,
      borderRadius: 50,
      height: AVATAR_SIZE,
      marginRight: Spacing.SIZE_05,
      width: AVATAR_SIZE,
    },
    wrapper: {
      alignItems: isGreaterThanTablet ? 'center' : 'flex-start',
      borderColor: palette.neutral[200],
      borderRadius: 8,
      borderWidth: 1,
      flexDirection: isGreaterThanTablet ? 'row' : 'column',
      padding: isGreaterThanTablet ? Spacing.SIZE_07 : Spacing.SIZE_05,
    },
  });
};
