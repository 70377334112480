import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';

import { AuthBridge } from '@app/features/authBridge/AuthBridge';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

type AuthBridgeViewProps = StackScreenProps<PublicStackParamList, PublicNavigatorRoutes.Auth>;

export const AuthBridgeView: React.FC<AuthBridgeViewProps> = ({ route }) => {
  return <AuthBridge deletedAccount={route.params?.deletedAccount} />;
};
