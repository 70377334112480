export const devices = ['mobile', 'tablet', 'laptop', 'desktop', 'wide', 'ultraWide'] as const;
export type Device = (typeof devices)[number];

export interface ScreenValue {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isWide: boolean;
  isUltraWide: boolean;
}
