import { RequiredThemes } from '@app/ui/theme/types';

export const getRadioColor = (
  checked: boolean,
  disabled: boolean,
  error: boolean,
  radioButtonTheme: RequiredThemes['radioButton']
) => {
  if (disabled) {
    return radioButtonTheme.colors.radio['disabled'];
  }
  if (error) {
    return radioButtonTheme.colors.radio['error'];
  }
  if (checked) {
    return radioButtonTheme.colors.radio['active'];
  }

  return radioButtonTheme.colors.radio['default'];
};
