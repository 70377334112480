import React, { useMemo } from 'react';

import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { Theme, useResponsive, useTheme } from '@app/ui';
import { PositioningStatus, RealEstate } from '@app/libs/apollo/introspection';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { getVisibilityRights } from '@app/utils/property';
import { isWeb } from '@app/constants/platform';

import { LoginButtons } from './components/RealEstateLoginButtons';
import { RealEstatePreAppointmentButton } from './components/RealEstatePreAppointmentButton';
import { RealEstateInterestedButton } from './components/RealEstateInterestedButton';
import { PositioningButtons } from './components/RealEstatePositioningButtons';

interface RealEstateTabsButtonsProps {
  handleOnLayout?: (event: LayoutChangeEvent) => void;
  realEstate: RealEstate;
}

const getStyles = (
  theme: Theme,
  userExist: boolean,
  isNativeOrLowerThanDesktop: boolean,
  isLowerThanLaptop: boolean
) =>
  StyleSheet.create({
    buttonsContainerWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      ...(isNativeOrLowerThanDesktop && {
        paddingHorizontal: 0,
        paddingVertical: theme.spacing.SIZE_05,
      }),
    },
    container: {
      backgroundColor: theme.palette.base.white,
      borderTopColor: theme.palette.neutral[200],
      borderTopWidth: isNativeOrLowerThanDesktop ? 1 : 0,
      bottom: userExist && isLowerThanLaptop && isWeb() ? 30 : 0,
      justifyContent: 'center',
      paddingHorizontal: isNativeOrLowerThanDesktop ? theme.spacing.SIZE_06 : 0,
      position: isWeb() ? ('sticky' as 'absolute') : 'absolute',
      width: '100%',
    },
  });

export const RealEstateTabsButtons: React.FC<RealEstateTabsButtonsProps> = ({ realEstate }) => {
  const { customer, stateOfCustomer } = useMe();
  const { canSeeLoginButton } = getVisibilityRights(customer);
  const { isNativeOrResponsive, isLowerThanDesktop, isLowerThanLaptop } = useResponsive();
  const isNativeOrLowerThanDesktop = isNativeOrResponsive || isLowerThanDesktop;

  const theme = useTheme();
  const styles = useMemo(
    () => getStyles(theme, !!customer?.id, isNativeOrLowerThanDesktop, isLowerThanLaptop),
    [theme, customer?.id, isNativeOrLowerThanDesktop, isLowerThanLaptop]
  );

  const positioningStatus = realEstate.metadata?.positioning?.status;

  const userIsPreAppointment = stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT;

  const isRealEstateAvailable =
    !positioningStatus ||
    [PositioningStatus.PrePositioningCancelled, PositioningStatus.Cancelled].includes(
      positioningStatus
    );

  const getButtonSection = () => {
    if (canSeeLoginButton) {
      return <LoginButtons />;
    }

    if (userIsPreAppointment) {
      return <RealEstatePreAppointmentButton realEstateId={realEstate.id} />;
    }

    if (isRealEstateAvailable) {
      return <RealEstateInterestedButton realEstateId={realEstate.id} />;
    }

    return (
      <PositioningButtons
        realEstateId={realEstate.id}
        positioning={realEstate.metadata?.positioning}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.buttonsContainerWrapper}>{getButtonSection()}</View>
    </View>
  );
};
