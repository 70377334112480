import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgOther = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 8c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 014 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 016 4c.55 0 1.02.196 1.412.588C7.804 4.979 8 5.45 8 6c0 .55-.196 1.02-.588 1.412A1.923 1.923 0 016 8zm6 12c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0110 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0112 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0112 20zm-6 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 16c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.021-.588 1.413A1.925 1.925 0 016 20zm0-6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 014 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 10c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.412A1.923 1.923 0 016 14zm6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0110 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0112 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0112 14zm6-6c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0116 6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0118 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0118 8zm-6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0110 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0112 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0112 8zm6 6c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0116 12c0-.55.196-1.021.587-1.413A1.928 1.928 0 0118 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0118 14zm0 6c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0116 18c0-.55.196-1.021.587-1.413A1.928 1.928 0 0118 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0118 20z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgOther;
