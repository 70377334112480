import { MutableRefObject } from 'react';

import { View } from 'react-native';

import { GetProjectQuery } from '@app/libs/apollo/introspection';
import { DetailStepRecord, ProjectCategory } from '@app/features/project/Project.types';
import { transactionDetailSteps } from '@app/features/project/ProjectTabScreen/ProjectTransaction.constant';
import { renovationDetailSteps } from '@app/features/project/ProjectTabScreen/ProjectRenovation.constant';
import { rentingDetailSteps } from '@app/features/project/ProjectTabScreen/ProjectRenting.constant';

export interface ProjectTabScreenProps {
  realEstate: GetProjectQuery['project']['realEstate'];
  projectCategory?: ProjectCategory;
  // FIXME To fill when data will from the back
  projectSteps?: GetProjectQuery['project']['transaction'] | never;
  onExpandSubSteps: (stepId: number) => ({ expanded }: { expanded: boolean }) => void;
  onExpandSubStepsDetails: (
    stepId: number
  ) => (subStepId: number) => ({ expanded }: { expanded: boolean }) => void;
  stageRefs: MutableRefObject<Record<ProjectCategory, View[]>>;
}

export const detailSteps: DetailStepRecord = {
  [ProjectCategory.Transaction]: transactionDetailSteps,
  [ProjectCategory.Renovation]: renovationDetailSteps,
  [ProjectCategory.Renting]: rentingDetailSteps,
};
