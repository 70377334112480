import React, { useMemo, useState } from 'react';

import { View } from 'react-native';

import { useResponsive, Spacing, Text, Spacer, Button, StepStatus, Icon, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { getStageStatus } from '@app/utils/project';
import { projectTimelineSteps } from '@app/features/project/ProjectHeader/projectTimeline';
import { ProjectCategory } from '@app/features/project/Project.types';
import { getIconIndicator } from '@app/features/project/ProjectHeader/ProjectHeader.utils';
import { GetProjectQuery } from '@app/libs/apollo/introspection';
import { Timeline } from '@app/shared/components/timeline/Timeline';

import { ProjectEmptyListProps } from './ProjectList.type';
import { getProjectEmptyListStyles } from './ProjectList.styles';

export const ProjectEmptyList: React.FC<ProjectEmptyListProps> = ({ onPressSearch, testID }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanWide, isMobile } = useResponsive();
  const styles = useMemo(() => getProjectEmptyListStyles(isMobile, theme), [isMobile, theme]);
  const [stepActive, setStepActive] = useState<number>(1);

  const timelineSteps = useMemo(() => {
    const projectSteps = {
      [ProjectCategory.Transaction]: {},
      [ProjectCategory.Renovation]: {},
      [ProjectCategory.Renting]: {},
    };

    return Object.entries(projectSteps).reduce((acc, [category, steps]) => {
      acc[category] = {
        indicator: (
          <Icon
            name={getIconIndicator(category as ProjectCategory)}
            color={theme.palette.base.white}
            height="20"
          />
        ),
        status: StepStatus.Incoming,
        subSteps: projectTimelineSteps['fr-FR'][category].map(({ key }, index) => ({
          status: getStageStatus(
            index + 1,
            (steps as GetProjectQuery['project']['transaction'])?.currentStage
          ),
          title: t(`projectTimeline.${category}.steps.${key}`),
        })),
        title: t(`project.${category}.tab.name`),
      };
      return acc;
    }, {});
  }, [t, theme]);

  return (
    <View testID={testID} style={styles.wrapper}>
      <Text textStyle="Body1Medium" testID="no-project-yet-label">
        {t('projectList.noProjectYet')}
      </Text>
      <Spacer height={Spacing.SIZE_04} />
      <View style={styles.buttonWrapper}>
        <Button textStyle={styles.buttonLabelStyle} onPress={onPressSearch} testID="search-button">
          {t('projectList.searchButton')}
        </Button>
      </View>
      <Spacer height={isLowerThanWide ? Spacing.SIZE_07 : Spacing.SIZE_08} />
      <Text textStyle="Headline2Medium">{t('projectList.investmentStepsTitle')}</Text>
      <Spacer height={Spacing.SIZE_07} />
      <Timeline steps={timelineSteps} activeStep={stepActive} onStepPress={setStepActive} />
      <Spacer height={Spacing.SIZE_00} />
    </View>
  );
};
