import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgBed = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3.419 18.97a.965.965 0 01-.712-.287.965.965 0 01-.288-.712v-5c0-.45.091-.858.275-1.225.183-.367.425-.692.725-.975v-2.8c0-.833.291-1.542.875-2.125a2.893 2.893 0 012.125-.875h4c.383 0 .742.07 1.075.213.333.141.642.337.925.587.283-.25.591-.446.925-.587a2.714 2.714 0 011.075-.213h4c.833 0 1.541.292 2.125.875.583.583.875 1.292.875 2.125v2.8c.3.283.541.608.725.975a2.7 2.7 0 01.275 1.225v5c0 .283-.096.52-.288.712a.965.965 0 01-.712.288.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-1h-16v1c0 .283-.096.52-.287.712a.968.968 0 01-.713.288zm10-8.999h6v-2a.968.968 0 00-.288-.713.967.967 0 00-.712-.287h-4a.967.967 0 00-.712.287.968.968 0 00-.288.713v2zm-8 0h6v-2a.97.97 0 00-.287-.713.97.97 0 00-.713-.287h-4a.97.97 0 00-.713.287.97.97 0 00-.287.713v2zm-1 5h16v-2a.968.968 0 00-.288-.713.967.967 0 00-.712-.287h-14a.97.97 0 00-.713.287.97.97 0 00-.287.713v2zm16 0h-16 16z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgBed;
