import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Animated, View } from 'react-native';

import { Slider } from '@app/ui/molecules/slider/Slider';
import { DotsList } from '@app/ui/molecules/dots-list/DotsList';
import { Hoverable } from '@app/ui/atoms/hoverable/Hoverable';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { ActionButton, ActionButtonProps } from '@app/ui/molecules/ActionButton';

import { getStyle } from './Carousel.styles';
import { CarouselType, DotsPos } from './Carousel.types';

export { CarouselType, DotsPos };

export const Carousel: React.FC<CarouselType> = ({
  children,
  currentStep,
  dots,
  dotSize,
  gesturesEnabled,
  isDark,
  loop,
  numberOfDots,
  onGoStep,
  onNext,
  onPrev,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [shouldDisplayArrows, setShouldDisplayArrows] = useState(false);
  const controlsOpacity = useRef(new Animated.Value(0)).current;

  const { isMobile } = useResponsive();
  const styles = getStyle(dots);

  const handleOnHoverIn = useCallback(() => setIsHovered(true), []);
  const handleOnHoverOut = useCallback(() => setIsHovered(false), []);

  useEffect(() => {
    if (isMobile) {
      setShouldDisplayArrows(false);
      return;
    }

    if (isHovered) {
      controlsOpacity.setValue(0);
      setShouldDisplayArrows(true);
    }

    Animated.timing(controlsOpacity, {
      duration: 150,
      toValue: +isHovered,
      useNativeDriver: false,
    }).start(() => {
      if (!isHovered) {
        setShouldDisplayArrows(false);
      }
    });
  }, [controlsOpacity, isHovered, isMobile]);

  return (
    <Hoverable
      style={styles.container}
      onHoverIn={handleOnHoverIn}
      onHoverOut={handleOnHoverOut}
      onHovering={handleOnHoverIn}
    >
      <View style={styles.containerSlider}>
        {onNext && shouldDisplayArrows ? (
          <Animated.View
            style={[styles.arrow, styles.arrowRight, { opacity: controlsOpacity }]}
            testID="CarouselArrowRightContainer"
          >
            <ActionButton
              size="s"
              leftIconName="ChevronRight"
              onPress={onNext as ActionButtonProps['onPress']}
              testID="CarouselArrowRight"
            />
          </Animated.View>
        ) : null}
        {onPrev && shouldDisplayArrows ? (
          <Animated.View
            style={[styles.arrow, styles.arrowLeft, { opacity: controlsOpacity }]}
            testID="CarouselArrowLeftContainer"
          >
            <ActionButton
              size="s"
              leftIconName="ChevronLeft"
              onPress={onPrev as ActionButtonProps['onPress']}
              testID="CarouselArrowLeft"
            />
          </Animated.View>
        ) : null}
        <Slider
          currentStep={currentStep}
          gesturesEnabled={gesturesEnabled}
          loop={loop}
          onGoStep={onGoStep}
          onNext={onNext}
          onPrev={onPrev}
        >
          {children}
        </Slider>
      </View>
      {!!dots && children.length > 1 && (
        <View style={[styles.dots, styles.dotsPosition]} testID="CarouselDotsListContainer">
          <DotsList
            activeIndex={currentStep}
            isDark={isDark}
            size={dotSize}
            nbDot={children.length}
            numberOfDotsDisplayed={numberOfDots}
          />
        </View>
      )}
    </Hoverable>
  );
};
