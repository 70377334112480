/**
 * Creates a random index generator function that generates random indexes
 * for a given slice size and total count.
 *
 * @param {number} sliceSize - The size of each slice for generating indexes.
 * @returns {function} A function that takes the total count and returns an array
 * of random indexes for the specified slice size. The indexes already generated stay
 * the same between calls if they are below the total count
 *
 * @example
 * const generateRandomIndexes = createRandomIndexGenerator(5);
 * const randomIndexes = generateRandomIndexes(20);
 * console.log(randomIndexes); // [3, 7, 11, 15]
 */

export const createRandomIndexGenerator = (sliceSize: number): ((total: number) => number[]) => {
  const randomIndexesCache: number[] = [];

  return (total: number) => {
    const numberOfRandomIndexes = Math.ceil(total / sliceSize);

    for (let i = 0; i < numberOfRandomIndexes; i++) {
      const existingIndex = randomIndexesCache[i];
      if (!existingIndex || existingIndex > total) {
        randomIndexesCache[i] = Math.floor(Math.random() * (sliceSize % total)) + 1 + sliceSize * i;
      }
    }
    return randomIndexesCache.slice(0, numberOfRandomIndexes);
  };
};
