import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgArea = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 16V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 6h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 18H4zm0-2h16V8h-3v4h-2V8h-2v4h-2V8H9v4H7V8H4v8zm3-4h2-2zm4 0h2-2zm4 0h2-2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgArea;
