import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgListChevronDown = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.3 14.3l-2.6-2.6c-.317-.317-.387-.679-.212-1.087.175-.409.487-.613.937-.613h5.15c.45 0 .762.204.937.613.175.408.105.77-.212 1.087l-2.6 2.6c-.1.1-.208.175-.325.225A.942.942 0 0112 14.6a.941.941 0 01-.375-.075 1.038 1.038 0 01-.325-.225z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgListChevronDown;
