import React from 'react';

import { View } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { Text } from '@app/ui/atoms/Text/Text';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';

import { getTranslationSwitchStyle } from './TranslationSwitch.styles';
import { TranslationSwitchProps } from './TranslationSwitch.types';

export { TranslationSwitchProps };

export const TranslationSwitch: React.FC<TranslationSwitchProps> = ({
  handleChangeLang,
  showTranslation,
  translatedBy,
  showOriginal,
  showTranslated,
  dark,
}) => {
  const theme = useTheme();
  const styles = getTranslationSwitchStyle({ dark, theme });
  return (
    <View style={styles.link}>
      <Icon
        name="LanguageFilled"
        size={theme.spacing.SIZE_05}
        color={dark ? theme.palette.base.white : theme.palette.base.black}
      />
      <Spacer width={theme.spacing.SIZE_02} />
      <Text textStyle="Caption1" style={styles.caption}>
        {translatedBy}
      </Text>
      <Spacer width={theme.spacing.SIZE_02} />
      <Pressable accessibilityRole="button" onPress={handleChangeLang}>
        <Text textStyle="Caption1" style={styles.label}>
          {showTranslation ? showOriginal : showTranslated}
        </Text>
      </Pressable>
    </View>
  );
};
