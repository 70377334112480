import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Avatar } from '@app/ui/atoms/Avatar/Avatar';
import { Text } from '@app/ui/atoms/Text/Text';
import { BorderRadius } from '@app/ui/theme/Sizes';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';
import { Theme } from '@app/ui/theme/types';

import { AvatarWithLabelActivatorProps } from './AvatarWithLabelActivator.types';

export { AvatarWithLabelActivatorProps };

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[200],
      borderRadius: BorderRadius.SIZE_06,
      borderWidth: 1,
      flexDirection: 'row',
      padding: 6,
    },
  });

export const AvatarWithLabelActivator: React.FC<AvatarWithLabelActivatorProps> = ({
  onPress,
  label,
  avatarUrl,
  size = 32,
}) => {
  const theme = useTheme();

  const styles = getStyles(theme);

  return (
    <Pressable onPress={onPress} testID="avatar-with-label-activator">
      <View style={styles.container}>
        {/* if not disabled, press on avatar doesn't trigger the pressable onPress */}
        <Avatar avatarUrl={avatarUrl} size={size} disabled />
        <Spacer width={6} />
        <Text textStyle="Button2">{label}</Text>
      </View>
    </Pressable>
  );
};
