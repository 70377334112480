import { CodeResponse, useGoogleLogin } from '@react-oauth/google';

import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';
import { isFacebookBrowser, isInstagramBrowser } from '@app/utils/detectBrowser.web';
import { identityClient } from '@app/libs/identity/identityClient';
import { config } from '@app/config';
import { getAppUrl } from '@app/utils/location.utils.web';
import { ApollonRoutes } from '@app/constants/routes.web';

import { SSOProfile } from '../authBridge.types';

export const useGoogleSignIn = (
  onSignInSuccess: (accessToken: string, userInfo: SSOProfile) => void,
  onSignInFailure: () => void
): (() => void) => {
  const handleSuccess = async ({ code }: CodeResponse) => {
    try {
      const { idToken, ...userInfo } = await identityClient.authenticateGoogleWeb(code);
      onSignInSuccess(idToken, userInfo);
    } catch (err) {
      ErrorMonitoring.logError(err);
      onSignInFailure();
    }
  };

  const redirectMode = isInstagramBrowser() || isFacebookBrowser();

  return useGoogleLogin({
    flow: 'auth-code',
    onError: onSignInFailure,
    onSuccess: handleSuccess,
    redirect_uri: redirectMode ? getAppUrl() + ApollonRoutes.SSO_REDIRECT : config.IDENTITY_API_URL,
    ux_mode: redirectMode ? 'redirect' : 'popup',
  });
};
