import React, { useEffect } from 'react';

export const SplashScreen: React.FC = () => {
  useEffect(() => {
    const splashscreen = document.getElementById('splash-screen');
    if (splashscreen) {
      splashscreen.style.display = 'flex';
    }

    return () => {
      if (splashscreen) {
        splashscreen.style.display = 'none';
      }
    };
  }, []);

  return null;
};
