import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgRs = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="rs_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#rs_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.333h28V6.667H0v6.666z"
          fill="#17508F"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 6.667h28V0H0v6.667z" fill="#E1444D" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 10c0-.645.344-2.082.537-2.841a.656.656 0 01.638-.492h2.984c.3 0 .563.2.637.49.194.755.537 2.183.537 2.843 0 .745-.5 2.7-.634 3.21a.574.574 0 01-.112.223c-.255.31-1.088 1.234-1.92 1.234-.833 0-1.666-.926-1.921-1.235a.573.573 0 01-.112-.22C6.5 12.704 6 10.763 6 10z"
          fill="#fff"
        />
        <Mask
          id="rs_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={6}
          y={6}
          width={6}
          height={9}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 10c0-.645.344-2.082.537-2.841a.656.656 0 01.638-.492h2.984c.3 0 .563.2.637.49.194.755.537 2.183.537 2.843 0 .745-.5 2.7-.634 3.21a.574.574 0 01-.112.223c-.255.31-1.088 1.234-1.92 1.234-.833 0-1.666-.926-1.921-1.235a.573.573 0 01-.112-.22C6.5 12.704 6 10.763 6 10z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#rs_svg__b)" stroke="#E1444D" strokeWidth={1.333} strokeLinecap="square">
          <Path d="M11.333 7.333L6 14M6 7.333L11.333 14" />
        </G>
      </G>
    </Svg>
  );
};

export default SvgRs;
