import React from 'react';

import { View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { useTheme } from '@app/ui/contexts/config';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Icon } from '@app/ui/atoms/Icon';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';

import { getStyles } from './Subscription.styles';
import { SubscriptionHeaderProps } from './SubscriptionHeader.types';

export { SubscriptionHeaderProps };

/**
 * Component used in subscription page
 */
export const SubscriptionHeader: React.FC<SubscriptionHeaderProps> = ({
  title,
  onPressArrow,
  style = {},
  testID = 'SubscriptionHeader',
  backButtonTestID = 'SubscriptionHeaderBackButton',
  rightZone,
  actionWidth = 24,
  ...rest
}: SubscriptionHeaderProps) => {
  const theme = useTheme();
  const { isLowerThanTablet, isMobile } = useResponsive();

  const styles = getStyles({
    actionWidth,
    isLowerThanTablet,
    isMobile,
    theme,
  });

  return (
    <View style={[styles.container, style]} testID={testID} {...rest}>
      <Pressable
        onPress={onPressArrow}
        disabled={!onPressArrow}
        testID={backButtonTestID}
        style={styles.leftArrow}
      >
        <Icon name="ArrowLeft" />
      </Pressable>
      <Text textStyle="Headline2Medium" style={styles.titleText} numberOfLines={1}>
        {title}
      </Text>
      <View testID="SubscriptionHeaderRightZone" style={styles.rightZone}>
        {rightZone ? rightZone : null}
      </View>
    </View>
  );
};
