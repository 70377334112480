import { CustomDetector } from 'i18next-browser-languagedetector';

import { checkIsDebugModeRequested } from '../helper';

export const debugDetector: CustomDetector = {
  lookup() {
    if (checkIsDebugModeRequested()) {
      return ['cimode'];
    }
    return undefined;
  },
  name: 'debug',
};
