import React from 'react';

import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { StyleSheet, View } from 'react-native';

import { Button, useResponsive } from '@app/ui';
import { Positioning, PositioningStatus } from '@app/libs/apollo/introspection';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { SuggestionsSourcePage } from '@app/services/tracking/constants';
import { usePositioningActions } from '@app/features/homePage/hooks/usePositioningActions';
import { suggestionsCallLaunched } from '@app/features/homePage/Suggestions.utils';

interface PositioningButtonsProps {
  realEstateId: string;
  positioning: Positioning;
}

const styles = StyleSheet.create({
  gap: {
    gap: 10,
  },
});

export const PositioningButtons: React.FC<PositioningButtonsProps> = ({
  realEstateId,
  positioning,
}) => {
  const launchedCall = useReactiveVar(suggestionsCallLaunched);
  const { customer, stateOfCustomer } = useMe();
  const { t } = useTranslation();

  const positioningStatus = positioning?.status;

  const { isNativeOrResponsive, isLowerThanDesktop } = useResponsive();
  const isNativeOrLowerThanDesktop = isNativeOrResponsive || isLowerThanDesktop;

  const shouldShowNotInterestedButton = [
    PositioningStatus.Recommendation,
    PositioningStatus.Pending,
  ].includes(positioningStatus);

  const labelForNotInterestedButton =
    positioningStatus !== PositioningStatus.Recommendation
      ? 'suggestions.suggestionCard.actions.noLongerInterested'
      : 'suggestions.suggestionCard.actions.notInterested';

  const {
    handleApprovePositioning,
    handleCancelPositioning,
    handleNeedCallAfterConfirmPositioning,
  } = usePositioningActions();

  const sourcePage = SuggestionsSourcePage.PROPERTY_PAGE;

  const onPressApprovePositioning = () =>
    handleApprovePositioning(positioning.id, realEstateId)(sourcePage, positioning?.isSuggestion);

  const onPressNeedCallAfterConfirmPositioning = () =>
    handleNeedCallAfterConfirmPositioning(positioning.id)(sourcePage);

  const onPressCancelPositioning = () =>
    handleCancelPositioning(positioning.id, positioning.isSuggestion)(sourcePage);

  const isConfirmed = positioningStatus === PositioningStatus.Confirmed;

  const isNotCancelled = ![
    PositioningStatus.Cancelled,
    PositioningStatus.PrePositioningCancelled,
  ].includes(positioningStatus);

  return (
    <View style={styles.gap}>
      {!!shouldShowNotInterestedButton && (
        <Button
          onPress={onPressCancelPositioning}
          testID="button-not-interested"
          iconName="ThumbsDown"
          iconPlacement="left"
          variant="tertiary"
          textStyle={{ marginRight: -8 }}
          size="m"
        >
          {!isNativeOrLowerThanDesktop ? t(labelForNotInterestedButton) : ''}
        </Button>
      )}

      {!!isConfirmed && (
        <Button
          onPress={onPressNeedCallAfterConfirmPositioning}
          variant="secondary"
          iconName="Phone"
          iconPlacement="left"
          disabled={launchedCall.includes(positioning.id)}
          testID="button-need-call-after-confirm"
          wrapperStyle={{ ...(isNativeOrLowerThanDesktop && { flex: 1 }) }}
          size="m"
        >
          {t('suggestions.suggestionCard.actions.needCallAfterConfirm', {
            advisorName: customer.owner.firstName,
          })}
        </Button>
      )}

      {!!isNotCancelled && (
        <Button
          disabled={positioningStatus !== PositioningStatus.Recommendation}
          onPress={onPressApprovePositioning}
          testID="button-interested-from-suggestion"
          iconName={positioningStatus === PositioningStatus.Recommendation ? 'ThumbsUp' : null}
          iconPlacement="left"
          wrapperStyle={{ ...(isNativeOrLowerThanDesktop && { flex: 1 }) }}
          size="m"
        >
          {positioningStatus !== PositioningStatus.Recommendation
            ? stateOfCustomer === StateOfCustomer.POST_SIGNED_MANDATE
              ? t('shared.expertContacted')
              : t('shared.advisorContacted')
            : t('suggestions.suggestionCard.actions.interested')}
        </Button>
      )}
    </View>
  );
};
