import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgCoffeeMaker = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M6 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 6 2h14v2h-2v2c0 .283-.096.52-.288.713A.967.967 0 0 1 17 7H9a.968.968 0 0 1-.712-.287A.968.968 0 0 1 8 6V4H6v16h4.05a4.868 4.868 0 0 1-1.5-1.712A4.807 4.807 0 0 1 8 16v-5h10v5c0 .833-.183 1.596-.55 2.288A4.867 4.867 0 0 1 15.95 20H20v2H6Zm7-3c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 16 16v-3h-6v3c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 13 19Zm0-9c.283 0 .52-.096.713-.287A.967.967 0 0 0 14 9a.967.967 0 0 0-.287-.713A.967.967 0 0 0 13 8a.968.968 0 0 0-.713.287A.968.968 0 0 0 12 9c0 .283.096.52.287.713.192.191.43.287.713.287Z"
    />
  </Svg>
);
export default SvgCoffeeMaker;
