import React from 'react';

import { View } from 'react-native';

import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Icon } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { useTheme } from '@app/ui/contexts/config';

import { getStyle } from './FoldableItem.styles';
import { FoldableItemType } from './FoldableItem.type';

export { FoldableItemType };
export const FoldableItem: React.FC<FoldableItemType> = ({
  icon,
  title,
  subtitle,
  open = false,
  ...rest
}) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const styles = getStyle({ isMobile, theme });
  return (
    <View style={styles.container} {...rest}>
      <Icon style={[styles.icon, styles.child]} name={icon} />
      <View style={[styles.blockTitle, styles.child]}>
        <Text textStyle="Headline3Medium">{title}</Text>
        {!!subtitle && (
          <>
            <Spacer height={theme.spacing.SIZE_02} />
            <Text textStyle="Body2" numberOfLines={2} style={styles.subtitle}>
              {subtitle}
            </Text>
          </>
        )}
      </View>
      <Icon
        name={open ? 'ChevronUp' : 'ChevronDown'}
        style={styles.icon}
        color={theme.palette.neutral[600]}
      />
    </View>
  );
};
