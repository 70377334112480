import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgBj = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bj_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bj_svg__a)">
        <G filter="url(#bj_svg__filter0_d_4223_10449)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28V9.333H0V20z" fill="#F12641" />
        </G>
        <G filter="url(#bj_svg__filter1_d_4223_10449)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 9.333h28V0H0v9.333z" fill="#FFD648" />
        </G>
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h10.667V0H0v20z" fill="#17A668" />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgBj;
