import React, { useCallback, useMemo } from 'react';

import { View } from 'react-native';

import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { CounterActivator } from '@app/ui/molecules/dropdown-activator/counter-activator/CounterActivator';
import { DropdownFooterActions } from '@app/ui/molecules/dropdown-footer/dropdown-footer-actions/DropdownFooterActions';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';

import { FilterBudgetDropdownProps } from './FilterBudgetDropdown.types';
import { getStyles } from './FilterBudgetDropdown.styles';
import { formatActivatorValues } from './FilterBudgetDropdown.utils';

export { FilterBudgetDropdownProps };

export const FilterBudgetDropdown: React.FC<FilterBudgetDropdownProps> = ({
  maxHeight = 350,
  minWidth = 296,
  values,
  placeholder,
  onCallbackClose,
  minField,
  maxField,
  buttonLabel,
  onGhostClick,
  onPrimaryClick,
  disabled,
  ...rest
}) => {
  const styles = getStyles();

  const list = useMemo(
    () => (
      <View style={styles.listContainer} testID="filter-budget-dropdown-list-container">
        <View style={styles.fields} testID="filter-budget-dropdown-min-field-container">
          {minField}
        </View>
        <View style={styles.fields} testID="filter-budget-dropdown-max-field-container">
          {maxField}
        </View>
      </View>
    ),
    [maxField, minField, styles.listContainer, styles.fields]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return (
        <CounterActivator
          onPress={toggleList}
          placeholder={placeholder}
          values={values}
          open={isOpen}
          formatActivatorValues={formatActivatorValues}
          disabled={disabled}
          noCounter
        />
      );
    },
    [placeholder, values, disabled]
  );

  const getFooter = useCallback(
    (closeList: () => void) => {
      return (
        <DropdownFooterActions
          containerStyle={styles.footer}
          buttonLabel={buttonLabel}
          onGhostClick={onGhostClick}
          onPrimaryClick={onPrimaryClick}
          closeList={closeList}
        />
      );
    },
    [buttonLabel, onGhostClick, onPrimaryClick, styles.footer]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => (
      <DropdownListContainer list={list} footer={getFooter(closeList)} maxHeight={maxHeightList} />
    ),
    [getFooter, list]
  );

  return (
    <DropdownV2
      {...rest}
      renderList={renderList}
      maxHeight={maxHeight}
      minWidth={minWidth}
      fitParent={false}
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};
