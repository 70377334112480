import * as React from 'react';

import Svg, { SvgProps, G, Mask, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const University = ({ color, ...props }: SvgProps) => (
  <Svg width={50} height={50} viewBox="3 1 50 50" fill="none" {...props}>
    <G>
      <Mask id="b" fill="#fff">
        <Path
          fillRule="evenodd"
          d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
          clipRule="evenodd"
        />
      </Mask>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? '#C70'}
        d="m30.12 40.803-.123-.992-.323.04-.238.222.684.73Zm-4.778-.075.683-.73-.224-.21-.304-.048-.155.988ZM27.772 43l-.684.73.683.64.683-.64-.683-.73ZM43 25.967c0 7.031-5.61 12.928-13.003 13.844l.245 1.985C38.52 40.77 45 34.122 45 25.966h-2ZM28 12c8.348 0 15 6.315 15 13.967h2C45 17.087 37.325 10 28 10v2ZM13 25.967C13 18.315 19.652 12 28 12v-2c-9.325 0-17 7.087-17 15.967h2ZM25.497 39.74C18.355 38.623 13 32.837 13 25.967h-2c0 7.967 6.187 14.498 14.188 15.749l.309-1.976Zm2.957 2.53-2.429-2.272-1.366 1.46 2.43 2.272 1.365-1.46Zm.982-2.197-2.348 2.197 1.366 1.46 2.349-2.197-1.367-1.46Z"
        mask="url(#b)"
      />
      <Path
        fill={color ?? '#C70'}
        d="M27.538 34a1.643 1.643 0 0 1-.818-.215l-4.3-2.32a1.722 1.722 0 0 1-.667-.635 1.71 1.71 0 0 1-.237-.892v-4.126l-2.064-1.139a.837.837 0 0 1-.345-.322.87.87 0 0 1-.107-.43c0-.157.036-.3.108-.43a.836.836 0 0 1 .344-.322l7.268-3.954a1.643 1.643 0 0 1 1.237-.161c.136.036.269.09.398.16l8.193 4.47a.778.778 0 0 1 .334.312.864.864 0 0 1 .118.441v5.501a.832.832 0 0 1-.247.613.833.833 0 0 1-.613.247.833.833 0 0 1-.613-.247.832.832 0 0 1-.247-.613v-5.071l-1.72.945v4.126c0 .33-.08.627-.237.892-.158.265-.38.477-.667.634l-4.301 2.321a1.643 1.643 0 0 1-.817.215Zm0-6.898 5.892-3.18-5.892-3.181-5.893 3.18 5.893 3.18Zm0 5.179 4.3-2.321v-3.245l-3.462 1.913a1.769 1.769 0 0 1-1.269.161 1.677 1.677 0 0 1-.408-.162l-3.462-1.912v3.245l4.3 2.32Z"
      />
    </G>
    <Defs></Defs>
  </Svg>
);
export { University };
