import { StyleSheet } from 'react-native';

export const getStyles = () =>
  StyleSheet.create({
    footer: {
      flexDirection: 'row',
      padding: 20,
    },
    listContainer: { padding: 20 },
  });
