import React, { Suspense } from 'react';
import 'setimmediate'; // Import to remove when react-native-reanimated updates work well

import { createRoot } from 'react-dom/client';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ApolloProvider } from '@apollo/client';

import { ResponsiveContextProvider } from '@app/ui';
import ErrorBoundary from '@app/views/errorBoundary/ErrorBoundary.web';
import { AphroditeConfigBridge } from '@app/shared/contexts/aphroditeI18NBridge/aphroditeConfigBridge';
import { I18nProvider } from '@app/shared/contexts/i18n/I18nProvider';
import { ToastProvider } from '@app/shared/contexts/toast/Toast';
import { useInitialized } from '@app/shared/hooks/useInitialized.web';
import { SplashScreen } from '@app/features/splashScreen/SplashScreen.web';
import { ToastManager } from '@app/core/toast-manager/ToastManager';
import { getApolloClient } from '@app/libs/apollon/apollonClient';
import { AccountInformations } from '@app/core/accountInformations/AccountInformations';
import { useGetConfig } from '@app/config/useGetConfig.hook';
import { ConfigErrorBoundary } from '@app/views/config-error/ConfigErrorBoundary';

import './style/style.scss';
import { RootNavigator } from './navigation/RootNavigator';
import { ModalManagerProvider } from './shared/contexts/modal-manager/ModalManagerProvider';
import { ModalDisplayer } from './shared/contexts/modal-manager/ModalDisplayer';
import { BannerManagerProvider } from './shared/contexts/banner-manager/BannerManagerProvider';
import { UnleashFeatureFlagsProvider } from './libs/unleash/UnleashFeatureFlagsProvider';

const App = () => {
  const { isLoaded, error: configError } = useGetConfig();
  useInitialized(isLoaded);

  if (!isLoaded) {
    return null;
  }

  const client = getApolloClient();

  return (
    <Suspense fallback={<SplashScreen />}>
      <I18nProvider>
        <UnleashFeatureFlagsProvider>
          <ApolloProvider client={client}>
            <SafeAreaProvider>
              <AphroditeConfigBridge>
                <ErrorBoundary>
                  <ResponsiveContextProvider>
                    <ConfigErrorBoundary configError={configError}>
                      <ModalManagerProvider>
                        <BannerManagerProvider>
                          <ToastProvider>
                            <RootNavigator />
                            <AccountInformations />
                            <ToastManager />
                          </ToastProvider>
                          <ModalDisplayer />
                        </BannerManagerProvider>
                      </ModalManagerProvider>
                    </ConfigErrorBoundary>
                  </ResponsiveContextProvider>
                </ErrorBoundary>
              </AphroditeConfigBridge>
            </SafeAreaProvider>
          </ApolloProvider>
        </UnleashFeatureFlagsProvider>
      </I18nProvider>
    </Suspense>
  );
};

const root = createRoot(document.getElementById('masteos-root'));

root.render(<App />);
