import { Locale, compareDesc, format, startOfDay } from 'date-fns';
import { TFunction } from 'react-i18next';

import { getDiffDays } from '@app/utils/date/getDiffDays.utils';

export const getExpirationDateString = ({
  translate,
  expirationDate,
  locale,
  full = false,
}: {
  translate: TFunction;
  expirationDate: Date;
  locale: Locale;
  full?: boolean;
}): string | null => {
  const nowDate = new Date();

  if (compareDesc(startOfDay(nowDate), startOfDay(expirationDate)) === -1) {
    return null;
  }

  const diffDays = getDiffDays(nowDate, expirationDate);

  if (diffDays === 0) {
    return translate(
      full ? 'suggestions.expiredDate.today' : 'suggestions.expiredDate.today_short'
    );
  }

  if (diffDays === 1) {
    return translate(
      full ? 'suggestions.expiredDate.tomorrow' : 'suggestions.expiredDate.tomorrow_short'
    );
  }

  return full
    ? translate('suggestions.expiredDate.daysWithDate', {
        date: format(expirationDate, 'd MMMM y', { locale }),
        days: diffDays,
      })
    : translate('suggestions.expiredDate.days_short', { days: diffDays });
};
