import React, { useMemo } from 'react';

import { View } from 'react-native';

import { useResponsive, useTheme } from '@app/ui';

import { getStyleSheet } from './TabBar.styles';
import { Tab } from './TabView.type';
import { TabLabel } from './TabLabel';

export interface TabBarProps {
  tabs: Tab[];
  index: number;
  setIndex: (index: number) => void;
}

// TODO: move to Aphrodite
export const TabBar: React.FC<TabBarProps> = ({ tabs, index, setIndex }) => {
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const styles = useMemo(
    () => getStyleSheet(theme, isLowerThanDesktop),
    [theme, isLowerThanDesktop]
  );

  const memoList = useMemo(
    () =>
      tabs.map((tab, i) => (
        <TabLabel
          key={tab.key}
          title={tab.title}
          index={i}
          focused={index === i}
          setIndex={() => setIndex(i)}
          testID={tab.key}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs, index]
  );

  return <View style={styles.tabBar}>{memoList}</View>;
};
