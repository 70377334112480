import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgDownload = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.5 19.9c-1.517 0-2.812-.525-3.887-1.575C1.538 17.275 1 15.992 1 14.475c0-1.3.392-2.458 1.175-3.475S3.983 9.333 5.25 9.05c.283-1.2.992-2.342 2.125-3.425C8.508 4.542 9.717 4 11 4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v6.05l.9-.875a.934.934 0 01.688-.275c.275 0 .512.1.712.3a.948.948 0 01.275.7.948.948 0 01-.275.7l-2.6 2.6c-.1.1-.208.17-.325.212a1.1 1.1 0 01-.375.063 1.1 1.1 0 01-.375-.063.871.871 0 01-.325-.212l-2.6-2.6a.976.976 0 01-.287-.688.928.928 0 01.287-.712.979.979 0 01.687-.288.975.975 0 01.713.263l.9.875V6c-1.267.233-2.25.846-2.95 1.837C7.35 8.829 7 9.85 7 10.9h-.5c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 003 14.4c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 006.5 17.9h12c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775c-.483-.483-1.075-.725-1.775-.725H17v-2c0-.8-.183-1.546-.55-2.238a5.642 5.642 0 00-1.046-1.403 1.271 1.271 0 01-.404-.9c0-.829.866-1.362 1.503-.831a6.84 6.84 0 011.422 1.635A6.892 6.892 0 0119 10.9c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0123 15.4c0 1.25-.437 2.313-1.312 3.188S19.75 19.9 18.5 19.9h-12z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgDownload;
