import { StyleSheet, ViewStyle } from 'react-native';

import { RequiredThemes, Theme } from '@app/ui/theme/types';
import { getSizesMap } from '@app/ui/atoms/bubble-count/BubbleCount.styled';
import { Sizes as BubbleCountSizes } from '@app/ui/atoms/bubble-count/BubbleCount.types';

import { ActionButtonSizeVariant } from './ActionButton.types';

const light = 'inLight';
const variant = 'primary';

type ColorPropName =
  | 'background'
  | 'text'
  | 'solidBorderColor'
  | 'dashedBorderColor'
  | 'greyBackground';

interface StyledActionButtonProps {
  theme: Theme;
  componentTheme: RequiredThemes['actionButton'];
  size: ActionButtonSizeVariant;
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
  withSolidBorder: boolean;
  withShadow: boolean;
  withDashedBorder: boolean;
  withGreyBackground: boolean;
  onlyContainsIcon: boolean;
}

interface ActionButtonColorVariant {
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
  componentTheme: StyledActionButtonProps['componentTheme'];
  propName: ColorPropName;
}

interface ActiveBorderState {
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
  componentTheme: StyledActionButtonProps['componentTheme'];
}

interface Border {
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
  componentTheme: StyledActionButtonProps['componentTheme'];
  withSolidBorder: boolean;
  withShadow: boolean;
  withDashedBorder: boolean;
}

interface Shadow {
  componentTheme: StyledActionButtonProps['componentTheme'];
  withSolidBorder: boolean;
  withShadow: boolean;
  withDashedBorder: boolean;
}

interface DashedBorder {
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
  componentTheme: StyledActionButtonProps['componentTheme'];
  withSolidBorder: boolean;
  withShadow: boolean;
  withDashedBorder: boolean;
}

interface GreyBackground {
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
  componentTheme: StyledActionButtonProps['componentTheme'];
  withSolidBorder: boolean;
  withShadow: boolean;
  withDashedBorder: boolean;
  withGreyBackground: boolean;
}

interface IconColor {
  componentTheme: RequiredThemes['actionButton'];
  pressed: boolean;
  hovered: boolean;
  active: boolean;
  disabled: boolean;
}

export const bubbleCountSize = {
  l: BubbleCountSizes.L,
  m: BubbleCountSizes.M,
  s: BubbleCountSizes.M,
  xl: BubbleCountSizes.L,
  xs: BubbleCountSizes.S,
  xxs: BubbleCountSizes.S,
};

const getColor = ({
  pressed,
  hovered,
  active,
  disabled,
  componentTheme,
  propName,
}: ActionButtonColorVariant) => {
  const colors = componentTheme[variant][light][propName];
  let color = colors.default;

  if (active) {
    color = colors.active;
  }

  if (hovered) {
    color = colors.hover;
  }

  if (pressed) {
    color = colors.pressed;
  }

  if (disabled) {
    color = colors.disabled;
  }

  return color;
};

const getActiveStateBorder = ({
  pressed,
  hovered,
  active,
  disabled,
  componentTheme,
}: ActiveBorderState) => {
  if (active && !hovered && !pressed && !disabled) {
    return {
      borderColor: componentTheme[variant][light].solidBorderColor.active,
      borderWidth: componentTheme[variant][light].borderWidth,
    };
  }

  return {};
};

const showBorder = ({
  pressed,
  hovered,
  active,
  disabled,
  componentTheme,
  withSolidBorder,
  withShadow,
  withDashedBorder,
}: Border) => {
  const { borderWidth } = componentTheme[variant][light];
  const borderColor = getColor({
    active,
    componentTheme,
    disabled,
    hovered,
    pressed,
    propName: 'solidBorderColor',
  });

  return withSolidBorder && !withShadow && !withDashedBorder
    ? {
        borderColor,
        borderWidth,
      }
    : {};
};

const showShadow = ({ componentTheme, withSolidBorder, withShadow, withDashedBorder }: Shadow) => {
  return withShadow && !withSolidBorder && !withDashedBorder
    ? componentTheme[variant][light].shadow
    : {};
};

const showDashedBorder = ({
  pressed,
  hovered,
  active,
  disabled,
  componentTheme,
  withSolidBorder,
  withShadow,
  withDashedBorder,
}: DashedBorder) => {
  const { borderWidth } = componentTheme[variant][light];
  const borderStyle = componentTheme[variant][light].borderStyle as ViewStyle['borderStyle'];
  const borderColor = getColor({
    active,
    componentTheme,
    disabled,
    hovered,
    pressed,
    propName: 'dashedBorderColor',
  });

  return !withSolidBorder && !withShadow && withDashedBorder
    ? {
        borderColor,
        borderStyle,
        borderWidth,
      }
    : {};
};

const showGreyBackground = ({
  pressed,
  hovered,
  active,
  disabled,
  componentTheme,
  withSolidBorder,
  withShadow,
  withDashedBorder,
  withGreyBackground,
}: GreyBackground) => {
  const backgroundColor = getColor({
    active,
    componentTheme,
    disabled,
    hovered,
    pressed,
    propName: 'greyBackground',
  });

  return withGreyBackground && !withSolidBorder && !withShadow && !withDashedBorder
    ? {
        backgroundColor,
      }
    : {};
};

const bubbleCountTopDevideNumber = (size: ActionButtonSizeVariant) =>
  size === 'xs' || size === 'xxs' ? 4 : 2;
const bubbleCountRightDevideNumber = 4;

export const getStyle = ({
  size,
  theme,
  componentTheme,
  pressed,
  hovered,
  active,
  disabled,
  withSolidBorder,
  withShadow,
  withDashedBorder,
  withGreyBackground,
  onlyContainsIcon,
}: StyledActionButtonProps) => {
  const bubbleCountTop =
    Number(getSizesMap(theme).get(bubbleCountSize[size])) / bubbleCountTopDevideNumber(size);
  const bubbleCountRight =
    Number(getSizesMap(theme).get(bubbleCountSize[size])) / bubbleCountRightDevideNumber;

  return StyleSheet.create({
    bubbleCountContainer: {
      position: 'absolute',
      right: -bubbleCountRight,
      top: -bubbleCountTop,
    },
    button: {
      alignItems: 'center',
      backgroundColor: getColor({
        active,
        componentTheme,
        disabled,
        hovered,
        pressed,
        propName: 'background',
      }),
      borderRadius: componentTheme.size[size].container.borderRadius,
      flexDirection: 'row',
      height: componentTheme.size[size].container.height,
      justifyContent: onlyContainsIcon ? 'center' : 'space-between',
      padding: componentTheme.size[size].container.padding,
      ...(onlyContainsIcon
        ? { padding: 0, width: componentTheme.size[size].container.height }
        : undefined),
      ...showBorder({
        active,
        componentTheme,
        disabled,
        hovered,
        pressed,
        withDashedBorder,
        withShadow,
        withSolidBorder,
      }),
      ...getActiveStateBorder({
        active,
        componentTheme,
        disabled,
        hovered,
        pressed,
      }),
      ...showShadow({
        componentTheme,
        withDashedBorder,
        withShadow,
        withSolidBorder,
      }),
      ...showDashedBorder({
        active,
        componentTheme,
        disabled,
        hovered,
        pressed,
        withDashedBorder,
        withShadow,
        withSolidBorder,
      }),
      ...showGreyBackground({
        active,
        componentTheme,
        disabled,
        hovered,
        pressed,
        withDashedBorder,
        withGreyBackground,
        withShadow,
        withSolidBorder,
      }),
    },
    iconStyle: {
      ...componentTheme[variant].iconStyle,
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 0,
    },
    label: {
      ...componentTheme.size[size].text,
      color: getColor({
        active,
        componentTheme,
        disabled,
        hovered,
        pressed,
        propName: 'text',
      }),
      paddingHorizontal: componentTheme.size[size].textPadding,
    },
  });
};

export const getIconColor = ({ componentTheme, pressed, hovered, active, disabled }: IconColor) =>
  getColor({
    active,
    componentTheme,
    disabled,
    hovered,
    pressed,
    propName: 'text',
  });

export const getWrapperStyle = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingBottom: theme.spacing.SIZE_01,
      paddingLeft: theme.spacing.SIZE_01,
      paddingRight: theme.spacing.SIZE_01,
      paddingTop: theme.spacing.SIZE_01,
    },
  });
