import React, { ReactElement, useCallback, useMemo } from 'react';

import { ColorValue, Platform, StyleProp, StyleSheet, TextStyle, View } from 'react-native';

import {
  palette,
  Switch,
  Text,
  TextProps,
  IconPopoverOrModal,
  Theme,
  useTheme,
  IconName,
  Icon,
} from '@app/ui';
import { isWeb } from '@app/constants/platform';

export enum Importance {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface RealEstateAttributeProps {
  icon?: IconName;
  label?: string;
  value?: string | number | null;
  isOdd?: boolean;
  tooltip?: { title?: string; description: string; onOpen: () => void };
  inputSwitch?: {
    value: boolean;
    setValue: (v: boolean) => void;
    disabled?: boolean;
    testID?: string;
  };
  importance?: Importance;
  testID?: string;
  valueColor?: ColorValue;
  renderValue?: (value: string | number) => ReactElement;
}

const getStyles = (theme: Theme, importance: Importance) =>
  StyleSheet.create({
    commonText: {
      flexShrink: 1,
      flexWrap: 'wrap',
    },
    container: {
      ...(importance === Importance.MEDIUM && {
        borderBottomColor: theme.palette.neutral[100],
        borderBottomWidth: 1,
      }),
      flex: 1,
      paddingVertical: theme.spacing.SIZE_04,
    },
    lineLabel: {
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 1,
      width: 0,
    },
    tooltipWrapper: {
      paddingHorizontal: theme.spacing.SIZE_03,
      paddingVertical: theme.spacing.SIZE_02,
    },
    valueWrapper: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    wrapper: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
      justifyContent: 'space-between',
    },
  });

export const RealEstateAttribute: React.FC<RealEstateAttributeProps> = ({
  icon,
  label,
  value,
  isOdd,
  tooltip,
  inputSwitch,
  importance = Importance.MEDIUM,
  valueColor,
  testID,
  renderValue,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme, importance), [importance, theme]);

  const getTextProps: () => {
    labelProps: {
      textStyle: TextProps['textStyle'];
      style?: StyleProp<TextStyle>;
    };
    valueProps: {
      textStyle: TextProps['textStyle'];
      style?: StyleProp<TextStyle>;
    };
  } = useCallback(() => {
    if (importance === Importance.LOW) {
      return {
        labelProps: {
          textStyle: 'Body2',
        },
        valueProps: {
          textStyle: 'Body2',
        },
      };
    }

    if (importance === Importance.MEDIUM) {
      return {
        labelProps: {
          style: { color: palette.neutral[900] },
          textStyle: 'Body2',
        },
        valueProps: {
          style: { color: palette.neutral[900] },
          textStyle: 'Body2Medium',
        },
      };
    }

    return {
      labelProps: {
        style: { color: palette.neutral[900] },
        textStyle: Platform.select({ native: 'Body2Medium', web: 'Headline3Medium' }),
      },
      valueProps: {
        style: { color: palette.neutral[900] },
        textStyle: 'Body2Medium',
      },
    };
  }, [importance]);

  const { labelProps, valueProps } = useMemo(() => getTextProps(), [getTextProps]);

  return useMemo(() => {
    if (!label && !value) {
      return null;
    }

    return (
      <View style={styles.container}>
        <View
          style={[
            { backgroundColor: isOdd && isWeb() ? palette.neutral[50] : undefined },
            styles.wrapper,
          ]}
        >
          <View style={styles.lineLabel}>
            {inputSwitch ? (
              <Switch
                checked={inputSwitch.value}
                onCheck={inputSwitch.setValue}
                disabled={inputSwitch.disabled ?? false}
                label={label}
                testID={inputSwitch.testID}
              />
            ) : (
              !!label && (
                <View
                  style={{ alignItems: 'center', flexDirection: 'row', gap: theme.spacing.SIZE_03 }}
                >
                  {!!icon && <Icon name={icon} color={theme.palette.neutral[800]} size={20} />}
                  <Text {...labelProps} style={[labelProps?.style, styles.commonText]}>
                    {label}
                  </Text>
                </View>
              )
            )}
            {!!tooltip && (
              <View style={styles.tooltipWrapper}>
                <IconPopoverOrModal
                  title={tooltip.title || ''}
                  content={tooltip.description}
                  iconName="Info"
                  iconSize="s"
                  onOpen={tooltip.onOpen}
                />
              </View>
            )}
          </View>

          <View style={styles.valueWrapper}>
            {!!value &&
              (renderValue ? (
                renderValue(value)
              ) : (
                <Text
                  textStyle={valueProps.textStyle}
                  style={[valueProps.style, { color: valueColor }]}
                  testID={testID}
                >
                  {value}
                </Text>
              ))}
          </View>
        </View>
      </View>
    );
  }, [label, value, inputSwitch, valueColor, renderValue]);
};
