import { isArray } from 'lodash';

import { FilterListSelectorOptionType } from '@app/ui';

export const SELECTOR_ALL_KEY = 'all';

export type SelectorSelectValueArgsProps = {
  item: FilterListSelectorOptionType;
  list: FilterListSelectorOptionType[];
  onChange: (value: unknown) => void;
  value: string | string[];
};
export type SelectorSelectValueProps = (args: SelectorSelectValueArgsProps) => void;

/**
 * Utils for select value for selector
 */
export const selectValue: SelectorSelectValueProps = ({ item, list, onChange, value }) => {
  const indexAll = list.findIndex(listItem => listItem.key === SELECTOR_ALL_KEY);
  if (indexAll === -1) {
    onChange(item.key);
    return;
  }

  const values = isArray(value) ? value : [value];

  if (item.key === SELECTOR_ALL_KEY) {
    onChange(
      item.selected
        ? []
        : list.filter(element => element.key !== SELECTOR_ALL_KEY).map(element => element.key)
    );

    return;
  }

  const currentItem = item.key;
  const isSelected = values && !!values.find(val => val === currentItem);

  let newValue = [];

  if (isSelected) {
    newValue = values.filter(val => val !== currentItem);
  } else {
    newValue = [...values, item.key];
  }

  onChange(newValue.filter(element => element.key !== SELECTOR_ALL_KEY));
};

export type SelectorFormatOptionArgsProps = {
  optionList: FilterListSelectorOptionType[];
  value: string | string[];
};
export type SelectorFormatOptionProps = (
  args: SelectorFormatOptionArgsProps
) => FilterListSelectorOptionType[];

/**
 * Utils for format option list for selector
 */
export const formatOption: SelectorFormatOptionProps = ({ optionList, value }) => {
  if (!optionList?.length) {
    return optionList;
  }

  const list = value
    ? optionList.map(item => {
        return {
          ...item,
          selected: value.includes(item.key),
        };
      })
    : optionList;

  const allIndex = list.findIndex(item => item.key === SELECTOR_ALL_KEY);

  if (allIndex !== -1) {
    return list.map((item, index) => {
      if (index !== allIndex && item.selected) {
        return item;
      }

      return {
        ...item,
        selected: value.length === list.length - 1,
      };
    });
  }

  return list;
};

export const formatSelectorValues = (
  list: FilterListSelectorOptionType[],
  values: string[]
): string[] => {
  if (!values || !list || !list.length) {
    return [];
  }
  return values.map(element => list.find(item => item.key === element)?.label ?? '');
};
