import { useRoute } from '@react-navigation/native';

type StrToStr = (s: string) => string | null;

export const useQueryParam: StrToStr = paramName => {
  const { params } = useRoute();
  if (params && paramName in params) {
    return params[paramName];
  }
  return null;
};

export const parseParams = (search: string): StrToStr => {
  const urlParams = new URLSearchParams(search);
  return paramName => {
    const param = urlParams.get(paramName);
    return param?.length ? decodeURIComponent(param) : null;
  };
};

export const getLocationSearchParam = (paramName: string): string | null => {
  if (!window?.location) {
    return null;
  }
  return parseParams(window.location.search)(paramName);
};
