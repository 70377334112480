// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import styled from '@emotion/styled';

import { ErrorProps } from '../RadioButton.types';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing.SIZE_03}px;
  margin-bottom: ${({ theme }) => theme.spacing.SIZE_02}px;
  align-items: center;
  cursor: pointer;
`;

export const ErrorMessage = styled.p<ErrorProps>`
  margin: 0px;
  color: ${({ componentTheme }) => componentTheme.label.error};
  font-family: ${({ componentTheme }) => componentTheme.label.errorTextStyle.fontFamily};
  font-size: ${({ componentTheme }) => componentTheme.label.errorTextStyle.fontSize}px;
  font-weight: ${({ componentTheme }) => componentTheme.label.errorTextStyle.fontWeight};
  line-height: ${({ componentTheme }) => componentTheme.label.errorTextStyle.lineHeight}px;
  letter-spacing: ${({ componentTheme }) => componentTheme.label.errorTextStyle.letterSpacing}px;
  margin-bottom: ${({ theme }) => theme.spacing.SIZE_03}px;
`;
