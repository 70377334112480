import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgModeCoolOff = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M13 17.825V21c0 .283-.095.52-.287.712a.967.967 0 0 1-.712.288.968.968 0 0 1-.713-.288.968.968 0 0 1-.287-.712v-3.15l-2.55 2.525a.973.973 0 0 1-.713.262.977.977 0 0 1-.687-.287.948.948 0 0 1-.275-.7c0-.283.091-.517.275-.7l3.524-3.55-2-2-3.524 3.55a.977.977 0 0 1-.688.288.93.93 0 0 1-.712-.288.948.948 0 0 1-.275-.7c0-.283.091-.517.275-.7L6.15 13H3a.967.967 0 0 1-.713-.287.968.968 0 0 1-.287-.713c0-.283.095-.52.287-.713A.967.967 0 0 1 3.001 11h3.175l-4.7-4.7a.93.93 0 0 1-.288-.712 1.02 1.02 0 0 1 .313-.713c.2-.2.437-.3.712-.3.275 0 .513.1.713.3l16.974 17c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.973.973 0 0 1-.712-.3L13 17.825ZM15 13h-1.124L11 10.125V9L7.05 5.05a.977.977 0 0 1-.288-.688.93.93 0 0 1 .288-.712.948.948 0 0 1 .7-.275c.283 0 .516.092.7.275L11 6.15V3c0-.283.095-.52.287-.712A.968.968 0 0 1 12.001 2c.283 0 .52.096.712.288.192.191.288.429.288.712v3.15l2.55-2.525c.2-.183.437-.27.712-.263a.977.977 0 0 1 .688.288.948.948 0 0 1 .274.7.948.948 0 0 1-.274.7L13 9v2h2l3.95-3.95a.977.977 0 0 1 .687-.287.93.93 0 0 1 .712.287.948.948 0 0 1 .275.7.948.948 0 0 1-.274.7L17.85 11H21c.283 0 .52.096.712.287.192.192.287.43.287.713s-.095.52-.287.713A.967.967 0 0 1 21 13h-3.15l2.525 2.55c.1.1.171.212.213.337.042.125.063.25.063.375a.911.911 0 0 1-.075.363c-.05.117-.125.225-.226.325a.948.948 0 0 1-.7.275.949.949 0 0 1-.7-.275L15 13Z"
    />
  </Svg>
);
export default SvgModeCoolOff;
