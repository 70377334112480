import { useMemo } from 'react';

import { useGalleryContext } from '@app/features/realEstate/gallery/GalleryProvider';
import { PropertyPageTypeGallery, trackGalleryVisit } from '@app/services/tracking/trackTracking';
import { SliderImageObject } from '@app/features/realEstate/gallery/gallery.types';
import { getImageUrl } from '@app/utils/getImageUrl';
import { SoldRealEstateV2 } from '@app/libs/apollo/introspection';

import { getHeaderSliderImages } from '../utils/getHeaderSliderImages.utils';

interface UseImagesGallery {
  coverImageUrl: string;
  imagesCount: number;
  imagesGrid: SliderImageObject[];
  openMainGallery: (index: number) => () => void;
}

const IMAGES_TO_SHOW = 4;

export const useImagesGallery = (realEstate: SoldRealEstateV2): UseImagesGallery => {
  const { openGallery } = useGalleryContext();

  const headerSliderImages = useMemo(
    () =>
      getHeaderSliderImages(realEstate).map(({ url, ...image }) => ({
        ...image,
        url: getImageUrl(url),
      })),
    [realEstate]
  );

  const openMainGallery = (index: number) => () => {
    trackGalleryVisit({
      countGalleryImages: headerSliderImages.length,
      propertyId: realEstate.id,
      typeGallery: PropertyPageTypeGallery.MAIN,
    });
    openGallery({
      defaultIndex: index,
      images: headerSliderImages,
      url3d: realEstate.url3D,
    });
  };

  return {
    coverImageUrl: headerSliderImages[0].url,
    imagesCount: headerSliderImages.length,
    imagesGrid: headerSliderImages.slice(1, IMAGES_TO_SHOW + 1),
    openMainGallery,
  };
};
