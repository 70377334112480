import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { Theme, useTheme } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { RealEstateFinancialHeader } from './RealEstateFinancialHeader';
import { Importance, RealEstateAttribute } from '../../../components/RealEstateAttribute';
import { areEqual, differentFuncParameters } from '../utils/realEstateFinances.utils';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    border: {
      borderBottomColor: theme.palette.neutral[300],
      borderBottomWidth: 1,
      marginBottom: theme.spacing.SIZE_04,
      marginTop: theme.spacing.SIZE_04,
      width: '100%',
    },
    container: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      paddingBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_03,
      width: '100%',
    },
  });

interface RealEstateFinancialResultsProps {
  financialModel: FinancialModel;
  withShortTermRental: boolean;
}

export const RealEstateFinancialResults: React.FC<RealEstateFinancialResultsProps> = React.memo(
  ({ financialModel, withShortTermRental }) => {
    const { t } = useRealEstateTranslation();
    const theme = useTheme();
    const styles = useMemo(() => getStyles(theme), [theme]);

    const annualTotalIncome = currencyFormatter(financialModel.annualTotalIncome, 0);
    const annualCashflow = currencyFormatter(financialModel.monthlyCashflow * 12, 0);
    const monthlyCashflow = currencyFormatter(financialModel.monthlyCashflow, 0);
    const annualLoanPayments = currencyFormatter(-financialModel.annualLoanPayments, 0);
    const sumCharges = currencyFormatter(-financialModel.annualTotalExpenses, 0);

    const monthlyCashflowKey =
      financialModel.monthlyCashflow >= 0 ? 'monthlyCashflow' : 'cumultiveSavingsBeforeTax';
    const annualCashflowKey =
      financialModel.monthlyCashflow * 12 >= 0
        ? 'annualCashflow'
        : 'annualCumultiveSavingsBeforeTax';

    const valueColor = withShortTermRental ? theme.palette.tint.purple[500] : undefined;

    return (
      <View style={styles.container}>
        <RealEstateFinancialHeader title={t(`shared.financialTool.result`)} icon="TrendingUp" />
        <RealEstateAttribute
          label={t('shared.financialTool.annualTotalIncome2')}
          value={annualTotalIncome}
          importance={Importance.LOW}
          isOdd
          valueColor={valueColor}
        />
        <RealEstateAttribute
          label={t('shared.financialTool.sumCharges')}
          value={sumCharges}
          importance={Importance.LOW}
          valueColor={valueColor}
        />
        <RealEstateAttribute
          label={t('shared.financialTool.annualLoanPayments')}
          value={annualLoanPayments}
          importance={Importance.LOW}
          isOdd
          testID="property-financial-line-annual-loan-payments"
        />

        <View style={styles.border} />

        <RealEstateAttribute
          label={t(`shared.financialTool.${annualCashflowKey}`)}
          value={annualCashflow}
          importance={Importance.HIGH}
          valueColor={valueColor}
        />
        <RealEstateAttribute
          label={t(`shared.financialTool.${monthlyCashflowKey}`)}
          value={monthlyCashflow}
          importance={Importance.LOW}
          valueColor={valueColor}
        />
      </View>
    );
  },
  areEqual(['withShortTermRental'], (prevState, nextState) => {
    return differentFuncParameters(prevState, nextState, [
      'annualTotalIncome',
      'annualTotalExpenses',
      'monthlyCashflow',
      'annualLoanPayments',
    ]);
  })
);

RealEstateFinancialResults.displayName = 'PropertyFinancialResults';
