import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ma_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ma_svg__a)">
        <Path fill="#E33B44" d="M0 0h28v20H0z" />
        <Path
          d="M14.387 11.797L14 11.521l-.387.276-1.906 1.359.703-2.233.143-.453-.382-.283-1.88-1.392 2.34-.021.475-.005.15-.45.744-2.22.743 2.22.151.45.475.005 2.34.02-1.88 1.393-.382.283.143.453.703 2.233-1.906-1.359z"
          stroke="#0A6135"
          strokeWidth={1.333}
        />
      </G>
    </Svg>
  );
};

export default SvgMa;
