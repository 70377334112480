import React, { useCallback, useContext, useEffect } from 'react';

import { startCase } from 'lodash';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Text, useResponsive, useTheme } from '@app/ui';
import {
  trackMandateSignatureBannerClosed,
  trackMandateSignatureModalDisplayed,
} from '@app/services/tracking/trackTracking';
import { storage } from '@app/services/storage/storage';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { InAppSignatureModal } from '@app/shared/components/Modals/in-app-signature/InAppSignatureModal';
import { BannerContext } from '@app/shared/contexts/banner-manager/BannerManagerProvider';
import { BannerKey } from '@app/shared/contexts/banner-manager/BannerManager.types';
import { Banner } from '@app/shared/components/Banner/Banner';
import { MandateStatus } from '@app/libs/apollo/introspection';

import { useMe } from '../useMe';
import { SignMandateReminderType } from './useSignMandateState.hook.types';
import { shouldDisplayBanner, shouldDisplayModal } from './useSignMandateState.hook.utils';

const WEB_BANNER_HEIGHT = 72;
const MOBILE_BANNER_HEIGHT = 112;
const POLLING_INTERVAL = 10000;

export const useSignMandateStateHook = (): void => {
  const theme = useTheme();
  const { titleColor } = StyleSheet.create({
    titleColor: { color: theme.palette.system.primary[500] },
  });

  const { customer, refetch } = useMe();
  const { openModal } = useContext(ModalContext);
  const { openBanner, closeBanner, isDisplayed: isBannerVisible } = useContext(BannerContext);

  const { isLowerThanLaptop } = useResponsive();

  const handleOpenModal = useCallback(
    (source: string) => () => {
      const firstName = startCase(customer?.firstName);

      openModal?.(ModalKey.SignMandateInApp, <InAppSignatureModal />, {
        title: (
          <Text textStyle="Title3Medium">
            <Trans
              i18nKey="modalMandateSignature.title"
              values={{ firstName }}
              components={{
                color: <Text textStyle="Title3Medium" style={titleColor} />,
              }}
            />
          </Text>
        ),
        webSize: 'l',
      });

      trackMandateSignatureModalDisplayed(source);
    },
    [customer?.firstName, openModal, titleColor]
  );

  const handleOnBannerClose = useCallback(async () => {
    const data = await storage.readObject<SignMandateReminderType>('signMandateReminder');
    await storage.saveObject('signMandateReminder', { ...data, banner: new Date().toISOString() });

    closeBanner?.();

    trackMandateSignatureBannerClosed();
  }, [closeBanner]);

  const initOpen = useCallback(() => {
    if (!customer?.searchMandate) {
      return;
    }

    const cantBeShown = customer.searchMandate?.status !== MandateStatus.Pending;
    if (cantBeShown) {
      isBannerVisible && closeBanner?.();
      return;
    }

    const bannerHeight = isBannerVisible
      ? isLowerThanLaptop
        ? MOBILE_BANNER_HEIGHT
        : WEB_BANNER_HEIGHT
      : 0;

    storage.readObject<SignMandateReminderType>('signMandateReminder').then(signMandateReminder => {
      shouldDisplayModal(signMandateReminder) && handleOpenModal('default')();

      shouldDisplayBanner(signMandateReminder) &&
        openBanner?.(
          BannerKey.SignMandateInApp,
          <Banner
            bannerHeight={bannerHeight}
            onClose={handleOnBannerClose}
            handleBannerOpenModal={handleOpenModal('banner')}
          />
        );
    });
  }, [
    customer,
    isBannerVisible,
    isLowerThanLaptop,
    closeBanner,
    handleOpenModal,
    openBanner,
    handleOnBannerClose,
  ]);

  useEffect((): (() => void) | undefined => {
    // Poll the customer to check if the mandate is signed
    if (customer?.searchMandate?.status === MandateStatus.Pending) {
      const interval = setInterval(() => {
        try {
          refetch();
        } catch (error) {
          console.error('Error while polling customer mandate status', error);
        }
      }, POLLING_INTERVAL);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [customer?.searchMandate, refetch]);

  useEffect(() => {
    initOpen();
    // We need initOpen is launched when customer is loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.searchMandate]);
};
