import { AppEnvironment, NodeEnv } from './config.enum';
import { Config, WebConfig, WebDefaultConfig } from './config.types';
import { getOrganizedConfig } from './config.utils';

declare let process: {
  env: WebConfig;
};

export const getDefaultConfig = (): WebDefaultConfig => {
  return {
    APOLLON_API_URL: process.env.APOLLON_API_URL,
    ENVIRONMENT: process.env.ENVIRONMENT,
    ...(process.env.IDENTITY_API_URL && { IDENTITY_API_URL: process.env.IDENTITY_API_URL }),
  };
};

export const getValues = (config: WebConfig): Config => {
  const defaultConfig = getDefaultConfig();
  const injected = getOrganizedConfig<WebConfig>(defaultConfig, config);

  const {
    WEB_SEGMENT_API_HOST,
    WEB_SEGMENT_API_KEY,
    WEB_SEGMENT_CDN_HOST,
    WEB_SENTRY_DSN,
    ...sharedConfig
  } = injected;

  return {
    ...sharedConfig,
    SEGMENT_API_HOST: WEB_SEGMENT_API_HOST,
    SEGMENT_API_KEY: WEB_SEGMENT_API_KEY,
    SEGMENT_CDN_HOST: WEB_SEGMENT_CDN_HOST,
    SENTRY_DSN: WEB_SENTRY_DSN,
    isDev: injected.ENVIRONMENT === AppEnvironment.Development,
    isProduction:
      injected.NODE_ENV === NodeEnv.Production &&
      injected.ENVIRONMENT === AppEnvironment.Production,
    isStaging:
      injected.ENVIRONMENT === AppEnvironment.Staging && injected.NODE_ENV === NodeEnv.Production,
  };
};
