import React, { CSSProperties, useContext } from 'react';

import { getWeakId } from '@app/ui/utils/idGen';
import { RequiredThemes } from '@app/ui/theme/types';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { radioButtonTheme } from '@app/ui/atoms/RadioButton/radioButton.theme';

import { GroupContainer, ErrorMessage } from './RadioItem.styles';
import { RadioButton } from './RadioButton';
import { RadioItemProps, RadioSize } from '../RadioButton.types';
import { RadioContext } from './RadioGroup';

const getLabelStyle = (
  componentTheme: RequiredThemes['radioButton'],
  size: RadioSize,
  disabled: boolean
) => ({
  ...componentTheme.label.textStyles[size],
  color: componentTheme.colors.text[disabled ? 'disabled' : 'default'],
  cursor: 'pointer',
  lineHeight: `${componentTheme.label.lineHeight}px`,
  marginLeft: componentTheme.label.marginLeft,
});

export const RadioItem = (props: RadioItemProps): JSX.Element => {
  const componentTheme = useComponentTheme('radioButton', radioButtonTheme);
  const {
    label,
    value,
    checked,
    disabled = false,
    error,
    testID,
    size = 'l',
    errorMessage = '',
  } = props;
  const { onValueChange } = useContext(RadioContext);
  const id = getWeakId();

  return (
    <>
      <GroupContainer>
        <RadioButton
          testID={testID}
          checked={checked}
          disabled={disabled}
          error={error}
          onPress={() => onValueChange(value)}
          value={value}
          id={id}
          size={size}
        />
        {!!label && (
          <label
            htmlFor={id}
            style={getLabelStyle(componentTheme, size, disabled) as CSSProperties}
          >
            {label}
          </label>
        )}
        {!!errorMessage && (
          <ErrorMessage componentTheme={componentTheme}>{errorMessage}</ErrorMessage>
        )}
      </GroupContainer>
    </>
  );
};
