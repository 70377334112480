import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSimilar = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 013 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 015 3h3a.97.97 0 01.713.287A.97.97 0 019 4c0 .283-.096.52-.287.712A.968.968 0 018 5H5v14h3c.283 0 .521.096.713.288A.967.967 0 019 20c0 .283-.096.52-.287.712A.968.968 0 018 21H5zm7 2a.965.965 0 01-.712-.288A.965.965 0 0111 22V2c0-.283.096-.521.288-.713A.967.967 0 0112 1a.97.97 0 01.713.287A.97.97 0 0113 2v20c0 .283-.096.52-.287.712A.968.968 0 0112 23zm7-18V3c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413h-2zm0 8v-2h2v2h-2zm0 8v-2h2c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0119 21zm0-12V7h2v2h-2zm0 8v-2h2v2h-2zm-4 4v-2h2v2h-2zm0-16V3h2v2h-2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSimilar;
