import React, { PropsWithChildren } from 'react';

import { View } from 'react-native';

import { withLabel } from '@app/ui/hoc/with-label/withLabel';

import { RadioGroupProps, RadioContextType } from '../RadioButton.types';

export const RadioContext = React.createContext<RadioContextType>({
  onValueChange: () => undefined,
  value: '',
});

export const RadioGroupStandalone: React.FC<PropsWithChildren<RadioGroupProps>> = ({
  value,
  onValueChange,
  children,
}) => (
  <RadioContext.Provider value={{ onValueChange, value }}>
    <View accessible accessibilityRole="radiogroup">
      {children}
    </View>
  </RadioContext.Provider>
);

export const RadioGroup = withLabel(RadioGroupStandalone);
