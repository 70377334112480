import { FinancialModel } from '@masteos/agora';

import { FinancialModelAction, FinancialModelActions } from './actions';

interface FinancialModelComputationParams {
  evaluationRate: number;
  isPaymentCash: boolean;
  loanDown: number;
  loanDuration: number;
  loanInterestRate: number;
  resellingYear: number;
  withBrokerageFees: boolean;
  withPropertyManagement: boolean;
  withPremiumPropertyManagement: boolean;
  withShortTermRental: boolean;
  annualAvgOccupancyInMonths?: number;
  shortTermOccupancyRate?: number;
  shortTermNightlyRent?: number;
}

interface FinancialModelComputationState {
  financialModelComputation: FinancialModel | null;
  defaultFinancialModelComputation: FinancialModel | null;
  params: FinancialModelComputationParams;
}

export const initialState: FinancialModelComputationState = {
  defaultFinancialModelComputation: null,
  financialModelComputation: null,
  params: {
    annualAvgOccupancyInMonths: undefined,
    evaluationRate: 0,
    isPaymentCash: true,
    loanDown: 0,
    loanDuration: 0,
    loanInterestRate: 0,
    resellingYear: 0,
    shortTermNightlyRent: 0,
    shortTermOccupancyRate: undefined,
    withBrokerageFees: true,
    withPremiumPropertyManagement: false,
    withPropertyManagement: false,
    withShortTermRental: false,
  },
};

export const financialModelReducer = (
  state: FinancialModelComputationState,
  action: FinancialModelAction
): FinancialModelComputationState => {
  switch (action.type) {
    case FinancialModelActions.UPDATE_FINANCIAL_MODEL:
      return {
        ...state,
        financialModelComputation: action.payload,
      };
    case FinancialModelActions.SET_LOAN_DOWN:
      return {
        ...state,
        params: {
          ...state.params,
          loanDown: action.payload,
        },
      };
    case FinancialModelActions.SET_IS_PAYMENT_CASH:
      return {
        ...state,
        params: {
          ...state.params,
          isPaymentCash: action.payload,
        },
      };
    case FinancialModelActions.SET_EVALUATION_RATE:
      return {
        ...state,
        params: {
          ...state.params,
          evaluationRate: action.payload,
        },
      };
    case FinancialModelActions.SET_LOAN_DURATION:
      return {
        ...state,
        params: {
          ...state.params,
          loanDuration: action.payload,
        },
      };
    case FinancialModelActions.SET_LOAN_INTEREST_RATE:
      return {
        ...state,
        params: {
          ...state.params,
          loanInterestRate: action.payload,
        },
      };
    case FinancialModelActions.SET_RESELLING_YEAR:
      return {
        ...state,
        params: {
          ...state.params,
          resellingYear: action.payload,
        },
      };
    case FinancialModelActions.SET_WITH_BROKERAGE_FEES:
      return {
        ...state,
        params: {
          ...state.params,
          withBrokerageFees: action.payload,
        },
      };
    case FinancialModelActions.SET_WITH_PROPERTY_MANAGEMENT:
      return {
        ...state,
        params: {
          ...state.params,
          withPremiumPropertyManagement: action.payload
            ? false
            : state.params.withPremiumPropertyManagement,
          withPropertyManagement: action.payload,
        },
      };
    case FinancialModelActions.SET_WITH_PREMIUM_PROPERTY_MANAGEMENT:
      return {
        ...state,
        params: {
          ...state.params,
          withPremiumPropertyManagement: action.payload,
          withPropertyManagement: action.payload ? false : state.params.withPropertyManagement,
        },
      };
    case FinancialModelActions.SET_WITH_SHORT_TERM_RENTAL:
      return {
        ...state,
        params: {
          ...state.params,
          withShortTermRental: action.payload,
        },
      };
    case FinancialModelActions.INIT:
      return {
        defaultFinancialModelComputation: action.payload.fm,
        financialModelComputation: action.payload.fm,
        params: {
          annualAvgOccupancyInMonths: action.payload.fm.annualTurnoverIndice,
          evaluationRate: (action.payload.compProps.financialData?.evaluationRate || 0) * 100,
          isPaymentCash: action.payload.fm.DEFAULT_IS_PAYMENT_CASH,
          loanDown: action.payload.fm.defaultLoanDownPayment,
          loanDuration: action.payload.fm.DEFAULT_LOAN_DURATION,
          loanInterestRate: action.payload.fm.DEFAULT_LOAN_INTEREST_RATE,
          resellingYear: action.payload.fm.DEFAULT_RESELLING_YEAR,
          shortTermOccupancyRate: action.payload.compProps.financialData?.shortTermOccupancyRate,
          withBrokerageFees: action.payload.fm.DEFAULT_WITH_BROKERAGE_FEES,
          withPremiumPropertyManagement: action.payload.fm.DEFAULT_WITH_PREMIUM_PROPERTY_MANAGEMENT,
          withPropertyManagement: action.payload.fm.DEFAULT_WITH_PROPERTY_MANAGEMENT,
          withShortTermRental: false,
        },
      };
    case FinancialModelActions.SET_ANNUAL_AVG_OCCUPANCY_IN_MONTHS:
      return {
        ...state,
        params: {
          ...state.params,
          annualAvgOccupancyInMonths: action.payload,
        },
      };
    case FinancialModelActions.SET_SHORT_TERM_OCCUPANCY_RATE:
      return {
        ...state,
        params: {
          ...state.params,
          shortTermOccupancyRate: action.payload,
        },
      };
    case FinancialModelActions.SET_SHORT_TERM_NIGHTLY_RENT:
      return {
        ...state,
        params: {
          ...state.params,
          shortTermNightlyRent: action.payload,
        },
      };
    default:
      return state;
  }
};
