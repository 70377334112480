import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCv = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="cv_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#cv_svg__a)" clipRule="evenodd">
        <Path fillRule="evenodd" d="M0 10.667h28V0H0v10.667zM0 20h28v-5.333H0V20z" fill="#0C49AE" />
        <Path fillRule="evenodd" d="M0 14.667h28v-4H0v4z" fill="#fff" />
        <Path fillRule="evenodd" d="M0 13.333h28V12H0v1.333z" fill="#CD232E" />
        <Path
          d="M10.667 16.667a4 4 0 100-8 4 4 0 000 8z"
          stroke="#F7D035"
          strokeWidth={1.333}
          strokeLinecap="round"
          strokeDasharray="0.67 2"
        />
      </G>
    </Svg>
  );
};

export default SvgCv;
