import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tg_svg__a)">
        <Path fill="#FFD44D" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0h16v4H12V0zm0 8h16v4H12V8zm16 8H0v4h28v-4z"
          fill="#159A74"
        />
        <Path fill="#ED1F45" d="M0 0h12v12H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 7.56L3.649 9.236l.867-2.754-2.32-1.718 2.887-.026L6 2l.917 2.738 2.887.026-2.32 1.718.867 2.754L6 7.56z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgTg;
