import React, { useCallback, useContext } from 'react';

import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Avatar, Button, palette, Text, useResponsive } from '@app/ui';
import { fromS3ID } from '@app/utils/from-s3id';
import { useTranslation } from '@app/services/translations/translations';
import {
  TakeAppointmentSource,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import { useMe } from '@app/shared/hooks/useMe';
import { ProfileNavigatorRoutes, ProfileStackParamList } from '@app/navigation/types/routes';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';

import { AVATAR_SIZE } from './utils/advisor.constants';
import { getAdvisorCardStyle, getButtonWrapperStyle } from './advisor.styles';
import { DiscussTakeAppointmentContent } from '../discuss/components/DiscussTakeAppointmentContent';
import { DiscussModalTitle } from '../discuss/components/DiscussModalTitle';

interface AdvisorCardProps {
  advisorAvatar?: string;
  advisorFullName?: string;
  hasAppointment: boolean;
  appointmentFormattedDate: string | null;
}

type AdvisorCardNavigationProp = StackNavigationProp<
  ProfileStackParamList,
  ProfileNavigatorRoutes.ProfileAdvisor
>;

export const AdvisorCard: React.FC<AdvisorCardProps> = ({
  advisorAvatar,
  advisorFullName,
  hasAppointment,
  appointmentFormattedDate,
}) => {
  const { t } = useTranslation();
  const { isAssetManagementConsultant } = useMe();
  const { isGreaterThanTablet, isLaptop } = useResponsive();

  const advisorSideLarge = !advisorFullName;
  const { openModal } = useContext(ModalContext);
  const navigation = useNavigation<AdvisorCardNavigationProp>();

  const styles = getAdvisorCardStyle(isGreaterThanTablet, isLaptop, advisorSideLarge);

  const onPress = useCallback(() => {
    trackTakeAdvisorAppointmentClick({ sourcePage: TakeAppointmentSource.advisorPage });
    openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentContent />, {
      position: 'center',
      title: <DiscussModalTitle />,
      webSize: 'l',
    });
  }, [navigation]);

  return (
    <View style={styles.wrapper} testID="advisor-card-view">
      <View style={styles.advisorSide}>
        <View style={styles.avatarContainer}>
          <Avatar avatarUrl={fromS3ID(advisorAvatar, 'w-300')} size={AVATAR_SIZE} isAdvisor />
        </View>
        <View style={styles.advisorName}>
          {advisorFullName ? (
            <>
              <Text textStyle="Headline3Medium">{advisorFullName}</Text>
              <Text textStyle="Body3" style={styles.advisorDescription}>
                {t('advisor.description')}
              </Text>
            </>
          ) : (
            <Text textStyle="Body3" style={styles.noAdvisorLabel}>
              {t('advisor.takeFirstAppointment')}
            </Text>
          )}
        </View>
      </View>
      {!isAssetManagementConsultant && (
        <View style={styles.appointmentSide}>
          {hasAppointment ? (
            <Text textStyle="Body2" style={{ color: palette.neutral[900] }}>
              {appointmentFormattedDate
                ? t('advisor.scheduledAppointment', {
                    date: appointmentFormattedDate.toLowerCase(),
                  })
                : t('advisor.appointmentJustBooked')}
            </Text>
          ) : (
            <Button
              onPress={onPress}
              wrapperStyle={getButtonWrapperStyle(isGreaterThanTablet)}
              testID="take-appointment-btn"
            >
              {t('advisor.takeAppointment')}
            </Button>
          )}
        </View>
      )}
    </View>
  );
};
