import React from 'react';

import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { Icon } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';

import { markerHeight, markerWidth, getLocationMarkerStyle } from './LocationMarkerIcon.styles';
import { LocationMarkerIconProps } from './LocationMarkerIcon.types';

export const LocationMarkerIcon: React.FC<LocationMarkerIconProps> = () => {
  const theme = useTheme();
  const styles = getLocationMarkerStyle(theme);

  return (
    <View>
      <View style={styles.marker} />
      <View style={styles.homeIcon}>
        <Icon name="Home" color={theme.palette.base.white} size={20} />
      </View>
      <Svg
        viewBox="0 0 20 20"
        fill={theme.palette.neutral[900]}
        width={markerWidth}
        height={markerHeight}
      >
        <Path
          fillRule="evenodd"
          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </Svg>
    </View>
  );
};
