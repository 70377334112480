import { ListItemUnionProps } from '@app/ui';

import { SearchEngineSortStrategy } from './SearchEngineSort.types';

export { getSingleValue } from '../search-engine-filters-horizontal/SearchEngineFiltersHorizontal.utils';

export const getSortStrategyList = (t: (arg: string) => string): ListItemUnionProps[] => {
  const list = Object.values(SearchEngineSortStrategy)
    // TODO: remove the filter when FirstPublishedAtDesc strategy will be removed (deprecated now)
    .filter(x => x !== 'firstPublishedAt_desc')
    .map(
      v =>
        ({
          key: v,
          label: t(`searchEngine.sort.strategy.${v}`),
        } as ListItemUnionProps)
    );

  return list;
};
