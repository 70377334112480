export enum DotSize {
  'S' = 'S',
  'M' = 'M',
  'L' = 'L',
}

export type DotType = {
  active?: boolean;
  isDark?: boolean;
  size?: DotSize;
};
