import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgWallet = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 20c-1.1 0-2.042-.392-2.825-1.175C2.392 18.042 2 17.1 2 16V8c0-1.1.392-2.042 1.175-2.825C3.958 4.392 4.9 4 6 4h12c1.1 0 2.042.392 2.825 1.175C21.608 5.958 22 6.9 22 8v8c0 1.1-.392 2.042-1.175 2.825C20.042 19.608 19.1 20 18 20H6zM6 8h12c.367 0 .717.042 1.05.125.333.083.65.217.95.4V8c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0018 6H6c-.55 0-1.02.196-1.412.588A1.923 1.923 0 004 8v.525c.3-.183.617-.317.95-.4A4.315 4.315 0 016 8zm-1.85 3.25l11.125 2.7c.15.033.3.033.45 0 .15-.033.292-.1.425-.2l3.475-2.9a2.158 2.158 0 00-.7-.612A1.861 1.861 0 0018 10H6c-.433 0-.812.112-1.137.337-.325.225-.563.53-.713.913z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgWallet;
