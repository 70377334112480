import * as React from 'react';

import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const SvgUmbrella = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#umbrella_svg__clip0_4874_4169)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.894 4.134a12.05 12.05 0 0120.101 7.771A1 1 0 0123 13H13v6a2 2 0 004 0 1 1 0 112 0 4 4 0 11-8 0v-6H1a1 1 0 01-.995-1.095 12.05 12.05 0 013.89-7.771zM21.84 11A10.05 10.05 0 002.16 11H21.84z"
          fill={props.color}
        />
      </G>
      <Defs>
        <ClipPath id="umbrella_svg__clip0_4874_4169">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SvgUmbrella;
