import React from 'react';

import { View } from 'react-native';

import {
  RenovateWithMasteosListItem,
  RenovateWithMasteosListItemProps,
} from './RenovateWithMasteosListItem';

type RenovateWithMasteosListProps = {
  list: Pick<RenovateWithMasteosListItemProps, 'title' | 'description' | 'iconName'>[];
};

export const RenovateWithMasteosList: React.FC<RenovateWithMasteosListProps> = ({ list }) => {
  return (
    <View>
      {list.map((item, index) => (
        <RenovateWithMasteosListItem
          key={item.title}
          {...item}
          isLast={list.length - 1 === index}
        />
      ))}
    </View>
  );
};
