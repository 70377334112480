import { DetailStepRecord, ProjectCategory } from '@app/features/project/Project.types';

export const transactionDetailSteps: DetailStepRecord[ProjectCategory.Transaction] = {
  'fr-FR': [
    {
      content: 'project.transaction.detailSteps.1.content',
      requiredDocuments: [
        { label: 'project.requiredDocuments.financingCapacity' },
        { label: 'project.requiredDocuments.signedMandate' },
      ],
      stageNumber: 1,
      subSteps: [
        {
          content: 'project.transaction.detailSteps.1.subSteps.1.content',
          title: 'project.transaction.detailSteps.1.subSteps.1.title',
        },
        {
          content: 'project.transaction.detailSteps.1.subSteps.2.content',
          title: 'project.transaction.detailSteps.1.subSteps.2.title',
        },
        {
          content: 'project.transaction.detailSteps.1.subSteps.3.content',
          title: 'project.transaction.detailSteps.1.subSteps.3.title',
        },
      ],
      title: 'project.transaction.detailSteps.1.title',
    },
    {
      content: 'project.transaction.detailSteps.2.content',
      estimatedDuration: 10,
      requiredDocuments: [{ label: 'project.requiredDocuments.signedIntentionLetter' }],
      stageNumber: 2,
      subSteps: [
        {
          content: 'project.transaction.detailSteps.2.subSteps.1.content',
          title: 'project.transaction.detailSteps.2.subSteps.1.title',
        },
        {
          content: 'project.transaction.detailSteps.2.subSteps.2.content',
          title: 'project.transaction.detailSteps.2.subSteps.2.title',
        },
        {
          content: 'project.transaction.detailSteps.2.subSteps.3.content',
          title: 'project.transaction.detailSteps.2.subSteps.3.title',
        },
      ],
      title: 'project.transaction.detailSteps.2.title',
    },
    {
      content: 'project.transaction.detailSteps.3.content',
      estimatedDuration: 30,
      requiredDocuments: [{ label: 'project.requiredDocuments.signedQuote' }],
      stageNumber: 3,
      subSteps: [
        {
          content: 'project.transaction.detailSteps.3.subSteps.1.content',
          title: 'project.transaction.detailSteps.3.subSteps.1.title',
        },
        {
          content: 'project.transaction.detailSteps.3.subSteps.2.content',
          title: 'project.transaction.detailSteps.3.subSteps.2.title',
        },
        {
          content: 'project.transaction.detailSteps.3.subSteps.3.content',
          title: 'project.transaction.detailSteps.3.subSteps.3.title',
        },
        {
          content: 'project.transaction.detailSteps.3.subSteps.4.content',
          optional: true,
          title: 'project.transaction.detailSteps.3.subSteps.4.title',
        },
        {
          title: 'project.transaction.detailSteps.3.subSteps.5.title',
        },
      ],
      title: 'project.transaction.detailSteps.3.title',
    },
    {
      content: 'project.transaction.detailSteps.4.content',
      estimatedDuration: 70,
      requiredDocuments: [{ label: 'project.requiredDocuments.loanOffer' }],
      stageNumber: 4,
      subSteps: [
        {
          content: 'project.transaction.detailSteps.4.subSteps.1.content',
          title: 'project.transaction.detailSteps.4.subSteps.1.title',
        },
        {
          content: 'project.transaction.detailSteps.4.subSteps.2.content',
          title: 'project.transaction.detailSteps.4.subSteps.2.title',
        },
        {
          title: 'project.transaction.detailSteps.4.subSteps.3.title',
        },
      ],
      title: 'project.transaction.detailSteps.4.title',
    },
    {
      content: 'project.transaction.detailSteps.5.content',
      estimatedDuration: 30,
      requiredDocuments: [
        { label: 'project.requiredDocuments.annualHomeOwnerInsurance' },
        { label: 'project.requiredDocuments.managementMandate', optional: true },
      ],
      stageNumber: 5,
      subSteps: [
        {
          content: 'project.transaction.detailSteps.5.subSteps.1.content',
          title: 'project.transaction.detailSteps.5.subSteps.1.title',
        },
        {
          title: 'project.transaction.detailSteps.5.subSteps.2.title',
        },
        {
          content: 'project.transaction.detailSteps.5.subSteps.3.content',
          title: 'project.transaction.detailSteps.5.subSteps.3.title',
        },
        {
          content: 'project.transaction.detailSteps.5.subSteps.4.content',
          title: 'project.transaction.detailSteps.5.subSteps.4.title',
        },
        {
          content: 'project.transaction.detailSteps.5.subSteps.5.content',
          title: 'project.transaction.detailSteps.5.subSteps.5.title',
        },
      ],
      title: 'project.transaction.detailSteps.5.title',
    },
  ],
};
