import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgPa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="pa_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#pa_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M28 9.333H13.333V0H28v9.333z" fill="#E52448" />
        <Path
          d="M13.333 20H0V9.333h13.333V20zM6.667 5.76L5.099 6.824l.528-1.82L4.13 3.844l1.893-.06L6.667 2l.642 1.782 1.894.06-1.497 1.163.528 1.819L6.667 5.76z"
          fill="#1367AE"
        />
        <Path
          d="M20.667 15.76l-1.568 1.064.528-1.82-1.496-1.161 1.893-.06.643-1.783.642 1.782 1.894.06-1.497 1.162.528 1.82-1.567-1.064z"
          fill="#E52448"
        />
      </G>
    </Svg>
  );
};

export default SvgPa;
