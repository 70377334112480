import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgBriefCase = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3.518 21.148c-.55 0-1.02-.195-1.412-.587a1.927 1.927 0 01-.588-1.413v-11c0-.55.196-1.02.588-1.412a1.923 1.923 0 011.412-.588h4v-2c0-.55.196-1.02.588-1.413a1.925 1.925 0 011.412-.587h4c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v2h4c.55 0 1.02.196 1.413.588.391.392.587.862.587 1.412v11c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 01-1.413.587h-16zm6-15h4v-2h-4v2zm-6 13h16v-11h-16v11z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgBriefCase;
