import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgJm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="jm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#jm_svg__a)">
        <Path fill="#1DBE4F" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0l12 10L0 20V0zM28 0L16 10l12 10V0z"
          fill="#262626"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 8.392L-1.282-1.916-2.774.294 11.616 10l-14.39 9.706 1.492 2.21L14 11.608l15.282 10.308 1.492-2.21L16.384 10 30.774.294l-1.492-2.21L14 8.392z"
          fill="#FFD646"
        />
      </G>
    </Svg>
  );
};

export default SvgJm;
