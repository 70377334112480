import React, { useMemo } from 'react';

import { ScrollView, StyleSheet, View } from 'react-native';

import { IconButton, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { RenovationSummary, RenovationSummaryProps } from './RenovationSummary';

type RenovationSummaryDetailTemplateProps = RenovationSummaryProps & { onClose: () => void };

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    scrollView: {
      flex: 1,
      padding: theme.spacing.SIZE_06,
    },
    titleContainer: {
      borderBottomWidth: 1,
      borderColor: theme.palette.neutral[100],
      flexDirection: 'row',
      gap: theme.spacing.SIZE_05,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_05,
    },
  });

export const RenovationSummaryDetailTemplate: React.FC<RenovationSummaryDetailTemplateProps> = ({
  onClose,
  ...renovationSummaryProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <>
      <View style={styles.titleContainer}>
        <IconButton iconName="ArrowLeft" iconSize="m" onPress={onClose} />
        <Text textStyle="Headline2Medium">{t('propertyRenovation.description')}</Text>
      </View>
      <ScrollView style={styles.scrollView}>
        <RenovationSummary {...renovationSummaryProps} />
      </ScrollView>
    </>
  );
};
