import { Theme } from '@app/ui/theme/types';

export const actionButtonTheme = (theme: Theme) => {
  return {
    ghost: {
      inLight: {
        background: {
          active: theme.palette.neutral[100],
          default: theme.palette.base.white,
          disabled: theme.palette.neutral[200],
          hover: theme.palette.neutral[400],
        },
        border: {
          color: {
            active: theme.palette.neutral[900],
            default: theme.palette.neutral[500],
            disabled: theme.palette.neutral[200],
            hover: theme.palette.neutral[400],
          },
          width: {
            active: 1,
            default: 0,
            disabled: 0,
            hover: 0,
          },
        },
        text: {
          active: theme.palette.neutral[900],
          default: theme.palette.neutral[800],
          disabled: theme.palette.neutral[600],
          hover: theme.palette.neutral[700],
          pressed: theme.palette.neutral[900],
        },
      },
    },
    outlined: {
      inLight: {
        background: {
          active: theme.palette.neutral[100],
          default: theme.palette.base.white,
          disabled: theme.palette.neutral[200],
          hover: theme.palette.neutral[400],
        },
        border: {
          color: {
            active: theme.palette.neutral[900],
            default: theme.palette.neutral[200],
            disabled: theme.palette.neutral[200],
            hover: theme.palette.neutral[400],
          },
          width: {
            active: 1,
            default: 1,
            disabled: 1,
            hover: 1,
          },
        },
        text: {
          active: theme.palette.neutral[900],
          default: theme.palette.neutral[800],
          disabled: theme.palette.neutral[600],
          hover: theme.palette.neutral[700],
          pressed: theme.palette.neutral[900],
        },
      },
    },
    primary: {
      iconStyle: {
        flex: 1,
      },
      inLight: {
        background: {
          active: theme.palette.neutral[100],
          default: theme.palette.base.white,
          disabled: theme.palette.neutral[200],
          hover: theme.palette.neutral[50],
          pressed: theme.palette.neutral[100],
        },
        borderStyle: 'dashed',
        borderWidth: 1,
        dashedBorderColor: {
          active: theme.palette.neutral[900],
          default: theme.palette.neutral[300],
          disabled: theme.palette.neutral[200],
          hover: theme.palette.neutral[300],
          pressed: theme.palette.neutral[300],
        },
        greyBackground: {
          active: theme.palette.neutral[200],
          default: theme.palette.neutral[100],
          disabled: theme.palette.neutral[200],
          hover: theme.palette.neutral[50],
          pressed: theme.palette.neutral[200],
        },
        shadow: theme.shadows[50],
        solidBorderColor: {
          active: theme.palette.neutral[900],
          default: theme.palette.neutral[200],
          disabled: theme.palette.neutral[200],
          hover: theme.palette.neutral[200],
          pressed: theme.palette.neutral[200],
        },
        text: {
          active: theme.palette.neutral[900],
          default: theme.palette.neutral[800],
          disabled: theme.palette.neutral[600],
          hover: theme.palette.neutral[700],
          pressed: theme.palette.neutral[900],
        },
      },
    },
    size: {
      l: {
        container: {
          borderRadius: theme.spacing.SIZE_09 / 2,
          height: theme.spacing.SIZE_09,
          padding: theme.spacing.SIZE_03,
        },
        icon: {
          height: theme.spacing.SIZE_07,
          width: theme.spacing.SIZE_07,
        },
        text: theme.textStyles.Button1,
        textPadding: theme.spacing.SIZE_03,
      },
      m: {
        container: {
          borderRadius: (theme.spacing.SIZE_08 + 4) / 2,
          height: theme.spacing.SIZE_08 + 4,
          padding: theme.spacing.SIZE_03,
        },
        icon: {
          height: theme.spacing.SIZE_06,
          width: theme.spacing.SIZE_06,
        },
        text: theme.textStyles.Button2,
        textPadding: theme.spacing.SIZE_02 + 2,
      },
      s: {
        container: {
          borderRadius: theme.spacing.SIZE_08 / 2,
          height: theme.spacing.SIZE_08,
          padding: theme.spacing.SIZE_02 + 2,
        },
        icon: {
          height: theme.spacing.SIZE_06,
          width: theme.spacing.SIZE_06,
        },
        text: theme.textStyles.Button2,
        textPadding: theme.spacing.SIZE_02 + 2,
      },
      xl: {
        container: {
          borderRadius: theme.spacing.SIZE_10 / 2,
          height: theme.spacing.SIZE_10,
          padding: theme.spacing.SIZE_04,
        },
        icon: {
          height: theme.spacing.SIZE_07,
          width: theme.spacing.SIZE_07,
        },
        text: theme.textStyles.Button1,
        textPadding: theme.spacing.SIZE_03,
      },
      xs: {
        container: {
          borderRadius: theme.spacing.SIZE_07 / 2,
          height: theme.spacing.SIZE_07,
          padding: theme.spacing.SIZE_02,
        },
        icon: {
          height: theme.spacing.SIZE_05,
          width: theme.spacing.SIZE_05,
        },
        text: theme.textStyles.Button3,
        textPadding: theme.spacing.SIZE_01,
      },
      xxs: {
        container: {
          borderRadius: theme.spacing.SIZE_06 / 2,
          height: theme.spacing.SIZE_06,
          padding: theme.spacing.SIZE_01,
        },
        icon: {
          height: theme.spacing.SIZE_05,
          width: theme.spacing.SIZE_05,
        },
        text: theme.textStyles.Button3,
        textPadding: theme.spacing.SIZE_01,
      },
    },
  };
};
