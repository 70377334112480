import { NavigationContainerRefWithCurrent, ParamListBase } from '@react-navigation/native';

import { resetNavigation } from '@app/navigation/helpers/resetNavigation';
import { trackEmailNotificationClicked } from '@app/services/tracking/trackTracking';
import { parseParams } from '@app/shared/hooks/useQueryParams';
import { isNative } from '@app/constants/platform';

export const trackURLParams = (
  navigation: NavigationContainerRefWithCurrent<ParamListBase>
): void => {
  if (isNative()) {
    return;
  }

  const fromEmailNotificationName = parseParams(location.search)('fromEmailNotificationName');
  if (fromEmailNotificationName) {
    trackEmailNotificationClicked({ notificationName: fromEmailNotificationName });
    resetNavigation(navigation);
  }
};
