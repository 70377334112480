import { useEffect } from 'react';

import { config } from '@app/config';
import { initializeSegment } from '@app/libs/segment/segment.web';
import { initializeErrorMonitoring } from '@app/services/errorMonitoring/errorMonitoring';
import { initializeTranslation } from '@app/services/translations/translations';
import { initializeIdentity } from '@app/libs/identity/identityClient';

export const useInitialized = (isConfigured: boolean): void => {
  useEffect(() => {
    if (!isConfigured) {
      return;
    }

    initializeErrorMonitoring();

    if (config.isProduction || config.SEGMENT_API_KEY) {
      initializeSegment({
        apiKey: config.SEGMENT_API_KEY,
        customApiHost: config.SEGMENT_API_HOST,
        customCdnHost: config.SEGMENT_CDN_HOST,
      });
    }

    initializeIdentity(config.IDENTITY_API_URL);

    if (config.ENABLE_LOGS !== true) {
      console.log = function () {};
      console.info = function () {};
      console.warn = function () {};
    }
  }, [isConfigured]);

  useEffect(() => {
    const initialize = async () => {
      const translation = await initializeTranslation();
      document.documentElement.lang = translation.language;
    };
    initialize();
  }, []);
};
