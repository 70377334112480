import React from 'react';

import { View, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

import { Hoverable, LikeButton, RadiusContainer, Theme, useTheme } from '@app/ui';

export interface RealEstateHeaderProps {
  image: string;
  isBookmarked: boolean;
  isViewed: boolean;
}

type RealEstatePreviewCardHeaderProps = {
  isPublic?: boolean;
  onBookmark: () => void;
} & RealEstateHeaderProps;

const OPACITY_VIEWED_CARD = 0.5;

const getStyles = (theme: Theme, isViewed: boolean) =>
  StyleSheet.create({
    container: {
      borderWidth: 0,
      flex: 1,
      height: 120,
      overflow: 'hidden',
      width: 110,
    },
    image: { opacity: isViewed ? OPACITY_VIEWED_CARD : 1, width: 110 },
    likeButton: {
      bottom: theme.spacing.SIZE_03,
      position: 'absolute',
      right: theme.spacing.SIZE_03,
    },
  });

export const RealEstatePreviewCardHeader: React.FC<RealEstatePreviewCardHeaderProps> = ({
  image,
  isBookmarked,
  isPublic = false,
  isViewed,
  onBookmark,
}) => {
  const theme = useTheme();

  const styles = getStyles(theme, isViewed);

  const imageScale = useSharedValue(1);

  const animatedStyle = useAnimatedStyle(() => ({
    ...StyleSheet.absoluteFillObject,
    transform: [{ scale: withSpring(imageScale.value) }],
  }));

  const onHoverIn = () => {
    imageScale.value = 1.1;
  };

  const onHoverOut = () => {
    imageScale.value = 1;
  };

  return (
    <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      <RadiusContainer style={styles.container} radiusSize={theme.borderRadius.SIZE_06}>
        <Animated.Image
          style={[animatedStyle, styles.image]}
          source={{
            uri: image,
          }}
        />

        {!isPublic && (
          <View style={styles.likeButton}>
            <LikeButton
              liked={isBookmarked}
              onPress={onBookmark}
              size="s"
              testID="real-estate-like-btn"
            />
          </View>
        )}
      </RadiusContainer>
    </Hoverable>
  );
};
