import { StyleSheet } from 'react-native';

export const getStyles = (windowHeight: number) =>
  StyleSheet.create({
    buttonContainerStyle: {
      marginTop: 32,
    },
    scrollView: {
      height: windowHeight,
    },
  });
