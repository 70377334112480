import React, { useContext } from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { startCase } from 'lodash';
import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { DiscussModalTitle } from '@app/features/discuss/components/DiscussModalTitle';
import { DiscussTakeAppointmentContent } from '@app/features/discuss/components/DiscussTakeAppointmentContent';
import { TimelineStepName, TimelineStepUnion } from '@app/libs/apollo/introspection';
import {
  HomePageNavigatorRoutes,
  HomePageStackParamList,
  MainMenuNavigatorRoutes,
  PublicNavigatorRoutes,
} from '@app/navigation/types/routes';
import {
  trackHomepageTimelineStepFindGem,
  trackHomepageTimelineStepMandate,
  trackMandateSignatureModalDisplayed,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { InAppSignatureModal } from '@app/shared/components/Modals/in-app-signature/InAppSignatureModal';
import { useMe } from '@app/shared/hooks/useMe';
import { useTranslation } from '@app/services/translations/translations';

import { getStepInformations } from '../utils/getStepInformations';
import { Step } from '../components/Step/Step';
import { BreakPoint, sectionPaddingForScreen, useActiveBreakPoint } from '../SectionContainer';

type HomePageStepsWebNav = StackNavigationProp<
  HomePageStackParamList,
  HomePageNavigatorRoutes.HomePageRoot
>;

export const useHomePageRenderItem = (): {
  renderItem: (props: { item: TimelineStepUnion; index: number }) => React.ReactElement;
} => {
  const navigation = useNavigation<HomePageStepsWebNav>();
  const { openModal } = useContext(ModalContext);
  const activeBreakPoint = useActiveBreakPoint();
  const theme = useTheme();
  const { customer } = useMe();
  const { t } = useTranslation();
  const { isGreaterThanDesktop } = useResponsive();

  const onPress = (stepName: TimelineStepName) => {
    if (stepName === TimelineStepName.Register) {
      navigation.push(PublicNavigatorRoutes.Auth);
      return;
    }

    if (stepName === TimelineStepName.Appointment) {
      trackTakeAdvisorAppointmentClick({
        sourcePage: 'homePage',
      });
      openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentContent />, {
        position: 'center',
        title: <DiscussModalTitle />,
        webSize: 'l',
      });
    }

    if (stepName === TimelineStepName.Mandate) {
      const firstName = startCase(customer?.firstName);
      trackHomepageTimelineStepMandate();

      openModal?.(ModalKey.SignMandateInApp, <InAppSignatureModal />, {
        title: (
          <Text textStyle="Title3Medium">
            <Trans
              i18nKey="modalMandateSignature.title"
              values={{ firstName }}
              components={{
                color: (
                  <Text
                    textStyle="Title3Medium"
                    style={{ color: theme.palette.system.primary[500] }}
                  />
                ),
              }}
            />
          </Text>
        ),
        webSize: 'l',
      });

      trackMandateSignatureModalDisplayed('homePage');
    }

    if (stepName === TimelineStepName.FindGem) {
      trackHomepageTimelineStepFindGem();
      navigation.getParent()?.navigate(MainMenuNavigatorRoutes.RealEstates);
    }
  };

  const renderItem = ({ item, index }: { item: TimelineStepUnion; index: number }) => {
    const { buttonLabel, chipText, title, description, iconName } = getStepInformations({
      index,
      item,
      onPress,
      t,
    });

    const isLastIndex = index === 2;
    const isFirstIndex = index === 0;

    const styles = getStyles(theme, isLastIndex, isFirstIndex, activeBreakPoint);
    return (
      <>
        <Step
          buttonLabel={buttonLabel}
          onPress={() => onPress(item.name)}
          chipText={chipText}
          title={title}
          description={description}
          iconName={iconName}
          status={item.status}
          size={isGreaterThanDesktop ? 'large' : 'small'}
        />
        <View style={styles.separatorWrapper}>
          <View style={styles.separator} />
        </View>
      </>
    );
  };

  return {
    renderItem,
  };
};

const getStyles = (
  theme: Theme,
  isLastIndex: boolean,
  isFirstIndex: boolean,
  activeBreakPoint: BreakPoint
) =>
  StyleSheet.create({
    contentContainerStyle: { flexGrow: 1, justifyContent: 'space-between' },
    separator: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[300],
      height: 1,
      width: '100%',
    },
    separatorWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: isFirstIndex ? sectionPaddingForScreen[activeBreakPoint] : 0,
      position: 'absolute',
      top: '50%',
      width: isLastIndex ? sectionPaddingForScreen[activeBreakPoint] : '100%',
    },
  });
