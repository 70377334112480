import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgDz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="dz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#dz_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h14.667V0H0v20z" fill="#048345" />
        <Path
          d="M17.333 11.04l-1.567 1.117.578-1.836-1.547-1.145 1.925-.017.611-1.826.612 1.826 1.924.017-1.547 1.145.579 1.836-1.568-1.117z"
          fill="#E81B42"
        />
        <Path
          d="M17.68 14.381A5.605 5.605 0 0113.75 16C10.576 16 8 13.314 8 10s2.575-6 5.75-6c1.52 0 2.902.615 3.93 1.619a5.483 5.483 0 00-2.36-.528c-2.888 0-5.229 2.198-5.229 4.909s2.34 4.91 5.228 4.91c.85 0 1.652-.191 2.361-.529z"
          fill="#E81B42"
        />
      </G>
    </Svg>
  );
};

export default SvgDz;
