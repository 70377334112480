import React, { useCallback } from 'react';

import { Linking, Platform } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

import { getLinkStyle } from './Link.styles';
import { LinkType } from './Link.types';
import { Pressable } from '../pressable/Pressable';
import { Text } from '../Text/Text';

export const Link: React.FC<LinkType> = ({
  onFailed,
  textStyle = 'Link2',
  style,
  text,
  testID,
  url,
}) => {
  const theme = useTheme();
  const localStyle = getLinkStyle({ theme });

  const onPress = useCallback(async () => {
    const supported = await Linking.canOpenURL(url);
    if (!supported) {
      return onFailed?.();
    }
    return Linking.openURL(url);
  }, [url, onFailed]);

  const TextLink = useCallback(
    ({ style: hackStyle = {} }) => (
      <Text
        textStyle={textStyle}
        style={[localStyle.text, style, hackStyle]}
        onPress={onPress}
        testID={testID}
      >
        {text}
      </Text>
    ),
    [localStyle.text, onPress, style, testID, text, textStyle]
  );
  /**
   * @desc Hack used to deal with text align on nested text on android
   * @see https://github.com/facebook/react-native/issues/18790
   * @see https://github.com/facebook/react-native/issues/30375
   */
  return Platform.select({
    android: (
      <Pressable onPress={onPress} style={localStyle.hackAndroidContainer}>
        <TextLink style={localStyle.hackAndroidText} />
      </Pressable>
    ),
    default: <TextLink />,
  });
};

export { LinkType };
