import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBf = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bf_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bf_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-9.333H0V20z" fill="#00B051" />
        <Path d="M0 10.667h28V0H0v10.667z" fill="#FA494B" />
        <Path
          d="M14 12.227l-2.351 1.676.867-2.754-2.32-1.718 2.887-.026L14 6.667l.917 2.738 2.887.026-2.32 1.718.867 2.754L14 12.227z"
          fill="#FDD216"
        />
      </G>
    </Svg>
  );
};

export default SvgBf;
