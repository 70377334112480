export enum Sizes {
  S = 's',
  M = 'm',
  L = 'l',
}

export interface BubbleCountProps {
  /**
   * Size of the BubbleCount [s/m/l]
   */
  size: Sizes;
  /**
   * Count displayed on BubbleCount
   */
  count?: number;
}
