import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgLc = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="lc_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#lc_svg__a)">
        <Path fill="#86D7FF" d="M0 0h28v20H0z" />
        <G filter="url(#lc_svg__filter0_d_4223_11299)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 2.667l6 14.666H8l6-14.666z"
            fill="#fff"
          />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 5.333l4.667 12H9.333l4.667-12z"
          fill="#262626"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M14 9.333l6 8H8l6-8z" fill="#FFDA57" />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgLc;
