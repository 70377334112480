import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgCoc = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.444 3.333a.333.333 0 00-.2.067L10.72 5.293a.333.333 0 01-.119.057l-2.566.641a.333.333 0 00-.068.025L5.433 7.284a.333.333 0 00-.15.149L4.088 9.827a.333.333 0 00.02.334l1.17 1.755c.037.055.056.12.056.185v1.76c0 .09.035.174.098.237l1.066 1.065a.333.333 0 00.385.063l3.714-1.858a.334.334 0 01.15-.035h2.467c.078 0 .153.028.213.078l3.815 3.178c.06.05.135.078.213.078h2.368a.333.333 0 00.277-.149l2.512-3.767a.333.333 0 00.056-.185V10.1c0-.066-.02-.13-.056-.185L20 6l-1.903-3.171a.333.333 0 00-.286-.162h-.418a.3.3 0 00-.213.513.3.3 0 01.056.347l-.477.955a.333.333 0 01-.298.185h-1.588a.333.333 0 01-.298-.185l-.483-.964a.333.333 0 00-.298-.185h-.35zm5.89 14H18l.431.431c.13.13.341.13.471 0l.431-.43z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgCoc;
