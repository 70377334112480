import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgCo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="co_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#co_svg__a)">
        <G filter="url(#co_svg__filter0_d_4223_10624)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 14.667h28V9.333H0v5.334z"
            fill="#0748AE"
          />
        </G>
        <G filter="url(#co_svg__filter1_d_4223_10624)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-5.333H0V20z" fill="#DE2035" />
        </G>
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 9.333h28V0H0v9.333z" fill="#FFD935" />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgCo;
