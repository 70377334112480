import { Platform, ScaledSize, StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = ({ theme, dimensions }: { theme: Theme; dimensions: ScaledSize }) =>
  StyleSheet.create({
    dropdownContainer: {
      backgroundColor: theme.palette.base.white,
      borderRadius: theme.borderRadius.SIZE_06,
      overflow: 'hidden',
      position: 'absolute',
      ...Platform.select({
        android: {
          ...theme.shadows[300],
        },
        web: {
          ...theme.shadows[300],
        },
      }),
    },
    inputContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing.SIZE_03,
    },
    inputWrapperStyle: {
      marginTop: theme.spacing.NONE,
    },
    modalListContainer: {
      height: 'auto',
      maxWidth: '100%',
      paddingHorizontal: 0,
      paddingTop: 10,
      width: dimensions.width,
    },
    overlay: {
      height: '100%',
      width: '100%',
      ...Platform.select({
        ios: {
          ...theme.shadows[300],
        },
      }),
    },
    wrapperStyle: {
      width: '100%',
    },
  });
