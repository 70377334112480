import { useCallback, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import SplashScreen from 'react-native-splash-screen';

import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { trackIdentify } from '@app/services/tracking/identifyTracking';
import { isNative } from '@app/constants/platform';

import { afterLogin } from '../contexts/auth/auth.utils';

export const useAfterLogin = (customer: GetCustomerQuery['me'], loading: boolean): void => {
  const apolloClient = useApolloClient();
  const afterLoginFn = useCallback(async () => {
    if (loading) {
      return;
    }
    if (isNative()) {
      SplashScreen.hide();
    }
    if (!customer) {
      await apolloClient.resetStore();
      return;
    }

    trackIdentify(customer);
    await afterLogin(customer);
  }, [apolloClient, customer, loading]);

  useEffect(() => {
    afterLoginFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id, loading]);
};
