import { ApolloQueryResult } from '@apollo/client';

import {
  CustomerInformationInput,
  GetCustomerQuery,
  useGetCustomerQuery,
  useUpdateCustomerInformationsMutation,
} from '@app/libs/apollo/introspection';

export interface UseCustomer {
  customer: GetCustomerQuery['me'];
  refetch: () => Promise<ApolloQueryResult<GetCustomerQuery>>;
  loading: boolean;
  updateInformations: (customerInfo: CustomerInformationInput) => Promise<void>;
  updateInformationsLoading: boolean;
}

export function useCustomer(): UseCustomer {
  const {
    data,
    loading,
    refetch: refetchCustomer,
  } = useGetCustomerQuery({
    errorPolicy: 'ignore',
    variables: {},
  });

  const [updateInformationsMutation, { loading: updateInformationsLoading }] =
    useUpdateCustomerInformationsMutation();

  const updateInformations = async (customerInformations: CustomerInformationInput) => {
    await updateInformationsMutation({ variables: { customerInformations } });
  };

  const refetch = () => refetchCustomer();

  return { customer: data?.me, loading, refetch, updateInformations, updateInformationsLoading };
}
