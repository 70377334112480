import has from 'lodash/has';
import { ImageBackgroundProps, ImageURISource } from 'react-native';

export type ImageSourceType = {
  uri?: string | null;
};

export type ResponsiveImageProps = ImageBackgroundProps & {
  aspectRatio?: number;
  resizeMode?: string;
  onPress?: () => void;
};

export const isSourceImage = (source: ResponsiveImageProps['source']): source is ImageURISource => {
  return has(source, 'uri');
};
