import React, { useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { Button, Text, TextWithIcon, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

interface ShortTermRentalProps {
  onButtonPress: () => void;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    button: {
      backgroundColor: theme.palette.tint.purple[50],
      width: 260,
    },
    buttonWrapper: {
      alignSelf: 'center',
    },
    container: {
      backgroundColor: theme.palette.tint.purple[50],
      borderColor: theme.palette.tint.purple[500],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      gap: theme.spacing.SIZE_05,
      padding: theme.spacing.SIZE_05,
    },
    textContainer: {
      gap: theme.spacing.SIZE_03,
    },
    title: {
      color: theme.palette.tint.purple[700],
    },
  });

export const ShortTermRental: React.FC<ShortTermRentalProps> = ({ onButtonPress }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <TextWithIcon
          icon={{ color: theme.palette.tint.purple[700], name: 'Key', size: 12 }}
          text={{ font: 'Caption2Medium', style: styles.title }}
        >
          {t('realEstate.shortTermRental.infoTitle').toUpperCase()}
        </TextWithIcon>

        <Text textStyle="Body2">{t('realEstate.shortTermRental.infoDescription')}</Text>
      </View>

      <Button
        onPress={onButtonPress}
        variant="tertiary"
        size="m"
        style={styles.button}
        wrapperStyle={styles.buttonWrapper}
      >
        {t('realEstate.shortTermRental.infoButton')}
      </Button>
    </View>
  );
};
