import React from 'react';

import { StyleSheet, View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';

import { BaseLink, Chip, Hoverable, RadiusContainer, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { getImageUrl } from '@app/utils/getImageUrl';
import { Strategy } from '@app/libs/apollo/introspection';

export type SoldRealEstateItem = {
  key: string;
  id: string;
  coverImage: string;
  title: string;
  rentingDelay: number;
  strategy: Strategy;
};

type SoldRealEstateCardProps = {
  soldRealEstate: SoldRealEstateItem;
  onClick: () => void;
};

const getStyles = ({ theme }: { theme: Theme }) =>
  StyleSheet.create({
    bottomPart: {
      flexDirection: 'row',
    },
    bottomPartSeparator: {
      backgroundColor: `${theme.palette.base.white}B3`,
      height: 16,
      marginHorizontal: theme.spacing.SIZE_04,
      width: 1,
    },
    bottomPartText: {
      color: `${theme.palette.base.white}B3`,
    },
    chipContainer: {
      flexDirection: 'row',
    },
    container: {
      flex: 1,
      justifyContent: 'flex-end',
      padding: theme.spacing.SIZE_07,
    },
    content: {
      gap: 10,
    },
    radiusContainer: {
      borderWidth: 0,
      minHeight: 376,
      overflow: 'hidden',
    },
    title: { color: theme.palette.base.white },
  });

export const SoldRealEstateCard: React.FC<SoldRealEstateCardProps> = ({
  soldRealEstate,
  onClick,
}) => {
  const theme = useTheme();
  const styles = getStyles({ theme });
  const { t } = useTranslation();

  const imageScale = useSharedValue(1);

  const animatedStyle = useAnimatedStyle(() => ({
    ...StyleSheet.absoluteFillObject,
    transform: [{ scale: withSpring(imageScale.value) }],
  }));

  const onHoverIn = () => {
    imageScale.value = 1.1;
  };

  const onHoverOut = () => {
    imageScale.value = 1;
  };

  return (
    <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      <BaseLink href={`property/sold/${soldRealEstate.id}`} onClick={onClick}>
        <RadiusContainer style={styles.radiusContainer} radiusSize={theme.borderRadius.SIZE_06}>
          <Animated.Image
            style={animatedStyle}
            source={{
              uri: getImageUrl(soldRealEstate.coverImage),
            }}
          />
          <LinearGradient
            colors={['transparent', 'rgba(0, 0, 0, 0.5)']}
            style={StyleSheet.absoluteFillObject}
          />
          <View style={styles.container}>
            <View style={styles.content}>
              <View style={styles.chipContainer}>
                <Chip text={t('propertyPreview.isSold')} tint="black" iconName="Pricetag" />
              </View>
              <Text style={styles.title} textStyle="Headline1Medium">
                {soldRealEstate.title}
              </Text>
              <View style={styles.bottomPart}>
                <Text style={styles.bottomPartText} textStyle="Button3">
                  {t(`propertySold.preview.strategy.${soldRealEstate.strategy}`)}
                </Text>
                <View style={styles.bottomPartSeparator} />
                <Text style={styles.bottomPartText} textStyle="Button3">
                  {t('propertySold.preview.rentInDays', { days: soldRealEstate.rentingDelay })}
                </Text>
              </View>
            </View>
          </View>
        </RadiusContainer>
      </BaseLink>
    </Hoverable>
  );
};
