import React, { useState } from 'react';

import { Controller, useForm, useFormContext } from 'react-hook-form';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { CheckboxTile, CheckboxTileVariantType, IconName } from '@app/ui';
import { PropertyKind } from '@app/libs/apollo/introspection';

import { FilterForm } from '../Filters.type';
import { FilterFormContainer } from './FilterFormContainer';
import { handleCheckboxChange } from '../utils/filterForm.utils';
import { useCountResults } from '../hooks/useCountResults.hook';

interface TypologyForm {
  typology: PropertyKind[];
}

const GAP = 16;
const getStyles = () =>
  StyleSheet.create({
    inputs: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: GAP,
    },
  });

const getTileIconName = (typo: PropertyKind): IconName => {
  switch (typo) {
    case PropertyKind.Apartment:
      return 'Flat';
    case PropertyKind.Building:
      return 'Building';
    case PropertyKind.House:
    default:
      return 'Home';
  }
};

export const TypologyFilterForm: React.FC<FilterForm> = ({ goBack }) => {
  const { t } = useTranslation();
  const styles = getStyles();

  const { getValues: getGlobalValues, setValue } = useFormContext<SearchEngineFilterForm>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm<TypologyForm>({
    mode: 'onChange',
    values: { typology: getGlobalValues('typology') },
  });

  const onApply = (form: TypologyForm) => {
    setValue('typology', form.typology);
    goBack();
  };

  const onReset = () => {
    reset();
  };

  const [tileWidth, setTileWidth] = useState(0);
  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setTileWidth(nativeEvent.layout.width / 2 - GAP / 2);
  };

  const { count, loading } = useCountResults(watch());

  return (
    <FilterFormContainer
      title={t('realEstate.filters.typology.title')}
      canApply={isValid}
      resultCount={count}
      loading={loading}
      onApply={handleSubmit(onApply)}
      onBack={goBack}
      onReset={onReset}
    >
      <View style={styles.inputs} onLayout={onLayout}>
        <Controller
          name="typology"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              {Object.values(PropertyKind).map(typology => (
                <View key={typology} style={{ width: tileWidth }}>
                  <CheckboxTile
                    iconName={getTileIconName(typology)}
                    testID={`search-engine-filter-${typology}--checkbox`}
                    checked={!!value?.includes(typology)}
                    label={t(`shared.propertyKind.${typology}`)}
                    onChange={handleCheckboxChange(onChange, value, typology)}
                    variant={CheckboxTileVariantType.SquareFill}
                  />
                </View>
              ))}
            </>
          )}
        />
      </View>
    </FilterFormContainer>
  );
};
