import * as segment from '@app/libs/segment/segment.web';
import { RealEstate, TransactionStatus } from '@app/libs/apollo/introspection';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import {
  PublicNavigatorRoutes,
  ProfileNavigatorRoutes,
  AdvisorNavigatorRoutes,
  Routes,
  ProjectNavigatorRoutes,
  RealEstateNavigatorRoutes,
  ForgotPasswordNavigatorRoutes,
  MainMenuNavigatorRoutes,
  HomePageNavigatorRoutes,
} from '@app/navigation/types/routes';
import { ExtendedSearchResultsType } from '@app/features/search-engine/searchEngine.types';

import { getRealEstateBadgeType } from './utils';

export enum WebPageName {
  ERROR = '/error',
  AUTH = '/auth',
  VIEW_DOCUMENT = '/profile/document',
  FAVORITES = '/favorites',
  SELECTIONS = '/selections',
  PROJECT = 'projectPage',
  PROJECTS = 'projectListPage',
  ONBOARDING = 'welcome',
}

export const trackChangePage = (
  pageId: WebPageName | Routes,
  params: {
    method?: SignInMethod;
    realEstate?: RealEstate;
    soldRealEstate?: {
      id: string;
    };
    document?: { id: string; category: string };
    count?: number;
    realEstatePosition?: number;
    project?: { id: string; status?: TransactionStatus | null };
    projectNumber?: number;
    eventName?: string;
    isFromSearchEngine?: boolean;
    fromExtendedResultsSection?: ExtendedSearchResultsType;
  } = {}
): void => {
  switch (pageId) {
    // Public routes
    case PublicNavigatorRoutes.Auth:
      segment.page('View auth form page');
      break;

    case PublicNavigatorRoutes.Login:
      segment.page('View login form page');
      break;

    case PublicNavigatorRoutes.Register:
      segment.page('View registration', {
        method: params.method as string,
      });
      break;

    case PublicNavigatorRoutes.PublicHome:
      // Already executed on PublicHomePage
      break;

    // Home page
    case HomePageNavigatorRoutes.HomePageRoot:
      segment.page('View homepage');
      break;

    // Forgot password routes
    case ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep:
      segment.page('View forgotten password');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep:
      segment.page('View forgotten code');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep:
      segment.page('View forgotten confirmed password');
      break;

    // Real estates routes
    case RealEstateNavigatorRoutes.RealEstateList:
      segment.page('View matching page');
      break;

    case RealEstateNavigatorRoutes.RealEstate:
      if (params.realEstate) {
        segment.page('View property page', {
          annualRevaluationRate: params.realEstate.project.renting.annual.revaluationRate,
          ...(params.isFromSearchEngine && {
            badgeType: getRealEstateBadgeType(
              params.realEstate.isNew,
              params.realEstate.isExclusive,
              !!params.realEstate.project.renting.shortTerm
            ),
          }),
          city: params?.realEstate?.building.address?.city,
          investStrategy: params.realEstate.project.strategy,
          isARecommandation: !!params.realEstate?.metadata?.positioning?.isSuggestion,
          ...(params.fromExtendedResultsSection && {
            extendedResultsSection: params.fromExtendedResultsSection,
          }),
          isFromExtendedResults: !!params.fromExtendedResultsSection,
          locationType: params.realEstate.project.renting.type,
          propertyId: params.realEstate.id,
          propertyPosition: params.realEstatePosition,
          region: params.realEstate?.building.address?.regionName,
          renovationAmount: params.realEstate.project.renovation.amount,
          type: params.realEstate.type,
        });
      }
      break;

    case RealEstateNavigatorRoutes.RealEstateSold:
      if (params.soldRealEstate) {
        segment.page('View sold property page', {
          id: params.soldRealEstate.id,
        });
      }
      break;

    // Project routes
    case ProjectNavigatorRoutes.Project:
      segment.page('Project Detail', {
        projectId: params.project?.id,
        projectStatus: params.project?.status,
        propertyId: params.realEstate?.id,
      });
      break;

    case MainMenuNavigatorRoutes.Projects:
    case ProjectNavigatorRoutes.ProjectList:
      segment.page('Project Menu page', { projectNumber: params.projectNumber });
      break;

    // Advisor routes
    case AdvisorNavigatorRoutes.AdvisorRoot:
      segment.page('View advisor page');
      break;

    case AdvisorNavigatorRoutes.AdvisorAppointment:
      segment.page('View take appointment page');
      break;

    // Profile routes
    case MainMenuNavigatorRoutes.Profile:
    case ProfileNavigatorRoutes.ProfileRoot:
      segment.page('View profile page');
      break;

    case ProfileNavigatorRoutes.ProfileDocumentsList:
      segment.page('View a document', {
        documentCategory: params.document?.category,
        documentId: params.document?.id,
      });
      break;

    // WebPageName
    case WebPageName.ERROR:
      segment.page('View error page');
      break;

    case WebPageName.ONBOARDING:
      params.eventName && segment.page(params.eventName);
      break;

    default:
      // we hide console on production thanks to "babel-plugin-transform-remove-console"
      console.log(`[Tracking] - This pageId doesn't have a tracking condition: ${pageId}`);
      break;
  }
};
