import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgArmchair = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5 21a.968.968 0 01-.713-.288A.967.967 0 014 20v-1a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 011 16v-5c0-.833.292-1.542.875-2.125A2.893 2.893 0 014 8V6c0-.833.292-1.542.875-2.125A2.893 2.893 0 017 3h10c.833 0 1.542.292 2.125.875S20 5.167 20 6v2c.833 0 1.542.292 2.125.875S23 10.167 23 11v5c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0120 19v1c0 .283-.096.52-.288.712A.965.965 0 0119 21a.965.965 0 01-.712-.288A.965.965 0 0118 20v-1H6v1c0 .283-.096.52-.287.712A.968.968 0 015 21zm-1-4h16c.283 0 .52-.096.712-.288A.965.965 0 0021 16v-5a.968.968 0 00-.288-.713A.967.967 0 0020 10a.967.967 0 00-.712.287A.968.968 0 0019 11v4H5v-4a.97.97 0 00-.287-.713A.97.97 0 004 10a.967.967 0 00-.712.287A.968.968 0 003 11v5c0 .283.096.52.288.712A.965.965 0 004 17zm3-4h10v-2c0-.45.092-.858.275-1.225.183-.367.425-.692.725-.975V6a.968.968 0 00-.288-.713A.967.967 0 0017 5H7a.97.97 0 00-.713.287A.97.97 0 006 6v2.8c.3.283.542.608.725.975A2.7 2.7 0 017 11v2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgArmchair;
