import { InMemoryCacheConfig } from '@apollo/client';

import { myRealEstatesMemoryCache } from '../myRealEstatesMemoryCache';

export const apolloInMemoryCache: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        searchRealEstates: myRealEstatesMemoryCache,
      },
    },
    RealEstate: {
      fields: {
        annual: { merge: (existing, incoming) => ({ ...existing, ...incoming }) },
        building: { merge: (existing, incoming) => ({ ...existing, ...incoming }) },
        metadata: {
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
      },
    },
  },
};
