import React, { useContext } from 'react';

import { ScrollView } from 'react-native-gesture-handler';

import { useTranslation } from '@app/services/translations/translations';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';
import { HeaderPage, ListItem, useTheme } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { useDateFormatter } from '@app/shared/hooks/useDateFormatter';
import {
  trackDiscussAppointment,
  trackDiscussBeCalledBack,
} from '@app/services/tracking/trackTracking';

import { DiscussTakeAppointmentContent } from './components/DiscussTakeAppointmentContent';
import { DiscussBeCalled } from './DiscussBeCalled';
import { DiscussModalTitle } from './components/DiscussModalTitle';

export const Discuss: React.FC = () => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();
  const theme = useTheme();
  const { openModal } = useContext(ModalContext);

  const { customer } = useMe();

  const goToBeCalled = () => {
    trackDiscussBeCalledBack();
    openModal?.(ModalKey.BeCalled, <DiscussBeCalled />, {
      position: 'center',
      title: t('discuss.list.beCalledTitle'),
      webSize: 'l',
    });
  };

  const goToTakeAppointment = () => {
    trackDiscussAppointment();
    openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentContent />, {
      position: 'center',
      title: <DiscussModalTitle />,
      webSize: 'l',
    });
  };

  return (
    <ScreenWrapper>
      <ScrollView>
        <HeaderPage title={t('discuss.title')} />
        {!customer?.nextAppointment && (
          <>
            {customer?.isAwaitingCallback ? (
              <ListItem
                key="1"
                label={t('discuss.list.awaitingCallTitle')}
                iconName="Check"
                iconColor={theme.palette.tint.green['500']}
                description={t('discuss.list.awaitingCallDescription')}
                numberOfLines={2}
                disabled
              />
            ) : (
              <ListItem
                key="2"
                label={t('discuss.list.beCalledTitle')}
                iconName="Phone"
                description={t('discuss.list.beCalledDescription')}
                hasRightArrow
                numberOfLines={2}
                onPress={goToBeCalled}
              />
            )}
          </>
        )}
        {customer.nextAppointment?.startAt ? (
          <ListItem
            key="3"
            iconName="Check"
            iconColor={theme.palette.tint.green['500']}
            numberOfLines={2}
            label={t('discuss.list.appointmentTakenTitle')}
            description={t('discuss.list.appointmentTakenDescription', {
              date: formatDate(customer.nextAppointment.startAt),
            })}
            disabled
          />
        ) : (
          <ListItem
            key="4"
            numberOfLines={2}
            label={t('discuss.list.takeAppointmentTitle')}
            iconName="Calendar"
            description={t('discuss.list.takeAppointmentDescription')}
            hasRightArrow
            onPress={goToTakeAppointment}
          />
        )}
      </ScrollView>
    </ScreenWrapper>
  );
};
