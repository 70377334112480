import styled from '@emotion/styled';

import { RequiredThemes } from '@app/ui/theme/types';

interface BottomBarMenuContainerProps {
  componentTheme: RequiredThemes['bottomBarMenu'];
}

export const BottomBarMenuContainer = styled.div<BottomBarMenuContainerProps>`
  height: ${({ componentTheme }) => componentTheme.menuWrapper.height};
  display: flex;
  width: ${({ componentTheme }) => componentTheme.menuWrapper.width};
  align-items: center;
  justify-content: space-around;
  background: ${({ componentTheme }) => componentTheme.menuWrapper.background};
  border-top: ${({ componentTheme }) => componentTheme.menuWrapper.border};
  bottom: 0;
  position: fixed;
`;
