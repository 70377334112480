import React from 'react';

import { Controller, useForm, useFormContext } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { Select, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { stringToSelect } from '@app/utils/select-formatter';
import { SelectGroup } from '@app/ui/organisms/select-group/SelectGroup';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';

import { FilterFormContainer } from './FilterFormContainer';
import { FilterForm } from '../Filters.type';
import { getZoneGroupOptions } from '../../utils/get-zone-group-options.utils';
import { getRegionListValue } from '../../utils/get-region-list-value';
import { useRegionSelect } from '../hooks/useRegionSelect.hook';
import { useZonesSelect } from '../hooks/useZonesSelect.hook';
import { useCountResults } from '../hooks/useCountResults.hook';

interface LocationForm {
  country: AvailableCountryCode | null;
  regionList: string[];
  zones: string[];
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_05,
    },
    selectWrapper: {
      width: '100%',
    },
  });

export const LocationFilterForm: React.FC<FilterForm> = ({ goBack }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const { getValues: getGlobalValues, setValue } = useFormContext<SearchEngineFilterForm>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm<LocationForm>({
    mode: 'onChange',
    values: {
      country: getGlobalValues('country'),
      regionList: getGlobalValues('regionList'),
      zones: getGlobalValues('zones'),
    },
  });

  // TODO: Remove support for others countries #countryRemoval
  const country = AvailableCountryCode.Fr;
  const showZoneFilter = country === AvailableCountryCode.Fr;
  const { handleRegionChange, regionOptionList } = useRegionSelect(country);
  const { zoneListByCountry } = useZonesSelect();

  const onApply = (form: LocationForm) => {
    setValue('country', form.country);
    setValue('regionList', form.regionList);
    setValue('zones', form.zones);
    goBack();
  };

  const onReset = () => {
    reset();
  };

  const { count, loading } = useCountResults(watch());

  return (
    <FilterFormContainer
      title={t('realEstate.filters.location.title')}
      canApply={isValid}
      resultCount={count}
      loading={loading}
      onApply={handleSubmit(onApply)}
      onBack={goBack}
      onReset={onReset}
    >
      <View style={styles.container}>
        {showZoneFilter ? (
          <Controller
            key="zone-controller"
            name="zones"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectGroup
                testID="search-engine-filter-zone--select"
                placeholder={t('searchEngine.filter.zone.label')}
                optionGroups={getZoneGroupOptions({
                  regionZones: zoneListByCountry[country] || [],
                  t,
                })}
                selectAllLabel={t(`searchEngine.filter.zone.selectAll${country}`) as string}
                unselectAllLabel={t(`searchEngine.filter.zone.selectAll${country}`) as string}
                onChangeValues={onChange}
                values={value}
                multiple
              />
            )}
          />
        ) : (
          <Controller
            name="regionList"
            key="region-controller"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                testID="search-engine-filter-region--select"
                label={t('searchEngine.filter.region.label')}
                selectPlaceholder={t('searchEngine.filter.region.placeholder')}
                value={getRegionListValue(value, regionOptionList)}
                options={regionOptionList}
                onSelect={handleRegionChange(
                  onChange,
                  value.map(v => stringToSelect(v))
                )}
                isMultichoice
                canDisplayButtons={false}
                isDisabled={regionOptionList.length <= 1}
                wrapperStyle={styles.selectWrapper}
              />
            )}
          />
        )}
      </View>
    </FilterFormContainer>
  );
};
