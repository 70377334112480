import * as React from 'react';

import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const SvgZa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <G clipPath="url(#za_svg__clip0_4223_12145)">
        <Path
          d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"
          fill="#06A86E"
        />
        <Path
          d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"
          fill="#06A86E"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2.667L9.333 10 0 17.333V2.667z"
          fill="#FFBF2E"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M-1.333 3l9 7-9 7V3z" fill="#262626" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M12 8L2.667 0H28v8H12z" fill="#fff" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.333 6.667L4.333 0H28v6.667H12.333z"
          fill="#F44E46"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M2.667 20H28v-8H12l-9.333 8z" fill="#fff" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.333 20H28v-6.667H12.333l-8 6.667z"
          fill="#072CB4"
        />
      </G>
      <Defs>
        <ClipPath id="za_svg__clip0_4223_12145">
          <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SvgZa;
