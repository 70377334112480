import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

export const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    allFilter: {
      flexShrink: 0,
    },
    container: {
      flexDirection: 'row',
      gap: 6,
      justifyContent: 'space-between',
      marginTop: 17,
      paddingBottom: theme.spacing.SIZE_05,
      paddingTop: theme.spacing.SIZE_03,
    },
    filters: {
      flexDirection: 'row',
      gap: 6,
    },
    selector: {
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 1,
      maxWidth: 250,
      minWidth: 100,
    },
  });
};
