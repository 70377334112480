import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgBm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bm_svg__a)">
        <Path fill="#DC1F37" d="M0 0h28v20H0z" />
        <Path fill="#042C90" d="M0 0h12v9.333H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.333 10V6.667h5.334V10c0 2-2.667 3.333-2.667 3.333S17.333 12 17.333 10z"
          fill="#fff"
        />
        <Mask
          id="bm_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={17}
          y={6}
          width={6}
          height={8}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.333 10V6.667h5.334V10c0 2-2.667 3.333-2.667 3.333S17.333 12 17.333 10z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#bm_svg__b)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 12a1.333 1.333 0 100-2.667A1.333 1.333 0 0020 12z"
            fill="#65B5D2"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 9.333A.667.667 0 1020 8a.667.667 0 000 1.333zm-1.333 1.334a.667.667 0 100-1.334.667.667 0 000 1.334zM22 10a.667.667 0 11-1.333 0A.667.667 0 0122 10z"
            fill="url(#bm_svg__paint0_linear_4223_10546)"
          />
          <Path fill="#2F8F22" d="M17.333 12h5.333v1.333h-5.333z" />
        </G>
        <Path
          d="M0-.333h-.901l.684.586 4.55 3.9v1.009L-.194 8.395l-.14.1v1.44l.51-.319L5.43 6.333h1.13l4.523 3.23a.554.554 0 00.682-.87L7.667 5.18V4.172l4.387-3.134a.667.667 0 00.28-.543V-.6l-.51.318L6.57 3h-1.13L.86-.271.772-.333H0z"
          fill="#DB1E36"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.333V6h4.667v3.333c0 .369.298.667.666.667h1.334a.667.667 0 00.666-.667V6H12a.667.667 0 00.667-.667V4A.667.667 0 0012 3.333H7.333V0H4.667v3.333H0z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h5.333V0h1.334v4H12v1.333H6.667v4H5.333v-4H0V4z"
          fill="url(#bm_svg__paint1_linear_4223_10546)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="bm_svg__paint0_linear_4223_10546"
          x1={18}
          y1={8}
          x2={18}
          y2={10.667}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E20B0C" />
          <Stop offset={1} stopColor="#D40001" />
        </LinearGradient>
        <LinearGradient
          id="bm_svg__paint1_linear_4223_10546"
          x1={0}
          y1={0}
          x2={0}
          y2={9.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB1E36" />
          <Stop offset={1} stopColor="#D51931" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgBm;
