import React from 'react';

import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useTranslation } from '@app/services/translations/translations';
import { SubscriptionHeader } from '@app/shared/components/SubscriptionHeader/SubscriptionHeader';
import {
  ForgotPasswordNavigatorRoutes,
  ForgotPasswordStackParamList,
  PublicNavigatorRoutes,
  PublicStackParamList,
} from '@app/navigation/types/routes';

type ForgotPasswordHeaderNavigationProp = CompositeNavigationProp<
  StackNavigationProp<PublicStackParamList>,
  StackNavigationProp<ForgotPasswordStackParamList>
>;

type ForgotPasswordHeaderRouteProp = RouteProp<ForgotPasswordStackParamList>;

export const ForgotPasswordHeader: React.FC = () => {
  const navigation = useNavigation<ForgotPasswordHeaderNavigationProp>();
  const route = useRoute<ForgotPasswordHeaderRouteProp>();
  const { t } = useTranslation();

  const handleGoBack = () => {
    switch (route.name) {
      case ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep:
        navigation.navigate(PublicNavigatorRoutes.Login);
        break;

      case ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep:
        navigation.navigate(ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep, route.params);
        break;

      case ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep:
        navigation.navigate(ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep, route.params);
        break;

      default:
        navigation.goBack();
        break;
    }
  };

  return <SubscriptionHeader title={t('forgotPassword.title')} onPressArrow={handleGoBack} />;
};
