import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import {
  Text,
  Theme,
  TranslatedContent,
  TranslatedContentContentType,
  useResponsive,
  useTheme,
} from '@app/ui';
import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { useTranslation } from '@app/services/translations/translations';
import { ApollonTranslation, PointOfInterest } from '@app/libs/apollo/introspection';

import { PointsOfInterest } from '../../realEstateMap/components/PointsOfInterest';
import { RealEstateMap } from '../../realEstateMap/RealEstateMap';

interface RealEstateLocationProps {
  accessTranslation: ApollonTranslation;
  lat: number;
  lng: number;
  pointsOfInterest: PointOfInterest[];
  shouldShowMap: boolean;
  handleSwitchLanguage: (
    type: TranslatedContentContentType,
    sourceLang: string,
    targetLang: string
  ) => void;
  onMapPress: () => void;
}

const getStyles = ({ theme, isMobile }: { isMobile: boolean; theme: Theme }) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_07,
    },
    mapWrapper: {
      borderRadius: theme.borderRadius.SIZE_06,
      overflow: 'hidden',
      ...(!isMobile && {
        height: 500,
      }),
    },
    textContainer: {
      gap: theme.spacing.SIZE_06,
    },
  });

export const RealEstateLocation: React.FC<RealEstateLocationProps> = ({
  accessTranslation,
  lat,
  lng,
  pointsOfInterest,
  shouldShowMap,
  handleSwitchLanguage,
  onMapPress,
}) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const styles = useMemo(() => getStyles({ isMobile, theme }), [isMobile, theme]);
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text textStyle="Headline1Medium">{t('shared.descriptionFields.access')}</Text>

        <TranslatedContent
          translation={accessTranslation}
          onHandleDone={handleSwitchLanguage}
          translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
          showOriginal={t('shared.showOriginal')}
          showTranslated={t('shared.showTranslated')}
          textStyle="Body2Medium"
        />
      </View>

      {!!shouldShowMap && (
        <>
          <View style={styles.mapWrapper}>
            <RealEstateMap
              lat={lat}
              lng={lng}
              pointsOfInterest={pointsOfInterest}
              onPress={onMapPress}
            />
          </View>

          {!!pointsOfInterest && <PointsOfInterest pointsOfInterest={pointsOfInterest} />}
        </>
      )}
    </View>
  );
};
