import React, { useEffect } from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { Onboarding } from '@app/features/onboarding-v2/Onboarding';
import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { trackURLParams } from '@app/utils/trackURLParams';
import { MainLayout } from '@app/core/main-layout/MainLayout';
import { NPSContainer } from '@app/features/nps/NPS-container';

import { useCurrentRoute } from './helpers/useCurrentRoutes';
import { MainMenuNavigator } from './MainMenuNavigator';
import { navigationRef } from './navigationRef';
import { PrivateNavigatorRoutes, PrivateStackParamList } from './types/routes';

interface PrivateNavigatorProps {
  customer: GetCustomerQuery['me'];
}

const Stack = createStackNavigator<PrivateStackParamList>();

const PrivateNavigator: React.FC<PrivateNavigatorProps> = ({ customer }) => {
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    if (!navigationRef.isReady()) {
      return;
    }
    trackURLParams(navigationRef);

    if (customer?.mustSeeOnboarding && currentRoute?.name !== PrivateNavigatorRoutes.Onboarding) {
      navigationRef.navigate(PrivateNavigatorRoutes.Onboarding as never);
    }
  }, [currentRoute?.name, customer?.mustSeeOnboarding]);

  const initialRoute = customer?.mustSeeOnboarding
    ? PrivateNavigatorRoutes.Onboarding
    : PrivateNavigatorRoutes.MainMenu;

  return (
    <>
      <Stack.Navigator
        initialRouteName={initialRoute}
        screenOptions={{ gestureEnabled: false, headerShown: false }}
      >
        <Stack.Screen name={PrivateNavigatorRoutes.Onboarding} component={Onboarding} />
        <Stack.Screen name={PrivateNavigatorRoutes.MainMenu} options={{ headerShown: false }}>
          {props => (
            <MainLayout>
              <MainMenuNavigator {...props} customer={customer} />
            </MainLayout>
          )}
        </Stack.Screen>
      </Stack.Navigator>

      <NPSContainer />
    </>
  );
};

export { PrivateNavigator };
