import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgVc = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="vc_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#vc_svg__a)">
        <Path fill="#08C47A" d="M13.333 0H28v20H13.333z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h9.333V0H0v20z" fill="#0C87E1" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M8 20h12V0H8v20z" fill="#FECB2F" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 8l2-4 2 4-2 4-2-4zm5.334 0l2-4 2 4-2 4-2-4zM14 9.333l-2 4 2 4 2-4-2-4z"
          fill="#08C47A"
        />
      </G>
    </Svg>
  );
};

export default SvgVc;
