import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLevels = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.597 19.699c-.417 0-.77-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-8c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v8c0 .416-.146.77-.438 1.062a1.444 1.444 0 01-1.062.438zm5.5 0c-.417 0-.77-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-12c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v12c0 .416-.146.77-.438 1.062a1.444 1.444 0 01-1.062.438zm5.5 0c-.417 0-.77-.146-1.062-.438a1.444 1.444 0 01-.438-1.062v-4c0-.417.146-.77.438-1.062a1.444 1.444 0 011.062-.438c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v4c0 .416-.146.77-.438 1.062a1.444 1.444 0 01-1.062.438z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLevels;
