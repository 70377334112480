import { useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useTranslation } from '@app/services/translations/translations';
import { useResponsive } from '@app/ui';
import { RealEstate } from '@app/libs/apollo/introspection';
import { trackOpenMapFullScreen } from '@app/services/tracking/trackTracking';
import { useLocationInfoPermission } from '@app/shared/hooks/useLocationInfoPermission';
import { isNative } from '@app/constants/platform';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { getFormattedAddressFromProperty } from '@app/features/realEstate/realEstate.utils.web';

interface UseRealEstateMap {
  addressGoogleMapModal: string;
  isMapModalVisible: boolean;
  shouldShowMap: boolean;
  toggleMapModal: () => void;
}

type RealEstateNavigationProp = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

export const useRealEstateMap = (realEstate: RealEstate): UseRealEstateMap => {
  const navigation = useNavigation<RealEstateNavigationProp>();
  const { t } = useTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const { addressGoogleMapModal } = getFormattedAddressFromProperty(
    realEstate,
    t,
    isLowerThanLaptop
  );
  const shouldShowMap = useLocationInfoPermission(realEstate.id);

  const toggleMapModal = () => {
    if (!isMapModalVisible) {
      trackOpenMapFullScreen(realEstate.id);
    }
    if (isNative()) {
      navigation.navigate(RealEstateNavigatorRoutes.RealEstateMap, { property: realEstate });
    } else {
      setIsMapModalVisible(!isMapModalVisible);
    }
  };

  return { addressGoogleMapModal, isMapModalVisible, shouldShowMap, toggleMapModal };
};
