import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgCsa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16.041c0-.027.003-.054.01-.08l1.3-5.2a.332.332 0 00-.007-.186l-.593-1.777a.333.333 0 00-.167-.193l-1.025-.513a.333.333 0 01-.185-.298V5.412c0-.052.012-.103.036-.149l1.206-2.412a.333.333 0 01.298-.184h3.46c.184 0 .334.149.334.333v.667c0 .184.149.333.333.333h.667c.184 0 .333.15.333.333v.794c0 .127.071.242.184.298l2.298 1.15a.333.333 0 01.185.298V7.92a.334.334 0 01-.036.15l-.591 1.183a.334.334 0 01-.114.128l-1.852 1.236a.333.333 0 00-.114.128l-1.254 2.508a.333.333 0 01-.113.128l-1.853 1.236a.333.333 0 00-.113.128l-1.202 2.403a.333.333 0 01-.298.184h-.794A.333.333 0 0110 17v-.959z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgCsa;
