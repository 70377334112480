import { useResponsive } from '@app/ui';

// Yes it's stupid... but it's for the eslint rule
const fiveCol = 5;
const fourCol = 4;
const treeCol = 3;
const twoCol = 2;
const oneCol = 1;

export const useColumnsNb = (): number => {
  const { isDesktop, isWide, isUltraWide, isGreaterThanTablet } = useResponsive();

  if (isUltraWide) {
    return fiveCol;
  }

  if (isWide) {
    return fourCol;
  }

  if (isDesktop) {
    return treeCol;
  }

  if (isGreaterThanTablet) {
    return twoCol;
  }

  return oneCol;
};
