import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Text } from '@app/ui/atoms/Text/Text';

import { chipTheme } from './chip.theme';
import { ChipProps } from './Chip.types';
import { computedPadding, styles } from './styles';

// TODO: replace backgroundColor and textColor by type ex: for an alert chip the color of the text is white and the background is red
export const Chip: React.FC<ChipProps> = ({
  text,
  size = 'small',
  iconName,
  tint,
  textColor,
  backgroundColor,
  style,
}) => {
  const componentTheme = useComponentTheme('chip', chipTheme);

  const computedColors =
    tint && componentTheme.tints[tint]
      ? componentTheme.tints[tint]
      : {
          backgroundColor: backgroundColor || componentTheme.tints.grey.backgroundColor,
          color: textColor || componentTheme.tints.grey.color,
        };

  const textStyle = size === 'small' ? 'Button3' : 'Button2';

  const themedStyles = styles(componentTheme);

  const mergedStyle = StyleSheet.create({
    style: {
      ...themedStyles.wrapper,
      ...computedColors,
      ...computedPadding(componentTheme, size),
    },
  }).style;

  return (
    <View style={[mergedStyle, style]}>
      {!!iconName && (
        <Icon
          style={themedStyles.icon}
          name={iconName}
          color={computedColors.color}
          size={componentTheme.icon.size}
        />
      )}
      <Text textStyle={textStyle} style={computedColors} numberOfLines={1}>
        {text}
      </Text>
    </View>
  );
};
