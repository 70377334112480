import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgCaf = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.028 5.503a.333.333 0 01.093-.291l2.448-2.448a.333.333 0 01.236-.097h1.656c.126 0 .241.071.298.184l.482.965A.333.333 0 0013.54 4h2.255c.126 0 .242.071.298.184l1.816 3.632a.333.333 0 00.298.184h1.127c.275 0 .432.314.267.533l-1.582 2.11a.33.33 0 00-.031.05l-.618 1.237a.334.334 0 00-.036.149v1.176a.333.333 0 01-.035.149l-1.872 3.745a.333.333 0 01-.299.184h-.921a.333.333 0 01-.298-.184l-.54-1.079a.334.334 0 01-.035-.149v-3.783a.333.333 0 00-.097-.236l-.514-.513a.332.332 0 01-.08-.13l-.566-1.698a.333.333 0 00-.316-.228H8.949a.333.333 0 01-.329-.278l-.592-3.552zM18.667 13.667c0-.184.149-.334.333-.334h.667c.184 0 .333.15.333.334v.921a.332.332 0 01-.035.149l-.54 1.079a.333.333 0 01-.298.184H19a.333.333 0 01-.333-.333v-2z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgCaf;
