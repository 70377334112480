import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgEg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="eg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#eg_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 6.667h28V0H0v6.667z" fill="#E41D33" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#262626" />
        <Path
          d="M12.368 9.482l.285.569.306-.557h0l.001-.002.005-.01.023-.038a5.144 5.144 0 01.383-.573c.122-.157.252-.302.378-.404.134-.11.217-.134.251-.134.057 0 .151.031.281.136.126.1.252.242.369.398a4.51 4.51 0 01.38.604l.004.01.001.001h0l.298.597.299-.597.574-1.149h.46c.185 0 .334.15.334.334v2.087a.333.333 0 01-.518.278l-.964-.643-.084-.056h-1.101v1a.333.333 0 11-.666 0v-1h-1.101l-.084.056-.964.643a.333.333 0 01-.518-.278V8.667c0-.184.15-.334.333-.334h.461l.574 1.15z"
          fill="#F4B32E"
          fillOpacity={0.25}
          stroke="#C6A846"
          strokeWidth={0.667}
        />
      </G>
    </Svg>
  );
};

export default SvgEg;
