import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { EmailStep } from '@app/features/forgotPassword/forgotPasswordSteps/EmailStep';
import { CodeStep } from '@app/features/forgotPassword/forgotPasswordSteps/CodeStep';
import { NewPasswordStep } from '@app/features/forgotPassword/forgotPasswordSteps/NewPasswordStep';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';

import { ForgotPasswordNavigatorRoutes, ForgotPasswordStackParamList } from './types/routes';

const Stack = createStackNavigator<ForgotPasswordStackParamList>();

export const ForgotPasswordNavigator: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={SCREEN_OPTIONS}>
      <Stack.Screen
        name={ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep}
        component={EmailStep}
      />
      <Stack.Screen
        name={ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep}
        component={CodeStep}
      />
      <Stack.Screen
        name={ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep}
        component={NewPasswordStep}
      />
    </Stack.Navigator>
  );
};
