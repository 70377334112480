import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

export const getStyle = (isLowerThanLaptop: boolean, theme: Theme) =>
  StyleSheet.create({
    buttonContainer: {
      flexDirection: isLowerThanLaptop ? 'column' : 'row',
      marginTop: isLowerThanLaptop ? theme.spacing.SIZE_08 : theme.spacing.SIZE_07,
    },
    italic: {
      fontStyle: 'italic',
    },
    noResultContainer: {
      marginHorizontal: theme.spacing.SIZE_04,
    },
    orText: {
      alignSelf: 'center',
      marginHorizontal: theme.spacing.SIZE_05,
    },
  });
