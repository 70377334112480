import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCoffeeTable = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4.35 9H19.7l-.85-3H5.225L4.35 9zm2.625 4h10.1l-.25-2H7.25l-.275 2zM5.15 20a.927.927 0 01-.738-.337.998.998 0 01-.237-.788L5.25 11H3.025a.917.917 0 01-.787-.4.996.996 0 01-.163-.875l1.425-5a1.05 1.05 0 01.35-.525.93.93 0 01.6-.2H19.6a.93.93 0 01.6.2c.167.133.283.308.35.525l1.425 5a.996.996 0 01-.163.875.917.917 0 01-.787.4h-2.2l1.05 7.875a.998.998 0 01-.237.788.927.927 0 01-.738.337c-.25 0-.47-.08-.662-.238a.923.923 0 01-.338-.612L17.35 15H6.7l-.55 4.15a.923.923 0 01-.338.612A1.007 1.007 0 015.15 20z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCoffeeTable;
