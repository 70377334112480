import * as React from 'react';

import Svg, { SvgProps, Mask, Path, G } from 'react-native-svg';

const SvgStandLamp = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Mask id="stand-lamp_svg__a" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </Mask>
      <G mask="url(#stand-lamp_svg__a)">
        <Path
          d="M4 22a.965.965 0 01-.712-.288A.965.965 0 013 21c0-.283.096-.52.288-.712A.965.965 0 014 20h6c.283 0 .521.096.713.288A.967.967 0 0111 21c0 .283-.096.52-.287.712A.968.968 0 0110 22H4zm13-1c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0115 19h-3a.965.965 0 01-.712-.288A.965.965 0 0111 18c-.033-1.5.433-2.8 1.4-3.9.967-1.1 2.167-1.767 3.6-2V3c0-.283.096-.521.288-.713A.967.967 0 0117 2c.283 0 .52.096.712.287.192.192.288.43.288.713v9.1c1.45.233 2.654.9 3.613 2 .958 1.1 1.42 2.4 1.387 3.9 0 .283-.096.52-.288.712A.965.965 0 0122 19h-3c0 .55-.192 1.021-.575 1.413A1.915 1.915 0 0117 21zM7 19a.968.968 0 01-.713-.288A.967.967 0 016 18v-7H2a.93.93 0 01-.788-.387.968.968 0 01-.162-.863l1.875-7a1.09 1.09 0 01.363-.538A.944.944 0 013.9 2h6.225c.233 0 .438.07.613.212s.295.321.362.538l1.875 7a.967.967 0 01-.163.863.927.927 0 01-.787.387H8v7c0 .283-.096.52-.287.712A.968.968 0 017 19zm6.1-2h7.8c-.2-.9-.667-1.625-1.4-2.175A4.059 4.059 0 0017 14c-.917 0-1.742.275-2.475.825A3.73 3.73 0 0013.1 17zM3.3 9h7.4L9.375 4H4.65L3.3 9z"
          fill={props.color}
        />
      </G>
    </Svg>
  );
};

export default SvgStandLamp;
