import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKh = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="kh_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#kh_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 5.333h28V0H0v5.333zM0 20h28v-5.333H0V20z" fill="#0F3EB6" />
        <Path d="M0 14.667h28V5.333H0v9.334z" fill="#ED1A3C" />
        <Path
          d="M12 8.727h1.333V8a.667.667 0 011.334 0v.727H16V10h-4V8.727zM17.333 8a.667.667 0 00-.666.667V10H18V8.667A.667.667 0 0017.333 8zm-6 5.333v-2.666H10l-1.333 2.666h2.666zM16 10.667h-4v2.666h4v-2.666zm.667 0H18l1.333 2.666h-2.666v-2.666zm-6-2.667a.667.667 0 00-.667.667V10h1.333V8.667A.667.667 0 0010.667 8z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgKh;
