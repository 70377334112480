import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSk = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="sk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#sk_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.333h28V6.667H0v6.666z"
          fill="#0C47B7"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#E53B35" />
        <Path
          d="M6.95 3.333A2 2 0 004.95 5.256l-.26 6.74a2 2 0 00.698 1.596l2.876 2.465a2.667 2.667 0 003.47 0l2.876-2.465a2 2 0 00.697-1.595l-.26-6.74a2 2 0 00-1.998-1.924h-6.1z"
          fill="#F73744"
          stroke="#fff"
          strokeWidth={1.333}
        />
        <Mask
          id="sk_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={4}
          y={2}
          width={12}
          height={16}
        >
          <Path
            d="M6.95 3.333A2 2 0 004.95 5.256l-.26 6.74a2 2 0 00.698 1.596l2.876 2.465a2.667 2.667 0 003.47 0l2.876-2.465a2 2 0 00.697-1.595l-.26-6.74a2 2 0 00-1.998-1.924h-6.1z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={1.333}
          />
        </Mask>
        <G mask="url(#sk_svg__b)" fillRule="evenodd" clipRule="evenodd">
          <Path
            d="M9.333 11A.333.333 0 009 10.667H7.667a.333.333 0 01-.334-.334v-.666c0-.184.15-.334.334-.334H9c.184 0 .333-.149.333-.333v-.667A.333.333 0 009 8h-.667A.333.333 0 018 7.667V7c0-.184.15-.333.333-.333H9c.184 0 .333-.15.333-.334v-.666c0-.184.15-.334.334-.334h.666c.184 0 .334.15.334.334v.666c0 .184.149.334.333.334h.667c.184 0 .333.149.333.333v.667c0 .184-.15.333-.333.333H11a.333.333 0 00-.333.333V9c0 .184.149.333.333.333h1.333c.184 0 .334.15.334.334v.666c0 .184-.15.334-.334.334H11a.333.333 0 00-.333.333v2c0 .184-.15.333-.334.333h-.666A.333.333 0 019.333 13v-2z"
            fill="#fff"
          />
          <Path
            d="M11.204 13.425a1.333 1.333 0 00-2.408 0C7.176 13.681 6 14.438 6 15.333c0 1.105 1.79 2 4 2s4-.895 4-2c0-.895-1.175-1.652-2.796-1.908z"
            fill="#1251A1"
          />
        </G>
      </G>
    </Svg>
  );
};

export default SvgSk;
