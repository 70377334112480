import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { DiscussNavigatorRoutes, DiscussStackParamList } from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import DiscussView from '@app/views/discuss/DiscussView';
import { DiscussBeCalled } from '@app/features/discuss/DiscussBeCalled';
import { DiscussTakeAppointment } from '@app/features/discuss/DiscussTakeAppointment';
import { DiscussTakeAppointmentConfirmation } from '@app/features/discuss/DiscussTakeAppointmentConfirmation';

const Stack = createStackNavigator<DiscussStackParamList>();

export const DiscussNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={DiscussNavigatorRoutes.DiscussRoot}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={DiscussNavigatorRoutes.DiscussRoot} component={DiscussView} />
    <Stack.Screen name={DiscussNavigatorRoutes.DiscussBeCalled} component={DiscussBeCalled} />
    <Stack.Screen
      name={DiscussNavigatorRoutes.DiscussTakeAppointment}
      component={DiscussTakeAppointment}
    />
    <Stack.Screen
      name={DiscussNavigatorRoutes.DiscussTakeAppointmentConfirmation}
      component={DiscussTakeAppointmentConfirmation}
    />
  </Stack.Navigator>
);
