import React, { useMemo } from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { Theme, TranslatedContentContentType, useTheme } from '@app/ui';
import { trackTranslationSwitch } from '@app/services/tracking/trackTracking';
// eslint-disable-next-line max-len
import { RenovationSummaryDetailTemplate } from '@app/features/realEstate/realEstateAvailable/realEstateRenovation/components/renovationSummary/RenovationSummaryDetailTemplate';

type RenovationSummaryDetailProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RenovationSummaryDetail
>;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    safeArea: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },
  });

export const RenovationSummaryDetailView: React.FC<RenovationSummaryDetailProps> = ({
  navigation,
  route,
}) => {
  const { renovationSummary } = route.params;
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const trackSwitchLanguage = (
    type: TranslatedContentContentType,
    sourceLang?: string,
    targetLang?: string
  ) => {
    trackTranslationSwitch({
      propertyId: renovationSummary.realEstateId,
      sourceLang,
      targetLang,
      type,
    });
  };

  return (
    <SafeAreaView style={styles.safeArea} edges={['top']}>
      <RenovationSummaryDetailTemplate
        realEstateId={renovationSummary.realEstateId}
        dpe={renovationSummary.dpe}
        ges={renovationSummary.ges}
        furnishingAmount={renovationSummary.furnishingAmount}
        renovationAmount={renovationSummary.renovationAmount}
        translatedDescription={renovationSummary.translatedDescription}
        trackSwitchLanguage={trackSwitchLanguage}
        onClose={navigation.goBack}
      />
    </SafeAreaView>
  );
};
