import React from 'react';

import { View } from 'react-native';

import { Text } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { ProjectDetailStepAdditionalInfoProps } from '@app/features/project/ProjectDetail/ProjectDetail.type';
import { detailStepInfoStyles as styles } from '@app/features/project/ProjectDetail/ProjectDetailStage.styles';

export const ProjectDetailStepAdditionalInfo: React.FC<ProjectDetailStepAdditionalInfoProps> = ({
  infoList,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text textStyle="Body3" style={styles.title}>
        {title}
      </Text>
      {infoList.map(info => (
        <View key={`project-detail-step-additional-info-${info.label}`} style={styles.infoLine}>
          <Text textStyle="Body2Medium" style={styles.info}>
            {t(info.label)}
          </Text>
          {info.optional ? (
            <Text textStyle="Body2" style={styles.optionalText}>
              {t('project.optional')}
            </Text>
          ) : null}
        </View>
      ))}
    </View>
  );
};
