import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { ExpandableGroup, Expandable } from '@app/ui/atoms/expandable';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Text } from '@app/ui/atoms/Text/Text';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { useTheme } from '@app/ui/contexts/config';
import { useTranslation } from '@app/services/translations/translations';
import { Theme } from '@app/ui';

import { Grade, GradeIndicator } from './GradeIndicator';

interface PerformanceIndicatorProps {
  data: Grade[];
  title: string;
  caption: string;
  labelHighest: string;
  labelLowest: string;
  value: string;
}

const getStyles = (isLowerThanLaptop: boolean, theme: Theme) =>
  StyleSheet.create({
    containerPerformanceModal: { minWidth: isLowerThanLaptop ? 360 : 480 },
    description: { color: theme.palette.neutral[800] },
    labelHighest: { color: theme.palette.neutral[700] },
    labelLowest: { color: theme.palette.neutral[700] },
  });

export const PerformanceIndicator: React.FC<PerformanceIndicatorProps> = ({
  data,
  title,
  caption,
  value,
  labelHighest,
  labelLowest,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isLowerThanLaptop } = useResponsive();

  const styles = getStyles(isLowerThanLaptop, theme);

  const expandableGradeIndicator = useMemo(
    () =>
      data.map((item, index) => (
        <Expandable id={item.value} key={index}>
          <Expandable.Header>
            <GradeIndicator
              index={index}
              value={t(item.value)}
              label={t(item.label)}
              color={item.color}
              selected={item.value === value}
            />
          </Expandable.Header>
          <Expandable.Content>
            <View>
              <Spacer height={10} />
              <Text style={styles.description} textStyle="Caption1">
                {t(item.description)}
              </Text>
              <Spacer height={10} />
            </View>
          </Expandable.Content>
        </Expandable>
      )),
    []
  );

  return (
    <View style={!isMobile && styles.containerPerformanceModal}>
      <Text textStyle="Headline2Medium">{title}</Text>
      <Spacer height={theme.spacing.SIZE_06} />
      <Text style={styles.labelLowest} textStyle="Caption1">
        {labelLowest}
      </Text>
      <Spacer height={theme.spacing.SIZE_03} />
      <ExpandableGroup initialExpandedId={value}>{expandableGradeIndicator}</ExpandableGroup>
      <Spacer height={theme.spacing.SIZE_03} />
      <Text style={styles.labelHighest} textStyle="Caption1">
        {labelHighest}
      </Text>
      <Spacer height={theme.spacing.SIZE_06} />
      <Text textStyle="Caption2">{caption}</Text>
    </View>
  );
};
