import { DetailStepRecord, ProjectCategory } from '@app/features/project/Project.types';

export const renovationDetailSteps: DetailStepRecord[ProjectCategory.Renovation] = {
  'fr-FR': [
    {
      content: 'project.renovation.detailSteps.1.content',
      stageNumber: 1,
      title: 'project.renovation.detailSteps.1.title',
    },
    {
      content: 'project.renovation.detailSteps.2.content',
      stageNumber: 2,
      subSteps: [
        {
          content: 'project.renovation.detailSteps.2.subSteps.1.content',
          title: 'project.renovation.detailSteps.2.subSteps.1.title',
        },
        {
          content: 'project.renovation.detailSteps.2.subSteps.2.content',
          title: 'project.renovation.detailSteps.2.subSteps.2.title',
        },
        {
          content: 'project.renovation.detailSteps.2.subSteps.3.content',
          title: 'project.renovation.detailSteps.2.subSteps.3.title',
        },
      ],
      title: 'project.renovation.detailSteps.2.title',
    },
    {
      content: 'project.renovation.detailSteps.3.content',
      stageNumber: 3,
      subSteps: [
        {
          content: 'project.renovation.detailSteps.3.subSteps.1.content',
          title: 'project.renovation.detailSteps.3.subSteps.1.title',
        },
        {
          content: 'project.renovation.detailSteps.3.subSteps.2.content',
          title: 'project.renovation.detailSteps.3.subSteps.2.title',
        },
      ],
      title: 'project.renovation.detailSteps.3.title',
    },
    {
      content: 'project.renovation.detailSteps.4.content',
      stageNumber: 4,
      subSteps: [
        {
          content: 'project.renovation.detailSteps.4.subSteps.1.content',
          title: 'project.renovation.detailSteps.4.subSteps.1.title',
        },
        {
          content: 'project.renovation.detailSteps.4.subSteps.2.content',
          title: 'project.renovation.detailSteps.4.subSteps.2.title',
        },
        {
          content: 'project.renovation.detailSteps.4.subSteps.3.content',
          title: 'project.renovation.detailSteps.4.subSteps.3.title',
        },
      ],
      title: 'project.renovation.detailSteps.4.title',
    },
    {
      content: 'project.renovation.detailSteps.5.content',
      stageNumber: 5,
      subSteps: [
        {
          content: 'project.renovation.detailSteps.5.subSteps.1.content',
          title: 'project.renovation.detailSteps.5.subSteps.1.title',
        },
        {
          content: 'project.renovation.detailSteps.5.subSteps.2.content',
          title: 'project.renovation.detailSteps.5.subSteps.2.title',
        },
        {
          content: 'project.renovation.detailSteps.5.subSteps.3.content',
          title: 'project.renovation.detailSteps.5.subSteps.3.title',
        },
      ],
      title: 'project.renovation.detailSteps.5.title',
    },
  ],
};
