import React, { useMemo } from 'react';

import { View } from 'react-native';

import { CircleStepIndicator } from '@app/ui/molecules/circle-step-indicator/CircleStepIndicator';
import { Text } from '@app/ui/atoms/Text/Text';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import {
  StepSize,
  StepVariant,
} from '@app/ui/molecules/circle-step-indicator/CircleStepIndicator.types';

import { TimelineStepProps } from './Timeline.types';
import { getVerticalStepStyles } from './Timeline.styles';

export const TimelineVerticalStep: React.FC<TimelineStepProps> = ({
  indicator,
  isLastStep = false,
  isNextStepFinished,
  onPress,
  status,
  step,
  subSteps,
  title,
}) => {
  const isSubStep = !subSteps?.length;
  const styles = useMemo(
    () => getVerticalStepStyles(status, isSubStep, isNextStepFinished, onPress),
    [isSubStep, isNextStepFinished, status, onPress]
  );

  const handleOnPress = () => onPress?.(step);

  return (
    <View style={styles.container} testID="timeline-step">
      <View style={styles.step}>
        <Pressable onPress={handleOnPress} style={styles.circleContainer}>
          <CircleStepIndicator
            indicator={indicator ?? step}
            status={status}
            size={isSubStep ? StepSize.S : StepSize.L}
            variant={StepVariant.Primary}
          />
        </Pressable>
        {!isLastStep && <View style={styles.stepLink} />}
      </View>
      <Text textStyle="Body2Medium" style={styles.title} ellipsizeMode="tail" numberOfLines={1}>
        {title}
      </Text>
    </View>
  );
};
