import React from 'react';

import { StyleSheet, View } from 'react-native';
import { Trans } from 'react-i18next';

import { Expandable, Icon, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

interface FAQQuestionProps {
  questionKey: string;
  answerKey: string;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    answer: {
      paddingBottom: theme.spacing.SIZE_06,
      paddingHorizontal: theme.spacing.SIZE_03,
      paddingTop: theme.spacing.SIZE_04,
    },
    container: {
      borderBottomWidth: 1,
      borderColor: theme.palette.neutral[200],
    },
    question: {
      flex: 1,
    },
    questionContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: theme.spacing.SIZE_05,
    },
    title: {
      textAlign: 'center',
    },
  });

export const FAQQuestion: React.FC<FAQQuestionProps> = ({ answerKey, questionKey }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.container}>
      <Expandable>
        <Expandable.Header>
          <Expandable.Context.Consumer>
            {({ expanded }) => (
              <View style={styles.questionContainer}>
                <Text textStyle="Body2Medium" style={styles.question}>
                  {t(questionKey)}
                </Text>

                <Icon name={expanded ? 'ChevronUp' : 'ChevronDown'} size={24} />
              </View>
            )}
          </Expandable.Context.Consumer>
        </Expandable.Header>
        <Expandable.Content>
          <View style={styles.answer}>
            <Text textStyle="Body3">
              <Trans i18nKey={answerKey} components={{ bold: <Text textStyle="Body3Medium" /> }} />
            </Text>
          </View>
        </Expandable.Content>
      </Expandable>
    </View>
  );
};
