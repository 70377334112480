import React from 'react';

import { StyleSheet, View, GestureResponderEvent } from 'react-native';

import { Pressable, Text, Theme, useTheme } from '@app/ui';

const getStyle = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderBottomWidth: 1,
      borderColor: theme.palette.neutral[300],
    },
    text: {
      padding: 20,
    },
  });

type OnPress = (e: GestureResponderEvent) => void;

export const PickerItem: React.FC<{ label: string; onPress: OnPress }> = ({ label, onPress }) => {
  const theme = useTheme();
  const styles = getStyle(theme);

  return (
    <Pressable onPress={onPress}>
      <View style={styles.container}>
        <Text textStyle="Body2" style={styles.text}>
          {label}
        </Text>
      </View>
    </Pressable>
  );
};
