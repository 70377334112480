import { NavigationContainerRefWithCurrent, ParamListBase } from '@react-navigation/native';

export const resetNavigation = (navigation: NavigationContainerRefWithCurrent<ParamListBase>) => {
  return navigation.reset({
    index: 0,
    routes: [
      {
        name: navigation.getCurrentRoute().name,
        params: { id: navigation.getCurrentRoute().params['id'] },
      },
    ],
  });
};
