import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgArrowDown = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12.378 19.778c-.133 0-.258-.02-.375-.063a.871.871 0 01-.325-.212l-6.6-6.6c-.2-.2-.3-.438-.3-.713 0-.274.1-.512.3-.712.2-.2.433-.3.7-.3.267 0 .5.1.7.3l4.9 4.9v-11.2c0-.283.096-.516.288-.7a.989.989 0 01.712-.275.97.97 0 01.713.287.97.97 0 01.287.713v11.175l4.9-4.9c.2-.2.433-.3.7-.3.267 0 .5.1.7.3.2.2.3.438.3.712 0 .276-.1.513-.3.713l-6.6 6.6c-.1.1-.208.17-.325.212a1.1 1.1 0 01-.375.063z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgArrowDown;
