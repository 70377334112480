import { AppEnvironment } from './config.enum';

export const getOrganizedConfig = <T>(
  defaultConfig: Partial<T> & { ENVIRONMENT: AppEnvironment },
  config: T
): T => {
  return defaultConfig.ENVIRONMENT === AppEnvironment.Development
    ? {
        ...config,
        ...defaultConfig,
      }
    : {
        ...defaultConfig,
        ...config,
      };
};
