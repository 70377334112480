import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.base.white,
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: 1,
      flexDirection: 'row',
      height: '56px',
      paddingHorizontal: 40,
      width: '100%',
    },
    left: {
      alignItems: 'center',
      flexBasis: 140,
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'flex-start',
    },
    menu: {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
    },
    menuGap: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_08,
      height: '100%',
      justifyContent: 'center',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    menuItem: {
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 1,
      justifyContent: 'center',
    },
    right: {
      alignItems: 'flex-end',
      flexBasis: 140,
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'center',
    },
  });
