import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgPaint = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M15 23h-4a.965.965 0 01-.712-.288A.965.965 0 0110 22v-6c0-.283.096-.521.288-.713A.967.967 0 0111 15h1v-3H4c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 012 10V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h2V3a.97.97 0 01.287-.713A.97.97 0 017 2h12c.283 0 .52.096.712.287.192.192.288.43.288.713v4a.968.968 0 01-.288.713A.967.967 0 0119 8H7a.97.97 0 01-.713-.287A.97.97 0 016 7V6H4v4h8c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v3h1a.97.97 0 01.713.287A.97.97 0 0116 16v6c0 .283-.096.52-.287.712A.968.968 0 0115 23zm-3-2h2v-4h-2v4zM8 6h10V4H8v2z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgPaint;
