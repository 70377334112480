import React, { useCallback, useRef, useState } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ICarouselInstance } from 'react-native-reanimated-carousel-4';

import { ActionButton, Text, Theme, useResponsive, useTheme } from '@app/ui';
import {
  GetMyProjectsQuery,
  LocationType,
  TransactionStatus,
} from '@app/libs/apollo/introspection';
import { HomePageNavigatorRoutes, HomePageStackParamList } from '@app/navigation/types/routes';
import { isNative, isWeb } from '@app/constants/platform';
import { useTranslation } from '@app/services/translations/translations';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';

import { ProjectCard } from '../projectList/ProjectCard/ProjectCard';
import {
  BreakPoint,
  SectionContainer,
  sectionPaddingForScreen,
  useActiveBreakPoint,
} from './SectionContainer';

type HomePageProjectsPropsNav = StackNavigationProp<
  HomePageStackParamList,
  HomePageNavigatorRoutes.Project
>;

const getStyles = ({
  theme,
  isLowerThanDesktop,
  isNativeOrResponsive,
  activeBreakPoint,
  cardWith,
}: {
  theme: Theme;
  isLowerThanDesktop: boolean;
  isNativeOrResponsive: boolean;
  activeBreakPoint: BreakPoint;
  cardWith: number;
}) =>
  StyleSheet.create({
    buttons: { flexDirection: 'row', marginBottom: theme.spacing.SIZE_06 },
    container: {
      backgroundColor: isLowerThanDesktop
        ? theme.palette.base.transparent
        : theme.palette.base.white,
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_06,
      borderWidth: isLowerThanDesktop ? 0 : 1,
      margin: isLowerThanDesktop ? 0 : theme.spacing.SIZE_06,
      marginBottom: theme.spacing.SIZE_06,
      padding: isLowerThanDesktop ? 0 : theme.spacing.SIZE_07,
      paddingHorizontal: isNativeOrResponsive ? theme.spacing.SIZE_06 : theme.spacing.SIZE_07,
    },
    description: { marginBottom: theme.spacing.SIZE_06 },
    dotContainer: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_02,
      justifyContent: 'center',
      marginTop: theme.spacing.SIZE_06,
    },
    projectCard: {
      flex: 1,
      height: 430,
      paddingLeft: sectionPaddingForScreen[activeBreakPoint],
      width: cardWith,
    },
    separator: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[300],
      height: 1,
      width: 350 * 3,
    },
    separatorWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 135,
      width: '100%',
    },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing.SIZE_03,
    },
  });

interface HomePageProjectsProps {
  myProjects: GetMyProjectsQuery['myProjects'];
  loading: boolean;
}

export const HomePageProjects: React.FC<HomePageProjectsProps> = ({ myProjects, loading }) => {
  const navigation = useNavigation<HomePageProjectsPropsNav>();
  const { isLowerThanDesktop, isGreaterThanTablet, isNativeOrResponsive } = useResponsive();

  const { width } = useWindowDimensions();
  const activeBreakPoint = useActiveBreakPoint();
  const CARD_WIDTH = isNativeOrResponsive ? 308 : 400;
  const theme = useTheme();
  const carouselRef = useRef<ICarouselInstance>(null);

  const { t } = useTranslation();

  const styles = getStyles({
    activeBreakPoint,
    cardWith: CARD_WIDTH,
    isLowerThanDesktop,
    isNativeOrResponsive,
    theme,
  });
  const [index, setIndex] = useState(0);

  const canSeeDots = isNative() || isLowerThanDesktop;

  const onPressCard = useCallback(
    (projectId: string) => () => {
      navigation.navigate(HomePageNavigatorRoutes.Project, { id: projectId });
    },
    [navigation]
  );

  const renderItem = useCallback(
    ({
      item: { id, projectAmount, renting, status, realEstate },
    }: {
      item: GetMyProjectsQuery['myProjects'][0];
    }) => (
      <View key={id} style={styles.projectCard}>
        <ProjectCard
          projectAmount={projectAmount}
          rentingType={renting?.type || LocationType.Simple}
          status={status ?? TransactionStatus.Ongoing}
          realEstate={realEstate}
          onPress={onPressCard(id)}
        />
      </View>
    ),
    [onPressCard]
  );

  const onPressBtn = (i: number) => () => {
    setIndex(i);
    carouselRef?.current?.scrollTo({ animated: true, index: i });
  };

  return !loading ? (
    <SectionContainer>
      <Text textStyle="Title3Medium">{t('homePage.projects.title')}</Text>

      {isWeb() && isGreaterThanTablet && myProjects.length > 1 ? (
        <View style={styles.wrapper}>
          <Text textStyle="Body3">{t('homePage.projects.description')}</Text>
          <View style={styles.buttons}>
            <ActionButton
              withSolidBorder
              leftIconName="ArrowLeft"
              disabled={index === 0}
              onPress={onPressBtn(index - 1)}
            />
            <ActionButton
              withSolidBorder
              leftIconName="ArrowRight"
              disabled={index === myProjects.length - 1}
              onPress={onPressBtn(index + 1)}
            />
          </View>
        </View>
      ) : (
        <Text textStyle="Body3" style={styles.description}>
          {t('homePage.projects.description')}
        </Text>
      )}

      <Carousel
        showDots={canSeeDots}
        ref={carouselRef}
        panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
        width={isNativeOrResponsive ? width * 0.9 : CARD_WIDTH}
        height={380}
        style={{
          marginHorizontal: -sectionPaddingForScreen[activeBreakPoint],
          width: width,
        }}
        loop={false}
        data={myProjects}
        renderItem={renderItem}
      />
    </SectionContainer>
  ) : null;
};
