import { useCallback, useState } from 'react';

import {
  UseSliderType,
  UseSlideTypeResponse,
  UseSliderCallbackParamsType,
  UseSliderCallbackType,
} from './useSlider.types';

export { UseSliderType, UseSlideTypeResponse, UseSliderCallbackParamsType, UseSliderCallbackType };

export const useSlider = ({
  initialStep = 0,
  loop,
  numberOfSlides,
  onStep,
  withControls = false,
  ...props
}: UseSliderType): UseSlideTypeResponse => {
  const [step, setStep] = useState<number>(
    initialStep < numberOfSlides ? Math.max(0, initialStep) : 0
  );

  const goPrev = useCallback(() => {
    const newStep = !loop || step > 0 ? step - 1 : numberOfSlides - 1;
    onStep?.({ lastStep: step, step: newStep });
    setStep(newStep);
  }, [loop, step, numberOfSlides, onStep, setStep]);

  const goNext = useCallback(() => {
    const newStep = !loop || step < numberOfSlides - 1 ? step + 1 : 0;
    onStep?.({ lastStep: step, step: newStep });
    setStep(newStep);
  }, [loop, step, numberOfSlides, onStep, setStep]);

  const goStep = useCallback(
    (newStep: number) => {
      onStep?.({ lastStep: step, step: newStep });
      setStep(newStep);
    },
    [onStep, step, setStep]
  );

  const next = numberOfSlides > 1 && (step < numberOfSlides - 1 || loop) ? goNext : undefined;
  const prev = numberOfSlides > 1 && (step > 0 || loop) ? goPrev : undefined;

  return {
    carouselProps: {
      ...props,
      currentStep: step,
      loop,
      onGoStep: goStep,
      ...(withControls
        ? {
            onNext: next,
            onPrev: prev,
          }
        : {}),
    },
    goStep: goStep,
    next,
    numberOfSlides,
    prev,
    sliderProps: {
      ...props,
      currentStep: step,
      loop,
      onGoStep: goStep,
      onNext: next,
      onPrev: prev,
    },
    step,
  };
};
