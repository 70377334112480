import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgFeather = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M8 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 016 18v-2a.97.97 0 01.287-.713A.97.97 0 017 15h2v-2.25a4.884 4.884 0 01-1.662-.388A4.387 4.387 0 015.9 11.35v-1.1H4.75l-2.4-2.4c-.283-.283-.425-.575-.425-.875 0-.3.142-.567.425-.8.483-.417 1.08-.733 1.788-.95A7.69 7.69 0 016.4 4.9c.45 0 .888.033 1.313.1.425.067.854.192 1.287.375V5c0-.283.096-.521.288-.713A.967.967 0 0110 4h10c.283 0 .52.096.712.287.192.192.288.43.288.713v12c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0118 20H8zm3-5h5c.283 0 .52.096.712.287.192.192.288.43.288.713v1c0 .283.096.52.288.712A.965.965 0 0018 18c.283 0 .52-.096.712-.288A.965.965 0 0019 17V6h-8v.6l5.7 5.7c.117.117.196.225.238.325.041.1.062.225.062.375 0 .283-.096.52-.288.712A.965.965 0 0116 14a.987.987 0 01-.375-.062 1.07 1.07 0 01-.325-.238l-2.55-2.55-.2.2c-.233.233-.48.442-.738.625A3.289 3.289 0 0111 12.4V15zM5.6 8.25h2.3v2.15c.2.133.408.225.625.275.217.05.442.075.675.075.383 0 .73-.058 1.038-.175.308-.117.612-.325.912-.625l.2-.2-1.4-1.4a4.851 4.851 0 00-1.625-1.088A5.11 5.11 0 006.4 6.9c-.333 0-.65.025-.95.075-.3.05-.6.125-.9.225L5.6 8.25zM8 18h7.15a2.794 2.794 0 01-.112-.475A3.497 3.497 0 0115 17H8v1zm0 0v-1 1z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgFeather;
