/* eslint-disable react-native/no-inline-styles */
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { Animated, PressableStateCallbackType, View } from 'react-native';

import { useAnimation } from '@app/ui/utils/useAnimation';
import { getWeakId } from '@app/ui/utils/idGen';

import { ExpandableGroupContext } from './ExpandableGroup';
import {
  ExpandableComposition,
  ExpandableProps,
  ExpandableContextType,
} from './ExpandableGroup.types';
import { Pressable } from '../pressable/Pressable';

export const Header: React.FC<{
  testID?: string;
  children: React.ReactNode | ((state: PressableStateCallbackType) => React.ReactNode);
}> = ({ children, testID = '' }) => {
  const { toggleContent, onPress, expanded } = useContext(ExpandableContext);

  return (
    <Pressable
      testID={testID}
      withPressOpacity={false}
      onPress={() => {
        onPress?.({ expanded: !expanded });
        toggleContent();
      }}
    >
      {children}
    </Pressable>
  );
};

const Content: React.FC<PropsWithChildren<{ testID?: string }>> = ({ children, testID }) => {
  const { expanded } = useContext(ExpandableContext);
  const { setIsOn, interpolate } = useAnimation(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const [height] = interpolate([0, contentHeight || 0]);

  useEffect(() => {
    if (height !== undefined) {
      setIsOn(expanded);
    }
  }, [expanded, height]);

  return (
    <Animated.View
      testID={testID}
      style={{
        height,
        overflow: 'hidden',
      }}
    >
      <View
        style={{ position: 'absolute', width: '100%' }}
        onLayout={event => {
          const { height: layoutHeight } = event.nativeEvent.layout;
          setContentHeight(layoutHeight);
        }}
      >
        {children}
      </View>
    </Animated.View>
  );
};

export const ExpandableContext = createContext<ExpandableContextType>({
  expanded: false,
  onPress: () => {},
  toggleContent: () => {},
});

/**
 * @description
 * @param {ExpandableId} [id] - Allow ExpandableGroup parent component to identify Epxandable children. If used within ExpandableGroup Wrapper, id must be different.
 * @param {boolean} [initialExpanded=true] - If true expands the content at initialization, otherwise keeps it collapsed .
 * @returns {Expandable}
 */
export const Expandable: React.FC<ExpandableProps> & ExpandableComposition = ({
  children,
  id = getWeakId(),
  initialExpanded = false,
  onPress = () => {},
}) => {
  const { shouldCollapse, onExpand, initialExpandedId } = useContext(ExpandableGroupContext);
  const expanded0 = initialExpandedId === id || initialExpanded;
  const [expanded, setExpanded] = useState(expanded0);

  useEffect(() => {
    if (shouldCollapse?.(id)) {
      setExpanded(false);
    }
  }, [id, shouldCollapse]);

  const toggleContent = () => {
    if (!expanded) {
      onExpand?.(id);
    }

    setExpanded(!expanded);
  };

  const value = { expanded, onPress, toggleContent };

  return (
    <ExpandableContext.Provider value={value}>
      <View>{children}</View>
    </ExpandableContext.Provider>
  );
};

Expandable.Header = Header;
Expandable.Content = Content;
Expandable.Context = ExpandableContext;
