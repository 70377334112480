import React, { useState } from 'react';

import { StyleSheet } from 'react-native';
import { SliderInput } from '@ui/atoms/slider-input/SliderInput';

import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackTooltip } from '@app/services/tracking/trackTracking';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { Callout, useTheme } from '@app/ui';
import { percentFormatter } from '@app/utils/currency-formatter';
import {
  Importance,
  RealEstateAttribute,
} from '@app/features/realEstate/components/RealEstateAttribute';

interface RealEstateAnnualAvgOccupancyProps {
  annualAvgOccupancyInMonths: number;
  countryCode: AvailableCountryCode;
  financialModelVersion: number;
  isFurnished: boolean;
  propertyId: string;
  shortTermOccupancyRate?: number;
  withShortTermRental: boolean;
  setAnnualAvgOccupancyInMonths: (v: number) => void;
  setShortTermOccupancyRate: (v: number) => void;
}

const styles = StyleSheet.create({
  callout: {
    alignItems: 'flex-start',
  },
});

export const RealEstateAnnualAvgOccupancy: React.FC<RealEstateAnnualAvgOccupancyProps> = ({
  annualAvgOccupancyInMonths,
  countryCode,
  financialModelVersion,
  isFurnished,
  propertyId,
  shortTermOccupancyRate = 0,
  withShortTermRental,
  setAnnualAvgOccupancyInMonths,
  setShortTermOccupancyRate,
}) => {
  const { t } = useRealEstateTranslation();
  const theme = useTheme();

  const [annualAvgOccupancyInMonthsState, setAnnualAvgOccupancyInMonthsState] = useState(
    annualAvgOccupancyInMonths
  );
  const [shortTermOccupancyRateState, setShortTermOccupancyRateState] =
    useState(shortTermOccupancyRate);

  const isAnnualAvgOccupancyVisible =
    (financialModelVersion >= 4 && countryCode === AvailableCountryCode.Fr) ||
    (financialModelVersion >= 3 && countryCode === AvailableCountryCode.Es);

  const trackTooltipAnnualAvgOccupancy = () =>
    trackTooltip({ propertyId, tooltipType: "Taux d'occupation annuel" });
  const trackTooltipShortTermOccupancyRate = () =>
    trackTooltip({ propertyId, tooltipType: "Taux d'occupation location courte durée" });

  const annualTurnoverIndiceSlider = React.useMemo(
    () =>
      withShortTermRental ? (
        <SliderInput
          min={0}
          step={1}
          max={100}
          setSliderValue={(value: number) => {
            setShortTermOccupancyRate(value);
            setShortTermOccupancyRateState(value);
          }}
          testID="annualAvgOccupancy-short-term-occupancy-rate-slider"
          value={shortTermOccupancyRateState}
        />
      ) : (
        <SliderInput
          min={0}
          step={0.5}
          max={12}
          setSliderValue={(value: number) => {
            setAnnualAvgOccupancyInMonthsState(value);
            setAnnualAvgOccupancyInMonths(value);
          }}
          testID="annualAvgOccupancy-annual-turnover-indice-slider"
          value={annualAvgOccupancyInMonthsState}
        />
      ),
    [
      annualAvgOccupancyInMonthsState,
      setAnnualAvgOccupancyInMonths,
      setShortTermOccupancyRate,
      shortTermOccupancyRateState,
      withShortTermRental,
    ]
  );

  const annualTurnoverDescription = t(
    'shared.financialTool.tooltip.annualTurnoverIndiceDescription',
    { context: isFurnished ? 'furnished' : 'unfurnished' }
  );
  const shortTermOccupancyRateDescription = t(
    'shared.financialTool.tooltip.shortTermOccupancyRateDescription'
  );

  return (
    <>
      {withShortTermRental ? (
        <RealEstateAttribute
          label={t('shared.financialTool.shortTermOccupancyRate.label')}
          value={percentFormatter(shortTermOccupancyRateState, 0)}
          importance={Importance.LOW}
          tooltip={{
            description: shortTermOccupancyRateDescription,
            onOpen: trackTooltipShortTermOccupancyRate,
            title: t(`shared.financialTool.tooltip.shortTermOccupancyRate`),
          }}
          isOdd
          valueColor={theme.palette.tint.purple[500]}
        />
      ) : (
        <RealEstateAttribute
          label={t('shared.financialTool.annualAvgOccupancy.label')}
          value={t('shared.financialTool.annualAvgOccupancy.value', {
            amount: annualAvgOccupancyInMonthsState,
          })}
          importance={Importance.LOW}
          tooltip={{
            description: annualTurnoverDescription,
            onOpen: trackTooltipAnnualAvgOccupancy,
            title: t(`shared.financialTool.tooltip.annualTurnoverIndice`),
          }}
          isOdd
        />
      )}

      {!!isAnnualAvgOccupancyVisible && annualTurnoverIndiceSlider}

      {!!withShortTermRental && (
        <Callout
          tint={theme.palette.tint.purple}
          icon="Help"
          wrapperStyle={styles.callout}
          text={t('shared.financialTool.shortTermOccupancyRateInfo', {
            defaultOccupancyRate: shortTermOccupancyRate,
          })}
        />
      )}
    </>
  );
};
