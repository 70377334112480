import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgSc = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="sc_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#sc_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M28 0H0v20h28v-6.667L0 20 28 6.667V0z"
          fill="url(#sc_svg__paint0_linear_4223_11783)"
        />
        <Path d="M0 0h9.333L0 20V0z" fill="#0858B4" />
        <Path d="M18.667 0H28v6.667L0 20 18.667 0z" fill="#ED3535" />
        <Path d="M28 20v-6.667L0 20h28z" fill="#08964F" />
        <Path d="M9.333 0h9.334L0 20 9.333 0z" fill="#FFDD67" />
      </G>
      <Defs>
        <LinearGradient
          id="sc_svg__paint0_linear_4223_11783"
          x1={0}
          y1={0}
          x2={0}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1DBE4F" />
          <Stop offset={1} stopColor="#159B3F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgSc;
