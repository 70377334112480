import React from 'react';

import { Callout } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

export const ProfileNotificationCallout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Callout
      text={t('profile.notificationPreferences.callout')}
      icon="Help"
      testID="profile-notification-callout"
    />
  );
};
