import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="gg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#gg_svg__a)">
        <Path fill="#E8112D" d="M10.667 0h6.667v20h-6.667z" />
        <Path fill="#E8112D" d="M0 6.667h28v6.667H0z" />
        <Path fill="#F9DD16" d="M13.333 2.667h1.333v14.667h-1.333z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 16l1.333 1.333h-2.666L14 16z"
          fill="#F9DD16"
        />
        <Path fill="#F9DD16" d="M6.667 9.333h14.667v1.333H6.667z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 4l1.333-1.333h-2.666L14 4zM8 10L6.667 8.667v2.666L8 10zM20 10l1.333-1.333v2.666L20 10z"
          fill="#F9DD16"
        />
      </G>
    </Svg>
  );
};

export default SvgGg;
