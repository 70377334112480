import React, { memo } from 'react';

import { StyleSheet, View } from 'react-native';
import { capitalize } from 'lodash';

import { IconPin, Popover, Text } from '@app/ui';
import { kmConverter } from '@app/utils/kmConverter';
import { PointOfInterestSubType } from '@app/libs/apollo/introspection';

type MapMarkerProps = {
  distance?: number;
  label: string;
  lat: number;
  lines?: string;
  lng: number;
  onMarkerClick?: () => void;
  subType: PointOfInterestSubType;
};

const styles = StyleSheet.create({
  container: { alignItems: 'center', flexDirection: 'row' },
  popoverContainer: { transform: [{ translateX: -25 }, { translateY: -50 }] },
});

const MapMarker = ({ subType, label, distance, lines }: MapMarkerProps) => {
  return (
    <View style={styles.popoverContainer}>
      <Popover
        title={`${capitalize(subType)} - ${label}`}
        content={
          <View>
            <Text textStyle="Body3">à {kmConverter(distance)}</Text>

            <View style={styles.container}>
              <View>
                <IconPin icon={subType} withTranslate={false} />
              </View>
              {!!lines && <Text textStyle="Body3Medium">{lines}</Text>}
            </View>
          </View>
        }
        triggerType="hover"
        variant="inLight"
      >
        <IconPin icon={subType} />
      </Popover>
    </View>
  );
};

export default memo(MapMarker);
