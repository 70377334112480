/**
 * @see https://www.figma.com/file/2t1KkEdtEW8hQrLufF5OTI/%F0%9F%92%8E-Masteos-Foundations?node-id=4745%3A1156
 */

export interface Tint {
  400: string;
  50: string;
  500: string;
  600: string;
  700: string;
}

export const palette = {
  alert: {
    error: '#E72038',
    info: '#323234',
    valid: '#16875E',
  },
  base: {
    black: '#131315',
    transparent: 'transparent',
    white: '#FFFFFF',
  },
  brand: {
    1: '#F2F1F0',
    2: '#E4DDD3',
    3: '#B2B0AB',
    4: '#888F8B',
    5: '#2E2E2D',
    6: '#030304',
  },
  neutral: {
    100: '#F2F2F6',
    200: '#E5E5E9',
    300: '#D1D1D6',
    400: '#C7C7CB',
    50: '#FAFAFC',
    500: '#AEAEB2',
    600: '#8E8E93',
    700: '#6A6A6F',
    800: '#323234',
    900: '#19191A',
  },
  system: {
    danger: {
      400: '#B1192B',
      500: '#A72635',
      600: '#8B1B28',
    },
    primary: {
      400: '#0A6CFF',
      500: '#0063F8',
      600: '#0050C7',
    },
    secondary: {
      400: '#484860',
      500: '#2E2E3F',
      600: '#202028',
    },
  },

  tint: {
    blue: {
      400: '#479DFA',
      50: '#E0EFFF',
      500: '#0072EF',
      600: '#0062CC',
      700: '#004999',
    },
    chesnut: {
      400: '#B49C7E',
      50: '#F2EEE8',
      500: '#A2845E',
      600: '#81694B',
      700: '#614F38',
    },
    gray: {
      300: '#D1D1D6',
      400: '#C7C7CB',
      50: '#E5E5E9',
      500: '#AEAEB2',
      600: '#8E8E93',
      700: '#19191A',
    },
    green: {
      400: '#36CE83',
      50: '#EAFAF2',
      500: '#14BD69',
      600: '#0F8F4F',
      700: '#0A6136',
    },
    orange: {
      400: '#FFAA33',
      50: '#FFF4E5',
      500: '#FF9500',
      600: '#CC7700',
      700: '#794B0B',
    },
    pink: {
      400: '#EE77D4',
      50: '#FCE8F8',
      500: '#E849C5',
      600: '#E21DB7',
      700: '#9C157E',
    },
    purple: {
      400: '#A17FE6',
      50: '#F2EEFC',
      500: '#8152DE',
      600: '#6329D6',
      700: '#4F21AB',
    },
    red: {
      400: '#F7697A',
      50: '#FEF0F2',
      500: '#F4384E',
      600: '#ED0C27',
      700: '#AA091C',
    },
    turquoise: {
      400: '#31B7C9',
      50: '#E6F7F9',
      500: '#2792A0',
      600: '#1D6D77',
      700: '#13474E',
    },
    yellow: {
      400: '#FFD633',
      50: '#FFFAE5',
      500: '#FFCC00',
      600: '#CCA300',
      700: '#8E7401',
    },
  },
};

/**
 *
 * @param color : color in hexadecimal with #;
 * @param opacity : opacity in percentage;
 * @returns color in hexadecimal with the opacity;
 */
export const getColorWithOpacity = (color: string, opacity: number): string => {
  if (color[0] !== '#') {
    throw new Error('Invalid color - Missing # at beginning of string');
  }

  const hexOpacity = Number((255 * opacity) / 100)
    .toString(16)
    .slice(0, 2)
    .padStart(2, '0');

  return color + hexOpacity;
};
