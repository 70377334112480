// eslint-disable-next-line max-len
import { avalaibleCountryCodeToCountryCodeOption } from '@app/features/search-engine/compounds/search-engine-filters/utils/filter-to-preferences.mapper';
import {
  CountryCodeOption,
  CustomerPreferences,
  CustomerPreferencesInput,
} from '@app/libs/apollo/introspection';

export const customerPreferencesToPreferencesInput = (
  customerPreferences: CustomerPreferences | undefined
): CustomerPreferencesInput => {
  const countryCode = avalaibleCountryCodeToCountryCodeOption(customerPreferences?.countryCode);
  const useZoneInsteadOfRegions = countryCode === CountryCodeOption.Fr;

  return {
    budgetMax: customerPreferences?.budgetMax,
    budgetMin: customerPreferences?.budgetMin,
    countryCode,
    hasNeedRenovation: customerPreferences?.hasNeedRenovation,
    propertyKind: customerPreferences?.propertyKind || [],
    propertyOnTheGroundFloor: customerPreferences?.propertyOnTheGroundFloor,
    propertyRented: customerPreferences?.propertyRented,
    propertyRoomCount: customerPreferences?.propertyRoomCount || [],
    regions: !useZoneInsteadOfRegions ? customerPreferences?.regions || [] : [],
    sharedProperty: customerPreferences?.sharedProperty,
    sortStrategy: customerPreferences?.sortStrategy,
    withShortTermRental: customerPreferences?.withShortTermRental,
    zones: useZoneInsteadOfRegions ? customerPreferences?.zones : [],
  };
};
