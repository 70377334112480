export const handleCheckboxChange: <T>(
  onChange: (newList: T[]) => void,
  list: T[],
  target: T
) => (checked: boolean) => void = (onChange, list, target) => checked => {
  onChange(checked ? [...new Set([...list, target])] : list.filter(v => v !== target));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRadioLabels = (t: (key: string) => string) => ({
  false: t('propertiesPreferences.no'),
  null: t('propertiesPreferences.noMatter'),
  true: t('propertiesPreferences.yes'),
});
