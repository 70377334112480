import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGy = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="gy_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#gy_svg__a)">
        <Path fill="#08C55F" d="M0 0h28v20H0z" />
        <Path
          d="M-.667 19.333V.667h.559l27.441 9.147v.372L-.108 19.333h-.559z"
          fill="#FFD831"
          stroke="#fff"
          strokeWidth={1.333}
        />
        <Path
          d="M-.667 19.333V.667h.469L14.112 10l-14.31 9.333h-.469z"
          fill="#E92034"
          stroke="#262626"
          strokeWidth={1.333}
        />
      </G>
    </Svg>
  );
};

export default SvgGy;
