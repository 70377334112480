import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getBannerStyle = ({
  theme,
  isLowerThanLaptop,
}: {
  theme: Theme;
  isLowerThanLaptop: boolean;
}) => {
  return StyleSheet.create({
    buttonContainer: {
      flexGrow: 1,
    },
    container: {
      backgroundColor: theme.palette.brand[5],
      flexDirection: isLowerThanLaptop ? 'column' : 'row',
      padding: theme.spacing.SIZE_05,
      width: '100%',
      zIndex: 5,
    },
    label: {
      ...theme.textStyles.Body2,
      color: theme.palette.base.white,
      paddingLeft: theme.spacing.SIZE_03,
    },
    left: {
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 1,
    },
    right: {
      ...(isLowerThanLaptop
        ? { justifyContent: 'space-around', paddingTop: theme.spacing.SIZE_05 }
        : null),
      flexDirection: 'row',
    },
  });
};
