import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMoreVertical = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.692 19.895c-.55 0-1.02-.196-1.412-.587a1.927 1.927 0 01-.588-1.413c0-.55.196-1.021.588-1.413a1.925 1.925 0 011.412-.587c.55 0 1.021.196 1.413.587.392.392.587.863.587 1.413s-.195 1.021-.587 1.413a1.928 1.928 0 01-1.413.587zm0-6c-.55 0-1.02-.196-1.412-.588a1.923 1.923 0 01-.588-1.412c0-.55.196-1.021.588-1.413a1.925 1.925 0 011.412-.587c.55 0 1.021.196 1.413.587.392.392.587.863.587 1.413s-.195 1.02-.587 1.412a1.927 1.927 0 01-1.413.588zm0-6c-.55 0-1.02-.196-1.412-.588a1.923 1.923 0 01-.588-1.412c0-.55.196-1.02.588-1.412a1.923 1.923 0 011.412-.588c.55 0 1.021.196 1.413.588.392.391.587.862.587 1.412 0 .55-.195 1.02-.587 1.412a1.927 1.927 0 01-1.413.588z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMoreVertical;
