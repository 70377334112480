import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTw = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tw_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tw_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M-1.333 21.333h30.666V-1.333H-1.333v22.666z" fill="#FE3030" />
        <Path d="M-1.333 10.667h16v-12h-16v12z" fill="#0909B6" />
        <Path
          d="M7.333 7L6.058 8.413l.097-1.901-1.901.097 1.413-1.276-1.413-1.275 1.9.097-.096-1.901 1.275 1.413L8.61 2.254l-.097 1.9 1.9-.096L9 5.333l1.413 1.276-1.901-.097.097 1.9L7.333 7z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgTw;
