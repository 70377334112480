import React, { useCallback } from 'react';

import { StyleSheet, View } from 'react-native';

import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { ListItem } from '@app/ui/atoms/list/ListItem';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { Button } from '@app/ui/molecules/Button';

import { ButtonSelectorProps } from './ButtonSelector.types';

export { ButtonSelectorProps };

const getStyles = () =>
  StyleSheet.create({
    listContainer: { padding: 12 },
  });

export const ButtonSelector: React.FC<ButtonSelectorProps> = ({
  options,
  iconName,
  maxWidth,
  size,
  onPressSelector,
  iconPlacement,
  label,
  maxHeight = 310,
  minWidth = 296,
  onCallbackClose,
  ...rest
}) => {
  const styles = getStyles();

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="filter-list-selector-list-container">
          {options.map(({ onSelect, ...restItem }) => {
            const onPress = () => {
              onSelect?.(closeList);
            };

            return <ListItem {...restItem} onPress={onPress} key={restItem.label.toLowerCase()} />;
          })}
        </View>
      );
    },
    [options, styles.listContainer]
  );

  const renderActivator = useCallback(({ toggleList }: RenderActivatorDisplayType) => {
    const onPress = () => {
      onPressSelector?.();
      toggleList();
    };
    return (
      <Button onPress={onPress} iconName={iconName} iconPlacement={iconPlacement} size={size}>
        {label}
      </Button>
    );
  }, []);

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => {
      return <DropdownListContainer list={getList(closeList)} maxHeight={maxHeightList} />;
    },
    [getList]
  );

  return (
    <DropdownV2
      {...rest}
      renderList={renderList}
      maxHeight={maxHeight}
      minWidth={minWidth}
      maxWidth={maxWidth}
      fitParent={false}
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};
