import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCheckCircle = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10.6 13.8l-2.175-2.175a.89.89 0 00-.687-.262.976.976 0 00-.688.287.948.948 0 00-.275.7c0 .283.092.517.275.7L9.9 15.9a.948.948 0 00.7.275.948.948 0 00.7-.275l5.675-5.675a.894.894 0 00.263-.688.979.979 0 00-.288-.687.948.948 0 00-.7-.275.948.948 0 00-.7.275L10.6 13.8zM12 22a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 012 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0112 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0122 12a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0112 22zm0-2c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCheckCircle;
