import React, { PropsWithChildren, useEffect, useState } from 'react';

import { I18nextProvider } from 'react-i18next';
import { ActivityIndicator } from 'react-native';

import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { initialize, i18n } from '@app/libs/i18n/i18n';
import { initialize as initializeNumbersFormatter } from '@app/services/numbersFormatter/numbersFormatter';

const supportedLngs = [
  I18nSupportedLanguage.fr,
  I18nSupportedLanguage.en,
  I18nSupportedLanguage.es,
];

export const I18nProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [myI18n, setMyI18n] = useState(i18n);

  useEffect(() => {
    async function createInstance() {
      const newInstance = await initialize({
        supportedLngs,
      });
      initializeNumbersFormatter(newInstance.language);
      newInstance.on('languageChanged', language => {
        initializeNumbersFormatter(language);
      });
      setMyI18n(newInstance);
    }
    createInstance();
  }, []);

  if (!myI18n.isInitialized) {
    return <ActivityIndicator />;
  }

  return <I18nextProvider i18n={myI18n}>{children}</I18nextProvider>;
};
