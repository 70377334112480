import React from 'react';

import { FlatList, StyleSheet, View } from 'react-native';

import { Theme, useTheme } from '@app/ui';
import { Timeline, TimelineStepUnion } from '@app/libs/apollo/introspection';

import { useHomePageRenderItem } from './hooks/useHomePageRenderItem';
import { SectionContainer } from './SectionContainer';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    contentContainerStyle: { flexGrow: 1, justifyContent: 'space-between' },
    separator: {
      backgroundColor: theme.palette.neutral[300],
      height: 1,
      width: '90%',
    },
    separatorWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      width: '90%',
    },
  });

type HomePageStepsProps = {
  timeline: Timeline;
};

export const HomePageStepsWeb: React.FC<HomePageStepsProps> = ({ timeline }) => {
  const theme = useTheme();
  const { renderItem } = useHomePageRenderItem();

  const styles = getStyles(theme);
  return (
    <SectionContainer>
      <View style={styles.separatorWrapper}>
        <View style={styles.separator} />
      </View>

      <FlatList<TimelineStepUnion>
        data={timeline.steps}
        renderItem={renderItem}
        keyExtractor={item => item.name.toString()}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </SectionContainer>
  );
};
