import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bn_svg__a)">
        <Path fill="#F7E250" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.167 3.388l36.334 13.224 1.368-3.758L-2.799-.371l-1.368 3.759z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.535 7.146l36.334 13.225 1.368-3.759L-4.167 3.388l-1.368 3.758z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgBn;
