import React, { useMemo } from 'react';

import { View } from 'react-native';

import { Chip, Spacer, StepStatus, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { ProjectDetailStageWithStatusProps } from '@app/features/project/ProjectDetail/ProjectDetail.type';
import {
  detailStageListStyles as styles,
  getDetailStageChipProps,
} from '@app/features/project/ProjectDetail/ProjectDetailStage.styles';

import { ProjectDetailStage } from './ProjectDetailStage';

export const ProjectDetailStageListWithStatus: React.FC<ProjectDetailStageWithStatusProps> = ({
  status,
  stages,
  stageRefs,
  onExpandSubSteps,
  onExpandSubStepsDetails,
  projectCategory,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const chipProps = useMemo(() => getDetailStageChipProps(theme)[status], [status, theme]);

  const stageList = useMemo(
    () =>
      stages.map(stage => (
        <View key={stage.title}>
          <ProjectDetailStage
            onExpandSubSteps={onExpandSubSteps(stage.stageNumber)}
            onExpandSubStepsDetails={onExpandSubStepsDetails(stage.stageNumber)}
            projectCategory={projectCategory}
            stageRefs={stageRefs}
            status={status}
            {...stage}
          />
          <Spacer height={theme.spacing.SIZE_05} />
        </View>
      )),
    [onExpandSubSteps, onExpandSubStepsDetails, projectCategory, stageRefs, stages, status, theme]
  );

  if (!stages.length) {
    return null;
  }

  return (
    <>
      <View style={styles.chip}>
        <Chip {...chipProps} size="medium" text={t(`project.detailSteps.status.${status}`)} />
      </View>
      <Spacer height={theme.spacing.SIZE_05} />
      {stageList}
      {status !== StepStatus.Finished && <Spacer height={theme.spacing.SIZE_09} />}
    </>
  );
};
