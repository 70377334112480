import { StyleSheet } from 'react-native';

import { Spacing } from '@app/ui/theme/Sizes';
import { Theme } from '@app/ui/theme/types';

export const getStyle = ({
  centered,
  isWeb,
  isSmall,
  theme,
  isLowerThanLaptop,
}: {
  centered: boolean;
  isWeb: boolean;
  isSmall: boolean;
  theme: Theme;
  isLowerThanLaptop: boolean;
}) => {
  return StyleSheet.create({
    bold: {
      fontWeight: '600',
    },
    container: {
      flexDirection: isLowerThanLaptop ? 'column' : 'row',
    },
    enlightenmentIcon: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[200],
      borderRadius: isSmall ? Spacing.SIZE_09 : Spacing.SIZE_10,
      height: isSmall ? Spacing.SIZE_09 : Spacing.SIZE_10,
      justifyContent: 'center',
      width: isSmall ? Spacing.SIZE_09 : Spacing.SIZE_10,
    },
    flexRow: {
      flexDirection: 'row',
    },
    flexWrap: {
      flexWrap: 'wrap',
    },
    iconContainer: {
      flexDirection: 'row',
      justifyContent: centered ? 'center' : 'flex-start',
    },
    item: {
      gap: theme.spacing.SIZE_02,
    },
    itemContainer: {
      flex: 1,
      gap: isSmall ? theme.spacing.SIZE_04 : theme.spacing.SIZE_05,
      paddingVertical: theme.spacing.SIZE_06,
    },
    maxContent: {
      width: 'max-content',
    },
    text: {
      flexWrap: 'wrap',
      textAlign: centered ? 'center' : 'auto',
    },
    textContainer: {
      minWidth: '100%',
      width: isWeb ? 'min-content' : 'auto',
    },
  });
};
