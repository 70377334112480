import React from 'react';

import { View } from 'react-native';

import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { useTheme } from '@app/ui/contexts/config';
import { Text } from '@app/ui/atoms/Text/Text';
import { isWeb } from '@app/constants/platform';

import { EnlightenmentProps } from './Enlightenment.types';
import { getStyle } from './Enlightenment.styles';

export { EnlightenmentProps };

const iconOverlap = -12;

export const Enlightenment: React.FC<EnlightenmentProps> = ({ itemList }) => {
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const centered = itemList.length === 1;
  const isSmall = itemList.length > 1;
  const styles = getStyle({ centered, isLowerThanLaptop, isSmall, isWeb: isWeb(), theme });

  return (
    <View style={styles.container}>
      {itemList.map((item, index) => (
        <View
          key={`gap_${index}`}
          style={[
            styles.itemContainer,
            index > 0 ? { borderColor: theme.palette.neutral[200], borderTopWidth: 1 } : {},
          ]}
        >
          <View style={styles.iconContainer}>
            {item.iconList.map((icon, i, arr) => (
              <View
                key={`icon_${i}`}
                style={[
                  styles.enlightenmentIcon,
                  {
                    marginRight: i === arr.length - 1 ? 0 : iconOverlap,
                    zIndex: arr.length - i,
                  },
                ]}
              >
                {icon}
              </View>
            ))}
          </View>
          <View style={styles.item}>
            <Text textStyle={isSmall ? 'Body1Medium' : 'Headline1Medium'} style={styles.text}>
              {item.title}
            </Text>
            <View style={styles.textContainer} testID="enlightenment-text-container">
              <Text style={styles.text} textStyle={isSmall ? 'Body3' : 'Body2'}>
                {item.description}
              </Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};
