import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import {
  TouchableOpacity as TouchableOpacityRNGestureHandler,
  TouchableOpacityProps,
} from 'react-native-gesture-handler';

export const TouchableOpacity: React.FC<TouchableOpacityProps> = props => {
  return (
    <TouchableOpacityRNGestureHandler
      {...props}
      style={[props.style, { cursor: 'pointer' } as StyleProp<ViewStyle>]}
    />
  );
};
