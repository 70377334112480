import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCube = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.25 19.75l-5.5-3.2a1.465 1.465 0 01-.75-1.3V8.9c0-.283.067-.538.2-.763.133-.225.317-.404.55-.537l5.5-3.2c.233-.133.483-.2.75-.2s.517.067.75.2l5.5 3.2c.233.133.417.312.55.537.133.225.2.48.2.763v6.35a1.465 1.465 0 01-.75 1.3l-5.5 3.2c-.233.133-.483.2-.75.2s-.517-.067-.75-.2zm-.25-2.5v-4.6l-4-2.3v4.6l4 2.3zm2 0l4-2.3v-4.6l-4 2.3v4.6zM2 6a.967.967 0 01-.712-.287A.968.968 0 011 5V4c0-.833.292-1.542.875-2.125A2.893 2.893 0 014 1h1a.97.97 0 01.713.287A.97.97 0 016 2c0 .283-.096.52-.287.712A.968.968 0 015 3H4a.967.967 0 00-.712.287A.968.968 0 003 4v1a.97.97 0 01-.287.713A.97.97 0 012 6zm2 17a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 011 20v-1c0-.283.096-.52.288-.712A.965.965 0 012 18c.283 0 .521.096.713.288A.967.967 0 013 19v1c0 .283.096.52.288.712A.965.965 0 004 21h1c.283 0 .521.096.713.288A.967.967 0 016 22c0 .283-.096.52-.287.712A.968.968 0 015 23H4zm15 0a.965.965 0 01-.712-.288A.965.965 0 0118 22c0-.283.096-.52.288-.712A.965.965 0 0119 21h1c.283 0 .52-.096.712-.288A.965.965 0 0021 20v-1c0-.283.096-.52.288-.712A.965.965 0 0122 18c.283 0 .52.096.712.288A.965.965 0 0123 19v1c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0120 23h-1zm3-17a.967.967 0 01-.712-.287A.968.968 0 0121 5V4a.968.968 0 00-.288-.713A.967.967 0 0020 3h-1a.965.965 0 01-.712-.288A.965.965 0 0118 2c0-.283.096-.521.288-.713A.967.967 0 0119 1h1c.833 0 1.542.292 2.125.875S23 3.167 23 4v1a.968.968 0 01-.288.713A.967.967 0 0122 6zm-10 4.9l4-2.3-4-2.3-4 2.3 4 2.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCube;
