import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgPh = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="ph_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#ph_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28V9.333H12.444l.89.667L0 20z" fill="#DD1C34" />
        <Path d="M28 9.333H12.444L0 0h28v9.333z" fill="#0D4BC3" />
        <Path
          d="M2 4a.667.667 0 100-1.333A.667.667 0 002 4zm8 6.667a.667.667 0 100-1.334.667.667 0 000 1.334zm-7.333 6a.667.667 0 11-1.334 0 .667.667 0 011.334 0zm1.865-5.34L3.391 13.08l.433-2.047a1.345 1.345 0 01-.19-.19l-2.047.433 1.753-1.141a1.35 1.35 0 010-.27l-1.753-1.14 2.046.432c.057-.07.121-.133.19-.19L3.392 6.92l1.141 1.753a1.348 1.348 0 01.27 0l1.14-1.753-.432 2.047c.07.057.133.12.19.19l2.046-.433-1.753 1.141a1.351 1.351 0 010 .27l1.753 1.14-2.046-.432c-.057.07-.12.133-.19.19l.432 2.047-1.14-1.753a1.33 1.33 0 01-.27 0z"
          fill="#FDD64D"
        />
      </G>
    </Svg>
  );
};

export default SvgPh;
