import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSe = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="se_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#se_svg__a)">
        <Path fill="#157CBB" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12h8v8h4v-8h16V8H12V0H8v8H0v4z"
          fill="#FFD34D"
        />
      </G>
    </Svg>
  );
};

export default SvgSe;
