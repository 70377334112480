import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Button, Spacer, useTheme, Text } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { CongratsModalProps } from '@app/shared/components/Modals/in-app-signature/InAppSignatureModal.types';

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
  },
});

/**
 * @description Step 3/3 of Sign in process
 */
export const CongratsModal: React.FC<CongratsModalProps> = ({ handleNextStep }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <View style={styles.content}>
        <Spacer height={theme.spacing.SIZE_04} />
        <Text textStyle="Body2">{t('modalMandateSignatureCongrats.text')}</Text>
        <Spacer height={theme.spacing.SIZE_07} />
      </View>
      <Button onPress={handleNextStep}>{t('modalMandateSignatureCongrats.button')}</Button>
    </>
  );
};
