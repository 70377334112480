import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgEt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="et_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#et_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 6.667h28V0H0v6.667z" fill="#20AA46" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#E92F3B" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.333h28V6.667H0v6.666z"
          fill="#FADF50"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z"
          fill="#205CCA"
        />
        <Path
          d="M14.194 11.029L14 10.89l-.194.138-1.148.819.424-1.346.071-.227-.191-.141-1.134-.84 1.41-.012.239-.003.075-.225L14 7.716l.448 1.338.075.225.238.003 1.41.012-1.133.84-.19.141.07.227.424 1.345-1.149-.818z"
          stroke="#FFDB3D"
          strokeWidth={0.667}
        />
      </G>
    </Svg>
  );
};

export default SvgEt;
