import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMv = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mv_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mv_svg__a)">
        <Path fill="#F32249" d="M0 0h28v20H0z" />
        <Rect x={4} y={4} width={20} height={12} rx={0.667} fill="#15A04F" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.333 14.56a4.667 4.667 0 110-9.119 4.668 4.668 0 000 9.118z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgMv;
