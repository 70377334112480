import * as React from 'react';

import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const SvgJe = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <G clipPath="url(#je_svg__clip0_4223_12761)">
        <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.311-2.583l1.529-2.184L14.659 8.846 34.1-4.767l1.53 2.184-18.646 13.056L35.629 23.53 34.1 25.714 14.659 12.1l-19.44 13.613-1.53-2.185 18.645-13.056L-6.31-2.583zm19.904 5.25h2.148l-.088-.052.987-.57-1.276.143.18-.855-.877.713-.877-.713.179.855-1.276-.142.987.57-.087.05z"
          fill="#E3243B"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.667 5.167V4c0-.736.597-1.333 1.333-1.333h1.333c.737 0 1.334.597 1.334 1.333v1.167c0 1.785-2 2.5-2 2.5s-2-.715-2-2.5z"
          fill="#E3243B"
        />
        <Path
          d="M14.333 7A.333.333 0 1115 7a.333.333 0 01-.667 0zM13.333 5.667c0-.184.15-.334.334-.334h2a.333.333 0 010 .667h-2a.333.333 0 01-.334-.333zM13.333 4.333c0-.184.15-.333.334-.333h2a.333.333 0 110 .667h-2a.333.333 0 01-.334-.334z"
          fill="#FAEF32"
        />
      </G>
      <Defs>
        <ClipPath id="je_svg__clip0_4223_12761">
          <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SvgJe;
