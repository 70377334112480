import { TFunction } from 'i18next';

import { ExtendedSearchResultsType } from '../../searchEngine.types';

type GetExtendedResultsListParams<T> = {
  withHigherBudgetList: T[];
  withNearbyList: T[];
  t: TFunction;
};
export const getExtendedResultsList = <T,>({
  withHigherBudgetList,
  withNearbyList,
  t,
}: GetExtendedResultsListParams<T>): {
  list: T[];
  title: string;
  resultsType: ExtendedSearchResultsType;
}[] =>
  [
    {
      list: withHigherBudgetList,
      resultsType: ExtendedSearchResultsType.HigherBudget,
      title: t('searchEngine.moreResults.withMoreBudgetTitle'),
    },
    {
      list: withNearbyList,
      resultsType: ExtendedSearchResultsType.Nearby,
      title: t('searchEngine.moreResults.withNearbyTitle'),
    },
  ].filter(({ list }) => !!list.length);
