import React, { useContext } from 'react';

import { View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Button, Text, Theme, useTheme } from '@app/ui';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';

const getStyles = (theme: Theme) => ({
  gap: {
    gap: theme.spacing.SIZE_08,
  },
});

export const DiscussBeCalledErrorModal: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { closeModal } = useContext(ModalContext);

  const onPress = () => {
    closeModal?.();
  };

  return (
    <View style={styles.gap}>
      <Text>{t('discuss.beCalledErrorModal.description')}</Text>
      <Button onPress={onPress}>{t('shared.close')}</Button>
    </View>
  );
};
