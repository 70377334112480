import { FormatBudgetActivatorValuesType } from './FilterBudgetDropdown.types';

export const currencySymbol = '€';

export const formatActivatorValues: FormatBudgetActivatorValuesType = (placeholder, values) => {
  if (!values || !Object.keys(values).length || (!values.min.value && !values.max.value)) {
    return {
      count: 0,
      valueString: placeholder,
    };
  }

  /* @TODO To be adjusted when we have a real currency management */

  const minValue = values.min.value
    ? new Intl.NumberFormat('fr-FR').format(parseInt(values.min.value))
    : null;
  const maxValue = values.max.value
    ? new Intl.NumberFormat('fr-FR').format(parseInt(values.max.value))
    : null;

  let valuesList = [];
  let allValue = false;

  if (minValue && maxValue) {
    valuesList = [minValue, currencySymbol, '-', maxValue, currencySymbol];
    allValue = true;
  } else if (minValue) {
    valuesList = [values.min.label.substring(0, 3), minValue, currencySymbol];
  } else {
    valuesList = [values.max.label.substring(0, 3), maxValue, currencySymbol];
  }

  return {
    count: allValue ? 2 : 1,
    valueString: valuesList.join(' '),
  };
};
