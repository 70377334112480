import { useEffect, useState } from 'react';

import { isAppointmentJustBooked } from '@app/utils/appointment.utils';
import { GetCustomerQuery } from '@app/libs/apollo/introspection';

import { StateOfCustomer } from './useMe';

interface UseStateOfCustomerProps {
  customer?: GetCustomerQuery['me'];
  isAssetManagementConsultant: boolean;
}

export const useStateOfCustomer = ({
  customer,
  isAssetManagementConsultant,
}: UseStateOfCustomerProps): StateOfCustomer => {
  const [stateOfCustomer, setStateOfCustomer] = useState<StateOfCustomer | null>(null);

  useEffect(() => {
    const updateStateOfCustomer = async () => {
      if (isAssetManagementConsultant) {
        setStateOfCustomer(StateOfCustomer.ASSET_MANAGEMENT_CONSULTANT);
        return;
      }
      if (customer?.searchMandate?.valid) {
        setStateOfCustomer(StateOfCustomer.POST_SIGNED_MANDATE);
        return;
      }
      if (customer?.searchMandate) {
        setStateOfCustomer(StateOfCustomer.PRE_SIGNED_MANDATE);
        return;
      }
      if (customer?.hasOngoingProject) {
        setStateOfCustomer(StateOfCustomer.IN_TRANSACTION);
        return;
      }
      if (customer?.hasNoAppointmentsPassed) {
        const meetingJustBooked = await isAppointmentJustBooked();
        setStateOfCustomer(
          meetingJustBooked || !!customer.nextAppointment
            ? StateOfCustomer.ONGOING_APPOINTMENT
            : StateOfCustomer.PRE_APPOINTMENT
        );
        return;
      }
      setStateOfCustomer(StateOfCustomer.POST_APPOINTMENT);
      return;
    };

    if (customer) {
      updateStateOfCustomer();
    }
  }, [customer, isAssetManagementConsultant]);

  return stateOfCustomer;
};
