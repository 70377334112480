import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { shouldMakeNewAppointment } from '@app/utils/customer.utils';

import { MainMenuNavigatorRoutes } from '../types/routes';

/**
 * For the moment it doesn't seem relevant,
 * but in the batch of suggestions the homepage
 * will change depending on the presence or not of suggestions. I anticipate now.
 */
export const getHomePage = (customer: GetCustomerQuery['me']): MainMenuNavigatorRoutes => {
  let homePage = shouldMakeNewAppointment({
    hasOngoingProject: customer.hasOngoingProject,
    hasValidSearchMandate: customer.searchMandate?.valid || false,
  })
    ? MainMenuNavigatorRoutes.Projects
    : MainMenuNavigatorRoutes.HomePage;

  homePage =
    !!customer.owner && customer.hasSuggestions ? MainMenuNavigatorRoutes.HomePage : homePage;

  return homePage;
};
