import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import ProjectView from '@app/views/project/ProjectView';
import { ProjectListView } from '@app/views/projectList/ProjectListView';
import { ProjectNavigatorRoutes, ProjectsStackParamList } from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import { RealEstateAvailableView } from '@app/views/realEstate/realEstateAvailable/RealEstateAvailableView';
import { RenovationSummaryDetailView } from '@app/views/realEstate/renovationTab/RenovationSummaryDetailView';
import { FurnishmentDetailsView } from '@app/views/realEstate/renovationTab/FurnishmentDetailsView';
import { RealEstateMap } from '@app/views/realEstate/realEstateMap/RealEstateMap';
import { RenovationDetailsView } from '@app/views/realEstate/renovationTab/RenovationDetailsView';

const Stack = createStackNavigator<ProjectsStackParamList>();

export const ProjectNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={ProjectNavigatorRoutes.ProjectList}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={ProjectNavigatorRoutes.Project} component={ProjectView} />
    <Stack.Screen name={ProjectNavigatorRoutes.ProjectList} component={ProjectListView} />
    <Stack.Screen
      navigationKey="project"
      name={ProjectNavigatorRoutes.RealEstate}
      component={RealEstateAvailableView}
    />

    {/* TODO: remove when a solution has been found for the realEstate page navigator */}
    <Stack.Screen name={ProjectNavigatorRoutes.RealEstateMap} component={RealEstateMap} />
    <Stack.Screen
      name={ProjectNavigatorRoutes.RenovationSummaryDetail}
      component={RenovationSummaryDetailView}
    />
    <Stack.Screen
      name={ProjectNavigatorRoutes.FurnishmentDetails}
      component={FurnishmentDetailsView}
    />
    <Stack.Screen
      name={ProjectNavigatorRoutes.RenovationDetails}
      component={RenovationDetailsView}
    />
    {/* END: remove when a solution has been found for the realEstate page navigator */}
  </Stack.Navigator>
);
