import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgArchive = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 013 20V8.7a2.472 2.472 0 01-.712-.7A1.74 1.74 0 012 7V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 014 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v3c0 .383-.096.717-.288 1a2.472 2.472 0 01-.712.7V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0119 22H5zM5 9v11h14V9H5zm15-2V4H4v3h16zm-10 7h4a.968.968 0 00.713-.288A.967.967 0 0015 13a.97.97 0 00-.287-.713A.97.97 0 0014 12h-4a.967.967 0 00-.712.287A.968.968 0 009 13c0 .283.096.52.288.712A.965.965 0 0010 14z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgArchive;
