import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

import { useWindowDimensions, View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { palette } from '@app/ui/theme/palette';
import { useTheme } from '@app/ui/contexts/config';

import { getStyles } from './DumbTooltip.styles';
import { Measures, DumbTooltipProps } from './DumbTooltip.types';

const defaultMeasures: Measures = {
  height: 0,
  innerElementHeight: 0,
  innerElementWidth: 0,
  offsetRight: 0,
  width: 0,
};

export const DumbTooltip: React.FC<PropsWithChildren<DumbTooltipProps>> = ({
  text,
  children,
  show = true,
}) => {
  const snackRef = useRef<View>(null);
  const [measures, setMeasures] = useState<Measures>(defaultMeasures);
  const theme = useTheme();
  const { width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    const newMeasures: Measures = { ...defaultMeasures };
    if (snackRef.current) {
      snackRef?.current?.measure(
        (_x: number, _y: number, width: number, height: number, pageX: number) => {
          newMeasures.innerElementWidth = width;
          newMeasures.innerElementHeight = height;

          const offsetRight = pageX + (100 + width) / 2 - windowWidth;

          if (offsetRight > 0) {
            newMeasures.offsetRight = offsetRight;
          }

          setMeasures(newMeasures);
        }
      );
    }
  }, [snackRef.current, windowWidth]);

  const styles = useMemo(() => getStyles(measures, theme), [theme, measures]);

  return (
    <View style={styles.wrapper} ref={snackRef}>
      {children}

      {show ? (
        <View style={styles.snackBarContainer} testID="snack-bar">
          <View style={styles.snackBarArrow} />
          <View style={styles.snackBarContent}>
            <Text style={{ color: palette.base.white }} textStyle="Caption1">
              {text}
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export { DumbTooltipProps };
