import { Role } from '@app/libs/apollo/introspection';

export const InternalRoles = [
  Role.Admin,
  Role.AssetManagementConsultant,
  Role.AdvisorManager,
  Role.Advisor,
  Role.Animator,
  Role.Dev,
  Role.HunterManager,
  Role.Hunter,
  Role.Mandator,
  Role.RentalBuildingManager,
  Role.AssetManagementConsultant,
];

export const isProAccount = (roles: Role[]): boolean =>
  !!roles.includes(Role.AssetManagementConsultant);

export const isInternalAccount = (roles: Role[]): boolean =>
  !!roles?.find(role => InternalRoles.includes(role));
