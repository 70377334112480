import { StyleSheet } from 'react-native';

import { RequiredThemes } from '@app/ui/theme/types';

export const itemStyles = (componentTheme: RequiredThemes['section']) =>
  StyleSheet.create({
    border: {
      backgroundColor: componentTheme.itemStyles.border.backgroundColor,
      height: 1,
    },
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    icon: {
      marginLeft: 10,
      marginRight: componentTheme.itemStyles.icon.marginRight,
    },
    mainView: {
      marginVertical: componentTheme.itemStyles.mainView.marginVertical,
    },
    titleWrapper: {
      flexGrow: 1,
      marginLeft: 5,
      marginRight: 5,
    },
  });

export const getMainViewStyle = (componentTheme: RequiredThemes['section'], size: 'sm' | 'md') => ({
  marginVertical:
    size === 'sm'
      ? componentTheme.mainViewStyle.marginVerticalSmall
      : componentTheme.mainViewStyle.marginVerticalMedium,
});

export const background = (componentTheme: RequiredThemes['section']) => ({
  active: componentTheme.background.active,
  default: componentTheme.background.default,
});
