import React from 'react';

import { useTranslation } from '@app/services/translations/translations';
import { Button, Spacer, Spacing, Text } from '@app/ui';

interface Props {
  loginCb: () => void;
  subscribeCb: () => void;
}

export const PushToSubscribeModalContent: React.FC<Props> = ({ subscribeCb, loginCb }) => {
  const { t } = useTranslation();

  return (
    <>
      <Spacer height={Spacing.SIZE_08} />
      <Text textStyle="Body1">{t('pushToSubscribeModal.content')}</Text>
      <Spacer height={Spacing.SIZE_08} />
      <Button onPress={subscribeCb}>{t('pushToSubscribeModal.subscribeButton')}</Button>
      <Spacer height={Spacing.SIZE_03} />
      <Button onPress={loginCb} variant="tertiary">
        {t('pushToSubscribeModal.loginButton')}
      </Button>
    </>
  );
};
