import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgSecondoeuvre = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3 21.6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 011 19.6v-7c0-.283.096-.521.288-.713A.967.967 0 012 11.6h20c.283 0 .52.096.712.287.192.192.288.43.288.713v7c0 .55-.196 1.02-.587 1.413A1.928 1.928 0 0121 21.6H3zm0-2h18v-6H3v6zm4-1a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 014 15.6v-8a.97.97 0 01.287-.713A.97.97 0 015 6.6a.97.97 0 01.713.287A.97.97 0 016 7.6v8c0 .283.096.52.287.712.192.192.43.288.713.288a.968.968 0 00.713-.288A.967.967 0 008 15.6v-6c0-.833.292-1.542.875-2.125A2.893 2.893 0 0111 6.6c.833 0 1.542.292 2.125.875S14 8.767 14 9.6v6c0 .283.096.52.288.712A.965.965 0 0015 16.6a.968.968 0 00.713-.288A.967.967 0 0016 15.6v-7c0-.833.292-1.542.875-2.125A2.893 2.893 0 0119 5.6h1.175l-.475-.475c-.2-.2-.3-.438-.3-.713 0-.275.1-.512.3-.712.2-.2.433-.3.7-.3.267 0 .5.1.7.3l2.175 2.175c.2.2.3.438.3.713 0 .275-.1.512-.3.712L21.1 9.475a.878.878 0 01-.675.263 1.057 1.057 0 01-.7-.288.948.948 0 01-.275-.7c0-.283.092-.517.275-.7l.45-.45H19a.967.967 0 00-.712.287A.968.968 0 0018 8.6v7c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0115 18.6a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 0112 15.6v-6a.97.97 0 00-.287-.713A.97.97 0 0011 8.6a.967.967 0 00-.712.287A.968.968 0 0010 9.6v6c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 017 18.6z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgSecondoeuvre;
