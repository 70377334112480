import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSmile = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M15.683 10.722c.433 0 .791-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075-.284-.283-.642-.425-1.075-.425-.434 0-.792.142-1.075.425-.284.283-.425.642-.425 1.075 0 .433.141.792.425 1.075.283.283.641.425 1.075.425zm-7 0c.433 0 .791-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075-.284-.283-.642-.425-1.075-.425-.434 0-.792.142-1.075.425-.284.283-.425.642-.425 1.075 0 .433.141.792.425 1.075.283.283.641.425 1.075.425zm3.5 6.5c1.033 0 1.958-.25 2.775-.75a5.671 5.671 0 001.95-1.975c.116-.2.125-.38.025-.537-.1-.159-.267-.238-.5-.238h-8.5c-.234 0-.4.08-.5.238-.1.158-.092.337.025.537a5.672 5.672 0 001.95 1.975c.816.5 1.741.75 2.775.75zm0 4.5a9.731 9.731 0 01-3.9-.788 10.09 10.09 0 01-3.175-2.137c-.9-.9-1.613-1.958-2.137-3.175a9.732 9.732 0 01-.788-3.9c0-1.383.262-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138a9.743 9.743 0 013.9-.787c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175a9.731 9.731 0 01.788 3.9 9.73 9.73 0 01-.788 3.9 10.09 10.09 0 01-2.137 3.175c-.9.9-1.959 1.612-3.175 2.137a9.731 9.731 0 01-3.9.788zm0-2c2.216 0 4.104-.78 5.663-2.337 1.558-1.559 2.337-3.446 2.337-5.663s-.78-4.104-2.337-5.663c-1.559-1.558-3.447-2.337-5.663-2.337-2.217 0-4.104.779-5.662 2.337-1.559 1.559-2.338 3.446-2.338 5.663s.779 4.104 2.338 5.663c1.558 1.558 3.445 2.337 5.662 2.337z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSmile;
