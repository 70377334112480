import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { identify } from '@app/libs/segment/segment';
import { isInternalAccount, isProAccount } from '@app/utils/computedUsers';

export const trackIdentify = (
  customer: GetCustomerQuery['me'] & { account: { utmUrlConv?: string } }
): void => {
  identify(customer.id, {
    advisorEmail: customer.owner?.email,
    countryCode: customer.preferences.countryCode,
    email: customer.email,
    financingLetterStatus: customer.financingLetterStatus,
    firstName: customer.firstName,
    hasASearchMandate: customer.searchMandate?.valid ?? false,
    hasAnActiveTransaction: customer.hasOngoingProject,
    id: customer.id,
    isInternalAccount: isInternalAccount(customer.account.roles ?? []),
    isProAccount: isProAccount(customer.account.roles ?? []),
    language: customer.lang,
    lastName: customer.lastName,
    maxBudget: customer.preferences.budgetMax,
    phoneNumber: customer.phoneNumber,
    searchRegions: customer.preferences.regions,
    userEmail: customer.email,
    utm_campaign: customer.account.utmCampaign,
    utm_content: customer.account.utmContent,
    utm_medium: customer.account.utmMedium,
    utm_source: customer.account.utmSource,
    utm_term: customer.account.utmTerm,
    utm_urlconv: customer.account.utmUrlConv,
  });
};

export const trackSimpleIdentify = (userId: string, userEmail: string): void => {
  identify(userId, {
    email: userEmail,
    id: userId,
    userEmail,
  });
};
