import { TFunction } from 'react-i18next';
import * as localeList from 'date-fns/locale';

import { PositioningStatus } from '@app/libs/apollo/introspection';
import { ChipProps } from '@app/ui';

import { getExpirationDateString } from './getExpirationDateString.utils';

export const getRealEstateTag = ({
  isNew,
  // shortTermNightlyRent,
  isExclusive,
  positioningStatus,
  isInTransaction,
  expirationDate,
  translate,
  language,
  shortVersion,
}: {
  isNew?: boolean;
  shortTermNightlyRent?: number;
  isExclusive?: boolean;
  positioningStatus?: PositioningStatus;
  isInTransaction?: boolean;
  expirationDate?: Date;
  translate: TFunction;
  language: string;
  shortVersion?: boolean;
}): ChipProps | null => {
  if (positioningStatus === PositioningStatus.Pending) {
    return {
      iconName: 'Handshake',
      text: translate('realEstate.tag.sharedWithAdvisor'),
      tint: 'white',
    };
  }

  if (positioningStatus === PositioningStatus.Confirmed) {
    return {
      iconName: 'CheckCircle',
      text: translate('realEstate.tag.confirmedPositioning'),
      tint: 'white',
    };
  }

  if (positioningStatus === PositioningStatus.Cancelled) {
    return {
      iconName: 'Cross',
      text: translate('realEstate.tag.cancelledPositioning'),
      tint: 'white',
    };
  }

  if (positioningStatus === PositioningStatus.PrePositioningCancelled) {
    return {
      iconName: 'Cross',
      text: translate('realEstate.tag.cancelledPrePositioning'),
      tint: 'white',
    };
  }

  if (expirationDate) {
    const locale = localeList[language];

    const expirationDateText = getExpirationDateString({
      expirationDate,
      full: !shortVersion,
      locale,
      translate,
    });

    return expirationDateText ? { iconName: 'Clock', text: expirationDateText, tint: 'red' } : null;
  }

  if (isNew) {
    return { iconName: 'New', text: translate('realEstate.tag.isNew'), tint: 'green' };
  }

  if (isInTransaction) {
    return {
      iconName: 'Key',
      text: translate('realEstate.tag.inTransaction'),
      tint: 'white',
    };
  }

  // TODO: Feature removed for now #removeShortTermRental
  // if (shortTermNightlyRent) {
  //   return {
  //     iconName: 'Key',
  //     text: shortVersion
  //       ? translate('propertyPreview.shortTermRental')
  //       : translate('realEstate.tag.shortTermRental'),
  //     tint: 'purple',
  //   };
  // }

  if (isExclusive) {
    return {
      iconName: 'Masteos',
      text: translate('realEstate.tag.isExclusive'),
      tint: 'orange',
    };
  }

  return null;
};
