import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSend = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3 18.5v-13c0-.367.15-.646.45-.838a.99.99 0 01.95-.087l15.4 6.5c.417.183.625.492.625.925 0 .433-.208.742-.625.925l-15.4 6.5a.99.99 0 01-.95-.088c-.3-.191-.45-.47-.45-.837zM5 17l11.85-5L5 7v3.5l6 1.5-6 1.5V17zM5 7v10V7z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSend;
