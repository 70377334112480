import React from 'react';

import { SvgProps, NumberProp } from 'react-native-svg';
import { StyleProp, View, ViewStyle, StyleSheet, ColorValue } from 'react-native';

export { IconName } from './types';
import { omit } from 'lodash';

import { useTheme } from '@app/ui/contexts/config';
import { Badge } from '@app/ui/atoms/Badge/Badge';

import { IconName } from './types';
import * as Ic from './elements';
import { defaultIconColor } from './style';

export interface IconProps extends SvgProps {
  /**
   * Name of the Icon you want to show
   * @type {IconName}
   * @memberof IconProps
   */
  name: IconName;
  /**
   * Color of the Icon
   * @type {ColorValue}
   * @memberof SvgProps
   */
  color?: ColorValue;
  /**
   * Size of the Icon
   * @type {number}
   * @memberof IconProps
   */
  size?: NumberProp;
  /**
   * Style of the icon
   * @type {StyleProp<ViewStyle>}
   * @memberof SvgProps
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Tells if add or not new tag
   * @type {boolean}
   * @memberof IconProps
   */
  isNew?: boolean;
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  iconWrapper: {
    position: 'relative',
  },
});

export const Icon: React.FC<IconProps> = ({
  name,
  color,
  size = 24,
  isNew = false,
  ...svgProps
}) => {
  const theme = useTheme();
  const MyIcon = Ic[name];
  const filteredSvgProps = omit(svgProps, 'iconPlacement');

  if (!MyIcon) {
    return null;
  }

  return isNew ? (
    <View style={styles.iconWrapper}>
      <MyIcon
        color={color || defaultIconColor(theme)}
        width={size}
        height={size}
        {...filteredSvgProps}
      />
      <Badge style={styles.badge} />
    </View>
  ) : (
    <MyIcon
      color={color || defaultIconColor(theme)}
      width={size}
      height={size}
      {...filteredSvgProps}
    />
  );
};
