import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { Theme } from '@app/ui/theme/types';

type GetStyleType = {
  theme: Theme;
  isLowerThanTablet: boolean;
  actionWidth: number;
  isMobile: boolean;
};

export const getStyles = ({
  theme,
  isLowerThanTablet,
  actionWidth,
  isMobile,
}: GetStyleType): Record<string, StyleProp<ViewStyle | TextStyle>> =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.palette.neutral[200],
      borderStyle: 'solid',
      flexDirection: 'row',
      height: isLowerThanTablet ? 56 : 72,
      justifyContent: 'space-between',
      paddingHorizontal: isLowerThanTablet ? 12 : 20,
      width: '100%',
    },
    leftArrow: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: isLowerThanTablet ? 12 : 20,
      width: isMobile ? 'auto' : actionWidth,
    },
    rightZone: {
      alignItems: 'flex-end',
      marginLeft: isLowerThanTablet ? 12 : 20,
      paddingLeft: isLowerThanTablet ? 12 : 20,
      width: actionWidth,
    },

    titleText: {
      flex: 1,
      flexGrow: 1,
      textAlign: 'center',
    },
  });
