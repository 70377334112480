import { StyleSheet } from 'react-native';

import { Spacing } from '@app/ui/theme/Sizes';

export const getHeaderPageStyles = (isMobile: boolean) => {
  return StyleSheet.create({
    bottomWrapper: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      marginTop: Spacing.SIZE_02,
    },
    icon: { width: 50 },
    iconWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginRight: Spacing.SIZE_04,
    },
    root: {
      justifyContent: 'center',
      marginBottom: isMobile ? Spacing.SIZE_04 : Spacing.SIZE_06,
      marginTop: isMobile ? Spacing.SIZE_04 : Spacing.SIZE_09,
      minHeight: 40,
      width: '100%',
    },
    subtitle: {
      alignSelf: 'center',
      marginLeft: Spacing.SIZE_04,
    },
    titleAndSubtitle: {
      alignItems: 'center',
      alignSelf: 'stretch',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    topWrapper: { flexDirection: 'row' },
    wrapper: { flexDirection: 'row' },
  });
};
