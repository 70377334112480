import { DetailStepRecord, ProjectCategory } from '@app/features/project/Project.types';

export const rentingDetailSteps: DetailStepRecord[ProjectCategory.Renting] = {
  'fr-FR': [
    {
      content: 'project.renting.detailSteps.1.content',
      stageNumber: 1,
      subSteps: [
        {
          content: 'project.renting.detailSteps.1.subSteps.1.content',
          title: 'project.renting.detailSteps.1.subSteps.1.title',
        },
        {
          content: 'project.renting.detailSteps.1.subSteps.2.content',
          title: 'project.renting.detailSteps.1.subSteps.2.title',
        },
      ],
      title: 'project.renting.detailSteps.1.title',
    },
    {
      content: 'project.renting.detailSteps.2.content',
      stageNumber: 2,
      subSteps: [
        {
          content: 'project.renting.detailSteps.2.subSteps.1.content',
          title: 'project.renting.detailSteps.2.subSteps.1.title',
        },
        {
          content: 'project.renting.detailSteps.2.subSteps.2.content',
          title: 'project.renting.detailSteps.2.subSteps.2.title',
        },
        {
          content: 'project.renting.detailSteps.2.subSteps.3.content',
          title: 'project.renting.detailSteps.2.subSteps.3.title',
        },
        {
          content: 'project.renting.detailSteps.2.subSteps.4.content',
          title: 'project.renting.detailSteps.2.subSteps.4.title',
        },
      ],
      title: 'project.renting.detailSteps.2.title',
    },
    {
      content: 'project.renting.detailSteps.3.content',
      stageNumber: 3,
      title: 'project.renting.detailSteps.3.title',
    },
  ],
};
