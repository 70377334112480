export const getStickyHeaderIndices = ({
  isNative,
  notificationStatus,
  isLowerThanLaptop,
  isGreaterThanTablet,
}: {
  isNative: boolean;
  isGreaterThanTablet: boolean;
  notificationStatus: string;
  isLowerThanLaptop: boolean;
}) => {
  if (isNative && notificationStatus === 'blocked') {
    return [1];
  }
  if (isNative && notificationStatus === 'denied') {
    return [1];
  }
  if (isGreaterThanTablet) {
    return [];
  }
  if (isLowerThanLaptop && !isNative) {
    return [1];
  }

  return [0];
};
