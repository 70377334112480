import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChevronRight = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M8.336 17.704A.96.96 0 018 16.951a.96.96 0 01.336-.754L13.099 12 8.336 7.803A.96.96 0 018 7.049a.96.96 0 01.336-.753C8.56 6.099 8.845 6 9.19 6s.63.099.855.296l5.618 4.95a.949.949 0 01.26.35c.05.126.076.26.076.404 0 .143-.025.278-.076.404a.949.949 0 01-.26.35l-5.618 4.95c-.224.197-.51.296-.855.296-.346 0-.631-.099-.855-.296z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChevronRight;
