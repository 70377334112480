import { SearchEngineType } from '@app/features/search-engine/searchEngine.types';
import { AvailableCountryCode, useGetZonesQuery } from '@app/libs/apollo/introspection';
import { getAPILang } from '@app/libs/i18n/helper';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { useTranslation } from '@app/services/translations/translations';

interface UseZonesSelect {
  zoneListByCountry: Record<
    AvailableCountryCode,
    {
      regionCode: string;
      zones: {
        id: string;
        name: string;
      }[];
    }[]
  >;
}

export const useZonesSelect = (): UseZonesSelect => {
  const { i18n } = useTranslation();
  const apiLang = getAPILang(i18n.resolvedLanguage as I18nSupportedLanguage);
  const { data: getZonesData } = useGetZonesQuery({ variables: { lang: apiLang } });

  const zoneListByCountry = (getZonesData?.getZones.locations ?? []).reduce(
    (acc, loc) => {
      acc[loc.countryCode] = loc.regions;
      return acc;
    },
    { ES: [], FR: [] } as SearchEngineType['zoneLocationListByCountry']
  );

  return { zoneListByCountry };
};
