import React from 'react';

import { View } from 'react-native';
import { Slider } from '@miblanchard/react-native-slider';

import { Text } from '@app/ui/atoms/Text/Text';
import { Input } from '@app/ui/atoms/input/Input';
import { useTheme } from '@app/ui/contexts/config';

import { SliderInputProps } from './SliderInput.types';
import { getStyles } from './SliderInput.styles';
import { isArrayValue } from './SliderInput.utils';

export const SliderInput = <T extends number | number[]>({
  min = 0,
  max = 1,
  value,
  setSliderValue,
  step = 10,
  onSlidingComplete,
  disabled = false,
  label = '',
  formatValueText,
  inputLabelLeft,
  inputLabelRight,
  showValue,
  showInput,
  testID,
}: SliderInputProps<T>): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme, disabled);
  const isRange = isArrayValue(value);

  const renderThumbComponent = () => {
    return <View testID={testID} style={styles.renderThumbComponent} />;
  };

  const handleSetValue = (valueToSet: number[]) => {
    if (isArrayValue(value)) {
      setSliderValue(valueToSet as T);
    } else {
      setSliderValue(valueToSet[0] as T);
    }
  };

  const handleOnSlidingComplete = () => {
    onSlidingComplete && value && onSlidingComplete(value);
  };

  const [leftValue, rightValue] = isArrayValue(value) ? value : [value, value];

  const leftValueFormatted = formatValueText?.(leftValue as number) ?? '';
  const rightValueFormatted = formatValueText?.(rightValue as number) ?? '';

  return (
    <View style={styles.container}>
      {(!!label || !!showValue) && (
        <View style={styles.labelContainer}>
          <Text style={styles.label} textStyle="Body2">
            {label}
          </Text>
          <Text style={styles.label} textStyle="Body2Medium">
            {isRange ? `${leftValueFormatted} - ${rightValueFormatted}` : leftValueFormatted}
          </Text>
        </View>
      )}
      <Slider
        maximumValue={max}
        minimumValue={min}
        step={step}
        value={value}
        maximumTrackTintColor={theme.palette.neutral[200]}
        minimumTrackTintColor={theme.palette.neutral[900]}
        containerStyle={styles.container}
        onValueChange={handleSetValue}
        trackStyle={{ height: 2 }}
        onSlidingComplete={handleOnSlidingComplete}
        animateTransitions
        renderThumbComponent={renderThumbComponent}
      />
      {showInput ? (
        <View style={styles.inputsContainer}>
          <View>
            <Input
              style={styles.inputStyle}
              label={inputLabelLeft}
              value={leftValueFormatted}
              editable={!disabled}
            />
          </View>
          {isRange ? (
            <View>
              <Input
                editable={!disabled}
                style={styles.inputStyle}
                label={inputLabelRight}
                value={rightValueFormatted}
              />
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

export { SliderInputProps };
