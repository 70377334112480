import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';
import { useTheme } from '@app/ui/contexts/config';

import { getDataBlockStyle } from './DataBlock.styles';
import { DataBlockProps } from './DataBlock.types';

export const DataBlock: React.FC<DataBlockProps> = ({
  variant = 'neutral',
  icon,
  title,
  testID,
  data,
  wrapperStyle,
  style,
  hint,
  width,
  valueStyle,
}) => {
  const theme = useTheme();
  const styles = getDataBlockStyle({
    theme,
    variant,
  });

  return (
    <View
      style={StyleSheet.flatten([styles.container, style, width ? { width } : {}])}
      testID={testID}
    >
      <View style={styles.hintPosition}>{hint}</View>
      <View style={StyleSheet.flatten([styles.main, wrapperStyle])}>
        {!!icon?.length && (
          <Icon
            size={theme.spacing.SIZE_07}
            color={theme.palette.neutral[variant === 'inverted' ? 100 : 900]}
            name={icon}
          />
        )}

        <Text textStyle="Headline2Medium" style={[styles.title, valueStyle]}>
          {data}
        </Text>
        <Text textStyle="Body3" style={styles.content}>
          {title}
        </Text>
      </View>
    </View>
  );
};

export { DataBlockProps };
