import React, { useCallback } from 'react';

import { View } from 'react-native';

import { ListItem } from '@app/ui/atoms/list/ListItem';
import { ActionButton } from '@app/ui/molecules/ActionButton';
import { Text } from '@app/ui/atoms/Text/Text';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';

import { getStyles } from './FilterSortSelector.styles';
import { SimpleTextSelectorProps } from '../simple-text-selector/SimpleTextSelector.types';

export type FilterSortSelectorProps = Pick<
  SimpleTextSelectorProps,
  'options' | 'value' | 'onSelectValue'
> & {
  label: string;
};

export const FilterSortSelector: React.FC<FilterSortSelectorProps> = ({
  options,
  value,
  onSelectValue,
  label,
}) => {
  const { isGreaterThanLaptop, isLowerThanTablet } = useResponsive();

  const styles = getStyles({ isLowerThanTablet });

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="filter-sort-selector-list">
          {options.map(item => {
            const onSelect = () => {
              onSelectValue(item, closeList);
            };

            return (
              <ListItem
                {...item}
                key={item.key || item.label.toLowerCase()}
                onPress={onSelect}
                checked={item.selected}
              />
            );
          })}
        </View>
      );
    },
    [onSelectValue, options, styles.listContainer]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight }: ListType) => {
      const header = isLowerThanTablet ? (
        <View style={styles.listHeader} testID="filter-sort-selector-header">
          <Text textStyle="Title3Medium">{label}</Text>
        </View>
      ) : undefined;

      return (
        <DropdownListContainer header={header} list={getList(closeList)} maxHeight={maxHeight} />
      );
    },
    [getList, isLowerThanTablet, label, styles.listHeader]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return (
        <View collapsable={false}>
          <ActionButton
            withSolidBorder={true}
            onPress={toggleList}
            rightIconName={isOpen ? 'ChevronUp' : 'ChevronDown'}
            label={isGreaterThanLaptop ? `${label} : ${value}` : value}
            testID="filter-sort-selector-activator"
          />
        </View>
      );
    },
    [value, isGreaterThanLaptop, label]
  );

  return (
    <DropdownV2
      renderList={renderList}
      renderActivator={renderActivator}
      fitParent={false}
      minWidth={296}
      testID="filter-sort-selector-dropwdown"
    />
  );
};
