import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const markerHeight = 50;
export const markerWidth = 50;

export const getLocationMarkerStyle = (theme: Theme) =>
  StyleSheet.create({
    homeIcon: {
      left: 15,
      position: 'absolute',
      top: 12,
      zIndex: 10,
    },
    marker: {
      backgroundColor: theme.palette.neutral[900],
      height: 20,
      left: 20,
      position: 'absolute',
      top: 15,
      width: 10,
    },
  });

export const getLocationMarkerIconWithZoomStyle = () =>
  StyleSheet.create({
    zoomWrapper: {
      ...Platform.select({
        web: {
          transform: [{ translateX: -markerWidth / 2 }, { translateY: -markerHeight }],
        },
      }),
    },
  });
