import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCalendar = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.66 13.742a.965.965 0 01-.711-.289.965.965 0 01-.288-.711c0-.284.096-.522.288-.714a.967.967 0 01.712-.287.97.97 0 01.713.287.97.97 0 01.287.713c0 .284-.096.521-.287.713a.968.968 0 01-.713.287zm-4 0a.968.968 0 01-.712-.289.967.967 0 01-.287-.711.97.97 0 01.287-.714.97.97 0 01.713-.287.97.97 0 01.713.287.97.97 0 01.287.713c0 .284-.096.521-.287.713a.968.968 0 01-.713.287zm8 0a.965.965 0 01-.711-.289.965.965 0 01-.288-.711c0-.284.096-.522.288-.714a.967.967 0 01.712-.287c.283 0 .52.096.712.287.192.192.288.43.288.713 0 .284-.096.521-.288.713a.965.965 0 01-.712.287zm-4 4a.965.965 0 01-.711-.289.965.965 0 01-.288-.712c0-.283.096-.52.288-.712a.965.965 0 01.712-.287c.283 0 .52.095.713.287a.967.967 0 01.287.712c0 .284-.096.521-.287.712a.968.968 0 01-.713.288zm-4 0a.968.968 0 01-.712-.289.967.967 0 01-.287-.712c0-.283.096-.52.287-.712a.968.968 0 01.713-.287c.283 0 .52.095.713.287a.967.967 0 01.287.712c0 .284-.096.521-.287.712a.968.968 0 01-.713.288zm8 0a.965.965 0 01-.711-.289.965.965 0 01-.288-.712c0-.283.096-.52.288-.712a.965.965 0 01.712-.287c.283 0 .52.095.712.287a.965.965 0 01.288.712c0 .284-.096.521-.288.712a.965.965 0 01-.712.288zm-11 4c-.55 0-1.02-.196-1.412-.588a1.928 1.928 0 01-.587-1.413v-14c0-.55.196-1.02.587-1.412a1.927 1.927 0 011.413-.588h1v-1a.97.97 0 01.287-.713.97.97 0 01.713-.287.97.97 0 01.713.287.97.97 0 01.287.713v1h8v-1c0-.283.096-.52.288-.713a.967.967 0 01.712-.287c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.55 0 1.02.196 1.413.588.391.392.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 01-1.413.587h-14zm0-2h14v-10h-14v10zm0-12h14v-2h-14v2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCalendar;
