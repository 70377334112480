import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgDm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="dm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#dm_svg__a)" clipRule="evenodd">
        <Path fillRule="evenodd" d="M0 8h28V0H0v8zM0 20h28v-8H0v8z" fill="#108753" />
        <Path fillRule="evenodd" d="M16 20h-4v-8H0V8h12V0h4v8h12v4H16v8z" fill="#fff" />
        <Path
          fillRule="evenodd"
          d="M14.667 20h-1.334v-9.333H0V9.333h13.333V0h1.334v9.333H28v1.334H14.667V20z"
          fill="#262626"
        />
        <Path
          fillRule="evenodd"
          d="M13.333 20H12V9.333H0V8h12V0h1.333v8H28v1.333H13.333V20z"
          fill="#FCD449"
        />
        <Path
          fillRule="evenodd"
          d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z"
          fill="url(#dm_svg__paint0_linear_4223_10691)"
        />
        <Path
          d="M14 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666z"
          stroke="#0E673F"
          strokeWidth={1.333}
          strokeLinecap="round"
          strokeDasharray="0.67 2.67"
        />
        <Path
          fillRule="evenodd"
          d="M14 11.333c.736 0 .667-.597.667-1.333s.07-1.333-.667-1.333c-.736 0-.667.597-.667 1.333s-.07 1.333.667 1.333z"
          fill="#0E673F"
        />
      </G>
      <Defs>
        <LinearGradient
          id="dm_svg__paint0_linear_4223_10691"
          x1={9.333}
          y1={5.333}
          x2={9.333}
          y2={14.667}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E02C42" />
          <Stop offset={1} stopColor="#D22036" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgDm;
