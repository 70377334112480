import React, { useEffect } from 'react';

import { ScrollView, StyleSheet, View } from 'react-native';
import { ParamListBase, useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { ActionButton, Button, Theme, useResponsive, useTheme } from '@app/ui';
import { isNative, isWeb } from '@app/constants/platform';
import { BOTTOM_BAR_MENU_HEIGHT } from '@app/ui/organisms/BottomBarMenu/bottomBarMenu.theme';
import { useTranslation } from '@app/services/translations/translations';
import { SoldRealEstateV2 } from '@app/libs/apollo/introspection';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { PublicNavigatorRoutes, RealEstateNavigatorRoutes } from '@app/navigation/types/routes';
import {
  TakeAppointmentSource,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import { currentSearchEngineScrollPosition } from '@app/features/search-engine/utils/scrollTo.utils';
import { navigationPushFromRef } from '@app/navigation/navigationRef';
import { useMe } from '@app/shared/hooks/useMe';

import { RealEstateSoldHeader } from './realEstateSoldHeader/RealEstateSoldHeader';
import { Synthesis } from './components/Synthesis';
import { SectionContainer } from './components/SectionContainer';
import { FinancialStrategy } from './components/FinancialStrategy';
import { BeforeRenovation } from './components/beforeRenovation/BeforeRenovation';
import { AfterRenovation } from './components/afterRenovation/AfterRenovation';

type RealEstateSoldProps = {
  realEstate: SoldRealEstateV2;
  currentScrollPosition?: number;
};

const getStyles = ({
  theme,
  isLowerThanTablet,
  isLowerThanWide,
  isTablet,
}: {
  theme: Theme;
  isTablet: boolean;
  isLowerThanTablet: boolean;
  isLowerThanWide: boolean;
}) =>
  StyleSheet.create({
    actionButtonContainer: {
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_04,
    },
    backButton: {
      left: isLowerThanWide ? 16 : 40,
      position: 'absolute',
      top: 8,
      zIndex: 2,
    },
    container: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
      paddingBottom: !isNative() && (isTablet || isLowerThanTablet) ? BOTTOM_BAR_MENU_HEIGHT : 0,
    },
    headerContainer: {
      alignItems: 'center',
    },
  });

type RealEstateSoldNavigationProp = StackNavigationProp<
  ParamListBase,
  RealEstateNavigatorRoutes.RealEstate
>;

export const RealEstateSold: React.FC<RealEstateSoldProps> = ({
  realEstate,
  currentScrollPosition,
}) => {
  const theme = useTheme();
  const { customer } = useMe();
  const navigation = useNavigation<RealEstateSoldNavigationProp>();
  const { isLowerThanDesktop, isTablet, isLowerThanTablet, isLowerThanLaptop, isLowerThanWide } =
    useResponsive();
  const styles = getStyles({ isLowerThanTablet, isLowerThanWide, isTablet, theme });
  const { t } = useTranslation();

  const isFocused = useIsFocused();

  const id = realEstate?.id;

  const handleGoToAdvisorAppointment = () => {
    trackTakeAdvisorAppointmentClick({
      propertyId: realEstate.id,
      sourcePage: TakeAppointmentSource.soldPropertyPage,
    });
    goToAdvisorAppointment();
  };

  useEffect(() => {
    if (isFocused && id) {
      if (isWeb()) {
        trackChangePage(RealEstateNavigatorRoutes.RealEstateSold, { soldRealEstate: { id } });
      } else {
        trackChangeScreen(RealEstateNavigatorRoutes.RealEstateSold, { soldRealEstate: { id } });
      }
    }
  }, [id, isFocused]);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      isWeb() && currentSearchEngineScrollPosition(currentScrollPosition);
      navigation.goBack();
    } else if (customer?.id) {
      navigationPushFromRef(navigation.getState().routeNames[0]);
    } else {
      navigation.navigate(PublicNavigatorRoutes.Auth);
    }
  };

  const goToAdvisorAppointment = () => {
    trackTakeAdvisorAppointmentClick({
      propertyId: realEstate.id,
      sourcePage: TakeAppointmentSource.soldPropertyPage,
    });
    navigation.navigate(RealEstateNavigatorRoutes.AdvisorAppointment);
  };

  return (
    <View style={styles.container}>
      {!isLowerThanLaptop && (
        <ActionButton
          leftIconName="ChevronLeft"
          size="m"
          onPress={handleGoBack}
          testID="go-back-action-btn"
          withSolidBorder
          style={styles.backButton}
        />
      )}

      <ScrollView testID="real-estate-sold-view">
        <View style={styles.headerContainer}>
          <RealEstateSoldHeader realEstate={realEstate} onBack={handleGoBack} />
        </View>
        <SectionContainer>
          <Synthesis
            financialStrategy={realEstate.afterRenovation.financialStrategy}
            surface={realEstate.surface}
            goToAdvisorAppointment={handleGoToAdvisorAppointment}
          />
        </SectionContainer>
        <SectionContainer backgroundColor={theme.palette.brand[1]}>
          <BeforeRenovation
            businesses={realEstate.building.pointsOfInterest.businesses}
            transport={realEstate.building.pointsOfInterest.transport}
            education={realEstate.building.pointsOfInterest.education}
            places={realEstate.building.pointsOfInterest.places}
            images={realEstate.beforeRenovation.images}
            description={realEstate.beforeRenovation.description}
            dpe={realEstate.beforeRenovation.dpe}
          />
        </SectionContainer>
        <SectionContainer backgroundColor={theme.palette.brand[2]}>
          <AfterRenovation
            description={realEstate.afterRenovation.description}
            images={realEstate.afterRenovation.images}
            dpe={realEstate.afterRenovation.dpe}
            esthetics={realEstate.afterRenovation.esthetics}
            layout={realEstate.afterRenovation.layout}
            performances={realEstate.afterRenovation.performances}
          />
        </SectionContainer>
        <SectionContainer>
          <FinancialStrategy
            financialStrategy={realEstate.afterRenovation.financialStrategy}
            goToAdvisorAppointment={handleGoToAdvisorAppointment}
          />
        </SectionContainer>
      </ScrollView>

      {!!isLowerThanDesktop && (
        <View style={styles.actionButtonContainer}>
          <Button
            testID="button-contact-our-team"
            onPress={handleGoToAdvisorAppointment}
            iconName="Phone"
            iconPlacement="left"
          >
            {t('shared.contactOurTeam')}
          </Button>
        </View>
      )}
    </View>
  );
};
