import { QueryResult } from '@apollo/client';

import { GetMyProjectsQuery, useGetMyProjectsQuery } from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';

export const useMyProjectsList = (): Partial<QueryResult<GetMyProjectsQuery>> => {
  const { customer } = useMe();

  const { loading, data } = useGetMyProjectsQuery({
    fetchPolicy: 'no-cache',
    skip: !customer?.id,
  });

  return { data, loading };
};
