import React, { FC, useState, useEffect, useMemo, createRef } from 'react';

import {
  View,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  TextInput,
} from 'react-native';

import { Input } from '@app/ui/atoms/input/Input';
import { getStyles as getInputStyles } from '@app/ui/atoms/input/Input.styles';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { inputTheme } from '@app/ui/atoms/input/input.theme';
import { Text } from '@app/ui/atoms/Text/Text';
import { useTheme } from '@app/ui/contexts/config';

import { getStyles } from './ResetCode.styles';

export interface ResetCodeProps {
  style?: ViewStyle;
  count: number;
  label?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

export const ResetCode: FC<ResetCodeProps> = ({
  style,
  count,
  label,
  autoFocus = false,
  onChange,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const componentTheme = useComponentTheme('input', inputTheme);

  const inputStyle = getInputStyles({
    componentTheme,
    disabled: false,
    error: false,
    focused: false,
    hovered: false,
    isSearch: false,
    isValid: false,
    prefix: false,
    prefixWidth: 0,
    suffix: false,
    suffixWidth: 0,
  });

  const [values, setValues] = useState<string[]>(Array(count).fill(''));
  const refs = useMemo(
    () => Array.from({ length: count }).map(() => createRef<TextInput>()),
    [count]
  );

  useEffect(() => {
    if (autoFocus) {
      refs[0].current?.focus();
    }
  }, [refs, autoFocus]);

  const onKeyPress = (index: number, e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === 'Backspace' && index > 0) {
      if (values[index] === '') {
        refs[index - 1].current?.focus();
      }
    }
  };

  const onChangeTextHandler = (index: number, value: string) => {
    const newValues = [...values];
    newValues.splice(index, 1, value);
    setValues(newValues);

    if (!value) {
      return;
    }

    if (onChange) {
      onChange(newValues.join(''));
    }

    if (index < count - 1) {
      refs[index + 1].current?.focus();
    }
  };

  return (
    <View>
      {!!label && (
        <Text testID="label-test-id" style={inputStyle.label}>
          {label}
        </Text>
      )}

      <View style={[styles.textContainer, style]} testID="test-input-container-id">
        {values.map((value, index) => (
          <View key={index} style={styles.inputContainer}>
            <Input
              ref={refs[index]}
              style={[
                styles.input,
                index === 0 && styles.leftMargin,
                index === count - 1 && styles.rightMargin,
              ]}
              maxLength={1}
              keyboardType="number-pad"
              value={value}
              onChangeText={txt => onChangeTextHandler(index, txt)}
              onKeyPress={e => onKeyPress(index, e)}
              testID={`test-input-id-${index}`}
            />
          </View>
        ))}
      </View>
    </View>
  );
};
