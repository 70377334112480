import React, { ReactElement } from 'react';

import { StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';

import { Button, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { RenovationDetail } from '@app/libs/apollo/introspection';
import { isWeb } from '@app/constants/platform';
import { RenovationDetailsTemplate } from '@app/features/realEstate/realEstateAvailable/realEstateRenovation/components/renovationDetails/RenovationDetailsTemplate';

import { TablePreview } from '../TablePreview';
import { RenovationDetailItem } from './RenovationDetailItem';

type RenovationDetailsTableNavigation = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

interface RenovationDetailsTableProps {
  renovationAmount: number | undefined;
  details: RenovationDetail[];
  toggleDrawer: (content: ReactElement | null) => void;
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_07,
      borderWidth: 1,
      gap: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
      padding: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_08,
    },
    drawer: isLowerThanLaptop ? { flex: 1 } : { minWidth: 423 },
    gap: {
      gap: theme.spacing.SIZE_03,
    },
    subtitle: {
      textAlign: 'center',
    },
    titleContainer: {
      alignItems: 'center',
      gap: theme.spacing.SIZE_03,
      justifyContent: 'center',
    },
  });

export const RenovationDetailsTable: React.FC<RenovationDetailsTableProps> = ({
  details,
  renovationAmount = 0,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop);

  const navigation = useNavigation<RenovationDetailsTableNavigation>();

  const onPressViewDetails = () => {
    if (isWeb()) {
      toggleDrawer(
        <View style={styles.drawer}>
          <RenovationDetailsTemplate
            renovationAmount={renovationAmount}
            details={details}
            onClose={() => toggleDrawer(null)}
          />
        </View>
      );
    } else {
      navigation.navigate(RealEstateNavigatorRoutes.RenovationDetails, {
        details,
        renovationAmount,
      });
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'}>
          {t('realEstate.renovation.renovationDetails.title')}
        </Text>
        <Text textStyle={isLowerThanLaptop ? 'Caption1' : 'Body3'} style={styles.subtitle}>
          {t('realEstate.renovation.renovationDetails.subtitle')}
        </Text>
      </View>

      <View style={styles.gap}>
        <TablePreview
          withGradient
          title={
            !isLowerThanLaptop ? t('realEstate.renovation.renovationDetails.tableTitle') : undefined
          }
          buttonLabel={
            !isLowerThanLaptop
              ? t('realEstate.renovation.renovationDetails.viewDetails')
              : undefined
          }
          onViewDetailsPress={!isLowerThanLaptop ? onPressViewDetails : undefined}
          total={renovationAmount}
          totalLabel={t('realEstate.renovation.renovationDetails.totalLabel')}
          data={details.slice(0, 4)}
          renderItem={item => <RenovationDetailItem detail={item} />}
        />

        <Text textStyle={isLowerThanLaptop ? 'Caption1Italic' : 'Body3Italic'}>
          {t('realEstate.renovation.renovationDetails.warning')}
        </Text>
      </View>

      {!!isLowerThanLaptop && (
        <Button
          variant="tertiary"
          iconName="ArrowRight"
          iconPlacement="right"
          size="l"
          onPress={onPressViewDetails}
        >
          {t('realEstate.renovation.renovationDetails.viewDetails')}
        </Button>
      )}
    </View>
  );
};
