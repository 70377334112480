import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

export const getStyles = ({
  isMobile,
  isLowerThanLaptop,
  isGreaterThanTablet,
  heightCarousel,
  theme,
}: {
  isMobile: boolean;
  isLowerThanLaptop: boolean;
  isGreaterThanTablet: boolean;
  heightCarousel: number;
  theme: Theme;
}) =>
  StyleSheet.create({
    backContainer: {
      left: 0,
      padding: 20,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 10,
    },
    carouselContainer: {
      alignItems: 'center',
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: 'center',
      maxWidth: isGreaterThanTablet ? 788 : '100%',
      width: '100%',
    },
    containerScreen: {
      alignItems: 'center',
      justifyContent: isMobile ? 'flex-start' : 'center',
      minHeight: heightCarousel - 5,
      paddingHorizontal: 20,
      paddingTop: 20,
      width: '100%',
    },
    dotsContainer: { flexGrow: 0, marginTop: 20 },
    wrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      height: '100%',
      justifyContent: isLowerThanLaptop ? 'flex-start' : 'center',
      paddingBottom: 20,
      paddingTop: isMobile ? 20 : 0,
      width: '100%',
    },
  });
