import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgWs = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ws_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ws_svg__a)">
        <Path fill="#E31F37" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.333 10.667h16v-12h-16v12z"
          fill="#083B9A"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.667 2.667l-.943.276L6 2l-.276-.943.943.276.942-.276L7.333 2l.276.943-.942-.276zm0 6.666l-.943.276L6 8.667l-.276-.943.943.276.942-.276-.276.943.276.942-.942-.276zm-3.61-3.057L4 6l.943.276-.276-.943.276-.942L4 4.667l-.943-.276.276.942-.276.943zM10 5.333l-.943.276.276-.942-.276-.943L10 4l.943-.276-.276.943.276.942L10 5.333zM8.667 7A.333.333 0 118 7a.333.333 0 01.667 0z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgWs;
