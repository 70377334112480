import { cloneDeepWith } from 'lodash';

export const omitTypenameDeep = <T>(variables: Record<string, unknown>): T =>
  cloneDeepWith(variables, value => {
    if (value?.__typename) {
      const { __typename, ...valWithoutTypename } = value;
      return omitTypenameDeep(valWithoutTypename);
    }

    return undefined;
  });
