import React, { useCallback, useEffect, useState } from 'react';

import { Drawer } from './Drawer.web';

interface ControlledDrawerProps {
  onRest?: () => void;
  onClickBackdrop?: () => void;
  onClickCrossBtn?: () => void;
  isOpen?: boolean;
  style?: React.CSSProperties;
  direction?: 'right' | 'bottom';
  showCloseBtn?: boolean;
  children?: React.ReactNode;
}

export const ControlledDrawer: React.FC<ControlledDrawerProps> = ({
  children,
  onRest,
  onClickBackdrop,
  onClickCrossBtn,
  direction,
  isOpen,
  showCloseBtn,
  style,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(isOpen);

  useEffect(() => {
    setIsDrawerVisible(isOpen);
  }, [isOpen]);

  const handleRest = useCallback(() => {
    onRest?.();
  }, [onRest]);

  return (
    <Drawer
      isOpen={isDrawerVisible}
      direction={direction}
      onRest={handleRest}
      onClickBackdrop={onClickBackdrop}
      onClickCrossBtn={onClickCrossBtn}
      showCloseBtn={showCloseBtn}
      style={style}
    >
      {children}
    </Drawer>
  );
};
