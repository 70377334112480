import React, { useCallback, useMemo } from 'react';

import { View } from 'react-native';

import { withLabel } from '@app/ui/hoc/with-label/withLabel';
import { ListItem } from '@app/ui/atoms/list/List';
import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { SelectActivator } from '@app/ui/molecules/dropdown-activator/select-activator/SelectActivator';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { SearchInput } from '@app/ui/molecules/search-input/SearchInput';

import { getStyles } from './Select.styles';
import { SelectV2Props, SelectOptionsProps } from './Select.types';

export { SelectV2Props, SelectOptionsProps };

const SelectStandalone: React.FC<SelectV2Props> = ({
  maxHeight = 240,
  options,
  values,
  placeholder,
  onSelectValue,
  multiple = false,
  onCallbackClose,
  isDisabled,
  search,
  helperText,
  ...rest
}) => {
  const hasSearch = !!(search && Object.keys(search).length && search.onSearch);
  const styles = getStyles({
    hasSearch,
  });

  const getList = useCallback(
    (closeList: () => void) => {
      return (
        <View style={styles.listContainer} testID="select-list-container">
          {options.map(item => {
            const onSelect = () => {
              onSelectValue(item, closeList);
            };
            return (
              <ListItem
                onPress={onSelect}
                checkbox={multiple}
                checked={item.selected}
                {...item}
                key={item.label.toLowerCase()}
              />
            );
          })}
        </View>
      );
    },
    [multiple, onSelectValue, options, styles.listContainer]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return (
        <SelectActivator
          onPress={toggleList}
          placeholder={placeholder}
          values={values}
          open={isOpen}
          isDisabled={isDisabled}
          helperText={helperText}
        />
      );
    },
    [placeholder, values, isDisabled, helperText]
  );

  const header = useMemo(() => {
    return hasSearch ? (
      <View style={styles.headerContainer} testID="select-header-container">
        <SearchInput {...search} />
      </View>
    ) : null;
  }, [hasSearch, styles.headerContainer, search]);

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => {
      return (
        <DropdownListContainer
          list={getList(closeList)}
          header={header}
          maxHeight={maxHeightList}
        />
      );
    },
    [getList, header]
  );

  return (
    <DropdownV2
      {...rest}
      maxHeight={maxHeight}
      renderList={renderList}
      fitParent
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};

export const SelectV2 = withLabel(SelectStandalone);
