import React, { PropsWithChildren } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Button, IconButton, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { isWeb } from '@app/constants/platform';
import { BOTTOM_BAR_MENU_HEIGHT } from '@app/ui/organisms/BottomBarMenu/bottomBarMenu.theme';

interface SubFilterContainer {
  canApply: boolean;
  loading?: boolean;
  resultCount?: number | undefined;
  title: string;
  onBack: () => void;
  onApply: () => void;
  onReset: () => void;
}

const getStyles = ({
  isLowerThanLaptop,
  isLowerThanTablet,
  height,
  theme,
}: {
  isLowerThanLaptop: boolean;
  isLowerThanTablet: boolean;
  height: number;
  theme: Theme;
}) =>
  StyleSheet.create({
    applyButton: {
      ...(isLowerThanTablet && { width: '100%' }),
      ...(!isLowerThanTablet && { flex: 2 / 3 }),
    },
    clearButton: {
      ...(isLowerThanTablet && { width: '100%' }),
      ...(!isLowerThanTablet && { flex: 1 / 3 }),
    },
    container: {
      flex: 1,
      gap: theme.spacing.SIZE_08,
      ...(isWeb() && {
        maxHeight: height - (isLowerThanLaptop ? BOTTOM_BAR_MENU_HEIGHT : 0),
        minHeight: height - (isLowerThanLaptop ? BOTTOM_BAR_MENU_HEIGHT : 0),
      }),
    },
    content: {
      flex: 1,
      paddingHorizontal: isLowerThanTablet ? theme.spacing.SIZE_06 : theme.spacing.SIZE_08,
    },
    footer: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[300],
      borderTopWidth: 1,
      flexDirection: isLowerThanTablet ? 'column-reverse' : 'row',
      gap: theme.spacing.SIZE_04,
      padding: isLowerThanTablet ? theme.spacing.SIZE_06 : theme.spacing.SIZE_04,
      ...(isLowerThanLaptop && { paddingTop: theme.spacing.SIZE_04 }),
    },
    header: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_04,
      ...(isLowerThanTablet && {
        borderBottomWidth: 1,
        borderColor: theme.palette.neutral[300],
        paddingHorizontal: theme.spacing.SIZE_06,
        paddingVertical: theme.spacing.SIZE_05,
      }),
      ...(!isLowerThanTablet && {
        paddingHorizontal: theme.spacing.SIZE_08,
        paddingTop: theme.spacing.SIZE_08,
      }),
    },
  });

export const FilterFormContainer: React.FC<PropsWithChildren<SubFilterContainer>> = ({
  canApply,
  loading,
  resultCount,
  title,
  onApply,
  onBack,
  onReset,
  children,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const { isLowerThanTablet, isLowerThanLaptop } = useResponsive();
  const styles = getStyles({ height, isLowerThanLaptop, isLowerThanTablet, theme });

  const hasResult = typeof resultCount === 'number';
  const resultKey = `realEstate.filters.resultCount.${resultCount ?? 0 > 1 ? 'other' : 'one'}`;
  const resultText = hasResult ? t(resultKey, { count: resultCount }) : '';

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <IconButton iconName="ChevronLeft" onPress={onBack} />
        <Text textStyle={isLowerThanTablet ? 'Headline3Medium' : 'Headline1Medium'}>{title}</Text>
      </View>

      <View style={styles.content}>{children}</View>

      <View style={styles.footer}>
        <Button
          testID="search-engine-filter-reset--button"
          variant="ghost"
          wrapperStyle={styles.clearButton}
          onPress={onReset}
        >
          {t('realEstate.filters.reset')}
        </Button>
        <Button
          disabled={!canApply}
          testID="search-engine-filter--submit"
          variant="secondary"
          wrapperStyle={styles.applyButton}
          onPress={onApply}
          isLoading={loading}
        >
          {`${t('realEstate.filters.apply')} ${resultText}`}
        </Button>
      </View>
    </View>
  );
};
