import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgSkillet = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M4.425 10c.233-.6.27-1.133.112-1.6-.158-.467-.429-.933-.812-1.4-.55-.667-.913-1.296-1.088-1.888C2.462 4.521 2.433 3.817 2.55 3H4.5c-.133.633-.154 1.15-.063 1.55.092.4.33.833.713 1.3.633.767 1.037 1.446 1.212 2.038.175.591.18 1.295.013 2.112h-1.95Zm4 0c.233-.6.275-1.133.125-1.6-.15-.467-.417-.933-.8-1.4-.55-.667-.917-1.296-1.1-1.888-.183-.591-.217-1.295-.1-2.112H8.5c-.133.633-.154 1.15-.063 1.55.092.4.33.833.713 1.3.633.767 1.037 1.446 1.213 2.038.175.591.179 1.295.012 2.112h-1.95Zm4 0c.233-.6.275-1.133.125-1.6-.15-.467-.417-.933-.8-1.4-.55-.667-.917-1.296-1.1-1.888-.183-.591-.217-1.295-.1-2.112h1.95c-.133.633-.154 1.15-.063 1.55.092.4.33.833.713 1.3.633.767 1.037 1.446 1.213 2.038.175.591.179 1.295.012 2.112h-1.95ZM5 20a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 2 17v-5h14.025a2.868 2.868 0 0 1 .675-1.488 3.07 3.07 0 0 1 1.35-.912l4.625-1.55.625 1.9-4.625 1.55a.959.959 0 0 0-.488.363.987.987 0 0 0-.187.587V17c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 15 20H5Zm0-2h10c.283 0 .52-.096.713-.288A.968.968 0 0 0 16 17v-3H4v3c0 .283.096.52.287.712.192.192.43.288.713.288Z"
    />
  </Svg>
);
export default SvgSkillet;
