import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgOrders = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M4 19.998v-12.9l-1.95-4.25 1.8-.85 2.35 5.05h11.6l2.35-5.05 1.8.85L20 7.098v12.9H4Zm6-7h4c.284 0 .522-.096.713-.287a.968.968 0 0 0 .288-.713.968.968 0 0 0-.288-.712.968.968 0 0 0-.712-.288h-4a.968.968 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .283.096.52.287.713.192.191.43.287.713.287Zm-4 5h12v-8.95H6v8.95Z"
    />
  </Svg>
);
export default SvgOrders;
