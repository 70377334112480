import React, { useCallback, useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { ExpandableGroup, Theme, useTheme } from '@app/ui';
import { SelectActivator } from '@app/ui/molecules/dropdown-activator/select-activator/SelectActivator';

import { SelectGroupProps } from './SelectGroup.types';
import { SelectGroupItem } from './SelectGroupItem';
import { SelectGroupHeader } from './SelectGroupHeader';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    footer: {
      flexDirection: 'row',
      padding: theme.spacing.SIZE_06,
    },
    headerContainer: {
      paddingHorizontal: theme.spacing.SIZE_04,
      paddingTop: theme.spacing.SIZE_06,
    },
    listContainer: { padding: theme.spacing.SIZE_06 },
  });

export const SelectGroup: React.FC<SelectGroupProps> = ({
  optionGroups,
  maxHeight = 310,
  values,
  placeholder,
  multiple = false,
  onCallbackClose,
  onChangeValues,
  selectAllLabel,
  unselectAllLabel,
  isDisabled,
  helperText,
  ...rest
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const optionGroupsWithSelected = useMemo(
    () =>
      optionGroups.map(optionGroup => ({
        label: optionGroup.label,
        options: optionGroup.options.map(x => ({
          ...x,
          selected: !!values.find(value => value === x.key),
        })),
      })),
    [optionGroups, values]
  );

  const selectedValueLabels = useMemo(() => {
    const labels: string[] = [];
    for (const groupItem of optionGroupsWithSelected) {
      for (const option of groupItem.options) {
        if (option.selected) {
          labels.push(option.label);
        }
      }
    }
    return labels;
  }, [optionGroupsWithSelected]);

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="select-group-list-container">
          <ExpandableGroup
            allowMultipleExpand={true}
            initialExpandedId={optionGroupsWithSelected[0]?.label}
          >
            {optionGroupsWithSelected.map((item, index) => (
              <SelectGroupItem
                isFirst={index === 0}
                key={item.label}
                {...(multiple
                  ? {
                      canSelectAll: !!selectAllLabel,
                      multiple: true,
                    }
                  : {
                      canSelectAll: false,
                      multiple: false,
                    })}
                closeList={closeList}
                label={item.label}
                options={item.options}
                onChangeValues={onChangeValues}
                values={values}
              />
            ))}
          </ExpandableGroup>
        </View>
      );
    },
    [
      styles.listContainer,
      optionGroupsWithSelected,
      multiple,
      selectAllLabel,
      onChangeValues,
      values,
    ]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return (
        <SelectActivator
          onPress={toggleList}
          placeholder={placeholder}
          values={selectedValueLabels}
          open={isOpen}
          isDisabled={isDisabled}
          helperText={helperText}
        />
      );
    },
    [placeholder, selectedValueLabels, isDisabled, helperText]
  );

  const header = useMemo(() => {
    if (!selectAllLabel) {
      return null;
    }

    return (
      <SelectGroupHeader
        optionGroups={optionGroups}
        values={values}
        onChangeValues={onChangeValues}
        selectAllLabel={selectAllLabel}
        unselectAllLabel={unselectAllLabel}
      />
    );
  }, [values, optionGroups, onChangeValues, selectAllLabel, unselectAllLabel]);

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => {
      return (
        <DropdownListContainer
          header={header}
          list={getList(closeList)}
          maxHeight={maxHeightList}
        />
      );
    },
    [getList, header]
  );

  return (
    <DropdownV2
      {...rest}
      maxHeight={maxHeight}
      renderList={renderList}
      fitParent
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};
