import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgNewspaper = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 21c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 19V4c0-.283.067-.454.2-.513.133-.058.3.013.5.213l.975.975.95-.95c.1-.1.208-.175.325-.225a.942.942 0 01.375-.075c.133 0 .258.02.375.062a.883.883 0 01.325.213L7 4.675l.975-.975c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.025.375.075c.117.05.225.125.325.225l.95.95.975-.975c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.883.883 0 01.325.213l.975.975.95-.95c.1-.1.208-.175.325-.225a.942.942 0 01.375-.075c.133 0 .258.02.375.062a.883.883 0 01.325.213l.975.975.975-.975c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.025.375.075c.117.05.225.125.325.225l.95.95.975-.975c.2-.2.367-.271.5-.213.133.059.2.23.2.513v15c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 21H4zm0-2h7v-6H4v6zm9 0h7v-2h-7v2zm0-4h7v-2h-7v2zm-9-4h16V8H4v3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgNewspaper;
