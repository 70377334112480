import { getColorWithOpacity } from '@app/ui/theme/palette';
import { Theme } from '@app/ui/theme/types';

export const buttonTheme = (theme: Theme) => ({
  danger: {
    inDark: {
      background: {
        active: getColorWithOpacity(theme.palette.system.danger[500], 100),
        default: getColorWithOpacity(theme.palette.system.danger[500], 100),
        disabled: getColorWithOpacity(theme.palette.base.white, 10),
        hover: getColorWithOpacity(theme.palette.system.danger[400], 100),
        loading: getColorWithOpacity(theme.palette.system.danger[600], 100),
        pressed: getColorWithOpacity(theme.palette.system.danger[600], 100),
      },
      loader: {
        default: theme.palette.base.white,
      },
      text: {
        active: theme.palette.base.white,
        default: theme.palette.base.white,
        disabled: getColorWithOpacity(theme.palette.neutral[600], 100),
        hover: theme.palette.base.white,
        pressed: theme.palette.base.white,
      },
    },
    inLight: {
      background: {
        active: theme.palette.system.danger[600],
        default: getColorWithOpacity(theme.palette.system.danger[500], 100),
        disabled: getColorWithOpacity(theme.palette.neutral[300], 100),
        hover: getColorWithOpacity(theme.palette.system.danger[400], 100),
        loading: getColorWithOpacity(theme.palette.system.danger[500], 100),
        pressed: getColorWithOpacity(theme.palette.system.danger[600], 100),
      },
      loader: {
        default: theme.palette.base.white,
      },
      text: {
        active: theme.palette.base.white,
        default: theme.palette.base.white,
        disabled: getColorWithOpacity(theme.palette.neutral[600], 100),
        hover: theme.palette.base.white,
        pressed: theme.palette.base.white,
      },
    },
  },
  ghost: {
    inDark: {
      background: {
        active: 'initial',
        default: 'initial',
        disabled: 'initial',
        hover: 'initial',
      },
      loader: {
        default: getColorWithOpacity(theme.palette.base.white, 80),
      },
      text: {
        active: getColorWithOpacity(theme.palette.base.white, 80),
        default: theme.palette.base.white,
        disabled: getColorWithOpacity(theme.palette.base.white, 30),
        hover: getColorWithOpacity(theme.palette.base.white, 90),
      },
    },
    inLight: {
      background: {
        active: theme.palette.neutral[100],
        default: 'initial',
        disabled: theme.palette.neutral[300],
        hover: theme.palette.neutral[50],
      },
      loader: {
        default: theme.palette.neutral[800],
      },
      text: {
        active: theme.palette.neutral[900],
        default: theme.palette.neutral[800],
        disabled: theme.palette.neutral[600],
        hover: theme.palette.neutral[700],
      },
    },
  },
  primary: {
    inDark: {
      background: {
        active: theme.palette.system.primary[600],
        default: theme.palette.system.primary[500],
        disabled: theme.palette.neutral[800],
        hover: theme.palette.system.primary[400],
        loading: theme.palette.system.primary[500],
      },
      loader: {
        default: theme.palette.system.secondary[500],
      },
      text: {
        active: theme.palette.base.white,
        default: theme.palette.base.white,
        disabled: theme.palette.neutral[600],
        hover: theme.palette.base.white,
      },
    },
    inLight: {
      background: {
        active: theme.palette.system.primary[600],
        default: theme.palette.system.primary[500],
        disabled: theme.palette.neutral[300],
        hover: theme.palette.system.primary[400],
        loading: theme.palette.system.primary[500],
      },
      loader: {
        default: theme.palette.base.white,
      },
      text: {
        active: theme.palette.base.white,
        default: theme.palette.base.white,
        disabled: theme.palette.neutral[600],
        hover: theme.palette.base.white,
      },
    },
  },
  secondary: {
    inDark: {
      background: {
        active: theme.palette.neutral[200],
        default: theme.palette.neutral[100],
        disabled: theme.palette.neutral[800],
        hover: theme.palette.neutral[100],
      },
      loader: {
        default: theme.palette.base.white,
      },
      text: {
        active: theme.palette.system.secondary[600],
        default: theme.palette.system.secondary[500],
        disabled: theme.palette.neutral[600],
        hover: theme.palette.system.secondary[400],
      },
    },
    inLight: {
      background: {
        active: theme.palette.system.secondary[600],
        default: theme.palette.system.secondary[500],
        disabled: theme.palette.neutral[300],
        hover: theme.palette.system.secondary[400],
      },
      loader: {
        default: theme.palette.base.white,
      },
      text: {
        active: theme.palette.base.white,
        default: theme.palette.base.white,
        disabled: theme.palette.neutral[600],
        hover: theme.palette.base.white,
      },
    },
  },
  size: {
    icon: {
      padding: theme.spacing.SIZE_03,
    },
    l: {
      height: theme.spacing.SIZE_10,
      paddingX: theme.spacing.SIZE_06,
      radius: theme.borderRadius.SIZE_05,
    },
    m: {
      height: theme.spacing.SIZE_09,
      paddingX: theme.spacing.SIZE_05,
      radius: theme.borderRadius.SIZE_05,
    },
    s: {
      height: theme.spacing.SIZE_08,
      paddingX: theme.spacing.SIZE_04,
      radius: theme.borderRadius.SIZE_04,
    },
  },
  tertiary: {
    inDark: {
      background: {
        active: getColorWithOpacity(theme.palette.base.white, 7),
        default: 'initial',
        disabled: 'initial',
        hover: 'initial',
      },
      borderColor: {
        active: getColorWithOpacity(theme.palette.base.white, 80),
        default: theme.palette.base.white,
        disabled: getColorWithOpacity(theme.palette.base.white, 40),
        hover: getColorWithOpacity(theme.palette.base.white, 90),
      },
      borderWidth: 1,
      loader: {
        default: theme.palette.base.white,
      },
      text: {
        active: getColorWithOpacity(theme.palette.base.white, 80),
        default: theme.palette.base.white,
        disabled: getColorWithOpacity(theme.palette.base.white, 40),
        hover: getColorWithOpacity(theme.palette.base.white, 90),
      },
    },
    inLight: {
      background: {
        active: theme.palette.base.white,
        default: theme.palette.base.white,
        disabled: theme.palette.neutral[300],
        hover: theme.palette.base.white,
      },
      borderColor: {
        active: theme.palette.neutral[900],
        default: theme.palette.neutral[800],
        disabled: theme.palette.neutral[300],
        hover: theme.palette.neutral[700],
      },
      borderWidth: 1,
      loader: {
        default: theme.palette.neutral[800],
      },
      text: {
        active: theme.palette.neutral[900],
        default: theme.palette.neutral[800],
        disabled: theme.palette.neutral[600],
        hover: theme.palette.neutral[700],
      },
    },
  },
});
