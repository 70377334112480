import { Theme } from '@app/ui/theme/types';

export const switchTheme = (theme: Theme) => {
  return {
    checked: {
      color: theme.palette.neutral[900],
    },
    colors: {
      indicatorBackground: theme.palette.base.white,
    },
    disabled: {
      color: theme.palette.neutral[200],
    },
    labelColor: {
      default: theme.palette.neutral[800],
      disabled: theme.palette.neutral[600],
    },
    sizes: {
      borderRadius: theme.spacing.SIZE_09,
      indicatorGap: 3,
      indicatorSize: 18,
      l: {
        height: theme.spacing.SIZE_07,
        indicatorSize: theme.spacing.SIZE_05 + 2,
        textStyles: theme.textStyles.Body1,
        width: theme.spacing.SIZE_10,
      },
      labelGap: theme.spacing.SIZE_05,
      m: {
        height: theme.spacing.SIZE_06,
        indicatorSize: theme.spacing.SIZE_04 + 2,
        textStyles: theme.textStyles.Body2,
        width: theme.spacing.SIZE_09,
      },
      outlineFocusWidth: 2,
      s: {
        height: theme.spacing.SIZE_05,
        indicatorSize: theme.spacing.SIZE_03 + 2,
        textStyles: theme.textStyles.Body3,
        width: theme.spacing.SIZE_08,
      },
    },
    unchecked: {
      color: theme.palette.neutral[300],
    },
  };
};
