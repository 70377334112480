import React from 'react';

import { LoginEmailPassword } from '@app/features/loginEmailPassword/LoginEmailPassword';

export const LoginEmailPasswordView: React.FC = () => {
  return <LoginEmailPassword />;
};

// eslint-disable-next-line import/no-default-export
export default LoginEmailPassword;
