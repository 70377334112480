import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgOptions = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M13 9V7h3V3h2v4h3v2h-8zm3 12V11h2v10h-2zM6 21v-4H3v-2h8v2H8v4H6zm0-8V3h2v10H6z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgOptions;
