import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgUniversity = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.925 20.45a1.906 1.906 0 01-.95-.25l-5-2.7a2.003 2.003 0 01-.775-.738 1.991 1.991 0 01-.275-1.037v-4.8L2.525 9.6a.973.973 0 01-.4-.375 1.012 1.012 0 01-.125-.5c0-.183.042-.35.125-.5a.973.973 0 01.4-.375l8.45-4.6a1.907 1.907 0 011.438-.188c.158.042.312.105.462.188l9.525 5.2a.905.905 0 01.388.363c.091.158.137.329.137.512v6.4c0 .283-.096.52-.288.713a.968.968 0 01-.712.287.968.968 0 01-.713-.288.968.968 0 01-.287-.712v-5.9l-2 1.1v4.8c0 .383-.092.73-.275 1.037a2.003 2.003 0 01-.775.738l-5 2.7a1.906 1.906 0 01-.95.25zm0-8.025l6.85-3.7-6.85-3.7-6.85 3.7 6.85 3.7zm0 6.025l5-2.7v-3.775L12.9 14.2a2.053 2.053 0 01-1.475.188 1.948 1.948 0 01-.475-.188l-4.025-2.225v3.775l5 2.7z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgUniversity;
