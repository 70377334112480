import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTv = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9 21a.965.965 0 01-.712-.288A.965.965 0 018 20v-1H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 17V5c0-.55.196-1.021.588-1.413A1.925 1.925 0 014 3h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 19h-4v1c0 .283-.096.52-.287.712A.968.968 0 0115 21H9zm-5-4h16V5H4v12z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTv;
