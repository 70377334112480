import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="sa_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#sa_svg__a)">
        <Path fill="#128C4B" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.667 12h10.666v-.921c0-.496.522-.818.965-.597l.369.185V12h2c.368 0 .666.299.666.667v.666c0 .737-.597 1.334-1.333 1.334h-2a.667.667 0 01-.667-.667v-.667H8.276a.667.667 0 01-.471-.195L6.667 12zM6.667 8.138c0-.088.035-.173.097-.236l.334-.333a.333.333 0 000-.471l-.334-.334a.333.333 0 01-.097-.235v-.862c0-.184.149-.334.333-.334h.745c.141 0 .255.114.255.255 0 .269.362.354.482.114l.1-.199a.307.307 0 01.274-.17h1.477a.333.333 0 110 .667h-.666a.333.333 0 000 .667h.666c.184 0 .334.149.334.333v2c0 .184-.15.333-.334.333h-.666A.333.333 0 019.333 9v-.667c0-.184.15-.333.334-.333a.333.333 0 100-.667H9a.333.333 0 00-.333.334V9A.333.333 0 118 9v-.667a.333.333 0 10-.667 0V9a.333.333 0 11-.666 0v-.862zM17.333 8.138c0-.088.035-.173.098-.236l.333-.333a.333.333 0 000-.471l-.333-.334a.333.333 0 01-.098-.235v-.862c0-.184.15-.334.334-.334h.745c.14 0 .255.114.255.255 0 .269.362.354.482.114l.1-.199a.307.307 0 01.274-.17H21A.333.333 0 1121 6h-.667a.333.333 0 000 .667H21c.184 0 .333.149.333.333v2c0 .184-.149.333-.333.333h-.667A.333.333 0 0120 9v-.667c0-.184.15-.333.333-.333a.333.333 0 100-.667h-.666a.333.333 0 00-.334.334V9a.333.333 0 11-.666 0v-.667a.333.333 0 00-.667 0V9a.333.333 0 11-.667 0v-.862zM16 6.33c0 .186-.104.44-.24.577l-.186.186a.337.337 0 000 .481l.185.185a.92.92 0 01.241.577v.66a.332.332 0 01-.336.337h-.66a.331.331 0 01-.337-.336v-.66c0-.186-.062-.214-.155-.028l-.357.715a.612.612 0 01-.485.31h-1.34A.335.335 0 0112 9a.33.33 0 01.336-.333h.66a.338.338 0 00.337-.334.33.33 0 00-.336-.333h-.66A.33.33 0 0112 7.673V5.66c0-.18.143-.327.336-.327h.66c.187 0 .337.143.337.337v.66a.338.338 0 01-.333.337.337.337 0 00-.333.333.33.33 0 00.336.333h.66a.34.34 0 00.337-.33v-1.34c0-.182.155-.33.333-.33a.33.33 0 01.334.337v.66c0 .186.154.337.333.337a.33.33 0 00.333-.337v-.66c0-.186.155-.337.334-.337A.33.33 0 0116 5.67v.66z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgSa;
