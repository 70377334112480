import React, { PropsWithChildren } from 'react';

import Modal from 'react-native-modal';
import { useWindowDimensions, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Icon } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';
import { isWeb } from '@app/constants/platform';

import { BottomSheetProps } from './BottomSheet.types';
import { getStyles } from './BottomSheet.styles';
import { getWebSize } from './BottomSheet.utils';

export { BottomSheetProps };

export const BottomSheet: React.FC<PropsWithChildren<BottomSheetProps>> = ({
  children,
  visible,
  testID,
  webSize = 'm',
  isHideCross,
  noPadding = false,
  onPressClose,
}) => {
  const { width, height } = useWindowDimensions();
  const { isNativeOrResponsive } = useResponsive();
  const inset = useSafeAreaInsets();

  const theme = useTheme();

  const windowWidth = useWindowDimensions().width;
  const webSizeWidth = getWebSize(webSize, windowWidth);

  const maxWidth = isWeb() ? windowWidth * 0.9 : windowWidth;

  const styles = getStyles({
    inset,
    isNativeOrResponsive,
    noPadding,
    theme,
    webSize,
    webSizeWidth,
  });

  return (
    <Modal
      isVisible={visible}
      backdropOpacity={0.2}
      backdropColor={theme.palette.neutral[700]}
      animationIn={isNativeOrResponsive ? 'slideInUp' : 'fadeIn'}
      animationOut={isNativeOrResponsive ? 'slideOutDown' : 'fadeOut'}
      useNativeDriverForBackdrop
      hardwareAccelerated
      animationInTiming={300}
      animationOutTiming={300}
      backdropTransitionInTiming={300}
      backdropTransitionOutTiming={300}
      onBackButtonPress={onPressClose}
      onBackdropPress={onPressClose}
      onSwipeComplete={onPressClose}
      deviceWidth={width}
      deviceHeight={height}
      useNativeDriver
      testID={testID}
      hideModalContentWhileAnimating
      style={styles.modal}
      statusBarTranslucent
    >
      <View style={[styles.container, { maxWidth }]}>
        {!isHideCross && (
          <Pressable
            onPress={onPressClose}
            style={styles.buttonContainer}
            testID="bottom-sheet-close-button"
          >
            <Icon name="Cross" size={24} />
          </Pressable>
        )}
        {children}
      </View>
    </Modal>
  );
};
