import { palette } from './palette';

export type ShadowSize = keyof typeof shadows;

export const shadows = {
  100: {
    elevation: 2,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 12,
  },
  200: {
    elevation: 3,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 32,
  },
  300: {
    elevation: 3,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 48,
  },
  400: {
    elevation: 3,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 56,
  },
  50: {
    elevation: 2,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 8,
  },
  500: {
    elevation: 3,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 64,
  },
  600: {
    elevation: 3,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 72,
  },
  700: {
    elevation: 4,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 80,
  },
  800: {
    elevation: 4,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 96,
  },
  900: {
    elevation: 4,
    shadowColor: palette.neutral[900],
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.15,

    shadowRadius: 120,
  },
};
