import React from 'react';

import { StyleSheet, View } from 'react-native';

import { CalloutWithButton, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

type CalloutProps = {
  onClick: () => void;
};

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    calloutDescription: { gap: 18 },
    calloutText: {
      color: theme.palette.tint.pink[700],
      flex: 1,
    },
  });

export const Callout: React.FC<CalloutProps> = ({ onClick }) => {
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const { t } = useTranslation();
  const styles = getStyles(theme);

  return (
    <CalloutWithButton
      tint={theme.palette.tint.pink}
      text={t('propertySold.callout.text').toUpperCase()}
      buttonText={t('propertySold.callout.buttonText')}
      iconName="Phone"
      onButtonPress={onClick}
    >
      <Text style={styles.calloutText} textStyle="Body3">
        {t('propertySold.callout.description1')}
      </Text>
      {isLowerThanDesktop ? (
        <View style={styles.calloutDescription}>
          <Text style={styles.calloutText} textStyle="Body3">
            {t('propertySold.callout.description2')}
          </Text>
          <Text style={styles.calloutText} textStyle="Body3">
            {t('propertySold.callout.description3')}
          </Text>
        </View>
      ) : (
        <Text style={styles.calloutText} textStyle="Body3">
          {`${t('propertySold.callout.description2')} ${t('propertySold.callout.description3')}`}
        </Text>
      )}
    </CalloutWithButton>
  );
};
