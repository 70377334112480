import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCheckboxChecked = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V6a4 4 0 00-4-4H6zm1.287 9.507a1 1 0 011.414-.013l2.085 2.048 4.25-4.25a1 1 0 111.414 1.415l-4.95 4.95a1 1 0 01-1.408.006L7.3 12.921a1 1 0 01-.013-1.414z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCheckboxChecked;
