import { StyleSheet } from 'react-native';

import { RequiredThemes } from '@app/ui/theme/types';

export const styles = (componentTheme: RequiredThemes['avatar']) =>
  StyleSheet.create({
    background: {
      alignItems: 'center',
      backgroundColor: componentTheme.background.backgroundColor,
      borderRadius: componentTheme.background.borderRadius,
      height: componentTheme.background.size,
      justifyContent: 'center',
      overflow: 'hidden',
      width: componentTheme.background.size,
    },
    icon: {
      zIndex: -2,
    },
    wrapper: {
      overflow: 'hidden',
    },
  });

export const rawStyles = (componentTheme: RequiredThemes['avatar']) => ({
  background: {
    size: componentTheme.background.size,
  },
  icon: {
    color: componentTheme.icon.color,
    size: componentTheme.icon.size,
  },
});
