import { StyleSheet } from 'react-native';

import { BorderRadius, Theme } from '@app/ui';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    bottomSheet: {
      flexDirection: 'row',
    },
    bottomSheetPressable: { flex: 1, justifyContent: 'flex-end' },
    bottomSheetText: { flex: 1, justifyContent: 'center' },
    close: {
      position: 'absolute',
      right: 30,
      top: 30,
    },
    closeButton: {
      left: 20,
      position: 'absolute',
      top: 40,
      zIndex: 1,
    },
    closeButtonWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderRadius: 50,
      elevation: 5,
      height: 40,
      justifyContent: 'center',
      width: 40,
    },
    container: { flex: 1 },
    mapWrapper: {
      flex: 1,
    },
    modalContainer: {
      backgroundColor: theme.palette.base.white,
      borderRadius: BorderRadius.SIZE_04,
      padding: 30,
    },
  });
