import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgDo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="do_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#do_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8h12V0H0v8zM16 20h12v-8H16v8z"
          fill="#083D7A"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20h12v-8H0v8zM16 8h12V0H16v8z"
          fill="#DF1E35"
        />
        <Mask id="do_svg__b" fill="#fff">
          <Path fillRule="evenodd" clipRule="evenodd" d="M12.586 8.586a2 2 0 102.828 0" />
        </Mask>
        <Path
          d="M12.114 8.114a2.667 2.667 0 000 3.772l.943-.943a1.333 1.333 0 010-1.886l-.943-.943zm0 3.772a2.667 2.667 0 003.772 0l-.943-.943c-.52.52-1.365.52-1.886 0l-.943.943zm3.772 0a2.667 2.667 0 000-3.772l-.943.943c.52.52.52 1.365 0 1.886l.943.943z"
          fill="#0F6D1A"
          mask="url(#do_svg__b)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 10.667a.667.667 0 100-1.334.667.667 0 000 1.334z"
          fill="#042F60"
        />
      </G>
    </Svg>
  );
};

export default SvgDo;
