import { useEffect } from 'react';

import { RealEstate, useViewRealEstateMutation } from '@app/libs/apollo/introspection';

import { useMe } from './useMe';

export const useViewRealEstate = (realEstate?: RealEstate): void => {
  const { customer } = useMe();

  const [viewRealEstate] = useViewRealEstateMutation();

  useEffect(() => {
    if (!!customer?.id && realEstate) {
      viewRealEstate({ variables: { realEstateId: realEstate.id } });
    }
  }, [customer?.id, realEstate?.id]);
};
