import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgSb = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="sb_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#sb_svg__a)">
        <Path fill="url(#sb_svg__paint0_linear_4223_11770)" d="M0 0h28v20H0z" />
        <G filter="url(#sb_svg__filter0_d_4223_11770)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0h28L0 20V0z"
            fill="url(#sb_svg__paint1_linear_4223_11770)"
          />
        </G>
        <Path fillRule="evenodd" clipRule="evenodd" d="M28 0L0 20h28V0z" fill="#2C7442" />
        <Path fill="#FFD646" d="M-2.774 19.706L25.038.946l13.327 19.76-27.812 18.759z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.02 4.348l.187-1.09-.792-.773 1.095-.16.49-.992.49.993 1.095.159-.792.773.187 1.09L3 3.833l-.98.515zm6.667 0l.187-1.09-.792-.773 1.095-.16.49-.992.49.993 1.095.159-.793.773.187 1.09-.98-.515-.979.515zm.187 2.91l-.187 1.09.98-.515.98.515-.188-1.09.793-.773-1.096-.16-.49-.992-.49.993-1.094.159.792.773zM3 7.833l-.98.515.187-1.09-.792-.773 1.095-.16.49-.992.49.993 1.095.159-.792.773.187 1.09L3 7.833zm2.354-1.485l.98-.515.979.515-.187-1.09.792-.773-1.095-.16-.49-.992-.49.993-1.095.159.793.773-.187 1.09z"
          fill="#fff"
        />
      </G>
      <Defs>
        <LinearGradient
          id="sb_svg__paint0_linear_4223_11770"
          x1={0}
          y1={0}
          x2={0}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1DBE4F" />
          <Stop offset={1} stopColor="#159B3F" />
        </LinearGradient>
        <LinearGradient
          id="sb_svg__paint1_linear_4223_11770"
          x1={0}
          y1={0}
          x2={0}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#0660D4" />
          <Stop offset={1} stopColor="#0051BB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgSb;
