import { uniqBy } from 'lodash';

import rawCountries from './countries.json';

const BASE_COUNTRIES = new Set(['FR', 'BE', 'CH', 'GB', 'US', 'DE']);
const countries = uniqBy(
  [
    ...rawCountries.filter(e => BASE_COUNTRIES.has(e.alpha2)),
    ...rawCountries.filter(
      e => e.emoji && e.countryCallingCodes[0]?.length > 0 && !BASE_COUNTRIES.has(e.alpha2)
    ),
  ],
  'countryCallingCodes[0]'
);

export const prefixOptions = countries.map(({ alpha2, countryCallingCodes, emoji, name }) => ({
  countryCode: alpha2,
  symbol: emoji,
  title: name,
  value: countryCallingCodes[0],
}));
