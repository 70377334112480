import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgUy = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="uy_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#uy_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M28 2.667H13.333v2.666H28V2.667zM13.333 8v2.667H28V8H13.333zm0 5.333H0V16h28v-2.667H13.333zM0 20v-1.333h28V20H0z"
          fill="#0E4DC5"
        />
        <Path
          d="M6.667 9.321l-.89 1.329-.226-1.583-1.378.81.484-1.524-1.594.133L4.16 7.322 2.667 6.75l1.493-.572-1.097-1.163 1.594.132-.484-1.524 1.378.81.226-1.582.89 1.328.89-1.328.225 1.583 1.379-.811-.484 1.524 1.594-.132-1.098 1.163 1.494.572-1.494.572 1.098 1.164-1.594-.133.484 1.525-1.379-.811-.225 1.583-.89-1.329zm0-.025a2.545 2.545 0 100-5.091 2.545 2.545 0 000 5.09zm2-2.63a2 2 0 11-4 0 2 2 0 014 0z"
          fill="#FED443"
        />
      </G>
    </Svg>
  );
};

export default SvgUy;
