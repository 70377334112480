import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { Theme } from '@app/ui/theme/types';

interface GetBannerStyles {
  childrenWrapper: ViewStyle;
  container: ViewStyle;
  text: TextStyle;
}

export const getCardBannerStyle = ({
  theme,
  color,
}: {
  theme: Theme;
  color: string;
}): StyleSheet.NamedStyles<GetBannerStyles> => {
  return StyleSheet.create<GetBannerStyles>({
    childrenWrapper: {
      display: 'flex',
    },
    container: {
      alignItems: 'center',
      backgroundColor: color,
      bottom: 0,
      flexDirection: 'row',
      height: 40,
      left: 0,
      padding: theme.spacing.SIZE_04,
      position: 'absolute',
      width: '100%',
    },
    text: {
      color: theme.palette.base.white,
      display: 'flex',
      height: 24,
      lineHeight: 24,
      marginHorizontal: theme.spacing.SIZE_01,
    },
  });
};
