import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTicketFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 18v-4c.55 0 1.021-.196 1.413-.588C3.804 13.021 4 12.55 4 12c0-.55-.196-1.021-.587-1.413A1.928 1.928 0 002 10V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v4c-.55 0-1.021.196-1.413.587A1.928 1.928 0 0020 12c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588v4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 20H4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTicketFilled;
