import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgStrategy = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7.775 10.535c-.383 0-.675-.17-.875-.512-.2-.342-.192-.68.025-1.013l3.725-6.075a.95.95 0 01.85-.475.95.95 0 01.85.475l3.725 6.075c.217.333.225.671.025 1.013-.2.341-.492.512-.875.512h-7.45zm9.225 11c-1.25 0-2.313-.437-3.188-1.312S12.5 18.285 12.5 17.035c0-1.25.437-2.313 1.312-3.188S15.75 12.535 17 12.535c1.25 0 2.313.437 3.188 1.312s1.312 1.938 1.312 3.188c0 1.25-.437 2.313-1.312 3.188S18.25 21.535 17 21.535zm-13.5-.5a.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-6c0-.283.096-.521.288-.713a.967.967 0 01.712-.287h6a.97.97 0 01.713.287.97.97 0 01.287.713v6c0 .283-.096.52-.287.712a.968.968 0 01-.713.288h-6zm13.5-1.5c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775c-.483-.483-1.075-.725-1.775-.725s-1.292.242-1.775.725c-.483.483-.725 1.075-.725 1.775s.242 1.292.725 1.775c.483.483 1.075.725 1.775.725zm-12.5-.5h4v-4h-4v4zm5.05-10.5h3.9l-1.95-3.15-1.95 3.15z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgStrategy;
