import React, { JSXElementConstructor, ReactElement } from 'react';

import { ErrorScreen } from '@app/features/errorScreen/ErrorScreen';
import { useTranslation } from '@app/services/translations/translations';
import { isWeb } from '@app/constants/platform';

interface ConfigErrorBoundaryProps {
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  configError?: string;
}

export const ConfigErrorBoundary: React.FC<ConfigErrorBoundaryProps> = ({
  children,
  configError,
}) => {
  const { t } = useTranslation();

  const handleRestartApp = () => location.reload();

  if (configError) {
    return (
      <ErrorScreen
        message={t('errorScreen.configError')}
        resetError={isWeb() ? handleRestartApp : undefined}
      />
    );
  }

  return children;
};
