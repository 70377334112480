import { TFunction } from 'i18next';

import { RoomCountFilter } from '@app/libs/apollo/introspection';

import { Room } from '../searchEngineFilters.type';

export const getRoomLabels = (t: TFunction): Record<RoomCountFilter, string> => ({
  [RoomCountFilter.One]: '1',
  [RoomCountFilter.Two]: '2',
  [RoomCountFilter.Three]: '3',
  [RoomCountFilter.Four]: '4',
  [RoomCountFilter.Five]: '5',
  [RoomCountFilter.SixOrMore]: t('propertiesPreferences.sixAndMore'),
});

export const getRoomList = (t: TFunction): Room[] => {
  const labels = getRoomLabels(t);
  return [
    { label: labels[RoomCountFilter.One], roomCount: RoomCountFilter.One },
    { label: labels[RoomCountFilter.Two], roomCount: RoomCountFilter.Two },
    { label: labels[RoomCountFilter.Three], roomCount: RoomCountFilter.Three },
    { label: labels[RoomCountFilter.Four], roomCount: RoomCountFilter.Four },
    { label: labels[RoomCountFilter.Five], roomCount: RoomCountFilter.Five },
    { label: labels[RoomCountFilter.SixOrMore], roomCount: RoomCountFilter.SixOrMore },
  ];
};
