import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgMeterPrice = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19h2v2Zm-2-4v-2h2v2H3Zm0-4v-2h2v2H3Zm0-4V7h2v2H3Zm0-4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3v2H3Zm4 16v-2h2v2H7ZM7 5V3h2v2H7Zm4 0V3h2v2h-2Zm4 0V3h2v2h-2Zm4 4V7h2v2h-2Zm0-4V3c.55 0 1.02.196 1.413.587C20.803 3.98 21 4.45 21 5h-2ZM18.357 21.096c-.983 0-1.858-.279-2.625-.837a4.5 4.5 0 0 1-1.625-2.163h-1.75a.483.483 0 0 1-.356-.144.483.483 0 0 1-.144-.356c0-.142.048-.26.144-.356a.483.483 0 0 1 .356-.144h1.525a1.415 1.415 0 0 1-.025-.25v-.5c0-.075.008-.158.025-.25h-1.525a.483.483 0 0 1-.356-.144.483.483 0 0 1-.144-.356c0-.142.048-.26.144-.356a.483.483 0 0 1 .356-.144h1.75a4.5 4.5 0 0 1 1.625-2.162c.767-.559 1.642-.838 2.625-.838.458 0 .894.063 1.306.188a4.5 4.5 0 0 1 1.156.537c.15.1.232.242.244.425a.608.608 0 0 1-.194.488c-.1.1-.227.158-.38.175a.707.707 0 0 1-.445-.1 3.775 3.775 0 0 0-.8-.338 3.13 3.13 0 0 0-.887-.125c-.625 0-1.194.16-1.706.482-.513.32-.902.743-1.17 1.268h2.876c.142 0 .26.048.356.144a.485.485 0 0 1 .144.356c0 .142-.048.26-.144.356a.484.484 0 0 1-.356.144h-3.2a2.285 2.285 0 0 0-.037.75c.008.075.02.159.037.25h3.2c.142 0 .26.048.356.144a.485.485 0 0 1 .144.356c0 .142-.048.26-.144.356a.484.484 0 0 1-.356.144h-2.875a3.17 3.17 0 0 0 1.169 1.27 3.15 3.15 0 0 0 1.706.48 3.442 3.442 0 0 0 1.688-.462.73.73 0 0 1 .437-.107c.15.013.28.073.387.182a.608.608 0 0 1 .194.487.528.528 0 0 1-.244.425 4.5 4.5 0 0 1-1.156.538 4.482 4.482 0 0 1-1.306.187Z"
    />
  </Svg>
);
export default SvgMeterPrice;
