import React, { PropsWithChildren } from 'react';

import { View } from 'react-native';

import { ViewOrModalBlockProps } from './ViewOrModalBlock.types';

export { ViewOrModalBlockProps };

export const ViewOrModalBlock: React.FC<PropsWithChildren<ViewOrModalBlockProps>> = ({
  children,
  isHiddenInMobile = false,
  isHiddenInModal = false,
  isModalRender = false,
  isHiddenInWeb = false,
  isWebRender = false,
  isMobileRender,
  ...rest
}) => {
  if (
    (isMobileRender && isHiddenInMobile) ||
    (isModalRender && isHiddenInModal) ||
    (isWebRender && isHiddenInWeb)
  ) {
    return null;
  }

  return (
    <View {...rest} testID="view-or-modal-block">
      {children}
    </View>
  );
};
