import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgKettle = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 19V6L3 2h15v3h2c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0120 14h-2v5H6zm2-2h8V4H7l1 1.3V17zm10-5h2V7h-2v5zm-6 4h3V5h-3v11zm-9 6v-2h18v2H3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgKettle;
