import React, { useMemo } from 'react';

import { View } from 'react-native';

import { Button, Spacer, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { PositioningStatus } from '@app/libs/apollo/introspection';
import { SuggestionsSourcePage } from '@app/services/tracking/constants';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
// eslint-disable-next-line max-len
import { RealEstatePreviewCardVertical } from '@app/shared/components/real-estate-preview-card/RealEstatePreviewCardVertical';

import { SuggestionCardProps } from './SuggestionCard.types';
import { getStyle } from './SuggestionCard.styles';

export const SuggestionCard: React.FC<SuggestionCardProps> = ({
  onApprovePositioning,
  onCancelPositioning,
  onNeedCallAfterConfirmPositioning,
  advisorName,
  afterConfirmCallLaunched,
  expirationDate,
  positioningStatus,
  maxWidth,
  ...realEstateCardProps
}) => {
  const { isLowerThanTablet } = useResponsive();
  const { t } = useTranslation();
  const theme = useTheme();

  const { stateOfCustomer } = useMe();

  const styles = getStyle({ isLowerThanTablet, maxWidth });

  const handleCancelPositioning = () => onCancelPositioning(SuggestionsSourcePage.SUGGESTIONS_PAGE);

  const handleNeedCallAfterConfirmPositioning = () =>
    onNeedCallAfterConfirmPositioning(SuggestionsSourcePage.SUGGESTIONS_PAGE);

  const InterestedButton = useMemo(() => {
    if (
      ![PositioningStatus.Recommendation, PositioningStatus.Pending].includes(positioningStatus)
    ) {
      return null;
    }

    const handleApprovePositioning = () =>
      onApprovePositioning(SuggestionsSourcePage.SUGGESTIONS_PAGE, true);

    const positioningIsPending = positioningStatus === PositioningStatus.Pending;

    const label = positioningIsPending
      ? stateOfCustomer === StateOfCustomer.POST_SIGNED_MANDATE
        ? 'suggestions.suggestionCard.expertNotified'
        : 'suggestions.suggestionCard.advisorNotified'
      : 'suggestions.suggestionCard.actions.interested';

    return (
      <>
        <Button
          onPress={handleApprovePositioning}
          variant="primary"
          disabled={positioningIsPending}
          iconName={positioningIsPending ? undefined : 'Phone'}
          iconPlacement="left"
          testID="suggestion-card-actions-button-interested"
        >
          {t(label)}
        </Button>
        <Spacer height={theme.spacing.SIZE_03} />
      </>
    );
  }, [onApprovePositioning, positioningStatus, stateOfCustomer, t, theme.spacing.SIZE_03]);

  const labelForNotInterestedButton =
    positioningStatus !== PositioningStatus.Recommendation
      ? 'suggestions.suggestionCard.actions.noLongerInterested'
      : 'suggestions.suggestionCard.actions.notInterested';

  const { onClick: onPress, onBookmark, ...realEstatePreview } = realEstateCardProps;

  return (
    <View style={styles.container} testID="suggestion-card-container">
      <RealEstatePreviewCardVertical
        realEstatePreview={{
          ...realEstatePreview,
          expirationDate: expirationDate ? new Date(expirationDate) : undefined,
          positioningStatus,
        }}
        onPress={onPress}
        onBookmark={onBookmark}
      />
      <Spacer height={theme.spacing.SIZE_04} />
      {InterestedButton}
      {positioningStatus === PositioningStatus.Confirmed ? (
        <Button
          onPress={handleNeedCallAfterConfirmPositioning}
          variant="secondary"
          iconName="Phone"
          iconPlacement="left"
          disabled={afterConfirmCallLaunched}
          testID="suggestion-card-actions-button-needCallAfterConfirm"
        >
          {t('suggestions.suggestionCard.actions.needCallAfterConfirm', { advisorName })}
        </Button>
      ) : (
        <Button
          onPress={handleCancelPositioning}
          variant="tertiary"
          iconName="ThumbsDown"
          iconPlacement="left"
          testID="suggestion-card-actions-button-notInterested"
        >
          {t(labelForNotInterestedButton)}
        </Button>
      )}
    </View>
  );
};
