import React from 'react';

import { View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';

import {
  SearchEngineMoreResultsList,
  SearchEngineMoreResultsListProps,
} from './SearchEngineMoreResultList';
import { getExtendedResultsList } from './SearchEngineMoreResults.utils';

type SearchEngineMoreResultsProps = Pick<
  SearchEngineMoreResultsListProps,
  'onItemBookmark' | 'onItemClick'
> & {
  withHigherBudgetList: SearchEngineMoreResultsListProps['list'];
  withNearbyList: SearchEngineMoreResultsListProps['list'];
};

export const SearchEngineMoreResults: React.FC<SearchEngineMoreResultsProps> = ({
  withHigherBudgetList,
  withNearbyList,
  onItemBookmark,
  onItemClick,
}) => {
  const { t } = useTranslation();

  const extendedResultsList = getExtendedResultsList({
    t,
    withHigherBudgetList,
    withNearbyList,
  });

  if (!extendedResultsList.length) {
    return null;
  }

  return (
    <View testID="search-engine-more-results-container">
      {extendedResultsList.map(({ list, title, resultsType }) => (
        <SearchEngineMoreResultsList
          resultsType={resultsType}
          key={title}
          title={title}
          list={list}
          onItemBookmark={onItemBookmark}
          onItemClick={onItemClick}
        />
      ))}
    </View>
  );
};
