import React, { useMemo } from 'react';

import { Platform, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useTranslation } from '@app/services/translations/translations';
import { Icon, Pressable, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { isWeb } from '@app/constants/platform';

interface GalleryHeaderProps {
  onClose: () => void;
}

const getStyles = ({ theme, isLowerThanLaptop }: { theme: Theme; isLowerThanLaptop: boolean }) =>
  StyleSheet.create({
    closeButton: {
      zIndex: 100,
    },
    header: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_04, // web style
      marginHorizontal: isLowerThanLaptop ? theme.spacing.SIZE_06 : 0,
      marginTop: theme.spacing.SIZE_10,
    },
    headerTitle: {
      color: theme.palette.base.white,
      flex: 1,
      marginRight: theme.spacing.SIZE_09, // mobile style
      textAlign: isLowerThanLaptop ? 'center' : 'left',
    },
  });

export const GalleryHeader: React.FC<GalleryHeaderProps> = ({ onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles({ isLowerThanLaptop, theme }), [theme, isLowerThanLaptop]);

  /* 
    There is a bug in the base react native modal with the last version of RNGH,
    A Pressable within a modal doesn't work on iOS, the onPress callback isn't called.
    https://www.reddit.com/r/reactnative/comments/15i5bwp/pressable_not_working_inside_react_native_modal/
  */
  const Button = Platform.OS !== 'ios' ? Pressable : TouchableOpacity;

  return (
    <View style={styles.header}>
      <Button onPress={onClose} style={styles.closeButton} testID="property-gallery-close-button">
        <Icon
          name={isWeb() ? 'ArrowLeft' : 'Cross'}
          size={isLowerThanLaptop ? theme.spacing.SIZE_08 : theme.spacing.SIZE_07}
          color={theme.palette.base.white}
        />
      </Button>

      <Text textStyle={isWeb() ? 'Title3Medium' : 'Headline2Medium'} style={styles.headerTitle}>
        {t('shared.gallery')}
      </Text>
    </View>
  );
};
