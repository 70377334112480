import React, { useEffect, useMemo } from 'react';

import { Animated, StyleSheet } from 'react-native';

import { useAnimation } from '@app/ui/utils/useAnimation';
import { Text } from '@app/ui/atoms/Text/Text';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { switchTheme } from '@app/ui/atoms/switch/switch.theme';
import { useTheme } from '@app/ui/contexts/config';

import type { SwitchProps } from './Switch.types';
import { styles as getStyles } from './Switch.styles';
import { Pressable } from '../pressable/Pressable';

export const Switch: React.FC<SwitchProps> = ({
  checked = false,
  onCheck,
  disabled = false,
  testID,
  label,
  size = 'm',
  style,
}) => {
  const theme = useTheme();

  const componentTheme = useComponentTheme('switch', switchTheme);

  const { setIsOn, interpolate } = useAnimation(checked);

  useEffect(() => {
    setIsOn(checked);
  }, [checked]);

  const themedStyles = getStyles(theme, size, componentTheme, disabled);

  const { backgroundColor, ...styles } = style ?? {};
  const checkedColor = useMemo(
    () =>
      disabled ? componentTheme.disabled.color : backgroundColor ?? componentTheme.checked.color,
    [disabled, backgroundColor]
  );

  const uncheckedColor = useMemo(
    () => (disabled ? componentTheme.disabled.color : componentTheme.unchecked.color),
    [disabled]
  );

  const containerWidth = componentTheme.sizes[size].width;
  const checkWidth = componentTheme.sizes[size].indicatorSize;

  const [translateX, animatedBackgroundColor] = interpolate(
    [3, containerWidth - checkWidth - 3],
    [uncheckedColor, checkedColor]
  );

  return (
    <Pressable
      onPress={() => onCheck?.(!checked)}
      testID={testID}
      style={StyleSheet.flatten([
        themedStyles.mainContainer,
        disabled ? themedStyles.disabledMainContainer : {},
        styles,
      ])}
      accessibilityRole="switch"
      accessibilityState={{ checked }}
      disabled={disabled}
      withPressOpacity={false}
    >
      <Animated.View style={[themedStyles.container, { backgroundColor: animatedBackgroundColor }]}>
        <Animated.View style={[themedStyles.check, { transform: [{ translateX }] }]} />
      </Animated.View>
      {label ? (
        <Text
          style={StyleSheet.flatten([componentTheme.sizes[size].textStyles, themedStyles.label])}
        >
          {label}
        </Text>
      ) : null}
    </Pressable>
  );
};
