import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgWardrobe = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 017 20v-4H5.4c-.65 0-1.213-.238-1.688-.713C3.237 14.812 3 14.25 3 13.6c0-.483.13-.93.388-1.338a2.362 2.362 0 011.062-.912L11 8.45V7.8a2.936 2.936 0 01-1.438-1.063A2.851 2.851 0 019 5c0-.85.288-1.563.863-2.138S11.15 2 12 2c.7 0 1.304.17 1.812.512.509.342.838.78.988 1.313.083.317.033.592-.15.825-.183.233-.458.35-.825.35-.233 0-.433-.054-.6-.163a1.71 1.71 0 01-.45-.437.785.785 0 00-.337-.3A1.02 1.02 0 0012 4a.967.967 0 00-.712.287A.968.968 0 0011 5c0 .283.096.521.288.713A.967.967 0 0012 6a.97.97 0 01.713.287A.97.97 0 0113 7v1.45l6.55 2.9c.45.2.804.504 1.063.912.258.409.387.855.387 1.338 0 .65-.237 1.212-.712 1.687-.475.475-1.038.713-1.688.713H17v4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0115 22H9zm-3.6-8h1.875c.217-.333.463-.583.738-.75.275-.167.604-.25.987-.25h6c.383 0 .713.083.988.25.275.167.52.417.737.75H18.6c.15 0 .254-.05.313-.15.058-.1.087-.2.087-.3a.36.36 0 00-.062-.213.414.414 0 00-.188-.137l-6.75-3-6.75 3a.414.414 0 00-.188.137.36.36 0 00-.062.213c0 .133.033.242.1.325.067.083.167.125.3.125zM9 20h6v-5H9v5z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgWardrobe;
