import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLink = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.417 16.515h-4c-1.383 0-2.562-.487-3.537-1.463-.975-.974-1.463-2.153-1.463-3.537 0-1.383.488-2.562 1.463-3.538.975-.974 2.154-1.462 3.537-1.462h4v2h-4c-.833 0-1.542.292-2.125.875a2.893 2.893 0 00-.875 2.125c0 .834.292 1.542.875 2.125a2.893 2.893 0 002.125.875h4v2zm-3-4v-2h8v2h-8zm5 4v-2h4c.833 0 1.542-.291 2.125-.875a2.893 2.893 0 00.875-2.125c0-.833-.292-1.541-.875-2.125a2.893 2.893 0 00-2.125-.875h-4v-2h4c1.383 0 2.563.488 3.538 1.462.975.976 1.462 2.155 1.462 3.538 0 1.384-.487 2.563-1.462 3.537-.975.976-2.155 1.463-3.538 1.463h-4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLink;
