import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { Device, Theme } from '@app/ui';

const getTextWidth = (device: Device): `${string}%` => {
  switch (device) {
    case 'desktop':
      return '60%';
    case 'laptop':
    case 'tablet':
    case 'mobile':
      return '90%';
    default:
      return '45%';
  }
};

export const getStyles = (
  theme: Theme,
  device: Device
): StyleSheet.NamedStyles<{
  container: ViewStyle;
  text: TextStyle;
  image: ViewStyle;
  imageMask: ViewStyle;
}> =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    image: {
      borderBottomRightRadius: theme.borderRadius.SIZE_06,
      borderColor: theme.palette.base.white,
      borderTopRightRadius: theme.borderRadius.SIZE_06,
      borderWidth: 1,
    },
    imageMask: {
      backgroundColor: theme.palette.base.white,
      height: '100%',
      opacity: 0.3,
      position: 'absolute',
      width: '100%',
    },
    text: {
      color: theme.palette.neutral[900],
      textAlign: 'center',
      width: getTextWidth(device),
    },
  });
