import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyle = ({ theme, isMobile }: { theme: Theme; isMobile: boolean }) =>
  StyleSheet.create({
    blockTitle: {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
    },
    child: {
      marginRight: theme.spacing.SIZE_06,
    },
    container: {
      alignItems: 'center',
      flex: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: isMobile ? theme.spacing.SIZE_06 : theme.spacing.SIZE_07,
      width: '100%',
    },
    icon: {
      flexBasis: 30,
      flexGrow: 0,
      flexShrink: 0,
    },
    subtitle: {
      color: theme.palette.neutral[600],
      overflow: 'hidden',
    },
    toggleIcon: {
      color: theme.palette.neutral[600],
    },
  });
