// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Icon } from '@app/ui/atoms/Icon';
import { RequiredThemes } from '@app/ui/theme/types';

import { Variant, ButtonSize, ButtonProps, IconPlacement } from '../Button.types';
interface IconProps {
  iconPlacement?: IconPlacement;
}

interface StyledButtonProps {
  variant: Variant;
  isInDark?: ButtonProps['isInDark'];
  style?: React.CSSProperties;
  componentTheme: RequiredThemes['button'];
  size: ButtonSize;
  iconPlacement?: IconPlacement;
}

export const StyledIcon = styled(Icon)<IconProps>`
 ${({ iconPlacement, theme }) =>
   iconPlacement === 'right'
     ? css`
         margin-left: ${theme.spacing.SIZE_03}px;
       `
     : css`
         margin-right: ${theme.spacing.SIZE_03}px;
       `}
  }
`;

export const ButtonContainer = styled.button<StyledButtonProps>`
  height: ${({ componentTheme, size }) => componentTheme.size[size].height}px;
  border-radius: ${({ componentTheme, size }) => componentTheme.size[size].radius}px;
  padding-left: ${({ componentTheme, size }) => componentTheme.size[size].paddingX}px;
  padding-right: ${({ componentTheme, size }) => componentTheme.size[size].paddingX}px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: ${({ variant, isInDark, componentTheme }) =>
    variant !== 'tertiary'
      ? 'none'
      : isInDark
      ? `${componentTheme.tertiary.inDark.borderWidth}px solid ${componentTheme.tertiary.inDark.borderColor.default}`
      : `${componentTheme.tertiary.inLight.borderWidth}px solid ${componentTheme.tertiary.inLight.borderColor.default}`};
  background-color: ${({ variant, isInDark, componentTheme }) =>
    isInDark
      ? componentTheme[variant].inDark.background.default
      : componentTheme[variant].inLight.background.default};

  &:disabled {
    pointer-events: none;
    background-color: ${({ variant, isInDark, componentTheme }) =>
      isInDark
        ? componentTheme[variant].inDark.background.disabled
        : componentTheme[variant].inLight.background.disabled};
    border: none;
  }
  &:hover {
    background-color: ${({ variant, isInDark, componentTheme }) =>
      isInDark
        ? componentTheme[variant].inDark.background.hover
        : componentTheme[variant].inLight.background.hover};
    border: ${({ variant, isInDark, componentTheme }) =>
      variant !== 'tertiary'
        ? 'none'
        : isInDark
        ? `${componentTheme.tertiary.inDark.borderWidth}px solid ${componentTheme.tertiary.inDark.borderColor.hover}`
        : `${componentTheme.tertiary.inLight.borderWidth}px solid ${componentTheme.tertiary.inLight.borderColor.hover}`};
  }
  &:hover p {
    color: ${({ variant, isInDark, componentTheme }) =>
      isInDark
        ? componentTheme[variant].inDark.text.hover
        : componentTheme[variant].inLight.text.hover};
  }
  &:active {
    background-color: ${({ variant, isInDark, componentTheme }) =>
      isInDark
        ? componentTheme[variant].inDark.background.active
        : componentTheme[variant].inLight.background.active};
    border: ${({ variant, isInDark, componentTheme }) =>
      variant !== 'tertiary'
        ? 'none'
        : isInDark
        ? `${componentTheme.tertiary.inDark.borderWidth}px solid ${componentTheme.tertiary.inDark.borderColor.active}`
        : `${componentTheme.tertiary.inLight.borderWidth}px solid ${componentTheme.tertiary.inLight.borderColor.active}`};
  }

  & div svg path,
  &:hover div svg path,
  &:active div svg path {
    fill: ${({ componentTheme, variant, isInDark, disabled }) => {
      const isDisabled = disabled ? 'disabled' : 'default';
      return isInDark
        ? componentTheme[variant].inDark.text[isDisabled]
        : componentTheme[variant].inLight.text[isDisabled];
    }};
  }
`;

interface LabelContainerProps {
  style?: React.CSSProperties;
  iconPlacement?: IconPlacement;
}

interface LabelProps {
  variant: Variant;
  isInDark?: ButtonProps['isInDark'];
  style?: React.CSSProperties;
  componentTheme: RequiredThemes['button'];
  size: ButtonSize;
  disabled: boolean;
  textFont: 'Button1' | 'Button2' | 'Button3';
}

export const LabelContainer = styled.div<LabelContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ iconPlacement }) => iconPlacement === 'left' && 'row-reverse'};
`;

export const Label = styled.div<LabelProps>`
  color: ${({ componentTheme, variant, isInDark, disabled }) => {
    const isDisabled = disabled ? 'disabled' : 'default';
    return isInDark
      ? componentTheme[variant].inDark.text[isDisabled]
      : componentTheme[variant].inLight.text[isDisabled];
  }};
  font-family: ${({ theme, textFont }) => theme.textStyles[textFont].fontFamily};
  font-size: ${({ theme, textFont }) => theme.textStyles[textFont].fontSize}px;
  font-weight: ${({ theme, textFont }) => theme.textStyles[textFont].fontWeight};
  line-height: ${({ theme, textFont }) => theme.textStyles[textFont].lineHeight}px;
  letter-spacing: ${({ theme, textFont }) => theme.textStyles[textFont].letterSpacing}px;
`;
