import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgSh = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="sh_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#sh_svg__a)">
        <Path fill="url(#sh_svg__paint0_linear_4223_11834)" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.333 10V6.666c0-.737.597-1.333 1.334-1.333h4c.736 0 1.333.596 1.333 1.333V10c0 3.333-3.333 4.667-3.333 4.667S17.333 13.333 17.333 10z"
          fill="#8FC5FF"
        />
        <Mask
          id="sh_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={17}
          y={5}
          width={7}
          height={10}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.333 10V6.666c0-.737.597-1.333 1.334-1.333h4c.736 0 1.333.596 1.333 1.333V10c0 3.333-3.333 4.667-3.333 4.667S17.333 13.333 17.333 10z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#sh_svg__b)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.09 11.884s.667-.864 1.06-.864c.392 0 .464.573 1.068.573.603 0 .922-.914 1.503-.914.581 0 0 3 0 3l-.788.812-4.611.533 1.768-3.14z"
            fill="#366CC9"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.126 10.551l.589-.551.578 1.416.776-1.416.668 4.035-1.11-.226L16 12.152l2.126-1.6z"
            fill="#CF6200"
          />
          <Path fill="#FF0" d="M17.333 5.333H24v4h-6.667z" />
        </G>
        <Path
          d="M17.667 10V6.666a1 1 0 011-1h4a1 1 0 011 1V10c0 1.543-.768 2.626-1.557 3.336a6.258 6.258 0 01-1.443.966h-.001a6.25 6.25 0 01-1.443-.966c-.788-.71-1.556-1.793-1.556-3.336z"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Mask
          id="sh_svg__c"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={17}
          y={5}
          width={7}
          height={10}
        >
          <Path
            d="M17.667 10V6.666a1 1 0 011-1h4a1 1 0 011 1V10c0 1.543-.768 2.626-1.557 3.336a6.258 6.258 0 01-1.443.966h-.001a6.25 6.25 0 01-1.443-.966c-.788-.71-1.556-1.793-1.556-3.336z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.667}
          />
        </Mask>
        <Path
          d="M0-.333h-.901l.684.586 4.55 3.9v1.009L-.194 8.395l-.14.1v1.44l.51-.319L5.43 6.333h1.13l4.523 3.23a.554.554 0 00.682-.87L7.667 5.18V4.172l4.387-3.134a.667.667 0 00.28-.543V-.6l-.51.318L6.57 3h-1.13L.86-.271.772-.333H0z"
          fill="#DB1E36"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.333V6h4.667v3.333c0 .369.298.667.666.667h1.334a.667.667 0 00.666-.667V6H12a.667.667 0 00.667-.667V4A.667.667 0 0012 3.333H7.333V0H4.667v3.333H0z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h5.333V0h1.334v4H12v1.333H6.667v4H5.333v-4H0V4z"
          fill="#DB1E36"
        />
      </G>
      <Defs>
        <LinearGradient
          id="sh_svg__paint0_linear_4223_11834"
          x1={0}
          y1={0}
          x2={0}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#07319C" />
          <Stop offset={1} stopColor="#00247E" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgSh;
