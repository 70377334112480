import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bz_svg__a)" clipRule="evenodd">
        <Path fillRule="evenodd" d="M0 17.333h28V2.667H0v14.666z" fill="#094995" />
        <Path fillRule="evenodd" d="M0 2.667h28V0H0v2.667zM0 20h28v-2.667H0V20z" fill="#D5182F" />
        <Path fillRule="evenodd" d="M14 16a6 6 0 100-12 6 6 0 000 12z" fill="#fff" />
        <Path
          d="M14 14a4 4 0 100-8 4 4 0 000 8z"
          stroke="#118014"
          strokeWidth={1.333}
          strokeLinecap="round"
          strokeDasharray="0.67 2"
        />
      </G>
    </Svg>
  );
};

export default SvgBz;
