import React, { useContext, useEffect, useMemo, useState } from 'react';

import { StyleSheet, View } from 'react-native';
import { Trans } from 'react-i18next';
import { startCase } from 'lodash';

import { Button, LoaderDefault, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { WebView } from '@app/shared/components/WebView/WebView';
import { trackMandateSignatureDone } from '@app/services/tracking/trackTracking';
import { storage } from '@app/services/storage/storage';
import { SignatureModalProps } from '@app/shared/components/Modals/in-app-signature/InAppSignatureModal.types';
import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { useTranslation } from '@app/services/translations/translations';
import { useUpdateSearchMandateStatusMutation } from '@app/libs/apollo/introspection';
import { getApolloClient } from '@app/libs/apollon/apollonClient';
import { useMe } from '@app/shared/hooks/useMe';
import { isWeb } from '@app/constants/platform';

// @todo: Hack to handle message from iframe => webView, to remove when Pandadoc will fix the issue
const injectedJs = `
  window.addEventListener("message", receiveMessage, false);
  function receiveMessage(event) {
    window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
  };
`;

const getStyles = ({
  canShownButton,
  isLowerThanLaptop,
  theme,
}: {
  canShownButton: boolean;
  isLowerThanLaptop: boolean;
  theme: Theme;
}) => {
  const contentHeight = isWeb()
    ? { height: `calc(100vh - 160px - ${isLowerThanLaptop ? '100px' : '50px'})` }
    : { flexGrow: 1 };
  return StyleSheet.create({
    button: { marginTop: 20 },
    loaderWrapper: {
      ...contentHeight,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    titleColor: { color: theme.palette.system.primary[500] },
    webView: canShownButton ? contentHeight : {},
  });
};

/**
 * @description Step 2/3 of Sign in process
 */
export const SignInModal: React.FC<SignatureModalProps> = ({
  createPandadocLink,
  handleNextStep,
}) => {
  const [updateSearchMandateStatus] = useUpdateSearchMandateStatusMutation();
  const [canShownButton, setCanShownButton] = useState<boolean>(false);
  const theme = useTheme();
  const { cache } = getApolloClient();
  const { customer, refetch: refetchMe } = useMe();
  const { isLowerThanLaptop } = useResponsive();
  const { setTitle } = useContext(ModalContext);
  const { t } = useTranslation();

  const firstName = startCase(customer.firstName);

  const styles = useMemo(
    () => getStyles({ canShownButton, isLowerThanLaptop, theme }),
    [canShownButton, isLowerThanLaptop, theme]
  );

  const onMessage = async pandaDocData => {
    const { type, payload } = pandaDocData ?? {};

    switch (type) {
      case 'session_view.document.completed':
        handleNextStep();

        try {
          // Old hack to update mandate state in back ? To remove ?
          await updateSearchMandateStatus();
          cache.modify({
            fields: { searchMandate: mandate => ({ ...mandate, valid: true }) },
            id: cache.identify({ __typename: 'Customer', id: customer.id }),
          });
          trackMandateSignatureDone();
          refetchMe();
          setTitle(
            <Text textStyle="Title3Medium">
              <Trans
                i18nKey="modalMandateSignatureCongrats.title"
                values={{ firstName }}
                components={{
                  color: <Text textStyle="Title3Medium" style={styles.titleColor} />,
                }}
              />
            </Text>
          );
        } catch (error) {
          ErrorMonitoring.logError(error as string, { payload });
        }

        await storage.clear('signMandateReminder');

        setCanShownButton(true);
        break;
      case 'session_view.document.exception':
        ErrorMonitoring.logError(new Error('Session_view document exception'), { payload });
    }
  };

  useEffect(() => {
    // reset button if link changes
    setCanShownButton(false);
  }, [createPandadocLink]);

  return (
    <>
      {createPandadocLink?.sessionId ? (
        <WebView
          withLoader={false}
          onMessage={onMessage}
          uri={`https://app.pandadoc.com/s/${createPandadocLink?.sessionId}`}
          testID="sign-search-mandate-web-view"
          styles={styles.webView}
          injectedJavaScript={injectedJs}
        />
      ) : (
        <View style={styles.loaderWrapper}>
          <LoaderDefault variant="inLight" />
        </View>
      )}
      {!!canShownButton && (
        <Button testID="continue-btn-testID" onPress={handleNextStep} style={styles.button}>
          {t('shared.continue')}
        </Button>
      )}
    </>
  );
};
