import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgEdit = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4.807 18.95h1.4l8.625-8.625-1.4-1.4-8.625 8.625v1.4zm14.3-10.075l-4.25-4.2 1.4-1.4A1.92 1.92 0 0117.67 2.7a1.92 1.92 0 011.412.575l1.4 1.4c.384.383.584.846.6 1.388a1.807 1.807 0 01-.55 1.387l-1.425 1.425zM3.807 20.95a.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-2.825a1.03 1.03 0 01.3-.725l10.3-10.3 4.25 4.25-10.3 10.3a1 1 0 01-.725.3H3.807z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgEdit;
