import React, { useEffect, useRef } from 'react';

import { Animated, StyleProp, ViewStyle } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { isNative } from '@app/constants/platform';

import { useTheme } from '../contexts/config';

export const RotatingChevron: React.FC<{
  opened: boolean;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  color?: string;
  size?: number;
  chevronType?: 'ListChevronDown' | 'ChevronDown';
}> = ({ opened, disabled, style = {}, color, size = 16, chevronType = 'ListChevronDown' }) => {
  const rotateValue = useRef(new Animated.Value(0)).current;

  const theme = useTheme();

  useEffect(() => {
    Animated.timing(rotateValue, {
      duration: 200,
      toValue: opened ? 1 : 0,
      useNativeDriver: isNative(),
    }).start();
  }, [opened]);

  const spin = rotateValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const colorIcon = color || theme.palette.base.black;

  return (
    <Animated.View
      style={[
        {
          marginLeft: 12,
          transform: [{ rotate: spin }],
        },
        style,
      ]}
    >
      <Icon
        name={chevronType}
        color={disabled ? theme.palette.neutral[500] : colorIcon}
        width={size}
        height={size}
      />
    </Animated.View>
  );
};
