import React, { useState } from 'react';

import { TranslationSwitch } from '@app/ui/molecules/translation-switch/TranslationSwitch';
import { TranslationContent } from '@app/ui/atoms/translation-content/TranslationContent';

import { TranslatedContentProps, TranslatedContentContentType } from './TranslatedContent.types';

export { TranslatedContentProps, TranslatedContentContentType };

export const TranslatedContent: React.FC<TranslatedContentProps> = ({
  translation,
  onHandleDone,
  translatedBy,
  showOriginal,
  showTranslated,
  textStyle,
  dark,
  numberOfLines,
}) => {
  const { translatedText, targetLang, sourceLang } = translation;
  const canTranslate = !!translatedText;
  const [showTranslation, setShowTranslation] = useState<boolean>(canTranslate);

  const handleChangeLang = () => {
    setShowTranslation(!showTranslation);
    const contentType = showTranslation
      ? TranslatedContentContentType.Target
      : TranslatedContentContentType.Source;
    onHandleDone?.(contentType, sourceLang, targetLang);
  };

  return (
    <>
      <TranslationContent
        showTranslation={showTranslation}
        translation={translation}
        textStyle={textStyle}
        dark={dark}
        numberOfLines={numberOfLines}
      />
      {canTranslate ? (
        <TranslationSwitch
          showTranslation={showTranslation}
          handleChangeLang={handleChangeLang}
          translatedBy={translatedBy}
          showOriginal={showOriginal}
          showTranslated={showTranslated}
          dark={dark}
        />
      ) : null}
    </>
  );
};
