import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="gm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#gm_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 6.667h28V0H0v6.667z" fill="#DF2237" />
        <Path d="M0 20h28v-6.667H0V20z" fill="#4B9334" />
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="#fff" />
        <Path d="M0 12h28V8H0v4z" fill="#1526A7" />
      </G>
    </Svg>
  );
};

export default SvgGm;
