import React from 'react';

import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { FeatureFlags } from '@app/constants/feature-flags';

import { DebugMenuHeader } from './compounds/DebugMenuHeader';
import { DebugMenuWrapper } from './compounds/DebugMenuWrapper';
import { DebugMenuContentWrapper } from './compounds/DebugMenuContentWrapper';
import { DebugMenuUnleashFlag } from './DebugMenuUnleashFlag';

export const DebugMenuUnleashFeatureFlags: React.FC = () => {
  const navigation = useNavigation();

  return (
    <DebugMenuWrapper>
      <DebugMenuHeader title="Unleash Feature Flags" onPress={navigation.goBack} />

      <ScrollView>
        <DebugMenuContentWrapper>
          {Object.values(FeatureFlags).map(featureFlag => (
            <DebugMenuUnleashFlag featureFlag={featureFlag} key={featureFlag} />
          ))}
        </DebugMenuContentWrapper>
      </ScrollView>
    </DebugMenuWrapper>
  );
};
