import { ListItemUnionProps } from '@app/ui';
import { ChosenOption } from '@app/ui/organisms/select/Select.types';

export const stringToSelect = (str: string, label = str): ListItemUnionProps => ({
  key: str,
  label,
  value: str,
});

const selectToString = (item: ListItemUnionProps): string =>
  item.value === null ? null : item.value?.toString() ?? item.key;

export const handleSelectChange: (
  onChange: (nextState: string) => void
) => (target: ListItemUnionProps) => void = onChange => target => onChange(selectToString(target));

export const handleMultipleSelectChange: (
  onChange: (nextList: string[]) => void,
  list: ListItemUnionProps[]
) => (target: ChosenOption | ChosenOption[]) => void = (onChange, list) => target => {
  if (Array.isArray(target)) {
    return onChange(target.map(selectToString));
  }

  const value = target.value?.toString();

  const nextList = list.map(selectToString);

  if (!value) {
    return onChange([]);
  }
  onChange(nextList.includes(value) ? nextList.filter(v => v !== value) : [...nextList, value]);
};
