import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTl = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tl_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tl_svg__a)">
        <Path fill="#FF323E" d="M0 0h28v20H0z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 0l17.333 10L0 20V0z" fill="#FFCC51" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 0l12 10L0 20V0z" fill="#262626" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.944 10.824l-.884 1.94-.462-2.081-2.118-.242L4.316 9.36l-.424-2.088L5.49 8.682l1.855-1.049-.85 1.955 1.571 1.44-2.121-.204z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgTl;
