import React from 'react';

import { View, ViewStyle } from 'react-native';

import { Icon, IconName } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';
import { Tint } from '@app/ui/theme/palette';
import { Button } from '@app/ui/molecules/Button';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Text } from '@app/ui/atoms/Text/Text';

import { getCalloutWithButtonStyle } from './CalloutWithButton.styles';

export type CalloutWithButtonProps = {
  text: string;
  wrapperStyle?: ViewStyle;
  icon?: IconName;
  tint?: Tint;
  testID?: string;
  buttonText: string;
  onButtonPress: () => void;
  iconName: IconName;
  children?: React.ReactNode;
};

export const CalloutWithButton: React.FC<CalloutWithButtonProps> = ({
  text,
  wrapperStyle,
  icon,
  tint,
  testID,
  onButtonPress,
  children,
  buttonText,
  iconName,
}) => {
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();

  const _tint = tint ?? theme.palette.tint.blue;

  const style = getCalloutWithButtonStyle({
    isLowerThanDesktop,
    theme,
    tint: _tint,
  });

  return (
    <View style={[style.container, wrapperStyle]} testID={testID}>
      <View style={style.textContainer}>
        <View style={style.titleContainer}>
          <View style={style.iconContainer}>
            <Icon
              name={icon ?? 'Help'}
              size={theme.spacing.SIZE_05}
              color={_tint[700]}
              testID="callout-icon"
            />
          </View>

          <Text style={style.textStyle} textStyle="Caption2Medium">
            {text}
          </Text>
        </View>
        {children}
      </View>

      <Button
        onPress={onButtonPress}
        size="s"
        variant="secondary"
        iconName={iconName}
        iconPlacement="left"
        testID="callout-button"
      >
        {buttonText}
      </Button>
    </View>
  );
};
