import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGq = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="gq_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#gq_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20z" fill="#EC3938" />
        <Path d="M0 6.667h28V0H0v6.667z" fill="#51B422" />
        <Path
          d="M13.607 11.715a.168.168 0 00.047-.115l.011-1.973a.292.292 0 00-.292-.294.292.292 0 01-.207-.499l.598-.598c.13-.13.341-.13.472 0l.597.597c.185.185.054.5-.207.5a.293.293 0 00-.293.293v1.972c0 .044.018.086.05.117a.167.167 0 01-.119.285h-.536a.168.168 0 01-.121-.285z"
          fill="#159940"
        />
        <Path d="M0 0l9.333 10L0 20V0z" fill="#258DE7" />
      </G>
    </Svg>
  );
};

export default SvgGq;
