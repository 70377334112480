import React from 'react';

import { View } from 'react-native';

import { Text, useTheme } from '@app/ui';
import { TranslatableWithSwitchWrapper } from '@app/shared/components/TranslatableWithSwithWrapper/TransalatableWithSwitchWrapper';
import { RealEstateSynthesisItem } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';

interface RealEstateSynthesisProps {
  synthesis: RealEstateSynthesisItem[];
  children?: React.ReactNode;
}

export const RealEstateSynthesisWrapper: React.FC<RealEstateSynthesisProps> = ({
  synthesis,
  children,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hasTranslatedText = synthesis.some(({ translation }) => !!translation?.translatedText);

  if (!synthesis.length) {
    return null;
  }

  return (
    <TranslatableWithSwitchWrapper hasTranslatedText={hasTranslatedText}>
      <View style={{ gap: theme.spacing.SIZE_07 }}>
        <Text textStyle="Headline1Medium">{t('propertyDescription.propertyPros')}</Text>
        {children}
      </View>
    </TranslatableWithSwitchWrapper>
  );
};
