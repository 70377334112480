import React from 'react';

import { View } from 'react-native';

import { Spacer, Spacing, Text } from '@app/ui';
import { isNative } from '@app/constants/platform';

interface RealEstateTabTitleProps {
  title: string;
}

export const RealEstateTabTitle: React.FC<RealEstateTabTitleProps> = ({ title }) => {
  return (
    <View>
      {isNative() && <Spacer height={Spacing.SIZE_09} />}
      <Text textStyle="Headline1Medium">{title}</Text>
      <Spacer height={Spacing.SIZE_07} />
    </View>
  );
};
