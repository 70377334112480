import React from 'react';

import { Card, palette, Text } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { styles } from './projectCard.styles';
import { ProjectCardTitle } from './ProjectCardTitle';
import { ProjectListCardProps } from './projectCard.types';
import { getProjectCardPreviewUrl } from './projectCard.utils';

export const FinishedProjectCard: React.FC<ProjectListCardProps> = ({
  address,
  coverImage,
  title,
  onPress,
}) => {
  const { t } = useTranslation();

  return (
    <Card onPress={onPress} testID="finished-project-card">
      <Card.Header>
        <Card.Chip
          position="topright"
          text={t('projectList.status.finished')}
          iconName="CheckCircle"
          textColor={palette.base.black}
          backgroundColor={palette.base.white}
        />
        <Card.Image imageUri={getProjectCardPreviewUrl(coverImage)} />
      </Card.Header>
      <Card.Content>
        <Card.Block withLine>
          <ProjectCardTitle address={address} realEstateTitle={title} />
        </Card.Block>
        <Card.Block>
          <Text textStyle="Body3" style={styles.italicText}>
            {t('projectList.finishedProjectMessage')}
          </Text>
        </Card.Block>
      </Card.Content>
    </Card>
  );
};
