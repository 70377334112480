import { merge } from 'lodash';

import { storage } from '@app/services/storage/storage';

import { OnboardingMachineContext } from './stateMachine.types';

// TODO: rename 'data' properly, isn't there a saved state machine interface... ?

/**
 * Save the context of the state machine to the Universal Storage
 * @param context - The context of the State Machine
 */
export async function saveStateMachineToStorage(context: OnboardingMachineContext): Promise<void> {
  const data = await storage.readObject<OnboardingMachineContext>('masteos.onboarding');
  const record = {
    email: context.email || '',
    firstName: context.firstName || '',
    lastName: context.lastName || '',
  } as const;

  await storage.saveObject('masteos.onboarding', merge(data, record));
}
