import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { MainMenuNavigatorRoutes } from '@app/navigation/types/routes';
import { isAssetManagementConsultant } from '@app/utils/customer.utils';

export function filterNavigationLinks<T extends { key: MainMenuNavigatorRoutes } & object>(
  navigationLinks: T[],
  customer: GetCustomerQuery['me']
): T[] {
  const keysToDelete: string[] = [];

  if (isAssetManagementConsultant(customer)) {
    keysToDelete.push(MainMenuNavigatorRoutes.Projects);
  }

  return navigationLinks.filter(({ key }) => !keysToDelete.includes(key));
}
