import {
  SearchEngineFilterForm,
  SearchEngineFilterFormProps,
} from '@app/features/search-engine/searchEngine.types';

export const getInitialFormValues = (
  props: SearchEngineFilterFormProps,
  defaultValues: SearchEngineFilterForm
): SearchEngineFilterForm => ({
  alreadyRented: props.alreadyRented ?? defaultValues.alreadyRented,
  atGroundFloor: props.atGroundFloor ?? defaultValues.atGroundFloor,
  budgetMax: props.budgetMax ?? defaultValues.budgetMax,
  budgetMin: props.budgetMin ?? defaultValues.budgetMin,
  country: props.country ?? defaultValues.country,
  flatSharing: props.flatSharing ?? defaultValues.flatSharing,
  regionList: props.regionList ?? defaultValues.regionList,
  roomCount: props.roomCount ?? defaultValues.roomCount,
  sortStrategy: props.sortStrategy ?? defaultValues.sortStrategy,
  typology: props.typology ?? defaultValues.typology,
  withRenovation: props.withRenovation ?? defaultValues.withRenovation,
  withShortTermRental: props.withShortTermRental ?? defaultValues.withShortTermRental,
  zones: props.zones ?? defaultValues.zones,
});
