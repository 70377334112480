import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCookie = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10.563 9.885c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062a1.444 1.444 0 00-1.062-.438c-.417 0-.77.146-1.062.438a1.444 1.444 0 00-.438 1.062c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zm-2 5c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062a1.444 1.444 0 00-1.062-.438c-.417 0-.77.146-1.062.438a1.444 1.444 0 00-.438 1.062c0 .417.146.77.438 1.062.291.292.645.438 1.062.438zm6.5 1a.968.968 0 00.713-.288.967.967 0 00.287-.712.97.97 0 00-.287-.713.97.97 0 00-.713-.287.967.967 0 00-.712.287.968.968 0 00-.288.713c0 .283.096.52.288.712a.965.965 0 00.712.288zm-3 6a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175a9.733 9.733 0 01-.788-3.9c0-1.35.28-2.663.838-3.938a10.566 10.566 0 012.325-3.362c.991-.967 2.17-1.7 3.537-2.2 1.367-.5 2.867-.65 4.5-.45.25.033.442.137.575.312.133.175.208.413.225.713a3.912 3.912 0 001.188 2.737c.758.759 1.662 1.155 2.712 1.188.35.017.617.117.8.3.183.183.283.467.3.85.033.7.246 1.275.638 1.725.391.45.937.792 1.637 1.025.233.083.412.204.537.362a.92.92 0 01.188.588 9.454 9.454 0 01-.725 3.925 10.295 10.295 0 01-2.125 3.237 9.884 9.884 0 01-3.2 2.188c-1.233.533-2.55.8-3.95.8zm0-2c2.033 0 3.838-.7 5.413-2.1 1.575-1.4 2.437-3.183 2.587-5.35-.833-.367-1.487-.867-1.962-1.5a5.354 5.354 0 01-.963-2.125 5.688 5.688 0 01-3.3-1.65 5.961 5.961 0 01-1.7-3.3c-1.333-.033-2.504.208-3.513.725-1.008.517-1.85 1.179-2.524 1.987A8.666 8.666 0 004.576 9.21c-.342.95-.513 1.842-.513 2.675 0 2.217.78 4.104 2.338 5.663 1.558 1.558 3.445 2.337 5.662 2.337z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCookie;
