import { Route, useNavigationState } from '@react-navigation/native';

export function useCurrentRoute(): Route<string> | null | any {
  return useNavigationState(extractCurrentRoute);
}

export const extractCurrentRoute = state => {
  const numberOfRoutes = state?.routes.length;

  if (numberOfRoutes > 0) {
    return state.routes[numberOfRoutes - 1];
  }
  return null;
};
