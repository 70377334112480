import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgEuro = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M16 21c-1.967 0-3.717-.558-5.25-1.675S8.133 16.767 7.5 15H4a.965.965 0 01-.712-.288A.965.965 0 013 14c0-.283.096-.521.288-.713A.967.967 0 014 13h3.05a2.817 2.817 0 01-.05-.5V12v-.5c0-.15.017-.317.05-.5H4a.965.965 0 01-.712-.288A.965.965 0 013 10c0-.283.096-.521.288-.713A.967.967 0 014 9h3.5c.633-1.767 1.717-3.208 3.25-4.325S14.033 3 16 3c.917 0 1.788.125 2.613.375a9 9 0 012.312 1.075c.3.2.463.483.488.85.025.367-.105.692-.388.975-.2.2-.454.317-.762.35a1.413 1.413 0 01-.888-.2 7.56 7.56 0 00-1.6-.675A6.258 6.258 0 0016 5.5c-1.25 0-2.387.321-3.412.963A6.33 6.33 0 0010.25 9H16a.97.97 0 01.713.287A.97.97 0 0117 10c0 .283-.096.52-.287.712A.968.968 0 0116 11H9.6c-.033.183-.058.35-.075.5-.017.15-.025.317-.025.5s.008.35.025.5c.017.15.042.317.075.5H16a.97.97 0 01.713.287A.97.97 0 0117 14c0 .283-.096.52-.287.712A.968.968 0 0116 15h-5.75a6.338 6.338 0 002.338 2.538c1.025.641 2.162.962 3.412.962.6 0 1.192-.083 1.775-.25a6.895 6.895 0 001.6-.675c.283-.167.575-.238.875-.213.3.025.558.146.775.363.283.283.413.608.388.975-.025.367-.188.65-.488.85a8.999 8.999 0 01-2.312 1.075A8.965 8.965 0 0116 21z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgEuro;
