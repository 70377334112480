import React from 'react';

import { EmptyText, TableView } from '@app/ui/organisms/table/Table.styles';
import { TableHeader } from '@app/ui/molecules/table-header/TableHeader';
import { TableRow } from '@app/ui/molecules/table-row/TableRow';
import { useTheme } from '@app/ui/contexts/config';
import { Text } from '@app/ui/atoms/Text/Text';

import { TableProps } from './Table.types';

export { TableProps };

export const Table = <T,>({
  columns,
  rowKey,
  rows,
  emptyDescription,
}: TableProps<T>): JSX.Element => {
  const theme = useTheme();

  return (
    <TableView columns={columns}>
      <TableHeader columns={columns} />
      {rows.length > 0 ? (
        rows.map(row => (
          <TableRow key={`${row[rowKey]}_rows`} rowKey={rowKey} columns={columns} row={row} />
        ))
      ) : (
        <EmptyText theme={theme} columnsLength={columns.length}>
          <Text textStyle="Caption1">{emptyDescription}</Text>
        </EmptyText>
      )}
    </TableView>
  );
};
