import {
  AnalyticsBrowser,
  AnalyticsBrowserSettings,
  InitOptions,
  JSONObject,
} from '@segment/analytics-next';
import { makeVar } from '@apollo/client';
import { v4 } from '@lukeed/uuid';

import { log } from '@app/utils/logs/log';
import { getCookieValueByName } from '@app/utils/cookie';

import { Source } from './segment.type';

let client: AnalyticsBrowser;
const segmentIntegrations: Record<string, boolean | JSONObject | undefined> = {};

const identifiedUser = makeVar({});

function configureSegmentIntegrations() {
  // set the session_id for amplitude
  // fix sessionId -1 on amplitude: see https://segment.com/docs/connections/destinations/catalog/amplitude/
  const amplitudeIntegration: JSONObject = {
    // eslint-disable-next-line no-magic-numbers
    session_id: Math.floor(Date.now() / 1000),
  };

  // map Amplitude for legacy reason
  segmentIntegrations['Amplitude'] = amplitudeIntegration;
  // map on Actions Amplitude for analytics.js 2.0
  segmentIntegrations['Actions Amplitude'] = amplitudeIntegration;
}

export function initializeSegment({
  apiKey,
  customCdnHost,
  customApiHost,
}: {
  apiKey: string;
  customCdnHost?: string;
  customApiHost?: string;
}): void {
  const analyticsOptions: InitOptions = {
    integrations: {},
  };
  const analyticsSettings: AnalyticsBrowserSettings = {
    writeKey: apiKey,
  };

  configureSegmentIntegrations();

  if (customApiHost) {
    analyticsOptions.integrations['Segment.io'] = {
      apiHost: customApiHost,
    };
  }

  if (customCdnHost) {
    analyticsSettings.cdnURL = customCdnHost;
  }

  client = AnalyticsBrowser.load(analyticsSettings, analyticsOptions);
}

export const track = (
  eventName: string,
  data: Record<string, string | number | boolean> = {}
): void => {
  const uuid = v4();
  const newData = {
    event_id: uuid,
    order_id: uuid,
    ...data,
    source: Source.Apollon,
  };
  log('[Tracking] Track', { data: newData, eventName });

  client?.track(eventName, newData, {
    integrations: segmentIntegrations,
  });
};

export const trackWithFacebookData = (
  eventName: string,
  data: Record<string, string | number | boolean> = {}
): void => {
  const common = {
    fbc: getCookieValueByName('_fbc'),
    fbp: getCookieValueByName('_fbp'),
  };

  const newData = {
    ...common,
    ...identifiedUser(),
    ...data,
  };

  track(eventName, newData);
};

export const identify = (
  userId: string,
  data: Record<string, string | number | boolean> = {}
): void => {
  log('[Tracking] Identify', { data, userId });

  identifiedUser(data);

  client?.identify(userId, data, {
    integrations: segmentIntegrations,
  });
};

export const page = (
  pageName: string,
  data: Record<string, string | number | boolean> = {}
): void => {
  log('[Tracking] Page', { data, pageName });

  client?.page(pageName, data, {
    integrations: segmentIntegrations,
  });
};

export const reset = (): void => {
  log('[Tracking] Reset');
  client?.reset();
};
