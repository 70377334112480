import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgTo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="to_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#to_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M28 0H16v10.667H0V20h28V0z" fill="#E20F1B" />
        <Path
          d="M7 1.333a.333.333 0 00-.333.334V4H4.333A.333.333 0 004 4.333v2c0 .184.15.334.333.334h2.334V9c0 .184.149.333.333.333h2c.184 0 .333-.149.333-.333V6.667h2.334c.184 0 .333-.15.333-.334v-2A.333.333 0 0011.667 4H9.333V1.667A.333.333 0 009 1.333H7z"
          fill="url(#to_svg__paint0_linear_4223_11948)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="to_svg__paint0_linear_4223_11948"
          x1={4}
          y1={1.333}
          x2={4}
          y2={9.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DF101B" />
          <Stop offset={1} stopColor="#C00711" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgTo;
