import React from 'react';

import { StyleSheet, View } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { DataBlock, Spacing, useResponsive, IconPopoverOrModal, Theme, useTheme } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackTooltip } from '@app/services/tracking/trackTracking';

import { areEqual, differentFuncParameters } from '../utils/realEstateFinances.utils';

const DATA_BLOCK_SIZE = 470;

const getStyles = (theme: Theme, isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    evenElement: {
      marginBottom: Spacing.SIZE_06,
      marginRight: isLowerThanDesktop ? Spacing.NONE : Spacing.SIZE_04,
      maxWidth: isLowerThanDesktop ? '100%' : `calc(50% - ${Spacing.SIZE_04}px)`,
      width: isLowerThanDesktop ? '100%' : DATA_BLOCK_SIZE,
    },
    oddElement: {
      marginBottom: Spacing.SIZE_06,
      marginLeft: isLowerThanDesktop ? Spacing.NONE : Spacing.SIZE_04,
      maxWidth: isLowerThanDesktop ? '100%' : `calc(50% - ${Spacing.SIZE_04}px)`,
      width: isLowerThanDesktop ? '100%' : DATA_BLOCK_SIZE,
    },
    withShortTermRentalText: {
      color: theme.palette.tint.purple[500],
    },
  });

interface RealEstateFinancialAnalysisSummaryProps {
  financialModel: FinancialModel;
  financialModelVersion: number;
  realEstateId: string;
  withShortTermRental: boolean;
}

export const RealEstateFinancialAnalysisSummary: React.FC<RealEstateFinancialAnalysisSummaryProps> =
  React.memo(
    ({ financialModel, financialModelVersion, realEstateId, withShortTermRental }) => {
      const { isLowerThanDesktop } = useResponsive();
      const { t } = useRealEstateTranslation();
      const theme = useTheme();
      const styles = getStyles(theme, isLowerThanDesktop);

      const formattedGrossReturn = `${financialModel.grossReturn}%`;
      const formattedNetReturn = `${financialModel.netReturn}%`;
      const formattedMonthlyCashflow = currencyFormatter(financialModel.monthlyCashflow, 0);
      const formattedMonthlyEnrichment = currencyFormatter(financialModel.monthlyEnrichment, 0);

      const trackTooltipNetReturn = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Rendement net' });

      const trackTooltipMonthlyEnrichment = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Enrichissement mensuel moyen' });

      const trackTooltipMonthlyCashflow = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: "Effort d'épargne mensuel" });

      const trackTooltipGrossReturn = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Rendement brut' });

      const valueStyle = withShortTermRental ? styles.withShortTermRentalText : undefined;

      return (
        <View style={styles.container}>
          <DataBlock
            style={styles.evenElement}
            title={t('shared.financialTool.grossReturn')}
            data={formattedGrossReturn}
            hint={
              <IconPopoverOrModal
                onOpen={trackTooltipGrossReturn}
                title={t('shared.financialTool.grossReturn')}
                content={t('shared.financialTool.tooltip.grossReturnDescription', {
                  context: `v${financialModelVersion}`,
                })}
                iconName="Info"
                iconSize="s"
              />
            }
            valueStyle={valueStyle}
          />

          <DataBlock
            style={styles.oddElement}
            title={t('shared.financialTool.netReturn')}
            data={formattedNetReturn}
            hint={
              <IconPopoverOrModal
                title={t('shared.financialTool.netReturn')}
                content={t('shared.financialTool.tooltip.netReturnDescription', {
                  context: `v${financialModelVersion}`,
                })}
                onOpen={trackTooltipNetReturn}
                iconName="Info"
                iconSize="s"
              />
            }
            valueStyle={valueStyle}
          />

          <DataBlock
            style={styles.evenElement}
            title={
              financialModel.monthlyCashflow < 0
                ? t('shared.financialTool.negativeMonthlyCashflowLabel')
                : t('shared.financialTool.positiveMonthlyCashflowLabel')
            }
            data={formattedMonthlyCashflow}
            hint={
              <IconPopoverOrModal
                title={
                  financialModel.monthlyCashflow < 0
                    ? t('shared.financialTool.negativeMonthlyCashflowLabel')
                    : t('shared.financialTool.positiveMonthlyCashflowLabel')
                }
                onOpen={trackTooltipMonthlyCashflow}
                content={t('shared.financialTool.tooltip.monthlyCashflowDescription')}
                iconName="Info"
                iconSize="s"
              />
            }
            valueStyle={valueStyle}
          />

          <DataBlock
            style={styles.oddElement}
            title={t('shared.financialTool.monthlyEnrichment')}
            data={formattedMonthlyEnrichment}
            hint={
              <IconPopoverOrModal
                title={t('shared.financialTool.monthlyEnrichment')}
                content={
                  financialModel.resellingCumulativeCashFlow < 0
                    ? t('shared.financialTool.tooltip.negativeMonthlyEnrichmentDescription')
                    : t('shared.financialTool.tooltip.positiveMonthlyEnrichmentDescription')
                }
                iconName="Info"
                iconSize="s"
                onOpen={trackTooltipMonthlyEnrichment}
              />
            }
            valueStyle={valueStyle}
          />
        </View>
      );
    },
    areEqual<RealEstateFinancialAnalysisSummaryProps>(
      ['withShortTermRental'],
      (prevState, nextState) => {
        return differentFuncParameters(prevState, nextState, [
          'grossReturn',
          'netReturn',
          'monthlyCashflow',
          'monthlyEnrichment',
          'resellingCumulativeCashFlow',
        ]);
      }
    )
  );

RealEstateFinancialAnalysisSummary.displayName = 'PropertyFinancialAnalysisSummary';
