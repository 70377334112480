import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCrossFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7.7 16.3a.948.948 0 00.7.275.948.948 0 00.7-.275l2.9-2.9 2.925 2.925c.183.183.413.27.688.262a.975.975 0 00.687-.287.948.948 0 00.275-.7.948.948 0 00-.275-.7L13.4 12l2.925-2.925a.891.891 0 00.262-.688.975.975 0 00-.287-.687.948.948 0 00-.7-.275.948.948 0 00-.7.275L12 10.6 9.075 7.675a.893.893 0 00-.687-.263.98.98 0 00-.688.288.948.948 0 00-.275.7c0 .283.092.517.275.7l2.9 2.9-2.925 2.925a.89.89 0 00-.262.687.976.976 0 00.287.688zM12 22a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 012 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0112 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0122 12a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0112 22z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCrossFilled;
