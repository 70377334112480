import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';

import { ThemeProvider } from '@emotion/react';

import { ComponentThemes, Theme } from '@app/ui/theme/types';
import { Paths } from '@app/ui/types/paths';
import { defaultTheme } from '@app/ui/theme/default';

import { Lang, useLang } from './useLang.hook';
import { useConfigTheme } from './useConfigTheme.hook';

export { Lang } from './useLang.hook';

export type ChangeThemeType = (
  value: string | number | object | unknown[],
  componentTheme: ComponentThemes | Theme,
  path: Paths<Theme>
) => void;

export interface AphroditeConfigContextValue {
  currentLang: Lang;
  setCurrentLang: (lang: Lang) => void;
  currentTheme: Theme;
  setCurrentTheme: (theme: Theme) => void;
  changeTheme: (currentTheme: Theme) => ChangeThemeType;
}

export const AphroditeConfigDefaults: AphroditeConfigContextValue = {
  changeTheme: () => () => {
    /**/
  },
  currentLang: 'fr',
  currentTheme: defaultTheme,
  setCurrentLang: () => {
    /**/
  },
  setCurrentTheme: () => {
    /**/
  },
};

export const AphroditeConfigContext =
  createContext<AphroditeConfigContextValue>(AphroditeConfigDefaults);

export const useAphroditeConfig = () => useContext(AphroditeConfigContext);

/**
 * @description
 * This hook is used to get the theme of in the application.
 * @returns Theme
 *
 * @example
 * import { useTheme } from "@app/ui";
 *
 * const myComp = () => {
 *   const theme = useTheme();
 *
 *   return <View style={{ backgroundColor: theme.palette.primary[500] }}>Hello World</View>;
 * }
 */
export const useTheme = (): Theme => {
  const { currentTheme } = useAphroditeConfig();
  return currentTheme;
};

export interface AphroditeConfigProviderProps {
  currentLang: Lang;
}

export const AphroditeConfigProvider: React.FC<PropsWithChildren<AphroditeConfigProviderProps>> = ({
  children,
  currentLang: defaultCurrentLang,
}) => {
  const { currentLang, setCurrentLang } = useLang(defaultCurrentLang);
  const { currentTheme, setCurrentTheme, changeTheme } = useConfigTheme(defaultTheme);

  useEffect(() => {
    setCurrentLang(defaultCurrentLang);
  }, [defaultCurrentLang]);

  return (
    <AphroditeConfigContext.Provider
      value={{
        changeTheme,
        currentLang,
        currentTheme,
        setCurrentLang,
        setCurrentTheme,
      }}
    >
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </AphroditeConfigContext.Provider>
  );
};
