import { Platform } from 'react-native';
import deviceInfoModule from 'react-native-device-info';

import packageJson from '../../package.json';

const getLastWebVersion = (): string | undefined => {
  return packageJson.version.split('-')[0];
};
export const getLastVersion = (): string | undefined => {
  return Platform.select({
    native: deviceInfoModule.getVersion(),
    web: getLastWebVersion(),
  });
};
