import React, { createContext, FC, PropsWithChildren } from 'react';

import {
  useNativeResponsive,
  defaultValueMobile,
  useWebResponsive,
} from '@app/ui/utils/responsive/useResponsive';
import { isWeb } from '@app/constants/platform';

export const ResponsiveContext = createContext(defaultValueMobile);
ResponsiveContext.displayName = 'ResponsiveContext';

export const ResponsiveContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const responsiveValue = isWeb() ? useWebResponsive : useNativeResponsive;

  const contextValue = responsiveValue();

  return <ResponsiveContext.Provider value={contextValue}>{children}</ResponsiveContext.Provider>;
};
