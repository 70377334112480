import { LayoutChangeEvent, ScaledSize, StyleSheet } from 'react-native';

import { BorderRadius, Spacing } from '@app/ui/theme/Sizes';
import { getColorWithOpacity, palette } from '@app/ui/theme/palette';
import { isWeb } from '@app/constants/platform';

import type { ModalPosition } from './Modal.types';

type ContainerPosition = LayoutChangeEvent['nativeEvent']['layout'];

interface Position {
  position: 'absolute';
  width?: number;
  maxWidth?: number;
  maxHeight?: number;
  height?: number;
  top?: number;
  left?: number;
  borderRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  padding?: number;
}

const computeTopPosition = (dimensions: ScaledSize, container: ContainerPosition): Position => ({
  position: 'absolute',
  ...(isWeb()
    ? {
        borderRadius: BorderRadius.SIZE_05,
        left: dimensions.width / 2 - container.width / 2,
        maxHeight: dimensions.height - Spacing.SIZE_09,
        maxWidth: dimensions.width - Spacing.SIZE_09,
        top: Spacing.SIZE_05,
      }
    : {
        borderBottomLeftRadius: BorderRadius.SIZE_05,
        borderBottomRightRadius: BorderRadius.SIZE_05,
        left: 0,
        maxHeight: dimensions.height - Spacing.SIZE_09,
        top: 0,
        width: dimensions.width,
      }),
});

const computeBottomPosition = (dimensions: ScaledSize, container: ContainerPosition): Position => ({
  position: 'absolute',
  ...(isWeb()
    ? {
        borderRadius: BorderRadius.SIZE_05,
        left: dimensions.width / 2 - container.width / 2,
        maxHeight: dimensions.height - Spacing.SIZE_09,
        maxWidth: dimensions.width - Spacing.SIZE_09,
        top: dimensions.height - container.height - Spacing.SIZE_05,
      }
    : {
        borderTopLeftRadius: BorderRadius.SIZE_05,
        borderTopRightRadius: BorderRadius.SIZE_06,
        left: 0,
        maxHeight: dimensions.height - Spacing.SIZE_09,
        top: dimensions.height - container.height,
        width: dimensions.width,
      }),
});

const computeCenterPosition = (dimensions: ScaledSize, container: ContainerPosition): Position => ({
  borderRadius: BorderRadius.SIZE_05,
  left: dimensions.width / 2 - container.width / 2,
  maxHeight: dimensions.height - Spacing.SIZE_09,
  maxWidth: dimensions.width - Spacing.SIZE_09,
  position: 'absolute',
  top: dimensions.height / 2 - container.height / 2,
});

const computeLeftPosition = (dimensions: ScaledSize): Position => ({
  position: 'absolute',
  ...(isWeb()
    ? {
        borderRadius: BorderRadius.SIZE_05,
        height: dimensions.height - Spacing.SIZE_09,
        left: Spacing.SIZE_06,
        maxWidth: dimensions.width - Spacing.SIZE_09,
        top: Spacing.SIZE_06,
      }
    : {
        height: dimensions.height,
        left: 0,
        top: 0,
      }),
});

const computeRightPosition = (dimensions: ScaledSize, container: ContainerPosition): Position => ({
  position: 'absolute',
  ...(isWeb()
    ? {
        borderRadius: BorderRadius.SIZE_05,
        height: dimensions.height - Spacing.SIZE_09,
        left: dimensions.width - container.width - Spacing.SIZE_06,
        maxWidth: dimensions.width - Spacing.SIZE_09,
        top: Spacing.SIZE_06,
      }
    : {
        height: dimensions.height,
        left: dimensions.width - container.width,
        top: 0,
      }),
});

const computeFullscreenPosition = (dimensions: ScaledSize) => ({
  height: dimensions.height,
  left: 0,
  position: 'absolute',
  top: 0,
  width: dimensions.width,
});

export const computePosition = (
  position: ModalPosition,
  containerStyles: ContainerPosition | null,
  dimensions: ScaledSize
) => {
  if (!containerStyles) {
    return {};
  }
  switch (position) {
    case 'top':
      return computeTopPosition(dimensions, containerStyles);
    case 'bottom':
      return computeBottomPosition(dimensions, containerStyles);
    case 'center':
      return computeCenterPosition(dimensions, containerStyles);
    case 'left':
      return computeLeftPosition(dimensions);
    case 'right':
      return computeRightPosition(dimensions, containerStyles);
    case 'fullscreen':
      return computeFullscreenPosition(dimensions);
    default:
      throw new Error('[Modal] Invalid position');
  }
};

export const getStyles = ({
  dimensions,
  crossSecondary,
  leftPositionCross,
  noContainerPadding,
}: {
  dimensions: ScaledSize;
  crossSecondary: boolean;
  leftPositionCross: boolean;
  noContainerPadding: boolean;
}) =>
  StyleSheet.create({
    backdrop: {
      backgroundColor: getColorWithOpacity(palette.base.black, 20),
    },
    closeButton: {
      ...(crossSecondary && {
        backgroundColor: palette.base.white,
        borderRadius: Spacing.SIZE_07,
        padding: Spacing.SIZE_03,
      }),
      height: 'auto',
      position: 'absolute',
      top: Spacing.SIZE_04,
      width: 'auto',
      ...(leftPositionCross ? { left: Spacing.SIZE_04 } : { right: Spacing.SIZE_04 }),
      zIndex: 1,
    },
    container: {
      alignSelf: 'center',
      backgroundColor: palette.base.white,
      maxHeight: dimensions.height,
      maxWidth: dimensions.width,
      overflow: 'hidden',
      padding: noContainerPadding ? 0 : Spacing.SIZE_08,
    },
    fullScreen: {
      height: dimensions.height,
      width: dimensions.width,
    },
  });
