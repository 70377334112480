import { StackNavigationProp } from '@react-navigation/stack';

import { ProjectNavigatorRoutes, ProjectsStackParamList } from '@app/navigation/types/routes';
import { GetProjectQuery } from '@app/libs/apollo/introspection';
import { DetailStep } from '@app/features/project/ProjectDetail/ProjectDetail.type';

export interface ProjectProps {
  project: GetProjectQuery['project'];
}

export type ProjectNavigationProp = StackNavigationProp<
  ProjectsStackParamList,
  ProjectNavigatorRoutes.Project
>;

export enum ProjectCategory {
  Transaction = 'transaction',
  Renovation = 'renovation',
  Renting = 'renting',
}

export type DetailStepRecord = {
  [ProjectCategory.Transaction]: Record<string, DetailStep[]>;
  [ProjectCategory.Renovation]: Record<string, DetailStep[]>;
  [ProjectCategory.Renting]: Record<string, DetailStep[]>;
};
