import React from 'react';

import { ScrollView, StyleSheet, View } from 'react-native';

import { RenovationDetail } from '@app/libs/apollo/introspection';
import { IconButton, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { isWeb } from '@app/constants/platform';
import { useTranslation } from '@app/services/translations/translations';

import { TablePreview } from '../TablePreview';
import { RenovationDetailItem } from './RenovationDetailItem';
import { RenovationWarranty } from '../RenovationWarranty';

interface RenovationDetailsTemplateProps {
  renovationAmount: number;
  details: RenovationDetail[];
  onClose?: () => void;
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    scrollView: {
      flex: 1,
      padding: theme.spacing.SIZE_06,
    },
    scrollViewContent: {
      gap: theme.spacing.SIZE_05,
    },
    titleContainer: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_05,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_05,
      ...(isLowerThanLaptop && {
        borderBottomWidth: 1,
        borderColor: theme.palette.neutral[100],
      }),
      ...(isWeb() && {
        paddingTop: theme.spacing.SIZE_05,
      }),
    },
  });

export const RenovationDetailsTemplate: React.FC<RenovationDetailsTemplateProps> = ({
  details,
  renovationAmount,
  onClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop);

  return (
    <>
      <View style={styles.titleContainer}>
        {!!isLowerThanLaptop && <IconButton iconName="ArrowLeft" iconSize="m" onPress={onClose} />}
        <Text textStyle={isLowerThanLaptop ? 'Headline2Medium' : 'Title3Medium'}>
          {t('realEstate.renovation.renovationDetails.tableTitle')}
        </Text>
      </View>

      <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContent}>
        <RenovationWarranty large={false} />

        <TablePreview
          total={renovationAmount}
          totalLabel={t('realEstate.renovation.renovationDetails.totalLabel')}
          data={details}
          renderItem={item => <RenovationDetailItem detail={item} />}
        />
      </ScrollView>
    </>
  );
};
