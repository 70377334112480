import { TranslationContentValue } from '@app/ui/atoms/translation-content/TranslationContent.types';
import { TEXT_STYLES } from '@app/ui/theme/fonts';

export interface TranslatedContentProps {
  onHandleDone?: (
    contentType: TranslatedContentContentType,
    sourceLang?: string,
    targetLang?: string
  ) => void;
  handleChangeLang?: () => void;
  translation: TranslationContentValue;
  translatedBy: string;
  showOriginal: string;
  showTranslated: string;
  textStyle?: keyof typeof TEXT_STYLES;
  dark?: boolean;
  numberOfLines?: number;
}

export enum TranslatedContentContentType {
  Source = 'SOURCE',
  Target = 'TARGET',
}
