import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgAirFreshener = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M12 5a.968.968 0 0 1-.713-.287A.967.967 0 0 1 11 4c0-.283.096-.52.287-.712A.968.968 0 0 1 12 3c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 12 5Zm0-3a.968.968 0 0 1-.713-.288A.967.967 0 0 1 11 1c0-.283.096-.52.287-.713A.968.968 0 0 1 12 0c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.712A.968.968 0 0 1 12 2ZM8.4 22a6.796 6.796 0 0 1-2.5-2.55A6.923 6.923 0 0 1 5 16c0-1.35.358-2.596 1.075-3.738A6.827 6.827 0 0 1 9 9.675V7c0-.283.096-.52.287-.713A.968.968 0 0 1 10 6h4c.283 0 .52.096.713.287.191.192.287.43.287.713v2.675a6.94 6.94 0 0 1 2.913 2.587A6.837 6.837 0 0 1 19 16c0 1.233-.3 2.383-.9 3.45A6.796 6.796 0 0 1 15.6 22H8.4Zm.6-2h6a5.082 5.082 0 0 0 1.475-1.762C16.825 17.546 17 16.8 17 16c0-.967-.254-1.854-.762-2.662A4.86 4.86 0 0 0 14.15 11.5L13 10.95V8h-2v2.95l-1.15.55a4.86 4.86 0 0 0-2.087 1.838A4.903 4.903 0 0 0 7 16c0 .8.175 1.546.525 2.238.35.691.842 1.279 1.475 1.762ZM7 3a.968.968 0 0 1-.713-.288A.968.968 0 0 1 6 2c0-.283.096-.52.287-.712A.968.968 0 0 1 7 1c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 7 3Zm10 0a.968.968 0 0 1-.712-.288A.967.967 0 0 1 16 2c0-.283.096-.52.288-.712A.968.968 0 0 1 17 1c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 17 3ZM9 5a.968.968 0 0 1-.713-.287A.968.968 0 0 1 8 4c0-.283.096-.52.287-.712A.968.968 0 0 1 9 3c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 9 5Zm6 0a.968.968 0 0 1-.713-.287A.967.967 0 0 1 14 4c0-.283.096-.52.287-.712A.968.968 0 0 1 15 3c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 15 5Z"
    />
  </Svg>
);
export default SvgAirFreshener;
