import React, { useEffect } from 'react';

import { StyleSheet, View, SafeAreaView } from 'react-native';

import { palette, Spacing, Text } from '@app/ui';
import { trackSubject } from '@app/libs/segment/segment';
import { useDebugMenu } from '@app/shared/hooks/useDebugMenu';

const styles = StyleSheet.create({
  item: {
    padding: Spacing.SIZE_01,
  },
  root: {
    alignItems: 'center',
    backgroundColor: palette.neutral[300],
    justifyContent: 'center',
    left: Spacing.NONE,
    opacity: 0.7,
    position: 'absolute',
    right: Spacing.NONE,
    top: Spacing.NONE,
    zIndex: 999,
  },
});

export const TrackingOverlay: React.FC = () => {
  const { isTrackingOverlayEnable, pushTrackEvent, trackHistory } = useDebugMenu();

  useEffect(() => {
    const onTrackSubject = item => {
      if (isTrackingOverlayEnable) {
        pushTrackEvent({ ...item, timestamp: Date.now() });
      }
    };

    const subscription = trackSubject.subscribe(onTrackSubject);

    return () => {
      subscription.unsubscribe();
    };
  }, [isTrackingOverlayEnable, pushTrackEvent]);

  const lastItem = trackHistory.length ? trackHistory[trackHistory.length - 1] : undefined;

  return isTrackingOverlayEnable ? (
    <SafeAreaView style={styles.root} pointerEvents="none">
      <View style={styles.item}>
        <Text textStyle="Headline3Medium">{lastItem?.event}</Text>
        <Text textStyle="Caption1">{JSON.stringify(lastItem?.properties, null, 2)}</Text>
      </View>
    </SafeAreaView>
  ) : null;
};
