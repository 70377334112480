import React, { useState } from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Icon } from '@app/ui/atoms/Icon';

import { likeButtonTheme } from './LikeButton.theme';
import { LikeButtonProps, PressableState, LikeButtonSize } from './LikeButton.types';
import { getContainerStyle, getIconStyle } from './LikeButton.styles';
import { Pressable } from '../pressable/Pressable';

export { LikeButtonProps, LikeButtonSize };

export const LikeButton = ({
  size = 'm',
  onPress,
  liked = false,
  disabled = false,
  withBorder = false,
  withShadow = false,
  accessibilityLabel,
  testID,
}: LikeButtonProps) => {
  const componentTheme = useComponentTheme('likeButton', likeButtonTheme);

  const [pressed, setPressed] = useState(false);

  const handleStyleChange = (state: PressableState) => {
    const styles = getContainerStyle(
      size,
      state.pressed,
      disabled,
      liked,
      withBorder,
      withShadow,
      !!state.hovered,
      componentTheme
    );

    setPressed(state.pressed);

    return styles;
  };

  const iconStyle = getIconStyle(size, componentTheme, liked, pressed, disabled);

  return (
    <Pressable
      testID={testID}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      withPressOpacity={false}
      disabled={disabled}
      onPress={onPress}
      style={state => handleStyleChange(state)}
    >
      <Icon style={iconStyle.iconSize} color={iconStyle.color} name="HeartFilled" />
    </Pressable>
  );
};
