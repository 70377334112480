import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgAn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 .5H2A1.5 1.5 0 00.5 2v16A1.5 1.5 0 002 19.5h24a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0026 .5zM2 0a2 2 0 00-2 2v16a2 2 0 002 2h24a2 2 0 002-2V2a2 2 0 00-2-2H2z"
        fill="#F5F5F5"
      />
      <Path d="M10.667 0h6.666v20h-6.666V0z" fill="#D6182D" />
      <Path d="M0 6.667h28v6.666H0V6.667z" fill="#1425A2" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.333 11.667l-.471.138.138-.472-.138-.471.471.138.472-.138-.138.471.138.472-.472-.138zM16.667 11.667l-.472.138.138-.472-.138-.471.472.138.471-.138-.138.471.138.472-.471-.138zM8.667 10.333l-.472.138.138-.471-.138-.471.472.138.471-.138L9 10l.138.471-.471-.138zM19.333 10.333l-.471.138L19 10l-.138-.471.471.138.472-.138-.138.471.138.471-.472-.138zM14 9l-.471.138.138-.471-.138-.472.471.138.471-.138-.138.472.138.471L14 9z"
        fill="#fff"
      />
    </Svg>
  );
};

export default SvgAn;
