export enum Spacing {
  NONE = 0,
  SIZE_00 = 1,
  SIZE_01 = 2,
  SIZE_02 = 4,
  SIZE_03 = 8,
  SIZE_04 = 12,
  SIZE_05 = 16,
  SIZE_06 = 20,
  SIZE_07 = 24,
  SIZE_08 = 32,
  SIZE_09 = 40,
  SIZE_10 = 48,
  SIZE_11 = 56,
  SIZE_12 = 64,
  SIZE_13 = 72,
  SIZE_14 = 80,
  SIZE_15 = 96,
}

export enum Breakpoints {
  maxMobile = 480,
  largeMobile = 540,
  minTablet = Breakpoints.maxMobile,
  maxTablet = 768,
  minLaptop = Breakpoints.maxTablet,
  maxLaptop = 1024,
  minDesktop = Breakpoints.maxLaptop,
  maxDesktop = 1440,
  minWide = Breakpoints.maxDesktop,
  maxWide = 1920,
  minUltraWide = Breakpoints.maxWide,
}

export const MediaQuery = {
  desktop: `(min-width: ${Breakpoints.minDesktop}px) and (max-width: ${Breakpoints.maxDesktop}px)`,
  laptop: `(min-width: ${Breakpoints.minLaptop}px) and (max-width: ${Breakpoints.maxLaptop}px)`,
  largeMobile: `(min-width: ${Breakpoints.maxMobile}px) and (max-width: ${Breakpoints.largeMobile}px)`,
  mobile: `(max-width: ${Breakpoints.maxMobile}px)`,
  tablet: `(min-width: ${Breakpoints.minTablet}px) and (max-width: ${Breakpoints.maxTablet}px)`,
  ultraWide: `(min-width: ${Breakpoints.minUltraWide}px)`,
  wide: `(min-width: ${Breakpoints.minWide}px) and (max-width: ${Breakpoints.maxWide}px)`,
};

export enum BorderRadius {
  SIZE_01 = 2,
  SIZE_02 = 4,
  SIZE_03 = 8,
  SIZE_04 = 12,
  SIZE_05 = 16,
  SIZE_06 = 20,
  SIZE_07 = 24,
  SIZE_08 = 32,
  SIZE_09 = 40,
  SIZE_10 = 48,
  SIZE_11 = 56,
  SIZE_12 = 64,
  SIZE_13 = 72,
  SIZE_14 = 80,
  SIZE_15 = 96,
}

export const CHECKBOX_SIZE = 20;
