import {
  CustomerNotificationPreferencesInput,
  useUpdateCustomerNotificationPreferencesMutation,
  YesNoLater,
} from '@app/libs/apollo/introspection';
import { ensureNotificationPermissions } from '@app/services/notification/notification-permission';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { isNative } from '@app/constants/platform';

type useUpdateCustomerNotificationSettingsPreferencesFn = (
  customerNotificationPreferences: CustomerNotificationPreferencesInput,
  isNotificationEnabled: YesNoLater
) => Promise<void>;

export const useUpdateCustomerNotificationSettings = (
  setIsLoading: (loading: boolean) => void
): useUpdateCustomerNotificationSettingsPreferencesFn => {
  const { t } = useTranslation();
  const { setErrorMsg, setValidMsg } = useToastContext();

  const [updateCustomerNotificationPreferences] =
    useUpdateCustomerNotificationPreferencesMutation();

  return async (
    customerNotificationPreferences: CustomerNotificationPreferencesInput,
    isNotificationEnabled: YesNoLater
  ) => {
    setIsLoading(true);

    if (isNative() && isNotificationEnabled === YesNoLater.Later) {
      await ensureNotificationPermissions();
    }

    try {
      await updateCustomerNotificationPreferences({
        variables: {
          notificationPreferences: {
            notificationPreferences: customerNotificationPreferences,
          },
        },
      });
      setValidMsg({ icon: 'Check', title: t('profile.updateProfileMessage'), withClose: true });
    } catch (err) {
      setErrorMsg({
        title: t('profile.notificationPreferences.error'),
        withClose: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
};
