import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgConception = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="m10.85 11.71-3.453 8.966a.593.593 0 0 1-.16.21l-1.022.934a.593.593 0 0 1-.656.135c-.245-.09-.389-.256-.431-.496L5 20.165l.032-.271 3.58-9.327c.32.281.666.517 1.039.707.373.19.772.336 1.198.437ZM12 10.628c-1.065 0-1.971-.35-2.717-1.053-.746-.702-1.119-1.554-1.119-2.557 0-.782.24-1.479.72-2.09a3.744 3.744 0 0 1 1.838-1.28V2.204c0-.34.122-.626.367-.857.245-.23.549-.346.911-.346s.666.115.911.346c.245.23.367.516.367.857v1.445c.746.24 1.359.666 1.838 1.278.48.612.72 1.309.72 2.091 0 1.003-.373 1.855-1.12 2.558-.745.702-1.65 1.053-2.716 1.053Zm0-2.406c.362 0 .666-.116.911-.346a1.13 1.13 0 0 0 .367-.858 1.13 1.13 0 0 0-.367-.857A1.278 1.278 0 0 0 12 5.814c-.362 0-.666.115-.911.346a1.13 1.13 0 0 0-.367.857c0 .341.122.627.367.858.245.23.549.346.911.346Zm1.15 3.49c.427-.1.826-.246 1.2-.437.372-.19.719-.426 1.038-.707l3.58 9.327c.021.06.032.15.032.27l-.128 1.294c-.043.241-.186.407-.431.497a.593.593 0 0 1-.656-.135l-1.022-.933-.16-.21-3.452-8.966Z"
    />
  </Svg>
);
export default SvgConception;
