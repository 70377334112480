import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgHn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="hn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#hn_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 6.667h28V0H0v6.667zM0 20h28v-6.667H0V20z" fill="#0884E6" />
        <Path
          d="M14 10.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.276.943.276.943-.943-.276zM19.333 9.333l-.942.276.276-.942-.277-.943.943.276.943-.276-.276.943.276.942-.943-.276zM19.333 12l-.942.276.276-.943-.277-.943.943.277.943-.277-.276.943.276.943-.943-.276zM8.667 12l-.943.276.276-.943-.276-.943.943.277.942-.277-.276.943.276.943L8.667 12zM8.667 9.333l-.943.276L8 8.667l-.276-.943.943.276.942-.276-.276.943.276.942-.942-.276z"
          fill="#0E88E9"
        />
      </G>
    </Svg>
  );
};

export default SvgHn;
