import React, { useContext, useEffect, useMemo, useRef } from 'react';

import { Animated, View } from 'react-native';

import { ExpandableContext, Icon, palette, Text, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { ProjectDetailSubStepHeaderProps } from './ProjectDetail.type';
import {
  getDetailSubStepHeaderStyles,
  getIconColor,
  rotateAnimation,
} from './ProjectDetailStage.styles';

export const ProjectDetailSubStepHeader: React.FC<ProjectDetailSubStepHeaderProps> = ({
  optional,
  showChevron,
  status,
  title,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { expanded } = useContext(ExpandableContext);
  const rotateValue = useRef(new Animated.Value(0)).current;
  // FIXME enable when data will be dynamic
  // const iconName = status === StepStatus.Ongoing ? 'Clock' : 'CheckFilled';
  const iconName = 'CheckFilled';

  useEffect(() => rotateAnimation({ expanded, rotateValue }), [expanded, rotateValue]);

  const spin = rotateValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const styles = useMemo(
    () => getDetailSubStepHeaderStyles({ showChevron, spin }),
    [showChevron, spin]
  );

  return (
    <View style={styles.header}>
      <Icon name={iconName} color={getIconColor(theme, status)} size={20} />
      <View style={styles.textContainer}>
        <Text textStyle="Body1Medium" style={styles.textHeader}>
          {t(title)}
        </Text>
        {optional ? (
          <Text textStyle="Body2" style={styles.optionalText}>
            {t('project.optional')}
          </Text>
        ) : null}
      </View>
      {showChevron ? (
        <Animated.View style={styles.animation}>
          <Icon name="ChevronDown" color={palette.neutral[900]} size={24} style={styles.chevron} />
        </Animated.View>
      ) : null}
    </View>
  );
};
