import React, { useCallback } from 'react';

import { View } from 'react-native';

import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { ListItem } from '@app/ui/atoms/list/ListItem';
import { CounterActivator } from '@app/ui/molecules/dropdown-activator/counter-activator/CounterActivator';
import { DropdownFooterActions } from '@app/ui/molecules/dropdown-footer/dropdown-footer-actions/DropdownFooterActions';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';

import { FilterListSelectorProps, FilterListSelectorOptionType } from './FilterListSelector.types';
import { getStyles } from './FilterListSelector.styles';

export { FilterListSelectorProps, FilterListSelectorOptionType };

export const FilterListSelector: React.FC<FilterListSelectorProps> = ({
  options,
  maxHeight = 310,
  minWidth = 296,
  values,
  placeholder,
  onSelectValue,
  multiple = false,
  onCallbackClose,
  buttonLabel,
  onPrimaryClick,
  onGhostClick,
  disabled,
  ...rest
}) => {
  const styles = getStyles();

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="filter-list-selector-list-container">
          {options.map(item => {
            const onSelect = () => {
              onSelectValue(item, closeList);
            };
            return (
              <ListItem
                onPress={onSelect}
                checkbox={multiple}
                checked={item.selected}
                {...item}
                key={item.label.toLowerCase()}
              />
            );
          })}
        </View>
      );
    },
    [multiple, onSelectValue, options, styles.listContainer]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return (
        <CounterActivator
          onPress={toggleList}
          placeholder={placeholder}
          values={values}
          open={isOpen}
          disabled={disabled}
          noCounter={!multiple}
        />
      );
    },
    [values, placeholder, disabled, multiple]
  );

  const getFooter = useCallback(
    (closeList: () => void) => {
      return (
        <DropdownFooterActions
          containerStyle={styles.footer}
          buttonLabel={buttonLabel}
          onGhostClick={onGhostClick}
          onPrimaryClick={onPrimaryClick}
          closeList={closeList}
        />
      );
    },
    [buttonLabel, onGhostClick, onPrimaryClick, styles.footer]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => {
      return (
        <DropdownListContainer
          list={getList(closeList)}
          footer={getFooter(closeList)}
          maxHeight={maxHeightList}
        />
      );
    },
    [getFooter, getList]
  );

  return (
    <DropdownV2
      {...rest}
      renderList={renderList}
      maxHeight={maxHeight}
      minWidth={minWidth}
      fitParent={false}
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};
