import React, { createContext } from 'react';

import { useInterpret } from '@xstate/react';

import {
  GlobalStateContextProps,
  GlobalStateProviderProps,
} from '@app/features/onboarding/stateMachine/stateMachine.types';
import { config } from '@app/config';

import { onboardingMachine } from './stateMachine/stateMachine';

export const OnboardingStateContext: React.Context<GlobalStateContextProps> =
  createContext<GlobalStateContextProps>({});

export const OnboardingContext: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const onboardingService = useInterpret(onboardingMachine, { devTools: config.isDev });

  return (
    <OnboardingStateContext.Provider value={{ onboardingService }}>
      {children}
    </OnboardingStateContext.Provider>
  );
};
