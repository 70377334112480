import { StyleSheet, TextStyle } from 'react-native';

import { Spacing } from '@app/ui/theme/Sizes';
import { Theme } from '@app/ui/theme/types';

import { Sizes } from './BubbleCount.types';

export const getSizesMap = (theme: Theme) =>
  new Map<Sizes, Spacing>([
    [Sizes.S, theme.spacing.SIZE_03],
    [Sizes.M, theme.spacing.SIZE_06],
    [Sizes.L, theme.spacing.SIZE_07],
  ]);

export const getStyles = (theme: Theme, size: Sizes) => {
  const sizesMap = getSizesMap(theme);

  const textMap = new Map<Sizes, TextStyle>([
    [Sizes.S, theme.textStyles.Caption2Medium],
    [Sizes.M, theme.textStyles.Caption2Medium],
    [Sizes.L, theme.textStyles.Button3],
  ]);

  return StyleSheet.create({
    counterText: {
      ...textMap.get(size || Sizes.M),
      color: theme.palette.base.white,
    },
    counterWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[900],
      borderRadius: 50,
      height: sizesMap.get(size),
      justifyContent: 'center',
      width: sizesMap.get(size),
      zIndex: 2,
    },
  });
};
