import styled from '@emotion/styled';

import { RequiredThemes } from '@app/ui/theme/types';

import { CheckboxSize } from '../Checkbox.types';

interface CheckStylesProps {
  $checked?: boolean;
  $disabled?: boolean;
  $error?: boolean;
  $size?: CheckboxSize;
  componentTheme: RequiredThemes['checkbox'];
}

const getSize = (componentTheme: RequiredThemes['checkbox'], size: CheckboxSize = 'm') =>
  componentTheme.size.size[size];

export const CheckboxInputContainer = styled.div<CheckStylesProps>`
  position: relative;
  width: ${({ componentTheme, $size }) => getSize(componentTheme, $size)}px;
  height: ${({ componentTheme, $size }) => getSize(componentTheme, $size)}px;
`;

export const CheckboxTickWrapper = styled.div<CheckStylesProps>`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Input = styled.input<CheckStylesProps>`
  position: absolute;
  opacity: 0 !important;
  width: ${({ componentTheme, $size }) => getSize(componentTheme, $size)}px;
  height: ${({ componentTheme, $size }) => getSize(componentTheme, $size)}px;
  margin: 0;
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'default')};
`;
