import React, { useContext, useMemo } from 'react';

import { useTranslation } from '@app/services/translations/translations';
import { useMe } from '@app/shared/hooks/useMe';
import { ButtonSelector } from '@app/ui/organisms/button-selector/ButtonSelector';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { DiscussBeCalledContent } from '@app/features/discuss/components/DiscussBeCalledContent';
import { DiscussTakeAppointmentContent } from '@app/features/discuss/components/DiscussTakeAppointmentContent';
import { IconName, useTheme } from '@app/ui';
import { DiscussModalTitle } from '@app/features/discuss/components/DiscussModalTitle';
import { useDateFormatter } from '@app/shared/hooks/useDateFormatter';
import {
  trackDiscussAppointment,
  trackDiscussBeCalledBack,
  trackDiscussMenu,
} from '@app/services/tracking/trackTracking';

export const DiscussDropdown: React.FC = () => {
  const theme = useTheme();
  const { customer } = useMe();
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();

  const { openModal } = useContext(ModalContext);

  const onPressSelector = () => {
    trackDiscussMenu();
  };

  const options = useMemo(() => {
    // Create options
    const optionCallMeBack = customer.isAwaitingCallback
      ? {
          description: t('discuss.list.awaitingCallDescription'),
          disabled: true,
          hasRightArrow: false,
          iconColor: theme.palette.tint.green[500],
          iconName: 'Check' as IconName,
          key: 'awaitingCall',
          label: t('discuss.list.awaitingCallTitle'),
          numberOfLines: 2,
        }
      : {
          description: t('discuss.list.beCalledDescription'),
          hasRightArrow: true,
          iconName: 'Phone' as IconName,
          key: 'beCalled',
          label: t('discuss.list.beCalledTitle'),
          numberOfLines: 2,
          onSelect: (closeList: () => void) => {
            trackDiscussBeCalledBack();
            closeList();
            openModal?.(ModalKey.BeCalled, <DiscussBeCalledContent />, {
              position: 'center',
              title: t('discuss.list.beCalledTitle'),
              webSize: 's',
            });
          },
        };

    const optionTakeAppointment = customer.nextAppointment?.startAt
      ? {
          description: t('discuss.list.appointmentTakenDescription', {
            date: formatDate(customer.nextAppointment.startAt),
          }),
          disabled: true,
          iconColor: theme.palette.tint.green[500],
          iconName: 'Check' as IconName,
          key: 'appointmentTaken',
          label: t('discuss.list.appointmentTakenTitle'),
          numberOfLines: 2,
        }
      : {
          description: t('discuss.list.takeAppointmentDescription'),
          hasRightArrow: true,
          iconName: 'Calendar' as IconName,
          key: 'takeAppointment',
          label: t('discuss.list.takeAppointmentTitle'),
          numberOfLines: 2,
          onSelect: closeList => {
            trackDiscussAppointment();
            closeList();
            openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentContent />, {
              position: 'center',
              title: <DiscussModalTitle />,
              webSize: 'l',
            });
          },
        };

    return [
      // A user with a scheduled appointment cannot access the callback option anymore.
      ...(customer.nextAppointment ? [] : [optionCallMeBack]),
      optionTakeAppointment,
    ];
  }, [customer.isAwaitingCallback, customer.nextAppointment, openModal, t, theme]);

  return (
    <ButtonSelector
      iconName="Headset"
      iconPlacement="left"
      maxWidth={400}
      size="s"
      onPressSelector={onPressSelector}
      options={options}
      label={t('navigation.topBar.discuss')}
    />
  );
};
