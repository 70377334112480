import React, { ReactNode, useState } from 'react';

import { IconButton, IconName } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import {
  Dpe,
  EstimatedWorks,
  Ges,
  RealEstateBuilding,
  RealEstateSubPartBuilding,
} from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackDPEButtonClick, trackGESButtonClick } from '@app/services/tracking/trackTracking';

interface Rows {
  icon: IconName;
  title: string;
  data: string;
  testID: string;
  suffix?: ReactNode;
  onSuffixPressed?: () => void;
}

interface RealEstateRenovationOutput {
  rows: Rows[];
  shouldDisplayFurniture: boolean;
  shouldDisplayPlan: boolean;
  isVisibleDPEModal: boolean;
  isVisibleGESModal: boolean;
  toggleDPEModal: () => void;
  toggleGESModal: () => void;
}

interface RealEstateRenovationParams {
  id: string;
  plans: RealEstateSubPartBuilding | null | undefined;
  dpe: Dpe | null | undefined;
  ges: Ges | null | undefined;
  building: RealEstateBuilding;
  renovationAmount: number;
  renovationWorkload: EstimatedWorks;
  furnishingAmount: number;
}

export const useRealEstateRenovation = ({
  id,
  building,
  plans,
  dpe,
  ges,
  renovationAmount,
  renovationWorkload,
  furnishingAmount,
}: RealEstateRenovationParams): RealEstateRenovationOutput => {
  const { t } = useRealEstateTranslation();
  const { countryCode } = building.address;

  const [isVisibleDPEModal, setIsVisibleDPEModal] = useState(false);
  const [isVisibleGESModal, setIsVisibleGESModal] = useState(false);

  const hasDPE = dpe && dpe !== Dpe.NoDiag;
  const hasGES = ges && ges !== Ges.NoDiag;

  const shouldDisplayPlan =
    !!plans &&
    ((Array.isArray(plans.images) && plans.images.length > 0) ||
      !!plans.translation?.translatedText ||
      !!plans.translation?.sourceText);

  const shouldDisplayFurniture = furnishingAmount > 0;

  const toggleGESModal = () => {
    if (!isVisibleGESModal) {
      trackGESButtonClick(id);
    }
    setIsVisibleGESModal(!isVisibleGESModal);
  };

  const toggleDPEModal = () => {
    if (!isVisibleDPEModal) {
      trackDPEButtonClick(id);
    }
    setIsVisibleDPEModal(!isVisibleDPEModal);
  };

  const rows: Rows[] = [
    ...(renovationWorkload !== EstimatedWorks.None
      ? [
          {
            data: t(`propertyRenovation.${renovationWorkload}`),
            icon: 'Works' as IconName,
            testID: 'property-renovation-data-block-furniture-amount',
            title: t('propertyRenovation.renovationType'),
          },
        ]
      : []),
    {
      data: currencyFormatter(renovationAmount, 0),
      icon: 'Hammer',
      testID: t('shared.financialTool.renovationType'),
      title: t('propertyRenovation.renovate'),
    },
    {
      data: currencyFormatter(furnishingAmount, 0),
      icon: 'Chair',
      testID: 'property-renovation-data-block-renovation-amount',
      title: t('propertyRenovation.furniture'),
    },

    {
      data: hasDPE
        ? t('propertyRenovation.DPE.data', { value: Dpe[dpe || 'NoDiag'] })
        : t('propertyRenovation.DPE.inProgress'),
      icon: 'Zap',
      suffix:
        countryCode === 'FR' ? (
          <IconButton iconName="Help" onPress={toggleDPEModal} iconSize="s" />
        ) : null,
      testID: 'property-renovation-data-block-dpe',
      title: t('propertyRenovation.DPE.title'),
    },
    ...(['FR', 'ES'].includes(countryCode)
      ? [
          {
            data: hasGES
              ? t('propertyRenovation.GES.data', { value: Ges[ges || 'NoDiag'] })
              : t('propertyRenovation.GES.inProgress'),
            icon: 'Cloud' as IconName,
            suffix:
              countryCode === 'FR' ? (
                <IconButton iconName="Help" onPress={toggleGESModal} iconSize="s" />
              ) : null,
            testID: 'property-renovation-data-block-ges',
            title: t('propertyRenovation.GES.title'),
          },
        ]
      : []),
  ];

  return {
    isVisibleDPEModal,
    isVisibleGESModal,
    rows,
    shouldDisplayFurniture,
    shouldDisplayPlan,
    toggleDPEModal,
    toggleGESModal,
  };
};
