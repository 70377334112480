import React, { useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { DataRows } from '@app/ui/organisms/data-rows/DataRows';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { SoldRealEstateV2 } from '@app/libs/apollo/introspection';

import { Callout } from './Callout';

interface FinancialStrategyProps {
  financialStrategy: SoldRealEstateV2['afterRenovation']['financialStrategy'];
  goToAdvisorAppointment: () => void;
}

const getStyles = ({ theme }: { theme: Theme; isLowerThanDesktop: boolean }) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_08,
    },
  });

export const FinancialStrategy: React.FC<FinancialStrategyProps> = ({
  financialStrategy,
  goToAdvisorAppointment,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();

  const styles = useMemo(
    () => getStyles({ isLowerThanDesktop, theme }),
    [theme, isLowerThanDesktop]
  );

  return (
    <View style={styles.container}>
      <Text textStyle="Headline1Medium">{t('propertySold.financialStrategy.title')}</Text>
      <DataRows
        rows={[
          {
            description: currencyFormatter(financialStrategy.projectAmount, 0),
            label: t('propertySold.financialStrategy.projectAmount'),
          },
          {
            description: currencyFormatter(financialStrategy.annualRent, 0),
            label: t('propertySold.financialStrategy.annualRent'),
          },
          {
            description: currencyFormatter(financialStrategy.monthlyAmount, 0),
            label: t('propertySold.financialStrategy.monthlyAmount'),
          },
          {
            description: currencyFormatter(financialStrategy.annualCharges, 0),
            label: t('propertySold.financialStrategy.annualCharges'),
          },
          {
            description: currencyFormatter(financialStrategy.monthlyCashflow, 0),
            label: t('propertySold.financialStrategy.monthlyCashflow'),
          },
          {
            description: `${financialStrategy.netReturn} %`,
            label: t('propertySold.financialStrategy.netReturn'),
          },
          {
            description: `${financialStrategy.rentingDelay} ${t('shared.day_short')}`,
            label: t('propertySold.financialStrategy.rentingDelay'),
          },
        ]}
        keepColumns={true}
      />
      <Callout onClick={goToAdvisorAppointment} />
    </View>
  );
};
