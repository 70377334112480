import { useCallback } from 'react';

import { useAddBookmarkMutation, useDeleteBookmarkMutation } from '@app/libs/apollo/introspection';
import { captureException } from '@app/libs/sentry/sentry';
import {
  PropertyLikeType,
  PropertyPage,
  TrackingOrigin,
  trackBookmark,
  trackUnbookmark,
} from '@app/services/tracking/trackTracking';

import { useToastContext } from '../contexts/toast/Toast';

type UseBookMarkProps = {
  callbackOnBookmark?: () => void;
  callbackUnBookmark?: () => void;
};

type Source = { currentTab: TrackingOrigin; page: PropertyPage };

type OptimisticRealEstate = { __typename: string; id: string };

export type toggleBookmarkRealEstate = { id: string; isBookmarked: boolean };

interface UseBookMarkResponseProps {
  toggleBookmark: (item: toggleBookmarkRealEstate, source: Source) => void;
}

const getOptimisticResponse = (realEstate: OptimisticRealEstate, isFavorites: boolean) => ({
  realEstate: {
    id: realEstate.id,
    metadata: {
      isFavorites,
    },
  },
});

export const useBookmark = ({
  callbackOnBookmark,
  callbackUnBookmark,
}: UseBookMarkProps): UseBookMarkResponseProps => {
  const { setErrorMsg } = useToastContext();
  const [addBookmark] = useAddBookmarkMutation();
  const [deleteBookmark] = useDeleteBookmarkMutation();

  const onUnBookmark = useCallback(
    (id: string, realEstateOptimisticResponse: OptimisticRealEstate, source: Source) => {
      deleteBookmark({
        optimisticResponse: {
          deleteBookmark: getOptimisticResponse(realEstateOptimisticResponse, false),
        },
        variables: { realEstateId: id },
      })
        .catch(e => {
          setErrorMsg();
          captureException(`[Bookmark]: Remove Failed: ${e.message}`);
        })
        .then(() => {
          trackUnbookmark({
            ...source,
            realEstateId: id,
          });
          callbackUnBookmark?.();
        });
    },
    [callbackUnBookmark, deleteBookmark, setErrorMsg]
  );

  const onBookmark = useCallback(
    (id: string, realEstateOptimisticResponse: OptimisticRealEstate, source: Source) => {
      addBookmark({
        optimisticResponse: {
          addBookmark: getOptimisticResponse(realEstateOptimisticResponse, true),
        },
        variables: { isPositionedOn: false, realEstateId: id },
      })
        .catch(e => {
          setErrorMsg();
          captureException(`[Bookmark]: Add Failed: ${e.message}`);
        })
        .then(() => {
          trackBookmark({
            ...source,
            likeType: PropertyLikeType.FAVORITE,
            realEstateId: id,
          });
          callbackOnBookmark?.();
        });
    },
    [addBookmark, callbackOnBookmark, setErrorMsg]
  );

  const toggleBookmark = useCallback(
    (trackProps: toggleBookmarkRealEstate, source: Source) => {
      const realEstateOptimisticResponse: OptimisticRealEstate = {
        __typename: 'RealEstate',
        id: trackProps.id,
      };

      trackProps.isBookmarked && onUnBookmark(trackProps.id, realEstateOptimisticResponse, source);
      !trackProps.isBookmarked && onBookmark(trackProps.id, realEstateOptimisticResponse, source);
    },
    [onBookmark, onUnBookmark]
  );

  return {
    toggleBookmark,
  };
};
