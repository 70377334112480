import { useSharedValue } from 'react-native-reanimated';

export const useCarouselPageIndicator = () => {
  const progressValue = useSharedValue<number>(0);

  const onProgressChange = (_: number, absoluteProgress: number) => {
    progressValue.value = absoluteProgress;
  };

  return { onProgressChange, progressValue };
};
