import React, { RefObject, useMemo, useRef, useState } from 'react';

import { LayoutChangeEvent, View, useWindowDimensions } from 'react-native';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { useResponsive, useTheme } from '@app/ui';

import { TAB_MAX_WIDTH, getStyleSheet } from './TabView.styles.web';
import { Tab } from './TabView.type';
import { TabAnimationContextProvider, TabAnimationContext } from './TabViewContext';
import { TabBar } from './TabBar';
import { TabContent } from './TabContent.web';

interface TabViewProps {
  header: React.ReactNode;
  index: number;
  onIndexChange: (index: number) => void;
  swipeToNavigate?: boolean;
  tabs: Tab[];
  tabsRightElementWeb?: React.ReactNode;
  scrollViewRef?: RefObject<Animated.ScrollView>;
}

export const TabView: React.FC<TabViewProps> = ({
  header,
  tabs,
  onIndexChange,
  index,
  swipeToNavigate,
  tabsRightElementWeb,
  scrollViewRef: containerRef,
}) => {
  const [height, setHeight] = useState(0);
  const { width } = useWindowDimensions();

  const scrollViewRef = useRef<Animated.ScrollView>(null);
  const wrapperRef = useRef<View>(null);

  const theme = useTheme();
  const { isMobile, isLowerThanDesktop, isGreaterThanTablet } = useResponsive();
  const styles = useMemo(
    () =>
      getStyleSheet({
        isGreaterThanTablet,
        isLowerThanDesktop,
        isMobile,
        theme,
      }),
    [isGreaterThanTablet, isLowerThanDesktop, isMobile, theme]
  );

  const onLayout = () => {
    wrapperRef?.current &&
      wrapperRef.current.measureInWindow((x: number, y: number, w: number, h: number) => {
        setHeight(h);
      });
  };

  const yValue = useSharedValue(0);
  const headerHeight = useSharedValue(0);
  const tabBarBorderWidth = useDerivedValue(() => {
    if (yValue.value >= headerHeight.value) {
      return width;
    }

    const padding = isMobile
      ? theme.spacing.SIZE_00
      : isGreaterThanTablet
      ? theme.spacing.SIZE_10
      : theme.spacing.SIZE_15;
    return Math.min(TAB_MAX_WIDTH, width) - padding * 2;
  });

  const onHeaderLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    if (!headerHeight.value) {
      headerHeight.value = nativeEvent.layout.height;
    }
  };

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: e => {
      yValue.value = e.contentOffset.y;
    },
  });

  const tabBarBorderStyle = useAnimatedStyle(() => ({
    width: withTiming(tabBarBorderWidth.value, { duration: 150 }),
  }));

  return (
    <TabAnimationContextProvider index={index}>
      <TabAnimationContext.Consumer>
        {ctx => {
          const setIndex = (n: number) => {
            if (height > 0) {
              (containerRef ?? scrollViewRef).current?.scrollTo({ animated: false, y: height });
            }

            ctx.setIndex(n);
            onIndexChange(n);
          };

          return (
            <Animated.ScrollView
              onLayout={onLayout}
              contentContainerStyle={styles.headerWrapper}
              ref={containerRef ?? scrollViewRef}
              onScroll={scrollHandler}
              scrollEventThrottle={16}
            >
              {!!header && (
                <View ref={wrapperRef} style={styles.headerContainer} onLayout={onHeaderLayout}>
                  {header}
                </View>
              )}
              <View style={styles.tabsWrapper}>
                <View style={styles.tabsContainer}>
                  <TabBar setIndex={setIndex} index={ctx.index ?? 0} tabs={tabs} />
                </View>
                {!!tabsRightElementWeb && (
                  <View style={styles.rightElement}>{tabsRightElementWeb}</View>
                )}
              </View>
              <Animated.View style={[styles.tabsBorder, tabBarBorderStyle]} />

              <TabContent
                swipeToNavigate={swipeToNavigate}
                setIndex={setIndex}
                tabs={tabs}
                index={ctx.index ?? 1}
              />
            </Animated.ScrollView>
          );
        }}
      </TabAnimationContext.Consumer>
    </TabAnimationContextProvider>
  );
};
