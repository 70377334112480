import React, { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import { View, useWindowDimensions } from 'react-native';
import { LivingRoom } from '@assets/illustrations';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import LinearGradient from 'react-native-linear-gradient';

import { Chip, ChipProps, Image, useResponsive, useTheme } from '@app/ui';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { RealEstate, Role } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import { ShowCaseInfoChips } from '@app/features/show-case/compounds/show-case-info-chips/ShowCaseInfoChips';
import { ShowCaseCallout } from '@app/features/show-case/compounds/show-case-callout/ShowCaseCallout';
import { useRealEstateTitle } from '@app/shared/hooks/usePropertyTitle';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { isNative } from '@app/constants/platform';
import { RealEstateStickyTitle } from '@app/features/realEstate/realEstateHeader/components/RealEstateStickyTitle';
import { getRealEstateTag } from '@app/utils/real-estate/getRealEstateTag.utils';
import { hasPublicToken } from '@app/utils/getPublicToken';

import { useShareButton } from '../../realEstateHeader/hooks/useShareButton';
import { use3dUrlButton } from '../../realEstateHeader/hooks/use3dUrlButton';
import { RealEstateTopImagesAppointment } from '../../realEstateHeader/components/RealEstateTopImagesAppointment';
import { RealEstateAuthButtons } from '../../realEstateHeader/components/RealEstateAuthButtons';
import { RealEstateUrl3dCard } from '../../realEstateHeader/components/RealEstateUrl3dCard';
import { useLikeButton } from '../../realEstateHeader/hooks/useLikeButton';
import { useImagesGallery } from './hooks/useImagesGallery';
import { RealEstateCoverImageOverlay } from '../../realEstateHeader/components/RealEstateCoverImageOverlay';
import { getGridImageCellStyle, getStyles } from '../../realEstateHeader/realEstateHeader.styles';
import { getFormattedAddressFromProperty } from '../../realEstate.utils.web';
import { RealEstateTitle } from '../../realEstateHeader/components/RealEstateTitle';

interface RealEstateAvailableHeaderProps {
  realEstate: RealEstate;
  showPrivateInformations: boolean;
  onBack: () => void;
}

const RolesThatCanSeeTheHunter: Role[] = [
  Role.Advisor,
  Role.Hunter,
  Role.HunterManager,
  Role.Admin,
];

type RealEstateHeaderNavigation = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.RealEstate
>;

export const RealEstateAvailableHeader = forwardRef(
  (
    { realEstate, showPrivateInformations, onBack }: RealEstateAvailableHeaderProps,
    ref: ForwardedRef<View>
  ) => {
    const { customer, stateOfCustomer } = useMe();
    const navigation = useNavigation<RealEstateHeaderNavigation>();

    const {
      t,
      i18n: { resolvedLanguage },
    } = useTranslation();
    const theme = useTheme();
    const { height } = useWindowDimensions();
    const { isLowerThanLaptop, isMobile, isLaptop } = useResponsive();
    const styles = useMemo(
      () => getStyles({ height, isLaptop, isLowerThanLaptop, isMobile, theme }),
      [height, isLaptop, isLowerThanLaptop, theme, isMobile]
    );

    const title = useRealEstateTitle({
      city: realEstate.building.address.city,
      realEstateType: realEstate.type,
      surface: realEstate.surface,
      totalLots: realEstate.building.totalLots,
    });

    const isCustomerLogged = !!customer?.id;
    const hasPublicFullAccess = hasPublicToken();
    const canSeeGallery = isCustomerLogged || hasPublicFullAccess;
    const { address } = getFormattedAddressFromProperty(realEstate, t, isLowerThanLaptop);
    const isHunterNameVisible =
      customer?.account.roles &&
      RolesThatCanSeeTheHunter.some(role => customer?.account.roles?.includes(role));

    const { isFavorite, onPress: onPressLikeButton } = useLikeButton(
      realEstate.id,
      realEstate.metadata?.isFavorites
    );

    // TODO: Feature deactivited for now #removeShortTermRental
    // const hasShortTermRental = !!realEstate.project.renting.shortTerm;
    const hasShortTermRental = false;

    const { blurredCoverImageUrl, coverImageUrl, imagesCount, imagesGrid, openMainGallery } =
      useImagesGallery(realEstate, showPrivateInformations, canSeeGallery);

    const { shouldUseShare, onPress: onPressShareButton } = useShareButton(realEstate.id, title);

    const { onPress: onPress3dUrlButton, isButtonVisible: is3dUrlButtonVisible } = use3dUrlButton(
      realEstate.id,
      realEstate.url3D
    );

    const onPressAuthButton = () => navigation.navigate(PublicNavigatorRoutes.Auth);

    const renderChip = useCallback(
      (props: Partial<ChipProps>) => {
        const chipProps = getRealEstateTag({
          isExclusive: realEstate.isExclusive,
          isNew: realEstate.isNew || false,
          language: resolvedLanguage,
          shortTermNightlyRent: realEstate.project?.renting.shortTerm?.nightlyRent,
          translate: t,
        });
        return chipProps ? <Chip {...props} {...chipProps} /> : undefined;
      },
      [
        realEstate.isExclusive,
        realEstate.isNew,
        realEstate.project?.renting.shortTerm,
        t,
        resolvedLanguage,
      ]
    );

    return (
      <>
        {!isLowerThanLaptop && (
          <RealEstateStickyTitle
            address={showPrivateInformations ? address : undefined}
            isFavorite={isFavorite}
            onShare={onPressShareButton}
            shouldUseShare={shouldUseShare}
            title={title}
            onLike={isCustomerLogged ? onPressLikeButton : undefined}
            renderChip={renderChip}
          />
        )}

        <View style={styles.container}>
          <View style={styles.infoContainer}>
            {!!isLowerThanLaptop && (
              <RealEstateTitle
                title={title}
                address={showPrivateInformations ? address : undefined}
                hunter={isHunterNameVisible ? realEstate.hunter : undefined}
                renderChip={!isLowerThanLaptop && hasShortTermRental ? renderChip : undefined}
              />
            )}

            <ShowCaseInfoChips
              bookmarkCount={realEstate.metadata?.bookmarkCount}
              positioningCount={realEstate.metadata?.positioningCount}
            />
            <ShowCaseCallout realEstate={realEstate} />
          </View>

          <View style={styles.coverImageContainer}>
            <Image
              source={{ uri: coverImageUrl }}
              onPress={canSeeGallery ? openMainGallery(0) : undefined}
              style={styles.coverImage}
            >
              {isNative() && (
                <LinearGradient
                  colors={['rgba(0, 0, 0, 0.5)', 'transparent']}
                  end={{ x: 0.5, y: 0.3 }}
                  style={{ flex: 1 }}
                />
              )}
            </Image>

            {!!isLowerThanLaptop && (
              <RealEstateCoverImageOverlay
                imagesCount={stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT ? 2 : imagesCount}
                isFavorite={isFavorite}
                shouldUseShare={shouldUseShare}
                onBack={onBack}
                onLike={isCustomerLogged ? onPressLikeButton : undefined}
                onPress3dUrl={is3dUrlButtonVisible ? onPress3dUrlButton : undefined}
                onShare={onPressShareButton}
                renderChip={hasShortTermRental ? renderChip : undefined}
              />
            )}

            {!isLowerThanLaptop && (
              <View style={styles.imagesGrid}>
                {stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT ? (
                  <RealEstateTopImagesAppointment imageUrl={blurredCoverImageUrl} />
                ) : (
                  imagesGrid.map(({ url }, index) => (
                    <Image
                      key={url}
                      source={{ uri: url ?? LivingRoom }}
                      resizeMode={url ? 'cover' : 'contain'}
                      onPress={canSeeGallery ? openMainGallery(index + 1) : undefined}
                      style={[styles.imageCell, getGridImageCellStyle(index, theme)]}
                    />
                  ))
                )}

                {!!is3dUrlButtonVisible && <RealEstateUrl3dCard onPress={onPress3dUrlButton} />}
              </View>
            )}

            {!isCustomerLogged && !hasPublicFullAccess && (
              <RealEstateAuthButtons onPress={onPressAuthButton} ref={ref} />
            )}
          </View>
        </View>
      </>
    );
  }
);

RealEstateAvailableHeader.displayName = 'NewRealEstateHeader';
