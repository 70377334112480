import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { Animated, LayoutRectangle, Modal, useWindowDimensions, View } from 'react-native';

import { Input } from '@app/ui/atoms/input/Input';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';

import { useAnimations, memoizedLayout, ANIMATED_OFFSET } from './utils';
import { getStyles } from './Dropdown.styles';
import { DropdownProps } from './Dropdown.types';

export { DropdownProps };

export const DEFAULT_MESURES = {
  height: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  children,
  isOpen,
  onClose,
  onSearch,
  searchValue,
  fixedHeight,
  parentRef,
  maxWidth,
  anchorRight,
  searchPlaceholder = 'Quick search',
  animationType,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const shouldDisplaySearch = onSearch !== undefined && searchValue !== undefined;

  const [parentLayout, setParentLayout] = useState<LayoutRectangle | null>(DEFAULT_MESURES);
  const { display, animatedTiming } = useAnimations(isOpen);

  const dimensions = useWindowDimensions();
  const computedLayout = useMemo(
    () =>
      parentLayout ? memoizedLayout(parentLayout, dimensions, fixedHeight, anchorRight)() : {},
    [anchorRight, dimensions, fixedHeight, parentLayout]
  );

  useEffect(() => {
    parentRef.current?.measureInWindow((x, y, width, height) => {
      setParentLayout({
        height,
        width,
        x,
        y,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef?.current, dimensions, isOpen]);

  return (
    <Modal transparent visible={display} animationType={animationType} testID="Dropdown">
      <Pressable onPress={isOpen ? onClose : null} style={styles.overlay} testID="Dropdown-overlay">
        <Animated.ScrollView
          needsOffscreenAlphaCompositing
          style={[
            styles.dropdownContainer,
            computedLayout,
            {
              opacity: animatedTiming.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
              transform: [
                {
                  translateY: animatedTiming.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, computedLayout.bottom ? -ANIMATED_OFFSET : ANIMATED_OFFSET],
                  }),
                },
              ],
            },
            { maxWidth: maxWidth || parentLayout?.width },
          ]}
        >
          {shouldDisplaySearch ? (
            <View style={styles.inputContainer} testID="Dropdown-search">
              <Input
                isSearch
                placeholder={searchPlaceholder}
                onChangeText={onSearch}
                value={searchValue}
                inputWrapperStyle={styles.inputWrapperStyle}
                wrapperStyle={styles.wrapperStyle}
              />
            </View>
          ) : null}
          <View>{children}</View>
        </Animated.ScrollView>
      </Pressable>
    </Modal>
  );
};
