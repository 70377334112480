import React, { useMemo, useRef, useState } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import { ICarouselInstance } from 'react-native-reanimated-carousel';

import { useTranslation } from '@app/services/translations/translations';
import { ActionButton, LoaderDefault, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { GetCustomerSuggestionsQuery } from '@app/libs/apollo/introspection';
import { isWeb } from '@app/constants/platform';

import { HomePageRecommendationsCarousel } from './HomePageRecommendationCarousel';
import { formatSuggestions, suggestionsCallLaunched } from './Suggestions.utils';
import { SectionContainer } from './SectionContainer';

const getStyles = ({
  theme,
  isNativeOrResponsive,
  CARD_WIDTH,
}: {
  theme: Theme;
  isNativeOrResponsive: boolean;
  CARD_WIDTH: number;
}) =>
  StyleSheet.create({
    buttons: { flexDirection: 'row', marginBottom: theme.spacing.SIZE_06 },
    cardContainer: { flex: 1, marginLeft: theme.spacing.SIZE_06, maxWidth: CARD_WIDTH },
    carouselWrapper: { overflow: 'hidden' },
    container: {
      margin: isNativeOrResponsive ? 0 : theme.spacing.SIZE_06,
      zIndex: 500,
    },
    description: {
      marginBottom: theme.spacing.SIZE_06,
    },
    linearGradient: {
      borderBottomLeftRadius: isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderBottomRightRadius: isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_10,
      borderWidth: isNativeOrResponsive ? 0 : 1,
      paddingVertical: theme.spacing.SIZE_08,
    },
    loader: { alignItems: 'center', flex: 1, justifyContent: 'center' },

    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing.SIZE_03,
    },
  });

interface HomePageRecommendationsProps {
  data: GetCustomerSuggestionsQuery | undefined;
  loading: boolean;
}

export const HomePageRecommendations: React.FC<HomePageRecommendationsProps> = ({
  data,
  loading,
}) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(0);

  const { t } = useTranslation();
  const launchedCall = useReactiveVar(suggestionsCallLaunched);
  const { isNativeOrResponsive, isGreaterThanTablet } = useResponsive();

  const suggestionsLength = useMemo(() => data?.getCustomerSuggestions.length, [data]);

  const CARD_WIDTH = isNativeOrResponsive ? 308 : 400;

  const styles = getStyles({ CARD_WIDTH, isNativeOrResponsive, theme });
  const carouselRef = useRef<ICarouselInstance>(null);

  const suggestions = useMemo(() => formatSuggestions(data, launchedCall), [data, launchedCall]);

  const cardVisibleCount = useMemo(() => {
    if (width === 0) {
      return 0;
    }
    return Math.floor(width / CARD_WIDTH);
  }, [CARD_WIDTH, width]);

  const paginationDisabled = useMemo(() => {
    if (suggestionsLength && index + cardVisibleCount >= suggestionsLength) {
      return false;
    }

    return true;
  }, [suggestionsLength, index, cardVisibleCount]);

  const hasSuggestion = !!data?.getCustomerSuggestions.length;

  const onPressBtn = (i: number) => () => {
    setIndex(i);
    carouselRef?.current?.scrollTo({ animated: true, index: i });
  };

  const isBothPaginationDisabled = index === 0 && !paginationDisabled;

  if (!hasSuggestion) {
    return null;
  }

  return (
    <SectionContainer>
      <Text textStyle="Title3Medium">{t('homePage.recommandations.title')}</Text>

      {isWeb() && isGreaterThanTablet && suggestionsLength && !isBothPaginationDisabled ? (
        <View style={styles.wrapper}>
          <Text textStyle="Body3">{t('homePage.recommandations.subtitle')}</Text>
          <View style={styles.buttons}>
            <ActionButton
              withSolidBorder
              leftIconName="ArrowLeft"
              disabled={index === 0}
              onPress={onPressBtn(index - 1)}
            />
            <ActionButton
              withSolidBorder
              leftIconName="ArrowRight"
              disabled={!paginationDisabled}
              onPress={onPressBtn(index + 1)}
            />
          </View>
        </View>
      ) : (
        <View style={styles.description}>
          <Text textStyle="Body3">{t('homePage.projects.description')}</Text>
        </View>
      )}

      {loading ? (
        <View style={styles.loader}>
          <LoaderDefault variant="inLight" />
        </View>
      ) : null}
      {hasSuggestion ? (
        <HomePageRecommendationsCarousel suggestions={suggestions} inputRef={carouselRef} />
      ) : null}
    </SectionContainer>
  );
};
