import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChevronUp = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.295 15.635c-.197-.223-.295-.507-.295-.852s.098-.628.295-.851l4.94-5.597a.946.946 0 01.349-.26c.125-.05.26-.075.403-.075.143 0 .277.025.402.075a.947.947 0 01.35.26l4.966 5.627c.197.223.295.497.295.821 0 .325-.107.609-.322.852a.959.959 0 01-.752.335.959.959 0 01-.752-.335l-4.187-4.745-4.215 4.775a.93.93 0 01-.725.335c-.286 0-.537-.122-.752-.365z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChevronUp;
