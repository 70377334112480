import React, { useMemo, useState } from 'react';

import { Platform, StyleSheet, View } from 'react-native';

import { ShadowContainer, TabLabel, Theme, useResponsive, useTheme } from '@app/ui';
import { PointOfInterest } from '@app/libs/apollo/introspection';
import { kmConverter } from '@app/utils/kmConverter';

import {
  getEducationPointsOfInterest,
  getTransportsPointsOfInterest,
} from '../utils/pointsOfInterest.utils';
import { RowWithIcon } from './rowWithIcon/RowWithIcon';

type PointsOfInterestType = {
  pointsOfInterest: PointOfInterest[];
};

const TAB_BAR_HEIGHT = {
  DESKTOP: 65,
  MOBILE: 48,
};

const getStyles = (theme: Theme, isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    bottomTabBar: { backgroundColor: theme.palette.neutral[200], height: 1, width: '100%' },
    pointsOfInterest: {
      gap: theme.spacing.SIZE_05,
      paddingVertical: theme.spacing.SIZE_05,
    },
    shadowContainer: {
      borderRadius: theme.borderRadius.SIZE_05,
      paddingHorizontal: 12,
      paddingVertical: 0,
    },
    tabBarWrapper: {
      alignItems: Platform.select({
        default: 'center',
        web: 'stretch',
      }),
      backgroundColor: theme.palette.base.white,
      elevation: 0,
      flexDirection: 'row',
      height: isLowerThanDesktop ? TAB_BAR_HEIGHT.MOBILE : TAB_BAR_HEIGHT.DESKTOP,
      shadowOpacity: 0,
      ...Platform.select({
        default: {
          borderBottomColor: theme.palette.neutral[200],
          borderBottomWidth: 1,
          width: '100%',
        },
        web: { borderBottomWidth: 0 },
      }),
    },
    tabContainer: { flexDirection: 'row' },
  });

export const PointsOfInterest: React.FC<PointsOfInterestType> = ({ pointsOfInterest }) => {
  const transportPOIs = getTransportsPointsOfInterest(pointsOfInterest);
  const educationPOIs = getEducationPointsOfInterest(pointsOfInterest);

  const [tabIndexPOI, setTabIndexPOI] = useState(0);
  const [interestList, setInterestList] = useState(transportPOIs);

  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanDesktop), [theme, isLowerThanDesktop]);

  const handlePressTab = (index: number, pois: PointOfInterest[]) => () => {
    setTabIndexPOI(index);
    setInterestList(pois);
  };

  if (interestList.length === 0) {
    return null;
  }

  return (
    <ShadowContainer style={styles.shadowContainer}>
      <View style={styles.tabBarWrapper}>
        <View style={styles.tabContainer}>
          <TabLabel
            isActive={tabIndexPOI === 0}
            title="Transports"
            onPress={handlePressTab(0, transportPOIs)}
          />
          <TabLabel
            isActive={tabIndexPOI === 1}
            title="Éducation"
            onPress={handlePressTab(1, educationPOIs)}
          />
        </View>
      </View>
      <View style={styles.bottomTabBar} />

      <View style={styles.pointsOfInterest}>
        {interestList.map((POI, index) => (
          <RowWithIcon
            key={index}
            iconName={POI.subType}
            title={POI.label}
            value={kmConverter(POI.distanceValue)}
            numberOfLines={2}
          />
        ))}
      </View>
    </ShadowContainer>
  );
};
