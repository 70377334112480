import { Theme } from '@app/ui/theme/types';

export const badgeTheme = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.tint.red[500],
    borderRadius: theme.spacing.SIZE_03,
    height: theme.spacing.SIZE_03,
    width: theme.spacing.SIZE_03,
  };
};
