import React from 'react';

import { useFlag } from '@unleash/proxy-client-react';

import { palette, Spacer, Spacing } from '@app/ui';

import { MenuItem } from './compounds/MenuItem';

interface DebugMenuUnleashFlagProps {
  featureFlag: string;
}

const formatFeatureFlagName = name => {
  const formattedName: string = name.replace('feature-flag', '').split('-').join(' ').trim();

  return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
};

export const DebugMenuUnleashFlag: React.FC<DebugMenuUnleashFlagProps> = ({ featureFlag }) => {
  const enabled = useFlag(featureFlag);

  return (
    <React.Fragment key={featureFlag}>
      <MenuItem
        key={featureFlag}
        icon={enabled ? 'CheckFilled' : null}
        iconColor={enabled ? palette.tint.green[400] : palette.tint.red[400]}
        title={formatFeatureFlagName(featureFlag)}
        subtitle={formatFeatureFlagName(featureFlag)}
        isDisabled={!enabled}
        hasTopRadius
        hasBottomRadius
      />

      <Spacer height={Spacing.SIZE_03} />
    </React.Fragment>
  );
};
