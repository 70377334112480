import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

import {
  CheckboxTileIconStyleType,
  CheckboxTileLabelStyleType,
  CheckboxTileLayoutCheckboxType,
  CheckboxTileLayoutContainerType,
  CheckboxTileLayoutType,
  CheckboxTileStyleType,
  CheckboxTileVariantType,
  ColorStyle,
  Style,
} from './CheckboxTile.types';

const getSharedCheckboxStyle = (theme: Theme): CheckboxTileLayoutCheckboxType => {
  return {
    position: 'absolute',
    right: theme.spacing.SIZE_03,
    top: Platform.select({
      native: 28,
      web: theme.spacing.SIZE_03,
    }),
  };
};

const getSharedContainerStyle = (theme: Theme): Partial<CheckboxTileLayoutContainerType> => {
  return {
    alignItems: 'center',
    borderRadius: theme.spacing.SIZE_05,
    borderWidth: 1,
  };
};

export const getColumnLayout = (theme: Theme): CheckboxTileLayoutType => {
  return StyleSheet.create<CheckboxTileLayoutType>({
    checkbox: getSharedCheckboxStyle(theme),
    container: {
      ...getSharedContainerStyle(theme),
      height: 120,
      justifyContent: 'center',
      width: 120,
    },
  });
};

export const getColumnFillLayout = (theme: Theme): CheckboxTileLayoutType => {
  return StyleSheet.create<CheckboxTileLayoutType>({
    checkbox: getSharedCheckboxStyle(theme),
    container: {
      ...getSharedContainerStyle(theme),
      height: 120,
      justifyContent: 'center',
      minWidth: 120,
    },
  });
};

export const getRowLayout = (theme: Theme): CheckboxTileLayoutType => {
  return StyleSheet.create<CheckboxTileLayoutType>({
    checkbox: {
      position: 'absolute',
      right: theme.spacing.SIZE_03,
    },
    container: {
      ...getSharedContainerStyle(theme),
      flexDirection: 'row',
      height: theme.spacing.SIZE_10,
      minWidth: 200,
      paddingLeft: theme.spacing.SIZE_03,
    },
  });
};

export const getBorderColor = (theme: Theme, checked: boolean, disabled: boolean): string => {
  return checked && !disabled ? theme.palette.neutral[900] : theme.palette.neutral[200];
};

export const getBackgroundColor = ({ theme, disabled, hovered, checked }: ColorStyle): string => {
  const interaction = disabled || hovered || checked;

  return interaction ? theme.palette.neutral[50] : theme.palette.base.white;
};

export const getVariantLayout = (
  variant: CheckboxTileVariantType
): ((theme: Theme) => CheckboxTileLayoutType) => {
  switch (variant) {
    case CheckboxTileVariantType.Square:
      return getColumnLayout;
    case CheckboxTileVariantType.SquareFill:
      return getColumnFillLayout;
    case CheckboxTileVariantType.Default:
    default:
      return getRowLayout;
  }
};

const getVariantLabelStyle = (
  variant: CheckboxTileVariantType,
  theme: Theme
): CheckboxTileLabelStyleType => {
  switch (variant) {
    case CheckboxTileVariantType.SquareFill:
    case CheckboxTileVariantType.Square:
      return {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        paddingRight: theme.spacing.NONE,
        wordBreak: 'break-word',
      };
    case CheckboxTileVariantType.Default:
    default:
      return {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingRight: theme.spacing.SIZE_11,
        wordBreak: 'break-word',
      };
  }
};

export const getVariantIconStyle = (
  variant: CheckboxTileVariantType,
  theme: Theme
): Partial<CheckboxTileIconStyleType> => {
  switch (variant) {
    case CheckboxTileVariantType.SquareFill:
    case CheckboxTileVariantType.Square:
      return { alignItems: 'center' };
    case CheckboxTileVariantType.Default:
    default:
      return {
        marginLeft: theme.spacing.SIZE_03,
        marginRight: theme.spacing.NONE,
      };
  }
};

export const getCheckboxTileStyle = ({
  theme,
  disabled,
  hovered,
  checked,
  variant,
}: Style): CheckboxTileStyleType => {
  const layout = getVariantLayout(variant)(theme);

  return {
    checkbox: layout.checkbox,
    container: layout.container,
    containerColor: {
      backgroundColor: getBackgroundColor({
        checked,
        disabled,
        hovered,
        theme,
      }),
      borderColor: getBorderColor(theme, checked, disabled),
    },
    iconStyle: getVariantIconStyle(variant, theme),
    labelContainer: getVariantLabelStyle(variant, theme),
    textStyle: {
      color: theme.palette.neutral[disabled ? 500 : 900],
      flexWrap: 'wrap',
      paddingHorizontal: theme.spacing.SIZE_04,
      textAlign: 'center',
    },
  };
};
