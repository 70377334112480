import { NativeModules, Platform } from 'react-native';

import { parseParams } from '@app/shared/hooks/useQueryParams';

import { SupportedLanguage as APISupportedLanguage } from '../apollo/introspection';
import { I18nSupportedLanguage } from './i18n.types';

const i18NLangToAPILangMap: Record<I18nSupportedLanguage, APISupportedLanguage> = {
  [I18nSupportedLanguage.fr]: APISupportedLanguage.Fr,
  [I18nSupportedLanguage.es]: APISupportedLanguage.Es,
  [I18nSupportedLanguage.en]: APISupportedLanguage.En,
};

export const getAPILang = (regionCode: I18nSupportedLanguage): APISupportedLanguage =>
  i18NLangToAPILangMap[regionCode];

export const getDeviceLanguage = Platform.select({
  android: () => NativeModules.I18nManager.localeIdentifier,
  ios: () =>
    NativeModules.SettingsManager?.settings?.AppleLocale ||
    NativeModules.SettingsManager?.settings?.AppleLanguages,
  web: () => navigator.language,
});

export const mapDeviceLangToI18nLang = (lang: string): string => {
  return lang.replace('_', '-');
};

export const checkIsDebugModeRequested = (): boolean => {
  if (!window?.location) {
    return false;
  }
  const lang = parseParams(location.search)('lng');

  return lang === 'debug';
};
