import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgListChevronUp = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9.744 13.809c-.45 0-.763-.204-.937-.613-.176-.408-.105-.77.212-1.087l2.6-2.6c.1-.1.208-.175.325-.225a.942.942 0 01.375-.075c.133 0 .258.025.375.075.116.05.225.125.325.225l2.6 2.6c.316.317.387.679.212 1.087-.175.409-.487.613-.937.613h-5.15z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgListChevronUp;
