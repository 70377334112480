import React, { ReactElement } from 'react';

import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Button, Icon, IconName, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { isWeb } from '@app/constants/platform';
import { useTranslation } from '@app/services/translations/translations';

import { FurnishmentDetailsTemplate } from './FurnishmentDetailsTemplate';
import { furnishmentPreview } from '../../utils/furnishing';
import { TablePreview } from '../TablePreview';

interface FurnishmentPreviewTableProps {
  furnishingAmount: number | undefined;
  roomCount: number;
  toggleDrawer: (content: ReactElement | null) => void;
}

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_07,
      borderWidth: 1,
      gap: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
      padding: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_08,
    },
    drawer: isLowerThanLaptop ? { flex: 1 } : { minWidth: 423 },
    item: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    itemTitle: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
    },
    subtitle: {
      textAlign: 'center',
    },
    titleContainer: {
      alignItems: 'center',
      gap: theme.spacing.SIZE_03,
      justifyContent: 'center',
    },
  });

type FurnishmentPreviewTableNavigation = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

export const FurnishmentPreviewTable: React.FC<FurnishmentPreviewTableProps> = ({
  furnishingAmount = 0,
  roomCount,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop);

  const navigation = useNavigation<FurnishmentPreviewTableNavigation>();

  const onPressViewDetails = () => {
    if (isWeb()) {
      toggleDrawer(
        <View style={styles.drawer}>
          <FurnishmentDetailsTemplate
            furnishingAmount={furnishingAmount}
            roomCount={roomCount}
            onClose={() => toggleDrawer(null)}
          />
        </View>
      );
    } else {
      navigation.navigate(RealEstateNavigatorRoutes.FurnishmentDetails, {
        furnishingAmount,
        roomCount,
      });
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'}>
          {t('realEstate.renovation.furnishment.title')}
        </Text>
        <Text textStyle={isLowerThanLaptop ? 'Caption1' : 'Body3'} style={styles.subtitle}>
          {t('realEstate.renovation.furnishment.subtitle')}
        </Text>
      </View>

      <TablePreview
        title={!isLowerThanLaptop ? t('realEstate.renovation.furnishment.tableTitle') : undefined}
        total={furnishingAmount}
        totalLabel={t('realEstate.renovation.furnishment.totalLabel')}
        data={furnishmentPreview}
        renderItem={item => (
          <View style={styles.item}>
            <View style={styles.itemTitle}>
              <Icon name={item.icon as IconName} size={16} />
              <Text textStyle={isLowerThanLaptop ? 'Body2' : 'Body1'}>
                {t(`realEstate.renovation.furnishment.${item.title}`)}
              </Text>
            </View>

            {!!item.action && !isLowerThanLaptop && (
              <Button
                variant="secondary"
                iconName="ArrowRight"
                iconPlacement="right"
                size="s"
                onPress={onPressViewDetails}
              >
                {t(`realEstate.renovation.furnishment.${item.action}`)}
              </Button>
            )}
          </View>
        )}
      />

      {!!isLowerThanLaptop && (
        <Button
          variant="tertiary"
          iconName="ArrowRight"
          iconPlacement="right"
          size="l"
          onPress={onPressViewDetails}
        >
          {t('realEstate.renovation.furnishment.viewFurnishmentDetails')}
        </Button>
      )}
    </View>
  );
};
