import React from 'react';

import { ModalPosition } from '@app/ui';

export enum ModalKey {
  SignMandateInApp = 'SignMandateInApp',
  CongratsMandateSignedInApp = 'CongratsMandateSignedInApp',
  FirstAppointment = 'FirstAppointment',
  Positioning = 'Positioning',
  MissingDocument = 'MissingDocument',
  EnableNotifications = 'EnableNotifications',
  NotificationsSettings = 'NotificationsSettings',
  ApprovePositioning = 'ApprovePositioning',
  CancelPositioning = 'CancelPositioning',
  BeCalled = 'BeCalled',
  BeCalledConfirm = 'BeCalledConfirm',
  BeCalledError = 'BeCalledError',
  PushToSubscribe = 'PushToSubscribe',
}

export type ModalContextProps = {
  openModal?: (
    key: ModalKey,
    Component: React.ReactNode,
    options?: {
      excludes?: ModalKey[];
      title?: string | React.ReactNode;
      position?: ModalPosition;
      webSize?: 's' | 'm' | 'l';
      callbackClose?: (params?: unknown) => void;
    }
  ) => void;
  closeModal?: (params?: unknown) => void;
  clearExcludes?: () => void;
  setExcludes?: (excludes: ModalKey[]) => void;
  isVisible?: boolean;
  position?: ModalPosition;
  webSize?: 's' | 'm' | 'l';
  component?: React.ReactNode;
  setTitle?: (params?: unknown) => void;
  title?: string | React.ReactNode;
};
