import React, { useMemo } from 'react';

import { isWeb } from '@app/constants/platform';

import { BaseLinkType } from './BaseLink.types';
import { Pressable } from '../pressable/Pressable';

export const BaseLink: React.FC<BaseLinkType> = ({ href, onClick, testID, children, ...rest }) => {
  const clickHandler = useMemo(() => {
    if (!onClick) {
      return onClick;
    }

    return (e: React.MouseEvent<HTMLAnchorElement>) => {
      const { shiftKey, altKey, ctrlKey, metaKey } = e;
      if (!(shiftKey || altKey || ctrlKey || metaKey)) {
        e.preventDefault();
        onClick();
      }
    };
  }, [onClick]);

  if (isWeb()) {
    return (
      <a href={href} onClick={clickHandler} data-testid={testID} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Pressable {...rest} onPress={onClick} testID={testID}>
      {children}
    </Pressable>
  );
};

export { BaseLinkType };
