import * as React from 'react';

import Svg, { SvgProps } from 'react-native-svg';

const SvgMasteos = (props: SvgProps) => {
  return (
    <Svg width="353" height="50" viewBox="0 0 353 50" {...props}>
      <path
        d="M97.091 3.88416L111.197 37.8525L125.425 3.88416H136.005V49.0846H129.025V12.1474L113.605 49.0846H108.522L92.9807 12.1474V49.0846H86.1221V3.88416H97.091Z"
        fill="#030304"
      />
      <path
        d="M143.058 39.1391C143.058 31.7665 148.847 28.7482 157.213 27.3875C165.142 26.3484 167.501 25.3835 167.501 22.8105C167.501 20.4849 164.704 18.5552 159.888 18.5552C154.927 18.5552 150.817 20.8066 148.579 25.2103L144.007 21.2766C146.098 16.6997 151.571 12.6918 159.621 12.6918C168.498 12.6918 173.898 16.6997 173.898 22.2415V40.4256C173.898 42.5532 174.846 43.6665 176.5 43.6665C177.4 43.6665 178.154 43.4686 178.786 42.8996V48.194C177.449 49.3568 175.673 50 173.703 50C170.079 50 168.304 47.3528 167.671 43.9387C164.996 47.625 160.691 50 155.097 50C149.406 49.9753 143.058 46.8828 143.058 39.1391ZM156.654 44.3098C161.348 44.3098 164.899 42.2316 167.452 38.6938V30.1089C165.239 31.4696 161.542 32.2365 158.259 32.8303C152.106 33.8694 150.257 35.6754 150.257 38.8917C150.233 41.7863 151.887 44.3098 156.654 44.3098Z"
        fill="#030304"
      />
      <path
        d="M180.951 39.708L186.788 36.2196C188.563 41.5883 192.382 44.2849 198.413 44.2849C204.567 44.2849 207.291 41.712 207.291 39.0648C207.291 36.1702 205.077 34.9332 200.554 34.2157L194.717 33.3003C187.347 32.1375 183.286 29.0945 183.286 23.5526C183.286 17.1697 189.244 12.6422 197.441 12.6422C205.953 12.6422 210.89 16.9718 213.128 21.3508L207.68 24.8391C205.831 20.7075 202.475 18.3077 197.149 18.3077C192.698 18.3077 189.779 20.188 189.779 22.6867C189.779 25.4082 192.503 26.4967 196.711 27.14L202.353 27.9812C208.896 28.946 213.785 32.0385 213.785 38.372C213.785 45.423 206.804 49.9257 198.365 49.9257C190.096 49.9752 183.748 46.1652 180.951 39.708Z"
        fill="#030304"
      />
      <path
        d="M224.559 41.4646V19.6932H217.579V13.5576H224.559V4.32954L231.101 0V13.5576H240.489V19.6932H231.101V39.4607C231.101 43.0232 232.95 43.7902 235.674 43.7902C237.838 43.7902 239.419 43.3944 240.489 42.8253V49.0351C238.592 49.6784 236.671 50 234.336 50C230.031 49.9753 224.559 48.4908 224.559 41.4646Z"
        fill="#030304"
      />
      <path
        d="M244.381 31.3211C244.381 20.7323 251.361 12.667 261.017 12.667C270.867 12.667 277.701 20.2128 277.701 30.7521C277.701 31.6675 277.628 33.1519 277.58 33.7209H251.361C252.066 40.4255 255.861 44.186 261.576 44.186C266.854 44.186 269.383 41.0193 270.599 36.8135L277.02 39.3864C274.734 45.4478 269.456 49.9753 261.6 49.9753C251.239 49.9753 244.381 42.1079 244.381 31.3211ZM251.483 27.9565H270.453C269.505 21.8209 265.954 18.4562 260.992 18.4562C255.934 18.481 252.383 21.9693 251.483 27.9565Z"
        fill="#030304"
      />
      <path
        d="M282.979 31.3211C282.979 20.5344 289.448 12.667 299.663 12.667C309.83 12.667 316.348 20.5344 316.348 31.3211C316.348 42.1821 309.805 49.9753 299.663 49.9753C289.448 49.9753 282.979 42.1821 282.979 31.3211ZM309.514 31.3211C309.514 23.4537 305.525 18.5304 299.663 18.5304C293.632 18.5304 289.813 23.429 289.813 31.3211C289.813 39.2627 293.632 44.1118 299.663 44.1118C305.501 44.1118 309.514 39.2627 309.514 31.3211Z"
        fill="#030304"
      />
      <path
        d="M320.166 39.708L326.003 36.2196C327.779 41.5883 331.597 44.2849 337.629 44.2849C343.782 44.2849 346.506 41.712 346.506 39.0648C346.506 36.1702 344.293 34.9332 339.769 34.2157L333.932 33.3003C326.563 32.1375 322.501 29.0945 322.501 23.5526C322.501 17.1697 328.46 12.6422 336.656 12.6422C345.168 12.6422 350.106 16.9718 352.343 21.3508L346.895 24.8391C345.047 20.7075 341.69 18.3077 336.364 18.3077C331.913 18.3077 328.995 20.188 328.995 22.6867C328.995 25.4082 331.719 26.4967 335.926 27.14L341.569 27.9812C348.111 28.946 353 32.0385 353 38.372C353 45.423 346.02 49.9257 337.58 49.9257C329.311 49.9752 322.963 46.1652 320.166 39.708Z"
        fill="#030304"
      />
      <path
        d="M50.6369 3.88416C47.4022 3.88416 44.4106 4.94799 41.9785 6.72928C41.0543 7.39727 40.2274 8.18896 39.4734 9.05487C38.8167 9.82182 38.233 10.6382 37.7466 11.5289C37.1629 12.568 36.7008 13.706 36.3603 14.8936C35.9954 16.18 35.8252 17.5408 35.8252 18.951V38.7926V49.0846H63.4056H65.4486V18.951C65.4486 10.6135 58.8332 3.88416 50.6369 3.88416Z"
        fill="#030304"
      />
      <path
        d="M14.8117 3.88416C11.577 3.88416 8.58544 4.94799 6.1533 6.72928C5.22909 7.39727 4.40216 8.18896 3.6482 9.05487C2.99153 9.82182 2.40781 10.6382 1.92138 11.5289C1.33767 12.568 0.875568 13.706 0.535069 14.8936C0.170249 16.18 0 17.5408 0 18.951V38.7926V49.0846H27.5804H29.6234V18.951C29.6234 10.6135 22.9837 3.88416 14.8117 3.88416Z"
        fill="#030304"
      />
    </Svg>
  );
};

export default SvgMasteos;
