import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgActivity = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.273 3c.391 0 .738.248.862.615l4.592 13.639 1.865-5.539a.908.908 0 01.863-.615h3.636c.502 0 .909.403.909.9s-.407.9-.91.9h-2.98l-2.52 7.485a.908.908 0 01-.863.615.909.909 0 01-.862-.615L9.273 6.746l-1.865 5.539a.908.908 0 01-.863.615H2.91A.905.905 0 012 12c0-.497.407-.9.91-.9h2.98l2.52-7.485A.908.908 0 019.273 3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgActivity;
