import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgFm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="fm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#fm_svg__a)">
        <Path fill="#95CEF5" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.433 6.824L14 5.76l1.567 1.064-.527-1.82 1.496-1.161-1.893-.06L14 2l-.643 1.782-1.893.06 1.496 1.163-.527 1.819zm0 10.667L14 16.427l1.567 1.064-.527-1.82 1.496-1.162-1.893-.06L14 12.667l-.643 1.782-1.893.06 1.496 1.162-.527 1.82zm6.9-6.398l-1.567 1.064.528-1.82-1.497-1.161 1.894-.06.642-1.783.643 1.782 1.893.061-1.496 1.162.528 1.82-1.568-1.065zM7.1 12.157l1.568-1.064 1.567 1.064-.527-1.82 1.496-1.161-1.894-.06-.642-1.783-.643 1.782-1.893.061 1.496 1.162-.528 1.82z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgFm;
