import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgAx = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ax_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ax_svg__a)">
        <Path fill="#157CBB" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12h8v8h4v-8h16V8H12V0H8v8H0v4z"
          fill="#FFD34D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.667h9.333V20h1.334v-9.333H28V9.333H10.667V0H9.333v9.333H0v1.334z"
          fill="#EB363A"
        />
      </G>
    </Svg>
  );
};

export default SvgAx;
