import styled from '@emotion/styled';
import { StyleSheet } from 'react-native';

import { palette, Spacing } from '@app/ui';

export const InputError = styled.p`
  color: ${palette.alert.error};
  text-align: center;
`;

export const FormStyleSheets = StyleSheet.create({
  button: {
    alignSelf: 'center',
    marginBottom: Spacing.SIZE_05,
    minWidth: 225,
  },
  input: {
    marginBottom: 32,
    width: '100%',
  },
});
