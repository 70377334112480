import { CustomDetector } from 'i18next-browser-languagedetector';

import { getDeviceLanguage, mapDeviceLangToI18nLang } from '../helper';

export const nativeDetector: CustomDetector = {
  lookup() {
    const deviceLanguage = getDeviceLanguage();
    if (Array.isArray(deviceLanguage) && deviceLanguage.length > 0) {
      return deviceLanguage.map(mapDeviceLangToI18nLang);
    }
    if (typeof deviceLanguage === 'string') {
      return [mapDeviceLangToI18nLang(deviceLanguage)];
    }
    return undefined;
  },
  name: 'native',
};
