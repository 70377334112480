import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgRadar = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm0-2a7.854 7.854 0 0 0 4.9-1.675L15.475 16.9a5.75 5.75 0 0 1-1.612.813A6.005 6.005 0 0 1 12 18c-1.667 0-3.083-.583-4.25-1.75C6.583 15.083 6 13.667 6 12c0-1.667.583-3.083 1.75-4.25C8.917 6.583 10.333 6 12 6c1.667 0 3.083.583 4.25 1.75C17.417 8.917 18 10.333 18 12c0 .65-.1 1.275-.3 1.875-.2.6-.475 1.142-.825 1.625l1.425 1.425a8.169 8.169 0 0 0 1.25-2.275c.3-.833.45-1.717.45-2.65 0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Zm0-4c.367 0 .72-.046 1.063-.137.341-.092.662-.23.962-.413L12.5 13.925a.917.917 0 0 1-.25.063A2.521 2.521 0 0 1 12 14c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 12 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .1-.004.196-.012.287a.929.929 0 0 1-.063.263l1.5 1.5A3.853 3.853 0 0 0 16 12c0-1.1-.392-2.042-1.175-2.825C14.042 8.392 13.1 8 12 8s-2.042.392-2.825 1.175C8.392 9.958 8 10.9 8 12s.392 2.042 1.175 2.825C9.958 15.608 10.9 16 12 16Z"
    />
  </Svg>
);
export default SvgRadar;
