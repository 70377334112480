import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    inputContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputWrapperStyle: {
      marginTop: theme.spacing.NONE,
    },
    wrapperStyle: {
      width: '100%',
    },
  });
