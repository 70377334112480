import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgWorks = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3.745 21.32a1 1 0 01-1-1v-2.836a1 1 0 01.293-.707l4.207-4.207-3.875-3.9c-.2-.2-.346-.425-.437-.675a2.054 2.054 0 010-1.487c.091-.242.237-.463.437-.663l1.9-1.9c.2-.2.425-.346.675-.438a2.075 2.075 0 011.488 0c.241.092.462.238.662.438l3.9 3.9 4.475-4.5c.1-.1.208-.175.325-.225a.942.942 0 01.375-.075 1.03 1.03 0 01.725.3L20.72 6.17c.1.1.175.208.225.325.05.116.075.241.075.375a1 1 0 01-.3.725l-4.475 4.475 3.875 3.9c.2.2.345.425.437.675a2.054 2.054 0 010 1.487 1.866 1.866 0 01-.437.663l-1.9 1.9c-.2.2-.421.345-.663.437a2.053 2.053 0 01-1.487 0 1.843 1.843 0 01-.675-.437l-3.9-3.9-4.207 4.23a1 1 0 01-.709.295H3.745zM8.67 11.145l1.9-1.9-1.2-1.2-1.2 1.175-1.4-1.4 1.175-1.2L6.67 5.37l-1.875 1.9 3.875 3.875zm8.125 8.125l1.9-1.9-1.275-1.25-1.175 1.175-1.425-1.4 1.2-1.2-1.225-1.2-1.9 1.9 3.9 3.875zm-12.05.05h1.4l9.625-9.6-1.425-1.425-9.6 9.625v1.4zM17.17 8.295l1.425-1.425-1.425-1.4-1.4 1.4 1.4 1.425z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgWorks;
