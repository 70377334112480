export const translations = {
  en: {
    false: 'No',
    null: 'No matter',
    true: 'Yes',
  },
  es: {
    false: 'No',
    null: 'No importa',
    true: 'Si',
  },
  fr: {
    false: 'Non',
    null: 'Peu importe',
    true: 'Oui',
  },
  // TODO
  nl: {
    false: '',
    null: '',
    true: '',
  },
};
