import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgHk = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="hk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#hk_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28V0H0v20z" fill="#F0371D" />
        <Path
          d="M13.984 8.146c.963-.806 1.021-.986 1.252-1.696.077-.237.023-.432-.041-.662-.093-.331-.206-.736.025-1.447-1.05-.34-2.271.518-2.726 1.919-.455 1.4.027 2.812 1.078 3.154.188.06.167.017.11-.103-.1-.21-.311-.652.302-1.165zM11.99 9.618c-.469-1.165-.622-1.277-1.226-1.715-.202-.147-.404-.155-.642-.165-.344-.015-.764-.032-1.368-.472-.65.894-.21 2.32.982 3.186 1.191.866 2.683.843 3.333-.05.116-.16.067-.154-.064-.137-.23.03-.716.095-1.015-.647zm6.01.636c-.747 0-.936 0-2-.667-.678-.424-1.033-.087-1.202.073-.096.091-.131.125-.131-.073 0-1.104 1.194-2 2.666-2 1.473 0 2.667.896 2.667 2-.747 0-1.097.233-1.384.423-.198.132-.367.244-.616.244zM18 9a.333.333 0 11-.667 0A.333.333 0 0118 9zm-2.748 2.95c-.305 1.218-.247 1.398-.016 2.108.077.237.235.363.422.51.27.215.6.476.83 1.186 1.05-.341 1.533-1.753 1.078-3.154-.455-1.4-1.675-2.26-2.726-1.918-.188.061-.145.084-.028.147.204.111.635.345.44 1.12zm1.176 1.72a.333.333 0 11-.205-.633.333.333 0 01.206.634zm-3.654-1.701c-1.253.086-1.406.197-2.01.636-.202.146-.272.336-.355.56-.12.322-.267.716-.871 1.155.649.893 2.141.916 3.333.05 1.191-.865 1.63-2.291.981-3.185-.116-.16-.125-.112-.149.018-.042.23-.131.71-.93.766zm-1.273 1.65a.333.333 0 11.54-.391.333.333 0 01-.54.391zm-1.474-4.702a.333.333 0 11.54.392.333.333 0 01-.54-.392zm4.017-2.855a.333.333 0 11-.206.634.333.333 0 01.206-.634z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgHk;
