import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Theme, useResponsive, useTheme } from '@app/ui';

type SectionContainerProps = {
  backgroundColor?: string;
  children: React.ReactNode;
};

const getStyles = ({
  isMobile,
  theme,
  backgroundColor,
}: {
  isMobile: boolean;
  theme: Theme;
  backgroundColor?: string;
}) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor,
      padding: isMobile ? theme.spacing.SIZE_07 : theme.spacing.SIZE_12,
    },
    wrapper: {
      maxWidth: 928,
      width: '100%',
    },
  });

export const SectionContainer: React.FC<SectionContainerProps> = ({
  backgroundColor,
  children,
}) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const styles = getStyles({ backgroundColor, isMobile, theme });
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>{children}</View>
    </View>
  );
};
