import React, { PropsWithChildren, useState } from 'react';

import { Role } from '@app/libs/apollo/introspection';
import { TrackingOverlay } from '@app/features/debugMenu/TrackingOverlay';
import { useAuthorized } from '@app/shared/hooks/useAuthorized';

export type DebugMenuContextType = {
  isTrackingOverlayEnable: boolean;
  trackHistory: {
    event: string;
    properties: unknown;
    timestamp: number;
  }[];
  showTrackingOverlay: () => void;
  hideTrackingOverlay: () => void;
  toggleTrackingOverlay: () => void;
  pushTrackEvent: (item: { event: string; properties: unknown }) => void;
};

export const DebugMenuContext = React.createContext<DebugMenuContextType>({
  hideTrackingOverlay: () => undefined,
  isTrackingOverlayEnable: false,
  pushTrackEvent: () => undefined,
  showTrackingOverlay: () => undefined,
  toggleTrackingOverlay: () => undefined,
  trackHistory: [],
});

export const authorizedRoles = [Role.Admin, Role.Dev];

export const DebugMenuProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isTrackingOverlayEnable, setIsTrackingOverlayEnable] = useState(false);
  const [trackHistory, setTrackHistory] = useState<DebugMenuContextType['trackHistory']>([]);
  const { isAuthorizedCustomer } = useAuthorized(authorizedRoles);

  const showTrackingOverlay = () => setIsTrackingOverlayEnable(isAuthorizedCustomer);
  const hideTrackingOverlay = () => setIsTrackingOverlayEnable(false);
  const toggleTrackingOverlay = () => setIsTrackingOverlayEnable(!isTrackingOverlayEnable);

  const pushTrackEvent = item =>
    setTrackHistory(prev => [...prev, { ...item, timestamp: Date.now() }]);

  const context: DebugMenuContextType = {
    hideTrackingOverlay,
    isTrackingOverlayEnable,
    pushTrackEvent,
    showTrackingOverlay,
    toggleTrackingOverlay,
    trackHistory,
  };
  return (
    <DebugMenuContext.Provider value={context}>
      <>
        {isAuthorizedCustomer ? (
          <>
            <TrackingOverlay />
          </>
        ) : null}
        {children}
      </>
    </DebugMenuContext.Provider>
  );
};
