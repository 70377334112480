import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StyleSheet } from 'react-native';

import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
// eslint-disable-next-line max-len
import { RenovationDetailsTemplate } from '@app/features/realEstate/realEstateAvailable/realEstateRenovation/components/renovationDetails/RenovationDetailsTemplate';
import { Theme, useTheme } from '@app/ui';

type RenovationDetailsViewProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RenovationDetails
>;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    safeArea: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },
  });

export const RenovationDetailsView: React.FC<RenovationDetailsViewProps> = ({
  route,
  navigation,
}) => {
  const { renovationAmount, details } = route.params;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <SafeAreaView style={styles.safeArea} edges={['top']}>
      <RenovationDetailsTemplate
        renovationAmount={renovationAmount}
        details={details}
        onClose={navigation.goBack}
      />
    </SafeAreaView>
  );
};
