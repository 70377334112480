import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTicket = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 18v-4c.55 0 1.021-.196 1.413-.588C3.804 13.021 4 12.55 4 12c0-.55-.196-1.021-.587-1.413A1.928 1.928 0 002 10V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v4c-.55 0-1.021.196-1.413.587A1.928 1.928 0 0020 12c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588v4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 20H4zm0-2h16v-2.55a4.097 4.097 0 01-1.462-1.462A3.846 3.846 0 0118 12c0-.717.18-1.38.538-1.988A4.096 4.096 0 0120 8.55V6H4v2.55a4.105 4.105 0 011.463 1.462C5.821 10.621 6 11.283 6 12c0 .717-.179 1.38-.537 1.988A4.105 4.105 0 014 15.45V18zm8-1a.968.968 0 00.713-.288A.967.967 0 0013 16a.97.97 0 00-.287-.713A.97.97 0 0012 15a.967.967 0 00-.712.287A.968.968 0 0011 16c0 .283.096.52.288.712A.965.965 0 0012 17zm0-4a.968.968 0 00.713-.288A.967.967 0 0013 12a.97.97 0 00-.287-.713A.97.97 0 0012 11a.967.967 0 00-.712.287A.968.968 0 0011 12c0 .283.096.52.288.712A.965.965 0 0012 13zm0-4a.968.968 0 00.713-.288A.967.967 0 0013 8a.97.97 0 00-.287-.713A.97.97 0 0012 7a.967.967 0 00-.712.287A.968.968 0 0011 8c0 .283.096.52.288.712A.965.965 0 0012 9z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTicket;
