import { Theme } from '@app/ui/theme/types';

export const sectionTheme = (theme: Theme) => {
  return {
    background: {
      active: theme.palette.neutral[50],
      default: theme.palette.base.white,
    },
    defaultIcons: {
      color: theme.palette.base.black,
      size1: theme.spacing.SIZE_06,
      size2: theme.spacing.SIZE_05,
    },
    description: theme.palette.neutral[600],
    itemStyles: {
      border: {
        backgroundColor: theme.palette.neutral[100],
      },
      icon: {
        marginRight: theme.spacing.SIZE_05,
      },
      mainView: {
        marginVertical: theme.spacing.SIZE_06,
      },
      titleWrapper: {
        marginRight: theme.spacing.SIZE_08,
      },
    },
    mainViewStyle: {
      marginVerticalMedium: theme.spacing.SIZE_08,
      marginVerticalSmall: theme.spacing.SIZE_05,
    },
  };
};
