import { Maybe, RealEstate, RealEstateSubPartBuilding } from '@app/libs/apollo/introspection';
import { StateOfCustomer } from '@app/shared/hooks/useMe';

import { SliderImageObject } from '../../../gallery/gallery.types';

const sliderImageObjectAdaptor = (
  descriptonWithImage: Maybe<RealEstateSubPartBuilding> | undefined,
  sectionName: string
): SliderImageObject[] => {
  return (
    descriptonWithImage?.images?.map(imageUrl => ({
      description: descriptonWithImage.description ?? '',
      title: `shared.descriptionFields.${sectionName}`,
      translation: descriptonWithImage.translation,
      url: imageUrl,
    })) ?? []
  );
};

export const getHeaderSliderImages = (
  property: RealEstate,
  isLocationInformationVisible: boolean,
  stateOfCustomer: StateOfCustomer | null
): SliderImageObject[] => {
  if (!property) {
    return [];
  }

  const cover = { title: 'coverImage', url: property.coverImage };
  if (stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT) {
    return [cover];
  }
  const inside = sliderImageObjectAdaptor(property.inside, 'inside');
  const outside = sliderImageObjectAdaptor(property.building.outside, 'outside');
  const common = sliderImageObjectAdaptor(property.building.common, 'common');
  const neighborhood = sliderImageObjectAdaptor(property.building.neighborhood, 'neighborhood');

  if (isLocationInformationVisible) {
    return [cover, ...inside, ...outside, ...common, ...neighborhood];
  }
  return [cover, ...inside, ...common];
};
