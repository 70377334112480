import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgAo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ao_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ao_svg__a)">
        <Path fill="url(#ao_svg__paint0_linear_4223_10327)" d="M0 0h28v20H0z" />
        <G filter="url(#ao_svg__filter0_d_4223_10327)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-9.333H0V20z" fill="#262626" />
        </G>
        <G filter="url(#ao_svg__filter1_d_4223_10327)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 10.667h28V0H0v10.667z" fill="#DD2137" />
        </G>
        <G filter="url(#ao_svg__filter2_d_4223_10327)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.198 11.553a4 4 0 00-2.44-5.848.667.667 0 00-.344 1.288 2.667 2.667 0 011.67 3.817l-2.047-1.365a.667.667 0 10-.74 1.11l1.877 1.251a2.66 2.66 0 01-2.14.339.667.667 0 10-.346 1.287 3.996 3.996 0 003.66-.844l.949.633a.667.667 0 00.74-1.109l-.84-.56zm-3.531-2.886a.667.667 0 100-1.334.667.667 0 000 1.334z"
            fill="#F8D84B"
          />
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="ao_svg__paint0_linear_4223_10327"
          x1={0}
          y1={0}
          x2={0}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FF323E" />
          <Stop offset={1} stopColor="#FD0D1B" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgAo;
