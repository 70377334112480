import { useContext } from 'react';

import { TFunction } from 'react-i18next';

import { RealEstateContext } from '@app/shared/contexts/realEstate/RealEstateProvider';

import { useRegionTranslation } from './useRegionTranslations';

export const useRealEstateTranslation = (): { t: TFunction } => {
  const realEstateContext = useContext(RealEstateContext);

  return useRegionTranslation(
    realEstateContext.realEstate ? realEstateContext.selectRegionCode() : null
  );
};
