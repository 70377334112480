import {
  SearchEngineFilterFormHorizontal,
  SearchEngineFiltersHorizontalProps,
} from '../search-engine-filters-horizontal/SearchEngineFiltersHorizontal.types';

export { SearchSortStrategy as SearchEngineSortStrategy } from '@app/libs/apollo/introspection';
export const { SORT_STRATEGY } = SearchEngineFilterFormHorizontal;
export type SearchEngineSortProps = Pick<
  SearchEngineFiltersHorizontalProps,
  'onSubmit' | 'onSubmitError' | 'form' | 'defaultValues'
>;
