import { TextStyle } from 'react-native';

import { isWeb } from '@app/constants/platform';

import { palette } from './palette';

export enum FontFamilies {
  AvenirNextLTPro = 'AvenirNextLTPro',
}

export const Fonts = {
  [FontFamilies.AvenirNextLTPro]: isWeb()
    ? {
        bold: 'AvenirNextLTPro-Bold, AvenirNext, sans-serif',
        demi: 'AvenirNextLTPro-Demi, AvenirNext, sans-serif',
        heavy: 'AvenirNextLTPro-Heavy, AvenirNext, sans-serif',
        light: 'AvenirNextLTPro-Light, AvenirNext, sans-serif',
        medium: 'AvenirNextLTPro-Medium, AvenirNext, sans-serif',
        regular: 'AvenirNextLTPro-Regular, AvenirNext, sans-serif',
        thin: 'AvenirNextLTPro-Thin, AvenirNext, sans-serif',
      }
    : {
        bold: 'AvenirNextLTPro-Bold',
        demi: 'AvenirNextLTPro-Demi',
        heavy: 'AvenirNextLTPro-Heavy',
        light: 'AvenirNextLTPro-Light',
        medium: 'AvenirNextLTPro-Medium',
        regular: 'AvenirNextLTPro-Regular',
        thin: 'AvenirNextLTPro-Thin',
      },
};

export const FONT_WEIGHTS: {
  [index: string]: TextStyle['fontWeight'];
} = {
  demi: '700',
  light: '300',
  medium: '500',
  regular: '400',
  semibold: '600',
};

export const TEXT_STYLES = {
  Body1: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Body1Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Body1Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 18,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Body1Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Body2: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  Body2Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  Body2Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  Body2Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  Body3: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 17.5,
  },
  Body3Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 17.5,
  },
  Body3Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 17.5,
  },
  Body3Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 17.5,
  },
  Button1: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 17,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 24,
  },
  Button2: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  Button3: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 16,
  },
  Caption1: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 15,
  },
  Caption1Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 15,
  },
  Caption1Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 15,
  },
  Caption1Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 15,
  },
  Caption2: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 10,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 12.5,
  },
  Caption2Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 10,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 12.5,
  },
  Caption2Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 10,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 12.5,
  },
  Caption2Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 10,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 12.5,
  },
  Display1: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 76,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 95,
  },
  Display1Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 76,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 95,
  },
  Display1Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 76,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 95,
  },
  Display1Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 76,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 95,
  },
  Display2: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 60,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 75,
  },
  Display2Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 60,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 75,
  },
  Display2Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 60,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 75,
  },
  Display2Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 60,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 75,
  },
  Display3: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 54,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 68,
  },
  Display3Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 54,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 68,
  },
  Display3Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 54,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 68,
  },
  Display3Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 54,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 68,
  },
  Headline1: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 24,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 30,
  },
  Headline1Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 24,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 30,
  },
  Headline1Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 24,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 30,
  },
  Headline1Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 24,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 30,
  },
  Headline2: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 22,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 25,
  },
  Headline2Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 22,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 25,
  },
  Headline2Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 22,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 25,
  },
  Headline2Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 22,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 25,
  },
  Headline3: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 20,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Headline3Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 20,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Headline3Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Headline3Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 20,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Link1: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 22.5,
  },
  Link2: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  Link3: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 17.5,
  },
  Link4: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 15,
  },
  Link5: {
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 10,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 12.5,
  },
  Paragraph1: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 29,
  },
  Paragraph1Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 29,
  },
  Paragraph1Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 18,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 29,
  },
  Paragraph1Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 18,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 29,
  },
  Paragraph2: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 26,
  },
  Paragraph2Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 26,
  },
  Paragraph2Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 26,
  },
  Paragraph2Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 26,
  },
  Paragraph3: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 23,
  },
  Paragraph3Demi: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 23,
  },
  Paragraph3Italic: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 23,
  },
  Paragraph3Medium: {
    color: palette.neutral[800],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 23,
  },
  Title1: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 48,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 60,
  },
  Title1Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 48,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 60,
  },
  Title1Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 48,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 60,
  },
  Title1Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 48,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 60,
  },
  Title2: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 40,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 50,
  },
  Title2Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 40,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 50,
  },
  Title2Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 40,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 50,
  },
  Title2Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 40,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 50,
  },
  Title3: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 32,
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 40,
  },
  Title3Demi: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].demi,
    fontSize: 32,
    fontWeight: FONT_WEIGHTS.demi,
    letterSpacing: 0.25,
    lineHeight: 40,
  },
  Title3Italic: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].regular,
    fontSize: 32,
    fontStyle: 'italic',
    fontWeight: FONT_WEIGHTS.regular,
    letterSpacing: 0.25,
    lineHeight: 40,
  },
  Title3Medium: {
    color: palette.neutral[900],
    fontFamily: Fonts[FontFamilies.AvenirNextLTPro].medium,
    fontSize: 32,
    fontWeight: FONT_WEIGHTS.medium,
    letterSpacing: 0.25,
    lineHeight: 40,
  },
};

export type TextType = keyof typeof TEXT_STYLES;
