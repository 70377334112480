import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBroom = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11 11h2V4a.97.97 0 00-.287-.713A.97.97 0 0012 3a.967.967 0 00-.712.287A.968.968 0 0011 4v7zm-6 4h14v-2H5v2zm-1.45 6H6v-2c0-.283.096-.52.287-.712A.968.968 0 017 18c.283 0 .521.096.713.288A.967.967 0 018 19v2h3v-2c0-.283.096-.52.288-.712A.965.965 0 0112 18c.283 0 .521.096.713.288A.967.967 0 0113 19v2h3v-2c0-.283.096-.52.288-.712A.965.965 0 0117 18c.283 0 .52.096.712.288A.965.965 0 0118 19v2h2.45l-1-4H4.55l-1 4zm16.9 2H3.55c-.65 0-1.175-.258-1.575-.775a1.894 1.894 0 01-.35-1.725L3 15v-2c0-.55.196-1.021.587-1.413A1.928 1.928 0 015 11h4V4c0-.833.292-1.542.875-2.125A2.893 2.893 0 0112 1c.833 0 1.542.292 2.125.875S15 3.167 15 4v7h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2l1.375 5.5c.217.633.121 1.208-.287 1.725-.409.517-.955.775-1.638.775z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgBroom;
