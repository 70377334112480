import { PropertyChangesFilter } from '@app/services/tracking/trackTracking';
import { SearchEngineFilterFormProps } from '@app/features/search-engine/searchEngine.types';
import { getDefaultFormValues } from '@app/features/search-engine/compounds/search-engine-filters/utils/get-default-form-values';
import { filterToPreferencesMapper } from '@app/features/search-engine/compounds/search-engine-filters/utils/filter-to-preferences.mapper';

// FIXME: unit tests
export const getFilterChanges = (filter: SearchEngineFilterFormProps): PropertyChangesFilter => {
  const { capacity, ...f } = filter;
  const defaultValues = getDefaultFormValues({ capacity });
  const preferences = filterToPreferencesMapper({ ...defaultValues, ...f });

  return Object.entries(filterToPreferencesMapper(defaultValues)).reduce((acc, [key, value]) => {
    acc[key] =
      Array.isArray(value) && Array.isArray(preferences[key])
        ? value.length !== preferences[key].length ||
          value.some(v => v !== preferences[key].includes(v))
        : preferences[key] !== value;

    return acc;
  }, {} as PropertyChangesFilter);
};
