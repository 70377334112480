import { SelectGroupProps } from './SelectGroup.types';

export const getIsAllSelected = (
  optionGroups: SelectGroupProps['optionGroups'],
  values: SelectGroupProps['values']
): boolean => {
  const allValues = optionGroups
    .map(optionGroup => optionGroup.options.map(option => option.key))
    .flat();

  return allValues.every(value => values?.includes(value));
};
