import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgImage = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.624 17.144h10c.2 0 .35-.092.45-.275a.44.44 0 00-.05-.525l-2.75-3.675a.475.475 0 00-.4-.2c-.167 0-.3.067-.4.2l-2.6 3.475-1.85-2.475a.475.475 0 00-.4-.2c-.167 0-.3.067-.4.2l-2 2.675a.44.44 0 00-.05.525c.1.183.25.275.45.275zm-2 4c-.55 0-1.02-.196-1.413-.587a1.928 1.928 0 01-.587-1.413v-14c0-.55.196-1.021.587-1.413a1.928 1.928 0 011.413-.587h14c.55 0 1.021.195 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.02-.587 1.413a1.928 1.928 0 01-1.413.587h-14zm0-2h14v-14h-14v14z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgImage;
