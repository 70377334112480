import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgTram = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7.425 22.975a.83.83 0 01-.638-.625c-.091-.333-.02-.617.213-.85l.5-.5c-.983 0-1.813-.337-2.487-1.012C4.338 19.313 4 18.483 4 17.5V8c0-1.617.708-2.675 2.125-3.175S9.167 4.05 11 4l.75-1.5h-4A.728.728 0 017 1.75.728.728 0 017.75 1h8.5a.728.728 0 01.75.75.728.728 0 01-.75.75h-2.5L13 4c1.983.05 3.646.32 4.988.813C19.329 5.303 20 6.367 20 8v9.5c0 .983-.337 1.813-1.012 2.488S17.483 21 16.5 21l.5.5c.233.233.304.517.212.85a.83.83 0 01-.637.625.97.97 0 01-.438 0 .805.805 0 01-.387-.225L14 21h-4l-1.75 1.75a.805.805 0 01-.388.225.97.97 0 01-.437 0zM12 18c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0012 15c-.417 0-.77.146-1.063.438A1.446 1.446 0 0010.5 16.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437zm-6-6h12V9H6v3zm1.5 7h9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075V14H6v3.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425zm4.45-13c-2.233 0-3.667.12-4.3.362S6.6 6.817 6.4 7h11.25c-.2-.233-.633-.458-1.3-.675-.667-.217-2.133-.325-4.4-.325z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgTram;
