import React, { useMemo } from 'react';

import { View, useWindowDimensions } from 'react-native';
import { LivingRoom } from '@assets/illustrations';
import LinearGradient from 'react-native-linear-gradient';

import { Chip, ChipProps, Image, useResponsive, useTheme } from '@app/ui';
import { useRealEstateTitle } from '@app/shared/hooks/usePropertyTitle';
import { useTranslation } from '@app/services/translations/translations';
import { SoldRealEstateV2 } from '@app/libs/apollo/introspection';
import { RealEstateStickyTitle } from '@app/features/realEstate/realEstateHeader/components/RealEstateStickyTitle';
import { isNative } from '@app/constants/platform';

import { useShareButton } from '../../realEstateHeader/hooks/useShareButton';
import { use3dUrlButton } from '../../realEstateHeader/hooks/use3dUrlButton';
import { RealEstateUrl3dCard } from '../../realEstateHeader/components/RealEstateUrl3dCard';
import { RealEstateActionButtons } from '../../realEstateHeader/components/RealEstateActionButtons';
import { useImagesGallery } from './hooks/useImagesGallery';
import { RealEstateCoverImageOverlay } from '../../realEstateHeader/components/RealEstateCoverImageOverlay';
import { getGridImageCellStyle, getStyles } from '../../realEstateHeader/realEstateHeader.styles';
import { RealEstateTitle } from '../../realEstateHeader/components/RealEstateTitle';

interface RealEstateSoldHeaderProps {
  realEstate: SoldRealEstateV2;
  onBack: () => void;
}

export const RealEstateSoldHeader: React.FC<RealEstateSoldHeaderProps> = ({
  realEstate,
  onBack,
}: RealEstateSoldHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { isLowerThanLaptop, isMobile, isLaptop } = useResponsive();
  const styles = useMemo(
    () => getStyles({ height, isLaptop, isLowerThanLaptop, isMobile, theme }),
    [height, isLaptop, isLowerThanLaptop, theme, isMobile]
  );

  const title = useRealEstateTitle({
    city: realEstate.building.address.city,
    realEstateType: realEstate.type,
    surface: realEstate.surface,
    totalLots: realEstate.building.totalLots ?? undefined,
  });

  const { coverImageUrl, imagesCount, imagesGrid, openMainGallery } = useImagesGallery(realEstate);

  const { shouldUseShare, onPress: onPressShareButton } = useShareButton(realEstate.id, title);

  const { onPress: onPress3dUrlButton, isButtonVisible: is3dUrlButtonVisible } = use3dUrlButton(
    realEstate.id,
    realEstate.url3D ?? undefined
  );

  const renderChip = (props: Partial<ChipProps>): React.ReactElement<typeof Chip> => {
    return <Chip {...props} iconName="Pricetag" text={t('propertyPreview.isSold')} tint="pink" />;
  };

  return (
    <>
      {!isLowerThanLaptop && (
        <RealEstateStickyTitle
          onShare={onPressShareButton}
          shouldUseShare={shouldUseShare}
          title={title}
          renderChip={renderChip}
        />
      )}

      <View style={styles.container}>
        {!!isLowerThanLaptop && (
          <View style={styles.infoContainer}>
            <View style={styles.titleAndActionButtons}>
              <RealEstateTitle title={title} />
              {!isLowerThanLaptop && (
                <RealEstateActionButtons
                  isFavorite={false}
                  onShare={onPressShareButton}
                  shouldUseShare={shouldUseShare}
                />
              )}
            </View>
          </View>
        )}

        <View style={styles.coverImageContainer}>
          <Image
            source={{ uri: coverImageUrl }}
            onPress={openMainGallery(0)}
            style={styles.coverImage}
          >
            {isNative() && (
              <LinearGradient
                colors={['rgba(0, 0, 0, 0.5)', 'transparent']}
                end={{ x: 0.5, y: 0.3 }}
                style={{ flex: 1 }}
              />
            )}
          </Image>

          {!!isLowerThanLaptop && (
            <RealEstateCoverImageOverlay
              imagesCount={imagesCount}
              isFavorite={false}
              shouldUseShare={shouldUseShare}
              onBack={onBack}
              onPress3dUrl={is3dUrlButtonVisible ? onPress3dUrlButton : undefined}
              onShare={onPressShareButton}
              renderChip={renderChip}
            />
          )}

          {!isLowerThanLaptop && (
            <View style={styles.imagesGrid}>
              {imagesGrid.map(({ url }, index) => (
                <Image
                  key={url}
                  source={{ uri: url ?? LivingRoom }}
                  resizeMode={url ? 'cover' : 'contain'}
                  onPress={openMainGallery(index + 1)}
                  style={[styles.imageCell, getGridImageCellStyle(index, theme)]}
                />
              ))}
              {!!is3dUrlButtonVisible && <RealEstateUrl3dCard onPress={onPress3dUrlButton} />}
            </View>
          )}
        </View>
      </View>
    </>
  );
};
