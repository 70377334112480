import React from 'react';

import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Chip, ChipProps, Text } from '@app/ui';
import { RealEstate } from '@app/libs/apollo/introspection';

const styles = StyleSheet.create({
  address: {
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  titleContainer: {
    flexDirection: 'row',
  },
  titleTag: {
    marginLeft: 12,
  },
  titleTagContainer: {
    justifyContent: 'center',
  },
});

type RealEstateHeaderTitleProps = {
  title: string;
  address?: string;
  hunter?: RealEstate['hunter'];
  renderChip?: (props: Partial<ChipProps>) => React.ReactElement<typeof Chip> | undefined;
};

export const RealEstateTitle: React.FC<RealEstateHeaderTitleProps> = ({
  title,
  address,
  hunter,
  renderChip,
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text textStyle="Headline1Medium" style={styles.title}>
          {title}
        </Text>
        {!!renderChip && (
          <View style={styles.titleTagContainer} testID="real-estate-header-title-chip">
            {renderChip({ size: 'small', style: styles.titleTag })}
          </View>
        )}
      </View>

      {!!address && (
        <Text textStyle="Body2" style={styles.address} testID="real-estate-header-title-address">
          {address}
        </Text>
      )}

      {!!hunter && (
        <Text textStyle="Body2" testID="real-estate-header-title-hunter">
          {t('propertyInfo.hunterName', hunter)}
        </Text>
      )}
    </View>
  );
};
