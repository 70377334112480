import React from 'react';

import { BannerKey } from '@app/shared/contexts/banner-manager/BannerManager.types';

export enum BannerManagerActions {
  OPEN_BANNER = 'OPEN_BANNER',
  CLOSE_BANNER = 'CLOSE_BANNER',
}

export interface BannerManagerState {
  component: React.ReactNode;
  isDisplayed: boolean;
  bannerKey?: BannerKey;
  callbackClose?: (params?: unknown) => void;
}

interface ActionOpenBanner {
  type: BannerManagerActions.OPEN_BANNER;
  payload?: BannerManagerState;
}

interface ActionCloseBanner {
  type: BannerManagerActions.CLOSE_BANNER;
}

export type BannerManagerAction = ActionOpenBanner | ActionCloseBanner;
