import React, { CSSProperties, useState } from 'react';

import { View } from 'react-native';

import { Button, Spacer, Spacing, useResponsive, Input, Text, BottomSheet } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { useDeleteAccount } from './hooks/useDeleteAccount.hook';

interface ProfileDeleteAccountModalProps {
  hasAPassword: boolean;
  isVisibleDeleteAccountModal: boolean;
  toggleProfileDeleteAccountModal: () => void;
}

const getStyles = (isLowerThanDesktop: boolean) => ({
  cancelButton: { order: isLowerThanDesktop ? 3 : 1 },
  deleteButton: { order: isLowerThanDesktop ? 1 : 3 },
  modalContainer: { maxWidth: 600 },
  spacerBetweenButtons: { order: 2 },
  viewButton: {
    display: 'flex',
    flexDirection: (isLowerThanDesktop ? 'column' : 'row') as CSSProperties['flexDirection'],
    width: '100%',
  },
});
const getButtonWrapperStyle = (isLowerThanDesktop: boolean) => !isLowerThanDesktop && { flex: 1 };
const getPasswordInputWrapperStyle = (isLowerThanDesktop: boolean) => ({
  width: isLowerThanDesktop ? '100%' : 295,
});

export const ProfileDeleteAccountModal: React.FC<ProfileDeleteAccountModalProps> = ({
  hasAPassword,
  isVisibleDeleteAccountModal,
  toggleProfileDeleteAccountModal,
}) => {
  const { t } = useTranslation();
  const { isLowerThanDesktop } = useResponsive();

  const [password, setPassword] = useState('');
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const deleteAccount = useDeleteAccount(hasAPassword, setInputErrorMessage);
  const [isLoading, setIsLoading] = useState(false);

  const styles = getStyles(isLowerThanDesktop);
  const disableButton = hasAPassword && password.length < 1;

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    await deleteAccount(password).catch(() => setIsLoading(false));
  };

  return (
    <BottomSheet
      onPressClose={toggleProfileDeleteAccountModal}
      visible={isVisibleDeleteAccountModal}
      webSize="m"
    >
      <View style={styles.modalContainer}>
        <Text textStyle="Title3Medium">{t('profile.deleteAccountModal.title')}</Text>

        {!!hasAPassword && (
          <>
            <Text textStyle="Body2">{t('profile.deleteAccountModal.description')}</Text>
            <Spacer height={Spacing.SIZE_08} />
            <Input
              error={inputErrorMessage}
              errorMessageTestID="profile-delete-account-modal-input-password-error-message"
              isPassword
              label={t('profile.deleteAccountModal.inputLabel')}
              onChangeText={setPassword}
              testID="profile-delete-account-modal-input-password"
              value={password}
              wrapperStyle={getPasswordInputWrapperStyle(isLowerThanDesktop)}
            />
          </>
        )}

        <Spacer height={isLowerThanDesktop ? Spacing.SIZE_07 : Spacing.SIZE_08} />

        <div style={styles.viewButton}>
          <Spacer height={Spacing.SIZE_09 - Spacing.SIZE_07} />
          <Button
            variant="secondary"
            onPress={toggleProfileDeleteAccountModal}
            wrapperStyle={getButtonWrapperStyle(isLowerThanDesktop)}
            testID="delete-account-modal-cancel-button"
            style={styles.cancelButton}
          >
            {t('profile.deleteAccountModal.cancelButton')}
          </Button>
          <div style={styles.spacerBetweenButtons}>
            <Spacer height={Spacing.SIZE_03} width={Spacing.SIZE_03} />
          </div>
          <Button
            disabled={disableButton}
            isLoading={isLoading}
            onPress={handleDeleteAccount}
            style={styles.deleteButton}
            testID="delete-account-modal-submit-button"
            variant="danger"
            wrapperStyle={getButtonWrapperStyle(isLowerThanDesktop)}
          >
            {t('profile.deleteAccountModal.submitButton')}
          </Button>
        </div>
      </View>
    </BottomSheet>
  );
};
