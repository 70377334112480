import React, { useMemo } from 'react';

import { StyleSheet, useWindowDimensions, View } from 'react-native';

import { Modal } from '@app/ui';
import { PointOfInterest } from '@app/libs/apollo/introspection';
import { isWeb } from '@app/constants/platform';

import { GeoMap } from './GeoMap';
import { RealEstateMapInfo } from './RealEstateMapInfo';

interface RealEstateDescriptionGoogleMapModalProps {
  coord: {
    lat: number;
    lng: number;
  };
  title: string;
  address: string;
  coverImageURL: string;
  isVisibleGoogleMapModal: boolean;
  setIsVisibleGoogleMapModal: () => void;
  pointsOfInterest: PointOfInterest[];
}

const getStyle = (height: number) =>
  StyleSheet.create({
    mapWrapper: {
      alignItems: 'center',
      height: isWeb() ? '100vh' : height,
      justifyContent: 'flex-end',
      width: '100%',
    },
  });

export const RealEstateMapModal: React.FC<RealEstateDescriptionGoogleMapModalProps> = ({
  coord,
  isVisibleGoogleMapModal,
  setIsVisibleGoogleMapModal,
  title,
  pointsOfInterest,
  address,
  coverImageURL,
}) => {
  const { height } = useWindowDimensions();
  const styles = useMemo(() => getStyle(height), [height]);

  return (
    <Modal
      visible={isVisibleGoogleMapModal}
      onPressClose={setIsVisibleGoogleMapModal}
      position="fullscreen"
      noContainerPadding
      crossSecondary
      leftPositionCross
      withSafeAreaView
    >
      <View style={styles.mapWrapper}>
        <GeoMap pointsOfInterest={pointsOfInterest} coord={coord} />
      </View>

      <RealEstateMapInfo title={title} address={address} coverImageURL={coverImageURL} />
    </Modal>
  );
};
