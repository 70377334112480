import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Button, Chip, IconName, Text, Theme, useTheme } from '@app/ui';
import { isWeb } from '@app/constants/platform';
import { TimelineStepStatus } from '@app/libs/apollo/introspection';

import { getIconColorChip, getIconNameChip } from './Step.utils';
import { StepWrapper } from '../StepWrapper/StepWrapper';
import { BreakPoint, sectionPaddingForScreen, useActiveBreakPoint } from '../../SectionContainer';

const marginForScreen: Record<BreakPoint, number> = {
  desktop: 0,
  laptop: 0,
  nativeOrResponsive: sectionPaddingForScreen.nativeOrResponsive,
  tablet: sectionPaddingForScreen.tablet,
  wide: 0,
};

const getStyles = ({
  theme,
  status,
  size,
  activeBreakPoint,
}: {
  theme: Theme;
  status: TimelineStepStatus;
  size: 'small' | 'large';
  activeBreakPoint: BreakPoint;
}) =>
  StyleSheet.create({
    chipContainer: {
      alignSelf: 'flex-start',
    },
    container: {
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[100],
      borderRadius: size === 'large' ? theme.borderRadius.SIZE_08 : theme.borderRadius.SIZE_07,
      borderWidth: 1,
      gap: 19,
      marginHorizontal: marginForScreen[activeBreakPoint],
      marginVertical: !isWeb() ? theme.spacing.SIZE_05 : theme.spacing.SIZE_03,
      padding: theme.spacing.SIZE_05,
      zIndex: 100,
      ...(isWeb() && {
        width: size === 'large' ? 365 : 240,
      }),
      height: size === 'large' ? 230 : 240,
    },
    description: {
      color:
        status === TimelineStepStatus.Inactive
          ? theme.palette.neutral[500]
          : theme.palette.neutral[800],
    },
    textContainer: {
      height: size === 'large' ? 80 : 98,
      marginBottom: theme.spacing.SIZE_02,
    },
    title: {
      color:
        status === TimelineStepStatus.Inactive
          ? theme.palette.neutral[700]
          : theme.palette.neutral[800],
      marginBottom: size === 'large' ? theme.spacing.SIZE_03 : theme.spacing.SIZE_00,
    },
  });

export type StepProps = {
  iconName: IconName;
  chipText: string;
  title: string;
  status: TimelineStepStatus;
  description: string;
  size?: 'small' | 'large';
  buttonLabel: string;
  onPress: () => void;
};

export const Step: React.FC<StepProps> = ({
  iconName,
  chipText,
  status,
  title,
  buttonLabel,
  size = 'large',
  description,
  onPress,
}) => {
  const theme = useTheme();
  const activeBreakPoint = useActiveBreakPoint();

  const styles = getStyles({ activeBreakPoint, size, status, theme });

  const iconNameChip = getIconNameChip(status, iconName);

  const iconColorChip = getIconColorChip(status);

  return (
    <StepWrapper
      key={title}
      style={styles.container}
      testID="stepContainer"
      shadowSize={50}
      status={status}
    >
      <View style={styles.chipContainer}>
        <Chip iconName={iconNameChip} size="medium" tint={iconColorChip} text={chipText} />
      </View>

      <View style={styles.textContainer}>
        <Text textStyle="Headline1Medium" style={styles.title}>
          {title}
        </Text>
        <Text textStyle="Caption1" style={styles.description}>
          {description}
        </Text>
      </View>

      {status !== TimelineStepStatus.Completed && (
        <Button
          onPress={onPress}
          iconName="ArrowRight"
          iconPlacement="right"
          size="m"
          disabled={![TimelineStepStatus.Active, TimelineStepStatus.Error].includes(status)}
        >
          {buttonLabel}
        </Button>
      )}
    </StepWrapper>
  );
};
