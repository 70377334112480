import { CustomerPreferences } from '@app/libs/apollo/introspection';
import { SearchEngineFilterFormProps } from '@app/features/search-engine/searchEngine.types';

export const preferencesToFilterMapper = ({
  budgetMax,
  budgetMin,
  countryCode: country,
  hasNeedRenovation: withRenovation,
  propertyKind: typology,
  propertyOnTheGroundFloor: atGroundFloor,
  propertyRented: alreadyRented,
  propertyRoomCount: roomCount,
  regions: regionList,
  zones,
  sharedProperty: flatSharing,
  capacity,
  sortStrategy,
  withShortTermRental,
}: CustomerPreferences & {
  capacity: number | null;
}): SearchEngineFilterFormProps => ({
  alreadyRented,
  atGroundFloor,
  budgetMax,
  budgetMin,
  capacity,
  country,
  flatSharing,
  regionList: regionList || [],
  roomCount,
  sortStrategy,
  typology,
  withRenovation,
  withShortTermRental,
  zones: zones || [],
});
