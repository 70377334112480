import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { IconName } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { SoldRealEstateV2 } from '@app/libs/apollo/introspection';

export const useGetSynthesisRows = (
  financialStrategy: SoldRealEstateV2['afterRenovation']['financialStrategy'],
  surface: number
): Rows[] => {
  const { t } = useRealEstateTranslation();

  return [
    {
      data: currencyFormatter(financialStrategy.projectAmount, 0),
      icon: 'Wallet',
      testID: 'real-estate-sold-data-block-project-amount',
      title: t('propertySold.projectAmount'),
    },
    {
      data: currencyFormatter(financialStrategy.renovationAmount, 0),
      icon: 'Works',
      testID: 'real-estate-sold-data-block-renovation-amount',
      title: t('propertySold.renovationAmount'),
    },
    {
      data: `${surface} m²`,
      icon: 'Area',
      testID: 'real-estate-sold-data-block-surface',
      title: t('propertySold.surface'),
    },
    {
      data: `${financialStrategy.rentingDelay} ${t('shared.days')}`,
      icon: 'Clock',
      testID: 'real-estate-sold-data-block-renting-delay',
      title: t('propertySold.rentingDelay'),
    },
  ];
};

interface Rows {
  icon: IconName;
  title: string;
  data: string;
  testID: string;
  hint?: React.ReactNode;
}
