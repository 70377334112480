import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="kr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#kr_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z"
          fill="#E01B41"
        />
        <Mask
          id="kr_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={9}
          y={5}
          width={10}
          height={10}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#kr_svg__b)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.333 10.667c1.334 1.25 3.334 2 4.667 0 1.333-2 4-2 4.667 0 .666 2 0 4 0 4H9.333s-1.333-5.25 0-4z"
            fill="#0E4B9C"
          />
        </G>
        <Path
          opacity={0.75}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.173 3.557a.333.333 0 01.455-.122l.578.333c.16.092.214.296.122.455L5.66 7.11a.333.333 0 01-.455.122l-.578-.333a.333.333 0 01-.122-.456l1.667-2.886zm14.621.21a.333.333 0 00-.122.456L22.34 7.11c.092.16.296.214.455.122l.578-.333a.333.333 0 00.122-.456l-1.667-2.886a.333.333 0 00-.455-.122l-.578.333zm-1.732 1.001a.333.333 0 00-.122.455l1.667 2.887c.092.16.296.214.455.122l.578-.333a.333.333 0 00.122-.456l-1.667-2.886a.333.333 0 00-.455-.122l-.578.333zM4.506 13.557a.333.333 0 01.122-.456l.578-.333a.333.333 0 01.455.122l1.667 2.887a.333.333 0 01-.122.455l-.578.333a.333.333 0 01-.455-.122l-1.667-2.886zM6.36 12.1a.333.333 0 00-.122.456l1.667 2.886c.092.16.296.214.455.122l.578-.333a.333.333 0 00.122-.455L7.393 11.89a.333.333 0 00-.455-.122l-.578.333zm14.247-.211a.333.333 0 01.455-.122l.578.333c.159.092.214.296.122.456l-1.667 2.886a.333.333 0 01-.455.122l-.578-.333a.333.333 0 01-.122-.455l1.667-2.887zm2.187.878a.333.333 0 00-.455.122l-1.667 2.887a.333.333 0 00.122.455l.578.333c.159.092.363.038.455-.122l1.667-2.886a.333.333 0 00-.122-.456l-.578-.333zM8.36 4.435a.333.333 0 00-.455.122L6.238 7.443a.333.333 0 00.122.456l.578.333c.16.092.363.037.455-.122L9.06 5.223a.333.333 0 00-.122-.455l-.578-.333z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgKr;
