import { AvailableCountryCode, useGetLocationsQuery } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import { ListItemUnionProps } from '@app/ui';
import { ChosenOption } from '@app/ui/organisms/select/Select.types';
import { handleMultipleSelectChange } from '@app/utils/select-formatter';

import { getAllRegionOption } from '../../utils/get-all-region-option';
import { getRegionOptionList } from '../../utils/get-region-option-list';

interface UseRegionSelect {
  handleRegionChange: (
    onChange: (next: string[]) => void,
    list: ListItemUnionProps[]
  ) => (target: ChosenOption) => void;
  regionOptionList: ListItemUnionProps[];
}

export const useRegionSelect = (currentCountry: AvailableCountryCode | null): UseRegionSelect => {
  const { t } = useTranslation();
  const { data: getLocationsData } = useGetLocationsQuery();

  const regionListByCountry =
    (getLocationsData?.getLocations.locations ?? []).reduce(
      (acc, loc) => {
        acc[loc.countryCode] = loc.regions;
        return acc;
      },
      { ES: [], FR: [] } as { ES: string[]; FR: string[] }
    ) || {};

  const regionOptionList = getRegionOptionList(
    currentCountry ?? AvailableCountryCode.Fr,
    regionListByCountry,
    getAllRegionOption(t)
  );

  const handleRegionChange: (
    onChange: (nextList: string[]) => void,
    list: ListItemUnionProps[]
  ) => (target: ChosenOption) => void = (onChange, list) => (target: ChosenOption) => {
    let nextState: ChosenOption | ListItemUnionProps[] = target;

    /**
     * If we click on the option "all" then we need to add/remove all region list
     * (excluding the option "all") to the form
     */
    if (target.value === null) {
      nextState =
        list.length < regionOptionList.length - 1
          ? regionOptionList.filter(v => v.value !== null)
          : [];
    }

    handleMultipleSelectChange(onChange, list)(nextState);
  };

  return { handleRegionChange, regionOptionList };
};
