import { useContext } from 'react';

import { useActor } from '@xstate/react';
import { Event, EventData, SingleOrArray } from 'xstate';

import { OnboardingStateContext } from '../OnboardingContext';
import {
  OnboardingMachineContext,
  OnboardingMachineEvent,
  OnboardingServiceInterpreter,
  OnboardingServiceState,
} from './stateMachine.types';

export interface UseOnboardingStateMachine {
  context: OnboardingMachineContext;
  send: (
    event: SingleOrArray<Event<OnboardingMachineEvent>> | Event<OnboardingMachineEvent>,
    payload?: EventData
  ) => OnboardingServiceState;
  state: OnboardingServiceState;
  service: OnboardingServiceInterpreter;
}

export const useOnboardingStateMachine = (): UseOnboardingStateMachine => {
  const onboardingStateServices = useContext(OnboardingStateContext);
  const [state] = useActor(onboardingStateServices.onboardingService);

  return {
    context: state.context,
    send: onboardingStateServices.onboardingService.send,
    service: onboardingStateServices.onboardingService,
    state,
  };
};
