import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { AdvisorView } from '@app/views/advisor/AdvisorView';
import { AdvisorAppointmentView } from '@app/views/advisor/AdvisorAppointmentView';
import { AdvisorNavigatorRoutes, AdvisorStackParamList } from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';

const Stack = createStackNavigator<AdvisorStackParamList>();

export const AdvisorNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={AdvisorNavigatorRoutes.AdvisorRoot}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={AdvisorNavigatorRoutes.AdvisorRoot} component={AdvisorView} />
    <Stack.Screen
      name={AdvisorNavigatorRoutes.AdvisorAppointment}
      component={AdvisorAppointmentView}
    />
  </Stack.Navigator>
);
