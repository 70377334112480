export const guides_FR = [
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/3.jpg',
    index: 1,
    link: 'https://www.masteos.com/guide/investir/rendement-locatif/bon-rendement-locatif',
    title: 'Quel est le bon taux de rendement locatif ?',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/1.jpg',
    index: 2,
    link: 'https://www.masteos.com/guide/fiscalite/fiscalite-location/imposition-plus-value-immobiliere',
    title: 'Plus-value immobilière en SCI, quelle imposition ?',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/4.jpg',
    index: 3,
    link: 'https://www.masteos.com/guide/investir/rendement-locatif/est-ce-rentable-d-acheter-un-appartement-pour-le-louer',
    title: 'Est-ce qu’il est rentable d’acheter pour louer dans l’immobilier en 2023 ?',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/5.jpg',
    index: 4,
    link: 'https://www.masteos.com/guide/investir/acheter-un-bien-immobilier/devenir-rentier-immobilier',
    title: "Est-il possible de devenir rentier avec l'immobilier ?",
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/2.jpg',
    index: 5,
    link: 'https://www.masteos.com/guide/investir/espagne/acheter-espagne-2023',
    title: 'Investir en Espagne : nos bons plans pour 2023',
  },
];

export const guides_ES = [
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/3.jpg',
    index: 1,
    link: 'https://www.masteos.com/es/guia/mejores-barrios-donde-invertir-en-vivienda-en-espana-en-2023',
    title: '¿Dónde invertir 100 000 €? 4 activos rentables',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/1.jpg',
    index: 2,
    link: 'https://www.masteos.com/es/guia/como-y-donde-invertir-100-000-euros-2023-4-activos-muy-rentables',
    title: 'Los mejores barrios dónde invertir en 2023',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/4.jpg',
    index: 3,
    link: 'https://www.masteos.com/es/guia/como-se-hace-modelo-contrato-alquiler-temporal-plantilla-gratis',
    title: '¿Cómo se hace un contrato de alquiler temporal?',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/5.jpg',
    index: 4,
    link: 'https://www.masteos.com/es/guia/guia-paso-paso-comprar-piso-alquilar-espana',
    title: 'Guía paso a paso para comprar un piso en España',
  },
  {
    img: 'https://ik.imagekit.io/masteos/tr:ar-1,w-1080,q-100/masteosapp/homepage/2.jpg',
    index: 5,
    link: 'https://www.masteos.com/es/guia/cuales-impuestos-compra-propiedades-espana-por-extranjeros',
    title: '¿Qué impuestos pagan los extranjeros en España al comprar una vivienda?',
  },
];
