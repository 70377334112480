import React, { useMemo } from 'react';

import { Text, View } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

import { BubbleCountProps } from './BubbleCount.types';
import { getStyles } from './BubbleCount.styled';

export const BubbleCount: React.FC<BubbleCountProps> = ({ size, count }) => {
  const theme = useTheme();
  const styles = getStyles(theme, size);

  const countToDisplay = useMemo(() => (count && count > 99 ? '99+' : count), [count]);

  return (
    <View testID="BubbleCount-counter" style={styles.counterWrapper}>
      {size !== 's' && <Text style={styles.counterText}>{countToDisplay}</Text>}
    </View>
  );
};
