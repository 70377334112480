import React, { CSSProperties } from 'react';

import { Text } from '@app/ui/atoms/Text/Text';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { checkboxTheme } from '@app/ui/atoms/checkbox/checkbox.theme';

import type { CheckboxProps } from '../Checkbox.types';
import { CheckboxInput } from './CheckboxInput';
import { ContentContainer, SideContent, getLabelStyles } from './Checkbox.styles';

const getWeakId = () => `${Math.random() * 1000}`.split('.')[1];

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  children,
  disabled = false,
  errorMessage,
  hideErrorMessage = true,
  label,
  onChange,
  testID,
  error = false,
  size = 'l',
  style,
}) => {
  const componentTheme = useComponentTheme('checkbox', checkboxTheme);
  const id = getWeakId();

  if (label && children) {
    console.warn('If you provide a child the label is not displayed');
  }

  return (
    <div style={style as CSSProperties}>
      <ContentContainer hideErrorMessage={hideErrorMessage}>
        <CheckboxInput
          testID={testID}
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          error={error}
          size={size}
        />
        {(!!children || !!label) && (
          <SideContent>
            {children}
            {!children && !!label && (
              <div>
                <label
                  htmlFor={id}
                  style={getLabelStyles(componentTheme, size, disabled) as CSSProperties}
                >
                  {label}
                </label>
              </div>
            )}
          </SideContent>
        )}
      </ContentContainer>
      {!hideErrorMessage && (
        <Text
          textStyle="Caption1"
          style={{
            color: componentTheme.color.error,
          }}
        >
          {errorMessage || ' '}
        </Text>
      )}
    </div>
  );
};
