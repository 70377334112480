import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgVu = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="vu_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#vu_svg__a)">
        <G filter="url(#vu_svg__filter0_d_4223_12092)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-8H0v8z" fill="#009543" />
        </G>
        <G filter="url(#vu_svg__filter1_d_4223_12092)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 8h28V0H0v8z" fill="#D21034" />
        </G>
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 12h28V8H0v4z" fill="#262626" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 0l13.333 10L0 20V0z" fill="#262626" />
        <Path d="M-.667 17.363V2.637L9.528 10-.667 17.363z" stroke="#FDCE12" strokeWidth={1.333} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 10.667H28V9.333H9.333v1.334z"
          fill="#FDCE12"
        />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgVu;
