import React from 'react';

import { View } from 'react-native';

import { LocationMarkerIcon } from './LocationMarkerIcon';
import { LocationMarkerIconProps } from './LocationMarkerIcon.types';
import { getLocationMarkerIconWithZoomStyle } from './LocationMarkerIcon.styles';

export const LocationMarkerIconWithZoom: React.FC<LocationMarkerIconProps> = () => {
  const styles = getLocationMarkerIconWithZoomStyle();
  return (
    <View style={styles.zoomWrapper}>
      <LocationMarkerIcon />
    </View>
  );
};
