import { capitalize } from 'lodash';
import { TFunction } from 'i18next';
import { format } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';

import {
  MandateStepParams,
  TimelineStepName,
  TimelineStepUnion,
} from '@app/libs/apollo/introspection';
import { IconName } from '@app/ui';

import { StepProps } from '../components/Step/Step';

const formatParams = (params?: Maybe<MandateStepParams>): Record<string, string> | undefined => {
  const formattedParams: Record<string, string> = {};
  if (!params) {
    return undefined;
  }
  Object.entries(params).forEach(([key, value]) => {
    let formattedValue: string;
    if (key === 'expiresAt') {
      formattedValue = format(new Date(value as string), 'dd/MM/yyyy');
    } else {
      formattedValue = value?.toString() ?? '';
    }

    formattedParams[key] = formattedValue;
  });
  return formattedParams;
};

export const getStepInformations = ({
  item,
  index,
  t,
  onPress,
}: {
  item: TimelineStepUnion;
  index: number;
  t: TFunction;
  onPress: (name: TimelineStepName) => void;
}): Pick<
  StepProps,
  'buttonLabel' | 'chipText' | 'description' | 'iconName' | 'onPress' | 'status' | 'title'
> => {
  return {
    buttonLabel: t(`homePage.steps.${item.name}.${item.status}.buttonLabel`),
    chipText: capitalize(`${t('homePage.step')} ${index + 1}`),
    description: t(
      `homePage.steps.${item.name}.${item.status}.description`,
      formatParams(item?.params)
    ),
    iconName:
      item.name === TimelineStepName.FindGem
        ? ('SearchProperty' as IconName)
        : item.name === TimelineStepName.Mandate
        ? ('Feather' as IconName)
        : ('Calendar' as IconName),
    onPress: () => onPress(item.name),
    status: item.status,
    title: t(`homePage.steps.${item.name}.${item.status}.title`),
  };
};
