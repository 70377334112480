import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgFootprint = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M7.875 18c-1.283 0-2.258-.475-2.925-1.425a5.175 5.175 0 0 1-.95-3.2l-.45-.675c-.183-.283-.488-.925-.913-1.925C2.212 9.775 2 8.517 2 7c0-1.717.425-3.146 1.275-4.287C4.125 1.57 5.2 1 6.5 1c1.417 0 2.52.63 3.312 1.888A7.576 7.576 0 0 1 11 7c0 .967-.133 1.858-.4 2.675-.267.817-.5 1.475-.7 1.975l.2.325c.133.233.317.604.55 1.113.233.508.35 1.037.35 1.587 0 .95-.296 1.742-.887 2.375-.592.633-1.338.95-2.238.95ZM5.25 11.6 8 11.05c.217-.533.437-1.142.662-1.825A7.075 7.075 0 0 0 9 7c0-1-.23-1.917-.688-2.75C7.854 3.417 7.25 3 6.5 3s-1.354.417-1.813 1.25A5.612 5.612 0 0 0 4 7c0 1.05.146 1.98.437 2.787.292.809.563 1.413.813 1.813ZM7.875 16c.317 0 .583-.117.8-.35.217-.233.325-.558.325-.975 0-.283-.067-.575-.2-.875a8.923 8.923 0 0 0-.4-.8l-2.4.5c0 .667.146 1.25.437 1.75.292.5.771.75 1.438.75Zm8.25 7c-.9 0-1.646-.317-2.238-.95-.591-.633-.887-1.425-.887-2.375 0-.55.117-1.08.35-1.588a15.4 15.4 0 0 1 .55-1.112l.2-.325c-.2-.5-.433-1.158-.7-1.975-.267-.817-.4-1.708-.4-2.675 0-1.483.396-2.854 1.188-4.112C14.979 6.629 16.082 6 17.5 6c1.3 0 2.375.57 3.225 1.713C21.575 8.854 22 10.283 22 12c0 1.517-.212 2.77-.637 3.762-.425.992-.73 1.63-.913 1.913l-.45.7c.017 1.183-.304 2.25-.962 3.2-.659.95-1.63 1.425-2.913 1.425Zm2.625-6.4c.25-.4.52-1 .813-1.8.291-.8.437-1.733.437-2.8 0-1-.23-1.917-.688-2.75C18.855 8.417 18.25 8 17.5 8s-1.354.417-1.813 1.25A5.61 5.61 0 0 0 15 12c0 .8.113 1.537.338 2.213.225.675.445 1.287.662 1.837l2.75.55ZM16.125 21c.667 0 1.146-.25 1.438-.75.291-.5.437-1.083.437-1.75l-2.4-.5c-.133.233-.267.5-.4.8-.133.3-.2.592-.2.875 0 .333.104.637.313.912.208.276.479.413.812.413Z"
    />
  </Svg>
);
export default SvgFootprint;
