import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLanguageFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M22.422 20.325l-4.219-10.22a1.031 1.031 0 00-1.906 0l-4.22 10.22a1.034 1.034 0 00.559 1.351 1.03 1.03 0 001.348-.564l.86-2.081h4.812l.86 2.08a1.032 1.032 0 101.906-.786zm-6.727-3.357l1.555-3.766 1.555 3.766h-3.11zM12.555 16.074a1.031 1.031 0 00-.23-1.439c-.009-.007-.702-.521-1.71-1.628 1.859-2.516 2.912-5.379 3.341-6.726h1.513a1.031 1.031 0 000-2.062H10.03V3.28a1.031 1.031 0 10-2.062 0v.938H2.53a1.031 1.031 0 100 2.062h9.246c-.446 1.264-1.268 3.258-2.521 5.08-1.472-1.954-2.02-3.218-2.024-3.229a1.031 1.031 0 00-1.902.797c.027.065.682 1.605 2.478 3.934l.128.165c-1.839 2.079-3.644 3.368-4.399 3.785a1.03 1.03 0 10.988 1.81c.101-.055 2.278-1.26 4.764-4.012 1.055 1.13 1.781 1.662 1.825 1.693a1.031 1.031 0 001.441-.23z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLanguageFilled;
