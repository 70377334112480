import React from 'react';

import { Modal } from '@app/ui';

import { RenovationSummaryProps } from './RenovationSummary';
import { RenovationSummaryDetailTemplate } from './RenovationSummaryDetailTemplate';

type RenovationSummaryDetailModalProps = {
  isVisible: boolean;
  onClose: () => void;
} & Omit<RenovationSummaryProps, 'onPressViewDetail'>;

export const RenovationSummaryDetailModal: React.FC<RenovationSummaryDetailModalProps> = ({
  isVisible,
  onClose,
  ...renonvationSummaryProps
}) => {
  return (
    <Modal
      visible={isVisible}
      onPressClose={onClose}
      position="fullscreen"
      hideCross
      noContainerPadding
      withSafeAreaView
    >
      <RenovationSummaryDetailTemplate {...renonvationSummaryProps} onClose={onClose} />
    </Modal>
  );
};
