import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgHammer = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10 21a.965.965 0 01-.712-.288A.965.965 0 019 20V8H4c0-1.383.488-2.562 1.463-3.537C6.438 3.488 7.617 3 9 3h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v1l2.575-2.575c.117-.117.275-.217.475-.3.2-.083.392-.125.575-.125.383 0 .708.133.975.4s.4.592.4.975v5.25c0 .383-.138.708-.413.975-.275.267-.604.4-.987.4-.167 0-.35-.042-.55-.125a1.453 1.453 0 01-.475-.3L15 8v12c0 .283-.096.52-.287.712A.968.968 0 0114 21h-4zm1-2h2v-6h-2v6zm0-8h2V5H9a2.92 2.92 0 00-1.225.262 3.007 3.007 0 00-1 .738H11v5zm2 0V6v-.738V5v6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgHammer;
