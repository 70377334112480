import * as React from 'react';

import Svg, { SvgProps, Mask, Path, G } from 'react-native-svg';
const SvgRealEstateAgent = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Mask
        id="real-estate-agent_svg__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <Path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </Mask>
      <G mask="url(#real-estate-agent_svg__a)">
        <Path
          d="M19 14h2V7.525a1.947 1.947 0 00-.85-1.625l-5-3.575A1.936 1.936 0 0014 1.95c-.417 0-.8.125-1.15.375l-5 3.575A1.94 1.94 0 007 7.525V9h2V7.5L14 4l5 3.5V14zm-4-6a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7A.48.48 0 0115 8zm-2 0a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7A.48.48 0 0113 8zm2 2a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7.48.48 0 01-.35.15zm-2 0a.48.48 0 01-.35-.15.48.48 0 010-.7.48.48 0 01.7 0 .48.48 0 010 .7.48.48 0 01-.35.15zm.95 10.4l5.95-1.85a1.142 1.142 0 00-.362-.387A.929.929 0 0019 18h-5.05a9.1 9.1 0 01-1.075-.05 3.958 3.958 0 01-.825-.2l-1.425-.475a.905.905 0 01-.575-.5A1.04 1.04 0 0110 16a.954.954 0 01.488-.588.97.97 0 01.762-.062l1.05.35c.283.083.604.15.962.2.359.05.846.083 1.463.1H15a.948.948 0 00-.162-.525.753.753 0 00-.388-.325L8.6 13H7v5.5l6.95 1.9zm-.525 1.95L7 20.55a1.93 1.93 0 01-.787 1.05c-.392.267-.796.4-1.213.4H3c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 011 20v-7c0-.55.196-1.02.587-1.412A1.926 1.926 0 013 11h5.6c.117 0 .233.012.35.037.117.025.225.055.325.088L15.15 13.3c.55.2.996.55 1.338 1.05.341.5.512 1.05.512 1.65h2c.833 0 1.542.275 2.125.825.583.55.875 1.275.875 2.175 0 .367-.096.654-.288.863-.191.208-.487.379-.887.512l-6.25 1.95a1.67 1.67 0 01-.575.1c-.2 0-.392-.025-.575-.075zM3 20h2v-7H3v7z"
          fill={props.color}
        />
      </G>
    </Svg>
  );
};
export default SvgRealEstateAgent;
