import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSmartphone = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7 23c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 015 21V3c0-.55.196-1.021.588-1.413A1.925 1.925 0 017 1h10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0117 23H7zm0-5h10V6H7v12zm0 2v1h10v-1H7zM7 4h10V3H7v1z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSmartphone;
