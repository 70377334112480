import { useCallback, useEffect, useRef } from 'react';

import { Animated, PanResponder } from 'react-native';

import { getPanResponderCallbacks } from '@app/ui/hooks/use-pan-responder/usePanResponderCallbacks.utils';

import { Output, Props } from './usePanResponderCallbacks.types';

export function usePanResponderCallbacks({
  count,
  currentStep,
  gesturesEnabled,
  loop,
  minDistanceForAction,
  minDistanceToCapture,
  onGoStep,
  width,
}: Props): Output {
  const pan = useRef(new Animated.ValueXY()).current;

  // useCallback block the first rerender in case of initialization currentStep !== 0
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const spring = (x: number) => {
    Animated.timing(pan, {
      duration: 200,
      toValue: { x, y: 0 },
      useNativeDriver: false,
    }).start();
  };

  useEffect(() => {
    const activePosition = width * currentStep * -1;
    const currentPosition = JSON.parse(JSON.stringify(pan.x));
    // Trigger the animation if currentStep position changed by callback
    if (currentPosition % width === 0 && currentPosition !== activePosition) {
      pan.flattenOffset();
      spring(activePosition);
    }
  }, [currentStep, pan, spring, width]);

  const setOffset = useCallback(() => {
    const newX = -width * currentStep;
    pan.setOffset({ x: newX, y: 0 });
    pan.setValue({ x: 0, y: 0 });
  }, [currentStep, pan, width]);

  const panResponder = PanResponder.create(
    getPanResponderCallbacks({
      count,
      currentStep,
      gesturesEnabled,
      loop,
      minDistanceForAction,
      minDistanceToCapture,
      onGoStep,
      pan,
      setOffset,
      spring,
      width,
    })
  );

  return { pan, panResponder, setOffset };
}
