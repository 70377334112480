import { Platform, StyleSheet } from 'react-native';

import { RequiredThemes, Theme } from '@app/ui/theme/types';

interface StyledInputProps {
  componentTheme: RequiredThemes['input'];
  error: boolean;
  hovered: boolean;
  disabled: boolean;
  shouldLabelBeUp?: boolean;
  focused: boolean;
  isLabelVisible?: boolean;
  isValid: boolean;
  isSearch: boolean;
  prefix: boolean;
  prefixWidth: number;
  suffix: boolean;
  suffixWidth: number;
}

export type InputVariant = 'primary' | 'outlined';

interface BorderStyle {
  focused: boolean;
  error: boolean;
  hovered: boolean;
  componentTheme: StyledInputProps['componentTheme'];
}

interface BackgroundColor {
  focused: boolean;
  disabled: boolean;
  hovered: boolean;
  componentTheme: StyledInputProps['componentTheme'];
}
interface TextColor {
  disabled: boolean;
  componentTheme: StyledInputProps['componentTheme'];
}

export const getRawColors = (theme: Theme, componentTheme: RequiredThemes['input']) => ({
  cursorColor: theme.palette.base.black,
  passwordIconColor: theme.palette.neutral[900],
  placeholderColor: componentTheme.primary.textColor.placeholder,
});

const getBorderStyles = ({ focused, error, hovered, componentTheme }: BorderStyle) => {
  const currentVariant = componentTheme['primary'];

  const { borderWidth } = currentVariant;

  let borderColor = 'transparent';

  if (hovered) {
    borderColor = currentVariant.borderColor.hover;
  }

  if (focused) {
    borderColor = currentVariant.borderColor.focus;
  }

  if (error) {
    borderColor = currentVariant.borderColor.error;
  }

  return {
    ...Platform.select({
      web: {
        outlineColor: borderColor,
        outlineStyle: 'none',
      },
    }),
    borderColor,
    borderWidth,
  };
};

const getTextColor = ({ disabled, componentTheme }: TextColor) => {
  const textColors = componentTheme.primary.textColor;
  let textColor = textColors.inputText;

  if (disabled) {
    textColor = textColors.disabled;
  }

  return textColor;
};

const getBackgroundColor = ({ hovered, focused, disabled, componentTheme }: BackgroundColor) => {
  const backgroundColors = componentTheme.primary.backgroundColor;
  let backgroundColor = backgroundColors.default;

  if (hovered) {
    backgroundColor = backgroundColors.hovered;
  }

  if (focused) {
    backgroundColor = backgroundColors.focus;
  }

  if (disabled) {
    backgroundColor = backgroundColors.disabled;
  }

  return backgroundColor;
};

const getLabelColor = (disabled: boolean, componentTheme: StyledInputProps['componentTheme']) => {
  const labelColor = componentTheme.primary.textColor.label;
  return disabled ? labelColor.disabled : labelColor.default;
};

export const getIconColor = (
  isValid: boolean,
  error: boolean,
  componentTheme: StyledInputProps['componentTheme']
) => {
  const colors = componentTheme.primary.iconColor;
  let color = colors.error;

  if (isValid) {
    color = colors.valid;
  }

  if (error) {
    color = colors.error;
  }

  return color;
};

export const getSearchIconColor = (
  disabled: boolean,
  componentTheme: StyledInputProps['componentTheme']
) =>
  disabled
    ? componentTheme.primary.iconColor.searchDisabled
    : componentTheme.primary.iconColor.search;

const passwordIconRightPosition = (
  isValid: boolean,
  error: boolean,
  componentTheme: StyledInputProps['componentTheme']
) =>
  isValid || error
    ? componentTheme.primary.iconPosition.passwordWithValidation
    : componentTheme.primary.iconPosition.default;

const getInputPaddingLeft = (
  isSearch: boolean,
  prefix: boolean,
  prefixWidth: number,
  componentTheme: StyledInputProps['componentTheme']
) => {
  const { size } = componentTheme.primary;
  let paddingLeft = size.defaultPadding;

  if (isSearch) {
    paddingLeft = size.paddingIconWithSearch;
  }

  if (prefix) {
    paddingLeft = prefixWidth + size.paddingWithPrefix;
  }

  return paddingLeft;
};

const getInputPaddingRight = (
  suffix: boolean,
  suffixWidth: number,
  componentTheme: StyledInputProps['componentTheme']
) => {
  const { size } = componentTheme.primary;
  let paddingRight = size.defaultPadding;

  if (suffix) {
    paddingRight = size.defaultPadding + suffixWidth + 8;
  }

  return paddingRight;
};

export const getStyles = ({
  componentTheme,
  error,
  hovered: hoveredState,
  disabled,
  focused,
  isValid,
  isSearch,
  prefix,
  prefixWidth,
  suffix,
  suffixWidth,
}: StyledInputProps) => {
  const currentVariant = componentTheme['primary'];
  const hovered = !disabled && hoveredState;

  return StyleSheet.create({
    errorMessage: {
      minHeight: componentTheme.size.errorMessage,
      ...Platform.select({ web: { cursor: 'default' } }),
    },
    input: {
      borderRadius: currentVariant.size.borderRadius,
      height: currentVariant.size.height,
      paddingLeft: getInputPaddingLeft(isSearch, prefix, prefixWidth, componentTheme),
      paddingRight: getInputPaddingRight(suffix, suffixWidth, componentTheme),
      ...getBorderStyles({
        componentTheme,
        error,
        focused,
        hovered,
      }),
      backgroundColor: getBackgroundColor({
        componentTheme,
        disabled,
        focused,
        hovered,
      }),
      color: getTextColor({ componentTheme, disabled }),
      width: '100%',
    },
    inputContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    },
    inputWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: currentVariant.spacing.topSpace,
      paddingLeft: currentVariant.spacing.leftSpace,
    },
    label: {
      ...currentVariant.size.labelText,
      color: getLabelColor(disabled, componentTheme),
      paddingBottom: currentVariant.size.labelBottomPadiing,
      paddingLeft: currentVariant.size.labelLeftPadding,
    },
    passwordIconRight: {
      position: 'absolute',
      right: passwordIconRightPosition(isValid, error, componentTheme),
    },
    searchInputIcon: {
      height: componentTheme.primary.iconSize,
      left: componentTheme.primary.iconPosition.default,
      position: 'absolute',
      width: componentTheme.primary.iconSize,
      zIndex: 2,
    },
    suffix: {
      textAlign: 'right',
      ...currentVariant.size.placeholderText,
      color: getLabelColor(disabled, componentTheme),
    },
    validationIconRight: {
      height: componentTheme.primary.iconSize,
      position: 'absolute',
      right: componentTheme.primary.iconPosition.default,
      width: componentTheme.primary.iconSize,
    },
    wrapper: {
      ...Platform.select({
        web: {
          cursor: 'default',
        },
      }),
      maxWidth: '100%',
    },
  });
};
