import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgGlobe = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.341 20.188v-1.95c-.55 0-1.02-.196-1.412-.587a1.927 1.927 0 01-.588-1.413v-1l-4.8-4.8c-.05.3-.096.6-.138.9-.041.3-.062.6-.062.9 0 2.017.663 3.783 1.988 5.3s2.996 2.4 5.012 2.65zm6.9-2.55a7.982 7.982 0 002.1-5.4 7.846 7.846 0 00-1.362-4.475 7.704 7.704 0 00-3.638-2.925v.4c0 .55-.195 1.02-.587 1.412a1.927 1.927 0 01-1.413.588h-2v2c0 .283-.095.52-.287.712a.968.968 0 01-.713.288h-2v2h6a.97.97 0 01.713.287.97.97 0 01.287.713v3h1c.433 0 .825.129 1.175.387.35.259.592.596.725 1.013zm-5.9 4.6a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175a9.732 9.732 0 01-.788-3.9c0-1.383.263-2.683.788-3.9a10.091 10.091 0 012.137-3.175c.9-.9 1.959-1.613 3.175-2.138a9.743 9.743 0 013.9-.787c1.384 0 2.684.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.613 1.958 2.137 3.175a9.733 9.733 0 01.788 3.9 9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137a9.733 9.733 0 01-3.9.788z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgGlobe;
