import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMic = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12.038 13.886a2.893 2.893 0 01-2.125-.875 2.893 2.893 0 01-.875-2.125v-6c0-.834.292-1.542.875-2.126a2.893 2.893 0 012.125-.874c.833 0 1.542.291 2.125.875.583.583.875 1.291.875 2.125v6c0 .833-.292 1.541-.875 2.125a2.893 2.893 0 01-2.125.875zm0 7a.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-2.1c-1.55-.2-2.866-.842-3.95-1.926a6.855 6.855 0 01-1.975-3.9.839.839 0 01.225-.75c.2-.216.467-.325.8-.325.234 0 .442.088.625.263.184.175.3.388.35.638.217 1.166.784 2.141 1.7 2.925.917.783 1.992 1.175 3.225 1.175s2.309-.392 3.225-1.175c.917-.784 1.484-1.759 1.7-2.925.05-.25.171-.463.363-.638a.923.923 0 01.637-.262c.317 0 .575.108.775.325.2.216.275.466.225.75a6.855 6.855 0 01-1.975 3.9c-1.083 1.083-2.4 1.724-3.95 1.924v2.1c0 .284-.095.521-.287.712a.968.968 0 01-.713.288zm0-9a.968.968 0 00.713-.288.967.967 0 00.287-.713v-6a.97.97 0 00-.287-.712.97.97 0 00-.713-.288.967.967 0 00-.712.288.968.968 0 00-.288.713v6c0 .283.096.52.288.712a.965.965 0 00.712.287z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMic;
