import React from 'react';

import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Icon, IconName, Text, Theme, useTheme } from '@app/ui';

export interface ContentItem {
  icon: IconName;
  key: string;
}

interface RenovationWarrantyItemProps {
  large: boolean;
  content: ContentItem;
}

const getStyles = (theme: Theme, isLarge: boolean) =>
  StyleSheet.create({
    item: {
      ...(isLarge && { flex: 1 }),
      ...(!isLarge && {
        flexDirection: 'row',
        paddingHorizontal: theme.spacing.SIZE_02,
        paddingVertical: theme.spacing.SIZE_04,
      }),
      alignItems: 'center',
      gap: theme.spacing.SIZE_03,
    },
    whiteText: {
      color: theme.palette.base.white,
      textAlign: 'center',
    },
  });

export const RenovationWarrantyItem: React.FC<RenovationWarrantyItemProps> = ({
  content,
  large,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme, large);
  const { t } = useTranslation();

  return (
    <View style={styles.item}>
      <Icon name={content.icon} size={24} color={theme.palette.system.primary[400]} />
      <Text textStyle={large ? 'Body1Medium' : 'Body2'} style={styles.whiteText}>
        {t(`realEstate.renovation.warranty.${content.key}`)}
      </Text>
      {!!large && (
        <Text textStyle="Body3" style={styles.whiteText}>
          {t(`realEstate.renovation.warranty.${content.key}Subtitle`)}
        </Text>
      )}
    </View>
  );
};
