import React, { useEffect, useMemo, useState } from 'react';

import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Theme, useResponsive, useTheme } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import {
  Timeline,
  TimelineStepStatus,
  useGetCustomerSuggestionsQuery,
} from '@app/libs/apollo/introspection';
import { isNative } from '@app/constants/platform';
import { useNotificationsStatus } from '@app/shared/hooks/useNotificationsStatus.hook';

import { HomePageSteps } from './HomePageSteps';
import { HomePageHeader } from './HomePageHeader';
import { HomePageStepsWeb } from './HomePageStepsWeb';
import { HomePageProjects } from './HomePageProjects';
import { HomePageRecommendations } from './HomePageRecommendations';
import { HomePageNews } from './HomePageNews';
import { useMyProjectsList } from '../projectList/useProjectList.hook';
import { HomePageGuides } from './HomePageGuides';
import { HomePageNotifications } from './HomePageNotifications';
import { HomePageDownloadApp } from './HomePageDownloadApp';
import { getStickyHeaderIndices } from './utils/getStickyHeaderIndices';

const getStyles = (theme: Theme, isNativeOrResponsive: boolean) =>
  StyleSheet.create({
    flex: { flex: 1 },
    footerContainer: {
      marginBottom: theme.spacing.SIZE_05,
      overflow: 'hidden',
      zIndex: 50,
    },
    gradient: {
      flex: 1,
      padding: 0,
      width: '100%',
    },
    scrollView: { flex: 1, paddingTop: theme.spacing.SIZE_09 },
    wrapper: {
      backgroundColor: isNativeOrResponsive
        ? theme.palette.base.white
        : theme.palette.base.transparent,
      borderTopLeftRadius: theme.borderRadius.SIZE_08,
      borderTopRightRadius: theme.borderRadius.SIZE_08,
    },
  });

export const HomePage: React.FC = () => {
  const { isLowerThanLaptop, isNativeOrResponsive, isGreaterThanTablet } = useResponsive();
  const theme = useTheme();
  const { status: notificationStatus } = useNotificationsStatus();

  const stickerHeaderIndices = getStickyHeaderIndices({
    isGreaterThanTablet,
    isLowerThanLaptop,
    isNative: isNative(),
    notificationStatus: notificationStatus ?? 'denied',
  });

  const {
    customer: { timeline },
  } = useMe();

  const { data: { myProjects } = { myProjects: [] }, loading: loadingProjects } =
    useMyProjectsList();

  const { data, loading } = useGetCustomerSuggestionsQuery();

  const suggestionsLength = useMemo(() => data?.getCustomerSuggestions.length, [data]);

  const isFirstStep = timeline?.steps[0]?.status === TimelineStepStatus.Active;

  const styles = getStyles(theme, isNativeOrResponsive);

  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const handleCloseNotifications = () => {
    setIsNotificationVisible(false);
  };

  useEffect(() => {
    setIsNotificationVisible(notificationStatus === 'blocked' || notificationStatus === 'denied');
  }, [notificationStatus]);

  return (
    <SafeAreaView edges={['top']} style={styles.flex}>
      <ScrollView style={styles.scrollView} stickyHeaderIndices={stickerHeaderIndices}>
        <View>
          <HomePageHeader isFirstStep={isFirstStep} />

          {timeline === null ? (
            <HomePageProjects myProjects={myProjects} loading={loadingProjects ?? false} />
          ) : isLowerThanLaptop ? (
            <HomePageSteps timeline={timeline as Timeline} />
          ) : (
            <HomePageStepsWeb timeline={timeline as Timeline} />
          )}
        </View>

        {isNative() ? (
          <HomePageNotifications
            isNotificationVisible={isNotificationVisible}
            handleCloseNotifications={handleCloseNotifications}
          />
        ) : (
          <HomePageDownloadApp />
        )}

        <View style={styles.footerContainer}>
          <View style={styles.wrapper}>
            <HomePageRecommendations data={data} loading={loading} />
            <HomePageNews hasRecommendations={!!suggestionsLength && suggestionsLength > 0} />
          </View>
          <HomePageGuides />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
