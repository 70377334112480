import { StepStatus } from '@app/ui/molecules/circle-step-indicator/CircleStepIndicator.types';

import { TimelineStep } from './Timeline.types';

export const isAfterStepFinished: (
  collection: TimelineStep[],
  index: number,
  activeStep: number
) => boolean = (collection, index, activeStep) => {
  return (
    collection[index + 1]?.status === StepStatus.Ongoing ||
    collection[index + 1]?.status === StepStatus.Finished ||
    (activeStep - 1 === index && collection[index]?.subSteps?.[0]?.status !== StepStatus.Incoming)
  );
};

export const isBeforeStepFinished: (collection: TimelineStep[], index: number) => boolean = (
  collection,
  index
) => collection[index]?.status !== StepStatus.Incoming;

export const isAfterSubStepFinished: (
  parentStatus: StepStatus,
  collection: TimelineStep[],
  index: number
) => boolean = (parentStatus, collection, index) =>
  parentStatus === StepStatus.Finished ||
  collection[index + 1]?.status === StepStatus.Finished ||
  collection[index + 1]?.status === StepStatus.Ongoing;

export const isLastStep: (
  parentCollectionLength: number,
  index: number,
  activeStep: number,
  collectionLength?: number
) => boolean = (parentCollectionLength, index, activeStep, collectionLength = 0) => {
  if (collectionLength) {
    return parentCollectionLength === activeStep && collectionLength === index + 1;
  }
  return parentCollectionLength === index + 1 && parentCollectionLength !== activeStep;
};
