import React from 'react';

import { Card, palette } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { ProjectCardTitle } from './ProjectCardTitle';
import { BaseProjectCardProps } from './projectCard.types';
import { getProjectCardPreviewUrl } from './projectCard.utils';

export const CanceledProjectCard: React.FC<BaseProjectCardProps> = ({
  coverImage,
  address,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <Card disabled testID="canceled-project-card">
      <Card.Header>
        <Card.Chip
          position="topright"
          text={t('projectList.status.canceled')}
          iconName="Cross"
          textColor={palette.base.black}
          backgroundColor={palette.base.white}
        />
        <Card.Image imageUri={getProjectCardPreviewUrl(coverImage)} />
      </Card.Header>
      <Card.Content>
        <Card.Block withLine>
          <ProjectCardTitle address={address} realEstateTitle={title} />
        </Card.Block>
      </Card.Content>
    </Card>
  );
};
