import React, { useCallback, useState } from 'react';

import { View, StyleSheet, LayoutChangeEvent } from 'react-native';

import { SliderImageObject } from '@app/features/realEstate/gallery/gallery.types';

import { ImageCell } from './ImageCell';

interface MiniGalleryProps {
  imagesGrid: SliderImageObject[];
  openGallery: (index: number) => () => void;
  columnsCount?: number;
}

const gridGap = 4;

const getStyles = () =>
  StyleSheet.create({
    container: {
      borderRadius: 20,
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: gridGap,
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
  });

export const MiniGallery: React.FC<MiniGalleryProps> = ({
  imagesGrid,
  openGallery,
  columnsCount = 2,
}) => {
  const [cellWidth, setCellWidth] = useState(0);

  const styles = getStyles();

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { width } = event.nativeEvent.layout;
      const calculatedCellWidth =
        width / columnsCount - (gridGap * (columnsCount - 1)) / columnsCount;
      setCellWidth(calculatedCellWidth);
    },
    [columnsCount]
  );

  return (
    <View style={styles.container} onLayout={onLayout}>
      {imagesGrid.map(({ url }, index) => (
        <ImageCell url={url} width={cellWidth} onPress={openGallery(index)} key={url} />
      ))}
    </View>
  );
};
