// eslint-disable-next-line no-restricted-imports
import styled from '@emotion/styled';

import { Spacing } from '@app/ui';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  padding: ${Spacing.SIZE_11}px ${Spacing.SIZE_06}px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.SIZE_05}px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
`;

export const DocumentContainer = (isMobile: boolean) => styled.div`
  margin: ${isMobile ? Spacing.SIZE_04 : Spacing.SIZE_11}px auto;
  max-width: 100%;
`;

export const Document = styled.div`
  padding-left: 5px;
`;

export const FoldableSectionLang = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const saveButtonWrapperStyle = {
  maxWidth: 350,
  width: '100%',
};

export const saveButtonStyle = {
  ...saveButtonWrapperStyle,
  margin: 'auto',
};
