import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgAd = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ad_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ad_svg__a)">
        <Path fill="#1537D1" d="M0 0h28v20H0z" />
        <Path fill="#EA3058" d="M13.333 0H28v20H13.333z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 20h9.334V0H9.333v20z"
          fill="#FFCF3C"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.667 8.667H14v.666h-1.333v-.666z"
          fill="#FFEDB1"
        />
        <Path
          d="M12.392 8.694a.333.333 0 01.333-.36h2.55c.195 0 .349.166.333.36l-.172 2.056a1 1 0 01-.996.917h-.88a1 1 0 01-.996-.917l-.172-2.056z"
          stroke="#D32E28"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.667 9.333h2.666V10h-2.666v-.667z"
          fill="#D32E28"
        />
      </G>
    </Svg>
  );
};

export default SvgAd;
