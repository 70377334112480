import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPhone = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84 5.381a.838.838 0 00-.833-.714H5.465a.83.83 0 00-.795.902 15.685 15.685 0 002.428 6.853l.004.006a15.432 15.432 0 004.744 4.744l.005.003a15.656 15.656 0 006.867 2.433h.027a.844.844 0 00.829-.841V16.25a.837.837 0 00-.714-.85c-.857-.116-1.7-.321-2.518-.627a.841.841 0 00-.882.186l-1.055 1.055a.833.833 0 01-1.001.136 14.168 14.168 0 01-5.313-5.313.833.833 0 01.135-1.001L9.281 8.78a.841.841 0 00.187-.882A11.659 11.659 0 018.84 5.38zm-.845-2.38c1.25-.011 2.32.912 2.496 2.15v.004c.1.742.278 1.465.538 2.162l.001.002a2.508 2.508 0 01-.562 2.632l-.005.005-.598.598a12.508 12.508 0 003.822 3.821l.603-.603a2.508 2.508 0 012.631-.562l.003.001c.697.26 1.42.438 2.162.538h.003a2.503 2.503 0 012.151 2.525v2.493a2.51 2.51 0 01-2.484 2.508h-.016c-.04 0-.145 0-.255-.015a17.326 17.326 0 01-7.536-2.683 17.099 17.099 0 01-5.252-5.252A17.352 17.352 0 013.012 5.74l-.002-.013A2.497 2.497 0 015.5 3h2.496z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPhone;
