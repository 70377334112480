import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Button, Image, Spacer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { isNative } from '@app/constants/platform';
import { trackHomePageDownloadApp } from '@app/services/tracking/trackTracking';

import { getMobileOperatingSystem } from './utils/getUserAgent';
import { openAppStore, openPlayStore } from '../startup/hooks/useMinimalVersion';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignSelf: 'center',
      backgroundColor: theme.palette.system.secondary[600],
      borderRadius: theme.borderRadius.SIZE_06,
      height: 164,
      marginBottom: theme.spacing.SIZE_06,
      marginHorizontal: theme.spacing.SIZE_06,
      overflow: 'hidden',
      paddingHorizontal: theme.spacing.SIZE_05,
      width: 335,
      zIndex: 1,
    },
    imageWrapper: { height: 200, marginTop: theme.spacing.SIZE_07, width: 100 },
    text: { color: theme.palette.base.white, textAlign: 'center' },
    textWrapper: { flex: 1, flexDirection: 'column', justifyContent: 'center' },
    wrapper: { flex: 1, flexDirection: 'row', gap: theme.spacing.SIZE_03 },
  });

export const HomePageDownloadApp: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { isLowerThanTablet } = useResponsive();

  const mobileOperatingSystem = getMobileOperatingSystem();

  const imageURL =
    mobileOperatingSystem === 'iOS'
      ? 'https://ik.imagekit.io/masteos/masteosapp/homepage/ios.png'
      : 'https://ik.imagekit.io/masteos/masteosapp/homepage/android.png';

  const styles = getStyles(theme);
  const onPress = () => {
    if (mobileOperatingSystem === 'iOS') {
      trackHomePageDownloadApp('ios');
      openAppStore();
    } else {
      trackHomePageDownloadApp('android');
      openPlayStore();
    }
  };

  if (!isLowerThanTablet || isNative()) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={styles.imageWrapper}>
          <Image
            source={{
              uri: imageURL,
            }}
            resizeMode="contain"
          />
        </View>

        <View style={styles.textWrapper}>
          <Text textStyle="Body1Medium" style={styles.text}>
            {t('homePage.downloadApp.title')}
          </Text>

          <Spacer height={theme.spacing.SIZE_03} />
          <Button variant="secondary" iconPlacement="left" onPress={onPress}>
            {t('homePage.downloadApp.button')}
          </Button>
        </View>
      </View>
    </View>
  );
};
