import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTable = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9.2 17l-.975 2.425a.986.986 0 01-.35.425c-.15.1-.317.15-.5.15a.908.908 0 01-.787-.413.828.828 0 01-.088-.862l1-2.475c.15-.383.392-.688.725-.913A1.967 1.967 0 019.35 15H11v-4.025c-2.55-.083-4.687-.458-6.412-1.125C2.863 9.183 2 8.4 2 7.5c0-.967.975-1.792 2.925-2.475C6.875 4.342 9.233 4 12 4c2.783 0 5.146.342 7.087 1.025C21.029 5.708 22 6.533 22 7.5c0 .9-.862 1.683-2.587 2.35S15.55 10.892 13 10.975V15h1.65c.4 0 .771.112 1.113.337.341.225.587.53.737.913l1 2.475c.067.15.087.3.062.45-.025.15-.079.287-.162.412a.93.93 0 01-.325.3.899.899 0 01-.45.113.882.882 0 01-.5-.15.986.986 0 01-.35-.425L14.8 17H9.2zM12 9a23.57 23.57 0 004.575-.425c1.433-.283 2.483-.642 3.15-1.075-.667-.433-1.717-.792-3.15-1.075A23.57 23.57 0 0012 6a23.57 23.57 0 00-4.575.425c-1.433.283-2.483.642-3.15 1.075.667.433 1.717.792 3.15 1.075A23.57 23.57 0 0012 9z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTable;
