import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Chip, ChipProps, Spacer, Text, Theme, useTheme } from '@app/ui';
import { RealEstateActionButtons } from '@app/features/realEstate/realEstateHeader/components/RealEstateActionButtons';
import { isWeb } from '@app/constants/platform';

interface RealEstateStickyTitleProps {
  onLike?: () => void;
  onShare: () => void;
  shouldUseShare: boolean;
  title: string;
  address?: string;
  isFavorite?: boolean;
  renderChip: (props: Partial<ChipProps>) => React.ReactElement<typeof Chip> | undefined;
}

const HEADER_HEIGHT = 56;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: 1,
      flexDirection: 'row',
      height: HEADER_HEIGHT,
      justifyContent: 'space-between',
      paddingHorizontal: theme.spacing.SIZE_09,
      paddingVertical: theme.spacing.SIZE_05,
      position: isWeb() ? ('sticky' as 'absolute') : 'absolute',
      top: 0,
      width: '100%',
      zIndex: 20,
    },
    title: {
      alignItems: 'center',
      columnGap: theme.spacing.SIZE_03,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      rowGap: theme.spacing.SIZE_02,
    },
  });

export const RealEstateStickyTitle: React.FC<RealEstateStickyTitleProps> = ({
  onShare,
  shouldUseShare,
  onLike,
  address,
  isFavorite = false,
  title,
  renderChip,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <Spacer width={theme.spacing.SIZE_09} />

      <View style={styles.title}>
        {!!renderChip && renderChip({ size: 'small' })}
        <Text textStyle="Body2Medium">{title}</Text>
        {!!address && <Text textStyle="Body2">/ {address}</Text>}
      </View>

      <RealEstateActionButtons
        isFavorite={isFavorite}
        onLike={onLike}
        onShare={onShare}
        shouldUseShare={shouldUseShare}
      />
    </View>
  );
};
