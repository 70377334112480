import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLamp = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 21c-1.1 0-2.042-.392-2.825-1.175C8.392 19.042 8 18.1 8 17H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 013 15c0-2.333.767-4.346 2.3-6.037C6.833 7.271 8.733 6.3 11 6.05V4c0-.283.096-.521.288-.713A.967.967 0 0112 3a.97.97 0 01.713.287A.97.97 0 0113 4v2.05c2.267.25 4.167 1.221 5.7 2.913C20.233 10.654 21 12.667 21 15c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0119 17h-3c0 1.1-.392 2.042-1.175 2.825C14.042 20.608 13.1 21 12 21zm-7-6h14c0-1.933-.683-3.583-2.05-4.95C15.583 8.683 13.933 8 12 8c-1.933 0-3.583.683-4.95 2.05C5.683 11.417 5 13.067 5 15zm7 4c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413h-4c0 .55.196 1.021.588 1.413.391.391.862.587 1.412.587z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLamp;
