import React from 'react';

import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Button, Spacer, Text, useTheme, BottomSheet } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { ProfileNavigatorRoutes, ProfileStackParamList } from '@app/navigation/types/routes';

interface DeletedAccountModalProps {
  visible: boolean;
  hideModal: () => void;
}

const getDeletedAccountModalStyles = () =>
  StyleSheet.create({
    modalContainer: { maxWidth: 500 },
  });

type DeletedAccountModalNavigationProp = StackNavigationProp<
  ProfileStackParamList,
  ProfileNavigatorRoutes.ProfileRoot
>;

export const DeletedAccountModal: React.FC<DeletedAccountModalProps> = ({ visible, hideModal }) => {
  const { t } = useTranslation();
  const navigation = useNavigation<DeletedAccountModalNavigationProp>();
  const theme = useTheme();

  const styles = getDeletedAccountModalStyles();
  const closeModal = () => {
    hideModal();
    navigation.navigate(ProfileNavigatorRoutes.Auth, { deletedAccount: false });
  };

  return (
    <BottomSheet visible={visible} onPressClose={hideModal} webSize="s">
      <Text testID="ModalTitle" textStyle="Title3Medium">
        {t('authTunnel.title')}
      </Text>
      <Spacer height={theme.spacing.SIZE_04} />
      <View style={styles.modalContainer}>
        <Text>{t('authTunnel.description')}</Text>
        <Spacer height={theme.spacing.SIZE_08} />
        <Button onPress={closeModal} testID="login-deleted-account-modal-confirm-button">
          {t('authTunnel.modalDeletedAccount.button')}
        </Button>
      </View>
    </BottomSheet>
  );
};
