import { getLocationSearchParam } from '@app/shared/hooks/useQueryParams';

export const hasPublicToken = (): boolean => {
  return !!getLocationSearchParam('hash');
};

export const getPublicToken = (): string | null => {
  // Les CGP ont un token qui leur permet de voir les informations de localisation sans connexion
  const publicToken = getLocationSearchParam('hash');
  return publicToken;
};
