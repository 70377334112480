import React, { useCallback, useMemo } from 'react';

import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ICarouselInstance } from 'react-native-reanimated-carousel-4';

import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { useTranslation } from '@app/services/translations/translations';
import { Theme, useResponsive, useTheme } from '@app/ui';
import {
  HomePageNavigatorRoutes,
  HomePageStackParamList,
  MainMenuNavigatorRoutes,
  RealEstateNavigatorRoutes,
} from '@app/navigation/types/routes';
import { PropertyPage, TrackingOrigin } from '@app/services/tracking/trackTracking';
import { useBookmark } from '@app/shared/hooks/useBookmark';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { isWeb } from '@app/constants/platform';
import { fromS3ID } from '@app/utils/from-s3id';
import { useMe } from '@app/shared/hooks/useMe';
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';

import { PositioningItemList } from './Suggestions.types';
import { usePositioningActions } from './hooks/usePositioningActions';
import { SuggestionCard } from './components/suggestion-card/SuggestionCard';
import { BreakPoint, sectionPaddingForScreen, useActiveBreakPoint } from './SectionContainer';

type HomepageNavigationProps = StackNavigationProp<
  HomePageStackParamList,
  HomePageNavigatorRoutes.HomePageRoot
>;

const getStyles = ({
  theme,
  isNativeOrResponsive,
  CARD_WIDTH,
  width,
  activeBreakPoint,
}: {
  theme: Theme;
  isNativeOrResponsive: boolean;
  CARD_WIDTH: number;
  width: number;
  activeBreakPoint: BreakPoint;
}) =>
  StyleSheet.create({
    buttons: { flexDirection: 'row', marginBottom: theme.spacing.SIZE_06 },
    cardContainer: {
      flex: 1,
      paddingLeft: sectionPaddingForScreen[activeBreakPoint],
      width: CARD_WIDTH,
    },
    carousel: {
      marginHorizontal: -sectionPaddingForScreen[activeBreakPoint],
      width,
    },
    carouselWrapper: {
      overflow: 'hidden',
    },
    container: {
      margin: isNativeOrResponsive ? 0 : theme.spacing.SIZE_06,
    },
    description: { marginBottom: theme.spacing.SIZE_06, paddingHorizontal: theme.spacing.SIZE_07 },
    linearGradient: {
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_10,
      borderWidth: 1,
      paddingVertical: theme.spacing.SIZE_08,
    },
    loader: { alignItems: 'center', flex: 1, justifyContent: 'center' },
    title: {
      paddingHorizontal: theme.spacing.SIZE_07,
    },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing.SIZE_03,
    },
  });

type HomePageRecommendationsCarouselProps = {
  suggestions: PositioningItemList;
  inputRef: React.RefObject<ICarouselInstance>;
};

export const HomePageRecommendationsCarousel: React.FC<HomePageRecommendationsCarouselProps> = ({
  suggestions,
  inputRef,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setValidMsg } = useToastContext();
  const { isNativeOrResponsive, isGreaterThanTablet } = useResponsive();
  const { width } = useWindowDimensions();
  const activeBreakPoint = useActiveBreakPoint();

  const CARD_WIDTH = isNativeOrResponsive ? width * 0.85 : 400;

  const { customer } = useMe();
  const navigation = useNavigation<HomepageNavigationProps>();

  const canSeeDots = isNativeOrResponsive || !isGreaterThanTablet;
  const styles = getStyles({ CARD_WIDTH, activeBreakPoint, isNativeOrResponsive, theme, width });

  const callbackOnBookmark = () => {
    setValidMsg({
      icon: 'HeartFilled',
      title: t('searchEngine.toast.favorite.add'),
      withClose: true,
    });
  };

  const callbackUnBookmark = () => {
    setValidMsg({
      icon: 'Heart',
      title: t('searchEngine.toast.favorite.remove'),
      withClose: true,
    });
  };

  const { toggleBookmark } = useBookmark({
    callbackOnBookmark,
    callbackUnBookmark,
  });

  const owner = useMemo(
    () => ({
      avatar: fromS3ID(customer?.owner?.avatarS3ID, `w-300`),
      name: customer?.owner?.firstName,
    }),
    [customer?.owner?.avatarS3ID, customer?.owner?.firstName]
  );

  const {
    handleApprovePositioning,
    handleCancelPositioning,
    handleNeedCallAfterConfirmPositioning,
  } = usePositioningActions();

  const handleItemClick = useCallback(
    (item: RealEstatePreviewItem): (() => void) =>
      () => {
        if (isWeb()) {
          navigation.getParent()?.navigate(MainMenuNavigatorRoutes.RealEstates, {
            params: {
              id: item.id,
            },
            screen: RealEstateNavigatorRoutes.RealEstate,
          });
        } else {
          navigation.navigate(HomePageNavigatorRoutes.RealEstate, {
            id: item.id,
          });
        }
      },
    [navigation]
  );
  const handleItemBookmark = useCallback(
    (item: RealEstatePreviewItem): (() => void) =>
      () =>
        toggleBookmark(item, {
          currentTab: TrackingOrigin.PREVIEW,
          page: PropertyPage.SUGGESTIONS,
        }),
    [toggleBookmark]
  );

  return (
    <View>
      <Carousel
        showDots={canSeeDots}
        ref={inputRef}
        width={isNativeOrResponsive ? width * 0.85 : CARD_WIDTH}
        style={styles.carousel}
        loop={false}
        data={suggestions}
        panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
        height={520}
        renderItem={({ item }) => {
          const { realEstateItem } = item;
          return (
            <View key={item.id} style={styles.cardContainer}>
              <SuggestionCard
                {...realEstateItem}
                maxWidth={CARD_WIDTH}
                advisorName={owner.name}
                afterConfirmCallLaunched={item.afterConfirmCallLaunched}
                onBookmark={handleItemBookmark(realEstateItem)}
                onClick={handleItemClick(realEstateItem)}
                onApprovePositioning={handleApprovePositioning(item.id, item.realEstateItem.id)}
                onCancelPositioning={handleCancelPositioning(item.id, item.isSuggestion)}
                onTooltipHover={() => null}
                onNeedCallAfterConfirmPositioning={handleNeedCallAfterConfirmPositioning(item.id)}
                positioningStatus={item.status}
                expirationDate={item.expirationDate ? item.expirationDate : new Date()}
              />
            </View>
          );
        }}
      />
    </View>
  );
};
