import { Theme } from '@app/ui/theme/types';

export const phoneInputTheme = (theme: Theme) => {
  return {
    chevron: {
      borderRadius: theme.borderRadius.SIZE_02,
      closedBackground: 'transparent',
      marginLeft: theme.spacing.SIZE_02,
      marginRight: theme.spacing.SIZE_03,
      openBackground: theme.palette.neutral[200],
    },
    iconSize: theme.spacing.SIZE_05,
    input: {
      paddingBottom: theme.spacing.NONE,
    },
    prefixPositionBottom: theme.spacing.SIZE_05,
    prefixPositionLeft: theme.spacing.SIZE_04,
    prefixText: {
      paddingLeft: theme.spacing.SIZE_02,
    },
    selectButton: {
      marginBottom: -theme.spacing.SIZE_02,
      marginRight: theme.spacing.SIZE_03,
    },
    textMarginLeft: theme.spacing.SIZE_02,
  };
};
