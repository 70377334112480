import { StyleSheet } from 'react-native';

import { DotsPos } from './Carousel.types';

export const getStyle = (dots?: DotsPos) =>
  StyleSheet.create({
    arrow: {
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      zIndex: 10,
    },
    arrowLeft: {
      left: 0,
      paddingLeft: 12,
    },
    arrowRight: {
      paddingRight: 12,
      right: 0,
    },
    container: { height: '100%', width: '100%' },
    containerSlider: { flexGrow: 1 },
    dots: {
      alignItems: 'center',
      padding: 5,
    },
    dotsPosition:
      dots === DotsPos.absolute
        ? {
            bottom: 0,
            position: 'absolute',
            width: '100%',
          }
        : {},
  });
