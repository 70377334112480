import * as React from 'react';

import Svg, { Mask, Rect, G, Path } from 'react-native-svg';

const SvgAl = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Mask
        id="al_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#al_svg__a)">
        <Path fill="#EE343C" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.386 4.386a.667.667 0 00-.84-.083l-.59.393a.667.667 0 00.072 1.151l1.27.635a.667.667 0 01.369.597v.254A.667.667 0 0112 8h-.51a.667.667 0 01-.297-.07L9.096 6.882a.667.667 0 00-.77.124l-.339.34a.667.667 0 00.174 1.068l.944.472c.399.2.433.755.062 1.003a.58.58 0 00.208 1.053l.562.112c.54.108.698.803.257 1.133l-.503.378a.427.427 0 00.36.756l1.262-.316a.54.54 0 01.58.823.54.54 0 00.448.839h.584c.244 0 .46.156.537.387a.567.567 0 001.075 0 .567.567 0 01.538-.387h.584a.54.54 0 00.449-.84.54.54 0 01.58-.822l1.261.316a.427.427 0 00.36-.756l-.503-.377a.637.637 0 01.257-1.134l.562-.112a.581.581 0 00.208-1.053.58.58 0 01.062-1.003l.944-.472a.667.667 0 00.174-1.068l-.34-.34a.667.667 0 00-.77-.124L16.808 7.93a.666.666 0 01-.298.07H16a.667.667 0 01-.667-.667V7.08c0-.253.143-.484.369-.597l1.27-.635a.667.667 0 00.072-1.15l-.59-.394a.667.667 0 00-.84.083L14.47 5.53a.667.667 0 01-.942 0l-1.143-1.143z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgAl;
