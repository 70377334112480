import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSchool = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 20.725a1.925 1.925 0 01-.95-.25l-5-2.7a2.004 2.004 0 01-.775-.737A1.994 1.994 0 015 16v-4.8L2.6 9.875a.973.973 0 01-.4-.375 1.012 1.012 0 01-.125-.5c0-.183.042-.35.125-.5a.973.973 0 01.4-.375l8.45-4.6a1.928 1.928 0 011.9 0l9.525 5.2a.909.909 0 01.388.362c.091.159.137.33.137.513V16c0 .283-.096.52-.288.712A.965.965 0 0122 17a.965.965 0 01-.712-.288A.965.965 0 0121 16v-5.9l-2 1.1V16c0 .383-.092.73-.275 1.038a2.004 2.004 0 01-.775.737l-5 2.7a1.925 1.925 0 01-.95.25zm0-8.025L18.85 9 12 5.3 5.15 9 12 12.7zm0 6.025l5-2.7V12.25l-4.025 2.225a2.04 2.04 0 01-1.475.187 1.926 1.926 0 01-.475-.187L7 12.25v3.775l5 2.7z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSchool;
