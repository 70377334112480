import React from 'react';

import { View } from 'react-native';

import { Card, Chip, Text, useResponsive } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useTranslation } from '@app/services/translations/translations';
import { RealEstateChips } from '@app/shared/hooks/useRealEstateChips';

import { getBlockStyles, styles } from './projectCard.styles';
import { ProjectCardTitle } from './ProjectCardTitle';
import { ProjectListCardProps } from './projectCard.types';
import { getProjectCardPreviewUrl } from './projectCard.utils';

type InProgressProjectCardProps = ProjectListCardProps & {
  projectAmount: number;
  chips: RealEstateChips;
};

export const InProgressProjectCard: React.FC<InProgressProjectCardProps> = ({
  projectAmount,
  coverImage,
  title,
  address,
  onPress,
  chips,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const formattedProjectAmount = currencyFormatter(projectAmount, 0);

  const blockStyles = getBlockStyles(isMobile);

  const chipList = [
    { text: chips.type },
    { text: chips.bedrooms },
    { text: chips.lots },
    { text: chips.surface },
  ];

  return (
    <Card onPress={onPress} testID="in-progress-project-card">
      <Card.Header>
        <Card.Chip position="topright" text={t('projectList.status.onGoing')} iconName="Clock" />

        <Card.Image imageUri={getProjectCardPreviewUrl(coverImage)} />
      </Card.Header>
      <Card.Content>
        <Card.Block withLine>
          <ProjectCardTitle address={address} realEstateTitle={title} />
        </Card.Block>
        <Card.Block>
          <View style={styles.block}>
            <View style={blockStyles.chipWrapper}>
              <View style={styles.wrapper}>
                {chipList.map((chip, index) =>
                  chip.text ? <Chip {...chip} style={styles.chip} key={index} /> : null
                )}
              </View>
            </View>

            <Text textStyle="Body2Medium" style={blockStyles.amountText}>
              {formattedProjectAmount}
            </Text>
          </View>
        </Card.Block>
      </Card.Content>
    </Card>
  );
};
