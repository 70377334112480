import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgClock = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M14.462 16.04a.917.917 0 00.675.275c.266 0 .5-.1.7-.3a.948.948 0 00.275-.7.949.949 0 00-.275-.7l-3-3V7.99a.928.928 0 00-.287-.7.993.993 0 00-.713-.275.967.967 0 00-.712.287.968.968 0 00-.288.713v3.975a1.03 1.03 0 00.3.725l3.325 3.325zm-2.625 5.975a9.731 9.731 0 01-3.9-.788 10.09 10.09 0 01-3.175-2.137c-.9-.9-1.613-1.958-2.137-3.175a9.732 9.732 0 01-.788-3.9c0-1.383.262-2.683.788-3.9A10.092 10.092 0 014.762 4.94c.9-.9 1.958-1.612 3.175-2.138a9.743 9.743 0 013.9-.787c1.383 0 2.683.263 3.9.787a10.106 10.106 0 013.175 2.138c.9.9 1.612 1.959 2.137 3.175a9.733 9.733 0 01.788 3.9 9.732 9.732 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.959 1.613-3.175 2.137a9.731 9.731 0 01-3.9.788zm0-2c2.216 0 4.104-.779 5.663-2.337 1.558-1.558 2.337-3.446 2.337-5.663 0-2.216-.78-4.104-2.337-5.663-1.559-1.558-3.447-2.337-5.663-2.337-2.217 0-4.104.78-5.662 2.337C4.616 7.911 3.837 9.8 3.837 12.015c0 2.217.779 4.105 2.338 5.663 1.558 1.558 3.445 2.337 5.662 2.337z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgClock;
