import React, { useMemo } from 'react';

import { View } from 'react-native';

import { BorderRadius } from '@app/ui/theme/Sizes';
import { useTheme } from '@app/ui/contexts/config';

import { getStyle } from './RadiusContainer.styles';
import { RadiusContainerType } from './RadiusContainer.types';

export { RadiusContainerType };

export const RadiusContainer: React.FC<RadiusContainerType> = ({
  children,
  style,
  testID,
  variant = 'primary',
  radiusSize = BorderRadius.SIZE_01,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyle({ radiusSize, theme }), [radiusSize, theme]);
  return (
    <View testID={testID} style={[styles[variant], styles.common, style]}>
      {children}
    </View>
  );
};
