import { StyleSheet } from 'react-native';

import { RequiredThemes } from '@app/ui/theme/types';

export const getStyles = ({
  componentTheme,
  isOpened,
}: {
  componentTheme: RequiredThemes['phoneInput'];
  isOpened?: boolean;
}) => {
  return StyleSheet.create({
    chevronPrefixStyle: {
      backgroundColor: isOpened
        ? componentTheme.chevron.openBackground
        : componentTheme.chevron.closedBackground,
      borderRadius: componentTheme.chevron.borderRadius,
      marginLeft: componentTheme.chevron.marginLeft,
      marginRight: componentTheme.chevron.marginRight,
    },
    input: {
      alignSelf: 'auto',
    },
    selectButton: {
      alignItems: 'center',
      bottom: componentTheme.prefixPositionBottom,
      flexDirection: 'row',
      left: componentTheme.prefixPositionLeft,
      position: 'absolute',
      zIndex: 2,
    },
    textPrefixStyle: {
      marginLeft: componentTheme.textMarginLeft,
    },
  });
};
