import React, { FC, useMemo } from 'react';

import { View } from 'react-native';

import { ShadowContainer } from '@app/ui/atoms/shadow-container/ShadowContainer';
import { FoldableItem } from '@app/ui/molecules/foldable-item/FoldableItem';
import { ExpandableGroup } from '@app/ui/atoms/expandable/ExpandableGroup';
import { Expandable, ExpandableContext } from '@app/ui/atoms/expandable/Expandable';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { useTheme } from '@app/ui/contexts/config';

import { getStyle } from './FoldableSection.styles';
import { FoldableSectionType } from './FoldableSection.types';

export { FoldableSectionType };
export const FoldableSection: FC<FoldableSectionType> = ({
  title,
  icon,
  subtitle,
  onPress,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const styles = useMemo(() => getStyle({ isMobile, theme }), [isMobile, theme]);

  return (
    <ExpandableGroup {...rest}>
      <ShadowContainer style={styles.container} shadowSize={100}>
        <Expandable id="expandable" onPress={onPress}>
          <Expandable.Header>
            <View>
              <ExpandableContext.Consumer>
                {({ expanded }) => (
                  <FoldableItem open={expanded} icon={icon} title={title} subtitle={subtitle} />
                )}
              </ExpandableContext.Consumer>
            </View>
          </Expandable.Header>
          <Expandable.Content>
            <View style={styles.contentContainer}>{children}</View>
          </Expandable.Content>
        </Expandable>
      </ShadowContainer>
    </ExpandableGroup>
  );
};
