import { formatOrdinal } from '@app/services/numbersFormatter/numbersFormatter';
import { GetCustomerQuery, Role } from '@app/libs/apollo/introspection';

export const getVisibilityRights = (
  customer?: GetCustomerQuery['me']
): {
  canSeePositioningButton: boolean;
  canSeeLikeButton: boolean;
  canSeeLoginButton: boolean;
  canSeeMandateButton: boolean;
  canSeeAppointmentButton: boolean;
} => {
  const isAssetManagementConsultant = customer?.account.roles?.includes(
    Role.AssetManagementConsultant
  );
  const canSeeLoginButton = !customer?.id;
  const canSeeMandateButton = !!customer?.id && !customer.searchMandate?.valid;
  const canSeeAppointmentButton =
    !!customer?.id && !customer.searchMandate?.valid && !isAssetManagementConsultant;
  const canSeeLikeButton = !!customer?.id && !!customer.owner && !!customer.searchMandate?.valid;
  const canSeePositioningButton = !!customer?.id && !!customer.owner;

  return {
    canSeeAppointmentButton,
    canSeeLikeButton,
    canSeeLoginButton,
    canSeeMandateButton,
    canSeePositioningButton,
  };
};

export const propertyFloorToString = (
  groundString: string,
  floor?: number | null
): string | null => {
  if (!floor) {
    return null;
  }

  if (floor === 0) {
    return groundString;
  }

  return formatOrdinal(floor);
};
