import React, { useCallback, useMemo } from 'react';

import { Controller } from 'react-hook-form';

import { FilterListSelectorOptionType, FilterSortSelector } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { formatOption, selectValue } from '@app/utils/selector.utils';
import { SearchEngineSource } from '@app/features/search-engine/searchEngine.types';
import { isWeb } from '@app/constants/platform';

import { getSingleValue, getSortStrategyList } from './SearchEngineSort.utils';
import { SearchEngineSortProps, SORT_STRATEGY } from './SearchEngineSort.types';

export const SearchEngineSort: React.FC<SearchEngineSortProps> = ({
  onSubmit,
  onSubmitError,
  form,
  defaultValues,
}) => {
  const field = SORT_STRATEGY;

  const { t } = useTranslation();

  const { control, handleSubmit, reset } = form;

  const sortStrategyList = useMemo(() => getSortStrategyList(t), [t]);

  const submit = useCallback(
    (closeList?: () => void, resetMode = false) => {
      handleSubmit(
        v =>
          Promise.resolve(onSubmit(v, SearchEngineSource.SORT_STRATEGY, resetMode))
            .catch(() => reset(defaultValues))
            .finally(() => {
              if (isWeb()) {
                window.scrollTo({ behavior: 'smooth', top: 0 });
              }
            }),
        v => onSubmitError?.(v)
      )();

      closeList?.();
    },
    [defaultValues, handleSubmit, onSubmit, onSubmitError, reset, field]
  );

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <FilterSortSelector
            options={formatOption({
              optionList: sortStrategyList,
              value,
            })}
            value={getSingleValue(value, sortStrategyList)[0]}
            onSelectValue={(item, closeList) => {
              selectValue({
                item: item as FilterListSelectorOptionType,
                list: sortStrategyList,
                onChange,
                value,
              });
              submit(closeList);
            }}
            label={t('searchEngine.sort.label')}
          />
        );
      }}
      name={field}
    />
  );
};
