import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLoading = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M14.225 21.75a.922.922 0 01-.875-.2c-.25-.217-.375-.517-.375-.9 0-.183.071-.354.213-.513.141-.158.304-.262.487-.312.467-.117.921-.263 1.363-.438a6.741 6.741 0 001.262-.662.908.908 0 01.575-.15c.2.017.367.092.5.225.267.267.388.57.363.912a.962.962 0 01-.488.788c-.467.283-.954.53-1.462.738-.509.208-1.03.379-1.563.512zm4.6-4.375a.845.845 0 01-.238-.512.789.789 0 01.138-.563c.25-.4.467-.821.65-1.263a7.8 7.8 0 00.425-1.362c.05-.2.158-.367.325-.5a.86.86 0 01.55-.2c.367 0 .654.133.863.4.208.267.27.567.187.9a10.807 10.807 0 01-1.25 3 .954.954 0 01-.762.462c-.325.025-.621-.095-.888-.362zm1.825-6.4a.83.83 0 01-.525-.2.909.909 0 01-.325-.5 7.83 7.83 0 00-.425-1.363 8.602 8.602 0 00-.65-1.262.81.81 0 01-.138-.575.858.858 0 01.238-.525c.25-.267.542-.383.875-.35a1 1 0 01.775.475c.3.467.554.95.763 1.45.208.5.379 1.017.512 1.55a.98.98 0 01-.2.9c-.217.267-.517.4-.9.4zM9.725 21.725c-2.233-.533-4.075-1.7-5.525-3.5-1.45-1.8-2.175-3.883-2.175-6.25s.725-4.45 2.175-6.25c1.45-1.8 3.3-2.967 5.55-3.5a.922.922 0 01.875.2c.25.217.375.508.375.875 0 .183-.07.354-.212.512a1.005 1.005 0 01-.488.313c-1.833.417-3.337 1.35-4.512 2.8-1.175 1.45-1.763 3.133-1.763 5.05 0 1.917.588 3.6 1.763 5.05 1.175 1.45 2.67 2.383 4.487 2.8.183.05.346.154.488.312a.787.787 0 01.212.538c0 .367-.125.654-.375.863-.25.208-.542.27-.875.187zM16.35 5.25a8.525 8.525 0 00-2.65-1.125 1 1 0 01-.487-.313.782.782 0 01-.213-.537c0-.367.125-.658.375-.875a.922.922 0 01.875-.2c.533.133 1.054.304 1.562.512.509.209.996.455 1.463.738.3.183.467.446.5.787.033.342-.083.646-.35.913a.787.787 0 01-.512.225.924.924 0 01-.563-.125z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLoading;
