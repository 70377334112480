import React, { isValidElement, ReactElement, useMemo } from 'react';

import { View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { Icon } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';

import {
  CircleStepIndicatorProps,
  StepSize,
  StepStatus,
  StepVariant,
} from './CircleStepIndicator.types';
import { getCircleStepIndicatorStyles } from './CircleStepIndicator.styles';

export { CircleStepIndicatorProps, StepStatus, StepSize, StepVariant };

export const CircleStepIndicator: React.FC<CircleStepIndicatorProps> = ({
  indicator,
  size = StepSize.M,
  status = StepStatus.Incoming,
  variant = StepVariant.Neutral,
}) => {
  const theme = useTheme();
  const style = useMemo(
    () => getCircleStepIndicatorStyles({ size, status, theme, variant }),
    [size, status, theme, variant]
  );
  const showTinyIcon = variant === StepVariant.Primary && status === StepStatus.Finished;
  const Elem: ReactElement = isValidElement(indicator) ? (
    <View>{indicator}</View>
  ) : (
    <Text
      textStyle={size === StepSize.S ? 'Caption1Medium' : 'Body3Medium'}
      style={style.indicator}
    >
      {indicator}
    </Text>
  );

  return (
    <View style={style.circle}>
      {showTinyIcon ? (
        <Icon
          size={size === StepSize.S ? 10 : 16}
          style={style.finishedCircle}
          name="CheckFilled"
          color={theme.palette.tint.green[500]}
        />
      ) : null}
      {Elem}
    </View>
  );
};
