import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgFloorLevel = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M7 14v3c0 .283.096.52.287.712.192.192.43.288.713.288h1c.283 0 .52-.096.713-.288A.968.968 0 0 0 10 17v-3c.183-.183.396-.33.637-.438A.57.57 0 0 0 11 13v-1.5c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 9 9.5H8c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 6 11.5V13c0 .267.12.454.362.563.242.108.455.254.638.437Zm1.5-5.5c.35 0 .646-.12.887-.363.242-.241.363-.537.363-.887s-.12-.646-.363-.888A1.207 1.207 0 0 0 8.5 6c-.35 0-.646.12-.888.362a1.207 1.207 0 0 0-.362.888c0 .35.12.646.362.887.242.242.538.363.888.363Zm5.4 2.5h3.2a.46.46 0 0 0 .438-.262.493.493 0 0 0-.013-.513l-1.6-2.55a.47.47 0 0 0-.425-.25.47.47 0 0 0-.425.25l-1.6 2.55a.493.493 0 0 0-.012.513.46.46 0 0 0 .437.262Zm2.025 5.325 1.6-2.55a.493.493 0 0 0 .013-.513A.46.46 0 0 0 17.1 13h-3.2a.46.46 0 0 0-.438.262.493.493 0 0 0 .013.513l1.6 2.55c.1.167.242.25.425.25a.47.47 0 0 0 .425-.25ZM5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3h14c.55 0 1.02.196 1.413.587C20.803 3.98 21 4.45 21 5v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21H5Zm0-2h14V5H5v14Z"
    />
  </Svg>
);
export default SvgFloorLevel;
