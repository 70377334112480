import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPaperclip = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.124 22.144c-1.533 0-2.833-.534-3.9-1.6-1.067-1.067-1.6-2.367-1.6-3.9v-10.5c0-1.1.392-2.042 1.175-2.825.783-.784 1.725-1.175 2.825-1.175s2.042.391 2.825 1.175c.783.783 1.175 1.725 1.175 2.825v9.5c0 .7-.242 1.292-.725 1.775-.483.483-1.075.725-1.775.725s-1.292-.242-1.775-.725c-.483-.483-.725-1.075-.725-1.775v-8.75a.726.726 0 01.75-.75.728.728 0 01.75.75v8.75c0 .283.096.52.288.712a.965.965 0 00.712.288.968.968 0 00.713-.288.967.967 0 00.287-.712v-9.5c0-.7-.242-1.292-.725-1.775-.483-.484-1.075-.725-1.775-.725s-1.292.241-1.775.725c-.483.483-.725 1.075-.725 1.775v10.5c0 1.1.392 2.042 1.175 2.825.783.783 1.725 1.175 2.825 1.175s2.042-.392 2.825-1.175c.783-.784 1.175-1.725 1.175-2.825v-9.75a.726.726 0 01.75-.75.728.728 0 01.75.75v9.75c0 1.533-.533 2.833-1.6 3.9-1.067 1.066-2.367 1.6-3.9 1.6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPaperclip;
