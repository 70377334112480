import { LinkingOptions } from '@react-navigation/native';

import { config } from '@app/config';
import {
  AdvisorNavigatorRoutes,
  DiscussNavigatorRoutes,
  ForgotPasswordNavigatorRoutes,
  HomePageNavigatorRoutes,
  MainMenuNavigatorRoutes,
  PrivateNavigatorRoutes,
  ProfileNavigatorRoutes,
  ProjectNavigatorRoutes,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
} from '@app/navigation/types/routes';
import { isWeb } from '@app/constants/platform';

import { subscribe } from '../utils/deeplink';

const LOCALHOST = 'http://localhost:9000';

const prefixes = [config.WEB_APP_URL ?? LOCALHOST];

const MAIN_MENU_LINKING_PATHS = {
  [MainMenuNavigatorRoutes.HomePage]: '',
  [MainMenuNavigatorRoutes.Advisor]: 'advisor',
  [MainMenuNavigatorRoutes.Profile]: 'profile',
  [MainMenuNavigatorRoutes.Projects]: 'projects',
  [MainMenuNavigatorRoutes.RealEstates]: 'property',
  [MainMenuNavigatorRoutes.Discuss]: 'discuss',
};

export const privateLinking: LinkingOptions<ReactNavigation.RootParamList> = {
  config: {
    screens: {
      [PrivateNavigatorRoutes.Onboarding]: 'welcome',
      NotFound: '*',
      [PrivateNavigatorRoutes.MainMenu]: {
        path: '',
        screens: {
          [MainMenuNavigatorRoutes.HomePage]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.HomePage],
            screens: {
              [HomePageNavigatorRoutes.HomePageRoot]: '',
            },
          },
          [MainMenuNavigatorRoutes.Advisor]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Advisor],
            screens: {
              [AdvisorNavigatorRoutes.AdvisorAppointment]: 'take-appointment',
              [AdvisorNavigatorRoutes.AdvisorRoot]: '',
            },
          },
          [MainMenuNavigatorRoutes.Discuss]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Discuss],
            screens: {
              [DiscussNavigatorRoutes.DiscussRoot]: '',
              [DiscussNavigatorRoutes.DiscussTakeAppointmentConfirmation]:
                'take-appointment-confirmation',
            },
          },
          [MainMenuNavigatorRoutes.Profile]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Profile],
            screens: {
              [ProfileNavigatorRoutes.ProfileRoot]: '',
              [ProfileNavigatorRoutes.ProfileDocument]: 'document/:id',
            },
          },
          [MainMenuNavigatorRoutes.Projects]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Projects],
            screens: {
              [ProjectNavigatorRoutes.ProjectList]: '',
              [ProjectNavigatorRoutes.Project]: ':id',
            },
          },

          [MainMenuNavigatorRoutes.RealEstates]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.RealEstates],
            screens: {
              [RealEstateNavigatorRoutes.RealEstateList]: '',
              [RealEstateNavigatorRoutes.RealEstateSold]: 'sold/:id',
              [RealEstateNavigatorRoutes.RealEstate]: ':id',
            },
          },
        },
      },
    },
  },

  prefixes,
  subscribe,
};

export const publicLinking: LinkingOptions<ReactNavigation.RootParamList> = {
  config: {
    screens: {
      [PublicNavigatorRoutes.Auth]: 'auth',
      [PublicNavigatorRoutes.ForgotPassword]: {
        path: 'forgot-password',
        screens: {
          [ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep]: 'codeStep',
          [ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep]: 'newPassword',
          [ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep]: '',
        },
      },
      [PublicNavigatorRoutes.Login]: 'login',
      [PublicNavigatorRoutes.PublicHome]: 'public-home',
      [PublicNavigatorRoutes.Register]: 'onboarding/register',
      [PublicNavigatorRoutes.SsoRedirect]: 'sso-redirect',

      NotFound: '*',

      ...(isWeb() && {
        [PublicNavigatorRoutes.RealEstate]: 'property/:id',
        [PublicNavigatorRoutes.RealEstateSold]: 'property/sold/:id',
      }),
    },
  },
  prefixes,
};
