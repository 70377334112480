import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgKitchen = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5 20a.968.968 0 01-.713-.288A.967.967 0 014 19v-7H3a.965.965 0 01-.712-.288A.965.965 0 012 11c0-.283.096-.521.288-.713A.967.967 0 013 10h3c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 014 8V5a.97.97 0 01.287-.713A.97.97 0 015 4h4a.97.97 0 01.713.287A.97.97 0 0110 5v3c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 018 10h8V7a.97.97 0 00-.287-.713A.97.97 0 0015 6a.943.943 0 00-.5.137c-.15.092-.292.23-.425.413a1.013 1.013 0 01-.387.35.97.97 0 01-.413.1c-.433 0-.737-.163-.912-.488-.175-.325-.155-.67.062-1.037A3.203 3.203 0 0113.5 4.412C13.95 4.137 14.45 4 15 4c.833 0 1.542.292 2.125.875S18 6.167 18 7v3h3c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0121 12h-1v7c0 .283-.096.52-.288.712A.965.965 0 0119 20H5zM6 8h2V6H6v2zm0 10h5v-6H6v6zm7 0h5v-6h-5v6z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgKitchen;
