import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGw = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="gw_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#gw_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28V9.333H0V20z" fill="#1AB558" />
        <Path d="M0 9.333h28V0H0v9.333z" fill="#FFD952" />
        <Path d="M0 20h10.667V0H0v20z" fill="#E72B42" />
        <Path
          d="M5.333 11.278l-1.959 1.419.744-2.302L2.163 8.97l2.419-.004.751-2.3.752 2.3 2.419.004-1.955 1.425.744 2.302-1.96-1.419z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgGw;
