import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgPf = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.333h28V0H0v5.333zM0 20h28v-5.333H0V20z"
        fill="#D82332"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.068 10.753l-.285.265c.257.072.614-.012.756-.265h-.471zM10.773 10.933c.192.193.51.17.703.049a.656.656 0 01.454-.117c.013.108.041.248.09.385h-1.142a3.867 3.867 0 01-.105-.317zM10.984 11.448a.557.557 0 01.553.054c.183.141.485.103.633.023.057.092.126.183.209.253h-1.222a2.676 2.676 0 01-.173-.33zM16.298 12.325a2.25 2.25 0 00.174-.208c-.08-.01-.17-.067-.269-.116-.18-.09-.351-.09-.505.016-.227.157-.51.196-.732.039-.242-.173-.467-.135-.656-.026a.746.746 0 01-.423.115.746.746 0 01-.423-.115c-.19-.11-.414-.147-.657.026-.22.157-.505.118-.732-.039-.153-.106-.325-.106-.505-.016-.098.049-.19.106-.268.116.047.064.12.153.173.208h4.823zM15.707 12.861c.05-.032.19-.138.258-.192-.075.016-.248-.04-.337-.087-.26-.135-.43-.122-.622-.02-.193.104-.464.158-.663.05-.199-.11-.34-.139-.445-.139-.104 0-.246.029-.445.138-.199.11-.47.055-.663-.048-.192-.103-.363-.116-.622.02-.089.046-.261.102-.337.086.07.054.208.16.258.192h3.618zM14.568 11.795a.57.57 0 01-.173-.182c-.092-.138-.237-.215-.455-.215-.218 0-.363.077-.454.215a.57.57 0 01-.174.182h1.256zM14.21 11.3a1.279 1.279 0 01-.085-.225.855.855 0 01-.205.023.855.855 0 01-.205-.023 1.279 1.279 0 01-.085.225h.58zM14.736 10.753l.284.265c-.257.072-.613-.012-.755-.265h.471zM17.1 10.933c-.193.193-.512.17-.704.049a.656.656 0 00-.454-.117 1.843 1.843 0 01-.09.385h1.142c.028-.07.083-.235.105-.317zM16.929 11.448a.557.557 0 00-.552.054c-.183.141-.486.103-.634.023a1.03 1.03 0 01-.209.253h1.222c.05-.08.157-.277.173-.33zM15.159 13.072a3.23 3.23 0 01-2.482 0c.082-.01.19-.05.279-.1.142-.08.4-.096.524-.016.123.08.271.16.438.16.168 0 .316-.08.439-.16s.382-.064.524.016c.088.05.196.09.278.1z"
        fill="#064A89"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.625 6.727c-.691-.144-1.313-.061-1.313.6v1.809c0 .198.004.224.237.279l1.101.26v-1.86c-.429-.049-.593-.294-.593-.578 0-.276.19-.456.568-.51zM12.854 11.829c-.385 0-.79-.34-.79-.988V9.23c0-.146.017-.167-.017-.2-.02-.02.022-.05.05-.08.043-.043.073-.079.015-.137-.057-.059-.033-.074.003-.111.037-.037.05-.074.016-.11-.035-.034-.043-.046-.005-.084.038-.039.023-.055-.01-.087-.03-.032-.046-.055-.005-.096.04-.04.042-.046.088-.046h.19c.044 0 .044.013.007.051-.048.049-.062.068-.008.123.055.056.052.074.018.11-.052.052-.075.088-.022.142.053.054.057.078.017.119-.04.04-.044.077 0 .122.034.034.036.054.036.115v1.644c0 .276.196.443.568.443.373 0 .5-.154.606-.257-.069.494-.271.937-.757.937zM13.94 10.255c.053.016.103.093.103.176h.103c0-.07-.03-.18-.085-.247a.232.232 0 00-.007-.048c.038-.048.093-.125.092-.199-.001-.046-.055-.045-.062-.008-.005.03-.025.077-.063.093 0-.059-.033-.13-.08-.13-.048 0-.08.071-.08.13-.039-.016-.059-.062-.064-.093-.006-.037-.06-.038-.062.008-.001.074.054.151.092.2a.239.239 0 00-.007.047.442.442 0 00-.085.247h.103c0-.083.05-.16.103-.176z"
        fill="#514537"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.412 10.255c.052.016.102.093.102.176h.103c0-.07-.03-.18-.085-.247a.226.226 0 00-.007-.048c.038-.048.093-.125.092-.199-.001-.046-.056-.045-.062-.008-.005.03-.025.077-.063.093 0-.059-.033-.13-.08-.13-.048 0-.08.071-.08.13-.039-.016-.059-.062-.064-.093-.006-.037-.06-.038-.062.008-.001.074.054.151.092.2a.239.239 0 00-.007.047.442.442 0 00-.085.247h.103c0-.083.05-.16.103-.176zM12.883 10.255c.052.016.102.093.102.176h.103c0-.07-.03-.18-.085-.247a.232.232 0 00-.007-.048c.038-.048.093-.125.092-.199-.001-.046-.056-.045-.062-.008-.005.03-.025.077-.063.093 0-.059-.033-.13-.08-.13-.048 0-.081.071-.081.13-.038-.016-.058-.062-.063-.093-.007-.037-.06-.038-.062.008-.001.074.054.151.092.2a.232.232 0 00-.007.047.441.441 0 00-.085.247h.103c0-.083.05-.16.103-.176zM14.47 10.255c-.052.016-.103.093-.103.176h-.102c0-.07.03-.18.084-.247 0-.017.003-.032.007-.048-.038-.048-.093-.125-.091-.199 0-.046.055-.045.061-.008.005.03.025.077.063.093 0-.059.034-.13.08-.13.048 0 .081.071.081.13.038-.016.058-.062.063-.093.007-.037.06-.038.062.008.002.074-.054.151-.092.2.004.014.007.03.008.047a.441.441 0 01.084.247h-.103c0-.083-.05-.16-.102-.176zM14.999 10.255c-.052.016-.103.093-.103.176h-.102c0-.07.03-.18.084-.247.001-.017.003-.032.007-.048-.038-.048-.093-.125-.091-.199 0-.046.055-.045.061-.008.005.03.025.077.063.093 0-.059.034-.13.08-.13.048 0 .081.071.081.13.038-.016.058-.062.063-.093.007-.037.061-.038.062.008.002.074-.054.151-.091.2.003.014.006.03.007.047a.441.441 0 01.084.247h-.103c0-.083-.05-.16-.102-.176zM12.571 10.538h2.778v.133h-2.778v-.133z"
        fill="#514537"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.022 11.829c.385 0 .79-.34.79-.988V9.23c0-.146-.017-.167.017-.2.02-.02-.023-.05-.05-.08-.043-.043-.073-.079-.015-.137.058-.059.033-.074-.003-.111-.037-.037-.05-.074-.016-.11.035-.034.043-.046.005-.084-.038-.039-.023-.055.009-.087.032-.032.047-.055.006-.096-.04-.04-.042-.046-.088-.046h-.19c-.043 0-.044.013-.007.051.048.049.062.068.008.123-.055.056-.052.074-.018.11.052.052.075.088.022.142-.053.054-.057.078-.017.119.04.04.044.077 0 .122-.034.034-.036.054-.036.115v1.644c0 .276-.196.443-.568.443-.373 0-.499-.154-.606-.257.069.494.271.937.757.937z"
        fill="#514537"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.571 9.674v-.212l.556.212h-.556zM11.91 9.773l-1.243-.17c.036-.271.104-.535.202-.786l1.04.42v.536zM14.476 7.696l.221-.922c.258.064.51.16.748.286l-.713 1.386.003-.696c-.082 0-.208-.012-.259-.054zM11.938 9.863l-1.265-.078c-.017.27 0 .542.048.81 0 0 1.217.008 1.217 0v-.732zM11.926 8.996c-.041-.055-.016-.084.028-.113.044-.029.038-.051.016-.093s-.044-.086-.003-.131c.041-.045.025-.064-.006-.097-.032-.032-.035-.067-.007-.093.029-.025.022-.044.004-.07-.02-.026-.057-.078-.023-.111l-.517-.439c-.17.208-.316.437-.434.682l.942.465zM13.163 9.234l-.616-.606c.087-.088.096-.103.034-.165-.067-.068-.07-.075.067-.214.047-.047.02-.08-.045-.08h-.406c-.035 0-.062.001-.087.027l-.491-.482c.187-.196.396-.367.622-.51l.922 1.506v.524zM12.523 9.327v-.321c0-.045-.003-.063-.038-.098-.035-.035-.017-.052.01-.09l.583.493v.29l-.555-.274zM13.07 8.446v-.71c0-.463.025-.694.091-.74l-.053-.222c-.259.071-.51.167-.748.293l.71 1.38zM15.349 9.674v-.212l-.555.212h.555zM15.958 9.773l1.242-.17a3.386 3.386 0 00-.202-.786l-1.04.42v.536zM15.958 9.863l1.265-.078c.017.27 0 .542-.048.81 0 0-1.217.008-1.217 0v-.732zM15.918 8.996c.04-.055.015-.084-.029-.113-.044-.029-.038-.051-.016-.093.023-.042.045-.086.004-.131-.042-.045-.026-.064.006-.097.031-.032.035-.067.006-.093-.028-.025-.022-.044-.003-.07.019-.026.056-.078.023-.111l.516-.439c.17.208.317.437.434.682l-.941.465zM14.794 9.234l.615-.606c-.087-.088-.096-.103-.034-.165.067-.068.07-.075-.067-.214-.047-.047-.02-.08.046-.08h.405c.035 0 .062.001.088.027l.49-.482a3.297 3.297 0 00-.622-.51l-.921 1.506v.524zM15.35 9.327l-.001-.321c0-.045.003-.063.038-.098.035-.035.017-.052-.01-.09l-.583.493v.29l.555-.274z"
        fill="#EEA339"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.148 9.032c.022.023.032.045.032.1v1.716c0 .57.34.888.691.888.322 0 .533-.298.644-.645-.142.113-.35.148-.55.148-.23 0-.602-.157-.602-.497V9.077c0-.045-.044-.045-.215-.045zM14.161 6.784c-.44-.045-.848.048-.848.658v1.62c0 .272-.021.24.204.296.41.103.456.117.953.232V7.94c-.424-.047-.608-.329-.608-.641 0-.25.108-.483.299-.515zM15.737 9.032c-.022.023-.031.045-.031.1v1.716c0 .57-.341.888-.692.888-.322 0-.533-.298-.644-.645.143.113.35.148.55.148.23 0 .602-.157.602-.497V9.077c0-.045.045-.045.215-.045z"
        fill="#D82332"
      />
    </Svg>
  );
};

export default SvgPf;
