import { useEffect } from 'react';

import { ApolloError, ApolloQueryResult, useLazyQuery } from '@apollo/client';

import { useTranslation } from '@app/services/translations/translations';
import {
  GetPublicRealEstateDocument,
  GetRealEstateDocument,
  PublicRealEstate,
  RealEstate,
} from '@app/libs/apollo/introspection';
import { getAPILang } from '@app/libs/i18n/helper';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { getPublicToken } from '@app/utils/getPublicToken';

import { useMe } from './useMe';

type UseQueryRealEstateProps = {
  id: string;
};

type ReturnType = {
  loading: boolean;
  error?: ApolloError;
  realEstate?: RealEstate | PublicRealEstate | null;
  refetch?: () => Promise<ApolloQueryResult<unknown>>;
};

export const useQueryRealEstate = ({ id: realEstateId }: UseQueryRealEstateProps): ReturnType => {
  const { customer } = useMe();
  const { i18n } = useTranslation();

  const apiLang = getAPILang(i18n.resolvedLanguage as I18nSupportedLanguage);
  const [getRealEstate, { loading, error, data, refetch }] = useLazyQuery<{
    realEstate: RealEstate;
  }>(GetRealEstateDocument);
  const [
    getPublicRealEstate,
    {
      loading: publicLoading,
      error: publicError,
      data: publicData,
      refetch: refetchPublicRealEstate,
    },
  ] = useLazyQuery(GetPublicRealEstateDocument);

  const token = getPublicToken();
  const shouldUsePrivateRoute = customer?.id || token;

  useEffect(() => {
    const variables = { id: realEstateId, lang: apiLang };
    if (shouldUsePrivateRoute) {
      getRealEstate({ variables: { ...variables, token } });
    } else {
      getPublicRealEstate({ variables });
    }
  }, [apiLang, customer?.id, realEstateId]);

  if (shouldUsePrivateRoute) {
    return { error, loading, realEstate: data?.realEstate, refetch };
  }
  return {
    error: publicError,
    loading: publicLoading,
    realEstate: publicData?.publicRealEstate,
    refetch: refetchPublicRealEstate,
  };
};
