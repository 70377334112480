import { useCallback, useState } from 'react';

import { useFocusEffect } from '@react-navigation/native';

import { checkNotificationsStatus } from '@app/libs/rn-permissions/rn-permissions';
import { NotificationPermissionStatus } from '@app/libs/rn-permissions/rn-permissions.types';

export const useNotificationsStatus = (): {
  isLoading: boolean;
  status?: NotificationPermissionStatus;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<NotificationPermissionStatus>();

  useFocusEffect(
    useCallback(() => {
      let mounted = true;
      setIsLoading(true);
      checkNotificationsStatus().then(notificationStatus => {
        if (mounted) {
          setStatus(notificationStatus);
          setIsLoading(false);
        }
      });

      return () => {
        mounted = false;
      };
    }, [])
  );

  return {
    isLoading,
    status,
  };
};
