import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Chip, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { getShowCaseChipsList } from './showCaseInfoChips.utils';

const getStyles = ({
  theme,
  isGreaterThanTablet,
}: {
  theme: Theme;
  isGreaterThanTablet: boolean;
}) =>
  StyleSheet.create({
    chipList: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing.SIZE_04,
      justifyContent: isGreaterThanTablet ? 'flex-start' : 'center',
      maxWidth: '100%',
    },
    chipWrapper: {
      flexDirection: 'row',
      width: 'auto',
    },
  });

interface ShowCaseInfoChipsProps {
  bookmarkCount: number | undefined;
  positioningCount: number | undefined;
}

const MIN_ACTION_COUNT_HIGH_SUCCESS = 10;

export const ShowCaseInfoChips: React.FC<ShowCaseInfoChipsProps> = ({
  bookmarkCount = 0,
  positioningCount = 0,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const { isGreaterThanTablet } = useResponsive();
  const styles = getStyles({ isGreaterThanTablet, theme });

  const showChips = bookmarkCount > 0 || positioningCount > 0;
  const showHighSuccess = bookmarkCount + positioningCount >= MIN_ACTION_COUNT_HIGH_SUCCESS;

  if (!showChips) {
    return null;
  }

  const chips = getShowCaseChipsList({ bookmarkCount, positioningCount, t });

  return (
    <View style={styles.chipList}>
      {!!showHighSuccess && (
        <Text textStyle="Body3Medium">{t('propertyInfo.fomo.propertySuccessful')}</Text>
      )}

      {chips.map(chip => (
        <Chip
          key={chip.text}
          text={chip.text}
          tint={chip.tint}
          iconName={chip.iconName}
          size="small"
        />
      ))}
    </View>
  );
};
