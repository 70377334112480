import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTc = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tc_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tc_svg__a)">
        <Path fill="#07319C" d="M0 0h28v20H0z" />
        <Path
          d="M17.667 10V6.666a1 1 0 011-1h4a1 1 0 011 1V10c0 1.543-.768 2.626-1.557 3.336a6.254 6.254 0 01-1.443.966h-.001a6.25 6.25 0 01-1.443-.966c-.788-.71-1.556-1.793-1.556-3.336z"
          fill="#FFD033"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Mask
          id="tc_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={17}
          y={5}
          width={7}
          height={10}
        >
          <Path
            d="M17.667 10V6.666a1 1 0 011-1h4a1 1 0 011 1V10c0 1.543-.768 2.626-1.557 3.336a6.254 6.254 0 01-1.443.966h-.001a6.25 6.25 0 01-1.443-.966c-.788-.71-1.556-1.793-1.556-3.336z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.667}
          />
        </Mask>
        <G mask="url(#tc_svg__b)" fillRule="evenodd" clipRule="evenodd">
          <Path
            d="M22 9.333c.368 0 .667-.597.667-1.333S22.368 6.667 22 6.667c-.368 0-.667.597-.667 1.333s.299 1.333.667 1.333z"
            fill="#BA5B1F"
          />
          <Path
            d="M19.538 9.293c.346-.126.423-.789.171-1.48-.252-.693-.737-1.152-1.082-1.026-.346.126-.423.79-.171 1.481.252.692.736 1.151 1.082 1.025z"
            fill="#FF9F3E"
          />
          <Path
            d="M19.052 9.27c.333.157.856-.258 1.167-.926.311-.667.293-1.334-.04-1.49-.334-.156-.857.26-1.168.927-.311.667-.293 1.334.04 1.49z"
            fill="#FF937F"
          />
          <Path d="M20 10.667l.667-1.334.666 1.334V12H20v-1.333z" fill="#01A827" />
        </G>
        <Path
          d="M0-.333h-.901l.684.586 4.55 3.9v1.009L-.194 8.395l-.14.1v1.44l.51-.319L5.43 6.333h1.13l4.523 3.23a.554.554 0 00.682-.87L7.667 5.18V4.172l4.387-3.134a.667.667 0 00.28-.543V-.6l-.51.318L6.57 3h-1.13L.86-.271.772-.333H0z"
          fill="#DB1E36"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.333V6h4.667v3.333c0 .369.298.667.666.667h1.334a.667.667 0 00.666-.667V6H12a.667.667 0 00.667-.667V4A.667.667 0 0012 3.333H7.333V0H4.667v3.333H0z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h5.333V0h1.334v4H12v1.333H6.667v4H5.333v-4H0V4z"
          fill="#DB1E36"
        />
      </G>
    </Svg>
  );
};

export default SvgTc;
