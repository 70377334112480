import React, { useRef, useState } from 'react';

import GoogleMapReact from 'google-maps-react-markers';

import { LocationMarkerIconWithZoom } from '@app/ui';
import { config } from '@app/config';
import { PointOfInterest } from '@app/libs/apollo/introspection';

import MapMarker from './MapMarker';

interface GeoMapProps {
  coord: { lat: number; lng: number };
  pointsOfInterest: PointOfInterest[];
}

const DEFAULT_ZOOM = 14;

export const GeoMap: React.FC<GeoMapProps> = ({ coord, pointsOfInterest }) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const center = { lat: coord.lat, lng: coord.lng };
  const mapRef = useRef(null);

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
  };

  const options = {
    fullscreenControl: false,
    gestureHandling: 'greedy',
    zoomControl: false,
  };

  return (
    <GoogleMapReact
      apiKey={config.GMAPS_API_KEY}
      defaultCenter={coord}
      defaultZoom={zoom}
      zoom={zoom}
      center={center}
      onGoogleApiLoaded={onGoogleApiLoaded}
      yesIWantToUseGoogleMapApiInternals
      resetBoundsOnResize={true}
      onZoomAnimationEnd={val => setZoom(val)}
      options={{ ...options, clickableIcons: false }}
    >
      <LocationMarkerIconWithZoom lat={coord.lat} lng={coord.lng} />
      {pointsOfInterest.map(({ label, subType, latitude, longitude, distanceValue, lines }, i) => (
        <MapMarker
          key={i}
          subType={subType}
          lat={latitude}
          distance={distanceValue}
          lng={longitude}
          label={label}
          lines={lines}
        />
      ))}
    </GoogleMapReact>
  );
};
