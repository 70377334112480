import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="st_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#st_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20zM0 6.667h28V0H0v6.667z" fill="#2ACB41" />
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="#FDD043" />
        <Path d="M0 0l10.667 10L0 20V0z" fill="#E71E43" />
        <Path
          d="M14.667 11.093l-1.568 1.064.528-1.82-1.497-1.161 1.894-.06.643-1.783.642 1.782 1.894.061-1.496 1.162.527 1.82-1.567-1.065zM21.333 11.093l-1.567 1.064.528-1.82-1.497-1.161 1.894-.06.642-1.783.643 1.782 1.893.061-1.496 1.162.528 1.82-1.568-1.065z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgSt;
