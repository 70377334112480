import React from 'react';

import { StyleSheet } from 'react-native';

import { RadiusContainer, Theme, useTheme } from '@app/ui';

import { DataRowsItem, DataRowsItemProps } from './DataRowsItem';

export type DataRowsProps = {
  rows: Pick<DataRowsItemProps, 'iconName' | 'label' | 'description'>[];
  keepColumns?: boolean;
  testID?: string;
};

const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[200],
      borderWidth: 1,
      padding: theme.spacing.SIZE_07,
    },
  });
};

export const DataRows: React.FC<DataRowsProps> = ({ rows, testID, keepColumns = false }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <RadiusContainer
      variant="primary"
      radiusSize={theme.borderRadius.SIZE_06}
      style={styles.container}
      testID={testID}
    >
      {rows.map((row, index) => (
        <DataRowsItem
          key={row.label}
          {...row}
          isLast={index === rows.length - 1}
          keepColumns={keepColumns}
        />
      ))}
    </RadiusContainer>
  );
};
