import React from 'react';

import { useNavigation } from '@react-navigation/core';
import { format } from 'date-fns';

import { Section, ListItem, List } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { ProfileNavigatorRoutes } from '@app/navigation/types/routes';
import * as S from '@app/features/profile/profile.styles.web';
import {
  ProfileDocumentsListNavigation,
  ProfileDocumentsListWebProps,
} from '@app/features/profile/compounds/profileDocuments/profileDocuments.type';

export const ProfileDocumentsList: React.FC<ProfileDocumentsListWebProps> = ({ documentsList }) => {
  const { t } = useTranslation();
  const navigation = useNavigation<ProfileDocumentsListNavigation>();

  const selectDocument = ({ id, category }) => {
    trackChangePage(ProfileNavigatorRoutes.ProfileDocumentsList, {
      document: { category, id: id },
    });
    navigation.navigate(ProfileNavigatorRoutes.ProfileDocument, { id });
  };

  return (
    <Section>
      <List>
        {documentsList.map(document => (
          <S.Document onClick={() => selectDocument(document)} key={document.id}>
            <ListItem
              key={document.id}
              onPress={() => selectDocument(document)}
              label={t(`shared.documentCategory.${document.category}`)}
              description={t('profile.documentsSubtitle', {
                date: format(new Date(document.createdAt), 'dd/MM/yyyy'),
              })}
              hasRightArrow
              height={45}
            />
          </S.Document>
        ))}
      </List>
    </Section>
  );
};
