import React, { forwardRef, useCallback } from 'react';

import {
  Pressable as RNWebPressable,
  PressableStateCallbackType,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import { PressableType } from './Pressable.types';

export { PressableType };

/**
 * This component is an overload of react-native's Pressable component to make it type compatible with react-native-web
 * It adds an opacity management to the default click
 */
export const Pressable = forwardRef<View, PressableType>(
  ({ style, withPressOpacity = true, ...rest }, ref) => {
    const _style = useCallback(
      ({ pressed }: PressableStateCallbackType) => {
        return [{ opacity: pressed ? 0.5 : 1 }, style ? style : {}] as StyleProp<ViewStyle>;
      },

      [style]
    );

    return (
      <RNWebPressable
        testID="pressable"
        style={withPressOpacity ? _style : style}
        ref={ref}
        {...rest}
      />
    );
  }
);

Pressable.displayName = 'Pressable';
