import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMicrosoft = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11 5H5v6h6V5zm2 0v6h6V5h-6zm6 8h-6v6h6v-6zm-8 6v-6H5v6h6zM3 3h18v18H3V3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMicrosoft;
