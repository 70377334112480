import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMaximize = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4.33 20.534a.965.965 0 01-.713-.288.965.965 0 01-.288-.712v-6c0-.284.096-.521.288-.713a.967.967 0 01.712-.287.97.97 0 01.713.287.97.97 0 01.287.713v3.6l12.6-12.6h-3.6a.965.965 0 01-.712-.288.965.965 0 01-.288-.712c0-.284.096-.521.288-.713a.967.967 0 01.712-.287h6c.284 0 .521.095.712.287.192.192.288.43.288.713v6c0 .283-.096.52-.288.712a.965.965 0 01-.712.288.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-3.6l-12.6 12.6h3.6c.284 0 .521.096.713.288a.967.967 0 01.287.712c0 .283-.095.52-.287.712a.968.968 0 01-.713.288h-6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMaximize;
