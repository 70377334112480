import React, { useMemo, useRef } from 'react';

import { StyleSheet, View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';

import { Pressable, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { GetRealEstateRealizationsQuery } from '@app/libs/apollo/introspection';
import { getImageUrl } from '@app/utils/getImageUrl';
import { useTranslation } from '@app/services/translations/translations';
import { SliderImageObject } from '@app/features/realEstate/gallery/gallery.types';
import { isNative } from '@app/constants/platform';

export type RealEstateRealization = GetRealEstateRealizationsQuery['soldRealEstatesV2'][number];

interface RealizationProps {
  realization: RealEstateRealization;
  isFirst: boolean;
  onPress: (images: SliderImageObject[]) => void;
}

export const RESPONSIVE_CARD_HEIGHT = 392;
export const CARD_HEIGHT = 320;

const getStyles = (theme: Theme, isLowerThanLaptop: boolean, isFirst: boolean) =>
  StyleSheet.create({
    card: {
      justifyContent: 'flex-end',
      padding: theme.spacing.SIZE_07,
    },
    container: {
      gap: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
      overflow: 'visible',
    },
    image: {
      flex: 1,
    },
    item: {
      borderRadius: theme.borderRadius.SIZE_06,
      height: isLowerThanLaptop ? RESPONSIVE_CARD_HEIGHT : CARD_HEIGHT,
      marginLeft: isFirst && !isNative() ? 0 : theme.spacing.SIZE_06,
      overflow: 'hidden',
    },
    title: {
      color: theme.palette.base.white,
    },
  });

export const Realization: React.FC<RealizationProps> = ({ realization, isFirst, onPress }) => {
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop, isFirst);

  const initialAfterRenovationImages = useRef(realization.afterRenovation.images);

  const { t } = useTranslation();

  const imageScale = useSharedValue(1);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: withSpring(imageScale.value) }],
  }));

  const onHoverIn = () => {
    imageScale.value = 1.1;
  };

  const onHoverOut = () => {
    imageScale.value = 1;
  };

  const images = useMemo(
    () =>
      initialAfterRenovationImages.current.map(image => ({
        description: t('property.afterWork'),
        url: getImageUrl(image),
      })),
    [t]
  );

  const handleOnPress = () => {
    onPress(images);
  };

  return (
    <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} onPress={handleOnPress}>
      <View style={styles.item}>
        <Animated.Image
          source={{ uri: getImageUrl(realization.coverImage) }}
          resizeMode="cover"
          style={[styles.image, animatedStyle]}
        />
        <LinearGradient
          colors={['transparent', 'rgba(0, 0, 0, 0.5)']}
          style={StyleSheet.absoluteFillObject}
        />

        <View style={[StyleSheet.absoluteFillObject, styles.card]}>
          <Text style={styles.title} textStyle="Headline1Medium">
            {realization.title}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};
