import { differenceInDays, differenceInMinutes } from 'date-fns';

import {
  DAYS_BEFORE_REMINDER_MODAL,
  MINUTES_BEFORE_REMINDER_BANNER,
} from '@app/constants/signMandateInApp';
import { SignMandateReminderType } from '@app/shared/hooks/useSignMandateState/useSignMandateState.hook.types';

export const shouldDisplayModal = (
  signMandateReminder: SignMandateReminderType | null
): boolean => {
  if (signMandateReminder?.modal?.shouldDisplay === false) {
    return false;
  }

  if (signMandateReminder?.modal?.lastDisplayedDate) {
    const dateDiff = Math.abs(
      differenceInDays(new Date(), new Date(signMandateReminder.modal.lastDisplayedDate))
    );
    return dateDiff >= DAYS_BEFORE_REMINDER_MODAL;
  }
  return true;
};

export const shouldDisplayBanner = (
  signMandateReminder: SignMandateReminderType | null
): boolean => {
  if (signMandateReminder?.banner) {
    const minutesDiff = Math.abs(
      differenceInMinutes(new Date(), new Date(signMandateReminder?.banner))
    );
    return minutesDiff >= MINUTES_BEFORE_REMINDER_BANNER;
  }
  return true;
};
