import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMe = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="me_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#me_svg__a)">
        <Path fill="#E6BE53" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.333 17.667a1 1 0 001 1h23.334a1 1 0 001-1V2.333a1 1 0 00-1-1H2.333a1 1 0 00-1 1v15.334z"
          fill="#E01826"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.023 3.659a.667.667 0 00-.26 1.104l.375.375a.667.667 0 00.472.195h.78c.177 0 .347-.07.472-.195l.375-.375a.667.667 0 00-.26-1.104l-.766-.255a.666.666 0 00-.422 0l-.766.255zm1.448 3.203a.667.667 0 01-.942 0l-.196-.195-.993-.994a.667.667 0 00-.77-.125l-.076.038a.667.667 0 00-.173 1.068l.012.013.994.993a.667.667 0 01.125.77l-.414.827a.36.36 0 00-.038.161.36.36 0 01-.52.322l-.323-.162a.395.395 0 01-.103-.632.395.395 0 000-.559l-.31-.31a.665.665 0 01-.126-.174l-.585-1.17a.67.67 0 01-.05-.137l-.31-1.237a.667.667 0 00-1.18-.238L7.467 6.489a.667.667 0 00-.134.4v2.287c0 .103.024.206.07.298l.549 1.096a.667.667 0 00.124.173l1.18 1.18c.052.051.11.093.174.125l1.174.587c.041.02.087.032.133.032.315 0 .415.424.133.565l-.154.077a.34.34 0 00-.088.063l-.295.295a.471.471 0 01-.666 0 .471.471 0 00-.667 0l-.054.054a.548.548 0 00-.103.632l.306.612a.667.667 0 00.596.368h.764a.667.667 0 00.298-.07l.994-.497a.666.666 0 00.298-.298l.303-.605a.427.427 0 11.737.428l-.77 1.154a.667.667 0 00.186.925l1.075.717c.224.15.516.15.74 0l1.075-.717a.667.667 0 00.185-.925l-.77-1.154a.427.427 0 11.738-.428l.303.605c.064.129.169.233.298.298l.994.497c.092.046.194.07.298.07h.764a.666.666 0 00.596-.368l.306-.612a.548.548 0 00-.103-.632L19 13.667a.471.471 0 00-.667 0 .471.471 0 01-.666 0l-.295-.295a.34.34 0 00-.088-.063l-.154-.077c-.282-.141-.182-.565.133-.565.046 0 .092-.011.133-.032l1.174-.587a.668.668 0 00.173-.124l1.18-1.18c.051-.051.093-.11.125-.174l.548-1.096a.666.666 0 00.07-.298V6.889a.666.666 0 00-.133-.4L19.507 5.12a.667.667 0 00-1.18.238l-.31 1.237a.67.67 0 01-.05.136l-.585 1.171a.665.665 0 01-.125.173l-.311.311a.395.395 0 000 .559c.19.19.138.512-.103.632l-.323.162a.36.36 0 01-.52-.322.36.36 0 00-.038-.16l-.414-.828a.667.667 0 01.125-.77l.994-.993.012-.013a.667.667 0 00-.173-1.068l-.076-.038a.667.667 0 00-.77.125l-.993.994-.196.195z"
          fill="#E6BE53"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.667 11.333c0 .246.413.328.533.113.232-.416.516-.78.8-.78.284 0 .568.364.8.78.12.215.533.133.533-.113 0-1.104-.597-2-1.333-2s-1.333.896-1.333 2z"
          fill="#215F90"
        />
      </G>
    </Svg>
  );
};

export default SvgMe;
