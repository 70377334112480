import { Theme } from '@app/ui/theme/types';

import { Variant } from './Loader.types';

export const getStyles = (theme: Theme, variant?: Variant) => ({
  rect: {
    backgroundColor: variant === 'inLight' ? theme.palette.brand[6] : theme.palette.base.white,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    transform: [
      {
        rotate: '180deg',
      },
    ],
    width: 11,
  },
  wrapper: {
    flexDirection: 'row' as const,
    height: theme.spacing.SIZE_07,
    justifyContent: 'space-between' as const,
    transform: [
      {
        rotate: '180deg',
      },
    ],
    width: theme.spacing.SIZE_07,
  },
});
