import { IConfig } from 'unleash-proxy-client';

import { AppEnvironment, config } from '@app/config';
import { storage } from '@app/services/storage/storage';

const unleashEnv = config.isProduction ? AppEnvironment.Production : AppEnvironment.Development;

const defaultRefreshInterval = 15;

export const unleashWebConfig = (): IConfig => ({
  appName: config.UNLEASH_APP_NAME,
  clientKey: config.UNLEASH_CLIENT_KEY,
  environment: unleashEnv,
  refreshInterval: config.UNLEASH_REFRESH_INTERVAL || defaultRefreshInterval,
  url: config.UNLEASH_URL,
});

export const unleashMobileConfig = (): IConfig => {
  const baseConfig = unleashWebConfig();
  return {
    ...baseConfig,
    storageProvider: {
      get: storage.readObject,
      save: storage.saveObject,
    },
  };
};
