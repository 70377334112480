import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgVe = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ve_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ve_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="#063191" />
        <Path d="M0 20h28v-6.667H0V20z" fill="#E2213B" />
        <Path
          d="M14 9.333A.667.667 0 1014 8a.667.667 0 000 1.333zM11.333 10a.667.667 0 100-1.333.667.667 0 000 1.333zm6-.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0zM10 12a.667.667 0 100-1.333A.667.667 0 0010 12zm8.667-.667a.667.667 0 11-1.334 0 .667.667 0 011.334 0z"
          fill="#fff"
        />
        <Path d="M0 6.667h28V0H0v6.667z" fill="#FFD044" />
      </G>
    </Svg>
  );
};

export default SvgVe;
