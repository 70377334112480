import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgGlass = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9 21a.965.965 0 01-.712-.288A.965.965 0 018 20c0-.283.096-.52.288-.712A.965.965 0 019 19h2v-4.1a5.823 5.823 0 01-3.575-2C6.475 11.8 6 10.5 6 9V4a.97.97 0 01.287-.713A.97.97 0 017 3h10c.283 0 .52.096.712.287.192.192.288.43.288.713v5c0 1.5-.475 2.8-1.425 3.9a5.823 5.823 0 01-3.575 2V19h2c.283 0 .521.096.713.288A.967.967 0 0116 20c0 .283-.096.52-.287.712A.968.968 0 0115 21H9zm3-8c.933 0 1.75-.283 2.45-.85a4.059 4.059 0 001.4-2.15h-7.7a4.06 4.06 0 001.4 2.15c.7.567 1.517.85 2.45.85zM8 8h8V5H8v3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgGlass;
