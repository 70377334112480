import React, { useMemo } from 'react';

import { TextStyle, View } from 'react-native';

import { computeStyle } from '@app/ui/utils/style';
import { Text } from '@app/ui/atoms/Text/Text';
import { getTableCellStyle } from '@app/ui/atoms/table-cell/TableCell.styles';
import { useTheme } from '@app/ui/contexts/config';

import { TableCellProps, TableRowType } from './TableCell.types';
export { TableCellProps, TableRowType };

export const TableCell = <T,>({ column, row, style }: TableCellProps<T>): JSX.Element | null => {
  const theme = useTheme();
  const tableCellStyle = getTableCellStyle(theme);
  const rowStyle = useMemo(
    () => ({
      ...computeStyle<TextStyle>(row.style, 'backgroundColor'),
      ...computeStyle<TextStyle>(row.style, 'color'),
      ...computeStyle<TextStyle>(row.style, 'width'),
    }),
    [row.style]
  );
  if (!Object.keys(row).length) {
    return null;
  }
  if (column.renderCell) {
    return (
      <View style={[tableCellStyle.renderCell, rowStyle, style]}>{column.renderCell(row)}</View>
    );
  }
  return (
    <Text textStyle="Caption1" style={[tableCellStyle.row, rowStyle, style]}>
      {column.valueGetter
        ? column.valueGetter(row)
        : (row[column.field as keyof T] as unknown as React.ReactNode)}
    </Text>
  );
};
