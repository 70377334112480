import React from 'react';

import { View, StyleSheet, ViewStyle } from 'react-native';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { RequiredThemes } from '@app/ui/theme/types';

import { badgeTheme } from './badge.theme';
export interface BadgeProps {
  color?: string;
  diameter?: number;
  style?: ViewStyle;
}

const styles = (componentTheme: RequiredThemes['badge'], color?: string, diameter?: number) =>
  StyleSheet.create({
    badge: {
      backgroundColor: color || componentTheme.backgroundColor,
      borderRadius: diameter || componentTheme.borderRadius,
      height: diameter || componentTheme.height,
      width: diameter || componentTheme.width,
    },
  }).badge;

export const Badge: React.FC<BadgeProps> = ({ color, diameter, style }) => {
  const componentTheme = useComponentTheme('badge', badgeTheme);

  return <View style={[styles(componentTheme, color, diameter), style]} />;
};
