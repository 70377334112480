import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgCna = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.759 13.518a.333.333 0 00-.298-.185h-.323a.333.333 0 01-.236-.097l-2.471-2.472a.333.333 0 01-.098-.235V9.47a.333.333 0 00-.097-.235l-1.17-1.17a.333.333 0 01-.088-.156l-.6-2.4a.333.333 0 00-.218-.235l-1.804-.6a.332.332 0 01-.044-.019l-1.128-.564A.333.333 0 014 3.794V3c0-.184.15-.333.333-.333h.862c.089 0 .173.035.236.097l.471.472a.333.333 0 00.236.097h1.057a.333.333 0 00.236-.097l.471-.472a.333.333 0 01.236-.097h2.45c.052 0 .103.012.149.035l1.193.596a.333.333 0 00.149.035h1.176a.333.333 0 00.149-.035l1.192-.596a.333.333 0 01.15-.035H17c.184 0 .333.149.333.333v.667c0 .184-.149.333-.333.333h-.667a.333.333 0 00-.333.333v1.461c0 .126.071.242.184.298l.667.333c.222.111.482-.05.482-.298V5c0-.184.15-.333.334-.333H19c.184 0 .333.149.333.333 0 .184.15.333.334.333H21c.184 0 .333.15.333.334v.946a.331.331 0 01-.017.105l-.624 1.874a.332.332 0 01-.081.13l-1.906 1.906a.335.335 0 00-.063.087l-.607 1.215a.333.333 0 00-.035.149V13c0 .184-.15.333-.333.333h-.128a.333.333 0 01-.298-.184l-.482-.965A.333.333 0 0016.46 12h-1.588a.333.333 0 00-.298.184l-.5 1a.333.333 0 000 .298l.5 1a.333.333 0 00.298.185h.989c.088 0 .173.035.236.097l1.138 1.138a.333.333 0 01.097.236V17c0 .184-.149.333-.333.333h-.794a.333.333 0 01-.298-.184l-.482-.965a.333.333 0 00-.299-.184h-.921a.333.333 0 01-.298-.184l-1.15-2.298z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgCna;
