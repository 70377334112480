export enum NotificationPreferencesCategory {
  advisorReminder = 'advisorReminder',
  dealFollowUp = 'dealFollowUp',
  realEstateSearch = 'realEstateSearch',
  preventAutoCancelRecommendations = 'preventAutoCancelRecommendations',
}

export enum OldNotificationPreferencesCategory {
  advisorReminder = 'advisorReminder',
  dealFollowUp = 'dealFollowUp',
}

export enum NotificationPreferencesSource {
  advisorReminder = 'advisor',
  dealFollowUp = 'dealFollowUp',
  realEstateSearch = 'property',
  preventAutoCancelRecommendations = 'recommendationExpiration',
}

export enum NotificationPreferencesType {
  appPush = 'appNotification',
  email = 'emailNotification',
}

export enum TileIconName {
  appPush = 'Mobile',
  email = 'Mail',
}
