import React, { Children, PropsWithChildren, useMemo } from 'react';

import { View } from 'react-native';

import { withLabel } from '@app/ui/hoc/with-label/withLabel';
import { useTheme } from '@app/ui/contexts/config';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';

import { CheckboxGroupType } from './CheckboxGroup.types';
import { getCheckboxGroupStyle } from './CheckboxGroup.styles';

export { CheckboxGroupType };

export const CheckboxGroupStandalone: React.FC<PropsWithChildren<CheckboxGroupType>> = ({
  inline = false,
  children,
}) => {
  const theme = useTheme();
  const spacing = theme.spacing.SIZE_05;
  const style = getCheckboxGroupStyle({ inline, spacing, theme });
  const childrenList = useMemo(
    () =>
      Children.toArray(children).map((child, index, arr) => (
        <View key={index} style={index !== arr.length - 1 ? style.child : {}}>
          {child}
          <Spacer height={spacing} />
        </View>
      )),
    [children, spacing, style.child]
  );

  return <View style={style.container}>{childrenList}</View>;
};

export const CheckboxGroup = withLabel(CheckboxGroupStandalone);
