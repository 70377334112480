import { TimelineStepStatus } from '@app/libs/apollo/introspection';
import { ChipTint, IconName } from '@app/ui';

export const getIconNameChip = (status: TimelineStepStatus, iconName: IconName): IconName => {
  if (status === TimelineStepStatus.Error) {
    return 'Cross';
  }
  if (status === TimelineStepStatus.Inactive) {
    return 'Lock';
  }

  if (status === TimelineStepStatus.Completed) {
    return 'CheckFilled';
  }

  return iconName;
};

export const getIconColorChip = (status: TimelineStepStatus): ChipTint => {
  if (status === TimelineStepStatus.Error) {
    return 'red';
  }

  if (status === TimelineStepStatus.Inactive) {
    return 'grey';
  }

  if (status === TimelineStepStatus.Completed) {
    return 'green';
  }

  return 'blue';
};
