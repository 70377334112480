import { DotSize, DotType } from '@app/ui/atoms/dot/Dot.types';
import { SliderType } from '@app/ui/molecules/slider/Slider.types';

export enum DotsPos {
  'relative' = 'relative',
  'absolute' = 'absolute',
}

export type CarouselType = SliderType &
  Pick<DotType, 'isDark'> & {
    dots?: DotsPos;
    dotSize?: DotSize;
    numberOfDots?: number;
  };
