import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgBs = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bs_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bs_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20zM0 6.667h28V0H0v6.667z" fill="#22B7D5" />
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="url(#bs_svg__paint0_linear_4223_10409)" />
        <Path d="M0 0l13.333 10L0 20V0z" fill="#262626" />
      </G>
      <Defs>
        <LinearGradient
          id="bs_svg__paint0_linear_4223_10409"
          x1={0}
          y1={6.667}
          x2={0}
          y2={13.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FCE569" />
          <Stop offset={1} stopColor="#FADF52" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgBs;
