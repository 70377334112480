import React from 'react';

import { View } from 'react-native';

import { isWeb } from '@app/constants/platform';

import { HoverableType } from './Hoverable.types';

export { HoverableType };

export const Hoverable: React.FC<HoverableType> = ({
  onHoverIn,
  onHoverOut,
  onHovering,
  ...viewProps
}) => {
  const mouseEventProps = isWeb()
    ? {
        onMouseEnter: onHoverIn,
        onMouseLeave: onHoverOut,
        onMouseMove: onHovering,
      }
    : {};

  return <View {...mouseEventProps} {...viewProps} />;
};

Hoverable.displayName = 'Hoverable';
