import React, { useMemo, useState } from 'react';

import { StyleSheet, View } from 'react-native';

import { Spacing, Text, Spacer, useTheme, Theme, SliderInput } from '@app/ui';
import {
  trackTooltip,
  trackResellingYear,
  trackEvaluationRate,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter, percentFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { Importance, RealEstateAttribute } from '../../../components/RealEstateAttribute';
import { areEqual } from '../utils/realEstateFinances.utils';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    border: {
      borderBottomColor: theme.palette.neutral[300],
      borderBottomWidth: 1,
      flex: 1,
    },
    container: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      flex: 1,
      paddingBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_03,
    },
  });

interface TrendingAppreciation {
  appreciationZone?: string;
  evaluationRate?: number;
}

interface RealEstateEnrichmentProjectionProps {
  bankCapitalStillDue: number;
  trendingAppreciation: TrendingAppreciation;
  cumulativeCashFlow: number;
  enrichementResellingYear: number;
  evaluationRate: number;
  loanDownAmount: number;
  resellingPrice: number;
  resellingYear: number;
  resellingYearCashFlow: number;
  setEvaluationRate: (evaluationRate: number) => void;
  setResellingYear: (resellingYear: number) => void;
  realEstateId: string;
}

export const RealEstateEnrichmentProjection: React.FC<RealEstateEnrichmentProjectionProps> =
  React.memo(
    ({
      bankCapitalStillDue,
      trendingAppreciation,
      cumulativeCashFlow,
      enrichementResellingYear,
      evaluationRate: defaultEvaluationRate,
      loanDownAmount,
      resellingPrice,
      resellingYear: defaultResallingYear,
      resellingYearCashFlow,
      setEvaluationRate: updateEvaluationRate,
      setResellingYear: updateResallingYear,
      realEstateId,
    }) => {
      const theme = useTheme();
      const styles = useMemo(() => getStyles(theme), [theme]);
      const { t } = useRealEstateTranslation();

      const [resellingYear, setResellingYear] = useState(defaultResallingYear);
      const [evaluationRate, setEvaluationRate] = useState(defaultEvaluationRate);

      const baseEvaluationRate = trendingAppreciation?.evaluationRate || 0;
      const formattedAnnualPercentEvolutionValue = Math.floor(evaluationRate * 10) / 10;
      const formattedBaseEvaluationRate = percentFormatter(baseEvaluationRate * 100, 1);
      const formattedBankCapitalStillDue = currencyFormatter(-bankCapitalStillDue, 0);
      const formattedCumulativeCashFlow = currencyFormatter(cumulativeCashFlow, 0);
      const formattedEnrichementResellingYear = currencyFormatter(enrichementResellingYear, 0);
      const formattedEvaluationRate = percentFormatter(evaluationRate, 1);
      const formattedLoanDownAmount = currencyFormatter(-loanDownAmount, 0);
      const formattedReselingYearCashFlow = currencyFormatter(resellingYearCashFlow, 0);
      const formattedResellingPrice = currencyFormatter(resellingPrice, 0);
      const formattedResellingYear = t('propertyFinances.countYears', { count: resellingYear });
      const cumulativeCashFlowKey =
        cumulativeCashFlow > 0 ? 'cumulativeCashFlowPositive' : 'cumulativeCashFlowNegative';
      const evaluationRateKey =
        baseEvaluationRate && baseEvaluationRate > 0
          ? 'annualPercentEvolution'
          : 'evaluationRateUnavaible';

      const zoneEvaluation = t(`shared.trending.${trendingAppreciation?.appreciationZone}`);

      const handleResellingYear = (value: number): void => {
        trackResellingYear(value, realEstateId);
      };
      const handleAnnualPercentEvolution = (value: number): void => {
        trackEvaluationRate({
          initializationRate: baseEvaluationRate,
          propertyId: realEstateId,
          rateSetByUser: value,
        });
      };
      const trackTooltipAnnualPercentEvolution = () =>
        trackTooltip({
          propertyId: realEstateId,
          tooltipType: 'Evolution annuelle du prix du bien',
        });
      const trackTooltipResellingYear = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Durée de détention' });
      const trackTooltipResellingPrice = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Prix de revente' });
      const trackTooltipBankCapitalStillDue = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Capital restant dû à la banque' });
      const trackTooltipCumulativeCashFlow = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: "Cumul effort d'épargne" });
      const trackTooltipLoanDownAmount = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Apport' });

      const resallingYearSlider = React.useMemo(
        () => (
          <SliderInput
            min={1}
            step={1}
            max={30}
            setSliderValue={(value: number) => {
              setResellingYear(value);
              updateResallingYear(value);
            }}
            value={resellingYear}
            onSlidingComplete={handleResellingYear}
          />
        ),
        [handleResellingYear, resellingYear, updateResallingYear]
      );

      const evaluationRateSlider = React.useMemo(
        () => (
          <SliderInput
            min={-5}
            step={0.1}
            max={20}
            setSliderValue={(value: number) => {
              setEvaluationRate(value);
              updateEvaluationRate(value);
            }}
            value={formattedAnnualPercentEvolutionValue}
            onSlidingComplete={handleAnnualPercentEvolution}
          />
        ),
        [formattedAnnualPercentEvolutionValue, handleAnnualPercentEvolution, updateEvaluationRate]
      );

      return (
        <View style={styles.container}>
          <View>
            <RealEstateAttribute
              label={t('shared.financialTool.resellingYear')}
              tooltip={{
                description: t('shared.financialTool.tooltip.resellingYearDescription'),
                onOpen: trackTooltipResellingYear,
              }}
              value={formattedResellingYear}
              importance={Importance.LOW}
            />
            {resallingYearSlider}
          </View>

          <View>
            <RealEstateAttribute
              label={t('shared.financialTool.annualPercentEvolution')}
              tooltip={{
                description: t(`shared.financialTool.tooltip.${evaluationRateKey}`, {
                  evaluationRate: formattedBaseEvaluationRate,
                  zone: zoneEvaluation,
                }),
                onOpen: trackTooltipAnnualPercentEvolution,
              }}
              value={formattedEvaluationRate}
              importance={Importance.LOW}
            />
            {baseEvaluationRate > 0 ? (
              <Text textStyle="Caption1">
                {t('shared.financialTool.annualPercentEvolutionDescription', {
                  evolution: formattedBaseEvaluationRate,
                  years: 5,
                })}
              </Text>
            ) : null}
            {evaluationRateSlider}
          </View>

          <RealEstateAttribute
            label={t('shared.financialTool.resellingPrice')}
            value={formattedResellingPrice}
            importance={Importance.LOW}
            isOdd
            tooltip={{
              description: t('shared.financialTool.tooltip.resellingPrice', {
                evaluationRate: formattedEvaluationRate,
                resellingYear,
              }),
              onOpen: trackTooltipResellingPrice,
            }}
          />

          <RealEstateAttribute
            label={t('shared.financialTool.bankCapitalStillDue')}
            value={formattedBankCapitalStillDue}
            importance={Importance.LOW}
            tooltip={{
              description: t('shared.financialTool.tooltip.bankCapitalStillDue'),
              onOpen: trackTooltipBankCapitalStillDue,
            }}
          />

          <View style={styles.border} />
          <Spacer height={Spacing.SIZE_04} />
          <RealEstateAttribute
            label={t('shared.financialTool.resellingYearCashFlow')}
            value={formattedReselingYearCashFlow}
            importance={Importance.HIGH}
          />

          <RealEstateAttribute
            label={t('shared.financialTool.loanDown')}
            value={formattedLoanDownAmount}
            importance={Importance.LOW}
            isOdd
            tooltip={{
              description: t('shared.financialTool.tooltip.loanDownDescription'),
              onOpen: trackTooltipLoanDownAmount,
            }}
          />

          <RealEstateAttribute
            label={t(`shared.financialTool.${cumulativeCashFlowKey}`)}
            value={formattedCumulativeCashFlow}
            importance={Importance.LOW}
            tooltip={{
              description: t(`shared.financialTool.tooltip.${cumulativeCashFlowKey}`),
              onOpen: trackTooltipCumulativeCashFlow,
            }}
          />

          <View style={styles.border} />
          <Spacer height={Spacing.SIZE_04} />
          <RealEstateAttribute
            label={t('shared.financialTool.enrichmentResellingYear', { year: resellingYear })}
            value={formattedEnrichementResellingYear}
            importance={Importance.HIGH}
          />
        </View>
      );
    },
    areEqual<RealEstateEnrichmentProjectionProps>([
      'bankCapitalStillDue',
      'trendingAppreciation',
      'cumulativeCashFlow',
      'enrichementResellingYear',
      'resellingPrice',
      'loanDownAmount',
      'resellingYearCashFlow',
    ])
  );

RealEstateEnrichmentProjection.displayName = 'PropertyEnrichmentProjection';
