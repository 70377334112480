/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */

export const ZINDEX = {
  dropdown: 1000,
  sticky: 1020,
  fixed: 1030,
  modalbackdrop: 1040,
  modal: 1050,
  popover: 1060,
};
