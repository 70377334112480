import React from 'react';

import { View, ViewProps, StyleSheet } from 'react-native';

import { useTheme } from '../contexts/config';

export type RoundedBoxProps = ViewProps;

export const RoundedBox: React.FC<RoundedBoxProps> = ({ children, style, ...props }) => {
  const theme = useTheme();

  return (
    <View
      style={StyleSheet.flatten([
        {
          backgroundColor: theme.palette.neutral[100],
          borderRadius: theme.spacing.SIZE_05,
          padding: theme.spacing.SIZE_04,
        },
        style,
      ])}
      {...props}
    >
      {children}
    </View>
  );
};
