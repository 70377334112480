import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

import { DotType, DotSize } from './Dot.types';

export const SizeWidth = {
  [DotSize.L]: 8,
  [DotSize.M]: 6,
  [DotSize.S]: 4,
};

type GetStyleType = DotType & {
  theme: Theme;
  size: DotSize;
};

export const getStyle = ({ theme, active, isDark, size }: GetStyleType) => {
  const activeBackground = isDark ? theme.palette.base.white : theme.palette.neutral[900];

  const inactiveBackground = isDark ? theme.palette.base.white : theme.palette.neutral[300];

  return StyleSheet.create({
    dot: {
      backgroundColor: active ? activeBackground : inactiveBackground,
      borderRadius: 100,
      height: SizeWidth[size],
      opacity: isDark && !active ? 0.4 : 1,
      width: SizeWidth[size],
    },
  });
};
