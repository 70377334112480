import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgJo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="jo_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#jo_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20z" fill="#199E56" />
        <Path d="M0 6.667h28V0H0v6.667z" fill="#262626" />
        <Path d="M0 0l13.333 10L0 20V0z" fill="#E6223A" />
        <Path
          d="M4.667 11l-.868.802.086-1.178-1.168-.179.975-.668-.589-1.024 1.13.346L4.667 8 5.1 9.099l1.13-.346-.59 1.024.976.668-1.169.178.086 1.179L4.667 11z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgJo;
