import { IconName } from '@app/ui';

interface Furnishment {
  furnitures: string[];
  icon: IconName;
  title: string;
  subtitle?: string;
}

interface FurnishmentPreviewItem {
  action?: string;
  icon: IconName;
  title: string;
}

const livingRoom: Furnishment = {
  furnitures: ['sofa', 'armChair', 'carpet', 'lowTable', 'tvStand'],
  icon: 'Tv',
  title: 'category.livingRoom',
};

const diningRoom: Furnishment = {
  furnitures: ['table', 'chairs'],
  icon: 'Table',
  title: 'category.diningRoom',
};

const room: Furnishment = {
  furnitures: ['mattress', 'bedSideTable', 'desk', 'dressing', 'laundryBasket'],
  icon: 'Bed',
  subtitle: 'forEachRoom',
  title: 'category.bedroom',
};

const studioRoom: Furnishment = {
  furnitures: ['sofaBed', 'carpet', 'lowTable', 'tvStand', 'dressing', 'laundryBasket'],
  icon: 'Tv',
  title: 'category.studio',
};

const baseFurnishment: Furnishment[] = [
  {
    furnitures: [
      'filterCoffeeMachine',
      'kettle',
      'toaster',
      'ironTable',
      'dryingRack',
      'vacuumCleaner',
      'television',
    ],
    icon: 'CoffeeMaker',
    title: 'category.smallAppliance',
  },
  {
    furnitures: ['plates', 'bowls', 'cups', 'glasses', 'cutleries', 'saladBowls', 'glassDishes'],
    icon: 'Ustensils',
    title: 'category.tableware',
  },
  {
    furnitures: [
      'cookware',
      'strainer',
      'woodenUtensils',
      'knifeBlock',
      'corkscrew',
      'peeler',
      'cuttingBoard',
    ],
    icon: 'Skillet',
    title: 'category.utensils',
  },
  {
    furnitures: ['bin', 'drainer', 'trivet', 'fruitBasket'],
    icon: 'Trash',
    title: 'category.accessories',
  },
  {
    furnitures: ['toothbrushPot', 'soapHolder', 'soapDispenser', 'smallBin', 'bathMat'],
    icon: 'Account',
    title: 'category.bathroom',
  },
  {
    furnitures: [
      'sprayBroom',
      'broom',
      'dustpanBrush',
      'rags',
      'powerStrips',
      'hangers',
      'toiletBrush',
      'toiletPaperDispenser',
    ],
    icon: 'Iron',
    title: 'category.laundry',
  },
  {
    furnitures: [
      'floorLamp',
      'bedsideLamp',
      'deskLamp',
      'suspendedLamp',
      'ceilingLamp',
      'bathRoomCeilingLamp',
      'wallLamp',
      'lightBulbs',
    ],
    icon: 'StandLamp',
    title: 'category.lights',
  },
  {
    furnitures: [
      'coatRack',
      'mirrors',
      'frames',
      'pillows',
      'curtains',
      'vases',
      'scentBox',
      'lightBox',
      'smallPlants',
      'smallShelf',
      'smallItems',
    ],
    icon: 'AirFreshener',
    title: 'category.decorative',
  },
  {
    furnitures: ['protectiveSheet', 'fittedSheet', 'duvet', 'duvetCover', 'pillows', 'pillowcases'],
    icon: 'Bedding',
    title: 'category.bedding',
  },
];

const viewPackAction: FurnishmentPreviewItem = {
  action: 'viewFurnishmentDetails',
  icon: 'Masonry',
  title: 'pack',
};

const baseFurnishmentPreviews: FurnishmentPreviewItem[] = [
  { icon: 'Works', title: 'conception' },
  { icon: 'LocalShipping', title: 'delivery' },
  { icon: 'Orders', title: 'installation' },
];

export const getFurnishmentDetails = (
  roomCount: number
): (Furnishment | FurnishmentPreviewItem)[] => {
  const furnitures: Furnishment[] = [];
  if (roomCount === 1) {
    furnitures.push(studioRoom, diningRoom);
  } else {
    furnitures.push(livingRoom, diningRoom, room);
  }

  return [...furnitures, ...baseFurnishment, ...baseFurnishmentPreviews];
};

export const furnishmentPreview: FurnishmentPreviewItem[] = [
  viewPackAction,
  ...baseFurnishmentPreviews,
];
