import { useEffect, useState } from 'react';

import { Role } from '@app/libs/apollo/introspection';

import { useMe } from './useMe';

export const useAuthorized = (authorizedRoles: Role[]): { isAuthorizedCustomer: boolean } => {
  const { customer } = useMe();
  const [isAuthorizedCustomer, setIsAuthorizedCustomer] = useState<boolean>();

  useEffect(() => {
    if (customer) {
      const permission = customer?.account.roles?.some(role => authorizedRoles.includes(role));
      setIsAuthorizedCustomer(permission);
    }
  }, [authorizedRoles, customer]);

  return { isAuthorizedCustomer };
};
