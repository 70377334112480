import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';

import { LoaderMasteos } from '@app/ui';
import { useQueryFinancialModelTemplates } from '@app/shared/hooks/useQueryFinancialModelTemplates';
import { useQueryRealEstate } from '@app/shared/hooks/useQueryRealEstate';
import { RealEstate as RealEstateType } from '@app/libs/apollo/introspection';
import { RealEstateProvider } from '@app/shared/contexts/realEstate/RealEstateProvider';
import { useViewRealEstate } from '@app/shared/hooks/useViewRealEstate';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { GalleryProvider } from '@app/features/realEstate/gallery/GalleryProvider';
import { RealEstateAvailable } from '@app/features/realEstate/realEstateAvailable/RealEstateAvailable';

import { getStyles } from '../styles.web';

type RealEstatesStackRouteProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

export const RealEstateAvailableView: React.FC<RealEstatesStackRouteProps> = ({
  route,
  navigation,
}) => {
  const styles = getStyles();
  const {
    position: realEstatePosition = 0,
    id: realEstateId,
    currentScrollPosition,
  } = route.params;

  const { loading, error, realEstate } = useQueryRealEstate({ id: realEstateId });
  const { loading: templatesLoading } = useQueryFinancialModelTemplates();

  useViewRealEstate(realEstate as RealEstateType);

  if (loading || templatesLoading) {
    return (
      <View style={styles.loader}>
        <LoaderMasteos variant="inLight" />
      </View>
    );
  }

  if (!realEstate) {
    if (error instanceof Error) {
      navigation.navigate(RealEstateNavigatorRoutes.RealEstateList);
    }
    return null;
  }

  return (
    <RealEstateProvider realEstate={realEstate}>
      <GalleryProvider propertyId={realEstate.id}>
        <RealEstateAvailable
          realEstate={realEstate as RealEstateType}
          realEstatePosition={realEstatePosition}
          isFromSearchEngine={route.params.isFromSearchEngine}
          fromExtendedResultsSection={route.params.fromExtendedResultsSection}
          currentScrollPosition={currentScrollPosition}
        />
      </GalleryProvider>
    </RealEstateProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default RealEstateAvailableView;
