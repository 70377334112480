import { StyleSheet } from 'react-native';

export const getStyles = ({ hasSearch }: { hasSearch: boolean }) =>
  StyleSheet.create({
    headerContainer: { paddingHorizontal: 12, paddingTop: 12 },
    listContainer: {
      paddingBottom: 12,
      paddingHorizontal: 12,
      paddingTop: hasSearch ? 8 : 12,
    },
  });
