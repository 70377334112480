import React, { useMemo } from 'react';

import { Platform, StyleProp, View, ViewStyle } from 'react-native';

import { CircleStepIndicator } from '@app/ui/molecules/circle-step-indicator/CircleStepIndicator';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Text } from '@app/ui/atoms/Text/Text';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { PopoverOrModal } from '@app/ui/molecules/popover-or-modal/PopoverOrModal';
import {
  StepSize,
  StepVariant,
} from '@app/ui/molecules/circle-step-indicator/CircleStepIndicator.types';

import { TimelineStepProps } from './Timeline.types';
import { getHorizontalStepStyles } from './Timeline.styles';

export const TimelineHorizontalStep: React.FC<TimelineStepProps> = ({
  content,
  indicator,
  isBeforeStepFinished,
  isFirstStep = false,
  isLastStep = false,
  isNextStepFinished,
  onPress,
  status,
  step,
  subSteps,
  title,
}) => {
  const { isGreaterThanDesktop } = useResponsive();
  const isSubStep = !subSteps?.length;
  const styles = useMemo(
    () =>
      getHorizontalStepStyles({
        isStepAfterFinished: isNextStepFinished,
        isStepBeforeFinished: isBeforeStepFinished,
        isSubStep,
        onPress,
        status,
      }),
    [status, isNextStepFinished, isBeforeStepFinished, isSubStep, onPress]
  );

  const handleOnPress = () => onPress?.(step);
  const Component = isSubStep ? PopoverOrModal : Pressable;

  return (
    <View style={styles.container} testID="timeline-step">
      <View style={styles.step}>
        {!isFirstStep && <View style={[styles.stepLink, styles.stepLinkBefore]} />}
        <Component
          title={title}
          content={content}
          variant="inLight"
          style={
            Platform.select({
              web: { cursor: onPress ? 'pointer' : 'default' },
            }) as StyleProp<ViewStyle>
          }
          onPress={handleOnPress}
          onPopoverPress={handleOnPress}
          testID="timeline-step--press"
        >
          <CircleStepIndicator
            indicator={indicator ?? step}
            status={status}
            size={isSubStep ? StepSize.S : StepSize.L}
            variant={StepVariant.Primary}
          />
        </Component>
        {!isLastStep && <View style={[styles.stepLink, styles.stepLinkAfter]} />}
      </View>
      {!isSubStep && (
        <Text textStyle={isGreaterThanDesktop ? 'Body3Medium' : 'Body2Medium'} style={styles.title}>
          {title}
        </Text>
      )}
    </View>
  );
};
