import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Icon, IconName, Spacer, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { getRealEstateTag } from '@app/utils/real-estate/getRealEstateTag.utils';
import { PositioningStatus } from '@app/libs/apollo/introspection';
import { currencyFormatter } from '@app/utils/currency-formatter';

import { TagList, TagListProps } from '../../tag-list/TagList';

export type RealEstatePreviewCardBodyHorizontalProps = {
  title: string;
  price: number;
  isBookmarked: boolean;
  isNew?: boolean;
  shortTermNightlyRent?: number;
  isExclusive?: boolean;
  positioningStatus?: PositioningStatus;
  isInTransaction?: boolean;
  expirationDate?: Date;
  isViewed: boolean;
} & TagListProps;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: { flex: 1, minHeight: 50 },
    headerTag: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_02,
    },
    noChip: { height: 12.5, width: 1 },
    priceCaption: {
      color: theme.palette.neutral[700],
      marginBottom: theme.spacing.SIZE_01,
      paddingLeft: theme.spacing.SIZE_02,
    },
    priceContainer: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      marginTop: theme.spacing.SIZE_03,
    },
    tagListContainer: { maxHeight: 50, overflow: 'hidden' },
  });

export const RealEstatePreviewCardBodyHorizontal: React.FC<
  RealEstatePreviewCardBodyHorizontalProps
> = ({
  title,
  surface,
  grossReturnRate,
  netReturnRate,
  price,
  propertyKind,
  totalLots,
  roomCount,
  expirationDate,
  isExclusive,
  isInTransaction,
  isNew,
  positioningStatus,
  shortTermNightlyRent,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const realEstateHeaderChipProps = getRealEstateTag({
    expirationDate,
    isExclusive,
    isInTransaction,
    isNew,
    language: i18n.resolvedLanguage,
    positioningStatus,
    shortTermNightlyRent,
    shortVersion: true,
    translate: t,
  });

  return (
    <View style={styles.container}>
      {realEstateHeaderChipProps ? (
        <View style={styles.headerTag} testID="real-estate-tag">
          <Icon
            name={realEstateHeaderChipProps.iconName as IconName}
            color={realEstateHeaderChipProps.tint}
            size={12}
          />

          <Text textStyle="Caption2Medium" style={{ color: realEstateHeaderChipProps.tint }}>
            {realEstateHeaderChipProps.text}
          </Text>
        </View>
      ) : (
        <View style={styles.noChip} />
      )}
      <View>
        <Text textStyle="Headline3Medium" numberOfLines={1}>
          {title}
        </Text>
      </View>

      <Spacer height={theme.spacing.SIZE_03} />

      <View style={styles.tagListContainer}>
        <TagList
          surface={surface}
          grossReturnRate={grossReturnRate}
          netReturnRate={netReturnRate}
          propertyKind={propertyKind}
          totalLots={totalLots}
          roomCount={roomCount}
        />
      </View>
      <View style={styles.priceContainer}>
        <Text textStyle="Body1Medium">{currencyFormatter(price, 0)}</Text>
      </View>
    </View>
  );
};
