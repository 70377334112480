import { NavigationState, PartialState } from '@react-navigation/native';

export function getActiveRouteName(
  state: NavigationState | PartialState<NavigationState>
): string | undefined {
  const route = state.routes[state.index ?? 0];

  if (!route?.state) {
    return route?.name;
  }

  return getActiveRouteName(route.state);
}
