import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLinkedin = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9a5 5 0 00-5 5v6h2v-6a3 3 0 016 0v6h2v-6a5 5 0 00-5-5zm0-2a7 7 0 00-7 7v7a1 1 0 001 1h4a1 1 0 001-1v-7a1 1 0 012 0v7a1 1 0 001 1h4a1 1 0 001-1v-7a7 7 0 00-7-7zM1 9a1 1 0 011-1h4a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V9zm2 1v10h2V10H3zM4 3a1 1 0 100 2 1 1 0 000-2zM1 4a3 3 0 116 0 3 3 0 01-6 0z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLinkedin;
