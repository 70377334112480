import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgKey = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7 14c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 015 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 017 10c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.412A1.923 1.923 0 017 14zm0 4c-1.667 0-3.083-.583-4.25-1.75C1.583 15.083 1 13.667 1 12c0-1.667.583-3.083 1.75-4.25C3.917 6.583 5.333 6 7 6c1.117 0 2.13.275 3.038.825A6.213 6.213 0 0112.2 9h8.375a1 1 0 01.725.3l2 2c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 01-.213.325l-3.175 3.175a.946.946 0 01-.3.2c-.117.05-.233.083-.35.1a.832.832 0 01-.35-.025.884.884 0 01-.325-.175L17.5 15l-1.425 1.075a.945.945 0 01-.887.15.857.857 0 01-.288-.15L13.375 15H12.2a6.213 6.213 0 01-2.162 2.175C9.129 17.725 8.117 18 7 18zm0-2c.933 0 1.754-.283 2.463-.85A4.033 4.033 0 0010.875 13H14l1.45 1.025v0L17.5 12.5l1.775 1.375L21.15 12h-.012.012l-1-1v-.012V11h-9.275a4.033 4.033 0 00-1.412-2.15C8.754 8.283 7.933 8 7 8c-1.1 0-2.042.392-2.825 1.175C3.392 9.958 3 10.9 3 12s.392 2.042 1.175 2.825C4.958 15.608 5.9 16 7 16z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgKey;
