import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgLa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="la_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#la_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 14.667h28V5.333H0v9.334z" fill="#073A88" />
        <Path d="M0 5.333h28V0H0v5.333zM0 20h28v-5.333H0V20z" fill="#E2273E" />
        <Path d="M14 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666z" fill="#fff" />
      </G>
    </Svg>
  );
};

export default SvgLa;
