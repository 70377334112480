import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMenu = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 18a.965.965 0 01-.712-.288A.965.965 0 013 17c0-.283.096-.52.288-.712A.965.965 0 014 16h16c.283 0 .52.096.712.288A.965.965 0 0121 17c0 .283-.096.52-.288.712A.965.965 0 0120 18H4zm0-5a.965.965 0 01-.712-.288A.965.965 0 013 12c0-.283.096-.521.288-.713A.967.967 0 014 11h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0120 13H4zm0-5a.967.967 0 01-.712-.287A.968.968 0 013 7c0-.283.096-.521.288-.713A.967.967 0 014 6h16c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 01-.288.713A.967.967 0 0120 8H4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMenu;
