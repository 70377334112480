import { GetCustomerQuery, Role } from '@app/libs/apollo/introspection';

/**
 * @description Is used to change customer experience.
 * If the customer has a project or pre-project oriented account
 */
export const shouldMakeNewAppointment = ({
  hasValidSearchMandate,
  hasOngoingProject,
}: {
  hasValidSearchMandate: boolean;
  hasOngoingProject: boolean;
}): boolean => !hasValidSearchMandate && hasOngoingProject;

export const isAssetManagementConsultant = (customer: GetCustomerQuery['me']): boolean =>
  customer?.account.roles?.includes(Role.AssetManagementConsultant) ?? false;
