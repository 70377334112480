import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { Spacing } from '@app/ui/theme/Sizes';

export const getCheckboxGroupStyle = ({
  inline,
  spacing,
  theme,
}: {
  inline: boolean;
  theme: Theme;
  spacing: number;
}) => {
  return StyleSheet.create({
    child: {
      marginRight: inline ? theme.spacing.SIZE_05 : 0,
    },
    container: {
      flexDirection: inline ? 'row' : 'column',
      flexWrap: 'wrap',
      marginBottom: -spacing,
      marginTop: Spacing.SIZE_03,
    },
  });
};
