import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgPt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="pt_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#pt_svg__a)">
        <Path fill="#FF2936" d="M0 0h28v20H0z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h10.667V0H0v20z" fill="#128415" />
        <Path
          d="M10.667 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666z"
          stroke="#FAF94F"
          strokeWidth={1.333}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 8.333c0-.184.15-.333.334-.333h2c.184 0 .333.15.333.333v2.334a1.333 1.333 0 01-2.667 0V8.333z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.667 10.667c.368 0 .666-.965.666-1.334a.667.667 0 10-1.333 0c0 .369.299 1.334.667 1.334z"
          fill="#1D50B5"
        />
      </G>
    </Svg>
  );
};

export default SvgPt;
