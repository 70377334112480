import React from 'react';

import { View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { Button } from '@app/ui/molecules/Button';
import { Image } from '@app/ui/molecules/image/Image';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { useTheme } from '@app/ui/contexts/config';

import { getStyles } from './OnboardingScreen.styles';
import { OnboardingScreenType } from './OnboardingScreen.types';

export { OnboardingScreenType };

export const OnboardingScreen: React.FC<OnboardingScreenType> = ({
  description,
  primaryAction,
  primaryTitle,
  secondaryAction,
  secondaryTitle,
  subTitle,
  title,
  image,
}) => {
  const { isLowerThanLaptop, isLowerThanTablet } = useResponsive();
  const theme = useTheme();
  const styles = getStyles({
    isLowerThanLaptop,
    isLowerThanTablet,
    theme,
  });

  const textPolice = isLowerThanTablet ? 'Body2' : 'Body1';
  const titlePolice = isLowerThanTablet ? 'Title3Medium' : 'Title1Medium';

  return (
    <View style={styles.wrapper}>
      <View style={styles.imageContainer} testID="OnboardingScreenImageContainer">
        <Image source={image} resizeMode="contain" />
      </View>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text
            testID="OnboardingScreenTitle"
            textStyle={titlePolice}
            style={[styles.text, isLowerThanTablet ? styles.titleMobile : {}]}
          >
            {title}
          </Text>
          {!!subTitle && (
            <Text
              testID="OnboardingScreenSubtitle"
              textStyle={titlePolice}
              style={[styles.text, styles.subtitle, isLowerThanTablet ? styles.titleMobile : {}]}
            >
              {subTitle} 👋
            </Text>
          )}

          <Text
            textStyle={textPolice}
            testID="OnboardingScreenDescription"
            style={[styles.text, styles.description]}
          >
            {description}
          </Text>
        </View>

        <View style={styles.actionContainer}>
          {!!secondaryAction && (
            <Button
              testID="OnboardingScreenSecondaryButton"
              variant="ghost"
              onPress={secondaryAction}
            >
              {secondaryTitle}
            </Button>
          )}

          {!!primaryAction && (
            <Button testID="OnboardingScreenPrimaryButton" onPress={primaryAction}>
              {primaryTitle}
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};
