import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgHome = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 18.5h3v-5a1 1 0 011-1h4a1 1 0 011 1v5h3v-9L12 5 6 9.5v9zm0 2c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 18.5v-9c0-.317.071-.617.213-.9.141-.283.337-.517.587-.7l6-4.5a2.11 2.11 0 01.575-.3c.2-.067.408-.1.625-.1.217 0 .425.033.625.1s.392.167.575.3l6 4.5a1.99 1.99 0 01.8 1.6v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 20.5h-4a1 1 0 01-1-1v-5h-2v5a1 1 0 01-1 1H6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgHome;
