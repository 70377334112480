import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tm_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28V0H0v20z" fill="#30C375" />
        <Path fill="#DE414F" d="M4 0h4v20H4z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4c1.105 0 2-.597 2-1.333 0-.737-.895-1.334-2-1.334S4 1.93 4 2.667C4 3.403 4.895 4 6 4z"
          fill="#fff"
        />
        <Mask
          id="tm_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={4}
          y={1}
          width={4}
          height={3}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4c1.105 0 2-.597 2-1.333 0-.737-.895-1.334-2-1.334S4 1.93 4 2.667C4 3.403 4.895 4 6 4z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#tm_svg__b)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 3.333A.667.667 0 106 2a.667.667 0 000 1.333z"
            fill="#DD404F"
          />
          <Path fill="#FBAF29" d="M4 1.333h2v1.333H4zM6 2.667h2V4H6z" />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 18.667c1.105 0 2-.597 2-1.334C8 16.597 7.105 16 6 16s-2 .597-2 1.333c0 .737.895 1.334 2 1.334z"
          fill="#fff"
        />
        <Mask
          id="tm_svg__c"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={4}
          y={16}
          width={4}
          height={3}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 18.667c1.105 0 2-.597 2-1.334C8 16.597 7.105 16 6 16s-2 .597-2 1.333c0 .737.895 1.334 2 1.334z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#tm_svg__c)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 18a.667.667 0 100-1.333A.667.667 0 006 18z"
            fill="#DD404F"
          />
          <Path fill="#FBAF29" d="M4 16h2v1.333H4zM6 17.333h2v1.333H6z" />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12c1.105 0 2-.597 2-1.333 0-.737-.895-1.334-2-1.334s-2 .597-2 1.334C4 11.403 4.895 12 6 12z"
          fill="#28AE67"
        />
        <Mask
          id="tm_svg__d"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={4}
          y={9}
          width={4}
          height={3}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 12c1.105 0 2-.597 2-1.333 0-.737-.895-1.334-2-1.334s-2 .597-2 1.334C4 11.403 4.895 12 6 12z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#tm_svg__d)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 11.333c.736 0 1.333-.298 1.333-.666C7.333 10.299 6.736 10 6 10s-1.333.299-1.333.667c0 .368.597.666 1.333.666z"
            fill="#DD404F"
          />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 14.667c1.105 0 2-.299 2-.667 0-.368-.895-.667-2-.667S4 13.632 4 14c0 .368.895.667 2 .667z"
          fill="#28AE67"
        />
        <Mask
          id="tm_svg__e"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={4}
          y={13}
          width={4}
          height={2}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 14.667c1.105 0 2-.299 2-.667 0-.368-.895-.667-2-.667S4 13.632 4 14c0 .368.895.667 2 .667z"
            fill="#fff"
          />
        </Mask>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 8c1.105 0 2-.597 2-1.333 0-.737-.895-1.334-2-1.334S4 5.93 4 6.667C4 7.403 4.895 8 6 8z"
          fill="#FBAF29"
        />
        <Mask
          id="tm_svg__f"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={4}
          y={5}
          width={4}
          height={3}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 8c1.105 0 2-.597 2-1.333 0-.737-.895-1.334-2-1.334S4 5.93 4 6.667C4 7.403 4.895 8 6 8z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#tm_svg__f)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 7.333A.667.667 0 106 6a.667.667 0 000 1.333z"
            fill="#DD404F"
          />
          <Path fill="#28AE67" d="M4 5.333h1.333V8H4zM6.667 5.333H8V8H6.667z" />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.823 4.238a.667.667 0 101.021.857.667.667 0 00-1.021-.857zm-1.728 2.585a.667.667 0 10-.857 1.021.667.667 0 00.857-1.021zm-1.333-1.334a.667.667 0 10-.857 1.022.667.667 0 00.857-1.022zm2.666-2.666a.667.667 0 10-.857 1.021.667.667 0 00.857-1.021zm-.666 2a.667.667 0 10-.857 1.021.667.667 0 00.857-1.021z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.476 9.45a4.066 4.066 0 01-6.168-.487 4.068 4.068 0 005.68-5.68 4.067 4.067 0 01.488 6.168z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgTm;
