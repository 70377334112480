import React from 'react';

import { StyleSheet, View } from 'react-native';
import { Trans } from 'react-i18next';

import {
  DataBlock,
  Spacer,
  Spacing,
  Text,
  useResponsive,
  TranslatedContent,
  useTheme,
  Theme,
  TranslatedContentContentType,
} from '@app/ui';
import {
  PropertyPageTypeGallery,
  trackGalleryVisit,
  trackTranslationSwitch,
} from '@app/services/tracking/trackTracking';
import { RealEstate, Dpe, Ges } from '@app/libs/apollo/introspection';
import { FlexGrid } from '@app/shared/components/Grid';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { fromS3ID } from '@app/utils/from-s3id';
import { isNative } from '@app/constants/platform';

import { RealEstateRenovationDPEModal } from './components/renovationSummary/RealEstateRenovationDPEModal';
import { RealEstateRenovationGESModal } from './components/renovationSummary/RealEstateRenovationGESModal';
import { InvestmentWarning } from '../../components/InvestmentWarning';
import { RealEstateTabTitle } from '../../components/RealEstateTabTitle';
import { globalStyles } from '../realEstateAvailable.styles';
import { useRealEstateRenovation } from './hooks/useRealEstateRenovation';
import { useGalleryContext } from '../../gallery/GalleryProvider';
import { GalleryImagesProps } from '../../gallery/gallery.types';
import { staticFurniturePackGalleries } from './constants';
import { GalleryThumbnail } from './components/GalleryThumbnail';

interface RealEstateRenovationOldProps {
  realEstate: RealEstate;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    dataFieldWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing.SIZE_06,
    },
  });

// eslint-disable-next-line no-magic-numbers
const THUMBNAIL_RATIO = 328 / 290;

export const RealEstateRenovationOld: React.FC<RealEstateRenovationOldProps> = ({ realEstate }) => {
  const { openGallery } = useGalleryContext();
  const { t } = useRealEstateTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { isLowerThanLaptop, isLowerThanTablet, isNativeOrResponsive } = useResponsive();

  const {
    rows,
    shouldDisplayPlan,
    shouldDisplayFurniture,
    isVisibleDPEModal,
    isVisibleGESModal,
    toggleDPEModal,
    toggleGESModal,
  } = useRealEstateRenovation({
    building: realEstate.building,
    dpe: realEstate.dpe,
    furnishingAmount: realEstate.project.furnishing.amount ?? 0,
    ges: realEstate.ges,
    id: realEstate.id,
    plans: realEstate.plans,
    renovationAmount: realEstate.project.renovation.amount ?? 0,
    renovationWorkload: realEstate.project.renovation.workload,
  });

  const handleSwitchLanguage = (
    type: TranslatedContentContentType,
    sourceLang: string,
    targetLang: string
  ) => {
    trackTranslationSwitch({
      propertyId: realEstate.id,
      sourceLang,
      targetLang,
      type,
    });
  };

  const openPlanGallery = () => {
    trackGalleryVisit({
      countGalleryImages: realEstate.plans?.images.length,
      propertyId: realEstate.id,
      typeGallery: PropertyPageTypeGallery.PLAN,
    });
    openGallery({
      images: realEstate.plans.images.map(url => ({
        description: realEstate.plans.description,
        title: t('propertyRenovation.plansTitle'),
        translation: realEstate.plans.translation,
        url: fromS3ID(url, 'q-100'),
      })),
    });
  };

  const openFurnitureGallery = (gallery: GalleryImagesProps) => () => {
    trackGalleryVisit({
      countGalleryImages: gallery?.images.length,
      propertyId: realEstate.id,
      typeGallery: PropertyPageTypeGallery.FURNITURE,
    });
    openGallery({
      images: gallery.images.map(url => ({
        description: t(gallery.description),
        title: t(gallery.title),
        url: fromS3ID(url, 'q-100'),
      })),
    });
  };

  return (
    <>
      <View style={isNative() ? globalStyles.containerNative : {}}>
        <RealEstateTabTitle title={t('propertyRenovation.description')} />

        <TranslatedContent
          translation={realEstate.project.renovation.translation}
          onHandleDone={handleSwitchLanguage}
          translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
          showOriginal={t('shared.showOriginal')}
          showTranslated={t('shared.showTranslated')}
          textStyle="Body2Medium"
        />
        <Spacer height={isLowerThanTablet ? Spacing.SIZE_07 : Spacing.SIZE_08} />

        {!!rows && (
          <>
            <View style={styles.dataFieldWrapper}>
              {rows.map(row => (
                <DataBlock
                  key={row.title}
                  icon={row.icon}
                  title={row.title}
                  data={row.data}
                  hint={row.suffix}
                  width={isNativeOrResponsive ? '100%' : '47%'}
                />
              ))}
            </View>
            <Spacer height={isLowerThanTablet ? Spacing.SIZE_09 : Spacing.SIZE_11} />
          </>
        )}

        {!!shouldDisplayPlan && (
          <>
            <Text textStyle="Headline1Medium">{t('propertyRenovation.plansTitle')}</Text>
            <Spacer height={Spacing.SIZE_06} />

            <TranslatedContent
              translation={realEstate.plans.translation}
              onHandleDone={handleSwitchLanguage}
              translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
              showOriginal={t('shared.showOriginal')}
              showTranslated={t('shared.showTranslated')}
            />

            {!!realEstate.plans.images?.length && (
              <GalleryThumbnail
                thumbnailImageUrl={fromS3ID(realEstate.plans.images[0], 'q-100')}
                totalImages={realEstate.plans.images.length}
                onPress={openPlanGallery}
              />
            )}

            <Spacer height={isLowerThanTablet ? Spacing.SIZE_09 : Spacing.SIZE_11} />
          </>
        )}

        {!!shouldDisplayFurniture && (
          <>
            <Text textStyle="Headline1Medium">{t('propertyRenovation.furniture')}</Text>
            <Spacer height={Spacing.SIZE_06} />
            <Text textStyle="Body2">
              <Trans
                i18nKey="propertyRenovation.furnitureDescription"
                components={{
                  bold: <Text textStyle="Body2Medium" />,
                  medium: <Text textStyle="Body2Medium" />,
                }}
              />
            </Text>

            <Spacer height={Spacing.SIZE_07} />

            <FlexGrid
              col={2}
              verticalGap={isLowerThanLaptop ? Spacing.SIZE_04 : Spacing.SIZE_08}
              horizontalGap={isLowerThanLaptop ? Spacing.SIZE_03 : Spacing.SIZE_07}
            >
              {staticFurniturePackGalleries.map((gallery, index) => (
                <FlexGrid.Cell key={index}>
                  <Text textStyle={isLowerThanLaptop ? 'Body2Medium' : 'Headline2Medium'}>
                    {t(gallery.name)}
                  </Text>
                  <Spacer height={Spacing.SIZE_04} />
                  <GalleryThumbnail
                    thumbnailImageUrl={fromS3ID(gallery.images[0], 'q-100')}
                    totalImages={gallery.images.length}
                    ratio={THUMBNAIL_RATIO}
                    onPress={openFurnitureGallery(gallery)}
                  />
                </FlexGrid.Cell>
              ))}
            </FlexGrid>
          </>
        )}

        <Spacer height={isLowerThanTablet ? Spacing.SIZE_09 : Spacing.SIZE_11} />
        <InvestmentWarning />
      </View>

      <RealEstateRenovationDPEModal
        value={Dpe[realEstate.dpe]}
        isVisibleDPEModal={isVisibleDPEModal}
        onClose={toggleDPEModal}
      />
      <RealEstateRenovationGESModal
        value={Ges[realEstate.ges]}
        isVisibleGESModal={isVisibleGESModal}
        setIsVisibleGESModal={toggleGESModal}
      />
    </>
  );
};
