import { PointOfInterest, PointOfInterestSubType } from '@app/libs/apollo/introspection';

export const getTransportsPointsOfInterest = (
  pointOfInterest: PointOfInterest[]
): PointOfInterest[] => {
  const transportsList = [
    PointOfInterestSubType.Bus,
    PointOfInterestSubType.Rail,
    PointOfInterestSubType.Subway,
    PointOfInterestSubType.Tram,
  ];

  const transportsPointsOfInterest = pointOfInterest.filter(POI =>
    transportsList.includes(POI.subType)
  );

  return transportsPointsOfInterest;
};

export const getEducationPointsOfInterest = (
  pointOfInterest: PointOfInterest[]
): PointOfInterest[] => {
  const educationList = [PointOfInterestSubType.University];
  const educationPointsOfInterest = pointOfInterest.filter(POI =>
    educationList.includes(POI.subType)
  );
  return educationPointsOfInterest;
};
