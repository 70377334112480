import React, { useRef } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { isNative, isWeb } from '@app/constants/platform';
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { ICarouselInstance } from '@app/ui/organisms/carousel/CarouselLib';
// eslint-disable-next-line max-len
import { RealEstatePreviewCardVertical } from '@app/shared/components/real-estate-preview-card/RealEstatePreviewCardVertical';
import { useColumnsNb } from '@app/shared/hooks/useColumnsNb';

import {
  SearchEngineItem,
  SearchEngineLocationItem,
  ExtendedSearchResultsType,
} from '../../searchEngine.types';

export type SearchEngineMoreResultsListProps = {
  list: SearchEngineItem[];
  resultsType: ExtendedSearchResultsType;
  onItemBookmark: (item: RealEstatePreviewItem) => void;
  onItemClick: (
    item: SearchEngineItem,
    info: SearchEngineLocationItem,
    extendedResultsSection?: ExtendedSearchResultsType
  ) => void;
  title: string;
};

const SEPARATOR_WIDTH = 1;
const PERCENTAGE_OF_SHOWING_NEXT_CARD = 0.15;
const CAROUSEL_HEIGHT_WITH_DOTS = 400;
const CAROUSEL_HEIGHT_WITHOUT_DOTS = 375;

const getStyle = ({
  theme,
  isLowerThanTablet,
  width,
  isNativeOrResponsive,
}: {
  theme: Theme;
  isLowerThanTablet: boolean;
  width: number;
  isNativeOrResponsive: boolean;
}) =>
  StyleSheet.create({
    carouselContainer: {
      paddingTop: isLowerThanTablet ? theme.spacing.SIZE_05 : theme.spacing.SIZE_10,
    },
    carouselNative: {
      width,
    },
    carouselWeb: {
      width,
      ...(isNativeOrResponsive
        ? {
            marginHorizontal: -theme.spacing.SIZE_07,
            paddingHorizontal: theme.spacing.SIZE_07,
          }
        : {
            marginHorizontal: -theme.spacing.SIZE_09,
            paddingHorizontal: theme.spacing.SIZE_09,
          }),
    },
    container: {
      paddingBottom: theme.spacing.SIZE_07,
      paddingTop: isLowerThanTablet
        ? theme.spacing.SIZE_07
        : theme.spacing.SIZE_08 - theme.spacing.SIZE_02,
    },
    separator: {
      borderStyle: isWeb() ? 'dashed' : 'solid',
      borderTopColor: theme.palette.neutral[200],
      borderTopWidth: SEPARATOR_WIDTH,
      maxWidth: 1012,
      paddingTop: isLowerThanTablet
        ? theme.spacing.SIZE_08
        : theme.spacing.SIZE_09 - SEPARATOR_WIDTH,
      width: '100%',
    },
    separatorContainer: {
      alignItems: 'center',
      paddingHorizontal: isWeb() ? 0 : theme.spacing.SIZE_06,
    },
    title: {
      textAlign: 'center',
    },
  });

export const SearchEngineMoreResultsList: React.FC<SearchEngineMoreResultsListProps> = ({
  list,
  onItemBookmark,
  onItemClick,
  title,
  resultsType,
}) => {
  const theme = useTheme();
  const { isNativeOrResponsive, isLowerThanTablet } = useResponsive();
  const { width } = useWindowDimensions();
  const styles = getStyle({ isLowerThanTablet, isNativeOrResponsive, theme, width });

  const handleItemClick = (item: SearchEngineItem, info: SearchEngineLocationItem) => () =>
    onItemClick(item, info, resultsType);

  const handleItemBookmark = (item: RealEstatePreviewItem) => () => onItemBookmark(item);

  const columnsNb = useColumnsNb();

  const canSeeDots = list.length > columnsNb;
  const cardWidthNative = canSeeDots
    ? width * (1 - PERCENTAGE_OF_SHOWING_NEXT_CARD)
    : width - theme.spacing.SIZE_07;
  const cardWidthWebNextCardShowingWidth = canSeeDots ? theme.spacing.SIZE_09 : columnsNb;
  const cardWidthWeb =
    (width - styles.carouselWeb.paddingHorizontal) / columnsNb -
    cardWidthWebNextCardShowingWidth / columnsNb;
  const cardWidth = isNative() ? cardWidthNative : cardWidthWeb;

  const carouselRef = useRef<ICarouselInstance>(null);
  const carouselHeight = canSeeDots ? CAROUSEL_HEIGHT_WITH_DOTS : CAROUSEL_HEIGHT_WITHOUT_DOTS;

  return (
    <View style={styles.container}>
      <View style={styles.separatorContainer}>
        <View style={styles.separator} />
      </View>
      <Text style={styles.title} textStyle="Headline1Medium">
        {title}
      </Text>
      <View style={styles.carouselContainer}>
        <Carousel
          showDots={canSeeDots}
          ref={carouselRef}
          width={cardWidth}
          loop={false}
          data={list}
          panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
          height={carouselHeight}
          style={isNative() ? styles.carouselNative : styles.carouselWeb}
          renderItem={({ item, index }) => {
            const styleNative = {
              paddingLeft: index > 0 ? theme.spacing.SIZE_04 : theme.spacing.SIZE_07,
            };
            const styleWeb = {
              paddingRight: index < list.length ? theme.spacing.SIZE_07 : 0,
            };
            return (
              <View style={isNative() ? styleNative : styleWeb}>
                <RealEstatePreviewCardVertical
                  realEstatePreview={item}
                  key={item.key}
                  onBookmark={handleItemBookmark(item)}
                  onPress={handleItemClick(item, { position: index })}
                />
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};
