import React, { PropsWithChildren } from 'react';

import { View } from 'react-native';

import { Chip as HeaderChip } from '@app/ui/atoms/Chip';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Image } from '@app/ui/molecules/image/Image';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';

import { cardTheme } from './card.theme';
import {
  CardBlockProps,
  CardChipProps,
  CardComposition,
  CardContentProps,
  CardHeaderProps,
  CardImageProps,
  CardProps,
} from './Card.types';
import { blockStyle, cardStyle, chipStyle, styles } from './styles';

const Header: React.FC<PropsWithChildren<CardHeaderProps>> = ({ children, height }) => {
  const componentTheme = useComponentTheme('card', cardTheme);

  const themedStyles = styles(componentTheme);

  const headerStyle = height ? [themedStyles.header, { height }] : themedStyles.header;

  return <View style={headerStyle}>{children}</View>;
};

const ImageCard: React.FC<CardImageProps> = ({ imageUri = undefined }) => {
  return <Image source={{ uri: imageUri }} testID="card-image" />;
};

const Chip: React.FC<CardChipProps> = ({
  position,
  text,
  size = 'small',
  iconName,
  tint,
  textColor,
  backgroundColor,
}) => {
  const componentTheme = useComponentTheme('card', cardTheme);

  return (
    <View style={chipStyle(componentTheme, position)}>
      <HeaderChip
        text={text}
        size={size}
        iconName={iconName}
        textColor={textColor}
        backgroundColor={backgroundColor}
        tint={tint}
      />
    </View>
  );
};

// TODO Move this in a molecules Component
const Content: React.FC<CardContentProps> = ({ children, noSpacer }) => {
  const componentTheme = useComponentTheme('card', cardTheme);
  const theamedStyles = styles(componentTheme);

  const hasMultipleChildren = Array.isArray(children) && children.length > 1;

  //@TODO Remove this padding system for sub children, it's up to each integration to decide that
  if (noSpacer) {
    return <View style={theamedStyles.content}>{children}</View>;
  }

  const blocks = {
    firstChild: hasMultipleChildren ? children[0] : children,
    withoutFirstChild: hasMultipleChildren ? children.slice(1) : null,
  };

  return (
    <View style={theamedStyles.content}>
      {blocks.firstChild}
      {!!blocks.withoutFirstChild && (
        <View style={theamedStyles.blockWithoutFirstChild}>{blocks.withoutFirstChild}</View>
      )}
    </View>
  );
};

const Block: React.FC<CardBlockProps> = ({ withLine = false, children }) => {
  const componentTheme = useComponentTheme('card', cardTheme);
  return <View style={blockStyle(componentTheme, withLine)}>{children}</View>;
};

export const Card: React.FC<PropsWithChildren<CardProps>> & CardComposition = ({
  width,
  height,
  disabled,
  ghosted,
  children,
  onPress,
  testID = 'card',
  backgroundColor,
}) => {
  const componentTheme = useComponentTheme('card', cardTheme);
  const computedStyle = cardStyle({
    backgroundColor,
    disabled,
    ghosted,
    height,
    theme: componentTheme,
    width,
  });

  return onPress ? (
    <Pressable
      disabled={disabled}
      needsOffscreenAlphaCompositing={ghosted || disabled}
      onPress={onPress}
      style={computedStyle}
      testID={testID}
      pointerEvents={disabled ? 'none' : 'auto'}
    >
      {children}
    </Pressable>
  ) : (
    <View style={computedStyle} testID={testID}>
      {children}
    </View>
  );
};

Card.Header = Header;
Card.Chip = Chip;
Card.Image = ImageCard;
Card.Content = Content;
Card.Block = Block;
