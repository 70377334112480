import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="sr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#sr_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 4h28V0H0v4zM0 20h28v-4H0v4z" fill="#43924C" />
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="#CD153A" />
        <Path
          d="M14 11.7l-1.96 1.33.66-2.274-1.87-1.453 2.367-.075L14 7l.803 2.228 2.367.075-1.87 1.453.66 2.274L14 11.7z"
          fill="#F5D24B"
        />
      </G>
    </Svg>
  );
};

export default SvgSr;
