import { useState } from 'react';

export const AvailableLangs = ['fr', 'en'] as const;

export const DefaultLang = 'fr';

export type Lang = (typeof AvailableLangs)[number];

// Guard for the Lang, later can be extended by user config
export const isLang = (lang = ''): lang is Lang => {
  return AvailableLangs.includes(lang as unknown as Lang);
};

// As we don't support extending langs at the moment a guard is useful here
export const getValidLang = (lang?: Lang): Lang => {
  if (isLang(lang)) {
    return lang;
  }
  console.warn('[ConfigContext] Invalid currentLang');
  return DefaultLang;
};

export const useLang = (defaultLang?: Lang) => {
  const [stateCurrentLang, setStateCurrentLang] = useState<Lang>(getValidLang(defaultLang));

  const setCurrentLang = (lang: Lang) => setStateCurrentLang(getValidLang(lang));

  return {
    currentLang: stateCurrentLang,
    setCurrentLang,
  };
};
