import React, { useCallback, useMemo, useRef, useState } from 'react';

import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Animated, { useAnimatedRef } from 'react-native-reanimated';

import { Button, Theme, useResponsive, useTheme } from '@app/ui';
import { useRealEstateTitle } from '@app/shared/hooks/usePropertyTitle';
import { useTranslation } from '@app/services/translations/translations';
import { useRealEstateChips } from '@app/shared/hooks/useRealEstateChips';
import {
  trackProjectPropertyPageClicked,
  trackProjectSubStepsClicked,
  trackProjectSubStepsDetailsClicked,
  trackProjectTabClicked,
  trackTimelineStepClicked,
} from '@app/services/tracking/trackTracking';
import { ProjectNavigatorRoutes } from '@app/navigation/types/routes';
import {
  ProjectCategory,
  ProjectNavigationProp,
  ProjectProps,
} from '@app/features/project/Project.types';
import { MENU_TOP_HEIGHT } from '@app/features/project/Project.styles';
import { ProjectTabScreen } from '@app/features/project/ProjectTabScreen/ProjectTabScreen';
import { TabView } from '@app/shared/components/TabView/TabView';
import { ProjectHeader } from '@app/features/project/ProjectHeader/ProjectHeader';

const PADDING_CARD = 20;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    button: { width: 300 },
    container: {
      flex: 1,
    },
    header: { paddingBottom: theme.spacing.SIZE_09, width: '100%' },
  });

export const Project: React.FC<ProjectProps> = ({
  project: { realEstate, renting, status, projectAmount, id, transaction },
}) => {
  const navigation = useNavigation<ProjectNavigationProp>();
  const { t } = useTranslation();
  const stageRefs = useRef<Record<ProjectCategory, View[]>>({
    [ProjectCategory.Transaction]: [],
    [ProjectCategory.Renovation]: [],
    [ProjectCategory.Renting]: [],
  });
  const containerRef = useAnimatedRef<Animated.ScrollView>();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const realEstateTitle = useRealEstateTitle({
    city: realEstate.building.address.city,
    realEstateType: realEstate.type,
    rentingType: renting.type,
    surface: realEstate.surface,
    totalLots: realEstate.building.totalLots,
  });
  const realEstateChips = useRealEstateChips(realEstate);
  // FIXME To fill when data will from the back
  const projectSteps = {
    [ProjectCategory.Transaction]: transaction,
    [ProjectCategory.Renovation]: {},
    [ProjectCategory.Renting]: {},
  };
  const [stepActive, setStepActive] = useState<number>(1);

  const address = `${realEstate.building.address.street}, ${t('propertyInfo.address', {
    city: realEstate.building.address.city,
    country: realEstate.building.address.country,
    postalCode: realEstate.building.address.postalCode,
  })}`;

  const detailsButtonStatus = (expanded: boolean) => (expanded ? 'Show' : 'Hide');

  const navigateToRealEstate = () => {
    trackProjectPropertyPageClicked({
      projectId: id,
      projectStatus: status,
      propertyId: realEstate.id,
    });

    navigation.navigate(ProjectNavigatorRoutes.RealEstate, { id: realEstate.id });
  };
  const scrollToStage = (stageNumber: number) => {
    trackTimelineStepClicked({
      category: Object.values(ProjectCategory)[stepActive - 1],
      projectId: id,
      stepNumber: stageNumber,
    });

    Object.values(stageRefs.current)[stepActive - 1][stageNumber - 1].measure(
      // eslint-disable-next-line max-params
      (_, _1, _2, _3, _4, pageY) =>
        containerRef.current?.scrollTo({
          y: pageY - 2 * MENU_TOP_HEIGHT - PADDING_CARD,
        })
    );
  };

  const onExpandSubSteps = useCallback(
    (stepId: number) =>
      ({ expanded }: { expanded: boolean }) =>
        trackProjectSubStepsClicked({
          category: Object.values(ProjectCategory)[stepActive - 1],
          detailsButtonStatus: detailsButtonStatus(expanded),
          projectId: id,
          stepId,
        }),
    [id, stepActive]
  );
  const onExpandSubStepsDetails = useCallback(
    (stepId: number) => (subStepId: number) => {
      return ({ expanded }: { expanded: boolean }) =>
        trackProjectSubStepsDetailsClicked({
          category: Object.values(ProjectCategory)[stepActive - 1],
          detailsButtonStatus: detailsButtonStatus(expanded),
          projectId: id,
          stepId,
          subStepId,
        });
    },
    [id, stepActive]
  );
  const onChangeTab = useCallback((stepNumber: number) => {
    setStepActive(stepNumber);
    trackProjectTabClicked({ name: Object.values(ProjectCategory)[stepNumber - 1] });
  }, []);

  const onTimelinePress = useCallback(
    (stepNumber: number) => {
      setStepActive(stepNumber);
      trackTimelineStepClicked({
        category: Object.values(ProjectCategory)[stepNumber - 1],
        projectId: id,
        stepNumber: null,
      });
    },
    [id]
  );

  const tabsContent = Object.entries(projectSteps).map(([category, steps]) => ({
    component: () => (
      <ProjectTabScreen
        realEstate={realEstate}
        projectCategory={category as ProjectCategory}
        projectSteps={steps}
        onExpandSubSteps={onExpandSubSteps}
        onExpandSubStepsDetails={onExpandSubStepsDetails}
        stageRefs={stageRefs}
      />
    ),
    key: `${category}`,
    title: t(`project.${category}.tab.name`),
  }));
  const onIndexChange = (index: number) => onChangeTab?.(index + 1);
  const { isLowerThanDesktop } = useResponsive();

  return (
    <View style={styles.container} testID="project-container">
      <TabView
        header={
          <View style={styles.header}>
            <ProjectHeader
              address={address}
              chips={realEstateChips}
              imageCover={realEstate.coverImage}
              onBack={navigation.goBack}
              onButtonPress={navigateToRealEstate}
              projectAmount={projectAmount}
              projectSteps={projectSteps}
              scrollToStage={scrollToStage}
              setStepActive={onTimelinePress}
              status={status}
              stepActive={stepActive}
              contact={realEstate.contact}
              title={realEstateTitle}
            />
          </View>
        }
        index={stepActive - 1}
        onIndexChange={onIndexChange}
        scrollViewRef={containerRef}
        tabs={tabsContent}
        tabsRightElementWeb={
          !isLowerThanDesktop && (
            <Button
              onPress={navigateToRealEstate}
              size="m"
              testID="realEstateLink--press"
              variant="secondary"
              wrapperStyle={styles.button}
            >
              {t('project.realEstateLink')}
            </Button>
          )
        }
      />
    </View>
  );
};
