import { StyleSheet } from 'react-native';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = () =>
  StyleSheet.create({
    loader: {
      alignItems: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
    },
  });
