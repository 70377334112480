import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgJp = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="jp_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#jp_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 16a6 6 0 100-12 6 6 0 000 12z"
          fill="url(#jp_svg__paint0_linear_4223_11133)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="jp_svg__paint0_linear_4223_11133"
          x1={8}
          y1={4}
          x2={8}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D81441" />
          <Stop offset={1} stopColor="#BB0831" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgJp;
