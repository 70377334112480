import * as React from 'react';

import Svg, { SvgProps, G, Mask, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const Bus = ({ color, ...props }: SvgProps) => (
  <Svg width={50} height={50} viewBox="3 1 50 50" fill="none" {...props}>
    <G>
      <Mask id="b" fill="#fff">
        <Path
          fillRule="evenodd"
          d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
          clipRule="evenodd"
        />
      </Mask>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="m30.12 40.803-.123-.992-.323.04-.238.222.684.73Zm-4.778-.075.683-.73-.224-.21-.304-.048-.155.988ZM27.772 43l-.684.73.683.64.683-.64-.683-.73ZM43 25.967c0 7.031-5.61 12.928-13.003 13.844l.245 1.985C38.52 40.77 45 34.122 45 25.966h-2ZM28 12c8.348 0 15 6.315 15 13.967h2C45 17.087 37.325 10 28 10v2ZM13 25.967C13 18.315 19.652 12 28 12v-2c-9.325 0-17 7.087-17 15.967h2ZM25.497 39.74C18.355 38.623 13 32.837 13 25.967h-2c0 7.967 6.187 14.498 14.188 15.749l.309-1.976Zm2.957 2.53-2.429-2.272-1.366 1.46 2.43 2.272 1.365-1.46Zm.982-2.197-2.348 2.197 1.366 1.46 2.349-2.197-1.367-1.46Z"
        mask="url(#b)"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="M29.582 18.9c1.215 0 2.133.337 2.754 1.012.634.675.95 1.658.95 2.948 0 .675-.145 1.29-.435 1.845-.277.54-.694.967-1.248 1.282-.542.315-1.222.48-2.04.495l.039-.832c.436 0 .905.067 1.407.202.515.135.997.368 1.446.698.449.33.819.78 1.11 1.35.29.555.435 1.252.435 2.092 0 .915-.132 1.68-.396 2.295-.264.6-.614 1.073-1.05 1.418a4.125 4.125 0 0 1-1.447.742 5.998 5.998 0 0 1-1.525.203H24.51a.946.946 0 0 1-.713-.315 1.215 1.215 0 0 1-.297-.833V20.047c0-.33.1-.6.297-.81a.919.919 0 0 1 .713-.337h5.072Zm-.277 2.205h-3.903l.238-.36v4.837l-.218-.247h3.922a1.827 1.827 0 0 0 1.248-.54c.37-.36.555-.885.555-1.575 0-.705-.165-1.23-.495-1.575-.33-.36-.78-.54-1.347-.54Zm.138 6.435h-3.942l.139-.158v5.31l-.159-.202h4.081c.687 0 1.242-.203 1.664-.608.423-.42.634-1.05.634-1.89 0-.705-.125-1.23-.376-1.575a1.841 1.841 0 0 0-.931-.697c-.37-.12-.74-.18-1.11-.18Z"
      />
    </G>
    <Defs></Defs>
  </Svg>
);
export { Bus };
