import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

interface StylesProps {
  isMobile: boolean;
  theme: Theme;
}

export const getStyles = ({ isMobile = false, theme }: StylesProps) => {
  return StyleSheet.create({
    buttonSize: {
      width: 0,
    },
    buttonStyle: {
      borderRadius: theme.borderRadius.SIZE_08,
      height: theme.spacing.SIZE_08,
    },
    buttonWithIconSize: {
      width: 84,
    },
    buttonsContainer: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
      justifyContent: 'space-between',
      paddingHorizontal: isMobile ? theme.spacing.SIZE_06 : theme.spacing.NONE,
    },
    buttonsContainerContent: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
    },
    container: {
      alignItems: isMobile ? 'stretch' : 'center',
      backgroundColor: theme.palette.base.white,
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      marginHorizontal: theme.spacing.NONE,
      paddingTop: isMobile ? theme.spacing.SIZE_03 : theme.spacing.NONE,
      zIndex: 1,
    },
    horizontalLine: {
      borderColor: theme.palette.neutral[200],
      borderTopWidth: 1,
      marginBottom: theme.spacing.SIZE_03,
      marginTop: theme.spacing.SIZE_04,
    },
    resultMobileContainer: {
      marginTop: theme.spacing.SIZE_07,
      paddingHorizontal: theme.spacing.SIZE_06,
    },
    titleContainer: {
      alignItems: isMobile ? 'center' : 'baseline',
      flexDirection: 'row',
      justifyContent: isMobile ? 'space-between' : 'flex-start',
      paddingHorizontal: isMobile ? theme.spacing.SIZE_06 : theme.spacing.NONE,
    },
  });
};
