import React from 'react';

import { ScrollView } from 'react-native-gesture-handler';
import { StackScreenProps } from '@react-navigation/stack';

import { useTranslation } from '@app/services/translations/translations';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';
import { HeaderPage } from '@app/ui';
import { DiscussNavigatorRoutes, DiscussStackParamList } from '@app/navigation/types/routes';

import { DiscussTakeAppointmentContent } from './components/DiscussTakeAppointmentContent';

type DiscussProps = StackScreenProps<
  DiscussStackParamList,
  DiscussNavigatorRoutes.DiscussTakeAppointment
>;

export const DiscussTakeAppointment: React.FC<DiscussProps> = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <ScreenWrapper>
      <ScrollView>
        <HeaderPage title={t('discuss.takeAppointment.title')} onBack={navigation.goBack} />

        <DiscussTakeAppointmentContent />
      </ScrollView>
    </ScreenWrapper>
  );
};
