import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMap = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M14.35 20.775L9 18.9l-4.65 1.8a.902.902 0 01-.925-.113A.986.986 0 013 19.75v-14c0-.217.063-.408.188-.575.125-.167.295-.292.512-.375l4.65-1.575A2.55 2.55 0 019 3.112c.217-.008.433.03.65.113L15 5.1l4.65-1.8a.904.904 0 01.925.112.989.989 0 01.425.838v14a.931.931 0 01-.188.575 1.126 1.126 0 01-.512.375l-4.65 1.575a2.517 2.517 0 01-.65.112 1.616 1.616 0 01-.65-.112zM14 18.55V6.85l-4-1.4v11.7l4 1.4zm2 0l3-1V5.7l-3 1.15v11.7zM5 18.3l3-1.15V5.45l-3 1V18.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMap;
