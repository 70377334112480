// react-native-fast-image don't work in expo
import React, { useCallback, useState } from 'react';

import { ImageBackground as Image, View } from 'react-native';

import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { DefaultImage } from '@app/ui/atoms/default-image/DefaultImage';

import { isSourceImage, ResponsiveImageProps } from './Image.types';
import { getStyle } from './Image.styles';

export { ResponsiveImageProps };

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  aspectRatio,
  style,
  source,
  resizeMode,
  onPress,
  children,
  ...props
}) => {
  const hasUri = isSourceImage(source);

  const [errSrc, setErrSrc] = useState<string | undefined | null>();

  const onError = useCallback(() => {
    hasUri && setErrSrc(source?.uri);
  }, [hasUri, source]);

  const styles = getStyle({ aspectRatio });

  const Wrapper = onPress ? Pressable : View;

  const otherProp = { onPress };

  return (
    <Wrapper style={[styles.wrapper, style]} {...otherProp}>
      {(!hasUri && source) || (hasUri && source?.uri && source?.uri !== errSrc) ? (
        <Image
          style={styles.image}
          source={source}
          onError={onError}
          testID="Image"
          resizeMode={resizeMode || 'cover'}
          {...props}
        >
          {children}
        </Image>
      ) : (
        <>
          <DefaultImage />
          <View style={styles.defaultChildrenWrapper}>{children}</View>
        </>
      )}
    </Wrapper>
  );
};

export { ResponsiveImage as Image };
