import React, { useMemo } from 'react';

import { ColorValue, StyleSheet, View } from 'react-native';

import { IconButton, Text, Theme, useTheme } from '@app/ui';

interface EnergeticTagProps {
  backgroundColor: ColorValue;
  openModal: () => void;
  value: string;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    energeticContainer: {
      alignItems: 'center',
      borderRadius: theme.borderRadius.SIZE_08,
      flexDirection: 'row',
      gap: theme.spacing.SIZE_02,
      paddingHorizontal: 10,
      paddingVertical: 6,
    },
    energeticValue: {
      color: theme.palette.base.white,
    },
  });

export const EnergeticTag: React.FC<EnergeticTagProps> = ({
  backgroundColor,
  openModal,
  value,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <View style={[styles.energeticContainer, { backgroundColor }]}>
      <Text textStyle="Button2" style={styles.energeticValue}>
        {value}
      </Text>
      <IconButton
        iconName="Help"
        iconColor={theme.palette.base.white}
        iconSize="s"
        onPress={openModal}
        hitSlop={20}
      />
    </View>
  );
};
