import { RefObject } from 'react';

import { LayoutRectangle, View } from 'react-native';

import { calcXPosType, calcYPosType, GetListPositionType } from './Dropdown.types';

export const ANIMATED_OFFSET = 4;
export const MARGIN = 8;

export const calcXPos: calcXPosType = ({
  screenWidth,
  parentLeft,
  parentWidth,
  fitParent,
  maxWidth,
  listWidth,
  minWidth,
}) => {
  if (fitParent) {
    return {
      left: parentLeft,
      maxWidth: parentWidth,
      minWidth: parentWidth,
    };
  }

  const widthList = listWidth || maxWidth || minWidth || 0;
  if (parentLeft + widthList < screenWidth) {
    return {
      left: parentLeft,
      maxWidth,
      minWidth: minWidth,
    };
  }

  return {
    left: 'auto',
    maxWidth,
    minWidth: minWidth,
    right: screenWidth - (parentLeft + parentWidth),
  };
};

export const calcYPos: calcYPosType = ({
  parentTop,
  parentHeight,
  screenHeight,
  maxHeight = 0,
  fitParent,
}) => {
  const parentBottom = parentTop + parentHeight;

  const topSize = parentTop;
  const bottomSize = screenHeight - (parentTop + parentHeight);

  const maxHeightBottom = Math.min(screenHeight - parentBottom, maxHeight);
  const maxHeightTop = Math.min(parentTop, maxHeight);

  const margin = fitParent ? 0 : MARGIN;

  if (bottomSize < topSize && bottomSize < maxHeightTop) {
    return {
      bottom: screenHeight - parentTop + margin,
      maxHeight: maxHeightTop,
    };
  }

  return {
    maxHeight: maxHeightBottom,
    top: parentBottom + margin,
  };
};

export const getListPosition: GetListPositionType = ({
  parentLayout = null,
  dimensions,
  maxHeight = Infinity,
  fitParent,
  maxWidth,
  listWidth,
  minWidth,
}) => {
  if (!parentLayout) {
    return { left: 0, top: 0 };
  }

  const { x: parentLeft, y: parentTop, height: parentHeight, width: parentWidth } = parentLayout;

  const { height: screenHeight, width: screenWidth } = dimensions;

  const posX = calcXPos({
    fitParent,
    listWidth,
    maxWidth,
    minWidth,
    parentLeft,
    parentWidth,
    screenWidth,
  });

  const posY = calcYPos({
    fitParent,
    maxHeight,
    parentHeight,
    parentTop,
    screenHeight,
  });

  return {
    ...posX,
    ...posY,
  };
};

export type updateActivatorPositionType = (
  activatorRef: RefObject<View>,
  activatorPosition: LayoutRectangle | null,
  setActivatorPosition: (args: LayoutRectangle | null) => void
) => void;

export const updateActivatorPosition: updateActivatorPositionType = (
  activatorRef,
  activatorPosition,
  setActivatorPosition
) => {
  activatorRef.current?.measureInWindow((x: number, y: number, width: number, height: number) => {
    if (activatorPosition && activatorPosition.x !== x) {
      setActivatorPosition({
        height,
        width,
        x,
        y,
      });
    }
  });
};
