import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Icon, IconName, Text, Theme, useTheme } from '@app/ui';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';

export type DataRowsItemProps = {
  iconName?: IconName;
  label: string;
  description: string;
  keepColumns?: boolean;
  isLast?: boolean;
};

const getStyles = ({
  theme,
  isLowerThanDesktop,
  isLast,
  keepColumns,
}: {
  theme: Theme;
  isLowerThanDesktop: boolean;
  keepColumns: boolean;
  isLast: boolean;
}) => {
  return StyleSheet.create({
    container: {
      borderColor: theme.palette.neutral['200'],
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderWidth: 1,
      flexDirection: isLowerThanDesktop && !keepColumns ? 'column' : 'row',
      gap: isLowerThanDesktop ? 0 : 14,
      justifyContent: 'space-between',
      marginBottom: isLast ? 0 : 20,
      paddingBottom: 8,
    },
    descriptionText: {
      textAlign: isLowerThanDesktop ? 'left' : 'right',
    },
    icon: {
      marginBottom: 2,
      marginRight: 6,
    },
    labelContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: isLowerThanDesktop && !keepColumns ? 8 : 0,
    },
  });
};

export const DataRowsItem: React.FC<DataRowsItemProps> = ({
  iconName,
  label,
  description,
  keepColumns = false,
  isLast = false,
}) => {
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const styles = getStyles({ isLast, isLowerThanDesktop, keepColumns, theme });
  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        {!!iconName && <Icon name={iconName} style={styles.icon} size={15} />}
        <Text textStyle="Body2">{label}</Text>
      </View>
      <Text textStyle="Body2Medium" style={styles.descriptionText}>
        {description}
      </Text>
    </View>
  );
};
