import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgCm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="cm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#cm_svg__a)">
        <Path fill="url(#cm_svg__paint0_linear_4223_10609)" d="M13.333 0H28v20H13.333z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h9.333V0H0v20z" fill="#059170" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 20h9.334V0H9.333v20z"
          fill="#E21A30"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M18.667 20H28V0h-9.333v20z" fill="#FFDC44" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11.3l-1.96 1.397.724-2.295L10.83 8.97l2.406-.022L14 6.667l.764 2.281 2.406.022-1.934 1.432.723 2.295L14 11.3z"
          fill="#FFDC42"
        />
      </G>
      <Defs>
        <LinearGradient
          id="cm_svg__paint0_linear_4223_10609"
          x1={13.333}
          y1={0}
          x2={13.333}
          y2={20}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FFDC44" />
          <Stop offset={1} stopColor="#FDD216" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgCm;
