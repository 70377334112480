import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgIron = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M2 18v-3c0-1.1.392-2.042 1.175-2.825C3.958 11.392 4.9 11 6 11h9v-1a.967.967 0 0 0-.287-.713A.968.968 0 0 0 14 9h-4a.968.968 0 0 0-.713.287A.968.968 0 0 0 9 10H7c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 10 7h4c.833 0 1.542.292 2.125.875S17 9.167 17 10v4c.283 0 .52-.096.712-.287A.968.968 0 0 0 18 13V9c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 21 6h1v2h-1a.968.968 0 0 0-.712.287A.967.967 0 0 0 20 9v4c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 17 16v2H2Zm2-2h11v-3H6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 4 15v1Z"
    />
  </Svg>
);
export default SvgIron;
