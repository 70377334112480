import { Dispatch, SetStateAction } from 'react';

import { ErrorResponse } from '@apollo/client/link/error';

import { useDeleteCustomerMutation } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';
import { trackRemoveAccount } from '@app/services/tracking/trackTracking';
import { storage } from '@app/services/storage/storage';
import { useMe } from '@app/shared/hooks/useMe';
import { useLogout } from '@app/shared/hooks/useLogout';

type DeleteAccountFn = (password: string) => Promise<void>;

export const useDeleteAccount = (
  hasAPassword: boolean,
  setInputErrorMessage: Dispatch<SetStateAction<string>>
): DeleteAccountFn => {
  const { t } = useTranslation();
  const [deleteCustomer] = useDeleteCustomerMutation();
  const { customer } = useMe();
  const { logout } = useLogout();

  const deleteAccount = async (password: string): Promise<void> => {
    try {
      await deleteCustomer(hasAPassword ? { variables: { password } } : undefined);
    } catch (error) {
      const { graphQLErrors } = error as ErrorResponse;

      if (!graphQLErrors) {
        setInputErrorMessage(
          t(`profile.deleteAccountModal.${error.code ? 'errorApiMessage' : 'errorNetworkMessage'}`)
        );
        ErrorMonitoring.logError(error, {
          message: `Error ${error.code ? 'API' : 'Network'} when delete account`,
          userId: customer.id,
        });
        return error;
      }

      if (graphQLErrors[0].message === 'Bad Request') {
        setInputErrorMessage(t('profile.deleteAccountModal.errorPasswordMessage'));
      }
      return;
    }
    trackRemoveAccount();
    storage.saveObject('deleted_account', true);
    await logout();
  };

  return deleteAccount;
};
