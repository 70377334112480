import { StyleSheet, Platform } from 'react-native';

import { Theme } from '@app/ui/theme/types';

interface StylesParams {
  theme: Theme;
  pressed?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  height?: number;
  hasAddon?: boolean;
  selected?: boolean;
  isLastItem?: boolean;
  inline?: boolean;
}

const getBackgroundColor = ({ theme, pressed, hovered, selected }: StylesParams): string => {
  if (pressed) {
    return theme.palette.neutral[100];
  }

  if (hovered || selected) {
    return theme.palette.neutral[50];
  }

  return theme.palette.base.white;
};

export const getTextAndIconColor = ({
  theme,
  pressed,
  hovered,
  disabled,
}: StylesParams): string => {
  if (disabled) {
    return theme.palette.neutral[600];
  }

  if (pressed) {
    return theme.palette.neutral[900];
  }

  if (hovered) {
    return theme.palette.neutral[700];
  }

  return theme.palette.neutral[800];
};

export const getStyles = ({
  theme,
  pressed,
  hovered,
  disabled,
  height,
  hasAddon,
  selected,
  isLastItem,
  inline,
}: StylesParams) =>
  StyleSheet.create({
    avatar: {
      opacity: disabled ? 0.7 : 1,
    },
    container: {
      alignItems: 'center',
      backgroundColor: getBackgroundColor({
        hovered,
        pressed,
        selected,
        theme,
      }),
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: isLastItem ? 0 : 1,
      flexDirection: 'row',
      height,

      paddingHorizontal: theme.spacing.SIZE_02,

      paddingVertical: inline ? theme.spacing.SIZE_06 : theme.spacing.SIZE_04,
      // minWidth: 200, Why ???!!!!
      ...Platform.select({
        web: {
          cursor: disabled ? 'default' : 'pointer',
        },
      }),
    },
    description: {
      ...theme.textStyles.Caption1,
      marginTop: theme.spacing.SIZE_02,
    },
    flag: {
      opacity: disabled ? 0.7 : 1,
    },
    labelContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    labelText: {
      ...theme.textStyles.Body2Medium,
      color: getTextAndIconColor({
        disabled,
        hovered,
        pressed,
        theme,
      }),
      marginLeft: hasAddon ? theme.spacing.SIZE_03 : theme.spacing.NONE,
    },
    rightIcon: {
      marginLeft: 'auto',
    },
    subContainer: {
      flex: 1,
      ...(inline && {
        flexDirection: 'row',
        justifyContent: 'space-between',
      }),
    },
  });
