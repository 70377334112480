import React, { useEffect, useState } from 'react';

import { Animated, StyleProp, View, ViewStyle } from 'react-native';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { Icon, IconName } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';
import { useAnimation } from '@app/ui/utils/useAnimation';

import { sectionTheme } from './section.theme';
import { background, getMainViewStyle, itemStyles } from './styles';

export interface SectionItemProps {
  /** Action triggered when the item is clicked */
  onPress: () => void;
  /** Main title of the title */
  title: string;
  /** testID Name */
  testID?: string;
  /** Icon name of the item icon */
  icon: IconName;
  /** Vertical size of the item */
  size?: 'md' | 'sm';
  /** Last item in the Section display bottom border */
  isLast?: boolean;
  /** First item in the Section display doesn't display top border */
  isFirst?: boolean;
  /** Style of content */
  contentStyle?: StyleProp<ViewStyle>;
  /** Hide the icon */
  hideChevron?: boolean;
  /** Description of the section */
  description?: string;
}

export const SectionItem: React.FC<SectionItemProps> = ({
  onPress,
  title,
  description = null,
  icon,
  size = 'md',
  isLast = false,
  isFirst = false,
  contentStyle,
  testID,
  hideChevron = false,
}) => {
  const componentTheme = useComponentTheme('section', sectionTheme);

  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);

  const themedBackground = background(componentTheme);
  const themedItmeStyles = itemStyles(componentTheme);

  const { setIsOn, interpolate } = useAnimation();
  const [backgroundColor] = interpolate([themedBackground.default, themedBackground.active]);

  useEffect(() => {
    setIsOn(pressed || hovered);
  }, [pressed, hovered, setIsOn]);

  return (
    <Pressable
      onPress={onPress}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      withPressOpacity={false}
      testID={testID}
    >
      <Animated.View style={[{ backgroundColor }, contentStyle]}>
        {!isFirst && <View style={themedItmeStyles.border} />}
        <View style={getMainViewStyle(componentTheme, size)}>
          <View style={themedItmeStyles.container}>
            <View style={themedItmeStyles.titleWrapper}>
              <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <Icon
                  style={themedItmeStyles.icon}
                  size={componentTheme.defaultIcons.size1}
                  name={icon}
                />
                <View>
                  <Text textStyle="Body2Medium">{title}</Text>
                  {!!description && (
                    <Text textStyle="Caption1" style={{ color: componentTheme.description }}>
                      {description}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            {!hideChevron && (
              <Icon
                name="ChevronRight"
                size={componentTheme.defaultIcons.size2}
                color={componentTheme.defaultIcons.color}
              />
            )}
          </View>
        </View>
        {!!isLast && <View style={themedItmeStyles.border} />}
      </Animated.View>
    </Pressable>
  );
};
