import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTrendingUp = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M2.7 17.3a.933.933 0 01-.275-.688c0-.275.092-.512.275-.712l6-6.05c.1-.083.208-.15.325-.2a.942.942 0 01.375-.075c.133 0 .263.025.388.075.125.05.229.117.312.2l3.3 3.3L18.6 8H17a.967.967 0 01-.712-.287A.968.968 0 0116 7c0-.283.096-.521.288-.713A.967.967 0 0117 6h4c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0121 12a.965.965 0 01-.712-.288A.965.965 0 0120 11V9.4l-5.9 5.9c-.1.1-.208.17-.325.212a1.1 1.1 0 01-.375.063 1.1 1.1 0 01-.375-.063.871.871 0 01-.325-.212L9.4 12l-5.325 5.325a.918.918 0 01-.675.275.96.96 0 01-.7-.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTrendingUp;
