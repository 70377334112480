import React from 'react';

import { Controller, useForm, useFormContext } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { yupResolver } from '@hookform/resolvers/yup';

import { inputToNumber, numberToInput } from '@app/utils/input-formatter';
import { Input, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { useMe } from '@app/shared/hooks/useMe';

import { FilterFormContainer } from './FilterFormContainer';
import { getBudgetFormSchema } from '../../utils/get-budget-rules';
import { FilterForm } from '../Filters.type';
import { useCountResults } from '../hooks/useCountResults.hook';

interface BudgetForm {
  budgetMin: number | null;
  budgetMax: number | null;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    error: {
      color: theme.palette.alert.error,
    },
    input: {
      flex: 1,
    },
    inputs: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_04,
    },
  });

export const BudgetFilterForm: React.FC<FilterForm> = ({ goBack }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const { customer } = useMe();

  const { getValues: getGlobalValues, setValue } = useFormContext<SearchEngineFilterForm>();

  const budgetSchema = getBudgetFormSchema(customer.searchMandate?.capacity ?? 0, t);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<BudgetForm>({
    mode: 'onChange',
    resolver: yupResolver(budgetSchema),
    values: {
      budgetMax: getGlobalValues('budgetMax'),
      budgetMin: getGlobalValues('budgetMin'),
    },
  });

  const onApply = (form: BudgetForm) => {
    setValue('budgetMax', form.budgetMax);
    setValue('budgetMin', form.budgetMin);
    goBack();
  };

  const onReset = () => {
    reset();
  };

  const { count, loading } = useCountResults(watch());

  return (
    <FilterFormContainer
      title={t('realEstate.filters.budget.title')}
      canApply={isValid}
      resultCount={count}
      loading={loading}
      onApply={handleSubmit(onApply)}
      onBack={goBack}
      onReset={onReset}
    >
      <View style={styles.inputs}>
        <Controller
          control={control}
          name="budgetMin"
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Input
              testID="search-engine-filter-budget-min--input"
              errorMessageTestID="search-engine-filter-budget-min--input-error"
              label={t('propertiesPreferences.minimalBudget')}
              onBlur={onBlur}
              onChangeText={v => onChange(inputToNumber(v))}
              value={numberToInput(value)}
              error={error?.type !== 'lessThanMax' ? error?.message : ' '}
              keyboardType="numeric"
              wrapperStyle={styles.input}
            />
          )}
        />

        <Controller
          control={control}
          name="budgetMax"
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Input
              testID="search-engine-filter-budget-max--input"
              errorMessageTestID="search-engine-filter-budget-max--input-error"
              label={t('propertiesPreferences.maximalBudget')}
              onBlur={onBlur}
              onChangeText={v => onChange(inputToNumber(v))}
              value={numberToInput(value)}
              keyboardType="numeric"
              wrapperStyle={styles.input}
              error={
                error?.message || (errors.budgetMin?.type === 'lessThanMax' && ' ') || undefined
              }
            />
          )}
        />
      </View>

      {errors.budgetMin?.type === 'lessThanMax' && (
        <Text textStyle="Caption1" style={styles.error} numberOfLines={1}>
          {errors.budgetMin.message}
        </Text>
      )}
    </FilterFormContainer>
  );
};
