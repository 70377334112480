import React from 'react';

import { ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { useFormContext } from 'react-hook-form';

import { Button, ListItem, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { isNative, isWeb } from '@app/constants/platform';
import {
  SearchEngineFilterForm,
  SearchEngineSource,
} from '@app/features/search-engine/searchEngine.types';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { captureException } from '@app/libs/sentry/sentry';
import { BOTTOM_BAR_MENU_HEIGHT } from '@app/ui/organisms/BottomBarMenu/bottomBarMenu.theme';
import { Trilean } from '@app/ui/molecules/trilean-controller/TrileanController.types';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';

import { FilterType } from '../Filters.type';
import { getRadioLabels } from '../utils/filterForm.utils';
import { getRoomLabels } from '../../utils/get-room-list';
import { getDefaultFormValues } from '../../utils/get-default-form-values';
import { SearchEngineSortStrategy } from '../../search-engine-sort/SearchEngineSort.types';
import { useCountResults } from '../hooks/useCountResults.hook';

interface FilterListProps {
  onClose: () => void;
  onPress: (type: FilterType) => void;
  onSubmit: (filter: SearchEngineFilterForm, source: SearchEngineSource) => Promise<void> | void;
}

const getStyles = ({
  theme,
  isLowerThanLaptop,
  isLowerThanTablet,
  height,
}: {
  theme: Theme;
  isLowerThanLaptop: boolean;
  isLowerThanTablet: boolean;
  height: number;
}) =>
  StyleSheet.create({
    applyButton: {
      ...(isLowerThanTablet && { width: '100%' }),
      ...(!isLowerThanTablet && { flex: 2 / 3 }),
    },
    container: {
      flex: 1,
      ...(isWeb() && {
        maxHeight: height - (isLowerThanLaptop ? BOTTOM_BAR_MENU_HEIGHT : 0),
        minHeight: height - (isLowerThanLaptop ? BOTTOM_BAR_MENU_HEIGHT : 0),
      }),
    },
    footer: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[300],
      borderTopWidth: 1,
      flexDirection: isLowerThanTablet ? 'column-reverse' : 'row',
      gap: theme.spacing.SIZE_04,
      padding: isLowerThanTablet ? theme.spacing.SIZE_06 : theme.spacing.SIZE_04,
    },
    header: {
      borderBottomWidth: isLowerThanTablet ? 1 : 0,
      borderColor: theme.palette.neutral[300],
      padding: theme.spacing.SIZE_05,
      ...(!isLowerThanTablet && {
        padding: theme.spacing.SIZE_08,
        paddingBottom: theme.spacing.SIZE_03,
      }),
    },
    list: { flex: 1 },
    listContent: {
      paddingHorizontal: isLowerThanTablet ? theme.spacing.SIZE_06 : theme.spacing.SIZE_08,
      paddingVertical: isLowerThanTablet ? theme.spacing.SIZE_08 : theme.spacing.SIZE_03,
    },
    resetButton: {
      ...(isLowerThanTablet && { width: '100%' }),
      ...(!isLowerThanTablet && { flex: 1 / 3 }),
    },
  });

export const FilterList: React.FC<FilterListProps> = ({ onPress, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const { isLowerThanLaptop, isLowerThanTablet } = useResponsive();
  const styles = getStyles({ height, isLowerThanLaptop, isLowerThanTablet, theme });

  const { customer } = useMe();
  const { watch, handleSubmit, reset } = useFormContext<SearchEngineFilterForm>();
  const {
    alreadyRented,
    atGroundFloor,
    budgetMax,
    budgetMin,
    // country,
    flatSharing,
    regionList,
    roomCount,
    typology: typologies,
    withRenovation,
    withShortTermRental,
    zones,
  } = watch();

  // TODO: Remove support for others countries #countryRemoval
  const country = AvailableCountryCode.Fr;

  const handleOnPress = (type: FilterType) => () => {
    onPress(type);
  };

  const onReset = () => {
    reset(
      getDefaultFormValues({
        capacity: customer.searchMandate?.capacity ?? 0,
        sortStrategy: !customer.searchMandate?.valid
          ? SearchEngineSortStrategy.NetReturnDesc
          : SearchEngineSortStrategy.LastPublishedAtDesc,
      })
    );
  };

  const ScrollViewToUse = isNative() ? BottomSheetScrollView : ScrollView;
  const budgetDescription = `${currencyFormatter(budgetMin ?? 0, 0)} - ${currencyFormatter(
    budgetMax ?? 0,
    0
  )}`;
  const typologyDescription = typologies.length
    ? typologies.map(typology => t(`shared.propertyKind.${typology}`)).join(', ')
    : t('realEstate.filters.all');
  const roomLabels = getRoomLabels(t);
  const roomCountDescription = roomCount.length
    ? roomCount.map(count => roomLabels[count]).join(', ')
    : t('realEstate.filters.all');

  const getRadioLabelsDescription = (value: Trilean) =>
    value !== null ? getRadioLabels(t)[`${value}`] : t('propertiesPreferences.noMatter');
  const flatSharingDescription = getRadioLabelsDescription(flatSharing);
  const withRenovationDescription = getRadioLabelsDescription(withRenovation);
  const alreadyRentedDescription = getRadioLabelsDescription(alreadyRented);
  const atGroundFloorDescription = getRadioLabelsDescription(atGroundFloor);
  const withShortTermRentalDescription = getRadioLabelsDescription(withShortTermRental);
  const locationCount = country === AvailableCountryCode.Fr ? zones.length : regionList.length;
  const locationDescription = t(
    `realEstate.filters.location.city.${locationCount > 1 ? 'other' : 'one'}`,
    { count: locationCount }
  );

  const submit = (form: SearchEngineFilterForm) =>
    Promise.resolve(onSubmit(form, SearchEngineSource.DRAWER))
      .catch(e => {
        reset();
        captureException(e);
      })
      .finally(onClose);

  const { count: resultCount, loading } = useCountResults(watch());
  const hasResult = typeof resultCount === 'number';
  const resultKey = `realEstate.filters.resultCount.${resultCount ?? 0 > 1 ? 'other' : 'one'}`;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text textStyle={isLowerThanTablet ? 'Headline3Medium' : 'Title3Medium'}>
          {t('realEstate.filters.title')}
        </Text>
      </View>

      <ScrollViewToUse style={styles.list} contentContainerStyle={styles.listContent}>
        <ListItem
          key="location"
          label={t('realEstate.filters.location.title')}
          description={locationCount > 0 ? locationDescription : undefined}
          onPress={handleOnPress(FilterType.Location)}
          hasRightArrow
          inline
        />
        <ListItem
          key="budget"
          label={t('realEstate.filters.budget.title')}
          description={budgetDescription}
          onPress={handleOnPress(FilterType.Budget)}
          hasRightArrow
          inline
        />
        <ListItem
          key="typology"
          label={t('realEstate.filters.typology.title')}
          description={typologyDescription}
          onPress={handleOnPress(FilterType.Typology)}
          hasRightArrow
          inline
        />
        <ListItem
          key="roomCount"
          label={t('realEstate.filters.roomCount.title')}
          description={roomCountDescription}
          onPress={handleOnPress(FilterType.RoomCount)}
          hasRightArrow
          inline
        />
        <ListItem
          key="withShortTermRental"
          label={t('realEstate.filters.withShortTermRental.title')}
          description={withShortTermRentalDescription}
          onPress={handleOnPress(FilterType.WithShortTermRental)}
          hasRightArrow
          inline
        />
        <ListItem
          key="flatSharing"
          label={t('realEstate.filters.flatSharing.title')}
          description={flatSharingDescription}
          onPress={handleOnPress(FilterType.FlatSharing)}
          hasRightArrow
          inline
        />
        <ListItem
          key="alreadyRented"
          label={t('realEstate.filters.alreadyRented.title')}
          description={alreadyRentedDescription}
          onPress={handleOnPress(FilterType.AlreadyRented)}
          hasRightArrow
          inline
        />
        <ListItem
          key="withRenovation"
          label={t('realEstate.filters.withRenovation.title')}
          description={withRenovationDescription}
          onPress={handleOnPress(FilterType.WithRenovation)}
          hasRightArrow
          inline
        />
        <ListItem
          key="atGroundFloor"
          label={t('realEstate.filters.atGroundFloor.title')}
          description={atGroundFloorDescription}
          onPress={handleOnPress(FilterType.AtGroundFloor)}
          hasRightArrow
          inline
        />
      </ScrollViewToUse>

      <View style={styles.footer}>
        <Button
          testID="search-engine-filter-reset--button"
          variant="ghost"
          wrapperStyle={styles.resetButton}
          onPress={onReset}
        >
          {t('propertiesPreferences.erase')}
        </Button>
        <Button
          testID="search-engine-filter--submit"
          variant="primary"
          wrapperStyle={styles.applyButton}
          onPress={handleSubmit(submit)}
          isLoading={loading}
        >
          {`${t('shared.show')} ${hasResult ? t(resultKey, { count: resultCount }) : ''}`}
        </Button>
      </View>
    </View>
  );
};
