import { StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@app/ui';

export const getStyles = (theme: Theme): Record<string, ViewStyle> =>
  StyleSheet.create({
    preferencesContainer: { marginHorizontal: 20 },
    safeAreaContainer: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },
  });
