import { StepStatus } from '@app/ui';

export const getStageStatus = (
  stageNumber: number,
  validatedStagesNumber?: number | null
): StepStatus => {
  // For now, we don't want the steps after "signer l'acte authentique" (stageNumber 5) to be dynamic
  if (stageNumber > 5) {
    return StepStatus.Incoming;
  }

  if (!validatedStagesNumber) {
    return StepStatus.Incoming;
  }

  if (stageNumber < validatedStagesNumber) {
    return StepStatus.Finished;
  }
  if (stageNumber === validatedStagesNumber) {
    return StepStatus.Ongoing;
  }
  return StepStatus.Incoming;
};
