import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="cg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#cg_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M6.667 18.667L26.667 0H28v20H6.667v-1.333z" fill="#F33630" />
        <Path d="M21.333 1.333L1.333 20H0V0h21.333v1.333z" fill="#009643" />
        <Path d="M30.24-2.059L3.38 25.756l-4.795-4.631L25.445-6.69l4.795 4.631z" fill="#FCDF4A" />
      </G>
    </Svg>
  );
};

export default SvgCg;
