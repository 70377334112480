import { Theme } from '@app/ui/theme/types';

export const likeButtonTheme = (theme: Theme) => ({
  border: {
    borderColor: theme.palette.neutral[200],
    borderWidth: theme.spacing.SIZE_00,
  },
  colors: {
    active: {
      backgroundColor: theme.palette.base.white,
      iconColor: theme.palette.tint.red[500],
    },
    default: {
      backgroundColor: theme.palette.base.white,
      iconColor: theme.palette.neutral[600],
    },
    disabled: {
      backgroundColor: theme.palette.base.white,
      iconColor: theme.palette.neutral[400],
    },
    hover: {
      backgroundColor: theme.palette.neutral[50],
      iconColor: theme.palette.neutral[600],
    },
    pressed: {
      backgroundColor: theme.palette.neutral[100],
      iconColor: theme.palette.tint.red[500],
    },
  },
  containerSize: {
    l: {
      borderRadius: theme.spacing.SIZE_09,
      height: theme.spacing.SIZE_09,
      width: theme.spacing.SIZE_09,
    },
    m: {
      borderRadius: theme.spacing.SIZE_08 + 4,
      height: theme.spacing.SIZE_08 + 4,
      width: theme.spacing.SIZE_08 + 4,
    },
    s: {
      borderRadius: theme.spacing.SIZE_08,
      height: theme.spacing.SIZE_08,
      width: theme.spacing.SIZE_08,
    },
    xl: {
      borderRadius: theme.spacing.SIZE_10,
      height: theme.spacing.SIZE_10,
      width: theme.spacing.SIZE_10,
    },
    xs: {
      borderRadius: theme.spacing.SIZE_07,
      height: theme.spacing.SIZE_07,
      width: theme.spacing.SIZE_07,
    },
    xxs: {
      borderRadius: theme.spacing.SIZE_06,
      height: theme.spacing.SIZE_06,
      width: theme.spacing.SIZE_06,
    },
  },
  iconSize: {
    l: {
      height: theme.spacing.SIZE_07,
      width: theme.spacing.SIZE_07,
    },
    m: {
      height: theme.spacing.SIZE_06,
      width: theme.spacing.SIZE_06,
    },
    s: {
      height: theme.spacing.SIZE_06,
      width: theme.spacing.SIZE_06,
    },
    xl: {
      height: theme.spacing.SIZE_07,
      width: theme.spacing.SIZE_07,
    },
    xs: {
      height: theme.spacing.SIZE_05,
      width: theme.spacing.SIZE_05,
    },
    xxs: {
      height: theme.spacing.SIZE_05,
      width: theme.spacing.SIZE_05,
    },
  },
  shadow: theme.shadows[50],
});
