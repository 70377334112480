import React, { PropsWithChildren } from 'react';

import FlagProvider from '@unleash/proxy-client-react';
import { faker } from '@faker-js/faker';

import { isWeb as isWebPlatform } from '@app/constants/platform';

import { unleashMobileConfig, unleashWebConfig } from './config';

const localSTorageUnleashKey = 'unleash-session-id';

function getOrCreateSessionId(isWeb = false): string {
  if (!isWeb) {
    return faker.datatype.uuid();
  }

  const existingUnleashSessionId = localStorage.getItem(localSTorageUnleashKey);

  if (existingUnleashSessionId) {
    return existingUnleashSessionId;
  }

  const newUnleashSessionId = faker.datatype.uuid();
  localStorage.setItem(localSTorageUnleashKey, newUnleashSessionId);

  return newUnleashSessionId;
}

export const UnleashFeatureFlagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const unleashConfig = isWebPlatform() ? unleashWebConfig() : unleashMobileConfig();

  const unleashConfigWithContext = {
    ...unleashConfig,
    context: {
      sessionId: getOrCreateSessionId(isWebPlatform()),
    },
  };

  return <FlagProvider config={unleashConfigWithContext}>{children}</FlagProvider>;
};
