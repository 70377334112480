import React from 'react';

import { Ges } from '@app/libs/apollo/introspection';
import { BottomSheet } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { gesColors } from '../../utils/ges';
import { PerformanceIndicator } from './PerformanceIndicator';

interface RealEstateRenovationGESModalProps {
  value: string;
  isVisibleGESModal: boolean;
  setIsVisibleGESModal: () => void;
}

const performanceIndicators = [Ges.A, Ges.B, Ges.C, Ges.D, Ges.E, Ges.F, Ges.G];

const data = performanceIndicators.map(ges => ({
  color: gesColors[ges],
  description: `shared.performanceIndicator.GES.${ges.toLowerCase()}Description`,
  label: `shared.performanceIndicator.GES.${ges.toLowerCase()}Label`,
  value: ges,
}));

export const RealEstateRenovationGESModal: React.FC<RealEstateRenovationGESModalProps> = ({
  value,
  isVisibleGESModal,
  setIsVisibleGESModal,
}) => {
  const { t } = useTranslation();

  return (
    <BottomSheet visible={isVisibleGESModal} onPressClose={setIsVisibleGESModal}>
      <PerformanceIndicator
        title={t('shared.performanceIndicator.GES.title')}
        caption={t('shared.performanceIndicator.GES.caption')}
        labelLowest={t('shared.performanceIndicator.GES.labelLowest')}
        labelHighest={t('shared.performanceIndicator.GES.labelHighest')}
        value={value}
        data={data}
      />
    </BottomSheet>
  );
};
