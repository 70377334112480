import React from 'react';

import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';

const getStyles = (theme: Theme, isNativeOrResponsive: boolean, isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: isNativeOrResponsive ? 0 : theme.spacing.SIZE_08,
      paddingHorizontal: isLowerThanDesktop ? theme.spacing.SIZE_06 : theme.spacing.SIZE_09,
    },
    subtitle: { color: theme.palette.neutral[700], width: 591 },
    title: { color: theme.palette.system.primary[500] },
  });

type HomePageHeaderProps = {
  isFirstStep: boolean;
};

export const HomePageHeader: React.FC<HomePageHeaderProps> = ({ isFirstStep }) => {
  const { customer } = useMe();
  const { t } = useTranslation();

  const theme = useTheme();

  const { isNativeOrResponsive, isLowerThanDesktop } = useResponsive();
  const styles = getStyles(theme, isNativeOrResponsive, isLowerThanDesktop);

  return (
    <View style={styles.container}>
      <Text textStyle="Title2Medium" testID="homePageHeader">
        {isFirstStep ? t('homePage.welcome') : t('homePage.hello')}{' '}
        <Text textStyle="Title2Medium" style={styles.title}>
          {customer.firstName}
        </Text>
      </Text>

      {!isNativeOrResponsive && (
        <Text textStyle="Headline3" style={styles.subtitle}>
          {t('homePage.subtitle')}
        </Text>
      )}
    </View>
  );
};
