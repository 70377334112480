import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Button, Icon, Text, Theme, getColorWithOpacity, useResponsive, useTheme } from '@app/ui';

const getStyles = ({
  isLowerThanLaptop,
  isLaptop,
  theme,
}: {
  isLowerThanLaptop: boolean;
  isLaptop: boolean;
  theme: Theme;
}) =>
  StyleSheet.create({
    button: {
      height: 32,
    },
    container: {
      alignItems: 'center',
      backdropFilter: 'blur(8px)',
      backgroundColor: getColorWithOpacity(theme.palette.base.white, 70),
      borderColor: theme.palette.base.white,
      borderTopRightRadius: theme.borderRadius.SIZE_06,
      ...(isLaptop && {
        borderBottomRightRadius: theme.borderRadius.SIZE_06,
      }),
      borderWidth: 1,
      bottom: isLaptop ? 0 : '50%',
      gap: isLowerThanLaptop ? theme.spacing.SIZE_03 : theme.spacing.SIZE_04,
      justifyContent: 'center',
      left: isLaptop ? 0 : '50%',
      overflow: 'hidden',
      padding: theme.spacing.SIZE_04,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    label: {
      textAlign: 'center',
    },
  });

interface RealEstateUrl3dCardProps {
  onPress: () => void;
}

export const RealEstateUrl3dCard: React.FC<RealEstateUrl3dCardProps> = ({ onPress }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop, isLaptop } = useResponsive();
  const styles = useMemo(
    () => getStyles({ isLaptop, isLowerThanLaptop, theme }),
    [isLaptop, isLowerThanLaptop, theme]
  );

  return (
    <View style={styles.container}>
      <Icon name="Cube" size={16} />
      <Text textStyle={isLowerThanLaptop ? 'Body2' : 'Body3'} style={styles.label}>
        {t('propertyInfo.3DDescription')}
      </Text>
      <Button
        testID="virtual-visit-button"
        onPress={onPress}
        variant="tertiary"
        style={styles.button}
      >
        {t('propertyInfo.3DButton')}
      </Button>
    </View>
  );
};
