import { LanguageSwitcherOption } from './languageSwitcher.types';

export const languageSwitcherOptions: LanguageSwitcherOption[] = [
  {
    key: 'fr',
    label: 'Français (France)',
    value: 'fr',
  },
  {
    key: 'en',
    label: 'English',
    value: 'en',
  },
  {
    key: 'es',
    label: 'Español',
    value: 'es',
  },
];
