import React from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { radioButtonTheme } from '@app/ui/atoms/RadioButton/radioButton.theme';
import { Text } from '@app/ui/atoms/Text/Text';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';

import { RadioButton } from './RadioButton';
import { RadioContext } from './RadioGroup';
import type { RadioButtonProps, RadioItemProps } from '../RadioButton.types';
import { radioItemStyles } from '../styles';

export const RadioItem: React.FC<RadioItemProps> = ({
  checked,
  disabled = false,
  label,
  value,
  testID,
  textStyle,
  error = false,
  size = 'l',
  errorMessage,
}) => {
  const radioButtonProps: RadioButtonProps = { checked, disabled };
  const componentTheme = useComponentTheme('radioButton', radioButtonTheme);

  const theme = useTheme();

  const themedRadioStyleItems = radioItemStyles(theme, error, componentTheme, size, disabled);

  return (
    <RadioContext.Consumer>
      {({ onValueChange }) => (
        <>
          <Pressable
            testID={testID}
            onPress={() => {
              onValueChange(value);
            }}
            disabled={disabled}
            style={themedRadioStyleItems.container}
          >
            <RadioButton {...radioButtonProps} onPress={() => onValueChange(value)} />
            <Text textStyle="Body2" style={[themedRadioStyleItems.label, textStyle]}>
              {label}
            </Text>
          </Pressable>
          {error ? <Text style={themedRadioStyleItems.errorLabel}>{errorMessage}</Text> : null}
        </>
      )}
    </RadioContext.Consumer>
  );
};
