import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgPg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="pg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#pg_svg__a)">
        <Path fill="#262626" d="M0 0h28v20H0z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 0h28v20L0 0z" fill="#E8283F" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.667 16.667l-.943.276L6 16l-.276-.943.943.276.942-.276-.276.943.276.943-.942-.276zM6.667 8.667l-.943.276L6 8l-.276-.943.943.276.942-.276L7.333 8l.276.943-.942-.276zM3.333 12l-.942.276.276-.943-.276-.943.942.277.943-.277-.276.943.276.943L3.333 12zM9.333 11.333l-.942.277.276-.943-.276-.943.942.276.943-.276-.276.943.276.943-.943-.277z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.861 9.266a.653.653 0 01-.289-.502V7.535c0-.181-.13-.285-.328-.22l-.758.254a1.16 1.16 0 01-.656 0l-.758-.253c-.181-.06-.201-.185-.04-.283l1.78-1.067a.43.43 0 00.18-.502l-.253-.758c-.06-.181.02-.284.218-.219l.759.253a.442.442 0 00.501-.18l1.067-1.778c.096-.16.217-.159.283.039l.253.758c.06.18.066.458 0 .656l-.253.758c-.06.181.039.328.22.328h1.228c.181 0 .404.127.502.289l1.067 1.779c.096.16.019.32-.154.354l-1.702.34a.552.552 0 00-.393.394l-.34 1.702c-.036.18-.193.251-.355.154l-1.779-1.067z"
          fill="#FFD84E"
        />
      </G>
    </Svg>
  );
};

export default SvgPg;
