import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tt_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tt_svg__a)">
        <Path fill="#ED233C" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.469 25.42L-.063.951 7.531-5.42l20.532 24.468-7.594 6.373z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.874 26.953L-.981-2.667l5.107-4.286 24.855 29.62-5.107 4.286z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgTt;
