import React, { useMemo } from 'react';

import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { Button, Input, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { useChangePasswordForm } from '@app/features/profile/hooks/useChangePasswordForm.hook';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      flex: 1,
      width: '100%',
    },
    content: {
      gap: theme.spacing.SIZE_07,
      maxWidth: 360,
      width: '100%',
    },
    inputs: {
      gap: theme.spacing.SIZE_04,
    },
  });

export const ProfilePassword: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const { setValidMsg } = useToastContext();

  const { control, isSaving, onSubmit } = useChangePasswordForm(() => {
    setValidMsg({ timeout: 3000, title: t('profile.updatePasswordMessage') });
  });

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.inputs}>
          <Controller
            control={control}
            name="currentPassword"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={t('profile.currentPassword')}
                onChangeText={onChange}
                value={value}
                error={error?.message}
                autoCapitalize="none"
                isPassword
              />
            )}
          />

          <Controller
            control={control}
            name="newPassword"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={t('shared.newPassword')}
                onChangeText={onChange}
                value={value}
                error={error?.message}
                autoCapitalize="none"
                isPassword
              />
            )}
          />

          <Controller
            control={control}
            name="newPasswordConfirmation"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={t('profile.newPasswordConfirmation')}
                onChangeText={onChange}
                value={value}
                error={error?.message}
                autoCapitalize="none"
                isPassword
              />
            )}
          />
        </View>

        <Button variant="primary" isLoading={isSaving} onPress={onSubmit} style={{ width: '100%' }}>
          {t('shared.save')}
        </Button>
      </View>
    </View>
  );
};
