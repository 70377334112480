import { ColorValue } from 'react-native';

import { Ges } from '@app/libs/apollo/introspection';

export const gesColors: Record<Ges, ColorValue> = {
  [Ges.A]: '#63C7FF',
  [Ges.B]: '#2EB4FF',
  [Ges.C]: '#00A0FA',
  [Ges.D]: '#4C7993',
  [Ges.E]: '#919191',
  [Ges.F]: '#5E5E5E',
  [Ges.G]: '#454545',
  [Ges.NoDiag]: '',
};
