// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { StyleSheet } from 'react-native';

export const DiamondContainer = styled.div<{
  diamondX?: number;
  diamondY?: number;
}>`
  display: flex;
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: ${({ diamondX }) => diamondX}px;
  top: ${({ diamondY }) => diamondY}px;
`;

export const Wrapper = styled.div<{
  visible: boolean;
  position: {
    x: number;
    y: number;
  };
  isInLight?: boolean;
  renderAsTooltip?: boolean;
}>`
  display: flex;
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.popover};
  left: ${({ position: p }) => `${p.x}px`};
  top: ${({ position: p }) => `${p.y}px`};
  animation: ${p => (p.visible ? '100ms scaleIn' : '')};
  background-color: ${({ theme, isInLight }) =>
    isInLight ? theme.palette.base.white : theme.palette.alert.info};
  border-radius: ${({ theme, renderAsTooltip }) =>
    renderAsTooltip ? theme.borderRadius.SIZE_04 : theme.borderRadius.SIZE_05}px;
  padding: ${({ theme, renderAsTooltip }) => (renderAsTooltip ? 7 : theme.spacing.SIZE_05)}px
    ${({ theme }) => theme.spacing.SIZE_05}px;
  box-shadow: 0px 1px 32px rgba(25, 25, 26, 0.15);

  @keyframes scaleIn {
    0% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ContentContainer = styled.div<{ renderAsTooltip?: boolean }>`
  max-width: ${({ renderAsTooltip }) => (renderAsTooltip ? 'auto' : '300px')};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const getStyles = ({ theme, isInLight }: { theme: Theme; isInLight?: boolean }) =>
  StyleSheet.create({
    title: {
      color: isInLight ? theme.palette.neutral[900] : theme.palette.base.white,
    },
  });
