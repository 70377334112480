import React, { useContext } from 'react';

import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Button, Icon, IconName, Spacer, Text, useResponsive, UseResponsiveValue } from '@app/ui';
import { DocumentCategory } from '@app/libs/apollo/introspection';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';

export enum PositioningRequiredDocuments {
  FinancingCertificate = DocumentCategory.FinancingCertificate,
  SearchMandate = DocumentCategory.SearchMandate,
}

const MAP_DOCUMENT_ICON: Record<PositioningRequiredDocuments, IconName> = {
  [PositioningRequiredDocuments.FinancingCertificate]: 'Feather',
  [PositioningRequiredDocuments.SearchMandate]: 'SearchProperty',
};

type MissingDocumentModalProps = {
  documentList: PositioningRequiredDocuments[];
};

const getStyles = ({ isLowerThanLaptop }: Partial<UseResponsiveValue>) =>
  StyleSheet.create({
    button: {
      width: isLowerThanLaptop ? '100%' : 'unset',
    },
    buttonContainer: {
      justifyContent: 'center',
      width: '100%',
    },
    documentListItem: { flexDirection: 'row' },
  });

/**
 * feat(positioning) This modal is shown after confirming the positioning if some documents are missing.
 */
export const MissingDocumentModal: React.FC<MissingDocumentModalProps> = ({ documentList }) => {
  const { t } = useTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles({ isLowerThanLaptop });
  const { closeModal } = useContext(ModalContext);

  return (
    <View>
      <Text>{t('positioning.missingDocumentModal.body')}</Text>
      <Spacer height={24} />
      {documentList.map((documentCategory: PositioningRequiredDocuments) => (
        <View key={documentCategory}>
          <View style={styles.documentListItem} key={documentCategory}>
            <View>
              <Icon name={MAP_DOCUMENT_ICON[documentCategory]} />
            </View>
            <Spacer width={10} />
            <View>
              <Text textStyle="Body2Medium">
                {t(`shared.documentCategory.${documentCategory}`)}
              </Text>
            </View>
          </View>

          <Spacer height={24} separator />
        </View>
      ))}
      <Spacer height={16} />
      <View style={styles.buttonContainer}>
        <Button onPress={closeModal} wrapperStyle={styles.button}>
          {t('positioning.missingDocument.confirmButton')}
        </Button>
      </View>
    </View>
  );
};
