import { useMemo, useState } from 'react';

import { useMe } from '@app/shared/hooks/useMe';
import { NpsCategory } from '@app/libs/apollo/introspection';

interface NPSDisplay {
  onClose: () => void;
  isVisible: boolean;
  category?: NpsCategory | null;
}

export const useNPSDisplay = (): NPSDisplay => {
  const { customer } = useMe();
  const [isClosed, setIsClosed] = useState(false);

  const isVisible = useMemo(() => {
    return !!customer.NPS && !isClosed;
  }, [customer, isClosed]);

  const onClose = () => setIsClosed(true);

  return { category: customer.NPS, isVisible, onClose };
};
