import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgDemoliton = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.007 2.383c-.171 1.456-.278 3.815.66 5.848.48 1.04 1.236 2.003 2.385 2.696.729.439 1.588.754 2.591.925a8 8 0 101.132-1.877c-1.182-.084-2.05-.375-2.69-.761-.76-.458-1.265-1.09-1.602-1.82-.695-1.509-.652-3.4-.49-4.777l-1.986-.234zM9 15a6 6 0 1112 0 6 6 0 01-12 0zM22 2a1 1 0 011 1v4.5a1 1 0 11-2 0V3a1 1 0 011-1zM15.293 2.293a1 1 0 011.414 0l2.5 2.5a1 1 0 01-1.414 1.414l-2.5-2.5a1 1 0 010-1.414zM2 22a1 1 0 011-1h4a1 1 0 110 2H3a1 1 0 01-1-1zM2.293 15.793a1 1 0 011.414 0l2.5 2.5a1 1 0 11-1.414 1.414l-2.5-2.5a1 1 0 010-1.414z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgDemoliton;
