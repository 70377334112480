import { ApolloQueryResult } from '@apollo/client';

import { EmailExistDocument, EmailExistQuery, Provider } from '@app/libs/apollo/introspection';
import { getApolloClient } from '@app/libs/apollon/apollonClient';

const emailCache: Map<string, boolean> = new Map();

/**
 * Check if the email already exists
 * @param email - The email to check
 * @returns False if the email is already registered
 */
export const checkIfEmailExists = async (email = ''): Promise<boolean> => {
  if (!emailCache.has(email)) {
    const { data }: ApolloQueryResult<EmailExistQuery> = await getApolloClient().query({
      fetchPolicy: 'network-only',
      query: EmailExistDocument,
      variables: {
        email: email.toLowerCase().trim(),
      },
    });

    emailCache.set(email, data?.emailExist);
  }

  return !emailCache.get(email);
};

/**
 * Reset the cache for emailExists
 */
export const flushCacheForEmailExists = (): void => {
  emailCache.clear();
};

/**
 * Check if this method is an SSO method
 */
export const isSSOMethod = (method): method is Provider => {
  return Object.values(Provider).includes(method as Provider);
};
