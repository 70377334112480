import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps, NumberProp } from 'react-native-svg';

export { FlagName } from './types';
import { FlagName } from './types';
import * as Flags from './elements';

export interface FlagProps extends SvgProps {
  /**
   * Name of the flag you want to show
   * @type {FlagName}
   * @memberof FlagProps
   */
  name: FlagName;
  /**
   * Size of the icon
   * @type {number}
   * @memberof SvgProps
   */
  size?: NumberProp;
  /**
   * Style of the icon
   * @type {StyleProp<ViewStyle>}
   * @memberof SvgProps
   */
  style?: StyleProp<ViewStyle>;
}

export const Flag: React.FC<FlagProps> = ({ name, size = 24, ...svgProps }) => {
  const MyFlag = Flags[name];

  if (!MyFlag) {
    return null;
  }

  return <MyFlag width={size} height={size} {...svgProps} />;
};
