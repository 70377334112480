import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCh = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ch_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ch_svg__a)">
        <Path fill="red" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12H8.333A.333.333 0 018 11.667V8.333C8 8.15 8.15 8 8.333 8H12V4.333c0-.184.15-.333.333-.333h3.334c.184 0 .333.15.333.333V8h3.667c.184 0 .333.15.333.333v3.334c0 .184-.15.333-.333.333H16v3.667c0 .184-.15.333-.333.333h-3.334a.333.333 0 01-.333-.333V12z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgCh;
