import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mr_svg__a)">
        <Path fill="#108B4D" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.814 7.733a6 6 0 11-11.852 0 6.001 6.001 0 0011.852 0zM14 8.564L12.352 9.74l.608-1.93-1.627-1.205 2.024-.018.643-1.92.643 1.92 2.024.018-1.627 1.204.608 1.931L14 8.564z"
          fill="#FFC941"
        />
      </G>
    </Svg>
  );
};

export default SvgMr;
