import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMu = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mu_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mu_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-4H0v4z" fill="#1DBB63" />
        <Path d="M0 10.667h28V5.333H0v5.334z" fill="#222B82" />
        <Path d="M0 16h28v-5.333H0V16z" fill="#FFD741" />
        <Path d="M0 5.333h28V0H0v5.333z" fill="#F4374A" />
      </G>
    </Svg>
  );
};

export default SvgMu;
