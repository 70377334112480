import React, { useCallback } from 'react';

import { GestureResponderEvent, View } from 'react-native';

import { Button } from '@app/ui/molecules/Button';
import { useTheme } from '@app/ui/contexts/config';

import { getStyles } from './DropdownFooterActions.styles';
import { DropdownFooterActionsProps } from './DropdownFooterActions.types';

export { DropdownFooterActionsProps };

export const DropdownFooterActions: React.FC<DropdownFooterActionsProps> = ({
  containerStyle,
  onGhostClick,
  onPrimaryClick,
  buttonLabel,
  closeList,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const onPressGhost = useCallback(
    (ev: GestureResponderEvent | React.MouseEvent<HTMLButtonElement>) => {
      ev?.stopPropagation();
      onGhostClick?.(closeList);
    },
    [closeList, onGhostClick]
  );

  const onPressPrimary = useCallback(
    (ev: GestureResponderEvent | React.MouseEvent<HTMLButtonElement>) => {
      ev?.stopPropagation();
      onPrimaryClick?.(closeList);
    },
    [closeList, onPrimaryClick]
  );

  return (
    <View style={[styles.container, containerStyle]} testID="dropdown-footer-actions-container">
      <View style={styles.buttonClear}>
        <Button
          variant="ghost"
          size="s"
          onPress={onPressGhost}
          testID="dropdown-footer-actions-button-ghost"
        >
          {buttonLabel.ghost}
        </Button>
      </View>
      <View style={styles.buttonSend}>
        <Button
          size="s"
          onPress={onPressPrimary}
          disabled={!onPrimaryClick}
          testID="dropdown-footer-actions-button-primary"
        >
          {buttonLabel.primary}
        </Button>
      </View>
    </View>
  );
};
