import React from 'react';

import { Controller, useForm, useFormContext } from 'react-hook-form';

import { useTranslation } from '@app/services/translations/translations';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { RoomCountFilter } from '@app/libs/apollo/introspection';
import { Checkbox, CheckboxGroup } from '@app/ui';

import { FilterFormContainer } from './FilterFormContainer';
import { FilterForm } from '../Filters.type';
import { getRoomList } from '../../utils/get-room-list';
import { handleCheckboxChange } from '../utils/filterForm.utils';
import { useCountResults } from '../hooks/useCountResults.hook';

interface RoomCountForm {
  roomCount: RoomCountFilter[];
}

export const RoomCountFilterForm: React.FC<FilterForm> = ({ goBack }) => {
  const { t } = useTranslation();

  const { getValues: getGlobalValues, setValue } = useFormContext<SearchEngineFilterForm>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm<RoomCountForm>({
    mode: 'onChange',
    values: { roomCount: getGlobalValues('roomCount') },
  });

  const onApply = (form: RoomCountForm) => {
    setValue('roomCount', form.roomCount);
    goBack();
  };

  const onReset = () => {
    reset();
  };

  const { count, loading } = useCountResults(watch());

  const roomList = getRoomList(t);

  return (
    <FilterFormContainer
      title={t('realEstate.filters.roomCount.title')}
      canApply={isValid}
      resultCount={count}
      loading={loading}
      onApply={handleSubmit(onApply)}
      onBack={goBack}
      onReset={onReset}
    >
      <Controller
        name="roomCount"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup inline>
            {Object.values(roomList).map(({ label, roomCount }) => (
              <Checkbox
                testID={`search-engine-filter-${roomCount}--checkbox`}
                key={roomCount}
                checked={value.includes(roomCount)}
                label={label}
                onChange={handleCheckboxChange(onChange, value, roomCount)}
              />
            ))}
          </CheckboxGroup>
        )}
      />
    </FilterFormContainer>
  );
};
