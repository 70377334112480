import React from 'react';

import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Icon, Spacer, Text, useTheme } from '@app/ui';

const styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 12,
  },
});
export const DiscussModalTitle = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <View style={styles.view}>
      <Icon name="Calendar" />

      <Text testID="ModalTitle" textStyle="Title3Medium">
        {t('discuss.takeAppointment.title')}
      </Text>
      <Spacer height={theme.spacing.SIZE_04} />
    </View>
  );
};
