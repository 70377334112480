import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgFlat = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M6 21a.967.967 0 0 1-.713-.288A.968.968 0 0 1 5 20V4c0-.283.096-.52.287-.712A.968.968 0 0 1 6 3h12c.283 0 .52.096.712.288.192.191.288.429.288.712v16c0 .283-.096.52-.288.712A.968.968 0 0 1 18 21h-5v-4h-2v4H6Zm1-2h2v-4h6v4h2V5H7v14Zm2-6h2v-2H9v2Zm0-4h2V7H9v2Zm4 4h2v-2h-2v2Zm0-4h2V7h-2v2Z"
    />
  </Svg>
);
export default SvgFlat;
