import React, { useCallback, useMemo } from 'react';

import { ActivityIndicator, StyleProp, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Button, palette, useResponsive } from '@app/ui';
import { SearchEngineListType } from '@app/features/search-engine/compounds/search-engine-list/searchEngineList.type';
import { useColumnsNb } from '@app/shared/hooks/useColumnsNb';
import { getStyle } from '@app/features/search-engine/compounds/search-engine-list/searchEngineListWeb.styles';
// eslint-disable-next-line max-len
import { SearchEngineNoResult } from '@app/features/search-engine/compounds/search-engine-no-result/SearchEngineNoResult';
import './searchEngineListWeb.css';
// eslint-disable-next-line max-len
import { RealEstatePreviewCardVertical } from '@app/shared/components/real-estate-preview-card/RealEstatePreviewCardVertical';
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';

import { SoldRealEstateCard } from '../sold-real-estate-card/SoldRealEstateCard';
import { isSoldRealEstateItem } from '../../utils/isSoldRealEstateItem.utils';
import { SearchEngineItem } from '../../searchEngine.types';
import { SearchEngineMoreResults } from '../search-engine-more-results/SearchEngineMoreResults';

export const SearchEngineList: React.FC<SearchEngineListType> = ({
  list,
  section,
  hasMore,
  loading,
  withHigherBudgetList,
  withNearbyList,
  onItemBookmark,
  onItemClick,
  onFilterShow,
  onFilterReset,
  onLoadMoreRealEstates,
}) => {
  const { t } = useTranslation();
  const { isLowerThanTablet } = useResponsive();

  const columnsNb = useColumnsNb();

  const styles = getStyle({ columnsNb, isLowerThanTablet });

  const handleItemClick = useCallback(
    (item: SearchEngineItem, pos: number) => () => onItemClick(item, { position: pos }),
    [onItemClick]
  );

  const handleItemBookmark = useCallback(
    (item: RealEstatePreviewItem) => () => onItemBookmark(item),
    [onItemBookmark]
  );

  const renderList = useMemo(() => {
    return list.map((item: SearchEngineItem, index) => {
      if (isSoldRealEstateItem(item)) {
        return (
          <SoldRealEstateCard
            key={item.key}
            soldRealEstate={item}
            onClick={handleItemClick(item, index)}
          />
        );
      }

      return (
        <RealEstatePreviewCardVertical
          realEstatePreview={item}
          key={item.key}
          onBookmark={handleItemBookmark(item)}
          onPress={handleItemClick(item, index)}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const renderLoader = useMemo(() => {
    return (
      <div style={styles.loaderContainer}>
        <ActivityIndicator size="large" color={palette.neutral[500]} />
      </div>
    );
  }, [styles.loaderContainer]);

  if (loading && list.length === 0) {
    return renderLoader;
  }

  const LoadMore = (
    <Button onPress={onLoadMoreRealEstates} wrapperStyle={styles.seeMoreButton} variant="tertiary">
      {t('shared.seeMore')}
    </Button>
  );

  const MoreResults = (
    <SearchEngineMoreResults
      withHigherBudgetList={withHigherBudgetList}
      withNearbyList={withNearbyList}
      onItemBookmark={onItemBookmark}
      onItemClick={onItemClick}
    />
  );

  const Footer = hasMore ? LoadMore : MoreResults;

  return (
    <div style={styles.container}>
      {!list?.length && (
        <SearchEngineNoResult
          section={section}
          onFilterShow={onFilterShow}
          onFilterReset={onFilterReset}
        />
      )}

      <View style={styles.wrapper as StyleProp<ViewStyle>}>{renderList}</View>

      <View style={styles.seeMoreWrapper as StyleProp<ViewStyle>}>
        {!!loading && renderLoader}
        {!loading && !!onLoadMoreRealEstates && Footer}
      </View>
    </div>
  );
};
