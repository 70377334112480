import React from 'react';

import { View } from 'react-native';

import { Input } from '@app/ui/atoms/input/Input';
import { useTheme } from '@app/ui/contexts/config';

import { getStyles } from './SearchInput.styles';
import { SearchInputProps } from './SearchInput.types';

export { SearchInputProps };

export const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onSearch, value }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.inputContainer} testID="Dropdown-search">
      <Input
        isSearch
        placeholder={placeholder}
        onChangeText={onSearch}
        value={value}
        inputWrapperStyle={styles.inputWrapperStyle}
        wrapperStyle={styles.wrapperStyle}
        testID="search-input"
      />
    </View>
  );
};
