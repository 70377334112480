import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    leftBlock: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
    },
    rightBlock: {
      alignItems: 'flex-end',
    },
    valueText: {
      color: theme.palette.neutral[900],
    },
  });
