import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCameraOff = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M19.775 22.575L18.175 21H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 19V7c0-.3.058-.58.175-.838.117-.258.283-.479.5-.662l-1.3-1.3c-.2-.2-.3-.438-.3-.713 0-.275.1-.512.3-.712.2-.2.437-.3.712-.3.275 0 .513.1.713.3l18.4 18.4c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.974.974 0 01-.713-.3zM4 19h12.175l-2.025-2.025a3.648 3.648 0 01-1.025.4A5.063 5.063 0 0112 17.5c-1.25 0-2.313-.437-3.188-1.312S7.5 14.25 7.5 13c0-.383.042-.758.125-1.125.083-.367.217-.708.4-1.025L4.175 7H4v12zm18 .125l-2-2V7H9.875L7.5 4.625l.9-.975A2.01 2.01 0 019.875 3h4.25a2.01 2.01 0 011.475.65L16.85 5H20c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12.125zM16.475 13.6L11.4 8.525c.1-.017.2-.025.3-.025h.3c1.25 0 2.313.437 3.188 1.312S16.5 11.75 16.5 13v.3c0 .1-.008.2-.025.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCameraOff;
