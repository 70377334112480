import React, { useContext } from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { radioButtonTheme } from '@app/ui/atoms/RadioButton/radioButton.theme';
import { Icon } from '@app/ui/atoms/Icon';
import { getRadioColor } from '@app/ui/atoms/RadioButton/utils';

import { RadioContext } from './RadioGroup';
import { RadioButtonProps } from '../RadioButton.types';
import { RadioContainer, RadioInput } from './RadioButton.styles';

export const RadioButton: React.FC<RadioButtonProps> = ({
  onPress = () => {},
  checked = false,
  disabled = false,
  value,
  id,
  testID,
  error = false,
  size = 'l',
}) => {
  const { onValueChange } = useContext(RadioContext);
  const commonProps = {
    checked,
    disabled,
    error,
  };

  const componentTheme = useComponentTheme('radioButton', radioButtonTheme);

  const radioColor = getRadioColor(checked, !!disabled, !!error, componentTheme);
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onPress?.(!checked);
  };

  const handleChange = () => {
    onValueChange(value || '');
  };

  return (
    <RadioContainer
      componentTheme={componentTheme}
      {...commonProps}
      size={size}
      onClick={handleClick}
    >
      {checked ? (
        <Icon name="RadioButtonOn" size={componentTheme.size.outerSize[size]} color={radioColor} />
      ) : (
        <Icon name="RadioButtonOff" size={componentTheme.size.outerSize[size]} color={radioColor} />
      )}
      <RadioInput
        componentTheme={componentTheme}
        data-testid={testID}
        type="radio"
        value={value}
        id={id}
        onChange={handleChange}
        {...commonProps}
      />
    </RadioContainer>
  );
};
