import React from 'react';

import { Text, View } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { Checkbox } from '@app/ui/atoms/checkbox';
import { Avatar } from '@app/ui/atoms/Avatar/Avatar';
import { Flag } from '@app/ui/atoms/Flag/Flag';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';

import { ListItemUnionProps } from './List.types';
import { getStyles, getTextAndIconColor } from './ListItem.styles';

/**
 * TODO MOVE THIS IN A FOLDER IN atoms/list-items/
 */
export const ListItem: React.FC<ListItemUnionProps> = ({
  onPress,
  label,
  description,
  disabled,
  height,
  numberOfLines = 1,
  avatarUrl,
  iconName,
  iconColor,
  flagName,
  checkbox,
  checked,
  hasRightArrow,
  isLastItem,
  inline = false,
}) => {
  const theme = useTheme();
  const hasAddon = [avatarUrl, iconName, flagName].some(e => e);

  return (
    <Pressable disabled={disabled} onPress={onPress} testID="ListItem" withPressOpacity={false}>
      {({ pressed, hovered }) => {
        const accentColor = getTextAndIconColor({
          disabled,
          hovered,
          pressed,
          theme,
        });

        const styles = getStyles({
          disabled,
          hasAddon,
          height,
          hovered,
          inline,
          isLastItem,
          pressed,
          selected: checked && !checkbox,
          theme,
        });

        return (
          <View style={styles.container} testID="list-item-container">
            <View style={styles.subContainer}>
              <View style={styles.labelContainer}>
                {!!checkbox && (
                  <Checkbox
                    checked={checked}
                    onChange={() => onPress?.()}
                    testID="ListItem-checkbox"
                    style={{ marginRight: theme.spacing.SIZE_03 }}
                  />
                )}
                {!!iconName && (
                  <Icon
                    name={iconName}
                    size={theme.spacing.SIZE_06}
                    color={iconColor || accentColor}
                    testID="ListItem-icon"
                  />
                )}
                {!!flagName && (
                  <Flag
                    name={flagName}
                    size={theme.spacing.SIZE_06}
                    style={styles.flag}
                    testID="ListItem-flag"
                  />
                )}
                {!!avatarUrl && (
                  <Avatar
                    size={theme.spacing.SIZE_09}
                    avatarUrl={avatarUrl}
                    style={styles.avatar}
                    onPress={onPress}
                    testID="ListItem-avatar"
                  />
                )}
                <Text style={styles.labelText}>{label}</Text>
              </View>
              {description ? (
                <Text
                  testID="list-item-description"
                  numberOfLines={numberOfLines === -1 ? undefined : numberOfLines}
                  style={styles.description}
                >
                  {description}
                </Text>
              ) : null}
            </View>
            {hasRightArrow ? (
              <Icon
                name="ChevronRight"
                size={theme.spacing.SIZE_06}
                color={accentColor}
                style={styles.rightIcon}
                testID="ListItem-rightIcon"
              />
            ) : null}
          </View>
        );
      }}
    </Pressable>
  );
};
