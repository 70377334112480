import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChevronLeft = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M13.954 17.704l-5.618-4.95a.937.937 0 01-.26-.35A1.057 1.057 0 018 12c0-.143.026-.278.077-.404a.937.937 0 01.259-.35l5.618-4.95c.224-.197.51-.296.855-.296.346 0 .631.099.855.296a.96.96 0 01.336.753.96.96 0 01-.336.754L10.901 12l4.763 4.197a.96.96 0 01.336.754.96.96 0 01-.336.753c-.224.197-.509.296-.855.296s-.63-.099-.855-.296z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChevronLeft;
