import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyle = ({ theme, isMobile }: { theme: Theme; isMobile: boolean }) =>
  StyleSheet.create({
    container: {
      borderRadius: theme.borderRadius.SIZE_05,
    },
    contentContainer: {
      paddingBottom: theme.spacing.SIZE_07,
      paddingHorizontal: isMobile ? theme.spacing.SIZE_06 : theme.spacing.SIZE_07,
    },
  });
