import React from 'react';

import styled from '@emotion/styled';
import Svg, { Defs, RadialGradient, Stop } from 'react-native-svg';

import { getColorWithOpacity } from '@app/ui/theme/palette';
import { useTheme } from '@app/ui/contexts/config';

import { LoaderProps } from '../Loader.types';

const Wrapper = styled.p`
  display: flex;
  align-items: center;

  @keyframes rotate {
    from {
      transform: rotate(105deg);
    }
    to {
      transform: rotate(465deg);
    }
  }
  .rotating {
    animation: rotate 1500ms linear infinite;
    transform-origin: 12px 12px;
  }
`;

export const LoaderDefault: React.FC<LoaderProps> = ({ variant }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM5.5 12C5.5 15.5898 8.41015 18.5 12 18.5C15.5898 18.5 18.5 15.5898 18.5 12C18.5 8.41015 15.5898 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12Z"
          fill={
            variant === 'inLight'
              ? getColorWithOpacity(theme.palette.neutral[900], 10)
              : getColorWithOpacity(theme.palette.base.white, 20)
          }
        />
        <path
          className="rotating"
          d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.76121C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12L18.5 12C18.5 11.1464 18.3319 10.3012 18.0052 9.51256C17.6786 8.72394 17.1998 8.00739 16.5962 7.40381C15.9926 6.80023 15.2761 6.32144 14.4874 5.99478C13.6988 5.66813 12.8536 5.5 12 5.5L12 2Z"
          fill={variant === 'inLight' ? 'url(#gradient-light)' : 'url(#gradient-dark)'}
        />
        <Defs>
          <RadialGradient
            id="gradient-light"
            cx="-5%"
            cy="3%"
            r="2.75"
            gradientTransform="scale(4)"
          >
            <Stop offset="0.03" stopOpacity="0" />
            <Stop offset="0.05" stopOpacity="0.1" />
            <Stop offset="0.14" stopOpacity="1" />
          </RadialGradient>
          <RadialGradient id="gradient-dark" cx="-5%" cy="3%" r="2.75" gradientTransform="scale(4)">
            <Stop offset="0.02" stopOpacity="0" stopColor={theme.palette.base.white} />
            <Stop offset="0.04" stopOpacity="0.1" stopColor={theme.palette.base.white} />
            <Stop offset="0.14" stopOpacity="1" stopColor={theme.palette.base.white} />
          </RadialGradient>
        </Defs>
      </Svg>
    </Wrapper>
  );
};
