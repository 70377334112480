import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgKe = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ke_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ke_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 5.333h28V0H0v5.333z" fill="#262626" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-5.333H0V20z" fill="#018301" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 14.667h28V5.333H0v9.334z" fill="#fff" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.333h28V6.667H0v6.666z"
          fill="#DC0808"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 16.667c1.333 0 3.333-2.617 3.333-6.667s-2-6.667-3.333-6.667c-1.333 0-3.333 2.617-3.333 6.667s2 6.667 3.333 6.667z"
          fill="#BC0000"
        />
        <Mask
          id="ke_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={10}
          y={3}
          width={8}
          height={14}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 16.667c1.333 0 3.333-2.617 3.333-6.667s-2-6.667-3.333-6.667c-1.333 0-3.333 2.617-3.333 6.667s2 6.667 3.333 6.667z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#ke_svg__b)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.667 17.333C10.507 17.333 12 14.05 12 10c0-4.05-1.492-7.333-3.333-7.333-1.841 0-3.334 3.283-3.334 7.333 0 4.05 1.493 7.333 3.334 7.333zM19.333 17.333c1.841 0 3.334-3.283 3.334-7.333 0-4.05-1.493-7.333-3.334-7.333C17.493 2.667 16 5.95 16 10c0 4.05 1.492 7.333 3.333 7.333z"
            fill="#262626"
          />
          <G filter="url(#ke_svg__filter0_d_4223_11178)">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 8c.368 0 .667-1.194.667-2.667 0-1.472-.299-2.666-.667-2.666-.368 0-.667 1.194-.667 2.666C13.333 6.806 13.632 8 14 8zm0 4c.368 0 .667-.895.667-2S14.368 8 14 8c-.368 0-.667.895-.667 2s.299 2 .667 2zm0 0c-.368 0-.667 1.194-.667 2.667 0 1.472.299 2.666.667 2.666.368 0 .667-1.194.667-2.666 0-1.473-.299-2.667-.667-2.667z"
              fill="url(#ke_svg__paint0_linear_4223_11178)"
            />
          </G>
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="ke_svg__paint0_linear_4223_11178"
          x1={13.333}
          y1={2.667}
          x2={13.333}
          y2={17.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset={1} stopColor="#F0F0F0" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgKe;
