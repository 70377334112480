import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

import { Measures } from './DumbTooltip.types';

const arrowSize = 17;
const snackBarWidth = 100;

export const getStyles = (measures: Measures, theme: Theme) =>
  StyleSheet.create({
    snackBarArrow: {
      backgroundColor: theme.palette.base.black,
      borderRadius: 2,
      height: arrowSize,
      left: (measures.innerElementWidth - arrowSize) / 2,
      position: 'absolute',
      transform: [{ rotate: '45deg' }],
      width: arrowSize,
    },
    snackBarContainer: {
      position: 'absolute',
      top: measures.innerElementHeight + theme.spacing.SIZE_03,
      zIndex: 1001,
    },
    snackBarContent: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.black,
      borderRadius: theme.borderRadius.SIZE_02,
      height: 40,
      justifyContent: 'center',
      left: -((snackBarWidth - measures.innerElementWidth) / 2 + measures.offsetRight),
      paddingHorizontal: theme.spacing.SIZE_04,
      paddingVertical: theme.spacing.SIZE_04,
      position: 'absolute',
      top: arrowSize / 2,
      width: 100,
    },
    wrapper: {
      position: 'relative',
    },
  });
