import React, { useCallback } from 'react';

import { View } from 'react-native';

import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { ListItem } from '@app/ui/atoms/list/ListItem';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { AvatarActivator } from '@app/ui/molecules/dropdown-activator/avatar-activator/AvatarActivator';

import { AvatarSelectorProps, AvatarSelectorOptionType } from './AvatarSelector.types';
import { getStyles } from './AvatarSelector.styles';

export { AvatarSelectorProps, AvatarSelectorOptionType };

export const AvatarSelector: React.FC<AvatarSelectorProps> = ({
  options,
  maxHeight = 310,
  minWidth = 296,
  avatarUrl,
  onCallbackClose,
  ...rest
}) => {
  const styles = getStyles();

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="filter-list-selector-list-container">
          {options.map(({ onSelect, ...restItem }) => {
            const onPress = () => {
              onSelect(closeList);
            };

            return <ListItem {...restItem} onPress={onPress} key={restItem.label.toLowerCase()} />;
          })}
        </View>
      );
    },
    [options, styles.listContainer]
  );

  const renderActivator = useCallback(
    ({ toggleList }: RenderActivatorDisplayType) => {
      return <AvatarActivator onPress={toggleList} avatarUrl={avatarUrl} />;
    },
    [avatarUrl]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => {
      return <DropdownListContainer list={getList(closeList)} maxHeight={maxHeightList} />;
    },
    [getList]
  );

  return (
    <DropdownV2
      {...rest}
      renderList={renderList}
      maxHeight={maxHeight}
      minWidth={minWidth}
      fitParent={false}
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};
