import { SliderImageObject } from '@app/features/realEstate/gallery/gallery.types';
import { Scalars, SoldRealEstateV2 } from '@app/libs/apollo/introspection';

const sliderImageObjectAdaptor = (
  images: Scalars['String'][],
  title: string
): SliderImageObject[] => {
  return (
    images?.map(imageUrl => ({
      title,
      url: imageUrl,
    })) ?? []
  );
};

export const getHeaderSliderImages = (property: SoldRealEstateV2): SliderImageObject[] => {
  if (!property) {
    return [];
  }

  const cover = { title: 'coverImage', url: property.coverImage };
  const beforeRenovation = sliderImageObjectAdaptor(
    property.beforeRenovation.images,
    'property.beforeWork'
  );
  const afterRenovation = sliderImageObjectAdaptor(
    property.afterRenovation.images,
    'property.afterWork'
  );

  return [cover, ...afterRenovation, ...beforeRenovation];
};
