import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBd = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bd_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bd_svg__a)">
        <Path fill="#128363" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.667 16a6 6 0 100-12 6 6 0 000 12z"
          fill="#F23C53"
        />
      </G>
    </Svg>
  );
};

export default SvgBd;
