import React, { useState } from 'react';

import { Modal, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { capitalize } from 'lodash';

import { Icon, IconPin, Pressable, Spacing, Text, palette, useTheme } from '@app/ui';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { realEstateToFields, usePropertyTitle } from '@app/shared/hooks/usePropertyTitle';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { GeoMap } from '@app/features/realEstate/realEstateAvailable/realEstateMap/components/GeoMap';
import { kmConverter } from '@app/utils/kmConverter';
import { RealEstateMapInfo } from '@app/features/realEstate/realEstateAvailable/realEstateMap/components/RealEstateMapInfo';

import { getStyles } from './RealEstateMap.styles';

type RealEstateMapProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstateMap
>;

export const RealEstateMap: React.FC<RealEstateMapProps> = ({ route, navigation }) => {
  const { property: realEstate } = route.params;
  const { t } = useRealEstateTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const address = t('propertyInfo.address', {
    city: realEstate?.building.address?.city,
    country: realEstate?.building.address?.country,
    postalCode: realEstate?.building.address?.postalCode,
  });
  const propertyTitle = usePropertyTitle(realEstateToFields(realEstate));

  const onBackPress = () => {
    navigation.goBack();
  };

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [selectedPointOfInterestIndex, setSelectedPointOfInterestIndex] = useState<number | null>(
    null
  );

  const onMarkerPress = (index: number) => {
    setIsBottomSheetOpen(true);
    setSelectedPointOfInterestIndex(index);
  };

  const selectedPointOfInterest = selectedPointOfInterestIndex
    ? realEstate?.building.pointsOfInterest?.[selectedPointOfInterestIndex]
    : undefined;

  return (
    <View style={styles.container}>
      <Pressable onPress={onBackPress} style={styles.closeButton} hitSlop={30}>
        <View style={styles.closeButtonWrapper}>
          <Icon name="ChevronLeft" size={Spacing.SIZE_07} color={palette.neutral[800]} />
        </View>
      </Pressable>

      <View style={styles.mapWrapper}>
        <GeoMap
          pointsOfInterest={realEstate?.building?.pointsOfInterest ?? []}
          coord={{ lat: realEstate.building.address.lat, lng: realEstate.building.address.lng }}
          onMarkerPress={onMarkerPress}
        />
      </View>

      <RealEstateMapInfo
        title={propertyTitle}
        address={address}
        coverImageURL={realEstate.coverImage}
      />

      <Modal transparent={true} visible={isBottomSheetOpen} animationType="slide">
        {!!selectedPointOfInterest && (
          <Pressable
            style={styles.bottomSheetPressable}
            onPressIn={() => setIsBottomSheetOpen(false)}
          >
            <View style={styles.modalContainer}>
              <View style={styles.close}>
                <Icon name="Cross" size={Spacing.SIZE_07} color={palette.neutral[800]} />
              </View>
              <View style={styles.bottomSheet}>
                <View>
                  <IconPin icon={selectedPointOfInterest.subType} withTranslate={false} />
                </View>
                <View style={styles.bottomSheetText}>
                  <Text textStyle="Body3">
                    {capitalize(selectedPointOfInterest.subType)} - {selectedPointOfInterest.label}
                  </Text>
                  <Text textStyle="Body3">
                    à {kmConverter(selectedPointOfInterest.distanceValue)}
                  </Text>

                  {!!selectedPointOfInterest.lines && (
                    <Text textStyle="Body3Medium">{selectedPointOfInterest.lines}</Text>
                  )}
                </View>
              </View>
            </View>
          </Pressable>
        )}
      </Modal>
    </View>
  );
};
