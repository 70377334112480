import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const Svg360 = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M8.3 19.3a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7l1.1-1.1c-2.133-.283-3.9-.867-5.3-1.75S2 13.15 2 12c0-1.383.963-2.563 2.888-3.538C6.813 7.487 9.183 7 12 7s5.188.487 7.113 1.462C21.038 9.437 22 10.617 22 12c0 .9-.433 1.725-1.3 2.475s-2.025 1.35-3.475 1.8c-.333.1-.62.058-.862-.125S16 15.692 16 15.325c0-.2.07-.396.212-.587.142-.192.313-.321.513-.388 1.05-.333 1.858-.725 2.425-1.175.567-.45.85-.842.85-1.175 0-.533-.712-1.167-2.137-1.9C16.438 9.367 14.483 9 12 9c-2.483 0-4.437.367-5.862 1.1C4.713 10.833 4 11.467 4 12c0 .4.425.88 1.275 1.438.85.558 2.058.979 3.625 1.262l-.6-.6a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7a.948.948 0 01.7-.275c.283 0 .517.092.7.275l2.6 2.6c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 01-.213.325l-2.6 2.6a.948.948 0 01-.7.275.948.948 0 01-.7-.275z"
        fill={props.color}
      />
    </Svg>
  );
};
export default Svg360;
