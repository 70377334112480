import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getTranslationSwitchStyle = ({ theme, dark }: { theme: Theme; dark?: boolean }) => {
  const textColor = dark ? theme.palette.base.white : theme.palette.neutral[800];

  return StyleSheet.create({
    caption: {
      color: textColor,
    },
    label: {
      color: textColor,
      textDecorationLine: 'underline',
    },
    link: {
      alignItems: 'center',
      color: textColor,
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing.SIZE_04,
    },
  });
};
