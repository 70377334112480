import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSidebar = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M20 4c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413v12c0 .55-.196 1.02-.588 1.412A1.923 1.923 0 0120 20H4c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 012 18V6c0-.55.196-1.021.587-1.413A1.928 1.928 0 014 4h16zM6.5 15.325H4V18h2.5v-2.675zm0-4.65H4v2.65h2.5v-2.65zM20 6H8.5v12H20V6zM6.5 6H4v2.675h2.5V6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSidebar;
