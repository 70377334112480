import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { Text, Theme, TranslatedContent, useTheme } from '@app/ui';
import { ContactableUser } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';

import { SliderImageObject } from '../gallery.types';

interface GalleryFooterWebDescriptionProps {
  currentImage: SliderImageObject;
  contact?: ContactableUser;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    contact: {
      gap: theme.spacing.SIZE_02,
    },
    leftFooter: {
      flex: 4,
      flexGrow: 4,
    },
    whiteText: {
      color: theme.palette.base.white,
    },
  });

export const GalleryFooterWebDescription: React.FC<GalleryFooterWebDescriptionProps> = ({
  currentImage,
  contact,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <ScrollView
      style={styles.leftFooter}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      {!!currentImage?.description && (
        <View style={styles.contact}>
          {!!contact && (
            <Text textStyle="Body2Medium" style={styles.whiteText}>
              {contact.firstName} {contact.lastName}
            </Text>
          )}

          {currentImage.translation ? (
            <TranslatedContent
              dark
              translation={currentImage.translation}
              translatedBy={t('shared.translatedBy', {
                providerName: TRANSLATION_SERVICE,
              })}
              showOriginal={t('shared.showOriginal')}
              showTranslated={t('shared.showTranslated')}
            />
          ) : (
            <Text textStyle="Body3" style={styles.whiteText}>
              {currentImage.description}
            </Text>
          )}
        </View>
      )}
    </ScrollView>
  );
};
