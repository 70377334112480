import React, { useContext, useEffect, useRef } from 'react';

import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Button, Text, useResponsive, UseResponsiveValue } from '@app/ui';
import { useCreatePositioningMutation } from '@app/libs/apollo/introspection';
import {
  trackPositioningConfirmed,
  trackPositioningCancelled,
} from '@app/services/tracking/trackTracking';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';

type PositioningModalProps = {
  realEstateId: string;
  callbackAfterConfirm?: () => void;
};

const getStyles = ({ isLowerThanLaptop }: Partial<UseResponsiveValue>) =>
  StyleSheet.create({
    buttonContainer: {
      marginHorizontal: isLowerThanLaptop ? 0 : 4,
      marginVertical: isLowerThanLaptop ? 4 : 0,
      width: isLowerThanLaptop ? '100%' : '50%',
    },
    buttonGroup: {
      alignItems: 'center',
      flexDirection: isLowerThanLaptop ? 'column-reverse' : 'row',
      gap: 8,
      justifyContent: 'center',
      marginHorizontal: isLowerThanLaptop ? 0 : -4,
      marginTop: 32,
      width: '100%',
    },
    modalContent: {},
  });

export const PositioningModal: React.FC<PositioningModalProps> = ({
  realEstateId,
  callbackAfterConfirm,
}) => {
  const [createPositioning, { loading }] = useCreatePositioningMutation();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles({ isLowerThanLaptop });
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  /**
   * Todo : move to useModalManagerContext on modal cancel
   * Handle track 'trackPositioningCancelled' on modal closed after 'clickOutside' or 'clickOnCross'
   * */
  const confirmFlag = useRef(false);
  useEffect(() => () => !confirmFlag.current && trackPositioningCancelled(realEstateId), []);

  const handleOnClose = closeModal;
  const handleOnConfirm = async () => {
    await createPositioning({
      variables: { realEstateId },
    }).finally(() => {
      confirmFlag.current = true;
      closeModal();
      callbackAfterConfirm?.();
    });
    trackPositioningConfirmed(realEstateId, false);
  };

  return (
    <View style={styles.modalContent}>
      <Text>{t('positioning.positioningModal.body')}</Text>
      <View style={styles.buttonGroup}>
        <View style={styles.buttonContainer}>
          <Button style={{}} onPress={handleOnClose} variant="tertiary">
            {t('positioning.positioningModal.cancelButton')}
          </Button>
        </View>
        <View style={styles.buttonContainer}>
          <Button style={{}} onPress={handleOnConfirm} disabled={loading} isLoading={loading}>
            {t('positioning.positioningModal.confirmButton')}
          </Button>
        </View>
      </View>
    </View>
  );
};
