import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgPk = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="pk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#pk_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M28 0H8v20h20V0z" fill="#0A632F" />
        <Path
          d="M23.67 10.75a5.576 5.576 0 01-1.604 3.395c-2.245 2.246-5.965 2.167-8.309-.176-2.343-2.343-2.422-6.063-.176-8.31a5.576 5.576 0 013.395-1.603 5.554 5.554 0 00-1.107.86c-2.042 2.041-2.143 5.25-.226 7.167 1.917 1.918 5.126 1.816 7.168-.225.34-.34.627-.713.86-1.107zm-2.578-2.645l-.318 1.898-.89-1.707-1.903.284 1.349-1.373-.859-1.723 1.723.858 1.374-1.349-.284 1.904 1.707.89-1.899.318z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgPk;
