import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgLocalShipping = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M6.002 20.002a2.893 2.893 0 0 1-2.125-.875 2.893 2.893 0 0 1-.875-2.125h-2v-11c0-.55.196-1.02.587-1.413a1.926 1.926 0 0 1 1.413-.587h14v4h3l3 4v5h-2c0 .833-.292 1.542-.875 2.125a2.893 2.893 0 0 1-2.125.875 2.893 2.893 0 0 1-2.125-.875 2.893 2.893 0 0 1-.875-2.125h-6c0 .833-.292 1.542-.875 2.125a2.893 2.893 0 0 1-2.125.875Zm0-2c.283 0 .52-.096.712-.287a.968.968 0 0 0 .288-.713.967.967 0 0 0-.288-.712.967.967 0 0 0-.712-.288.967.967 0 0 0-.713.288.967.967 0 0 0-.287.712c0 .283.096.52.287.713.192.191.43.287.713.287Zm-3-3h.8c.283-.3.608-.542.975-.725a2.7 2.7 0 0 1 1.225-.275 2.7 2.7 0 0 1 1.225.275c.367.183.692.425.975.725h6.8v-9h-12v9Zm15 3c.283 0 .52-.096.713-.287a.968.968 0 0 0 .287-.713.968.968 0 0 0-.287-.712.968.968 0 0 0-.713-.288.967.967 0 0 0-.712.288.967.967 0 0 0-.288.712c0 .283.096.52.288.713.191.191.429.287.712.287Zm-1-5h4.25l-2.25-3h-2v3Z"
    />
  </Svg>
);
export default SvgLocalShipping;
