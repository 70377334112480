import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';
import { upperFirst, isNil } from 'lodash';

import { FinancialModel } from '@masteos/agora';

import { Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { propertyFloorToString } from '@app/utils/property';
import {
  LocationType,
  PropertyKind,
  RealEstate,
  RentalSituations,
} from '@app/libs/apollo/introspection';

import { RealEstateAttribute } from './RealEstateAttribute';

interface RealEstateAttributesListProps {
  property: RealEstate;
  financialModel: FinancialModel;
}
const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderColor: theme.palette.neutral[200],
      borderRadius: theme.borderRadius.SIZE_06,
      borderWidth: 1,
      padding: theme.spacing.SIZE_07,
      width: '100%',
    },
  });

export const RealEstateAttributesList: React.FC<RealEstateAttributesListProps> = ({
  property,
  financialModel,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  if (!property || !financialModel) {
    return null;
  }

  const isCondominium = property.building.condominium ? t('shared.yes') : t('shared.no');
  const { type, surface } = property;
  const netSeller = currencyFormatter(financialModel.salePrice, 0);
  const pricePerMeter = currencyFormatter(financialModel.pricePerMeter, 0);
  const isColocation = property.project.renting.type === LocationType.Colocation;
  const shouldShowFloor =
    !isNil(property.floor) && ['OFFICE', PropertyKind.Apartment].includes(property.type || '');
  const floor = propertyFloorToString(t('propertyDescription.groundFloor'), property.floor);
  const rentalSituation = property.project.renting.situation;

  const propertyAttributes = [
    { label: t('propertyDescription.type'), value: t(`propertyDescription.${type}`) },
    {
      label: t('shared.colocation'),
      value: upperFirst(t(`shared.${isColocation ? 'yes' : 'no'}`)),
    },
    { label: t('propertyDescription.coOwnership'), value: upperFirst(isCondominium) },
    ...(shouldShowFloor ? [{ label: t('propertyDescription.floor'), value: floor }] : []),
    {
      label: t('propertyRent.rentalStatus'),
      value:
        rentalSituation !== RentalSituations.Unknown ? t(`propertyRent.${rentalSituation}`) : '',
    },
    { label: t('propertyDescription.surface'), value: `${surface} ${t('shared.m2')}` },
    { label: t('propertyRent.numberOfLots'), value: property.building.totalLots },
    {
      label: t('propertyRent.locationType'),
      value: property.project.furnishing.isFurnished
        ? t('propertyRent.furnished')
        : t('propertyRent.noFurnished'),
    },
    { label: t('propertyDescription.sellingPrice'), value: netSeller },
    { label: t('propertyDescription.pricePerMeter'), value: pricePerMeter },
  ].filter(Boolean);

  return (
    <View style={styles.container}>
      {propertyAttributes.map(({ label, value }, index) => (
        <RealEstateAttribute key={index} label={label} value={value} />
      ))}
    </View>
  );
};
