import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

export interface SvgDiamondProps extends SvgProps {
  className?: string;
}

export const SvgDiamond: React.FC<SvgDiamondProps> = ({ color, ...props }) => (
  <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M0.688544 10.6623C-0.229514 9.74423 -0.229515 8.25577 0.688543 7.33771L7.33771 0.688544C8.25577 -0.229514 9.74423 -0.229515 10.6623 0.688543L17.3115 7.33771C18.2295 8.25577 18.2295 9.74423 17.3115 10.6623L10.6623 17.3115C9.74423 18.2295 8.25577 18.2295 7.33771 17.3115L0.688544 10.6623Z"
      fill={color}
    />
  </Svg>
);
