import React, { useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { DataRows } from '@app/ui/organisms/data-rows/DataRows';
import {
  SoldRealEstateBeforeRenovationV2,
  SoldRealEstateBuildingPointsOfInterestV2,
} from '@app/libs/apollo/introspection';
import { getImageUrl } from '@app/utils/getImageUrl';
import { useGalleryContext } from '@app/features/realEstate/gallery/GalleryProvider';

import { MiniGallery } from '../MiniGallery/MiniGallery';
import { getBeforeRenovationRows } from './getBeforeRenovationRows.utils';

type BeforeRenovationProps = Pick<
  SoldRealEstateBuildingPointsOfInterestV2,
  'transport' | 'places' | 'education' | 'businesses'
> &
  Pick<SoldRealEstateBeforeRenovationV2, 'dpe' | 'description' | 'images'>;

const getStyles = ({ theme }: { theme: Theme; isLowerThanDesktop: boolean }) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_08,
    },
    title: {
      paddingBottom: theme.spacing.SIZE_06,
    },
  });

export const BeforeRenovation: React.FC<BeforeRenovationProps> = ({
  images,
  description,
  dpe,
  businesses,
  education,
  places,
  transport,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const { openGallery } = useGalleryContext();
  const styles = useMemo(
    () => getStyles({ isLowerThanDesktop, theme }),
    [theme, isLowerThanDesktop]
  );

  const imagesGrid = images.map(url => ({
    description: t('property.beforeWork'),
    url: getImageUrl(url),
  }));

  const openGalleryAt = (index: number) => () => {
    openGallery({
      defaultIndex: index,
      images: imagesGrid,
    });
  };

  const rows = getBeforeRenovationRows({
    businesses,
    dpe,
    education,
    places,
    t,
    transport,
  });

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title} textStyle="Headline1Medium">
          {t('propertySold.beforeRenovation.title')}
        </Text>
        <Text textStyle="Body2">{description}</Text>
      </View>
      {!!rows.length && <DataRows testID="before-renovation-data-rows" rows={rows} />}
      <MiniGallery imagesGrid={imagesGrid} openGallery={openGalleryAt} />
    </View>
  );
};
