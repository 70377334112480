import { StyleSheet } from 'react-native';

export const getStyles = (listHeight: number | undefined, padding: number) =>
  StyleSheet.create({
    container: {
      height: listHeight,
      padding: padding,
      width: '100%',
    },
  });
