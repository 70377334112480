import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgCas = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.482 8.369a.333.333 0 00.15.447l2.07 1.035a.333.333 0 01.149.447l-.369.737a.333.333 0 01-.447.15l-.965-.483a.333.333 0 00-.149-.035H4.333A.333.333 0 004 11v.76c0 .143.092.27.228.316l1.26.42a.333.333 0 01.13.552l-.187.188a.333.333 0 00-.098.235v.475c0 .036.006.071.017.105l.606 1.818a.333.333 0 00.167.193l.995.497a.333.333 0 00.385-.062l1.503-1.503a.333.333 0 00-.086-.534l-.736-.368A.333.333 0 018 13.794v-.034c0-.217.204-.376.414-.323l2 .5c.149.037.253.17.253.323v.972c0 .066.02.13.056.185l1.178 1.768a.334.334 0 00.277.148h.263a.333.333 0 00.31-.21l.973-2.434a.333.333 0 01.608-.025l.31.621a.335.335 0 00.063.087l1.257 1.256a.332.332 0 00.086.063l.803.401c.222.11.482-.05.482-.298v-.656a.333.333 0 00-.097-.236l-.334-.333a.333.333 0 010-.471l.334-.334a.333.333 0 01.235-.097h1.117a.333.333 0 00.149-.035l1.079-.54a.333.333 0 00.184-.298v-.99a.333.333 0 00-.098-.235l-.333-.333a.333.333 0 010-.472l.77-.77a.333.333 0 01.535.086l.377.755a.298.298 0 00.533-.267l-.302-.603a.333.333 0 01.15-.447l.736-.369a.333.333 0 00.15-.447l-.456-.912a.333.333 0 00-.193-.167l-1.357-.452a.333.333 0 01-.13-.552l.854-.855a.333.333 0 01.235-.097h1.656a.333.333 0 00.299-.185l.356-.712a.333.333 0 00-.268-.48l-6.139-.559a.333.333 0 01-.205-.568l.066-.065a.333.333 0 00.097-.236V3A.333.333 0 0017 2.667h-2.862a.333.333 0 00-.236.097l-1.837 1.837a.333.333 0 01-.155.088L6.786 5.97a.333.333 0 01-.23-.025l-.503-.252a.333.333 0 00-.465.404l.368 1.105a.333.333 0 01-.018.255l-.456.912zm9.185 8.298a.667.667 0 11-1.334 0 .667.667 0 011.334 0zm-4.667-6a.667.667 0 100-1.334.667.667 0 000 1.334zm12.667 2v.666l-.765.765c-.21.21-.06.569.236.569h.323a.333.333 0 00.298-.185l.574-1.149-.666-.666zm0 0l.368-.185c.24-.12.155-.482-.114-.482a.255.255 0 00-.254.255v.412z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgCas;
