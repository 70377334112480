import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgEu = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#043CAE" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.057 4.276L14 4l.943.276-.276-.943.276-.942-.943.276-.943-.276.276.942-.276.943zm0 13.334l.943-.277.943.277-.276-.943.276-.943L14 16l-.943-.276.276.943-.276.942zm7.61-6.943l-.943.276L20 10l-.276-.943.943.276.942-.276-.276.943.277.943-.943-.276zm-14.276.276l.942-.276.943.276L8 10l.276-.943-.943.276-.942-.276.276.943-.276.943zm13.382-3.61l-.942.276.276-.942-.276-.943.942.276.943-.276-.276.943.276.942-.943-.276zm-12.49 6.943L8.227 14l.943.276-.276-.943.276-.943-.943.277-.942-.277.276.943-.276.943zm10.05-9.383l-.942.276.276-.942-.277-.943.943.276.943-.276-.276.942.276.943-.943-.276zm-7.61 11.823l.944-.276.943.276-.277-.943.277-.942-.943.276-.943-.276.276.943-.276.942zM19.774 14l-.942.276.276-.943-.276-.943.942.277.943-.277-.276.943.276.943-.943-.276zM7.283 7.61l.943-.277.943.276-.276-.942.276-.943L8.226 6l-.942-.276.276.943-.276.942zm10.05 8.83l-.942.276.276-.943-.277-.942.943.276.943-.276-.276.943.276.942-.943-.276zM9.723 5.17l.944-.277.943.276-.277-.942.277-.943-.943.276-.943-.276.276.942-.276.943z"
        fill="#FFD429"
      />
    </Svg>
  );
};

export default SvgEu;
