import { StyleSheet } from 'react-native';

export const getStyle = ({
  positionFixed,
  x,
  y,
  width,
  height,
  count,
}: {
  positionFixed: boolean | undefined;
  x: number;
  y: number;
  width: number;
  height: number;
  count: number;
}) => {
  return StyleSheet.create({
    container: {
      height,
      justifyContent: 'space-between',
      left: positionFixed ? x : 0,
      overflow: 'hidden',
      position: positionFixed ? ('fixed' as 'relative') : 'relative',
      top: positionFixed ? y : 0,
      width,
    },
    root: { flex: 1 },
    swipeArea: {
      flexDirection: 'row',
      height,
      left: 0,
      position: 'absolute',
      top: 0,
      width: width * count,
    },
  });
};
