import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

export const getProjectViewStyles = (isLowerThanLaptop: boolean, theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
      paddingBottom: Platform.select({ web: isLowerThanLaptop ? 48 : 0 }),
    },
    loadingWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      bottom: 0,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 1000,
    },
  });
