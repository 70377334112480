import React, { useCallback, useMemo, useState } from 'react';

import { Animated, View } from 'react-native';

import { Pressable, Text, useResponsive, useTheme } from '@app/ui';

import { getStyleSheet } from './TabLabel.styles';
import { useAnimatedValuesForTab } from './TabViewContext';

interface TabLabelProps {
  index: number;
  title: string;
  focused: boolean;
  setIndex: () => void;
  testID: string;
}

// TODO: move to Aphrodite
export const TabLabel: React.FC<TabLabelProps> = ({ focused, index, title, setIndex, testID }) => {
  const [width, setWidth] = useState<number>(0);
  const { translateX, opacity } = useAnimatedValuesForTab(index, width);
  const theme = useTheme();
  const { isLowerThanDesktop } = useResponsive();
  const styles = useMemo(
    () => getStyleSheet(focused, isLowerThanDesktop, theme),
    [focused, isLowerThanDesktop, theme]
  );
  const onLayout = useCallback(
    ({ nativeEvent }) => {
      const { layout } = nativeEvent;
      if (layout.width !== width) {
        setWidth(layout.width);
      }
    },
    [width]
  );

  return (
    <View onLayout={onLayout} style={styles.tabLabel}>
      <Animated.View
        style={[
          width && styles.animatedBorder,
          {
            transform: [{ translateX }],
          },
        ]}
      />
      <Pressable onPress={setIndex} testID={`tab-${testID}`}>
        <View style={styles.tabLabel}>
          <Animated.Text style={{ opacity }}>
            <Text
              textStyle={focused ? 'Body2Medium' : 'Body2'}
              style={styles.label}
              numberOfLines={1}
            >
              {title}
            </Text>
          </Animated.Text>
        </View>
      </Pressable>
    </View>
  );
};
