import React, { PropsWithChildren } from 'react';

import { Modal as RNModal, View, useWindowDimensions, StyleProp, ViewStyle } from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';

import { Spacing } from '@app/ui/theme/Sizes';
import { palette } from '@app/ui/theme/palette';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { Icon } from '@app/ui/atoms/Icon';
import { isWeb } from '@app/constants/platform';

import { getStyles } from './ModalFullScreen.styles';
import { useTheme } from '../../contexts/config';

export interface ModalFullScreenProps {
  isVisible: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  onPressClose?: () => void;
  hideCross?: boolean;
  testID?: string;
  leftPositionCross?: boolean;
  crossSecondary?: boolean;
  /**
   * @deprecated, need to remove modal in gallery on Apollon...
   */
  noPadding?: boolean;
}

export const ModalFullScreen: React.FC<PropsWithChildren<ModalFullScreenProps>> = ({
  children,
  isVisible,
  onPressClose,
  hideCross = false,
  leftPositionCross = false,
  crossSecondary = false,
  noPadding = false,
  ...modalProps
}) => {
  const dimensions = useWindowDimensions();
  const inset = useSafeAreaInsets();

  const theme = useTheme();
  const styles = getStyles({
    crossSecondary,
    dimensions,
    inset,
    leftPositionCross,
    noPadding,
    theme,
  });

  return (
    <RNModal
      visible={isVisible}
      onRequestClose={onPressClose}
      transparent
      animationType={isWeb() ? 'fade' : 'slide'}
      statusBarTranslucent
      testID="modal-full-screen"
      {...modalProps}
    >
      <SafeAreaView testID="modal-safeAreaView" style={styles.wrapper}>
        {!hideCross ? (
          <Pressable
            onPress={onPressClose}
            style={styles.closeButton}
            testID="modal-full-screen-close"
            hitSlop={30}
          >
            <Icon name="Cross" size={Spacing.SIZE_07} color={palette.neutral[800]} />
          </Pressable>
        ) : null}
        <View style={styles.fullScreen}>{children}</View>
      </SafeAreaView>
    </RNModal>
  );
};
