import * as React from 'react';

import Svg, { SvgProps, Mask, Path, G } from 'react-native-svg';
const SvgLocationAutomation = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Mask
        id="location-automation_svg__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <Path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </Mask>
      <G mask="url(#location-automation_svg__a)">
        <Path
          d="M4 19V7l8-6 8 6v4h-2V8l-6-4.5L6 8v9h6v2H4zm14.5 3l-1.4-3.1-3.1-1.4 3.1-1.4 1.4-3.1 1.4 3.1 3.1 1.4-3.1 1.4-1.4 3.1z"
          fill={props.color}
        />
      </G>
    </Svg>
  );
};
export default SvgLocationAutomation;
