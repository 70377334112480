import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgHomeWork = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M17 9h2V7h-2v2Zm0 4h2v-2h-2v2Zm0 4h2v-2h-2v2Zm4 4h-3a.968.968 0 0 1-.712-.288A.968.968 0 0 1 17 20c0-.283.096-.52.288-.712A.968.968 0 0 1 18 19h3V5h-9v1.4l-2-1.45c0-.533.192-.992.575-1.375A1.876 1.876 0 0 1 11.95 3H21c.55 0 1.02.196 1.413.587C22.803 3.98 23 4.45 23 5v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 21 21ZM3 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 1 19v-6.975a1.947 1.947 0 0 1 .85-1.625l5-3.575c.35-.25.733-.375 1.15-.375.417 0 .8.125 1.15.375l5 3.575a1.94 1.94 0 0 1 .85 1.625V19c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 13 21h-3a.967.967 0 0 1-.713-.288A.968.968 0 0 1 9 20v-4H7v4c0 .283-.096.52-.287.712A.967.967 0 0 1 6 21H3Zm0-9v7h2v-4c0-.283.096-.52.287-.713A.967.967 0 0 1 6 14h4c.283 0 .52.096.713.287.191.192.287.43.287.713v4h2v-7L8 8.45 3 12Z"
    />
  </Svg>
);
export default SvgHomeWork;
