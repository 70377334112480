import React from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Text, Image, ShadowContainer } from '@app/ui';
import { fromS3ID } from '@app/utils/from-s3id';
import { isWeb } from '@app/constants/platform';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverImage: {
    borderRadius: 8,
    height: 56,
    width: 56,
  },
  textWrapper: {
    flex: 1,
    flexWrap: 'wrap',
    marginLeft: Spacing.SIZE_03,
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: palette.base.white,
    borderRadius: 8,
    bottom: isWeb() ? Spacing.SIZE_09 : 120,
    flexDirection: 'row',
    padding: Spacing.SIZE_04,
    position: 'absolute',
    width: 327,
  },
});

interface RealEstateMapInfoProps {
  coverImageURL: string;
  address: string;
  title: string;
}

export const RealEstateMapInfo: React.FC<RealEstateMapInfoProps> = ({
  coverImageURL,
  address,
  title,
}) => {
  return (
    <ShadowContainer style={styles.container}>
      <View style={styles.wrapper}>
        <Image
          style={styles.coverImage}
          source={{
            uri: fromS3ID(coverImageURL, 'w-56,h-56,q-80'),
          }}
          resizeMode="contain"
        />

        <View style={styles.textWrapper}>
          <View>
            <Text textStyle="Body3Medium" style={{ color: palette.base.black }}>
              {title}
            </Text>
          </View>
          <View>
            <Text textStyle="Caption1" style={{ color: palette.neutral[700] }}>
              {address}
            </Text>
          </View>
        </View>
      </View>
    </ShadowContainer>
  );
};
