import React, { useContext } from 'react';

import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Button, Spacer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { AdvisorAppointment } from '@app/features/advisorAppointment/AdvisorAppointment';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';

import { DiscussReinsurance } from './DiscussReinsurance';
import { DiscussTakeAppointmentConfirmation } from '../DiscussTakeAppointmentConfirmation';

const getStyles = (isNativeOrResponsive: boolean, theme: Theme) =>
  StyleSheet.create({
    left: {
      flex: isNativeOrResponsive ? 1 : 1 / 3,
    },
    right: {
      ...(!isNativeOrResponsive && { height: '100%' }),
      flex: 2 / 3,
    },
    row: {
      ...(!isNativeOrResponsive && { height: 'calc(100vh - 300px)' }),
      flexDirection: 'row',
      gap: theme.spacing.SIZE_05,
      marginTop: theme.spacing.SIZE_06,
    },
  });

type DiscussTakeAppointmentContentProps = {
  callbackClose?: () => void;
};

export const DiscussTakeAppointmentContent: React.FC<DiscussTakeAppointmentContentProps> = ({
  callbackClose,
}) => {
  const { t } = useTranslation();
  const { isNativeOrResponsive } = useResponsive();
  const theme = useTheme();
  const styles = getStyles(isNativeOrResponsive, theme);
  const { closeModal, openModal } = useContext(ModalContext);

  const handleSubmit = () => {
    /**
     * Passing a parameter to skip the callback in case of submit.
     * The callback will only be triggered when the modal is closed without submit
     * -> ( press on the cross or press outside )
     * */
    closeModal?.({ skipCallback: true });

    setTimeout(() => {
      openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentConfirmation />, {
        callbackClose,
        position: 'center',
        title: t('discuss.takeAppointment.title'),
        webSize: 'l',
      });
    }, 500);
  };

  return (
    <View style={styles.row}>
      <View style={styles.left}>
        <Text textStyle="Body2">{t('discuss.takeAppointment.content')}</Text>
        <Spacer height={theme.spacing.SIZE_04} />
        <DiscussReinsurance />
        {!!isNativeOrResponsive && (
          <Button iconName="ArrowRight" onPress={handleSubmit}>
            {t('discuss.takeAppointment.button')}
          </Button>
        )}
      </View>
      {!isNativeOrResponsive && (
        <View style={styles.right}>
          <AdvisorAppointment callBackAppointmentBooked={() => null} />
        </View>
      )}
    </View>
  );
};
