// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';

import { Icon } from '@app/ui/atoms/Icon';
import { PopoverOrModal } from '@app/ui/molecules/popover-or-modal/PopoverOrModal';

import { IconPopoverOrModalProps } from './IconPopoverOrModal.types';

export { IconPopoverOrModalProps };

export const IconPopoverOrModal: React.FC<IconPopoverOrModalProps> = ({
  iconName = 'Help',
  iconColor,
  iconSize = 'm',
  iconTestID,
  ...props
}) => {
  const iconSizeInNumber = iconSize === 'm' ? 24 : 16;

  return (
    <PopoverOrModal {...props}>
      <Icon color={iconColor} name={iconName} size={iconSizeInNumber} testID={iconTestID} />
    </PopoverOrModal>
  );
};
