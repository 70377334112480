import React, { useCallback, useMemo } from 'react';

import { ScrollView, View, ViewStyle } from 'react-native';

import { useResponsive, useTheme } from '@app/ui';

import { TabBarProps } from './TabBar';
import { getStyleSheet } from './TabContent.styles';

// TODO: move to Aphrodite
export const TabContent: React.FC<TabBarProps & { swipeToNavigate?: boolean }> = ({
  tabs,
  index,
}) => {
  const theme = useTheme();
  const { isMobile, isLowerThanDesktop, isLowerThanLaptop } = useResponsive();

  const tabContentStyles = useMemo(
    () =>
      getStyleSheet({
        isLowerThanDesktop,
        isLowerThanLaptop,
        isMobile,
        theme,
      }),
    [isLowerThanDesktop, isLowerThanLaptop, isMobile, theme]
  );

  const getDisplay = useCallback(
    (i: number, j: number) => ({ display: i !== j ? 'none' : 'flex' } as ViewStyle),
    []
  );

  return (
    <View style={tabContentStyles.tabsContainer}>
      {tabs.map((item, i: number) => (
        <View key={item.key} style={[tabContentStyles.renderItem, getDisplay(i, index)]}>
          {/* Overflow must be inline-styles to apply */}
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <ScrollView style={[{ overflow: 'visible' }, tabContentStyles.scrollViewContent]}>
            {item.component()}
          </ScrollView>
        </View>
      ))}
    </View>
  );
};
