import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgInstagram = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V7a4 4 0 00-4-4H7zM1 7a6 6 0 016-6h10a6 6 0 016 6v10a6 6 0 01-6 6H7a6 6 0 01-6-6V7z"
        fill={props.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.483 8.99a3 3 0 10-.88 5.934 3 3 0 00.88-5.935zM9.74 7.518a5 5 0 114.606 8.876A5 5 0 019.74 7.519zM16.5 6.5a1 1 0 011-1h.01a1 1 0 110 2h-.01a1 1 0 01-1-1z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgInstagram;
