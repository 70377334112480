import { TFunction } from 'i18next';

import { SoldRealEstateAfterRenovationV2 } from '@app/libs/apollo/introspection';
import { DataRowsProps } from '@app/ui/organisms/data-rows/DataRows';

type getAfterRenovationRowsParams = Pick<
  SoldRealEstateAfterRenovationV2,
  'dpe' | 'esthetics' | 'layout' | 'performances'
> & {
  t: TFunction;
};

export const getAfterRenovationRows = ({
  dpe,
  esthetics,
  layout,
  performances,
  t,
}: getAfterRenovationRowsParams): DataRowsProps['rows'] => {
  const rows: DataRowsProps['rows'] = [];

  if (layout) {
    rows.push({
      description: layout,
      iconName: 'Works',
      label: t('propertySold.afterRenovation.layout'),
    });
  }

  if (performances) {
    rows.push({
      description: performances,
      iconName: 'Monitoring',
      label: t('propertySold.afterRenovation.performances'),
    });
  }

  if (esthetics) {
    rows.push({
      description: esthetics,
      iconName: 'Paint',
      label: t('propertySold.afterRenovation.esthetics'),
    });
  }

  if (dpe) {
    rows.push({
      description: t('propertyRenovation.DPE.data', { value: dpe }),
      iconName: 'Zap',
      label: t('shared.performanceIndicator.DPE.acronym'),
    });
  }

  return rows;
};
