import {
  SearchEngineFilterForm,
  SearchEngineSource,
} from '@app/features/search-engine/searchEngine.types';

export enum FilterType {
  Location = 'location',
  Typology = 'typology',
  Budget = 'budget',
  RoomCount = 'roomCount',
  FlatSharing = 'flatSharing',
  WithRenovation = 'withRenovation',
  AlreadyRented = 'alreadyRented',
  AtGroundFloor = 'atGroundFloor',
  WithShortTermRental = 'withShortTermRental',
}

export type FilterStackScreenProps = {
  Root: {
    onSubmit: (filter: SearchEngineFilterForm, source: SearchEngineSource) => Promise<void> | void;
    onClose: () => void;
  };
  [FilterType.Location]: undefined;
  [FilterType.Typology]: undefined;
  [FilterType.Budget]: undefined;
  [FilterType.RoomCount]: undefined;
  [FilterType.FlatSharing]: undefined;
  [FilterType.WithRenovation]: undefined;
  [FilterType.AlreadyRented]: undefined;
  [FilterType.AtGroundFloor]: undefined;
  [FilterType.WithShortTermRental]: undefined;
};

export interface FilterForm {
  goBack: () => void;
}

export interface FiltersProps {
  onClose: () => void;
  isOpen?: boolean;
  onSubmit: (filter: SearchEngineFilterForm, source: SearchEngineSource) => Promise<void> | void;
}
