import React, { ReactElement, useMemo, useState } from 'react';

import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { GetRealEstateQuery } from '@app/libs/apollo/introspection';
import { Theme, TranslatedContentContentType, useResponsive, useTheme } from '@app/ui';
import { trackTranslationSwitch } from '@app/services/tracking/trackTracking';
import { isNative, isWeb } from '@app/constants/platform';
import { InvestmentWarning } from '@app/features/realEstate/components/InvestmentWarning';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';

import { RenovationRealization } from './components/RenovationRealizations';
import { sortRenovationDetails } from './utils/renovationDetails';
import { RenovationDetailsTable } from './components/renovationDetails/RenovationDetailsTable';
import { RenovationWarranty } from './components/RenovationWarranty';
import { FurnishmentPreviewTable } from './components/furnishment/FurnishmentPreviewTable';
import { RenovationSummaryDetailModal } from './components/renovationSummary/RenovationSummaryDetailModal';
import {
  RenovationSummary,
  RenovationSummaryValues,
} from './components/renovationSummary/RenovationSummary';
import { RenovationFAQ } from './components/RenovationFAQ';

type RealEstate = GetRealEstateQuery['realEstate'];

interface RealEstateRenovationProps {
  realEstate: RealEstate;
  toggleDrawer: (content: ReactElement | null) => void;
  renovationAmount: number;
}

type RealEstateRenovationNavigation = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      gap: isLowerThanLaptop ? theme.spacing.SIZE_08 : theme.spacing.SIZE_09,
      ...(isNative() && { paddingTop: theme.spacing.SIZE_08 }),
    },
    padded: {
      gap: isLowerThanLaptop ? theme.spacing.SIZE_08 : theme.spacing.SIZE_09,
      ...(isNative() && { paddingHorizontal: theme.spacing.SIZE_06 }),
    },
  });

export const RealEstateRenovation: React.FC<RealEstateRenovationProps> = ({
  realEstate,
  toggleDrawer,
  renovationAmount,
}) => {
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanLaptop), [isLowerThanLaptop, theme]);

  const navigation = useNavigation<RealEstateRenovationNavigation>();
  const [isRenovationSummaryModalVisible, setIsRenovationSummaryModalVisible] = useState(false);

  const roomCount = realEstate.roomCount ?? 1;

  const furnishingAmount = realEstate.project.furnishing.amount ?? 0;
  const renovationDetails = [...realEstate.project.renovation.details].sort(sortRenovationDetails);
  const renovationSummaryValues: RenovationSummaryValues = {
    dpe: realEstate.dpe,
    furnishingAmount,
    ges: realEstate.ges,
    realEstateId: realEstate.id,
    renovationAmount,
    translatedDescription: realEstate.project.renovation.translation,
  };

  const trackSwitchLanguage = (
    type: TranslatedContentContentType,
    sourceLang?: string,
    targetLang?: string
  ) => {
    trackTranslationSwitch({
      propertyId: realEstate.id,
      sourceLang,
      targetLang,
      type,
    });
  };

  const onPressViewDetail = () => {
    if (isWeb()) {
      setIsRenovationSummaryModalVisible(true);
    } else {
      navigation.push(RealEstateNavigatorRoutes.RenovationSummaryDetail, {
        renovationSummary: renovationSummaryValues,
      });
    }
  };

  const closeRenovationSummaryModal = () => setIsRenovationSummaryModalVisible(false);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.padded}>
          <RenovationSummary
            {...renovationSummaryValues}
            trackSwitchLanguage={trackSwitchLanguage}
            onPressViewDetail={onPressViewDetail}
          />

          {renovationDetails.length > 0 && (
            <RenovationDetailsTable
              details={renovationDetails}
              renovationAmount={renovationAmount}
              toggleDrawer={toggleDrawer}
            />
          )}

          {renovationAmount > 0 && <RenovationWarranty large={!isLowerThanLaptop} />}

          {furnishingAmount > 0 && (
            <FurnishmentPreviewTable
              furnishingAmount={furnishingAmount}
              roomCount={roomCount}
              toggleDrawer={toggleDrawer}
            />
          )}
        </View>

        <RenovationRealization />

        <View style={styles.padded}>
          <RenovationFAQ />

          <InvestmentWarning />
        </View>
      </View>

      {isWeb() && (
        <RenovationSummaryDetailModal
          {...renovationSummaryValues}
          trackSwitchLanguage={trackSwitchLanguage}
          isVisible={isRenovationSummaryModalVisible}
          onClose={closeRenovationSummaryModal}
        />
      )}
    </>
  );
};
