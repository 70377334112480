import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="sg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#sg_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 10.667h28V0H0v10.667z" fill="#E12237" />
        <Path
          d="M6.667 9.333c.945 0 1.813-.327 2.498-.876a3.333 3.333 0 110-6.248 4 4 0 10-2.498 7.124zM8 4.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0zM8.667 8a.667.667 0 100-1.333.667.667 0 000 1.333zM12 7.333a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm.667-2a.667.667 0 100-1.333.667.667 0 000 1.333zm-2-2.666a.667.667 0 11-1.334 0 .667.667 0 011.334 0z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgSg;
