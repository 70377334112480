/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { PropsWithChildren } from 'react';

import { StyleSheet, View, ViewStyle } from 'react-native';

import { Theme, useResponsive, useTheme } from '@app/ui';

export type BreakPoint = 'nativeOrResponsive' | 'tablet' | 'laptop' | 'desktop' | 'wide';

const sectionMarginForScreen: Record<BreakPoint, number> = {
  nativeOrResponsive: 20,
  tablet: 20,
  laptop: 40,
  desktop: 40,
  wide: 40,
};
export const sectionPaddingForScreen: Record<BreakPoint, number> = {
  nativeOrResponsive: 20,
  tablet: 20,
  laptop: 30,
  desktop: 30,
  wide: 30,
};

const getStyles = (theme: Theme) => {
  return StyleSheet.create<{ [P in BreakPoint]: ViewStyle }>({
    nativeOrResponsive: {
      overflow: 'hidden',
      paddingHorizontal: sectionPaddingForScreen.nativeOrResponsive,
      paddingVertical: theme.spacing.SIZE_07,
    },
    /**
     * Specify styles for web responsive and mobile screens (up to 540px)
     */
    // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
    tablet: {
      overflow: 'hidden',
      paddingHorizontal: sectionPaddingForScreen.nativeOrResponsive,
      paddingVertical: theme.spacing.SIZE_07,
    },

    /**
     * Specify styles for tablet screens (up to 768px)
     */
    // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
    laptop: {
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[200],
      borderRadius: theme.borderRadius.SIZE_10,
      borderWidth: 1,
      margin: sectionMarginForScreen.laptop,
      overflow: 'hidden',
      paddingHorizontal: sectionPaddingForScreen.laptop,
      paddingVertical: theme.spacing.SIZE_07,
    },

    /**
     * Specify styles for large tablet screens (up to 1024px )
     */
    // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
    desktop: {
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[200],
      borderRadius: theme.borderRadius.SIZE_10,
      borderWidth: 1,
      margin: sectionMarginForScreen.desktop,
      overflow: 'hidden',
      paddingHorizontal: sectionPaddingForScreen.desktop,
      paddingVertical: theme.spacing.SIZE_08,
    },

    /**
     * Specify styles for laptop screens (up top 1440px)
     */
    // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
    wide: {
      backgroundColor: theme.palette.base.white,
      borderColor: theme.palette.neutral[200],
      borderRadius: theme.borderRadius.SIZE_10,
      borderWidth: 1,
      margin: sectionMarginForScreen.wide,
      overflow: 'hidden',
      paddingHorizontal: sectionPaddingForScreen.wide,
      paddingVertical: theme.spacing.SIZE_08,
    },
  });
};

type SectionContainerProps = PropsWithChildren;

export const useActiveBreakPoint = (): BreakPoint => {
  const { isTablet, isLaptop, isDesktop, isNativeOrResponsive } = useResponsive();

  if (isNativeOrResponsive) {
    return 'nativeOrResponsive';
  }
  if (isTablet) {
    return 'tablet';
  }
  if (isLaptop) {
    return 'laptop';
  }
  if (isDesktop) {
    return 'desktop';
  }
  return 'wide';
};

export const SectionContainer: React.FC<SectionContainerProps> = ({ children }) => {
  const theme = useTheme();
  const activeBreakpoint = useActiveBreakPoint();
  return <View style={getStyles(theme)[activeBreakpoint]}>{children}</View>;
};
