import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgWrite = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10 21l4-4h6c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0120 21H10zm-6-2h1.4l8.625-8.625-1.4-1.4L4 17.6V19zM18.3 8.925l-4.25-4.2 1.4-1.4a1.92 1.92 0 011.413-.575 1.92 1.92 0 011.412.575l1.4 1.4c.383.383.583.846.6 1.388a1.806 1.806 0 01-.55 1.387L18.3 8.925zM3 21a.965.965 0 01-.712-.288A.965.965 0 012 20v-2.825a1.03 1.03 0 01.3-.725l10.3-10.3 4.25 4.25-10.3 10.3a1 1 0 01-.725.3H3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgWrite;
