import React, { useState } from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { format } from 'date-fns';
import { StyleSheet } from 'react-native';

import { useResponsive, Text } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { config } from '@app/config';
import { useGetCustomerDocumentsQuery } from '@app/libs/apollo/introspection';
import * as S from '@app/features/profile/profile.styles.web';
import { ProfileDocumentProps } from '@app/features/profile/compounds/profileDocuments/profileDocuments.type';
import { useToken } from '@app/shared/hooks/useToken';

const styles = StyleSheet.create({ text: { marginBottom: 20 } });

const MOBILE_CONTAINER_WIDTH = 310;
const DESKTOP_CONTAINER_WIDTH = 720;

export const ProfileDocument: React.FC<ProfileDocumentProps> = ({ route }) => {
  const { t } = useTranslation();
  const { id: documentId } = route.params ?? {};
  const { data } = useGetCustomerDocumentsQuery();
  const [numPages, setNumPages] = useState();
  const { isMobile } = useResponsive();
  const token = useToken();

  if (!data) {
    return null;
  }

  const document = data.documents.find(({ id }) => id === documentId);
  const widthDocument = isMobile ? MOBILE_CONTAINER_WIDTH : DESKTOP_CONTAINER_WIDTH;
  const profilePageTitle = t('profile.profileDocumentMenu', {
    category: t(`shared.documentCategory.${document.category}`),
    date: format(new Date(document.createdAt), 'dd/MM/yyyy'),
  });

  const onDocumentLoadSuccess = props => setNumPages(props.numPages);

  const DocumentContainer = S.DocumentContainer(isMobile);

  return (
    <DocumentContainer style={{ width: `${widthDocument}px` }}>
      <Text textStyle="Headline3Medium" style={styles.text}>
        {profilePageTitle}
      </Text>
      <Document
        file={`${config.APOLLON_API_URL}/documents/download/${document.id}/file.pdf?access_token=${token}`}
        loading={t('profile.loadingDocumentMessage')}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={widthDocument} />
        ))}
      </Document>
    </DocumentContainer>
  );
};
