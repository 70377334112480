import React from 'react';

import { useResponsive } from '@app/ui';
import { PointOfInterest } from '@app/libs/apollo/introspection';
import { isNative } from '@app/constants/platform';

import { GeoMap } from './components/GeoMap';
import { RealEstateMapResponsivePreview } from './components/RealEstateMapResponsivePreview';

interface PropertyDescriptionMapProps {
  lat: number;
  lng: number;
  pointsOfInterest: PointOfInterest[];
  onPress: () => void;
}

export const RealEstateMap: React.FC<PropertyDescriptionMapProps> = ({
  lat,
  lng,
  pointsOfInterest,
  onPress,
}) => {
  const { isMobile } = useResponsive();

  return isNative() || isMobile ? (
    <RealEstateMapResponsivePreview onPress={onPress} coord={{ lat, lng }} />
  ) : (
    <GeoMap coord={{ lat, lng }} pointsOfInterest={pointsOfInterest} />
  );
};
