import React, { useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AdvisorAppointment } from '@app/features/advisorAppointment/AdvisorAppointment';
import { useTranslation } from '@app/services/translations/translations';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';
import { NavigationHeader } from '@app/features/navigation/NavigationHeader';
import { ProfileNavigatorRoutes, ProfileStackParamList } from '@app/navigation/types/routes';
import { isNative } from '@app/constants/platform';

type AdvisorNavigationProp = StackNavigationProp<
  ProfileStackParamList,
  ProfileNavigatorRoutes.ProfileAdvisor
>;

export const AdvisorAppointmentView: React.FC = () => {
  const { t } = useTranslation();

  const navigation = useNavigation<AdvisorNavigationProp>();
  const [appointmentBooked, setAppointmentBooked] = useState<'default' | 'appointmentBooked'>(
    'default'
  );

  const callBackAppointmentBooked = () => setAppointmentBooked('appointmentBooked');

  const headerState = {
    appointmentBooked: {
      onBack: () => navigation.navigate(ProfileNavigatorRoutes.ProfileAdvisor),
      title: t('advisorAppointment.goToAdvisorPage'),
    },
    default: { onBack: null, title: t('advisorAppointment.takeAppointment') },
  };

  return (
    <ScreenWrapper withoutScrollView withoutPadding>
      {isNative() && (
        <NavigationHeader
          onBack={() => navigation.goBack()}
          title={headerState[appointmentBooked].title}
        />
      )}

      <AdvisorAppointment callBackAppointmentBooked={callBackAppointmentBooked} />
    </ScreenWrapper>
  );
};
