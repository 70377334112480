import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgDk = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="dk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#dk_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0a2 2 0 00-2 2v6h8V0H2zm10 0v8h16V2a2 2 0 00-2-2H12zm16 12H12v8h14a2 2 0 002-2v-6zM8 20v-8H0v6a2 2 0 002 2h6z"
          fill="#EF264D"
        />
      </G>
    </Svg>
  );
};

export default SvgDk;
