import React, { RefObject, useMemo, useRef, useState } from 'react';

import { ICarouselInstance } from 'react-native-reanimated-carousel-4';
import { CarouselRenderItemInfo } from 'react-native-reanimated-carousel-4/lib/typescript/types';
import { PanGestureHandlerProps } from 'react-native-gesture-handler';

import { ContactableUser } from '@app/libs/apollo/introspection';

import { CarouselImageProps } from '../components/CarouselImage';
import { SliderImageObject } from '../gallery.types';

type UseGalleryProps = {
  images: SliderImageObject[];
  SlideComponent: React.FC<CarouselImageProps>;
  appointmentImage?: JSX.Element;
  contact?: ContactableUser;
  defaultIndex?: number;
};

interface UseGallery {
  currentIndex: number;
  mainCarouselRef: RefObject<ICarouselInstance>;
  slides: JSX.Element[];
  smallCarouselRef: RefObject<ICarouselInstance>;
  onSnapToItem: (index: number) => void;
}

const ACTIVE_OFFSET = 10;
export const PAN_GESTURE_HANDLER_PROPS: PanGestureHandlerProps = {
  activeOffsetX: [-ACTIVE_OFFSET, ACTIVE_OFFSET],
};

export const MAX_IMAGE_WIDTH = 796;
export const MAX_IMAGE_HEIGHT = 284;

export const renderImage = ({ item }: CarouselRenderItemInfo<JSX.Element>): JSX.Element => {
  return item;
};

export const useGallery = ({
  appointmentImage,
  contact,
  images,
  SlideComponent,
  defaultIndex = 0,
}: UseGalleryProps): UseGallery => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);

  const mainCarouselRef = useRef<ICarouselInstance>(null);
  const smallCarouselRef = useRef<ICarouselInstance>(null);

  const onSnapToItem = (index: number) => {
    setCurrentIndex(index);
    smallCarouselRef.current?.scrollTo({ animated: true, index });
  };

  const slides = useMemo(() => {
    const carouselSlides = images.map((img, i) => (
      <SlideComponent key={i} image={img} contact={contact} />
    ));
    return appointmentImage ? carouselSlides.concat(appointmentImage) : carouselSlides;
  }, [images, appointmentImage, SlideComponent, contact]);

  return { currentIndex, mainCarouselRef, onSnapToItem, slides, smallCarouselRef };
};
