import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { PopoverContainer } from '@app/ui/molecules/popover-container/PopoverContainer.web';

import { TooltipProps } from './Tooltip.types';

export * from './Tooltip.types';

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  title,
  children,
  onOpen: onHoverIn,
  onClose: onHoverOut,
  testID,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
    onHoverIn?.();
  };

  const onClose = () => {
    setTimeout(() => {
      setIsOpen(false);
      onHoverOut?.();
    }, 150);
  };

  useEffect(() => {
    const listener = () => {
      if (isOpen) {
        setIsOpen(false);
        onHoverOut?.();
      }
    };
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [isOpen, onHoverOut]);

  if (!title) {
    return null;
  }

  return (
    <>
      <PopoverContainer
        open={isOpen}
        onClose={() => undefined}
        triggerRef={ref}
        title={title}
        testID={testID}
        renderAsTooltip
      />
      <div ref={ref} onMouseEnter={onOpen} onMouseLeave={onClose}>
        {children}
      </div>
    </>
  );
};
