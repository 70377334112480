import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgBeta = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5 21c-.85 0-1.454-.379-1.812-1.137-.359-.759-.271-1.463.262-2.113L9 11V5H8a.968.968 0 01-.713-.288A.967.967 0 017 4a.97.97 0 01.287-.713A.97.97 0 018 3h8c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0116 5h-1v6l5.55 6.75c.533.65.62 1.354.262 2.113C20.454 20.621 19.85 21 19 21H5zm0-2h14l-6-7.3V5h-2v6.7L5 19z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgBeta;
