import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="gr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#gr_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.333 0H0v5.333h5.333V0zm8 0H8v5.333h5.333V8H8v5.333h20v-2.666H13.333V8H28V5.333H13.333V2.667H28V0H13.333zM28 16H0v2.667h28V16zM5.333 8H0v5.333h5.333V8z"
          fill="#1C6DC1"
        />
      </G>
    </Svg>
  );
};

export default SvgGr;
