import { useEffect, useRef, useState } from 'react';

import { Animated, LayoutRectangle, ScaledSize } from 'react-native';

export const ANIMATED_OFFSET = 4;
export const MARGIN = 8;

export const useAnimations = (opened: boolean) => {
  const animatedTiming = useRef(new Animated.Value(0)).current;
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (opened) {
      setDisplay(true);
    }
    Animated.timing(animatedTiming, {
      duration: 200,
      toValue: opened ? 1 : 0,
      useNativeDriver: false,
    }).start(() => !opened && setDisplay(false));
  }, [animatedTiming, opened]);

  return { animatedTiming, display };
};

export const memoizedLayout =
  (
    parentLayout: LayoutRectangle | null,
    dimensions: ScaledSize,
    fixedMaxHeight = Infinity,
    anchorRight = false
  ) =>
  (): {
    left?: number;
    width?: number;
    maxHeight?: number;
    bottom?: number;
    top?: number;
  } => {
    if (!parentLayout) {
      return { left: 0, top: 0 };
    }

    const { x: parentLeft, y: parentTop, height: parentHeight, width: parentWidth } = parentLayout;

    const { height: screenHeight, width: screenWidth } = dimensions;

    const res = {
      left: 'auto',
      maxWidth: screenWidth - parentLeft,
      minWidth: parentWidth,
    } as {
      [n: string]: string | number;
    };

    if (anchorRight) {
      res['right'] = screenWidth - (parentLeft + parentWidth);
    } else {
      res['left'] = parentLeft;
    }

    const parentBottom = parentTop + parentHeight;

    const topSize = parentTop;
    const bottomSize = screenHeight - (parentTop + parentHeight);
    const maxHeightBottom = Math.min(
      screenHeight - parentBottom - ANIMATED_OFFSET - MARGIN,
      fixedMaxHeight + ANIMATED_OFFSET + MARGIN * 2
    );
    const maxHeightTop = Math.min(
      parentTop - ANIMATED_OFFSET - MARGIN,
      fixedMaxHeight + ANIMATED_OFFSET + MARGIN * 2
    );

    if (bottomSize < topSize && bottomSize < maxHeightTop) {
      return {
        ...res,
        bottom: screenHeight - parentTop,
        maxHeight: maxHeightTop,
      };
    }

    return {
      ...res,
      maxHeight: maxHeightBottom,
      top: parentBottom,
    };
  };
