import React, { useCallback, useMemo, useState } from 'react';

import { StyleSheet, View } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { SliderInput, Text, Theme, useTheme } from '@app/ui';
import {
  trackLoanDown,
  trackLoanDuration,
  trackLoanInterestRate,
  trackPaymentCash,
  trackTooltip,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter, percentFormatter } from '@app/utils/currency-formatter';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { RealEstateFinancialHeader } from './RealEstateFinancialHeader';
import { Importance, RealEstateAttribute } from '../../../components/RealEstateAttribute';
import { areEqual, differentFuncParameters } from '../utils/realEstateFinances.utils';

interface RealEstateFinancialFundingProps {
  realEstate: RealEstate;
  financialModel: FinancialModel;
  defaultFinancialModel: FinancialModel;
  isPaymentCash: boolean;
  loanDown: number;
  loanDuration: number;
  loanInterestRate: number;
  setIsPaymentCash: (v: boolean) => void;
  setLoanDown: (v: number) => void;
  setLoanDuration: (v: number) => void;
  setLoanInterestRate: (v: number) => void;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      paddingBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_03,
      width: '100%',
    },
  });

const MAX_LOAN_DOWN_INTEREST_RATE = 7;
const MAX_LOAN_DURATION = 30;

export const RealEstateFinancialFunding: React.FC<RealEstateFinancialFundingProps> = React.memo(
  ({
    realEstate,
    financialModel,
    defaultFinancialModel,
    isPaymentCash,
    loanDown: defaultLoanDown,
    loanDuration: defaultLoanDuration,
    loanInterestRate: defaultLoanInterestRate,
    setIsPaymentCash,
    setLoanDown: updateLoanDown,
    setLoanDuration: updateLoanDuration,
    setLoanInterestRate: updateLoanInterestRate,
  }) => {
    const theme = useTheme();
    const styles = useMemo(() => getStyles(theme), [theme]);

    // We set slider state here to avoid the value to wait for computation before getting updated
    const [loanDown, setLoanDown] = useState(defaultLoanDown);
    const [loanDuration, setLoanDuration] = useState(defaultLoanDuration);
    const [loanInterestRate, setLoanInterestRate] = useState(defaultLoanInterestRate);
    const { t } = useRealEstateTranslation();
    const formattedLoanDown = currencyFormatter(loanDown, 0);
    const formattedLoanDoanforPositiveCashflow = currencyFormatter(
      defaultFinancialModel.loanDownPaymentForPositiveCashflow,
      0
    );
    const handleIsPaymentCash = useCallback((value: boolean) => {
      trackPaymentCash({ isPaymentCash: value, propertyId: realEstate.id });
      setIsPaymentCash(value);
    }, []);
    const handleLoanDown = useCallback(
      value =>
        trackLoanDown({
          newLoanDown: value,
          oldLoanDown: defaultFinancialModel.defaultLoanDownPayment,
          propertyId: realEstate.id,
        }),
      []
    );
    const handleLoanDuration = useCallback(
      value => trackLoanDuration({ loanDuration: value, propertyId: realEstate.id }),
      []
    );
    const handleLoanInterestRate = useCallback(
      value => trackLoanInterestRate({ loanInterestRate: value, propertyId: realEstate.id }),
      []
    );
    const trackTooltipLoanDown = () =>
      trackTooltip({ propertyId: realEstate.id, tooltipType: 'Apport' });

    const loanDownSlider = React.useMemo(
      () => (
        <SliderInput
          min={0}
          step={5000}
          max={realEstate.project.transaction.netSeller!}
          setSliderValue={(value: number) => {
            setLoanDown(value);
            updateLoanDown(value);
          }}
          value={loanDown}
          onSlidingComplete={handleLoanDown}
          testID="property-financial-loan-down-slider"
        />
      ),
      [loanDown]
    );

    const loanDownInterestRateSlider = React.useMemo(
      () => (
        <SliderInput
          min={1}
          step={0.01}
          max={MAX_LOAN_DOWN_INTEREST_RATE}
          setSliderValue={(value: number) => {
            setLoanInterestRate(value);
            updateLoanInterestRate(value);
          }}
          value={loanInterestRate}
          onSlidingComplete={handleLoanInterestRate}
          testID="property-financial-loan-interest-rate-slider"
        />
      ),
      [loanInterestRate]
    );

    const loanDurationSlider = React.useMemo(
      () => (
        <SliderInput
          min={1}
          step={1}
          max={MAX_LOAN_DURATION}
          setSliderValue={(value: number) => {
            setLoanDuration(value);
            updateLoanDuration(value);
          }}
          value={loanDuration}
          onSlidingComplete={handleLoanDuration}
          testID="property-financial-loan-interest-rate-slider"
        />
      ),
      [loanDuration]
    );

    return (
      <View style={styles.container}>
        <RealEstateFinancialHeader icon="Levels" title={t('shared.financialTool.funding')} />
        <RealEstateAttribute
          label={t('shared.financialTool.paymentCash')}
          importance={Importance.LOW}
          inputSwitch={{
            setValue: handleIsPaymentCash,
            testID: 'property-financial-payment-cash-switch',
            value: isPaymentCash,
          }}
        />

        {realEstate.project.transaction.netSeller && !isPaymentCash ? (
          <View>
            <RealEstateAttribute
              label={t('shared.financialTool.loanDown')}
              tooltip={{
                description: t('shared.financialTool.tooltip.loanDownDescription'),
                onOpen: trackTooltipLoanDown,
                title: t('shared.financialTool.tooltip.loanDown'),
              }}
              value={formattedLoanDown}
              importance={Importance.LOW}
            />

            {!defaultFinancialModel.isSelfFundedWordingHidden && (
              <Text textStyle="Caption1">
                {t('shared.financialTool.projectCashFlow', {
                  loanDownValue: formattedLoanDoanforPositiveCashflow,
                  loanDurationValue: defaultFinancialModel.DEFAULT_LOAN_DURATION,
                })}
              </Text>
            )}
            {loanDownSlider}
          </View>
        ) : null}

        {loanDuration && !isPaymentCash ? (
          <View>
            <RealEstateAttribute
              label={t('shared.financialTool.loanDuration')}
              value={t<string>('propertyFinances.countYears', { count: loanDuration })}
              importance={Importance.LOW}
            />

            {loanDurationSlider}
          </View>
        ) : null}

        {loanInterestRate && !isPaymentCash ? (
          <View>
            <RealEstateAttribute
              label={t('shared.financialTool.loanInterestRate')}
              value={percentFormatter(loanInterestRate)}
              importance={Importance.LOW}
            />

            {loanDownInterestRateSlider}
          </View>
        ) : null}

        <RealEstateAttribute
          label={t('shared.financialTool.loanMonthlyAmount')}
          value={currencyFormatter(financialModel.loanMonthlyAmount, 0)}
          importance={Importance.HIGH}
          testID="property-financial-line-loan-monthly-amount"
        />
      </View>
    );
  },
  areEqual<RealEstateFinancialFundingProps>(
    ['isPaymentCash'],
    (prevState: RealEstateFinancialFundingProps, nextState: RealEstateFinancialFundingProps) => {
      return differentFuncParameters(prevState, nextState, [
        'loanDownPaymentForPositiveCashflow',
        'defaultLoanDownPayment',
        'DEFAULT_LOAN_DURATION',
        'loanMonthlyAmount',
      ]);
    }
  )
);

RealEstateFinancialFunding.displayName = 'PropertyFinancialFunding';
