import { useEffect, useReducer } from 'react';

import {
  FinancialModel,
  FinancialModelComputationProps,
  financialModelEngine as getFinancialModel,
  PropertyKind,
  LocationType,
  PropertySellTarget,
} from '@masteos/agora';

import { useQueryFinancialModelTemplates } from '@app/shared/hooks/useQueryFinancialModelTemplates';
import { RealEstate } from '@app/libs/apollo/introspection';

import { FinancialModelActions } from './actions';
import { financialModelReducer, initialState } from './reducer';

export interface UseFinancialModelOutput {
  defaultFinancialModel: FinancialModel | null;
  evaluationRate: number;
  financialModel: FinancialModel | null;
  isPaymentCash: boolean;
  loanDown: number;
  loanDuration: number;
  loading: boolean;
  loanInterestRate: number;
  shortTermNightlyRent: number;
  resellingYear: number;
  setEvaluationRate: (v: number) => void;
  setIsPaymentCash: (v: boolean) => void;
  setLoanDown: (v: number) => void;
  setLoanDuration: (v: number) => void;
  setLoanInterestRate: (v: number) => void;
  setResellingYear: (v: number) => void;
  setWithBrokerageFees: (v: boolean) => void;
  setWithPropertyManagement: (v: boolean) => void;
  setWithPremiumPropertyManagement: (v: boolean) => void;
  setAnnualAvgOccupancyInMonths: (v: number) => void;
  setShortTermNightlyRent: (v: number) => void;
  setShortTermOccupancyRate: (v: number) => void;
  setWithShortTermRental: (v: boolean) => void;
  withBrokerageFees: boolean;
  withPropertyManagement: boolean;
  withPremiumPropertyManagement: boolean;
  withShortTermRental: boolean;
}

export const realEstateToFinancialModelCompProps = (
  realEstate: RealEstate
): Partial<FinancialModelComputationProps> => {
  return {
    financialData: {
      agencySellerCommission: realEstate.project.transaction.commission.agency || 0,
      annualCharges: realEstate.annual.charges || 0,
      annualCurrentExpenses: realEstate.building.annual?.expenses || 0,
      annualHomeOwnerInsurance: realEstate.annual.ownerInsurance || 0,
      annualPropertyTax: realEstate.annual.tax || 0,
      annualRentIncome: realEstate.project.renting.annual.income || 0,
      evaluationRate: realEstate.trending?.appreciation?.evaluationRate || 0,
      furnituresAmount: realEstate.project.furnishing.amount || 0,
      netSeller: realEstate.project.transaction.netSeller || 0,
      renovationAmount: realEstate.project.renovation.amount || 0,
      // TODO: Feature removed for now #removeShortTermRental
      shortTermNightlyRent: 0, // realEstate.project.renting.shortTerm?.nightlyRent,
      shortTermOccupancyRate: 0, // realEstate.project.renting.shortTerm?.occupancyRate,
    },
    financialModelVersion: realEstate.financialModelVersion || 1,
    propertyData: {
      isFurnished: realEstate.project.furnishing.isFurnished ?? true,
      locationType: realEstate.project.renting.type?.toUpperCase() as LocationType,
      regionCode: realEstate.building.address.regionCode || '',
      sellTarget: realEstate.sellTarget as PropertySellTarget,
      surface: realEstate.surface || 0,
      type: realEstate.type as unknown as PropertyKind,
      withShortTermRental: false,
    },
  };
};

export const useFinancialModel = (realEstate: RealEstate): UseFinancialModelOutput => {
  const compProps = realEstateToFinancialModelCompProps(realEstate);

  const [financialModelState, dispatchFinancialModel] = useReducer(
    financialModelReducer,
    initialState
  );
  const { templates, loading } = useQueryFinancialModelTemplates();

  const calc = () => {
    const fiscality = {
      isPaymentCash: financialModelState.params.isPaymentCash,
      loanDownPayment: financialModelState.params.loanDown,
      loanDuration: financialModelState.params.loanDuration,
      loanInterestRate: financialModelState.params.loanInterestRate,
      withBrokerageFees: financialModelState.params.withBrokerageFees,
      withPremiumPropertyManagement: financialModelState.params.withPremiumPropertyManagement,
      withPropertyManagement: financialModelState.params.withPropertyManagement,
    };

    const resellingData = {
      resellingAnnualPercentEvolution: financialModelState.params.evaluationRate / 100,
      resellingYear: financialModelState.params.resellingYear,
    };

    const financialData = {
      ...compProps.financialData,
      annualAvgOccupancyInMonths: financialModelState.params.annualAvgOccupancyInMonths,
      // shortTermNightlyRent:
      //   financialModelState.params.shortTermNightlyRent ??
      //   compProps.financialData?.shortTermNightlyRent,
      // shortTermOccupancyRate: financialModelState.params.shortTermOccupancyRate,
      shortTermNightlyRent: 0,
      shortTermOccupancyRate: 0,
    };

    const propertyData = {
      ...compProps.propertyData,
      // withShortTermRental: financialModelState.params.withShortTermRental,
      withShortTermRental: false,
    };

    const fm = getFinancialModel({
      ...compProps,
      financialData,
      fiscality,
      propertyData,
      resale: resellingData,
      templates,
    } as FinancialModelComputationProps);

    dispatchFinancialModel({
      payload: fm,
      type: FinancialModelActions.UPDATE_FINANCIAL_MODEL,
    });
  };

  useEffect(() => {
    if (!!templates && !financialModelState.financialModelComputation) {
      const fm = getFinancialModel({
        ...compProps,
        templates,
      } as FinancialModelComputationProps);
      dispatchFinancialModel({
        payload: {
          compProps,
          fm,
        },
        type: FinancialModelActions.INIT,
      });
    }

    const timout = setTimeout(() => {
      if (financialModelState.financialModelComputation) {
        calc();
      }
    }, 0);

    return () => {
      clearTimeout(timout);
    };
  }, [realEstate.id, templates, financialModelState.params]);

  const setShortTermNightlyRent = (shortTermNightlyRent: number) => {
    dispatchFinancialModel({
      payload: shortTermNightlyRent,
      type: FinancialModelActions.SET_SHORT_TERM_NIGHTLY_RENT,
    });
  };

  const setLoanDown = (loanDown: number) => {
    dispatchFinancialModel({
      payload: loanDown,
      type: FinancialModelActions.SET_LOAN_DOWN,
    });
  };

  const setEvaluationRate = (evaluationRate: number) => {
    dispatchFinancialModel({
      payload: evaluationRate,
      type: FinancialModelActions.SET_EVALUATION_RATE,
    });
  };

  const setIsPaymentCash = (isPaymentCash: boolean) => {
    dispatchFinancialModel({
      payload: isPaymentCash,
      type: FinancialModelActions.SET_IS_PAYMENT_CASH,
    });
  };

  const setLoanDuration = (loanDuration: number) => {
    dispatchFinancialModel({
      payload: loanDuration,
      type: FinancialModelActions.SET_LOAN_DURATION,
    });
  };

  const setLoanInterestRate = (loanInterestRate: number) => {
    dispatchFinancialModel({
      payload: loanInterestRate,
      type: FinancialModelActions.SET_LOAN_INTEREST_RATE,
    });
  };

  const setResellingYear = (resellingYear: number) => {
    dispatchFinancialModel({
      payload: resellingYear,
      type: FinancialModelActions.SET_RESELLING_YEAR,
    });
  };

  const setWithBrokerageFees = (withBrokerageFees: boolean) => {
    dispatchFinancialModel({
      payload: withBrokerageFees,
      type: FinancialModelActions.SET_WITH_BROKERAGE_FEES,
    });
  };

  const setWithPropertyManagement = (propertyManagement: boolean) => {
    dispatchFinancialModel({
      payload: propertyManagement,
      type: FinancialModelActions.SET_WITH_PROPERTY_MANAGEMENT,
    });
  };

  const setWithPremiumPropertyManagement = (premiumPropertyManagement: boolean) => {
    dispatchFinancialModel({
      payload: premiumPropertyManagement,
      type: FinancialModelActions.SET_WITH_PREMIUM_PROPERTY_MANAGEMENT,
    });
  };

  const setWithShortTermRental = (withShortTermRental: boolean) => {
    dispatchFinancialModel({
      payload: withShortTermRental,
      type: FinancialModelActions.SET_WITH_SHORT_TERM_RENTAL,
    });
  };

  const setAnnualAvgOccupancyInMonths = (annualAvgOccupancyInMonths: number) => {
    dispatchFinancialModel({
      payload: annualAvgOccupancyInMonths,
      type: FinancialModelActions.SET_ANNUAL_AVG_OCCUPANCY_IN_MONTHS,
    });
  };

  const setShortTermOccupancyRate = (shortTermOccupancyRate: number) => {
    dispatchFinancialModel({
      payload: shortTermOccupancyRate,
      type: FinancialModelActions.SET_SHORT_TERM_OCCUPANCY_RATE,
    });
  };

  return {
    defaultFinancialModel: financialModelState.defaultFinancialModelComputation,
    evaluationRate: financialModelState.params.evaluationRate,
    financialModel: financialModelState.financialModelComputation,
    isPaymentCash: financialModelState.params.isPaymentCash,
    loading,
    loanDown: financialModelState.params.loanDown,
    loanDuration: financialModelState.params.loanDuration,
    loanInterestRate: financialModelState.params.loanInterestRate,
    resellingYear: financialModelState.params.resellingYear,
    setAnnualAvgOccupancyInMonths,
    setEvaluationRate,
    setIsPaymentCash,
    setLoanDown,
    setLoanDuration,
    setLoanInterestRate,
    setResellingYear,
    setShortTermNightlyRent,
    setShortTermOccupancyRate,
    setWithBrokerageFees,
    setWithPremiumPropertyManagement,
    setWithPropertyManagement,
    setWithShortTermRental,
    shortTermNightlyRent:
      financialModelState.params.shortTermNightlyRent ??
      (compProps.financialData?.shortTermNightlyRent || 0),
    withBrokerageFees: financialModelState.params.withBrokerageFees,
    withPremiumPropertyManagement: financialModelState.params.withPremiumPropertyManagement,
    withPropertyManagement: financialModelState.params.withPropertyManagement,
    withShortTermRental: financialModelState.params.withShortTermRental,
  };
};
