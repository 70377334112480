import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { View } from 'react-native';

import { isNative, isWeb } from '@app/constants/platform';

interface UseHeaderAuthButtons {
  isHeaderAuthButtonsVisible: boolean;
  ref: MutableRefObject<View>;
}

export const useHeaderAuthButtons = (): UseHeaderAuthButtons => {
  const ref = useRef<View>();
  const [isHeaderAuthButtonsVisible, setIsHeaderAuthButtonsVisible] = useState(false);
  const observer = useRef(
    isWeb() && 'IntersectionObserver' in window
      ? new IntersectionObserver(([entry]) => setIsHeaderAuthButtonsVisible(entry.isIntersecting))
      : null
  );

  useEffect(() => {
    if (!observer.current || isNative()) {
      return;
    }

    if (ref.current) {
      observer.current.observe(ref.current as unknown as Element);
    }

    return () => {
      observer.current?.disconnect();
    };
  }, []);

  return { isHeaderAuthButtonsVisible, ref };
};
