import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBy = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="by_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#by_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 13.333h28V0H0v13.333z" fill="#E54252" />
        <Path d="M0 20h28v-6.667H0V20z" fill="#5CBE6B" />
        <Path
          d="M0 0h4v3l-.667 1L4 5v2l-.667 1L4 9v2l-.667 1L4 13v2l-.667 1L4 17v3H0V0z"
          fill="#fff"
        />
        <Path
          d="M-.667 2L-2 4l1.333 2L-2 8l1.333 2L-2 12l1.333 2L-2 16l1.333 2 1.334-2-1.334-2 1.334-2-1.334-2L.667 8-.667 6 .667 4-.667 2zM.667 2L2 0l1.333 2L2 4 .667 2zM2 8L.667 6 2 4l1.333 2L2 8zm0 4L.667 10 2 8l1.333 2L2 12zm0 4l1.333-2L2 12 .667 14 2 16zm0 0l1.333 2L2 20 .667 18 2 16z"
          fill="#E54252"
        />
      </G>
    </Svg>
  );
};

export default SvgBy;
