import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSellPrice = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 20.5c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 18.5v-9c0-.317.071-.617.213-.9.141-.283.337-.517.587-.7l6-4.5c.35-.267.75-.4 1.2-.4.45 0 .85.133 1.2.4l6 4.5a1.99 1.99 0 01.8 1.6v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 20.5H6zm0-2h12v-9L12 5 6 9.5v9z"
        fill={props.color}
      />
      <Path
        d="M12.647 16.5a3.718 3.718 0 01-2.28-.744 3.992 3.992 0 01-1.412-1.923h-1.52A.434.434 0 017 13.39c-.001-.126.04-.232.124-.317a.415.415 0 01.31-.128h1.324a1.279 1.279 0 01-.021-.222V12.5v-.222c0-.067.007-.141.021-.222H7.434A.434.434 0 017 11.612c0-.127.042-.232.125-.318a.415.415 0 01.31-.127h1.52a3.992 3.992 0 011.412-1.923 3.718 3.718 0 012.28-.744 3.813 3.813 0 012.14.644c.13.09.2.215.212.378a.548.548 0 01-.169.434.529.529 0 01-.33.155.603.603 0 01-.387-.089 3.245 3.245 0 00-.695-.3 2.66 2.66 0 00-.77-.11 2.69 2.69 0 00-1.483.427 2.79 2.79 0 00-1.016 1.128h2.498c.123 0 .227.042.31.127a.437.437 0 01.125.317.435.435 0 01-.435.445h-2.78a3.334 3.334 0 00-.033.222 2.07 2.07 0 000 .444c.008.067.019.141.033.222h2.78c.123 0 .227.043.31.128a.436.436 0 01.125.317.435.435 0 01-.435.444H10.15c.232.467.57.843 1.016 1.128.445.285.94.428 1.482.428.26 0 .518-.037.771-.111.254-.074.485-.174.695-.3a.622.622 0 01.38-.095.52.52 0 01.337.161c.123.126.18.27.169.434a.472.472 0 01-.212.378 3.814 3.814 0 01-2.14.644z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSellPrice;
