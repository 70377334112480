import React, { useCallback, useState } from 'react';

import { View } from 'react-native';

import { getStyles } from './CancelPositioningModal.styles';
import {
  CancelPositioningModalProps,
  CancelPositioningModalStep,
} from './CancelPositioningModal.types';
import { CancelPositioningThanksStep } from './compounds/cancel-positioning-thanks-step/CancelPositioningThanksStep';
import { CancelPositioningFormStep } from './compounds/cancel-positioning-form-step/CancelPositioningFormStep';

export const CancelPositioningModal: React.FC<CancelPositioningModalProps> = ({
  isSuggestion,
  onSubmit,
  onClose,
}) => {
  const styles = getStyles();
  const [step, setStep] = useState<CancelPositioningModalStep>(CancelPositioningModalStep.FORM);

  const onSuccess = useCallback(() => setStep(CancelPositioningModalStep.THANKS), [setStep]);

  return (
    <View testID="suggestions-cancel-positioning-modal" style={styles.container}>
      {step === CancelPositioningModalStep.THANKS ? (
        <CancelPositioningThanksStep onClose={onClose} isSuggestion={isSuggestion} />
      ) : (
        <CancelPositioningFormStep onSubmit={onSubmit} onSuccess={onSuccess} />
      )}
    </View>
  );
};
