import { hexToRgbA } from '@app/ui/utils/style';

import { Styles, Color, GetStylesReturnValues } from './IconButton.types';

export const getLightColor = ({ pressed, disabled, hovered, theme }: Color) => {
  let color = theme.palette.neutral[900];

  if (hovered) {
    color = theme.palette.neutral[700];
  }

  if (pressed) {
    color = theme.palette.neutral[900];
  }

  if (disabled) {
    color = theme.palette.neutral[600];
  }

  return color;
};

export const getDarkColor = ({ pressed, disabled, hovered, theme }: Color) => {
  let color = theme.palette.base.white;
  if (hovered) {
    color = hexToRgbA(theme.palette.base.white, 0.8);
  }

  if (pressed) {
    color = theme.palette.base.white;
  }

  if (disabled) {
    color = hexToRgbA(theme.palette.base.white, 0.4);
  }

  return color;
};

const getStyles = ({ theme, size, pressed, disabled, hovered }: Styles): GetStylesReturnValues => {
  const sizeMap = {
    m: theme.spacing.SIZE_07,
    s: theme.spacing.SIZE_05,
  };

  return {
    iconSize: {
      height: sizeMap[size],
      width: sizeMap[size],
    },
    inDark: {
      color: getDarkColor({ disabled, hovered, pressed, theme }),
    },
    inLight: {
      color: getLightColor({ disabled, hovered, pressed, theme }),
    },
  };
};

export default getStyles;
