import React, { useState } from 'react';

import { BottomSheet } from '@app/ui';

import { DiscussBeCalledModal } from './DiscussBeCalledModal';
import { DiscussBeCalledContent } from './components/DiscussBeCalledContent';

export const DiscussBeCalled: React.FC = () => {
  const [isModalConfirmationVisible, setIsModalConfirmationVisible] = useState(false);

  const onPressClose = () => {
    setIsModalConfirmationVisible(false);
  };

  return (
    <>
      <DiscussBeCalledContent setIsModalConfirmationVisible={setIsModalConfirmationVisible} />

      <BottomSheet visible={isModalConfirmationVisible} onPressClose={onPressClose}>
        <DiscussBeCalledModal />
      </BottomSheet>
    </>
  );
};
