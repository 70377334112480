// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import styled from '@emotion/styled';

import { RequiredThemes } from '@app/ui/theme/types';

import { RadioProps, RadioSize } from '../RadioButton.types';

const getRadioSize = (radioButtonTheme: RequiredThemes['radioButton'], size: RadioSize = 'm') =>
  radioButtonTheme.size.outerSize[size];

export const RadioInput = styled.input<RadioProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: ${({ theme }) => theme.spacing.NONE};
  left: ${({ theme }) => theme.spacing.NONE};
  margin: ${({ theme }) => theme.spacing.NONE};
  opacity: 0;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.NONE};
`;

export const RadioContainer = styled.div<RadioProps>`
  width: ${({ componentTheme, size }) => getRadioSize(componentTheme, size)}px;
  height: ${({ componentTheme, size }) => getRadioSize(componentTheme, size)}px;
  position: relative;
  box-sizing: border-box;
  margin: ${({ theme }) => theme.spacing.NONE};
  padding: ${({ theme }) => theme.spacing.NONE};
  cursor: pointer;
`;
