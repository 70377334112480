import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { ScrollView } from 'react-native';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  trackRegisterClicked,
  trackRegisterCompleted,
  trackToGTM,
} from '@app/services/tracking/trackTracking';
import { captureException } from '@app/libs/sentry/sentry';
import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { storage } from '@app/services/storage/storage';
import { DEFAULT_PREFIX_NUMBER } from '@app/constants/onboarding';
import { getOrigin } from '@app/utils/originAndUtm';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { useAuthentication } from '@app/shared/hooks/useAuthentication';
import { AuthenticationStrategySSO } from '@app/libs/identity/identity.type';
import { trackSimpleIdentify } from '@app/services/tracking/identifyTracking';
import { isWeb } from '@app/constants/platform';
import { useResponsive, useTheme } from '@app/ui';

import { getStepRegisterValidationSchema } from '../validatorRules';
import { useOnboardingStateMachine } from '../stateMachine/stateMachine.hooks';
import { saveStateMachineToStorage } from '../stateMachine/stateMachine.utils';
import { flushCacheForEmailExists, isSSOMethod } from '../onboarding.utils';
import { useStepRegisterProcess } from './stepRegister.hooks';
import { getStyles } from '../onboarding.styles';
import { RegisterForm, StepRegisterForm } from './StepRegisterForm';

type StepRegisterRouteProp = RouteProp<PublicStackParamList, PublicNavigatorRoutes.Register>;

export const StepRegister: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    createCustomer,
    emailFromQueryParam,
    firstNameFromQueryParam,
    idTokenFromQueryParam,
    isLoading: createCustomerLoading,
    lastNameFromQueryParam,
    method,
    strategy,
  } = useStepRegisterProcess();
  const route = useRoute<StepRegisterRouteProp>();
  const { loginWithEmailPassword, completeAuthentication } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const { context } = useOnboardingStateMachine();

  const connectAndLogin = async (email: string, password: string) => {
    await storage.clear('masteos.onboarding');
    flushCacheForEmailExists();

    await (password
      ? loginWithEmailPassword(email, password)
      : completeAuthentication(idTokenFromQueryParam, strategy as AuthenticationStrategySSO));
  };

  const initialValues: RegisterForm = {
    email: emailFromQueryParam || context.email || '',
    firstName: firstNameFromQueryParam || context.firstName || '',
    isSubscribeNewsletter: false,
    lastName: lastNameFromQueryParam || context.lastName || '',
    password: '',
    phoneNumberPrefix: DEFAULT_PREFIX_NUMBER,
    phoneNumberSuffix: '',
    terms: false,
  };

  const { control, handleSubmit, setError } = useForm<RegisterForm>({
    defaultValues: initialValues,
    resolver: yupResolver(getStepRegisterValidationSchema(t, method)),
  });

  const register = async ({
    email,
    firstName,
    lastName,
    password,
    phoneNumberPrefix,
    phoneNumberSuffix,
    isSubscribeNewsletter,
  }: RegisterForm) => {
    trackRegisterClicked();
    const phoneNumber = `${phoneNumberPrefix}${phoneNumberSuffix}`.replace(/ /g, '');
    const siteOrigin = await getOrigin();

    setIsLoading(true);

    await saveStateMachineToStorage({
      ...context,
      email,
      firstName,
      isSubscribeNewsletter,
      lastName,
    });

    const simpleCustomer = await createCustomer({
      email,
      firstName,
      isSubscribeNewsletter,
      lang: i18n.language,
      lastName,
      password,
      phoneNumber,
      siteOrigin,
    });

    const sponsorEmail = await storage.readString('sponsorEmail');

    trackSimpleIdentify(simpleCustomer.id, simpleCustomer.email);

    trackRegisterCompleted({
      email,
      firstName,
      isReferred: !!sponsorEmail,
      isSubscribeNewsletter,
      lastName,
      method,
    });

    await storage.clear('sponsorEmail');

    try {
      await connectAndLogin(email, password);
      // To be removed once new tracking tested by the marketing team
      trackToGTM('register', simpleCustomer.id, {
        email,
        firstName,
        lastName,
        phoneNumber,
        userEmail: email,
      });
    } catch (e) {
      captureException(e);
      setIsLoading(false);
      setError('email', t('authTunnel.errorAuthenticateMessage'));
    }
  };

  useEffect(() => {
    if (isWeb()) {
      trackChangePage(route.name, { method });
    } else {
      trackChangeScreen(route.name, { method });
    }
  }, []);

  const { isGreaterThanTablet } = useResponsive();
  const theme = useTheme();
  const styles = getStyles({
    isFixed: isWeb(),
    isGreaterThanTablet,
    theme,
  });

  return (
    <ScrollView
      contentContainerStyle={[
        styles.scrollViewContainerWeb,
        { paddingBottom: theme.spacing.SIZE_04 },
      ]}
      style={styles.scrollView}
      showsVerticalScrollIndicator={false}
    >
      <StepRegisterForm
        control={control}
        loading={isLoading || createCustomerLoading}
        onSubmit={handleSubmit(register)}
        showEmailPasswordInputs={!isSSOMethod(method)}
      />
    </ScrollView>
  );
};
