import React, { useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { Card, DataBlock, Spacer, Spacing, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { SoldRealEstateV2 } from '@app/libs/apollo/introspection';

import { Callout } from './Callout';
import { useGetSynthesisRows } from '../hooks/useGetSynthesisRows';

const amastasContactUri =
  'https://ik.imagekit.io/masteos/tr:ar-4-2,w-350/masteosapp/amastas/amastas_contact.png';

interface SynthesisProps {
  financialStrategy: SoldRealEstateV2['afterRenovation']['financialStrategy'];
  surface: number;
  goToAdvisorAppointment: () => void;
}

const getStyles = ({ theme, isLowerThanDesktop }: { theme: Theme; isLowerThanDesktop: boolean }) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_08,
    },
    dataBlockWrapper: {
      flex: 1,
      flexDirection: 'column',
      gap: theme.spacing.SIZE_05,
    },
    wrapper: {
      flexDirection: isLowerThanDesktop ? 'column' : 'row',
      gap: theme.spacing.SIZE_07,
    },
  });

export const Synthesis: React.FC<SynthesisProps> = ({
  financialStrategy,
  surface,
  goToAdvisorAppointment,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const rows = useGetSynthesisRows(financialStrategy, surface);
  const { isLowerThanDesktop } = useResponsive();

  const styles = useMemo(
    () => getStyles({ isLowerThanDesktop, theme }),
    [theme, isLowerThanDesktop]
  );

  return (
    <View style={styles.container}>
      <Text textStyle="Headline1Medium">{t('shared.synthesis')}</Text>
      <Callout onClick={goToAdvisorAppointment} />
      <View style={styles.wrapper}>
        <View style={styles.dataBlockWrapper}>
          {rows?.map(row => (
            <DataBlock
              key={row.title}
              icon={row.icon}
              title={row.title}
              data={row.data}
              testID={row.testID}
              hint={row.hint}
            />
          ))}
        </View>
        <Card
          backgroundColor={theme.palette.tint.blue[50]}
          width={isLowerThanDesktop ? undefined : 384}
        >
          <Card.Header height={isLowerThanDesktop ? 188 : '50%'}>
            <Card.Image imageUri={amastasContactUri} />
          </Card.Header>
          <Card.Content>
            <Card.Block>
              <Text textStyle="Headline2Medium">{t('propertySold.synthesis.card.text')}</Text>
              <Spacer height={Spacing.SIZE_03} />
              <Text textStyle="Body3">{t('propertySold.synthesis.card.description')}</Text>
            </Card.Block>
          </Card.Content>
        </Card>
      </View>
    </View>
  );
};
