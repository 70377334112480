import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgRw = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="rw_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#rw_svg__a)">
        <G filter="url(#rw_svg__filter0_d_4223_11694)">
          <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-5.333H0V20z" fill="#2D754D" />
        </G>
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 9.333h28V0H0v9.333z" fill="#25B1EB" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.667h28V9.333H0v5.334zM21.333 6l-1.02 1.13.078-1.52-1.521.077L20 4.667l-1.13-1.02 1.52.077-.077-1.521 1.02 1.13 1.02-1.13-.077 1.52 1.521-.077-1.13 1.02 1.13 1.021-1.52-.078.077 1.521L21.334 6z"
          fill="#FECB2F"
        />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default SvgRw;
