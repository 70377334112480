import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgFilePlus = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 18a.968.968 0 00.713-.288A.967.967 0 0013 17v-2h2a.968.968 0 00.713-.288A.967.967 0 0016 14a.97.97 0 00-.287-.713A.97.97 0 0015 13h-2v-2a.97.97 0 00-.287-.713A.97.97 0 0012 10a.967.967 0 00-.712.287A.968.968 0 0011 11v2H9a.967.967 0 00-.712.287A.968.968 0 008 14c0 .283.096.52.288.712A.965.965 0 009 15h2v2c0 .283.096.52.288.712A.965.965 0 0012 18zm-6 4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 2h7.175a1.978 1.978 0 011.4.575l4.85 4.85a1.978 1.978 0 01.575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 22H6zm7-18H6v16h12V9h-4a.965.965 0 01-.712-.288A.965.965 0 0113 8V4zM6 4v5-5 16V4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgFilePlus;
