import React, { useMemo } from 'react';

import { View } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { TopBarItem } from '@app/ui/molecules/top-bar-item/TopBarItem';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { Icon } from '@app/ui/atoms/Icon';

import { getStyles } from './TopBar.styles';
import { TopBarProps } from './TopBar.types';

export { TopBarProps };

export const TopBar: React.FC<TopBarProps> = ({ rightElement, items, onLogoClick }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { isLowerThanLaptop } = useResponsive();

  const menuItems = useMemo(
    () =>
      items?.map(item => {
        return (
          <View style={styles.menuItem} key={item.label}>
            <TopBarItem
              key={item.label}
              label={item.label}
              onPress={item.onPress}
              isActive={item.isActive}
            />
          </View>
        );
      }),
    [items, styles.menuItem]
  );

  return !isLowerThanLaptop ? (
    <View style={styles.container}>
      {onLogoClick ? (
        <Pressable onPress={onLogoClick} style={styles.left} testID="topbar-logo-container">
          <Icon name="MasteosFull" width={120} />
        </Pressable>
      ) : (
        <View style={styles.left}>
          <Icon name="MasteosFull" width={120} />
        </View>
      )}

      <View style={styles.menu}>
        <View style={styles.menuGap}>{menuItems}</View>
      </View>
      <View style={styles.right}>{rightElement}</View>
    </View>
  ) : null;
};
