import React, { useMemo } from 'react';

import { StyleSheet, View, ViewStyle, useWindowDimensions } from 'react-native';

import {
  Text,
  Theme,
  TranslatedContent,
  TranslatedContentContentType,
  useResponsive,
  useTheme,
} from '@app/ui';
import {
  trackSwitchToShortTermRental,
  trackTranslationSwitch,
} from '@app/services/tracking/trackTracking';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { isNative, isWeb } from '@app/constants/platform';
import { useRealEstateTitle } from '@app/shared/hooks/usePropertyTitle';
import { useTabAnimationContext } from '@app/shared/components/TabView/TabViewContext';

import { RealEstateKeyNumbers } from './components/RealEstateKeyNumbers';
import { useRealEstateMap } from './hooks/useRealEstateMap';
import { RealEstateMapModal } from '../realEstateMap/components/RealEstateMapModal';
import { RealEstateLocation } from './components/RealEstateLocation';
import { InvestmentWarning } from '../../components/InvestmentWarning';
import { RealEstateSynthesisWrapper } from './components/RealEstateSynthesisWrapper';
import { RealEstateHuntersDescription } from './components/RealEstateHunterDescription';
import { useRealEstateGeneral } from './hooks/useRealEstateGeneral';
import { RealEstateAttributesList } from '../../components/RealEstateAttributesList';
import { RealEstateSynthesisItem } from './components/RealEstateSynthesisItem';
import { ShortTermRental } from './components/ShortTermRental';

interface RealEstateGeneralProps {
  realEstate: RealEstate;
  isPublicRealEstate: boolean;
}

const SIDE_BY_SIDE_MIN_WIDTH = 400;

const getStyles = (theme: Theme, isLowerThanLaptop: boolean, width: number) =>
  StyleSheet.create({
    container: {
      gap: isLowerThanLaptop ? theme.spacing.SIZE_08 : theme.spacing.SIZE_09,
      ...(isNative() && {
        paddingHorizontal: theme.spacing.SIZE_06,
        paddingTop: theme.spacing.SIZE_09,
      }),
    },
    shortTermRental: {
      marginBottom: theme.spacing.SIZE_08,
    },
    sideBySideContainer: {
      columnGap: theme.spacing.SIZE_07,
      flexDirection: 'row',
      flexWrap: 'wrap-reverse',
      justifyContent: 'flex-end',
      rowGap: theme.spacing.SIZE_08,
    },
    sideBySideContent: {
      flex: 1,
      minWidth: !isLowerThanLaptop ? SIDE_BY_SIDE_MIN_WIDTH : width - theme.spacing.SIZE_11 * 2,
    },
    text: { color: theme.palette.base.black },
    titleAndContent: {
      gap: theme.spacing.SIZE_07,
    },
    titleContainer: {
      alignItems: 'flex-start',
      ...(!isLowerThanLaptop
        ? { flexDirection: 'row', justifyContent: 'space-between' }
        : { marginBottom: theme.spacing.SIZE_04 }),
    },
    wrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing.SIZE_06,
    },
  });

const FINANCES_TAB_INDEX = 2;

export const RealEstateGeneral: React.FC<RealEstateGeneralProps> = ({
  realEstate,
  isPublicRealEstate,
}) => {
  const { t } = useTranslation();
  const { setIndex: setTabIndex } = useTabAnimationContext();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const { width: windowWidth } = useWindowDimensions();
  const styles = useMemo(
    () => getStyles(theme, isLowerThanLaptop, windowWidth),
    [isLowerThanLaptop, theme, windowWidth]
  );

  const { financialModel, rows, width, withShortTermRental, setWithShortTermRental } =
    useRealEstateGeneral(realEstate);
  const { addressGoogleMapModal, isMapModalVisible, shouldShowMap, toggleMapModal } =
    useRealEstateMap(realEstate);

  const realEstateTitle = useRealEstateTitle({
    city: realEstate.building.address.city,
    realEstateType: realEstate.type,
    surface: realEstate.surface,
    totalLots: realEstate.building.totalLots,
  });

  const handleSwitchLanguage = (
    type: TranslatedContentContentType,
    sourceLang: string,
    targetLang: string
  ) => {
    trackTranslationSwitch({ propertyId: realEstate.id, sourceLang, targetLang, type });
  };

  const onPressShortTermRental = () => {
    setTabIndex(FINANCES_TAB_INDEX);
  };

  const toggleShortTermRental = (value: boolean) => {
    if (value) {
      trackSwitchToShortTermRental('general');
    }
    setWithShortTermRental(value);
  };

  return (
    <View style={styles.container}>
      <View style={styles.sideBySideContainer}>
        {!!realEstate.description && !!realEstate.translation && (
          <View style={styles.sideBySideContent}>
            <RealEstateHuntersDescription translation={realEstate.translation} />
          </View>
        )}

        <View style={styles.sideBySideContent}>
          <RealEstateKeyNumbers
            rows={rows}
            // TODO: Feature deactivited for now #removeShortTermRental
            // hasShortTermRental={!!realEstate.project.renting.shortTerm}
            hasShortTermRental={false}
            projectAmout={financialModel?.projectAmount}
            withShortTermRental={withShortTermRental}
            toggleShortTermRental={toggleShortTermRental}
          />
        </View>
      </View>

      {!!withShortTermRental && <ShortTermRental onButtonPress={onPressShortTermRental} />}

      <View>
        <RealEstateSynthesisWrapper synthesis={realEstate.synthesis}>
          <View style={styles.wrapper}>
            {realEstate.synthesis.map(
              ({ theme: thematic, translation }) =>
                !!translation &&
                !!thematic && (
                  <RealEstateSynthesisItem
                    style={[{ width }] as ViewStyle}
                    key={thematic}
                    t={t}
                    thematic={thematic}
                    translation={translation}
                  />
                )
            )}
          </View>
        </RealEstateSynthesisWrapper>
      </View>

      <View style={styles.titleAndContent}>
        <Text textStyle="Headline1Medium" style={styles.text}>
          {t('propertyDescription.theProperty')}
        </Text>

        {!!financialModel && (
          <RealEstateAttributesList property={realEstate} financialModel={financialModel} />
        )}
      </View>

      {!!realEstate.building.neighborhood?.description &&
        !!realEstate.building.neighborhood.translation && (
          <View style={styles.titleAndContent}>
            <Text textStyle="Headline1Medium">{t('shared.neighborhoodTitle')}</Text>
            <TranslatedContent
              translation={realEstate.building.neighborhood.translation}
              onHandleDone={handleSwitchLanguage}
              translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
              showOriginal={t('shared.showOriginal')}
              showTranslated={t('shared.showTranslated')}
              textStyle="Body2Medium"
            />
          </View>
        )}

      {!isPublicRealEstate && !!realEstate.building.access?.translation && (
        <RealEstateLocation
          accessTranslation={realEstate.building.access.translation}
          handleSwitchLanguage={handleSwitchLanguage}
          lat={realEstate.building.address.lat}
          lng={realEstate.building.address.lng}
          pointsOfInterest={realEstate.building.pointsOfInterest ?? []}
          onMapPress={toggleMapModal}
          shouldShowMap={shouldShowMap}
        />
      )}

      <InvestmentWarning />

      {isWeb() && (
        <RealEstateMapModal
          coord={{ lat: realEstate.building.address.lat, lng: realEstate.building.address.lng }}
          title={realEstateTitle}
          coverImageURL={realEstate.coverImage}
          address={addressGoogleMapModal}
          isVisibleGoogleMapModal={isMapModalVisible}
          setIsVisibleGoogleMapModal={toggleMapModal}
          pointsOfInterest={realEstate.building.pointsOfInterest ?? []}
        />
      )}
    </View>
  );
};
