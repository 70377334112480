import React, { useCallback, useState } from 'react';

import { ScrollView, View, useWindowDimensions } from 'react-native';

import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { isNative } from '@app/constants/platform';

import { ViewOrModalProps } from './ViewOrModal.types';
import { getStyles } from './ViewOrModal.styles';
import { Button } from '../Button';
import { ModalFullScreen } from '../modal-full-screen/ModalFullScreen';

export { ViewOrModalProps };

export const ViewOrModal: React.FC<ViewOrModalProps> = ({
  children,
  showMoreTxt,
  delegatePosButton = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isLargeMobile } = useResponsive();
  const dimensions = useWindowDimensions();

  const isMobileMode = isNative() || isLargeMobile;
  const styles = getStyles(dimensions.height);

  const openModal = useCallback(() => setIsVisible(true), []);
  const closeModal = useCallback(() => setIsVisible(false), []);

  if (!isMobileMode) {
    return <>{children({ isWebRender: true })}</>;
  }

  return (
    <View testID="view-or-modal-mobile-container">
      {children({
        isMobileRender: true,
        openModal: delegatePosButton ? openModal : undefined,
      })}

      {!delegatePosButton ? (
        <View style={styles.buttonContainerStyle}>
          <Button onPress={openModal} variant="tertiary" testID="view-or-modal-button-open-modal">
            {showMoreTxt}
          </Button>
        </View>
      ) : null}

      <ModalFullScreen isVisible={isVisible} onPressClose={closeModal}>
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          {children({ isModalRender: true })}
        </ScrollView>
      </ModalFullScreen>
    </View>
  );
};

//@TODO : animationType="slide" => when use new modal by @ju
