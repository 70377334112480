import React from 'react';

import { View, Text } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { Button } from '@app/ui/molecules/Button';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { ShadowContainer } from '@app/ui/atoms/shadow-container/ShadowContainer';
import { useTheme } from '@app/ui/contexts/config';

import { getBannerStyle } from './Banner.styles';
import { BannerType } from './Banner.types';

export const Banner: React.FC<BannerType> = ({
  text,
  buttonLabel,
  iconName,
  closeButtonLabel,
  onButtonPress,
  onClose,
  style: styleContainer,
  testID = 'banner-container',
}) => {
  const { isLowerThanLaptop } = useResponsive();
  const theme = useTheme();
  const style = getBannerStyle({ isLowerThanLaptop, theme });

  return (
    <ShadowContainer style={[style.container, styleContainer]} shadowSize={50} testID={testID}>
      <View style={style.left}>
        <Icon name={iconName} color={theme.palette.base.white} />
        <Text style={style.label} numberOfLines={2}>
          {text}
        </Text>
      </View>

      <View style={style.right}>
        <View style={style.buttonContainer}>
          <Button size="m" variant="primary" onPress={onButtonPress}>
            {buttonLabel}
          </Button>
        </View>
        <Button size="m" variant="ghost" isInDark onPress={onClose}>
          {closeButtonLabel}
        </Button>
      </View>
    </ShadowContainer>
  );
};

export { BannerType };
