import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCu = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="cu_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#cu_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 4h28V0H0v4zM0 12h28V8H0v4zM0 20h28v-4H0v4z" fill="#0B389F" />
        <Path d="M0 0l13.333 10L0 20V0z" fill="#E12A43" />
        <Path
          d="M4.667 11.023l-1.568 1.134.595-1.841-1.563-1.14 1.935-.003.6-1.84.602 1.84 1.935.003-1.564 1.14.595 1.841-1.567-1.134z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgCu;
