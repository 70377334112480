import React from 'react';

import { Text as RNText, TextProps as RNTextProps } from 'react-native';

import { TEXT_STYLES } from '@app/ui/theme/fonts';
import { useTheme } from '@app/ui/contexts/config';

export interface TextProps extends RNTextProps {
  textStyle?: keyof typeof TEXT_STYLES;
  testID?: string;
}

export const Text: React.FC<TextProps> = ({
  textStyle = 'Body2',
  children,
  style,
  testID,
  ...props
}) => {
  const theme = useTheme();

  return (
    <RNText style={[theme.textStyles[textStyle], style]} {...props} testID={testID}>
      {children}
    </RNText>
  );
};
