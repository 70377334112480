import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGe = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="ge_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#ge_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M16 21.333h-4V12H-1.333V8H12v-9.333h4V8h13.333v4H16v9.333z" fill="#FF2B37" />
        <Path
          d="M21.6 4.4l-1.6.267V3.333l1.6.267-.267-1.6h1.334L22.4 3.6l1.6-.267v1.334L22.4 4.4l.267 1.6h-1.334l.267-1.6zM5.6 4.4L4 4.667V3.333l1.6.267L5.333 2h1.334L6.4 3.6 8 3.333v1.334L6.4 4.4 6.667 6H5.333L5.6 4.4zM5.6 16.4l-1.6.267v-1.334l1.6.267-.267-1.6h1.334L6.4 15.6l1.6-.267v1.334L6.4 16.4l.267 1.6H5.333l.267-1.6zM21.6 16.4l-1.6.267v-1.334l1.6.267-.267-1.6h1.334l-.267 1.6 1.6-.267v1.334l-1.6-.267.267 1.6h-1.334l.267-1.6z"
          fill="#FD0D1B"
        />
      </G>
    </Svg>
  );
};

export default SvgGe;
