import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgNo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="no_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#no_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M8 0H0v8h8V0zm4 0v8h16V0H12zm16 12H12v8h16v-8zM8 20v-8H0v8h8z" fill="#F14247" />
        <Path
          d="M0 10.667h9.333V20h1.334v-9.333H28V9.333H10.667V0H9.333v9.333H0v1.334z"
          fill="#0A3A85"
        />
      </G>
    </Svg>
  );
};

export default SvgNo;
