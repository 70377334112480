// todo fix
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { cloneDeep, merge } from 'lodash';

import { ComponentThemes, Theme } from '../theme/types';
import { Paths } from '../types/paths';

export const replaceThemeValue = (
  value: string | object | number | unknown[],
  currentTheme: Theme | ComponentThemes,
  path: Paths<Theme>
): Theme | ComponentThemes => {
  const clonedTheme = cloneDeep(currentTheme);
  if (!path.length) {
    return clonedTheme;
  }

  const objectToReturn = clonedTheme;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let object: any = objectToReturn;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];
    const currentDestination = object[key];

    if (i === path.length - 1 && currentDestination) {
      if (typeof value === 'object') {
        object[key] = merge(object[key], value);
      } else {
        object[key] = value;
      }
    }

    if (currentDestination) {
      object = currentDestination;
    } else {
      return clonedTheme;
    }
  }

  return objectToReturn;
};
