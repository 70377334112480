import React from 'react';

import { AphroditeConfigProvider } from '@app/ui/contexts/config';
import {
  ResponsiveContext,
  ResponsiveContextProvider,
} from '@app/ui/contexts/responsive/ResponsiveContext';

import { UseResponsiveValue, defaultValueMobile } from '../responsive/useResponsive';

export type renderOptions = {
  withAphrodite?: boolean;
  responsiveMock?: Partial<UseResponsiveValue> | boolean;
};

export const prepareTree = (
  children: React.ReactElement,
  options: renderOptions
): React.ReactElement => {
  const { withAphrodite, responsiveMock } = options || {};

  if (withAphrodite) {
    children = <AphroditeConfigProvider currentLang="fr">{children}</AphroditeConfigProvider>;
  }

  if (responsiveMock) {
    if (responsiveMock === true) {
      children = <ResponsiveContextProvider>{children}</ResponsiveContextProvider>;
    } else {
      children = (
        <ResponsiveContext.Provider value={{ ...defaultValueMobile, ...responsiveMock }}>
          {children}
        </ResponsiveContext.Provider>
      );
    }
  }

  return children;
};
