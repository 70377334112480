import {
  callTelephone,
  sendEmailMessage,
  sendWhatsappMessage,
} from '@app/services/deepLinking/deepLinking';
import {
  trackContactAdvisorMail,
  trackContactAdvisorPhone,
  trackContactAdvisorWhatsapp,
} from '@app/services/tracking/trackTracking';
import { getFullName } from '@app/utils/getFullName';
import { GetCustomerQuery, ContactableUser } from '@app/libs/apollo/introspection';
import { AdvisorSourcePage } from '@app/services/tracking/constants';
import { isWeb } from '@app/constants/platform';

type ExtractAdvisorInfoReturnType =
  | {
      advisorAvatar?: string;
      advisorFullName: string;
      appointmentFormattedDate: string;
      onCallClick: () => void;
      onMailClick: () => void;
      onWhatsappClick: () => void;
      phoneNumber: string | null;
    }
  | Record<string, never>;

export const extractAdvisorInfo = ({
  source,
  customer,
  contact,
  formatDate,
  t,
}: {
  customer: GetCustomerQuery['me'];
  contact?: ContactableUser;
  t: (text: string) => string;
  formatDate: (date: Date) => string;
  source: AdvisorSourcePage;
}): ExtractAdvisorInfoReturnType => {
  if (!customer?.owner) {
    return {};
  }

  const { owner } = customer;

  const appointmentFormattedDate = customer.nextAppointment?.startAt
    ? formatDate(customer.nextAppointment.startAt)
    : t('advisor.noAppointmentSchedule');

  const phoneNumber = contact?.phoneNumber || owner.phoneNumber;

  const onCallClick = () => {
    if (isWeb()) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      callTelephone(phoneNumber ?? '-', t('shared.telephoneUnavailable'), t('advisor.phone'));
    }

    trackContactAdvisorPhone(customer.email, source);
  };

  const onWhatsappClick = () => {
    sendWhatsappMessage(phoneNumber ?? '-', t('shared.whatsappUnavailable'), t('shared.whatsapp'));
    trackContactAdvisorWhatsapp(customer.email, source);
  };

  const onMailClick = () => {
    if (isWeb()) {
      window.location.href = `mailto:${owner.email}`;
    } else {
      sendEmailMessage(owner, t('advisor.emailUnavailable'), t('shared.email'));
    }

    trackContactAdvisorMail(customer.email, source);
  };

  return {
    advisorAvatar: owner.avatarS3ID ?? undefined,
    advisorFullName: getFullName(owner.firstName, owner.lastName),
    appointmentFormattedDate,
    onCallClick,
    onMailClick,
    onWhatsappClick,
    phoneNumber: owner.phoneNumber || null,
  };
};
