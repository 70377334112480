/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { View } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

export interface SpacerProps {
  /**
   * Flex grow value to fill empty space
   *
   * @type {number}
   * @memberof SpacerProps
   */
  flex?: number;
  /**
   * Height of the spacer
   *
   * @type {number}
   * @memberof SpacerProps
   */
  height?: number;
  /**
   * Define if we display a separator
   * @type {number}
   * @memberof SpacerProps
   */
  separator?: true;
  /**
   * Width of the spacer
   *
   * @type {number}
   * @memberof SpacerProps
   */
  width?: number;
}

/**
 * this component represent a spacer
 *
 * @return {*}
 */
export const Spacer: React.FC<SpacerProps> = ({ flex = 0, height, separator, width }) => {
  const theme = useTheme();
  return (
    <View
      style={{
        alignItems: 'center',
        flexGrow: flex,
        height,
        justifyContent: 'center',
        width,
      }}
    >
      {!!separator && (
        <View
          style={{
            backgroundColor: theme.palette.neutral[200],
            height: width ? '100%' : 1,
            width: height ? '100%' : 1,
          }}
        />
      )}
    </View>
  );
};
