import React, { createContext, PropsWithChildren } from 'react';

import { withLabel } from '@app/ui/hoc/with-label/withLabel';

import { RadioGroupProps, RadioContextType } from '../RadioButton.types';

export const RadioContext = createContext<RadioContextType>({
  onValueChange: () => undefined,
  value: '',
});

export const RadioGroupStandalone: React.FC<PropsWithChildren<RadioGroupProps>> = props => {
  const { children, value, onValueChange } = props;
  return (
    <RadioContext.Provider value={{ onValueChange, value }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    </RadioContext.Provider>
  );
};

export const RadioGroup = withLabel(RadioGroupStandalone);
