import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgShare = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M18 22a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 0115 19c0-.117.008-.238.025-.363s.042-.237.075-.337l-7.05-4.1c-.283.25-.6.446-.95.587-.35.142-.717.213-1.1.213a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 013 12c0-.833.292-1.542.875-2.125A2.893 2.893 0 016 9c.383 0 .75.07 1.1.212.35.142.667.338.95.588l7.05-4.1a1.843 1.843 0 01-.075-.337A2.749 2.749 0 0115 5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0118 2c.833 0 1.542.292 2.125.875S21 4.167 21 5s-.292 1.542-.875 2.125A2.893 2.893 0 0118 8c-.383 0-.75-.071-1.1-.213a3.284 3.284 0 01-.95-.587L8.9 11.3c.033.1.058.212.075.337a2.753 2.753 0 010 .725 1.838 1.838 0 01-.075.338l7.05 4.1c.283-.25.6-.446.95-.588.35-.141.717-.212 1.1-.212.833 0 1.542.292 2.125.875S21 18.167 21 19s-.292 1.542-.875 2.125A2.893 2.893 0 0118 22zm0-16c.283 0 .52-.096.712-.287A.968.968 0 0019 5a.968.968 0 00-.288-.713A.967.967 0 0018 4a.967.967 0 00-.712.287A.968.968 0 0017 5c0 .283.096.521.288.713A.967.967 0 0018 6zM6 13a.968.968 0 00.713-.288A.967.967 0 007 12a.97.97 0 00-.287-.713A.97.97 0 006 11a.97.97 0 00-.713.287A.97.97 0 005 12c0 .283.096.52.287.712.192.192.43.288.713.288zm12 7c.283 0 .52-.096.712-.288A.965.965 0 0019 19a.965.965 0 00-.288-.712A.965.965 0 0018 18a.965.965 0 00-.712.288A.965.965 0 0017 19c0 .283.096.52.288.712A.965.965 0 0018 20z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgShare;
