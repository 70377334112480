import { makeVar } from '@apollo/client';

import {
  SearchSortStrategy,
  UpdateCustomerPreferencesMutationFn,
} from '@app/libs/apollo/introspection';
import { navigationRef } from '@app/navigation/navigationRef';
import {
  HomePageNavigatorRoutes,
  MainMenuNavigatorRoutes,
  RealEstateNavigatorRoutes,
} from '@app/navigation/types/routes';
import { trackNotificationClicked } from '@app/services/tracking/trackTracking';

import { NotificationName, NotificationPayload } from './notificationProvider.types';

export const notificationModalBlocked = makeVar(false);

export const notificationProviderOpenedHandler = async (
  payload: NotificationPayload,
  updateCustomerPreferencesMutation: UpdateCustomerPreferencesMutationFn
): Promise<void> => {
  const notificationData = payload?.notification?.additionalData;
  trackNotificationClicked(notificationData);

  switch (notificationData?.notificationName) {
    case NotificationName.NewAdvisorRecommendation: {
      const realEstateIds = notificationData?.realEstateIds;
      setTimeout(() => {
        if (realEstateIds?.length === 1) {
          navigationRef.current?.navigate(MainMenuNavigatorRoutes.HomePage, {
            params: {
              id: realEstateIds[0],
              isFromNotifications: true,
            },
            screen: HomePageNavigatorRoutes.RealEstate,
          });
        } else {
          navigationRef.current?.navigate(MainMenuNavigatorRoutes.HomePage, {
            screen: HomePageNavigatorRoutes.HomePageRoot,
          });
        }
      }, 1000);
      break;
    }

    case NotificationName.PreventAutoCancelRecommendations: {
      const realEstateIds = notificationData?.realEstateIds;
      setTimeout(() => {
        navigationRef.current?.navigate(MainMenuNavigatorRoutes.HomePage, {
          params: {
            id: realEstateIds[0],
            isFromNotifications: true,
          },
          screen: HomePageNavigatorRoutes.RealEstate,
        });
      }, 1000);
      break;
    }

    case NotificationName.NewShowCasePublished: {
      const realEstateIds = notificationData?.realEstateIds;

      if (realEstateIds?.length > 1) {
        await updateCustomerPreferencesMutation({
          variables: {
            customerPreferences: {
              preferences: { sortStrategy: SearchSortStrategy.LastPublishedAtDesc },
            },
          },
        });
      }
      // falls through
    }

    default: {
      const realEstateIds = notificationData?.realEstateIds;
      if (realEstateIds?.length === 1) {
        setTimeout(() => {
          navigationRef.current?.navigate(MainMenuNavigatorRoutes.RealEstates, {
            screen: RealEstateNavigatorRoutes.RealEstateList,
          });
          navigationRef.current?.navigate(MainMenuNavigatorRoutes.RealEstates, {
            params: {
              id: realEstateIds[0],
              isFromNotifications: true,
            },
            screen: RealEstateNavigatorRoutes.RealEstate,
          });
        }, 1000);
      } else {
        navigationRef.current?.navigate(MainMenuNavigatorRoutes.RealEstates, {
          screen: RealEstateNavigatorRoutes.RealEstateList,
        });
      }
    }
  }
};
