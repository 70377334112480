import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';

import { Theme, useResponsive, useTheme } from '@app/ui';

import { FilterList } from './components/FilterList';
import { FilterForm, FilterType, FiltersProps } from './Filters.type';
import { BudgetFilterForm } from './components/BudgetFilterForm';
import { TypologyFilterForm } from './components/TypologyFilterForm';
import { RoomCountFilterForm } from './components/RoomCountFilterForm';
import { FlatSharingFilterForm } from './components/FlatSharingFilterForm';
import { WithRenovationFilterForm } from './components/WithRenovationFilterForm';
import { AlreadyRentedFilterForm } from './components/AlreadyRentedFilterForm';
import { AtGroundFloorFilterForm } from './components/AtGroundFloorFilterForm';
import { WithShortTermRentalFilterForm } from './components/WithShortTermRentalFilterForm';
import { LocationFilterForm } from './components/LocationFilterForm';

const DRAWER_WIDTH = 423;
const getStyles = ({ width }: { theme: Theme; width: number }) =>
  StyleSheet.create({
    filterListScreen: {
      width,
    },
    header: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: 5,
    },
    scrollView: {
      width,
    },
    subFilterScreen: {
      width,
    },
  });

const getSubFilterScreen = (filterType: FilterType): React.FC<FilterForm> => {
  switch (filterType) {
    default:
    case FilterType.Budget:
      return BudgetFilterForm;
    case FilterType.Typology:
      return TypologyFilterForm;
    case FilterType.Location:
      return LocationFilterForm;
    case FilterType.RoomCount:
      return RoomCountFilterForm;
    case FilterType.FlatSharing:
      return FlatSharingFilterForm;
    case FilterType.WithRenovation:
      return WithRenovationFilterForm;
    case FilterType.AlreadyRented:
      return AlreadyRentedFilterForm;
    case FilterType.AtGroundFloor:
      return AtGroundFloorFilterForm;
    case FilterType.WithShortTermRental:
      return WithShortTermRentalFilterForm;
  }
};

export const Filters: React.FC<FiltersProps> = ({ isOpen, onSubmit, onClose }) => {
  const theme = useTheme();
  const { isLowerThanTablet } = useResponsive();
  const { width } = useWindowDimensions();
  const subFilterWidth = isLowerThanTablet ? width : DRAWER_WIDTH;
  const styles = getStyles({ theme, width: subFilterWidth });

  const [subFilterToShow, setSubFilterToShow] = useState<ReactElement | null>(null);
  const scrollViewRef = useRef<ScrollView>(null);

  const navigateToSubFilter = (filterType: FilterType) => {
    const FilterScreen = getSubFilterScreen(filterType);
    setSubFilterToShow(<FilterScreen goBack={navigateToRoot} />);
    setTimeout(() => scrollViewRef.current?.scrollToEnd()); // Animation doesn't works without setTimeout
  };

  const navigateToRoot = () => {
    scrollViewRef.current?.scrollTo({ animated: true, x: 0 });
    setTimeout(() => setSubFilterToShow(null), 500);
  };

  useEffect(() => {
    if (!isOpen) {
      navigateToRoot();
    }
  }, [isOpen]);

  return (
    <ScrollView
      ref={scrollViewRef}
      showsHorizontalScrollIndicator={false}
      style={styles.scrollView}
      scrollEnabled={false}
      pagingEnabled
      horizontal
    >
      <View style={styles.filterListScreen}>
        <FilterList onPress={navigateToSubFilter} onSubmit={onSubmit} onClose={onClose} />
      </View>

      <View style={styles.subFilterScreen}>{subFilterToShow}</View>
    </ScrollView>
  );
};
