import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ct_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ct_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 3h28V0H0v3z" fill="#FADF50" />
        <Path d="M0 5h28V3H0v2z" fill="#DD172C" />
        <Path d="M0 7h28V5H0v2z" fill="#FADF50" />
        <Path d="M0 9h28V7H0v2z" fill="#DD172C" />
        <Path d="M0 11h28V9H0v2z" fill="#FADF50" />
        <Path d="M0 13h28v-2H0v2z" fill="#DD172C" />
        <Path d="M0 15h28v-2H0v2z" fill="#FADF50" />
        <Path d="M0 17h28v-2H0v2z" fill="#DD172C" />
        <Path d="M0 20h28v-3H0v3z" fill="#FADF50" />
      </G>
    </Svg>
  );
};

export default SvgCt;
