import React from 'react';

import { Platform, StyleSheet, View } from 'react-native';

import { IconPinType } from './IconPin.types';
import { Tram } from './svg/Tram';
import { Rail } from './svg/Rail';
import { Subway } from './svg/Subway';
import { University } from './svg/University';
import { Bus } from './svg/Bus';

export { IconPinType };

const markerHeight = 50;
const markerWidth = 50;

const styles = StyleSheet.create({
  zoomWrapper: {
    ...Platform.select({
      web: {
        transform: [{ translateX: -markerWidth / 2 }, { translateY: -markerHeight }],
      },
    }),
  },
});

export const IconPin: React.FC<IconPinType> = ({ color, icon, withTranslate = false }) => {
  const getIcon = () => {
    switch (icon) {
      case 'tram':
        return <Tram color={color} />;
      case 'rail':
        return <Rail color={color} />;
      case 'subway':
        return <Subway color={color} />;
      case 'university':
        return <University color={color} />;
      case 'bus':
        return <Bus color={color} />;
      default:
        return null;
    }
  };
  return (
    <View testID="iconPin" style={withTranslate ? styles.zoomWrapper : {}}>
      {getIcon()}
    </View>
  );
};
