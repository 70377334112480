import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgStar = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.993 20.8c-.183.15-.38.158-.587.025-.209-.133-.271-.317-.188-.55l1.875-6.125-4.875-3.5c-.2-.133-.262-.317-.187-.55.075-.233.229-.35.462-.35h6.05l1.925-6.4c.033-.133.096-.225.188-.275a.59.59 0 01.575 0c.091.05.154.142.187.275l1.925 6.4h6.05c.233 0 .388.117.463.35.075.233.012.417-.188.55l-4.875 3.5 1.875 6.125c.083.233.02.417-.187.55-.209.133-.405.125-.588-.025l-4.95-3.75-4.95 3.75zm2.55-4.4l2.4-1.85 2.4 1.85-.9-3.05 2.25-1.6h-2.8l-.95-3.1-.95 3.1h-2.8l2.25 1.6-.9 3.05z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgStar;
