import PN from 'awesome-phonenumber';
import { isString } from 'lodash';

const defaultPhone = {
  phonePrefix: '+33',
  phoneSuffix: '',
};

export const parsePhoneNumber = (phoneNumber?: string | null): typeof defaultPhone => {
  if (!isString(phoneNumber)) {
    return defaultPhone;
  }

  const pn = new PN(phoneNumber);

  if (pn.isValid() || (phoneNumber?.length > 3 && phoneNumber.startsWith('+'))) {
    return {
      phonePrefix: `+${pn.getCountryCode()}`,
      phoneSuffix: pn.getNumber('significant'),
    };
  }

  return defaultPhone;
};

export const combinePhone = (prefix: string, suffix: string): string => `${prefix}${suffix}`;
