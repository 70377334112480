import { useMemo } from 'react';

import { get, has } from 'lodash';

import { ComponentThemes, Theme } from '@app/ui/theme/types';

import { useTheme } from '../contexts/config';

type ComponentThemeGetter = (theme: Theme) => ComponentThemes[keyof ComponentThemes];

const getComputedTheme = (
  componentName: string,
  componentTheme: ComponentThemeGetter,
  theme: Theme
) => {
  if (has(theme, componentName)) {
    return get(theme, [componentName]);
  }

  return get({ [componentName]: componentTheme(theme) }, componentName);
};

export const useComponentTheme = <ComponentThemes>(
  componentName: keyof ComponentThemes,
  componentTheme: ComponentThemeGetter
): ComponentThemes[keyof ComponentThemes] => {
  const theme = useTheme();

  return useMemo(() => getComputedTheme(componentName as string, componentTheme, theme), [theme]);
};
