import React from 'react';

import { prepareTree, renderOptions } from './prepareTree';

export const WrapperTesting = ({
  children,
  options,
}: {
  children: React.ReactElement;
  options: renderOptions;
}): React.ReactElement => {
  const tree = prepareTree(children, options);

  return <>{tree}</>;
};
