import React, { useCallback, useMemo } from 'react';

import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation, getFocusedRouteNameFromRoute } from '@react-navigation/native';

import { TopBar, useTheme } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { MainMenuNavigatorRoutes } from '@app/navigation/types/routes';
import { MainNavigationWebProps } from '@app/features/navigation/MainNavigation.web';
import { CustomerDropdown } from '@app/shared/components/customer-dropdown/CustomerDropdown';
import { getHomePage } from '@app/navigation/helpers/getHomePage.utils';
import { useCurrentRoute } from '@app/navigation/helpers/useCurrentRoutes';
import { filterNavigationLinks } from '@app/navigation/utils/filterNavigationLinks.utils';

import { getStyles } from './navigation.styles.web';
import { DesktopNavigationNavigationProp } from './navigation.types';
import { DOM_BODY_CONTAINER_ID } from './navigation.constants';
import { DiscussDropdown } from '../discuss/DiscussDropdown';

export const DesktopNavigation: React.FC<MainNavigationWebProps> = ({ children }) => {
  const navigation = useNavigation<DesktopNavigationNavigationProp>();
  const { customer } = useMe();
  const theme = useTheme();

  const currentStack = useCurrentRoute();

  const homePage = useMemo(() => getHomePage(customer), [customer]);
  let routeName = getFocusedRouteNameFromRoute(currentStack) ?? homePage;
  routeName = routeName === MainMenuNavigatorRoutes.HomePage ? homePage : routeName;

  const { t } = useTranslation();

  const styles = useMemo(() => getStyles(theme), [theme]);

  const redirectPage = useCallback(
    (path: MainMenuNavigatorRoutes) => () => {
      navigation.reset({
        index: 0,
        routes: [{ name: path }],
      });
    },
    [navigation]
  );

  const rightElement = useMemo(
    () => (
      <View style={{ alignItems: 'center', flexDirection: 'row', gap: 10 }}>
        {!!customer.hasNoAppointmentsPassed && <DiscussDropdown />}
        <CustomerDropdown />
      </View>
    ),
    [customer.hasNoAppointmentsPassed]
  );

  const items = useMemo(() => {
    const nav = [
      {
        isActive: routeName === MainMenuNavigatorRoutes.HomePage,
        key: MainMenuNavigatorRoutes.HomePage,
        label: t('navigation.topBar.homePage'),
        onPress: redirectPage(MainMenuNavigatorRoutes.HomePage),
      },
      {
        isActive: routeName === MainMenuNavigatorRoutes.RealEstates,
        key: MainMenuNavigatorRoutes.RealEstates,
        label: t('navigation.topBar.mySearch'),
        onPress: redirectPage(MainMenuNavigatorRoutes.RealEstates),
      },
      ...(customer.hasOngoingProject
        ? [
            {
              isActive: routeName === MainMenuNavigatorRoutes.Projects,
              key: MainMenuNavigatorRoutes.Projects,
              label: t('navigation.topBar.myProjects'),
              onPress: redirectPage(MainMenuNavigatorRoutes.Projects),
            },
          ]
        : []),
    ];

    return filterNavigationLinks(nav, customer);
  }, [customer, redirectPage, routeName, t]);

  return (
    <View style={styles.container} testID="desktop-navigation">
      <TopBar rightElement={rightElement} items={items} onLogoClick={redirectPage(homePage)} />
      <View style={styles.body} testID="desktop-navigation-body" nativeID={DOM_BODY_CONTAINER_ID}>
        {children}
      </View>
    </View>
  );
};
