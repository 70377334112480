import { BannerManagerAction, BannerManagerActions, BannerManagerState } from './actions';

export const initialState = {
  component: null,
  isDisplayed: false,
  modalKey: undefined,
};

export const bannerManagerReducer = (
  state: BannerManagerState,
  action: BannerManagerAction
): BannerManagerState => {
  switch (action.type) {
    case BannerManagerActions.OPEN_BANNER:
      return {
        ...state,
        bannerKey: action.payload.bannerKey,
        callbackClose: action.payload.callbackClose,
        component: action.payload.component,
        isDisplayed: action.payload.isDisplayed,
      };

    case BannerManagerActions.CLOSE_BANNER:
      return {
        ...state,
        component: null,
        isDisplayed: false,
      };
    default:
      return state;
  }
};
