import React from 'react';

import { Animated, View, PressableStateCallbackType, StyleSheet } from 'react-native';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Icon } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';
import { BubbleCount } from '@app/ui/atoms/bubble-count/BubbleCount';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { actionButtonTheme } from '@app/ui/molecules/ActionButton/actionButton.theme';
import { useTheme } from '@app/ui/contexts/config';

import { ActionButtonProps } from './ActionButton.types';
import { getIconColor, getStyle, bubbleCountSize, getWrapperStyle } from './ActionButton.styles';

interface PressableState extends PressableStateCallbackType {
  hovered: boolean;
}
export { ActionButtonProps };

export const ActionButton: React.FC<ActionButtonProps> = ({
  onPress = () => {
    /* */
  },
  disabled = false,
  testID,
  leftIconName,
  rightIconName,
  size = 'm',
  active = false,
  label,
  withSolidBorder = false,
  withShadow = false,
  withDashedBorder = false,
  withGreyBackground = false,
  count,
  style,
  iconColor,
  ...viewProps
}) => {
  const theme = useTheme();
  const componentTheme = useComponentTheme('actionButton', actionButtonTheme);

  const wrapperStyles = getWrapperStyle(theme);
  const onlyContainsIcon = Boolean((leftIconName || rightIconName) && !label);

  return (
    <View style={StyleSheet.flatten([wrapperStyles.container, style])}>
      <Pressable
        onPress={onPress}
        testID={testID}
        accessibilityRole="button"
        disabled={disabled}
        withPressOpacity={false}
      >
        {(state: PressableState) => {
          const { pressed } = state;
          const hovered = !!state.hovered;

          const styles = getStyle({
            active,
            componentTheme: actionButtonTheme(theme),
            disabled,
            hovered,
            onlyContainsIcon,
            pressed,
            size,
            theme,
            withDashedBorder,
            withGreyBackground,
            withShadow,
            withSolidBorder,
          });

          const computedIconColor = getIconColor({
            active,
            componentTheme,
            disabled,
            hovered,
            pressed,
          });

          return (
            <Animated.View {...viewProps}>
              <View style={styles.button}>
                {!!count && (
                  <View style={styles.bubbleCountContainer}>
                    <BubbleCount count={count} size={bubbleCountSize[size]} />
                  </View>
                )}
                {leftIconName?.length ? (
                  <Icon
                    style={styles.iconStyle}
                    name={leftIconName}
                    color={iconColor || computedIconColor}
                    width={componentTheme.size[size].icon.width}
                    height={componentTheme.size[size].icon.height}
                  />
                ) : null}
                {label?.length ? (
                  <Text style={styles.label} numberOfLines={1}>
                    {label}
                  </Text>
                ) : null}
                {rightIconName?.length ? (
                  <Icon
                    style={styles.iconStyle}
                    name={rightIconName}
                    color={iconColor || computedIconColor}
                    width={componentTheme.size[size].icon.width}
                    height={componentTheme.size[size].icon.height}
                  />
                ) : null}
              </View>
            </Animated.View>
          );
        }}
      </Pressable>
    </View>
  );
};
