import { ProjectCategory } from '@app/features/project/Project.types';

export const projectTimelineSteps = {
  'fr-FR': {
    [ProjectCategory.Transaction]: [
      { key: 'findProperty' },
      { key: 'makeProposalOffer' },
      { key: 'signCompromise' },
      { key: 'obtainFinancing' },
      { key: 'signRAA' },
    ],
    [ProjectCategory.Renovation]: [
      { key: 'demolition' },
      { key: 'arrangement' },
      { key: 'preparation' },
      { key: 'finishing' },
      { key: 'furnishing' },
    ],
    [ProjectCategory.Renting]: [{ key: 'advert' }, { key: 'signing' }, { key: 'renting' }],
  },
};
