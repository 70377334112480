export enum NodeEnv {
  Production = 'production',
  Development = 'development',
  Test = 'test',
}

export enum AppEnvironment {
  Production = 'production',
  Development = 'development',
  Staging = 'staging',
}

export enum ConfigKeys {
  HUBSPOT_ADVISOR_APPOINTMENT_URI = 'HUBSPOT_ADVISOR_APPOINTMENT_URI',
}
