import OneSignal from 'react-native-onesignal';

import { config } from '@app/config';
import { GetCustomerQuery } from '@app/libs/apollo/introspection';
const { ONESIGNAL_APP_ID, ENABLE_ONESIGNAL_NOTIFICATION } = config;

type OneSignalApiResponse = { push?: { success?: number } };

export const NotificationSystem = {
  addPermissionObserver: OneSignal.addPermissionObserver,

  addSubscriptionObserver: OneSignal.addSubscriptionObserver,

  clearHandlers: OneSignal.clearHandlers,

  getDeviceState: OneSignal.getDeviceState,

  init: (): void => {
    OneSignal.setLogLevel(0, 0);
    ENABLE_ONESIGNAL_NOTIFICATION && OneSignal.setAppId(ONESIGNAL_APP_ID);
  },

  isLogged: async (): Promise<boolean> => {
    return OneSignal.getDeviceState().then(i => Boolean(i.userId));
  },

  login: (customer: GetCustomerQuery['me']): Promise<void | boolean> => {
    return ENABLE_ONESIGNAL_NOTIFICATION
      ? new Promise((resolve, reject) => {
          OneSignal.setLanguage(customer.lang || 'en');
          OneSignal.setExternalUserId(customer.id, (res: OneSignalApiResponse) => {
            const tags: { [key: string]: string } = {
              advisor_firstName: customer.owner?.firstName || '-',
              email: customer.email,
              firstName: customer.firstName,
              userId: customer.id,
            };

            OneSignal.sendTags(tags);
            if (res?.push?.success) {
              resolve();
            } else {
              reject({ data: res, message: 'unknown error on OneSignal setExternalUserId' });
            }
          });
        })
      : Promise.resolve(false);
  },

  logout: (): Promise<void | boolean> => {
    return ENABLE_ONESIGNAL_NOTIFICATION
      ? new Promise((resolve, reject) => {
          OneSignal.removeExternalUserId((res: OneSignalApiResponse) => {
            if (res?.push?.success) {
              resolve();
            } else {
              reject({ data: res, message: 'unknown error on OneSignal setExternalUserId' });
            }
          });
        })
      : Promise.resolve(false);
  },

  promptPermission: (): Promise<boolean> => {
    return new Promise(OneSignal.promptForPushNotificationsWithUserResponse);
  },

  setNotificationOpenedHandler: OneSignal.setNotificationOpenedHandler as (
    f: (a: { notification?: { additionalData?: Record<string, unknown> } }) => void
  ) => void,
};
