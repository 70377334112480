import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTwitter = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M15.3 5.55a2.9 2.9 0 00-2.9 2.847l-.028 1.575a.599.599 0 01-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 01.034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 018.385-3.355c.174-.001.342.009.516.012.872.018 1.907.745 1.578 1.553-.156.382-.354.73-.639 1.121 0 7.642-4.697 11.358-9.463 12.309-2.365.472-5.507.041-7.58-.771-.785-.309-.54-1.204.284-1.387 1.028-.229 2.206-.61 3.058-1.233-1.295-.854-6.214-3.754-3.822-11.295.225-.71 1.137-.86 1.665-.332 1.334 1.334 2.683 2.282 4.046 2.841 1.158.475 1.442.465 1.973.538L10.4 8.36z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTwitter;
