import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgWrap = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.28 19.555a.968.968 0 01-.713-.288.967.967 0 01-.287-.712v-7h-1.7a.469.469 0 01-.475-.325c-.084-.217-.034-.4.15-.55l8.35-7.525a.918.918 0 01.675-.275c.266 0 .491.092.675.275l8.35 7.525c.183.15.233.333.15.55a.469.469 0 01-.475.325h-1.7v7c0 .283-.096.52-.288.712a.965.965 0 01-.712.288.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-1h-10v1c0 .283-.096.52-.287.712a.968.968 0 01-.713.288zm3.45-12h5.1l-2.55-2.3-2.55 2.3zm-2.45 4h10v-1.8l-.225-.2h-9.55l-.225.2v1.8zm0 4h10v-2h-10v2z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgWrap;
