import { StyleSheet, ViewStyle } from 'react-native';

import { RequiredThemes } from '@app/ui/theme/types';
import { isWeb } from '@app/constants/platform';

import { CardStyleProps, ChipPostion } from './Card.types';

export const cardStyle = ({
  theme,
  width,
  height,
  disabled = false,
  ghosted = false,
  backgroundColor,
}: CardStyleProps) =>
  StyleSheet.create({
    style: {
      backgroundColor: backgroundColor || theme.cardStyle.backgroundColor,
      borderRadius: theme.cardStyle.borderRadius,
      height: height || 'auto',
      minWidth: 240,
      opacity: ghosted || disabled ? 0.6 : 1,
      width: width || 'auto',
      ...theme.cardStyle.shadow,
    },
  }).style;

export const styles = (theme: RequiredThemes['card']) =>
  StyleSheet.create({
    blockWithoutFirstChild: {
      paddingTop: theme.commonStyle.content.blockWithoutFirstChild.paddingTop,
    },
    content: {
      padding: theme.commonStyle.content.padding,
    },
    header: {
      borderTopLeftRadius: theme.commonStyle.header.borderTopLeftRadius,
      borderTopRightRadius: theme.commonStyle.header.borderTopLeftRadius,
      height: 172,
      overflow: 'hidden',
    },
  });

export const chipStyle = (theme: RequiredThemes['card'], position: ChipPostion) =>
  StyleSheet.create({
    style: {
      position: 'absolute',
      zIndex: 1,
      ...(position.includes('top') && { top: theme.chipStyles.top }),
      ...(position.includes('bottom') && { bottom: theme.chipStyles.bottom }),
      ...(position.includes('left') && { left: theme.chipStyles.left }),
      ...(position.includes('right') && { right: theme.chipStyles.right }),
    },
  }).style;

export const blockStyle = (theme: RequiredThemes['card'], withLine: boolean): ViewStyle => ({
  borderBottomColor: theme.blockStyle.borderBottomColor,
  borderBottomWidth: withLine ? theme.blockStyle.borderBottomWidth : 0,
  borderStyle: isWeb() ? 'dashed' : 'solid',
  paddingBottom: withLine ? theme.blockStyle.paddingBottom : theme.blockStyle.noPaddingBottom,
});
