import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { BorderRadius } from '@app/ui/theme/Sizes';

export const getStyle = ({
  theme,
  radiusSize: borderRadius,
}: {
  theme: Theme;
  radiusSize: BorderRadius;
}) => {
  return StyleSheet.create({
    common: {
      borderRadius,
      borderWidth: theme.spacing.SIZE_00,
    },
    neutral: {
      backgroundColor: theme.palette.neutral[100],
      borderColor: theme.palette.base.transparent,
    },
    primary: {
      borderColor: theme.palette.neutral[300],
      borderStyle: 'solid',
    },
  });
};
