import React, { useRef, useMemo, useState, useCallback } from 'react';

import { find } from 'lodash';
import { View, Text } from 'react-native';

import { withLabel } from '@app/ui/hoc/with-label/withLabel';
import { Dropdown } from '@app/ui/molecules/dropdown/Dropdown';
import { List, ListItem, ListItemUnionProps } from '@app/ui/atoms/list/List';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { RotatingChevron } from '@app/ui/atoms/RotatingChevron';
import { useTheme } from '@app/ui/contexts/config';
import { ActionButton } from '@app/ui/molecules/ActionButton';

import { SelectProps } from './Select.types';
import { getStyles, getPressableStyles } from './Select.styles';

/**
 * @deprecated Please use Select V2
 */
const SelectStandalone: React.FC<SelectProps> = ({
  options,
  value,
  onOpen,
  onSelect,
  onButtonClick,
  onSearch,
  searchPhrase,
  searchPlaceholder,
  selectPlaceholder = 'Choose an option',
  isMultichoice,
  canDisplayButtons = true,
  isDisabled = false,
  helperText,
  itemsHeight = 48,
  numberOfLines = 1,
  isHeightLimited = true,
  wrapperStyle = {},
  testID,
}) => {
  const [opened, setOpened] = useState(false);
  const pressableRef = useRef<View>(null);

  const shouldRenderButtons = canDisplayButtons && value.length > 0 && isMultichoice;

  const theme = useTheme();
  const styles = getStyles({ isDisabled, opened, theme });

  const handleSelectPress = useCallback(() => {
    if (!opened) {
      onOpen?.();
    }
    if (!isDisabled) {
      setOpened(!opened);
    }
  }, [isDisabled, onOpen, opened]);

  const handleItemPress = useCallback(
    (item: ListItemUnionProps) => () => {
      onSelect(item);
      if (!isMultichoice) {
        setOpened(false);
      }
    },
    [isMultichoice, onSelect]
  );

  const renderChosenLabels = () =>
    value
      .filter(v => v.value !== null)
      .map(v => v.label)
      .join(', ');

  const buttonsToRender = useMemo(
    () =>
      value.map(e => (
        <ActionButton
          key={e.value}
          label={e.label}
          onPress={onButtonClick ? () => onButtonClick(e) : undefined}
          size="xs"
          withGreyBackground
          rightIconName="Cross"
        />
      )),
    [onButtonClick, value]
  );

  const optionsToRender = useMemo(
    () =>
      options.map(item => (
        <ListItem
          {...item}
          key={item.key}
          onPress={handleItemPress(item)}
          checked={Boolean(find(value, option => option.value === item.value))}
        />
      )),
    [handleItemPress, options, value]
  );

  return (
    <View style={[styles.container, wrapperStyle]} testID="Select">
      <Pressable ref={pressableRef} onPress={handleSelectPress} testID={testID ?? 'Select-toggler'}>
        {({ hovered }) => {
          const pressableStyles = getPressableStyles({
            hovered,
            isDisabled,
            opened,
            theme,
          });

          return (
            <View style={pressableStyles.inputContainer}>
              <Text style={pressableStyles.placeholderText} numberOfLines={1} testID="Select-input">
                {value.length === 0 ? selectPlaceholder : renderChosenLabels()}
              </Text>
              <RotatingChevron
                opened={opened}
                disabled={false}
                size={20}
                chevronType="ChevronDown"
                color={theme.palette.neutral[isDisabled ? 600 : 900]}
                style={pressableStyles.chevron}
              />
            </View>
          );
        }}
      </Pressable>
      <View style={styles.buttonsContainer}>{shouldRenderButtons ? buttonsToRender : null}</View>
      <Text style={styles.helperText}>{helperText}</Text>
      <Dropdown
        parentRef={pressableRef}
        isOpen={opened}
        onClose={() => setOpened(false)}
        searchValue={searchPhrase}
        onSearch={onSearch ? onSearch : undefined}
        searchPlaceholder={searchPlaceholder}
      >
        <List
          itemsHeight={itemsHeight}
          numberOfLines={numberOfLines}
          isHeightLimited={isHeightLimited}
        >
          {optionsToRender}
        </List>
      </Dropdown>
    </View>
  );
};

export { SelectProps };
/**
 * @deprecated Please use Select V2
 */
export const Select = withLabel(SelectStandalone);
