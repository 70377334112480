import React from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Spacer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { useMe } from '@app/shared/hooks/useMe';

import { Guide } from './components/Guide/Guide';
import { guides_ES, guides_FR } from './constants/guides';
import {
  BreakPoint,
  SectionContainer,
  sectionPaddingForScreen,
  useActiveBreakPoint,
} from './SectionContainer';

const getStyles = ({
  theme,
  width,
  isNativeOrResponsive,
  activeBreakPoint,
}: {
  theme: Theme;
  width: number;
  isNativeOrResponsive: boolean;
  activeBreakPoint: BreakPoint;
}) =>
  StyleSheet.create({
    carousel: {
      marginHorizontal: -sectionPaddingForScreen[activeBreakPoint],
      marginTop: theme.spacing.SIZE_06,
      width: width,
    },
    container: {
      backgroundColor: isNativeOrResponsive ? theme.palette.neutral[100] : theme.palette.base.white,
      borderColor: theme.palette.neutral[200],
      borderRadius: isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderWidth: isNativeOrResponsive ? 0 : 1,
      margin: isNativeOrResponsive ? 0 : theme.spacing.SIZE_06,
      marginTop: isNativeOrResponsive ? 0 : theme.spacing.SIZE_06,
      overflow: 'hidden',
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_09,
      zIndex: 20,
    },
    description: {
      marginBottom: theme.spacing.SIZE_06,
    },
    guideWrapper: { flex: 1 },

    renderItem: {
      marginLeft: sectionPaddingForScreen[activeBreakPoint],
      maxWidth: 350,
    },
    rightGuide: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_07,
    },
    rightWrapper: {
      flex: 1,
      flexWrap: 'wrap',
      height: '100%',
      justifyContent: 'space-between',
    },
    wrapper: {
      flex: 1,
      flexDirection: 'row',
      gap: theme.spacing.SIZE_07,
    },
  });

export const HomePageGuides: React.FC = () => {
  const theme = useTheme();
  const { customer } = useMe();
  const { t, i18n } = useTranslation();

  const currentLang = customer?.lang || i18n.language;

  const guides = (currentLang === 'es' ? guides_ES : guides_FR) as {
    title: string;
    img: string;
    index: number;
    link: string;
  }[];

  const { width } = useWindowDimensions();
  const activeBreakPoint = useActiveBreakPoint();
  const { isNativeOrResponsive, isLowerThanDesktop, isLowerThanLaptop } = useResponsive();

  const styles = getStyles({ activeBreakPoint, isNativeOrResponsive, theme, width });

  const Container = isNativeOrResponsive ? View : SectionContainer;

  return (
    <Container style={styles.container}>
      <Text textStyle="Title3Medium">{t('homePage.guides.title')}</Text>

      <View style={styles.description}>
        <Text textStyle="Body3">{t('homePage.guides.subtitle')}</Text>
      </View>

      {isLowerThanDesktop ? (
        <View>
          {!!guides[0] && (
            <Guide
              showButton
              variant={isNativeOrResponsive ? 'm' : 'l'}
              indexGuide={guides[0].index}
              title={guides[0].title}
              link={guides[0].link}
              img={guides[0].img}
            />
          )}

          <Carousel
            showDots={true}
            width={isNativeOrResponsive ? width * 0.9 : isLowerThanLaptop ? width * 0.45 : 300}
            height={250}
            panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
            style={styles.carousel}
            loop={false}
            data={guides.slice(1)}
            renderItem={({ item }) => (
              <View style={styles.renderItem}>
                {!!item && (
                  <Guide
                    variant="xs"
                    title={item.title}
                    link={item.link}
                    img={item.img}
                    indexGuide={item.index}
                  />
                )}
              </View>
            )}
          />

          <Spacer height={20} />
        </View>
      ) : (
        <View style={styles.wrapper}>
          <View style={styles.guideWrapper}>
            {!!guides[0] && (
              <Guide
                variant="l"
                title={guides[0].title}
                link={guides[0].link}
                img={guides[0].img}
                indexGuide={guides[0].index}
              />
            )}
          </View>

          <View style={styles.rightWrapper}>
            <View style={styles.rightGuide}>
              {!!guides[1] && (
                <View style={styles.guideWrapper}>
                  <Guide
                    variant="s"
                    title={guides[1].title}
                    link={guides[1].link}
                    img={guides[1].img}
                    indexGuide={guides[1].index}
                  />
                </View>
              )}

              {!!guides[2] && (
                <View style={styles.guideWrapper}>
                  <Guide
                    variant="s"
                    title={guides[2].title}
                    link={guides[2].link}
                    img={guides[2].img}
                    indexGuide={guides[2].index}
                  />
                </View>
              )}
            </View>
            <View style={styles.rightGuide}>
              {!!guides[3] && (
                <View style={styles.guideWrapper}>
                  <Guide
                    variant="s"
                    title={guides[3].title}
                    link={guides[3].link}
                    img={guides[3].img}
                    indexGuide={guides[3].index}
                  />
                </View>
              )}

              {!!guides[4] && (
                <View style={styles.guideWrapper}>
                  <Guide
                    variant="s"
                    title={guides[4].title}
                    link={guides[4].link}
                    img={guides[4].img}
                    indexGuide={guides[4].index}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
      )}
    </Container>
  );
};
