import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCy = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="cy_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#cy_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 3.333c.363-.181.732.236.506.574l-.439.659a.605.605 0 01-.168.168l-1.844 1.23a.67.67 0 00-.102.083l-.148.148a.667.667 0 000 .943l.39.39c.26.26.26.683 0 .943l-.522.523a.667.667 0 01-.77.124l-.307-.153a.667.667 0 00-.894.298l-.245.49a.666.666 0 01-.386.334l-1.635.545a.666.666 0 01-.21.035h-.814a.667.667 0 00-.596.368l-.184.369a.667.667 0 01-.895.298l-1.974-.987a.667.667 0 01-.173-.125L7.53 9.53a.667.667 0 01-.196-.472v-.586c0-.26.211-.471.472-.471.125 0 .245-.05.333-.138l1-1a.667.667 0 01.471-.195h.577a.667.667 0 00.633-.456l.328-.986a.667.667 0 01.763-.443l2.61.522c.097.02.196.017.292-.007l2.45-.613a.667.667 0 00.137-.05L18.667 4 20 3.333z"
          fill="#E7832C"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 16a.667.667 0 100-1.333A.667.667 0 0014 16z"
          fill="#445128"
        />
        <Path
          opacity={0.75}
          d="M16 14.667s2-.667 2.667-2M12 14.667s-2-.667-2.667-2"
          stroke="#445128"
          strokeWidth={1.333}
          strokeLinecap="round"
        />
      </G>
    </Svg>
  );
};

export default SvgCy;
