import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mm_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mm_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20z" fill="#F13D4F" />
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="#4AC94B" />
        <Path d="M0 6.667h28V0H0v6.667z" fill="#FFD043" />
        <Path
          d="M14 12.34l-3.527 2.514 1.301-4.13-3.48-2.578 4.33-.04L14 4l1.375 4.107 4.331.039-3.48 2.577 1.3 4.131L14 12.34z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgMm;
