import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgArrowLeft = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10.875 18.889l-6.6-6.6a.883.883 0 01-.213-.325A1.115 1.115 0 014 11.588c0-.133.02-.258.062-.374a.883.883 0 01.213-.325l6.6-6.6A.978.978 0 0111.562 4a.933.933 0 01.713.288c.2.184.304.413.313.687a.933.933 0 01-.288.713l-4.9 4.9h11.175a.97.97 0 01.713.288.97.97 0 01.287.713c0 .283-.096.52-.287.711a.968.968 0 01-.713.288H7.4l4.9 4.9c.183.184.28.417.288.7a.872.872 0 01-.288.7c-.183.2-.417.3-.7.3a.988.988 0 01-.725-.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgArrowLeft;
