import { useEffect, useRef } from 'react';

import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';

import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { useCountRealEstatesLazyQuery } from '@app/libs/apollo/introspection';

import { filterToPreferencesMapper } from '../../utils/filter-to-preferences.mapper';

interface UseCountResult {
  count: number | undefined;
  loading: boolean;
}

const DEBOUNCE_TIME = 1000;

export const useCountResults = (watch: Partial<SearchEngineFilterForm> = {}): UseCountResult => {
  const { getValues: getGlobalValues } = useFormContext<SearchEngineFilterForm>();
  const [searchRealEstates, { data, loading }] = useCountRealEstatesLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const countResult = useRef(
    debounce(async (preferences: Partial<SearchEngineFilterForm>) => {
      await searchRealEstates({
        variables: { filters: filterToPreferencesMapper({ ...getGlobalValues(), ...preferences }) },
      });
    }, DEBOUNCE_TIME)
  ).current;

  useEffect(() => {
    countResult(watch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(watch));

  return { count: data?.searchRealEstates.total, loading };
};
