export const POPOVER_CONSTANTS = {
  containerSpacing: 0,
  diamondOffset: 1.4,
  diamondSize: 18,
  paddingX: 16,
  paddingY: 16,
};

export const TOOLTIP_CONSTANTS = {
  containerSpacing: 8,
  diamondOffset: 0.7,
  diamondSize: 12,
  paddingX: 16,
  paddingY: 7,
};
