import React from 'react';

import { View } from 'react-native';

import { useResponsive, useTheme } from '@app/ui';
import { useUtmValues } from '@app/shared/hooks/useUtmValues.web';
import { LayoutSideText } from '@app/shared/components/LayoutSideText/LayoutSideText';

import { OnboardingContext } from './OnboardingContext';
import { OnboardingContent } from './onboardingContent/OnboardingContent';
import { getStyles } from './onboarding.styles';

export const Onboarding: React.FC = () => {
  const { isGreaterThanTablet } = useResponsive();
  const theme = useTheme();
  const styles = getStyles({ isGreaterThanTablet, theme });

  useUtmValues();

  return (
    <OnboardingContext>
      <LayoutSideText>
        <View style={styles.mainWrapper}>
          <OnboardingContent />
        </View>
      </LayoutSideText>
    </OnboardingContext>
  );
};
