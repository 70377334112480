import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMy = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="my_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#my_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 0H13.333v1.333H28V0zm0 2.667H13.333V4H28V2.667zM13.333 5.333H28v1.334H13.333V5.333zM28 8H13.333v1.333H28V8zM0 10.667h28V12H0v-1.333zm28 2.666H0v1.334h28v-1.334zM0 16h28v1.333H0V16zm28 2.667H0V20h28v-1.333z"
          fill="#E1244A"
        />
        <Path fill="#0C3F8E" d="M0 0h16v10.667H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.667 9.333c.945 0 1.813-.327 2.498-.876a3.333 3.333 0 110-6.248 4 4 0 10-2.498 7.124zM9.51 7.736l1.157-1.31 1.157 1.31-.303-1.721 1.745-.088-1.533-.837 1.018-1.42-1.61.678-.474-1.681-.475 1.681-1.61-.677L9.6 5.09l-1.534.837 1.745.088-.302 1.72z"
          fill="#FFD34F"
        />
      </G>
    </Svg>
  );
};

export default SvgMy;
