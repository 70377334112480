import { ColorValue } from 'react-native';

import { Dpe } from '@app/libs/apollo/introspection';

export const dpeColors: Record<Dpe, ColorValue> = {
  [Dpe.A]: '#01C249',
  [Dpe.APlus]: '',
  [Dpe.B]: '#7BC201',
  [Dpe.C]: '#BBC201',
  [Dpe.D]: '#FCCD26',
  [Dpe.E]: '#FCA626',
  [Dpe.F]: '#F86800',
  [Dpe.G]: '#E30C1C',
  [Dpe.NoDiag]: '',
};
