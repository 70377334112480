import React, { useCallback } from 'react';

import { TextStyle, View, Text } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';
import { isNative } from '@app/constants/platform';

import { getSidebarItemStyle, containerStyle, getColor } from './SidebarItem.styles';
import { SidebarItemType } from './SidebarItem.types';
import { Pressable } from '../../../../ui/atoms/pressable/Pressable';

export const SidebarItem: React.FC<SidebarItemType> = ({
  iconName,
  disabled,
  label,
  onPress,
  isActive,
  isMobile,
  notification,
  wrapperStyle,
  testID,
}) => {
  const theme = useTheme();

  const renderLabel = useCallback(
    (labelStyle: TextStyle) => {
      if (isMobile) {
        return null;
      }

      if (isNative() && isActive) {
        return (
          <Text testID="label-test-id" style={labelStyle}>
            {label}
          </Text>
        );
      }

      if (isNative()) {
        return null;
      }

      return (
        <Text testID="label-test-id" style={labelStyle}>
          {label}
        </Text>
      );
    },
    [isMobile, isActive, label]
  );

  const iconSize = isMobile || isNative() ? theme.spacing.SIZE_07 : theme.spacing.SIZE_06;

  return (
    <Pressable
      style={[containerStyle.pressable, wrapperStyle]}
      disabled={disabled}
      onPress={onPress}
      testID={testID}
      withPressOpacity={false}
    >
      {({ hovered }) => {
        const style = getSidebarItemStyle({
          active: !!isActive,
          disabled: !!disabled,
          hovered,
          theme,
        });

        const iconColor = getColor({
          active: !!isActive,
          disabled: !!disabled,
          hovered,
          theme,
        });

        return (
          <>
            {notification ? (
              <View testID="notification-test-id" style={style.notification} />
            ) : null}
            <Icon color={iconColor} size={iconSize} name={iconName} />
            {renderLabel(style.label)}
          </>
        );
      }}
    </Pressable>
  );
};
