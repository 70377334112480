import { IconName } from '@app/ui/atoms/Icon';

export enum ToastVariant {
  DEFAULT = 'default',
  ERROR = 'error',
}

export type ToastType = {
  label: string;
  numberOfLines?: number;
  width?: string;
  icon?: IconName;
  onClose?: () => void;
  onAction?: () => void;
  buttonLabel?: string;
  variant?: ToastVariant;
};
