import { Linking, Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import { config } from '@app/config';

const APPSTORE_ID = 'id1537425358';
const PLAYSTORE_ID = 'com.masteos';

interface UseMinimalVersion {
  currentVersion: string;
  minimalVersion: string;
  hasToUpdate: boolean;
  openInStore: () => void;
  openAppStore: () => void;
  openPlayStore: () => void;
}

/**
 * Compare two semver together
 * - 0 if equals
 * - 1 if 'a' is higher than 'b'
 * - -1 if 'a' is lower than 'b'
 * @param {string} a
 * @param {string} b
 * @return {number}
 */
export const compareSemver = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });

/**
 * Try to open a deeplink or fallback to an web url
 *
 * @param {string} url - Any scheme url
 * @param {string} [fallback] - HTTP/HTTPS url
 */
const open = (url: string, fallback?: string) => {
  Linking.canOpenURL(url)
    .then(supported => (supported ? Linking.openURL(url) : open(fallback)))
    .catch(e => (fallback ? open(fallback) : console.error(e)));
};

/**
 * Open the app page in App Store
 */
export const openAppStore = (): void =>
  open(
    `itms-apps://apps.apple.com/app/${APPSTORE_ID}`,
    `https://apps.apple.com/app/${APPSTORE_ID}`
  );

/**
 * Open the app page in Play Store
 */
export const openPlayStore = (): void =>
  open(`https://play.google.com/store/apps/details?id=${PLAYSTORE_ID}`);

/**
 * Open the app page in corresponding store based on OS
 */
const openInStore = (): void => {
  Platform.select({ android: openPlayStore, ios: openAppStore })();
};

export const useMinimalVersion = (): UseMinimalVersion => {
  const minimalVersion = config.MINIMAL_VERSION || '0';
  const currentVersion = DeviceInfo.getVersion();

  return {
    currentVersion,
    hasToUpdate: compareSemver(minimalVersion, currentVersion) === 1,
    minimalVersion,
    openAppStore,
    openInStore,
    openPlayStore,
  };
};
