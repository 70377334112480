import { useState, useMemo, useCallback } from 'react';

import { LayoutChangeEvent } from 'react-native';

import { useContainerQueriesHookType } from './useContainerQueriesHook.types';
import { matchesWidths } from './useContainerQueriesHook.utils';

export { useContainerQueriesHookType };
/**
 * Hook to detect the size of the parent container.
 * Returns a query array with the expected maximum width value.
 * Returns the list of queries with boolean that informs if it matches
 * @type {[type]}
 */
export const useContainerQueriesHook: useContainerQueriesHookType = ({ query }) => {
  const [width, setWidth] = useState<number>(0);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;

      layout.width !== width && setWidth(layout.width);
    },
    [width, setWidth]
  );

  const matches = useMemo(() => matchesWidths({ query, width }), [width, query]);

  return { matches, onLayout };
};
