import { useContext } from 'react';

import { useMediaQuery } from 'react-responsive';

import { Breakpoints } from '@app/ui/theme/Sizes';
import { ResponsiveContext } from '@app/ui/contexts/responsive/ResponsiveContext';
import { isNative } from '@app/constants/platform';

import { getDevice, isGreaterThanScreen, isLowerThanScreen, isScreen } from './responsive';
import { Device, ScreenValue } from './types';

export interface UseResponsiveValue extends ScreenValue {
  device: Device;
  is: (device: Device) => boolean;
  isGreaterThanLaptop: boolean;
  isGreaterThanMobile: boolean;
  isGreaterThanTablet: boolean;
  isGreaterThanDesktop: boolean;
  isGreaterThanWide: boolean;
  isLowerThanLaptop: boolean;
  isLowerThanWide: boolean;
  isLowerThanTablet: boolean;
  isLowerThanDesktop: boolean;
  isLowerThanUltraWide: boolean;
  isLargeMobile: boolean;
  isNativeOrResponsive: boolean;
}

export const useWebResponsive = (): UseResponsiveValue => {
  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.maxMobile,
  });

  const isLargeMobile = useMediaQuery({
    maxWidth: Breakpoints.largeMobile,
  });

  const isTablet = useMediaQuery({
    maxWidth: Breakpoints.maxTablet,
    minWidth: Breakpoints.minTablet,
  });

  const isLaptop = useMediaQuery({
    maxWidth: Breakpoints.maxLaptop,
    minWidth: Breakpoints.minLaptop,
  });

  const isDesktop = useMediaQuery({
    maxWidth: Breakpoints.maxDesktop,
    minWidth: Breakpoints.minDesktop,
  });

  const isWide = useMediaQuery({
    maxWidth: Breakpoints.maxWide,
    minWidth: Breakpoints.minWide,
  });

  const isNativeOrResponsive = isNative() || isLargeMobile;

  const isUltraWide = useMediaQuery({ minWidth: Breakpoints.minUltraWide });

  const screenValues = {
    isDesktop,
    isLaptop,
    isLargeMobile,
    isMobile,
    isTablet,
    isUltraWide,
    isWide,
  };

  const is = (device: Device) => isScreen(device, screenValues);

  const isGreaterThan = (device: Omit<Device, 'wide'>) => isGreaterThanScreen(device, screenValues);

  const isLowerThan = (device: Omit<Device, 'mobile'>) => isLowerThanScreen(device, screenValues);

  return {
    device: getDevice({
      isDesktop,
      isLaptop,
      isMobile,
      isTablet,
      isUltraWide,
      isWide,
    }),
    is,
    isDesktop,
    isGreaterThanDesktop: isGreaterThan('desktop'),
    isGreaterThanLaptop: isGreaterThan('laptop'),
    isGreaterThanMobile: isGreaterThan('mobile'),
    isGreaterThanTablet: isGreaterThan('tablet'),
    isGreaterThanWide: isGreaterThan('wide'),
    isLaptop,
    isLargeMobile,
    isLowerThanDesktop: isLowerThan('desktop'),
    isLowerThanLaptop: isLowerThan('laptop'),
    isLowerThanTablet: isLowerThan('tablet'),
    isLowerThanUltraWide: isLowerThan('ultraWide'),
    isLowerThanWide: isLowerThan('wide'),
    isMobile,
    isNativeOrResponsive,
    isTablet,
    isUltraWide,
    isWide,
  };
};

export const defaultValueMobile = {
  device: 'mobile',
  is: (device: Device) => device === 'mobile',
  isDesktop: false,
  isGreaterThanDesktop: false,
  isGreaterThanLaptop: false,
  isGreaterThanMobile: false,
  isGreaterThanTablet: false,
  isGreaterThanWide: false,
  isLaptop: false,
  isLargeMobile: false,
  isLowerThanDesktop: true,
  isLowerThanLaptop: true,
  isLowerThanTablet: true,
  isLowerThanUltraWide: true,
  isLowerThanWide: true,
  isMobile: true,
  isNativeOrResponsive: true,
  isTablet: false,
  isUltraWide: false,
  isWide: false,
} as UseResponsiveValue;

export const useNativeResponsive = (): UseResponsiveValue => defaultValueMobile;

export const useResponsive = (): UseResponsiveValue => {
  const values = useContext<UseResponsiveValue>(ResponsiveContext);
  return values;
};
