import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTarget = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 22a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 012 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0112 2h.25c.217 0 .396.07.538.212A.731.731 0 0113 2.75v7.525c.3.183.542.42.725.712.183.292.275.63.275 1.013 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0112 14c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0110 12c0-.383.092-.725.275-1.025.183-.3.425-.533.725-.7v-2.15a4.033 4.033 0 00-2.15 1.412C8.283 10.246 8 11.067 8 12c0 1.1.392 2.042 1.175 2.825C9.958 15.608 10.9 16 12 16s2.042-.392 2.825-1.175C15.608 14.042 16 13.1 16 12c0-.383-.054-.763-.162-1.138A3.966 3.966 0 0015.35 9.8c-.15-.233-.212-.463-.187-.688a.958.958 0 01.287-.587c.2-.2.454-.3.762-.3.309 0 .555.133.738.4.367.533.633 1.087.8 1.662.167.575.25 1.146.25 1.713 0 1.667-.583 3.083-1.75 4.25C15.083 17.417 13.667 18 12 18c-1.667 0-3.083-.583-4.25-1.75C6.583 15.083 6 13.667 6 12c0-1.5.475-2.804 1.425-3.912.95-1.109 2.142-1.78 3.575-2.013V4.05c-1.983.25-3.646 1.125-4.987 2.625C4.671 8.175 4 9.95 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-.983-.142-1.867-.425-2.65a7.938 7.938 0 00-1.2-2.175 1.13 1.13 0 01-.262-.75c.008-.267.112-.5.312-.7.2-.2.438-.3.713-.3.275 0 .495.108.662.325.7.85 1.242 1.792 1.625 2.825C21.808 9.608 22 10.75 22 12a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0112 22z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTarget;
