import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChart = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M8.497 17.074a.968.968 0 00.713-.288.967.967 0 00.287-.712v-5a.97.97 0 00-.287-.713.97.97 0 00-.713-.287.97.97 0 00-.713.287.97.97 0 00-.287.713v5c0 .283.096.52.287.712.192.192.43.288.713.288zm4 0a.968.968 0 00.713-.288.967.967 0 00.287-.712v-8a.97.97 0 00-.287-.713.97.97 0 00-.713-.287.967.967 0 00-.712.287.968.968 0 00-.288.713v8c0 .283.096.52.288.712a.965.965 0 00.712.288zm4 0c.284 0 .521-.096.712-.288a.965.965 0 00.288-.712v-2a.968.968 0 00-.288-.713.966.966 0 00-.712-.287.967.967 0 00-.712.287.968.968 0 00-.288.713v2c0 .283.096.52.288.712a.965.965 0 00.712.288zm-11 4c-.55 0-1.02-.196-1.413-.587a1.928 1.928 0 01-.587-1.413v-14c0-.55.196-1.021.587-1.413a1.928 1.928 0 011.413-.587h14c.55 0 1.021.196 1.413.587.392.392.587.863.587 1.413v14c0 .55-.195 1.021-.587 1.413a1.928 1.928 0 01-1.413.587h-14zm0-2h14v-14h-14v14z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChart;
