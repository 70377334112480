import React from 'react';

import { View } from 'react-native';

import { palette } from '@app/ui/theme/palette';
import { Spacing } from '@app/ui/theme/Sizes';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Text } from '@app/ui/atoms/Text/Text';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { Icon } from '@app/ui/atoms/Icon';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';

import { HeaderPageProps } from './HeaderPage.types';
import { getHeaderPageStyles } from './HeaderPage.styles';

export { HeaderPageProps };

export const HeaderPage: React.FC<HeaderPageProps> = ({
  title,
  testID,
  bottomElement,
  subtitle,
  onBack,
  rightElement,
}) => {
  const { isMobile } = useResponsive();
  const style = getHeaderPageStyles(isMobile);

  return (
    <View style={style.root} testID={testID}>
      <View style={style.wrapper}>
        {!!onBack && (
          <View style={style.iconWrapper}>
            <Pressable onPress={onBack} testID="header-back-btn">
              <Icon name="ArrowLeft" onPress={onBack} />
            </Pressable>
          </View>
        )}
        <View style={style.titleAndSubtitle}>
          <View style={style.topWrapper}>
            <Text
              textStyle="Headline1Medium"
              style={{ color: palette.base.black }}
              testID="header-title"
            >
              {title}
            </Text>
            {subtitle ? <View style={style.subtitle}>{subtitle}</View> : null}
          </View>
          {rightElement ? rightElement : null}
        </View>
      </View>
      <View style={style.wrapper}>
        {!!onBack && <View style={style.icon} />}
        {bottomElement ? (
          <View style={style.bottomWrapper}>
            <Spacer height={Spacing.SIZE_02} />
            <Text textStyle="Body2">{bottomElement}</Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};
