import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

export const getStyles = ({
  topOffset,
}: {
  topOffset: number;
}): Record<string, StyleProp<ViewStyle>> =>
  StyleSheet.create({
    componentOffset: { marginTop: -topOffset },
    container: {
      flex: 1,
    },
  });
