import { TFunction } from 'react-i18next';

import { RealEstate } from '@app/libs/apollo/introspection';

export const getFormattedAddressFromProperty = (
  realEstate: RealEstate,
  t: TFunction,
  isLowerThanLaptop: boolean
): { address: string; addressGoogleMapModal: string } => {
  const addressTranslateProps = {
    city: realEstate?.building.address.city || '',
    country: realEstate?.building.address.country || '',
    postalCode: realEstate?.building.address.postalCode || '',
    street: realEstate?.building.address.street || '',
  };
  const streetAddress = realEstate?.building.address.street || '';
  const addressTranslation = t(
    isLowerThanLaptop ? 'propertyInfo.addressWithBreak' : 'propertyInfo.address',
    addressTranslateProps
  );

  let address = '';
  if (streetAddress) {
    address = isLowerThanLaptop ? addressTranslation : `${streetAddress}, ${addressTranslation}`;
  }

  const addressGoogleMapModal = `${streetAddress}, ${t(
    'propertyInfo.address',
    addressTranslateProps
  )}`;

  return {
    address,
    addressGoogleMapModal,
  };
};
