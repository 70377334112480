import { Platform, StyleSheet } from 'react-native';

import { HORIZONTAL_PADDING } from '@app/features/project/Project.styles';

const CONTENT_PADDING = {
  DEFAULT: 32,
  DESKTOP: 40,
};
const TABVIEW_CONTENT_PADDING = 16;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getProjectTabScreenStyles = ({
  isLowerThanTablet,
  isLowerThanLaptop,
}: {
  isLowerThanTablet: boolean;
  isLowerThanLaptop: boolean;
}) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: Platform.select({
        native: isLowerThanTablet ? HORIZONTAL_PADDING.MOBILE : 0,
      }),
      paddingTop:
        (isLowerThanLaptop ? CONTENT_PADDING.DEFAULT : CONTENT_PADDING.DESKTOP) -
        TABVIEW_CONTENT_PADDING,
    },
  });
