import React, { PropsWithChildren } from 'react';

import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { isNative } from '@app/constants/platform';

const getStyles = (isMobile: boolean) =>
  StyleSheet.create({
    container: { paddingHorizontal: isMobile ? 20 : 0 },
  });

interface DebugMenuHeaderProps {
  style?: StyleProp<ViewStyle>;
}

export const DebugMenuContentWrapper = ({
  children,
  style,
}: PropsWithChildren<DebugMenuHeaderProps>): JSX.Element => {
  const styles = getStyles(isNative());

  return (
    <View style={[styles.container, style]} testID="wrapper">
      {children}
    </View>
  );
};
