import React, { useCallback, useRef, useState } from 'react';

import { TextInput, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Input, Pressable, Spacer, Text, useTheme } from '@app/ui';
import { LayoutSideText } from '@app/shared/components/LayoutSideText/LayoutSideText';
import { DismissKeyboard } from '@app/shared/containers/DismissKeyboard';
import { resetInfinityScroll } from '@app/utils/propertiesSearchResults';
import { SubscriptionHeader } from '@app/shared/components/SubscriptionHeader/SubscriptionHeader';
import {
  ForgotPasswordNavigatorRoutes,
  PublicNavigatorRoutes,
  PublicStackParamList,
} from '@app/navigation/types/routes';
import { useQueryParam } from '@app/shared/hooks/useQueryParams';
import { useAuthentication } from '@app/shared/hooks/useAuthentication';
import { trackForgotPassword } from '@app/services/tracking/trackTracking';

import { getStyles } from './loginEmailPassword.style';

interface InitialValues {
  email: string;
  password: string;
}

type LoginEmailPasswordNavigationProp = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.Login
>;

const validationSchema = yup.object().shape({
  email: yup.string(),
  password: yup.string(),
});

export const LoginEmailPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<LoginEmailPasswordNavigationProp>();
  const theme = useTheme();
  const { loginWithEmailPassword } = useAuthentication();
  const styles = getStyles(theme);

  const [loading, setLoading] = useState(false);
  const emailFromQueryParam = useQueryParam('email')?.toLowerCase().trim();

  const { control, handleSubmit, setError, watch } = useForm<InitialValues>({
    defaultValues: { email: emailFromQueryParam ?? '', password: '' },
    resolver: yupResolver(validationSchema),
  });

  const handleGoBack = () => navigation.navigate(PublicNavigatorRoutes.Auth);

  const onLogin = useCallback(
    ({ email, password }: InitialValues): void => {
      setLoading(true);
      loginWithEmailPassword(email.toLowerCase().trim(), password.trim())
        .then(() => resetInfinityScroll())
        .catch(err => {
          if (err.response.status === 401) {
            setError('password', t('login.invalidCreds'));
          } else {
            setError('password', t('shared.genericError'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [loginWithEmailPassword, setError, t]
  );

  const emailWatcher = watch('email');
  const onPressOnForgotPassword = () => {
    trackForgotPassword();
    navigation.navigate(PublicNavigatorRoutes.ForgotPassword, {
      params: { email: emailWatcher },
      screen: ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep,
    });
  };

  const passwordRef = useRef<TextInput>(null);

  return (
    <LayoutSideText>
      <SubscriptionHeader title={t('shared.connexion')} onPressArrow={handleGoBack} />
      <DismissKeyboard>
        <View style={styles.container}>
          <View style={styles.form}>
            <Controller
              control={control}
              name="email"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect={false}
                  keyboardType="email-address"
                  label={t('shared.email')}
                  placeholder={t('register.emailPlaceholder')}
                  testID="login-email-input"
                  textContentType="emailAddress"
                  value={value}
                  onChangeText={onChange}
                  error={error?.message}
                  onSubmitEditing={() => passwordRef.current?.focus()}
                />
              )}
            />

            <Spacer height={theme.spacing.SIZE_03} />
            <Controller
              control={control}
              name="password"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  autoCapitalize="none"
                  autoComplete="password"
                  autoCorrect={false}
                  autoFocus
                  isPassword
                  label={t('shared.password')}
                  testID="login-password-input"
                  textContentType="password"
                  value={value}
                  onChangeText={onChange}
                  onSubmitEditing={handleSubmit(onLogin)}
                  error={error?.message}
                  ref={passwordRef}
                />
              )}
            />

            <Spacer height={theme.spacing.SIZE_05} />
            <Pressable onPress={onPressOnForgotPassword}>
              <Text textStyle="Body2" style={styles.forgotPasswordLink}>
                {t('login.forgotPassword')}
              </Text>
            </Pressable>

            <Spacer height={theme.spacing.SIZE_07} />
            <Button
              variant="primary"
              testID="login-submit-button"
              onPress={handleSubmit(onLogin)}
              isLoading={loading}
            >
              {t('shared.login')}
            </Button>
          </View>
        </View>
      </DismissKeyboard>
    </LayoutSideText>
  );
};
