import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgDj = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="dj_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#dj_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28V9.333H0V20z" fill="#1DC837" />
        <Path d="M0 9.333h28V0H0v9.333z" fill="#82C5F5" />
        <Path d="M0 0l13.333 10L0 20V0z" fill="#fff" />
        <Path
          d="M4.667 11.3l-1.96 1.397.723-2.295L1.496 8.97l2.407-.022.764-2.281.764 2.281 2.406.022-1.934 1.432.723 2.295-1.96-1.397z"
          fill="#E21C21"
        />
      </G>
    </Svg>
  );
};

export default SvgDj;
