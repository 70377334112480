import React from 'react';

import { Container, List } from './DropdownListContainer.styles.web';
import { DropdownListContainerType } from './DropdownListContainer.types';

export { DropdownListContainerType };

export const DropdownListContainer: React.FC<DropdownListContainerType> = ({
  header,
  list,
  footer,
  maxHeight,
}) => {
  return (
    <Container $maxHeight={maxHeight} data-testid="dropdown-list-container-container">
      {!!header && <div data-testid="dropdown-list-container-header">{header}</div>}

      <List data-testid="dropdown-list-container-scrollView">{list}</List>

      {!!footer && <div data-testid="dropdown-list-container-footer">{footer}</div>}
    </Container>
  );
};
