import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';

import { ActionButton, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
interface RealEstateActionButtonsProps {
  shouldUseShare: boolean;
  isFavorite: boolean;
  onLike?: () => void;
  onShare: () => void;
  onPress3dUrl?: () => void;
}

const getStyles = ({
  theme,
  isLowerThanLaptop,
  isMobile,
  inset,
}: {
  theme: Theme;
  isLowerThanLaptop: boolean;
  isMobile: boolean;
  inset: EdgeInsets;
}) =>
  StyleSheet.create({
    actionButtons: {
      flexDirection: 'row',
      gap: isLowerThanLaptop ? 0 : theme.spacing.SIZE_03,
      ...(isLowerThanLaptop && {
        position: 'absolute',
        right: isMobile ? 4 : 12,
        top: isMobile ? inset.top + 8 : 12,
      }),
    },
  });

export const RealEstateActionButtons: React.FC<RealEstateActionButtonsProps> = ({
  isFavorite,
  shouldUseShare,
  onLike,
  onShare,
  onPress3dUrl,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inset = useSafeAreaInsets();
  const { isLowerThanLaptop, isMobile } = useResponsive();
  const styles = useMemo(
    () => getStyles({ inset, isLowerThanLaptop, isMobile, theme }),
    [inset, isLowerThanLaptop, theme, isMobile]
  );

  return (
    <View style={styles.actionButtons}>
      <ActionButton
        withSolidBorder={!isLowerThanLaptop}
        leftIconName={shouldUseShare ? 'Share' : 'Link'}
        iconColor={theme.palette.neutral[600]}
        onPress={onShare}
      />
      {!!onLike && (
        <ActionButton
          testID="real-estate-action-buttons-like"
          withSolidBorder={!isLowerThanLaptop}
          leftIconName="HeartFilled"
          iconColor={isFavorite ? theme.palette.tint.red[500] : theme.palette.neutral[600]}
          onPress={onLike}
        />
      )}
      {!!onPress3dUrl && (
        <ActionButton
          testID="real-estate-action-buttons-3d-visit"
          withSolidBorder={!isLowerThanLaptop}
          leftIconName="Cube"
          label={t('shared.virtualVisitLabel')}
          onPress={onPress3dUrl}
        />
      )}
    </View>
  );
};
