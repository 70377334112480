import 'intl-pluralrules';
import i18n, { InitOptions, i18n as I18n } from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Platform } from 'react-native';

import { enUS, esES, frFR } from '@app/langs';
import { storage } from '@app/services/storage/storage';

import { nativeDetector } from './detectors/nativeDetector';
import { debugDetector } from './detectors/debugDetector';
import { I18nDefaultLanguage, I18nSupportedLanguage } from './i18n.types';

const defaultResources = {
  [I18nSupportedLanguage.es]: {
    translation: esES,
  },
  [I18nSupportedLanguage.fr]: {
    translation: frFR,
  },
  [I18nSupportedLanguage.en]: {
    translation: enUS,
  },
};

// order and from where user language should be detected
const order = Platform.select({
  native: ['masteosStorage', 'native'],
  web: ['debug', 'masteosStorage', 'querystring', 'navigator'],
});

const defaultOptions: InitOptions = {
  debug: false,
  detection: {
    order,
  },
  fallbackLng: {
    default: [I18nDefaultLanguage],
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  load: 'all',
  nonExplicitSupportedLngs: true,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    transSupportBasicHtmlNodes: true,
    useSuspense: false,
  },
  resources: defaultResources,
} as const;

export const changeLanguage = (newLanguage: I18nSupportedLanguage): void => {
  i18n.changeLanguage(newLanguage);
};

export const initialize = async (options = defaultOptions): Promise<I18n> => {
  const localStorageLang: string = await storage.readString('i18nextLng');
  const instance = i18n.createInstance();
  const languageDetector = new LanguageDetector();

  languageDetector.addDetector(nativeDetector);
  languageDetector.addDetector({
    lookup: () => localStorageLang,
    name: 'masteosStorage',
  });
  languageDetector.addDetector(debugDetector);

  await instance
    .use(languageDetector)
    .use(initReactI18next)
    .init({ ...defaultOptions, ...options });

  return instance;
};

export { i18n, useTranslation };
