import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTj = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="tj_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#tj_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 6.667h28V0H0v6.667z" fill="#D9101C" />
        <Path d="M0 20h28v-6.667H0V20z" fill="#0F7811" />
        <Path
          d="M13.333 8.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0zM14.667 10c0 .12-.032.377-.088.667h.754a.667.667 0 110 1.333h-2.666a.667.667 0 010-1.333h.754a4.357 4.357 0 01-.088-.667.667.667 0 011.334 0zM16 8.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm2 2a.667.667 0 100-1.334.667.667 0 000 1.334zM10.667 10a.667.667 0 11-1.334 0 .667.667 0 011.334 0z"
          fill="#FAD14E"
        />
      </G>
    </Svg>
  );
};

export default SvgTj;
