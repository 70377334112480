import * as React from 'react';

import Svg, { SvgProps, G, Path, Mask, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const Tram = ({ color, ...props }: SvgProps) => (
  <Svg width={50} height={50} viewBox="3 1 50 50" fill="none" {...props}>
    <G>
      <Path
        fill={color ?? '#0050C7'}
        d="M27.982 33c-.33 0-.6-.097-.812-.291a1.01 1.01 0 0 1-.318-.755v-9.943h2.296v9.943a.986.986 0 0 1-.335.755c-.212.194-.49.291-.83.291Zm-3.904-10.029c-.306 0-.566-.091-.778-.274a.929.929 0 0 1-.3-.72c0-.297.1-.531.3-.703.212-.183.471-.274.778-.274h7.844c.306 0 .56.091.76.274a.905.905 0 0 1 .318.72.905.905 0 0 1-.318.72c-.2.172-.454.257-.76.257h-7.844Z"
      />
      <Mask id="b" fill="#fff">
        <Path
          fillRule="evenodd"
          d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
          clipRule="evenodd"
        />
      </Mask>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M30.12 40.803C37.954 39.833 44 33.56 44 25.967 44 17.7 36.837 11 28 11s-16 6.7-16 14.967c0 7.419 5.77 13.577 13.342 14.76L27.772 43l2.348-2.197Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="m30.12 40.803-.123-.992-.323.04-.238.222.684.73Zm-4.778-.075.683-.73-.224-.21-.304-.048-.155.988ZM27.772 43l-.684.73.683.64.683-.64-.683-.73ZM43 25.967c0 7.031-5.61 12.928-13.003 13.844l.245 1.985C38.52 40.77 45 34.122 45 25.966h-2ZM28 12c8.348 0 15 6.315 15 13.967h2C45 17.087 37.325 10 28 10v2ZM13 25.967C13 18.315 19.652 12 28 12v-2c-9.325 0-17 7.087-17 15.967h2ZM25.497 39.74C18.355 38.623 13 32.837 13 25.967h-2c0 7.967 6.187 14.498 14.188 15.749l.309-1.976Zm2.957 2.53-2.429-2.272-1.366 1.46 2.43 2.272 1.365-1.46Zm.982-2.197-2.348 2.197 1.366 1.46 2.349-2.197-1.367-1.46Z"
        mask="url(#b)"
      />
      <Path
        fill={color ?? '#0050C7'}
        d="M27.975 34.65c-.462 0-.84-.128-1.138-.383-.296-.27-.445-.6-.445-.99v-13.05h3.216v13.05c0 .39-.157.72-.47.99-.297.255-.685.383-1.163.383ZM22.51 21.487c-.429 0-.792-.12-1.088-.36-.28-.24-.421-.555-.421-.945 0-.39.14-.697.42-.922.297-.24.66-.36 1.089-.36H33.49c.429 0 .783.12 1.064.36.297.24.445.555.445.945 0 .39-.148.705-.445.945-.28.225-.635.337-1.064.337H22.51Z"
      />
    </G>
    <Defs></Defs>
  </Svg>
);
export { Tram };
