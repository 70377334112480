import React, { useMemo } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Image, Theme, useResponsive, useTheme } from '@app/ui';
import { ContactableUser } from '@app/libs/apollo/introspection';

import { MAX_IMAGE_WIDTH } from '../hooks/useGallery';
import { SliderImageObject } from '../gallery.types';

export interface CarouselImageProps {
  image: SliderImageObject;
  contact?: ContactableUser;
}

const getStyles = ({
  theme,
  isLowerThanLaptop,
  width,
}: {
  theme: Theme;
  isLowerThanLaptop: boolean;
  width: number;
}) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    image: {
      backgroundColor: theme.palette.neutral[900],
      width: isLowerThanLaptop ? width : MAX_IMAGE_WIDTH,
    },
  });

export const CarouselImage: React.FC<CarouselImageProps> = ({ image }) => {
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const { width } = useWindowDimensions();
  const styles = useMemo(
    () => getStyles({ isLowerThanLaptop, theme, width }),
    [theme, isLowerThanLaptop, width]
  );

  return (
    <View style={styles.container}>
      <Image source={{ uri: image.url }} style={styles.image} resizeMode="contain" />
    </View>
  );
};
