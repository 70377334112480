import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mz_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-5.333H0V20z" fill="#FFE547" />
        <Path d="M0 5.333h28V0H0v5.333z" fill="#118C81" />
        <Path d="M0 14.667h28V5.333H0v9.334z" fill="#fff" />
        <Path d="M0 13.333h28V6.667H0v6.666z" fill="#262626" />
        <Path d="M0 0l13.333 10L0 20V0z" fill="#EF2147" />
        <Path
          d="M4.667 11.274l-1.96 1.423.748-2.303L1.496 8.97h2.422l.749-2.303.749 2.302h2.42l-1.958 1.425.748 2.303-1.96-1.423z"
          fill="#FDE444"
        />
      </G>
    </Svg>
  );
};

export default SvgMz;
