import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgThumbsUp = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.086 1.594A1 1 0 0111 1a4 4 0 014 4v3h4.655a3 3 0 012.994 3.45l-1.38 9A3.002 3.002 0 0118.275 23H4a3 3 0 01-3-3v-7a3 3 0 013-3h2.35l3.736-8.406zM8 11.212l3.608-8.117A2 2 0 0113 5v4a1 1 0 001 1h5.671a1 1 0 011 1.15l-1.38 9a1 1 0 01-1 .85H8v-9.788zM6 21v-9H4a1 1 0 00-1 1v7a1 1 0 001 1h2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgThumbsUp;
