import { useContext } from 'react';

import { TranslationContentValue } from '@app/ui/atoms/translation-content/TranslationContent.types';
import { TranslationContentContext } from '@app/ui/organisms/translation-content-group/TranslationContentGroup';

type UseTranslationContentContext = () => {
  getTranslationContent: (translation: TranslationContentValue) => string | undefined;
  showTranslation: boolean;
  toggleTranslation: (() => void) | undefined;
};

export const useTranslationContentContext: UseTranslationContentContext = () => {
  const translationContentContext = useContext(TranslationContentContext);

  const getTranslationContent = (translation: TranslationContentValue) => {
    const { sourceText, translatedText } = translation;

    return translationContentContext.showTranslation ? translatedText : sourceText;
  };

  return {
    getTranslationContent,
    showTranslation: translationContentContext.showTranslation,
    toggleTranslation: translationContentContext.toggleTranslation,
  };
};
