import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgShield = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.807 21.875h-.25a.495.495 0 01-.225-.05c-2.183-.684-3.983-2.038-5.4-4.063-1.416-2.025-2.125-4.262-2.125-6.712V6.325c0-.417.121-.792.363-1.125.242-.334.554-.575.937-.725l6-2.25c.234-.084.467-.125.7-.125.234 0 .467.041.7.125l6 2.25c.384.15.696.391.938.725.242.333.362.708.362 1.125v4.725c0 2.45-.708 4.687-2.125 6.712-1.416 2.025-3.216 3.38-5.4 4.063-.083.033-.241.05-.475.05zm0-2.025c1.734-.55 3.167-1.65 4.3-3.3 1.134-1.65 1.7-3.484 1.7-5.5V6.325l-6-2.25-6 2.25v4.725c0 2.016.567 3.85 1.7 5.5 1.134 1.65 2.567 2.75 4.3 3.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgShield;
