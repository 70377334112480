import * as React from 'react';

import Svg, { Path, Mask, G } from 'react-native-svg';

const SvgAf = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Mask
        id="af_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      </Mask>
      <G mask="url(#af_svg__a)">
        <Path d="M13.333 0H28v20H13.333V0z" fill="#1AB11F" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h9.333V0H0v20z" fill="#262626" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 20h9.334V0H9.333v20z"
          fill="#DC0D18"
        />
        <Path
          opacity={0.75}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.333 8.667c.368 0 .667.298.667.666 0 .739.4 1.385.998 1.732a.667.667 0 01-.669 1.153 3.332 3.332 0 01-1.662-2.885c0-.368.298-.666.666-.666zm5.334 0c.368 0 .666.298.666.666a3.331 3.331 0 01-1.539 2.81.667.667 0 11-.718-1.123c.557-.357.924-.979.924-1.687 0-.368.299-.666.667-.666z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 10.667c.736 0 1.333-.896 1.333-2 0-1.105-.597-2-1.333-2s-1.333.895-1.333 2c0 1.104.597 2 1.333 2z"
          fill="#fff"
          fillOpacity={0.5}
        />
      </G>
    </Svg>
  );
};

export default SvgAf;
