import { storage } from '@app/services/storage/storage';

import { useQueryParam } from './useQueryParams';

export const useOrigin = async (): Promise<void> => {
  const siteOriginValue = useQueryParam('site_origin')?.trim().toLowerCase();

  if (siteOriginValue) {
    await storage.saveString('site_origin', siteOriginValue);
  }
};
