import React, { useState } from 'react';

import { LayoutChangeEvent, StyleSheet, View, useWindowDimensions } from 'react-native';
import { CarouselRenderItemInfo } from 'react-native-reanimated-carousel/lib/typescript/types';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useGetRealEstateRealizationsQuery } from '@app/libs/apollo/introspection';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { useGalleryContext } from '@app/features/realEstate/gallery/GalleryProvider';
import { SliderImageObject } from '@app/features/realEstate/gallery/gallery.types';
import { useTranslation } from '@app/services/translations/translations';
import { resolveToMasteosLanguage } from '@app/utils/lang/resolveLanguageCode';

import {
  CARD_HEIGHT,
  RESPONSIVE_CARD_HEIGHT,
  RealEstateRealization,
  Realization,
} from './Realization';

const CARD_TO_SHOW = 3;

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      gap: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
    },
    title: {
      textAlign: 'center',
    },
  });

export const RenovationRealization: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { data, loading } = useGetRealEstateRealizationsQuery({
    fetchPolicy: 'no-cache',
    variables: { lang: resolveToMasteosLanguage(i18n.resolvedLanguage) },
  });
  const { openGallery } = useGalleryContext();

  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop);

  const [carouselWidth, setCarouselWidth] = useState(0);
  const PERCENT_CARD_WIDTH = isLowerThanLaptop ? 0.9 : 1;

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setCarouselWidth(isLowerThanLaptop ? width : nativeEvent.layout.width);
  };

  const handleOpenGallery = (images: SliderImageObject[]) => {
    openGallery({ images });
  };

  if (loading || !data) {
    return null;
  }

  return (
    <View style={styles.container} onLayout={onLayout}>
      <Text textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'} style={styles.title}>
        {t('realEstate.renovation.realisationExamples')}
      </Text>

      {carouselWidth > 0 && (
        <Carousel
          showDots={isLowerThanLaptop}
          enabled={isLowerThanLaptop}
          width={(carouselWidth * PERCENT_CARD_WIDTH) / (isLowerThanLaptop ? 1 : CARD_TO_SHOW)}
          style={{ width: carouselWidth }}
          panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
          loop={false}
          data={data.soldRealEstatesV2.slice(0, CARD_TO_SHOW)}
          height={isLowerThanLaptop ? RESPONSIVE_CARD_HEIGHT : CARD_HEIGHT}
          renderItem={({ item, index }: CarouselRenderItemInfo<RealEstateRealization>) => (
            <Realization realization={item} isFirst={index === 0} onPress={handleOpenGallery} />
          )}
        />
      )}
    </View>
  );
};
