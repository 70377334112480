import { TFunction } from 'i18next';

import { AvailableCountryCode } from '@app/libs/apollo/introspection';
// eslint-disable-next-line max-len
import { FilterGroupListSelectorProps } from '@app/ui/organisms/filter-group-list-selector/FilterGroupListSelector.types';

import { SearchEngineFiltersDrawerType } from '../search-engine-filters-drawer/searchEngineFiltersDrawer.type';

export const getZoneGroupOptions = ({
  regionZones,
  t,
}: {
  t: TFunction;
  regionZones: SearchEngineFiltersDrawerType['zoneLocationListByCountry'][AvailableCountryCode.Fr];
}): FilterGroupListSelectorProps['optionGroups'] => {
  return regionZones
    .map(region => ({
      label: t(`shared.regionNames.${region.regionCode}`),
      options: region.zones.map(zone => ({
        key: zone.id,
        label: zone.name,
      })),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
