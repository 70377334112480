import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBuilding = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V9c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 7h2V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 9 3h6c.55 0 1.02.196 1.413.587C16.803 3.98 17 4.45 17 5v6h2c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v6c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21h-6v-4h-2v4H5Zm0-2h2v-2H5v2Zm0-4h2v-2H5v2Zm0-4h2V9H5v2Zm4 4h2v-2H9v2Zm0-4h2V9H9v2Zm0-4h2V5H9v2Zm4 8h2v-2h-2v2Zm0-4h2V9h-2v2Zm0-4h2V5h-2v2Zm4 12h2v-2h-2v2Zm0-4h2v-2h-2v2Z"
    />
  </Svg>
);
export default SvgBuilding;
