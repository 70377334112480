import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgVg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="vg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#vg_svg__a)">
        <Path fill="#07319C" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.333 10V6.666c0-.737.597-1.333 1.334-1.333h4c.736 0 1.333.596 1.333 1.333V10c0 3.333-3.333 4.667-3.333 4.667S17.333 13.333 17.333 10z"
          fill="#008339"
        />
        <Mask
          id="vg_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={17}
          y={5}
          width={7}
          height={10}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.333 10V6.666c0-.737.597-1.333 1.334-1.333h4c.736 0 1.333.596 1.333 1.333V10c0 3.333-3.333 4.667-3.333 4.667S17.333 13.333 17.333 10z"
            fill="#fff"
          />
        </Mask>
        <G mask="url(#vg_svg__b)" fillRule="evenodd" clipRule="evenodd">
          <Path
            d="M19.333 8a.667.667 0 100-1.333.667.667 0 000 1.333zm0 1.333a.667.667 0 100-1.333.667.667 0 000 1.333zm0 1.334a.667.667 0 110-1.334.667.667 0 010 1.334zm0 0a.667.667 0 110 1.333.667.667 0 010-1.333zm3.334.666a.667.667 0 11-1.334 0 .667.667 0 011.334 0zm-.667-2a.667.667 0 110 1.334.667.667 0 010-1.334zM22 8a.667.667 0 100 1.333A.667.667 0 0022 8zm0 0a.667.667 0 110-1.333A.667.667 0 0122 8z"
            fill="#FFD033"
          />
          <Path
            d="M20.667 11.333c.368 0 .666-.895.666-2 0-1.104-.298-2-.666-2-.369 0-.667.896-.667 2 0 1.105.299 2 .667 2z"
            fill="#fff"
          />
        </G>
        <Path
          d="M16.667 13.333c0 1.272 1.82 2 4 2 2.178 0 4-.728 4-2a.667.667 0 00-1.334 0c0 .013-.161.166-.557.324-.537.215-1.294.343-2.11.343-.815 0-1.572-.128-2.109-.343-.395-.158-.557-.31-.557-.324a.667.667 0 00-1.333 0z"
          fill="#FFD033"
        />
        <Path
          d="M0-.333h-.901l.684.586 4.55 3.9v1.009L-.194 8.395l-.14.1v1.44l.51-.319L5.43 6.333h1.13l4.523 3.23a.554.554 0 00.682-.87L7.667 5.18V4.172l4.387-3.134a.667.667 0 00.28-.543V-.6l-.51.318L6.57 3h-1.13L.86-.271.772-.333H0z"
          fill="#DB1E36"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.333V6h4.667v3.333c0 .369.298.667.666.667h1.334a.667.667 0 00.666-.667V6H12a.667.667 0 00.667-.667V4A.667.667 0 0012 3.333H7.333V0H4.667v3.333H0z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h5.333V0h1.334v4H12v1.333H6.667v4H5.333v-4H0V4z"
          fill="#DB1E36"
        />
      </G>
    </Svg>
  );
};

export default SvgVg;
