import React, { useMemo } from 'react';

import { View } from 'react-native';

import {
  CheckboxTileGroupType,
  CheckboxTileGroupItemType,
} from '@app/ui/organisms/checkbox-tile-group/CheckboxTileGroup.types';
import { getStyles } from '@app/ui/organisms/checkbox-tile-group/CheckboxTileGroup.styles';
import { CheckboxTile } from '@app/ui/molecules/checkbox-tile/CheckboxTile';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { Text } from '@app/ui/atoms/Text/Text';
import { CheckboxTileVariantType } from '@app/ui/molecules/checkbox-tile/CheckboxTile.types';
import { Callout } from '@app/ui/molecules/callout/Callout';
import { useTheme } from '@app/ui/contexts/config';

export { CheckboxTileGroupType, CheckboxTileGroupItemType, CheckboxTileVariantType };

export const CheckboxTileGroup: React.FC<CheckboxTileGroupType> = ({
  items,
  disabled = false,
  checkboxTileVariant,
  testID,
  title,
  subtitle,
  calloutText,
  calloutIcon,
}: CheckboxTileGroupType) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);

  const checkboxList = useMemo(() => {
    return items.map(item => {
      return (
        <View style={styles.checkboxContainer} key={item.label.toLowerCase()}>
          <CheckboxTile
            label=""
            iconName="Svg360"
            onChange={function (): void {
              throw new Error('Function not implemented.');
            }}
            {...item}
            variant={checkboxTileVariant}
            disabled={!disabled && item.disabled ? item.disabled : disabled}
          />
        </View>
      );
    });
  }, [checkboxTileVariant, items, styles.checkboxContainer]);

  return (
    <View style={styles.mainContainer} testID={testID}>
      {!!title && (
        <Text
          testID="checkbox-tile-group-title-test-id"
          textStyle="Body1Medium"
          style={styles.title}
        >
          {title}
        </Text>
      )}
      {!!subtitle && (
        <Text
          testID="checkbox-tile-group-subtitle-test-id"
          textStyle="Body3"
          style={styles.subtitle}
        >
          {subtitle}
        </Text>
      )}
      {!!calloutText && (
        <Callout
          text={calloutText}
          icon={calloutIcon}
          testID="checkbox-tile-group-callout"
          wrapperStyle={styles.callout}
        />
      )}
      <View style={styles.checkboxGroupContainer}>{checkboxList}</View>
    </View>
  );
};
