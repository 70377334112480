import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgUg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ug_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ug_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 2.667h28V0H0v2.667zM0 13.333h28v-2.666H0v2.666z" fill="#262626" />
        <Path d="M0 10.667h28v-4H0v4z" fill="#ED1623" />
        <Path d="M0 6.667h28v-4H0v4z" fill="#FEE044" />
        <Path d="M0 20h28v-2.667H0V20z" fill="#ED1623" />
        <Path d="M0 17.333h28v-4H0v4z" fill="#FEE044" />
        <Path d="M14 14.667a4.667 4.667 0 100-9.334 4.667 4.667 0 000 9.334z" fill="#fff" />
        <Path
          d="M12.902 7.569a.333.333 0 010-.471l.412-.412a.395.395 0 00.035-.04c.035-.047.124-.16.229-.254.177-.161.68-.178.854-.015.048.045.09.094.127.14.072.09.076.212.025.315l-.549 1.098a.333.333 0 00-.035.149v.382c0 .126.071.241.184.298l1.101.55a.335.335 0 01.087.063l1.125 1.125a.333.333 0 01.062.385l-.352.704a.333.333 0 01-.534.087l-.104-.104a.333.333 0 00-.471 0l-.334.333a.333.333 0 00-.097.236v.39c0 .089.035.174.097.236.21.21.062.57-.235.57h-.39a.333.333 0 01-.237-.57.333.333 0 00.098-.235v-.196a.333.333 0 00-.333-.333h-.196a.333.333 0 00-.235.098l-.334.333a.333.333 0 01-.471 0l-.195-.195a.333.333 0 010-.472l.333-.333a.333.333 0 01.236-.098h.39a.333.333 0 00.236-.569L12.236 9.57a.333.333 0 010-.471l.862-.862a.333.333 0 000-.472l-.196-.195z"
          fill="#262626"
        />
      </G>
    </Svg>
  );
};

export default SvgUg;
