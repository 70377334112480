import React, { CSSProperties } from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Button } from '@app/ui/molecules/Button';

import { ItemProps } from '../BottomBarMenu.types';
import { bottomBarMenuTheme } from '../bottomBarMenu.theme';

export const Item: React.FC<ItemProps> = ({
  iconName,
  onPress,
  testID = '',
  wrapperStyle = {},
}) => {
  const componentTheme = useComponentTheme('bottomBarMenu', bottomBarMenuTheme);

  return (
    <div style={wrapperStyle as CSSProperties}>
      {!!iconName && (
        <Button
          testID={testID}
          onPress={onPress}
          variant="ghost"
          style={componentTheme.menuItem.button}
          iconName={iconName}
        />
      )}
    </div>
  );
};
