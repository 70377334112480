import { StyleSheet } from 'react-native';

import { Spacing } from '@app/ui/theme/Sizes';

export const getStyles = ({ isLowerThanTablet }: { isLowerThanTablet: boolean }) =>
  StyleSheet.create({
    listContainer: {
      paddingHorizontal: isLowerThanTablet ? Spacing.SIZE_07 : Spacing.SIZE_04,
      paddingVertical: Spacing.SIZE_04,
    },
    listHeader: {
      paddingHorizontal: Spacing.SIZE_07,
      paddingTop: 20,
    },
  });
