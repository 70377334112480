import { StyleSheet, ViewStyle } from 'react-native';

type getStylesParams = {
  isNativeOrResponsive: boolean;
};

export const getStyles = ({
  isNativeOrResponsive,
}: getStylesParams): { actionsContainer: ViewStyle; actionButton: ViewStyle } => {
  if (!isNativeOrResponsive) {
    return StyleSheet.create({
      actionButton: {
        flex: 1,
        width: 0,
      },
      actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
      },
    });
  }

  return StyleSheet.create({
    actionButton: {},
    actionsContainer: {},
  });
};
