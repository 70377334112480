import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgLr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="lr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#lr_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 0H13.333v2.667H28V0zm0 5.333H13.333V8H28V5.333zm-14.667 5.334H28v2.666H13.333v-2.666zM28 16H0v2.667h28V16z"
          fill="#E1244A"
        />
        <Path fill="#0C3F8E" d="M0 0h13.333v13.333H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.667 8.227L4.316 9.903l.867-2.754-2.32-1.718 2.887-.026.917-2.738.917 2.738 2.887.026-2.32 1.718.867 2.754-2.351-1.676z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgLr;
