import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgHeadset = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M5 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 18v-7c0-1.233.237-2.396.712-3.487A9.193 9.193 0 0 1 5.65 4.65a9.193 9.193 0 0 1 2.862-1.938A8.646 8.646 0 0 1 12 2c1.233 0 2.396.237 3.488.712A9.193 9.193 0 0 1 18.35 4.65a9.192 9.192 0 0 1 1.938 2.862A8.646 8.646 0 0 1 21 11v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 23h-6a.968.968 0 0 1-.713-.288A.968.968 0 0 1 12 22c0-.283.096-.52.287-.712A.968.968 0 0 1 13 21h6v-1h-2c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 15 18v-4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 17 12h2v-1c0-1.933-.683-3.583-2.05-4.95C15.583 4.683 13.933 4 12 4c-1.933 0-3.583.683-4.95 2.05C5.683 7.417 5 9.067 5 11v1h2c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 7 20H5Zm0-2h2v-4H5v4Zm12 0h2v-4h-2v4Z"
    />
  </Svg>
);
export default SvgHeadset;
