import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyle = ({
  theme,
  isActive,
  isLowerThanDesktop,
}: {
  theme: Theme;
  isActive: boolean;
  isLowerThanDesktop: boolean;
}) => {
  return StyleSheet.create({
    animatedBorder: {
      backgroundColor: theme.palette.system.primary[500],
      height: 1,
      left: 0,
      position: 'absolute',
      right: 0,
      top: Platform.select({ native: 46, web: isLowerThanDesktop ? 47 : 64 }),
    },
    label: {
      color: isActive ? theme.palette.system.primary[500] : theme.palette.neutral[900],
      padding: theme.spacing.SIZE_01,
    },
    tabLabel: {
      alignItems: 'center',
      height: Platform.select({
        native: 46,
        web: isLowerThanDesktop ? 47 : 64,
      }),
      justifyContent: 'center',
      paddingHorizontal: 16,
      position: 'relative',
    },
  });
};
