import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = ({
  theme,
  isLowerThanLaptop,
  isLowerThanTablet,
}: {
  theme: Theme;
  isLowerThanLaptop: boolean;
  isLowerThanTablet: boolean;
}) => {
  const imageHeight = isLowerThanTablet ? 275 : 400;
  const imageWidth = isLowerThanTablet ? 197 : 300;

  return StyleSheet.create({
    actionContainer: {
      flexDirection: isLowerThanLaptop ? 'column-reverse' : 'row',
      gap: theme.spacing.SIZE_03,
      width: 'auto',
    },
    container: {
      flexGrow: 1,
      flexShrink: 1,
    },
    description: {
      marginTop: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_06,
    },
    imageContainer: {
      alignItems: isLowerThanLaptop ? 'center' : 'flex-start',
      height: imageHeight,
      width: imageWidth,
    },
    subtitle: {
      color: theme.palette.system.primary[500],
    },
    text: {
      textAlign: isLowerThanLaptop ? 'center' : 'left',
    },
    textContainer: {
      alignItems: isLowerThanLaptop ? 'center' : 'flex-start',
      flexGrow: 1,
      marginBottom: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_06,
      width: '100%',
    },
    titleMobile: {
      fontSize: 30,
    },
    wrapper: {
      alignItems: 'center',
      flexDirection: isLowerThanLaptop ? 'column' : 'row',
      gap: isLowerThanLaptop
        ? isLowerThanTablet
          ? theme.spacing.SIZE_05
          : theme.spacing.SIZE_09
        : theme.spacing.SIZE_10,
      height: '100%',
      justifyContent: 'space-between',
      maxWidth: isLowerThanLaptop ? 496 : 748,
    },
  });
};
