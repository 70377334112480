import React from 'react';

import { StyleSheet, View } from 'react-native';
import { RESULTS, openSettings } from 'react-native-permissions';

import { useTranslation } from '@app/services/translations/translations';
import { Icon, Pressable, Text, Theme, useTheme } from '@app/ui';
import { ensureNotificationPermissions } from '@app/services/notification/notification-permission';
import {
  AllowNotificationsSource,
  trackNotificationsAllowed,
} from '@app/services/tracking/trackTracking';
import { Button } from '@app/ui/molecules/Button/index';
import { isNative } from '@app/constants/platform';
import {
  YesNoLater,
  useUpdateCustomerNotificationPreferencesMutation,
} from '@app/libs/apollo/introspection';
import { requestNotifications } from '@app/libs/rn-permissions/rn-permissions';

const getStyles = ({ theme }: { theme: Theme }) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.system.secondary[600],
      borderRadius: theme.borderRadius.SIZE_06,
      margin: theme.spacing.SIZE_05,
      paddingHorizontal: theme.spacing.SIZE_07,
      paddingVertical: theme.spacing.SIZE_05,
      zIndex: 1,
    },
    header: {
      alignSelf: 'center',
      backgroundColor: theme.palette.base.black,
      borderRadius: theme.borderRadius.SIZE_07,
      height: 95,
      justifyContent: 'center',
      marginBottom: theme.spacing.SIZE_06,
      marginTop: theme.spacing.SIZE_03,
      width: 97,
    },
    icon: { alignSelf: 'center' },
    notification: { alignSelf: 'center' },
    notificationsBadge: {
      alignSelf: 'center',
      backgroundColor: theme.palette.base.white,
      borderRadius: theme.borderRadius.SIZE_07,
      height: 31,
      justifyContent: 'center',
      position: 'absolute',
      right: -theme.spacing.SIZE_04,
      top: -theme.spacing.SIZE_04,
      width: 31,
    },
    text: {
      color: theme.palette.base.white,
      marginBottom: theme.spacing.SIZE_05,
      textAlign: 'center',
    },
  });

type Props = {
  isNotificationVisible: boolean;
  handleCloseNotifications: () => void;
};

export const HomePageNotifications: React.FC<Props> = ({
  isNotificationVisible,
  handleCloseNotifications,
}) => {
  const theme = useTheme();
  const styles = getStyles({ theme });
  const { t } = useTranslation();

  const [updateCustomerNotificationPreferences] =
    useUpdateCustomerNotificationPreferencesMutation();

  const handlePress = async () => {
    trackNotificationsAllowed(AllowNotificationsSource.Suggestions);
    await ensureNotificationPermissions();

    await updateCustomerNotificationPreferences({
      variables: { notificationPreferences: { isNotificationEnabled: YesNoLater.Yes } },
    });

    const detailedPermission = await requestNotifications();
    const isBlocked = detailedPermission === RESULTS.BLOCKED;

    if (isBlocked) {
      await openSettings();
    } else {
      handleCloseNotifications();
    }
  };

  if (!isNative() || !isNotificationVisible) {
    return null;
  }

  return (
    <Pressable style={styles.container} onPress={handlePress}>
      <View style={styles.header}>
        <View style={styles.notificationsBadge}>
          <Icon
            name="Notification"
            size={15}
            color={theme.palette.tint.red[600]}
            style={styles.notification}
          />
        </View>

        <Icon name="Masteos" size={48} color="white" style={styles.icon} />
      </View>

      <Text textStyle="Headline3Medium" style={styles.text}>
        {t('homePage.notifications.title')}
      </Text>
      <Button
        iconName="Notification"
        variant="secondary"
        onPress={handlePress}
        iconPlacement="left"
        testID="homePageCTAButton"
      >
        {t('homePage.notifications.button')}
      </Button>
    </Pressable>
  );
};
