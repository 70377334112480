import React from 'react';

import { View } from 'react-native';

interface SectionProps {
  children: JSX.Element[] | JSX.Element;
}

/**
 * Section containing items to display a main menu
 */
export const Section: React.FC<SectionProps> = ({ children }) => {
  if (!Array.isArray(children)) {
    return React.cloneElement(children, { isLast: true });
  }

  return (
    <View style={{ maxWidth: '100%' }}>
      {React.Children.map(children, (Child: JSX.Element, index) => {
        return Child
          ? React.cloneElement(Child, {
              isLast: children.filter(child => !!child).length - 1 === index,
            })
          : null;
      })}
    </View>
  );
};
