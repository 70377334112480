import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgDoor = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 21a.965.965 0 01-.712-.288A.965.965 0 013 20c0-.283.096-.52.288-.712A.965.965 0 014 19h1V4a.97.97 0 01.287-.713A.97.97 0 016 3h8a.97.97 0 01.713.287A.97.97 0 0115 4h3c.283 0 .52.096.712.287.192.192.288.43.288.713v14h1c.283 0 .52.096.712.288A.965.965 0 0121 20c0 .283-.096.52-.288.712A.965.965 0 0120 21h-2a.965.965 0 01-.712-.288A.965.965 0 0117 20V6h-2v14c0 .283-.096.52-.287.712A.968.968 0 0114 21H4zm8-9a.97.97 0 00-.287-.713A.97.97 0 0011 11a.967.967 0 00-.712.287A.968.968 0 0010 12c0 .283.096.52.288.712A.965.965 0 0011 13a.968.968 0 00.713-.288A.967.967 0 0012 12zm-5 7h6V5H7v14z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgDoor;
