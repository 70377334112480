import React from 'react';

import { View, StyleSheet } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { DataBlock, useResponsive, IconPopoverOrModal, useTheme, Theme } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackTooltip } from '@app/services/tracking/trackTracking';

import { areEqual, differentFuncParameters } from '../utils/realEstateFinances.utils';

const getStyles = (theme: Theme, isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing.SIZE_06,
      width: '100%',
    },
    dataBlock: {
      width: isLowerThanDesktop ? '100%' : `calc(50% - ${theme.spacing.SIZE_06 / 2}px)`,
    },
    withShortTermRentalText: {
      color: theme.palette.tint.purple[500],
    },
  });

interface RealEstateFinancialSummaryProps {
  financialModel: FinancialModel;
  realEstateId: string;
  withShortTermRental: boolean;
}

export const RealEstateFinancialSummary: React.FC<RealEstateFinancialSummaryProps> = React.memo(
  ({ financialModel, realEstateId, withShortTermRental }) => {
    const { t } = useRealEstateTranslation();
    const theme = useTheme();
    const { isLowerThanDesktop } = useResponsive();
    const styles = getStyles(theme, isLowerThanDesktop);

    const formattedProjectAmount = currencyFormatter(financialModel.projectAmount, 0);
    const formattedTotalLoanAmount = currencyFormatter(financialModel.totalLoan, 0);
    const formattedLoanMonthlyAmount = currencyFormatter(financialModel.loanMonthlyAmount, 0);
    const formattedAnnualRentIncome = currencyFormatter(financialModel.annualRentIncome / 12, 0);

    const trackTooltipProjectCost = () =>
      trackTooltip({ propertyId: realEstateId, tooltipType: 'Coût du projet' });

    const trackTooltipTotalLoanAmount = () =>
      trackTooltip({ propertyId: realEstateId, tooltipType: 'Capital emprunté' });

    const trackTooltipShortTermNighlty = () =>
      trackTooltip({ propertyId: realEstateId, tooltipType: 'Loyer nuitée' });

    return (
      <View style={styles.container}>
        <DataBlock
          style={styles.dataBlock}
          title={t('shared.financialTool.projectCost')}
          data={formattedProjectAmount}
          hint={
            <IconPopoverOrModal
              title={t('shared.financialTool.projectCost')}
              content={t('shared.financialTool.tooltip.projectAmountDescription')}
              iconName="Info"
              onOpen={trackTooltipProjectCost}
              iconSize="s"
            />
          }
        />

        <DataBlock
          style={styles.dataBlock}
          title={t('shared.financialTool.totalLoanAmount')}
          data={formattedTotalLoanAmount}
          hint={
            <IconPopoverOrModal
              title={t('shared.financialTool.totalLoanAmount')}
              content={t('shared.financialTool.tooltip.totalLoanAmountDescription')}
              iconName="Info"
              onOpen={trackTooltipTotalLoanAmount}
              iconSize="s"
            />
          }
        />

        {withShortTermRental ? (
          <DataBlock
            style={styles.dataBlock}
            title={t('shared.financialTool.shortTermMonthlyRentIncomeNoCharges')}
            data={formattedAnnualRentIncome}
            valueStyle={styles.withShortTermRentalText}
            hint={
              <IconPopoverOrModal
                title={t('shared.financialTool.tooltip.shortTermMonthlyRentIncomeNoCharges')}
                content={t(
                  'shared.financialTool.tooltip.shortTermMonthlyRentIncomeNoChargesDescription'
                )}
                iconName="Info"
                onOpen={trackTooltipShortTermNighlty}
                iconSize="s"
              />
            }
          />
        ) : (
          <DataBlock
            style={styles.dataBlock}
            title={t('shared.financialTool.monthlyRentIncomeNoCharges')}
            data={formattedAnnualRentIncome}
          />
        )}

        <DataBlock
          style={styles.dataBlock}
          title={t('shared.financialTool.loanMonthlyAmount')}
          data={formattedLoanMonthlyAmount}
        />
      </View>
    );
  },
  areEqual<RealEstateFinancialSummaryProps>(['withShortTermRental'], (prevState, nextState) => {
    return differentFuncParameters(prevState, nextState, [
      'annualRentIncome',
      'loanMonthlyAmount',
      'projectAmount',
      'totalLoan',
    ]);
  })
);

RealEstateFinancialSummary.displayName = 'PropertyFinancialSummary';
