import { FinancialModel } from '@masteos/agora';

export const differentFuncParameters = <T extends { financialModel: FinancialModel }>(
  prev: T,
  next: T,
  parameters: (keyof FinancialModel)[]
): boolean => {
  return areParamsDifferent(prev?.financialModel, next?.financialModel, parameters);
};

const areParamsDifferent = <T>(prevState: T, nextState: T, params: (keyof T)[]): boolean => {
  const different = params.map((param: keyof T) => prevState[param] !== nextState[param]);
  return different.includes(true);
};

export const areEqual =
  <T>(params: (keyof T)[], diffCondition: (prevState: T, nextState: T) => boolean = () => false) =>
  (prevState: T, nextState: T): boolean => {
    if (diffCondition(prevState, nextState) || areParamsDifferent(prevState, nextState, params)) {
      return false;
    }

    return true;
  };
