import { CSSProperties } from 'react';

import { StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@app/ui';
import { isWeb } from '@app/constants/platform';

interface OnboardingStylesProps {
  isFixed?: boolean;
  isGreaterThanTablet?: boolean;
  theme: Theme;
}

interface ScrollbarViewStyle extends ViewStyle {
  ['&::-webkit-scrollbar']: CSSProperties;
  msOverflowStyle?: string;
  scrollbarWidth?: string;
  overflowY?: string;
}

const scrollViewContainer: ScrollbarViewStyle = {
  '&::-webkit-scrollbar': {
    display: 'none' /* Chrome, Safari and Opera */,
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = ({
  isFixed = false,
  isGreaterThanTablet = false,
  theme,
}: OnboardingStylesProps) => {
  return StyleSheet.create({
    bottomContainer: {
      backgroundColor: theme.palette.base.white,
      bottom: isGreaterThanTablet || !isFixed ? theme.spacing.NONE : theme.spacing.SIZE_06,
      marginTop: theme.spacing.SIZE_04,
    },

    buttonWrapper: {
      marginBottom: isGreaterThanTablet || !isFixed ? theme.spacing.NONE : theme.spacing.SIZE_06,
      paddingHorizontal: isGreaterThanTablet ? theme.spacing.SIZE_06 : theme.spacing.NONE,
    },

    flex1: {
      flex: 1,
    },

    flexView: {
      flex: 1,
      width: 335,
    },

    formWrapper: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: isWeb() ? '100vh' : '100%',
      maxWidth: 400,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: isWeb() ? theme.spacing.SIZE_08 : theme.spacing.SIZE_00,
      width: '100%',
    },

    input: {
      marginBottom: theme.spacing.SIZE_04,
      width: '100%',
    },

    inputGroup: {
      marginBottom: 'auto',
      marginTop: isGreaterThanTablet ? 'auto' : theme.spacing.NONE,
      width: '100%',
    },

    link: {
      textDecorationColor: theme.palette.base.black,
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
    },

    mainContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxHeight: '100%',
      position: 'relative',
      width: '100%',
    },

    mainWrapper: {
      backgroundColor: theme.palette.base.white,
      height: '100%',
      position: 'relative',
    },

    safeAreaWrapper: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },

    safeMainWrapper: {
      flex: 1,
    },

    scrollView: {
      flex: 1,
      height: '100%',
    },

    scrollViewContainer,

    scrollViewContainerWeb: {
      flexGrow: isGreaterThanTablet ? 1 : undefined,
      justifyContent: 'center',
    },
  });
};
