import React, { useRef } from 'react';

import { StyleSheet, View } from 'react-native';

import { Expandable, SliderInput, Spacer, Theme, useTheme } from '@app/ui';
import {
  trackTooltip,
  trackFinancialToggle,
  trackFinancialUntoggle,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';

import { RealEstateFinancialHeader } from './RealEstateFinancialHeader';
import { Importance, RealEstateAttribute } from '../../../components/RealEstateAttribute';
import { areEqual } from '../utils/realEstateFinances.utils';
import { RealEstateAnnualAvgOccupancy } from './RealEstateAnnualAvgOccupancy';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    border: {
      borderBottomColor: theme.palette.neutral[300],
      borderBottomWidth: 1,
      width: '100%',
    },
    container: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      paddingBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_03,
      width: '100%',
    },
  });

interface RealEstateFinancialAnnualIncomesProps {
  annualCharges: number;
  annualRentIncome: number;
  annualTotalIncome: number;
  annualAvgOccupancyInMonths: number;
  countryCode: string;
  financialModelVersion: number;
  isFurnished: boolean;
  shortTermNightlyRent: number;
  realEstateId: string;
  shortTermOccupancyRate?: number;
  withShortTermRental: boolean;
  setAnnualAvgOccupancyInMonths: (v: number) => void;
  setShortTermNightlyRent: (v: number) => void;
  setShortTermOccupancyRate: (v: number) => void;
}

export const RealEstateFinancialAnnualIncomes: React.FC<RealEstateFinancialAnnualIncomesProps> =
  React.memo(
    ({
      annualCharges,
      annualRentIncome,
      annualTotalIncome,
      annualAvgOccupancyInMonths,
      countryCode,
      financialModelVersion,
      isFurnished,
      shortTermNightlyRent,
      realEstateId,
      shortTermOccupancyRate,
      withShortTermRental,
      setAnnualAvgOccupancyInMonths,
      setShortTermNightlyRent,
      setShortTermOccupancyRate,
    }) => {
      const { t } = useRealEstateTranslation();
      const theme = useTheme();
      const styles = getStyles(theme);

      const formattedAnnualCharges = currencyFormatter(annualCharges, 0);
      const formattedNightlyRentAmount = currencyFormatter(shortTermNightlyRent, 0);
      const formattedAnnualRentIncome = currencyFormatter(annualRentIncome, 0);
      const formattedAnnualTotalIncome = currencyFormatter(annualTotalIncome, 0);
      const { current: maxNightlyRentAmount } = useRef(shortTermNightlyRent * 2);

      const trackToggle = ({ expanded }: { expanded?: boolean }) => {
        expanded
          ? trackFinancialUntoggle({ propertyId: realEstateId, sectionName: 'revenue' })
          : trackFinancialToggle({ propertyId: realEstateId, sectionName: 'revenue' });
      };
      const trackTooltipAnnualCharges = () =>
        trackTooltip({ propertyId: realEstateId, tooltipType: 'Charges locataire' });

      const valueColor = withShortTermRental ? theme.palette.tint.purple[500] : undefined;

      return (
        <View style={styles.container}>
          <Expandable onPress={trackToggle}>
            <Expandable.Header>
              <Expandable.Context.Consumer>
                {({ expanded }) => (
                  <RealEstateFinancialHeader
                    title={t(`shared.financialTool.annualRentIncome`)}
                    icon="Euro"
                    hasChevron
                    chevronOpen={expanded}
                    amount={!expanded ? formattedAnnualTotalIncome : undefined}
                    amountColor={valueColor}
                  />
                )}
              </Expandable.Context.Consumer>
            </Expandable.Header>
            <Expandable.Content>
              <RealEstateAttribute
                label={t('shared.financialTool.yearlyRentIncomeNoCharges')}
                value={formattedAnnualRentIncome}
                importance={Importance.LOW}
                isOdd
                testID="property-financial-line-annual-rent-income"
                valueColor={valueColor}
              />
              {!!withShortTermRental && (
                <>
                  <RealEstateAttribute
                    label={t('shared.financialTool.nightlyRent')}
                    value={formattedNightlyRentAmount}
                    importance={Importance.LOW}
                    isOdd={false}
                    testID="nightly-rent-amount-label"
                    valueColor={valueColor}
                  />
                  <SliderInput
                    min={0}
                    step={1}
                    max={maxNightlyRentAmount}
                    setSliderValue={setShortTermNightlyRent}
                    testID="nightly-rent-amount-slider"
                    value={shortTermNightlyRent}
                  />
                </>
              )}
              <RealEstateAttribute
                label={t('shared.financialTool.annualCharges')}
                value={formattedAnnualCharges}
                importance={Importance.LOW}
                tooltip={{
                  description: t(`shared.financialTool.tooltip.annualChargesDescription`),
                  onOpen: trackTooltipAnnualCharges,
                  title: t(`shared.financialTool.tooltip.annualCharges`),
                }}
                testID="property-financial-line-annual-charges"
              />
              <RealEstateAnnualAvgOccupancy
                propertyId={realEstateId}
                countryCode={countryCode as AvailableCountryCode}
                financialModelVersion={financialModelVersion}
                isFurnished={isFurnished}
                annualAvgOccupancyInMonths={annualAvgOccupancyInMonths}
                shortTermOccupancyRate={shortTermOccupancyRate}
                setAnnualAvgOccupancyInMonths={setAnnualAvgOccupancyInMonths}
                setShortTermOccupancyRate={setShortTermOccupancyRate}
                withShortTermRental={withShortTermRental}
              />

              <Spacer height={theme.spacing.SIZE_05} />

              <View style={styles.border} />

              <Spacer height={theme.spacing.SIZE_04} />

              <RealEstateAttribute
                label={t('shared.financialTool.annualRentIncome')}
                value={formattedAnnualTotalIncome}
                importance={Importance.HIGH}
                testID="property-financial-line-annual-total-income"
                valueColor={valueColor}
              />
            </Expandable.Content>
          </Expandable>
        </View>
      );
    },
    areEqual<RealEstateFinancialAnnualIncomesProps>([
      'annualCharges',
      'annualRentIncome',
      'annualTotalIncome',
      'annualAvgOccupancyInMonths',
      'withShortTermRental',
      'shortTermOccupancyRate',
    ])
  );

RealEstateFinancialAnnualIncomes.displayName = 'RealEstateFinancialAnnualIncomes';
