import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBedding = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M1 19V4h2v10h8V6h8c1.1 0 2.042.392 2.825 1.175C22.608 7.958 23 8.9 23 10v9h-2v-3H3v3H1Zm6-6a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 4 10c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 7 7c.833 0 1.542.292 2.125.875S10 9.167 10 10s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 7 13Zm6 1h8v-4c0-.55-.196-1.02-.587-1.412A1.926 1.926 0 0 0 19 8h-6v6Zm-6-3c.283 0 .52-.096.713-.287A.968.968 0 0 0 8 10a.968.968 0 0 0-.287-.713A.968.968 0 0 0 7 9a.968.968 0 0 0-.713.287A.968.968 0 0 0 6 10c0 .283.096.52.287.713.192.191.43.287.713.287Z"
    />
  </Svg>
);
export default SvgBedding;
