import { ChipTint, IconName } from '@app/ui';

export interface ShowCaseChip {
  text: string;
  iconName: IconName;
  tint: ChipTint;
}

interface ShowCaseChipListParams {
  bookmarkCount: number;
  positioningCount: number;
  t: (key: string, options?: unknown) => string;
}

export const getShowCaseChipsList = ({
  bookmarkCount,
  positioningCount,
  t,
}: ShowCaseChipListParams): ShowCaseChip[] => {
  const chips = [];
  if (positioningCount > 0) {
    chips.push({
      iconName: 'Star',
      text: t(`propertyInfo.fomo.positioningCount.${positioningCount > 1 ? 'other' : 'one'}`, {
        count: positioningCount,
      }),
      tint: 'green',
    });
  }

  if (bookmarkCount > 0) {
    chips.push({
      iconName: 'Heart',
      text: t(`propertyInfo.fomo.bookmarkCount.${bookmarkCount > 1 ? 'other' : 'one'}`, {
        count: bookmarkCount,
      }),
      tint: 'purple',
    });
  }
  return chips;
};
