import React from 'react';

import { View } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

import { getStyle } from './ShadowContainer.styles';
import { ShadowContainerType } from './ShadowContainer.types';

export { ShadowContainerType };

export const ShadowContainer: React.FC<ShadowContainerType> = ({
  children,
  style,
  shadowSize = 100,
  testID = 'shadow-container',
}) => {
  const theme = useTheme();
  const styles = getStyle({ theme });

  const shadowType = theme.shadows[shadowSize];

  return (
    <View style={[shadowType, styles.shadow, style]} testID={testID}>
      {children}
    </View>
  );
};
