import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgDishwasher = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M8 7a.97.97 0 00.713-.287A.97.97 0 009 6a.97.97 0 00-.287-.713A.97.97 0 008 5a.97.97 0 00-.713.287A.97.97 0 007 6a.97.97 0 00.287.713A.97.97 0 008 7zm3 0a.97.97 0 00.713-.287A.97.97 0 0012 6a.97.97 0 00-.287-.713A.97.97 0 0011 5a.967.967 0 00-.712.287A.968.968 0 0010 6c0 .283.096.521.288.713A.967.967 0 0011 7zm1 11c1.05 0 1.946-.358 2.688-1.075.741-.717 1.112-1.592 1.112-2.625 0-.483-.083-.954-.25-1.413a3.206 3.206 0 00-.75-1.187L12 8.9l-2.7 2.7a3.823 3.823 0 00-.837 1.237c-.192.459-.28.946-.263 1.463.033 1.033.417 1.908 1.15 2.625C10.083 17.642 10.967 18 12 18zm0-1.9c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0110.2 14.3a1.843 1.843 0 01.55-1.325l1.25-1.25 1.225 1.225c.183.183.325.392.425.625.1.233.15.475.15.725 0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525zM6 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 2h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v16c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 22H6zm0-2h12V4H6v16z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgDishwasher;
