// todo: intl stuffs
import { formatCurrency } from '@masteos/agora';

export const currencyFormatter = (
  value: number | string,
  precision = 2,
  { defaultValue = '-' } = {}
): string => {
  return !Number.isNaN(Number(value)) ? formatCurrency(Number(value), { precision }) : defaultValue;
};

export const percentFormatter = (
  value: number | string,
  precision = 2,
  { defaultValue = '-' } = {}
): string => {
  return !Number.isNaN(Number(value))
    ? formatCurrency(Number(value), { decimal: ',', precision, unit: '%' })
    : defaultValue;
};
