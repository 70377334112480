import { config } from '@app/config';
import { LINKS_MASTEOS } from '@app/constants/constants';
import { isNative, isWeb } from '@app/constants/platform';
import { trackClickToShare } from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { useResponsive } from '@app/ui';
import { onShare } from '@app/utils/share';

interface UseShareButton {
  shouldUseShare: boolean;
  onPress: () => Promise<void>;
}

export const useShareButton = (realEstateId: string, title: string): UseShareButton => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { setValidMsg } = useToastContext();

  const baseUrl = config.isProduction ? LINKS_MASTEOS : `${config.WEB_APP_URL}/property`;
  const sharingContent = { title, url: `${baseUrl}/${realEstateId}` };

  const shouldUseShare =
    isNative() || (isWeb() && isMobile && navigator.canShare?.(sharingContent));

  const onPress = async () => {
    await onShare(sharingContent, isMobile);
    setValidMsg({ title: t('shared.copiedToClipboard'), withClose: false });
    trackClickToShare({ propertyId: realEstateId });
  };

  return { onPress, shouldUseShare };
};
