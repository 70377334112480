import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgFlag = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.283 20.505a.968.968 0 01-.713-.288.967.967 0 01-.287-.712v-15a.97.97 0 01.287-.713.97.97 0 01.713-.287h7.175a.96.96 0 01.625.225c.183.15.3.342.35.575l.25 1.2h4.6c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .284-.096.521-.288.712a.965.965 0 01-.712.288h-5.175a.96.96 0 01-.625-.225.99.99 0 01-.35-.575l-.25-1.2h-5.6v6c0 .284-.096.521-.287.712a.968.968 0 01-.713.288zm8.65-7h3.35v-6h-5.25l-.4-2h-5.35v6h7.25l.4 2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgFlag;
