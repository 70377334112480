import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKm = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="km_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#km_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 5.333h28V0H0v5.333z" fill="#FFCB49" />
        <Path d="M0 14.667h28V9.333H0v5.334z" fill="#DC1D34" />
        <Path d="M0 20h28v-5.333H0V20z" fill="#4985D4" />
        <Path d="M0 20l13.333-10L0 0v20z" fill="#4BA343" />
        <Path
          d="M5.333 14c.346 0 .68-.044 1-.126a4.002 4.002 0 010-7.748 4 4 0 10-1 7.874zM8 7.333a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm-.667 3.334a.667.667 0 100-1.334.667.667 0 000 1.334zm.667 2a.667.667 0 11-1.333 0 .667.667 0 011.333 0z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgKm;
