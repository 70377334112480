import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { storage } from '@app/services/storage/storage';

const appointmentJustBookedDuration: number = 20 * 60000; // 20 min.

const isAppointmentJustBookedExpired = (appointmentJustBookedStorageValue: string): boolean => {
  const appointmentJustBookedDate: Date = new Date(appointmentJustBookedStorageValue);

  return Date.now() > appointmentJustBookedDate.getTime() + appointmentJustBookedDuration;
};

export const isAppointmentJustBooked = async (): Promise<boolean> => {
  const appointmentJustBooked = await storage.readString('appointment_just_booked');

  return appointmentJustBooked && !isAppointmentJustBookedExpired(appointmentJustBooked);
};

export const checkAppointmentIsJustBooked = async (
  customer: GetCustomerQuery['me']
): Promise<void> => {
  const appointmentJustBooked: string | null = await storage.readString('appointment_just_booked');

  if (
    appointmentJustBooked &&
    (!!customer.nextAppointment || isAppointmentJustBookedExpired(appointmentJustBooked))
  ) {
    await storage.clear('appointment_just_booked');
  }
};
