import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKg = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="kg_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#kg_svg__a)">
        <Path fill="#F22A46" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666z"
          fill="#FFF04D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.368 17.15L14 14.713l1.632 2.435.413-2.902 2.527 1.486-.887-2.794 2.922.243-2.012-2.133L21.333 10l-2.738-1.049 2.012-2.133-2.922.243.887-2.794-2.527 1.486-.413-2.902L14 5.286l-1.632-2.435-.413 2.902-2.527-1.486.887 2.794-2.922-.243 2.012 2.133L6.667 10l2.738 1.049-2.012 2.133 2.922-.243-.887 2.794 2.527-1.486.413 2.902zM18.667 10a4.667 4.667 0 11-9.334 0 4.667 4.667 0 019.334 0z"
          fill="#FFF04D"
        />
      </G>
    </Svg>
  );
};

export default SvgKg;
