import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Theme, useTheme } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useTranslation } from '@app/services/translations/translations';

import { TagList, TagListProps } from '../../tag-list/TagList';

export type RealEstatePreviewCardBodyProps = {
  title: string;
  price: number;
} & TagListProps;

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_03,
    },
    priceCaption: {
      color: theme.palette.neutral[700],
      marginBottom: theme.spacing.SIZE_01,
      paddingLeft: theme.spacing.SIZE_02,
    },
    priceContainer: {
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
  });

export const RealEstatePreviewCardBody: React.FC<RealEstatePreviewCardBodyProps> = ({
  title,
  surface,
  grossReturnRate,
  netReturnRate,
  price,
  propertyKind,
  totalLots,
  roomCount,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.container}>
      <Text textStyle="Headline3Medium">{title}</Text>
      <TagList
        surface={surface}
        grossReturnRate={grossReturnRate}
        netReturnRate={netReturnRate}
        propertyKind={propertyKind}
        totalLots={totalLots}
        roomCount={roomCount}
      />
      <View style={styles.priceContainer}>
        <Text textStyle="Body1Medium">{currencyFormatter(price, 0)}</Text>
        <Text textStyle="Caption1" style={styles.priceCaption}>
          ({t('propertyPreview.totalCost')})
        </Text>
      </View>
    </View>
  );
};
