import React from 'react';

import { Platform, StyleSheet, ViewStyle } from 'react-native';

import { StepStatus } from '@app/ui/molecules/circle-step-indicator/CircleStepIndicator';
import { palette } from '@app/ui/theme/palette';

export const getHorizontalStepStyles: ({
  isStepAfterFinished,
  isStepBeforeFinished,
  isSubStep,
  onPress,
  status,
}: {
  status: StepStatus;
  isSubStep: boolean;
  isStepAfterFinished: boolean;
  isStepBeforeFinished?: boolean;
  onPress?: (stepNumber: number) => void;
}) => StyleSheet.NamedStyles<Record<string, React.CSSProperties>> = ({
  status,
  isStepAfterFinished,
  isStepBeforeFinished,
  isSubStep,
  onPress,
}) =>
  StyleSheet.create({
    circleContainer: Platform.select({
      web: { cursor: onPress ? 'pointer' : 'default' },
    }) as StyleSheet.NamedStyles<never>,
    container: { flex: 1 },
    step: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 40,
    },
    stepLink: {
      height: 1,
      position: 'absolute',
      width: isSubStep ? 'calc((100% - 20px)/ 2)' : 'calc((100% - 40px)/ 2)',
      zIndex: -1,
    },
    stepLinkAfter: {
      backgroundColor: isStepAfterFinished ? palette.system.primary[500] : palette.neutral[500],
      right: 0,
    },
    stepLinkBefore: {
      backgroundColor: isStepBeforeFinished ? palette.system.primary[500] : palette.neutral[500],
      left: 0,
    },
    title: {
      color: status === StepStatus.Incoming ? palette.neutral[500] : palette.system.primary[500],
      marginHorizontal: 'auto',
      marginVertical: 8,
      maxWidth: 95,
      textAlign: 'center',
    },
  });

export const getVerticalStepStyles: (
  status: StepStatus,
  isSubStep: boolean,
  nextStepIsFinished: boolean,
  onPress?: (stepNumber: number) => void
) => StyleSheet.NamedStyles<Record<string, React.CSSProperties>> = (
  status,
  isSubStep,
  nextStepIsFinished,
  onPress
) =>
  StyleSheet.create({
    circleContainer: Platform.select({
      web: { cursor: onPress ? 'pointer' : 'default' },
    }) as StyleSheet.NamedStyles<never>,
    container: { flexDirection: 'row' },
    step: {
      alignItems: 'center',
      ...(isSubStep && { marginLeft: 10 }),
    },
    stepLink: {
      backgroundColor: nextStepIsFinished ? palette.system.primary[500] : palette.neutral[500],
      height: 24,
      width: 2,
    },
    title: {
      color: status === StepStatus.Incoming ? palette.neutral[500] : palette.system.primary[500],
      lineHeight: isSubStep ? 20 : 40,
      paddingHorizontal: 12,
    },
  });

export const timelineStyles = (isVerticalTimeline: boolean): { [key: string]: ViewStyle } =>
  StyleSheet.create({
    container: {
      flexDirection: isVerticalTimeline ? 'column' : 'row',
      width: '100%',
    },
  });
