import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgHr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="hr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#hr_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 6.667h28V0H0v6.667z" fill="#FF202D" />
        <Path
          opacity={0.5}
          d="M10.667 5.333l.666-.666.667.666v1.334h-1.333V5.333zm2.666 0L14 4.667l.667.666v1.334h-1.334V5.333zm3.334-.666L16 5.333v1.334h1.333V5.333l-.666-.666z"
          fill="#1895DB"
        />
        <Path
          opacity={0.5}
          d="M12.667 4.667L12 5.333v1.334h1.333V5.333l-.666-.666zm2.666 0l-.666.666v1.334H16V5.333l-.667-.666z"
          fill="#191F94"
        />
        <Path d="M0 20h28v-6.667H0V20z" fill="#2027AC" />
        <Path
          d="M12 6.667h-.667L10.667 8H12v1.333h-1.333v1.334H12V12h-1.333v1.333H12v1.334h1.333v-1.334h1.334v1.334H16v-1.334h1.333V12H16v-1.333h1.333V9.333H16V8h1.333l-.666-1.333H16V8h-1.333V6.667h-1.334V8H12V6.667zm1.333 2.666V8h1.334v1.333h-1.334zm0 1.334V9.333H12v1.334h1.333zm1.334 0h-1.334V12H12v1.333h1.333V12h1.334v1.333H16V12h-1.333v-1.333zm0 0H16V9.333h-1.333v1.334z"
          fill="url(#hr_svg__paint0_linear_4223_10979)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="hr_svg__paint0_linear_4223_10979"
          x1={10.667}
          y1={6.667}
          x2={10.667}
          y2={14.667}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FF212E" />
          <Stop offset={1} stopColor="#FD0D1B" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgHr;
