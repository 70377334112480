import React, { useMemo, useState } from 'react';

import { StyleSheet, View } from 'react-native';

import { RealEstateAttribute } from '@app/features/realEstate/components/RealEstateAttribute';
import { Dpe, Ges } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import {
  Button,
  Theme,
  TranslatedContent,
  TranslatedContentContentType,
  useResponsive,
  useTheme,
} from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { TranslationContentValue } from '@app/ui/atoms/translation-content/TranslationContent.types';

import { EnergeticTag } from '../EnergeticTag';
import { RealEstateRenovationDPEModal } from './RealEstateRenovationDPEModal';
import { RealEstateRenovationGESModal } from './RealEstateRenovationGESModal';
import { dpeColors } from '../../utils/dpe';
import { gesColors } from '../../utils/ges';

export interface RenovationSummaryValues {
  realEstateId: string;
  translatedDescription: TranslationContentValue;
  dpe: Dpe | undefined;
  ges: Ges | undefined;
  renovationAmount: number | undefined;
  furnishingAmount: number | undefined;
}

export type RenovationSummaryProps = RenovationSummaryValues & {
  onPressViewDetail?: () => void;
  trackSwitchLanguage: (
    type: TranslatedContentContentType,
    sourceLang?: string,
    targetLang?: string
  ) => void;
};

const amountPerMonth = 15000;
const descriptionNumberOfLines = 7;

const getStyles = (theme: Theme, isLowerThanTablet: boolean) =>
  StyleSheet.create({
    container: {
      gap: isLowerThanTablet ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
    },
    summaryContainer: {
      backgroundColor: theme.palette.neutral[50],
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_06,
      borderWidth: 1,
      padding: theme.spacing.SIZE_05,
    },
  });

export const RenovationSummary: React.FC<RenovationSummaryProps> = ({
  translatedDescription,
  dpe = Dpe.NoDiag,
  ges = Ges.NoDiag,
  furnishingAmount = 0,
  renovationAmount = 0,
  onPressViewDetail,
  trackSwitchLanguage,
}) => {
  const theme = useTheme();
  const { isLowerThanTablet } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanTablet), [isLowerThanTablet, theme]);
  const { t } = useTranslation();

  const [isOpenDpeModal, setIsOpenDpeModal] = useState(false);
  const [isOpenGesModal, setIsOpenGesModal] = useState(false);

  const closeDpeModal = () => setIsOpenDpeModal(false);
  const closeGesModal = () => setIsOpenGesModal(false);

  const openDpeModal = () => setIsOpenDpeModal(true);
  const openGesModal = () => setIsOpenGesModal(true);

  const monthCount = Math.ceil((furnishingAmount > 0 ? 1 : 0) + renovationAmount / amountPerMonth);

  const showDpe = dpe !== Dpe.NoDiag;
  const showGes = ges !== Ges.NoDiag;

  const renderDpe = (value: string | number) => (
    <EnergeticTag
      value={t('realEstate.renovation.dpeTag', { dpe: value })}
      backgroundColor={dpeColors[value as Dpe]}
      openModal={openDpeModal}
    />
  );

  const renderGes = (value: string | number) => (
    <EnergeticTag
      value={t('realEstate.renovation.gesTag', { ges: value })}
      backgroundColor={gesColors[value as Ges]}
      openModal={openGesModal}
    />
  );

  return (
    <>
      <View style={styles.container}>
        <View style={styles.summaryContainer}>
          <RealEstateAttribute
            icon="Euro"
            value={currencyFormatter(renovationAmount, 0)}
            label={
              isLowerThanTablet
                ? t('realEstate.renovation.globalEstimationAmountAbbr')
                : t('realEstate.renovation.globalEstimationAmount')
            }
          />
          <RealEstateAttribute
            icon="Clock"
            value={t(`realEstate.renovation.durationInMonths.${monthCount > 1 ? 'other' : 'one'}`, {
              months: monthCount,
            })}
            label={t('realEstate.renovation.duration')}
          />
          <RealEstateAttribute
            icon="Zap"
            value={showDpe ? dpe : t('realEstate.renovation.noDpe')}
            label={t('realEstate.renovation.beforeDpe')}
            renderValue={showDpe ? renderDpe : undefined}
          />
          <RealEstateAttribute
            icon="Cloud"
            value={showGes ? ges : t('realEstate.renovation.noGes')}
            label={t('realEstate.renovation.beforeGes')}
            renderValue={showGes ? renderGes : undefined}
          />
        </View>

        <TranslatedContent
          translation={translatedDescription}
          onHandleDone={trackSwitchLanguage}
          translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
          showOriginal={t('shared.showOriginal')}
          showTranslated={t('shared.showTranslated')}
          textStyle="Paragraph1"
          numberOfLines={
            isLowerThanTablet && onPressViewDetail ? descriptionNumberOfLines : undefined
          }
        />

        {!!isLowerThanTablet && !!onPressViewDetail && !!translatedDescription.sourceText && (
          <Button
            variant="tertiary"
            onPress={onPressViewDetail}
            iconName="ArrowRight"
            iconPlacement="right"
          >
            {t('shared.knowMore')}
          </Button>
        )}
      </View>

      <RealEstateRenovationDPEModal
        value={Dpe[dpe]}
        isVisibleDPEModal={isOpenDpeModal}
        onClose={closeDpeModal}
      />
      <RealEstateRenovationGESModal
        value={Ges[ges]}
        isVisibleGESModal={isOpenGesModal}
        setIsVisibleGESModal={closeGesModal}
      />
    </>
  );
};
