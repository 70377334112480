import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgSn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="sn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#sn_svg__a)">
        <Path fill="#F13642" d="M13.333 0H28v20H13.333z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h9.333V0H0v20z" fill="#17A052" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.333 20h9.334V0H9.333v20z"
          fill="#FFF166"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11.367l-1.96 1.33.66-2.275-1.87-1.452 2.367-.076L14 6.667l.803 2.227 2.367.076-1.87 1.452.66 2.275-1.96-1.33z"
          fill="#17A052"
        />
      </G>
    </Svg>
  );
};

export default SvgSn;
