import React, { FC, useEffect, useCallback, useState, CSSProperties } from 'react';

import { ActivityIndicator, StyleSheet } from 'react-native';

import { useTheme } from '@app/ui';

import { WebViewProps } from './webView.types';

const IframeStyles = {
  border: 0,
  height: 'calc(100vh - 160px)',
};

const containerIframeStyles = {
  height: '100%',
};

export const WebView: FC<WebViewProps> = ({
  containerStyles,
  onMessage,
  styles,
  testID = 'web-view',
  title = 'web-view',
  uri,
  withLoader = true,
}) => {
  const [visible, setVisible] = useState(true);
  const theme = useTheme();

  const addEventListener = useCallback((event: MessageEvent) => onMessage(event.data), []);

  useEffect(() => {
    window.addEventListener('message', addEventListener);
    return () => window.removeEventListener('message', addEventListener);
  }, []);

  return (
    <>
      {visible && withLoader ? (
        <ActivityIndicator size="large" color={theme.palette.neutral[500]} />
      ) : null}
      <div
        style={StyleSheet.flatten([
          containerIframeStyles,
          containerStyles as unknown as CSSProperties,
        ])}
      >
        <iframe
          src={uri}
          height="100%"
          width="100%"
          title={title}
          data-testid={testID}
          style={StyleSheet.flatten([IframeStyles, styles as unknown as CSSProperties])}
          onLoad={() => setVisible(false)}
        />
      </div>
    </>
  );
};
