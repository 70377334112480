import styled from '@emotion/styled';

import { palette } from '@app/ui';

interface TransitionProp {
  $isIn?: boolean;
  direction?: 'right' | 'bottom';
  $isMobile?: boolean;
}

export const Backdrop = styled.div<TransitionProp>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(2, 3, 3, 0.2);
  z-index: 10;
`;

export const Content = styled.div<TransitionProp>`
  position: absolute;
  width: ${props => {
    if (props.$isMobile) {
      return '100%';
    }
    return props.direction === 'right' ? 'initial' : '100%';
  }};
  min-height: 100%;
  max-height: 100%;
  height: ${props => (props.direction === 'right' ? '100%' : 'initial')};
  right: ${props => (props.direction === 'right' ? 0 : 'initial')};
  @media screen and (min-width: 768px) {
    min-height: 80%;
  }
`;

export const CloseBtnWrapper = styled.div`
  position: absolute;
  height: 25px;
  width: 25px;
  top: 25px;
  right: 25px;
  z-index: 3;
`;

export const ContentWrapper = styled.div`
  z-index: 1;
  background-color: ${palette.base.white};
`;
