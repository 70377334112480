import { storage } from '@app/services/storage/storage';
import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';

const originMapping = [
  {
    origin: 'masteos.fr',
    siteOriginDomain: 'masteos.fr',
    utmOriginCountry: 'france',
  },
  {
    origin: 'masteos.be',
    siteOriginDomain: 'masteos.be',
    utmOriginCountry: 'belgique',
  },
  {
    origin: 'masteos.es',
    siteOriginDomain: 'masteos.es',
    utmOriginCountry: 'espagne',
  },
] as const;

const utmOriginCountries = originMapping.map(o => o.utmOriginCountry);
const siteOriginDomains = originMapping.map(o => o.siteOriginDomain);

type utmOriginCountry = (typeof utmOriginCountries)[number];
type siteOriginDomain = (typeof siteOriginDomains)[number];
type origin = (typeof originMapping)[number]['origin'];

const isUtmOrigin = (originFromUtm?: string): originFromUtm is utmOriginCountry => {
  const origin = utmOriginCountries.includes(originFromUtm as utmOriginCountry);

  if (originFromUtm && !origin) {
    ErrorMonitoring.logMessage(`Origin from utm is not valid: ${originFromUtm}`, { originFromUtm });
  }

  return origin;
};

const isSiteOrigin = (siteOrigin?: string): siteOrigin is siteOriginDomain => {
  const origin = siteOriginDomains.includes(siteOrigin as siteOriginDomain);

  if (siteOrigin && !origin) {
    ErrorMonitoring.logMessage(`Origin from site is not valid: ${siteOrigin}`, { siteOrigin });
  }

  return origin;
};

const getOriginFromUtm = async (): Promise<origin | null> => {
  const utmCampaign = (await storage.readString('campaign'))?.trim().toLowerCase();

  if (!utmCampaign) {
    return null;
  }

  const originFromUtm = utmCampaign?.split('_')?.[1];

  return isUtmOrigin(originFromUtm)
    ? originMapping.find(o => o.utmOriginCountry === originFromUtm)?.origin || null
    : null;
};

const getOriginFromSiteOrigin = async (): Promise<origin | null> => {
  const siteOrigin = (await storage.readString('site_origin'))?.trim().toLowerCase();

  return isSiteOrigin(siteOrigin)
    ? originMapping.find(o => o.siteOriginDomain === siteOrigin)?.origin || null
    : null;
};

export const getOrigin = async (): Promise<origin | undefined> => {
  const utmCampaign = await getOriginFromUtm();

  if (utmCampaign) {
    return utmCampaign;
  }

  const siteOrigin = await getOriginFromSiteOrigin();

  if (siteOrigin) {
    return siteOrigin;
  }

  return undefined;
};
