import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgExternalLink = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4.604 21.413c-.55 0-1.02-.196-1.413-.587a1.928 1.928 0 01-.587-1.413v-14c0-.55.196-1.021.587-1.413a1.928 1.928 0 011.413-.587h6a.97.97 0 01.713.287.97.97 0 01.287.713c0 .283-.095.52-.287.712a.968.968 0 01-.713.288h-6v14h14v-6c0-.283.096-.521.288-.713a.967.967 0 01.712-.287c.284 0 .52.096.712.287.192.192.288.43.288.713v6c0 .55-.195 1.021-.587 1.413a1.928 1.928 0 01-1.413.587h-14zm4-6a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7l8.6-8.6h-2.6a.965.965 0 01-.712-.288.965.965 0 01-.288-.712c0-.283.096-.521.288-.713a.967.967 0 01.712-.287h5c.284 0 .52.096.712.287.192.192.288.43.288.713v5c0 .283-.096.52-.288.712a.965.965 0 01-.712.288.965.965 0 01-.712-.288.965.965 0 01-.288-.712v-2.6L9.98 15.438a.918.918 0 01-.675.275.96.96 0 01-.7-.3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgExternalLink;
