import React from 'react';

import { StyleSheet, View } from 'react-native';

import { RenovationDetail } from '@app/libs/apollo/introspection';
import { Icon, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { RenovationDetailsIcons } from '../../utils/renovationDetails';

interface RenovationDetailItemProps {
  detail: RenovationDetail;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    item: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
    },
  });

export const RenovationDetailItem: React.FC<RenovationDetailItemProps> = ({ detail }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme);

  return (
    <View style={styles.item}>
      <Icon name={RenovationDetailsIcons[detail]} size={16} />
      <Text textStyle={isLowerThanLaptop ? 'Body2' : 'Body1'}>
        {t(`realEstate.renovation.renovationDetails.details.${detail}`)}
      </Text>
    </View>
  );
};
