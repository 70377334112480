import React, { useContext, useLayoutEffect } from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';

import { RealEstateAvailableView } from '@app/views/realEstate/realEstateAvailable/RealEstateAvailableView';
import {
  MainMenuNavigatorRoutes,
  MainMenuStackParamList,
  RealEstateNavigatorRoutes,
  RealEstatesStackParamList,
} from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import { AdvisorAppointmentView } from '@app/views/advisor/AdvisorAppointmentView';
import SearchEngine from '@app/features/search-engine/SearchEngine';
import { RealEstateMap } from '@app/views/realEstate/realEstateMap/RealEstateMap';
import { RealEstateSoldView } from '@app/views/realEstate/realEstateSold/RealEstateSoldView';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { useTranslation } from '@app/services/translations/translations';
import { RenovationSummaryDetailView } from '@app/views/realEstate/renovationTab/RenovationSummaryDetailView';
import { FurnishmentDetailsView } from '@app/views/realEstate/renovationTab/FurnishmentDetailsView';
import { RenovationDetailsView } from '@app/views/realEstate/renovationTab/RenovationDetailsView';

import { getActiveRouteName } from './utils/getActiveRouteName';

const Stack = createStackNavigator<RealEstatesStackParamList>();

export const RealEstateNavigator: React.FC<
  BottomTabScreenProps<MainMenuStackParamList, MainMenuNavigatorRoutes.RealEstates>
> = ({ navigation }) => {
  const state = navigation.getState();
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  const routeName = getActiveRouteName(state);

  useLayoutEffect(() => {
    const hideTab = [
      RealEstateNavigatorRoutes.RealEstateMap,
      RealEstateNavigatorRoutes.RenovationSummaryDetail,
      RealEstateNavigatorRoutes.FurnishmentDetails,
    ].includes(routeName as RealEstateNavigatorRoutes);

    if (hideTab) {
      navigation.setOptions({ tabBarStyle: { display: 'none' } });
    } else {
      navigation.setOptions({ tabBarStyle: { display: 'flex' } });
    }
  }, [navigation, routeName, openModal, t]);

  return (
    <Stack.Navigator
      initialRouteName={RealEstateNavigatorRoutes.RealEstateList}
      screenOptions={SCREEN_OPTIONS}
    >
      <Stack.Screen name={RealEstateNavigatorRoutes.RealEstateList} component={SearchEngine} />
      <Stack.Screen
        name={RealEstateNavigatorRoutes.RealEstate}
        component={RealEstateAvailableView}
      />
      <Stack.Screen
        navigationKey="realEstate"
        name={RealEstateNavigatorRoutes.AdvisorAppointment}
        component={AdvisorAppointmentView}
      />
      <Stack.Screen
        name={RealEstateNavigatorRoutes.RealEstateSold}
        component={RealEstateSoldView}
      />

      {/* TODO: remove when a solution has been found for the realEstate page navigator */}
      <Stack.Screen name={RealEstateNavigatorRoutes.RealEstateMap} component={RealEstateMap} />
      <Stack.Screen
        name={RealEstateNavigatorRoutes.RenovationSummaryDetail}
        component={RenovationSummaryDetailView}
      />
      <Stack.Screen
        name={RealEstateNavigatorRoutes.FurnishmentDetails}
        component={FurnishmentDetailsView}
      />
      <Stack.Screen
        name={RealEstateNavigatorRoutes.RenovationDetails}
        component={RenovationDetailsView}
      />
      {/* END: remove when a solution has been found for the realEstate page navigator */}
    </Stack.Navigator>
  );
};
