import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMasteos = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.111 8.41c.104-.368.245-.72.424-1.042.148-.276.327-.529.527-.766.23-.269.484-.514.766-.72A4.426 4.426 0 0117.474 5C19.978 5 22 7.084 22 9.667v8.709c0 .345-.28.624-.624.624h-7.428a1 1 0 01-1-1V9.667c0-.267.019-.529.06-.782.025-.162.06-.32.103-.475zm-2.121.478C10.63 6.678 8.767 5 6.526 5c-.988 0-1.902.33-2.646.881a4.601 4.601 0 00-.765.72c-.2.238-.38.491-.528.767A4.656 4.656 0 002 9.667V18a1 1 0 001 1h7.428a.624.624 0 00.624-.624v-8.71c0-.265-.021-.525-.062-.778z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMasteos;
