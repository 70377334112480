import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgZap = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10.664 3a.57.57 0 00-.45.2.539.539 0 00-.125.475l.9 6.325h-3.5c-.25 0-.404.067-.463.2-.058.133-.02.317.113.55l5.7 9.975c.034.083.1.15.2.2.1.05.192.075.275.075a.57.57 0 00.45-.2.54.54 0 00.125-.475L12.99 14h3.5c.25 0 .404-.067.462-.2.059-.133.022-.317-.112-.55l-5.7-9.975a.39.39 0 00-.2-.2.622.622 0 00-.275-.075z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgZap;
