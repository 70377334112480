import React, { useMemo } from 'react';

import { AvatarWithLabelSelector, IconName } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { extractAdvisorInfo } from '@app/utils/extractCustomerContactInfo';
import { useMe } from '@app/shared/hooks/useMe';
import { fromS3ID } from '@app/utils/from-s3id';
import { useDateFormatter } from '@app/shared/hooks/useDateFormatter';

import { AdvisorSelectorProps } from './AdvisorSelector.types';

export const AdvisorSelector: React.FC<AdvisorSelectorProps> = ({ source, contact }) => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();
  const { isAssetManagementConsultant, customer } = useMe();

  const { onCallClick, onMailClick, onWhatsappClick } = extractAdvisorInfo({
    contact,
    customer,
    formatDate,
    source,
    t,
  });

  const options = useMemo(() => {
    const optionsArray = [
      {
        iconName: 'Phone' as IconName,
        key: 'phone',
        label: t('advisor.callSectionTitle'),
        onSelect: closeList => {
          onCallClick();
          closeList();
        },
      },
      {
        iconName: 'Whatsapp' as IconName,
        key: 'Whatsapp',
        label: t('advisor.whatsAppSectionTitle'),
        onSelect: closeList => {
          onWhatsappClick();
          closeList();
        },
      },
    ];

    if (!contact?.firstName) {
      optionsArray.push({
        iconName: 'Mail' as IconName,
        key: 'mail',
        label: t('advisor.mailSectionTitle'),
        onSelect: closeList => {
          onMailClick();
          closeList();
        },
      });
    }

    return optionsArray;
  }, [t, contact?.firstName, onCallClick, onWhatsappClick, onMailClick]);

  if (!customer || isAssetManagementConsultant) {
    return null;
  }

  return (
    <AvatarWithLabelSelector
      avatarUrl={fromS3ID(contact?.avatarS3ID)}
      options={options}
      label={t('advisor.contactMyExpert', {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
      })}
    />
  );
};
