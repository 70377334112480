import { get } from 'lodash';

import { Customer, GetCustomerQuery } from '@app/libs/apollo/introspection';

import { SearchEngineSortStrategy } from '../compounds/search-engine-filters/search-engine-sort/SearchEngineSort.types';

export const getSortedCustomerPref = (
  customer: GetCustomerQuery['me']
): Customer['preferences'] | undefined => {
  if (!customer?.preferences) {
    return undefined;
  }

  return {
    ...customer?.preferences,
    regions: [...(get(customer, 'preferences.regions') ?? [])].sort(),
    sortStrategy: !customer.searchMandate?.valid
      ? SearchEngineSortStrategy.NetReturnDesc
      : SearchEngineSortStrategy.LastPublishedAtDesc,
  };
};
