import React, { ReactElement, useMemo } from 'react';

import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/ui/utils/translation';
import { RadioGroup, RadioItem } from '@app/ui/atoms/RadioButton';
import { Theme } from '@app/ui/theme/types';
import { useTheme } from '@app/ui/contexts/config';

import { TrileanControllerType } from './TrileanController.types';
import { trileanToString } from './utils/trileanToString';
import { stringToTrilean } from './utils/stringToTrilean';
import { translations } from './translations';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_07,
    },
  });

export const TrileanController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label: l,
  testID,
  radioLabels,
  ...props
}: TrileanControllerType<TFieldValues, TName>): ReactElement => {
  const { t } = useTranslation(translations);
  const theme = useTheme();
  const styles = getStyles(theme);

  const trileanValues = useMemo(() => {
    if (!radioLabels) {
      return [
        { label: t('true') as string, val: true },
        { label: t('false') as string, val: false },
        { label: t('null') as string, val: null },
      ];
    }
    return [
      { label: radioLabels.true, val: true },
      { label: radioLabels.false, val: false },
      { label: radioLabels.null, val: null },
    ];
  }, [radioLabels, t]);

  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          label={l}
          value={trileanToString(value)}
          onValueChange={v => onChange(stringToTrilean(v))}
        >
          {/*FIXME: implement inline on RadioGroup and use it instead of Gap*/}
          <View style={styles.container}>
            {Object.values(trileanValues).map(({ label, val }) => (
              <RadioItem
                key={label}
                testID={`${testID}-${val}`}
                value={trileanToString(val)}
                label={label}
                checked={val === value}
              />
            ))}
          </View>
        </RadioGroup>
      )}
    />
  );
};
