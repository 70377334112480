import get from 'lodash/get';

import { MatchesType, QueryType } from './useContainerQueriesHook.types';

type argsMatchesWidthsType = {
  width: number;
  query: QueryType;
};

export type matchesWidthsType = (args: argsMatchesWidthsType) => MatchesType;

/**
 * Returns whether the queries match according to the width value
 * @param  query Object of queries : { small: 250, medium: 850 }
 * @return       Queries object with value matched : { small: true, medium: false }
 */
export const matchesWidths: matchesWidthsType = ({ width, query }) => {
  if (width === 0) {
    return {};
  }

  return Object.keys(query).reduce((result, currentKey) => {
    if (!query) {
      return result;
    }

    const queryWidth = get(query, currentKey);

    result[currentKey] = queryWidth >= width;

    return result;
  }, {} as MatchesType);
};
