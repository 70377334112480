import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { ScrollView, View } from 'react-native';

import { Spacer, Spacing } from '@app/ui';
import { config } from '@app/config';

import { hideString } from './utils/hideString';
import { Data } from './compounds/Data';
import { DebugMenuWrapper } from './compounds/DebugMenuWrapper';
import { DebugMenuHeader } from './compounds/DebugMenuHeader';
import { DebugMenuContentWrapper } from './compounds/DebugMenuContentWrapper';

const getHiddenString = (value: string): string => hideString(value, value.length / 1.3);

const getValue = (value: string): string => {
  if (value && typeof value === 'string') {
    return getHiddenString(value);
  } else if (typeof value === 'boolean') {
    return `${value}`;
  }
  return 'undefined';
};

const DebugMenuEnvironmentVariables = (): JSX.Element => {
  const navigation = useNavigation();

  const newEnvs = Object.keys(config)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc, key) => {
      acc[key] = getValue(config[key]);
      return acc;
    }, {});

  return (
    <DebugMenuWrapper>
      <DebugMenuHeader title="Environment Variables" onPress={() => navigation.goBack()} />

      <ScrollView>
        <DebugMenuContentWrapper>
          {Object.keys(newEnvs).map(key => (
            <View key={key} testID={`env-${key}`}>
              <Data label={key} value={newEnvs[key]} isVertical />
              <Spacer height={Spacing.SIZE_05} />
            </View>
          ))}
        </DebugMenuContentWrapper>
      </ScrollView>
    </DebugMenuWrapper>
  );
};

export { DebugMenuEnvironmentVariables };
