import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgEc = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="ec_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#ec_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.667h28V9.333H0v5.334z"
          fill="#0748AE"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-5.333H0V20z" fill="#DE2035" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 9.333h28V0H0v9.333z" fill="#FFD935" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4 8a2.667 2.667 0 103.2 0l-.933 2.8h-1.334L12.4 8z"
          fill="#FFD935"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 7.333l.667 3.334h-1.334L14 7.333z"
          fill="#5FC0DC"
        />
        <Path
          opacity={0.66}
          d="M17.333 6l-2-.667L14 6.667l-1.333-1.334-2 .667"
          stroke="#3F2821"
          strokeWidth={0.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

export default SvgEc;
