import { Theme } from '@app/ui/theme/types';

export const avatarTheme = (theme: Theme) => {
  return {
    background: {
      backgroundColor: theme.palette.neutral[200],
      borderRadius: theme.spacing.SIZE_09,
      size: theme.spacing.SIZE_09,
    },
    icon: {
      color: theme.palette.neutral[500],
      size: theme.spacing.SIZE_06,
    },
  };
};
