import React, { useCallback } from 'react';

import { View } from 'react-native';

import { ListItem } from '@app/ui/atoms/list/ListItem';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { isLastItem } from '@app/ui/utils/selector';
import { AvatarWithLabelActivator } from '@app/ui/molecules/dropdown-activator/avatar-with-label-activator/AvatarWithLabelActivator';
import { Text } from '@app/ui/atoms/Text/Text';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { Spacing } from '@app/ui/theme/Sizes';

import {
  AvatarWithLabelSelectorProps,
  AvatarWithLabelSelectorOptionType,
} from './AvatarWithLabelSelector.types';
import { getStyles } from './AvatarWithLabelSelector.styles';

export { AvatarWithLabelSelectorProps, AvatarWithLabelSelectorOptionType };

export const AvatarWithLabelSelector: React.FC<AvatarWithLabelSelectorProps> = ({
  options,
  label,
  avatarUrl,
  maxWidth,
  minWidth = 200,
}) => {
  const { isNativeOrResponsive } = useResponsive();

  const styles = getStyles({ isNativeOrResponsive });

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="avatar-with-label-selector-list">
          {options.map(({ onSelect, ...restItem }, ind) => {
            const onPress = () => {
              onSelect(closeList);
            };

            return (
              <ListItem
                {...restItem}
                key={restItem.key || restItem.label.toLowerCase()}
                onPress={onPress}
                isLastItem={isLastItem(options.length, ind)}
              />
            );
          })}
        </View>
      );
    },
    [options, styles.listContainer]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight }: ListType) => {
      const header = isNativeOrResponsive ? (
        <View style={styles.listHeader} testID="avatar-with-label-selector-list-header">
          <Text testID="ModalTitle" textStyle="Title3Medium">
            {label}
          </Text>
          <Spacer height={Spacing.SIZE_04} />
        </View>
      ) : undefined;

      return (
        <DropdownListContainer header={header} list={getList(closeList)} maxHeight={maxHeight} />
      );
    },
    [getList, isNativeOrResponsive, label, styles.listHeader]
  );

  const renderActivator = useCallback(
    ({ toggleList }: RenderActivatorDisplayType) => {
      return (
        <AvatarWithLabelActivator
          size={20}
          label={label}
          onPress={toggleList}
          avatarUrl={avatarUrl}
        />
      );
    },
    [label, avatarUrl]
  );

  return (
    <DropdownV2
      renderList={renderList}
      renderActivator={renderActivator}
      fitParent={false}
      minWidth={minWidth}
      maxWidth={maxWidth}
      testID="avatar-with-label-selector-dropdown"
    />
  );
};
