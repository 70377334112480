import { useMemo } from 'react';

import { CountryMapper } from '@masteos/agora';

import {
  GetFinancialModelTemplatesQuery,
  useGetFinancialModelTemplatesQuery,
} from '@app/libs/apollo/introspection';

interface ReturnType {
  loading: boolean;
  templates: null | CountryMapper;
}

const parseTemplates = (data: GetFinancialModelTemplatesQuery) => {
  if (!data) {
    return null;
  }

  return {
    templates: JSON.parse(data.financialTemplate.templatesJSON),
  };
};

export const useQueryFinancialModelTemplates = (): ReturnType => {
  const { data, loading } = useGetFinancialModelTemplatesQuery({
    fetchPolicy: 'cache-first',
  });

  const financialTemplate = useMemo(() => parseTemplates(data), [data]);

  return {
    loading,
    templates: financialTemplate ? (financialTemplate.templates as unknown as CountryMapper) : null,
  };
};
