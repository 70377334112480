import { Theme } from '@app/ui/theme/types';

export const cardTheme = (theme: Theme) => {
  return {
    blockStyle: {
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: theme.spacing.SIZE_00,
      noPaddingBottom: theme.spacing.NONE,
      paddingBottom: theme.spacing.SIZE_05,
    },
    cardStyle: {
      backgroundColor: theme.palette.base.white,
      borderRadius: theme.spacing.SIZE_06,
      shadow: {
        elevation: 5,
        shadowColor: theme.palette.neutral[900],
        shadowOffset: { height: 4, width: 0 },
        shadowOpacity: 0.1,
        shadowRadius: theme.spacing.SIZE_09,
      },
    },
    chipStyles: {
      bottom: theme.spacing.SIZE_04,
      left: theme.spacing.SIZE_04,
      right: theme.spacing.SIZE_04,
      top: theme.spacing.SIZE_04,
    },
    commonStyle: {
      content: {
        blockWithoutFirstChild: {
          paddingTop: theme.spacing.SIZE_05,
        },
        padding: theme.spacing.SIZE_06,
      },
      header: {
        borderTopLeftRadius: theme.spacing.SIZE_06,
        borderTopRightRadius: theme.spacing.SIZE_06,
      },
    },
  };
};
