import React, { useCallback, useMemo } from 'react';

import { View, StyleSheet } from 'react-native';

import { DropdownV2 } from '@app/ui/molecules/dropdownV2/Dropdown';
import { DropdownListContainer } from '@app/ui/molecules/dropdown-list-container/DropdownListContainer';
import { CounterActivator } from '@app/ui/molecules/dropdown-activator/counter-activator/CounterActivator';
import { DropdownFooterActions } from '@app/ui/molecules/dropdown-footer/dropdown-footer-actions/DropdownFooterActions';
import { ListType, RenderActivatorDisplayType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { ExpandableGroup, Theme, useTheme } from '@app/ui';

import { FilterGroupListSelectorProps } from './FilterGroupListSelector.types';
import { SelectGroupItem } from '../select-group/SelectGroupItem';
import { SelectGroupHeader } from '../select-group/SelectGroupHeader';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    footer: {
      flexDirection: 'row',
      padding: theme.spacing.SIZE_06,
    },
    headerContainer: {
      paddingHorizontal: theme.spacing.SIZE_04,
      paddingTop: theme.spacing.SIZE_06,
    },
    listContainer: { padding: theme.spacing.SIZE_06 },
  });

export const FilterGroupListSelector: React.FC<FilterGroupListSelectorProps> = ({
  optionGroups,
  maxHeight = 310,
  minWidth = 296,
  values,
  placeholder,
  multiple = false,
  onCallbackClose,
  buttonLabel,
  onPrimaryClick,
  onGhostClick,
  disabled,
  onChangeValues,
  selectAllLabel,
  unselectAllLabel,
  formatActivatorValues,
  ...rest
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const optionGroupsWithSelected = useMemo(
    () =>
      optionGroups.map(optionGroup => ({
        label: optionGroup.label,
        options: optionGroup.options.map(x => ({
          ...x,
          selected: !!values.find(value => value === x.key),
        })),
      })),
    [optionGroups, values]
  );

  const getList = useCallback(
    (closeList: ListType['closeList']) => {
      return (
        <View style={styles.listContainer} testID="filter-group--list-selector-list-container">
          <ExpandableGroup
            allowMultipleExpand={true}
            initialExpandedId={optionGroupsWithSelected[0]?.label}
          >
            {optionGroupsWithSelected.map((item, index) => (
              <SelectGroupItem
                isFirst={index === 0}
                key={item.label}
                {...(multiple
                  ? {
                      canSelectAll: !!selectAllLabel,
                      multiple: true,
                    }
                  : {
                      canSelectAll: false,
                      multiple: false,
                    })}
                closeList={closeList}
                label={item.label}
                options={item.options}
                onChangeValues={onChangeValues}
                values={values}
              />
            ))}
          </ExpandableGroup>
        </View>
      );
    },
    [
      styles.listContainer,
      optionGroupsWithSelected,
      multiple,
      selectAllLabel,
      onChangeValues,
      values,
    ]
  );

  const renderActivator = useCallback(
    ({ toggleList, isOpen }: RenderActivatorDisplayType) => {
      return (
        <CounterActivator
          onPress={toggleList}
          placeholder={placeholder}
          values={values}
          open={isOpen}
          disabled={disabled}
          noCounter={!multiple}
          formatActivatorValues={formatActivatorValues}
        />
      );
    },
    [placeholder, values, disabled, multiple, formatActivatorValues]
  );

  const header = useMemo(() => {
    if (!selectAllLabel) {
      return null;
    }

    return (
      <SelectGroupHeader
        optionGroups={optionGroups}
        values={values}
        onChangeValues={onChangeValues}
        selectAllLabel={selectAllLabel}
        unselectAllLabel={unselectAllLabel}
      />
    );
  }, [values, optionGroups, onChangeValues, selectAllLabel, unselectAllLabel]);

  const getFooter = useCallback(
    (closeList: () => void) => {
      return (
        <DropdownFooterActions
          containerStyle={styles.footer}
          buttonLabel={buttonLabel}
          onGhostClick={onGhostClick}
          onPrimaryClick={onPrimaryClick}
          closeList={closeList}
        />
      );
    },
    [buttonLabel, onGhostClick, onPrimaryClick, styles.footer]
  );

  const renderList = useCallback(
    ({ closeList, maxHeight: maxHeightList }: ListType) => {
      return (
        <DropdownListContainer
          header={header}
          list={getList(closeList)}
          footer={getFooter(closeList)}
          maxHeight={maxHeightList}
        />
      );
    },
    [getFooter, getList, header]
  );

  return (
    <DropdownV2
      {...rest}
      renderList={renderList}
      maxHeight={maxHeight}
      minWidth={minWidth}
      fitParent={false}
      onCallbackClose={onCallbackClose}
      renderActivator={renderActivator}
    />
  );
};
