import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgIq = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="iq_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#iq_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 6.667h28V0H0v6.667z" fill="#DF1E35" />
        <Path d="M0 20h28v-6.667H0V20z" fill="#262626" />
        <Path
          d="M18.667 8.54c0-.248.26-.41.482-.299l.667.334a.333.333 0 01.184.298v2.794c0 .184-.15.333-.333.333H19a.333.333 0 01-.333-.333V8.539zM10.667 8.54c0-.248.26-.41.482-.299l.667.334a.333.333 0 01.184.298v2.794c0 .184-.15.333-.333.333H11a.333.333 0 01-.333-.333V8.539zM16 8.873c0-.127.071-.242.184-.298l.667-.334c.222-.11.482.05.482.298v3.128c0 .184-.149.333-.333.333h-.667a.333.333 0 01-.333-.333V8.873z"
          fill="#0F7A40"
        />
        <Path
          d="M13.333 10.206c0-.126.072-.242.185-.298l.666-.333c.222-.111.483.05.483.298v.853h2V12h-3a.333.333 0 01-.334-.333v-1.461zM8 10.206c0-.126.071-.242.184-.298l.667-.333c.222-.111.482.05.482.298v.853h2V12h-3A.333.333 0 018 11.667v-1.461z"
          fill="#0F7A40"
        />
      </G>
    </Svg>
  );
};

export default SvgIq;
