import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { FAQQuestion } from './FAQQuestion';

const getStyles = (theme: Theme, isLowerThanLaptop: boolean) =>
  StyleSheet.create({
    container: {
      gap: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_07,
    },
    title: {
      textAlign: 'center',
    },
  });

export const RenovationFAQ: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme, isLowerThanLaptop);

  return (
    <View style={styles.container}>
      <Text textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'} style={styles.title}>
        {t('realEstate.renovation.faq.title')}
      </Text>

      <View>
        <FAQQuestion
          questionKey="realEstate.renovation.faq.1.question"
          answerKey="realEstate.renovation.faq.1.answer"
        />
        <FAQQuestion
          questionKey="realEstate.renovation.faq.2.question"
          answerKey="realEstate.renovation.faq.2.answer"
        />
        <FAQQuestion
          questionKey="realEstate.renovation.faq.3.question"
          answerKey="realEstate.renovation.faq.3.answer"
        />
        <FAQQuestion
          questionKey="realEstate.renovation.faq.4.question"
          answerKey="realEstate.renovation.faq.4.answer"
        />
      </View>
    </View>
  );
};
