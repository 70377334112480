import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

import { Modal, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { LogLevel, log } from '@app/utils/logs/log';
import { Theme, useTheme } from '@app/ui';

import { GalleryHeader } from './components/GalleryHeader';
import { MAX_IMAGE_WIDTH } from './hooks/useGallery';
import { Gallery } from './Gallery';
import {
  GalleryBlockContextProps as GalleryContextProps,
  OpenGalleryProps,
  SliderImageObject,
} from './gallery.types';

export const GalleryContext = React.createContext<GalleryContextProps>({
  closeGallery: () => {
    log('Default close method', LogLevel.debug);
  },
  openGallery: () => {
    log('Default open method', LogLevel.debug);
  },
});

export const useGalleryContext = (): GalleryContextProps => useContext(GalleryContext);

interface RealEstateProviderProps {
  propertyId: string;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    centeredContent: {
      flex: 1,
      maxWidth: MAX_IMAGE_WIDTH,
    },
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.black,
      flex: 1,
    },
    modal: {
      backgroundColor: theme.palette.base.black,
    },
  });

export const GalleryProvider: React.FC<PropsWithChildren<RealEstateProviderProps>> = ({
  children,
  propertyId,
}) => {
  const [images, setImages] = useState<SliderImageObject[] | null>(null);
  const [options, setOptions] = useState<Omit<OpenGalleryProps, 'images'> | null>();

  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const onClose = () => {
    setImages(null);
    setOptions(null);
  };

  const openGallery = ({ images: imgs, ...opts }: OpenGalleryProps): void => {
    setImages(imgs);
    setOptions(opts);
  };

  return (
    <>
      <GalleryContext.Provider value={{ closeGallery: onClose, openGallery }}>
        {children}
      </GalleryContext.Provider>

      {!!images && (
        <Modal
          visible={!!images}
          style={styles.modal}
          onRequestClose={onClose}
          animationType="fade"
          statusBarTranslucent
        >
          <SafeAreaView style={styles.container} edges={['top']}>
            <View style={styles.centeredContent}>
              <GalleryHeader onClose={onClose} />

              <Gallery
                images={images}
                url3d={options?.url3d}
                contact={options?.contact}
                defaultIndex={options?.defaultIndex}
                appointmentImage={options?.appointmentImage}
                realEstateId={propertyId}
              />
            </View>
          </SafeAreaView>
        </Modal>
      )}
    </>
  );
};
