import React, { PropsWithChildren, Suspense, useContext } from 'react';

import { View } from 'react-native';

import { PublicRealEstate, RealEstate, SoldRealEstateV2 } from '@app/libs/apollo/introspection';

interface RealEstateContextProps {
  realEstate: RealEstate | PublicRealEstate | SoldRealEstateV2 | null;
  selectCountryCode: () => string;
  selectRegionCode: () => string;
}

export const RealEstateContext = React.createContext<RealEstateContextProps>({
  realEstate: null,
  selectCountryCode: () => '',
  selectRegionCode: () => '',
});

interface RealEstateProviderProps {
  realEstate: RealEstate | PublicRealEstate | SoldRealEstateV2;
}

export const useRealEstate = (): RealEstateContextProps => useContext(RealEstateContext);

export const RealEstateProvider: React.FC<PropsWithChildren<RealEstateProviderProps>> = ({
  realEstate,
  children,
}) => {
  const selectCountryCode = () => realEstate.building.address.countryCode;
  const selectRegionCode = () => realEstate.building.address.regionCode || '';

  const value: RealEstateContextProps = {
    realEstate,
    selectCountryCode,
    selectRegionCode,
  };

  return (
    <RealEstateContext.Provider value={value}>
      <Suspense fallback={<View />}>{children}</Suspense>
    </RealEstateContext.Provider>
  );
};
