import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgPr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="pr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#pr_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0h28v4H0V0zm0 8h28v4H0V8zm0 8v4h28v-4H0z"
          fill="#FA2222"
        />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 0l13.333 10L0 20V0z" fill="#1D63F9" />
        <G filter="url(#pr_svg__filter0_d_4223_11658)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.667 11.274l-1.96 1.423.748-2.303L1.496 8.97h2.422l.749-2.303.749 2.302 2.42.001-1.958 1.424.748 2.303-1.96-1.423z"
            fill="url(#pr_svg__paint0_linear_4223_11658)"
          />
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="pr_svg__paint0_linear_4223_11658"
          x1={1.333}
          y1={6.667}
          x2={1.333}
          y2={13.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset={1} stopColor="#F0F0F0" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgPr;
