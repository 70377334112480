import React, { useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

import { Text } from '@app/ui/atoms/Text/Text';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { Icon } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';

import { SvgDiamond } from './SvgDiamond';
import { computePosition, POPOVER_CONSTANTS, TOOLTIP_CONSTANTS } from './utils/computePosition';
import {
  DiamondContainer,
  getStyles,
  ContentContainer,
  Wrapper,
  IconWrapper,
} from './PopoverContainer.styles.web';
import { ComputedPosition, PopoverContainerProps } from './PopoverContainer.types';

export const PopoverContainer: React.FC<PopoverContainerProps> = ({
  open,
  title,
  content,
  triggerRef,
  onHoverIn,
  onHoverOut,
  onClose,
  onPressOutside = onClose,
  variant = 'inDark',
  renderAsTooltip = false,
  showCloseButton = false,
}) => {
  const theme = useTheme();
  const isInLight = variant === 'inLight';
  const styles = getStyles({ isInLight, theme });

  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<ComputedPosition>({
    diamondX: 0,
    diamondY: 0,
    x: 0,
    y: 0,
  });

  const CONSTANTS = renderAsTooltip ? TOOLTIP_CONSTANTS : POPOVER_CONSTANTS;

  useEffect(() => {
    if (open && ref?.current && triggerRef?.current && !visible) {
      const parameters = {
        CONSTANTS,
        container: ref.current.getBoundingClientRect(),
        trigger: (triggerRef.current as HTMLDivElement).getBoundingClientRect(),
        viewport: { height: window.innerHeight, width: window.innerWidth },
      };
      setPosition(computePosition(parameters));
      setVisible(true);
    }

    if (!open && visible) {
      setVisible(false);
    }
  }, [ref, triggerRef, open, renderAsTooltip]);

  const onPressOutsideListener = (event: MouseEvent) => {
    if (
      open &&
      !ref.current?.contains(event.target as Node) &&
      !(triggerRef.current as HTMLDivElement)?.contains(event.target as Node)
    ) {
      onPressOutside?.();
    }
  };

  const preventClickPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('mousedown', onPressOutsideListener);

    return () => {
      document.removeEventListener('mousedown', onPressOutsideListener);
    };
  }, [onPressOutside, open]);

  return ReactDOM.createPortal(
    <Wrapper
      position={position}
      visible={visible}
      ref={ref}
      isInLight={isInLight}
      renderAsTooltip={renderAsTooltip}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      onClick={preventClickPropagation}
    >
      <DiamondContainer diamondX={position.diamondX} diamondY={position.diamondY}>
        <SvgDiamond
          height={CONSTANTS.diamondSize}
          width={CONSTANTS.diamondSize}
          color={
            renderAsTooltip || !isInLight ? theme.palette.neutral[800] : theme.palette.base.white
          }
        />
      </DiamondContainer>
      {!renderAsTooltip && showCloseButton ? (
        <IconWrapper onClick={onClose}>
          <Icon
            name="Cross"
            color={isInLight ? theme.palette.neutral[800] : theme.palette.neutral[100]}
            size={24}
          />
        </IconWrapper>
      ) : null}
      <ContentContainer renderAsTooltip={renderAsTooltip}>
        {!!title && (
          <>
            <Text textStyle={renderAsTooltip ? 'Body3' : 'Body1Medium'} style={styles.title}>
              {title}
            </Text>
            <Spacer height={content ? theme.spacing.SIZE_02 : 0} />
          </>
        )}
        {typeof content === 'string' ? (
          <Text
            textStyle="Body2"
            style={{
              color: isInLight ? theme.palette.neutral[800] : theme.palette.neutral[100],
            }}
          >
            {content}
          </Text>
        ) : (
          content
        )}
      </ContentContainer>
    </Wrapper>,
    document.getElementsByTagName('body')[0] as HTMLElement
  );
};
