import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgLocation = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 16.475c1.65-1.333 2.896-2.617 3.738-3.85C16.579 11.392 17 10.233 17 9.15c0-.933-.17-1.73-.512-2.388-.342-.658-.763-1.191-1.263-1.6a4.862 4.862 0 00-1.625-.887C13.017 4.092 12.483 4 12 4c-.483 0-1.017.092-1.6.275a4.861 4.861 0 00-1.625.887c-.5.409-.92.942-1.262 1.6C7.171 7.421 7 8.217 7 9.15c0 1.083.42 2.242 1.262 3.475.842 1.233 2.088 2.517 3.738 3.85zm0 2.175c-.15 0-.292-.021-.425-.063a1.31 1.31 0 01-.375-.187c-2.083-1.617-3.637-3.192-4.662-4.725C5.513 12.142 5 10.633 5 9.15c0-1.183.213-2.221.638-3.113.425-.891.97-1.637 1.637-2.237a6.755 6.755 0 012.25-1.35A7.27 7.27 0 0112 2c.817 0 1.642.15 2.475.45.833.3 1.583.75 2.25 1.35.667.6 1.213 1.346 1.638 2.237.425.892.637 1.93.637 3.113 0 1.483-.513 2.992-1.538 4.525-1.025 1.533-2.579 3.108-4.662 4.725a.993.993 0 01-.362.187 1.536 1.536 0 01-.438.063zM12 11c.55 0 1.021-.196 1.413-.588C13.804 10.021 14 9.55 14 9c0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0012 7c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0010 9c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588zM6 22a.968.968 0 01-.713-.288A.967.967 0 015 21c0-.283.096-.52.287-.712A.968.968 0 016 20h12c.283 0 .52.096.712.288A.965.965 0 0119 21c0 .283-.096.52-.288.712A.965.965 0 0118 22H6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgLocation;
