import { CancelPositioningFormStepProps } from './compounds/cancel-positioning-form-step/CancelPositioningFormStep.types';

export type CancelPositioningModalProps = {
  isSuggestion: boolean;
  onSubmit: CancelPositioningFormStepProps['onSubmit'];
  onClose: () => void;
};

export enum CancelPositioningModalStep {
  FORM = 'FORM',
  THANKS = 'THANKS',
}
