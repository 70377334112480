import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { FONT_WEIGHTS } from '@app/ui/theme/fonts';

interface StylesParams {
  theme: Theme;
  hovered?: boolean;
  opened?: boolean;
  isDisabled?: boolean;
  isPlaceholder?: boolean;
}

const CHEVRON_SIZE = 20;

const getBorderColor = ({ hovered, isDisabled, opened, theme }: StylesParams) => {
  if (isDisabled) {
    return theme.palette.neutral[300];
  }

  if (opened) {
    return theme.palette.neutral[900];
  }

  if (hovered) {
    return theme.palette.neutral[300];
  }

  return theme.palette.neutral[200];
};

export const getPressableStyles = ({
  hovered,
  isDisabled,
  opened,
  theme,
  isPlaceholder,
}: StylesParams) =>
  StyleSheet.create({
    chevron: {
      position: 'absolute',
      right: theme.spacing.SIZE_04,
    },
    inputContainer: {
      alignItems: 'center',
      borderColor: getBorderColor({ hovered, isDisabled, opened, theme }),
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing.SIZE_04,
      paddingRight: theme.spacing.SIZE_04 + CHEVRON_SIZE,
      paddingVertical: theme.spacing.SIZE_05,
      width: '100%',
    },
    text: {
      ...theme.textStyles.Body2,
      color: theme.palette.neutral[isDisabled ? 600 : 900],
      fontWeight: isPlaceholder ? FONT_WEIGHTS.regular : FONT_WEIGHTS.medium,
    },
  });

export const getStyles = ({
  isDisabled,
  theme,
  opened,
}: Pick<StylesParams, 'theme' | 'isDisabled' | 'opened'>) =>
  StyleSheet.create({
    container: { width: '100%' },
    helperText: {
      ...theme.textStyles.Caption1,
      color: isDisabled ? theme.palette.neutral[600] : theme.palette.alert.info,
      marginLeft: theme.spacing.SIZE_02,
      marginTop: theme.spacing.SIZE_02,
      opacity: opened ? 0 : 1,
    },
  });
