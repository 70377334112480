import React, { useContext } from 'react';

import { Button, useResponsive } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import {
  TakeAppointmentSource,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import { DiscussTakeAppointmentContent } from '@app/features/discuss/components/DiscussTakeAppointmentContent';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { DiscussModalTitle } from '@app/features/discuss/components/DiscussModalTitle';

interface RealEstatePreAppointmentButtonProps {
  realEstateId: string;
}

export const RealEstatePreAppointmentButton: React.FC<RealEstatePreAppointmentButtonProps> = ({
  realEstateId,
}): React.ReactElement => {
  const { t } = useTranslation();
  const { isNativeOrResponsive, isLowerThanDesktop } = useResponsive();

  const { openModal } = useContext(ModalContext);

  const isNativeOrLowerThanDesktop = isNativeOrResponsive || isLowerThanDesktop;

  const onButtonPress = () => {
    trackTakeAdvisorAppointmentClick({
      propertyId: realEstateId,
      sourcePage: TakeAppointmentSource.propertyPage,
    });

    openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentContent />, {
      position: 'center',
      title: <DiscussModalTitle />,
      webSize: 'l',
    });
  };

  return (
    <Button
      iconName="Phone"
      iconPlacement="left"
      onPress={onButtonPress}
      testID="property-appointment-btn"
      wrapperStyle={{
        ...(isNativeOrLowerThanDesktop && { flex: 1 }),
      }}
    >
      {t('propertyActions.takeAppointment')}
    </Button>
  );
};
