import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgTr = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="tr_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#tr_svg__a)">
        <Path fill="#E92434" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.02 10.768l-1.224 1.485.084-1.923-1.791-.706 1.855-.515.117-1.921 1.063 1.605 1.864-.482-1.198 1.507 1.034 1.624-1.803-.674z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.403 13.684a6 6 0 110-7.368 5.21 5.21 0 00-3.07-.983c-2.761 0-5 2.09-5 4.667s2.239 4.667 5 4.667a5.21 5.21 0 003.07-.983z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgTr;
