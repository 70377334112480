/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StyleSheet } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';

import { Theme } from '../../theme/types';

export const getStyles = ({
  crossSecondary,
  dimensions,
  leftPositionCross,
  noPadding,
  inset,
  theme,
}: {
  dimensions: { width: number; height: number };
  leftPositionCross: boolean;
  crossSecondary: boolean;
  noPadding: boolean;
  inset: EdgeInsets;
  theme: Theme;
}) =>
  StyleSheet.create({
    closeButton: {
      height: 'auto',
      position: 'absolute',
      right: theme.spacing.SIZE_04,
      top: theme.spacing.SIZE_04 + inset.top,
      width: 'auto',
      ...(crossSecondary
        ? {
            backgroundColor: theme.palette.base.white,
            borderRadius: theme.spacing.SIZE_07,
            padding: theme.spacing.SIZE_03,
          }
        : {}),
      zIndex: 1,
      ...(leftPositionCross ? { left: theme.spacing.SIZE_04 } : {}),
    },
    container: {
      flex: 1,
      height: dimensions.height,
      width: dimensions.width,
    },
    fullScreen: {
      padding: noPadding ? 0 : theme.spacing.SIZE_08,
      top: 0,
    },
    wrapper: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
      paddingTop: inset.top,
    },
  });
