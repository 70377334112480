import { StyleSheet } from 'react-native';

export const getStyles = ({ isNativeOrResponsive }: { isNativeOrResponsive: boolean }) =>
  StyleSheet.create({
    listContainer: {
      paddingHorizontal: isNativeOrResponsive ? 24 : 12,
      paddingVertical: 12,
    },
    listHeader: {
      paddingHorizontal: 24,
      paddingTop: 20,
    },
  });
