import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';

import { useTranslation } from 'react-i18next';
import { StyleProp, ViewStyle } from 'react-native';

import { Button } from '@app/ui';
import { trackAppleAuthButtonClicked } from '@app/services/tracking/trackTracking';
import { SSOProfile } from '@app/features/authBridge/authBridge.types';

import { useAppleSignIn } from './useAppleSignIn';

interface AppleSignInProps {
  isLoading?: boolean;
  onSignInSuccess: (idToken: string, user: SSOProfile) => void;
  onSignInFailure?: () => void;
}

const buttonStyles: Record<string, StyleProp<ViewStyle>> = {
  wrapper: {
    width: '100%',
  },
};

const AppleSignInWithRef: ForwardRefRenderFunction<unknown, AppleSignInProps> = (
  { isLoading, onSignInSuccess, onSignInFailure },
  ref
) => {
  const { t } = useTranslation();
  const signIn = useAppleSignIn(onSignInSuccess, onSignInFailure);

  const handleSignIn = useCallback(async () => {
    trackAppleAuthButtonClicked();
    await signIn();
  }, []);

  useImperativeHandle(ref, () => ({
    signIn: handleSignIn,
  }));

  return (
    <Button
      variant="tertiary"
      isLoading={isLoading}
      onPress={handleSignIn}
      wrapperStyle={buttonStyles.wrapper}
      iconName="Apple"
      iconPlacement="left"
    >
      {t('authTunnel.appleAuthButton')}
    </Button>
  );
};

export const AppleSignIn = forwardRef(AppleSignInWithRef);
