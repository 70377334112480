/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import styled from '@emotion/styled';

import { RequiredThemes } from '@app/ui/theme/types';

import { CheckboxSize } from '../Checkbox.types';

export const getLabelStyles = (
  componentTheme: RequiredThemes['checkbox'],
  size: CheckboxSize,
  disabled: boolean
) => ({
  ...componentTheme.text[size],
  color: disabled ? componentTheme.text.disabled : componentTheme.text.default,
  cursor: 'pointer',
  lineHeight: '24px',
  pointerEvents: disabled ? 'none' : 'default',
});

export const ContentContainer = styled.div<{
  hideErrorMessage: boolean;
}>`
  display: flex;
`;

export const SideContent = styled.div`
  margin-left: ${({ theme }) => theme.spacing.SIZE_03}px;
`;
