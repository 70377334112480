export type NotificationPayload = {
  notification?: {
    additionalData?: {
      notificationName?: string;
      realEstateIds?: string[];
    };
  };
};

export enum NotificationName {
  NewShowCasePublished = 'newPropertyAvailableAlert',
  NewAdvisorRecommendation = 'newAdvisorRecommendation',
  PreventAutoCancelRecommendations = 'recommendationExpiration',
}
