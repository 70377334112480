import React from 'react';

import { ActionButton } from '@app/ui/molecules/ActionButton';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';

import { IconWithLabelActivatorProps } from './IconWithLabelActivator.types';

export { IconWithLabelActivatorProps };

export const IconWithLabelActivator: React.FC<IconWithLabelActivatorProps> = ({
  onPress,
  label,
  iconName,
  size = 's',
}) => {
  const { isNativeOrResponsive } = useResponsive();

  return (
    <ActionButton
      leftIconName={iconName}
      label={!isNativeOrResponsive ? label : ''}
      onPress={onPress}
      withSolidBorder
      testID="icon-with-label-activator"
      size={size}
    />
  );
};
