import React from 'react';

import { View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';
import { useTheme } from '@app/ui/contexts/config';

import { WithLabelType } from './withLabel.types';

export { WithLabelType };

export function withLabel<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithLabel = ({ label, children, ...props }: WithLabelType<T>) => {
    const theme = useTheme();

    if (!label) {
      return <WrappedComponent {...(props as unknown as T)}>{children}</WrappedComponent>;
    }

    return (
      <View>
        <Text textStyle="Body2Medium">{label}</Text>
        <Spacer height={theme.spacing.SIZE_02} />
        <WrappedComponent {...(props as unknown as T)}>{children}</WrappedComponent>
      </View>
    );
  };
  ComponentWithLabel.displayName = `withLabel(${displayName})`;
  return ComponentWithLabel;
}
