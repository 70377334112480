import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgHouseexterior = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 19c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 17v-6.175a1.978 1.978 0 01.575-1.4l2.85-2.85A1.978 1.978 0 016.825 6H7V5a.97.97 0 01.287-.713A.97.97 0 018 4a.97.97 0 01.713.287A.97.97 0 019 5v1h8.175a1.978 1.978 0 011.4.575l2.85 2.85a1.978 1.978 0 01.575 1.4V17c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 19H4zm12-2h4v-6.175l-2-2-2 2V17zM4 17h10v-5H4v5z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgHouseexterior;
