import { formatOrdinal } from '@app/services/numbersFormatter/numbersFormatter';

/**
 * Define a regex pattern for postal codes starting with '750', '690', or '130' (Paris, Lyon and Marseille)
 * and the last two characters are <= 20 (Paris has the biggest number of district (20) and 13080 is not a district)
 */
const regex = /^(750|690|130)(([01]?\d)|(20))$/;

export const getDistrict = (postalCode: string): string | null => {
  const match = regex.exec(postalCode);

  if (!match?.[2]) {
    return null;
  }

  return formatOrdinal(parseInt(match[2]));
};
