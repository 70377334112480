import { StyleSheet } from 'react-native';

import { palette, Spacing } from '@app/ui';

export const styles = StyleSheet.create({
  block: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: Spacing.SIZE_03,
    justifyContent: 'space-between',
  },
  chip: {
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 1,
  },
  italicText: {
    color: palette.neutral[800],
    fontStyle: 'italic',
  },
  text: {
    color: palette.neutral[700],
  },
  wrapper: {
    flexDirection: 'row',
    gap: Spacing.SIZE_03,
  },
});

export const getBlockStyles = (isMobile: boolean) =>
  StyleSheet.create({
    amountText: {
      paddingBottom: isMobile ? Spacing.SIZE_04 : 0,
    },
    chipWrapper: {
      flexDirection: 'row',
      paddingBottom: isMobile ? Spacing.SIZE_04 : 0,
    },
  });
