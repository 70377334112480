import { CSSProperties } from 'react';

import { StyleSheet, ViewStyle } from 'react-native';

import { palette, Spacing } from '@app/ui';

interface ScrollbarViewStyle extends ViewStyle {
  ['&::-webkit-scrollbar']: CSSProperties;
  msOverflowStyle?: string;
  scrollbarWidth?: string;
  overflowY?: string;
}

const sideContent: ScrollbarViewStyle = {
  '&::-webkit-scrollbar': {
    display: 'none' /* Chrome, Safari and Opera */,
  },
  alignItems: 'flex-start',
  height: '100%' /* IE and Edge */,
  marginLeft: '10%' /* Firefox */,
  msOverflowStyle: 'none',
  overflowY: 'auto',
  paddingTop: '12%',
  scrollbarWidth: 'none',
  width: '80%',
};

interface LayoutSideTextStylesProps {
  isGreaterThanTablet?: boolean;
  isLowerThanDesktop?: boolean;
  translateKey?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = ({
  isLowerThanDesktop,
  isGreaterThanTablet,
  translateKey,
}: LayoutSideTextStylesProps) =>
  StyleSheet.create({
    header: {
      color: palette.base.white,
      marginBottom: Spacing.SIZE_08,
      maxWidth: 550,
    },
    mainWrapper: {
      backgroundColor: palette.base.white,
      width: isGreaterThanTablet ? '45%' : '100%',
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: Spacing.SIZE_08,
    },
    safeAreaWrapper: {
      backgroundColor: palette.base.white,
      flex: 1,
      flexDirection: 'row',
    },
    sideContent,
    sideText: {
      width: isLowerThanDesktop || translateKey !== 'register' ? '100%' : '55%',
    },
    sideTextContainer: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
    },
    sideTextListItem: {
      alignItems: 'center',
      color: palette.base.white,
      textAlign: 'left',
      width: '75%',
    },
    sideTextNumber: {
      color: palette.base.white,
      marginRight: Spacing.SIZE_05,
    },
    sideWrapper: {
      backgroundColor: palette.base.black,
      width: isGreaterThanTablet ? '55%' : 0,
    },
  });
