import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgEs = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="es_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#es_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.333h28V0H0v5.333zM0 20h28v-5.333H0V20z"
          fill="#DD172C"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.667h28V5.333H0v9.334z"
          fill="#FFD133"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.333 9.333h1.334V10H7.333v-.667z"
          fill="#FFEDB1"
        />
        <Path
          d="M6.059 9.361A.333.333 0 016.391 9h1.884c.195 0 .349.167.333.361l-.172 2.055a1 1 0 01-.996.917h-.213a1 1 0 01-.997-.917L6.06 9.361z"
          stroke="#A41517"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 10h2.667v.667H8L7.333 12l-.666-1.333H6V10z"
          fill="#A41517"
        />
        <Rect x={4} y={8} width={1.333} height={4.667} rx={0.667} fill="#A41517" />
        <Rect x={9.333} y={8} width={1.333} height={4.667} rx={0.667} fill="#A41517" />
        <Path
          d="M6 7.733c0-.589.478-1.066 1.067-1.066H7.6c.59 0 1.067.477 1.067 1.066 0 .148-.12.267-.267.267H6.267A.267.267 0 016 7.733z"
          fill="#A41517"
        />
      </G>
    </Svg>
  );
};

export default SvgEs;
