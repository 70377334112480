import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { Button, Theme, useResponsive, useTheme } from '@app/ui';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

type PropertyLoginButtonsNavigationProp = StackNavigationProp<PublicStackParamList>;

const getStyles = (theme: Theme, isNativeOrLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_03,
      ...(isNativeOrLowerThanDesktop && { flex: 1 }),
    },
  });

export const LoginButtons = (): React.ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isNativeOrResponsive, isLowerThanDesktop } = useResponsive();
  const isNativeOrLowerThanDesktop = isNativeOrResponsive || isLowerThanDesktop;
  const styles = getStyles(theme, isNativeOrLowerThanDesktop);

  const navigation = useNavigation<PropertyLoginButtonsNavigationProp>();

  const navigateToAuth = () => navigation.navigate(PublicNavigatorRoutes.Auth);
  const navigateToRegister = () => navigation.navigate(PublicNavigatorRoutes.Register);

  return (
    <View testID="property-login-btn" style={styles.container}>
      <Button variant="secondary" onPress={navigateToAuth} style={{ flex: 1 }}>
        {t('shared.login')}
      </Button>
      <Button
        style={{
          ...(isNativeOrLowerThanDesktop && { flex: 1 }),
        }}
        onPress={navigateToRegister}
      >
        {t('shared.register')}
      </Button>
    </View>
  );
};
