import React, { useContext, useEffect } from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';

import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import {
  HomePageNavigatorRoutes,
  HomePageStackParamList,
  MainMenuNavigatorRoutes,
  MainMenuStackParamList,
} from '@app/navigation/types/routes';
import HomePageView from '@app/views/homePage/HomePageView';
import ProjectView from '@app/views/project/ProjectView';
import { RealEstateAvailableView } from '@app/views/realEstate/realEstateAvailable/RealEstateAvailableView';
import { useTranslation } from '@app/services/translations/translations';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { notificationModalBlocked } from '@app/shared/components/notification-provider/notificationProvider.utils';
import { DiscussModalTitle } from '@app/features/discuss/components/DiscussModalTitle';
import { DiscussTakeAppointmentContent } from '@app/features/discuss/components/DiscussTakeAppointmentContent';
import { RealEstateMap } from '@app/views/realEstate/realEstateMap/RealEstateMap';
import { RenovationSummaryDetailView } from '@app/views/realEstate/renovationTab/RenovationSummaryDetailView';
import { FurnishmentDetailsView } from '@app/views/realEstate/renovationTab/FurnishmentDetailsView';
import { RenovationDetailsView } from '@app/views/realEstate/renovationTab/RenovationDetailsView';

const Stack = createStackNavigator<HomePageStackParamList>();

export const HomePageNavigator: React.FC<
  BottomTabScreenProps<MainMenuStackParamList, MainMenuNavigatorRoutes.HomePage>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    if (route.params?.shouldDisplayAppointmentModal) {
      setTimeout(() => {
        openModal?.(
          ModalKey.FirstAppointment,
          <DiscussTakeAppointmentContent
            callbackClose={() => {
              notificationModalBlocked(false);
            }}
          />,
          {
            callbackClose: params => {
              if ((params as { skipCallback: boolean })?.skipCallback) {
                return;
              }
              notificationModalBlocked(false);
            },
            position: 'center',
            title: <DiscussModalTitle />,
            webSize: 'l',
          }
        );

        navigation.setParams({ shouldDisplayAppointmentModal: false });
      }, 500);
    }
  }, [navigation, openModal, route.params, t]);

  return (
    <Stack.Navigator
      initialRouteName={HomePageNavigatorRoutes.HomePageRoot}
      screenOptions={SCREEN_OPTIONS}
    >
      <Stack.Screen name={HomePageNavigatorRoutes.HomePageRoot} component={HomePageView} />
      <Stack.Screen name={HomePageNavigatorRoutes.Project} component={ProjectView} />
      <Stack.Screen name={HomePageNavigatorRoutes.RealEstate} component={RealEstateAvailableView} />

      {/* TODO: remove when a solution has been found for the realEstate page navigator */}
      <Stack.Screen name={HomePageNavigatorRoutes.RealEstateMap} component={RealEstateMap} />
      <Stack.Screen
        name={HomePageNavigatorRoutes.RenovationSummaryDetail}
        component={RenovationSummaryDetailView}
      />
      <Stack.Screen
        name={HomePageNavigatorRoutes.FurnishmentDetails}
        component={FurnishmentDetailsView}
      />
      <Stack.Screen
        name={HomePageNavigatorRoutes.RenovationDetails}
        component={RenovationDetailsView}
      />
      {/* END: remove when a solution has been found for the realEstate page navigator */}
    </Stack.Navigator>
  );
};
