import React, { PropsWithChildren } from 'react';

import { ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { palette, Spacing, useResponsive } from '@app/ui';

interface ScreenWrapperProps {
  withoutScrollView?: boolean;
  withoutPadding?: boolean;
}

const styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: isMobile ? Spacing.SIZE_06 : Spacing.SIZE_09,
    },
    safeAreaView: { backgroundColor: palette.base.white, flex: 1 },
  });

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const ScreenWrapper: React.FC<PropsWithChildren<ScreenWrapperProps>> = ({
  children,
  withoutScrollView,
  withoutPadding,
}) => {
  const { isMobile } = useResponsive();

  return (
    <SafeAreaView style={styles(isMobile).safeAreaView} edges={['top']}>
      <ConditionalWrapper
        condition={!withoutScrollView}
        wrapper={child => <ScrollView>{child}</ScrollView>}
      >
        <ConditionalWrapper
          condition={!withoutPadding}
          wrapper={child => <View style={styles(isMobile).container}>{child}</View>}
        >
          {children}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </SafeAreaView>
  );
};
