import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgOven = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 013 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 015 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0119 21H5zm0-9v7h14v-7h-2v5H7v-5H5zm4 3h6v-3H9v3zm-4-5h14V5H5v5zm3-2a.97.97 0 01-.713-.287A.97.97 0 017 7a.97.97 0 01.287-.713A.97.97 0 018 6a.97.97 0 01.713.287A.97.97 0 019 7a.97.97 0 01-.287.713A.97.97 0 018 8zm4 0a.967.967 0 01-.712-.287A.968.968 0 0111 7c0-.283.096-.521.288-.713A.967.967 0 0112 6a.97.97 0 01.713.287A.97.97 0 0113 7a.97.97 0 01-.287.713A.97.97 0 0112 8zm4 0a.967.967 0 01-.712-.287A.968.968 0 0115 7c0-.283.096-.521.288-.713A.967.967 0 0116 6c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 01-.288.713A.967.967 0 0116 8z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgOven;
