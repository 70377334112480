import { MasteosSupportedLanguage } from '@app/libs/apollo/introspection';

export const resolveLanguageCode = (code: string): string => {
  if (!code || code.indexOf('-') < 0) {
    return code;
  }

  return code.split('-')[0];
};

export const resolveToMasteosLanguage = (lang: string): MasteosSupportedLanguage => {
  if (lang.toLowerCase().startsWith('fr')) {
    return MasteosSupportedLanguage.Fr;
  }
  if (lang.toLowerCase().startsWith('es')) {
    return MasteosSupportedLanguage.Es;
  }
  return MasteosSupportedLanguage.En;
};
