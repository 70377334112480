import React, { useState } from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { StyleSheet, View } from 'react-native';

import { Button, Input } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import {
  ForgotPasswordNavigatorRoutes,
  ForgotPasswordStackParamList,
} from '@app/navigation/types/routes';
import { trackForgotPasswordModified } from '@app/services/tracking/trackTracking';
import { useAuthentication } from '@app/shared/hooks/useAuthentication';
import { identityClient } from '@app/libs/identity/identityClient';

import { FormStyleSheets } from '../forgotPassword.styles.web';
import { isConfirmationCorrect, PASSWORD_MIN_LENGTH } from '../forgotPassword.utils';
import { ForgotPasswordStepWrapper } from './ForgotPasswordStepWrapper.web';

type NewPasswordStepProps = StackScreenProps<
  ForgotPasswordStackParamList,
  ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep
>;

const styles = StyleSheet.create({
  button: {
    bottom: 0,
    position: 'absolute',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export const NewPasswordStep: React.FC<NewPasswordStepProps> = ({ route }) => {
  const { t } = useTranslation();
  const { loginWithEmailPassword } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState<string>(undefined);

  const submit = () => {
    if (password !== passwordConfirmation) {
      return;
    }

    setLoading(true);
    const { hash: hashFromParams, email: emailFromParams } = route.params;
    const email = emailFromParams.toLowerCase().trim();
    const hash = decodeURIComponent(hashFromParams);

    identityClient
      .renewPassword({ email, hash, password })
      .then(() => {
        setLoading(false);
        trackForgotPasswordModified();
        loginWithEmailPassword(email, password);
      })
      .catch(() => setError(t('forgotPassword.genericError')));
  };

  return (
    <ForgotPasswordStepWrapper>
      <View style={styles.container}>
        <Input
          data-e2e="input-password"
          onChangeText={setPassword}
          value={password}
          isPassword
          label={t('forgotPassword.passwordLabel')}
          wrapperStyle={FormStyleSheets.input}
          testID="input-password"
          error={
            password.length > 0 && password.length < PASSWORD_MIN_LENGTH
              ? t('authTunnel.passwordLength')
              : null
          }
        />

        <Input
          data-e2e="input-password-confirm"
          value={passwordConfirmation}
          onChangeText={setPasswordConfirmation}
          onSubmitEditing={submit}
          isPassword
          label={t('forgotPassword.confirmPasswordLabel')}
          wrapperStyle={FormStyleSheets.input}
          testID="input-password-confirmation"
          error={
            error ?? isConfirmationCorrect(password, passwordConfirmation)
              ? null
              : t('forgotPassword.errorDifferentPasswords')
          }
        />

        <View style={styles.button}>
          <Button
            variant="primary"
            onPress={submit}
            style={FormStyleSheets.button}
            isLoading={loading}
            disabled={password.length < PASSWORD_MIN_LENGTH || password !== passwordConfirmation}
            testID="new-password-button"
          >
            {t('forgotPassword.confirmButton')}
          </Button>
        </View>
      </View>
    </ForgotPasswordStepWrapper>
  );
};
