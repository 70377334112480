import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPack = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1a3 3 0 00-1.498.4L7.306 3.228a1 1 0 00-.554.316L3.504 5.4l-.004.002a3 3 0 00-.937.848 1.001 1.001 0 00-.25.414A3 3 0 002 7.997v8.002a3 3 0 001.5 2.595l.004.002 6.996 3.998.002.001a3 3 0 001.022.363.995.995 0 00.952 0 3 3 0 001.022-.363l.002-.001 6.996-3.998.004-.002a3 3 0 001.5-2.595V7.997a3 3 0 00-.314-1.334 1 1 0 00-.249-.413 3 3 0 00-.937-.848l-7-4-.002-.001A3 3 0 0012 1zm1 19.576l6.5-3.714.002-.001a1 1 0 00.498-.864V8.535l-7 4.05v7.991zm-2-7.991l-7-4.05v7.462a1 1 0 00.498.864H4.5l6.5 3.715v-7.991zm1.5-9.451l6.461 3.692-2.456 1.42-6.944-4.004 1.935-1.106.004-.002a1 1 0 011 0zm-.5 7.719l2.507-1.45L7.55 5.39 5.039 6.826l6.96 4.027z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPack;
