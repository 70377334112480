import { NpsCategory } from '@app/libs/apollo/introspection';

export enum NPSGroup {
  detractors = 'detractors',
  passives = 'passives',
  promoters = 'promoters',
}

export const NPSAnswers: Record<NpsCategory, Record<NPSGroup, string[]>> = {
  [NpsCategory.PostR2]: {
    detractors: [
      'COMMUNICATION_WITH_LOCAL_EXPERT',
      'QUALITY_OF_REAL_ESTATES',
      'OUR_INFOS_AND_SERVICES',
      'THE_QUALITY_OF_THE_APP',
    ],
    passives: [
      'COMMUNICATION_WITH_LOCAL_EXPERT',
      'QUALITY_OF_REAL_ESTATES',
      'OUR_INFOS_AND_SERVICES',
      'THE_QUALITY_OF_THE_APP',
    ],
    promoters: [
      'COMMUNICATION_WITH_LOCAL_EXPERT',
      'QUALITY_OF_REAL_ESTATES',
      'OUR_INFOS_AND_SERVICES',
      'THE_QUALITY_OF_THE_APP',
    ],
  },
};
