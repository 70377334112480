import { startCase } from 'lodash';

import { LocationType, PropertyKind, RealEstate } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';

interface PropertyTitleFields {
  address?: {
    city: string;
  };
  surface?: number | null;
  totalLots?: number | null;
  type: PropertyKind;
  locationType?: LocationType;
}

export const realEstateToFields = (realEstate: RealEstate): PropertyTitleFields => {
  return {
    address: { city: realEstate.building.address.city },
    locationType: realEstate.project.renting.type || LocationType.Simple,
    surface: realEstate.surface ?? 0,
    totalLots: realEstate.building.totalLots ?? 0,
    type: realEstate.type,
  };
};

export const usePropertyTitle = ({
  address,
  surface,
  totalLots,
  type,
  locationType,
}: PropertyTitleFields): string => {
  const { t } = useTranslation();

  if (!address) {
    return '';
  }

  const titleType = t(`propertyDescription.${type}`);
  const surfaceTitle = surface ? `${surface} m2` : '';

  if (locationType && startCase(locationType.toLowerCase()) === LocationType.Colocation) {
    return `${t('shared.colocation')} - ${surfaceTitle} - ${address.city}`;
  }

  if (type && startCase(type.toLowerCase()) === PropertyKind.Building && totalLots) {
    const totalLotsTitle = `${totalLots} ${t('shared.lot', { count: totalLots })}`;

    return [titleType, totalLotsTitle, address.city].filter(Boolean).join(' - ');
  }

  return `${titleType} - ${surfaceTitle} - ${address.city}`;
};

export const useRealEstateTitle = ({
  city,
  rentingType,
  surface,
  totalLots,
  realEstateType,
}: {
  city: string;
  rentingType?: LocationType;
  surface?: number | null;
  totalLots?: number | null;
  realEstateType: PropertyKind;
}): string => {
  const propertyFields = {
    address: { city },
    locationType: rentingType,
    surface,
    totalLots,
    type: realEstateType,
  };

  return usePropertyTitle(propertyFields);
};
