/* eslint-disable max-lines */
import { JsonMap } from '@segment/analytics-react-native';
import { identity, pickBy } from 'lodash';

import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import { SearchEngineSource } from '@app/features/search-engine/searchEngine.types';
import { CustomerPreferencesInput, TransactionStatus } from '@app/libs/apollo/introspection';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import * as segment from '@app/libs/segment/segment';
import {
  InAppSignatureModalCloseReason,
  SignatureModalState,
} from '@app/shared/components/Modals/in-app-signature/InAppSignatureModal.types';
import { SearchEngineSortStrategy } from '@app/features/search-engine/compounds/search-engine-filters/search-engine-sort/SearchEngineSort.types';
import { ProjectCategory } from '@app/features/project/Project.types';
import { TranslatedContentContentType } from '@app/ui';
import { TrackingNotInterestedDataType } from '@app/features/homePage/hooks/usePositioningActions.types';

import { AdvisorSourcePage, SuggestionsSourcePage } from './constants';

export enum PropertyLikeType {
  FAVORITE = 'favorite',
  PRESELECTION = 'preSelection',
}

export enum PropertyPage {
  PROPERTY = 'property',
  MATCHING = 'matching',
  SUGGESTIONS = 'suggestion',
  HOMEPAGE = 'homepage',
}

export enum TrackingOrigin {
  RENOVATION = 'Travaux',
  FINANCES = 'Finances',
  GENERAL = 'Général',
  RENTAL = 'Loyers',
  PREVIEW = 'Preview',
}

export enum PropertyPageTypeGallery {
  MAIN = 'main',
  LOCALISATION = 'localisation',
  PLAN = 'plan',
  FURNITURE = 'furniture',
}

export enum TakeAppointmentSource {
  advisorPage = 'advisorPage',
  modalAfterChangingFilters = 'modalAfterChangingFilters',
  modalAfterLike = 'modalAfterLike',
  modalAfterVisitingPropertyPage = 'modalAfterVisitingPropertyPage',
  newSearchPage = 'newSearchPage',
  propertyPage = 'propertyPage',
  propertyPageGallery = 'propertyPageGallery',
  soldPropertyPage = 'soldPropertyPage',
  homePage = 'homePage',
}

export enum TakeAppointmentLaterTrackSource {
  later = 'later cta',
  cross = 'cross',
  onboarding = 'onboarding',
}

export enum TakeAppointmentStep {
  qualificationSubmitted = 'Hubspot qualification',
  iframeLoaded = 'Hubspot iframe loaded',
}

export enum AllowNotificationsSource {
  PromptIn = 'promptIn',
  NotificationCenter = 'notificationCenter',
  Profile = 'Profile',
  Suggestions = 'suggestionsPage',
}

interface TrackTranslationSwitchProps {
  propertyId: string;
  type: TranslatedContentContentType;
  sourceLang?: string;
  targetLang?: string;
}

export type TrackBookmarkProps = {
  likeType?: PropertyLikeType;
  currentTab?: TrackingOrigin;
  page: PropertyPage;
  realEstateId: string;
};

type TrackProjectPropertyPageClicked = {
  projectId: string;
  projectStatus: TransactionStatus;
  propertyId: string;
};
type TrackProjectSubStepsClicked = {
  category: ProjectCategory;
  detailsButtonStatus: 'Hide' | 'Show';
  projectId: string;
  stepId: number;
};
type TrackProjectSubStepsDetailsClicked = TrackProjectSubStepsClicked & {
  subStepId: number;
};

export type PropertyChangesFilter = {
  [K in keyof CustomerPreferencesInput]: boolean;
};

interface TakeAppointmentTrack {
  sourcePage: TakeAppointmentSource | string;
  propertyId?: string;
}

interface TakeAppointmentIframeTrack {
  step: TakeAppointmentStep;
  hasAdvisor?: boolean;
}

export const trackLogin = (method: SignInMethod): void =>
  segment.track('User logged in', { method });

export const trackLogout = (): void => {
  segment.track('User logged out');
};

export const trackToGTM = (eventName: string, external_id: string, data: JsonMap = {}): void => {
  // External_id is used for the duplication
  segment.trackWithFacebookData(eventName, { external_id, ...data });
};

export const trackFilterClick = (
  changes: PropertyChangesFilter,
  preferences: CustomerPreferencesInput,
  numberOfResults: number,
  source: SearchEngineSource
): void =>
  segment.track('Filter Clicked', {
    ...changes,
    numberOfResults,
    ...Object.entries(preferences).reduce((acc, [key, value]) => {
      acc[`${key}_value`] = value;
      return acc;
    }, {}),
    trigger: source,
  });

export const trackSortStrategyClick = (sortValue: SearchEngineSortStrategy): void =>
  segment.track('Sort Clicked', {
    sortMethod: true,
    sortValue,
  });

export const trackReferralLinkCopied = (isPro: boolean): void => {
  segment.track('Referral link copied', {
    userType: isPro ? 'professional' : 'personal',
  });
};

export const trackLoadMoreButtonClicked = (pageNumber: number): void => {
  segment.track('Load more button clicked', {
    loadMoreButton: pageNumber,
  });
};

export const trackBookmarksFilterClick = (numberOfResults: number): void =>
  segment.track('Favorites filter Clicked', { numberOfResults });

export const trackFilterCountryClick = (data: { disabled: boolean }): void =>
  segment.track('Country select clicked', data);

export const trackFilterShow = (data: { source: SearchEngineSource }): void =>
  segment.track('Show Filter Clicked', data);

export const trackResetFilterClick = (data: {
  source: SearchEngineSource;
  numberOfResults: number;
}): void => segment.track('Reset Filter Clicked', data);

export const trackClickToShare = (data: Record<string, string | boolean | number>): void =>
  segment.track('Share button clicked', data);

export const trackProfileLanguage = (language: I18nSupportedLanguage): void =>
  segment.track('Profile language changed', {
    language,
  });

export const trackPublicLanguageSwitch = (language: I18nSupportedLanguage): void =>
  segment.track('Language switcher clicked', { language });

export const trackContactAdvisorMail = (userEmail: string, source: AdvisorSourcePage): void =>
  segment.track('advisor page - mail clicked', {
    source,
    userEmail,
  });

export const trackContactAdvisorWhatsapp = (userEmail: string, source: AdvisorSourcePage): void =>
  segment.track('advisor page - whatsapp clicked', {
    source,
    userEmail,
  });

export const trackContactAdvisorPhone = (userEmail: string, source: AdvisorSourcePage): void => {
  segment.track('advisor page - tel clicked', {
    source,
    userEmail,
  });
};

export const trackRegisterClicked = (): void => {
  segment.track('User clicked on register button');
};
interface TrackRegisterCompletedData extends Record<string, string | boolean> {
  email: string;
  firstName: string;
  isSubscribeNewsletter: boolean;
  lastName: string;
  method: SignInMethod;
  isReferred: boolean;
}

export const trackRegisterCompleted = (userInfo: TrackRegisterCompletedData): void => {
  segment.track('User registered', userInfo);
};

export const trackGoogleAuthButtonClicked = (): void => {
  segment.track('Google Auth method clicked');
};

export const trackAppleAuthButtonClicked = (): void => {
  segment.track('Apple Auth method clicked');
};

export const trackEmailPasswordButtonClicked = (): void => {
  segment.track('Email/password method clicked');
};

export const trackClickRemoveAccount = (): void => segment.track('Delete account clicked');

export const trackRemoveAccount = (): void => segment.track('User delete account');

export const trackForgotPassword = (): void => segment.track('Forgotten password');

export const trackForgotPasswordGetCode = (email: string): void => {
  segment.track('Forgotten password get code', { email });
};

export const trackForgotPasswordConfirmedCode = (): void =>
  segment.track('Forgotten password confirmed code');

export const trackForgotPasswordModified = (): void => segment.track('Forgotten password modified');

export const trackPropertyChangeTab = (params: { tabName: string; propertyId: string }): void =>
  segment.track(`${params?.tabName} tab clicked`, { ...params });

export const trackInterestedPropertyButton = (page: PropertyPage): void =>
  segment.track('Like button clicked', { page });

export const trackBookmark = ({
  likeType,
  currentTab,
  page,
  realEstateId,
}: TrackBookmarkProps): void =>
  segment.track('Like type clicked', { currentTab, likeType, page, propertyId: realEstateId });

export const trackUnbookmark = ({
  likeType,
  currentTab,
  page,
  realEstateId,
}: TrackBookmarkProps): void =>
  segment.track('Dislike type clicked', { currentTab, likeType, page, propertyId: realEstateId });

export const trackVirtualVisit = (params: { isInsideGallery: boolean; propertyId: string }): void =>
  segment.track('3D visit clicked', { ...params });

export const trackPaymentCash = (values: { isPaymentCash: boolean; propertyId: string }): void =>
  segment.track('Invest cash clicked', { ...values });

export const trackBrokerFees = (params: { isChecked: boolean; propertyId: string }): void =>
  segment.track('Broker fees clicked', { ...params });

export const trackRentalManagement = (params: { isChecked: boolean; propertyId: string }): void =>
  segment.track('Rental management Cliked', { ...params });

export const trackPremiumRentalManagement = (params: {
  isChecked: boolean;
  propertyId: string;
}): void => segment.track('Premium rental management Cliked', { ...params });

export const trackLoanInterestRate = (others: {
  loanInterestRate: number;
  propertyId: string;
}): void => segment.track('Loan rate set', { ...others });

export const trackLoanDown = (others: {
  newLoanDown: number;
  oldLoanDown: number;
  propertyId: string;
}): void => segment.track('Loan downpayment set', { ...others });

export const trackLoanDuration = (others: { propertyId: string; loanDuration: number }): void =>
  segment.track('Loan duration set', { ...others });

export const trackGalleryVisit = (params: {
  typeGallery: string;
  countGalleryImages: number;
  propertyId: string;
}): void =>
  segment.track('Visits a photo gallery', {
    ...params,
  });

export enum TooltipTarget {
  netReturn = 'Rendement net',
  appreciation = 'Prise de valeur',
}

export const trackTooltip = (params: { tooltipType: string; propertyId?: string }): void =>
  segment.track('Tooltip clicked', { ...params });

export const trackProjectPropertyPageClicked = (props: TrackProjectPropertyPageClicked): void =>
  segment.track('Project property page clicked', props);

export const trackProjectSubStepsClicked = (props: TrackProjectSubStepsClicked): void =>
  segment.track('Project sub-steps button clicked', props);

export const trackProjectSubStepsDetailsClicked = (
  props: TrackProjectSubStepsDetailsClicked
): void => segment.track('Project sub-steps details button clicked', props);

export const trackProjectTabClicked = (props: { name: ProjectCategory }): void =>
  segment.track('Project tab clicked', pickBy(props, identity));

export const trackOpenMapFullScreen = (propertyId: string): void =>
  segment.track('Open modal Map full screen', { propertyId });

export const trackDPEButtonClick = (propertyID: string): void =>
  segment.track('DPE button clicked', { propertyID });

export const trackGESButtonClick = (propertyID: string): void =>
  segment.track('GES button clicked', { propertyID });

export const trackTranslationSwitch = ({
  propertyId,
  type,
  sourceLang,
  targetLang,
}: TrackTranslationSwitchProps): void =>
  segment.track('Translation button clicked', { propertyId, sourceLang, targetLang, type });

export const trackFinancialToggle = (params: { sectionName: string; propertyId: string }): void =>
  segment.track('Section toggled', { ...params });

export const trackFinancialUntoggle = (params: { sectionName: string; propertyId: string }): void =>
  segment.track('Section untoggled', { ...params });

export const trackSearchButtonClick = (): void => segment.track('Continue search button clicked');

export const trackResellingYear = (resellingYear: number, propertyId: string): void =>
  segment.track('Projection duration set', {
    propertyId,
    years: resellingYear,
  });

export const trackEvaluationRate = (params: {
  rateSetByUser: number;
  initializationRate: number;
  propertyId: string;
}): void =>
  segment.track('Asset appreciation rate set', {
    ...params,
  });

export const trackTakeAdvisorAppointmentClick = ({
  sourcePage,
  propertyId,
}: TakeAppointmentTrack): void =>
  segment.track('Take appointment button clicked', { propertyId, sourcePage });

export const trackTakeAdvisorAppointmentLaterClick = (
  source: TakeAppointmentLaterTrackSource
): void => segment.track('Later clicked', { source });

export const trackTakeAppointmentIframe = ({
  step,
  hasAdvisor,
}: TakeAppointmentIframeTrack): void => {
  if (!step) {
    return;
  }
  segment.track(step, { hasAdvisor });
};

export const trackTimelineStepClicked = (params: {
  stepNumber: number;
  projectId: string;
  category: ProjectCategory;
}): void => segment.track('Timeline step clicked', params);

export const trackShareSponsorshipLink = (params: { platformType: string }): void =>
  segment.track('Platform button clicked', params);

export const trackSponsorshipProgramOpened = (): void => segment.track('Referral section opened');

export const trackNotificationsAllowed = (source: AllowNotificationsSource): void =>
  segment.track('Notification Allowed', { source });

export const trackNotificationsDelayed = (source: AllowNotificationsSource): void =>
  segment.track('Notification Delayed', { source });

export const trackNotificationClicked = (data): void => segment.track('Notification clicked', data);

export const trackEmailNotificationClicked = (data): void =>
  segment.track('Email Notification clicked', data);

export const trackOnboardingNextStep = (data): void =>
  segment.track('Onboarding page next button clicked', data);

export const trackOnboardingPrevStep = (data): void =>
  segment.track('Previous button clicked', data);

export const trackPositioningCancelled = (propertyId: string): void =>
  segment.track('Pre-positioning  cancelled button clicked', { propertyId });

export const trackPositioningConfirmed = (propertyId: string, isRecommendation: boolean): void =>
  segment.track('Pre-positioning confirmed button clicked', {
    isARecommandation: isRecommendation,
    propertyId,
  });

export const trackPositioningSeeMore = (propertyId: string): void =>
  segment.track('See more button clicked', { propertyId });

export const trackNotificationsSettingsClicked = (): void =>
  segment.track('Notification system settings link clicked');

export const trackNotificationsSettingsLaterClicked = (): void =>
  segment.track('Notification settings later clicked');

export const trackNotificationActivated = (data): void =>
  segment.track('Notification activated', data);

export const trackNotificationDeactivated = (data): void =>
  segment.track('Notification deactivated', data);

// Mandate sign in
export const trackMandateSignatureModalDisplayed = (source: string): void =>
  segment.track('Mandate signature modal displayed', { source });

export const trackMandateSignatureModalClosed = (
  source: InAppSignatureModalCloseReason,
  closeStep: SignatureModalState
): void => segment.track('Mandate signature modal closed', { closeStep, source });

export const trackMandateSignatureBannerClosed = (): void =>
  segment.track('Mandate signing banner closed');

export const trackMandateSignatureDone = (): void => segment.track('Mandate signed');

export const trackSuggestionsOpenSearchPage = (): void =>
  segment.track('Search project button clicked');

export const trackSuggestionsNotInterestedClicked = (source: SuggestionsSourcePage): void => {
  segment.track('Not interested button clicked', { source });
};

export const trackSuggestionsNotInterestedSubmit = (data: TrackingNotInterestedDataType): void =>
  segment.track('Dislike reason clicked', data);

export const trackSuggestionsDislikeReasonModalClosed = (): void => {
  segment.track('Dislike reason modal closed');
};

export const trackSuggestionsInterestedButtonClicked = (
  source: SuggestionsSourcePage,
  propertyId: string
): void => {
  segment.track('Interested button clicked', { propertyId, source });
};

export const trackSuggestionsPrePositioningCanceledButtonClicked = (propertyId: string): void => {
  segment.track('Pre-positioning canceled button clicked', { propertyId });
};

export const trackSuggestionsCallBackClicked = (source: SuggestionsSourcePage): void => {
  segment.track('Call back clicked', { source });
};

export const trackSwitchToShortTermRental = (tab: 'general' | 'finances'): void => {
  segment.track('Switch to LCD', { tab });
};

export const trackExtendedRealEstatesResult = ({
  extendedResultsDisplayed,
  extendedResults,
}: {
  extendedResultsDisplayed?: boolean;
  extendedResults?: {
    withHigherBudget?: number;
    withNearby?: number;
  };
}): void => {
  segment.track('Extended search results', {
    extendedResultsDisplayed,
    extendedResultsWithHigherBudget: extendedResults?.withHigherBudget || 0,
    extendedResultsWithNearby: extendedResults?.withNearby || 0,
  });
};

export const trackHomepageTimelineStepMandate = (): void => {
  segment.track('Timeline step 2 mandate clicked');
};

export const trackHomepageTimelineStepFindGem = (): void => {
  segment.track('Timeline step 3 search clicked');
};

export const trackHomepageSeeAllProperties = (): void => {
  segment.track('See all properties button clicked');
};

export const trackHomePageGuidesArticle = (index: number): void => {
  segment.track(`Article ${index} clicked`);
};

export const trackDiscussMenu = (): void => {
  segment.track('Discuss clicked');
};

export const trackDiscussAppointment = (): void => {
  segment.track('Discuss - RDV clicked');
};

export const trackDiscussBeCalledBack = (): void => {
  segment.track('Discuss- Call back clicked');
};

export const trackMinimalVersionModalOpened = (): void => {
  segment.track('Minimal version modal opened');
};

export const trackHomePageDownloadApp = (platform: string): void => {
  segment.track('Homepage - Download app clicked', { platform });
};
