import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Image, RadiusContainer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { fromS3ID } from '@app/utils/from-s3id';

import { RenovateWithMasteosList } from './components/RenovateWithMasteosList';

const getStyles = ({ isLowerThanDesktop, theme }: { theme: Theme; isLowerThanDesktop: boolean }) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.base.white,
      border: 0,
      flexDirection: isLowerThanDesktop ? 'column' : 'row',
      overflow: 'hidden',
    },
    imageContainer: {
      ...(isLowerThanDesktop
        ? {
            height: 250,
          }
        : { width: 277 }),
    },
    textBlock: {
      flex: 1,
      gap: isLowerThanDesktop ? theme.spacing.SIZE_07 : theme.spacing.SIZE_08,
      paddingHorizontal: isLowerThanDesktop ? theme.spacing.SIZE_06 : theme.spacing.SIZE_08,
      paddingVertical: isLowerThanDesktop ? theme.spacing.SIZE_07 : theme.spacing.SIZE_08,
    },
  });

export const RenovateWithMasteos: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isLowerThanDesktop } = useResponsive();
  const styles = getStyles({ isLowerThanDesktop, theme });

  return (
    <RadiusContainer style={styles.container} radiusSize={20}>
      <View style={styles.imageContainer}>
        <Image
          source={{
            uri: fromS3ID('masteosapp/amastas/amastas_renovation_mobile.png', 'ar-1,w-1080,q-100'),
          }}
        />
      </View>
      <View style={styles.textBlock}>
        <Text textStyle="Headline2Medium">{t('propertySold.renovateWithMasteos.title')}</Text>
        <RenovateWithMasteosList
          list={[
            {
              description: t('propertySold.renovateWithMasteos.items.customMade.description'),
              iconName: 'Works',
              title: t('propertySold.renovateWithMasteos.items.customMade.title'),
            },
            {
              description: t('propertySold.renovateWithMasteos.items.madeInMasteos.description'),
              iconName: 'Masteos',
              title: t('propertySold.renovateWithMasteos.items.madeInMasteos.title'),
            },
            {
              description: t('propertySold.renovateWithMasteos.items.singleContract.description'),
              iconName: 'Feather',
              title: t('propertySold.renovateWithMasteos.items.singleContract.title'),
            },
            {
              description: t('propertySold.renovateWithMasteos.items.taxBenefits.description'),
              iconName: 'Euro',
              title: t('propertySold.renovateWithMasteos.items.taxBenefits.title'),
            },
          ]}
        />
      </View>
    </RadiusContainer>
  );
};
