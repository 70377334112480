import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgBt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="bt_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#bt_svg__a)">
        <Path fill="#FF5F38" d="M0 0h28v20H0z" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20L28 0H0v20z" fill="#FFD951" />
      </G>
    </Svg>
  );
};

export default SvgBt;
