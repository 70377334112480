import styled from '@emotion/styled';
import { TextStyle } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const TableView = styled('div')`
  align-items: stretch;
  display: grid;
  grid-template-columns: ${({ columns }: { columns: { style?: TextStyle }[] }) =>
    columns.map(({ style }) => (style?.width && `${style.width}px`) || '1fr').join(' ')};
  width: 100%;
`;

export const EmptyText = styled.div<{ theme: Theme; columnsLength: number }>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral[300]};
  grid-column-start: 1;
  grid-column-end: ${({ columnsLength }) => columnsLength + 1};
  padding: ${({ theme }) => theme.spacing.SIZE_05}px ${({ theme }) => theme.spacing.SIZE_03}px;
`;
