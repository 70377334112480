/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useContext, useState } from 'react';

import { FlatList, StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { chunk } from 'lodash';
import { useFlag } from '@unleash/proxy-client-react';

import { useTranslation } from '@app/services/translations/translations';
import { Text, Theme, useResponsive, useTheme } from '@app/ui';
import {
  HomePageNavigatorRoutes,
  HomePageStackParamList,
  MainMenuNavigatorRoutes,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
} from '@app/navigation/types/routes';
import { PropertyPage, TrackingOrigin } from '@app/services/tracking/trackTracking';
import { useBookmark } from '@app/shared/hooks/useBookmark';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { isWeb } from '@app/constants/platform';
import { RealEstate } from '@app/libs/apollo/introspection';
// eslint-disable-next-line max-len
import { realEstateToItemMapper } from '@app/shared/components/real-estate-preview-card/real-estate-to-item.mapper';
// eslint-disable-next-line max-len
import { RealEstatePreviewCardHorizontal } from '@app/shared/components/real-estate-preview-card/RealEstatePreviewCardHorizontal';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { FeatureFlags } from '@app/constants/feature-flags';

import { BreakPoint, sectionPaddingForScreen, useActiveBreakPoint } from './SectionContainer';
import { PushToSubscribeModalContent } from '../publicHomePage/PushToSubscribeModal';

const PERCENT_CARD_WIDTH = 0.95;

type HomePageProjectsPropsNav = StackNavigationProp<
  HomePageStackParamList,
  HomePageNavigatorRoutes.Project
>;

const numberOfColumnForScreen: Record<BreakPoint, number> = {
  nativeOrResponsive: 1,
  tablet: 2,
  laptop: 2,
  desktop: 3,
  wide: 3,
};

const getStyles = ({
  theme,
  width,
  activeBreakPoint,
}: {
  theme: Theme;
  width: number | undefined;
  activeBreakPoint: BreakPoint;
}) =>
  StyleSheet.create({
    buttons: { flexDirection: 'row', marginBottom: theme.spacing.SIZE_06 },
    cardContainer: {
      marginBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06 / 2,
      width: width
        ? (width - sectionPaddingForScreen[activeBreakPoint]) /
          numberOfColumnForScreen[activeBreakPoint]
        : 0,
    },
    carouselWrapper: {
      flex: 1,
      justifyContent: 'center',
    },
    carousel: {
      marginHorizontal: -sectionPaddingForScreen[activeBreakPoint],
      width: width ? width + 2 * sectionPaddingForScreen[activeBreakPoint] : 0,
    },
    carouselCardContainer: {
      paddingBottom: theme.spacing.SIZE_04,
      flex: 1,
    },
    flatList: { flex: 1, marginHorizontal: -theme.spacing.SIZE_06 / 2 },

    description: { marginBottom: theme.spacing.SIZE_06, paddingHorizontal: theme.spacing.SIZE_07 },
    itemSeparatorComponent: { height: theme.spacing.SIZE_06, width: theme.spacing.SIZE_06 },

    carouselItem: {
      marginHorizontal: sectionPaddingForScreen[activeBreakPoint],
      height: '100%',
    },
    title: {
      paddingHorizontal: theme.spacing.SIZE_07,
    },
  });

type HomePageNewsCarouselProps = {
  isPublic?: boolean;
  realEstates: RealEstate[];
};

export const HomePageNewsCarousel: React.FC<HomePageNewsCarouselProps> = ({
  isPublic = false,
  realEstates,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setValidMsg } = useToastContext();
  const { isNativeOrResponsive } = useResponsive();

  const [containerWidth, setContainerWidth] = useState<number | undefined>();

  const activeBreakPoint = useActiveBreakPoint();

  const numColumns = numberOfColumnForScreen[activeBreakPoint];

  const keyExtractor = useCallback((item: RealEstate) => item.id, []);

  const navigation = useNavigation<HomePageProjectsPropsNav>();

  const styles = getStyles({
    activeBreakPoint,
    theme,
    width: containerWidth ? containerWidth : 0,
  });

  const callbackOnBookmark = () => {
    setValidMsg({
      icon: 'HeartFilled',
      title: t('searchEngine.toast.favorite.add'),
      withClose: true,
    });
  };

  const callbackUnBookmark = () => {
    setValidMsg({
      icon: 'Heart',
      title: t('searchEngine.toast.favorite.remove'),
      withClose: true,
    });
  };

  const { toggleBookmark } = useBookmark({
    callbackOnBookmark,
    callbackUnBookmark,
  });

  const { closeModal, openModal } = useContext(ModalContext);
  const canAccessToRealEstatePage = useFlag(FeatureFlags.publicRealEstateCardLink);
  const handleItemClick = useCallback(
    (item: RealEstate): (() => void) =>
      () => {
        if (isPublic) {
          if (canAccessToRealEstatePage) {
            navigation.navigate(PublicNavigatorRoutes.RealEstate, { id: item.id });
          } else {
            openModal?.(
              ModalKey.PushToSubscribe,
              <PushToSubscribeModalContent
                loginCb={() => {
                  closeModal?.();
                  navigation.navigate(PublicNavigatorRoutes.Login);
                }}
                subscribeCb={() => {
                  closeModal?.();
                  navigation.navigate(PublicNavigatorRoutes.Auth);
                }}
              />,
              {
                position: 'center',
                title: <Text textStyle="Headline1Medium">{t('pushToSubscribeModal.title')}</Text>,
                webSize: 's',
              }
            );
          }
          return;
        }

        if (isWeb()) {
          navigation.getParent()?.navigate(MainMenuNavigatorRoutes.RealEstates, {
            params: {
              id: item.id,
            },
            screen: RealEstateNavigatorRoutes.RealEstate,
          });
        } else {
          navigation.navigate(HomePageNavigatorRoutes.RealEstate, {
            id: item.id,
          });
        }
      },
    [canAccessToRealEstatePage, isPublic, navigation, openModal, t]
  );
  const handleItemBookmark = useCallback(
    (item: RealEstate) => () =>
      toggleBookmark(
        {
          ...item,
          isBookmarked: item.metadata.isFavorites,
        },
        {
          currentTab: TrackingOrigin.PREVIEW,
          page: PropertyPage.SUGGESTIONS,
        }
      ),
    [toggleBookmark]
  );

  return (
    <>
      {isNativeOrResponsive ? (
        <View
          onLayout={e => setContainerWidth(e.nativeEvent.layout.width)}
          style={styles.carouselWrapper}
        >
          {!!containerWidth && (
            <Carousel
              showDots={true}
              width={containerWidth * PERCENT_CARD_WIDTH}
              style={styles.carousel}
              panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
              loop={false}
              data={chunk(realEstates, 3)}
              height={420}
              renderItem={({ item }: { item: RealEstate[] }) => (
                <View style={styles.carouselItem}>
                  {item.map(i => (
                    <View key={i.id} style={styles.carouselCardContainer}>
                      <RealEstatePreviewCardHorizontal
                        isPublic={isPublic}
                        realEstatePreview={realEstateToItemMapper(i)}
                        onBookmark={handleItemBookmark(i)}
                        onPress={handleItemClick(i)}
                      />
                    </View>
                  ))}
                </View>
              )}
            />
          )}
        </View>
      ) : (
        <FlatList
          onLayout={e => setContainerWidth(e.nativeEvent.layout.width)}
          style={styles.flatList}
          data={realEstates}
          key={numColumns}
          renderItem={({ item }) => (
            <View style={styles.cardContainer}>
              <RealEstatePreviewCardHorizontal
                isPublic={isPublic}
                realEstatePreview={realEstateToItemMapper(item)}
                onBookmark={handleItemBookmark(item)}
                onPress={handleItemClick(item)}
              />
            </View>
          )}
          numColumns={numColumns}
          ItemSeparatorComponent={() => (
            <View style={styles.itemSeparatorComponent} testID="item-separator-component" />
          )}
          keyExtractor={keyExtractor}
        />
      )}
    </>
  );
};
