import { useQueryParam } from '@app/shared/hooks/useQueryParams';
import {
  useCreateCustomer_A12N2N3Mutation,
  useCreateCustomerWithSsoMutation,
} from '@app/libs/apollo/introspection';
import { useUtmValues } from '@app/shared/hooks/useUtmValues.web';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import { AuthenticationStrategy } from '@app/libs/identity/identity.type';
import { storage } from '@app/services/storage/storage';

import { isSSOMethod } from '../onboarding.utils';

interface CreateCustomerParams {
  email: string;
  lang?: string;
  firstName: string;
  lastName: string;
  isSubscribeNewsletter: boolean;
  password?: string;
  phoneNumber?: string;
  siteOrigin?: string;
}

export interface SimpleUser {
  id: string;
  email: string;
}

export interface UseStepRegisterProcessResult {
  createCustomer: (param: CreateCustomerParams) => Promise<SimpleUser>;
  emailFromQueryParam?: string;
  firstNameFromQueryParam?: string;
  idTokenFromQueryParam?: string;
  isLoading: boolean;
  lastNameFromQueryParam?: string;
  method: SignInMethod;
  strategy: AuthenticationStrategy;
}

export const useStepRegisterProcess = (): UseStepRegisterProcessResult => {
  const utmValues = useUtmValues();
  const idTokenFromQueryParam = useQueryParam('idToken')?.trim();
  const subFromQueryParam = useQueryParam('sub')?.trim();
  const emailFromQueryParam = useQueryParam('email')?.toLowerCase()?.trim();
  const firstNameFromQueryParam = useQueryParam('first-name')?.trim();
  const lastNameFromQueryParam = useQueryParam('last-name')?.trim();
  const methodFromQueryParam = useQueryParam('method')?.trim();
  const strategyFromQueryParam = useQueryParam('strategy')?.trim();
  const hasSignInMethod = (Object.values(SignInMethod) as [string]).includes(methodFromQueryParam);
  const [createCustomerMutation, { loading: createCustomerLoading }] =
    useCreateCustomer_A12N2N3Mutation();
  const [createCustomerWithSsoMutation, { loading: createCustomerWithSSOLoading }] =
    useCreateCustomerWithSsoMutation();

  const createCustomer = async ({
    email,
    password,
    lang,
    lastName,
    firstName,
    isSubscribeNewsletter,
    phoneNumber,
    siteOrigin,
  }: CreateCustomerParams) => {
    let createCustomerPromise;
    const utm = utmValues || {};
    const sponsorEmail = await storage.readString('sponsorEmail');

    if (isSSOMethod(methodFromQueryParam) && idTokenFromQueryParam) {
      createCustomerPromise = createCustomerWithSsoMutation({
        variables: {
          customerWithSSOInput: {
            affiliateEmail: sponsorEmail,
            email,
            firstName,
            isSubscribeNewsletter,
            lang,
            lastName,
            phoneNumber,
            provider: methodFromQueryParam,
            siteOrigin,
            thirdPartyId: subFromQueryParam,
            utm,
          },
        },
      });
    } else {
      createCustomerPromise = createCustomerMutation({
        variables: {
          customerInput: {
            affiliateEmail: sponsorEmail,
            email,
            firstName,
            isSubscribeNewsletter,
            lang,
            lastName,
            password,
            phoneNumber,
            siteOrigin,
            utm,
          },
        },
      });
    }

    const { data } = await createCustomerPromise;

    return data.createCustomer_A12N2N3 || data.createCustomerWithSSO;
  };

  if (methodFromQueryParam && !hasSignInMethod) {
    throw new Error(`Invalid query param "method": ${methodFromQueryParam}`);
  }

  return {
    createCustomer,
    emailFromQueryParam,
    firstNameFromQueryParam,
    idTokenFromQueryParam,
    isLoading: createCustomerLoading || createCustomerWithSSOLoading,
    lastNameFromQueryParam,
    method: methodFromQueryParam as SignInMethod,
    strategy: (strategyFromQueryParam || AuthenticationStrategy.Local) as AuthenticationStrategy,
  };
};
