import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgHeat = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M8.25 19.7c-.2.2-.442.3-.725.3a.908.908 0 0 1-.7-.3c-.617-.65-1.075-1.321-1.375-2.013A5.38 5.38 0 0 1 5 15.525c0-.55.083-1.13.25-1.738.167-.608.45-1.396.85-2.362.333-.8.567-1.425.7-1.875.133-.45.2-.859.2-1.225 0-.467-.104-.909-.313-1.325-.208-.417-.545-.842-1.012-1.275a.877.877 0 0 1-.325-.7c0-.284.1-.525.3-.725a.963.963 0 0 1 .675-.288c.267-.008.5.08.7.263.667.616 1.162 1.258 1.487 1.925.325.666.488 1.375.488 2.125 0 .516-.08 1.062-.238 1.637-.158.575-.429 1.321-.812 2.238-.367.866-.617 1.529-.75 1.987-.133.459-.2.888-.2 1.288 0 .5.104.983.312 1.45.209.466.53.933.963 1.4.183.2.27.433.262.7a.963.963 0 0 1-.287.675Zm5.025.025c-.2.2-.442.3-.725.3a.951.951 0 0 1-.725-.325c-.617-.65-1.075-1.321-1.375-2.013a5.38 5.38 0 0 1-.45-2.162c0-.55.083-1.13.25-1.738.167-.608.45-1.396.85-2.362.333-.8.567-1.425.7-1.875.133-.45.2-.859.2-1.225 0-.467-.104-.909-.313-1.325-.208-.417-.545-.842-1.012-1.275a.877.877 0 0 1-.325-.7c0-.284.1-.525.3-.725a.963.963 0 0 1 .675-.288c.267-.008.5.08.7.263.667.616 1.162 1.258 1.487 1.925.325.666.488 1.375.488 2.125 0 .516-.08 1.058-.238 1.625-.158.566-.429 1.316-.812 2.25-.367.866-.617 1.533-.75 2-.133.466-.2.9-.2 1.3 0 .5.108.979.325 1.437.217.459.542.921.975 1.388.183.183.27.416.262.7a.993.993 0 0 1-.287.7Zm5 0c-.2.2-.442.3-.725.3a.951.951 0 0 1-.725-.325c-.617-.65-1.075-1.321-1.375-2.013a5.38 5.38 0 0 1-.45-2.162c0-.55.083-1.13.25-1.738.167-.608.45-1.396.85-2.362.333-.8.567-1.425.7-1.875.133-.45.2-.859.2-1.225 0-.467-.104-.909-.313-1.325-.208-.417-.545-.842-1.012-1.275a.877.877 0 0 1-.325-.7c0-.284.1-.525.3-.725a.963.963 0 0 1 .675-.288c.267-.008.5.08.7.263.667.616 1.163 1.258 1.488 1.925.324.666.487 1.375.487 2.125 0 .516-.08 1.062-.238 1.637-.158.575-.429 1.321-.812 2.238-.367.85-.617 1.508-.75 1.975-.133.466-.2.9-.2 1.3 0 .5.108.983.325 1.45.217.466.542.933.975 1.4.183.2.27.437.262.712a.978.978 0 0 1-.287.688Z"
    />
  </Svg>
);
export default SvgHeat;
