/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

export const getStyleSheet = ({
  isLowerThanDesktop,
  isLowerThanLaptop,
  isMobile,
  theme,
}: {
  isLowerThanDesktop?: boolean;
  isLowerThanLaptop?: boolean;
  isMobile?: boolean;
  theme: Theme;
}) =>
  StyleSheet.create({
    renderItem: {
      paddingHorizontal: isMobile ? theme.spacing.SIZE_06 : theme.spacing.SIZE_09,
      paddingTop: theme.spacing.SIZE_08,
      width: '100%',
    },
    scrollViewContent: {
      paddingHorizontal: isLowerThanDesktop
        ? isMobile
          ? 0
          : theme.spacing.SIZE_05
        : theme.spacing.SIZE_09,
      width: '100%',
    },
    tabsContainer: {
      alignItems: 'center',
      maxWidth: isLowerThanLaptop ? '100%' : 1120,
      width: '100%',
    },
  });
