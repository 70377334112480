import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgLb = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="lb_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#lb_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 5.333h28V0H0v5.333zM0 20h28v-5.333H0V20z" fill="#F03340" />
        <Path
          d="M12.943 12.39a.552.552 0 000-.78l-.077-.077a.498.498 0 00-.473-.131l-.38.095c-.443.11-.718-.468-.352-.742l.342-.258a.276.276 0 00-.165-.497c-.292 0-.385-.393-.124-.524l.572-.286c.261-.13.168-.523-.123-.523a.276.276 0 01-.166-.498L13.6 6.967a.667.667 0 01.8 0l1.603 1.202c.213.16.1.498-.165.498-.292 0-.385.393-.124.523l.572.286c.261.13.168.524-.123.524a.276.276 0 00-.166.498l.342.257c.366.274.09.852-.353.742l-.38-.095a.498.498 0 00-.472.13l-.077.078a.552.552 0 000 .78.552.552 0 01-.39.943h-1.334a.552.552 0 01-.39-.943z"
          fill="#1FC065"
        />
      </G>
    </Svg>
  );
};

export default SvgLb;
