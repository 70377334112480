import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgHandshake = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.875 20a.58.58 0 00.35-.15l8.2-8.2c.2-.2.346-.425.438-.675a2.174 2.174 0 000-1.513 1.657 1.657 0 00-.438-.637l-4.25-4.25a1.657 1.657 0 00-.637-.438 2.143 2.143 0 00-1.513 0c-.25.092-.475.238-.675.438l-.275.275 1.85 1.875c.25.233.433.5.55.8.117.3.175.617.175.95 0 .7-.237 1.287-.712 1.762-.475.475-1.063.713-1.763.713-.333 0-.654-.058-.962-.175a2.274 2.274 0 01-.813-.525L9.525 8.4 5.15 12.775a.473.473 0 00-.15.35c0 .133.05.254.15.362a.444.444 0 00.55.113.582.582 0 00.15-.1l3.4-3.4 1.4 1.4-3.375 3.4a.48.48 0 00-.15.35c0 .133.05.25.15.35.1.1.217.15.35.15a.582.582 0 00.35-.15l3.4-3.375 1.4 1.4-3.375 3.4a.297.297 0 00-.112.15.56.56 0 00-.038.2c0 .133.05.25.15.35a.48.48 0 00.7 0l3.4-3.375 1.4 1.4-3.4 3.4a.47.47 0 00-.15.35c0 .133.054.25.163.35.108.1.229.15.362.15zm-.025 2a2.436 2.436 0 01-1.637-.613 2.384 2.384 0 01-.838-1.537 2.465 2.465 0 01-1.425-.7 2.465 2.465 0 01-.7-1.425 2.373 2.373 0 01-1.412-.712A2.544 2.544 0 015.15 15.6a2.377 2.377 0 01-1.55-.825c-.4-.467-.6-1.017-.6-1.65 0-.333.063-.654.188-.963a2.42 2.42 0 01.537-.812l5.8-5.775L12.8 8.85c.033.05.083.087.15.112a.56.56 0 00.2.038c.15 0 .275-.046.375-.137a.47.47 0 00.15-.363.572.572 0 00-.037-.2.302.302 0 00-.113-.15L9.95 4.575a1.656 1.656 0 00-.638-.438 2.135 2.135 0 00-1.512 0c-.25.092-.475.238-.675.438L3.6 8.125c-.15.15-.275.325-.375.525-.1.2-.167.4-.2.6a1.885 1.885 0 00.2 1.2l-1.45 1.45a3.974 3.974 0 01-.625-1.263 3.925 3.925 0 01.2-2.75c.2-.441.475-.837.825-1.187L5.7 3.175c.4-.383.846-.675 1.338-.875a3.976 3.976 0 014.337.875l.275.275.275-.275c.4-.383.846-.675 1.337-.875.492-.2.996-.3 1.513-.3.517 0 1.021.1 1.513.3.491.2.929.492 1.312.875L21.825 7.4A4.074 4.074 0 0123 10.25c0 .517-.1 1.02-.3 1.512-.2.492-.492.93-.875 1.313l-8.2 8.175a2.591 2.591 0 01-.813.55c-.308.133-.629.2-.962.2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgHandshake;
