import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgRoofing = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M3.3 12a.468.468 0 0 1-.475-.325c-.083-.217-.033-.4.15-.55L10.65 4.2c.183-.184.396-.313.637-.388.242-.075.48-.112.713-.112.233 0 .47.037.713.112.241.075.454.204.637.388L16 6.6V5c0-.284.096-.521.288-.713A.968.968 0 0 1 17 4h1c.283 0 .52.096.712.287.192.192.288.43.288.713v4.3l2.025 1.825c.183.15.233.333.15.55A.468.468 0 0 1 20.7 12h-.925a2.018 2.018 0 0 1-1.35-.525L12 5.7l-6.425 5.775a2.018 2.018 0 0 1-1.35.525H3.3ZM9 19v-4c0-.284.096-.521.288-.713A.967.967 0 0 1 10 14h4c.283 0 .52.095.713.287.191.192.287.43.287.713v4c0 .283-.096.52-.287.712A.968.968 0 0 1 14 20h-4a.967.967 0 0 1-.712-.288A.968.968 0 0 1 9 19Zm2-1h2v-2h-2v2Z"
    />
  </Svg>
);
export default SvgRoofing;
