import { ListItemUnionProps } from '@app/ui';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { stringToSelect } from '@app/utils/select-formatter';

export const getRegionOptionList = (
  watchCountry: AvailableCountryCode,
  regionListByCountry: Record<string, string[]>,
  allRegionOption: ListItemUnionProps
): ListItemUnionProps[] => {
  if (watchCountry && regionListByCountry[watchCountry]) {
    return [
      allRegionOption,
      ...regionListByCountry[watchCountry]
        .map(r => stringToSelect(r))
        .map(item => ({ checkbox: true, ...item })),
    ];
  }
  return [];
};
