import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

import { ToastVariant } from './Toast.types';

export const getVariantStyles = (
  variant: ToastVariant,
  theme: Theme
): { backgroundColor: string; color: string } => {
  const variantStyle = {
    [ToastVariant.DEFAULT]: {
      backgroundColor: theme.palette.neutral[800],
      color: theme.palette.base.white,
    },
    [ToastVariant.ERROR]: {
      backgroundColor: theme.palette.alert.error,
      color: theme.palette.base.white,
    },
  };

  return variantStyle[variant];
};

export const getToastStyle = ({
  theme,
  variant,
  width,
}: {
  theme: Theme;
  variant: ToastVariant;
  width?: string | number;
}) => {
  const variantColor = getVariantStyles(variant, theme);

  return StyleSheet.create({
    button: {
      marginLeft: theme.spacing.SIZE_03,
    },
    closeLabel: {
      color: variantColor.color,
      textDecorationLine: 'underline',
    },
    container: {
      alignItems: 'center',
      alignSelf: 'flex-start',
      backgroundColor: variantColor.backgroundColor,
      borderRadius: theme.borderRadius.SIZE_04,
      flexDirection: 'row',
      marginHorizontal: theme.spacing.SIZE_03,
      maxWidth: width || 450,
      paddingHorizontal: theme.spacing.SIZE_03,
      paddingVertical: theme.spacing.SIZE_04,
    },
    containerCloseButton: {
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 0,
      marginLeft: theme.spacing.SIZE_07,
    },
    icon: {
      marginRight: theme.spacing.SIZE_02,
    },
    label: {
      color: variantColor.color,
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
      marginRight: theme.spacing.SIZE_03,
    },
  });
};
