import React, { PropsWithChildren } from 'react';

import { StyleSheet, View } from 'react-native';

import { Spacing } from '@app/ui/theme/Sizes';
import { Icon } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';

import { TextWithIconProps } from './TextWithIcon.types';

export const TextWithIcon: React.FC<PropsWithChildren<TextWithIconProps>> = ({
  icon,
  text,
  children,
}): React.ReactElement => {
  const style = StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    icon: { marginRight: Spacing.SIZE_02, width: icon.size },
  });

  return (
    <View style={style.container}>
      <View style={style.icon}>
        <Icon color={icon.color} name={icon.name} size={icon.size} />
      </View>
      <Text textStyle={text.font} style={text.style}>
        {children}
      </Text>
    </View>
  );
};
