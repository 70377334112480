import { useEffect, useState } from 'react';

import { storage } from '@app/services/storage/storage';

interface DeleteAccountModalReturn {
  isVisibleDeletedAccountModal: boolean;
  setIsVisibleDeletedAccountModal: (value: boolean) => void;
  toggleIsVisibleDeletedAccountModal: () => void;
}

export const useDeletedAccountModal = (deletedAccount: boolean): DeleteAccountModalReturn => {
  const [isVisibleDeletedAccountModal, setIsVisibleDeletedAccountModal] = useState<boolean>(false);
  const toggleIsVisibleDeletedAccountModal = () => {
    setIsVisibleDeletedAccountModal(!isVisibleDeletedAccountModal);
  };

  useEffect(() => {
    storage.readObject<boolean>('deleted_account').then(hasDeletedAccountKey => {
      if (hasDeletedAccountKey || deletedAccount) {
        setTimeout(async () => {
          await storage.clear('deleted_account');
          toggleIsVisibleDeletedAccountModal();
        }, 1000);
      }
    });
  }, []);

  return {
    isVisibleDeletedAccountModal,
    setIsVisibleDeletedAccountModal,
    toggleIsVisibleDeletedAccountModal,
  };
};
