import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSearchProperty = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7 14v-2h2v2H7zm5-6V6h2v2h-2zM7 18v-2h2v2H7zm0 4v-2h2v2H7zm-4 0V10c0-.55.196-1.021.588-1.413A1.925 1.925 0 015 8h3V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0110 2h6c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v5h-2V4h-6v5a1 1 0 01-1 1H5v12H3z"
        fill={props.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9 21.3l-2-2c-.35.2-.725.367-1.125.5s-.825.2-1.275.2c-1.25 0-2.313-.437-3.188-1.312S11 16.75 11 15.5c0-1.25.437-2.313 1.312-3.188S14.25 11 15.5 11c1.25 0 2.313.437 3.188 1.312S20 14.25 20 15.5c0 .45-.067.875-.2 1.275-.133.4-.3.775-.5 1.125l2 2a.948.948 0 01.275.7.948.948 0 01-.275.7.948.948 0 01-.7.275.948.948 0 01-.7-.275zm-4.347-3.237a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSearchProperty;
