import React, { useMemo } from 'react';

import { ColorValue, StyleSheet, View } from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { useTheme } from '@app/ui/contexts/config';
import { Theme } from '@app/ui';

export interface Grade {
  value: string;
  label: string;
  color: ColorValue;
  description: string;
}

interface GradeIndicatorProps extends Omit<Grade, 'description'> {
  selected: boolean;
  index?: number;
}

const sizeStep = 20;
const getStyles = (color: ColorValue, selected: boolean, index: number, theme: Theme) =>
  StyleSheet.create({
    bar: {
      backgroundColor: selected ? color : theme.palette.base.white,
      flex: 1,
      height: theme.spacing.SIZE_00,
    },
    container: {
      alignItems: 'center',
      borderRadius: theme.borderRadius.SIZE_06,
      flexDirection: 'row',
      paddingVertical: theme.spacing.SIZE_02,
      width: '100%',
    },
    labelContainer: {
      minWidth: sizeStep + (index + 1) * sizeStep,
    },
    labelText: {
      color: theme.palette.base.white,
    },
    sideElement: {
      backgroundColor: color,
      borderRadius: theme.spacing.SIZE_09,
      display: 'flex',
      height: theme.spacing.SIZE_07,
      justifyContent: 'center',
      paddingHorizontal: theme.spacing.SIZE_04,
      textAlignVertical: 'center',
    },
    valueContainer: {
      backgroundColor: selected ? color : theme.palette.neutral[500],
    },
    valueText: {
      color: selected ? theme.palette.base.white : theme.palette.base.black,
    },
  });

export const GradeIndicator: React.FC<GradeIndicatorProps> = ({
  selected,
  value,
  label,
  color,
  index = 1,
}) => {
  const theme = useTheme();
  const styles = useMemo(
    () => getStyles(color, selected, index, theme),
    [color, selected, index, theme]
  );

  return (
    <View style={styles.container}>
      <View style={[styles.sideElement, styles.labelContainer]}>
        <Text style={styles.labelText} textStyle="Caption1">
          {label}
        </Text>
      </View>
      <View style={styles.bar}></View>
      <View style={[styles.sideElement, styles.valueContainer]}>
        <Text style={styles.valueText} textStyle="Body3">
          {value}
        </Text>
      </View>
    </View>
  );
};
