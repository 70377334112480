import { MutableRefObject } from 'react';

import { NavigationContainerRefWithCurrent, ParamListBase } from '@react-navigation/native';

import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import {
  MainMenuNavigatorRoutes,
  PrivateNavigatorRoutes,
  ProjectNavigatorRoutes,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
  Routes,
} from '@app/navigation/types/routes';
import { isNative } from '@app/constants/platform';

export const excludeTrackingRoutes = [
  MainMenuNavigatorRoutes.RealEstates,
  RealEstateNavigatorRoutes.RealEstate,
  PublicNavigatorRoutes.Register,
  MainMenuNavigatorRoutes.Projects,
  ProjectNavigatorRoutes.ProjectList,
  ProjectNavigatorRoutes.Project,
  PrivateNavigatorRoutes.Onboarding,
];

export const launchTracking = (
  routeNameRef: MutableRefObject<Routes | undefined>,
  navigationRef: NavigationContainerRefWithCurrent<ParamListBase>
): void => {
  const previousRouteName = routeNameRef.current;
  const currentRouteName = navigationRef.getCurrentRoute()?.name;
  const shouldTrack =
    (previousRouteName !== currentRouteName &&
      !excludeTrackingRoutes.some(routeName => currentRouteName === routeName)) ||
    (previousRouteName === PublicNavigatorRoutes.Auth &&
      currentRouteName === PublicNavigatorRoutes.Auth);

  if (!shouldTrack) {
    return;
  }

  if (isNative()) {
    trackChangeScreen(currentRouteName as Routes);
  } else {
    trackChangePage(currentRouteName as Routes);
  }
};
