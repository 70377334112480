import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgElectricalServices = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M19 15a.968.968 0 0 1-.712-.287A.968.968 0 0 1 18 14c0-.283.096-.52.288-.713A.968.968 0 0 1 19 13h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 15h-1Zm0 4a.968.968 0 0 1-.712-.288A.968.968 0 0 1 18 18c0-.283.096-.52.288-.712A.968.968 0 0 1 19 17h1c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 20 19h-1Zm-5 1c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 12 18h-1a.968.968 0 0 1-.713-.288A.968.968 0 0 1 10 17v-2c0-.283.096-.52.287-.713A.968.968 0 0 1 11 14h1c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 14 12h2c.283 0 .52.096.712.287.192.192.288.43.288.713v6c0 .283-.096.52-.288.712A.968.968 0 0 1 16 20h-2Zm-7-3c-1.1 0-2.042-.392-2.825-1.175C3.392 15.042 3 14.1 3 13s.392-2.042 1.175-2.825C4.958 9.392 5.9 9 7 9h1.5c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 8.5 6H5a.968.968 0 0 1-.713-.287A.968.968 0 0 1 4 5c0-.283.096-.52.287-.713A.968.968 0 0 1 5 4h3.5c.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0 1 12 7.5c0 .967-.342 1.792-1.025 2.475A3.372 3.372 0 0 1 8.5 11H7c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 5 13c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588h1c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.712A.967.967 0 0 1 8 17H7Z"
    />
  </Svg>
);
export default SvgElectricalServices;
