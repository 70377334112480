import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgCeu = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.098 17.764l-1.042-1.042a.335.335 0 01-.08-.13l-.567-1.698a.333.333 0 00-.639.187l.518 2.07a.333.333 0 01-.088.316l-.298.297a.333.333 0 01-.471 0l-.333-.333a.333.333 0 01-.098-.236V16.08a.333.333 0 00-.035-.15l-.54-1.078a.333.333 0 00-.298-.184h-1.715a.332.332 0 00-.149.035l-1.14.57a.333.333 0 00-.167.192l-.579 1.738a.334.334 0 01-.167.193l-1.06.53a.333.333 0 01-.3 0l-1-.5a.333.333 0 01-.183-.298V15c0-.184.149-.333.333-.333h1.333c.184 0 .334-.15.334-.334v-.946c0-.035.005-.071.017-.105l.573-1.72a.333.333 0 01.317-.229h1.014a.332.332 0 00.149-.035l1.193-.596a.333.333 0 01.149-.035H13c.184 0 .333-.15.333-.334v-.195c0-.088.035-.173.098-.236l.242-.242a.333.333 0 01.534.087l.367.735a.333.333 0 00.299.185h1.553c.144 0 .271-.092.317-.228l.514-1.544a.333.333 0 01.317-.228h.76a.333.333 0 100-.667h-.667a.333.333 0 01-.334-.333v-.922c0-.051.012-.102.036-.149l.524-1.048a.333.333 0 00-.063-.385l-.261-.26a.333.333 0 00-.471 0l-1 1a.333.333 0 00-.098.235V7.28a.334.334 0 01-.017.106l-.574 1.72a.333.333 0 01-.316.228h-.288a.333.333 0 01-.236-.097l-.473-.473A.328.328 0 0114 8.53a.328.328 0 00-.475-.294l-.643.322a.333.333 0 01-.385-.062l-.4-.4A.333.333 0 0112 7.863V6.805c0-.089.035-.173.098-.236l3.18-3.18a.333.333 0 01.13-.08l1.874-.625a.333.333 0 01.105-.017h1.074c.126 0 .241.071.298.184l.54 1.079a.333.333 0 01.034.149v1.783c0 .088.036.173.098.236l.423.423c.09.09.12.221.08.341l-.52 1.563c-.048.143.006.3.131.383L21.333 10l.996.498c.18.09.24.316.128.483l-1.068 1.602a.333.333 0 00-.056.185v1.658c0 .144-.091.271-.228.317l-1.64.547a.333.333 0 00-.193.167l-.581 1.161a.334.334 0 01-.063.087l-1.059 1.06a.333.333 0 01-.471 0zm-5.765-.43a.667.667 0 100-1.334.667.667 0 000 1.333zm-2-8.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0z"
        fill="#131315"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.965 8.737A.333.333 0 0010 8.588v-.51a.333.333 0 00-.035-.148l-.39-.78a.333.333 0 01.298-.483h.46c.184 0 .334.149.334.333v1.588c0 .052.012.103.035.15l.39.78a.333.333 0 01-.298.482h-.921a.333.333 0 01-.298-.482l.39-.781z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgCeu;
