/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StyleSheet } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';

import { Theme } from '@app/ui';
import { HORIZONTAL_PADDING, MAX_WIDTH_CONTAINER } from '@app/features/project/Project.styles';

export const getHeaderStyles = ({
  isLowerThanTablet,
  theme,
}: {
  isLowerThanTablet: boolean;
  theme: Theme;
}) =>
  StyleSheet.create({
    advisorSelector: {
      marginBottom: 20,
      marginTop: 40,
      minHeight: 40,
    },
    coverImage: { borderRadius: theme.borderRadius.SIZE_07, height: 312 },
    header: {
      paddingHorizontal: isLowerThanTablet ? HORIZONTAL_PADDING.MOBILE : HORIZONTAL_PADDING.DESKTOP,
    },
    headerBlock: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    subHeader: {
      alignSelf: 'center',
      maxWidth: MAX_WIDTH_CONTAINER,
      width: '100%',
    },
  });

export const getResponsiveHeaderStyles = (theme: Theme, inset: EdgeInsets) =>
  StyleSheet.create({
    chip: {
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 1,
    },
    content: {
      alignItems: 'center',
      paddingHorizontal: HORIZONTAL_PADDING.MOBILE,
      textAlign: 'center',
    },
    heading: {
      alignItems: 'center',
      paddingBottom: 20,
    },
    image: { height: 300 },
    realEstateOverview: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 20,
      width: '100%',
    },
    separator: { width: '80%' },
    status: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: HORIZONTAL_PADDING.MOBILE,
      paddingTop: 12 + inset.top,
      position: 'absolute',
      width: '100%',
    },
    wrapper: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_02,
    },
  });
