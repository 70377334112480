import { GetSoldRealEstatesV2Query } from '@app/libs/apollo/introspection';

import { SoldRealEstateItem } from '../compounds/sold-real-estate-card/SoldRealEstateCard';

export const soldRealEstateToItemMapper = (
  soldRealEstate: GetSoldRealEstatesV2Query['soldRealEstatesV2'][number],
  index: number
): SoldRealEstateItem => ({
  coverImage: soldRealEstate.coverImage,
  id: soldRealEstate.id,
  key: `${soldRealEstate.id}_${index}`,
  rentingDelay: soldRealEstate.afterRenovation.financialStrategy.rentingDelay,
  strategy: soldRealEstate.afterRenovation.financialStrategy.type,
  title: soldRealEstate.title,
});
