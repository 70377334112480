import React from 'react';

import { StyleSheet } from 'react-native';

import { useTheme, Theme } from '@app/ui';
import { TouchableOpacity } from '@app/ui/atoms/touchable-opacity/TouchableOpacity';

import {
  RealEstateHeaderProps,
  RealEstatePreviewCardHeader,
} from './components/Vertical/header/RealEstatePreviewCardHeader';
import {
  RealEstatePreviewCardBody,
  RealEstatePreviewCardBodyProps,
} from './components/Vertical/body/RealEstatePreviewCardBody';

export type RealEstatePreview = RealEstateHeaderProps & RealEstatePreviewCardBodyProps;

interface RealEstatePreviewCardVerticalProps {
  realEstatePreview: RealEstatePreview;
  onBookmark: () => void;
  onPress: () => void;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      gap: theme.spacing.SIZE_03,
      minHeight: 376,
      minWidth: 282,
      overflow: 'hidden',
    },
  });

export const RealEstatePreviewCardVertical: React.FC<RealEstatePreviewCardVerticalProps> = ({
  onBookmark,
  onPress,
  realEstatePreview: {
    image,
    isNew,
    shortTermNightlyRent,
    isExclusive,
    positioningStatus,
    isInTransaction,
    expirationDate,
    isBookmarked,
    title,
    surface,
    grossReturnRate,
    netReturnRate,
    price,
    propertyKind,
    totalLots,
    roomCount,
    isViewed,
  },
}) => {
  const theme = useTheme();

  const styles = getStyles(theme);

  return (
    // eslint-disable-next-line local-rules/no-touchable-opacity
    <TouchableOpacity
      onPress={onPress}
      style={styles.container}
      testID="real-estate-card--container"
    >
      <RealEstatePreviewCardHeader
        onBookmark={onBookmark}
        expirationDate={expirationDate}
        image={image}
        isBookmarked={isBookmarked}
        isExclusive={isExclusive}
        isInTransaction={isInTransaction}
        isNew={isNew}
        positioningStatus={positioningStatus}
        shortTermNightlyRent={shortTermNightlyRent}
        isViewed={isViewed}
      />
      <RealEstatePreviewCardBody
        grossReturnRate={grossReturnRate}
        netReturnRate={netReturnRate}
        price={price}
        propertyKind={propertyKind}
        roomCount={roomCount}
        surface={surface}
        title={title}
        totalLots={totalLots}
      />
    </TouchableOpacity>
  );
};
