import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgUstensilsPlastic = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3.725 20.3a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7l9.55-9.55c-.3-.7-.342-1.492-.125-2.375.217-.883.692-1.675 1.425-2.375.883-.883 1.867-1.4 2.95-1.55 1.083-.15 1.967.117 2.65.8.683.683.95 1.567.8 2.65-.15 1.083-.667 2.067-1.55 2.95-.7.733-1.492 1.208-2.375 1.425-.883.217-1.675.175-2.375-.125L13.425 12l6.9 6.9a.949.949 0 01.275.7.948.948 0 01-.275.7c-.2.2-.433.3-.7.3a.96.96 0 01-.7-.3l-6.9-6.85-6.9 6.85c-.2.2-.433.3-.7.3a.96.96 0 01-.7-.3zm3.65-7.85l-3-3c-.75-.75-1.188-1.625-1.313-2.625S3.125 4.883 3.625 4a.93.93 0 01.75-.475c.333-.033.625.083.875.35l5.325 5.375-3.2 3.2z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgUstensilsPlastic;
