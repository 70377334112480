import { StyleSheet } from 'react-native';

import { palette, Spacing, Theme } from '@app/ui';
import { isWeb } from '@app/constants/platform';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyle = ({
  isMobile,
  isLowerThanTablet,
}: {
  isMobile: boolean;
  isLowerThanTablet: boolean;
}) =>
  StyleSheet.create({
    bodyContainer: {
      flexDirection: 'row',
    },
    bodyContainerLayout: {
      marginTop: Spacing.SIZE_04,
      ...(isWeb()
        ? { marginHorizontal: isLowerThanTablet ? Spacing.SIZE_06 : Spacing.SIZE_09 }
        : { flex: 1 }),
    },
    container: isWeb() && isLowerThanTablet ? {} : { flex: 1 },
    drawerContainer: {
      flex: 1,
      width: !isMobile ? '480px' : '100%',
    },
    headerContainer: {
      backgroundColor: palette.base.white,
      paddingBottom: !isMobile ? Spacing.SIZE_07 : isWeb() ? Spacing.SIZE_04 : Spacing.NONE,
      paddingTop: !isMobile ? Spacing.SIZE_07 : Spacing.SIZE_04,
      top: 0,
      zIndex: 4,
      ...(isWeb() ? { position: 'sticky' as 'relative' } : { position: 'relative' }),
      ...(!isMobile ? { paddingHorizontal: Spacing.SIZE_09 } : {}),
    },
    transactionCardContainer: {
      alignItems: !isMobile ? 'baseline' : 'center',
      flex: 1,
      marginLeft: isMobile ? Spacing.NONE : Spacing.SIZE_08,
      marginTop: Spacing.SIZE_04,
    },
    transactionCardWrapper: { maxWidth: 320 },
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getContainerStyle = (theme: Theme) =>
  StyleSheet.create({ container: { backgroundColor: theme.palette.base.white, flex: 1 } });
