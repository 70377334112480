import { StyleSheet } from 'react-native';

import { RequiredThemes, Theme } from '@app/ui/theme/types';

import { RadioSize } from './RadioButton.types';

export const styles = (componentTheme: RequiredThemes['radioButton'], size: RadioSize) =>
  StyleSheet.create({
    animatedView: {
      left: 0,
      position: 'absolute',
      top: 0,
    },
    pressable: {
      height: componentTheme.size.outerSize[size],
      width: componentTheme.size.outerSize[size],
    },
  });

export const radioItemStyles = (
  theme: Theme,
  error: boolean,
  componentTheme: RequiredThemes['radioButton'],
  size: RadioSize,
  disabled: boolean
) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: !error ? 0 : theme.spacing.SIZE_03,
      paddingTop: theme.spacing.SIZE_03,
    },
    errorLabel: {
      paddingBottom: theme.spacing.SIZE_03,
      ...theme.textStyles.Caption1,
      color: theme.palette.alert.error,
    },
    label: {
      flexGrow: 1,
      flexShrink: 1,
      paddingLeft: theme.spacing.SIZE_04,
      ...componentTheme.label.textStyles[size],
      color: componentTheme.colors.text[disabled ? 'disabled' : 'default'],
    },
  });
