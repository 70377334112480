import { Theme } from '@app/ui/theme/types';

export const radioButtonTheme = (theme: Theme) => ({
  colors: {
    radio: {
      active: theme.palette.neutral[900],
      default: theme.palette.neutral[300],
      disabled: theme.palette.neutral[300],
      error: theme.palette.alert.error,
    },
    text: {
      active: theme.palette.base.black,
      default: theme.palette.neutral[900],
      disabled: theme.palette.neutral[600],
      error: theme.palette.alert.error,
    },
  },
  label: {
    error: theme.palette.alert.error,
    errorTextStyle: theme.textStyles.Caption1,
    lineHeight: theme.spacing.SIZE_07,
    marginLeft: theme.spacing.SIZE_03,
    textStyles: {
      l: theme.textStyles.Body2,
      m: theme.textStyles.Body3,
    },
  },
  size: {
    innerSize: {
      position: {
        l: theme.spacing.SIZE_02,
        m: theme.spacing.SIZE_02,
      },
      size: {
        l: theme.spacing.SIZE_04,
        m: theme.spacing.SIZE_03,
      },
    },
    outerSize: {
      l: theme.spacing.SIZE_07,
      m: theme.spacing.SIZE_06,
    },
  },
});
