import React, { useCallback, useContext, useMemo, useState } from 'react';

import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Image, Text, useResponsive, Spacer, useTheme, Button } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import {
  TakeAppointmentSource,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { DiscussTakeAppointmentContent } from '@app/features/discuss/components/DiscussTakeAppointmentContent';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { DiscussModalTitle } from '@app/features/discuss/components/DiscussModalTitle';

import { getStyles } from './realEstateImageAppointment.style';

type RealEstateGalleryImageAppointmentNavigationProp = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

interface PropertyTopImagesAppointmentProps {
  imageUrl: string;
  onPress: () => void;
  propertyId: string;
}

export const RealEstateGalleryImageAppointment: React.FC<PropertyTopImagesAppointmentProps> = ({
  imageUrl,
  onPress,
  propertyId,
}) => {
  const navigation = useNavigation<RealEstateGalleryImageAppointmentNavigationProp>();
  const { t } = useTranslation();
  const { device, isMobile } = useResponsive();
  const theme = useTheme();
  const { openModal } = useContext(ModalContext);

  const styles = useMemo(() => getStyles(theme, device), [theme, device]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = () => setImageLoaded(true);

  const handleOnPress = useCallback(() => {
    onPress();
    trackTakeAdvisorAppointmentClick({
      propertyId,
      sourcePage: TakeAppointmentSource.propertyPageGallery,
    });

    openModal?.(ModalKey.FirstAppointment, <DiscussTakeAppointmentContent />, {
      position: 'center',
      title: <DiscussModalTitle />,
      webSize: 'l',
    });
  }, [navigation, onPress, propertyId]);

  return (
    <Image testID="RealEstateGalleryImageAppointment" source={{ uri: imageUrl }} onLoad={onLoad}>
      {!!imageLoaded && (
        <>
          <View style={styles.imageMask} />
          <View style={styles.container}>
            <Text style={styles.text} textStyle={isMobile ? 'Body1Medium' : 'Headline2Medium'}>
              {t('propertyInfo.takeAppointmentToSeeMorePic')}
            </Text>
            <Spacer height={theme.spacing.SIZE_04} />
            <Button
              iconName="Phone"
              iconPlacement="left"
              testID="property-gallery-appointment"
              onPress={handleOnPress}
            >
              {t('propertyActions.meetMyAdvisor')}
            </Button>
            <Spacer height={theme.spacing.SIZE_04} />
            <Text style={styles.text} textStyle="Body3">
              {t('propertyInfo.freeWithoutCommitment')}
            </Text>
          </View>
        </>
      )}
    </Image>
  );
};
