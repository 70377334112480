import { FinancialModel } from '@masteos/agora';

import { realEstateToFinancialModelCompProps } from '@app/shared/hooks/useFinancialModel';

export enum FinancialModelActions {
  UPDATE_FINANCIAL_MODEL = 'UPDATE_FINANCIAL_MODEL',
  UPDATE_DEFAULT_FINANCIAL_MODEL = 'UPDATE_DEFAULT_FINANCIAL_MODEL',
  SET_LOAN_DOWN = 'SET_LOAN_DOWN',
  SET_EVALUATION_RATE = 'SET_EVALUATION_RATE',
  INIT = 'INIT',
  SET_IS_PAYMENT_CASH = 'SET_IS_PAYMENT_CASH',
  SET_LOAN_DURATION = 'SET_LOAN_DURATION',
  SET_LOAN_INTEREST_RATE = 'SET_LOAN_INTEREST_RATE',
  SET_RESELLING_YEAR = 'SET_RESELLING_YEAR',
  SET_WITH_BROKERAGE_FEES = 'SET_WITH_BROKERAGE_FEES',
  SET_WITH_PROPERTY_MANAGEMENT = 'SET_WITH_PROPERTY_MANAGEMENT',
  SET_WITH_PREMIUM_PROPERTY_MANAGEMENT = 'SET_WITH_PREMIUM_PROPERTY_MANAGEMENT',
  SET_ANNUAL_AVG_OCCUPANCY_IN_MONTHS = 'SET_ANNUAL_AVG_OCCUPANCY_IN_MONTHS',
  SET_WITH_SHORT_TERM_RENTAL = 'SET_WITH_SHORT_TERM_RENTAL',
  SET_SHORT_TERM_NIGHTLY_RENT = 'SET_SHORT_TERM_NIGHTLY_RENT',
  SET_SHORT_TERM_OCCUPANCY_RATE = 'SET_SHORT_TERM_OCCUPANCY_RATE',
}

interface ActionUpdateFinancialModel {
  type: FinancialModelActions.UPDATE_FINANCIAL_MODEL;
  payload: FinancialModel;
}

interface ActionSetLoanDown {
  type: FinancialModelActions.SET_LOAN_DOWN;
  payload: number;
}

interface ActionSetLoanInterestRate {
  type: FinancialModelActions.SET_LOAN_INTEREST_RATE;
  payload: number;
}

interface ActionSetLoanDuration {
  type: FinancialModelActions.SET_LOAN_DURATION;
  payload: number;
}

interface ActionSetWithBrokerageFees {
  type: FinancialModelActions.SET_WITH_BROKERAGE_FEES;
  payload: boolean;
}

interface ActionSetWithShortTermRental {
  type: FinancialModelActions.SET_WITH_SHORT_TERM_RENTAL;
  payload: boolean;
}
interface ActionSetShortTermOccupancyRate {
  type: FinancialModelActions.SET_SHORT_TERM_OCCUPANCY_RATE;
  payload: number;
}

interface ActionSetShortTermNightlyRent {
  type: FinancialModelActions.SET_SHORT_TERM_NIGHTLY_RENT;
  payload: number;
}

interface ActionSetResellingYear {
  type: FinancialModelActions.SET_RESELLING_YEAR;
  payload: number;
}

interface ActionSetIsPaymentCash {
  type: FinancialModelActions.SET_IS_PAYMENT_CASH;
  payload: boolean;
}

interface ActionSetEvaluationRate {
  type: FinancialModelActions.SET_EVALUATION_RATE;
  payload: number;
}

interface ActionSetWithPropertyManagement {
  type: FinancialModelActions.SET_WITH_PROPERTY_MANAGEMENT;
  payload: boolean;
}

interface ActionSetWithPremiumPropertyManagement {
  type: FinancialModelActions.SET_WITH_PREMIUM_PROPERTY_MANAGEMENT;
  payload: boolean;
}

interface ActionSetAnnualAvgOccupancyInMonths {
  type: FinancialModelActions.SET_ANNUAL_AVG_OCCUPANCY_IN_MONTHS;
  payload: number;
}

interface ActionInit {
  type: FinancialModelActions.INIT;
  payload: {
    compProps: ReturnType<typeof realEstateToFinancialModelCompProps>;
    fm: FinancialModel;
  };
}

export type FinancialModelAction =
  | ActionSetLoanInterestRate
  | ActionUpdateFinancialModel
  | ActionSetLoanDuration
  | ActionSetLoanDown
  | ActionSetIsPaymentCash
  | ActionSetEvaluationRate
  | ActionSetResellingYear
  | ActionSetWithBrokerageFees
  | ActionSetWithPropertyManagement
  | ActionSetWithPremiumPropertyManagement
  | ActionSetWithShortTermRental
  | ActionInit
  | ActionSetAnnualAvgOccupancyInMonths
  | ActionSetShortTermOccupancyRate
  | ActionSetShortTermNightlyRent;
