export interface CircleStepIndicatorProps {
  indicator: number | string | JSX.Element;
  size?: StepSize;
  status?: StepStatus;
  variant?: StepVariant;
}

export enum StepSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum StepVariant {
  Neutral = 'neutral',
  Primary = 'primary',
}

export enum StepStatus {
  Incoming = 'incoming',
  Ongoing = 'ongoing',
  Finished = 'finished',
}
