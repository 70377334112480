import React from 'react';

import { View } from 'react-native';

import { Avatar } from '@app/ui/atoms/Avatar/Avatar';

import { AvatarActivatorProps } from './AvatarActivator.types';

export { AvatarActivatorProps };

export const AvatarActivator: React.FC<AvatarActivatorProps> = ({ onPress, avatarUrl }) => {
  return (
    <View collapsable={false} testID="AvatarActivator">
      <Avatar avatarUrl={avatarUrl} onPress={onPress} size={32} />
    </View>
  );
};
