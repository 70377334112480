import React, { useEffect } from 'react';

import { Animated } from 'react-native';

import { useAnimation } from '@app/ui/utils/useAnimation';
import { Icon } from '@app/ui/atoms/Icon';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { radioButtonTheme } from '@app/ui/atoms/RadioButton/radioButton.theme';
import { RadioButtonProps } from '@app/ui/atoms/RadioButton/RadioButton.types';
import { styles } from '@app/ui/atoms/RadioButton/styles';
import { getRadioColor } from '@app/ui/atoms/RadioButton/utils';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';

/**
 * This component represent a RadioButton
 *
 * @param {RadioButtonProps} {
 *   checked = false,
 *   onPress,
 *   ...svgProps
 * }
 * @return {*}
 */
export const RadioButton: React.FC<RadioButtonProps> = ({
  checked = false,
  onPress,
  pressableStyle,
  disabled,
  testID,
  error = false,
  size = 'l',
  ...svgProps
}) => {
  const { setIsOn, animatedTiming } = useAnimation(checked);

  useEffect(() => {
    setIsOn(checked);
  }, [checked]);

  const componentTheme = useComponentTheme('radioButton', radioButtonTheme);

  const spin = animatedTiming.interpolate({
    inputRange: [0, 1],
    outputRange: ['100deg', '0deg'],
  });

  const invertedOpacity = animatedTiming.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  });

  const themedStyles = styles(componentTheme, size);
  const radioColor = getRadioColor(checked, !!disabled, !!error, componentTheme);

  return (
    <Pressable
      style={[themedStyles.pressable, pressableStyle]}
      onPress={() => {
        onPress?.(!checked);
      }}
      disabled={disabled}
      accessibilityRole="radio"
      accessibilityState={{ checked, disabled }}
      testID={testID}
    >
      <Animated.View
        style={[
          themedStyles.animatedView,
          {
            opacity: invertedOpacity,
          },
        ]}
      >
        <Icon
          name="RadioButtonOff"
          size={componentTheme.size.outerSize[size]}
          color={radioColor}
          {...svgProps}
        />
      </Animated.View>
      <Animated.View
        style={[
          themedStyles.animatedView,
          {
            opacity: animatedTiming,
            transform: [{ rotate: spin }],
          },
        ]}
      >
        <Icon
          name="RadioButtonOn"
          size={componentTheme.size.outerSize[size]}
          color={radioColor}
          {...svgProps}
        />
      </Animated.View>
    </Pressable>
  );
};
