import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChrome = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 2C8.728 2 5.832 3.579 4.008 6.008A9.942 9.942 0 002 12c0 5.197 3.964 9.465 9.033 9.951.318.03.64.049.967.049 5.523 0 10-4.477 10-10S17.523 2 12 2zm0 2c2.953 0 5.532 1.613 6.918 4h-3.945C14.14 7.38 13.118 7 12 7c-1.897 0-3.526 1.07-4.373 2.627l-2.19-2.19A7.993 7.993 0 0112 4zM5.037 8.074L7 12a5 5 0 005 5c.236 0 .461-.038.69-.07l-1.018 3.054C7.414 19.81 4 16.3 4 12c0-1.427.38-2.765 1.037-3.926zm14.238.615C19.737 9.7 20 10.82 20 12c0 4.294-3.404 7.8-7.654 7.982L15 16h-.027C16.196 15.089 17 13.643 17 12c0-.789-.2-1.525-.525-2.19l2.8-1.12zM12 9a3 3 0 110 6 3 3 0 010-6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChrome;
