import { RenovationDetail } from '@app/libs/apollo/introspection';
import { IconName } from '@app/ui';

export const RenovationDetailsIcons: Record<RenovationDetail, IconName> = {
  [RenovationDetail.DemolitionAndRemoval]: 'LocalShipping',
  [RenovationDetail.ElectricityAndVentilation]: 'ElectricalServices',
  [RenovationDetail.ExteriorOfTheBuilding]: 'HomeWork',
  [RenovationDetail.ExteriorWoodFurnishings]: 'Window',
  [RenovationDetail.FacadeAndExteriorInsulation]: 'ModeCoolOff',
  [RenovationDetail.Flooring]: 'Footprint',
  [RenovationDetail.Roofing]: 'Roofing',
  [RenovationDetail.Heating]: 'Heat',
  [RenovationDetail.InteriorJoinery]: 'Door',
  [RenovationDetail.KitchenAndHouseholdAppliances]: 'Kitchen',
  [RenovationDetail.Conception]: 'Conception',
  [RenovationDetail.MasteosEngineering]: 'Works',
  [RenovationDetail.Paint]: 'Paint',
  [RenovationDetail.Partitioning]: 'Masonry',
  [RenovationDetail.SanitaryAndPlumbing]: 'Plumbing',
  [RenovationDetail.SiteManagement]: 'Worksite',
  [RenovationDetail.TilesAndEarthenware]: 'GridSmall',
  [RenovationDetail.VariousWorks]: 'Category',
};

const renovationDetailsOrder = [
  RenovationDetail.Conception,
  RenovationDetail.MasteosEngineering,
  RenovationDetail.SiteManagement,
  RenovationDetail.DemolitionAndRemoval,
  RenovationDetail.Roofing,
  RenovationDetail.ExteriorWoodFurnishings,
  RenovationDetail.FacadeAndExteriorInsulation,
  RenovationDetail.Partitioning,
  RenovationDetail.Heating,
  RenovationDetail.SanitaryAndPlumbing,
  RenovationDetail.ElectricityAndVentilation,
  RenovationDetail.InteriorJoinery,
  RenovationDetail.Flooring,
  RenovationDetail.Paint,
  RenovationDetail.TilesAndEarthenware,
  RenovationDetail.KitchenAndHouseholdAppliances,
  RenovationDetail.VariousWorks,
  RenovationDetail.ExteriorOfTheBuilding,
];

export const sortRenovationDetails = (a: RenovationDetail, b: RenovationDetail): number => {
  return renovationDetailsOrder.indexOf(a) - renovationDetailsOrder.indexOf(b);
};
