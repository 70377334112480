import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCheck = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9.635 17.642c-.133 0-.258-.02-.375-.063a.871.871 0 01-.325-.212l-4.3-4.3c-.183-.183-.271-.42-.263-.713.009-.29.105-.528.288-.711a.948.948 0 01.7-.276c.283 0 .517.092.7.275l3.575 3.575 8.475-8.475c.183-.183.42-.274.713-.274.291 0 .529.091.712.274.183.184.275.421.275.713s-.092.53-.275.713l-9.2 9.2c-.1.1-.209.17-.325.212a1.098 1.098 0 01-.375.063z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCheck;
