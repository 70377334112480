import React, { useEffect, useRef } from 'react';

import { Animated, Easing } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

import { getStyles } from './LoaderMasteos.styled';
import { LoaderProps } from './Loader.types';

export const LoaderMasteos: React.FC<LoaderProps> = ({ variant = 'inLight' }) => {
  const theme = useTheme();
  const rect1Height = useRef(new Animated.Value(0)).current;
  const rect2Height = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rect1Height, {
          duration: 500,
          easing: Easing.linear,
          toValue: 16,
          useNativeDriver: false,
        }),
        Animated.timing(rect2Height, {
          duration: 500,
          easing: Easing.linear,
          toValue: 16,
          useNativeDriver: false,
        }),
        Animated.parallel([
          Animated.timing(rect1Height, {
            duration: 500,
            easing: Easing.linear,
            toValue: 0,
            useNativeDriver: false,
          }),
          Animated.timing(rect2Height, {
            duration: 500,
            easing: Easing.linear,
            toValue: 0,
            useNativeDriver: false,
          }),
        ]),
      ])
    ).start();
  }, []);

  return (
    <Animated.View style={getStyles(theme).wrapper}>
      <Animated.View style={{ ...getStyles(theme, variant).rect, height: rect2Height }} />
      <Animated.View style={{ ...getStyles(theme, variant).rect, height: rect1Height }} />
    </Animated.View>
  );
};
