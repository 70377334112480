import { Variant as LoaderVariant } from '../../atoms/Loader/Loader.types';
import { ButtonSize, Variant as ButtonVariant } from './Button.types';

export const getButtonFontStyle = (size: ButtonSize) => {
  switch (size) {
    case 'l':
      return 'Button1';
    case 'm':
      return 'Button2';
    case 's':
      return 'Button3';
    default:
      return 'Button3';
  }
};

export const getLoadersMap = (isInDark: boolean) =>
  new Map<ButtonVariant, LoaderVariant>([
    ['primary', 'inDark'],
    ['secondary', isInDark ? 'inLight' : 'inDark'],
    ['tertiary', isInDark ? 'inDark' : 'inLight'],
    ['ghost', isInDark ? 'inDark' : 'inLight'],
    ['danger', 'inDark'],
  ]);
