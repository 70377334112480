import { ApolloQueryResult } from '@apollo/client';

import {
  GetCustomerInfoByEmailDocument,
  GetCustomerInfoByEmailQuery,
} from '@app/libs/apollo/introspection';
import { getApolloClient } from '@app/libs/apollon/apollonClient';

import { GetCustomerInfoByEmail } from './authBridge.types';

/**
 * Check if the email already exists
 * @param email - The email to check
 * @returns False if the email is already registered
 */
export const getCustomerInfoByEmail = async (email = ''): Promise<GetCustomerInfoByEmail> => {
  try {
    const {
      data: { customerInfoByEmail },
    }: ApolloQueryResult<GetCustomerInfoByEmailQuery> = await getApolloClient().query({
      fetchPolicy: 'network-only',
      query: GetCustomerInfoByEmailDocument,
      variables: {
        email: email.toLowerCase().trim(),
      },
    });

    return { customerInfoByEmail };
  } catch (err) {
    return { error: true };
  }
};
