import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMinus = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 13a.968.968 0 01-.713-.288A.967.967 0 015 12a.97.97 0 01.287-.713A.97.97 0 016 11h12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0118 13H6z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMinus;
