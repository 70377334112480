import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgSave = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V8a1 1 0 00-.293-.707l-5-5A1 1 0 0016 2H5zm0 2a1 1 0 00-1 1v14a1 1 0 001 1h1v-7a1 1 0 011-1h10a1 1 0 011 1v7h1a1 1 0 001-1V8.414L15.586 4H8v3h7a1 1 0 110 2H7a1 1 0 01-1-1V4H5zm3 10v6h8v-6H8z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgSave;
