import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMo = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mo_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mo_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28V0H0v20z" fill="#079D7B" />
        <Path
          d="M15.885 8.667c0 .292-.021.575-.061.844a8.86 8.86 0 01.312-.09c1.779-.476 3.366-.319 3.545.352.163.606-.88 1.423-2.395 1.927-.268-.794-1.637-1.033-3.286-1.033-1.65 0-3.021.24-3.287 1.036-1.494-.505-2.518-1.314-2.357-1.915.18-.67 1.767-.828 3.545-.351.093.025.185.051.276.079a5.782 5.782 0 01-.062-.85c0-1.84.844-3.333 1.885-3.333s1.885 1.493 1.885 3.334zM10.667 12h1.166a8.745 8.745 0 01-.564-.13c.215.006.48.024.782.045.548.038 1.221.085 1.949.085s1.4-.047 1.949-.085c.291-.02.547-.037.757-.044a8.667 8.667 0 01-.582.129h3.21c-.159.475-.372.922-.631 1.333H9.297A6.128 6.128 0 018.667 12h2zM14 16c1.347 0 2.584-.5 3.555-1.333h-7.11A5.436 5.436 0 0014 16z"
          fill="#fff"
        />
        <Path
          d="M14 4a.667.667 0 100-1.333A.667.667 0 0014 4zM8.667 7.333a.667.667 0 100-1.333.667.667 0 000 1.333zM20 6.667a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm-2.667-1.334a.667.667 0 100-1.333.667.667 0 000 1.333zm-6-.666a.667.667 0 11-1.333 0 .667.667 0 011.333 0z"
          fill="#FCD117"
        />
      </G>
    </Svg>
  );
};

export default SvgMo;
