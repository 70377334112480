/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

import { TAB_BAR_HEIGHT } from './TabBar.styles';

export const TAB_MAX_WIDTH = 1056;

export const getStyleSheet = ({
  isGreaterThanTablet,
  isLowerThanDesktop,
  isMobile,
  theme,
}: {
  isGreaterThanTablet: boolean;
  isLowerThanDesktop: boolean;
  isMobile: boolean;
  theme: Theme;
}) =>
  StyleSheet.create({
    headerContainer: {
      alignItems: 'center',
      paddingBottom: theme.spacing.SIZE_05,
      width: '100%',
    },
    headerWrapper: {
      alignItems: 'center',
      width: '100%',
    },
    rightElement: {
      alignItems: 'flex-end',
      flex: 1,
      justifyContent: 'center',
      width: '60%',
    },
    tabsBorder: {
      backgroundColor: theme.palette.neutral[200],
      flexDirection: 'row',
      height: 1,
      position: 'sticky' as 'relative',
      top: isLowerThanDesktop ? TAB_BAR_HEIGHT.MOBILE : TAB_BAR_HEIGHT.DESKTOP,
      zIndex: 2,
    },
    tabsContainer: { width: isLowerThanDesktop ? '100%' : '40%' },
    tabsWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: TAB_MAX_WIDTH,
      paddingHorizontal: isMobile
        ? theme.spacing.SIZE_00
        : isGreaterThanTablet
        ? theme.spacing.SIZE_10
        : theme.spacing.SIZE_15,
      position: 'sticky' as 'relative',
      top: 0,
      width: '100%',
      zIndex: 10,
    },
  });
