import React from 'react';

import { StyleSheet, View } from 'react-native';

import { PropertyKind } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import { Chip, Theme, useTheme } from '@app/ui';

import { getTagList } from './get-tag-list.utils';

export type TagListProps = {
  surface: number;
  propertyKind: PropertyKind;
  totalLots: number;
  roomCount?: number;
  grossReturnRate: number;
  netReturnRate: number;
};

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing.SIZE_02,
    },
  });

export const TagList: React.FC<TagListProps> = ({
  surface,
  propertyKind,
  totalLots,
  roomCount,
  grossReturnRate,
  netReturnRate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const chips = getTagList({
    grossReturnRate,
    netReturnRate,
    propertyKind,
    roomCount,
    surface,
    t,
    totalLots,
  });

  return (
    <View style={styles.container}>
      {chips.map((chip, index) => (
        <Chip key={chip.text + index} {...chip} />
      ))}
    </View>
  );
};
