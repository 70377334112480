import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgUser = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 12c-1.1 0-2.042-.392-2.825-1.175C8.392 10.042 8 9.1 8 8s.392-2.042 1.175-2.825C9.958 4.392 10.9 4 12 4s2.042.392 2.825 1.175C15.608 5.958 16 6.9 16 8s-.392 2.042-1.175 2.825C14.042 11.608 13.1 12 12 12zm-7 8a1 1 0 01-1-1v-1.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 015.6 14.55a14.866 14.866 0 013.15-1.163A13.776 13.776 0 0112 13c1.1 0 2.183.129 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563V19a1 1 0 01-1 1H5zm1-2h12v-.8a.943.943 0 00-.137-.5.977.977 0 00-.363-.35c-.9-.45-1.808-.788-2.725-1.013a11.615 11.615 0 00-5.55 0c-.917.225-1.825.563-2.725 1.013a.97.97 0 00-.5.85v.8zm6-8c.55 0 1.021-.196 1.413-.588C13.804 9.021 14 8.55 14 8c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0012 6c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0010 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgUser;
