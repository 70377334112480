import { useState } from 'react';

import { flow, merge, cloneDeep } from 'lodash';

import { Theme } from '@app/ui/theme/types';
import { replaceThemeValue } from '@app/ui/utils/replaceValueInTheme';

export const useConfigTheme = (defaultTheme: Theme) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(defaultTheme);

  const changeTheme = (theme: Theme) =>
    flow(replaceThemeValue, value => merge(cloneDeep(theme), value), setCurrentTheme);

  return {
    changeTheme,
    currentTheme,
    setCurrentTheme,
  };
};
