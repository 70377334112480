import React, { useEffect, useMemo } from 'react';

import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useFlag } from '@unleash/proxy-client-react';

import * as segment from '@app/libs/segment/segment.web';
import { Spacer, Spacing, Text, Theme, TopBar, useResponsive, useTheme } from '@app/ui';
import { TimelineStepName, TimelineStepStatus } from '@app/libs/apollo/introspection';
import { CustomerDropdown } from '@app/shared/components/customer-dropdown/CustomerDropdown';
import {
  MainMenuNavigatorRoutes,
  PublicNavigatorRoutes,
  PublicStackParamList,
} from '@app/navigation/types/routes';
import { ListType } from '@app/ui/molecules/dropdownV2/Dropdown.types';
import { FeatureFlags } from '@app/constants/feature-flags';

import { PublicHomePageStepsWeb } from './PublicHomePageStepsWeb';
import { HomePageNews } from '../homePage/HomePageNews';
import { HomePageGuides } from '../homePage/HomePageGuides';
import { RenovateRealEstatesBlock } from './RenovateRealEstatesBlock';

const getStyles = (theme: Theme, isNativeOrResponsive: boolean, isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: isNativeOrResponsive ? 0 : theme.spacing.SIZE_08,
      paddingHorizontal: isLowerThanDesktop ? theme.spacing.SIZE_06 : theme.spacing.SIZE_09,
    },
    subtitle: { color: theme.palette.neutral[700], width: 591 },
  });

const timeline = {
  steps: [
    {
      name: TimelineStepName.Register,
      status: TimelineStepStatus.Active,
    },
    {
      name: TimelineStepName.Appointment,
      status: TimelineStepStatus.Inactive,
    },
    {
      name: TimelineStepName.Mandate,
      params: null,
      status: TimelineStepStatus.Inactive,
    },
    {
      name: TimelineStepName.FindGem,
      status: TimelineStepStatus.Inactive,
    },
  ],
};

export type PublicHomePageNavigation = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.PublicHome
>;

export const PublicHomePage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigation<PublicHomePageNavigation>();
  const { isNativeOrResponsive, isLowerThanDesktop } = useResponsive();
  const styles = getStyles(theme, isNativeOrResponsive, isLowerThanDesktop);

  const canAccessToRealEstatePage = useFlag(FeatureFlags.publicRealEstateCardLink);
  useEffect(
    () => segment.page('View public home', { canAccessToRealEstatePage }),
    [canAccessToRealEstatePage]
  );

  const options = useMemo(
    () => [
      {
        key: 'login',
        label: t('navigation.publicTopBar.login'),
        onSelect: (closeList: ListType['closeList']) => {
          closeList();
          navigation.navigate(PublicNavigatorRoutes.Login);
        },
      },
      {
        key: 'login',
        label: t('navigation.publicTopBar.subscribe'),
        onSelect: (closeList: ListType['closeList']) => {
          closeList();
          navigation.navigate(PublicNavigatorRoutes.Auth);
        },
      },
    ],
    [navigation, t]
  );

  const rightElement = useMemo(() => <CustomerDropdown optionsOverride={options} />, [options]);

  const items = useMemo(() => {
    return [
      {
        isActive: true,
        key: MainMenuNavigatorRoutes.HomePage,
        label: t('navigation.topBar.homePage'),
        onPress: () => null,
      },
    ];
  }, [t]);

  return (
    <>
      <TopBar rightElement={rightElement} items={items} />
      <Spacer height={Spacing.SIZE_10} />

      <View style={styles.container}>
        <Text textStyle="Title2Medium" testID="publicHomePageHeader">
          {t('publicHomePage.welcome')}
        </Text>
        {!isNativeOrResponsive && (
          <Text textStyle="Headline3" style={styles.subtitle}>
            {t('publicHomePage.subtitle')}
          </Text>
        )}
      </View>

      <PublicHomePageStepsWeb timeline={timeline} />

      <HomePageNews isPublic />

      <RenovateRealEstatesBlock />

      <HomePageGuides />
    </>
  );
};
