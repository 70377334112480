/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui';

export const getStyles = ({
  isLaptop,
  isLowerThanLaptop,
  theme,
  height,
  isMobile,
}: {
  isLaptop: boolean;
  isLowerThanLaptop: boolean;
  theme: Theme;
  height: number;
  isMobile: boolean;
}) =>
  StyleSheet.create({
    address: {
      textAlign: isLowerThanLaptop ? 'center' : 'left',
    },
    container: {
      flex: 1,
      flexDirection: isLowerThanLaptop ? 'column-reverse' : 'column',
      gap: theme.spacing.SIZE_04,
      maxWidth: 1120,
      paddingTop: isLowerThanLaptop ? 0 : theme.spacing.SIZE_08,
      width: '100%',
      ...(!isLowerThanLaptop && {
        paddingHorizontal: isLaptop ? theme.spacing.SIZE_11 : theme.spacing.SIZE_14,
      }),
    },
    coverImage: {
      flex: 1,
      ...(!isLowerThanLaptop && {
        borderBottomLeftRadius: theme.borderRadius.SIZE_06,
        borderColor: theme.palette.base.white,
        borderTopLeftRadius: theme.borderRadius.SIZE_06,
        borderWidth: 1,
      }),
    },
    coverImageContainer: {
      flexDirection: 'row',
      height: isLaptop ? height / 3 : height / 2,
      maxHeight: 350,
      overflow: 'hidden',
      ...(isMobile && {
        height: 320,
      }),
    },
    imageCell: {
      borderColor: theme.palette.base.white,
      borderWidth: 1,
      height: '50%',
      width: '50%',
    },
    imagesGrid: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    infoContainer: {
      gap: theme.spacing.SIZE_04,
      ...(isLowerThanLaptop && {
        alignItems: 'stretch',
        gap: theme.spacing.SIZE_07,
        padding: theme.spacing.SIZE_04,
      }),
      ...(isMobile && {
        gap: theme.spacing.SIZE_04,
      }),
    },
    title: {
      marginLeft: isLaptop ? theme.spacing.SIZE_10 : 0,
      textAlign: isLowerThanLaptop ? 'center' : 'left',
    },
    titleAndActionButtons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 12,
    },
    titleContainer: {
      flex: 1,
      gap: isLowerThanLaptop ? 0 : theme.spacing.SIZE_04,
      ...(isLowerThanLaptop && {
        alignItems: 'center',
      }),
    },
  });

export const getGridImageCellStyle = (index: number, theme: Theme) => ({
  ...(index === 1 && { borderTopRightRadius: theme.borderRadius.SIZE_06 }),
  ...(index === 3 && { borderBottomRightRadius: theme.borderRadius.SIZE_06 }),
});
