/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { ImageBackground, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { ActionButton, BaseLink, TEXT_STYLES, Text, Theme, useTheme } from '@app/ui';
import { openURL } from '@app/utils/browser';
import { trackHomePageGuidesArticle } from '@app/services/tracking/trackTracking';

type GuideProps = {
  title: string;
  showButton?: boolean;
  variant: 'l' | 'm' | 's' | 'xs';
  link: string;
  img: string;
  indexGuide: number;
};

const getStyles = (width: string, theme: Theme, height: number) =>
  StyleSheet.create({
    actionButton: { marginTop: theme.spacing.SIZE_04, width: 160 },
    flex: { flex: 1 },
    imageBackground: { height, width: '100%' },
    title: {
      color: theme.palette.base.white,
    },
    titleWrapper: { flex: 1, justifyContent: 'flex-end', padding: theme.spacing.SIZE_08 },
    wrapper: { borderRadius: theme.borderRadius.SIZE_08, overflow: 'hidden', width },
  });

const getSizeAndText = (
  variant: GuideProps['variant']
): { height: number; titleVariant: keyof typeof TEXT_STYLES; width: string } => {
  switch (variant) {
    case 'm':
      return { height: 400, titleVariant: 'Title3Medium', width: '100%' };
    case 's':
      return { height: 450 / 2 - 10, titleVariant: 'Headline3Medium', width: '100%' };
    case 'xs':
      return { height: 450 / 2 - 10, titleVariant: 'Headline3Medium', width: 'auto' };
    default:
      return { height: 450, titleVariant: 'Title3Medium', width: '100%' };
  }
};

export const Guide = ({
  title,
  variant,
  showButton,
  link,
  img,
  indexGuide,
}: GuideProps): JSX.Element => {
  const { width, height, titleVariant } = getSizeAndText(variant);

  const theme = useTheme();
  const styles = getStyles(width, theme, height);

  return (
    <View style={styles.wrapper}>
      <BaseLink
        href={link}
        onClick={() => {
          trackHomePageGuidesArticle(indexGuide);
          openURL(link);
        }}
      >
        <ImageBackground source={{ uri: img }} style={styles.imageBackground}>
          <LinearGradient
            colors={['transparent', 'rgba(0,0,0,0.5)']}
            end={{ x: 0, y: 1 }}
            start={{ x: 0, y: 0 }}
            style={styles.flex}
          >
            <View style={styles.titleWrapper}>
              <Text textStyle={titleVariant} style={styles.title}>
                {title}
              </Text>

              {!!showButton && (
                <View style={styles.actionButton}>
                  <ActionButton
                    rightIconName="ArrowRight"
                    label="Lire l'article"
                    size="l"
                    onPress={() => {
                      trackHomePageGuidesArticle(indexGuide);
                      openURL(link);
                    }}
                  />
                </View>
              )}
            </View>
          </LinearGradient>
        </ImageBackground>
      </BaseLink>
    </View>
  );
};
