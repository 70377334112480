import React, { useMemo } from 'react';

import { Platform, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Icon, Pressable, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { SliderImageObject } from '../gallery.types';

interface GalleryInformationsProps {
  currentImage: SliderImageObject | undefined;
  currentIndex: number;
  slideLength: number;
  open3dUrl?: () => void;
}

const getStyles = ({ isLowerThanLaptop, theme }: { isLowerThanLaptop: boolean; theme: Theme }) =>
  StyleSheet.create({
    imageCounter: {
      backgroundColor: theme.palette.system.secondary[400],
      borderRadius: theme.spacing.SIZE_02,
      justifyContent: 'center',
      opacity: 0.8,
      paddingHorizontal: theme.spacing.SIZE_03,
      ...(!isLowerThanLaptop && { height: 24 }),
    },
    infosGallery: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: isLowerThanLaptop ? theme.spacing.SIZE_06 : 0,
    },
    rightInfoGallery: {
      alignItems: 'flex-end',
      flexDirection: isLowerThanLaptop ? 'column' : 'row-reverse',
      gap: theme.spacing.SIZE_03,
      opacity: 0.8,
    },
    visit3dButton: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      borderRadius: 4,
      flexDirection: 'row',
      gap: theme.spacing.SIZE_04,
      ...(!isLowerThanLaptop && { height: 24 }),
      justifyContent: 'center',
      paddingHorizontal: theme.spacing.SIZE_03,
    },
    whiteText: {
      color: theme.palette.base.white,
    },
  });

export const GalleryInformations: React.FC<GalleryInformationsProps> = ({
  currentImage,
  currentIndex,
  slideLength,
  open3dUrl,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles({ isLowerThanLaptop, theme }), [theme, isLowerThanLaptop]);

  /* 
    There is a bug in the base react native modal with the last version of RNGH,
    A Pressable within a modal doesn't work on iOS, the onPress callback isn't called.
    https://www.reddit.com/r/reactnative/comments/15i5bwp/pressable_not_working_inside_react_native_modal/
  */
  const Button = Platform.OS !== 'ios' ? Pressable : TouchableOpacity;

  return (
    <View style={styles.infosGallery}>
      <Text textStyle="Headline2Medium" style={styles.whiteText}>
        {currentImage?.title && currentImage.title !== 'coverImage' ? t(currentImage.title) : ' '}
      </Text>

      <View style={styles.rightInfoGallery}>
        {!!open3dUrl && (
          <Button style={styles.visit3dButton} onPress={open3dUrl}>
            <Icon name="Cube" size={16} color={theme.palette.neutral[900]} />
            <Text textStyle="Body2Medium" style={{ color: theme.palette.neutral[900] }}>
              {t('shared.virtualVisit')}
            </Text>
          </Button>
        )}

        <View style={!isLowerThanLaptop && styles.imageCounter}>
          <Text textStyle="Body2" style={styles.whiteText}>
            {currentIndex + 1}/{slideLength}
          </Text>
        </View>
      </View>
    </View>
  );
};
