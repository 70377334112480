import React from 'react';

import { useForm, useFormContext } from 'react-hook-form';

import { useTranslation } from '@app/services/translations/translations';
import { TrileanController } from '@app/ui';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import { Trilean } from '@app/ui/molecules/trilean-controller/TrileanController.types';

import { FilterForm } from '../Filters.type';
import { getRadioLabels } from '../utils/filterForm.utils';
import { FilterFormContainer } from './FilterFormContainer';
import { useCountResults } from '../hooks/useCountResults.hook';

type TrileanFilterKeys = keyof {
  [K in keyof SearchEngineFilterForm as SearchEngineFilterForm[K] extends Trilean ? K : never]: K;
};
type TrileanFilterFormProps = FilterForm & {
  field: TrileanFilterKeys;
};

export const TrileanFilterForm: React.FC<TrileanFilterFormProps> = ({ goBack, field }) => {
  const { t } = useTranslation();
  const radioLabels = getRadioLabels(t);

  const { getValues: getGlobalValues, setValue } = useFormContext<SearchEngineFilterForm>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    values: { [field]: getGlobalValues(field) },
  });

  const onApply = (form: { [x: string]: Trilean }) => {
    const value = form[field];
    setValue(field, value === undefined ? null : value);
    goBack();
  };

  const onReset = () => {
    reset();
  };

  const { count, loading } = useCountResults(watch());

  return (
    <FilterFormContainer
      title={t(`realEstate.filters.${field}.title`)}
      canApply={isValid}
      resultCount={count}
      loading={loading}
      onApply={handleSubmit(onApply)}
      onBack={goBack}
      onReset={onReset}
    >
      <TrileanController
        testID={`search-engine-filter-${field}--trilean`}
        control={control as any}
        name={field}
        radioLabels={radioLabels}
      />
    </FilterFormContainer>
  );
};
