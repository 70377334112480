import React from 'react';

import { Animated, View } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';

import { getStyle } from './TabLabel.styles';
import { TabLabelType } from './TabLabel.types';
import { Pressable } from '../pressable/Pressable';
import { Text } from '../Text/Text';

export { TabLabelType };

export const TabLabel: React.FC<TabLabelType> = ({ isActive = false, title, onPress }) => {
  const theme = useTheme();

  const { isLowerThanDesktop } = useResponsive();

  const styles = getStyle({ isActive, isLowerThanDesktop, theme });

  return (
    <View style={styles.tabLabel}>
      <Animated.View style={!!isActive && styles.animatedBorder} testID="animatedBorder" />
      <Pressable onPress={onPress}>
        <Text
          textStyle={isActive ? 'Body2Medium' : 'Body2'}
          style={styles.label}
          numberOfLines={1}
          testID="TabLabel"
        >
          {title}
        </Text>
      </Pressable>
    </View>
  );
};
