import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getTableCellStyle = (theme: Theme) =>
  StyleSheet.create({
    renderCell: {
      borderBottomWidth: theme.spacing.SIZE_00,
      borderColor: theme.palette.neutral[200],
      borderStyle: 'solid',
      display: 'flex',
      justifyContent: 'center',
      paddingHorizontal: theme.spacing.SIZE_03,
      paddingVertical: theme.spacing.SIZE_05,
    },
    row: {
      alignItems: 'center',
      borderBottomWidth: theme.spacing.SIZE_00,
      borderColor: theme.palette.neutral[200],
      borderStyle: 'solid',
      display: 'flex',
      paddingHorizontal: theme.spacing.SIZE_03,
      paddingVertical: theme.spacing.SIZE_05,
    },
  });
