import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgAndroid = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M1 18c.15-1.783.696-3.425 1.638-4.925A10.397 10.397 0 0 1 6.4 9.5L4.55 6.3a.54.54 0 0 1-.075-.475.602.602 0 0 1 .325-.375.527.527 0 0 1 .45-.05c.167.05.3.15.4.3L7.5 8.9C8.933 8.3 10.433 8 12 8c1.567 0 3.067.3 4.5.9l1.85-3.2c.1-.15.233-.25.4-.3a.527.527 0 0 1 .45.05.601.601 0 0 1 .325.375.54.54 0 0 1-.075.475L17.6 9.5a10.397 10.397 0 0 1 3.762 3.575A10.838 10.838 0 0 1 23 18H1Zm6-2.75c.35 0 .646-.12.888-.363.241-.241.362-.537.362-.887s-.12-.646-.362-.887A1.207 1.207 0 0 0 7 12.75c-.35 0-.646.12-.888.363A1.207 1.207 0 0 0 5.75 14c0 .35.12.646.362.887.242.242.538.363.888.363Zm10 0c.35 0 .646-.12.887-.363.242-.241.363-.537.363-.887s-.12-.646-.363-.887A1.207 1.207 0 0 0 17 12.75c-.35 0-.646.12-.887.363a1.207 1.207 0 0 0-.363.887c0 .35.12.646.363.887.241.242.537.363.887.363Z"
    />
  </Svg>
);
export default SvgAndroid;
