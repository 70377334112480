import { StyleSheet } from 'react-native';

export const getStyles = () => {
  return StyleSheet.create({
    confirmButton: {
      flexShrink: 0,
    },
    scrollView: {
      flexGrow: 0,
    },
  });
};
