import React from 'react';

import { ExpandableGroup } from '@app/ui';
import { ProjectDetailSubStep } from '@app/features/project/ProjectDetail/ProjectDetailSubStep';
import { ProjectDetailSubStepsProps } from '@app/features/project/ProjectDetail/ProjectDetail.type';

export const ProjectDetailSubSteps: React.FC<ProjectDetailSubStepsProps> = ({
  onExpandSubStep,
  status,
  subSteps,
}) => {
  if (!subSteps) {
    return null;
  }

  return (
    <ExpandableGroup allowMultipleExpand>
      {subSteps.map((subStep, index) => (
        <ProjectDetailSubStep
          key={subStep.title}
          {...subStep}
          status={status}
          isLast={index + 1 === subSteps.length}
          onExpand={onExpandSubStep(index + 1)}
        />
      ))}
    </ExpandableGroup>
  );
};
