import React from 'react';

export enum BannerKey {
  SignMandateInApp = 'SignMandateInApp',
}

export type BannerContextProps = {
  openBanner?: (
    key: BannerKey,
    Component: React.ReactNode,
    options?: {
      callbackClose?: (params?: unknown) => void;
    }
  ) => void;
  closeBanner?: (params?: unknown) => void;
  isDisplayed?: boolean;
  component?: React.ReactNode;
};
