import { useEffect, useState } from 'react';

import { storage } from '@app/services/storage/storage';

export const useToken = (): string => {
  const [token, setToken] = useState('');

  const readTokenFromLocalStorage = () => {
    storage.readString('token').then(localToken => {
      setToken(localToken || '');
    });
  };

  useEffect(() => {
    readTokenFromLocalStorage();
  }, []);

  return token;
};
