import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgContact = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 14c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0012 10c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0010 12c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588zm-4 4h8v-.575a1.993 1.993 0 00-1.225-1.85 7.569 7.569 0 00-1.337-.425C12.979 15.05 12.5 15 12 15s-.98.05-1.438.15a7.57 7.57 0 00-1.337.425A1.993 1.993 0 008 17.425V18zm10 4H6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 016 2h7.175a1.978 1.978 0 011.4.575l4.85 4.85a1.978 1.978 0 01.575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 22zm0-2V8.85L13.15 4H6v16h12z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgContact;
