import { useMemo } from 'react';

import * as yup from 'yup';
import { Control, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PN from 'awesome-phonenumber';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useMe } from '@app/shared/hooks/useMe';
import { combinePhone, parsePhoneNumber } from '@app/utils/phone';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';
import { ProfileNavigatorRoutes, ProfileStackParamList } from '@app/navigation/types/routes';
import { fromS3ID } from '@app/utils/from-s3id';
import { isNative } from '@app/constants/platform';

interface ProfileInformationForm {
  avatarS3ID: string;
  email: string;
  firstName: string;
  lastName: string;
  phonePrefix: string;
  phoneSuffix: string;
  phoneNumber: string;
  promoCode: string;
}

interface UseProfileInformationOutput {
  control: Control<ProfileInformationForm, unknown>;
  onSubmit: () => Promise<void>;
  isSaving: boolean;
  isAssetManagementConsultant: boolean;
}

type UseProfileInformationNavigationProp = StackNavigationProp<
  ProfileStackParamList,
  ProfileNavigatorRoutes.ProfileInformation
>;

export const useProfileInformation = (): UseProfileInformationOutput => {
  const { t } = useTranslation();
  const navigation = useNavigation<UseProfileInformationNavigationProp>();
  const { customer, updateInformations, updateInformationsLoading, isAssetManagementConsultant } =
    useMe();
  const { setValidMsg, setErrorMsg } = useToastContext();
  const { phonePrefix: phonePrefixInit, phoneSuffix: phoneSuffixInit } = useMemo(
    () => parsePhoneNumber(customer.phoneNumber),
    [customer.phoneNumber]
  );

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('register.requiredField')).label(t('shared.firstName')),
    lastName: yup.string().required(t('register.requiredField')).label(t('shared.lastName')),
    phoneSuffix: yup
      .string()
      .nullable()
      .test(
        'phoneSuffix',
        t('profile.phoneNumber'),
        (phoneSuffix, ctx) =>
          phoneSuffix === '' || new PN(ctx.parent.phonePrefix + phoneSuffix).isValid()
      ),
  });

  const { control, handleSubmit } = useForm<ProfileInformationForm>({
    defaultValues: {
      avatarS3ID: customer.account.avatarS3ID ? fromS3ID(customer.account.avatarS3ID) : '',
      email: customer.email || '',
      firstName: customer.firstName || '',
      lastName: customer.lastName,
      phonePrefix: phonePrefixInit,
      phoneSuffix: phoneSuffixInit,
      promoCode: customer.account.promoCode ?? '',
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: ProfileInformationForm) => {
    try {
      const { phonePrefix, phoneSuffix, ...form } = data;
      await updateInformations({
        ...form,
        phoneNumber: phoneSuffix ? combinePhone(phonePrefix, phoneSuffix) : undefined,
      });
      setValidMsg({ icon: 'Check', title: t('profile.updateProfileMessage'), withClose: true });
      if (isNative()) {
        navigation.navigate(ProfileNavigatorRoutes.ProfileRoot);
      }
    } catch (e) {
      ErrorMonitoring.logError(e as Error);
      setErrorMsg({
        title: t('shared.genericError'),
      });
    }
  };

  return {
    control,
    isAssetManagementConsultant,
    isSaving: updateInformationsLoading,
    onSubmit: handleSubmit(onSubmit),
  };
};
