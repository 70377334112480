interface PaginationInfoResult {
  limit: number;
  loadMoreButton: number;
}

const MAX_PROPERTY_PER_PAGE = 24;
export const ADD_SOLD_PROPERTY_EVERY = 5;
export const LIMIT_PER_PAGE =
  (MAX_PROPERTY_PER_PAGE * ADD_SOLD_PROPERTY_EVERY) / (ADD_SOLD_PROPERTY_EVERY + 1);

export const getInfoPerTotalResult = (totalResults?: number): PaginationInfoResult => {
  return {
    limit: LIMIT_PER_PAGE,
    loadMoreButton: totalResults ? Math.max(Math.ceil(totalResults / LIMIT_PER_PAGE) - 1, 0) : 0,
  };
};
