import { palette, Spacing } from '@app/ui';
import { BOTTOM_BAR_MENU_HEIGHT } from '@app/ui/organisms/BottomBarMenu/bottomBarMenu.theme';

const defaultButtonWidth = 250;
const parentSpacing = 40;
const parentMobileSpacing = 20;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyle = ({
  columnsNb,
  isLowerThanTablet,
}: {
  columnsNb: number;
  isLowerThanTablet: boolean;
}) => ({
  container: {
    width: '100%',
  },
  enlightenmentContainer: {
    marginLeft: isLowerThanTablet ? -parentMobileSpacing : -parentSpacing,
    marginRight: isLowerThanTablet ? -parentMobileSpacing : -parentSpacing,
    marginTop: -400 - Spacing.SIZE_02,
    paddingTop: Spacing.SIZE_02,
  },
  enlightenmentWrapper: {
    backgroundImage: `linear-gradient(rgba(255,255,255,0), ${palette.base.white}, ${palette.base.white} 66%)`,
    paddingBottom: isLowerThanTablet ? BOTTOM_BAR_MENU_HEIGHT : 0,
    paddingLeft: isLowerThanTablet ? parentMobileSpacing : 0,
    paddingRight: isLowerThanTablet ? parentMobileSpacing : 0,
  },
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    minHeight: 48,
  },
  seeMoreButton: {
    maxWidth: columnsNb === 1 ? 'none' : defaultButtonWidth,
    width: '100%',
  },
  seeMoreWrapper: {
    alignItems: 'center',
    marginBottom: 68,
    paddingTop: Spacing.SIZE_09,
  },
  specificCard: { alignItems: 'center', alignSelf: 'center' },
  wrapper: {
    columnGap: Spacing.SIZE_07,
    display: 'grid',
    gridAutoFlow: 'dense',
    gridTemplateColumns: `repeat(${columnsNb}, minmax(0, 1fr))`,
    overflow: 'visible',
    rowGap: isLowerThanTablet ? Spacing.SIZE_08 : Spacing.SIZE_09,
    width: '100%',
  },
});
