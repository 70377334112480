import React, { useCallback, useEffect, useRef } from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';

import { LoaderMasteos } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { useAfterLogin } from '@app/shared/hooks/useAfterLogin';
import { storage } from '@app/services/storage/storage';
import { useTranslation } from '@app/services/translations/translations';
import { isWeb } from '@app/constants/platform';

import { MainMenuNavigatorRoutes, RealEstateNavigatorRoutes, Routes } from './types/routes';
import { setStatusBarStyleForRoute } from './utils/statusBar.utils';
import { privateLinking, publicLinking } from './constants/linking';
import { PrivateNavigator } from './PrivateNavigator';
import { PublicNavigator } from './PublicNavigator';
import { navigationRef } from './navigationRef';
import { launchTracking } from './utils/trackChangePage.utils';
import { getDocumentTitleOptions } from './constants/document-title';

const styles = StyleSheet.create({
  loader: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  },
});

export const RootNavigator: React.FC = () => {
  const { customer, loading } = useMe();
  const routeNameRef = useRef<Routes>();
  useAfterLogin(customer, loading);
  const { t } = useTranslation();

  const onStateChange = useCallback(() => {
    launchTracking(routeNameRef, navigationRef);
    routeNameRef.current = navigationRef.getCurrentRoute()?.name as Routes;
    setStatusBarStyleForRoute(routeNameRef.current);
  }, []);

  useEffect(() => {
    const restoreState = async () => {
      const savedStateString = (await storage.readObject('navigation_state')) as string;
      const state = savedStateString ? JSON.parse(savedStateString) : undefined;

      if (!state) {
        return;
      }

      const redirectTo = state.routes.find(route => route.params?.redirectTo);

      if (!redirectTo || !navigationRef.isReady()) {
        return;
      }

      const { redirectTo: redirectToName } = redirectTo.params;
      const computedRedirectToName = redirectToName.split('/')[0];

      if (computedRedirectToName === 'Property') {
        const realEstateId = redirectToName.split('/')[1]?.split('?')[0];
        if (realEstateId) {
          await storage.clear('navigation_state');
          return navigationRef.current?.navigate(RealEstateNavigatorRoutes.RealEstate, {
            id: realEstateId,
          });
        }
      }

      await storage.clear('navigation_state');

      const isRouteInStack = Object.values(MainMenuNavigatorRoutes).includes(
        computedRedirectToName as MainMenuNavigatorRoutes
      );

      if (isRouteInStack) {
        navigationRef.current?.navigate(
          MainMenuNavigatorRoutes[computedRedirectToName as MainMenuNavigatorRoutes]
        );
      }
    };

    if (!isWeb()) {
      return;
    }

    const timeoutId = setTimeout(() => {
      restoreState();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [routeNameRef.current]);

  if (loading) {
    return (
      <View style={styles.loader}>
        <LoaderMasteos />
      </View>
    );
  }

  return (
    <NavigationContainer
      linking={customer ? privateLinking : publicLinking}
      ref={navigationRef}
      onReady={onStateChange}
      documentTitle={getDocumentTitleOptions(t)}
      onStateChange={onStateChange}
      fallback={<LoaderMasteos />}
    >
      {customer ? <PrivateNavigator customer={customer} /> : <PublicNavigator />}
    </NavigationContainer>
  );
};
