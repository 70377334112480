import React, { createContext, PropsWithChildren, useState } from 'react';

import { ExpandableId } from './ExpandableGroup.types';

export interface ExpandableGroupProps {
  allowMultipleExpand?: boolean;
  initialExpandedId?: ExpandableId;
}

interface ExpandableGroupContextProps {
  initialExpandedId?: ExpandableId;
  onExpand: (id?: ExpandableId) => void;
  shouldCollapse: (id?: ExpandableId) => boolean;
}

export const ExpandableGroupContext = createContext<ExpandableGroupContextProps>({
  onExpand: () => {},
  shouldCollapse: () => false,
});

/**
 * @description ExpandableGroup wrapper component controls the nested Expandable components sending the shouldCollapse property through the context.
 * @param {boolean} [allowMultipleExpand=false] - If true, allow multiple Expandable children to expand, if false should collapse rule will apply.
 * @param {ExpandableId} [initialExpandedId] - ID of the expandable to be expanded by default.
 * @returns {ExpandableGroup}
 */
export const ExpandableGroup: React.FC<PropsWithChildren<ExpandableGroupProps>> = ({
  children,
  initialExpandedId,
  allowMultipleExpand,
}) => {
  const [activeId, setActiveId] = useState(initialExpandedId);

  const shouldCollapse = (expandableId?: ExpandableId) => {
    return !allowMultipleExpand && expandableId !== activeId;
  };

  const onExpand = setActiveId;
  const value = {
    initialExpandedId,
    onExpand,
    shouldCollapse,
  };

  return (
    <ExpandableGroupContext.Provider value={value}>{children}</ExpandableGroupContext.Provider>
  );
};
