import React, { ReactElement } from 'react';

import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { Button, Text, Theme, getColorWithOpacity, useResponsive, useTheme } from '@app/ui';
import { currencyFormatter } from '@app/utils/currency-formatter';

interface TablePreviewProps<T> {
  totalLabel: string;
  total: number;
  data: T[];
  renderItem: (item: T, index: number) => ReactElement;
  withGradient?: boolean;
  title?: string;
  onViewDetailsPress?: () => void;
  buttonLabel?: string;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderColor: theme.palette.neutral[100],
      borderRadius: theme.borderRadius.SIZE_07,
      borderWidth: 1,
      overflow: 'hidden',
    },
    gradient: {
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 2,
    },
    item: {
      borderBottomWidth: 1,
      borderColor: theme.palette.neutral[100],
      paddingVertical: theme.spacing.SIZE_05,
    },
    itemsContainer: {
      marginBottom: theme.spacing.SIZE_05,
      paddingHorizontal: theme.spacing.SIZE_05,
    },
    titleContainer: {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.palette.neutral[100],
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing.SIZE_05,
    },
    total: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[800],
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing.SIZE_05,
    },
    whiteText: {
      color: theme.palette.base.white,
    },
  });

export const TablePreview = <T,>({
  title,
  data,
  total,
  totalLabel,
  renderItem,
  buttonLabel,
  onViewDetailsPress,
  withGradient = false,
}: TablePreviewProps<T>): ReactElement => {
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = getStyles(theme);

  const showHeader = !!title || (!!onViewDetailsPress && !!buttonLabel);

  return (
    <View style={styles.container}>
      {!!showHeader && (
        <View style={styles.titleContainer}>
          {!!title && <Text textStyle="Body1Medium">{title}</Text>}

          {!!onViewDetailsPress && !!buttonLabel && (
            <Button
              variant="secondary"
              size="s"
              onPress={onViewDetailsPress}
              iconName="ArrowRight"
              iconPlacement="right"
            >
              {buttonLabel}
            </Button>
          )}
        </View>
      )}

      <View style={styles.itemsContainer}>
        {data.map((item, index) => (
          <View key={index} style={styles.item}>
            {renderItem(item, index)}
          </View>
        ))}

        {!!withGradient && (
          <LinearGradient
            colors={[getColorWithOpacity(theme.palette.base.white, 0), theme.palette.base.white]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 0, y: 1 }}
            style={styles.gradient}
          />
        )}
      </View>

      <View style={styles.total}>
        <Text
          textStyle={isLowerThanLaptop ? 'Body2Medium' : 'Headline3Medium'}
          style={styles.whiteText}
        >
          {totalLabel}
        </Text>
        <Text
          textStyle={isLowerThanLaptop ? 'Body2Medium' : 'Headline3Medium'}
          style={styles.whiteText}
        >
          {currencyFormatter(total, 0)}
        </Text>
      </View>
    </View>
  );
};
