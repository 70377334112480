import { TFunction } from 'react-i18next';

import { CalloutProps, Theme } from '@app/ui';
import {
  GetMyProjectsQuery,
  PositioningStatus,
  PropertyStatuses,
  RealEstate,
} from '@app/libs/apollo/introspection';
import { fromS3ID } from '@app/utils/from-s3id';

export const getTransactionCalloutProps = ({
  realEstate,
  projects,
  loadingProjects,
  t,
}: {
  realEstate: RealEstate;
  projects: GetMyProjectsQuery['myProjects'];
  loadingProjects: boolean;
  t: TFunction;
}): { text: string | null } => {
  if (realEstate.status !== PropertyStatuses.Transaction || loadingProjects) {
    return null;
  }

  if (
    !loadingProjects &&
    !projects.find(({ realEstate: projectRealEstate }) => projectRealEstate.id === realEstate.id)
  ) {
    return {
      text: t('propertyInfo.alert.inTransactionDesc'),
    };
  }

  return {
    text: t('propertyInfo.alert.inTransactionDescUser'),
  };
};

export const getPositioningCalloutList = ({
  t,
  theme,
  expiredText,
  isOwnAdvisor,
  phoneNumber = '',
  firstName = '',
  lastName = '',
  avatarS3ID,
  isGreaterThanTablet,
}: {
  t: TFunction;
  theme?: Theme;
  expiredText?: string;
  isOwnAdvisor?: boolean;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  avatarS3ID?: string;
  isGreaterThanTablet?: boolean;
}): Partial<Record<PositioningStatus, CalloutProps>> => ({
  [PositioningStatus.Recommendation]: {
    icon: 'Clock',
    text: expiredText,
    tint: theme.palette.tint.gray,
  },
  [PositioningStatus.Pending]: {
    avatarUrl: fromS3ID(avatarS3ID) || '',
    ...(!avatarS3ID && { icon: 'Phone' }),
    text: `${t(
      isOwnAdvisor
        ? 'propertyInfo.positioning.PendingAdvisor'
        : 'propertyInfo.positioning.PendingExpert',
      { firstName, lastName }
    )}${!isGreaterThanTablet ? '\n' : ''} ${phoneNumber}`,
  },
  [PositioningStatus.Confirmed]: { icon: 'Info', text: t('propertyInfo.positioning.Confirmed') },
  [PositioningStatus.Cancelled]: {
    icon: 'HeartBroken',
    text: t('propertyInfo.positioning.Cancelled'),
  },
  [PositioningStatus.PrePositioningCancelled]: {
    icon: 'HeartBroken',
    text: t('propertyInfo.positioning.PrePositioningCancelled'),
  },
});
