import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Avatar, Enlightenment, EnlightenmentProps, Icon, Text, Theme, useTheme } from '@app/ui';
import { fromS3ID } from '@app/utils/from-s3id';
import { useTranslation } from '@app/services/translations/translations';

const iconList = ['conseiller3', 'conseiller', 'conseiller2'].map(icon => (
  <Avatar key={`icon_${icon}`} avatarUrl={fromS3ID(`/production/assets/${icon}.png`, 'ar1-1')} />
));

const getStyles = (theme: Theme) => StyleSheet.create({ gap: { gap: theme.spacing.SIZE_07 } });

export const AdvisorEnlightenment: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const itemList = useMemo<EnlightenmentProps['itemList']>(
    () => [
      {
        description: t('advisor.enlightenment.1.description'),
        iconList,
        title: t('advisor.enlightenment.1.title'),
      },
      {
        description: t('advisor.enlightenment.2.description'),
        iconList: [<Icon key="icon_new" name="New" />],
        title: t('advisor.enlightenment.2.title'),
      },
      {
        description: t('advisor.enlightenment.3.description'),
        iconList: [<Icon key="icon_zap" name="Zap" />],
        title: t('advisor.enlightenment.3.title'),
      },
    ],
    [t]
  );

  return (
    <View style={styles.gap}>
      <Text textStyle="Headline1Medium">{t('advisor.enlightenment.title')}</Text>
      <Enlightenment itemList={itemList} />
    </View>
  );
};
