import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKp = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="kp_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#kp_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 4h28V0H0v4zM0 20h28v-4H0v4z" fill="#1461B6" />
        <Path d="M0 16h28V4H0v12z" fill="#fff" />
        <Path d="M0 14.667h28V5.333H0v9.334z" fill="#F53846" />
        <Path
          d="M13.333 10A3.333 3.333 0 1110 6.667l-.786 2.25-2.384.053 1.898 1.443-.687 2.284L10 11.337l1.96 1.36-.688-2.284L13.17 8.97l-2.384-.052L10 6.667A3.333 3.333 0 0113.333 10z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgKp;
