import * as yup from 'yup';

import { formatCurrency } from '@masteos/agora';

import { MAX_GRAPHQL_INT } from '@app/constants/constants';

import { BudgetRules } from '../searchEngineFilters.type';

export const getBudgetMinRules = (
  getValues: (name: string) => number | null,
  t: (key: string) => string
): BudgetRules => ({
  validate: {
    lessThanMax: v =>
      v === null ||
      (getValues('budgetMax') === null && v === null) ||
      v <= (getValues('budgetMax') ?? 0) ||
      t('propertiesPreferences.formErrors.budgetMinSupMax'),
    positive: v => v === null || v >= 0 || t('searchEngine.filter.errorMessage.mustBePositive'),
  },
});

export const getBudgetMaxRules = (
  capacity: number,
  t: (key: string, options?: { [k: string]: string }) => string
): BudgetRules => ({
  validate: {
    lessThanCapacity: v =>
      !capacity ||
      v <= capacity ||
      t('propertiesPreferences.formErrors.budgetMaxSupPostMandate', {
        budget: formatCurrency(capacity),
      }),
    maximum: v => v <= MAX_GRAPHQL_INT || t('searchEngine.filter.errorMessage.mustBeValid'),
    positive: v => v === null || v >= 0 || t('searchEngine.filter.errorMessage.mustBePositive'),
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getBudgetFormSchema = (
  capacity: number,
  t: (key: string, options?: { [k: string]: string }) => string
) =>
  yup
    .object()
    .shape({
      budgetMax: yup
        .number()
        .nullable()
        .min(0, t('searchEngine.filter.errorMessage.mustBePositive'))
        .max(MAX_GRAPHQL_INT)
        .test({
          message: t('propertiesPreferences.formErrors.budgetMaxSupPostMandate', {
            budget: formatCurrency(capacity),
          }),
          name: 'lessThanCapacity',
          test: v => !capacity || (typeof v === 'number' && v <= capacity),
        }),
      budgetMin: yup
        .number()
        .nullable()
        .min(0, t('searchEngine.filter.errorMessage.mustBePositive')),
    })
    .test({
      message: t('propertiesPreferences.formErrors.budgetMinSupMax'),
      name: 'lessThanMax',
      test: (v, ctx) => {
        if ((v.budgetMin ?? 0) > (v.budgetMax ?? 0)) {
          return ctx.createError({
            message: t('propertiesPreferences.formErrors.budgetMinSupMax'),
            path: 'budgetMin',
          });
        }
        return true;
      },
    });
