import React from 'react';

import { Controller } from 'react-hook-form';

import { Button, Input, PhoneInput, Spacer, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import * as S from '@app/features/profile/profile.styles.web';
import { useProfileInformation } from '@app/features/profile/hooks/useProfileInformation';

export const ProfileInformation: React.FC = () => {
  const { t } = useTranslation();
  const { control, onSubmit, isSaving } = useProfileInformation();
  const theme = useTheme();

  return (
    <S.FormWrapper>
      <S.Form>
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={t('shared.lastName')}
              value={value}
              onChangeText={onChange}
              error={error?.message}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={t('shared.firstName')}
              value={value}
              onChangeText={onChange}
              error={error?.message}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('shared.email')}
              value={value}
              onChangeText={onChange}
              editable={false}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />

        <Controller
          name="phonePrefix"
          control={control}
          render={({ field: { onChange: onPrefixChange, value: prefixValue } }) => (
            <Controller
              name="phoneSuffix"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  value={value}
                  label={t('profile.phoneLabel')}
                  countryValue={prefixValue}
                  onCountryChange={v => onPrefixChange(v?.toString())}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />

        <Controller
          name="promoCode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input label={t('profile.sponsorshipCode')} value={value} onChangeText={onChange} />
          )}
        />
        <Spacer height={theme.spacing.SIZE_09} />

        <Button onPress={onSubmit} isLoading={isSaving}>
          {t('shared.save')}
        </Button>
      </S.Form>
    </S.FormWrapper>
  );
};
