import React from 'react';

import { TableCell } from '@app/ui/atoms/table-cell/TableCell';

import { TableRowProps } from './TableRow.types';

export const TableRow = <T,>({ columns, row, rowKey, style }: TableRowProps<T>): JSX.Element => (
  <>
    {columns.map((column, index) => (
      <TableCell key={`${index}_${row[rowKey]}`} row={row} column={column} style={style} />
    ))}
  </>
);

export { TableRowProps };
