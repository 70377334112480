import {
  createNavigationContainerRef,
  ParamListBase,
  StackActions,
} from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef<ParamListBase>();

export const navigationPushFromRef = (path: string): void => {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.push.apply(null, [path]));
  }
};
