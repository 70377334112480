import React, { Fragment, useMemo } from 'react';

import { View } from 'react-native';

import { useResponsive } from '@app/ui/utils/responsive/useResponsive';

import {
  isAfterStepFinished,
  isAfterSubStepFinished,
  isBeforeStepFinished,
  isLastStep,
} from './Timeline.utils';
import { TimelineVerticalStep } from './TimelineVerticalStep';
import { TimelineHorizontalStep } from './TimelineHorizontalStep';
import { timelineStyles } from './Timeline.styles';
import { TimelineProps } from './Timeline.types';

export const Timeline: React.FC<TimelineProps> = ({
  steps,
  onStepPress,
  activeStep = 1,
  onSubStepPress,
}) => {
  const { isLowerThanDesktop } = useResponsive();

  const StepComponent = isLowerThanDesktop ? TimelineVerticalStep : TimelineHorizontalStep;

  const timelineSteps = useMemo(
    () =>
      Object.values(steps).map(
        ({ content, indicator, status, subSteps, title }, index, collection) => (
          <Fragment key={`${title}-${index}`}>
            <StepComponent
              isBeforeStepFinished={isBeforeStepFinished(collection, index)}
              indicator={indicator}
              isFirstStep={index === 0}
              isLastStep={isLastStep(collection.length, index, activeStep)}
              isNextStepFinished={isAfterStepFinished(collection, index, activeStep)}
              onPress={onStepPress}
              status={status}
              step={index + 1}
              subSteps={subSteps}
              content={content}
              title={title}
            />
            {!!subSteps && activeStep === index + 1 && (
              <>
                {subSteps.map((subStep, subStepIndex, subStepCollection) => (
                  <Fragment key={`${subStep.title}-${subStepIndex}`}>
                    <StepComponent
                      content={subStep.content}
                      indicator={subStep.indicator}
                      isBeforeStepFinished={isBeforeStepFinished(subStepCollection, subStepIndex)}
                      isLastStep={isLastStep(
                        collection.length,
                        subStepIndex,
                        activeStep,
                        subStepCollection.length
                      )}
                      isNextStepFinished={isAfterSubStepFinished(
                        status,
                        subStepCollection,
                        subStepIndex
                      )}
                      onPress={onSubStepPress}
                      status={subStep.status}
                      step={subStepIndex + 1}
                      title={subStep.title}
                    />
                  </Fragment>
                ))}
              </>
            )}
          </Fragment>
        )
      ),
    [StepComponent, activeStep, onStepPress, onSubStepPress, steps]
  );

  return (
    <View style={timelineStyles(isLowerThanDesktop).container} testID="timeline">
      {timelineSteps}
    </View>
  );
};
