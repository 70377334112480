import React from 'react';

import { View } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

import { getStyle } from './Dot.styles';
import { DotType, DotSize } from './Dot.types';

export { DotType, DotSize };

export const Dot: React.FC<DotType> = ({ active, isDark = false, size = DotSize.S }) => {
  const theme = useTheme();

  const style = getStyle({ active, isDark, size, theme });
  return <View testID="Dot" style={style.dot}></View>;
};
