import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgMt = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="mt_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#mt_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M14.667 20H28V0H14.667v20z" fill="#DE233C" />
        <Mask
          id="mt_svg__b"
          maskUnits="userSpaceOnUse"
          x={1.667}
          y={1.667}
          width={6}
          height={6}
          fill="#000"
        >
          <Path fill="#fff" d="M1.667 1.667h6v6h-6z" />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.333 2.667H4V4H2.667v1.333H4v1.334h1.333V5.333h1.334V4H5.333V2.667z"
          />
        </Mask>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.333 2.667H4V4H2.667v1.333H4v1.334h1.333V5.333h1.334V4H5.333V2.667z"
          fill="#CCCCCD"
        />
        <Path
          d="M4 2.667V2h-.667v.667H4zm1.333 0H6V2h-.667v.667zM4 4v.667h.667V4H4zM2.667 4v-.667H2V4h.667zm0 1.333H2V6h.667v-.667zm1.333 0h.667v-.666H4v.666zm0 1.334h-.667v.666H4v-.666zm1.333 0v.666H6v-.666h-.667zm0-1.334v-.666h-.666v.666h.666zm1.334 0V6h.666v-.667h-.666zm0-1.333h.666v-.667h-.666V4zM5.333 4h-.666v.667h.666V4zM4 3.333h1.333V2H4v1.333zM4.667 4V2.667H3.333V4h1.334zm-2 .667H4V3.333H2.667v1.334zm.666.666V4H2v1.333h1.333zM4 4.667H2.667V6H4V4.667zm.667 2V5.333H3.333v1.334h1.334zM5.333 6H4v1.333h1.333V6zm-.666-.667v1.334H6V5.333H4.667zm2-.666H5.333V6h1.334V4.667zM6 4v1.333h1.333V4H6zm-.667.667h1.334V3.333H5.333v1.334zm-.666-2V4H6V2.667H4.667z"
          fill="#7B716A"
          fillOpacity={0.66}
          mask="url(#mt_svg__b)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.667 5.333a.667.667 0 100-1.333.667.667 0 000 1.333z"
          fill="#7B716A"
        />
      </G>
    </Svg>
  );
};

export default SvgMt;
