import { StyleSheet } from 'react-native';

import { RequiredThemes } from '@app/ui/theme/types';

export const getStyles = (componentTheme: RequiredThemes['input']) =>
  StyleSheet.create({
    input: {
      minHeight: 120,
      padding: componentTheme.primary.size.defaultPadding,
      width: '100%',
    },
  });
