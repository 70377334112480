import React from 'react';

import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Control, Controller } from 'react-hook-form';

import { Button, Input, Theme, useTheme } from '@app/ui';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      bottom: theme.spacing.SIZE_06,
      gap: theme.spacing.SIZE_07,
      marginTop: theme.spacing.SIZE_04,
      width: '100%',
    },
  });

interface Form {
  email: string;
}

interface AuthBridgeFormProps {
  control: Control<Form>;
  isLoading: boolean;
  onSubmit: () => void;
}

export const AuthBridgeForm: React.FC<AuthBridgeFormProps> = ({ control, isLoading, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.container}>
      <Controller
        control={control}
        name="email"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            error={error?.message}
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect={false}
            keyboardType="email-address"
            label={t('shared.email')}
            onSubmitEditing={onSubmit}
            placeholder={t('register.emailPlaceholder')}
            testID="auth-email-input"
            textContentType="emailAddress"
          />
        )}
      />

      <Button
        variant="primary"
        testID="auth-submit-button"
        isLoading={isLoading}
        onPress={onSubmit}
      >
        {t('authTunnel.emailAuthButton')}
      </Button>
    </View>
  );
};
