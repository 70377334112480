import { useMemo, useState } from 'react';

import * as Yup from 'yup';
import { Control, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Keyboard } from 'react-native';
import { ErrorResponse } from '@apollo/client/link/error';

import { useTranslation } from '@app/services/translations/translations';
import { useUpdateCustomerPasswordMutation } from '@app/libs/apollo/introspection';
import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';

import { ProfilePasswordFormValues } from '../compounds/profilePassword/profilePassword.type';

const PASSWORD_MIN_LENGTH = 8;
const initialValues: ProfilePasswordFormValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

interface UseChangePasswordFormOutput {
  control: Control<ProfilePasswordFormValues>;
  isSaving: boolean;
  onSubmit: () => Promise<void>;
}

export const useChangePasswordForm = (onSuccess?: () => void): UseChangePasswordFormOutput => {
  const { t } = useTranslation();

  const [updatePassword] = useUpdateCustomerPasswordMutation();
  const [isSaving, setIsSaving] = useState(false);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        currentPassword: Yup.string()
          .required(t('register.requiredField'))
          .label(t('shared.defaultErrorFieldName')),
        newPassword: Yup.string()
          .min(PASSWORD_MIN_LENGTH, t('register.passwordLength'))
          .required(t('register.requiredField')),
        newPasswordConfirmation: Yup.string()
          .required(t('register.requiredField'))
          .min(PASSWORD_MIN_LENGTH, t('register.passwordLength'))
          .oneOf([Yup.ref('newPassword'), null], t('shared.passwordConfirmationNotSame'))
          .label(t('shared.defaultErrorFieldName')),
      }),
    [t]
  );

  const { control, handleSubmit, setError } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSave = async (data: ProfilePasswordFormValues) => {
    Keyboard.dismiss();
    setIsSaving(true);
    try {
      await updatePassword({
        variables: {
          passwordsInput: {
            currentPassword: data.currentPassword.trim(),
            password: data.newPassword.trim(),
          },
        },
      });
      setIsSaving(false);
      onSuccess?.();
    } catch (error) {
      const { graphQLErrors } = error as ErrorResponse;

      if (!graphQLErrors) {
        ErrorMonitoring.logError(error);
        setError('currentPassword', t('profile.errorUpdatePassword'));
        return;
      }
      const [{ message }] = graphQLErrors;

      if (message === 'Bad Request') {
        setError('currentPassword', t('profile.errorBadPassword'));
      } else if (message === 'Bad Request Exception') {
        setError('newPassword', t('profile.errorSamePassword'));
      }
      setIsSaving(false);
    }
  };

  return {
    control,
    isSaving,
    onSubmit: handleSubmit(onSave),
  };
};
