import React, { forwardRef } from 'react';

import { StyleSheet, View } from 'react-native';

import { useCarouselPageIndicator } from '@app/ui/molecules/page-indicator/hooks/useCarouselPageIndicator';
import { PageIndicator } from '@app/ui/molecules/page-indicator/PageIndicator';

import { CarouselComp, ICarouselInstance, TCarouselProps } from './CarouselLib';

const styles = StyleSheet.create({
  dotContainer: { flexDirection: 'row', gap: 4, justifyContent: 'center' },
});

type CarouselProps = {
  showDots?: boolean;
};

export const Carousel = forwardRef<ICarouselInstance, TCarouselProps & CarouselProps>(
  ({ showDots = true, ...viewProps }, ref) => {
    const { onProgressChange, progressValue } = useCarouselPageIndicator();

    return (
      <>
        <CarouselComp ref={ref} onProgressChange={onProgressChange} {...viewProps} />

        {!!showDots && (
          <View style={styles.dotContainer}>
            {viewProps.data.map((_: any, i: number) => (
              <PageIndicator
                animValue={progressValue}
                index={i}
                key={i}
                length={viewProps.data.length}
              />
            ))}
          </View>
        )}
      </>
    );
  }
);

Carousel.displayName = 'Carousel';
