import React from 'react';

import { ImageBackground, StyleSheet } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { avatarTheme } from '@app/ui/atoms/Avatar/avatar.theme';
import { Pressable, PressableType } from '@app/ui/atoms/pressable/Pressable';

import { rawStyles, styles } from './styles';

export interface AvatarProps extends PressableType {
  /**
   * Url of the image to display as the avatar
   */
  avatarUrl?: string;
  /**
   * Wanted size of the avatar
   */
  size?: number;
  /**
   * If the user is an advisor
   */
  isAdvisor?: boolean;
  /**
   * testID Name
   */
  testID?: string;
}

/**
 * Simple avatar component with default icon placeholder if no image
 */
export const Avatar: React.FC<AvatarProps> = ({
  avatarUrl,
  size,
  style,
  isAdvisor = false,
  testID,
  ...pressableProps
}) => {
  const componentTheme = useComponentTheme('avatar', avatarTheme);

  const themedRawStyles = rawStyles(componentTheme);
  const themedStyles = styles(componentTheme);

  const wrapperSize = size ?? themedRawStyles.background.size;
  const iconSize = size ? size / 2 : themedRawStyles.icon.size;

  return (
    <Pressable
      style={StyleSheet.flatten([themedStyles.wrapper, style])}
      {...pressableProps}
      testID={testID}
    >
      <ImageBackground
        source={{
          uri: avatarUrl,
        }}
        resizeMode="cover"
        style={[
          themedStyles.background,
          {
            borderRadius: wrapperSize,
            height: wrapperSize,
            width: wrapperSize,
          },
        ]}
      >
        <Icon
          style={themedStyles.icon}
          name={isAdvisor ? 'Phone' : 'User'}
          color={themedRawStyles.icon.color}
          size={iconSize}
        />
      </ImageBackground>
    </Pressable>
  );
};
