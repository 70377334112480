import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMicrowave = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 20H4zm0-2h16V6H4v12zm1-1h10V7H5v10zm13 0c.25 0 .48-.104.688-.312.208-.209.312-.438.312-.688 0-.25-.104-.48-.312-.688C18.479 15.104 18.25 15 18 15c-.25 0-.48.092-.688.275A.92.92 0 0017 16c0 .25.104.48.312.688.209.208.438.312.688.312zM7 15V9h6v6H7zm11-2c.25 0 .48-.092.688-.275A.92.92 0 0019 12a.92.92 0 00-.312-.725A1.02 1.02 0 0018 11c-.25 0-.48.092-.688.275A.92.92 0 0017 12c0 .3.104.542.312.725.209.183.438.275.688.275zm0-4c.25 0 .48-.092.688-.275A.92.92 0 0019 8a.92.92 0 00-.312-.725A1.02 1.02 0 0018 7c-.25 0-.48.092-.688.275A.92.92 0 0017 8c0 .3.104.542.312.725.209.183.438.275.688.275z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMicrowave;
