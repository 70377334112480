import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const SvgWw = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Path d="M0 2a2 2 0 012-2h24a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2z" fill="#fff" />
      <Path d="M0 0h28v20H0V0z" fill="#F2F2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 17.333a7.333 7.333 0 100-14.666 7.333 7.333 0 000 14.666zm5.963-6.666a6.006 6.006 0 01-3.746 4.91c.65-1.324 1.03-3.049 1.103-4.91h2.643zm-3.977 0c-.098 2.249-.685 4.236-1.6 5.32a6.079 6.079 0 01-.771 0c-.916-1.084-1.503-3.071-1.6-5.32h3.97zm-5.306 0c.073 1.861.453 3.586 1.103 4.91a6.006 6.006 0 01-3.746-4.91h2.643zm1.334-1.334h3.972c-.098-2.249-.685-4.236-1.6-5.32a6.086 6.086 0 00-.771 0c-.916 1.084-1.503 3.071-1.6 5.32zm-.231-4.91c-.65 1.324-1.03 3.049-1.103 4.91H8.037a6.006 6.006 0 013.746-4.91zm8.18 4.91H17.32c-.073-1.861-.453-3.586-1.103-4.91a6.006 6.006 0 013.746 4.91z"
        fill="#131315"
      />
    </Svg>
  );
};

export default SvgWw;
