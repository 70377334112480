import { RequiredThemes } from '@app/ui/theme/types';

import { LikeButtonSize } from './LikeButton.types';

export const getContainerStyle = (
  size: LikeButtonSize,
  pressed: boolean,
  disabled: boolean,
  active: boolean,
  withBorder: boolean,
  withShadow: boolean,
  hover: boolean,
  componentTheme: RequiredThemes['likeButton']
) => {
  const containerSize = componentTheme.containerSize[size];
  const border = withBorder ? componentTheme.border : {};
  const shadow = withShadow ? componentTheme.shadow : {};

  let containerBackgroundColor = componentTheme.colors.default.backgroundColor;

  if (active) {
    containerBackgroundColor = componentTheme.colors.active.backgroundColor;
  }

  if (hover) {
    containerBackgroundColor = componentTheme.colors.hover.backgroundColor;
  }

  if (pressed) {
    containerBackgroundColor = componentTheme.colors.pressed.backgroundColor;
  }

  if (disabled) {
    containerBackgroundColor = componentTheme.colors.disabled.backgroundColor;
  }

  return [
    containerSize,
    border,
    shadow,
    {
      alignItems: 'center',
      backgroundColor: containerBackgroundColor,
      justifyContent: 'center',
    },
  ];
};

export const getIconStyle = (
  size: LikeButtonSize,
  componentTheme: RequiredThemes['likeButton'],
  active: boolean,
  pressed: boolean,
  disabled: boolean
) => {
  const iconSize = componentTheme.iconSize[size];

  let { iconColor } = componentTheme.colors.default;

  if (active) {
    iconColor = componentTheme.colors.active.iconColor;
  }

  if (pressed) {
    iconColor = componentTheme.colors.pressed.iconColor;
  }

  if (disabled) {
    iconColor = componentTheme.colors.disabled.iconColor;
  }

  return {
    color: iconColor,
    iconSize,
  };
};
