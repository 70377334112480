import React, { useCallback, useMemo, useRef, useState } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ICarouselInstance } from 'react-native-reanimated-carousel';

import { useTranslation } from '@app/services/translations/translations';
import { ActionButton, LoaderDefault, Text, Theme, useResponsive, useTheme } from '@app/ui';
import { Carousel } from '@app/ui/organisms/carousel/Carousel';
import { useGetSoldRealEstatesV2Query } from '@app/libs/apollo/introspection';
import { resolveToMasteosLanguage } from '@app/utils/lang/resolveLanguageCode';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

import {
  BreakPoint,
  SectionContainer,
  sectionPaddingForScreen,
  useActiveBreakPoint,
} from '../homePage/SectionContainer';
import {
  SoldRealEstateCard,
  SoldRealEstateItem,
} from '../search-engine/compounds/sold-real-estate-card/SoldRealEstateCard';
import { SearchEngineItem } from '../search-engine/searchEngine.types';
import { soldRealEstateToItemMapper } from '../search-engine/utils/sold-real-estate-to-item.mapper';

const getStyles = ({
  theme,
  width,
  activeBreakPoint,
}: {
  theme: Theme;
  width: number;
  activeBreakPoint: BreakPoint;
}) =>
  StyleSheet.create({
    buttons: { flexDirection: 'row', marginBottom: theme.spacing.SIZE_06 },
    carousel: {
      marginHorizontal: -sectionPaddingForScreen[activeBreakPoint],
      marginTop: theme.spacing.SIZE_06,
      width: width,
    },
    headerContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing.SIZE_06,
    },
    loader: { alignItems: 'center', flex: 1, justifyContent: 'center' },
    renderItem: {
      marginLeft: sectionPaddingForScreen[activeBreakPoint],
      maxWidth: 350,
    },
  });

type RenovateRealEstatesBlockNavigation = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.PublicHome
>;

export const RenovateRealEstatesBlock: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation<RenovateRealEstatesBlockNavigation>();
  const [index, setIndex] = useState(0);
  const carouselRef = useRef<ICarouselInstance>(null);

  const { width } = useWindowDimensions();
  const activeBreakPoint = useActiveBreakPoint();
  const { isNativeOrResponsive, isLowerThanLaptop } = useResponsive();
  const styles = getStyles({ activeBreakPoint, theme, width });

  const { data, loading: getSoldRealEstatesLoading } = useGetSoldRealEstatesV2Query({
    fetchPolicy: 'cache-first',
    variables: { lang: resolveToMasteosLanguage(i18n.resolvedLanguage) },
  });
  const renovateRealEstatesMapped = (data?.soldRealEstatesV2.slice(0, 12) || []).map(
    soldRealEstateToItemMapper
  );

  const CARD_WIDTH = isNativeOrResponsive ? 308 : 400;
  const cardVisibleCount = useMemo(() => {
    if (width === 0) {
      return 0;
    }
    return Math.floor(width / CARD_WIDTH);
  }, [CARD_WIDTH, width]);

  const nextButtonDisable = useMemo(() => {
    return Boolean(
      renovateRealEstatesMapped.length &&
        index + cardVisibleCount >= renovateRealEstatesMapped.length
    );
  }, [renovateRealEstatesMapped.length, index, cardVisibleCount]);

  const handleItemClick = useCallback(
    (item: SearchEngineItem) => {
      navigate(PublicNavigatorRoutes.RealEstateSold, { id: item.id });
    },
    [navigate]
  );

  const onPressBtn = (i: number) => () => {
    setIndex(i);
    carouselRef?.current?.scrollTo({ animated: true, index: i });
  };

  return (
    <SectionContainer>
      <View style={styles.headerContainer}>
        <View>
          <Text textStyle="Title3Medium">{t('publicHomePage.renovateRealEstate.title')}</Text>
          <Text textStyle="Body3">{t('publicHomePage.renovateRealEstate.subtitle')}</Text>
        </View>
        <View style={styles.buttons}>
          <ActionButton
            withSolidBorder
            leftIconName="ArrowLeft"
            disabled={index === 0}
            onPress={onPressBtn(index - 1)}
          />
          <ActionButton
            withSolidBorder
            leftIconName="ArrowRight"
            disabled={nextButtonDisable}
            onPress={onPressBtn(index + 1)}
          />
        </View>
      </View>

      {getSoldRealEstatesLoading ? (
        <View style={styles.loader}>
          <LoaderDefault variant="inLight" />
        </View>
      ) : (
        <View>
          <Carousel
            showDots={false}
            width={isNativeOrResponsive ? width * 0.9 : isLowerThanLaptop ? width * 0.45 : 300}
            height={390}
            panGestureHandlerProps={{ activeOffsetX: [-20, 20] }}
            style={styles.carousel}
            loop={false}
            data={renovateRealEstatesMapped}
            ref={carouselRef}
            renderItem={({ item }: { item: SoldRealEstateItem }) => (
              <View style={styles.renderItem}>
                <SoldRealEstateCard
                  key={item.id}
                  soldRealEstate={item}
                  onClick={() => handleItemClick(item)}
                />
              </View>
            )}
          />
        </View>
      )}
    </SectionContainer>
  );
};
