import { ColorValue, StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

import { Variant } from './DataBlock.types';

export const getTitleColor = (theme: Theme, variant: Variant): ColorValue =>
  variant === 'inverted' ? theme.palette.base.white : theme.palette.neutral[900];

export const getBackgroundColor = (theme: Theme, variant: Variant): ColorValue => {
  if (variant === 'inverted') {
    return theme.palette.neutral[800];
  }
  if (variant === 'neutral') {
    return theme.palette.neutral[100];
  }
  return theme.palette.base.white;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDataBlockStyle = ({ theme, variant }: { theme: Theme; variant: Variant }) =>
  StyleSheet.create({
    container: {
      backgroundColor: getBackgroundColor(theme, variant),
      borderRadius: theme.spacing.SIZE_06,
      padding: theme.spacing.SIZE_05,
    },
    content: {
      color: theme.palette.neutral[variant === 'inverted' ? 100 : 700],
    },
    hintPosition: {
      position: 'absolute',
      right: theme.spacing.SIZE_05,
      top: theme.spacing.SIZE_05,
      zIndex: 2,
    },
    iconColor: {
      color: theme.palette.neutral[variant === 'inverted' ? 100 : 900],
    },
    main: {
      gap: theme.spacing.SIZE_03,
    },
    title: {
      color: getTitleColor(theme, variant),
      paddingBottom: theme.spacing.SIZE_03,
    },
  });
