import React from 'react';

import { StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@app/ui/theme/types';

import { StepSize, StepStatus, StepVariant } from './CircleStepIndicator.types';

const getVariantStyle: ({
  status,
  theme,
  variant,
}: {
  status: StepStatus;
  theme: Theme;
  variant: StepVariant;
}) => {
  backgroundColor: ViewStyle['backgroundColor'];
  borderWidth: ViewStyle['borderWidth'];
  color: string;
} = ({ status, theme, variant }) => {
  let backgroundColor;
  let borderWidth;
  let color = theme.palette.base.white;
  const isPrimary = variant === StepVariant.Primary;

  switch (status) {
    case StepStatus.Finished:
      backgroundColor = isPrimary ? theme.palette.system.primary[500] : theme.palette.alert.valid;
      break;
    case StepStatus.Ongoing:
      backgroundColor = isPrimary ? theme.palette.system.primary[500] : theme.palette.neutral[900];
      break;
    case StepStatus.Incoming:
    default:
      borderWidth = isPrimary ? 0 : 1;
      color = isPrimary ? theme.palette.base.white : theme.palette.neutral[900];
      backgroundColor = isPrimary ? theme.palette.neutral[500] : theme.palette.base.white;
  }

  return { backgroundColor, borderWidth, color };
};

const getSize: (size: StepSize) => number = size => {
  switch (size) {
    case StepSize.S:
      return 20;
    case StepSize.L:
      return 40;
    case StepSize.M:
    default:
      return 32;
  }
};

export const getCircleStepIndicatorStyles = ({
  theme,
  status,
  size,
  variant,
}: {
  theme: Theme;
  status: StepStatus;
  size: StepSize;
  variant: StepVariant;
}): StyleSheet.NamedStyles<Record<string, React.CSSProperties>> => {
  const { backgroundColor, borderWidth, color } = getVariantStyle({
    status,
    theme,
    variant,
  });
  const circleSize = getSize(size);

  return StyleSheet.create({
    circle: {
      alignItems: 'center',
      backgroundColor,
      borderColor: theme.palette.neutral[400],
      borderRadius: 50,
      borderStyle: 'solid',
      borderWidth,
      height: circleSize,
      justifyContent: 'center',
      position: 'relative',
      width: circleSize,
    },
    finishedCircle: {
      backgroundColor: theme.palette.base.white,
      borderRadius: 50,
      borderStyle: 'solid',
      borderWidth: 0,
      position: 'absolute',
      right: -4,
      top: -4,
    },
    indicator: { color },
  });
};
