import { StyleSheet } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';

import { Theme } from '@app/ui/theme/types';

import { BottomSheetWebSize } from './BottomSheet.types';

export const getStyles = ({
  inset,
  theme,
  isNativeOrResponsive,
  noPadding,
  webSizeWidth,
  webSize,
}: {
  inset: EdgeInsets;
  theme: Theme;
  isNativeOrResponsive: boolean;
  noPadding: boolean;
  webSizeWidth: number | string | undefined;
  webSize: BottomSheetWebSize;
}) =>
  StyleSheet.create({
    buttonContainer: {
      position: 'absolute',
      right: 8,
      top: 8,
      zIndex: 10,
    },
    container: {
      backgroundColor: theme.palette.base.white,
      borderBottomLeftRadius: isNativeOrResponsive ? 0 : 24,
      borderBottomRightRadius: isNativeOrResponsive ? 0 : 24,
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      maxHeight: isNativeOrResponsive ? '100%' : '90%',
      minWidth: isNativeOrResponsive ? '100%' : webSizeWidth,
      paddingHorizontal: noPadding ? 0 : isNativeOrResponsive ? 20 : 32,
      paddingVertical: noPadding ? 0 : 32,
      ...(webSize === 's' && {
        width: 400,
      }),
    },
    modal: {
      alignItems: 'center',
      flex: 1,
      justifyContent: isNativeOrResponsive ? 'flex-end' : 'center',
      margin: 0,
      paddingTop: inset.top,
    },
  });
