import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';

import { fromS3ID } from '@masteos/agora';

import { AvatarSelector } from '@app/ui';
import { useMe } from '@app/shared/hooks/useMe';
import { MainMenuNavigatorRoutes } from '@app/navigation/types/routes';
import { trackLogout } from '@app/services/tracking/trackTracking';
import { resetTracking } from '@app/services/tracking/resetTracking';
import { useLogout } from '@app/shared/hooks/useLogout';
import { DesktopNavigationNavigationProp } from '@app/features/navigation/navigation.types';
import { ListType } from '@app/ui/molecules/dropdownV2/Dropdown.types';

type CustomerDropdownProps = {
  optionsOverride?: {
    key: string;
    label: string;
    onSelect: (closeList: ListType['closeList']) => void;
  }[];
};

export const CustomerDropdown: React.FC<CustomerDropdownProps> = ({ optionsOverride }) => {
  const { customer } = useMe();
  const { logout } = useLogout();
  const { t } = useTranslation();
  const navigation = useNavigation<DesktopNavigationNavigationProp>();

  const customerAvatar = useMemo(
    () => fromS3ID(customer?.account?.avatarS3ID || '', `w-64`),
    [customer?.account?.avatarS3ID]
  );

  const logOut = useCallback(async () => {
    trackLogout();
    resetTracking();
    await logout();
  }, [logout]);

  const redirectPage = useCallback(
    (path: MainMenuNavigatorRoutes) => () => {
      navigation.reset({
        index: 0,
        routes: [{ name: path }],
      });
    },
    [navigation]
  );

  const options = useMemo(
    () =>
      optionsOverride || [
        {
          key: 'viewMyProfil',
          label: t('navigation.topBar.viewMyProfil'),
          onSelect: (closeList: ListType['closeList']) => {
            redirectPage(MainMenuNavigatorRoutes.Profile)();
            closeList();
          },
        },
        {
          key: 'logout',
          label: t('navigation.logout'),
          onSelect: async (closeList: ListType['closeList']) => {
            closeList();
            await logOut();
          },
        },
      ],
    [logOut, optionsOverride, redirectPage, t]
  );

  return <AvatarSelector avatarUrl={customerAvatar || ''} options={options} />;
};
