import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgBookmarkFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.45 20.894a.99.99 0 01-.95-.088c-.3-.191-.45-.47-.45-.837V5.494c0-.55.196-1.02.588-1.413a1.925 1.925 0 011.412-.587h10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413V19.97c0 .367-.15.646-.45.837a.99.99 0 01-.95.088l-5.6-2.4-5.6 2.4z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgBookmarkFilled;
