import React, { cloneElement, isValidElement, useCallback, useMemo, useState } from 'react';

import { Animated, LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { usePanResponderCallbacks } from '@app/ui/hooks/use-pan-responder/usePanResponderCallbacks';

import { getStyle } from './Slider.styles';
import { SliderType } from './Slider.types';

const Slider = ({
  children,
  containerStyle,
  currentStep,
  gesturesEnabled = true,
  innerContainerStyle,
  loop,
  minDistanceForAction,
  minDistanceToCapture,
  onGoStep,
  positionFixed,
  slideWrapperStyle,
  swipeAreaStyle,
}: SliderType): JSX.Element => {
  const count = children.length;

  const [x, setX] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const styles = useMemo(
    () => getStyle({ count, height, positionFixed, width, x, y: 0 }),
    [count, height, positionFixed, width, x]
  );

  const { panResponder, setOffset, pan } = usePanResponderCallbacks({
    count,
    currentStep,
    gesturesEnabled,
    loop,
    minDistanceForAction,
    minDistanceToCapture,
    onGoStep,
    width,
  });

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;

      setWidth(layout.width);
      setHeight(layout.height);
      setX(layout.x);
      setOffset();
    },
    [setOffset]
  );
  const transform = pan.getTranslateTransform();

  return (
    <View
      style={StyleSheet.flatten([styles.root, containerStyle])}
      onLayout={onLayout}
      testID="SliderContainer"
    >
      <View style={StyleSheet.flatten([styles.container, innerContainerStyle])}>
        <Animated.View
          style={StyleSheet.flatten([styles.swipeArea, swipeAreaStyle, { transform }])}
          {...panResponder.panHandlers}
        >
          {children.map((el, i: number) => (
            <View key={i} style={StyleSheet.flatten([{ height, width }, slideWrapperStyle])}>
              {isValidElement(el) &&
                cloneElement(
                  el as React.ReactElement<{
                    currentStep: number;
                    index: number;
                    isActive: boolean;
                  }>,
                  {
                    currentStep,
                    index: i,
                    isActive: i === currentStep,
                  }
                )}
            </View>
          ))}
        </Animated.View>
      </View>
    </View>
  );
};

export { Slider };
