import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgWorksite = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5.475 21c-.417 0-.77-.146-1.062-.438a1.444 1.444 0 01-.438-1.062c0-.417.146-.77.438-1.062A1.444 1.444 0 015.475 18h1.6l-2.55-8.35a2.99 2.99 0 01-1.112-1.1c-.292-.483-.438-1-.438-1.55 0-.833.292-1.542.875-2.125A2.893 2.893 0 015.975 4c.65 0 1.23.187 1.738.562.508.375.862.855 1.062 1.438h3.2V5c0-.283.096-.521.288-.713A.967.967 0 0112.975 4c.15 0 .296.033.438.1a.963.963 0 01.362.3l1.7-1.6c.15-.15.329-.246.537-.288a.932.932 0 01.613.088l3.9 1.8c.2.1.338.246.413.437.075.192.07.38-.013.563-.1.2-.246.329-.437.387a.773.773 0 01-.563-.037l-3.6-1.65-2.35 2.2v1.4l2.35 2.15 3.6-1.65a.761.761 0 01.575-.025c.2.067.342.192.425.375.1.2.108.392.025.575a.877.877 0 01-.425.425l-3.9 1.85c-.2.1-.404.129-.613.087a1.026 1.026 0 01-.537-.287l-1.7-1.6c-.1.1-.22.192-.362.275a.855.855 0 01-.438.125.965.965 0 01-.712-.288.965.965 0 01-.288-.712V8h-3.2c-.05.133-.104.258-.162.375a2.528 2.528 0 01-.238.375l5 9.25h2.1c.417 0 .771.146 1.063.438.291.291.437.645.437 1.062 0 .417-.146.77-.437 1.062a1.447 1.447 0 01-1.063.438h-10zm.5-13a.97.97 0 00.713-.287A.97.97 0 006.975 7a.97.97 0 00-.287-.713A.97.97 0 005.975 6a.967.967 0 00-.712.287.968.968 0 00-.288.713c0 .283.096.521.288.713A.967.967 0 005.975 8zm3.15 10h1.95l-4.3-8h-.1l2.45 8z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgWorksite;
