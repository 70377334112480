import React, { useMemo } from 'react';

import { View } from 'react-native';

import { Expandable, Spacer, Spacing, Text } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { ProjectDetailSubStepHeader } from '@app/features/project/ProjectDetail/ProjectDetailSubStepHeader';
import { ProjectDetailSubStepProps } from '@app/features/project/ProjectDetail/ProjectDetail.type';
import { getDetailSubStepStyles } from '@app/features/project/ProjectDetail/ProjectDetailStage.styles';

export const ProjectDetailSubStep: React.FC<ProjectDetailSubStepProps> = ({
  content,
  isLast,
  onExpand,
  optional,
  status,
  title,
}) => {
  const { t } = useTranslation();

  const styles = useMemo(() => getDetailSubStepStyles({ isLast }), [isLast]);

  return (
    <View style={styles.block}>
      <Expandable id={title} onPress={!!content && onExpand}>
        <Expandable.Header testID="sub-step-details--press">
          <ProjectDetailSubStepHeader
            showChevron={!!content}
            optional={optional}
            status={status}
            title={title}
          />
        </Expandable.Header>
        {content ? (
          <Expandable.Content>
            <Spacer height={Spacing.SIZE_05} />
            <Text style={styles.textContent} textStyle="Body2">
              {t(content)}
            </Text>
          </Expandable.Content>
        ) : null}
      </Expandable>
    </View>
  );
};
