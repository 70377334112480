import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgProjects = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3 20a.965.965 0 01-.712-.288A.965.965 0 012 19v-8.575a1 1 0 01.3-.725l5-5c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.02.375.062A.883.883 0 018.7 4.7l5 5a1 1 0 01.3.725V19c0 .283-.096.52-.287.712A.968.968 0 0113 20H3zm1-2h3v-2a.97.97 0 01.287-.713A.97.97 0 018 15a.97.97 0 01.713.287A.97.97 0 019 16v2h3v-7.175l-4-4-4 4V18zm4-5a.968.968 0 01-.713-.288A.967.967 0 017 12a.97.97 0 01.287-.713A.97.97 0 018 11a.97.97 0 01.713.287A.97.97 0 019 12c0 .283-.096.52-.287.712A.968.968 0 018 13zm9 7a.965.965 0 01-.712-.288A.965.965 0 0116 19V9.375c0-.033-.05-.15-.15-.35L12.525 5.7c-.317-.317-.392-.68-.225-1.088.167-.408.475-.612.925-.612.133 0 .267.029.4.087.133.059.242.13.325.213l3.75 3.75a1 1 0 01.3.725V19c0 .283-.096.52-.288.712A.965.965 0 0117 20zm4 0a.965.965 0 01-.712-.288A.965.965 0 0120 19V7.725c0-.033-.05-.15-.15-.35L18.175 5.7c-.317-.317-.392-.68-.225-1.088.167-.408.475-.612.925-.612.133 0 .267.029.4.087.133.059.242.13.325.213l2.1 2.1a1.03 1.03 0 01.3.725V19c0 .283-.096.52-.288.712A.965.965 0 0121 20zM4 18h8-8z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgProjects;
