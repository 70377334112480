import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { ParamListBase, useIsFocused, useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';

import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { TrackingOrigin, trackPropertyChangeTab } from '@app/services/tracking/trackTracking';
import { TabView } from '@app/shared/components/TabView/TabView';
import { RealEstate as RealEstateType, SupportedLanguage } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { PublicNavigatorRoutes, RealEstateNavigatorRoutes } from '@app/navigation/types/routes';
import { useLocationInfoPermission } from '@app/shared/hooks/useLocationInfoPermission';
import { isWeb } from '@app/constants/platform';
import { ActionButton, Theme, useResponsive, useTheme } from '@app/ui';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { currentSearchEngineScrollPosition } from '@app/features/search-engine/utils/scrollTo.utils';
import { useMe } from '@app/shared/hooks/useMe';
import { navigationPushFromRef } from '@app/navigation/navigationRef';
import { ExtendedSearchResultsType } from '@app/features/search-engine/searchEngine.types';
import { RealEstateRenovationOld } from '@app/features/realEstate/realEstateAvailable/realEstateRenovation/RealEstateRenovationOld';
import { hasPublicToken } from '@app/utils/getPublicToken';
import { useFinancialModel } from '@app/shared/hooks/useFinancialModel';

import { RealEstateRenovation } from './realEstateRenovation/RealEstateRenovation';
import { RealEstateFinances } from './realEstateFinances/RealEstateFinances';
import { RealEstateGeneral } from './realEstateGeneral/RealEstateGeneral';
import { RealEstateTabsButtons } from './realEstateTabsButtons/RealEstateTabsButtons';
import { useHeaderAuthButtons } from '../hooks/useHeaderAuthButtons';
import { RealEstateAvailableHeader } from './realEstateAvailableHeader/RealEstateAvailableHeader';
import { ResponsiveDrawer } from './realEstateRenovation/components/ResponsiveDrawer';
import { computedWorkAmount } from './realEstateGeneral/utils/computedWorkAmount.utils';

interface RealEstateAvailableProps {
  realEstate: RealEstateType;
  isFromNotifications?: boolean;
  realEstatePosition: number;
  isFromSearchEngine?: boolean;
  currentScrollPosition?: number;
  fromExtendedResultsSection?: ExtendedSearchResultsType;
}

type RealEstateNavigationProp = StackNavigationProp<
  ParamListBase,
  RealEstateNavigatorRoutes.RealEstate
>;

const getStyles = (theme: Theme, isLowerThanWide: boolean) =>
  StyleSheet.create({
    backButton: {
      left: isLowerThanWide ? 8 : 40,
      position: 'absolute',
      top: 8,
      zIndex: 2,
    },
    container: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },
  });

export const RealEstateAvailable: React.FC<RealEstateAvailableProps> = ({
  realEstate,
  realEstatePosition,
  isFromNotifications,
  isFromSearchEngine,
  currentScrollPosition,
  fromExtendedResultsSection,
}) => {
  const { customer } = useMe();
  const navigation = useNavigation<RealEstateNavigationProp>();
  const theme = useTheme();
  const { isLowerThanDesktop, isLowerThanLaptop, isLowerThanWide } = useResponsive();
  const styles = useMemo(() => getStyles(theme, isLowerThanWide), [theme, isLowerThanWide]);

  const { financialModel } = useFinancialModel(realEstate);
  const workAmount = computedWorkAmount(realEstate.project, financialModel);

  const { t } = useRealEstateTranslation();

  const { isHeaderAuthButtonsVisible, ref: headerRef } = useHeaderAuthButtons();

  const isFocused = useIsFocused();
  const shouldShowAddress = useLocationInfoPermission(realEstate?.id);
  const hasPublicFullAccess = hasPublicToken();

  const shouldShowPrivateInformations = hasPublicFullAccess || shouldShowAddress;

  /** Handle tracking on realEstate page */
  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (realEstate) {
      if (isWeb()) {
        trackChangePage(RealEstateNavigatorRoutes.RealEstate, {
          fromExtendedResultsSection,
          isFromSearchEngine,
          realEstate,
          realEstatePosition,
        });
      } else {
        trackChangeScreen(PublicNavigatorRoutes.RealEstate, {
          fromExtendedResultsSection,
          isFromNotifications,
          isFromSearchEngine,
          realEstate,
          realEstatePosition,
        });
      }
    }
    // /!\ Do not add the dependency 'realEstate' in the dependency array bellow to avoid
    // multiple trigger of trackChangePage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realEstate?.id, realEstatePosition]);

  const [drawerContent, setDrawerContent] = useState<ReactElement | null>(null);

  const tabContent = [
    {
      component: () => (
        <RealEstateGeneral
          realEstate={realEstate}
          isPublicRealEstate={isWeb() ? !shouldShowPrivateInformations : false}
        />
      ),
      key: 'general',
      title: t('propertyInfo.general'),
      trackingLabel: TrackingOrigin.GENERAL,
    },
    {
      component: () =>
        realEstate.lang === SupportedLanguage.Fr ? (
          <RealEstateRenovation
            realEstate={realEstate}
            toggleDrawer={setDrawerContent}
            renovationAmount={workAmount}
          />
        ) : (
          <RealEstateRenovationOld realEstate={realEstate} />
        ),
      key: 'renovation',
      title: t('propertyInfo.renovation'),
      trackingLabel: TrackingOrigin.RENOVATION,
    },
    {
      component: () => <RealEstateFinances realEstate={realEstate} />,
      key: 'financial',
      title: t('propertyInfo.financial'),
      trackingLabel: TrackingOrigin.FINANCES,
    },
  ];

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      isWeb() && currentSearchEngineScrollPosition(currentScrollPosition);
      navigation.goBack();
    } else if (customer?.id) {
      navigationPushFromRef(navigation.getState().routeNames[0]);
    } else {
      navigation.navigate(PublicNavigatorRoutes.Auth);
    }
  };

  const onIndexChange = (index: number) => {
    trackPropertyChangeTab({ propertyId: realEstate.id, tabName: tabContent[index].trackingLabel });
  };

  return (
    <View testID="real-estate-available-view" style={styles.container}>
      {!isLowerThanLaptop && (
        <ActionButton
          leftIconName="ChevronLeft"
          size="m"
          onPress={handleGoBack}
          testID="go-back-action-btn"
          withSolidBorder
          style={styles.backButton}
        />
      )}

      <TabView
        header={
          <RealEstateAvailableHeader
            realEstate={realEstate}
            showPrivateInformations={shouldShowPrivateInformations}
            onBack={handleGoBack}
            ref={headerRef}
          />
        }
        index={0}
        onIndexChange={onIndexChange}
        tabs={tabContent}
        tabsRightElementWeb={
          !isHeaderAuthButtonsVisible &&
          !isLowerThanDesktop &&
          !hasPublicFullAccess && <RealEstateTabsButtons realEstate={realEstate} />
        }
      />

      {!isHeaderAuthButtonsVisible && !hasPublicFullAccess && !!isLowerThanDesktop && (
        <RealEstateTabsButtons realEstate={realEstate} />
      )}

      {isWeb() && (
        <ResponsiveDrawer onClose={() => setDrawerContent(null)}>{drawerContent}</ResponsiveDrawer>
      )}
    </View>
  );
};
