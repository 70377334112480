import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCd = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="cd_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#cd_svg__a)">
        <Rect width={28} height={20} rx={2} fill="#158AFF" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.36 5.205L-2.094 22.759l-3.632-7.128L28.727-1.923l3.632 7.128z"
          fill="#FDD216"
        />
        <Path fill="#CE1120" d="M32.121 3.774L-2.33 21.328l-2.421-4.752L29.7-.978z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.667 5.967l-1.96 1.396.723-2.295-1.934-1.431 2.407-.022.764-2.282.764 2.282 2.406.022-1.934 1.431.723 2.295-1.96-1.396z"
          fill="#FDD216"
        />
      </G>
    </Svg>
  );
};

export default SvgCd;
