import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';

import { LoaderMasteos } from '@app/ui';
import { useGetSoldRealEstateV2Query } from '@app/libs/apollo/introspection';
import { RealEstateProvider } from '@app/shared/contexts/realEstate/RealEstateProvider';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { GalleryProvider } from '@app/features/realEstate/gallery/GalleryProvider';
import { RealEstateSold } from '@app/features/realEstate/realEstateSold/RealEstateSold';
import { resolveToMasteosLanguage } from '@app/utils/lang/resolveLanguageCode';
import { useTranslation } from '@app/services/translations/translations';

import { getStyles } from '../styles.web';

type RealEstatesStackRouteProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

export const RealEstateSoldView: React.FC<RealEstatesStackRouteProps> = ({ route, navigation }) => {
  const { id, currentScrollPosition } = route.params;
  const styles = getStyles();
  const { i18n } = useTranslation();

  const {
    loading,
    error,
    data: { soldRealEstateV2: soldRealEstate } = {},
  } = useGetSoldRealEstateV2Query({
    variables: { id, lang: resolveToMasteosLanguage(i18n.resolvedLanguage) },
  });

  if (loading) {
    return (
      <View style={styles.loader}>
        <LoaderMasteos variant="inLight" />
      </View>
    );
  }

  if (!soldRealEstate) {
    if (error instanceof Error) {
      navigation.navigate(RealEstateNavigatorRoutes.RealEstateList);
    }
    return null;
  }

  return (
    <RealEstateProvider realEstate={soldRealEstate}>
      <GalleryProvider propertyId={soldRealEstate.id}>
        <RealEstateSold realEstate={soldRealEstate} currentScrollPosition={currentScrollPosition} />
      </GalleryProvider>
    </RealEstateProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default RealEstateSoldView;
