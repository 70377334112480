import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgIn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="in_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#in_svg__a)">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 6.667h28V0H0v6.667z" fill="#FFA44A" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 20h28v-6.667H0V20z" fill="#1A9F0B" />
        <Path
          d="M14 12.333a2.333 2.333 0 100-4.666 2.333 2.333 0 000 4.666z"
          fill="#181A93"
          fillOpacity={0.15}
          stroke="#181A93"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 10.667a.667.667 0 100-1.334.667.667 0 000 1.334z"
          fill="#181A93"
        />
      </G>
    </Svg>
  );
};

export default SvgIn;
