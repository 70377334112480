import React, { ErrorInfo } from 'react';

import { withTranslation } from 'react-i18next';

import { componentLoader, DEFAULT_LAZY_RETRIES } from '@app/utils/componentLoader';
import * as ErrorMonitoring from '@app/services/errorMonitoring/errorMonitoring';

const ErrorScreen = React.lazy(() =>
  componentLoader(() => import('@app/features/errorScreen/ErrorScreen.web'), DEFAULT_LAZY_RETRIES)
);

interface ErrorBoundaryProps {
  t: (key: string) => string;
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error: string | null }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ErrorMonitoring.logError(error, { errorInfo });
  }

  render() {
    const { children, t } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorScreen
          resetError={() => this.setState({ error: null })}
          message={t('errorScreen.genericError')}
        />
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
