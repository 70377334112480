import jwtDecode from 'jwt-decode';
import { appleAuthHelpers } from 'react-apple-signin-auth';
import { Platform } from 'react-native';

import { config } from '@app/config';
import { isFacebookBrowser, isInstagramBrowser } from '@app/utils/detectBrowser.web';
import { identityClient } from '@app/libs/identity/identityClient';

import { IdTokenResponse } from './appleSignIn.types';
import { SSOProfile } from '../authBridge.types';

interface AppleAuthWebResponse {
  authorization: {
    code: string;
    id_token: string;
  };
}

export const useAppleSignIn = (
  onSignInSuccess: (idToken: string, profileSso: SSOProfile) => void,
  onSignInFailure: () => void
): (() => void) => {
  const handleAppleSignInSuccess = async ({ authorization }: AppleAuthWebResponse) => {
    const { code, id_token: appleToken } = authorization;
    const { email } = jwtDecode<IdTokenResponse>(appleToken);
    const { idToken, id } = await identityClient.authenticateApple({
      code,
      platform: Platform.OS,
    });
    onSignInSuccess(idToken, { email, id });
  };

  const redirectMode = isInstagramBrowser() || isFacebookBrowser();

  return async (): Promise<void> => {
    const data: AppleAuthWebResponse | null = await appleAuthHelpers.signIn({
      authOptions: {
        clientId: config.APPLE_SSO_CLIENT_ID,
        redirectURI: redirectMode
          ? `${config.IDENTITY_API_URL}/authenticate/apple`
          : window.location.href,
        scope: 'email name',
        usePopup: !redirectMode,
      },
      onError: onSignInFailure,
    });

    if (data?.authorization) {
      handleAppleSignInSuccess(data);
    }
  };
};
