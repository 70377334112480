import React from 'react';

import { View } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';

import { getDefaultImageStyle } from './DefaultImage.styles';

export const DefaultImage = () => {
  const theme = useTheme();
  const style = getDefaultImageStyle({ theme });
  return (
    <View style={style.imageWrapper} testID="defaultImage">
      <Icon name="CameraOff" color={theme.palette.neutral[400]} />
    </View>
  );
};
