import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgRadioButtonOff = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.898 22.089a9.731 9.731 0 01-3.9-.788 10.09 10.09 0 01-3.175-2.137c-.9-.9-1.613-1.959-2.137-3.175a9.732 9.732 0 01-.788-3.9c0-1.384.262-2.684.788-3.9a10.091 10.091 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138a9.743 9.743 0 013.9-.787c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175a9.733 9.733 0 01.788 3.9 9.732 9.732 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.959 1.612-3.175 2.137a9.731 9.731 0 01-3.9.788zm0-2c2.216 0 4.104-.78 5.663-2.337 1.558-1.559 2.337-3.447 2.337-5.663 0-2.217-.78-4.105-2.337-5.663-1.559-1.558-3.447-2.337-5.663-2.337-2.217 0-4.104.779-5.662 2.337-1.559 1.558-2.338 3.446-2.338 5.663 0 2.216.779 4.104 2.338 5.663 1.558 1.558 3.445 2.337 5.662 2.337z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgRadioButtonOff;
