import React, { useContext, useMemo } from 'react';

import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { useTranslation } from '@app/services/translations/translations';
import { Button, Spacer, Text, Theme, useResponsive, useTheme } from '@app/ui';
import {
  RealEstate,
  usePublicRealEstatesQuery,
  useSearchRealEstatesQuery,
} from '@app/libs/apollo/introspection';
import { MainMenuNavigatorRoutes, PublicNavigatorRoutes } from '@app/navigation/types/routes';
import { isNative } from '@app/constants/platform';
import { customerPreferencesToPreferencesInput } from '@app/utils/customerPreferencesToPreferencesInput';
import { useMe } from '@app/shared/hooks/useMe';
import { trackHomepageSeeAllProperties } from '@app/services/tracking/trackTracking';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';

import { HomePageNewsCarousel } from './HomePageNewsCarousel';
import { getSortedCustomerPref } from '../search-engine/hooks/useGetSearchEngineDatas.utils';
import { MobileNavigationNavigationProp } from '../navigation/navigation.types';
import { SectionContainer } from './SectionContainer';
import { PushToSubscribeModalContent } from '../publicHomePage/PushToSubscribeModal';

const getStyles = ({
  theme,
  isNativeOrResponsive,
  hasRecommendations,
}: {
  theme: Theme;
  isNativeOrResponsive: boolean;
  hasRecommendations?: boolean;
}) =>
  StyleSheet.create({
    buttons: { flexDirection: 'row', marginBottom: theme.spacing.SIZE_06 },
    cardContainer: { flex: 1, marginLeft: theme.spacing.SIZE_06, maxWidth: 308 },
    carouselWrapper: { overflow: 'hidden' },
    container: {
      margin: isNativeOrResponsive ? 0 : theme.spacing.SIZE_06,
      zIndex: 500,
    },
    description: {
      marginBottom: theme.spacing.SIZE_06,
    },
    footer: { marginVertical: theme.spacing.SIZE_07 },
    header: { alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' },
    linearGradient: {
      borderBottomLeftRadius: isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderBottomRightRadius: isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderColor: theme.palette.neutral[100],
      borderTopLeftRadius:
        hasRecommendations && isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderTopRightRadius:
        hasRecommendations && isNativeOrResponsive ? 0 : theme.borderRadius.SIZE_10,
      borderWidth: 1,
      paddingVertical: theme.spacing.SIZE_08,
    },
    loader: { alignItems: 'center', flex: 1, justifyContent: 'center' },
    rightButtonContainer: { paddingRight: theme.spacing.SIZE_09 },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing.SIZE_03,
      paddingHorizontal: theme.spacing.SIZE_07,
    },
  });

interface Props {
  hasRecommendations?: boolean;
  isPublic?: boolean;
}

export const HomePageNews: React.FC<Props> = ({ hasRecommendations = false, isPublic = false }) => {
  const theme = useTheme();
  const { customer } = useMe();
  const navigation = useNavigation<MobileNavigationNavigationProp>();
  const { t } = useTranslation();
  const { isLowerThanLaptop, isNativeOrResponsive } = useResponsive();

  const styles = getStyles({ hasRecommendations, isNativeOrResponsive, theme });
  const customerPref = getSortedCustomerPref(customer);

  const currentFilters = useMemo(
    () => customerPreferencesToPreferencesInput(customerPref),
    [customerPref]
  );

  const { data: dataPublic } = usePublicRealEstatesQuery({ skip: !isPublic });

  const { data } = useSearchRealEstatesQuery({
    fetchPolicy: 'cache-and-network',
    skip: isPublic,
    variables: {
      filters: currentFilters,
      limit: 6,
      withCustomerPreferencesUpdate: false,
    },
  });

  const { openModal, closeModal } = useContext(ModalContext);
  const onSeeAllPress = () => {
    if (isPublic) {
      openModal?.(
        ModalKey.PushToSubscribe,
        <PushToSubscribeModalContent
          loginCb={() => {
            closeModal?.();
            navigation.navigate(PublicNavigatorRoutes.Login as any);
          }}
          subscribeCb={() => {
            closeModal?.();
            navigation.navigate(PublicNavigatorRoutes.Auth);
          }}
        />,
        {
          position: 'center',
          title: <Text textStyle="Headline1Medium">{t('pushToSubscribeModal.title')}</Text>,
          webSize: 's',
        }
      );
      return;
    }

    trackHomepageSeeAllProperties();
    navigation.navigate(MainMenuNavigatorRoutes.RealEstates);
  };

  return (
    <SectionContainer>
      <View style={styles.header}>
        <View>
          <Text textStyle="Title3Medium">{t('homePage.news.title')}</Text>
          <Text textStyle="Body3">{t('homePage.projects.description')}</Text>
          <Spacer height={theme.spacing.SIZE_08} />
        </View>
        {!isLowerThanLaptop && (
          <View style={styles.rightButtonContainer}>
            <Button variant="ghost" iconName="ArrowRight" onPress={onSeeAllPress}>
              {t('homePage.news.seeAllNews')}
            </Button>
          </View>
        )}
      </View>

      {!!data?.searchRealEstates?.realEstates?.length && (
        <HomePageNewsCarousel
          realEstates={data.searchRealEstates.realEstates.slice(0, 6) as RealEstate[]}
        />
      )}
      {!!dataPublic?.publicRealEstates?.length && (
        <HomePageNewsCarousel
          isPublic
          realEstates={dataPublic.publicRealEstates.slice(0, 6) as unknown as RealEstate[]}
        />
      )}
      {!!(isLowerThanLaptop || isNative()) && (
        <View style={styles.footer}>
          <Button variant="tertiary" iconName="ArrowRight" onPress={onSeeAllPress}>
            {t('homePage.news.seeAllNews')}
          </Button>
        </View>
      )}
    </SectionContainer>
  );
};
