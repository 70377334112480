import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = ({ theme, isHovered }: { theme: Theme; isHovered: boolean }) =>
  StyleSheet.create({
    container: {
      justifyContent: 'center',
    },
    text: {
      color: isHovered ? theme.palette.system.primary[500] : theme.palette.neutral[700],
    },
  });
