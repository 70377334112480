import { useEffect, useMemo, useRef } from 'react';

import { useUnleashContext } from '@unleash/proxy-client-react';
import { faker } from '@faker-js/faker';

import { isAssetManagementConsultant as isAssetManagementConsultantUtil } from '@app/utils/customer.utils';

import { useStateOfCustomer } from './useStateOfCustomer';
import { UseCustomer, useCustomer } from './useCustomer';

export enum StateOfCustomer {
  ASSET_MANAGEMENT_CONSULTANT = 'assetManagementConsultant',
  IN_TRANSACTION = 'inTransaction',
  ONGOING_APPOINTMENT = 'ongoingAppointment',
  POST_APPOINTMENT = 'postAppointment',
  POST_SIGNED_MANDATE = 'postSignedMandate',
  PRE_APPOINTMENT = 'preAppointment',
  PRE_SIGNED_MANDATE = 'preSignedMandate',
}

export type CustomerInterface = UseCustomer & {
  isAssetManagementConsultant: boolean;
  stateOfCustomer: StateOfCustomer | null;
};

const useMe = (): CustomerInterface => {
  const customerHook = useCustomer();
  const updateUnleashContext = useUnleashContext();
  const customerRef = useRef(customerHook?.customer?.id);

  const isAssetManagementConsultant = useMemo(
    () => isAssetManagementConsultantUtil(customerHook?.customer),
    [customerHook?.customer]
  );
  const stateOfCustomer = useStateOfCustomer({
    customer: customerHook?.customer,
    isAssetManagementConsultant,
  });

  useEffect(() => {
    if (customerRef.current !== customerHook?.customer?.id) {
      updateUnleashContext({
        sessionId: customerHook?.customer?.id || faker.datatype.uuid(),
        userId: customerHook?.customer?.id,
      });
      customerRef.current = customerHook?.customer?.id;
    }
  }, [customerHook?.customer?.id, updateUnleashContext]);

  return {
    ...customerHook,
    isAssetManagementConsultant,
    stateOfCustomer,
  };
};

export { useMe };
