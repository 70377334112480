import React, { RefObject, forwardRef, useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Button, Text, Theme, getColorWithOpacity, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

const getStyles = ({ isLowerThanLaptop, theme }: { isLowerThanLaptop: boolean; theme: Theme }) =>
  StyleSheet.create({
    authButton: {
      height: theme.spacing.SIZE_10,
      width: isLowerThanLaptop ? 170 : 200,
    },
    buttonsContainer: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
    },
    container: {
      alignItems: 'center',
      backgroundColor: getColorWithOpacity(theme.palette.base.white, 70),
      gap: theme.spacing.SIZE_05,
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
    },
  });

interface RealEstateAuthButtonsProps {
  onPress: () => void;
}

export const RealEstateAuthButtons = forwardRef(
  ({ onPress }: RealEstateAuthButtonsProps, ref: RefObject<View>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isLowerThanLaptop } = useResponsive();
    const styles = useMemo(
      () => getStyles({ isLowerThanLaptop, theme }),
      [isLowerThanLaptop, theme]
    );

    return (
      <View style={styles.container} testID="realEstateAuthButtons">
        <Text textStyle="Body1" style={{ textAlign: 'center' }}>
          {t('propertyInfo.registerTitle')}
        </Text>

        <View style={styles.buttonsContainer} ref={ref}>
          <Button variant="tertiary" onPress={onPress} style={styles.authButton}>
            {t('shared.login')}
          </Button>
          <Button style={styles.authButton} onPress={onPress}>
            {t('shared.register')}
          </Button>
        </View>
      </View>
    );
  }
);

RealEstateAuthButtons.displayName = 'RealEstateAuthButtons';
