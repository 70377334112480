import { CustomerPositioningCancellationReason } from '@app/libs/apollo/introspection';

export const getSortedPositioningCancelReasons = (): CustomerPositioningCancellationReason[] => [
  CustomerPositioningCancellationReason.PricePerSquareMeterTooHigh,
  CustomerPositioningCancellationReason.WorkBudgetTooHigh,
  CustomerPositioningCancellationReason.Location,
  CustomerPositioningCancellationReason.LowYield,
  CustomerPositioningCancellationReason.Condominium,
  CustomerPositioningCancellationReason.Other,
];
