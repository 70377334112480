import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCarpet = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.712 16.712A.965.965 0 0118 17a.965.965 0 01-.712-.288A.965.965 0 0117 16V8c0-.283.096-.521.288-.713A.967.967 0 0118 7c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .283-.096.52-.288.712zM2.588 19.413C2.979 19.804 3.45 20 4 20h16c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V6c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0020 4H4c-.55 0-1.02.196-1.412.588A1.923 1.923 0 002 6v12c0 .55.196 1.021.588 1.413zM20 18H4V6h16v12zM6 17c.283 0 .52-.096.712-.288A.965.965 0 007 16V8a.968.968 0 00-.288-.713A.967.967 0 006 7a.967.967 0 00-.712.287A.968.968 0 005 8v8c0 .283.096.52.288.712A.965.965 0 006 17zM9 7a1 1 0 00-1 1v8a1 1 0 001 1h6a1 1 0 001-1V8a1 1 0 00-1-1H9z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgCarpet;
