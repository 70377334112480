import React from 'react';

import { Text } from '@app/ui/atoms/Text/Text';
import { getTranslatedContentStyle } from '@app/ui/atoms/translation-content/TranslationContent.style';
import { useTheme } from '@app/ui/contexts/config';

import { TranslationContentProps } from './TranslationContent.types';

export const TranslationContent: React.FC<TranslationContentProps> = ({
  translation,
  showTranslation,
  textStyle = 'Body2',
  dark,
  numberOfLines,
}) => {
  const theme = useTheme();
  const { sourceText, translatedText } = translation;

  const style = getTranslatedContentStyle({ dark, theme });

  return (
    <Text textStyle={textStyle} style={style.text} numberOfLines={numberOfLines}>
      {showTranslation ? translatedText?.trim() : sourceText?.trim()}
    </Text>
  );
};

export * from './TranslationContent.types';
