import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { isWeb } from '@app/constants/platform';

import { Color, SidebarItemStyle } from './SidebarItem.types';

export const getColor = ({ theme, hovered, active, disabled }: Color) => {
  let color = theme.palette.neutral[700];

  if (hovered) {
    color = theme.palette.system.primary[400];
  }

  if (active) {
    color = theme.palette.system.primary[500];
  }

  if (disabled) {
    color = theme.palette.neutral[500];
  }

  return color;
};

export const getLabelTextStyle = (theme: Theme) =>
  isWeb() ? theme.textStyles.Button2 : theme.textStyles.Caption2Demi;

export const containerStyle = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    flexDirection: isWeb() ? 'row' : 'column',
  },
});

export const getSidebarItemStyle = ({ theme, hovered, active, disabled }: SidebarItemStyle) => {
  return StyleSheet.create({
    label: {
      paddingLeft: isWeb() ? theme.spacing.SIZE_03 : theme.spacing.NONE,
      ...getLabelTextStyle(theme),
      color: getColor({
        active,
        disabled,
        hovered,
        theme,
      }),
    },
    notification: {
      backgroundColor: theme.palette.tint.red[500],
      borderRadius: theme.spacing.SIZE_03,
      height: theme.spacing.SIZE_03,
      left: -theme.spacing.SIZE_02,
      position: 'absolute',
      top: -theme.spacing.SIZE_02,
      width: theme.spacing.SIZE_03,
    },
  });
};
