import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChevronDown = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12.007 16c-.144 0-.278-.026-.403-.077a.934.934 0 01-.35-.258l-4.972-5.637c-.197-.224-.29-.503-.281-.839.008-.335.111-.614.308-.837a.96.96 0 01.753-.336.96.96 0 01.752.336l4.193 4.753 4.22-4.784a.903.903 0 01.739-.32c.295.01.541.127.738.35.197.224.296.508.296.854 0 .345-.099.63-.296.853l-4.945 5.607a.935.935 0 01-.35.258c-.125.051-.259.077-.402.077z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChevronDown;
