import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Theme, TranslationContentGroup, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { TranslatableWithSwitchWrapper } from '@app/shared/components/TranslatableWithSwithWrapper/TransalatableWithSwitchWrapper';
import { ApollonTranslation } from '@app/libs/apollo/introspection';

interface RealEstateHunterDescriptionsProps {
  translation: ApollonTranslation;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    descriptionContainer: {
      flexDirection: 'column',
      gap: theme.spacing.SIZE_07,
      wordBreak: 'break-word',
    },
  });

export const RealEstateHuntersDescription: React.FC<RealEstateHunterDescriptionsProps> = ({
  translation,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const hasTranslatedText = !!translation?.translatedText;

  return (
    <TranslatableWithSwitchWrapper hasTranslatedText={hasTranslatedText}>
      <View style={styles.descriptionContainer}>
        <Text textStyle="Headline1Medium">{t('propertyDescription.generalAbout')}</Text>
        <TranslationContentGroup.Item textStyle="Body2Medium" translation={translation} />
      </View>
    </TranslatableWithSwitchWrapper>
  );
};
