import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgPlumbing = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="m19.276 9.177-3.524-3.525-2.126 2.1-2.124-2.1 2.824-2.85c.2-.2.425-.346.675-.438a2.166 2.166 0 0 1 1.513 0c.242.092.454.238.637.438l2.125 2.125c.3.283.521.612.663.987.142.375.212.754.212 1.138 0 .383-.07.758-.212 1.125a2.87 2.87 0 0 1-.663 1Zm-13.774 4.6c-.3-.3-.45-.655-.45-1.063 0-.408.15-.762.45-1.062l2.45-2.475 2.124 2.125-2.474 2.475c-.284.3-.63.45-1.038.45-.408 0-.762-.15-1.062-.45Zm-1.075 7.425c-.184-.2-.325-.421-.425-.663-.1-.242-.15-.496-.15-.762 0-.267.045-.521.137-.763.092-.242.238-.462.438-.662l7.075-7.05-3.175-3.2c-.3-.284-.45-.63-.45-1.038 0-.408.15-.762.45-1.062.283-.3.633-.45 1.05-.45.416 0 .774.15 1.074.45l3.175 3.175 1.426-1.425 2.8 2.85c.2.2.3.433.3.7 0 .266-.1.5-.3.7-.2.2-.434.3-.7.3a.96.96 0 0 1-.7-.3l-9.2 9.2c-.2.2-.421.345-.663.437a2.006 2.006 0 0 1-1.487-.012c-.25-.1-.475-.242-.675-.425Z"
    />
  </Svg>
);
export default SvgPlumbing;
