import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgCa = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="ca_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#ca_svg__a)" fill="#FF3131">
        <Path d="M20 0h8v20h-8z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20h8V0H0v20zM16.112 9.222a.333.333 0 01-.563-.302L16 6.667l-1.333.666-.667-2-.667 2L12 6.667l.45 2.253a.333.333 0 01-.562.302l-.319-.32a.333.333 0 00-.471 0l-.431.431-1.334-.666L10 10l-.43.431a.333.333 0 000 .471l1.763 1.765h2l.334 2h.666l.334-2h2l1.764-1.765a.333.333 0 000-.471L18 10l.667-1.333-1.334.666-.43-.43a.333.333 0 00-.472 0l-.32.319z"
        />
      </G>
    </Svg>
  );
};

export default SvgCa;
