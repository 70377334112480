import React, { Fragment, useMemo } from 'react';

import * as localeList from 'date-fns/locale';
import { View } from 'react-native';

import { Callout, Spacer, Spacing, useResponsive, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';
import { useMyProjectsList } from '@app/features/projectList/useProjectList.hook';
import { useMe } from '@app/shared/hooks/useMe';
import { RealEstate } from '@app/libs/apollo/introspection';
import { getExpirationDateString } from '@app/utils/real-estate/getExpirationDateString.utils';

import { getPositioningCalloutList, getTransactionCalloutProps } from './ShowCaseCallout.utils';

interface ShowCaseCalloutProps {
  realEstate: RealEstate;
}

export const ShowCaseCallout: React.FC<ShowCaseCalloutProps> = ({ realEstate }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { isGreaterThanTablet } = useResponsive();
  const { data: { myProjects } = { myProjects: [] }, loading: loadingProjects } =
    useMyProjectsList();
  const { stateOfCustomer, customer } = useMe();

  const locale = localeList[i18n.resolvedLanguage];
  const positioningExpirationDate = realEstate?.metadata?.positioning?.expirationDate;

  const list = useMemo(() => {
    const positioningExpirationDateStringProps = {
      expirationDate: new Date(positioningExpirationDate),
      full: true,
      locale,
      translate: t,
    };

    const { contact } = realEstate;
    const isOwnAdvisor = contact?.id === customer?.owner?.id;

    return [
      {
        id: 'callout-positioning-status',
        props:
          getPositioningCalloutList({
            avatarS3ID: contact?.avatarS3ID,
            expiredText: positioningExpirationDate
              ? getExpirationDateString(positioningExpirationDateStringProps)
              : undefined,
            firstName: contact?.firstName,
            isGreaterThanTablet,
            isOwnAdvisor,
            lastName: contact?.lastName,
            phoneNumber: contact?.phoneNumber,
            t,
            theme,
          })[realEstate.metadata?.positioning?.status] || null,
      },
      {
        id: 'callout-in-transaction',
        props: getTransactionCalloutProps({ loadingProjects, projects: myProjects, realEstate, t }),
      },
    ].filter(callout => callout.props !== null);
  }, [
    customer?.owner,
    isGreaterThanTablet,
    loadingProjects,
    locale,
    myProjects,
    positioningExpirationDate,
    realEstate,
    stateOfCustomer,
    t,
    theme,
  ]);

  const callouts = useMemo(
    () =>
      list.map(({ id, props }, i) => (
        <Fragment key={i}>
          <Callout {...props} testID={id} />
          <Spacer height={Spacing.SIZE_04} />
        </Fragment>
      )),
    [list]
  );

  return callouts ? <View>{callouts}</View> : null;
};
