import { fromS3ID } from '@app/utils/from-s3id';
import {
  PositioningStatus,
  ProjectRentingShortTerm,
  PropertyStatuses,
  RealEstate,
} from '@app/libs/apollo/introspection';
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';

import { getDistrict } from './utils/get-district.utils';

type RealEstateToItemMapperParam = Pick<
  RealEstate,
  'id' | 'type' | 'coverImage' | 'surface' | 'roomCount' | 'status' | 'isNew' | 'isExclusive'
> & {
  building: Pick<RealEstate['building'], 'totalLots'> & {
    address: Pick<RealEstate['building']['address'], 'city' | 'postalCode'>;
  };
  annual: Pick<RealEstate['annual'], 'grossReturn' | 'netReturn'>;
  project: {
    projectAmount: number;
    renting?: {
      shortTerm?: Pick<ProjectRentingShortTerm, 'nightlyRent'> | null;
    };
  };
  metadata: Pick<RealEstate['metadata'], 'isFavorites' | 'isViewed'> & {
    positioning?: {
      status?: PositioningStatus;
    } | null;
  };
};

export const realEstateToItemMapper = ({
  id,
  type,
  coverImage,
  surface,
  roomCount,
  annual,
  project,
  building,
  status,
  isNew,
  isExclusive,
  metadata,
}: RealEstateToItemMapperParam): RealEstatePreviewItem => {
  const { isFavorites: isBookmarked, isViewed, positioning } = metadata || {};
  const {
    address: { city, postalCode },
    totalLots: lots,
  } = building;

  const district = getDistrict(postalCode);
  const title = district ? `${city} - ${district}` : city;

  const { projectAmount: price } = project || {};
  return {
    grossReturnRate: annual.grossReturn ? annual.grossReturn / 100 : 0,
    id,
    image: fromS3ID(coverImage, 'w-600'),
    isBookmarked,
    isExclusive,
    isInTransaction: status === PropertyStatuses.Transaction,
    isNew: !!isNew,
    isViewed: isViewed,
    key: id,
    netReturnRate: annual.netReturn ? annual.netReturn / 100 : 0,
    positioningStatus: positioning?.status,
    price,
    propertyKind: type,
    roomCount: roomCount ?? undefined,
    shortTermNightlyRent: project.renting?.shortTerm?.nightlyRent,
    surface: surface ?? 0,
    title,
    totalLots: lots ?? 0,
  };
};
