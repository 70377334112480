import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgDoorBack = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M4 21a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 20c0-.283.096-.52.288-.712A.967.967 0 0 1 4 19h1V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 7 3h10c.55 0 1.02.196 1.413.587C18.803 3.98 19 4.45 19 5v14h1c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 20 21H4Zm3-2h10V5H7v14Zm3-6c.283 0 .52-.096.713-.287A.968.968 0 0 0 11 12a.968.968 0 0 0-.287-.713A.968.968 0 0 0 10 11a.967.967 0 0 0-.713.287A.968.968 0 0 0 9 12c0 .283.096.52.287.713.192.191.43.287.713.287Z"
    />
  </Svg>
);
export default SvgDoorBack;
