import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgChat = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3 20.058V5.828c0-.503.176-.933.53-1.292A1.72 1.72 0 014.8 4h14.4c.495 0 .919.179 1.272.536.352.359.528.789.528 1.291v10.965c0 .502-.176.933-.528 1.29a1.722 1.722 0 01-1.272.537H6.6l-2.07 2.102c-.285.289-.611.354-.98.193-.366-.16-.55-.445-.55-.856zm1.8-2.193l1.058-1.073H19.2V5.827H4.8v12.038z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgChat;
