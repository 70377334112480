import React, { useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { useTranslation } from '@app/services/translations/translations';
import { NpsCategory, NpsResponse, useAnswerNpsMutation } from '@app/libs/apollo/introspection';
import { Button, Checkbox, Spacer, Spacing, Text, TextArea, Theme, useTheme } from '@app/ui';
import { isWeb } from '@app/constants/platform';

import { NPSAnswers, NPSGroup } from './constants/NPS.constants';

const NPS_SCORES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

enum NPSStep {
  score = 'score',
  answer = 'answer',
  suggestion = 'suggestion',
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    buttonGroup: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: theme.spacing.SIZE_04,
    },
    checkboxGroup: {
      alignItems: 'flex-start',
      marginTop: theme.spacing.SIZE_04,
    },
    scoreButtonWrapper: {
      borderRadius: 50,
      flex: 1,

      marginLeft: theme.spacing.SIZE_01,
      marginRight: theme.spacing.SIZE_01,
    },
    stepWrapper: { flex: isWeb() ? 1 : 0, justifyContent: 'space-between' },
    submitButtonWrapper: { flexDirection: 'row-reverse', marginVertical: theme.spacing.SIZE_04 },
  });

const getNPSGroup = (score: string | number) => {
  const _score = parseInt(score.toString());
  if (_score < 7) {
    return NPSGroup.detractors;
  }
  if (_score < 9) {
    return NPSGroup.passives;
  }
  return NPSGroup.promoters;
};

type NPSProps = {
  category: NpsCategory;
};

export const NPSContent: React.FC<NPSProps> = ({ category }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { handleSubmit, control, watch } = useForm<NpsResponse>({
    defaultValues: { answer: [], category },
  });
  const [step, setStep] = useState<NPSStep>(NPSStep.score);
  const [answerNpsMutation, { loading }] = useAnswerNpsMutation();

  const onSubmit: SubmitHandler<NpsResponse> = (npsResponse: NpsResponse) => {
    answerNpsMutation({ variables: { npsResponse } });
  };

  const watchScore = watch('score');
  const watchAnswer = watch('answer');

  const getNPSAnswers = (npsGroup: NPSGroup) => {
    return NPSAnswers[category][npsGroup];
  };

  const increaseStep = () => {
    if (step === NPSStep.score) {
      setStep(NPSStep.answer);
    }
    if (step === NPSStep.answer) {
      setStep(NPSStep.suggestion);
    }
  };

  return (
    <>
      {step === NPSStep.score && (
        <View style={styles.stepWrapper}>
          <Text textStyle="Headline3Medium">{t(`nps.${category}.form.title`)}</Text>
          <Spacer height={theme.spacing.SIZE_03} />
          <Text textStyle="Body3">{t(`nps.${category}.form.subtitle`)}</Text>
          <Spacer height={theme.spacing.SIZE_05} />
          <Controller
            control={control}
            name="score"
            render={({ field: { value, onChange } }) => (
              <View style={{ width: '100%' }}>
                <Text textStyle="Body2Medium">{t(`nps.${category}.form.scoreLabel`)}</Text>
                <Spacer height={Spacing.SIZE_04} />
                <View style={styles.buttonGroup}>
                  {NPS_SCORES.map(score => (
                    <Button
                      wrapperStyle={styles.scoreButtonWrapper}
                      textStyle={{
                        flex: 1,
                        margin: -Spacing.SIZE_04,
                        paddingHorizontal: Spacing.SIZE_01,

                        textAlign: 'center',
                        width: 20,
                      }}
                      style={{
                        padding: Spacing.SIZE_00,
                      }}
                      key={`button-score--${score}`}
                      onPress={() => {
                        onChange(score);
                      }}
                      size="s"
                      variant={value === score ? 'secondary' : 'tertiary'}
                    >
                      {score.toString()}
                    </Button>
                  ))}
                </View>
                <Spacer height={Spacing.SIZE_04} />
              </View>
            )}
          />
          <View style={{ flexDirection: 'row-reverse' }}>
            <Button variant="primary" disabled={!watchScore} size="s" onPress={increaseStep}>
              {t(`nps.${category}.form.next`)}
            </Button>
          </View>
        </View>
      )}
      {step === NPSStep.answer && (
        <View style={styles.stepWrapper}>
          <Controller
            control={control}
            name="answer"
            render={({ field: { value, onChange } }) => (
              <>
                <Text textStyle="Body2Medium">
                  {t(`nps.${category}.form.${getNPSGroup(watchScore)}Label`)}
                </Text>
                <View style={styles.checkboxGroup}>
                  {getNPSAnswers(getNPSGroup(watchScore)).map(answer => (
                    <Checkbox
                      size="m"
                      checked={value.includes(answer)}
                      label={t(`nps.${category}.form.${answer}Label`)}
                      key={answer}
                      onChange={() => {
                        let newValue = [];
                        if (value.includes(answer)) {
                          newValue = value.filter(item => item !== answer);
                        } else {
                          newValue = [...value, answer];
                        }
                        console.log('--SETTING VALUE', newValue);
                        onChange(newValue);
                      }}
                    />
                  ))}
                </View>
              </>
            )}
          />
          <View style={styles.submitButtonWrapper}>
            <Button
              variant="primary"
              disabled={!watchAnswer?.length}
              size="s"
              onPress={increaseStep}
            >
              {t(`nps.${category}.form.next`)}
            </Button>
          </View>
        </View>
      )}
      {step === NPSStep.suggestion && (
        <View style={styles.stepWrapper}>
          <Controller
            control={control}
            name="suggestion"
            render={({ field: { value, onChange } }) => (
              <>
                <Text textStyle="Body2Medium">{t(`nps.${category}.form.suggestionLabel`)}</Text>
                <TextArea
                  value={value || ''}
                  onChangeText={v => {
                    onChange(v);
                    increaseStep();
                  }}
                />
              </>
            )}
          />
          <View style={styles.submitButtonWrapper}>
            <Button disabled={loading} size="s" onPress={handleSubmit(onSubmit)}>
              {t(`nps.${category}.form.submit`)}
            </Button>
          </View>
        </View>
      )}
    </>
  );
};
