import React, { RefObject, useMemo, useRef } from 'react';

import { Control, Controller } from 'react-hook-form';
import { StyleSheet, TextInput, View } from 'react-native';

import { Button, Checkbox, Input, PhoneInput, Spacer, Theme, useTheme } from '@app/ui';
import { isWeb } from '@app/constants/platform';
import { useTranslation } from '@app/services/translations/translations';

import { CguRegister } from './CguRegister';

export interface RegisterForm {
  email: string;
  firstName: string;
  isSubscribeNewsletter: boolean;
  lastName: string;
  password: string;
  phoneNumberPrefix: string;
  phoneNumberSuffix: string;
  terms: boolean;
}

interface StepRegisterFormProps {
  control: Control<RegisterForm>;
  loading: boolean;
  showEmailPasswordInputs: boolean;
  onSubmit: () => void;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    input: {
      width: '100%',
    },
    inputs: {
      gap: theme.spacing.SIZE_04,
    },
  });

export const StepRegisterForm: React.FC<StepRegisterFormProps> = ({
  control,
  loading = false,
  showEmailPasswordInputs,
  onSubmit,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const { t } = useTranslation();

  const lastNameRef = useRef<TextInput>(null);
  const emailRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);

  const toNextInput = (nextInputRef: RefObject<TextInput>) => () => {
    nextInputRef.current?.focus();
  };

  return (
    <>
      <View style={styles.inputs}>
        <Controller
          control={control}
          name="firstName"
          render={({ field: { value, onChange } }) => (
            <Input
              autoCorrect={false}
              label={t('shared.firstName')}
              onSubmitEditing={toNextInput(lastNameRef)}
              testID="onboarding-first-name-input"
              value={value}
              onChangeText={onChange}
              wrapperStyle={styles.input}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          render={({ field: { value, onChange } }) => (
            <Input
              ref={lastNameRef}
              value={value}
              onChangeText={onChange}
              label={t('shared.lastName')}
              onSubmitEditing={toNextInput(emailRef)}
              testID="onboarding-last-name-input"
              wrapperStyle={styles.input}
              autoCorrect={false}
            />
          )}
        />

        {!!showEmailPasswordInputs && (
          <>
            <Controller
              control={control}
              name="email"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  ref={emailRef}
                  value={value}
                  onChangeText={onChange}
                  label={t('register.emailLabel')}
                  placeholder={t('register.emailPlaceholder')}
                  onSubmitEditing={toNextInput(passwordRef)}
                  error={error?.message}
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="email-address"
                  testID="onboarding-email-input"
                  textContentType="emailAddress"
                  wrapperStyle={styles.input}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  ref={passwordRef}
                  value={value}
                  onChangeText={onChange}
                  error={error?.message}
                  autoCapitalize="none"
                  isPassword
                  label={t('register.passwordLabel')}
                  testID="onboarding-password-input"
                  textContentType="password"
                  wrapperStyle={styles.input}
                />
              )}
            />
          </>
        )}

        <Controller
          name="phoneNumberPrefix"
          control={control}
          render={({ field: { onChange: onPrefixChange, value: prefixValue } }) => (
            <Controller
              name="phoneNumberSuffix"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  value={value}
                  countryValue={prefixValue}
                  onChange={onChange}
                  onCountryChange={v => onPrefixChange(v?.toString())}
                  label={t('register.phoneLabel')}
                  placeholder={t('register.phoneNumberPlaceholder')}
                  error={error?.message}
                  testID="onboarding-phone-number-input"
                />
              )}
            />
          )}
        />
      </View>

      <Spacer height={theme.spacing.SIZE_09} />

      <Controller
        control={control}
        name="isSubscribeNewsletter"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={value}
            onChange={onChange}
            label={t(`register.newsletterLabel`)}
            hideErrorMessage={false}
            testID="onboarding-newsletter-input"
          />
        )}
      />

      <Controller
        control={control}
        name="terms"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={value}
            onChange={onChange}
            hideErrorMessage={false}
            testID="onboarding-terms-input"
          >
            <CguRegister isWeb={isWeb()} />
          </Checkbox>
        )}
      />

      <Spacer height={theme.spacing.SIZE_04} />

      <Button
        variant="primary"
        onPress={onSubmit}
        isLoading={loading}
        disabled={loading}
        testID="onboarding-submit-button"
      >
        {t('register.submitButton')}
      </Button>
    </>
  );
};
