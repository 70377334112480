import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';
import {
  AvailableCountryCode,
  CountryCodeOption,
  CustomerPreferencesInput,
} from '@app/libs/apollo/introspection';

export const avalaibleCountryCodeToCountryCodeOption = (
  country: AvailableCountryCode // We can't map AvailableCountryCode and CountryCodeOption types, so we force it and check at runtime
): CountryCodeOption =>
  country === null
    ? CountryCodeOption.All
    : Object.values(CountryCodeOption).find(v => v === (country as unknown as CountryCodeOption));

export const filterToPreferencesMapper = ({
  alreadyRented,
  atGroundFloor,
  budgetMax,
  budgetMin,
  country,
  flatSharing,
  regionList,
  roomCount,
  typology,
  withRenovation,
  withShortTermRental,
  sortStrategy,
  zones,
}: SearchEngineFilterForm): CustomerPreferencesInput => {
  const countryCode = avalaibleCountryCodeToCountryCodeOption(country);
  const useZoneInsteadOfRegions = countryCode === CountryCodeOption.Fr;

  return {
    budgetMax,
    budgetMin,
    countryCode,
    hasNeedRenovation: withRenovation,
    propertyKind: typology,
    propertyOnTheGroundFloor: atGroundFloor,
    propertyRented: alreadyRented,
    propertyRoomCount: roomCount,
    regions: useZoneInsteadOfRegions ? [] : regionList,
    sharedProperty: flatSharing,
    sortStrategy,
    withShortTermRental,
    zones: useZoneInsteadOfRegions ? zones : [],
  };
};
