import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgStars = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M19 8.3a.547.547 0 01-.262-.075A.386.386 0 0118.55 8l-.8-1.75-1.75-.8a.386.386 0 01-.225-.188A.547.547 0 0115.7 5c0-.083.025-.171.075-.263A.387.387 0 0116 4.55l1.75-.8.8-1.75a.386.386 0 01.188-.225A.547.547 0 0119 1.7c.083 0 .171.025.263.075.091.05.154.125.187.225l.8 1.75 1.75.8c.1.033.175.096.225.187.05.092.075.18.075.263 0 .083-.025.17-.075.262A.386.386 0 0122 5.45l-1.75.8-.8 1.75a.387.387 0 01-.187.225A.552.552 0 0119 8.3zm0 14a.546.546 0 01-.262-.075.386.386 0 01-.188-.225l-.8-1.75-1.75-.8a.387.387 0 01-.225-.188A.546.546 0 0115.7 19c0-.083.025-.17.075-.262A.387.387 0 0116 18.55l1.75-.8.8-1.75a.386.386 0 01.188-.225A.547.547 0 0119 15.7c.083 0 .171.025.263.075.091.05.154.125.187.225l.8 1.75 1.75.8c.1.033.175.096.225.188.05.091.075.179.075.262 0 .083-.025.17-.075.262a.387.387 0 01-.225.188l-1.75.8-.8 1.75a.387.387 0 01-.187.225.552.552 0 01-.263.075zM9 18.575c-.183 0-.358-.05-.525-.15A.932.932 0 018.1 18l-1.6-3.5L3 12.9a.933.933 0 01-.425-.375c-.1-.167-.15-.342-.15-.525s.05-.358.15-.525A.932.932 0 013 11.1l3.5-1.6L8.1 6a.932.932 0 01.375-.425c.167-.1.342-.15.525-.15s.358.05.525.15c.167.1.292.242.375.425l1.6 3.5 3.5 1.6a.932.932 0 01.425.375c.1.167.15.342.15.525s-.05.358-.15.525A.933.933 0 0115 12.9l-3.5 1.6L9.9 18a.932.932 0 01-.375.425c-.167.1-.342.15-.525.15zm0-3.425L10 13l2.15-1L10 11 9 8.85 8 11l-2.15 1L8 13l1 2.15z"
        fill={props.color}
      />
    </Svg>
  );
};
export default SvgStars;
