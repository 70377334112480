import helloImg from '@assets/illustrations/onboarding/hello.png';
import supportImg from '@assets/illustrations/onboarding/support.png';
import searchImg from '@assets/illustrations/onboarding/search.png';
import renovateImg from '@assets/illustrations/onboarding/renovate.png';
import rentImg from '@assets/illustrations/onboarding/rent.png';

import { ActionType, OnboardingPagesScreenType } from '../Onboarding.types';

export const getOnboardingPagesScreen = (customerfirstName: string): OnboardingPagesScreenType => [
  {
    description: 'onboarding.hello.text',
    image: helloImg,
    key: 'hello',
    pageTrackingEvent: 'View onboarding hello page',
    primaryAction: ActionType.NEXT,
    primaryTitle: 'onboarding.hello.primaryButton',
    subTitle: customerfirstName,
    title: 'onboarding.hello.title',
  },
  {
    description: 'onboarding.search.text',
    image: searchImg,
    key: 'find',
    pageTrackingEvent: 'View onboarding find page',
    primaryAction: ActionType.NEXT,
    primaryTitle: 'shared.next',
    title: 'onboarding.search.title',
  },
  {
    description: 'onboarding.renovate.text',
    image: renovateImg,
    key: 'renovate',
    pageTrackingEvent: 'View onboarding renovate page',
    primaryAction: ActionType.NEXT,
    primaryTitle: 'shared.next',
    title: 'onboarding.renovate.title',
  },
  {
    description: 'onboarding.rent.text',
    image: rentImg,
    key: 'rent',
    pageTrackingEvent: 'View onboarding rent page',
    primaryAction: ActionType.NEXT,
    primaryTitle: 'shared.next',
    title: 'onboarding.rent.title',
  },
  {
    description: 'onboarding.support.text',
    image: supportImg,
    key: 'appointement',
    pageTrackingEvent: 'View onboarding appointment page',
    primaryAction: ActionType.ADVISOR,
    primaryTitle: 'onboarding.support.primaryButton',
    secondaryAction: ActionType.HOME,
    secondaryTitle: 'onboarding.support.secondaryButton',
    title: 'onboarding.support.title',
  },
];
