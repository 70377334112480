import React, { useMemo } from 'react';

import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { inputTheme } from '@app/ui/atoms/input/input.theme';

import { Input } from './Input';
import { getStyles } from './TextArea.styles';

export interface TextAreaProps {
  onChangeText?: (newValue: string) => void;
  value?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  numberOflines?: number;
  maximCharacters?: number;
}

export const TextArea = ({
  onChangeText,
  value,
  placeholder,
  error,
  disabled,
  label,
  numberOflines = 50,
  maximCharacters = 200,
}: TextAreaProps) => {
  const componentTheme = useComponentTheme('input', inputTheme);
  const styles = getStyles(componentTheme);
  const hint = useMemo(() => `${value?.length || 0}/${maximCharacters}`, [value, maximCharacters]);

  return (
    <Input
      isTextArea
      numberOfLines={numberOflines}
      onChangeText={onChangeText}
      value={value}
      placeholder={placeholder}
      error={error}
      hint={hint}
      editable={!disabled}
      label={label}
      style={styles.input}
    />
  );
};
