import { BottomSheetWebSize } from './BottomSheet.types';

export const getWebSize = (size: BottomSheetWebSize, windowWidth: number): number | string => {
  if (size === 's') {
    if (windowWidth > 400) {
      return 400;
    }
    return '90%';
  }
  if (size === 'm') {
    if (windowWidth > 600) {
      return 600;
    }
    return '90%';
  }

  if (size === 'l') {
    if (windowWidth > 760) {
      return 760;
    }
    return '90%';
  }

  return '90%';
};
