import { useApolloClient } from '@apollo/client';

import { storage } from '@app/services/storage/storage';

import { useMe } from './useMe';

export interface UseLogout {
  logout: () => Promise<void>;
}

export const useLogout = (): UseLogout => {
  const apolloClient = useApolloClient();
  const { customer } = useMe();

  const logout = async () => {
    await Promise.all([
      storage.clear('@storage-last-opened-tab'),
      storage.clear('appointment_just_booked'),
      storage.clear('token'),
      storage.clear('feathers-jwt'), // TODO: to remove when feathers will be deleted
    ]);
    apolloClient.cache.evict({
      id: apolloClient.cache.identify({ __typename: 'Customer', id: customer.id }),
    });
  };

  return { logout };
};
