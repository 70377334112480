import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgInvest = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3.75 19.75a1.03 1.03 0 01-.325-.75c0-.283.108-.533.325-.75L9.8 12.2c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.883.883 0 01.325.213l3.3 3.3 6.4-7.2a.89.89 0 01.713-.325c.291 0 .537.1.737.3.183.183.28.404.288.663a.942.942 0 01-.263.687L15.2 17.7a1 1 0 01-.337.262.906.906 0 01-.388.088 1.03 1.03 0 01-.725-.3L10.5 14.5l-5.25 5.25a1.03 1.03 0 01-.75.325 1.03 1.03 0 01-.75-.325zM4 13.3a.551.551 0 01-.25-.063.45.45 0 01-.2-.212l-.5-1.075-1.075-.5a.45.45 0 01-.212-.2A.552.552 0 011.7 11a.55.55 0 01.063-.25.45.45 0 01.212-.2l1.075-.5.5-1.075c.05-.1.117-.171.2-.213A.558.558 0 014 8.7c.083 0 .167.02.25.062.083.042.15.113.2.213l.5 1.075 1.075.5c.183.083.275.233.275.45s-.092.367-.275.45l-1.075.5-.5 1.075a.45.45 0 01-.2.212.551.551 0 01-.25.063zm11-2a.551.551 0 01-.25-.063.45.45 0 01-.2-.212l-.5-1.075-1.075-.5a.45.45 0 01-.212-.2A.551.551 0 0112.7 9c0-.083.021-.167.063-.25a.45.45 0 01.212-.2l1.075-.5.5-1.075c.05-.1.117-.171.2-.213A.558.558 0 0115 6.7c.083 0 .167.02.25.062.083.042.15.113.2.213l.5 1.075 1.075.5c.1.05.171.117.213.2A.559.559 0 0117.3 9c0 .083-.02.167-.062.25a.456.456 0 01-.213.2l-1.075.5-.5 1.075a.45.45 0 01-.2.212.551.551 0 01-.25.063zm-6.5-3a.482.482 0 01-.25-.075.53.53 0 01-.2-.225L7.4 6.6 6 5.95a.53.53 0 01-.225-.2.482.482 0 01-.075-.25c0-.083.025-.167.075-.25A.53.53 0 016 5.05l1.4-.65.65-1.4a.53.53 0 01.2-.225.482.482 0 01.25-.075c.083 0 .167.025.25.075a.53.53 0 01.2.225l.65 1.4 1.4.65c.1.05.175.117.225.2.05.083.075.167.075.25a.482.482 0 01-.075.25.53.53 0 01-.225.2l-1.4.65L8.95 8a.53.53 0 01-.2.225.482.482 0 01-.25.075z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgInvest;
