import { GalleryImagesProps } from '../../gallery/gallery.types';

const masteosFurnitureImagesPath = '/masteosapp/furniture';
const furniturePackGalleryTitle = 'propertyRenovation.furniture';

const LOFT_GALLERY = {
  description: 'propertyRenovation.furniturePack.loftDescription',
  images: [
    `${masteosFurnitureImagesPath}/loft/loft_1.jpg`,
    `${masteosFurnitureImagesPath}/loft/loft_2.jpg`,
    `${masteosFurnitureImagesPath}/loft/loft_3.jpg`,
    `${masteosFurnitureImagesPath}/loft/loft_4.jpg`,
    `${masteosFurnitureImagesPath}/loft/loft_5.jpg`,
    `${masteosFurnitureImagesPath}/loft/loft_6.jpg`,
  ],
  name: 'propertyRenovation.furniturePack.loftName',
  slug: 'loft',
  title: furniturePackGalleryTitle,
};

const NORDIC_GALLERY = {
  description: 'propertyRenovation.furniturePack.nordicDescription',
  images: [
    `${masteosFurnitureImagesPath}/nordic/nordique_1.jpg`,
    `${masteosFurnitureImagesPath}/nordic/nordique_2.jpg`,
    `${masteosFurnitureImagesPath}/nordic/nordique_3.jpg`,
    `${masteosFurnitureImagesPath}/nordic/nordique_4.jpg`,
    `${masteosFurnitureImagesPath}/nordic/nordique_5.jpg`,
  ],
  name: 'propertyRenovation.furniturePack.nordicName',
  slug: 'nordic',
  title: furniturePackGalleryTitle,
};

const VINTAGE_GALLERY = {
  description: 'propertyRenovation.furniturePack.vintageDescription',
  images: [
    `${masteosFurnitureImagesPath}/vintage/vintage_1.jpg`,
    `${masteosFurnitureImagesPath}/vintage/vintage_2.jpg`,
    `${masteosFurnitureImagesPath}/vintage/vintage_3.jpg`,
    `${masteosFurnitureImagesPath}/vintage/vintage_4.jpg`,
    `${masteosFurnitureImagesPath}/vintage/vintage_5.jpg`,
    `${masteosFurnitureImagesPath}/vintage/vintage_6.jpg`,
  ],
  name: 'propertyRenovation.furniturePack.vintageName',
  slug: 'vintage',
  title: furniturePackGalleryTitle,
};

const JAPANDI_GALLERY = {
  description: 'propertyRenovation.furniturePack.japandiDescription',
  images: [
    `${masteosFurnitureImagesPath}/japandi/japandi_1.jpg`,
    `${masteosFurnitureImagesPath}/japandi/japandi_2.jpg`,
    `${masteosFurnitureImagesPath}/japandi/japandi_3.jpg`,
    `${masteosFurnitureImagesPath}/japandi/japandi_4.jpg`,
    `${masteosFurnitureImagesPath}/japandi/japandi_5.jpg`,
    `${masteosFurnitureImagesPath}/japandi/japandi_6.jpg`,
  ],
  name: 'propertyRenovation.furniturePack.japandiName',
  slug: 'japandi',
  title: furniturePackGalleryTitle,
};

export const staticFurniturePackGalleries: GalleryImagesProps[] = [
  LOFT_GALLERY,
  NORDIC_GALLERY,
  VINTAGE_GALLERY,
  JAPANDI_GALLERY,
];
