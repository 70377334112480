import { Theme } from '@app/ui/theme/types';

export const checkboxTheme = (theme: Theme) => ({
  color: {
    active: theme.palette.neutral[900],
    default: theme.palette.neutral[300],
    disabled: theme.palette.neutral[300],
    error: theme.palette.alert.error,
  },
  size: {
    labelMargin: theme.spacing.SIZE_03,
    size: {
      l: theme.spacing.SIZE_07,
      m: theme.spacing.SIZE_06,
    },
  },
  text: {
    default: theme.palette.neutral[900],
    disabled: theme.palette.neutral[600],
    l: theme.textStyles.Body2,
    m: theme.textStyles.Body3,
  },
});
