import React from 'react';

import { snakeCase } from 'lodash';
import { ViewStyle } from 'react-native';

import { DataBlock, useResponsive, useTranslationContentContext } from '@app/ui';
import { ApollonTranslation, SynthesisTheme } from '@app/libs/apollo/introspection';

import { getIconFromTheme } from '../utils/realEstateSynthesisItem.utils';

export const RealEstateSynthesisItem: React.FC<{
  t: (key: string) => string;
  thematic: SynthesisTheme;
  translation: ApollonTranslation;
  style?: ViewStyle;
}> = ({ t, thematic, translation, style }) => {
  const { showTranslation } = useTranslationContentContext();
  const { isNativeOrResponsive } = useResponsive();
  const textContent = (showTranslation ? translation.translatedText : translation.sourceText) ?? '';

  return (
    <DataBlock
      style={style}
      key={thematic}
      title={textContent}
      icon={getIconFromTheme(thematic)}
      data={t(`propertySynthesis.theme.${snakeCase(thematic)}`)}
      width={isNativeOrResponsive ? '100%' : 'calc(50% - 10px)'}
    />
  );
};
