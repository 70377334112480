import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Spacer, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { ContentItem, RenovationWarrantyItem } from './RenovationWarrantyItem';

interface RenovationWarrantyProps {
  large?: boolean;
}

const getStyles = (theme: Theme, isLarge: boolean) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.system.secondary[600],
      borderRadius: theme.borderRadius.SIZE_08,
      gap: isLarge ? theme.spacing.SIZE_08 : 0,
      padding: isLarge ? theme.spacing.SIZE_08 : theme.spacing.SIZE_05,
    },
    content: {
      ...(isLarge && {
        flexDirection: 'row',
        gap: theme.spacing.SIZE_09,
        paddingHorizontal: theme.spacing.SIZE_08,
      }),
    },
    header: {
      alignItems: 'center',
      gap: theme.spacing.SIZE_03,
    },
    whiteText: {
      color: theme.palette.base.white,
      textAlign: 'center',
    },
  });

const directContact: ContentItem = { icon: 'Medal', key: 'directContract' };
const delayGuarantee: ContentItem = { icon: 'Clock', key: 'delayGuarantee' };
const provenExpertise: ContentItem = { icon: 'Works', key: 'provenExpertise' };
const partnerCompanies: ContentItem = { icon: 'Handshake', key: 'partnerCompanies' };

export const RenovationWarranty: React.FC<RenovationWarrantyProps> = ({ large = false }) => {
  const theme = useTheme();
  const styles = getStyles(theme, large);
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text textStyle={large ? 'Title3Medium' : 'Headline1Medium'} style={styles.whiteText}>
          {t('realEstate.renovation.warranty.title')}
        </Text>
        <Text textStyle={large ? 'Body3' : 'Caption1'} style={styles.whiteText}>
          {t('realEstate.renovation.warranty.subtitle')}
        </Text>
      </View>

      {!large && <Spacer height={theme.spacing.SIZE_07} />}

      <View style={styles.content}>
        <RenovationWarrantyItem content={directContact} large={large} />
        <RenovationWarrantyItem content={delayGuarantee} large={large} />
      </View>

      <View style={styles.content}>
        <RenovationWarrantyItem content={provenExpertise} large={large} />
        <RenovationWarrantyItem content={partnerCompanies} large={large} />
      </View>
    </View>
  );
};
