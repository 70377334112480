import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgMn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="mn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#mn_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M9.333 20h9.334V0H9.333v20z" fill="#146BBC" />
        <Path d="M0 20h9.333V0H0v20zM18.667 20H28V0h-9.333v20z" fill="#E43642" />
        <Path
          d="M4.667 10l.666.667H4L4.667 10zm0 2a.667.667 0 110-1.333.667.667 0 010 1.333zm.666 0H2.667v4H4v-1.333L4.667 16l.666-1.333V16h1.334v-4H5.333zm-.666 1.333L5.333 12v2a.667.667 0 00-.666-.667zM4 14c0-.368.298-.667.667-.667L4 12v2zm.667.667H4V14c0 .368.298.667.667.667zm0 0h.666V14a.667.667 0 01-.666.667z"
          fill="url(#mn_svg__paint0_linear_4223_11432)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="mn_svg__paint0_linear_4223_11432"
          x1={2.667}
          y1={10}
          x2={2.667}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F8D246" />
          <Stop offset={1} stopColor="#F9CE2F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgMn;
