import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgIl = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="il_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#il_svg__a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h28V0H0v4zM0 20h28v-4H0v4z"
          fill="#0E46D4"
        />
        <Path
          d="M10.375 11.838l-.275.495H17.9l-.275-.495-3.334-6L14 5.314l-.291.524-3.334 6z"
          stroke="#093EC5"
          strokeWidth={0.667}
        />
        <Path
          d="M10.375 8.162l-.275-.495H17.9l-.275.495-3.334 6-.291.524-.291-.524-3.334-6z"
          stroke="#093EC5"
          strokeWidth={0.667}
        />
      </G>
    </Svg>
  );
};

export default SvgIl;
