import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getLinkStyle = ({ theme }: { theme: Theme }) => {
  return StyleSheet.create({
    hackAndroidContainer: { flexDirection: 'row' },
    hackAndroidText: {
      alignSelf: 'flex-end',
      marginBottom: -4,
    },
    text: {
      color: theme.palette.system.primary[500],
      textDecorationLine: 'underline',
    },
  });
};
