import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyle = ({ theme }: { theme: Theme }) => {
  return StyleSheet.create({
    shadow: {
      backgroundColor: theme.palette.base.white,
    },
  });
};
