/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';

import { useTheme } from '@app/ui';
import {
  ExtendedSearchResultsType,
  SearchEngineItem,
  SearchEngineLocationItem,
  SearchEngineRealEstatesLoadingType,
  SearchEngineSection,
  SearchEngineSource,
  SearchEngineType,
} from '@app/features/search-engine/searchEngine.types';
import { realEstateToItemMapper } from '@app/shared/components/real-estate-preview-card/real-estate-to-item.mapper';
import { useMe } from '@app/shared/hooks/useMe';
import {
  trackBookmarksFilterClick,
  trackExtendedRealEstatesResult,
  trackFilterCountryClick,
  trackFilterShow,
} from '@app/services/tracking/trackTracking';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { getContainerStyle } from '@app/features/search-engine/searchEngine.styles';
import { isWeb } from '@app/constants/platform';
import { RealEstatePreviewItem } from '@app/shared/components/real-estate-preview-card/realEstatePreviewItem.type';

import { SearchEngineTemplate } from './SearchEngineTemplate';
import { FiltersStateProps, useGetSearchEngineDatas } from './hooks/useGetSearchEngineDatas';
import { useSearchEngineFilters } from './hooks/useSearchEngineFilters';
import {
  clearSearchEngineScrollPosition,
  currentSearchEngineScrollPosition,
} from './utils/scrollTo.utils';
import { DOM_BODY_CONTAINER_ID } from '../navigation/navigation.constants';
import { createRandomIndexGenerator } from './utils/createRandomIndexGenerator';
import { isSoldRealEstateItem } from './utils/isSoldRealEstateItem.utils';
import { soldRealEstateToItemMapper } from './utils/sold-real-estate-to-item.mapper';
import { ADD_SOLD_PROPERTY_EVERY } from './utils/get-limit-per-page';
type SearchEngineProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstateList
>;

const SearchEngine = React.memo(
  ({ navigation, route }: SearchEngineProps) => {
    const { stateOfCustomer } = useMe();
    const { position = 0, section: paramSection = SearchEngineSection.ALL } = route.params || {};
    const theme = useTheme();
    const [filters, setFilters] = useState<FiltersStateProps | null>(null);
    const { container } = getContainerStyle(theme);

    const {
      cursor,
      bookmarkedRealEstates,
      realEstates,
      soldRealEstates,
      totalBookmarks,
      locations,
      filterLoading,
      toggleBookmark,
      myFavoritesRefetch,
      next: nextMyRealEstates,
      loading: realEstatesLoading,
      loadingType: realEstatesLoadingType,
      totalRealEstates,
      extendedRealEstates,
      zoneLocations,
    } = useGetSearchEngineDatas({
      filters,
    });

    const {
      form,
      filter,
      filterCount,
      updateSearchEngineFilters,
      resetSearchEngineFilters,
      clearForm,
    } = useSearchEngineFilters(setFilters, filters);

    const randomIndexGeneratorRef = useRef(createRandomIndexGenerator(ADD_SOLD_PROPERTY_EVERY));

    useEffect(() => {
      randomIndexGeneratorRef.current = createRandomIndexGenerator(ADD_SOLD_PROPERTY_EVERY);
    }, [filters]);

    const handleLoadMoreRealEstates = useCallback(() => {
      nextMyRealEstates();
    }, [nextMyRealEstates]);

    const [section, setSection] = useState<SearchEngineSection>(paramSection);

    const regionsListByCountry = useMemo<Record<string, string[]>>(
      () =>
        locations.reduce((acc, loc) => {
          acc[loc.countryCode] = loc.regions;
          return acc;
        }, {}) || {},
      [locations]
    );

    const zoneLocationsListByCountry = useMemo<SearchEngineType['zoneLocationListByCountry']>(
      () =>
        zoneLocations.reduce(
          (acc, loc) => {
            acc[loc.countryCode] = loc.regions;
            return acc;
          },
          {
            ES: [],
            FR: [],
          } as SearchEngineType['zoneLocationListByCountry']
        ),
      [zoneLocations]
    );

    const searchEngineList = useMemo(() => {
      if (soldRealEstates.length === 0) {
        return realEstates.map(realEstateToItemMapper);
      }
      const randomIndexes = randomIndexGeneratorRef.current(realEstates.length);
      const listWithSoldRealEsates: SearchEngineItem[] =
        realEstates.map(realEstateToItemMapper) || [];

      randomIndexes.forEach((index, i) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const soldRealEstate = soldRealEstates[i % soldRealEstates.length]!;
        listWithSoldRealEsates.splice(
          index + i,
          0,
          soldRealEstateToItemMapper(soldRealEstate, index)
        );
      });

      return listWithSoldRealEsates;
    }, [realEstates, soldRealEstates]);

    const list = useMemo(() => {
      switch (section) {
        case SearchEngineSection.ALL:
          return searchEngineList;
        case SearchEngineSection.BOOKMARKED:
          return [...bookmarkedRealEstates.map(realEstateToItemMapper)];
      }
    }, [section, searchEngineList, bookmarkedRealEstates]);

    const handleSectionChange = useCallback(
      nextSection => {
        if (nextSection === SearchEngineSection.BOOKMARKED) {
          myFavoritesRefetch();
          trackBookmarksFilterClick(bookmarkedRealEstates.length);
        }

        setSection(nextSection);
      },
      [bookmarkedRealEstates, myFavoritesRefetch]
    );

    const header = useMemo<SearchEngineType['header']>(
      () => ({
        bookmarkedCount: totalBookmarks,
        filterCount,
        onSectionChange: nextSection => handleSectionChange(nextSection),
        resultCount: totalRealEstates,
        section,
        state: stateOfCustomer,
      }),
      [totalBookmarks, filterCount, handleSectionChange, section, stateOfCustomer, totalRealEstates]
    );

    const handleItemBookmark = useCallback(
      ({ id, isBookmarked }: RealEstatePreviewItem) => {
        toggleBookmark({ id, isBookmarked });
      },
      [toggleBookmark]
    );

    const handleItemClick = useCallback(
      (
        item: SearchEngineItem,
        itemInfo: SearchEngineLocationItem,
        extendedResultsSection?: ExtendedSearchResultsType
      ) => {
        const params = {
          currentScrollPosition: isWeb()
            ? document.getElementById(DOM_BODY_CONTAINER_ID)?.scrollTop
            : undefined,
          fromExtendedResultsSection: extendedResultsSection,
          id: item.id,
          isFromSearchEngine: true,
          position: itemInfo.position,
        };

        if (isSoldRealEstateItem(item)) {
          navigation.navigate(RealEstateNavigatorRoutes.RealEstateSold, params);
        } else {
          navigation.navigate(RealEstateNavigatorRoutes.RealEstate, params);
        }
      },
      [navigation]
    );

    // FIXME: move outside component (no deps)
    const handleFilterCountryClick = useCallback((disabled: boolean) => {
      trackFilterCountryClick({ disabled });
    }, []);

    // FIXME: move outside component (no deps)
    const handleFilterShow = useCallback((source: SearchEngineSource) => {
      trackFilterShow({ source });
    }, []);

    useEffect(() => {
      return () => {
        clearSearchEngineScrollPosition();
      };
    }, []);

    useFocusEffect(() => {
      const restorePos = currentSearchEngineScrollPosition();
      if (!realEstatesLoading && restorePos && isWeb()) {
        setTimeout(() => {
          document
            .getElementById(DOM_BODY_CONTAINER_ID)
            ?.scrollTo({ behavior: 'smooth', top: restorePos });
          clearSearchEngineScrollPosition();
        }, 1000);
      }
    });

    const { withHigherBudgetList, withNearbyList } = useMemo(() => {
      return {
        withHigherBudgetList: extendedRealEstates.withHigherBudget.map(realEstateToItemMapper),
        withNearbyList: extendedRealEstates.withNearby.map(realEstateToItemMapper),
      };
    }, [extendedRealEstates]);

    useEffect(() => {
      if (!realEstatesLoading) {
        trackExtendedRealEstatesResult({
          extendedResults: {
            withHigherBudget: withHigherBudgetList.length,
            withNearby: withNearbyList.length,
          },
          extendedResultsDisplayed: !!withHigherBudgetList.length || !!withNearbyList.length,
        });
      }
    }, [withHigherBudgetList, withNearbyList, realEstatesLoading]);

    if (searchEngineList === null || stateOfCustomer === null) {
      return null;
    }

    return (
      <SafeAreaView edges={['top']} style={container}>
        <SearchEngineTemplate
          list={list}
          withHigherBudgetList={withHigherBudgetList}
          withNearbyList={withNearbyList}
          header={header}
          state={stateOfCustomer}
          filter={filter}
          resetForm={clearForm}
          position={position}
          regionListByCountry={regionsListByCountry}
          hasMore={section === SearchEngineSection.BOOKMARKED ? false : !!cursor}
          listLoadingIsRefresh={
            !!realEstatesLoading &&
            realEstatesLoadingType === SearchEngineRealEstatesLoadingType.Refetch
          }
          listLoading={!!realEstatesLoading}
          filterLoading={filterLoading}
          onFilterCountryClick={handleFilterCountryClick}
          onFilterReset={resetSearchEngineFilters}
          onFilterShow={handleFilterShow}
          onFilterUpdate={updateSearchEngineFilters}
          onItemBookmark={handleItemBookmark}
          onItemClick={handleItemClick}
          zoneLocationListByCountry={zoneLocationsListByCountry}
          onLoadMoreRealEstates={handleLoadMoreRealEstates}
          form={form}
        />
      </SafeAreaView>
    );
  },
  (prev, next) => {
    // FIXME: Contexts are rendering this component 4 times.
    return prev.route.params === next.route.params && prev.navigation === next.navigation;
  }
);

SearchEngine.displayName = 'SearchEngine';

// INFO: this is also imported by React.lazy which needs to be default.
// eslint-disable-next-line import/no-default-export
export default SearchEngine;
