import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgNz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="nz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#nz_svg__a)">
        <Path fill="#0A17A7" d="M0 0h28v20H0z" />
        <Path
          d="M0-.333h-.901l.684.586 4.55 3.9v1.009L-.194 8.395l-.14.1v1.44l.51-.319L5.43 6.333h1.13l4.523 3.23a.554.554 0 00.682-.87L7.667 5.18V4.172l4.387-3.134a.667.667 0 00.28-.543V-.6l-.51.318L6.57 3h-1.13L.86-.271.772-.333H0z"
          fill="#FF2E3B"
          stroke="#fff"
          strokeWidth={0.667}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.333V6h4.667v3.333c0 .369.298.667.666.667h1.334a.667.667 0 00.666-.667V6H12a.667.667 0 00.667-.667V4A.667.667 0 0012 3.333H7.333V0H4.667v3.333H0z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4h5.333V0h1.334v4H12v1.333H6.667v4H5.333v-4H0V4z"
          fill="#FF2E3B"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 17.333l-.943.277.276-.943-.276-.943L20 16l.943-.276-.276.943.276.942-.943-.276zM20 4.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.276.943.276.943L20 4.667zM24 8.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.276.943.276.943L24 8.667zM16 10l-.943.276.276-.943-.276-.942.943.276.943-.276-.276.942.276.943L16 10z"
          fill="#CA1931"
        />
      </G>
    </Svg>
  );
};

export default SvgNz;
