import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTruck = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M5.874 19.553a2.893 2.893 0 01-2.125-.875 2.893 2.893 0 01-.875-2.125c-.55 0-1.02-.195-1.412-.587a1.927 1.927 0 01-.588-1.412v-9c0-.55.196-1.021.588-1.413a1.923 1.923 0 011.412-.588h12c.55 0 1.021.196 1.413.588.392.392.587.862.587 1.412v2h2.5c.167 0 .317.034.45.1.134.067.25.167.35.3l2.5 3.325a.883.883 0 01.15.275c.034.1.05.209.05.325v3.675c0 .284-.096.521-.288.712a.965.965 0 01-.712.288h-1c0 .834-.291 1.542-.875 2.125a2.893 2.893 0 01-2.125.875 2.893 2.893 0 01-2.125-.875 2.893 2.893 0 01-.875-2.125h-6c0 .834-.291 1.542-.875 2.125a2.893 2.893 0 01-2.125.875zm0-2a.968.968 0 00.713-.288.967.967 0 00.287-.712.967.967 0 00-.287-.712.968.968 0 00-.713-.287.968.968 0 00-.713.287.967.967 0 00-.287.713c0 .283.096.52.287.712.192.192.43.288.713.288zm-3-12v9h.8c.284-.3.609-.541.975-.725a2.7 2.7 0 011.225-.274 2.7 2.7 0 011.225.274c.367.184.692.426.975.726h6.8v-9h-12zm15 12c.284 0 .521-.096.712-.288a.965.965 0 00.288-.712.965.965 0 00-.288-.712.965.965 0 00-.712-.287.965.965 0 00-.712.287.965.965 0 00-.288.713c0 .283.096.52.288.712a.965.965 0 00.712.288zm-1-5h4.25l-2.25-3h-2v3z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTruck;
