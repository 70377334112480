import React from 'react';

import { Platform } from 'react-native';

import { LoaderProps } from './Loader.types';

export { LoaderMasteos } from './LoaderMasteos';

export const { LoaderDefault } = Platform.select<() => { LoaderDefault: React.FC<LoaderProps> }>({
  default: () => require('./web/LoaderDefault'),
  native: () => require('./native/LoaderDefault'),
})();
