import React, { useCallback, useState } from 'react';

import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';
import { Text } from '@app/ui/atoms/Text/Text';

import { TopBarItemProps } from './TopBarItem.types';
import { getStyles } from './TopBarItem.styles';

export { TopBarItemProps };

export const TopBarItem: React.FC<TopBarItemProps> = ({ label, isActive, onPress }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const onHoverIn = useCallback(() => setIsHovered(true), []);
  const onHoverOut = useCallback(() => setIsHovered(false), []);

  const styles = getStyles({ isHovered: isHovered || !!isActive, theme });

  return (
    <Pressable
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={onPress}
      style={styles.container}
    >
      <Text textStyle="Button1" style={styles.text} numberOfLines={1} testID="top-bar-item-text">
        {label}
      </Text>
    </Pressable>
  );
};
