import { StyleSheet } from 'react-native';

export const getStyles = () => {
  return StyleSheet.create({
    container: {
      maxHeight: '100%',
      maxWidth: 600,
    },
  });
};
