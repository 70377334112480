import React, { forwardRef, useCallback, useMemo, useState, useRef, useEffect } from 'react';

import {
  TextInput,
  View,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextStyle,
  Text as TextNative,
} from 'react-native';

import { Text } from '@app/ui/atoms/Text/Text';
import { inputTheme } from '@app/ui/atoms/input/input.theme';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { Icon, IconProps } from '@app/ui/atoms/Icon';
import { useTheme } from '@app/ui/contexts/config';

import { InputProps } from './Input.types';
import { getIconColor, getRawColors, getSearchIconColor, getStyles } from './Input.styles';
import { Pressable } from '../pressable/Pressable';

/**
 *
 * Default Masteos Text Input with built in error message display and animated label,
 * it should be used as a standard React Native `TextInput` as it extends all its props
 */
export const Input = forwardRef<TextInput, InputProps>(
  (
    {
      value = '',
      placeholder = '',
      label = '',
      error,
      style = {},
      wrapperStyle = {},
      inputWrapperStyle = {},
      onFocus,
      onBlur,
      isPassword,
      prefix,
      prefixWidth = 0,
      editable = true,
      testID,
      isValid,
      hint,
      isSearch,
      isTextArea,
      resetCode,
      errorMessageTestID,
      suffix,
      onClear,
      ...inputProps
    },
    ref
  ) => {
    const theme = useTheme();
    const componentTheme = useComponentTheme('input', inputTheme);

    const metrictsRef = useRef<TextNative>(null);

    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [suffixWidth, setSuffixWidth] = useState(0);

    const shouldLabelBeUp = focused || !!value?.length;
    const isLabelVisible = Boolean(label);
    const styles = getStyles({
      componentTheme,
      disabled: !editable,
      error: Boolean(error),
      focused,
      hovered,
      isLabelVisible,
      isSearch: !!isSearch,
      isValid: !!isValid,
      prefix: !!prefix,
      prefixWidth,
      shouldLabelBeUp,
      suffix: !!suffix?.length,
      suffixWidth,
    });

    useEffect(() => {
      metrictsRef.current?.measure((x, y, width) => {
        setSuffixWidth(width);
      });
    }, [metrictsRef.current]);

    const rawColors = getRawColors(theme, componentTheme);

    const togglePassword = () => {
      setIsPasswordShown(!isPasswordShown);
    };

    const handleOnFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(true);
      onFocus?.(e);
    };

    const handleOnBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(false);
      onBlur?.(e);
    };

    const renderValidationIcon = useCallback(() => {
      const iconColor = getIconColor(!!isValid, !!error, componentTheme);

      let iconName = '' as IconProps['name'];

      if (isValid) {
        iconName = 'CheckFilled';
      }

      if (error) {
        iconName = 'CrossFilled';
      }

      return <Icon name={iconName} color={iconColor} style={styles.validationIconRight} />;
    }, [isValid, error]);

    const renderTextUnderInput = useCallback(() => {
      const textColors = componentTheme.primary.textColor;
      const textStyles = componentTheme.primary.size;
      const paddingTop = componentTheme.primary.size.underInputTextPadding;
      const paddingLeft = componentTheme.primary.size.underInputTextPadding;

      let textColor = undefined as TextStyle['color'];
      let textStyle;

      if (hint?.length) {
        textColor = textColors.hint;
        textStyle = textStyles.hintText;
      }

      if (error) {
        textColor = textColors.errorMessage;
        textStyle = textStyles.errorText;
      }

      return !!error || !!hint ? (
        <Text
          testID={errorMessageTestID}
          style={[
            textStyle,
            {
              color: textColor,
              paddingLeft,
              paddingTop,
            },
          ]}
        >
          {error || hint}
        </Text>
      ) : null;
    }, [hint, error]);

    const searchIconColor = getSearchIconColor(editable, componentTheme);

    const handleOnHoverIn = () => setHovered(true);
    const handleOnHoverOut = () => setHovered(false);

    const wrapperCss = useMemo(
      () => [styles.wrapper, wrapperStyle],
      [styles.wrapper, wrapperStyle]
    );

    const shouldRenderValidationIcon = (error?.length || isValid) && !isTextArea && !resetCode;

    return (
      <Pressable
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onHoverIn={handleOnHoverIn}
        onHoverOut={handleOnHoverOut}
        accessible={false}
        focusable={false}
        withPressOpacity={false}
        style={wrapperCss}
      >
        <View style={[styles.inputWrapper, inputWrapperStyle]}>
          {!!prefix && prefix}
          <View style={{ width: '100%' }}>
            {isLabelVisible ? <Text style={styles.label}>{label}</Text> : null}
            <View style={styles.inputContainer}>
              {isSearch ? (
                <Icon color={searchIconColor} name="Search" style={styles.searchInputIcon} />
              ) : null}
              <TextInput
                ref={ref}
                testID={testID}
                editable={editable}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                value={value ?? undefined}
                placeholder={placeholder}
                style={[styles.input, style]}
                placeholderTextColor={rawColors.placeholderColor}
                selectionColor={rawColors.cursorColor}
                secureTextEntry={isPassword ? !isPasswordShown : false}
                accessibilityState={{ disabled: !editable }}
                multiline={isTextArea}
                autoCapitalize="none"
                {...inputProps}
              />
              {isPassword ? (
                <Pressable style={styles.passwordIconRight} onPress={togglePassword} hitSlop={10}>
                  <Icon
                    name={isPasswordShown ? 'Eye' : 'EyeOff'}
                    color={rawColors.passwordIconColor}
                    width={theme.spacing.SIZE_05}
                    height={theme.spacing.SIZE_05}
                  />
                </Pressable>
              ) : null}
              {!isPassword && !!onClear && (
                <Pressable
                  testID={`${testID}-clear`}
                  style={styles.passwordIconRight}
                  hitSlop={10}
                  onPress={onClear}
                >
                  <Icon
                    name="Trash"
                    color={rawColors.passwordIconColor}
                    width={theme.spacing.SIZE_05}
                    height={theme.spacing.SIZE_05}
                  />
                </Pressable>
              )}
              {suffix?.length ? (
                <TextNative ref={metrictsRef} style={[styles.passwordIconRight, styles.suffix]}>
                  {suffix}
                </TextNative>
              ) : null}
              {shouldRenderValidationIcon ? renderValidationIcon() : null}
            </View>
          </View>
        </View>
        {!resetCode ? renderTextUnderInput() : null}
      </Pressable>
    );
  }
);

Input.displayName = 'Input';
