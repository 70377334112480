import React, { useMemo, useState } from 'react';

import { StyleSheet, View, useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel-4';

import { trackVirtualVisit } from '@app/services/tracking/trackTracking';
import { Theme, useResponsive, useTheme } from '@app/ui';
import { openURL } from '@app/utils/browser.web';

import { SmallCarousel } from './components/SmallCarousel';
import { CarouselImage } from './components/CarouselImage';
import { GalleryArrows } from './components/GalleryArrows';
import { GalleryFooterWebDescription } from './components/GalleryFooterWebDescription';
import { GalleryInformations } from './components/GalleryInformations';
import { GalleryProps } from './gallery.types';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH, renderImage, useGallery } from './hooks/useGallery';

const getStyles = ({ theme, isLowerThanLaptop }: { theme: Theme; isLowerThanLaptop: boolean }) =>
  StyleSheet.create({
    footer: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
      paddingHorizontal: isLowerThanLaptop ? theme.spacing.SIZE_06 : 0,
    },
    mainCarouselContainer: {
      flex: 1,
      gap: theme.spacing.SIZE_07,
      justifyContent: 'flex-start',
      marginTop: theme.spacing.SIZE_07,
    },
    rightFooter: {
      flex: 5,
    },
  });

const HEIGHT_RATIO = 0.55;
const SLIDE_TO_SHOW = 5;

export const Gallery: React.FC<GalleryProps> = ({
  images,
  url3d,
  contact,
  realEstateId,
  appointmentImage,
  defaultIndex,
}) => {
  const [smallCarouselWidth, setSmallCarouselWidth] = useState(0);

  const { mainCarouselRef, currentIndex, onSnapToItem, slides, smallCarouselRef } = useGallery({
    SlideComponent: CarouselImage,
    appointmentImage,
    contact,
    defaultIndex,
    images,
  });

  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const { width, height } = useWindowDimensions();
  const styles = useMemo(() => getStyles({ isLowerThanLaptop, theme }), [isLowerThanLaptop, theme]);

  const carouselHeight = isLowerThanLaptop ? MAX_IMAGE_HEIGHT : height * HEIGHT_RATIO;
  const carouselWidth = isLowerThanLaptop ? width : MAX_IMAGE_WIDTH;
  const showSmallCarousel =
    smallCarouselWidth > 0 && images.length > 1 && !isLowerThanLaptop && !appointmentImage;

  const onArrowPress = (i: 'next' | 'prev') => () => {
    mainCarouselRef.current?.[i]();
    smallCarouselRef.current?.[i]();
  };

  const onSmallCarouselSlidePress = (index: number) => {
    mainCarouselRef.current?.scrollTo({ animated: true, index });
  };

  const open3dUrl = () => {
    if (url3d) {
      trackVirtualVisit({ isInsideGallery: true, propertyId: realEstateId });
      openURL(url3d);
    }
  };

  return (
    <View style={styles.mainCarouselContainer}>
      {slides.length > 1 && (
        <GalleryArrows onLeftPress={onArrowPress('prev')} onRightPress={onArrowPress('next')} />
      )}

      <GalleryInformations
        currentImage={images.at(currentIndex)}
        currentIndex={currentIndex}
        slideLength={slides.length}
        open3dUrl={url3d ? open3dUrl : undefined}
      />

      <Carousel
        ref={mainCarouselRef}
        width={carouselWidth}
        height={carouselHeight}
        defaultIndex={defaultIndex}
        enabled={false}
        data={slides}
        loop={slides.length > 1}
        onSnapToItem={onSnapToItem}
        renderItem={renderImage}
        windowSize={SLIDE_TO_SHOW}
      />

      <View style={styles.footer}>
        <GalleryFooterWebDescription contact={contact} currentImage={images[currentIndex]} />

        <View
          style={styles.rightFooter}
          onLayout={({ nativeEvent }) => {
            setSmallCarouselWidth(nativeEvent.layout.width);
          }}
        >
          {!!showSmallCarousel && (
            <SmallCarousel
              ref={smallCarouselRef}
              data={images}
              width={smallCarouselWidth}
              onSlidePress={onSmallCarouselSlidePress}
              defaultIndex={defaultIndex}
            />
          )}
        </View>
      </View>
    </View>
  );
};
