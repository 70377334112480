import * as React from 'react';

import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgCategory = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M7.425 9.476 11.15 3.4a.918.918 0 0 1 .375-.363 1.048 1.048 0 0 1 .95 0c.15.075.275.196.375.363l3.725 6.075c.1.166.15.341.15.525 0 .183-.042.35-.125.5a.973.973 0 0 1-.875.5h-7.45a.987.987 0 0 1-.525-.138.973.973 0 0 1-.35-.362 1.012 1.012 0 0 1-.125-.5c0-.184.05-.359.15-.525ZM17.5 22c-1.25 0-2.313-.438-3.188-1.313-.874-.875-1.312-1.937-1.312-3.187 0-1.25.438-2.313 1.313-3.188.874-.875 1.937-1.312 3.187-1.312 1.25 0 2.313.437 3.188 1.312S22 16.251 22 17.501c0 1.25-.438 2.312-1.313 3.187s-1.937 1.313-3.187 1.313ZM3 20.5v-6c0-.284.096-.521.288-.713A.967.967 0 0 1 4 13.501h6c.283 0 .52.096.713.287.191.192.287.43.287.713v6c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288H4a.968.968 0 0 1-.712-.288.968.968 0 0 1-.288-.712Zm14.5-.5c.7 0 1.292-.242 1.775-.725.483-.484.725-1.075.725-1.775s-.242-1.292-.725-1.775C18.792 15.242 18.2 15 17.5 15s-1.292.241-1.775.725C15.242 16.209 15 16.8 15 17.5s.242 1.291.725 1.775c.483.483 1.075.725 1.775.725ZM5 19.5h4v-4H5v4ZM10.05 9h3.9L12 5.85 10.05 9Z"
    />
  </Svg>
);
export default SvgCategory;
