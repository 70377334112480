import React from 'react';

import { View } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { Text } from '@app/ui/atoms/Text/Text';
import { Button } from '@app/ui/molecules/Button';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useTheme } from '@app/ui/contexts/config';

import { getToastStyle, getVariantStyles } from './Toast.styles';
import { ToastType, ToastVariant } from './Toast.types';

export { ToastType, ToastVariant };

export const Toast: React.FC<ToastType> = ({
  label,
  numberOfLines = 2, // 0 to disable truncate
  width,
  icon,
  onClose,
  onAction,
  buttonLabel,
  variant = ToastVariant.DEFAULT,
  ...rest
}) => {
  const theme = useTheme();
  const style = getToastStyle({ theme, variant, width });

  const iconColor = getVariantStyles(variant, theme).color;

  return (
    <View {...rest}>
      <View style={style.container}>
        {icon ? (
          <View style={style.icon}>
            <Icon name={icon} color={iconColor} testID="toast-icon" size={theme.spacing.SIZE_06} />
          </View>
        ) : null}
        <Text textStyle="Body3Medium" style={style.label} numberOfLines={numberOfLines}>
          {label}
        </Text>
        {onAction ? (
          <View style={style.button}>
            <Button onPress={onAction} testID="action-button" size="s" isInDark variant="tertiary">
              {buttonLabel}
            </Button>
          </View>
        ) : null}
        {onClose ? (
          <Pressable onPress={onClose} style={style.containerCloseButton} testID="toast-close">
            <Icon name="Cross" color={iconColor} testID="toast-icon" size={theme.spacing.SIZE_05} />
          </Pressable>
        ) : null}
      </View>
    </View>
  );
};
