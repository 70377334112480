import { Theme } from '@app/ui/theme/types';

export const chipTheme = (theme: Theme) => {
  return {
    icon: {
      marginRight: theme.spacing.SIZE_02,
      size: theme.spacing.SIZE_04,
    },
    paddingHorizontal: {
      medium: theme.spacing.SIZE_04 - theme.spacing.SIZE_01,
      small: theme.spacing.SIZE_02,
    },
    paddingVertical: {
      medium: theme.spacing.SIZE_02 + theme.spacing.SIZE_01,
      small: theme.spacing.SIZE_02,
    },
    tints: {
      black: {
        backgroundColor: theme.palette.neutral[800],
        color: theme.palette.neutral[100],
      },
      blue: {
        backgroundColor: theme.palette.tint.blue[50],
        color: theme.palette.tint.blue[700],
      },
      green: {
        backgroundColor: theme.palette.tint.green[50],
        color: theme.palette.tint.green[700],
      },
      grey: {
        backgroundColor: theme.palette.neutral[100],
        color: theme.palette.neutral[800],
      },
      orange: {
        backgroundColor: theme.palette.tint.orange[50],
        color: theme.palette.tint.orange[700],
      },
      pink: {
        backgroundColor: theme.palette.tint.pink[50],
        color: theme.palette.tint.pink[700],
      },
      purple: {
        backgroundColor: theme.palette.tint.purple[50],
        color: theme.palette.tint.purple[700],
      },
      red: {
        backgroundColor: theme.palette.tint.red[50],
        color: theme.palette.tint.red[700],
      },
      white: {
        backgroundColor: theme.palette.base.white,
        color: theme.palette.neutral[800],
      },
    },
    wrapper: {
      borderRadius: theme.spacing.SIZE_08,
    },
  };
};
