/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */

import { assign } from 'xstate';

import { PublicNavigatorRoutes } from '@app/navigation/types/routes';

import { ExternalState } from '../stateMachine.types';

export const registerMachineStates: ExternalState = {
  stepRegister: {
    entry: assign({
      url: PublicNavigatorRoutes.Register,
    }),
  },
};
