import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgPlay = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M9.525 18.025c-.333.217-.671.229-1.013.037-.341-.191-.512-.487-.512-.887V6.825c0-.4.17-.696.512-.888.342-.191.68-.179 1.013.038l8.15 5.175c.3.2.45.483.45.85s-.15.65-.45.85l-8.15 5.175zM10 15.35L15.25 12 10 8.65v6.7z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgPlay;
