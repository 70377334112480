import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme, isMobile: boolean) => {
  return StyleSheet.create({
    callout: {
      marginTop: 16,
    },
    checkboxContainer: {
      ...(!isMobile && { flex: 1 }),
    },
    checkboxGroupContainer: {
      flexDirection: isMobile ? 'column' : 'row',
      gap: theme.spacing.SIZE_05,
      marginTop: theme.spacing.SIZE_05,
    },
    mainContainer: {
      backgroundColor: theme.palette.neutral['50'],
      borderRadius: theme.spacing.SIZE_03,
      padding: theme.spacing.SIZE_04,
      width: '100%',
    },
    subtitle: {
      color: theme.palette.neutral['700'],
      marginTop: theme.spacing.SIZE_02,
    },
    title: {
      color: theme.palette.neutral['900'],
    },
  });
};
