import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Icon, Text, Theme, useTheme } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    reinsuranceWrapper: {
      backgroundColor: theme.palette.neutral[50],
      borderRadius: theme.borderRadius.SIZE_06,
      gap: theme.spacing.SIZE_03,
      padding: theme.spacing.SIZE_05,
    },
    row: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing.SIZE_02,
    },
  });

export const DiscussReinsurance = () => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { t } = useTranslation();

  return (
    <View style={styles.reinsuranceWrapper}>
      <View style={styles.row}>
        <Icon name="Clock" size={20} />
        <Text style={{ color: theme.palette.neutral[800] }} textStyle="Body3">
          {t('discuss.takeAppointment.reinsuranceText1')}
        </Text>
      </View>

      <View style={styles.row}>
        <Icon name="Phone" size={20} />
        <Text style={{ color: theme.palette.neutral[800] }} textStyle="Body3">
          {t('discuss.takeAppointment.reinsuranceText2')}
        </Text>
      </View>

      <View style={styles.row}>
        <Icon name="SellPrice" size={20} />
        <Text style={{ color: theme.palette.neutral[800] }} textStyle="Body3">
          {t('discuss.takeAppointment.reinsuranceText3')}
        </Text>
      </View>
    </View>
  );
};
