import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgGi = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Mask
        id="gi_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </Mask>
      <G mask="url(#gi_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 20h28v-6.667H0V20zM9.333 13.333h8V8h-8v5.333z" fill="#E34F4F" />
        <Path
          d="M10.667 13.333H12V12h-1.333v1.333zM14.667 13.333H16V12h-1.333v1.333zM9.333 8h1.334V6.667H9.333V8zM13.333 8h1.334V6.667h-1.334V8zM12 8h1.333V6.667H12V8zM16 8h1.333V6.667H16V8z"
          fill="#181A1A"
        />
        <Path
          d="M9.333 6.667h1.334V5.333H9.333v1.334zM13.333 6.667h1.334V5.333h-1.334v1.334zM12 6.667h1.333V5.333H12v1.334zM16 6.667h1.333V5.333H16v1.334z"
          fill="#E34F4F"
        />
      </G>
    </Svg>
  );
};

export default SvgGi;
