import { Theme } from '@app/ui/theme/types';

export const inputTheme = (theme: Theme) => {
  const iconSize = theme.spacing.SIZE_05;
  const defaultPadding = theme.spacing.SIZE_04;

  return {
    position: {
      label: {
        defaultLeft: 0,
        defaultTop: 0,
        upLeft: -10,
        upTop: -theme.spacing.SIZE_05,
      },
    },
    primary: {
      backgroundColor: {
        default: theme.palette.neutral[100],
        disabled: theme.palette.neutral[200],
        focus: theme.palette.neutral[100],
        hovered: theme.palette.neutral[100],
      },
      borderColor: {
        error: theme.palette.alert.error,
        focus: theme.palette.neutral[900],
        hover: theme.palette.neutral[300],
      },
      borderWidth: theme.spacing.SIZE_00,
      iconColor: {
        error: theme.palette.alert.error,
        password: theme.palette.neutral[900],
        passwordDisabled: theme.palette.neutral[600],
        search: theme.palette.neutral[700],
        searchDisabled: theme.palette.neutral[600],
        valid: theme.palette.alert.valid,
      },
      iconPosition: {
        default: theme.spacing.SIZE_04,
        passwordWithValidation: theme.spacing.SIZE_09 - 2,
      },
      iconSize,
      size: {
        borderRadius: theme.spacing.SIZE_05,
        defaultPadding,
        errorText: theme.textStyles.Caption1,
        height: theme.spacing.SIZE_10,
        hintText: theme.textStyles.Caption1,
        labelBottomPadiing: theme.spacing.SIZE_02,
        labelLeftPadding: theme.spacing.SIZE_02,
        labelText: theme.textStyles.Body3Medium,
        paddingIconWithSearch: defaultPadding + iconSize + theme.spacing.SIZE_03,
        paddingWithPrefix: theme.spacing.SIZE_04,
        placeholderText: theme.textStyles.Body2,
        underInputTextPadding: theme.spacing.SIZE_02,
      },
      spacing: {
        leftSpace: 0,
        textWithLabel: theme.spacing.SIZE_02,
        textWithoutLabel: theme.spacing.SIZE_02,
        topSpace: theme.spacing.SIZE_04,
      },
      textColor: {
        disabled: theme.palette.neutral[600],
        errorMessage: theme.palette.alert.error,
        hint: theme.palette.alert.info,
        inputText: theme.palette.neutral[900],
        label: {
          default: theme.palette.neutral[900],
          disabled: theme.palette.neutral[600],
        },
        placeholder: theme.palette.neutral[600],
      },
    },
    size: {
      border: 1,
      errorMessage: 14,
      height: theme.spacing.SIZE_08,
      input: theme.spacing.SIZE_05,
      label: theme.spacing.SIZE_04,
      labelScaleDown: 1,
      labelScaleUp: 0.75,
    },
  };
};
