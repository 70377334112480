import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { FinancialModel } from '@masteos/agora';

import { Spacing, Expandable, Spacer, useTheme, Theme } from '@app/ui';
import {
  trackRentalManagement,
  trackPremiumRentalManagement,
  trackTooltip,
  trackFinancialToggle,
  trackFinancialUntoggle,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { RealEstateFinancialHeader } from './RealEstateFinancialHeader';
import { Importance, RealEstateAttribute } from '../../../components/RealEstateAttribute';
import { areEqual, differentFuncParameters } from '../utils/realEstateFinances.utils';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    border: {
      borderBottomColor: theme.palette.neutral[300],
      borderBottomWidth: 1,
      width: '100%',
    },
    container: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_05,
      borderWidth: 1,
      paddingBottom: theme.spacing.SIZE_04,
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingTop: theme.spacing.SIZE_03,
      width: '100%',
    },
  });

interface RealEstateFinancialAnnualChargesProps {
  realEstate: RealEstate;
  financialModel: FinancialModel;
  setWithPropertyManagement: (v: boolean) => void;
  setWithPremiumPropertyManagement: (v: boolean) => void;
  withPropertyManagement: boolean;
  withPremiumPropertyManagement: boolean;
  withShortTermRental: boolean;
}

export const RealEstateFinancialAnnualCharges: React.FC<RealEstateFinancialAnnualChargesProps> =
  React.memo(
    ({
      realEstate,
      financialModel,
      setWithPropertyManagement,
      setWithPremiumPropertyManagement,
      withPropertyManagement,
      withPremiumPropertyManagement,
      withShortTermRental,
    }) => {
      const { t } = useRealEstateTranslation();
      const theme = useTheme();
      const styles = useMemo(() => getStyles(theme), [theme]);

      const annualPropertyManagement = currencyFormatter(
        financialModel.annualPropertyManagement,
        0
      );
      const annualPremiumPropertyManagement = currencyFormatter(
        financialModel.annualPremiumPropertyManagement,
        0
      );
      const totalAnnualCharges = currencyFormatter(financialModel.annualTotalExpenses, 0);
      const annualCurrentExpenses = currencyFormatter(realEstate.building.annual.expenses, 0);
      const annualPropertyTax = currencyFormatter(financialModel.annualPropertyTax, 0);
      const annualHomeOwnerInsurance = currencyFormatter(
        financialModel.annualHomeOwnerInsurance,
        0
      );

      const leasingCost = currencyFormatter(financialModel.leasingCost, 0);
      const rentalManagementStandardFees = currencyFormatter(
        financialModel.rentalManagementStandardFees,
        0
      );
      const rentalManagementPremiumFees = currencyFormatter(
        financialModel.rentalManagementPremiumFees,
        0
      );

      const annualCharges = realEstate.annual.charges;
      const tenantCharges = realEstate.building.annual.expenses - annualCharges;

      const handleWithPropertyManagement = (value: boolean) => {
        trackRentalManagement({ isChecked: value, propertyId: realEstate.id });
        setWithPropertyManagement(value);
      };
      const handleWithPremiumPropertyManagement = (value: boolean) => {
        trackPremiumRentalManagement({ isChecked: value, propertyId: realEstate.id });
        setWithPremiumPropertyManagement(value);
      };

      const trackToggle = ({ expanded }: { expanded?: boolean }) => {
        expanded
          ? trackFinancialUntoggle({ propertyId: realEstate.id, sectionName: 'charges' })
          : trackFinancialToggle({ propertyId: realEstate.id, sectionName: 'charges' });
      };
      const trackTooltipAnnualCurrentExpenses = () =>
        trackTooltip({ propertyId: realEstate.id, tooltipType: 'Charges de copropriété' });
      const trackTooltipAnnualHomeOwnerInsurance = () =>
        trackTooltip({ propertyId: realEstate.id, tooltipType: 'Assurance PNO' });
      const trackTooltipAnnualPropertyManagement = () =>
        trackTooltip({ propertyId: realEstate.id, tooltipType: 'Gestion locative' });
      const trackTooltipAnnualPremiumPropertyManagement = () =>
        trackTooltip({ propertyId: realEstate.id, tooltipType: 'Gestion locative premium' });
      const trackTooltipConciergeFees = () =>
        trackTooltip({ propertyId: realEstate.id, tooltipType: 'Frais de conciergerie' });

      const valueColor = withShortTermRental ? theme.palette.tint.purple[500] : undefined;

      const propertyManagementTooltip = {
        description: t('shared.financialTool.tooltip.annualPropertyManagementDescription', {
          context: `v${realEstate.financialModelVersion}`,
          leasingCost,
          rentalManagementFees: rentalManagementStandardFees,
        }),
        onOpen: trackTooltipAnnualPropertyManagement,
        title: t('shared.financialTool.tooltip.annualPropertyManagement'),
      };
      const premiumPropertyManagementTooltip = {
        description: t('shared.financialTool.tooltip.annualPremiumPropertyManagementDescription', {
          context: `v${realEstate.financialModelVersion}`,
          leasingCost,
          rentalManagementFees: rentalManagementPremiumFees,
        }),
        onOpen: trackTooltipAnnualPremiumPropertyManagement,
        title: t('shared.financialTool.tooltip.annualPremiumPropertyManagement'),
      };
      const conciergeFeesTooltip = {
        description: t('shared.financialTool.tooltip.conciergeFeesDescription'),
        onOpen: trackTooltipConciergeFees,
        title: t('shared.financialTool.tooltip.conciergeFees'),
      };

      const showPremiumManagement = (realEstate.financialModelVersion || 4) >= 5;

      return (
        <View style={styles.container}>
          <Expandable onPress={trackToggle} id="financesExpandable">
            <Expandable.Header>
              <Expandable.Context.Consumer>
                {({ expanded }) => (
                  <RealEstateFinancialHeader
                    title={t(`shared.financialTool.expenses`)}
                    icon="Home"
                    hasChevron
                    chevronOpen={expanded}
                    amount={!expanded && totalAnnualCharges}
                    amountColor={valueColor}
                  />
                )}
              </Expandable.Context.Consumer>
            </Expandable.Header>
            <Expandable.Content>
              <RealEstateAttribute
                label={t('shared.financialTool.annualCurrentExpenses')}
                value={annualCurrentExpenses}
                isOdd
                importance={Importance.LOW}
                testID="property-financial-line-annual-current-expenses"
                tooltip={{
                  description: t(`shared.financialTool.tooltip.annualCurrentExpensesDescription`, {
                    annualCharges,
                    tenantCharges,
                  }),
                  onOpen: trackTooltipAnnualCurrentExpenses,
                  title: t(`shared.financialTool.tooltip.annualCurrentExpenses`),
                }}
              />
              <RealEstateAttribute
                label={t('shared.financialTool.annualPropertyTax')}
                value={annualPropertyTax}
                importance={Importance.LOW}
                testID="property-financial-line-annual-property-tax"
              />
              <RealEstateAttribute
                label={t('shared.financialTool.annualHomeOwnerInsurance')}
                value={annualHomeOwnerInsurance}
                importance={Importance.LOW}
                isOdd
                tooltip={{
                  description: t(
                    `shared.financialTool.tooltip.annualHomeOwnerInsuranceDescription`
                  ),
                  onOpen: trackTooltipAnnualHomeOwnerInsurance,
                  title: t(`shared.financialTool.tooltip.annualHomeOwnerInsurance`),
                }}
                testID="property-financial-line-annual-home-owner-insurance"
              />
              <RealEstateAttribute
                label={
                  withShortTermRental
                    ? t('shared.financialTool.conciergeFees')
                    : t('shared.financialTool.annualPropertyManagement')
                }
                value={annualPropertyManagement}
                importance={Importance.LOW}
                tooltip={withShortTermRental ? conciergeFeesTooltip : propertyManagementTooltip}
                inputSwitch={{
                  setValue: handleWithPropertyManagement,
                  value: withPropertyManagement,
                }}
                testID="property-financial-line-annual-property-management"
                valueColor={valueColor}
              />

              {!!showPremiumManagement && (
                <RealEstateAttribute
                  label={t('shared.financialTool.annualPremiumPropertyManagement')}
                  value={annualPremiumPropertyManagement}
                  importance={Importance.LOW}
                  tooltip={premiumPropertyManagementTooltip}
                  inputSwitch={{
                    setValue: handleWithPremiumPropertyManagement,
                    value: withPremiumPropertyManagement,
                  }}
                  testID="property-financial-line-annual-premium-property-management"
                  valueColor={valueColor}
                />
              )}

              <Spacer height={Spacing.SIZE_04} />

              <View style={styles.border} />

              <Spacer height={Spacing.SIZE_04} />

              <RealEstateAttribute
                label={t('shared.financialTool.expenses')}
                value={totalAnnualCharges}
                importance={Importance.HIGH}
                testID="property-financial-line-total-annual-charges"
                valueColor={valueColor}
              />
            </Expandable.Content>
          </Expandable>
        </View>
      );
    },
    areEqual<RealEstateFinancialAnnualChargesProps>(
      ['withPropertyManagement', 'withShortTermRental'],
      (prev, next) => {
        return differentFuncParameters(prev, next, [
          'annualPropertyManagement',
          'annualPremiumPropertyManagement',
          'annualTotalExpenses',
          'annualHomeOwnerInsurance',
          'annualPropertyTax',
        ]);
      }
    )
  );

RealEstateFinancialAnnualCharges.displayName = 'PropertyFinancialAnnualCharges';
