import React, { useRef, useState, useEffect, PropsWithChildren } from 'react';

import { View, GestureResponderEvent } from 'react-native';

import { PopoverContainer } from '@app/ui/molecules/popover-container/PopoverContainer';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { isWeb } from '@app/constants/platform';

import { PopoverProps } from './Popover.types';

export const Popover: React.FC<PropsWithChildren<PopoverProps>> = ({
  children,
  content,
  onClose,
  onOpen,
  onPress,
  showCloseButton,
  testID,
  title,
  triggerType = 'hover',
  variant,
}) => {
  const ref = useRef<View>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | number>();
  const { isLowerThanLaptop } = useResponsive();

  const performOpen = () => {
    setIsOpen(true);
    onOpen?.();
  };

  const performClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleTriggerPress = (e: GestureResponderEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    onPress?.();
    performOpen();
  };
  const handleCloseRequest = () => setIsCloseRequested(true);
  const handleHoverIn = () => triggerType == 'hover' && setIsHover(true);
  const handleHoverOut = () => triggerType == 'hover' && setIsHover(false);

  useEffect(() => {
    /*
     *  Controls for touch devices
     */
    if (isLowerThanLaptop) {
      if (!isOpen) {
        setIsCloseRequested(false);
        return;
      }

      if (isCloseRequested) {
        performClose();
        return;
      }

      return;
    }

    /*
     *  Controls for mouse devices
     */
    if (!isOpen && !isHover) {
      setIsCloseRequested(false);
      return;
    }

    if (isHover && !isOpen && !isCloseRequested) {
      performOpen();
      return;
    }

    if (isCloseRequested) {
      performClose();
      return;
    }

    if (triggerType == 'hover') {
      clearTimeout(closeTimeout as number);
      setCloseTimeout(
        setTimeout(() => {
          if (isOpen && !isHover) {
            performClose();
          }
        }, 250)
      );
    }

    // Disable exhaustive-deps warning because it triggers over static functions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isHover, isCloseRequested, isLowerThanLaptop]);

  useEffect(() => {
    if (isWeb()) {
      const listener = () => {
        if (isOpen) {
          handleCloseRequest();
        }
      };
      window.addEventListener('scroll', listener);
      return () => window.removeEventListener('scroll', listener);
    }
  }, [isOpen]);

  return (
    <View testID={testID}>
      <Pressable
        ref={ref}
        onPress={handleTriggerPress}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        withPressOpacity={false}
      >
        {children}
      </Pressable>
      {!!isOpen && (
        <PopoverContainer
          open={isOpen}
          title={title}
          content={content}
          triggerRef={ref}
          variant={variant}
          showCloseButton={showCloseButton}
          onClose={handleCloseRequest}
          onPressOutside={handleCloseRequest}
          onHoverIn={handleHoverIn}
          onHoverOut={handleHoverOut}
        />
      )}
    </View>
  );
};
