import * as React from 'react';

import Svg, { Rect, Path, Mask, G } from 'react-native-svg';

const SvgNp = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2a2 2 0 012-2h.465c.131 0 .26.039.37.112l12.168 8.112c1.097.732.58 2.443-.74 2.443H8l7.996 6.996c.927.811.353 2.337-.878 2.337H2a2 2 0 01-2-2V2z"
        fill="#fff"
      />
      <Mask
        id="np_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={17}
        height={20}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2a2 2 0 012-2h.465c.131 0 .26.039.37.112l12.168 8.112c1.097.732.58 2.443-.74 2.443H8l7.996 6.996c.927.811.353 2.337-.878 2.337H2a2 2 0 01-2-2V2z"
          fill="#fff"
        />
      </Mask>
      <G mask="url(#np_svg__a)">
        <Path
          d="M15.118 19.333H.667V.667h1.798l12.168 8.112c.549.366.29 1.221-.37 1.221H8a.667.667 0 00-.439 1.168l7.996 6.997a.667.667 0 01-.439 1.168z"
          fill="#EE1B44"
          stroke="#0543A8"
          strokeWidth={1.333}
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.851 7.8c.384-.104.738-.248 1.051-.424a2.668 2.668 0 01-5.142 0c.314.176.67.32 1.055.426l.518-.469-.847-.765 1.14.058-.058-1.14.765.847.766-.847-.059 1.14 1.141-.058-.848.765.518.468zm-2.538 9.33L5.333 16l1.02 1.13-.077-1.52 1.521.077-1.13-1.02 1.13-1.02-1.52.077.077-1.521-1.02 1.13-1.021-1.13.078 1.52-1.521-.077L4 14.666l-1.13 1.021 1.52-.077-.077 1.52z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgNp;
