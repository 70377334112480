import React from 'react';

import { Dpe } from '@app/libs/apollo/introspection';
import { BottomSheet } from '@app/ui';
import { useTranslation } from '@app/services/translations/translations';

import { dpeColors } from '../../utils/dpe';
import { PerformanceIndicator } from './PerformanceIndicator';

interface RealEstateRenovationDPEModalProps {
  value: string;
  isVisibleDPEModal: boolean;
  onClose: () => void;
}

const performanceIndicators = [Dpe.A, Dpe.B, Dpe.C, Dpe.D, Dpe.E, Dpe.F, Dpe.G];

const data = performanceIndicators.map(dpe => ({
  color: dpeColors[dpe],
  description: `shared.performanceIndicator.DPE.${dpe.toLowerCase()}Description`,
  label: `shared.performanceIndicator.DPE.${dpe.toLowerCase()}Label`,
  value: dpe,
}));

export const RealEstateRenovationDPEModal: React.FC<RealEstateRenovationDPEModalProps> = ({
  value,
  isVisibleDPEModal,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <BottomSheet visible={isVisibleDPEModal} onPressClose={onClose}>
      <PerformanceIndicator
        title={t('shared.performanceIndicator.DPE.title')}
        caption={t('shared.performanceIndicator.DPE.caption')}
        labelLowest={t('shared.performanceIndicator.DPE.labelLowest')}
        labelHighest={t('shared.performanceIndicator.DPE.labelHighest')}
        value={value}
        data={data}
      />
    </BottomSheet>
  );
};
