import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getTranslatedContentStyle = ({ theme, dark }: { theme: Theme; dark?: boolean }) => {
  return StyleSheet.create({
    text: {
      color: dark ? theme.palette.base.white : theme.palette.neutral[800],
    },
  });
};
