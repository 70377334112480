/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-shadow */
import { NavigatorScreenParams } from '@react-navigation/native';

import {
  SearchEngineSection,
  ExtendedSearchResultsType,
} from '@app/features/search-engine/searchEngine.types';
import { Document, RealEstate, RenovationDetail } from '@app/libs/apollo/introspection';
// eslint-disable-next-line max-len
import { RenovationSummaryValues } from '@app/features/realEstate/realEstateAvailable/realEstateRenovation/components/renovationSummary/RenovationSummary';

export enum PrivateNavigatorRoutes {
  MainMenu = 'MainMenu',
  Onboarding = 'Onboarding',
}

export enum MainMenuNavigatorRoutes {
  Advisor = 'Advisor',
  Discuss = 'Discuss',
  RealEstates = 'RealEstates',
  Projects = 'Projects',
  Profile = 'Profile',
  HomePage = 'HomePage',
}

export enum PublicNavigatorRoutes {
  Auth = 'Auth',
  ForgotPassword = 'ForgotPassword',
  Login = 'Login',
  PublicHome = 'PublicHome',
  Register = 'Register',
  RealEstate = 'PublicRealEstate',
  RealEstateList = 'RealEstateList',
  RealEstateSold = 'PublicRealEstateSold',
  SsoRedirect = 'SsoRedirect',
}

export enum ProjectNavigatorRoutes {
  Advisor = 'Advisor',
  Project = 'Project',
  ProjectList = 'ProjectList',
  RealEstate = 'RealEstate',

  // TODO: remove when a solution has been found for the realEstate page navigator
  RealEstateMap = 'RealEstateMap',
  RenovationSummaryDetail = 'RenovationSummaryDetail',
  FurnishmentDetails = 'FurnishmentDetails',
  RenovationDetails = 'RenovationDetails',
}

export enum RealEstateNavigatorRoutes {
  RealEstate = 'RealEstate',
  RealEstateMap = 'RealEstateMap',
  RealEstateSold = 'RealEstateSold',
  RealEstateList = 'RealEstateList',
  RenovationSummaryDetail = 'RenovationSummaryDetail',
  FurnishmentDetails = 'FurnishmentDetails',
  RenovationDetails = 'RenovationDetails',
  AdvisorAppointment = 'AdvisorAppointment',
}

export enum HomePageNavigatorRoutes {
  HomePageRoot = 'HomePageRoot',
  RealEstate = 'RealEstate',
  Project = 'Project',

  // TODO: remove when a solution has been found for the realEstate page navigator
  RealEstateMap = 'RealEstateMap',
  RenovationSummaryDetail = 'RenovationSummaryDetail',
  FurnishmentDetails = 'FurnishmentDetails',
  RenovationDetails = 'RenovationDetails',
}

export enum DiscussNavigatorRoutes {
  DiscussRoot = 'DiscussRoot',
  DiscussBeCalled = 'DiscussBeCalled',
  DiscussTakeAppointment = 'DiscussTakeAppointment',
  DiscussTakeAppointmentConfirmation = 'DiscussTakeAppointmentConfirmation',
}

export enum AdvisorNavigatorRoutes {
  AdvisorRoot = 'AdvisorRoot',
  AdvisorAppointment = 'AdvisorAppointment',
}

export enum ForgotPasswordNavigatorRoutes {
  ForgotPasswordEmailStep = 'ForgotPasswordPasswordEmailStep',
  ForgotPasswordCodeStep = 'ForgotPasswordCodeStep',
  ForgotPasswordNewPasswordStep = 'ForgotPasswordNewPasswordStep',
}

export enum DebugMenuNavigatorRoutes {
  DebugMenuRoot = 'DebugMenuRoot',
  DebugMenuCodepush = 'DebugMenuCodepush',
  DebugMenuDevice = 'DebugMenuDevice',
  DebugMenuEnvironmentVariables = 'DebugMenuEnvironmentVariables',
  DebugMenuAsyncStorage = 'DebugMenuAsyncStorage',
  DebugMenuTranslations = 'DebugMenuTranslations',
  DebugMenuTracking = 'DebugMenuTracking',
  DebugMenuCustomer = 'DebugMenuCustomer',
  DebugMenuUnleashFeatureFlags = 'DebugMenuUnleashFeatureFlags',
}

export enum ProfileNavigatorRoutes {
  ProfileRoot = 'ProfileRoot',
  ProfileAdvisor = 'ProfileAdvisor',
  ProfileAdvisorAppointment = 'ProfileAdvisorAppointment',
  ProfileDocumentsList = 'ProfileDocumentsList',
  ProfileInformation = 'ProfileInformation',
  ProfileLang = 'ProfileLang',
  ProfileLegalNotices = 'ProfileLegalNotices',
  ProfilePassword = 'ProfilePassword',
  ProfileSponsorship = 'ProfileSponsorship',
  ProfileDocument = 'ProfileDocument',
  ProfileNotificationPreferences = 'ProfileNotificationPreferences',
  DebugMenu = 'DebugMenu',
  Auth = 'Auth',
}

export type Routes =
  | DebugMenuNavigatorRoutes
  | PublicNavigatorRoutes
  | PrivateNavigatorRoutes
  | ProjectNavigatorRoutes
  | RealEstateNavigatorRoutes
  | ProfileNavigatorRoutes
  | ForgotPasswordNavigatorRoutes
  | AdvisorNavigatorRoutes
  | HomePageNavigatorRoutes
  | DiscussNavigatorRoutes
  | MainMenuNavigatorRoutes;

export type AdvisorStackParamList = {
  [AdvisorNavigatorRoutes.AdvisorRoot]: undefined;
  [AdvisorNavigatorRoutes.AdvisorAppointment]: undefined;
};

export type HomePageStackParamList = {
  [HomePageNavigatorRoutes.HomePageRoot]: undefined;
  [HomePageNavigatorRoutes.Project]: { id: string };
  [HomePageNavigatorRoutes.RealEstate]: { id: string };

  // TODO: remove when a solution has been found for the realEstate page navigator
  [HomePageNavigatorRoutes.RealEstateMap]: { property: RealEstate };
  [HomePageNavigatorRoutes.RenovationSummaryDetail]: {
    renovationSummary: RenovationSummaryValues;
  };
  [HomePageNavigatorRoutes.FurnishmentDetails]: { furnishingAmount: number; roomCount: number };
  [HomePageNavigatorRoutes.RenovationDetails]: {
    renovationAmount: number;
    details: RenovationDetail[];
  };

  // To routing on public home page
  [PublicNavigatorRoutes.Auth]: undefined;
  [PublicNavigatorRoutes.Login]: undefined;
  [PublicNavigatorRoutes.RealEstate]: { id: string };
};

export type DiscussStackParamList = {
  [DiscussNavigatorRoutes.DiscussRoot]: undefined;
  [DiscussNavigatorRoutes.DiscussBeCalled]: undefined;
  [DiscussNavigatorRoutes.DiscussTakeAppointment]: undefined;
  [DiscussNavigatorRoutes.DiscussTakeAppointmentConfirmation]: undefined;
};

export type ForgotPasswordStackParamList = {
  [ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep]: { email: string };
  [ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep]: { email: string };
  [ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep]: { hash: string; email: string };
};

export type DebugMenuStackParamList = {
  [DebugMenuNavigatorRoutes.DebugMenuRoot]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuCodepush]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuDevice]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuEnvironmentVariables]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuAsyncStorage]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuTranslations]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuTracking]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuCustomer]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuUnleashFeatureFlags]: undefined;
};

export type RealEstatesStackParamList = {
  [RealEstateNavigatorRoutes.RealEstate]: {
    id: string;
    isFromSearchEngine?: boolean;
    isFromNotifications?: boolean;
    threshold?: number;
    position?: number;
    section?: SearchEngineSection;
    currentScrollPosition?: number;
    fromExtendedResultsSection?: ExtendedSearchResultsType;
  };
  [RealEstateNavigatorRoutes.RealEstateSold]: {
    id: string;
    isFromSearchEngine?: boolean;
    isFromNotifications?: boolean;
    threshold?: number;
    position?: number;
    section?: SearchEngineSection;
    currentScrollPosition?: number;
  };
  [RealEstateNavigatorRoutes.RealEstateMap]: { property: RealEstate };
  [RealEstateNavigatorRoutes.RealEstateList]:
    | {
        isFromNotifications?: boolean;
        position: number;
        threshold: number;
        section?: SearchEngineSection;
      }
    | undefined;
  [RealEstateNavigatorRoutes.RenovationSummaryDetail]: {
    renovationSummary: RenovationSummaryValues;
  };
  [RealEstateNavigatorRoutes.FurnishmentDetails]: { furnishingAmount: number; roomCount: number };
  [RealEstateNavigatorRoutes.RenovationDetails]: {
    renovationAmount: number;
    details: RenovationDetail[];
  };
  [RealEstateNavigatorRoutes.AdvisorAppointment]: undefined;
};

export type ProjectsStackParamList = {
  [ProjectNavigatorRoutes.Project]: { id: string };
  [ProjectNavigatorRoutes.ProjectList]: undefined;
  [ProjectNavigatorRoutes.RealEstate]: { id: string };

  // TODO: remove when a solution has been found for the realEstate page navigator
  [ProjectNavigatorRoutes.RealEstateMap]: { property: RealEstate };
  [ProjectNavigatorRoutes.RenovationSummaryDetail]: {
    renovationSummary: RenovationSummaryValues;
  };
  [ProjectNavigatorRoutes.FurnishmentDetails]: { furnishingAmount: number; roomCount: number };
  [ProjectNavigatorRoutes.RenovationDetails]: {
    renovationAmount: number;
    details: RenovationDetail[];
  };
};

export type MainMenuStackParamList = {
  [MainMenuNavigatorRoutes.Advisor]: NavigatorScreenParams<AdvisorStackParamList>;
  [MainMenuNavigatorRoutes.RealEstates]: undefined;
  [MainMenuNavigatorRoutes.Projects]: NavigatorScreenParams<ProjectsStackParamList>;
  [MainMenuNavigatorRoutes.Profile]: undefined;
  [MainMenuNavigatorRoutes.HomePage]: NavigatorScreenParams<RealEstatesStackParamList> & {
    shouldDisplayAppointmentModal?: boolean;
  };
  [MainMenuNavigatorRoutes.Discuss]: undefined;
};

export type PrivateStackParamList = {
  [PrivateNavigatorRoutes.Onboarding]: undefined;
  [PrivateNavigatorRoutes.MainMenu]: NavigatorScreenParams<MainMenuStackParamList>;
};

export type PublicStackParamList = {
  [PublicNavigatorRoutes.Auth]: { deletedAccount?: boolean } | undefined;
  [PublicNavigatorRoutes.ForgotPassword]: NavigatorScreenParams<ForgotPasswordStackParamList>;
  [PublicNavigatorRoutes.Login]:
    | { email?: string; propertyId?: string; redirectTo?: string }
    | undefined;
  [PublicNavigatorRoutes.PublicHome]: undefined;
  [PublicNavigatorRoutes.Register]: {
    email?: string;
    ['first-name']?: string;
    idToken?: string;
    ['last-name']?: string;
    method?: string;
    strategy?: string;
    propertyId?: string;
    sub?: string;
  };
  [PublicNavigatorRoutes.RealEstate]: { deletedAccount?: boolean; id?: string } | undefined;
  [PublicNavigatorRoutes.RealEstateList]: undefined;
  [PublicNavigatorRoutes.RealEstateSold]: { id: string };
  [PublicNavigatorRoutes.SsoRedirect]: {
    id?: string;
    idToken?: string;
    code?: string;
    type?: string;
    email?: string;
  };
};

export type ProfileStackParamList = {
  [ProfileNavigatorRoutes.ProfileRoot]: undefined;
  [ProfileNavigatorRoutes.ProfileAdvisor]: undefined;
  [ProfileNavigatorRoutes.ProfileAdvisorAppointment]: undefined;
  [ProfileNavigatorRoutes.ProfileDocumentsList]: {
    documents: Document[];
  };
  [ProfileNavigatorRoutes.ProfileDocument]: { id: string };
  [ProfileNavigatorRoutes.ProfileInformation]: undefined;
  [ProfileNavigatorRoutes.ProfileLang]: undefined;
  [ProfileNavigatorRoutes.ProfileLegalNotices]: undefined;
  [ProfileNavigatorRoutes.ProfilePassword]: undefined;
  [ProfileNavigatorRoutes.ProfileNotificationPreferences]: undefined;
  [ProfileNavigatorRoutes.ProfileSponsorship]: undefined;
  [ProfileNavigatorRoutes.DebugMenu]: undefined;
  [ProfileNavigatorRoutes.Auth]: { deletedAccount?: boolean };
};
