import { get } from 'lodash';

import { Lang, useAphroditeConfig } from '@app/ui/contexts/config';

type Translations<MaybeTranslationRecord> = MaybeTranslationRecord extends Record<string, unknown>
  ? {
      [key in keyof MaybeTranslationRecord]: Translations<MaybeTranslationRecord[key]>;
    }
  : string | string[];

export type TranslationHook = <TradDict extends Record<Lang, Translations<TradDict['fr']>>>(
  translations: TradDict
) => TranslationHookActions<TradDict>;

type TranslationHookActions<TradDict extends Record<Lang, Translations<TradDict['fr']>>> = {
  getCurrentLang: () => Lang;
  getTranslationDict: () => Translations<TradDict['fr']>;
  t: (key: string, replaces?: Record<string, string>) => string | string[];
};

export const useTranslation: TranslationHook = translations => {
  const { currentLang } = useAphroditeConfig();

  return {
    getCurrentLang: () => currentLang,
    getTranslationDict: () => translations[currentLang],
    t: (key, replaces = {}) => {
      let translationString: string = get(translations[currentLang], key);

      if (typeof translationString === 'undefined') {
        console.error(
          `[useTranslation] Missing translation for key '${key}' in lang '${currentLang}'`
        );
        return '';
      }

      Object.entries(replaces).forEach(([k, value]) => {
        const regexp = new RegExp(`{{ ?${k} ?}}`, 'g');
        translationString = translationString.replace(regexp, value);
      });

      return translationString;
    },
  };
};
