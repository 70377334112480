import { ListItemUnionProps } from '@app/ui';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { stringToSelect } from '@app/utils/select-formatter';

export const getCountryOptionList = (
  countryList: AvailableCountryCode[],
  t
): ListItemUnionProps[] =>
  countryList.map(countryCode => ({
    ...stringToSelect(countryCode, t(`share.country.${countryCode}`)),
    flagName: countryCode.toLocaleLowerCase(),
  })) as unknown as ListItemUnionProps[];
