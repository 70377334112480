import { Linking } from 'react-native';

import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { trackVirtualVisit } from '@app/services/tracking/trackTracking';

interface Use3dUrlButton {
  onPress: () => void;
  isButtonVisible: boolean;
}

export const use3dUrlButton = (realEstateId: string, url3d?: string | null): Use3dUrlButton => {
  const { customer, stateOfCustomer } = useMe();

  const isButtonVisible =
    (
      [
        StateOfCustomer.ASSET_MANAGEMENT_CONSULTANT,
        StateOfCustomer.POST_SIGNED_MANDATE,
        StateOfCustomer.IN_TRANSACTION,
      ] as (StateOfCustomer | null)[]
    ).includes(stateOfCustomer) &&
    !!url3d &&
    !!customer?.id;

  const onPress = () => {
    if (url3d) {
      trackVirtualVisit({ isInsideGallery: false, propertyId: realEstateId });
      Linking.openURL(url3d);
    }
  };

  return { isButtonVisible, onPress };
};
