import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgMedal = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.269 13.169l1.075-.825 1.05.825c.183.15.383.154.6.012.216-.141.283-.329.2-.562l-.425-1.35 1.2-.95a.477.477 0 00.163-.563c-.075-.225-.23-.337-.463-.337h-1.425l-.425-1.325c-.084-.233-.242-.35-.475-.35-.233 0-.392.117-.475.35l-.425 1.325h-1.425c-.233 0-.392.117-.475.35-.083.233-.033.417.15.55l1.2.95-.425 1.35c-.083.233-.021.42.187.562.208.142.413.138.613-.012zm-3.6 9.8a.94.94 0 01-.913-.125.942.942 0 01-.412-.8v-6.35a7.707 7.707 0 01-1.475-2.4 7.862 7.862 0 01-.525-2.875c0-2.233.775-4.125 2.325-5.675 1.55-1.55 3.442-2.325 5.675-2.325 2.233 0 4.125.775 5.675 2.325 1.55 1.55 2.325 3.442 2.325 5.675a7.862 7.862 0 01-.525 2.875c-.35.9-.842 1.7-1.475 2.4v6.35a.94.94 0 01-.413.8.938.938 0 01-.912.125l-4.675-1.55-4.675 1.55zm4.675-6.55c1.667 0 3.083-.583 4.25-1.75 1.166-1.167 1.75-2.583 1.75-4.25 0-1.667-.584-3.083-1.75-4.25-1.167-1.167-2.584-1.75-4.25-1.75-1.667 0-3.083.583-4.25 1.75-1.167 1.167-1.75 2.583-1.75 4.25 0 1.667.583 3.083 1.75 4.25 1.167 1.167 2.583 1.75 4.25 1.75zm-4 4.025l4-1.025 4 1.025v-3.1a8.416 8.416 0 01-1.887.787 7.69 7.69 0 01-2.113.288 7.689 7.689 0 01-2.113-.288 8.413 8.413 0 01-1.887-.787v3.1z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgMedal;
