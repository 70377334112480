import { FlexStyle, TextStyle } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { IconName } from '@app/ui/atoms/Icon/types';
import { CssTypes } from '@app/ui/types/css.types';
import { Spacing } from '@app/ui/theme/Sizes';

export type CheckboxTileLabelStyleType = {
  alignItems: FlexStyle['alignItems'];
  flex: number;
  flexDirection: FlexStyle['flexDirection'];
  justifyContent: FlexStyle['justifyContent'];
  paddingRight: number;
  wordBreak: CssTypes['wordBreak'];
};

export type CheckboxTileLayoutCheckboxType = {
  position: FlexStyle['position'];
  right: Spacing;
  top: number | undefined;
  zIndex?: number;
};

export type CheckboxTileLayoutContainerType = {
  alignItems: FlexStyle['alignItems'];
  borderRadius: Spacing;
  borderWidth: number;
  height: number | string;
  justifyContent: FlexStyle['justifyContent'];
  width: number | string;
  minWidth: number | string;
  flexDirection: FlexStyle['flexDirection'];
  paddingLeft: number;
};
export type CheckboxTileLayoutType = {
  checkbox: CheckboxTileLayoutCheckboxType;
  container: CheckboxTileLayoutContainerType;
};
export type CheckboxTileIconStyleType = {
  marginLeft: number;
  marginRight: number;
  alignItems: FlexStyle['alignItems'];
};
type CheckboxTileTextStyleType = {
  color: string;
  flexWrap: FlexStyle['flexWrap'];
  paddingHorizontal: number;
  textAlign: TextStyle['textAlign'];
};

export type CheckboxTileStyleType = {
  checkbox: CheckboxTileLayoutCheckboxType;
  container: CheckboxTileLayoutContainerType;
  containerColor: {
    backgroundColor: string;
    borderColor: string;
  };
  iconStyle: Partial<CheckboxTileIconStyleType>;
  labelContainer: CheckboxTileLabelStyleType;
  textStyle: CheckboxTileTextStyleType;
};

export enum CheckboxTileVariantType {
  Default = 'default',
  Square = 'square',
  SquareFill = 'square-fill',
}

export type CheckboxTileType = {
  testID?: string;
  label: string;
  iconName: IconName;
  disabled?: boolean;
  checked?: boolean;
  onChange: (current: boolean) => void;
  variant?: CheckboxTileVariantType;
};

export interface ColorStyle {
  theme: Theme;
  hovered: boolean;
  checked: boolean;
  disabled: boolean;
}

export interface Style extends ColorStyle {
  variant: CheckboxTileVariantType;
}
