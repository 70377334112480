import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Text, Image, Pressable, Theme, useTheme } from '@app/ui';

// eslint-disable-next-line no-magic-numbers
const DEFAULT_RATIO = 3 / 2;

const getStyles = (theme: Theme, ratio = DEFAULT_RATIO) =>
  StyleSheet.create({
    coverImageCount: {
      alignItems: 'center',
      backgroundColor: palette.neutral[900],
      borderRadius: 4,
      bottom: Spacing.SIZE_06,
      height: 24,
      justifyContent: 'center',
      position: 'absolute',
      right: Spacing.SIZE_06,
      width: 37,
      zIndex: 50,
    },
    image: { aspectRatio: ratio, borderRadius: theme.borderRadius.SIZE_06 },
  });

type GalleryThumbnailProps = {
  thumbnailImageUrl: string;
  totalImages: number;
  ratio?: number;
  onPress: () => void;
};

export const GalleryThumbnail: React.FC<GalleryThumbnailProps> = ({
  ratio = DEFAULT_RATIO,
  thumbnailImageUrl,
  totalImages,
  onPress,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme, ratio), [theme, ratio]);

  return (
    <View>
      <View style={styles.coverImageCount}>
        <Text textStyle="Body2" style={{ color: palette.base.white }}>
          1/{totalImages}
        </Text>
      </View>

      <Pressable onPress={onPress}>
        <Image
          source={{ uri: thumbnailImageUrl }}
          style={styles.image}
          resizeMode="cover"
          aspectRatio={ratio}
        />
      </Pressable>
    </View>
  );
};
