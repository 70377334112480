import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKz = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="kz_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#kz_svg__a)">
        <Path fill="#30C6E0" d="M0 0h28v20H0z" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 14a4 4 0 100-8 4 4 0 000 8z"
          fill="#FFCD4B"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.368 17.15L14 14.713l1.632 2.435.413-2.902 2.527 1.486-.887-2.794 2.922.243-2.012-2.133L21.333 10l-2.738-1.049 2.012-2.133-2.922.243.887-2.794-2.527 1.486-.413-2.902L14 5.286l-1.632-2.435-.413 2.902-2.527-1.486.887 2.794-2.922-.243 2.012 2.133L6.667 10l2.738 1.049-2.012 2.133 2.922-.243-.887 2.794 2.527-1.486.413 2.902zM18.667 10a4.667 4.667 0 11-9.334 0 4.667 4.667 0 019.334 0zM1.333 1.667c0-.184.15-.334.334-.334h.666c.184 0 .334.15.334.334v.666c0 .184-.15.334-.334.334h-.666a.333.333 0 01-.334-.334v-.666zm0 2.666c0-.184.15-.333.334-.333h.666c.184 0 .334.15.334.333V5c0 .184-.15.333-.334.333h-.666A.333.333 0 011.333 5v-.667zm.334 2.334A.333.333 0 001.333 7v.667c0 .184.15.333.334.333h.666c.184 0 .334-.15.334-.333V7a.333.333 0 00-.334-.333h-.666zm-.334 3c0-.184.15-.334.334-.334h.666c.184 0 .334.15.334.334v.666c0 .184-.15.334-.334.334h-.666a.333.333 0 01-.334-.334v-.666zM1.667 12a.333.333 0 00-.334.333V13c0 .184.15.333.334.333h.666c.184 0 .334-.149.334-.333v-.667A.333.333 0 002.333 12h-.666zm-.334 3c0-.184.15-.333.334-.333h.666c.184 0 .334.149.334.333v.667c0 .184-.15.333-.334.333h-.666a.333.333 0 01-.334-.333V15zm.334 2.333a.333.333 0 00-.334.334v.666c0 .184.15.334.334.334h.666c.184 0 .334-.15.334-.334v-.666a.333.333 0 00-.334-.334h-.666zm1-1c0-.184.149-.333.333-.333h.667c.184 0 .333.15.333.333V17c0 .184-.15.333-.333.333H3A.333.333 0 012.667 17v-.667zm.333-3a.333.333 0 00-.333.334v.666c0 .184.149.334.333.334h.667c.184 0 .333-.15.333-.334v-.666a.333.333 0 00-.333-.334H3zM2.667 11c0-.184.149-.333.333-.333h.667c.184 0 .333.149.333.333v.667c0 .184-.15.333-.333.333H3a.333.333 0 01-.333-.333V11zM3 8a.333.333 0 00-.333.333V9c0 .184.149.333.333.333h.667C3.85 9.333 4 9.184 4 9v-.667A.333.333 0 003.667 8H3zm-.333-2.333c0-.184.149-.334.333-.334h.667c.184 0 .333.15.333.334v.666c0 .184-.15.334-.333.334H3a.333.333 0 01-.333-.334v-.666zm.333-3A.333.333 0 002.667 3v.667c0 .184.149.333.333.333h.667C3.85 4 4 3.85 4 3.667V3a.333.333 0 00-.333-.333H3z"
          fill="#FFCD4B"
        />
      </G>
    </Svg>
  );
};

export default SvgKz;
