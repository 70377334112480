import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTrain = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M7.923 21.097a.828.828 0 01-.637-.625c-.092-.334-.022-.617.212-.85l.5-.5c-.984 0-1.813-.337-2.487-1.012-.676-.675-1.013-1.505-1.013-2.488v-9.5c0-.884.229-1.588.688-2.113.458-.525 1.062-.925 1.812-1.2.75-.275 1.604-.458 2.562-.55a30.965 30.965 0 012.938-.137c1.1 0 2.137.045 3.113.137.974.092 1.824.275 2.55.55.724.275 1.295.675 1.712 1.2.416.525.625 1.23.625 2.113v9.5c0 .983-.338 1.813-1.012 2.488-.676.675-1.505 1.012-2.488 1.012l.5.5c.233.233.304.517.212.85a.828.828 0 01-.637.625.965.965 0 01-.437 0 .805.805 0 01-.388-.225l-1.75-1.75h-4l-1.75 1.75a.805.805 0 01-.387.225.97.97 0 01-.438 0zm4.575-16.975c-1.767 0-3.059.104-3.875.312-.817.208-1.375.438-1.675.688h11.2c-.25-.284-.788-.521-1.612-.713-.826-.192-2.172-.287-4.038-.287zm-6 6h5v-3h-5v3zm10.5 2h-10.5 11.575-1.075zm-3.5-2h5v-3h-5v3zm-4.5 6c.433 0 .791-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.434-.142-.792-.425-1.075-.284-.284-.642-.425-1.075-.425-.434 0-.792.142-1.075.425-.284.283-.425.641-.425 1.075 0 .433.141.792.425 1.075.283.283.641.425 1.075.425zm7 0c.433 0 .791-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.434-.142-.792-.425-1.075-.284-.284-.642-.425-1.075-.425-.434 0-.792.142-1.075.425-.284.283-.425.641-.425 1.075 0 .433.141.792.425 1.075.283.283.641.425 1.075.425zm-8 1h9c.433 0 .791-.142 1.075-.425.283-.284.425-.642.425-1.075v-3.5h-12v3.5c0 .433.141.791.425 1.075.283.283.641.425 1.075.425zm4.5-12h5.65-11.2 5.55z"
        fill={props.color}
      />
    </Svg>
  );
};

export default SvgTrain;
