import React from 'react';

import { View } from 'react-native';

import { ActionButton } from '@app/ui/molecules/ActionButton';

import { SimpleTextActivatorProps } from './SimpleTextActivator.types';

export { SimpleTextActivatorProps };

export const SimpleTextActivator: React.FC<SimpleTextActivatorProps> = ({
  onPress,
  open,
  value,
}) => {
  return (
    <View collapsable={false} testID="SimpleTextActivator">
      <ActionButton
        onPress={onPress}
        rightIconName={open ? 'ChevronUp' : 'ChevronDown'}
        label={value}
      />
    </View>
  );
};
