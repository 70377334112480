import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';

import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { LayoutChangeEvent, TextInput, View } from 'react-native';

import { prefixOptions } from '@app/ui/utils/phone';
import { phoneInputTheme } from '@app/ui/molecules/phone-input/phoneInput.theme';
import { useComponentTheme } from '@app/ui/utils/useComponentTheme';
import { RotatingChevron } from '@app/ui/atoms/RotatingChevron';
import { Input } from '@app/ui/atoms/input/Input';
import { Text } from '@app/ui/atoms/Text/Text';
import { Flag, FlagName } from '@app/ui/atoms/Flag/Flag';
import { Dropdown } from '@app/ui/molecules/dropdown/Dropdown';
import { List, ListItem } from '@app/ui/atoms/list/List';
import { Pressable } from '@app/ui/atoms/pressable/Pressable';

import { getStyles } from './PhoneInput.styles';
import { PhoneInputProps } from './PhoneInput.type';

export const PhoneInput: React.FC<PhoneInputProps> = ({
  placeholder,
  options = prefixOptions as {
    countryCode: string;
    title: string;
    symbol: string;
    value: string;
  }[],
  countryValue = '+33',
  value,
  onCountryChange,
  onChange,
  disabled,
  error,
  autoFormat = true,
  testID: inputTestId,
  dropdownTogglerTestID,
  errorMessageTestID,
  hint,
  isValid,
  label,
  customInputStyles,
}) => {
  const inputRef = useRef<TextInput>(null);

  const [isOpened, setIsOpened] = useState(false);
  const [prefixWidth, setPrefixWidth] = useState(0);

  const componentTheme = useComponentTheme('phoneInput', phoneInputTheme);

  useEffect(() => {
    error && setIsOpened(false);
  }, [error]);

  const styles = useMemo(() => getStyles({ componentTheme, isOpened }), [componentTheme, isOpened]);
  const selectedOption = useMemo(
    () => options.find(v => v.value === countryValue) || options[0],
    [options, countryValue]
  );

  const formattedValue = formatIncompletePhoneNumber(`${countryValue}${value}`).split(
    `${countryValue}`
  )[1];

  const flagName = selectedOption.countryCode.toLowerCase() as FlagName;

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;

      layout.width !== prefixWidth && setPrefixWidth(layout.width);
    },
    [prefixWidth, setPrefixWidth]
  );

  const renderPrefix = () => (
    <Pressable
      testID={dropdownTogglerTestID}
      onLayout={onLayout}
      onPress={() => !disabled && setIsOpened(!isOpened)}
      style={styles.selectButton}
    >
      <Flag name={flagName} size={componentTheme.iconSize} />
      <Text style={styles.textPrefixStyle} textStyle="Caption1">
        {selectedOption?.value}
      </Text>
      <RotatingChevron
        style={styles.chevronPrefixStyle}
        size={componentTheme.iconSize}
        opened={isOpened}
      />
    </Pressable>
  );

  return (
    <View>
      <Input
        ref={inputRef}
        label={label}
        placeholder={placeholder}
        value={autoFormat ? formattedValue : value}
        error={error}
        onChangeText={onChange}
        style={[styles.input, customInputStyles]}
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        autoComplete="tel"
        testID={inputTestId}
        errorMessageTestID={errorMessageTestID}
        prefix={renderPrefix()}
        prefixWidth={prefixWidth}
        editable={!disabled}
        hint={hint}
        isValid={isValid}
      />
      <Dropdown parentRef={inputRef} isOpen={isOpened} onClose={() => setIsOpened(false)}>
        <List isHeightLimited>
          {options.map(({ countryCode, title, value: countryPrefixValue }) => (
            <ListItem
              key={countryCode}
              label={title}
              flagName={countryCode.toLocaleLowerCase() as FlagName}
              onPress={() => onCountryChange(countryPrefixValue)}
            />
          ))}
        </List>
      </Dropdown>
    </View>
  );
};
