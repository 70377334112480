import React from 'react';

import { View } from 'react-native';

import { Icon } from '@app/ui/atoms/Icon';
import { Avatar } from '@app/ui/atoms/Avatar/Avatar';
import { useTheme } from '@app/ui/contexts/config';
import { Text } from '@app/ui/atoms/Text/Text';

import { getCalloutStyle } from './Callout.styles';
import { CalloutProps } from './Callout.types';

export const Callout: React.FC<CalloutProps> = ({
  text,
  wrapperStyle,
  icon,
  avatarUrl,
  tint,
  testID,
}) => {
  const theme = useTheme();
  const _tint = tint ?? theme.palette.tint.blue;
  const style = getCalloutStyle({ theme, tint: _tint });
  const showIcon = !avatarUrl;
  const showAvatar = !!avatarUrl && !icon;

  return (
    <View style={[style.container, wrapperStyle]} testID={testID}>
      {!!(showIcon || showAvatar) && (
        <View style={style.iconContainer}>
          {!!showIcon && (
            <Icon
              name={icon ?? 'Help'}
              size={theme.spacing.SIZE_05}
              color={_tint[700]}
              testID="callout-icon"
            />
          )}
          {!!showAvatar && <Avatar avatarUrl={avatarUrl} size={32} testID="callout-avatar" />}
        </View>
      )}
      <Text style={style.textStyle} textStyle="Body3">
        {text}
      </Text>
    </View>
  );
};

export { CalloutProps };
