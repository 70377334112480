import React from 'react';

import { ColorValue } from 'react-native';

import { useTheme } from '@app/ui/contexts/config';

import { GetStylesReturnValues, IconButtonProps, PressableState } from './IconButton.types';
import getStyles from './IconButton.styles';
import { Pressable } from '../pressable/Pressable';
import { Icon } from '../Icon';

export const getIconColor = ({
  iconColor,
  styles,
  dark,
}: {
  iconColor?: ColorValue;
  styles: GetStylesReturnValues;
  dark: boolean;
}) => {
  if (iconColor) {
    return iconColor;
  }

  return dark ? styles.inDark.color : styles.inLight.color;
};

export const IconButton: React.FC<IconButtonProps> = ({
  onPress,
  iconName,
  iconColor,
  iconSize = 'm',
  buttonRef = null,
  disabled = false,
  dark = false,
  testID,
  hitSlop,
}) => {
  const theme = useTheme();
  const iconSizeInNumber = iconSize === 'm' ? 24 : 16;

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      testID={testID}
      ref={buttonRef}
      hitSlop={hitSlop || 0}
    >
      {({ hovered, pressed }: PressableState) => {
        const styles = getStyles({
          disabled,
          hovered,
          pressed,
          size: iconSize,
          theme,
        });

        const computedIconColor = getIconColor({ dark, iconColor, styles });
        return (
          <Icon
            color={computedIconColor}
            name={iconName}
            style={styles.iconSize}
            size={iconSizeInNumber}
          />
        );
      }}
    </Pressable>
  );
};

export { IconButtonProps } from './IconButton.types';
