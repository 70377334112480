import React, { useEffect } from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { OnboardingView } from '@app/views/onboarding/OnboardingView';
import { LoginEmailPasswordView } from '@app/views/loginEmailPassword/LoginEmailPasswordView';
import { AuthBridgeView } from '@app/views/authBridge/AuthBridgeView';
import { ForgotPasswordNavigator } from '@app/navigation/ForgotPasswordNavigator';
import { SsoRedirect } from '@app/features/authBridge/sso-redirect/SsoRedirect.web';
import { RealEstateAvailableView } from '@app/views/realEstate/realEstateAvailable/RealEstateAvailableView';
import { isWeb } from '@app/constants/platform';
import { storage } from '@app/services/storage/storage';
import { PublicHomeView } from '@app/views/publicHome/PublicHomeView';
import { RealEstateSoldView } from '@app/views/realEstate/realEstateSold/RealEstateSoldView';

import { SCREEN_OPTIONS } from './constants/navigation-options';
import { PublicNavigatorRoutes, PublicStackParamList } from './types/routes';
import { useCurrentRoute } from './helpers/useCurrentRoutes';
import { navigationRef } from './navigationRef';

const Stack = createStackNavigator<PublicStackParamList>();

const PublicNavigator: React.FC = () => {
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    if (!navigationRef.isReady() || !isWeb()) {
      return;
    }

    const saveState = async () => {
      await storage.saveObject('navigation_state', JSON.stringify(navigationRef.getState()));
    };
    saveState();
  }, [currentRoute?.name]);

  return (
    <Stack.Navigator screenOptions={SCREEN_OPTIONS}>
      <Stack.Screen name={PublicNavigatorRoutes.Auth} component={AuthBridgeView} />
      {isWeb() && (
        <>
          <Stack.Screen name={PublicNavigatorRoutes.PublicHome} component={PublicHomeView} />
          {/* so the navigation is handled when not logged in */}
          <Stack.Screen name={PublicNavigatorRoutes.RealEstateList} component={PublicHomeView} />
        </>
      )}
      <Stack.Screen name={PublicNavigatorRoutes.Register} component={OnboardingView} />
      <Stack.Screen
        name={PublicNavigatorRoutes.ForgotPassword}
        component={ForgotPasswordNavigator}
      />
      <Stack.Screen name={PublicNavigatorRoutes.Login} component={LoginEmailPasswordView} />
      <Stack.Screen name={PublicNavigatorRoutes.SsoRedirect} component={SsoRedirect} />
      {isWeb() && (
        <>
          <Stack.Screen
            name={PublicNavigatorRoutes.RealEstate}
            component={RealEstateAvailableView}
          />
          <Stack.Screen
            name={PublicNavigatorRoutes.RealEstateSold}
            component={RealEstateSoldView}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export { PublicNavigator };
