import React, { PropsWithChildren } from 'react';

import { Transition, TransitionStatus } from 'react-transition-group';

import { IconButton, useResponsive } from '@app/ui';

import * as s from './drawer.styles.web';

const TIMEOUT = 500;

interface DrawerProps {
  isOpen?: boolean;
  onRest?: () => void;
  onClickBackdrop?: () => void;
  onClickCrossBtn?: () => void;
  style?: React.CSSProperties;
  direction?: 'right' | 'bottom';
  showCloseBtn?: boolean;
}

interface ContentState {
  bottom: Record<TransitionStatus, React.CSSProperties>;
  right: Record<TransitionStatus, React.CSSProperties>;
}

const contentState: ContentState = {
  bottom: {
    entered: {
      bottom: '0%',
      transition: `bottom ${TIMEOUT}ms ease`,
    },
    entering: {
      bottom: '-100%',
    },
    exited: {},
    exiting: {
      bottom: '-100%',
      transition: `bottom ${TIMEOUT}ms ease`,
    },
    unmounted: {},
  },
  right: {
    entered: {
      right: 0,
    },
    entering: {
      right: 0,
      transition: `right ${TIMEOUT}ms ease`,
    },
    exited: {
      right: '-100%',
    },
    exiting: {
      right: '-100%',
      transition: `right ${TIMEOUT}ms ease`,
    },
    unmounted: {
      visibility: 'hidden',
    },
  },
};
const backdropState: Record<TransitionStatus, React.CSSProperties> = {
  entered: {
    opacity: 1,
  },
  entering: {
    opacity: 1,
    transition: `opacity ${TIMEOUT}ms ease-out`,
    visibility: 'visible',
  },
  exited: {
    opacity: 0,
    visibility: 'hidden',
  },
  exiting: {
    opacity: 0,
    transition: `opacity ${TIMEOUT}ms ease-out`,
  },
  unmounted: {},
};

// TODO : refacto with Portal : https://fr.reactjs.org/docs/portals.html
export const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({
  children,
  isOpen,
  onRest,
  onClickBackdrop,
  onClickCrossBtn,
  showCloseBtn = false,
  style = {},
  direction = 'bottom',
}) => {
  const { isMobile } = useResponsive();

  return (
    <Transition in={isOpen} timeout={TIMEOUT} onExited={onRest} appear>
      {state => {
        return (
          <s.Backdrop onClick={onClickBackdrop} style={{ ...backdropState[state] }}>
            <s.ContentWrapper onClick={e => e.stopPropagation()}>
              <s.Content
                style={{ ...style, ...contentState[direction][state] }}
                $isMobile={isMobile}
                direction={direction}
              >
                {!!showCloseBtn && (
                  <s.CloseBtnWrapper>
                    <IconButton onPress={onClickCrossBtn} iconName="Cross" />
                  </s.CloseBtnWrapper>
                )}
                {children}
              </s.Content>
            </s.ContentWrapper>
          </s.Backdrop>
        );
      }}
    </Transition>
  );
};
