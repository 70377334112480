import React from 'react';

import { StyleSheet, View } from 'react-native';
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from 'react-native-reanimated';

import { Theme, useTheme } from '@app/ui';

interface PageIndicatorProps {
  index: number;
  length: number;
  animValue: Animated.SharedValue<number>;
}

const PAGE_INDICATOR_WIDTH = 8;

const getStyles = (theme: Theme, width: number) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.neutral[300],
      borderRadius: 50,
      height: width,
      overflow: 'hidden',
      width: width,
    },
  });

export const PageIndicator: React.FC<PageIndicatorProps> = ({ animValue, index, length }) => {
  const theme = useTheme();

  const styles = getStyles(theme, PAGE_INDICATOR_WIDTH);

  const animStyle = useAnimatedStyle(() => {
    let inputRange = [index - 1, index, index + 1];
    let outputRange = [-PAGE_INDICATOR_WIDTH, 0, PAGE_INDICATOR_WIDTH];

    if (index === 0 && animValue?.value > length - 1) {
      inputRange = [length - 1, length, length + 1];
      outputRange = [-PAGE_INDICATOR_WIDTH, 0, PAGE_INDICATOR_WIDTH];
    }

    return {
      transform: [
        {
          translateX: interpolate(animValue?.value, inputRange, outputRange, Extrapolate.CLAMP),
        },
      ],
    };
  }, [animValue, index, length]);

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          {
            backgroundColor: theme.palette.neutral[800],
            borderRadius: 50,
            flex: 1,
          },
          animStyle,
        ]}
      />
    </View>
  );
};
