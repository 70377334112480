import type { Device, ScreenValue } from './types';

export const isGreaterThanScreen = (
  device: Omit<Device, 'wide'>,
  { isUltraWide, isWide, isTablet, isLaptop, isDesktop }: ScreenValue
) => {
  switch (device) {
    case 'mobile':
      return isTablet || isLaptop || isDesktop || isWide || isUltraWide;
    case 'tablet':
      return isLaptop || isDesktop || isWide || isUltraWide;
    case 'laptop':
      return isDesktop || isWide || isUltraWide;
    case 'desktop':
      return isWide || isUltraWide;
    case 'wide':
      return isUltraWide;
    default:
      return false;
  }
};

export const isLowerThanScreen = (
  device: Omit<Device, 'mobile'>,
  { isMobile, isTablet, isLaptop, isDesktop, isWide }: ScreenValue
) => {
  switch (device) {
    case 'tablet':
      return isMobile;
    case 'laptop':
      return isMobile || isTablet;
    case 'desktop':
      return isMobile || isTablet || isLaptop;
    case 'wide':
      return isMobile || isTablet || isLaptop || isDesktop;
    case 'ultraWide':
      return isMobile || isTablet || isLaptop || isDesktop || isWide;
    default:
      return false;
  }
};

export const isScreen = (
  device: Device,
  { isMobile, isTablet, isLaptop, isDesktop, isWide, isUltraWide }: ScreenValue
) => {
  switch (device) {
    case 'tablet':
      return isTablet;
    case 'laptop':
      return isLaptop;
    case 'desktop':
      return isDesktop;
    case 'wide':
      return isWide;
    case 'ultraWide':
      return isUltraWide;
    case 'mobile':
    default:
      return isMobile;
  }
};

export const getDevice = ({
  isMobile,
  isTablet,
  isLaptop,
  isDesktop,
  isWide,
}: ScreenValue): Device => {
  if (isMobile) {
    return 'mobile';
  }
  if (isTablet) {
    return 'tablet';
  }
  if (isLaptop) {
    return 'laptop';
  }
  if (isDesktop) {
    return 'desktop';
  }
  if (isWide) {
    return 'wide';
  }
  return 'ultraWide';
};
