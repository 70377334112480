import React from 'react';

import { StyleSheet } from 'react-native';

import { Pressable, useTheme, Theme } from '@app/ui';

import {
  RealEstateHeaderProps,
  RealEstatePreviewCardHeader,
} from './components/Horizontal/header/RealEstatePreviewCardHeaderHorizontal';
import {
  RealEstatePreviewCardBodyHorizontal,
  RealEstatePreviewCardBodyHorizontalProps,
} from './components/Horizontal/body/RealEstatePreviewCardBodyHorizontal';

type RealEstatePreview = RealEstateHeaderProps & RealEstatePreviewCardBodyHorizontalProps;

interface RealEstatePreviewCardHorizontalProps {
  isPublic?: boolean;
  realEstatePreview: RealEstatePreview;
  onBookmark: () => void;
  onPress: () => void;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
      height: 120,
    },
  });

export const RealEstatePreviewCardHorizontal: React.FC<RealEstatePreviewCardHorizontalProps> = ({
  isPublic = false,
  onBookmark,
  onPress,
  realEstatePreview: {
    image,
    isNew,
    shortTermNightlyRent,
    isExclusive,
    positioningStatus,
    isInTransaction,
    expirationDate,
    isBookmarked,
    title,
    surface,
    grossReturnRate,
    netReturnRate,
    price,
    propertyKind,
    totalLots,
    roomCount,
    isViewed,
  },
}) => {
  const theme = useTheme();

  const styles = getStyles(theme);

  return (
    <Pressable onPress={onPress} style={styles.container} testID="real-estate-card--container">
      <RealEstatePreviewCardHeader
        isPublic={isPublic}
        onBookmark={onBookmark}
        image={image}
        isBookmarked={isBookmarked}
        isViewed={isViewed}
      />
      <RealEstatePreviewCardBodyHorizontal
        isBookmarked={isBookmarked}
        isViewed={isViewed}
        grossReturnRate={grossReturnRate}
        netReturnRate={netReturnRate}
        expirationDate={expirationDate}
        isExclusive={isExclusive}
        isInTransaction={isInTransaction}
        isNew={isNew}
        positioningStatus={positioningStatus}
        shortTermNightlyRent={shortTermNightlyRent}
        price={price}
        propertyKind={propertyKind}
        roomCount={roomCount}
        surface={surface}
        title={title}
        totalLots={totalLots}
      />
    </Pressable>
  );
};
