import { StyleSheet } from 'react-native';

import { Theme } from '@app/ui/theme/types';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    buttonClear: { flex: 1 / 3 },
    buttonSend: { flex: 2 / 3 },
    container: {
      flexDirection: 'row',
      gap: theme.spacing.SIZE_03,
    },
  });
