import styled from '@emotion/styled';

import { ListType } from '../dropdownV2/Dropdown.types';

export const Container = styled.div<{ $maxHeight: ListType['maxHeight'] }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : '100%')};
  overflow: hidden;
`;

export const List = styled.div`
  flex: 1;
  overflow: auto;
`;
