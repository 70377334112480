import * as React from 'react';

import Svg, { Rect, Mask, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SvgGd = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="gd_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#gd_svg__a)">
        <Path fill="#E42235" d="M0 0h28v20H0z" />
        <Path fill="#079B77" d="M2.667 2.667h22.667v14.667H2.667z" />
        <Mask
          id="gd_svg__b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={2}
          y={2}
          width={24}
          height={16}
        >
          <Path fill="#fff" d="M2.667 2.667h22.667v14.667H2.667z" />
        </Mask>
        <G mask="url(#gd_svg__b)" fillRule="evenodd" clipRule="evenodd">
          <Path
            d="M4.393 10.132c.378.604.92 1.003 1.424 1.105.278.056-.23-1.218-.009-1.357.19-.118 1.1.942 1.18.72.183-.508.075-1.228-.333-1.881-.586-.937-.609-.319-2.19-.99.165.96-.657 1.466-.072 2.403z"
            fill="#FFD938"
          />
          <Path
            d="M14 10l11.333-7.333H2.667L14 10zm0 0L2.667 17.333h22.666L14 10z"
            fill="#FFD93B"
          />
          <Path
            d="M14 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666z"
            fill="url(#gd_svg__paint0_linear_4223_10921)"
          />
          <Path
            d="M14 11.028l-1.567 1.13.589-1.84-1.558-1.142 1.931-.008L14 7.333l.604 1.835 1.932.008-1.558 1.142.59 1.84L14 11.027z"
            fill="#FFD938"
          />
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="gd_svg__paint0_linear_4223_10921"
          x1={10.667}
          y1={6.667}
          x2={10.667}
          y2={13.333}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E21C30" />
          <Stop offset={1} stopColor="#CE1225" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SvgGd;
