import React, { useState } from 'react';

import { Pressable } from '@app/ui/atoms/pressable/Pressable';
import { Text } from '@app/ui/atoms/Text/Text';
import { useResponsive } from '@app/ui/utils/responsive/useResponsive';
import { isNative } from '@app/constants/platform';
import { Spacing } from '@app/ui/theme/Sizes';
import { Spacer } from '@app/ui/atoms/Spacer/Spacer';

import { PopoverOrModalProps } from './PopoverOrModal.types';
import { Popover } from '../popover/Popover';
import { BottomSheet } from '../bottom-sheet/BottomSheet';

export { PopoverOrModalProps };

export const PopoverOrModal: React.FC<PopoverOrModalProps> = ({
  children,
  content,
  onClose,
  onOpen,
  onPopoverPress,
  testID,
  title,
  triggerType,
  variant,
}) => {
  const { isLowerThanDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onPressCloseModal = () => {
    setIsModalVisible(false);
    onClose?.();
  };
  const onPressOpenModal = () => {
    setIsModalVisible(true);
    onOpen?.();
  };

  return isLowerThanDesktop || isNative() ? (
    <>
      <BottomSheet
        onPressClose={onPressCloseModal}
        onShow={onOpen}
        visible={isModalVisible}
        testID={testID}
      >
        {!!title && (
          <>
            <Text testID="ModalTitle" textStyle="Title3Medium">
              {title}
            </Text>
            <Spacer height={Spacing.SIZE_04} />
          </>
        )}

        <Text textStyle="Caption1">{content}</Text>
      </BottomSheet>
      <Pressable onPress={onPressOpenModal}>{children}</Pressable>
    </>
  ) : (
    <Popover
      title={title}
      content={content}
      onOpen={onOpen}
      onPress={onPopoverPress}
      onClose={onClose}
      testID={testID}
      variant={variant}
      triggerType={triggerType}
    >
      {children}
    </Popover>
  );
};
