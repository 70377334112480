import React, { useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { IconButton, Theme, useTheme } from '@app/ui';

interface GalleryArrows {
  onLeftPress: () => void;
  onRightPress: () => void;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    arrows: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 320,
      width: '100%',
    },
    leftArrow: {
      left: -theme.spacing.SIZE_09,
      zIndex: 100,
    },
    rightArrow: {
      right: -theme.spacing.SIZE_09,
      zIndex: 100,
    },
  });

export const GalleryArrows: React.FC<GalleryArrows> = ({ onLeftPress, onRightPress }) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <View style={styles.arrows}>
      <View style={styles.leftArrow}>
        <IconButton iconName="ChevronLeft" iconSize="m" dark onPress={onLeftPress} />
      </View>
      <View style={styles.rightArrow}>
        <IconButton iconName="ChevronRight" iconSize="m" dark onPress={onRightPress} />
      </View>
    </View>
  );
};
