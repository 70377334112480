import React, { useCallback, useMemo, useState } from 'react';

import { CheckboxTileGroup, CheckboxTileVariantType, Spacer } from '@app/ui';
import { ProfileNotificationPreferenceProps } from '@app/features/profile/types/notificationPrefences.type';
import { notificationToCheckboxTiles } from '@app/features/profile/utils/formatNotificationPreferences.utils';
import { CustomerNotificationInput } from '@app/libs/apollo/introspection';
import {
  AllowNotificationsSource,
  trackNotificationActivated,
  trackNotificationDeactivated,
} from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';

import { NotificationPreferencesSource, NotificationPreferencesType } from './constants';

export const ProfileNotificationPreference: React.FC<ProfileNotificationPreferenceProps> = ({
  category,
  notificationTypes,
  onChange,
  subTitle,
  title,
  calloutText,
  disabled,
  testID = 'notif-checkbox-grp',
}) => {
  const [notificationStatuses, setNotificationStatuses] =
    useState<CustomerNotificationInput>(notificationTypes);

  const { t, i18n } = useTranslation();

  const notificationOnChange = useCallback(
    type => {
      const preferences = {
        ...notificationStatuses,
        [type]: { status: !notificationStatuses[type]?.status ?? false },
      };
      setNotificationStatuses(preferences);
      onChange(category, preferences);

      const trackingData = {
        section: NotificationPreferencesSource[category],
        source: AllowNotificationsSource.Profile,
        type: NotificationPreferencesType[type],
      };
      !notificationStatuses[type]?.status
        ? trackNotificationActivated(trackingData)
        : trackNotificationDeactivated(trackingData);
    },
    [setNotificationStatuses, onChange, category, notificationStatuses]
  );

  const getCheckboxTileList = useMemo(
    () =>
      notificationToCheckboxTiles({
        category,
        notificationOnChange,
        notificationStatuses,
        t,
      }),
    [t, notificationOnChange, notificationStatuses]
  );

  const getCalloutText = i18n.exists(calloutText) ? t(calloutText) : undefined;

  return (
    <>
      <CheckboxTileGroup
        checkboxTileVariant={CheckboxTileVariantType.SquareFill}
        subtitle={t(subTitle)}
        title={t(title)}
        items={getCheckboxTileList}
        testID={testID}
        calloutText={getCalloutText}
        disabled={disabled}
      />

      <Spacer height={16} />
    </>
  );
};
