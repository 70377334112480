import { TFunction } from 'i18next';

import {
  SoldRealEstateBeforeRenovationV2,
  SoldRealEstateBuildingPointsOfInterestV2,
} from '@app/libs/apollo/introspection';
import { DataRowsProps } from '@app/ui/organisms/data-rows/DataRows';

type getBeforeRenovationRowsParams = Pick<
  SoldRealEstateBuildingPointsOfInterestV2,
  'transport' | 'places' | 'education' | 'businesses'
> &
  Pick<SoldRealEstateBeforeRenovationV2, 'dpe'> & {
    t: TFunction;
  };

export const getBeforeRenovationRows = ({
  dpe,
  transport,
  places,
  education,
  businesses,
  t,
}: getBeforeRenovationRowsParams): DataRowsProps['rows'] => {
  const rows: DataRowsProps['rows'] = [];

  if (transport) {
    rows.push({
      description: transport,
      iconName: 'Train',
      label: t('propertySold.beforeRenovation.pointsOfInterest.transport'),
    });
  }

  if (places) {
    rows.push({
      description: places,
      iconName: 'Interest',
      label: t('propertySold.beforeRenovation.pointsOfInterest.places'),
    });
  }

  if (education) {
    rows.push({
      description: education,
      iconName: 'School',
      label: t('propertySold.beforeRenovation.pointsOfInterest.education'),
    });
  }

  if (businesses) {
    rows.push({
      description: businesses,
      iconName: 'Store',
      label: t('propertySold.beforeRenovation.pointsOfInterest.businesses'),
    });
  }

  if (dpe) {
    rows.push({
      description: t('propertyRenovation.DPE.data', { value: dpe }),
      iconName: 'Zap',
      label: t('shared.performanceIndicator.DPE.acronym'),
    });
  }

  return rows;
};
