import * as React from 'react';

import Svg, { Rect, Mask, G, Path } from 'react-native-svg';

const SvgKn = props => {
  return (
    <Svg width={28} height={20} viewBox="0 0 28 20" fill="none" {...props}>
      <Rect width={28} height={20} rx={2} fill="#fff" />
      <Mask
        id="kn_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <Rect width={28} height={20} rx={2} fill="#fff" />
      </Mask>
      <G mask="url(#kn_svg__a)" fillRule="evenodd" clipRule="evenodd">
        <Path d="M0 13.333L28 0H0v13.333z" fill="#1EC160" />
        <Path d="M0 20h28V6.667L0 20z" fill="#DF2A40" />
        <Path d="M.784 25.333l32.791-20.49-6.359-10.176-32.791 20.49L.784 25.333z" fill="#FFD956" />
        <Path d="M-.63 23.072l32.792-20.49-3.533-5.654-32.791 20.49 3.533 5.654z" fill="#262626" />
        <Path
          d="M20.31 7.797l-.946 1.498-.613-1.661-1.717-.437 1.39-1.097-.115-1.767 1.473.984 1.645-.656-.48 1.705 1.132 1.361-1.77.07zM9.643 14.264l-.946 1.498-.613-1.662-1.716-.436 1.39-1.097-.115-1.767 1.472.983 1.645-.655-.48 1.704 1.132 1.362-1.77.07z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default SvgKn;
