import { StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@app/ui/theme/types';
import { Tint } from '@app/ui/theme/palette';

export const getCalloutWithButtonStyle = ({
  theme,
  tint,
  isLowerThanDesktop,
}: {
  theme: Theme;
  tint: Tint;
  isLowerThanDesktop: boolean;
}): StyleSheet.NamedStyles<{
  container: ViewStyle;
  iconContainer: ViewStyle;
  textStyle: ViewStyle;
  textContainer: ViewStyle;
  titleContainer: ViewStyle;
}> => {
  return StyleSheet.create({
    container: {
      alignItems: isLowerThanDesktop ? 'stretch' : 'center',
      backgroundColor: tint[50],
      borderRadius: theme.spacing.SIZE_04,
      flexDirection: isLowerThanDesktop ? 'column' : 'row',
      gap: 16,
      padding: theme.spacing.SIZE_04,
    },
    iconContainer: {
      height: '100%',
      marginRight: theme.spacing.SIZE_03,
    },
    textContainer: {
      flex: 1,
      gap: 4,
    },
    textStyle: {
      color: tint[700],
      flex: 1,
    },
    titleContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  });
};
