import { TFunction } from 'i18next';

import { PropertyKind } from '@app/libs/apollo/introspection';
import { ChipProps, IconName } from '@app/ui';
import { formatRate } from '@app/utils/format-rate';

const surfaceMapIcon: Record<PropertyKind, IconName> = {
  [PropertyKind.Apartment]: 'Flat',
  [PropertyKind.Building]: 'Building',
  [PropertyKind.House]: 'Home',
};

type GetTagListParam = {
  t: TFunction;
  surface: number;
  propertyKind: PropertyKind;
  totalLots: number;
  roomCount?: number;
  grossReturnRate: number;
  netReturnRate: number;
};

const formatAndRemoveSpaces = (value: string) => value.replace(/\s/g, '');

export const getTagList = ({
  t,
  propertyKind,
  surface,
  totalLots,
  roomCount,
  grossReturnRate,
  netReturnRate,
}: GetTagListParam): ChipProps[] => {
  const tagList: ChipProps[] = [];

  tagList.push({
    iconName: surfaceMapIcon[propertyKind],
    text: surface ? `${surface} ${t('shared.m2')}` : '',
  });

  if (propertyKind === PropertyKind.Building) {
    tagList.push({
      iconName: 'Projects',
      text: `${totalLots}`,
    });
  } else if (roomCount) {
    tagList.push({
      iconName: 'Bed',
      text: `${roomCount}`,
    });
  } else {
    tagList.push({
      text: t('propertyPreview.roomZero'),
    });
  }

  tagList.push({
    text: `${t('propertyPreview.grossReturn_short')}: ${formatAndRemoveSpaces(
      formatRate(grossReturnRate, 2)
    )}`,
  });

  tagList.push({
    text: `${t('propertyPreview.netReturn_short')}: ${formatAndRemoveSpaces(
      formatRate(netReturnRate, 2)
    )}`,
  });

  return tagList;
};
