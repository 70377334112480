import * as yup from 'yup';
import { TFunction } from 'react-i18next';
import { RequiredStringSchema } from 'yup/lib/string';
import { RequiredBooleanSchema } from 'yup/lib/boolean';
import { ObjectShape } from 'yup/lib/object';
import PN from 'awesome-phonenumber';
import { DebouncedFunc, debounce } from 'lodash';

import { SignInMethod } from '../authBridge/authBridge.constants';
import { checkIfEmailExists } from './onboarding.utils';

type ValidationSchema = (t: TFunction, method?: SignInMethod) => yup.AnyObjectSchema;

let debounceEmailFunc: DebouncedFunc<(email: string | undefined) => Promise<void>> | null;

const debounceTestEmail = (email: string | undefined): Promise<boolean> => {
  return new Promise(resolve => {
    if (debounceEmailFunc) {
      debounceEmailFunc.cancel();
    }

    debounceEmailFunc = debounce(async value => {
      const result = await checkIfEmailExists(value);

      debounceEmailFunc = null;
      resolve(result);
    }, 250);

    debounceEmailFunc(email);
  });
};

interface StepRegisterValidationShape extends ObjectShape {
  email: RequiredStringSchema<string>;
  firstName: RequiredStringSchema<string>;
  lastName: RequiredStringSchema<string>;
  terms: RequiredBooleanSchema<boolean>;
  password?: RequiredStringSchema<string>;
}

export const getStepRegisterValidationSchema: ValidationSchema = (
  t,
  method: SignInMethod = SignInMethod.Mail
) => {
  const defaultErrorFieldName = t('shared.defaultErrorFieldName');

  // This regex is the same use in backend and should stay the same in both
  const emailValidationRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.?)+([^<>().,;:\s@"]{2,}|[\d.]+))$/;

  const shape: StepRegisterValidationShape = {
    email: yup
      .string()
      .required(t('register.requiredField'))
      .matches(emailValidationRegex, t('register.invalidEmail'))
      .lowercase()
      .trim()
      .test('email', t('register.alreadyEmailMessage'), debounceTestEmail)
      .label(defaultErrorFieldName),
    firstName: yup
      .string()
      .trim()
      .required(t('register.requiredField'))
      .label(defaultErrorFieldName),
    lastName: yup
      .string()
      .trim()
      .required(t('register.requiredField'))
      .label(defaultErrorFieldName),
    phoneNumberSuffix: yup.string().test('', t('shared.phoneNumberError'), function (value, ctx) {
      if (!value) {
        return ctx.createError({ message: t('register.requiredField') });
      }

      const phoneNumber = new PN(`${this.parent.phoneNumberPrefix}${value}`.replace(/ /g, ''));

      return phoneNumber.isValid();
    }),
    terms: yup.boolean().isTrue(t('register.invalidTerms')),
  };

  if (method === SignInMethod.Mail) {
    shape.password = yup
      .string()
      .required(t('register.requiredField'))
      .trim()
      .min(8, t('register.passwordLength'))
      .label(defaultErrorFieldName);
  }

  return yup.object().shape(shape);
};
